/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable consistent-return */
import React, { useContext, createContext, useState } from 'react'
import { useQuery } from 'react-query'

import { message } from 'antd'

import api from '~/services/api'

import { useCompanies } from '~/hooks/Companies/useCompanies'
import { useLocale } from '~/hooks/locale/useLocale'

import { ShowError } from '~/utils/errors/apiErrors'
import { translate } from '~/utils/locale'

interface SegmentsProps {
  id: number
  title: string
  description: string
}

interface SegmentsContextData {
  segments?: SegmentsProps[]
  createSegments(name: object | undefined): void
  updateSegments(dataSegment: object | undefined, segmentId: number): void
  deleteSegments(segmentsId: number): void
  isLoading: boolean
  refetch: () => void
  isFetching: boolean
  segmentsTotal?: number
}

const SegmentsContext = createContext<SegmentsContextData>(
  {} as SegmentsContextData,
)

export const SegmentsProvider: React.FC = ({ children }) => {
  const { selectedCompany } = useCompanies()
  const { locale } = useLocale()

  const [segmentsTotal, setSegmentsTotal] = useState<number>()

  const {
    data: segments,
    isLoading,
    refetch,
    isFetching,
  } = useQuery(
    [`segmentos${selectedCompany}`, selectedCompany],

    async () => {
      try {
        if (!selectedCompany) {
          return undefined
        }

        const response = await api.get(`/company/${selectedCompany}/segments`)

        const { data } = response

        setSegmentsTotal(data.total)

        return data.results
      } catch (err: any) {
        ShowError(err.message, translate('useSegments.getErrorMessage'), locale)
      }
    },
    {
      staleTime: 5000,
    },
  )

  async function createSegments(name: object) {
    try {
      await api.post(`/company/${selectedCompany}/segment`, name)

      refetch()
      message.success(translate('useSegments.createSuccessMessage'))
    } catch (err: any) {
      ShowError(
        err.message,
        translate('useSegments.createErrorMessage'),
        locale,
      )
    }
  }

  async function updateSegments(dataSegment: object, segmentId: number) {
    try {
      await api.put(
        `/company/${selectedCompany}/segment/${segmentId}`,
        dataSegment,
      )

      refetch()
      message.success(translate('useSegments.updateSuccessMessage'))
    } catch (err: any) {
      ShowError(
        err.message,
        translate('useSegments.updateErrorMessage'),
        locale,
      )
    }
  }

  async function deleteSegments(segmentsId: number) {
    try {
      await api.delete(`/company/${selectedCompany}/segment/${segmentsId}`)

      refetch()
      message.success(translate('useSegments.deleteSuccessMessage'))
    } catch (err: any) {
      ShowError(
        err.message,
        translate('useSegments.deleteErrorMessage'),
        locale,
      )
    }
  }

  return (
    <SegmentsContext.Provider
      value={{
        segments,
        createSegments,
        updateSegments,
        deleteSegments,
        isLoading,
        refetch,
        isFetching,
        segmentsTotal,
      }}
    >
      {children}
    </SegmentsContext.Provider>
  )
}

export function useSegments(): SegmentsContextData {
  const context = useContext(SegmentsContext)

  if (!context) {
    throw new Error('useAuth must be used within an AutoProvider')
  }

  return context
}
