export default {
  pt: {
    ReportTasksComponent: {
      cardTitle: 'Relatórios de Tarefas',
      totalText: 'Total de',
      reportText: 'relatório',
      reportsText: 'relatórios',
    },
  },
  en: {
    ReportTasksComponent: {
      cardTitle: 'Task Reports',
      totalText: 'Total of',
      reportText: 'repoort',
      reportsText: 'reports',
    },
  },
  es: {
    ReportTasksComponent: {
      cardTitle: 'Informes de tareas',
      totalText: 'Total de',
      reportText: 'informe',
      reportsText: 'informes',
    },
  },
}
