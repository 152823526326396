/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable consistent-return */
import React, { useContext, createContext, useState, useCallback } from 'react'
import dayjs from 'dayjs'

// import { useQuery } from 'react-query'
// import { useCompanies } from '../Companies/useCompanies'
// import api from '~/services/api'
// import { ShowError } from '~/utils/errors/apiErrors'
// import { translate } from '~/utils/locale'
// import { useLocale } from '../locale/useLocale'

interface EquipmentsTasks {
  id: string
}

export interface TasksProps {
  checkin_type: string
  createdAt: Date
  value: number
  date: string
  duration_time: string
  external_code: string
  is_enabled: string
  priority: string
  _id: string
  hours: string
  currentDate: string
  internal_code: string
  current_status: string
  customer_id: string
  task_type_id: string
  responsible: {
    id: string
    type: string
    data: {
      contact: {
        name: string
      }
      title: string
    }
  }
  customer: {
    id: string
    contact: {
      name: string
    }
    parent_customer: string
  }
  taskType: {
    _id: string
    title: string
    description: string
    runtime: string
    valueTask: number
  }
  address: {
    city: string
    id: string
    location: {
      x: number
      y: number
    }
    neighborhood: string
    number: number
    state: string
    street: string
    zip_code: string
    complement?: string
  }
  equipments: EquipmentsTasks[]
  description: string
  note: string
}

interface FilterOptionsProps {
  fromDate?: dayjs.Dayjs
  toDate?: dayjs.Dayjs
  current_status?: string
}

interface TasksByTypePieChartProps {
  name: string
  value: number
  tasks: TasksProps[]
}

interface TasksTotalLineChartProps {
  Tarefas: number
  Date: string
  originalDate: Date
  tasks: TasksProps[]
}

interface TasksByValuePieChartProps {
  name: string
  totalValue: number
  tasks: TasksProps[]
}

interface TasksValueLineChartProps {
  date: string
  totalTaskValue: number
  originalDate: Date
  tasks: TasksProps[]
}

interface ContributorTasksProps {
  nome: string
  totalTarefas: number
  totalMedia: number
  valorTotal: number
  tasks: {
    _id: string
    date: string
    customer: string
    parent_customer: string
    value: number
  }[]
}

interface CustomersTasksProps {
  nome: string
  totalTarefas: number
  totalMedia: number
  valorTotal: number
  tasks: {
    _id: string
    date: string
    customer: string
    parent_customer: string
    value: number
  }[]
}

interface ParentCustomerTasksProps {
  _id: number
  nome: string
  totalTarefas: number
  totalMedia: number
  valorTotal: number
  tasks: {
    _id: string
    date: string
    customer: string
    parent_customer: string
    value: number
  }[]
}

export interface DashboardTasksDataProps {
  TasksByTypePieChart: TasksByTypePieChartProps[]
  TasksTotalLineChart: TasksTotalLineChartProps[]
  TasksByValuePieChart: TasksByValuePieChartProps[]
  TasksValueLineChart: TasksValueLineChartProps[]
  ContributorTasks: ContributorTasksProps[]
  CustomersTasks: CustomersTasksProps[]
  ParentCustomerTasks: ParentCustomerTasksProps[]
  filteredTasks: TasksProps[]
}

interface DashboardTasksContextData {
  filterOptions: FilterOptionsProps
  handleUpdateFilters: (newFilterOptions: any) => void
  handleResetFilters: () => void
  // data: DashboardTasksDataProps
  // isFetching: boolean
  // isLoading: boolean
  // refetch: () => void
}

const DashboardContext = createContext<DashboardTasksContextData>(
  {} as DashboardTasksContextData,
)

export const DashboardTasksProvider: React.FC = ({ children }) => {
  // const { selectedCompany } = useCompanies()
  // const { locale } = useLocale()

  const [filterOptions, setFilterOptions] = useState<FilterOptionsProps>(() => {
    const filter = localStorage.getItem('@Gstor:dashboardTasksFilterOptions')

    if (filter) {
      return JSON.parse(filter)
    }

    return {} as FilterOptionsProps
  })

  const handleUpdateFilters = useCallback((newFilterOptions) => {
    const newFilters = {
      current_status: newFilterOptions?.current_status,
      fromDate: dayjs(newFilterOptions?.fromDate).startOf('day'),
      toDate: dayjs(newFilterOptions?.toDate).endOf('day'),
    }
    setFilterOptions(newFilters)
    localStorage.setItem(
      '@Gstor:dashboardTasksFilterOptions',
      JSON.stringify(newFilters),
    )
  }, [])

  const handleResetFilters = useCallback(() => {
    localStorage.removeItem('@Gstor:dashboardTasksFilterOptions')
    setFilterOptions({})
  }, [])

  // const { data, isLoading, refetch, isFetching } = useQuery(
  //   [
  //     `Lista Dashboard de Tarefas${selectedCompany}`,
  //     selectedCompany,
  //     filterOptions,
  //   ],

  //   async () => {
  //     try {
  //       if (!selectedCompany) {
  //         return undefined
  //       }

  //       const response = await api.get(
  //         `/company/${selectedCompany}/tasks/dashboard`,
  //         {
  //           params: {
  //             // responsibleContributors: filterOptions?.responsibleContributors,
  //             // responsibleTeams: filterOptions?.responsibleTeams,
  //             // address: filterOptions?.address,
  //             // customers_id: filterOptions?.customers_id,
  //             // internal_code: filterOptions?.internal_code,
  //             // current_status: filterOptions?.current_status,
  //             // equipment_id: filterOptions?.equipment_id,
  //             // external_code: filterOptions?.external_code,
  //             // tasks_types_id: filterOptions?.tasks_types_id,
  //             // priority: filterOptions?.priority,
  //             // note: filterOptions?.note,
  //             fromDate: filterOptions?.fromDate
  //               ? dayjs.utc(filterOptions?.fromDate).format()
  //               : dayjs().subtract(7, 'days').startOf('day').format(),
  //             toDate: filterOptions?.toDate
  //               ? dayjs.utc(filterOptions?.toDate).format()
  //               : dayjs().add(7, 'days').startOf('day').format(),
  //           },
  //         },
  //       )

  //       const { data } = response

  //       return data.results
  //     } catch (err: any) {
  //       ShowError(
  //         err.message,
  //         translate('useListTasks.getErrorMessage'),
  //         locale,
  //       )
  //     }
  //   },
  // )

  return (
    <DashboardContext.Provider
      value={{
        filterOptions,
        handleUpdateFilters,
        handleResetFilters,
        // data,
        // isLoading,
        // isFetching,
        // refetch,
      }}
    >
      {children}
    </DashboardContext.Provider>
  )
}

export function useDashboardTasks(): DashboardTasksContextData {
  const context = useContext(DashboardContext)

  if (!context) {
    throw new Error('useAuth must be used within an AutoProvider')
  }

  return context
}
