export default {
  pt: {
    GroupsCreatePermissions: {
      permissionsButton: 'Permissões',
      modalTitle: 'Criar Permissão',
      closeButton: 'Fechar',
      saveButton: 'Salvar',
      moduleLabel: 'Módulo',
      modulePlaceholder: 'Escolha o módulo',
      contributorScale: 'Escala de Colaboradores',
      clientScale: 'Escala de Clientes',
      scheduleScale: 'Escala de Horários',
      scales: 'Escalas',
      clientContact: 'Contato de Clientes',
      clientSegments: 'Segmentos de Clientes',
      clients: 'Clientes',
      contributorStatus: 'Status de Colaboradores',
      groups: 'Grupos',
      addresses: 'Endereços',
      users: 'Usuários',
      groupsPermissions: 'Permissões de Grupos',
      companies: 'Empresas',
      contacts: 'Contatos',
      contributors: 'Colaboradores',
      contributorsAttachments: 'Documentos/Anexos de Colaboradores',
      contributorsEvaluations: 'Avaliações de Colaboradores',
      contributorsSkills: 'Habilidades de Colaboradores',
      associatedEquipments: 'Equipamentos Associados',
      movements: 'Movimentações',
      stocks: 'Estoques',
      equipments: 'Equipamentos',
      categories: 'Categorias',
      contactsAddresses: 'Endereços de Contatos',
      specificationsTemplates: 'Templates de Especificação',
      contributorsTeams: 'Equipe de Colaboradores',
      teams: 'Equipes',
      tasks: 'Tarefas',
      breakReasons: 'Motivos de Pausa',
      tasksTypes: 'Tipos de Tarefa',
      equipmentsAttachments: 'Documentos/Anexos do Equipamento',
      equipmentsSpecifications: 'Especificações do Equipamento',
      tasksStatus: 'Status da Tarefa',
      tasksReports: 'Evidências da Tarefa',
      tasksEquipments: 'Equipamentos da Tarefa',
      reports: 'Relatórios',
      permissionsLabel: 'Permissões',
      createPermission: 'Permitir que o usuário possa criar.',
      editPermission: 'Permitir que o usuário possa editar.',
      viewPermission: 'Permitir que o usuário possa visualizar.',
      deletePermission: 'Permitir que o usuário possa deletar.',
    },
  },
  en: {
    GroupsCreatePermissions: {
      permissionsButton: 'Permissions',
      modalTitle: 'Create Permission',
      closeButton: 'Close',
      saveButton: 'Save',
      moduleLabel: 'Module',
      modulePlaceholder: 'Choose Module',
      contributorScale: 'Scale of Collaborators',
      clientScale: 'Customer Scale',
      scheduleScale: 'Schedule Scale',
      scales: 'Scales',
      clientContact: 'Customer Contact',
      clientSegments: 'Customer Segments',
      clients: 'Customers',
      contributorStatus: 'Collaborators Status',
      groups: 'Groups',
      addresses: 'Addresses',
      users: 'Users',
      groupsPermissions: 'Group Permissions',
      companies: 'Companies',
      contacts: 'Contacts',
      contributors: 'Collaborators',
      contributorsAttachments: 'Documents/Attachments of Collaborators',
      contributorsEvaluations: 'Collaborator Reviews',
      contributorsSkills: 'Collaborators Skills',
      associatedEquipments: 'Associated Equipment',
      movements: 'Movements',
      stocks: 'Stocks',
      equipments: 'Equipment',
      categories: 'Categories',
      contactsAddresses: 'Contact Addresses',
      specificationsTemplates: 'Specification Templates',
      contributorsTeams: 'Team of Collaborators',
      teams: 'Teams',
      tasks: 'Tasks',
      breakReasons: 'Break Reasons',
      tasksTypes: 'Task Types',
      equipmentsAttachments: 'Documents/Equipment Attachments',
      equipmentsSpecifications: 'Equipment Specifications',
      tasksStatus: 'Task Status',
      tasksReports: 'Task Reports',
      tasksEquipments: 'Task Equipment',
      reports: 'Reports',
      permissionsLabel: 'Permissions',
      createPermission: 'Allow user to create.',
      editPermission: 'Allow user to edit.',
      viewPermission: 'Allow user to view.',
      deletePermission: 'Allow user to delete.',
    },
  },
  es: {
    GroupsCreatePermissions: {
      permissionsButton: 'Permisos',
      modalTitle: 'Crear permiso',
      closeButton: 'Cerrar',
      saveButton: 'Guardar',
      moduleLabel: 'Módulo',
      modulePlaceholder: 'Elegir módulo',
      contributorScale: 'Escala de Colaboradores',
      clientScale: 'Escala de Clientes',
      scheduleScale: 'Programación',
      scales: 'Escalas',
      clientContact: 'Contacto de cliente',
      clientSegments: 'Segmentos de Clientes',
      clients: 'Clientes',
      contributorStatus: 'Estado del Empleado',
      groups: 'Grupos',
      addresses: 'Direcciones',
      users: 'Usuarios',
      groupsPermissions: 'Permisos de grupo',
      companies: 'Compañías',
      contacts: 'Contactos',
      contributors: 'Colaboradores',
      contributorsAttachments: 'Documentos/Adjuntos de Empleados',
      contributorsEvaluations: 'Reseñas de empleados',
      contributorsSkills: 'Habilidades de los empleados',
      associatedEquipments: 'Equipamiento asociado',
      movements: 'Movimientos',
      stocks: 'Inventarios',
      equipments: 'Equipo',
      categories: 'Categorías',
      contactsAddresses: 'Direcciones de contacto',
      specificationsTemplates: 'Plantillas de especificaciones',
      contributorsTeams: 'Equipo de Colaboradores',
      teams: 'Equipos',
      tasks: 'Tareas',
      breakReasons: 'Romper Razones',
      tasksTypes: 'Tipos de tareas',
      equipmentsAttachments: 'Documentos/Accesorios de equipo',
      equipmentsSpecifications: 'Especificaciones del equipo',
      tasksStatus: 'Estado de la tarea',
      tasksReports: 'Informes de tareas',
      tasksEquipments: 'Equipo de trabajo',
      reports: 'Informes',
      permissionsLabel: 'Permisos',
      createPermission: 'Permitir que el usuario cree.',
      editPermission: 'Permitir que el usuario edite.',
      viewPermission: 'Permitir que el usuario vea.',
      deletePermission: 'Permitir que el usuario elimine.',
    },
  },
}
