/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-one-expression-per-line */
import { UnorderedListOutlined, UserOutlined } from '@ant-design/icons'
import { Modal, Button, Dropdown, Tabs, Row, Col, Tag, Timeline } from 'antd'
import Text from 'antd/lib/typography/Text'
import { format } from 'date-fns'
import React, { useState, Fragment } from 'react'
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'
import L from 'leaflet'

import { Link } from 'react-router-dom'
import { useListContributors } from '~/hooks/Contributors/useListContributors'
import { useTeams } from '~/hooks/Teams/useListTeams'
import { useListClients } from '~/hooks/Clients/useListClients'

import { Container, TasksWrapper } from './styles'
import { translate } from '~/utils/locale'

import type { MenuProps } from 'antd'

interface EquipmentsTasks {
  id: string
}

interface TaskTypeProps {
  _id: string
  title: string
}

interface Address {
  city: string
  id: string
  location: {
    x: number
    y: number
  }
  neighborhood: string
  number: number
  state: string
  street: string
  zip_code: string
  complement: string
}

interface Responsible {
  id: string
  type: string
}

interface Status {
  type: string
  created_at: {
    $date: Date
  }
  initialDate: Date
  collaborator_id: string
  location: {
    lat: number
    long: number
  }
}

interface Report {
  created_at: Date
  id: string
  type: string
  value: string
  title: string
  file_url: string
}

interface DataProps {
  checkin_type: string
  createdAt: string
  date: string
  duration_time: string
  external_code: string
  is_enabled: string
  priority: string
  description: string
  current_status: string
  _id: string
  hours: string
  currentDate: string
  task_type_id: TaskTypeProps
  initialTime: string
  customer_id: string
  equipments: EquipmentsTasks[]
  address: Address
  responsible: Responsible
  status: Status[]
  reports: Report[]
}

interface ModalReportTasksProps {
  dataReport: DataProps
}

const ModalReportTasks: React.FC<ModalReportTasksProps> = ({ dataReport }) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isMapVisible, setIsMapVisible] = useState(false)

  const { data } = useListContributors()
  const { teams } = useTeams()
  const { clients } = useListClients()

  const { TabPane } = Tabs

  const showModal = () => {
    setIsModalVisible(true)
  }

  const closeModal = () => {
    setIsModalVisible(false)
  }

  const showMap = () => {
    setIsMapVisible(true)
  }

  const closeMap = () => {
    setIsMapVisible(false)
  }

  // const menu = (
  //   <Menu>
  //     <Menu.Item key="0" onClick={showModal}>
  //       {translate('ReportTasksModal.menuDetails')}
  //     </Menu.Item>
  //     <Menu.Item key="1" onClick={showMap}>
  //       {translate('ReportTasksModal.menuMap')}
  //     </Menu.Item>
  //   </Menu>
  // );

  const items: MenuProps['items'] = [
    {
      key: 1,
      label: translate('ReportTasksModal.menuDetails'),
      icon: <UserOutlined onClick={showModal} />,
    },
    {
      key: 2,
      label: translate('ReportTasksModal.menuMap'),
      icon: <UserOutlined onClick={showMap} />,
    },
  ]

  const statusFormatted = dataReport.status.map((taskStatus: any) => {
    const dataFormat = {
      ...taskStatus,
      initialDate: format(
        new Date(taskStatus.created_at),
        `dd/MM/yyyy '${translate('ReportTasksModal.dateString')}' HH:mm'h'`,
      ),
      colors: taskStatus.type.indexOf(0) ? '#16a085' : '#3498db',
    }

    return dataFormat
  })

  const coordinates: any = statusFormatted.filter((coord) => {
    if (coord.location?.lat && coord.location?.long !== null) {
      return coord
    }

    return coordinates
  })

  const iconAgent = L.divIcon({
    className: '',
    html: `<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128"><defs><style>.cls-1{fill:'#3498db';}.cls-2{fill:'#3498db';}</style></defs><title>b</title><path class="cls-1" d="M64.00178,3.36652c-25.74943,0-43.04956,14.75866-43.04956,36.7246,0,29.11223,37.01485,81.60069,37.38345,82.01113a7.60318,7.60318,0,0,0,11.3233.00579c.37394-.41623,37.3888-52.90469,37.3888-82.01692C107.04778,18.12518,89.74853,3.36652,64.00178,3.36652ZM64,74.73868a28.29593,28.29593,0,1,1,28.296-28.296A28.29592,28.29592,0,0,1,64,74.73868Z"/><path class="cls-2" d="M82.84186,58.57151c-.155.24618-.31.4741-.4741.71116a22.39884,22.39884,0,0,1-36.73256.00913c-.17323-.23705-.33733-.48323-.48323-.72935.01825-.12765.0365-.24618.0547-.3647a4.03615,4.03615,0,0,1,2.16079-2.90834c3.76529-1.87811,12.00714-4.6406,12.00714-4.6406v-2.726l-.22793-.17323a7.86155,7.86155,0,0,1-2.99042-5.00525l-.04557-.29175h-.2188a3.02492,3.02492,0,0,1-2.81719-1.88724,3.275,3.275,0,0,1-.41935-1.61368,3.1367,3.1367,0,0,1,.20967-1.12143,1.58361,1.58361,0,0,1,.61083-.9846l.76586-.45585-.18235-.82056c-1.34018-5.86225,3.04512-11.141,9.06235-11.51483a.5194.5194,0,0,1,.11853-.00913c.10027-.00913.20055-.0182.30088-.0182h.9117c.10027,0,.20055.00907.30082.0182a.51916.51916,0,0,1,.11853.00913c6.02641.37383,10.41171,5.65258,9.07147,11.51483l-.19148.82056.76586.45585a1.54549,1.54549,0,0,1,.61083.9846,3.14584,3.14584,0,0,1,.2188,1.12143,3.37534,3.37534,0,0,1-.41935,1.61368,3.02486,3.02486,0,0,1-2.81719,1.88724h-.2188l-.0547.29175a7.81113,7.81113,0,0,1-2.98129,5.00525l-.22793.17323v2.726s8.24185,2.76249,11.99806,4.6406a4.01318,4.01318,0,0,1,2.16074,2.90834C82.80541,58.31626,82.82361,58.44391,82.84186,58.57151Z"/></svg>`,
    iconSize: [40, 50],
    iconAnchor: [20, 15],
  })

  return (
    <Container>
      <Dropdown menu={{ items }} trigger={['click']}>
        <UnorderedListOutlined />
      </Dropdown>
      <Modal
        title={translate('ReportTasksModal.modalTitle')}
        open={isModalVisible}
        onOk={closeModal}
        onCancel={closeModal}
        width="900px"
        style={{
          top: 40,
          overflowY: 'scroll',
          maxHeight: 'calc(100vh - 200px)',
        }}
        footer={[
          <Button key="close" onClick={closeModal}>
            {translate('ReportTasksModal.closeButton')}
          </Button>,
          <Link
            to={`/tarefas/visualizar/${dataReport._id}/detalhes`}
            key="view"
          >
            <Button key="save" type="primary" style={{ margin: '0 8px 0 8px' }}>
              {translate('ReportTasksModal.viewButton')}
            </Button>
          </Link>,
        ]}
      >
        <Tabs defaultActiveKey="1">
          <TabPane tab={translate('ReportTasksModal.taskDetailTab')} key="1">
            <TasksWrapper>
              <Row gutter={12}>
                <Col span={12}>
                  <div className="text">
                    <Text strong>
                      {translate('ReportTasksModal.executerLabel')}
                    </Text>
                    {dataReport.responsible?.type === 'collaborator' ? (
                      <>
                        {data?.map((c) => (
                          <div key={c.id}>
                            {c.id === dataReport.responsible?.id ? (
                              <Text>{c.contact.name}</Text>
                            ) : (
                              ''
                            )}
                          </div>
                        ))}
                      </>
                    ) : (
                      ''
                    )}

                    {dataReport.responsible?.type === 'team' ? (
                      <>
                        {teams?.map((c) => (
                          <div key={c.id}>
                            {c.id === dataReport.responsible.id ? (
                              <Text>{c?.title}</Text>
                            ) : (
                              ''
                            )}
                          </div>
                        ))}
                      </>
                    ) : (
                      ''
                    )}
                  </div>
                </Col>
                <Col span={12}>
                  <div className="text">
                    <Text strong>{translate('ReportTasksModal.toLabel')}</Text>
                    {clients?.map((c) => (
                      <Fragment key={c.id}>
                        {c.id === dataReport.customer_id ? (
                          <Text>{c.contact.name}</Text>
                        ) : (
                          ''
                        )}
                      </Fragment>
                    ))}
                  </div>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col span={12}>
                  <div className="text">
                    <Text strong>
                      {translate('ReportTasksModal.initialTimeLabel')}
                    </Text>
                    <Text>{dataReport.initialTime}</Text>
                  </div>
                </Col>
                <Col span={12}>
                  <div className="text">
                    <Text strong>
                      {translate('ReportTasksModal.durationTimeLabel')}
                    </Text>
                    <Text>{dataReport.duration_time}</Text>
                  </div>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col span={12}>
                  <div className="text">
                    <Text strong>
                      {translate('ReportTasksModal.checkinType')}
                    </Text>
                    <Text>
                      {translate('ReportTasksModal.checkinTypeManual')}
                    </Text>
                  </div>
                </Col>
                <Col span={12}>
                  <div className="text">
                    <Text strong>
                      {translate('ReportTasksModal.priorityLabel')}
                    </Text>
                    {dataReport.priority === 'low' ? (
                      <Tag color="green">
                        {translate('ReportTasksModal.priorityLow')}
                      </Tag>
                    ) : (
                      ''
                    )}
                    {dataReport.priority === 'medium' ? (
                      <Tag color="yellow">
                        {translate('ReportTasksModal.priorityMedium')}
                      </Tag>
                    ) : (
                      ''
                    )}
                    {dataReport.priority === 'high' ? (
                      <Tag color="red">
                        {translate('ReportTasksModal.priorityHigh')}
                      </Tag>
                    ) : (
                      ''
                    )}
                  </div>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col span={12}>
                  <div className="text">
                    <Text strong>{translate('ReportTasksModal.taskType')}</Text>
                    <Text>{dataReport.task_type_id?.title}</Text>
                  </div>
                </Col>
                <Col span={12}>
                  <div className="text">
                    <Text strong>
                      {translate('ReportTasksModal.externalCode')}
                    </Text>
                    <Text>{dataReport.external_code}</Text>
                  </div>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col span={12}>
                  <div className="text">
                    <Text strong>
                      {translate('ReportTasksModal.statusLabel')}
                    </Text>
                    {dataReport.is_enabled === 'false' ? (
                      <Tag>{translate('ReportTasksModal.statusInactive')}</Tag>
                    ) : (
                      <Tag color="green">
                        {translate('ReportTasksModal.statusActive')}
                      </Tag>
                    )}
                  </div>
                </Col>
              </Row>
            </TasksWrapper>
          </TabPane>
          <TabPane tab={translate('ReportTasksModal.addressTab')} key="2">
            <p>
              {translate('ReportTasksModal.zipcodeLabel')}
              {dataReport.address.zip_code}
            </p>
            <p>
              {translate('ReportTasksModal.addressLabel')}
              {dataReport.address.street}, &nbsp;
              {dataReport.address.number}
              &nbsp; - {dataReport.address.neighborhood}
            </p>
            <p style={{ marginBottom: 15 }}>
              {translate('ReportTasksModal.cityLabel')}
              {dataReport.address.city} - {dataReport.address.state}
            </p>
            <MapContainer
              center={[
                dataReport.address.location.x,
                dataReport.address.location.y,
              ]}
              zoom={13}
              scrollWheelZoom={false}
              style={{ height: '250px', width: '100%' }}
            >
              <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              {dataReport.address.location.x !== 0 &&
                dataReport.address.location.y !== 0 && (
                  <Marker
                    position={[
                      dataReport.address.location.x,
                      dataReport.address.location.y,
                    ]}
                  />
                )}
            </MapContainer>
          </TabPane>
        </Tabs>
      </Modal>

      <Modal
        title={translate('ReportTasksModal.statusModalTitle')}
        open={isMapVisible}
        onOk={closeMap}
        onCancel={closeMap}
        style={{
          top: 30,
          overflowY: 'scroll',
          maxHeight: 'calc(100vh - 200px)',
        }}
        width="1000px"
        footer={[
          <Button key="close" onClick={closeMap}>
            {translate('ReportTasksModal.closeButton')}
          </Button>,
        ]}
      >
        <MapContainer
          center={[
            dataReport.address.location.x,
            dataReport.address.location.y,
          ]}
          zoom={13}
          scrollWheelZoom={false}
          style={{ height: '350px', width: '100%' }}
        >
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {dataReport.address.location.x !== 0 &&
            dataReport.address.location.y !== 0 && (
              <Marker
                position={[
                  dataReport.address.location.x,
                  dataReport.address.location.y,
                ]}
              />
            )}

          {coordinates.map((c: Status, index: number) => (
            <Marker
              position={[c.location.lat, c.location.long]}
              icon={iconAgent}
              key={index}
            >
              <Popup>
                {c.type === 'viewed' ? (
                  <p>{translate('ReportTasksModal.statusViewed')}</p>
                ) : (
                  ''
                )}
                {c.type === 'en-route' ? (
                  <p>{translate('ReportTasksModal.statusEnRoute')}</p>
                ) : (
                  ''
                )}
                {c.type === 'check-in' ? (
                  <p>{translate('ReportTasksModal.statusCheckIn')}</p>
                ) : (
                  ''
                )}
                {c.type === 'pause-task' ? (
                  <p>{translate('ReportTasksModal.statusPauseTask')}</p>
                ) : (
                  ''
                )}
                {c.type === 'resume-working' ? (
                  <p>{translate('ReportTasksModal.statusResumeWorking')}</p>
                ) : (
                  ''
                )}
                {c.type === 'checkout' ? (
                  <p>{translate('ReportTasksModal.statusCheckout')}</p>
                ) : (
                  ''
                )}
                <p>{c.initialDate}</p>
              </Popup>
            </Marker>
          ))}
        </MapContainer>
        <div style={{ marginTop: 30 }}>
          <h3 style={{ marginBottom: 20 }}>
            {translate('ReportTasksModal.accompanimentLabel')}
          </h3>
          <Timeline>
            {statusFormatted.map((st, index) => (
              <Timeline.Item color={st.colors} key={index}>
                {st.type === 'received' ? (
                  <p>{translate('ReportTasksModal.statusReceived')}</p>
                ) : (
                  ''
                )}
                {st.type === 'viewed' ? (
                  <p>{translate('ReportTasksModal.statusViewed')}</p>
                ) : (
                  ''
                )}
                {st.type === 'en-route' ? (
                  <p>{translate('ReportTasksModal.statusEnRoute')}</p>
                ) : (
                  ''
                )}
                {st.type === 'check-in' ? (
                  <p>{translate('ReportTasksModal.statusCheckIn')}</p>
                ) : (
                  ''
                )}
                {st.type === 'pause-task' ? (
                  <p>{translate('ReportTasksModal.statusPauseTask')}</p>
                ) : (
                  ''
                )}
                {st.type === 'resume-working' ? (
                  <p>{translate('ReportTasksModal.statusResumeWorking')}</p>
                ) : (
                  ''
                )}
                {st.type === 'checkout' ? (
                  <p>{translate('ReportTasksModal.statusCheckout')}</p>
                ) : (
                  ''
                )}
                {data?.map((c) => (
                  <div key={c.id}>
                    {c.id === dataReport.responsible?.id ? (
                      <p>Colaborador {c.contact.name}</p>
                    ) : (
                      ''
                    )}
                  </div>
                ))}
                <p style={{ color: '#A7A7A7' }}>{st.initialTime}</p>
              </Timeline.Item>
            ))}
          </Timeline>
        </div>
      </Modal>
    </Container>
  )
}

export default ModalReportTasks
