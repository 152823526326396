export default {
  pt: {
    TasksBoardsList: {
      cardTitle: 'Tarefas',
      totalText: 'Total de',
      taskText: 'tarefa',
      tasksText: 'tarefas',
      exportTooltip: 'Exportar Tarefas',
      importTooltip: 'Importar Tarefas',
      modalTitle: 'Importação de Tarefas',
      importTitle: 'Importar Tarefas',
      importDescription: 'Upload de arquivo para importação de Tarefas',
      createButton: 'Criar Tarefa',
    },
  },
  en: {
    TasksBoardsList: {
      cardTitle: 'Tasks',
      totalText: 'Total of',
      taskText: 'task',
      tasksText: 'tasks',
      exportTooltip: 'Export Tasks',
      importTooltip: 'Import Tasks',
      modalTitle: 'Task Import',
      importTitle: 'Import Tasks',
      importDescription: 'File upload for Task Import',
      exportButton: 'Export',
      createButton: 'Create Task',
    },
  },
  es: {
    TasksBoardsList: {
      cardTitle: 'Tareas',
      totalText: 'Total de',
      taskText: 'tarea',
      tasksText: 'tareas',
      exportTooltip: 'Exportar Tareas',
      importTooltip: 'Importar Tareas',
      modalTitle: 'Importación de Tareas',
      importTitle: 'Importar Tareas',
      importDescription: 'Carga de archivos para la importación de tareas',
      exportButton: 'Exportar',
      createButton: 'Crear Tarea',
    },
  },
}
