export default {
  pt: {
    MiscWeekdaySelector: {
      sundayTooltip: 'Domingo',
      sunday: 'D',
      mondayTooltip: 'Segunda-Feira',
      monday: 'S',
      tuesdayTooltip: 'Terça-Feira',
      tuesday: 'T',
      wednesdayTooltip: 'Quarta-Feira',
      wednesday: 'Q',
      thursdayTooltip: 'Quinta-Feira',
      thursday: 'Q',
      fridayTooltip: 'Sexta-Feira',
      friday: 'S',
      saturdayTooltip: 'Sábado',
      saturday: 'S',
    },
  },
  en: {
    MiscWeekdaySelector: {
      sundayTooltip: 'Sunday',
      sunday: 'S',
      mondayTooltip: 'Monday',
      monday: 'M',
      tuesdayTooltip: 'Tuesday',
      tuesday: 'T',
      wednesdayTooltip: 'Wednesday',
      wednesday: 'W',
      thursdayTooltip: 'Thursday',
      thursday: 'T',
      fridayTooltip: 'Friday',
      friday: 'F',
      saturdayTooltip: 'Saturday',
      saturday: 'S',
    },
  },
  es: {
    MiscWeekdaySelector: {
      sundayTooltip: 'Domingo',
      sunday: 'D',
      mondayTooltip: 'Lunes',
      monday: 'L',
      tuesdayTooltip: 'Martes',
      tuesday: 'M',
      wednesdayTooltip: 'Miércoles',
      wednesday: 'M',
      thursdayTooltip: 'Jueves',
      thursday: 'J',
      fridayTooltip: 'Viernes',
      friday: 'V',
      saturdayTooltip: 'Sábado',
      saturday: 'S',
    },
  },
}
