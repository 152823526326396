export default {
  pt: {
    useListGroup: {
      getErrorMessage: 'Erro ao visualizar grupos de permissão.',
      createErrorMessage: 'Erro ao criar grupos de permissão.',
      updateSuccessMessage: 'Grupo atualizado com sucesso!',
      updateErrorMessage: 'Erro ao atualizar grupos de permissão.',
      deleteSuccessMessage: 'Grupo deletado com sucesso!',
      deleteErrorMessage: 'Erro ao excluir grupos de permissão.',
    },
  },
  en: {
    useListGroup: {
      getErrorMessage: 'Error viewing permission groups.',
      createErrorMessage: 'Error creating permission groups.',
      updateSuccessMessage: 'Group successfully updated!',
      updateErrorMessage: 'Error updating permission groups.',
      deleteSuccessMessage: 'Group successfully deleted!',
      deleteErrorMessage: 'Error deleting permission groups.',
    },
  },
  es: {
    useListGroup: {
      getErrorMessage: 'Error al ver los grupos de permisos.',
      createErrorMessage: 'Error al crear grupos de permisos.',
      updateSuccessMessage: '¡Grupo actualizado con éxito!',
      updateErrorMessage: 'Error al actualizar los grupos de permisos.',
      deleteSuccessMessage: '¡Grupo eliminado con éxito!',
      deleteErrorMessage: 'Error al eliminar grupos de permisos.',
    },
  },
}
