import React, { useState } from 'react'

import { Button, Modal, Space, Typography } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

import ScaleContributorsTable from '~/components/DataManipulation/Scales/ScaleContributors/ScaleContributorsTable'
import ContributorsFilter from '~/components/DataManipulation/Contributor/ContributorsFilter'

import { useListContributors } from '~/hooks/Contributors/useListContributors'
import { translate } from '~/utils/locale'

const ScaleContributorsAdd: React.FC = () => {
  const { Title } = Typography

  const [isModalVisible, setIsModalVisible] = useState(false)

  const { data, isLoading, isFetching } = useListContributors()

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  return (
    <>
      <Button type="primary" onClick={showModal}>
        {translate('ScalesContributorsAdd.addButton')}
      </Button>
      <Modal
        title={
          <Space>
            <Title level={5}>
              {translate('ScalesContributorsAdd.modalTitle')}
            </Title>
            {!isLoading && isFetching && <LoadingOutlined spin />}
          </Space>
        }
        open={isModalVisible}
        width={1200}
        onCancel={handleCancel}
        footer={[
          <Space key="FooterProps">
            <Button key="back" onClick={handleCancel}>
              {translate('ScalesContributorsAdd.closeButton')}
            </Button>
            <ContributorsFilter />
          </Space>,
        ]}
      >
        <ScaleContributorsTable data={data} loading={isLoading} />
      </Modal>
    </>
  )
}

export default ScaleContributorsAdd
