export default {
  pt: {
    useTicket: {
      getErrorMessage: 'Erro ao visualizar o ticket.',
      updateSuccessMessage: 'Ticket atualizado com sucesso!',
      updateErrorMessage: 'Erro ao atualizar o ticket.',
      linkSuccessMessage: 'Tarefa vinculada com sucesso!',
      linkErrorMessage: 'Houve um erro ao vincular o ticket.',
      deleteSuccessMessage: 'Tarefa desvinculada com sucesso!',
      deleteErrorMessage: 'Houve um erro ao desvincular o ticket.',
    },
  },
  en: {
    useTicket: {
      getErrorMessage: 'Error viewing ticket.',
      updateSuccessMessage: 'Ticket successfully updated!',
      updateErrorMessage: 'Error updating ticket.',
      linkSuccessMessage: 'Successfully linked task!',
      linkErrorMessage: 'There was an error linking the ticket.',
      deleteSuccessMessage: 'Task unlinked successfully!',
      deleteErrorMessage: 'There was an error linking the ticket.',
    },
  },
  es: {
    useTicket: {
      getErrorMessage: 'Error al ver el ticket.',
      updateSuccessMessage: '¡Ticket actualizado con éxito!',
      updateErrorMessage: 'Error al actualizar ticket.',
      linkSuccessMessage: '¡Tarea vinculada con éxito!',
      linkErrorMessage: 'Hubo un error al vincular el ticket.',
      deleteSuccessMessage: 'Tarea vinculada con éxito!',
      deleteErrorMessage: 'Hubo un error al vincular el ticket.',
    },
  },
}
