export default {
  pt: {
    Tasks: {
      helmetTitle: 'Agenda',
    },
  },
  en: {
    Tasks: {
      helmetTitle: 'Schedule',
    },
  },
  es: {
    Tasks: {
      helmetTitle: 'Calendario',
    },
  },
}
