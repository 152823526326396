/* eslint-disable func-names */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-curly-newline */
import {
  LoadingOutlined,
  PlusCircleOutlined,
  PlusOutlined,
  ReloadOutlined,
} from '@ant-design/icons'
import { Button, List, Modal, Popconfirm, Tooltip } from 'antd'
import React, { useState } from 'react'

import { useTaskCreate } from '~/hooks/Tasks/Create/createTask'
import { useScaleTask } from '~/hooks/Tasks/Create/scaleTask'
import { translate } from '~/utils/locale'

// import { Container } from './styles';

const ContributorCreateTask: React.FC = () => {
  const { scaleData, refetch, isFetching } = useScaleTask()
  const { contributorData, setContributorData } = useTaskCreate()

  const [isModalVisible, setIsModalVisible] = useState(false)

  async function handleContributorTask(id: string, name: string) {
    await setContributorData({ id, name })
    setIsModalVisible(false)
  }

  const showModal = () => {
    setIsModalVisible(true)
    refetch()
  }

  const closeModal = () => {
    setIsModalVisible(false)
  }

  return (
    <>
      {!contributorData?.id ? (
        <Button
          icon={<PlusCircleOutlined />}
          onClick={showModal}
          style={{ width: '100%' }}
        >
          {translate('TasksCreateModalContributor.addContributor')}
        </Button>
      ) : (
        <Button size="small" icon={<ReloadOutlined />} onClick={showModal}>
          {translate('TasksCreateModalContributor.changeContributor')}
        </Button>
      )}
      <Modal
        title={translate('TasksCreateModalContributor.modalTitle')}
        open={isModalVisible}
        onOk={closeModal}
        onCancel={closeModal}
        width="900px"
        style={{
          top: 30,
          overflowY: 'scroll',
          maxHeight: 'calc(100vh - 200px)',
        }}
      >
        <>
          <h4>
            {translate('TasksCreateModalContributor.rangeText')}&nbsp;
            {scaleData?.operating_range} KM
          </h4>
          <List
            size="small"
            header={
              <div>
                {translate('TasksCreateModalContributor.contributorHeader')}
              </div>
            }
            bordered
            dataSource={scaleData?.contributorsSchedules}
            loading={{
              indicator: <LoadingOutlined />,
              spinning: isFetching,
              size: 'large',
            }}
            renderItem={(item) => (
              <List.Item
                actions={[
                  <Popconfirm
                    key="ActionsProps"
                    title={translate(
                      'TasksCreateModalContributor.addPopconfirm',
                    )}
                    onConfirm={() =>
                      handleContributorTask(
                        item.collaborator.id,
                        item.collaborator.contact.name,
                      )
                    }
                  >
                    <Tooltip
                      title={translate(
                        'TasksCreateModalContributor.addTooltip',
                      )}
                    >
                      <Button
                        type="primary"
                        shape="circle"
                        icon={<PlusOutlined />}
                      />
                    </Tooltip>
                  </Popconfirm>,
                ]}
              >
                {item.collaborator.contact.name}
              </List.Item>
            )}
          />
        </>
      </Modal>
    </>
  )
}

export default ContributorCreateTask
