import React from 'react'
import { Helmet } from 'react-helmet'
import HelpDeskBoard from '~/components/HelpDesk'
import { ListTicketsProvider } from '~/hooks/HelpDesk/useListTickets'
import { TicketTypeProvider } from '~/hooks/HelpDesk/useTicketType'
import { TeamsProvider } from '~/hooks/Teams/useListTeams'

const HelpDesk: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>Tickets</title>
      </Helmet>
      <ListTicketsProvider>
        <TeamsProvider>
          <TicketTypeProvider>
            <HelpDeskBoard />
          </TicketTypeProvider>
        </TeamsProvider>
      </ListTicketsProvider>
    </>
  )
}

export default HelpDesk
