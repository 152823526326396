export default {
  pt: {
    StatusDetails: {
      dateString: 'às',
      pageHeaderTitle: 'Visualizar Status',
      cardTitle: 'Informações Gerais',
      createdText: 'Criado em',
      enabledText: 'Tipo: Habilitado',
      enabledWithRestrictionsText: 'Tipo: Habilitado com restrições',
      blockedText: 'Tipo: Bloqueado',
      customText: 'Tipo: Customizado',
      descriptionText: 'Descrição:',
      noneDescriptionText: 'Nenhuma descrição informada.',
      statusModalTitle: 'Colaboradores do status',
      contributorTooltip: 'Ver colaborador',
      contributorButton: 'Ver colaborador',
    },
  },
  en: {
    StatusDetails: {
      dateString: 'at',
      pageHeaderTitle: 'View Status',
      cardTitle: 'General information',
      createdText: 'Created in',
      enabledText: 'Type: Enabled',
      enabledWithRestrictionsText: 'Type: Enabled with Restrictions',
      blockedText: 'Type: Blocked',
      customText: 'Type: Custom',
      descriptionText: 'Descriptions:',
      noneDescriptionText: 'No description provided.',
      statusModalTitle: 'Status contributors',
      contributorTooltip: 'View contributor',
      buttonTooltip: 'View contributor',
    },
  },
  es: {
    StatusDetails: {
      dateString: 'a las',
      pageHeaderTitle: 'Ver Estado',
      cardTitle: 'Informaciones Generales',
      createdText: 'Creado en',
      enabledText: 'Tipo: Habilitado',
      enabledWithRestrictionsText: 'Tipo: Habilitado com restrições',
      blockedText: 'Tipo: Bloqueado',
      customText: 'Tipo: Personalizado',
      descriptionText: 'Descripción:',
      noneDescriptionText: 'No se proporcionó descripción.',
      statusModalTitle: 'Contribuyentes de estado',
      contributorTooltip: 'Ver colaborador',
      buttonTooltip: 'Ver colaborador',
    },
  },
}
