export default {
  pt: {
    useScale: {
      getErrorMessage: 'Erro ao visualizar escala.',
      createErrorMessage: 'Erro ao criar horário da escala.',
      updateErrorMessage: 'Erro ao atualizar horário da escala.',
    },
  },
  en: {
    useScale: {
      getErrorMessage: 'Error viewing scale.',
      createErrorMessage: 'Error creating scale schedule.',
      updateErrorMessage: 'Error updating scale time.',
    },
  },
  es: {
    useScale: {
      getErrorMessage: 'Error al ver la escala.',
      createErrorMessage: 'Error al crear el programa de escala.',
      updateErrorMessage: 'Error al actualizar el tiempo de escala.',
    },
  },
}
