/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-underscore-dangle */
import {
  DeleteFilled,
  FileUnknownOutlined,
  LoadingOutlined,
  ProfileOutlined,
  ShareAltOutlined,
} from '@ant-design/icons'
import {
  Popconfirm,
  Space,
  Table,
  Tag,
  Tooltip,
  Button,
  message,
  Typography,
} from 'antd'
import { ColumnsType } from 'antd/lib/table'
import Text from 'antd/lib/typography/Text'
import { format } from 'date-fns'
import moment from 'moment'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useTaskType } from '~/hooks/Tasks/TaskType/useTaskType'
import { useListTask } from '~/hooks/Tasks/useListTasks'
import { translate } from '~/utils/locale'

// import { Container } from './styles';

interface EquipmentsTasks {
  id: string
}

interface TaskListProps {
  checkin_type: string
  createdAt: Date
  date: string
  duration_time: string
  external_code: string
  is_enabled: string
  priority: string
  _id: string
  hours: string
  currentDate: string
  internal_code: string
  current_status: string
  customer_id: string
  task_type_id: string
  responsible: {
    id: string
    type: string
    data: {
      contact: {
        name: string
      }
      title: string
    }
  }
  customer: {
    id: string
    contact: {
      name: string
    }
    parent_customer: string
  }
  address: {
    city: string
    id: string
    location: {
      x: number
      y: number
    }
    neighborhood: string
    number: number
    state: string
    street: string
    zip_code: string
  }
  equipments: EquipmentsTasks[]
}

const TableTasks: React.FC = () => {
  const {
    tasks,
    isFetching,
    deleteTask,
    deleteTasks,
    setNumberPage,
    setPageSize,
    tasksTotal,
  } = useListTask()

  const { taskType } = useTaskType()

  const [isDelete, setIsDelete] = useState(false)
  const [tasksId, setTasksId] = useState<React.Key[]>([])

  const taskFormatted = tasks?.map((task: TaskListProps) => {
    const dataFormat = {
      ...task,
      initialTime: format(
        new Date(task.date),
        `dd/MM/yyyy '${translate('TasksOptionsTable.dateString')}' HH:mm'h'`,
      ),
    }

    return dataFormat
  })

  const columns: ColumnsType<TaskListProps> = [
    {
      title: translate('TasksOptionsTable.internalCodeColumn'),
      dataIndex: 'internal_code',
      key: 'internal_code',
      render: (name: string, record) => (
        <Link to={`/tarefas/visualizar/${record._id}`}>{name}</Link>
      ),
    },
    {
      title: translate('TasksOptionsTable.responsibleColumn'),
      key: 'responsible',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (record) => (
        <>
          {record.responsible?.type === 'collaborator' ? (
            <>
              {record?.responsible?.data?.contact?.name ? (
                <Link
                  to={`/colaboradores/visualizar/colaborador/${record?.responsible?.id}/contato/${record?.responsible?.data?.contact?.id}`}
                >
                  {record?.responsible?.data?.contact?.name}
                </Link>
              ) : (
                <Text type="secondary">
                  <Space size="small">
                    <FileUnknownOutlined />
                    N/A
                  </Space>
                </Text>
              )}
            </>
          ) : (
            <>
              {record?.responsible?.data?.title ? (
                <Text>{record?.responsible?.data?.title}</Text>
              ) : (
                <Text type="secondary">
                  <Space size="small">
                    <FileUnknownOutlined />
                    N/A
                  </Space>
                </Text>
              )}
            </>
          )}
        </>
      ),
    },
    {
      title: translate('TasksOptionsTable.clientColumn'),
      key: 'customer',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (record) => (
        <>
          {record?.customer?.contact?.name ? (
            <Text>{record?.customer?.contact.name}</Text>
          ) : (
            <Text type="secondary">
              <Space size="small">
                <FileUnknownOutlined />
                N/A
              </Space>
            </Text>
          )}
        </>
      ),
    },
    {
      title: translate('TasksOptionsTable.initialTimeColumn'),
      dataIndex: 'initialTime',
      key: 'date',
      sorter: (a: TaskListProps, b: TaskListProps) =>
        moment(a.createdAt).unix() - moment(b.createdAt).unix(),
    },
    {
      title: translate('TasksOptionsTable.taskTypeColumn'),
      key: 'taskType',
      render: (record) => (
        <>
          {taskType?.map((task) => (
            <div key={task._id}>
              {task._id === record.task_type_id ? (
                <Text>{task.title}</Text>
              ) : (
                ''
              )}
            </div>
          ))}
        </>
      ),
    },
    {
      title: translate('TasksOptionsTable.statusColumn'),
      key: 'status',
      render: (record) => (
        <>
          {record.current_status === 'received' ? (
            <Tag color="yellow">
              {translate('TasksOptionsTable.statusReceived')}
            </Tag>
          ) : (
            ''
          )}
          {record.current_status === 'viewed' ? (
            <Tag color="green">
              {translate('TasksOptionsTable.statusViewed')}
            </Tag>
          ) : (
            ''
          )}
          {record.current_status === 'en-route' ? (
            <Tag color="green">
              {translate('TasksOptionsTable.statusEnRoute')}
            </Tag>
          ) : (
            ''
          )}
          {record.current_status === 'working' ? (
            <Tag color="green">
              {translate('TasksOptionsTable.statusWorking')}
            </Tag>
          ) : (
            ''
          )}
          {record.current_status === 'break' ? (
            <Tag color="red">{translate('TasksOptionsTable.statusBreak')}</Tag>
          ) : (
            ''
          )}
          {record.current_status === 'resume-working' ? (
            <Tag color="green">
              {translate('TasksOptionsTable.statusResumeWorking')}
            </Tag>
          ) : (
            ''
          )}
          {record.current_status === 'canceled' ? (
            <Tag color="gray">
              {translate('TasksOptionsTable.statusCanceled')}
            </Tag>
          ) : (
            ''
          )}
          {record.current_status === 'finished' ? (
            <Tag color="geekblue">
              {translate('TasksOptionsTable.statusFinished')}
            </Tag>
          ) : (
            ''
          )}
        </>
      ),
    },

    {
      title: translate('TasksOptionsTable.actionsColumn'),
      key: 'remove',
      align: 'center',
      render: (record) => (
        <Space size="middle">
          <Tooltip title={translate('TasksOptionsTable.viewTooltip')}>
            <Link to={`/tarefas/visualizar/${record._id}`}>
              <Button
                ghost
                type="primary"
                shape="circle"
                icon={<ProfileOutlined />}
              />
            </Link>
          </Tooltip>
          <Popconfirm
            title={translate('TasksOptionsTable.deletePopconfirm')}
            onConfirm={() => deleteTask(record._id)}
          >
            <Tooltip title={translate('TasksOptionsTable.deleteTooltip')}>
              <Button ghost danger shape="circle" icon={<DeleteFilled />} />
            </Tooltip>
          </Popconfirm>

          <Tooltip title="Compartilhar tarefa">
            <Link to="/">
              <Button
                ghost
                type="primary"
                shape="circle"
                icon={<ShareAltOutlined />}
              />
            </Link>
          </Tooltip>
        </Space>
      ),
    },
  ]

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[]) => {
      setIsDelete(selectedRowKeys.length > 0)
      setTasksId(selectedRowKeys)
    },
    getCheckboxProps: (record: TaskListProps) => ({
      disabled: record.internal_code === 'Disabled User', // Column configuration not to be checked
      name: record.internal_code,
    }),
  }

  function DeleteTasks() {
    const tasks = tasksId.map((m) => {
      return deleteTasks(m.toString())
    })

    message.success('Tarefas deletadas com sucesso!')
    setIsDelete(false)
    return tasks
  }

  return (
    <>
      {isDelete && (
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <Popconfirm
            title="Deseja excluir as tarefas?"
            onConfirm={DeleteTasks}
          >
            <Tooltip title={translate('TasksOptionsTable.deleteTooltip')}>
              <Button
                ghost
                danger
                size="middle"
                shape="circle"
                icon={<DeleteFilled />}
                style={{ marginBottom: 10 }}
              />
            </Tooltip>
          </Popconfirm>

          {tasksId.length !== 1 ? (
            <Typography.Title level={5} type="danger">
              {tasksId.length} tarefas selecionadas
            </Typography.Title>
          ) : (
            <Typography.Title level={5} type="danger">
              1 tarefa selecionada
            </Typography.Title>
          )}
        </div>
      )}
      <Table
        rowKey={(record) => record._id}
        loading={{
          indicator: <LoadingOutlined />,
          spinning: isFetching,
          size: 'small',
        }}
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        pagination={{
          total: tasksTotal,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '50', '100', '200'],
          defaultPageSize: 20,
          showTotal: (total, range) => `${range[0]} - ${range[1]} / ${total}`,
          onChange: (page, pageSize) => {
            setPageSize(pageSize!)
            setNumberPage(page)
          },
        }}
        columns={columns}
        dataSource={taskFormatted}
        scroll={{ x: 1000 }}
      />
    </>
  )
}

export default TableTasks
