export default {
  pt: {
    EquipmentsCategoryBoard: {
      categoryTitle: 'Categorias',
      totalText: 'Total de',
      categoryText: 'categoria',
      categoriesText: 'categorias',
    },
  },
  en: {
    EquipmentsCategoryBoard: {
      categoryTitle: 'Categories',
      totalText: 'Total of',
      categoryText: 'category',
      categoriesText: 'categories',
    },
  },
  es: {
    EquipmentsCategoryBoard: {
      categoryTitle: 'Categorías',
      totalText: 'Total de',
      categoryText: 'categoría',
      categoriesText: 'categorías',
    },
  },
}
