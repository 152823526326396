export default {
  pt: {
    useTaskType: {
      getErrorMessage: 'Erro ao visualizar tipos de tarefa.',
      createSuccessMessage: 'Tipo de tarefa criada com sucesso!',
      createErrorMessage: 'Erro ao criar tipos de tarefa.',
      updateSuccessMessage: 'Tipo de tarefa atualizada com sucesso!',
      updateErrorMessage: 'Erro ao atualizar tipos de tarefa.',
      updateGenericError: 'Erro ao atualizar.',
      deleteSuccessMessage: 'Motivo de pausa deletado com sucesso!',
      deleteErrorMessage: 'Erro ao excluir tipos de tarefa.',
    },
  },
  en: {
    useTaskType: {
      getErrorMessage: 'Error viewing task types.',
      createSuccessMessage: 'Task type created successfully!',
      createErrorMessage: 'Error creating task types.',
      updateSuccessMessage: 'Task type updated successfully!',
      updateErrorMessage: 'Error updating task types.',
      updateGenericError: 'Error updating.',
      deleteSuccessMessage: 'Task type deleted successfully!',
      deleteErrorMessage: 'Error deleting task types.',
    },
  },
  es: {
    useTaskType: {
      getErrorMessage: 'Error al ver los tipos de tareas.',
      createSuccessMessage: '¡Tipo de tarea creado con éxito!',
      createErrorMessage: 'Error al crear tipos de tareas.',
      updateSuccessMessage: '¡Tipo de tarea actualizado con éxito!',
      updateErrorMessage: 'Error al actualizar los tipos de tareas.',
      updateGenericError: 'Error al actualizar.',
      deleteSuccessMessage: '¡Tipo de tarea eliminado con éxito!',
      deleteErrorMessage: 'Error al eliminar tipos de tareas.',
    },
  },
}
