export default {
  pt: {
    StatusContributor: {
      enabledText: 'Tipo: Habilitado',
      enabledWithRestrictionsText: 'Tipo: Habilitado com restrições',
      blockedText: 'Tipo: Bloqueado',
      customText: 'Tipo: Customizado',
      descriptionText: 'Descrição',
      noneDescriptionText: 'Nenhuma descrição informada',
      noneStatusText: 'Nenhum status informado',
    },
  },
  en: {
    StatusContributor: {
      enabledText: 'Type: Enabled',
      enabledWithRestrictionsText: 'Type: Enabled with Restrictions',
      blockedText: 'Type: Blocked',
      customText: 'Type: Custom',
      descriptionText: 'Description',
      noneDescriptionText: 'No description provided',
      noneStatusText: 'No status reported',
    },
  },
  es: {
    StatusContributor: {
      enabledText: 'Tipo: Habilitado',
      enabledWithRestrictionsText: 'Tipo: Habilitado con restricciones',
      blockedText: 'Tipo: Bloqueado',
      customText: 'Tipo: Personalizado',
      descriptionText: 'Descripción',
      noneDescriptionText: 'No se proporcionó descripción',
      noneStatusText: 'Ningún estado informado',
    },
  },
}
