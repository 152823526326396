export default {
  pt: {
    SkillSkills: {
      createSuccessMessage: 'Colaborador Cadastrado com sucesso!',
      createErrorMessage: 'Erro ao realizar cadastro',
      updateSuccessMessage: 'Habilidade Atualizada com sucesso!',
      updateErrorMessage: 'Erro ao realizar atualização',
      emptyDescription:
        'É necessário cadastrar habilidades antes de atribuí-las. Clique no botão abaixo para cadastrar',
      editTooltip: 'Editar',
      addButton: 'Atribuir Habilidade',
      updateDrawerTitle: 'Atualizar Habilidade do Colaborador',
      addDrawerTitle: 'Atribuir habilidade ao Colaborador',
      skillLabel: 'Habilidade',
      skillRule: 'Por favor selecione uma habilidade',
      levelLabel: 'Nível',
      levelRule: 'Por favor selecione um nível',
      levelBasic: 'Básico',
      levelIntermediary: 'Intermediário',
      levelAdvanced: 'Avançado',
      descriptionLabel:
        'Descreva com mais detalhes essa habilidade do Colaborador',
      descriptionRule: 'Por favor insira uma descrição',
      saveButtonText: 'Salvar',
      addButtonText: 'Atribuir',
    },
  },
  en: {
    SkillSkills: {
      createSuccessMessage: 'Contributor Registered Successfully!',
      createErrorMessage: 'Error when registering',
      updateSuccessMessage: 'Skill Updated Successfully!',
      updateErrorMessage: 'Error performing update',
      emptyDescription:
        'You must register skills before assigning them. Click the button below to register',
      editTooltip: 'Edit',
      addButton: 'Assign Skill',
      updateDrawerTitle: 'Update Contributor Skill',
      addDrawerTitle: 'Assign skill to Contributor',
      skillLabel: 'Skill',
      skillRule: 'Please select a skill',
      levelLabel: 'Level',
      levelRule: 'Please select a level',
      levelBasic: 'Basic',
      levelIntermediary: 'Intermediary',
      levelAdvanced: 'Advanced',
      descriptionLabel: 'Please describe this Contributor skill in more detail',
      descriptionRule: 'Please enter a description',
      saveButtonText: 'Save',
      addButtonText: 'Assign',
    },
  },
  es: {
    SkillSkills: {
      createSuccessMessage: '¡Colaborador Registrado Exitosamente!',
      createErrorMessage: 'Error al registrarse',
      updateSuccessMessage: '¡Habilidad actualizada con éxito!',
      updateErrorMessage: 'Error al realizar la actualización',
      emptyDescription:
        'Debes registrar las habilidades antes de asignarlas. Haga clic en el botón de abajo para registrarse',
      editTooltip: 'Editar',
      addButton: 'Asignar habilidad',
      updateDrawerTitle: 'Actualizar habilidad de colaborador',
      addDrawerTitle: 'Asignar habilidad a Colaborador',
      skillLabel: 'Habilidad',
      skillRule: 'Por favor seleccione una habilidad',
      levelLabel: 'Nivel',
      levelRule: 'Por favor seleccione un nivel',
      levelBasic: 'Básico',
      levelIntermediary: 'Intermediario',
      levelAdvanced: 'Avanzado',
      descriptionLabel:
        'Describa esta habilidad de Colaborador con más detalle.',
      descriptionRule: 'Por favor ingrese una descripción',
      saveButtonText: 'Guardar',
      addButtonText: 'Asignar',
    },
  },
}
