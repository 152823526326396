/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { useContext, createContext, useRef, useState } from 'react'

import { TourProps } from 'antd'
import { useHistory } from 'react-router-dom'

interface TaskTourContextData {
  createTaskRef: any
  taskVisualizationRef: any
  createClientTaskRef: any
  createDateTaskRef: any
  createTaskTypeTaskRef: any
  createDurationTaskRef: any
  createPriorityTaskRef: any

  taskAddressVisualization: any
  taskAddressOptions: any
  createCEPTaskRef: any
  createNumberTaskRef: any
  validateAddressTaskRef: any
  saveClientRef: any

  taskSteps: any
  createAddressSteps: any
  createResponsibleSteps: any
  createTaskSteps: any
  listClientSteps: any

  openCreateTask?: boolean
  setOpenCreateTask(value: any): void
  openListClient?: boolean
  setOpenListClient(value: any): void
  openAddressTask?: boolean
  setOpenAddressTask(value: any): void
  openResponsibleTask?: boolean
  setOpenResponsibleTask(value: any): void

  taskResponsibleVisualization: any
  createResponsibleTaskRef: any
  createCollaboratorTaskRef: any
  saveTaskRef: any

  openTask?: boolean
  setOpenTask(value: any): void
}

const TaskTourContext = createContext<TaskTourContextData>(
  {} as TaskTourContextData,
)

export const TaskTourProvider: React.FC = ({ children }) => {
  const createTaskRef = useRef<HTMLButtonElement>(null)
  const taskVisualizationRef = useRef<HTMLButtonElement>(null)
  const createClientTaskRef = useRef<HTMLButtonElement>(null)
  const createDateTaskRef = useRef<HTMLButtonElement>(null)
  const createTaskTypeTaskRef = useRef<HTMLButtonElement>(null)
  const createDurationTaskRef = useRef<HTMLButtonElement>(null)
  const createPriorityTaskRef = useRef<HTMLButtonElement>(null)

  const taskAddressVisualization = useRef<HTMLButtonElement>(null)
  const taskAddressOptions = useRef<HTMLButtonElement>(null)
  const createCEPTaskRef = useRef<HTMLButtonElement>(null)
  const createNumberTaskRef = useRef<HTMLButtonElement>(null)
  const validateAddressTaskRef = useRef<HTMLButtonElement>(null)

  const taskResponsibleVisualization = useRef<HTMLButtonElement>(null)
  const createResponsibleTaskRef = useRef<HTMLButtonElement>(null)
  const createCollaboratorTaskRef = useRef<HTMLButtonElement>(null)
  const saveTaskRef = useRef<HTMLButtonElement>(null)

  const saveClientRef = useRef<HTMLButtonElement>(null)

  const clientRef = useRef<HTMLButtonElement>(null)

  const history = useHistory()

  const [openCreateTask, setOpenCreateTask] = useState(false)
  const [openListClient, setOpenListClient] = useState(false)
  const [openTask, setOpenTask] = useState(false)

  const [openAddressTask, setOpenAddressTask] = useState(false)
  const [openResponsibleTask, setOpenResponsibleTask] = useState(false)

  function OpenCreateTask() {
    setOpenCreateTask(true)

    history.push('novaTarefa')
  }

  function OpenAddressTask() {
    setOpenAddressTask(true)
  }

  function OpenResponsibleTask() {
    setOpenResponsibleTask(true)
    setOpenAddressTask(false)
  }

  function OpenTask() {
    setOpenTask(true)

    history.push('tarefas')
  }

  const taskSteps: TourProps['steps'] = [
    {
      title: (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <h2 style={{ marginBottom: 0 }}>Criar Tarefa</h2>{' '}
          <span style={{ marginLeft: 8, color: 'gray' }}>Tarefas</span>
        </div>
      ),
      description: 'Clicando nesse botão você consegue criar uma tarefa.',
      target: () => createTaskRef.current!,
      nextButtonProps: { onClick: OpenCreateTask },
    },
  ]

  const createTaskSteps: TourProps['steps'] = [
    {
      title: (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <h2 style={{ marginBottom: 0 }}>Tarefas</h2>{' '}
          <span style={{ marginLeft: 8, color: 'gray' }}>Detalhes</span>
        </div>
      ),
      description:
        'Começaremos adicionando informações do cliente e detalhes técnicos da tarefa.',
      target: () => taskVisualizationRef.current!,
    },
    {
      title: (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <h2 style={{ marginBottom: 0 }}>Tarefas</h2>{' '}
          <span style={{ marginLeft: 8, color: 'white' }}>Detalhes</span>
        </div>
      ),
      description: 'Selecione o cliente',
      target: () => createClientTaskRef.current!,
      mask: false,
      type: 'primary',
    },
    {
      title: (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <h2 style={{ marginBottom: 0 }}>Tarefas</h2>{' '}
          <span style={{ marginLeft: 8, color: 'gray' }}>Detalhes</span>
        </div>
      ),
      description:
        'A data e a hora que será realizada a tarefa. Por padrão, a data e hora já estão selecionadas para o dia atual com duas horas adiantadas.',
      target: () => createDateTaskRef.current!,
    },
    {
      title: (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <h2 style={{ marginBottom: 0 }}>Tarefas</h2>{' '}
          <span style={{ marginLeft: 8, color: 'white' }}>Detalhes</span>
        </div>
      ),
      description: 'Selecione o tipo de tarefa.',
      target: () => createTaskTypeTaskRef.current!,
      mask: false,
      type: 'primary',
    },
    {
      title: (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <h2 style={{ marginBottom: 0 }}>Tarefas</h2>{' '}
          <span style={{ marginLeft: 8, color: 'gray' }}>Detalhes</span>
        </div>
      ),
      description: 'E informe a prioridade.',
      target: () => createPriorityTaskRef.current!,
    },
    {
      title: (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <h2 style={{ marginBottom: 0 }}>Tarefas</h2>{' '}
          <span style={{ marginLeft: 8, color: 'white' }}>Endereço</span>
        </div>
      ),
      description: 'Agora vamos adicionar os dados de endereço.',
      target: () => taskAddressVisualization.current!,
      nextButtonProps: { onClick: OpenAddressTask },
    },
  ]

  const createAddressSteps: TourProps['steps'] = [
    {
      title: (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <h2 style={{ marginBottom: 0 }}>Tarefas</h2>{' '}
          <span style={{ marginLeft: 8, color: 'gray' }}>Endereço</span>
        </div>
      ),
      description:
        'Você consegue adicionar um endereço manualmente ou selecionando um endereço cadastrado no cliente.',
      target: () => taskAddressOptions.current!,
    },
    {
      title: (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <h2 style={{ marginBottom: 0 }}>Tarefas</h2>{' '}
          <span style={{ marginLeft: 8, color: 'gray' }}>Endereço</span>
        </div>
      ),
      description: 'Adicione o CEP.',
      target: () => createCEPTaskRef.current!,
    },
    {
      title: (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <h2 style={{ marginBottom: 0 }}>Tarefas</h2>{' '}
          <span style={{ marginLeft: 8, color: 'gray' }}>Endereço</span>
        </div>
      ),
      description: 'Informe o número.',
      target: () => createNumberTaskRef.current!,
    },
    {
      title: (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <h2 style={{ marginBottom: 0 }}>Tarefas</h2>{' '}
          <span style={{ marginLeft: 8, color: 'gray' }}>Endereço</span>
        </div>
      ),
      description: 'E por fim, valide o endereço no mapa.',
      target: () => validateAddressTaskRef.current!,
    },
    {
      title: (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <h2 style={{ marginBottom: 0 }}>Tarefas</h2>{' '}
          <span style={{ marginLeft: 8, color: 'white' }}>Responsável</span>
        </div>
      ),
      description:
        'E o último passo para criar o serviço, é informar o responsável.',
      target: () => taskResponsibleVisualization.current!,
      nextButtonProps: { onClick: OpenResponsibleTask },
    },
  ]

  const createResponsibleSteps: TourProps['steps'] = [
    {
      title: (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <h2 style={{ marginBottom: 0 }}>Tarefas</h2>{' '}
          <span style={{ marginLeft: 8, color: 'white' }}>Responsável</span>
        </div>
      ),
      description:
        'Selecione se você quer adicionar o responsável através de escalas ou colaboradores, no nosso caso, adicionaremos através de colaboradores.',
      target: () => createResponsibleTaskRef.current!,
      placement: 'bottom',
      mask: false,
    },
    {
      title: (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <h2 style={{ marginBottom: 0 }}>Tarefas</h2>{' '}
          <span style={{ marginLeft: 8, color: 'white' }}>Responsável</span>
        </div>
      ),
      description: 'Selecione o colaborador.',
      target: () => createCollaboratorTaskRef.current!,
      mask: false,
      placement: 'leftBottom',
    },
    {
      title: (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <h2 style={{ marginBottom: 0 }}>Tarefas</h2>{' '}
          <span style={{ marginLeft: 8, color: 'white' }}>Responsável</span>
        </div>
      ),
      description: 'Agora vamos salvar a tarefa.',
      target: () => saveTaskRef.current!,
    },
  ]

  const listClientSteps: TourProps['steps'] = [
    {
      title: (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <h2 style={{ marginBottom: 0 }}>Tarefas</h2>{' '}
          <span style={{ marginLeft: 8, color: 'gray' }}>Tarefas</span>
        </div>
      ),
      description:
        'Agora que já temos o tipo de tarefa cadastrado. Vamos cadastrar as tarefas.',
      target: () => clientRef.current!,
      nextButtonProps: { onClick: OpenTask },
    },
  ]

  return (
    <TaskTourContext.Provider
      value={{
        createTaskRef,
        taskVisualizationRef,
        createClientTaskRef,
        createDateTaskRef,
        createDurationTaskRef,
        createPriorityTaskRef,
        createTaskTypeTaskRef,
        taskAddressVisualization,
        taskAddressOptions,
        createCEPTaskRef,
        createNumberTaskRef,
        validateAddressTaskRef,
        saveClientRef,
        taskSteps,
        createTaskSteps,
        openCreateTask,
        setOpenCreateTask,
        openListClient,
        setOpenListClient,
        listClientSteps,
        openTask,
        setOpenTask,
        openAddressTask,
        createAddressSteps,
        taskResponsibleVisualization,
        createCollaboratorTaskRef,
        createResponsibleTaskRef,
        saveTaskRef,
        createResponsibleSteps,
        openResponsibleTask,
        setOpenResponsibleTask,
        setOpenAddressTask,
      }}
    >
      {children}
    </TaskTourContext.Provider>
  )
}

export function useTaskTour(): TaskTourContextData {
  const context = useContext(TaskTourContext)

  if (!context) {
    throw new Error('useAuth must be used within an AutoProvider')
  }

  return context
}
