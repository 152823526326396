import styled from 'styled-components'

export const Container = styled.div`
  h1,
  h4 {
    margin-bottom: 0px;
  }

  margin-bottom: 20px;
`

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .total {
    border-left: 4px solid var(--quartenary-color);

    &:hover {
      background: var(--quartenary-color);
      h1,
      p {
        color: #fff;
      }

      svg {
        color: var(--quartenary-color);
      }
    }
  }

  .execution {
    border-right: 4px solid var(--quartenary-color);

    &:hover {
      background: var(--quartenary-color);
      h1,
      p {
        color: #fff;
      }

      svg {
        color: var(--quartenary-color);
      }
    }
  }
`

export const Card = styled.div`
  border: 1px solid #dcdcdc;
  width: 100%;
  max-width: 250px;
  border-radius: 4px;
  padding: 20px 20px 10px 20px;

  transition: 0.3s ease-in;

  p {
    font-size: 12px;
    margin-top: 15px;
    color: var(--secondary-initial-text);
  }

  h1 {
    font-weight: bold;
    color: var(--primary-initial-text);
  }

  #total {
    color: var(--quartenary-color);
  }

  #execution {
    color: var(--primary-color);
  }

  #finished {
    color: var(--secondary-color);
  }

  #canceled {
    color: #0cc2b3;
  }
`
