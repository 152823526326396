import React from 'react'
import { Helmet } from 'react-helmet'
import TeamsBoard from '~/components/DataManipulation/Teams'

import { ListContributorsProvider } from '~/hooks/Contributors/useListContributors'
import { TeamsProvider } from '~/hooks/Teams/useListTeams'
import { translate } from '~/utils/locale'

// import { Container } from './styles';

const Teams: React.FC = () => {
  return (
    <TeamsProvider>
      <Helmet>
        <title>{translate('Teams.helmetTitle')}</title>
      </Helmet>

      <ListContributorsProvider>
        <TeamsBoard />
      </ListContributorsProvider>
    </TeamsProvider>
  )
}

export default Teams
