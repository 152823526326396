import React from 'react'

import { Result } from 'antd'

import { Container } from './styles'
import { translate } from '~/utils/locale'

const UserAccess: React.FC = () => {
  return (
    <Container>
      <Result
        title={translate('UserAccess.resultTitle')}
        subTitle={translate('UserAccess.resultSubTitle')}
      />
    </Container>
  )
}

export default UserAccess
