import React from 'react'
import { Helmet } from 'react-helmet'
import PauseBoard from '~/components/DataManipulation/TasksRegistration/Pause'

import { PauseProvider } from '~/hooks/Tasks/Pause/usePause'
import { translate } from '~/utils/locale'

import { Container } from './styles'

const Pause: React.FC = () => {
  return (
    <Container>
      <Helmet>
        <title>{translate('Pause.helmetTitle')}</title>
      </Helmet>
      <PauseProvider>
        <PauseBoard />
      </PauseProvider>
    </Container>
  )
}

export default Pause
