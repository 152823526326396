export default {
  pt: {
    ClientsContactsTabUpdate: {
      editTooltip: 'Editar',
      modalTitle: 'Editar Contato',
      closeButton: 'Fechar',
      saveButton: 'Salvar',
      nameLabel: 'Nome',
      nameRule: 'Por favor informe o nome do cliente.',
      namePlaceholder: 'Insira o nome do cliente',
      emailLabel: 'E-mail',
      emailRule: 'Por favor informe o e-mail do cliente.',
      emailPlaceholder: 'Insira o e-mail do cliente',
      phoneLabel: 'Telefone',
      phoneRule: 'Insira um número válido.',
      phonePlaceholder: 'Telefone do Cliente',
      officeLabel: 'Cargo',
      officeRule: 'Por favor informe o cargo do cliente.',
      officePlaceholder: 'Insira o cargo do cliente',
      responsibleLabel: 'Responsável',
      responsibleCheckbox: 'Tornar o contato como responsável pelo cliente?',
      descriptionLabel: 'Descrição',
      descriptionPlaceholder: 'Insira a descrição',
    },
  },
  en: {
    ClientsContactsTabUpdate: {
      editTooltip: 'Edit',
      modalTitle: 'Edit Contact',
      closeButton: 'Close',
      saveButton: 'Save',
      nameLabel: 'Name',
      nameRule: "Please enter the customer's name.",
      namePlaceholder: 'Enter customer name',
      emailLabel: 'Email',
      emailRule: "Please provide the customer's email address.",
      emailPlaceholder: 'Enter customer email',
      phoneLabel: 'Phone',
      phoneRule: 'Please enter a valid number.',
      phonePlaceholder: 'Customer phone',
      officeLabel: 'Office',
      officeRule: "Please inform the customer's office.",
      officePlaceholder: "Enter the customer's office",
      responsibleLabel: 'Responsible',
      responsibleCheckbox: 'Make the contact responsible for the customer?',
      descriptionLabel: 'Description',
      descriptionPlaceholder: 'Enter description',
    },
  },
  es: {
    ClientsContactsTabUpdate: {
      editTooltip: 'Para editar',
      modalTitle: 'Editar contacto',
      closeButton: 'Cerrar',
      saveButton: 'Guardar',
      nameLabel: 'Nombre',
      nameRule: 'Por favor ingrese el nombre del cliente.',
      namePlaceholder: 'Ingrese el nombre del cliente',
      emailLabel: 'Correo electrónico',
      emailRule: 'Proporcione la dirección de correo electrónico del cliente.',
      emailPlaceholder: 'Ingrese el correo electrónico del cliente',
      phoneLabel: 'Teléfono',
      phoneRule: 'Por favor ingrese un número valido.',
      phonePlaceholder: 'Teléfono del cliente',
      officeLabel: 'Cargo',
      officeRule: 'Por favor, informe el cargo del cliente.',
      officePlaceholder: 'Ingrese el cargo del cliente',
      responsibleLabel: 'Responsable',
      responsibleCheckbox:
        '¿Hacer que el contacto sea responsable del cliente?',
      descriptionLabel: 'Descripción',
      descriptionPlaceholder: 'Ingrese la descripción',
    },
  },
}
