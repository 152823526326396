import React from 'react'
import { Helmet } from 'react-helmet'

import TypeTasksBoard from '~/components/DataManipulation/TasksRegistration/TypeTasks'

import { TaskTypeProvider } from '~/hooks/Tasks/TaskType/useTaskType'
import { translate } from '~/utils/locale'

import { Container } from './styles'

const TaskTypes: React.FC = () => {
  return (
    <Container>
      <Helmet>
        <title>{translate('TaskType.helmetTitle')}</title>
      </Helmet>

      <TaskTypeProvider>
        <TypeTasksBoard />
      </TaskTypeProvider>
    </Container>
  )
}

export default TaskTypes
