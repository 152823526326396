/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { useContext, createContext, useRef, useState } from 'react'

import { TourProps } from 'antd'
import { useHistory } from 'react-router-dom'

interface CollaboratorTourContextData {
  typeTaskRef: any
  createCollaboratorRef: any
  createNameCollaboratorRef: any
  createEmailCollaboratorRef: any
  createPhoneCollaboratorRef: any
  createGroupCollaboratorRef: any
  saveCollaborator: any

  collaboratorSteps: any
  createCollaborator: any
  listCollaboratorSteps: any

  openCreateCollaborator?: boolean
  setOpenCreateCollaborator(value: any): void
  openListCollaborator?: boolean
  setOpenListCollaborator(value: any): void

  openClient?: boolean
  setOpenClient(value: any): void
}

const CollaboratorTourContext = createContext<CollaboratorTourContextData>(
  {} as CollaboratorTourContextData,
)

export const CollaboratorTourProvider: React.FC = ({ children }) => {
  const typeTaskRef = useRef<HTMLButtonElement>(null)
  const createCollaboratorRef = useRef<HTMLButtonElement>(null)
  const createNameCollaboratorRef = useRef<HTMLButtonElement>(null)
  const createEmailCollaboratorRef = useRef<HTMLButtonElement>(null)
  const createPhoneCollaboratorRef = useRef<HTMLButtonElement>(null)
  const createGroupCollaboratorRef = useRef<HTMLButtonElement>(null)

  const collaboratorRef = useRef<HTMLButtonElement>(null)
  const saveCollaborator = useRef<HTMLButtonElement>(null)

  const history = useHistory()

  const [openCreateCollaborator, setOpenCreateCollaborator] = useState(false)
  const [openListCollaborator, setOpenListCollaborator] = useState(false)
  const [openClient, setOpenClient] = useState(false)

  function OpenCreateCollaborator() {
    setOpenCreateCollaborator(true)

    history.push('colaboradores/novo')
  }

  function OpenClient() {
    setOpenClient(true)

    history.push('clientes')
  }

  const collaboratorSteps: TourProps['steps'] = [
    {
      title: (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <h2 style={{ marginBottom: 0 }}>Criando Colaboradores</h2>{' '}
          <span style={{ marginLeft: 8, color: 'gray' }}>Tarefas</span>
        </div>
      ),
      description: 'Clicando nesse botão você consegue criar um colaborador',
      target: () => createCollaboratorRef.current!,
      nextButtonProps: { onClick: OpenCreateCollaborator },
    },
  ]

  const createCollaborator: TourProps['steps'] = [
    {
      title: (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <h2 style={{ marginBottom: 0 }}>Criando Colaboradores</h2>{' '}
          <span style={{ marginLeft: 8, color: 'gray' }}>Tarefas</span>
        </div>
      ),
      description: 'Insira o nome do colaborador.',
      target: () => createNameCollaboratorRef.current!,
    },
    {
      title: (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <h2 style={{ marginBottom: 0 }}>Criando Colaboradores</h2>{' '}
          <span style={{ marginLeft: 8, color: 'gray' }}>Tarefas</span>
        </div>
      ),
      description: 'Agora, informe o e-mail.',
      target: () => createEmailCollaboratorRef.current!,
    },
    {
      title: (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <h2 style={{ marginBottom: 0 }}>Criando Colaboradores</h2>{' '}
          <span style={{ marginLeft: 8, color: 'gray' }}>Tarefas</span>
        </div>
      ),
      description: 'Informe o telefone do colaborador.',
      target: () => createPhoneCollaboratorRef.current!,
    },
    {
      title: (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <h2 style={{ marginBottom: 0 }}>Criando Colaboradores</h2>{' '}
          <span style={{ marginLeft: 8, color: 'gray' }}>Tarefas</span>
        </div>
      ),
      description:
        'E por fim, informe qual grupo de permissão ele será pertencente.',
      target: () => createGroupCollaboratorRef.current!,
    },
    {
      title: (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <h2 style={{ marginBottom: 0 }}>Criando Colaboradores</h2>{' '}
          <span style={{ marginLeft: 8, color: 'gray' }}>Tarefas</span>
        </div>
      ),
      description: 'Agora vamos salvar o colaborador.',
      target: () => saveCollaborator.current!,
    },
  ]

  const listCollaboratorSteps: TourProps['steps'] = [
    {
      title: (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <h2 style={{ marginBottom: 0 }}>Clientes</h2>{' '}
          <span style={{ marginLeft: 8, color: 'gray' }}>Tarefas</span>
        </div>
      ),
      description:
        'Agora que já temos os colaboradores cadastrados. Vamos cadastrar os clientes.',
      target: () => collaboratorRef.current!,
      nextButtonProps: { onClick: OpenClient },
    },
  ]

  return (
    <CollaboratorTourContext.Provider
      value={{
        typeTaskRef,
        createCollaboratorRef,
        createNameCollaboratorRef,
        createEmailCollaboratorRef,
        createPhoneCollaboratorRef,
        createGroupCollaboratorRef,
        saveCollaborator,
        collaboratorSteps,
        createCollaborator,
        openCreateCollaborator,
        setOpenCreateCollaborator,
        openListCollaborator,
        setOpenListCollaborator,
        listCollaboratorSteps,
        openClient,
        setOpenClient,
      }}
    >
      {children}
    </CollaboratorTourContext.Provider>
  )
}

export function useCollaboratorTour(): CollaboratorTourContextData {
  const context = useContext(CollaboratorTourContext)

  if (!context) {
    throw new Error('useAuth must be used within an AutoProvider')
  }

  return context
}
