/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react/require-default-props */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-useless-escape */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react/jsx-indent */
/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react'

import {
  Card,
  Col,
  Row,
  Form,
  Avatar,
  Space,
  Typography,
  Checkbox,
  Button,
} from 'antd'
import {
  UserOutlined,
  FileUnknownOutlined,
  WhatsAppOutlined,
  EditOutlined,
} from '@ant-design/icons'

import { Wrapper, ContAvatar, ContForm, Container } from './styles'

import { useContributor } from '~/hooks/Contributors/useContributor'
import { translate } from '~/utils/locale'

import { useCompanies } from '~/hooks/Companies/useCompanies'

const ContributorDetails: React.FC = () => {
  const { contributorContact, setIsEdit, data } = useContributor()
  const { selectedCompany } = useCompanies()

  const [shortUrl, setShortUrl] = useState('')

  const [form] = Form.useForm()
  const { Text, Link: AntdLink } = Typography

  const [documentType, setDocumentType] = useState('')

  useEffect(() => {
    form.setFieldsValue(contributorContact)
    if (contributorContact.person_type) {
      setDocumentType(contributorContact.person_type)
    }
  }, [form, contributorContact])

  const linkBadge = `https://badge.gstor.com.br/company/${selectedCompany}/contributor/${data?.id}/cracha`

  const handleShortenUrl = async () => {
    const accessToken = process.env.REACT_APP_BITLY_ACCESS_TOKEN
    const response = await fetch('https://api-ssl.bitly.com/v4/shorten', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ long_url: linkBadge }),
    })
    const data = await response.json()
    setShortUrl(data.link)
  }

  return (
    <Container>
      <Form form={form} layout="vertical" initialValues={contributorContact}>
        <Card
          bordered={false}
          title={translate('ContributorDetails.cardTitle')}
          extra={
            <Space>
              <Button
                type="primary"
                icon={<EditOutlined />}
                onClick={() => setIsEdit(true)}
                id="btn-edit-contributor"
              >
                {translate('ContributorDetails.editButton')}
              </Button>
            </Space>
          }
          style={{
            display: 'inline-block',
            width: 'calc(100% - 20px)',
          }}
        >
          <Wrapper>
            <ContAvatar>
              <Avatar
                shape="square"
                size={175}
                src={contributorContact?.avatar_url}
                icon={<UserOutlined />}
                style={{
                  background: '#EAEAEA',
                }}
              />
            </ContAvatar>
            <ContForm>
              <Row gutter={12}>
                <Col span={12}>
                  <Form.Item
                    label={<b>{translate('ContributorDetails.nameLabel')}</b>}
                    name="name"
                  >
                    {contributorContact.name ? (
                      <Text>{contributorContact.name}</Text>
                    ) : (
                      <Text type="secondary">
                        <Space size="small">
                          <FileUnknownOutlined />
                          N/A
                        </Space>
                      </Text>
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={<b>{translate('ContributorDetails.emailLabel')}</b>}
                    name="email"
                  >
                    {contributorContact.email ? (
                      <Text>{contributorContact.email}</Text>
                    ) : (
                      <Text type="secondary">
                        <Space size="small">
                          <FileUnknownOutlined />
                          N/A
                        </Space>
                      </Text>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col span={12}>
                  <Form.Item
                    label={<b>{translate('ContributorDetails.genderLabel')}</b>}
                    name="gender"
                  >
                    {contributorContact.gender ? (
                      <Text>
                        {contributorContact.gender === 'male'
                          ? translate('ContributorDetails.genderMale')
                          : contributorContact.gender === 'female'
                            ? translate('ContributorDetails.genderFemale')
                            : contributorContact.gender === 'other'
                              ? translate('ContributorDetails.genderOther')
                              : ''}
                      </Text>
                    ) : (
                      <Text type="secondary">
                        <Space size="small">
                          <FileUnknownOutlined />
                          N/A
                        </Space>
                      </Text>
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={<b>{translate('ContributorDetails.birthLabel')}</b>}
                    name="birth"
                  >
                    {contributorContact.formattedBirth ? (
                      <Text>{contributorContact.formattedBirth}</Text>
                    ) : (
                      <Text type="secondary">
                        <Space size="small">
                          <FileUnknownOutlined />
                          N/A
                        </Space>
                      </Text>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col span={12}>
                  <Form.Item
                    label={
                      <b>
                        <WhatsAppOutlined /> Whatsapp
                      </b>
                    }
                    name="phone"
                  >
                    {contributorContact.formattedPhone ? (
                      <AntdLink
                        href={`https://wa.me/${contributorContact.phone}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {contributorContact.formattedPhone}
                      </AntdLink>
                    ) : (
                      <Text type="secondary">
                        <Space size="small">
                          <FileUnknownOutlined />
                          N/A
                        </Space>
                      </Text>
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={
                      <b>{translate('ContributorDetails.personTypeLabel')}</b>
                    }
                    name="person_type"
                  >
                    {contributorContact.person_type ? (
                      <Text>
                        {contributorContact.person_type === 'legalPerson'
                          ? translate('ContributorDetails.personTypeLegal')
                          : contributorContact.person_type === 'physicalPerson'
                            ? translate('ContributorDetails.personTypePhysical')
                            : contributorContact.person_type === 'foreign'
                              ? translate(
                                  'ContributorDetails.personTypeForeign',
                                )
                              : ''}
                      </Text>
                    ) : (
                      <Text type="secondary">
                        <Space size="small">
                          <FileUnknownOutlined />
                          N/A
                        </Space>
                      </Text>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={12}>
                {documentType !== '' && (
                  <>
                    <Col span={12}>
                      <Form.Item
                        label={
                          documentType === 'legalPerson' ||
                          documentType === 'physicalPerson' ? (
                            <b>{translate('ContributorDetails.cpfLabel')}</b>
                          ) : (
                            <b>
                              {translate('ContributorDetails.passportLabel')}
                            </b>
                          )
                        }
                        name="document"
                      >
                        {!contributorContact.formattedDocument ? (
                          <Text type="secondary">
                            <Space size="small">
                              <FileUnknownOutlined />
                              N/A
                            </Space>
                          </Text>
                        ) : documentType === 'legalPerson' ||
                          documentType === 'physicalPerson' ? (
                          <AntdLink
                            href="https://servicos.receita.fazenda.gov.br/servicos/cpf/consultasituacao/consultapublica.asp"
                            target="_blank"
                            rel="noreferrer"
                          >
                            {contributorContact.formattedDocument}
                          </AntdLink>
                        ) : (
                          <Text>{contributorContact.formattedDocument}</Text>
                        )}
                      </Form.Item>
                    </Col>

                    {documentType !== 'physicalPerson' && (
                      <Col span={12}>
                        <Form.Item
                          label={
                            <b>{translate('ContributorDetails.cnpjLabel')}</b>
                          }
                          name="cnpj"
                        >
                          {contributorContact.formattedCnpj ? (
                            <AntdLink
                              href={`https://servicos.receita.fazenda.gov.br/Servicos/cnpjreva/Cnpjreva_Solicitacao.asp?cnpj=${contributorContact.cnpj}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {contributorContact.formattedCnpj}
                            </AntdLink>
                          ) : (
                            <Text type="secondary">
                              <Space size="small">
                                <FileUnknownOutlined />
                                N/A
                              </Space>
                            </Text>
                          )}
                        </Form.Item>
                      </Col>
                    )}

                    {documentType === 'physicalPerson' && (
                      <Col span={12}>
                        <Form.Item
                          label={
                            <b>{translate('ContributorDetails.idLabel')}</b>
                          }
                          name="rg"
                        >
                          {contributorContact.rg ? (
                            <Text>{contributorContact.rg}</Text>
                          ) : (
                            <Text type="secondary">
                              <Space size="small">
                                <FileUnknownOutlined />
                                N/A
                              </Space>
                            </Text>
                          )}
                        </Form.Item>
                      </Col>
                    )}
                  </>
                )}
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    label={
                      <b>{translate('ContributorDetails.observationLabel')}</b>
                    }
                    name="notes"
                  >
                    {contributorContact?.notes ? (
                      <Text>{contributorContact?.notes}</Text>
                    ) : (
                      <Text type="secondary">
                        <Space size="small">
                          <FileUnknownOutlined />
                          N/A
                        </Space>
                      </Text>
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </ContForm>
          </Wrapper>

          <Form.Item
            label={
              <b>{translate('ContributorDetails.additionalPhonesLabel')}</b>
            }
            labelCol={{ span: 24 }}
            style={{ width: '100%' }}
          >
            <Form.List name="additional_phones">
              {(fields) => (
                <>
                  {contributorContact.additional_phones &&
                    fields.map(({ key, name, ...restField }) => (
                      <Space
                        key={key}
                        style={{ display: 'flex', marginBottom: 8 }}
                        align="baseline"
                      >
                        <Form.Item
                          {...restField}
                          label={
                            <b>{translate('ContributorDetails.phoneLabel')}</b>
                          }
                          name={[name, 'phone']}
                          labelCol={{ span: 24 }}
                        >
                          {contributorContact.additional_phones[key].phone ? (
                            <Text>
                              {
                                contributorContact.additional_phones[key]
                                  .formattedPhone
                              }
                            </Text>
                          ) : (
                            <Text type="secondary">
                              <Space size="small">
                                <FileUnknownOutlined />
                                N/A
                              </Space>
                            </Text>
                          )}
                        </Form.Item>

                        <Form.Item
                          {...restField}
                          name={[name, 'is_whatsapp']}
                          style={{ display: 'inline-block' }}
                          valuePropName="checked"
                        >
                          <Checkbox disabled>
                            <WhatsAppOutlined />
                          </Checkbox>
                        </Form.Item>

                        <Form.Item
                          {...restField}
                          label={
                            <b>
                              {translate('ContributorDetails.phoneTypeLabel')}
                            </b>
                          }
                          name={[name, 'type']}
                          labelCol={{ span: 24 }}
                        >
                          {contributorContact.additional_phones[key].type ? (
                            <Text>
                              {contributorContact.additional_phones[key]
                                .type === 'home'
                                ? translate(
                                    'ContributorDetails.phoneTypeResidential',
                                  )
                                : contributorContact.additional_phones[key]
                                      .type === 'cell'
                                  ? translate(
                                      'ContributorDetails.phoneTypeCellphone',
                                    )
                                  : translate(
                                      'ContributorDetails.phoneTypeCommercial',
                                    )}
                            </Text>
                          ) : (
                            <Text type="secondary">
                              <Space size="small">
                                <FileUnknownOutlined />
                                N/A
                              </Space>
                            </Text>
                          )}
                        </Form.Item>
                      </Space>
                    ))}
                </>
              )}
            </Form.List>
          </Form.Item>

          <Row gutter={4} align="middle">
            <Col>
              {!shortUrl ? (
                <Button onClick={handleShortenUrl}>Link do Crachá</Button>
              ) : (
                <Col>
                  {shortUrl && (
                    <Typography.Link
                      href={shortUrl}
                      target="_blank"
                      style={{ fontSize: 16 }}
                      copyable
                    >
                      {shortUrl}
                    </Typography.Link>
                  )}
                </Col>
              )}
            </Col>
          </Row>
        </Card>
      </Form>
    </Container>
  )
}

export default ContributorDetails
