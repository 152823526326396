export default {
  pt: {
    Status: {
      helmetTitle: 'Status',
    },
  },
  en: {
    Status: {
      helmetTitle: 'Status',
    },
  },
  es: {
    Status: {
      helmetTitle: 'Estado',
    },
  },
}
