export default {
  pt: {
    Clients: {
      helmetTitle: 'Clientes',
    },
  },
  en: {
    Clients: {
      helmetTitle: 'Customers',
    },
  },
  es: {
    Clients: {
      helmetTitle: 'Clientes',
    },
  },
}
