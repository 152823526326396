import React, { createContext, useContext, useState } from 'react'
import { useQuery } from 'react-query'

import api from '~/services/api'
import { useCompanies } from '../Companies/useCompanies'

interface IResponse {
  user: {
    id: string
    email: string
  }
  company: {
    id: string
  }
  collaborator: {
    id: string
    contact: {
      id: string
      name: string
      phone: string
    }
  }
}
interface IProfile {
  collaborator_id: string
  name: string
  email: string
  phone: string
}

interface ProfileData {
  profile: IProfile | undefined
  isLoading: boolean
  isFetching: boolean
}

const Profile = createContext<ProfileData>({} as ProfileData)

const ProfileProvider: React.FC = ({ children }) => {
  const { selectedCompany } = useCompanies()

  const [profile, setProfile] = useState<IProfile | undefined>(() => {
    const profileCache = localStorage.getItem('@Gstor:profile')

    if (profileCache) {
      return JSON.parse(profileCache)
    }

    return undefined
  })

  const { isLoading, isFetching } = useQuery(
    [`profile`, selectedCompany],
    async () => {
      if (!selectedCompany) {
        return
      }

      const response = await api.get<IResponse[]>(`/users/companies`)

      const userCompany = response.data.find(
        (item) => item.company.id === selectedCompany,
      )

      if (!userCompany) {
        return
      }

      const profileData: IProfile = {
        collaborator_id: userCompany.collaborator.id,
        email: userCompany.user.email,
        name: userCompany.collaborator.contact.name,
        phone: userCompany.collaborator.contact.phone,
      }
      setProfile(profileData)
      localStorage.setItem('@Gstor:profile', JSON.stringify(profileData))
    },
  )

  return (
    <Profile.Provider
      value={{
        profile,
        isLoading,
        isFetching,
      }}
    >
      {children}
    </Profile.Provider>
  )
}

function useProfile(): ProfileData {
  const context = useContext(Profile)

  if (!context) {
    throw new Error('useProfile must be used within an ProfileProvider')
  }

  return context
}

export { ProfileProvider, useProfile }
