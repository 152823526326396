export default {
  pt: {
    TasksRegistrationTypeTasksTable: {
      taskTypeColumn: 'Tipo de tarefa',
      toleranceColumn: 'Tempo de Tolerância',
      runtimeColumn: 'Tempo de Execução',
      descriptionColumn: 'Descrição',
      actionsColumn: 'Ações',
      deletePopconfirm: 'Deseja mesmo deletar o tipo de tarefa?',
      deleteTooltip: 'Deletar',
    },
  },
  en: {
    TasksRegistrationTypeTasksTable: {
      taskTypeColumn: 'Task type',
      toleranceColumn: 'Tolerance Time',
      runtimeColumn: 'Runtime',
      descriptionColumn: 'Description',
      actionsColumn: 'Actions',
      deletePopconfirm: 'Do you really want to delete the task type?',
      deleteTooltip: 'Delete',
    },
  },
  es: {
    TasksRegistrationTypeTasksTable: {
      taskTypeColumn: 'Tipo de tarea',
      toleranceColumn: 'Tiempo de Tolerancia',
      runtimeColumn: 'Tiempo de Ejecución',
      descriptionColumn: 'Descripción',
      actionsColumn: 'Acciones',
      deletePopconfirm: '¿Realmente desea eliminar el tipo de tarea?',
      deleteTooltip: 'Borrar',
    },
  },
}
