import { DeleteOutlined, DownloadOutlined } from '@ant-design/icons'
import { Card, Space, List, Tooltip, Popconfirm, Button } from 'antd'

import React from 'react'

import { useAttachment } from '~/hooks/Equipments/Attachments/useAttachments'
import { translate } from '~/utils/locale'
import AttachmentsCreate from './Create'

import { Container } from './styles'
import UpdateAttachment from './Update'

const AttachmentsTab: React.FC = () => {
  const { attachment, isLoading, deleteAttachment } = useAttachment()

  if (isLoading || !attachment) {
    return <p>{translate('EquipmentsTabsAttachments.loadingText')}</p>
  }

  return (
    <Container>
      <Card
        bordered={false}
        title={translate('EquipmentsTabsAttachments.cardTitle')}
        style={{
          display: 'inline-block',
          width: '100%',
        }}
        extra={<AttachmentsCreate />}
      >
        <List
          itemLayout="vertical"
          size="large"
          dataSource={attachment}
          renderItem={(item) => (
            <List.Item key={item.id}>
              <List.Item.Meta
                title={
                  <Space size="large">
                    <b>{item.title}</b>
                    <Space size="small">
                      <Tooltip
                        title={translate(
                          'EquipmentsTabsAttachments.downloadTooltip',
                        )}
                      >
                        <a
                          href={item.attachment_url}
                          target="_blank"
                          rel="noopener noreferrer"
                          download
                        >
                          <Button
                            ghost
                            shape="circle"
                            icon={
                              <DownloadOutlined style={{ color: ' #3C8ACB' }} />
                            }
                          />
                        </a>
                      </Tooltip>
                      <UpdateAttachment updateAttachments={item} />
                      <Popconfirm
                        title={translate(
                          'EquipmentsTabsAttachments.deletePopconfirm',
                        )}
                        onConfirm={() => deleteAttachment(item.id)}
                      >
                        <Tooltip
                          title={translate(
                            'EquipmentsTabsAttachments.deleteTooltip',
                          )}
                        >
                          <Button
                            ghost
                            shape="circle"
                            icon={<DeleteOutlined />}
                            danger
                          />
                        </Tooltip>
                      </Popconfirm>
                    </Space>
                  </Space>
                }
                description={item.description}
              />
            </List.Item>
          )}
        />
      </Card>
    </Container>
  )
}

export default AttachmentsTab
