/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-nested-ternary */
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

import { DeleteFilled } from '@ant-design/icons'
import { List, Tag, Tooltip, Popconfirm, Button, Space, message } from 'antd'

import api from '~/services/api'

import { useContributor } from '~/hooks/Contributors/useContributor'
import { useCompanies } from '~/hooks/Companies/useCompanies'
import { useLocale } from '~/hooks/locale/useLocale'

import Skills from '~/components/DataManipulation/Skill/Skills'

import { ShowError } from '~/utils/errors/apiErrors'
import { translate } from '~/utils/locale'

interface SkillListProps {
  isVisualization?: boolean
}

const SkillsList: React.FC<SkillListProps> = ({ isVisualization = false }) => {
  const { contributorSkills, contributorRefetch } = useContributor()
  const { locale } = useLocale()
  const [confirmLoading, setConfirmLoading] = useState(false)

  const { selectedCompany } = useCompanies()
  const { contributorId } = useParams<{ contributorId: string }>()

  const handleDelete = async (id: string) => {
    try {
      setConfirmLoading(true)
      await api.delete(
        `/company/${selectedCompany}/collaborator/${contributorId}/collaborator-skill/${id}`,
      )
      setConfirmLoading(false)
      contributorRefetch()
      message.success(translate('SkillSkillsList.successMessage'))
    } catch (err: any) {
      ShowError(err.message, translate('SkillSkillsList.errorMessage'), locale)

      setConfirmLoading(false)
    }
  }

  return (
    <List
      itemLayout="horizontal"
      dataSource={contributorSkills}
      renderItem={(item) => (
        <List.Item
          actions={[
            !isVisualization && (
              <Space size="small">
                <Skills isUpdate SkillInfo={item} />
                <Popconfirm
                  key={item.id}
                  title={translate('SkillSkillsList.deletePopconfirm')}
                  okButtonProps={{ loading: confirmLoading }}
                  onConfirm={() => handleDelete(item.id)}
                >
                  <Tooltip title={translate('SkillSkillsList.deleteTooltip')}>
                    <Button
                      danger
                      ghost
                      shape="circle"
                      icon={<DeleteFilled />}
                    />
                  </Tooltip>
                </Popconfirm>
              </Space>
            ),
          ]}
        >
          <List.Item.Meta
            title={
              item.level === 'basic' ? (
                <>
                  {item.skill.name}{' '}
                  <Tag color="orange">
                    {translate('SkillSkillsList.basicText')}
                  </Tag>
                </>
              ) : item.level === 'intermediary' ? (
                <>
                  {item.skill.name}{' '}
                  <Tag color="green">
                    {translate('SkillSkillsList.intermediaryText')}
                  </Tag>
                </>
              ) : (
                <>
                  {item.skill.name}{' '}
                  <Tag color="blue">
                    {translate('SkillSkillsList.advancedText')}
                  </Tag>
                </>
              )
            }
            description={item.description}
          />
        </List.Item>
      )}
    />
  )
}

export default SkillsList
