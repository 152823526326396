import styled from 'styled-components'

interface BoardProps {
  isCode: boolean
}

export const Container = styled.div`
  overflow-x: scroll;
  min-height: 70vh;
`

export const Boards = styled.div`
  display: flex;
  gap: 1rem;
  padding-bottom: 1rem;

  .teste {
    width: 100%;
  }

  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.8rem 1rem;
    background: #ebebeb;
    border-radius: 0.6rem;

    position: sticky;
    top: 0;
    z-index: 100;

    .principal {
      display: flex;
      align-items: center;
    }

    h4 {
      margin-bottom: 0;
      color: #3c3c3c;
      font-weight: bold;
    }

    span {
      //margin-left: 0.8rem;
      color: #3c3c3c;
      font-weight: bold;
      color: white;
      padding: 0.2rem 0.5rem;
      font-size: 12px;
      border-radius: 6px;
    }

    .circle {
      width: 11px;
      height: 11px;
      background: pink;
      border-radius: 100%;
      margin-right: 10px;
    }
  }

  #received {
    .circle,
    span {
      background: var(--primary-color);
    }
  }

  #viewed {
    .circle,
    span {
      background: var(--terciary-color);
    }
  }

  #accept {
    .circle,
    span {
      background: #49c870;
    }
  }

  #enroute {
    .circle,
    span {
      background: #53109a;
    }
  }

  #working {
    .circle,
    span {
      background: var(--quartenary-color);
    }
  }

  #break {
    .circle,
    span {
      background: #eccc01;
    }
  }

  #canceled {
    .circle,
    span {
      background: var(--color-danger);
    }
  }

  #finished {
    background: #eeeeed;

    h4 {
      color: #afafaf;
    }

    span {
      color: #737373;
    }

    .circle,
    span {
      background: #c4c1c1;
    }
  }
`

export const Wrapper = styled.div``

export const Board = styled.div<BoardProps>`
  min-width: ${(props) => (props.isCode ? '180px' : '350px')};
  margin-top: 0.8rem;

  padding-right: 0.8rem;
  padding-bottom: 1rem;

  overflow: auto;

  .cards {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 0.4rem;
  }
`
