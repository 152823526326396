export default {
  pt: {
    SkillSkillsList: {
      successMessage: 'Habilidade desatribuida com sucesso!',
      errorMessage: 'Erro ao desatribuir habilidade.',
      deletePopconfirm: 'Deseja mesmo desatribuir essa habilidade?',
      deleteTooltip: 'Desatribuir habilidade',
      basicText: 'Básico',
      intermediaryText: 'Intermediário',
      advancedText: 'Avançado',
    },
  },
  en: {
    SkillSkillsList: {
      successMessage: 'Skill successfully unassigned!',
      errorMessage: 'Error when unassigning skill.',
      deletePopconfirm: 'Do you really want to unassign this ability?',
      deleteTooltip: 'Unassign skill',
      basicText: 'Basic',
      intermediaryText: 'Intermediary',
      advancedText: 'Advanced',
    },
  },
  es: {
    SkillSkillsList: {
      successMessage: '¡Habilidad desasignada con éxito!',
      errorMessage: 'Error al desasignar habilidad.',
      deletePopconfirm: '¿Realmente quieres desasignar esta habilidad?',
      deleteTooltip: 'Anular asignación de habilidad',
      basicText: 'Básico',
      intermediaryText: 'Intermediario',
      advancedText: 'Avanzado',
    },
  },
}
