/* eslint-disable no-underscore-dangle */
import { EditOutlined } from '@ant-design/icons'
import { Modal, Button, Tooltip, Form, Input, Space } from 'antd'

import React, { useState } from 'react'
import { useTicketType } from '~/hooks/HelpDesk/useTicketType'

import { translate } from '~/utils/locale'
import { ContainerButton } from './styles'

interface TicketTypeProps {
  description: string
  _id: string
  title: string
}
interface UpdateTicketTypeData {
  data: TicketTypeProps
}

const UpdateTicketType: React.FC<UpdateTicketTypeData> = ({ data }) => {
  const { updateTicketType } = useTicketType()

  const [isModalVisible, setIsModalVisible] = useState(false)

  const initialData = {
    description: data.description,
    title: data.title,
  }

  function onUpdateTicketType(dataForm: TicketTypeProps) {
    try {
      updateTicketType(dataForm, data._id)
      setIsModalVisible(false)
    } catch (err) {
      setIsModalVisible(false)
    }
  }

  const showModal = () => {
    setIsModalVisible(true)
  }

  const closeModal = () => {
    setIsModalVisible(false)
  }

  return (
    <div>
      <Tooltip title={translate('TicketTypeUpdate.editTooltip')}>
        <Button
          type="primary"
          onClick={showModal}
          ghost
          shape="circle"
          icon={<EditOutlined />}
        />
      </Tooltip>
      <Modal
        title={translate('TicketTypeUpdate.modalTitle')}
        open={isModalVisible}
        onOk={closeModal}
        onCancel={closeModal}
        width="700px"
        footer={[
          <Space key="buttons">
            <Button key="back" onClick={closeModal}>
              {translate('TicketTypeUpdate.closeButton')}
            </Button>
            <ContainerButton>
              <Button
                type="primary"
                key="update"
                htmlType="submit"
                form="pauseUpdateForm"
                id="btn_update_ticket_type"
              >
                {translate('TicketTypeUpdate.saveButton')}
              </Button>
            </ContainerButton>
          </Space>,
        ]}
      >
        <Form
          onFinish={onUpdateTicketType}
          initialValues={initialData}
          layout="vertical"
          id="pauseUpdateForm"
        >
          <Form.Item
            label={translate('TicketTypeUpdate.titleLabel')}
            name="title"
            rules={[
              {
                required: true,
                message: translate('TicketTypeUpdate.titleRule'),
              },
            ]}
          >
            <Input
              placeholder={translate('TicketTypeUpdate.titlePlaceholder')}
            />
          </Form.Item>
          <Form.Item
            label={translate('TicketTypeUpdate.descriptionLabel')}
            name="description"
            rules={[
              {
                required: true,
                message: translate('TicketTypeUpdate.descriptionRule'),
              },
            ]}
          >
            <Input.TextArea />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}

export default UpdateTicketType
