/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, createContext, useState } from 'react'
import { useQuery } from 'react-query'

import { message } from 'antd'

import api from '~/services/api'

import { useCompanies } from '~/hooks/Companies/useCompanies'
import { useLocale } from '~/hooks/locale/useLocale'

import { ShowError } from '~/utils/errors/apiErrors'
import { translate } from '~/utils/locale'

interface SpecificationProps {
  attribute: string
  value: string
}

interface ListTemplateProps {
  id: string
  name: string
  specifications: SpecificationProps[]
}

interface ListTemplateContextData {
  listTemplate?: ListTemplateProps[]
  createTemplate(dataTemplate: object | undefined): void
  updateTemplates(dataTemplate: object | undefined, templateId: string): void
  deleteTemplate(templateId: string): void
  isLoading: boolean
  refetch: () => void
  isFetching: boolean
  templateTotal?: number
}

const ListTemplateContext = createContext<ListTemplateContextData>(
  {} as ListTemplateContextData,
)

export const ListTemplateProvider: React.FC = ({ children }) => {
  const { selectedCompany } = useCompanies()
  const { locale } = useLocale()

  const [templateTotal, setTemplateTotal] = useState<number>()

  const {
    data: listTemplate,
    isLoading,
    refetch,
    isFetching,
  } = useQuery(
    [`lista de templates${selectedCompany}`, selectedCompany],

    async () => {
      try {
        const response = await api.get(
          `/company/${selectedCompany}/specifications-templates`,
        )

        const { data } = response

        setTemplateTotal(data.total)

        return data.results
      } catch (err: any) {
        ShowError(
          err.message,
          translate('ListTemplate.getErrorMessage'),
          locale,
        )
      }
    },
    {
      staleTime: 5000,
    },
  )

  async function createTemplate(dataTemplate: object) {
    try {
      await api.post(
        `/company/${selectedCompany}/specification-template`,
        dataTemplate,
      )

      refetch()
      message.success(translate('ListTemplate.createSuccessMessage'))
    } catch (err: any) {
      ShowError(
        err.message,
        translate('ListTemplate.createErrorMessage'),
        locale,
      )
    }
  }

  async function updateTemplates(dataTemplate: object, templateId: string) {
    try {
      await api.put(
        `/company/${selectedCompany}/specification-template/${templateId}`,
        dataTemplate,
      )

      refetch()
      message.success('ListTemplate.updateSuccessMessage')
    } catch (err: any) {
      ShowError(
        err.message,
        translate('ListTemplate.updateErrorMessage'),
        locale,
      )
    }
  }

  async function deleteTemplate(templateId: string) {
    try {
      await api.delete(
        `/company/${selectedCompany}/specification-template/${templateId}`,
      )

      refetch()
      message.success(translate('ListTemplate.deleteSuccessMessage'))
    } catch (err: any) {
      ShowError(
        err.message,
        translate('ListTemplate.deleteErrorMessage'),
        locale,
      )
    }
  }

  return (
    <ListTemplateContext.Provider
      value={{
        listTemplate,
        createTemplate,
        deleteTemplate,
        updateTemplates,
        isLoading,
        refetch,
        isFetching,
        templateTotal,
      }}
    >
      {children}
    </ListTemplateContext.Provider>
  )
}

export function useListTemplate(): ListTemplateContextData {
  const context = useContext(ListTemplateContext)

  if (!context) {
    throw new Error('useAuth must be used within an AutoProvider')
  }

  return context
}
