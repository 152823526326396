export default {
  pt: {
    ScalesTeamsAdd: {
      addButton: 'Adicionar Equipes',
      modalTitle: 'Adicionar Equipes',
      closeButton: 'Fechar',
    },
  },
  en: {
    ScalesTeamsAdd: {
      addButton: 'Add Teams',
      modalTitle: 'Add Teams',
      closeButton: 'Close',
    },
  },
  es: {
    ScalesTeamsAdd: {
      addButton: 'Agregar Equipos',
      modalTitle: 'Agregar Equipos',
      closeButton: 'Cerrar',
    },
  },
}
