export default {
  pt: {
    ScalesClientsAdd: {
      addButton: 'Adicionar Clientes',
      modalTitle: 'Adicionar Clientes',
      closeButton: 'Fechar',
    },
  },
  en: {
    ScalesClientsAdd: {
      addButton: 'Add Customers',
      modalTitle: 'Add Customers',
      closeButton: 'Close',
    },
  },
  es: {
    ScalesClientsAdd: {
      addButton: 'Agregar Clientes',
      modalTitle: 'Agregar Clientes',
      closeButton: 'Cerrar',
    },
  },
}
