export default {
  pt: {
    EquipmentsComponent: {
      cardTitle: 'Equipamentos',
      totalText: 'Total de',
      equipmentText: 'equipamento',
      equipmentsText: 'equipamentos',
      newEquipmentButton: 'Novo Equipamento',
    },
  },
  en: {
    EquipmentsComponent: {
      cardTitle: 'Equipment',
      totalText: 'Total of',
      equipmentText: 'equipment',
      equipmentsText: 'equipment',
      newEquipmentButton: 'New Equipment',
    },
  },
  es: {
    EquipmentsComponent: {
      cardTitle: 'Equipo',
      totalText: 'Total de',
      equipmentText: 'equipo',
      equipmentsText: 'equipos',
      newEquipmentButton: 'Nuevo equipamiento',
    },
  },
}
