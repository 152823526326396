export default {
  pt: {
    EquipmentsTabsSpecificationImportTemplate: {
      successMessage: 'Importação realizada!',
      templateColumn: 'Template',
      attributesColumn: 'Atributos',
      attributeText: 'atributo',
      attributesText: 'atributos',
      actionsColumn: 'Ações',
      importPopconfirm: 'Deseja mesmo importar o template',
      importTooltip: 'Importar template',
      importButton: 'Importar Template de Especificação',
      modalTitle: 'Importar Template',
      closeButton: 'Fechar',
    },
  },
  en: {
    EquipmentsTabsSpecificationImportTemplate: {
      successMessage: 'Import done!',
      templateColumn: 'Template',
      attributesColumn: 'Attributes',
      attributeText: 'attribute',
      attributesText: 'attributes',
      actionsColumn: 'Actions',
      importPopconfirm: 'Do you really want to import the template',
      importTooltip: 'Import template',
      importButton: 'Import Specification Template',
      modalTitle: 'Import Template',
      closeButton: 'Close',
    },
  },
  es: {
    EquipmentsTabsSpecificationImportTemplate: {
      successMessage: '¡Importación hecha!',
      templateColumn: 'Plantilla',
      attributesColumn: 'Atributos',
      attributeText: 'atributo',
      attributesText: 'atributos',
      actionsColumn: 'Acciones',
      importPopconfirm: '¿Realmente quieres importar la plantilla',
      importTooltip: 'Importar plantilla',
      importButton: 'Plantilla de especificación de importación',
      modalTitle: 'Importar plantilla',
      closeButton: 'Cerrar',
    },
  },
}
