import { lighten } from 'polished'
import styled from 'styled-components'

export const Container = styled.div`
  #btn-edit-charge-client {
    background: var(--quartenary-color);
    border: 1px solid var(--quartenary-color);

    &:hover {
      background: ${lighten(0.05, '#3969B7')};
      border: 1px solid ${lighten(0.1, '#3969B7')};
    }
  }
`

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Address = styled.div`
  flex: 1;

  .text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-content: flex-start;
    padding-bottom: 23px;
  }
`

export const Map = styled.div`
  margin-left: 10px;
  flex: 0.6;
`
