export default {
  pt: {
    NewContributor: {
      helmetTitle: 'Novo Colaborador',
    },
  },
  en: {
    NewContributor: {
      helmetTitle: 'New Contributor',
    },
  },
  es: {
    NewContributor: {
      helmetTitle: 'Nuevo Colaborador',
    },
  },
}
