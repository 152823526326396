/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  useContext,
  createContext,
  useState,
  useEffect,
  useCallback,
} from 'react'
import { useQuery } from 'react-query'

import api from '~/services/api'

import { useCompanies } from '~/hooks/Companies/useCompanies'
import { useLocale } from '~/hooks/locale/useLocale'

import { ShowError } from '~/utils/errors/apiErrors'
import { translate } from '~/utils/locale'

interface AddressProps {
  id: string
  type: string
  address: {
    zip_code: string
    street: string
    number: string
    complement: string
    neighborhood: string
    city: string
    id: string
    state: string
    location: { x: number; y: number }
  }
}

interface ContactProps {
  name: string
  email: string
  id: string
  contactAddresses: AddressProps[]
}

interface ClientsProps {
  id: string
  contact: ContactProps
}

interface FiltersId {
  id: string
}

interface FilterOptionsProps {
  contact?: string
  address?: string
  corporate_name?: string
  external_code?: string
  municipal_registration?: string
  state_registration?: string
  segment_id?: string
  collaborator_id?: FiltersId[]
}

interface ClientsListTaskContextData {
  clients?: ClientsProps[]
  isLoading: boolean
  refetch: () => void
  isFetching: boolean
  handleUpdateFilters: (newFilterOptions: any) => void
  handleResetFilters: () => void
  filterOptions: FilterOptionsProps
  setPageSize(value: any): void
  setNumberPage(value: any): void
  clientsTotal?: number
}

const ClientsListTaskContext = createContext<ClientsListTaskContextData>(
  {} as ClientsListTaskContextData,
)

export const ClientsListTaskProvider: React.FC = ({ children }) => {
  const { selectedCompany } = useCompanies()
  const { locale } = useLocale()

  const [pageSize, setPageSize] = useState<number>(10)
  const [numberPage, setNumberPage] = useState<number>(1)

  const [clientsTotal, setClientsTotal] = useState<number>()

  const [filterOptions, setFilterOptions] = useState<FilterOptionsProps>(() => {
    const filter = localStorage.getItem('@Gstor:clientsTaskFilterOptions')

    if (filter) {
      return JSON.parse(filter)
    }

    return {} as FilterOptionsProps
  })

  const {
    data: clients,
    isLoading,
    refetch,
    isFetching,
  } = useQuery(
    [
      `lista de clientes em criação de tarefas${selectedCompany}`,
      selectedCompany,
      numberPage,
      pageSize,
      filterOptions,
    ],

    async () => {
      try {
        if (!selectedCompany) {
          return undefined
        }

        const paginationLimit = numberPage * pageSize
        const paginationOffset = paginationLimit - pageSize

        const response = await api.get(
          `/company/${selectedCompany}/customers`,
          {
            params: {
              offset: paginationOffset,
              limit: paginationLimit,
              contact: filterOptions?.contact,
              address: filterOptions?.address,
              corporate_name: filterOptions?.corporate_name,
              external_code: filterOptions?.external_code,
              municipal_registration: filterOptions?.municipal_registration,
              state_registration: filterOptions?.state_registration,
              segment_id: filterOptions?.segment_id,
              ...(filterOptions?.collaborator_id &&
                filterOptions?.collaborator_id?.join() !== '' && {
                  collaborator_id: filterOptions?.collaborator_id?.join(),
                }),
            },
          },
        )

        const { data } = response

        setClientsTotal(data.total)

        return data.results
      } catch (err: any) {
        ShowError(
          err.message,
          translate('useListClients.getErrorMessage'),
          locale,
        )
      }
    },
    {
      staleTime: 5000,
    },
  )

  const handleUpdateFilters = useCallback((newFilterOptions) => {
    const newFilters = {
      contact: newFilterOptions?.contact,
      address: newFilterOptions?.address,
      corporate_name: newFilterOptions?.corporate_name,
      external_code: newFilterOptions?.external_code,
      municipal_registration: newFilterOptions?.municipal_registration,
      state_registration: newFilterOptions?.state_registration,
      segment_id: newFilterOptions?.segment_id,
      collaborator_id: newFilterOptions?.collaborator_id,
    }
    setFilterOptions(newFilters)
    localStorage.setItem(
      '@Gstor:clientsTaskFilterOptions',
      JSON.stringify(newFilters),
    )
    // setNumberPage(1);
  }, [])

  const handleResetFilters = useCallback(() => {
    localStorage.removeItem('@Gstor:clientsTaskFilterOptions')
    setFilterOptions({})
    // setNumberPage(1);
  }, [])

  useEffect(() => {
    refetch()
  }, [selectedCompany, refetch])

  return (
    <ClientsListTaskContext.Provider
      value={{
        clients,
        isLoading,
        refetch,
        handleUpdateFilters,
        handleResetFilters,
        filterOptions,
        isFetching,
        setPageSize,
        setNumberPage,
        clientsTotal,
      }}
    >
      {children}
    </ClientsListTaskContext.Provider>
  )
}

export function useClientsListTask(): ClientsListTaskContextData {
  const context = useContext(ClientsListTaskContext)

  if (!context) {
    throw new Error('useAuth must be used within an AutoProvider')
  }

  return context
}
