/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, {
  ReactNode,
  useContext,
  createContext,
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
} from 'react'
import { useQuery } from 'react-query'

import dayjs from 'dayjs'

import { message } from 'antd'
import api from '~/services/api'

import { useCompanies } from '~/hooks/Companies/useCompanies'
import { useLocale } from '~/hooks/locale/useLocale'

import { ShowError } from '~/utils/errors/apiErrors'
import { translate } from '~/utils/locale'

interface ScaleProviderProps {
  children: ReactNode
}

interface TeamProps {
  collaborator_id: string
  id: string
  title: string
}

interface TeamsSchedulesProps {
  id: string
  team: TeamProps
}

interface AddressProps {
  city: string
  complement: string
  neighborhood: string
  id: string
  location: {
    x: number
    y: number
  }
  number: string
  state: string
  street: string
  zip_code: string
}

interface CustomerContactData {
  contactAddresses: any[]
  id: string
  name: string
}

interface CustomerProps {
  contact: CustomerContactData
  id: string
}

interface CustomersSchedulesProps {
  customer: CustomerProps
  id: string
}

interface ScheduleTimesProps {
  checkin: dayjs.Dayjs
  checkout: dayjs.Dayjs
  day: dayjs.Dayjs
  description: string
  group_id: string
  id: string
  type: string
}

interface CompanyData {
  id: string
  name: string
}

interface CollaboratorProps {
  contact: CompanyData
  id: string
}

interface ContributorsSchedulesProps {
  collaborator: CollaboratorProps
  id: string
}

export interface ScheduleTimeInput {
  day?: dayjs.Dayjs
  checkin: dayjs.Dayjs
  checkout: dayjs.Dayjs
  type: string
  description: string
}

interface Scale {
  address: AddressProps
  company: CompanyData
  contributorsSchedules: ContributorsSchedulesProps[]
  created_at: string
  customersSchedules: CustomersSchedulesProps[]
  description: string
  id: string
  is_enabled: boolean
  operating_range: number
  teamsSchedules: TeamsSchedulesProps[]
  scheduleTimes: ScheduleTimesProps[]
  title: string
}

interface ScaleContextData {
  scaleRefetch: () => void
  createScheduleTime: (scheduleTime: ScheduleTimeInput[]) => Promise<void>
  updateScheduleTime: (
    scheduleTime: ScheduleTimeInput,
    id: string,
  ) => Promise<void>
  updateGroupScheduleTime: (
    scheduleTime: ScheduleTimeInput,
    groupId: string,
  ) => Promise<void>
  scaleData: Scale
  isLoading: boolean
  isFetching: boolean
  setSelectedScale: Dispatch<SetStateAction<string>>
}

const ScaleContext = createContext<ScaleContextData>({} as ScaleContextData)

export function ScaleProvider({ children }: ScaleProviderProps) {
  const { selectedCompany } = useCompanies()
  const { locale } = useLocale()

  const [selectedScale, setSelectedScale] = useState('')

  const { data, isLoading, isFetching, refetch } = useQuery(
    ['scale', selectedCompany, selectedScale],
    async () => {
      try {
        if (!selectedCompany || !selectedScale) {
          return undefined
        }

        const response = await api.get(
          `/company/${selectedCompany}/schedule/${selectedScale}`,
        )

        const scale = response.data

        // console.log(scale);

        return scale
      } catch (err: any) {
        ShowError(err.message, translate('useScale.getErrorMessage'), locale)
      }
    },
    {
      staleTime: 5000, // 5 segundos
    },
  )

  useEffect(() => {
    refetch()
  }, [selectedCompany, refetch])

  async function createScheduleTime(scheduleTime: ScheduleTimeInput[]) {
    try {
      await api.post(
        `/company/${selectedCompany}/schedule/${selectedScale}/schedule-time`,
        scheduleTime,
      )

      message.success(translate('ScalesTimeAddModal.successMessage'))
      refetch()
    } catch (err: any) {
      ShowError(err.message, translate('useScale.createErrorMessage'), locale)
    }
  }

  async function updateScheduleTime(
    scheduleTime: ScheduleTimeInput,
    id: string,
  ) {
    try {
      await api.put(
        `/company/${selectedCompany}/schedule/${selectedScale}/schedule-time/${id}`,
        scheduleTime,
      )

      refetch()
    } catch (err: any) {
      ShowError(err.message, translate('useScale.updateErrorMessage'), locale)
    }
  }

  async function updateGroupScheduleTime(
    scheduleTime: ScheduleTimeInput,
    groupId: string,
  ) {
    try {
      await api.put(
        `/company/${selectedCompany}/schedule/${selectedScale}/schedule-time-group/${groupId}`,
        scheduleTime,
      )

      refetch()
    } catch (err: any) {
      ShowError(err.message, translate('useScale.updateErrorMessage'), locale)
    }
  }

  return (
    <ScaleContext.Provider
      value={{
        scaleData: data,
        createScheduleTime,
        updateScheduleTime,
        updateGroupScheduleTime,
        isLoading,
        isFetching,
        scaleRefetch: refetch,
        setSelectedScale,
      }}
    >
      {children}
    </ScaleContext.Provider>
  )
}

export function useScale() {
  const context = useContext(ScaleContext)

  return context
}
