export default {
  pt: {
    TeamsComponentTabFinancialUpdate: {
      cardTitle: 'Editar Financeiro',

      cardTitleTop: 'Dados de Pagamento',

      valueLabel: 'Valor a pagar',
      valuePlaceholder: 'Informe o valor a pagar',

      dayLabel: 'Dia de Pagamento',
      dayPlaceholder: 'Informe o dia de pagamento',

      hoursLabel: 'Quantidade de horas',
      hoursPlaceholder: 'Informe a quantidade de horas',

      valueHoursLabel: 'Valor Hora Adicional',
      valueHoursPlaceholder: 'Informe a quantidade de horas adicionais',

      kmLabel: 'Quantidade de KM',
      kmPlaceholder: 'Informe a quantidade de km',

      kmValueLabel: 'Valor KM Adicional',
      kmValuePlaceholder: 'Informe a quantidade de kms adicionais',

      conditionsLabel: 'Condições de Pagamento',
      conditionsPlaceholder: 'Informe as condições de pagamento',

      methodLabel: 'Método de Pagamento',
      methodPlaceholder: 'Informe o metódo de pagamento',

      cardTitleBottom: 'Dados Bancários',
      numberBankLabel: 'Número do Banco',
      numberBankPlaceholder: 'Informe o número do banco',

      nameBankLabel: 'Nome do Banco',
      nameBankPlaceholder: 'Informe o nome do banco',

      typePersonAccountLabel: 'Conta PF ou PJ',
      typePersonAccountPlaceholder: 'Informe se a conta é PF ou PJ',

      agencyLabel: 'Agência',
      agencyPlaceholder: 'Informe o número da agência',

      accountLabel: 'Conta / Poupança',
      accountPlaceholder: 'Informe o número da conta',

      typeAccountLabel: 'Tipo de Conta',
      typeAccountPlaceholder: 'Informe o tipo de conta',

      pixLabel: 'Chave pix',
      pixLabelPlaceholder: 'Informe a chave pix',

      buttonSave: 'Salvar',
      buttonClose: 'Fechar',
    },
  },
  en: {
    TeamsComponentTabFinancialUpdate: {
      cardTitle: 'Editar Financial',

      cardTitleTop: 'Payment Data',

      valueLabel: 'Amount to pay',
      valuePlaceholder: 'Enter the amount to pay',

      dayLabel: 'Payday',
      dayPlaceholder: 'Enter the payment date',

      hoursLabel: 'Number of hours',
      hoursPlaceholder: 'Enter the number of hours',

      valueHoursLabel: 'Additional Hourly Value',
      valueHoursPlaceholder: 'Enter the number of additional hours',

      kmLabel: 'Quantity of km',
      kmPlaceholder: 'Enter the number of km',

      kmValueLabel: 'Additional KM Value',
      kmValuePlaceholder: 'Enter the number of additional kms ',

      conditionsLabel: 'Payment Terms',
      conditionsPlaceholder: 'Provide payment terms',

      methodLabel: 'Payment Method',
      methodPlaceholder: 'Enter the payment method',

      cardTitleBottom: 'Bank Details',

      numberBankLabel: 'Bank Number',
      numberBankPlaceholder: 'Enter the number of the bank',

      nameBankLabel: 'Bank Name',
      nameBankPlaceholder: 'Enter the name of the bank',

      typePersonAccountLabel: 'PF or corporate account',
      typePersonAccountPlaceholder: 'Inform whether the account is PF or PJ',

      agencyLabel: 'Agency',
      agencyPlaceholder: 'Enter the agency number',

      accountLabel: 'Account / Savings',
      accountPlaceholder: 'Enter the account number',

      typeAccountLabel: 'Account Type',
      typeAccountPlaceholder: 'Enter the type of account',

      pixLabel: 'Pix key',
      pixLabelPlaceholder: 'Enter the pix key',

      buttonSave: 'Save',
      buttonClose: 'Close',
    },
  },
  es: {
    TeamsComponentTabFinancialUpdate: {
      cardTitle: 'Editar Financiero',

      cardTitleTop: 'Datos de pago',

      valueLabel: 'Cantidad a pagar',
      valuePlaceholder: 'Ingrese el monto a pagar',

      dayLabel: 'Día de paga',
      dayPlaceholder: 'Ingrese la fecha de pago',

      hoursLabel: 'Número de horas',
      hoursPlaceholder: 'Introduzca el número de horas',

      valueHoursLabel: 'Valor Hora Adicional',
      valueHoursPlaceholder: 'Ingrese el número de horas adicionales',

      kmLabel: 'Cantidad de KM',
      kmPlaceholder: 'Introduzca el número de kilómetros',

      kmValueLabel: 'Valor KM Adicional',
      kmValuePlaceholder: 'Introduce el número de kilómetros adicionales',

      conditionsLabel: 'Condiciones de pago',
      conditionsPlaceholder: 'Proporcionar condiciones de pago',

      methodLabel: 'Método de pago',
      methodPlaceholder: 'Ingrese el método de pago',

      cardTitleBottom: 'Detalles bancarios',

      numberBankLabel: 'Número de banco',
      numberBankPlaceholder: 'Ingrese el número del banco',

      nameBankLabel: 'Nombre del banco',
      nameBankPlaceholder: 'Ingrese el nombre del banco',

      typePersonAccountLabel: 'PF o cuenta corporativa',
      typePersonAccountPlaceholder: 'Informar si la cuenta es PF o PJ',

      agencyLabel: 'Agencia',
      agencyPlaceholder: 'Ingrese el número de agencia',

      accountLabel: 'Cuenta / Ahorros',
      accountPlaceholder: 'Ingrese el número de cuenta',

      typeAccountLabel: 'Tipo de cuenta',
      typeAccountPlaceholder: 'Ingrese el tipo de cuenta',

      pixLabel: 'Clave de foto',
      pixLabelPlaceholder: 'Ingrese la clave de foto',

      buttonSave: 'Guardar',
      buttonClose: 'Fechar',
    },
  },
}
