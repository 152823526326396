export default {
  pt: {
    EquipmentsFilter: {
      cleanFilter: 'Limpar Filtro',
      filterAppliedText: 'Filtro Aplicado',
      filterText: 'Filtros',
      drawerTitle: 'Filtros de Equipamentos',
      equipmentLabel: 'Equipamento:',
      equipmentPlaceholder: 'Informe o equipamento',
      categoryLabel: 'Categoria: ',
      categoryPlaceholder: 'Categoria',
      contributorLabel: 'Colaborador:',
      contributorPlaceholder: 'Informe o colaborador',
      clientLabel: 'Cliente:',
      clientPlaceholder: 'Informe o cliente',
      creationDateLabel: 'Data de Criação: ',
      expirationDateLabel: 'Data de Validade: ',
      statusLabel: 'Status:',
      statusActive: 'Ativo',
      statusInactive: 'Inativo',
      applyButton: 'Aplicar',
      cleanButton: 'Limpar',
    },
  },
  en: {
    EquipmentsFilter: {
      cleanFilter: 'Clean Filter',
      filterAppliedText: 'Filter Applied',
      filterText: 'Filter',
      drawerTitle: 'Equipment Filters',
      equipmentLabel: 'Equipment:',
      equipmentPlaceholder: 'Inform the equipment',
      categoryLabel: 'Category: ',
      categoryPlaceholder: 'Category',
      contributorLabel: 'Collaborator:',
      contributorPlaceholder: 'Inform the collaborator',
      clientLabel: 'Customer:',
      clientPlaceholder: 'Inform the customer',
      creationDateLabel: 'Creation date: ',
      expirationDateLabel: 'Expiration date: ',
      statusLabel: 'Status:',
      statusActive: 'Active',
      statusInactive: 'Inactive',
      applyButton: 'Apply',
      cleanButton: 'Clean',
    },
  },
  es: {
    EquipmentsFilter: {
      cleanFilter: 'Filtro Claro',
      filterAppliedText: 'Filtro Aplicado',
      filterText: 'Filtros',
      drawerTitle: 'Filtros de equipos',
      equipmentLabel: 'Equipo:',
      equipmentPlaceholder: 'Informar al equipo',
      categoryLabel: 'Categoría: ',
      categoryPlaceholder: 'Categoría',
      contributorLabel: 'Colaborador:',
      contributorPlaceholder: 'Informar al colaborador',
      clientLabel: 'Cliente:',
      clientPlaceholder: 'Informar al cliente',
      creationDateLabel: 'Fecha de creación: ',
      expirationDateLabel: 'Fecha de validez: ',
      statusLabel: 'Status:',
      statusActive: 'Activo',
      statusInactive: 'Inactivo',
      applyButton: 'Aplicar',
      cleanButton: 'Limpio',
    },
  },
}
