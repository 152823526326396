/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, {
  ReactNode,
  useContext,
  createContext,
  useEffect,
  useState,
  SetStateAction,
  Dispatch,
  useCallback,
} from 'react'
import { useQuery } from 'react-query'
import { formatPhoneNumber } from 'react-phone-number-input'

import { message } from 'antd'

import api from '~/services/api'

import { useCompanies } from '~/hooks/Companies/useCompanies'
import { useLocale } from '~/hooks/locale/useLocale'

import { ShowError } from '~/utils/errors/apiErrors'
import { translate } from '~/utils/locale'

interface ListContributorsProviderProps {
  children: ReactNode
}

interface Contact {
  avatar: string
  avatar_url: string
  birth: string
  gender: string
  id: string
  name: string
  personType: string
  phone: string
  formattedPhone?: string
}

interface Status {
  color: string
  id: string
  description: string
  title: string
  type: string
}

interface Skill {
  id: string
  description: string
  name: string
}

interface Skills {
  description: string
  id: string
  level: string
  skill: Skill
}

interface Attachments {
  id: string
  title: string
  description: string
  attachment_url: string
  attachment: string
}

interface Company {
  id: string
  logo: string
  logo_url: string
  name: string
}

export interface ListContributorsData {
  collaboratorAttachments: Attachments[]
  collaboratorSkills: Skills[]
  company: Company
  contact: Contact
  evaluation: number
  id: string
  status: Status
}

interface dataProps {
  collaboratorSkills: string[]
  company: Company
  contact: Contact
  evaluation: number
  id: string
  status: Status
}

interface StatusId {
  id: string
}

interface FilterOptionsProps {
  contact?: string
  address?: string
  fromEvaluation?: number
  toEvaluation?: number
  fromInternalEvaluation?: number
  toInternalEvaluation?: number
  status_id?: StatusId[]
  skills_id?: string
}

interface ListContributorsContextData {
  listContributorsRefetch: () => void
  handleUpdateFilters: (newFilterOptions: any) => void
  deleteContributor(contributorId: string): void
  deleteContributors(contributorId: string): void
  handleResetFilters: () => void
  data: ListContributorsData[]
  contributorsTotal: number
  previous: boolean
  next: boolean
  setPageSize: Dispatch<SetStateAction<number>>
  setNumberPage: Dispatch<SetStateAction<number>>
  isLoading: boolean
  isFetching: boolean
  filterOptions: FilterOptionsProps
  // allData: ListContributorsData[]
  // isAllDataLoading: boolean
  // isAllDataFetching: boolean
  // allDataRefetch: () => void
}

const ListContributorsContext = createContext<ListContributorsContextData>(
  {} as ListContributorsContextData,
)

export function ListContributorsProvider({
  children,
}: ListContributorsProviderProps) {
  const { selectedCompany } = useCompanies()
  const { locale } = useLocale()

  const [filterOptions, setFilterOptions] = useState<FilterOptionsProps>(() => {
    const filter = localStorage.getItem('@Gstor:contributorFilterOptions')

    if (filter) {
      return JSON.parse(filter)
    }

    return {} as FilterOptionsProps
  })
  const [contributorsTotal, setContributorsTotal] = useState(0)
  const [previous, setPrevious] = useState(false)
  const [next, setNext] = useState(false)
  const [pageSize, setPageSize] = useState(10)
  const [numberPage, setNumberPage] = useState(1)

  const { data, isLoading, isFetching, refetch } = useQuery(
    ['contributors', selectedCompany, pageSize, numberPage, filterOptions],
    async () => {
      try {
        if (!selectedCompany) {
          return undefined
        }

        const paginationLimit = numberPage * pageSize
        const paginationOffset = paginationLimit - pageSize

        const response = await api.get(
          `/company/${selectedCompany}/contributors`,
          {
            params: {
              limit: pageSize,
              offset: paginationOffset,
              contact: filterOptions?.contact?.toString().toUpperCase(),
              address: filterOptions?.address,
              fromEvaluation: filterOptions?.fromEvaluation,
              toEvaluation: filterOptions?.toEvaluation,
              fromInternalEvaluation: filterOptions?.fromInternalEvaluation,
              toInternalEvaluation: filterOptions?.toInternalEvaluation,
              ...(filterOptions?.status_id &&
                filterOptions?.status_id?.join() !== '' && {
                  status_id: filterOptions?.status_id?.join(),
                }),
              skills_id: filterOptions?.skills_id,
            },
          },
        )

        const allContributors = response.data.results

        allContributors.forEach((item: dataProps) => {
          if (item.contact.phone) {
            item.contact.formattedPhone = formatPhoneNumber(
              `+${item.contact.phone}`,
            )

            if (item.contact.formattedPhone.replace(/[0-9]/g, '') === '') {
              if (item.contact.formattedPhone.length === 10) {
                const forceFormat = item.contact.formattedPhone.match(
                  /^(\d{2})(\d{4})(\d{4})$/,
                )

                if (forceFormat) {
                  item.contact.formattedPhone = `(${forceFormat[1]}) ${forceFormat[2]}-${forceFormat[3]}`
                }
              } else if (item.contact.formattedPhone.length === 9) {
                const forceFormat = item.contact.formattedPhone.match(
                  /^(\d{2})(\d{4})(\d{3})$/,
                )

                if (forceFormat) {
                  item.contact.formattedPhone = `(${forceFormat[1]}) ${forceFormat[2]}-${forceFormat[3]}`
                }
              }
            }
          }
        })

        setContributorsTotal(response.data.total)
        setPrevious(response.data.previous)
        setNext(response.data.next)

        return allContributors
      } catch (err: any) {
        ShowError(
          err.message,
          translate('useListContributors.getErrorMessage'),
          locale,
        )
      }
    },
    {
      staleTime: 5000, // 5 segundos
    },
  )

  // const {
  //   data: allData,
  //   isLoading: isAllDataLoading,
  //   isFetching: isAllDataFetching,
  //   refetch: allDataRefetch,
  // } = useQuery(
  //   ['allContributors', selectedCompany],
  //   async () => {
  //     try {
  //       if (!selectedCompany) {
  //         return undefined
  //       }

  //       const response = await api.get(
  //         `/company/${selectedCompany}/contributors/all`,
  //       )

  //       const allContributors = response.data.results

  //       allContributors.forEach((item: dataProps) => {
  //         if (item.contact?.phone) {
  //           item.contact.formattedPhone = formatPhoneNumber(
  //             `+${item.contact?.phone}`,
  //           )

  //           if (item.contact.formattedPhone.replace(/[0-9]/g, '') === '') {
  //             if (item.contact.formattedPhone.length === 10) {
  //               const forceFormat = item.contact.formattedPhone.match(
  //                 /^(\d{2})(\d{4})(\d{4})$/,
  //               )

  //               if (forceFormat) {
  //                 item.contact.formattedPhone = `(${forceFormat[1]}) ${forceFormat[2]}-${forceFormat[3]}`
  //               }
  //             } else if (item.contact.formattedPhone.length === 9) {
  //               const forceFormat = item.contact.formattedPhone.match(
  //                 /^(\d{2})(\d{4})(\d{3})$/,
  //               )

  //               if (forceFormat) {
  //                 item.contact.formattedPhone = `(${forceFormat[1]}) ${forceFormat[2]}-${forceFormat[3]}`
  //               }
  //             }
  //           }
  //         }
  //       })

  //       return allContributors
  //     } catch (err: any) {
  //       ShowError(
  //         err.message,
  //         translate('useListContributors.getErrorMessage'),
  //         locale,
  //       )
  //     }
  //   },
  //   {
  //     staleTime: 300000, // 5 minutos
  //   },
  // )

  const handleUpdateFilters = useCallback((newFilterOptions) => {
    const newFilters = {
      contact: newFilterOptions?.contact,
      address: newFilterOptions?.address,
      fromEvaluation: newFilterOptions?.evaluationRange?.[0],
      toEvaluation: newFilterOptions?.evaluationRange?.[1],
      fromInternalEvaluation: newFilterOptions?.internalEvaluationRange?.[0],
      toInternalEvaluation: newFilterOptions?.internalEvaluationRange?.[1],
      status_id: newFilterOptions?.status_id,
      skills_id: newFilterOptions?.skills?.toString(),
    }
    setFilterOptions(newFilters)
    localStorage.setItem(
      '@Gstor:contributorFilterOptions',
      JSON.stringify(newFilters),
    )
    setNumberPage(1)
  }, [])

  const handleResetFilters = useCallback(() => {
    localStorage.removeItem('@Gstor:contributorFilterOptions')
    setFilterOptions({})
    setNumberPage(1)
  }, [])

  async function deleteContributor(contributorId: string) {
    try {
      await api.delete(
        `/company/${selectedCompany}/collaborator/${contributorId}`,
      )

      refetch()
      message.success(translate('useListContributors.deleteSuccessMessage'))
    } catch (err: any) {
      ShowError(
        err.message,
        translate('useListContributors.deleteErrorMessage'),
        locale,
      )
    }
  }

  async function deleteContributors(contributorId: string) {
    try {
      await api.delete(
        `/company/${selectedCompany}/collaborator/${contributorId}`,
      )

      refetch()
    } catch (err: any) {
      ShowError(
        err.message,
        translate('useListContributors.deleteErrorMessage'),
        locale,
      )
    }
  }

  useEffect(() => {
    refetch()
  }, [selectedCompany, refetch])

  return (
    <ListContributorsContext.Provider
      value={{
        filterOptions,
        data,
        contributorsTotal,
        deleteContributor,
        deleteContributors,
        previous,
        next,
        setNumberPage,
        setPageSize,
        isLoading,
        isFetching,
        listContributorsRefetch: refetch,
        handleUpdateFilters,
        handleResetFilters,
        // allData,
        // isAllDataLoading,
        // isAllDataFetching,
        // allDataRefetch,
      }}
    >
      {children}
    </ListContributorsContext.Provider>
  )
}

export function useListContributors() {
  const context = useContext(ListContributorsContext)

  return context
}
