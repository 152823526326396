import { lighten } from 'polished'
import styled from 'styled-components'

export const Container = styled.div`
  .avatar-uploader > .ant-upload {
    width: 175px;
    height: 175px;
  }

  #btn_create_equipments {
    background: #3db065;
    border: 1px solid #3db065;
    color: white;

    &:hover {
      background: ${lighten(0.05, '#3DB065')};
      border: 1px solid ${lighten(0.1, '#3DB065')};
    }
  }
`

export const ContUpload = styled.div`
  padding: 30px 20px 20px 20px;
`

export const ContForm = styled.div`
  flex: 1;
  margin-top: 40px;
`
