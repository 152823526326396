export default {
  pt: {
    EquipmentsCategory: {
      categoryColumn: 'Categoria',
      actionsColumn: 'Ações',
      deletePopconfirm: 'Deseja mesmo deletar a categoria?',
      deleteTooltip: 'Deletar',
    },
  },
  en: {
    EquipmentsCategory: {
      categoryColumn: 'Category',
      actionsColumn: 'Actions',
      deletePopconfirm: 'Do you really want to delete the category?',
      deleteTooltip: 'Delete',
    },
  },
  es: {
    EquipmentsCategory: {
      categoryColumn: 'Categoria',
      actionsColumn: 'Acciones',
      deletePopconfirm: '¿Realmente desea eliminar la categoría?',
      deleteTooltip: 'Borrar',
    },
  },
}
