/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react'

import {
  Drawer,
  Form,
  Button,
  Col,
  Row,
  Input,
  DatePicker,
  Tooltip,
} from 'antd'
import { CloseOutlined, FilterOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'

import { useSkills } from '~/hooks/Skills/useSkills'

import { Container } from './styles'
import { useScaleListTask } from '~/hooks/Tasks/Create/scaleListTask'
import { translate } from '~/utils/locale'

const ScalesFilterModal: React.FC = () => {
  const [visible, setVisible] = useState(false)

  const [form] = Form.useForm()

  const { skillsData } = useSkills()
  const { handleUpdateFilters, handleResetFilters, filterOptions } =
    useScaleListTask()

  useEffect(() => {
    form.setFieldsValue({
      title: filterOptions?.title,
      toDay: dayjs(filterOptions?.toDay),
      fromDay: dayjs(filterOptions?.fromDay),
    })
  }, [skillsData, filterOptions, form])

  const showDrawer = () => {
    setVisible(true)
  }

  const onClose = () => {
    setVisible(false)
  }

  const onFinish = (data: any) => {
    handleUpdateFilters(data)
    setVisible(false)
  }

  const onReset = () => {
    handleResetFilters()
    form.resetFields()
    setVisible(false)
  }

  const isFilter = Object.keys(filterOptions).length !== 0

  return (
    <Container isFilter={isFilter}>
      <Button
        type="primary"
        onClick={showDrawer}
        id="btn_filter_scales_task"
        icon={
          !isFilter ? (
            <FilterOutlined />
          ) : (
            <Tooltip
              title={translate('TasksCreateModalScaleFilterModal.cleanFilter')}
            >
              <CloseOutlined onClick={onReset} />
            </Tooltip>
          )
        }
      >
        {isFilter
          ? translate('TasksCreateModalScaleFilterModal.filterAppliedText')
          : translate('TasksCreateModalScaleFilterModal.filterText')}
      </Button>
      <Drawer
        title={translate('TasksCreateModalScaleFilterModal.drawerTitle')}
        width={580}
        onClose={onClose}
        open={visible}
        style={{ paddingBottom: 80 }}
      >
        <Form layout="vertical" onFinish={onFinish} form={form}>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="title"
                label={translate('TasksCreateModalScaleFilterModal.titleLabel')}
              >
                <Input
                  placeholder={translate(
                    'TasksCreateModalScaleFilterModal.titlePlaceholder',
                  )}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="fromDay"
                label={translate(
                  'TasksCreateModalScaleFilterModal.fromDayLabel',
                )}
                style={{ width: '100%' }}
              >
                <DatePicker
                  format="DD/MM/YYYY"
                  style={{ width: '100%' }}
                  placeholder={translate(
                    'TasksCreateModalScaleFilterModal.fromDayPlaceholder',
                  )}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="toDay"
                label={translate('TasksCreateModalScaleFilterModal.toDayLabel')}
                style={{ width: '100%' }}
              >
                <DatePicker
                  format="DD/MM/YYYY"
                  style={{ width: '100%' }}
                  placeholder={translate(
                    'TasksCreateModalScaleFilterModal.toDayPlaceholder',
                  )}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item style={{ width: '100%' }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: '100%' }}
                >
                  {translate('TasksCreateModalScaleFilterModal.applyButton')}
                </Button>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item style={{ width: '100%' }}>
                <Button onClick={onReset} style={{ width: '100%' }}>
                  {translate('TasksCreateModalScaleFilterModal.cleanButton')}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </Container>
  )
}

export default ScalesFilterModal
