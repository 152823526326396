/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  useRef,
  useCallback,
  ChangeEvent,
  useState,
  useEffect,
} from 'react'
import { useHistory, useParams, Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import * as Yup from 'yup'
import { FiLock } from 'react-icons/fi'

import { Result, Button as BtnBackLogin } from 'antd'

import { Container, Content, AnimationContainer, Background } from './styles'

import Input from '~/components/Login/Input'
import Button from '~/components/Login/Button'

import api from '~/services/api'

import { useLocale } from '~/hooks/locale/useLocale'

import LogoGestor from '~/assets/logo_icon.svg'

import getValidationErrors from '~/utils/getValidationErrors'
import { ShowError } from '~/utils/errors/apiErrors'
import { translate } from '~/utils/locale'

interface ResetPasswordFormData {
  password: string
  passwordConfirmation: string
}

interface ParamTypes {
  token: string
}

const ResetPassword: React.FC = () => {
  const [password, setPassword] = useState('DefaultValue1')
  const [passwordConfirmation, setConfirmPassword] = useState('DefaultValue1')
  const [loading, setLoading] = useState(false)

  const formRef = useRef<FormHandles>(null)

  const history = useHistory()
  const { locale } = useLocale()

  const { token } = useParams<ParamTypes>()

  const schema = Yup.object().shape({
    password: Yup.string()
      .required(translate('ResetPassword.passwordYup.required'))
      .matches(
        /^.*((?=.*[!@#$%^&*()\-_=+{};:,<.>A-Z]){1}).*$/,
        translate('ResetPassword.passwordYup.validationOne'),
      )
      .matches(
        /^.*(?=.*\d).*$/,
        translate('ResetPassword.passwordYup.validationTwo'),
      )
      .matches(
        /^.*((?=.*[a-z]){1}).*$/,
        translate('ResetPassword.passwordYup.validationThree'),
      )
      .matches(
        /^.*(?=.{8,}).*$/,
        translate('ResetPassword.passwordYup.validationFour'),
      ),
    passwordConfirmation: Yup.string()
      .nullable()
      .oneOf(
        [Yup.ref('password'), null],
        translate('ResetPassword.passwordConfirmationYup.validation'),
      ),
  })

  useEffect(() => {
    ;(async () => {
      const data = { password, password_confirmation: passwordConfirmation }

      try {
        formRef.current?.setErrors({})

        await schema.validate(data, {
          abortEarly: false,
        })
      } catch (error: any) {
        const errors = getValidationErrors(error)

        formRef.current?.setErrors(errors)
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [password, passwordConfirmation])

  const handleSubmit = useCallback(
    async (data: ResetPasswordFormData) => {
      try {
        setLoading(true)
        formRef.current?.setErrors({})

        await schema.validate(data, {
          abortEarly: false,
        })

        if (!token) {
          throw new Error()
        }

        await api.post('password/reset', {
          password,
          password_confirmation: passwordConfirmation,
          token,
        })

        history.push('/')
      } catch (err: any) {
        ShowError(err.message, translate('ResetPassword.errorMessage'), locale)
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err)
          formRef.current?.setErrors(errors)
        }
      } finally {
        setLoading(false)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [history, schema],
  )

  return (
    <Container>
      <Helmet>
        <title>{translate('ResetPassword.helmetTitle')}</title>
      </Helmet>
      <Content>
        <AnimationContainer>
          {token === undefined ? (
            <Result
              status="error"
              title={translate('ResetPassword.resultTitle')}
              subTitle={translate('ResetPassword.resultSubTitle')}
              extra={
                <Link to="/">
                  <BtnBackLogin type="primary">
                    {translate('ResetPassword.resultBackText')}
                  </BtnBackLogin>
                </Link>
              }
            />
          ) : (
            <Form ref={formRef} onSubmit={handleSubmit}>
              <img src={LogoGestor} alt="Logo Gestor" />

              <h1>{translate('ResetPassword.title')}</h1>
              <p>{translate('ResetPassword.subTitle')}</p>

              <Input
                name="password"
                icon={FiLock}
                type="password"
                placeholder={translate('ResetPassword.passwordPlaceholder')}
                isPassword
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setPassword(e.target.value)
                }}
              />

              <Input
                name="passwordConfirmation"
                icon={FiLock}
                type="password"
                placeholder={translate(
                  'ResetPassword.passwordConfirmationPlaceholder',
                )}
                isPassword
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setConfirmPassword(e.target.value)
                }}
              />

              <Button loading={loading} type="submit">
                {translate('ResetPassword.buttonText')}
              </Button>
            </Form>
          )}
        </AnimationContainer>
      </Content>
      <Background />
    </Container>
  )
}

export default ResetPassword
