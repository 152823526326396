/* eslint-disable @typescript-eslint/no-explicit-any */
import { Col, Form, Input, Row, Space, Typography } from 'antd'
import React, { useEffect } from 'react'
import HelpTooltip from '~/components/DataManipulation/Misc/HelpTooltip'
import { useParentCustomerFields } from '~/hooks/Clients/useParentCustomerFields'
import TaskTypeModalParentCustomer from '../Modal/TaskType'
import MoneyInput from '~/components/DataManipulation/Misc/MoneyInput'
import { translate } from '~/utils/locale'

// import { Container } from './styles';

interface FinancialCreateParentClientsProps {
  form: any
}

const CreateParentClientsFinancial: React.FC<
  FinancialCreateParentClientsProps
> = ({ form }) => {
  const {
    setTaskValue,
    amountHours,
    setAmountHours,
    setAmountHoursAdditional,
    numberKm,
    setNumberKm,
    setNumberKmAdditional,
    taskTypeData,
  } = useParentCustomerFields()

  useEffect(() => {
    if (taskTypeData?.id) {
      form.setFieldsValue({ task_value: taskTypeData?.amount_to_pay })
    }
  }, [form, taskTypeData])

  const handleTaskValue = (valueInCents: number | undefined) => {
    setTaskValue(valueInCents)
  }

  const handleHourValue = (valueInCents: number | undefined) => {
    setAmountHoursAdditional(valueInCents)
  }

  const handleKmValue = (valueInCents: number | undefined) => {
    setNumberKmAdditional(valueInCents)
  }

  return (
    <>
      <div style={{ marginBottom: '1.5rem' }}>
        <Typography.Title level={5}>
          {translate('ClientsPrincipalFinancialCreate.financialText')}
        </Typography.Title>
        <Typography.Text>
          {translate('ClientsPrincipalFinancialCreate.financialDescription')}
        </Typography.Text>
      </div>
      <Row>
        <Col span={12}>
          <Form.Item
            label={
              <Space>
                {translate('ClientsPrincipalFinancialCreate.valueLabel')}
                <TaskTypeModalParentCustomer />
              </Space>
            }
            name="task_value"
            rules={[
              {
                required: true,
                message: translate('ClientsPrincipalFinancialCreate.valueRule'),
              },
            ]}
          >
            <MoneyInput onChange={handleTaskValue} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={14}>
        <Col span={12}>
          <Form.Item
            label={translate('ClientsPrincipalFinancialCreate.hoursLabel')}
            name="amount_hours"
          >
            <Input
              placeholder={translate(
                'ClientsPrincipalFinancialCreate.hoursPlaceholder',
              )}
              maxLength={2}
              value={amountHours}
              onChange={(e) => setAmountHours(parseInt(e.target.value))}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={
              <Space>
                {translate('ClientsPrincipalFinancialCreate.valueHoursLabel')}
                <HelpTooltip
                  title={translate(
                    'ClientsPrincipalFinancialCreate.helpTooltipHour',
                  )}
                />
              </Space>
            }
            name="amount_hours_additional"
          >
            <MoneyInput onChange={handleHourValue} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={14}>
        <Col span={12}>
          <Form.Item
            label={translate('ClientsPrincipalFinancialCreate.kmLabel')}
            name="number_km"
          >
            <Input
              placeholder={translate(
                'ClientsPrincipalFinancialCreate.kmPlaceholder',
              )}
              value={numberKm}
              onChange={(e) => setNumberKm(parseInt(e.target.value))}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={
              <Space>
                {translate('ClientsPrincipalFinancialCreate.valueKmLabel')}
                <HelpTooltip
                  title={translate(
                    'ClientsPrincipalFinancialCreate.helpTooltipKm',
                  )}
                />
              </Space>
            }
            name="number_km_additional"
          >
            <MoneyInput onChange={handleKmValue} />
          </Form.Item>
        </Col>
      </Row>
    </>
  )
}

export default CreateParentClientsFinancial
