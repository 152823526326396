export default {
  pt: {
    EvaluationModal: {
      createSuccessMessage: 'Avaliação criada com sucesso!',
      createErrorMessage: 'Erro ao criar avaliação.',
      updateSuccessMessage: 'Avaliação atualizada com sucesso!',
      updateErrorMessage: 'Erro ao atualizar avaliação.',
      editTooltip: 'Editar Avaliação',
      createButton: 'Nova Avaliação de Atendimento',
      editTitle: 'Editar Avaliação de Atendimento',
      createTitle: 'Nova Avaliação de Atendimento',
      evaluationLabel: 'Avaliação',
      descriptionLabel: 'Descrição',
    },
  },
  en: {
    EvaluationModal: {
      createSuccessMessage: 'Rating created successfully!',
      createErrorMessage: 'Error creating rating.',
      updateSuccessMessage: 'Rating successfully updated!',
      updateErrorMessage: 'Error updating rating.',
      editTooltip: 'Edit Rating',
      createButton: 'New Service Rating',
      editTitle: 'Edit Service Rating',
      createTitle: 'New Service Rating',
      evaluationLabel: 'Rating',
      descriptionLabel: 'Description',
    },
  },
  es: {
    EvaluationModal: {
      createSuccessMessage: '¡Revisión creada con éxito!',
      createErrorMessage: 'Error al crear la evaluación.',
      updateSuccessMessage: '¡Revisión actualizada con éxito!',
      updateErrorMessage: 'Error al actualizar la reseña.',
      editTooltip: 'Editar calificación',
      createButton: 'Valoración de nuevos servicios',
      editTitle: 'Editar calificación de servicio',
      createTitle: 'Valoración de nuevos servicios',
      evaluationLabel: 'Evaluación',
      descriptionLabel: 'Descripción',
    },
  },
}
