export default {
  pt: {
    useTemplate: {
      getErrorMessage: 'Erro ao visualizar template.',
    },
  },
  en: {
    useTemplate: {
      getErrorMessage: 'Error viewing template.',
    },
  },
  es: {
    useTemplate: {
      getErrorMessage: 'Error al ver la plantilla.',
    },
  },
}
