export default {
  pt: {
    EquipmentsTemplateUpdate: {
      editTooltip: 'Editar',
      modalTitle: 'Editar Template',
      closeButton: 'Fechar',
      saveButton: 'Salvar',
      nameLabel: 'Nome do Template',
      namePlaceholder: 'Informe o nome do template',
      deleteTooltip: 'Deletar atributo',
      attributeLabel: 'Atributo',
      attributePlaceholder: 'Informe o atributo',
      valueLabel: 'Valor',
      valuePlaceholder: 'Informe um valor',
      addAttribute: 'Adicionar Atributo',
    },
  },
  en: {
    EquipmentsTemplateUpdate: {
      editTooltip: 'Edit',
      modalTitle: 'Edit Template',
      closeButton: 'Close',
      saveButton: 'Save',
      nameLabel: 'Template Name',
      namePlaceholder: 'Enter the name of the template',
      deleteTooltip: 'Delete attribute',
      attributeLabel: 'Attribute',
      attributePlaceholder: 'Inform the attribute',
      valueLabel: 'Value',
      valuePlaceholder: 'Enter a value',
      addAttribute: 'Add Attribute',
    },
  },
  es: {
    EquipmentsTemplateUpdate: {
      editTooltip: 'Editar',
      modalTitle: 'Editar plantilla',
      closeButton: 'Cercar',
      saveButton: 'Guardar',
      nameLabel: 'Nombre de la plantilla',
      namePlaceholder: 'Introduce el nombre de la plantilla.',
      deleteTooltip: 'Eliminar atributo',
      attributeLabel: 'Atributo',
      attributePlaceholder: 'Informar el atributo',
      valueLabel: 'Valor',
      valuePlaceholder: 'Introduce un valor',
      addAttribute: 'Agregar atributo',
    },
  },
}
