import axios from 'axios'

const validateWhatsappApi = axios.create({
  baseURL: 'https://wpp.zapform.com.br/clients/',
  headers: {
    Authorization:
      'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2NzgyMDgzOTcsInN1YiI6IjYxZmFjMGU4NzM5N2JjZGMxYzk2NjIxMSJ9.RIofUF6Up7BSgaa9MB4L1ugxIoNIhlTUvKDSf3l9rco',
  },
})

export default validateWhatsappApi
