/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-one-expression-per-line */
import { Card, Tabs } from 'antd'
import type { TabsProps } from 'antd'
import React, { useEffect, useState } from 'react'
import { TaskListProps, useListTask } from '~/hooks/Tasks/useListTasks'
import {
  ListContributorsData,
  useListContributors,
} from '~/hooks/Contributors/useListContributors'
import { LoadingIndicator } from './styles'
import { LoadingOutlined } from '@ant-design/icons'

interface MappedDataType extends ListContributorsData {
  tasks: TaskListProps[]
}

const DashboardContributorsGraphs: React.FC = () => {
  const { tasks } = useListTask()
  const { data } = useListContributors()
  const [mappedData, setMappedData] = useState<MappedDataType[]>([])
  const [maxTasksContributor, setMaxTasksContributor] =
    useState<MappedDataType | null>(null)
  const [maxEvaluationContributor, setMaxEvaluationContributor] =
    useState<MappedDataType | null>(null)

  useEffect(() => {
    function mapContributorsAndTeamsToTasks(
      contributors: ListContributorsData[],
      tasks: TaskListProps[],
    ) {
      let maxTasks = 0
      let maxEvaluation = 0

      const contributorsAndTeamsMap = new Map(
        contributors.map((contributor) => [
          contributor.id,
          { ...contributor, tasks: [] as TaskListProps[] },
        ]),
      )

      tasks.forEach((task) => {
        const responsible = contributorsAndTeamsMap.get(task.responsible.id)
        if (responsible) {
          responsible.tasks.push(task)

          // Check if this contributor has the most tasks
          if (responsible.tasks.length > maxTasks) {
            maxTasks = responsible.tasks.length
            setMaxTasksContributor(responsible)
          }

          // Check if this contributor has the highest evaluation
          if (responsible.evaluation > maxEvaluation) {
            maxEvaluation = responsible.evaluation
            setMaxEvaluationContributor(responsible)
          } else if (responsible.evaluation === maxEvaluation) {
            // If the evaluations are equal, check the number of tasks
            setMaxEvaluationContributor((prev) => {
              if (!prev || responsible.tasks.length > prev.tasks.length) {
                return responsible
              } else {
                return prev
              }
            })
          }
        }
      })

      setMappedData(Array.from(contributorsAndTeamsMap.values()))
    }

    if (data && tasks) {
      mapContributorsAndTeamsToTasks(data, tasks)
    }
  }, [data, tasks])

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Grafico Pizza',
      children:
        tasks && tasks.length > 0 ? (
          'yay'
        ) : (
          <LoadingIndicator>
            <LoadingOutlined style={{ fontSize: 64, margin: 'auto' }} spin />
          </LoadingIndicator>
        ),
    },
  ]

  return (
    <Card
      title="Dashboard Colaboradores"
      bordered={false}
      style={{ width: '100%', height: '100%', padding: 30 }}
    >
      <Tabs defaultActiveKey="1" items={items} />
    </Card>
  )
}

export default DashboardContributorsGraphs
