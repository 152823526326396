export default {
  pt: {
    ScalesTeamsTable: {
      titleColumn: 'Nome da Equipe',
      managerColumn: 'Gerente',
      actionsColumn: 'Ações',
      infoText:
        'Para adicionar equipes na escala é necessário que os mesmos estejam cadastrados no sistema.',
      addButton: 'Adicionar equipes',
    },
  },
  en: {
    ScalesTeamsTable: {
      titleColumn: 'Team Name',
      managerColumn: 'Manager',
      actionsColumn: 'Actions',
      infoText:
        'To add teams to the scale, they must be registered in the system.',
      addButton: 'Add Teams',
    },
  },
  es: {
    ScalesTeamsTable: {
      titleColumn: 'Nombre del equipo',
      managerColumn: 'Gerente',
      actionsColumn: 'Acciones',
      infoText:
        'Para agregar equipos a la escala, estos deben estar registrados en el sistema.',
      addButton: 'Agregar equipos',
    },
  },
}
