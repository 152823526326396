export default {
  pt: {
    ResetPassword: {
      passwordYup: {
        required: 'Insira sua senha',
        validationOne:
          'A senha precisa ter 1 Letra Maiúscula ou 1 Caractere especial.',
        validationTow: 'A senha precisa ter 1 dígito.',
        validationThree: 'A senha precisa ter uma letra minúscula.',
        validationFour: 'A senha precisa ter no mínimo 8 caracteres.',
      },
      passwordConfirmationYup: {
        validation: 'As senhas precisam ser iguais.',
      },
      errorMessage: 'Erro ao resetar a senha.',
      helmetTitle: 'Resetar Senha',
      resultTitle: 'Algo deu errado.',
      resultSubTitle: 'Não foi possível fazer a troca de senha',
      resultBackText: 'Voltar ao login',
      title: 'Resetar Senha',
      subTitle: 'Insira uma nova senha.',
      passwordPlaceholder: 'Nova Senha',
      passwordConfirmationPlaceholder: 'Confirmar senha',
      buttonText: 'Alterar senha',
    },
  },
  en: {
    ResetPassword: {
      passwordYup: {
        required: 'Enter your password',
        validationOne:
          'The password must have 1 Capital Letter or 1 Special Character.',
        validationTow: 'The password must be 1 digit.',
        validationThree: 'The password must be a lowercase letter.',
        validationFour: 'The password must be at least 8 characters long.',
      },
      passwordConfirmationYup: {
        validation: 'Passwords must be the same.',
      },
      errorMessage: 'Error resetting password.',
      helmetTitle: 'Reset password',
      resultTitle: 'Something went wrong.',
      resultSubTitle: 'Unable to change password.',
      resultBackText: 'Back to login',
      title: 'Reset password',
      subTitle: 'Enter a new password.',
      passwordPlaceholder: 'New password',
      passwordConfirmationPlaceholder: 'Confirm Password',
      buttonText: 'Change Password',
    },
  },
  es: {
    ResetPassword: {
      passwordYup: {
        required: 'Ingresa tu contraseña',
        validationOne:
          'La contraseña debe tener 1 letra mayúscula o 1 carácter especial.',
        validationTow: 'La contraseña debe ser de 1 dígito.',
        validationThree: 'La contraseña debe ser una letra minúscula.',
        validationFour: 'La contraseña debe tener al menos 8 caracteres.',
      },
      passwordConfirmationYup: {
        validation: 'Las contraseñas deben ser las mismas.',
      },
      errorMessage: 'Error al restablecer la contraseña.',
      helmetTitle: 'Restablecer la contraseña',
      resultTitle: 'Algo salió mal.',
      resultSubTitle: 'No se puede cambiar la contraseña',
      resultBackText: 'Atrás para iniciar sesión',
      title: 'Restablecer la contraseña',
      subTitle: 'Introduzca una nueva contraseña.',
      passwordPlaceholder: 'Nueva contraseña',
      passwordConfirmationPlaceholder: 'Confirmar seña',
      buttonText: 'Cambiar contraseña',
    },
  },
}
