import { LoadingOutlined } from '@ant-design/icons'
import { Card, Space } from 'antd'
import Text from 'antd/lib/typography/Text'
import React from 'react'
import { ListContributorsProvider } from '~/hooks/Contributors/useListContributors'
import { useReportTasks } from '~/hooks/Report/Tasks/useReportTasks'
import { TaskTypeProvider } from '~/hooks/Tasks/TaskType/useTaskType'
import { translate } from '~/utils/locale'
import ExportReportTask from './Export'
import FilterReportTasks from './Filter'
import GraphicsReportTask from './Graphics'
import TableReportTask from './Table'

// import { Container } from './styles';

const TasksReportBoard: React.FC = () => {
  const { isFetching, isLoading, reportTasks } = useReportTasks()

  return (
    <Card
      title={
        <Space size="large">
          <b>{translate('ReportTasksComponent.cardTitle')}</b>
          {isLoading ? (
            <LoadingOutlined spin />
          ) : (
            <Text
              type="secondary"
              style={{ fontWeight: 'normal', fontSize: 14 }}
            >
              {reportTasks?.results?.length === 1
                ? `${translate('ReportTasksComponent.totalText')} ${reportTasks
                    ?.results?.length} ${translate(
                    'ReportTasksComponent.reportText',
                  )}`
                : `${translate('ReportTasksComponent.totalText')} ${reportTasks
                    ?.results?.length} ${translate(
                    'ReportTasksComponent.reportsText',
                  )}`}
            </Text>
          )}
          {!isLoading && isFetching && <LoadingOutlined spin />}
        </Space>
      }
      extra={[
        <Space key="extaProps">
          <ExportReportTask />
          <FilterReportTasks />
        </Space>,
      ]}
      bordered={false}
      style={{ width: '100%', padding: 30 }}
    >
      <ListContributorsProvider>
        <TaskTypeProvider>
          <GraphicsReportTask />
          <TableReportTask />
        </TaskTypeProvider>
      </ListContributorsProvider>
    </Card>
  )
}

export default TasksReportBoard
