export default {
  pt: {
    UsersTable: {
      dateString: 'às',
      usernameColumn: 'Nome de usuário',
      emailColumn: 'E-mail',
      contactColumn: 'Contato',
      initialTimeColumn: 'Criado em',
      actionsColumn: 'Ações',
      viewTooltip: 'Visualizar Usuário',
      deletePopconfirm: 'Deseja mesmo deletar o usuário?',
      deleteTooltip: 'Deletar',
    },
  },
  en: {
    UsersTable: {
      dateString: 'at',
      usernameColumn: 'User name',
      emailColumn: 'Email',
      contactColumn: 'Contact',
      initialTimeColumn: 'Created at',
      actionsColumn: 'Actions',
      viewTooltip: 'View User',
      deletePopconfirm: 'Do you really want to delete the user?',
      deleteTooltip: 'Delete',
    },
  },
  es: {
    UsersTable: {
      dateString: 'a las',
      usernameColumn: 'Nombre de usuario',
      emailColumn: 'Correo electrónico',
      contactColumn: 'Contacto',
      initialTimeColumn: 'Creado en',
      actionsColumn: 'Acciones',
      viewTooltip: 'Ver Usuario',
      deletePopconfirm: '¿Realmente desea eliminar el usuario?',
      deleteTooltip: 'Borrar',
    },
  },
}
