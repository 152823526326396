/* eslint-disable no-underscore-dangle */
import { LoadingOutlined } from '@ant-design/icons'
import { Space, Table, Tag } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import Text from 'antd/lib/typography/Text'
import { format } from 'date-fns'
import moment from 'moment'
import React from 'react'
import { useListContributors } from '~/hooks/Contributors/useListContributors'
import { useReportTasks } from '~/hooks/Report/Tasks/useReportTasks'
import { useTeams } from '~/hooks/Teams/useListTeams'
import { translate } from '~/utils/locale'

import ModalReportTasks from '../Modal'

// import { Container } from './styles';

interface EquipmentsTasks {
  id: string
}

interface TaskTypeProps {
  _id: string
  name: string
}

interface Address {
  city: string
  id: string
  location: {
    x: number
    y: number
  }
  neighborhood: string
  number: number
  state: string
  street: string
  zip_code: string
  complement: string
}

interface Responsible {
  id: string
  type: string
}

interface Status {
  type: string
  created_at: Date
  collaborator_id: string
  location: {
    lat: number
    long: number
  }
}

interface DataProps {
  checkin_type: string
  createdAt: string
  date: string
  duration_time: string
  external_code: string
  is_enabled: string
  priority: string
  description: string
  current_status: string
  _id: string
  hours: string
  currentDate: string
  initialTime: string
  task_type_id: TaskTypeProps
  equipments: EquipmentsTasks[]
  address: Address
  responsible: Responsible
  status: Status[]
}

const TableReportTask: React.FC = () => {
  const { reportTasks, isFetching } = useReportTasks()
  const { data } = useListContributors()
  const { teams } = useTeams()

  const reportTaskFormatted = reportTasks?.results.map(
    (taskStatus: DataProps) => {
      const dataFormat = {
        ...taskStatus,
        initialTime: format(
          new Date(taskStatus.createdAt),
          `dd/MM/yyyy '${translate('ReportTasksTable.dateString')}' HH:mm'h'`,
        ),
      }

      return dataFormat
    },
  )

  const columns: ColumnsType<DataProps> = [
    {
      title: translate('ReportTasksTable.responsibleColumn'),
      key: 'responsible',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (record) => (
        <>
          {record.responsible?.type === 'collaborator' ? (
            <>
              {data?.map((d) => (
                <div key={d.id}>
                  {d.id === record.responsible.id ? (
                    <Text>{d.contact.name}</Text>
                  ) : (
                    ''
                  )}
                </div>
              ))}
            </>
          ) : (
            <>
              {teams?.map((t) => (
                <div key={t.id}>
                  {t.id === record.responsible?.id ? (
                    <Text>{t?.title}</Text>
                  ) : (
                    ''
                  )}
                </div>
              ))}
            </>
          )}
        </>
      ),
    },
    {
      title: translate('ReportTasksTable.initialTimeColumn'),
      dataIndex: 'initialTime',
      key: 'date',
      sorter: (a: DataProps, b: DataProps) =>
        moment(a.createdAt).unix() - moment(b.createdAt).unix(),
    },
    {
      title: translate('ReportTasksTable.taskTypeColumn'),
      key: 'taskType',
      dataIndex: ['task_type_id', 'title'],
    },
    {
      title: translate('ReportTasksTable.statusColumn'),
      key: 'status',
      render: (record) => (
        <>
          {record.current_status === 'received' ? (
            <Tag color="green">
              {translate('ReportTasksTable.statusReceived')}
            </Tag>
          ) : (
            ''
          )}
          {record.current_status === 'viewed' ? (
            <Tag color="green">
              {translate('ReportTasksTable.statusViewed')}
            </Tag>
          ) : (
            ''
          )}
          {record.current_status === 'en-route' ? (
            <Tag color="green">
              {translate('ReportTasksTable.statusEnRoute')}
            </Tag>
          ) : (
            ''
          )}
          {record.current_status === 'check-in' ? (
            <Tag color="green">
              {translate('ReportTasksTable.statusCheckIn')}
            </Tag>
          ) : (
            ''
          )}
          {record.current_status === 'pause-task' ? (
            <Tag color="red">
              {translate('ReportTasksTable.statusPauseTask')}
            </Tag>
          ) : (
            ''
          )}
          {record.current_status === 'resume-working' ? (
            <Tag color="green">
              {translate('ReportTasksTable.statusResumeWorking')}
            </Tag>
          ) : (
            ''
          )}
          {record.current_status === 'checkout' ? (
            <Tag color="green">
              {translate('ReportTasksTable.statusCheckout')}
            </Tag>
          ) : (
            ''
          )}
          {record.current_status === 'canceled' ? (
            <Tag color="gray">
              {translate('ReportTasksTable.statusCanceled')}
            </Tag>
          ) : (
            ''
          )}
          {record.current_status === 'finished' ? (
            <Tag color="geekblue">
              {translate('ReportTasksTable.statusFinished')}
            </Tag>
          ) : (
            ''
          )}
        </>
      ),
    },
    {
      title: translate('ReportTasksTable.actionsColumn'),
      key: 'remove',
      align: 'center',
      render: (record) => (
        <Space size="middle">
          <ModalReportTasks dataReport={record} />
        </Space>
      ),
    },
  ]

  return (
    <Table
      rowKey={(record) => record._id}
      loading={{
        indicator: <LoadingOutlined />,
        spinning: isFetching,
        size: 'large',
      }}
      columns={columns}
      dataSource={reportTaskFormatted}
      scroll={{ x: 1000 }}
      pagination={{
        showTotal: (total, range) => `${range[0]} - ${range[1]} / ${total}`,
      }}
    />
  )
}

export default TableReportTask
