import styled from 'styled-components'

export const Container = styled.div`
  .custom-last-row {
    .ant-table-cell:last-child {
      background-color: #fafafa;
      font-weight: bold; /* Aplica negrito à última coluna */
    }
  }
`
