export default {
  pt: {
    TasksRegistrationFormsTable: {
      formColumn: 'Formulário',
      descriptionColumn: 'Descrição',
      actionsColumn: 'Ações',
      deletePopconfirm: 'Deseja mesmo deletar o formulário?',
      deleteTooltip: 'Deletar',
    },
  },
  en: {
    TasksRegistrationFormsTable: {
      formColumn: 'Form',
      descriptionColumn: 'Description',
      actionsColumn: 'Actions',
      deletePopconfirm: 'Do you really want to delete the form?',
      deleteTooltip: 'Delete',
    },
  },
  es: {
    TasksRegistrationFormsTable: {
      formColumn: 'Forma',
      descriptionColumn: 'Descripción',
      actionsColumn: 'Acciones',
      deletePopconfirm: '¿Realmente desea eliminar el forma?',
      deleteTooltip: 'Borrar',
    },
  },
}
