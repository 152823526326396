/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable consistent-return */
import React, { useContext, createContext, useState } from 'react'
import { useQuery } from 'react-query'

import api from '~/services/api'

import { useCompanies } from '~/hooks/Companies/useCompanies'
import { useLocale } from '~/hooks/locale/useLocale'

import { ShowError } from '~/utils/errors/apiErrors'
import { translate } from '~/utils/locale'

interface TeamsTasksProps {
  _id: string
  company_id: string
  title: string
  tasks: [
    {
      task_id: string
      internal_code: string
      financialReceive?: {
        receive?: number
        amount_hours_receive: number
        amount_hours_additional_receive?: number
        number_km_receive: number
        number_km_additional_receive?: number
      }
    },
  ]
}

interface TeamsTasksContextData {
  teams?: TeamsTasksProps[]
  isLoading: boolean
  isFetching: boolean
  refetch: () => void
  teamsTotal?: number
}

const TeamsTasksContext = createContext<TeamsTasksContextData>(
  {} as TeamsTasksContextData,
)

export const TeamsTasksProvider: React.FC = ({ children }) => {
  const { selectedCompany } = useCompanies()
  const { locale } = useLocale()

  const [teamsTotal, setTeamsTotal] = useState<number>()

  const {
    data: teams,
    isLoading,
    isFetching,
    refetch,
  } = useQuery(
    [`teams list task${selectedCompany}`, selectedCompany],

    async () => {
      try {
        const response = await api.get(
          `/company/${selectedCompany}/teams-tasks`,
        )

        const { data } = response

        setTeamsTotal(data.total)

        return data.results
      } catch (err: any) {
        ShowError(
          err.message,
          translate('useListTeams.getErrorMessage'),
          locale,
        )
      }
    },
  )

  return (
    <TeamsTasksContext.Provider
      value={{
        teams,
        isLoading,
        isFetching,
        refetch,
        teamsTotal,
      }}
    >
      {children}
    </TeamsTasksContext.Provider>
  )
}

export function useTasksTeams(): TeamsTasksContextData {
  const context = useContext(TeamsTasksContext)

  if (!context) {
    throw new Error('useAuth must be used within an AutoProvider')
  }

  return context
}
