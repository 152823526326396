export default {
  pt: {
    ContributorWorkday: {
      successMessage: 'Colaborador Atualizado com sucesso!',
      errorMessage: 'Erro ao realizar atualização',
      cardTitle: 'Jornada de Trabalho',
      saveButton: 'Salvar',
      workdayRule: 'É necessário criar ao menos um horário.',
      weekdaysLabel: 'Dias da semana',
      weekdaysRule: 'Selecione os dias da semana',
      sunday: 'Domingo',
      monday: 'Segunda-Feira',
      tuesday: 'Terça-Feira',
      wednesday: 'Quarta-Feira',
      thursday: 'Quinta-Feira',
      friday: 'Sexta-Feira',
      saturday: 'Sábado',
      serviceHoursLabel: 'Jornada de Trabalho',
      serviceHoursRule: 'Por favor insira o horário de serviço',
      lunchHoursLabel: 'Horário de Almoço',
      lunchHoursRule: 'Por favor insira o horário de almoço',
      addHourButton: 'Adicionar Horário',
    },
  },
  en: {
    ContributorWorkday: {
      successMessage: 'Contributor Updated successfully!',
      errorMessage: 'Error performing update',
      cardTitle: 'Workday',
      saveButton: 'Save',
      workdayRule: 'You must create at least one schedule.',
      weekdaysLabel: 'Days of the week',
      weekdaysRule: 'Select the days of the week',
      sunday: 'Sunday',
      monday: 'Monday',
      tuesday: 'Tuesday',
      wednesday: 'Wednesday',
      thursday: 'Thursday',
      friday: 'Friday',
      saturday: 'Saturday',
      serviceHoursLabel: 'Workday',
      serviceHoursRule: 'Please enter service hours',
      lunchHoursLabel: 'Lunch time',
      lunchHoursRule: 'Please enter lunch time',
      addHourButton: 'Add Time',
    },
  },
  es: {
    ContributorWorkday: {
      successMessage: 'Colaborador ¡Actualizado con éxito!',
      errorMessage: 'Error al realizar la actualización',
      cardTitle: 'Jornada de trabajo',
      saveButton: 'Guardar',
      workdayRule: 'Debe crear al menos un horario.',
      weekdaysLabel: 'Dias de la semana',
      weekdaysRule: 'Seleccione los días de la semana',
      sunday: 'Domingo',
      monday: 'Lunes',
      tuesday: 'Martes',
      wednesday: 'Miércoles',
      thursday: 'Jueves',
      friday: 'Viernes',
      saturday: 'Sábado',
      serviceHoursLabel: 'Jornada de trabajo',
      serviceHoursRule: 'Por favor ingrese las horas de servicio',
      lunchHoursLabel: 'Horario de almuerzo',
      lunchHoursRule: 'Por favor ingrese la hora del almuerzo',
      addHourButton: 'Agregar tiempo',
    },
  },
}
