/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable consistent-return */
import React, { useContext, createContext, useState } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'

import { message } from 'antd'

import api from '~/services/api'

import { useCompanies } from '~/hooks/Companies/useCompanies'
import { useLocale } from '~/hooks/locale/useLocale'

import { ShowError } from '~/utils/errors/apiErrors'
import { translate } from '~/utils/locale'

interface contribuitorsTeams {
  collaborator: {
    id: string
    contact: {
      id: string
      name: string
    }
  }
}

interface Managers {
  id: string
  contact: {
    id: string
    name: string
  }
}

interface TeamsProps {
  title: string
  id: string
  created_at: Date
  manager: Managers
  description: string
  contributorsTeams: contribuitorsTeams[]
  payment_day: string
  payment_conditions?: string
  payment_method?: string
  number_bank?: string
  name_bank?: string
  personal_bank?: string
  agency_bank?: string
  account_bank?: string
  amount_to_receive?: number
  type_account_bank?: string
  key_pix?: string
  amount_hours_receive?: number
  amount_hours_additional_receive?: number
  number_km_receive?: number
  number_km_additional_receive?: number
}

interface TeamContextData {
  team?: TeamsProps
  isLoading: boolean
  refetch: () => void
  createTeamCollaborator(teamId?: string, collaboratorId?: string): void
  deleteTeamCollaborator(teamId?: string, collaboratorId?: string): void
  updateTeams(name: object | undefined, teamsId: string): void
  updateFinancialTeams(data: object | undefined): void
  setIsEdit(value: boolean): void
  isEdit?: boolean
}

const TeamContext = createContext<TeamContextData>({} as TeamContextData)

export const TeamProvider: React.FC = ({ children }) => {
  const { selectedCompany } = useCompanies()
  const { locale } = useLocale()
  const { teamId } = useParams<{ teamId: string }>()

  const [isEdit, setIsEdit] = useState<boolean>(false)

  const {
    data: team,
    isLoading,
    refetch,
  } = useQuery(
    [`Determinada equipe${selectedCompany}`, selectedCompany],

    async () => {
      try {
        const response = await api.get(
          `/company/${selectedCompany}/team/${teamId}`,
        )

        const { data } = response

        return data
      } catch (err: any) {
        ShowError(err.message, translate('useTeams.getErrorMessage'), locale)
      }
    },
  )

  async function createTeamCollaborator(
    teamsId: string,
    collaboratorId: string,
  ) {
    try {
      await api.post(
        `/company/${selectedCompany}/team/${teamId}/collaborator/${collaboratorId}`,
      )

      refetch()
      message.success(translate('useTeams.createSuccessMessage'))
    } catch (err: any) {
      ShowError(err.message, translate('useTeams.createErrorMessage'), locale)
    }
  }

  async function deleteTeamCollaborator(
    teamsId: string,
    collaboratorId: string,
  ) {
    try {
      await api.delete(
        `/company/${selectedCompany}/team/${teamId}/collaborator/${collaboratorId}`,
      )

      refetch()
      message.success(translate('useTeams.deleteSuccessMessage'))
    } catch (err: any) {
      ShowError(err.message, translate('useTeams.deleteErrorMessage'), locale)
    }
  }

  async function updateTeams(name: object, teamsId: string) {
    try {
      await api.put(`/company/${selectedCompany}/team/${teamsId}`, name)

      refetch()
      message.success(translate('useTeams.updateSuccessMessage'))
    } catch (err: any) {
      ShowError(err.message, translate('useTeams.updateErrorMessage'), locale)
    }
  }

  async function updateFinancialTeams(data: object) {
    try {
      await api.put(
        `/company/${selectedCompany}/team/${teamId}/financial`,
        data,
      )

      refetch()
      message.success(translate('useTeams.updateSuccessMessage'))
    } catch (err: any) {
      ShowError(err.message, translate('useTeams.updateErrorMessage'), locale)
    }
  }

  return (
    <TeamContext.Provider
      value={{
        team,
        isLoading,
        refetch,
        createTeamCollaborator,
        deleteTeamCollaborator,
        updateTeams,
        updateFinancialTeams,
        isEdit,
        setIsEdit,
      }}
    >
      {children}
    </TeamContext.Provider>
  )
}

export function useTeam(): TeamContextData {
  const context = useContext(TeamContext)

  if (!context) {
    throw new Error('useAuth must be used within an AutoProvider')
  }

  return context
}
