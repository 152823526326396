/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable func-names */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-nested-ternary */
import React, { useState } from 'react'
import { Link } from 'react-router-dom'

// import Highlighter from 'react-highlight-words';

import {
  Table,
  Tag,
  Space,
  Tooltip,
  Popconfirm,
  Button,
  Typography,
  Modal,
  Avatar,
} from 'antd'
import {
  FileUnknownOutlined,
  LoadingOutlined,
  PlusCircleOutlined,
  PlusOutlined,
  ReloadOutlined,
} from '@ant-design/icons'
import { ColumnsType } from 'antd/es/table'

import { translate } from '~/utils/locale'
import { useContributorsTask } from '~/hooks/Tasks/Create/useContributorTask'
import ContributorsTaskFilter from '../Filter'
import { useTaskCreate } from '~/hooks/Tasks/Create/createTask'
import { stringToHslColor } from '~/utils/stringToHslColor'
import { createLetterAvatar } from '~/utils/letter'

interface Company {
  id: string
  logo: string
  logo_url: string
  name: string
}

interface Contact {
  avatar: string
  avatar_url: string
  birth: string
  email: string
  gender: string
  id: string
  name: string
  personType: string
  phone: string
  formattedPhone?: string
  contactAddresses?: any[]
}

interface Status {
  color: string
  title: string
  id: string
  description: string
  type: string
}

interface Skill {
  id: string
  description: string
  name: string
}

interface Skills {
  description: string
  id: string
  level: string
  skill: Skill
}

interface Attachments {
  id: string
  title: string
  description: string
  attachment_url: string
  attachment: string
}

interface DataType {
  collaboratorAttachments: Attachments[]
  collaboratorSkills: Skills[]
  company: Company
  contact: Contact
  evaluation: number
  id: string
  status: Status
  distanceTask: number
  operating_range: number
}

interface ContributorsTaskTableProps {
  form: any
}

const ContributorsTaskTable: React.FC<ContributorsTaskTableProps> = ({
  form,
}) => {
  const { Text } = Typography

  const {
    contributorsTotal,
    setPageSize,
    setNumberPage,
    refetch,
    data,
    isFetching,
  } = useContributorsTask()
  const { contributorData, setContributorData } = useTaskCreate()

  const [isModalVisible, setIsModalVisible] = useState(false)

  function getDistanceFromLatLonInKm(position1: any, position2: any) {
    const deg2rad = function (deg: any) {
      return deg * (Math.PI / 180)
    }
    const R = 6371
    const dLat = deg2rad(position2.lat - position1.lat)
    const dLng = deg2rad(position2.lng - position1.lng)
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(position1.lat)) *
        Math.cos(deg2rad(position1.lat)) *
        Math.sin(dLng / 2) *
        Math.sin(dLng / 2)
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

    return ((R * c * 1000) / 1000).toFixed(1)
  }

  const addressInfo = data?.map((info) => {
    const formatData = {
      ...info,
      distanceTask: Number(
        getDistanceFromLatLonInKm(
          {
            lat: form.getFieldValue('latitude'),
            lng: form.getFieldValue('longitude'),
          },
          {
            lat: info.contact?.contactAddresses[0]?.address?.location.x,
            lng: info.contact?.contactAddresses[0]?.address?.location.y,
          },
        ),
      ),
    }

    return formatData
  })

  async function handleContributorTask(id: string, name: string) {
    await setContributorData({ id, name })
    setIsModalVisible(false)
  }

  const showModal = () => {
    setIsModalVisible(true)
    refetch()
  }

  const closeModal = () => {
    setIsModalVisible(false)
  }

  const columns: ColumnsType<DataType> = [
    {
      title: 'Ações',
      align: 'center',
      key: 'remove',
      render: (record) => (
        <Popconfirm
          title="Deseja mesmo adicionar o colaborador?"
          onConfirm={() =>
            handleContributorTask(record.id, record.contact.name)
          }
        >
          <Tooltip title="Adicionar colaborador">
            <Button size="small" shape="circle" icon={<PlusOutlined />} />
          </Tooltip>
        </Popconfirm>
      ),
    },
    {
      title: '',
      align: 'center',
      key: 'avatar',
      render: (record) => (
        <Avatar
          style={{
            background: stringToHslColor(`${record && record.contact.name}`),
          }}
        >
          {createLetterAvatar(`${record && record.contact.name}}`)}
        </Avatar>
      ),
    },
    {
      title: translate('ContributorTable.nameColumn'),
      align: 'left',
      dataIndex: ['contact', 'name'],
      key: 'name',
      render: (name: string, record) => (
        <Link
          target="_blank"
          to={`/colaboradores/visualizar/colaborador/${record.id}/contato/${record.contact.id}`}
        >
          {name}
        </Link>
      ),
    },
    {
      title: 'Distância',
      align: 'left',
      key: 'name',
      render: (name: string, record) => (
        <>
          {record.contact.contactAddresses?.length !== 0 ? (
            <Tag>{record.distanceTask} km de distância</Tag>
          ) : (
            <Tag>Nenhum endereço informado.</Tag>
          )}
        </>
      ),
    },
    {
      title: translate('ContributorTable.contactColumn'),
      dataIndex: ['contact', 'formattedPhone'],
      align: 'center',
      key: 'contact',
      render: (contact: string) => (
        <>
          {contact || (
            <Text type="secondary">
              <Space size="small">
                <FileUnknownOutlined />
                N/A
              </Space>
            </Text>
          )}
        </>
      ),
    },
    {
      title: translate('ContributorTable.skillsColumn'),
      align: 'center',
      key: 'collaboratorSkills',
      dataIndex: 'collaboratorSkills',
      render: (skills: Skills[]) => (
        <>
          {skills.length === 0 ? (
            <Text type="secondary">
              <Space size="small">
                <FileUnknownOutlined />
                N/A
              </Space>
            </Text>
          ) : (
            skills.map((skill) => {
              const color =
                skill.level === 'basic'
                  ? 'orange'
                  : skill.level === 'intermediary'
                    ? 'green'
                    : 'blue'
              return (
                <Tag color={color} key={skill.id}>
                  {skill.skill.name}
                </Tag>
              )
            })
          )}
        </>
      ),
    },
    {
      title: translate('ContributorTable.statusColumn'),
      dataIndex: ['status', 'title'],
      align: 'center',
      key: 'contact',
      render: (name: string, record) => (
        <>
          {record?.status ? (
            <Tag
              color={
                record.status?.color !== null
                  ? `${record.status?.color}`
                  : '#108ee9'
              }
            >
              {name}
            </Tag>
          ) : (
            <Tag color="default">
              {translate('ContributorTable.noStatusText')}
            </Tag>
          )}
        </>
      ),
    },
  ]

  return (
    <>
      {!contributorData?.id ? (
        <Button
          icon={<PlusCircleOutlined />}
          onClick={showModal}
          style={{ width: '100%' }}
        >
          Adicionar Colaborador
        </Button>
      ) : (
        <Button size="small" icon={<ReloadOutlined />} onClick={showModal}>
          Alterar Colaborador
        </Button>
      )}
      <Modal
        title="Adicionar Colaborador"
        open={isModalVisible}
        onOk={closeModal}
        onCancel={closeModal}
        width="900px"
        style={{
          top: 30,

          maxHeight: 'calc(100vh - 200px)',
        }}
        footer={[
          <Space key="FooterProps">
            <Button key="back" onClick={closeModal}>
              Fechar
            </Button>

            <ContributorsTaskFilter />
          </Space>,
        ]}
      >
        <Table
          rowKey={(record) => record.id}
          loading={{
            indicator: <LoadingOutlined />,
            spinning: isFetching,
            size: 'large',
          }}
          pagination={{
            total: contributorsTotal,
            showSizeChanger: true,
            pageSizeOptions: ['10', '20', '50', '100', '200'],
            defaultPageSize: 5,
            showTotal: (total, range) => `${range[0]} - ${range[1]} / ${total}`,
            onChange: (page, pageSize) => {
              setPageSize(pageSize!)
              setNumberPage(page)
            },
          }}
          columns={columns}
          size="small"
          dataSource={addressInfo}
          scroll={{ x: 1000 }}
        />
      </Modal>
    </>
  )
}

export default ContributorsTaskTable
