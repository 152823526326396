import { LoadingOutlined } from '@ant-design/icons'
import { Card, Space } from 'antd'
import Text from 'antd/lib/typography/Text'
import React from 'react'
import { useListStatus } from '~/hooks/Status/useListStatus'
import { translate } from '~/utils/locale'
import CreateStatus from './Create'
import StatusTable from './Table'

const StatusBoard: React.FC = () => {
  const { isLoading, isFetching, statusTotal } = useListStatus()

  return (
    <Card
      title={
        <Space size="large">
          <b>{translate('StatusComponent.cardTitle')}</b>
          {isLoading ? (
            <LoadingOutlined spin />
          ) : (
            <Text
              type="secondary"
              style={{ fontWeight: 'normal', fontSize: 14 }}
            >
              {statusTotal !== undefined
                ? `${translate(
                    'StatusComponent.totalText',
                  )} ${statusTotal} ${translate('StatusComponent.statusText')}`
                : ''}
            </Text>
          )}
          {!isLoading && isFetching && <LoadingOutlined spin />}
        </Space>
      }
      extra={<CreateStatus />}
      bordered={false}
      style={{ width: '100%', padding: 30 }}
    >
      <StatusTable />
    </Card>
  )
}

export default StatusBoard
