import { Form, Select } from 'antd'
import React from 'react'
import { IQuestion } from '~/hooks/Tasks/Forms/useTaskFormWithAnswers'

interface QuestionProps {
  data: IQuestion
  isEditing: boolean
}

const QuestionDropdownList: React.FC<QuestionProps> = ({ data, isEditing }) => {
  const defaultValue = isValidAnswerValue(data.answer?.value)
  return (
    <>
      {data.description && <h4>{data.description}</h4>}

      {isEditing ? (
        <Form.Item name={'value'} style={{ width: '100%' }}>
          <Select
            defaultValue={defaultValue}
            placeholder="Selecione uma das opções"
            options={
              data.options
                ? data.options.map((option) => ({
                    label: option,
                    value: option,
                  }))
                : []
            }
          />
        </Form.Item>
      ) : (
        <Select
          defaultValue={defaultValue}
          placeholder="Sem resposta"
          options={
            data.options
              ? data.options.map((option) => ({
                  label: option,
                  value: option,
                  disabled: true,
                }))
              : []
          }
        />
      )}
    </>
  )
}

const isValidAnswerValue = (
  value?: string | Array<string> | number | boolean | null,
): string[] | undefined => {
  if (
    typeof value === 'string' ||
    typeof value === 'number' ||
    (Array.isArray(value) && value.every((item) => typeof item === 'string'))
  ) {
    if (typeof value === 'string') {
      // Se value for uma string, retornar um array contendo essa string
      return [value]
    } else if (Array.isArray(value)) {
      // Se value for um array de strings, retornar o próprio array
      return value
    } else {
      // Se value for um número ou boolean, converter para string e retornar em um array
      return [String(value)]
    }
  }

  // Se não for um valor válido, retornar undefined
  return undefined
}

export default QuestionDropdownList
