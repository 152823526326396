export default {
  pt: {
    ShowTemplates: {
      helmetTitle: 'Templates de Especificação',
    },
  },
  en: {
    ShowTemplates: {
      helmetTitle: 'Specification Templates',
    },
  },
  es: {
    ShowTemplates: {
      helmetTitle: 'Plantillas de especificaciones',
    },
  },
}
