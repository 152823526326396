import React from 'react'

import {
  ExceptionOutlined,
  FileProtectOutlined,
  FileTextOutlined,
  FileZipOutlined,
} from '@ant-design/icons'
import { Container, Wrapper, Card } from './styles'

import { useReportTasks } from '~/hooks/Report/Tasks/useReportTasks'
import { translate } from '~/utils/locale'

const GraphicsReportTask: React.FC = () => {
  const { reportTasks } = useReportTasks()

  return (
    <Container>
      <Wrapper>
        <Card className="total">
          <FileProtectOutlined id="total" />
          <p>{translate('ReportTasksGraphics.totalLabel')}</p>
          <h1 id="totalText">{reportTasks?.graphic.total}</h1>
        </Card>
        <Card className="execution">
          <FileTextOutlined id="execution" />
          <p>{translate('ReportTasksGraphics.executionLabel')}</p>
          <h1>{reportTasks?.graphic.at_execution}</h1>
        </Card>
        <Card className="finished">
          <ExceptionOutlined id="finished" />
          <p>{translate('ReportTasksGraphics.finishedLabel')}</p>
          <h1>{reportTasks?.graphic.finished}</h1>
        </Card>
        <Card className="canceled">
          <FileZipOutlined id="canceled" />
          <p>{translate('ReportTasksGraphics.canceledLabel')}</p>
          <h1>{reportTasks?.graphic.canceled}</h1>
        </Card>
      </Wrapper>
    </Container>
  )
}

export default GraphicsReportTask
