/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable consistent-return */
import React, { useContext, createContext } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'

import { message } from 'antd'

import api from '~/services/api'

import { useCompanies } from '~/hooks/Companies/useCompanies'
import { useLocale } from '~/hooks/locale/useLocale'

import { ShowError } from '~/utils/errors/apiErrors'
import { translate } from '~/utils/locale'

interface PermissionsGroups {
  create: boolean
  delete: boolean
  edit: boolean
  view: boolean
  id: string
  module: {
    id: string
    name: string
  }
}

interface UserGroups {
  id: string
  group: {
    id: string
    name: string
    permissions: PermissionsGroups[]
  }
}

interface UserCompanies {
  id: string
  company: {
    id: string
    name: string
  }
}

interface UsersProps {
  id: string
  active: boolean
  created_at: Date
  email: string
  updated_at: Date
  initial_time: string
  username: string
  userGroups: UserGroups[]
  userCompanies: UserCompanies[]
}

interface UsersContextData {
  users?: UsersProps
  updateUsers(dataUser: object | undefined): void
  createUserGroup(group_id: string | undefined): void
  deleteUserGroup(group_id: string | undefined): void
  isLoading: boolean
  refetch: () => void
  isFetching: boolean
}

const UsersContext = createContext<UsersContextData>({} as UsersContextData)

export const UsersProvider: React.FC = ({ children }) => {
  const { selectedCompany } = useCompanies()
  const { locale } = useLocale()
  const { userId } = useParams<{ userId: string }>()

  const {
    data: users,
    isLoading,
    refetch,
    isFetching,
  } = useQuery(
    [`determinado usuário${selectedCompany}`, selectedCompany],

    async () => {
      try {
        const response = await api.get(
          `/company/${selectedCompany}/user/${userId}`,
        )

        const { data } = response

        return data
      } catch (err: any) {
        ShowError(err.message, translate('useUsers.getErrorMessage'), locale)
      }
    },
    {
      staleTime: 5000,
    },
  )

  async function updateUsers(dataUser: object) {
    try {
      await api.put(`/company/${selectedCompany}/user/${userId}`, dataUser)

      refetch()
      message.success(translate('useUsers.updateSuccessMessage'))
    } catch (err: any) {
      ShowError(err.message, translate('useUsers.updateErrorMessage'), locale)
    }
  }

  async function createUserGroup(group_id: string) {
    try {
      message.loading(translate('useUsers.createLoadingMessage'))
      await api.post(`/company/${selectedCompany}/user/${userId}/user-group`, {
        group_id,
      })

      refetch()
      message.success(translate('useUsers.createSuccessMessage'))
    } catch (err: any) {
      ShowError(err.message, translate('useUsers.createErrorMessage'), locale)
    }
  }

  async function deleteUserGroup(group_id: string) {
    try {
      message.loading(translate('useUsers.deleteLoadingMessage'))
      await api.delete(
        `/company/${selectedCompany}/user/${userId}/group/${group_id}/user-group`,
      )

      refetch()
      message.success(translate('useUsers.deleteSuccessMessage'))
    } catch (err: any) {
      ShowError(err.message, translate('useUsers.deleteErrorMessage'), locale)
    }
  }

  return (
    <UsersContext.Provider
      value={{
        users,
        updateUsers,
        isLoading,
        refetch,
        isFetching,
        createUserGroup,
        deleteUserGroup,
      }}
    >
      {children}
    </UsersContext.Provider>
  )
}

export function useUsers(): UsersContextData {
  const context = useContext(UsersContext)

  if (!context) {
    throw new Error('useAuth must be used within an AutoProvider')
  }

  return context
}
