export default {
  pt: {
    useUsers: {
      getErrorMessage: 'Erro ao visualizar usuário.',
      updateSuccessMessage: 'Usuário atualizado com sucesso!',
      updateErrorMessage: 'Erro ao atualizar usuário.',
      createLoadingMessage: 'Adicionando usuário ao grupo...',
      createSuccessMessage: 'Usuário adicionado ao grupo com sucesso!',
      createErrorMessage: 'Erro ao adicionar usuário.',
      deleteLoadingMessage: 'Retirando usuário do grupo...',
      deleteSuccessMessage: 'Você saiu do grupo.',
      deleteErrorMessage: 'Erro ao sair do grupo usuário.',
    },
  },
  en: {
    useUsers: {
      getErrorMessage: 'Error viewing user.',
      updateSuccessMessage: 'User successfully updated!',
      updateErrorMessage: 'Error updating user.',
      createLoadingMessage: 'Adding user to group...',
      createSuccessMessage: 'User successfully added to the group!',
      createErrorMessage: 'Error adding user.',
      deleteLoadingMessage: 'Removing user from group...',
      deleteSuccessMessage: 'You left the group.',
      deleteErrorMessage: 'Error leaving user group.',
    },
  },
  es: {
    useUsers: {
      getErrorMessage: 'Error al ver el usuario.',
      updateSuccessMessage: '¡Usuario actualizado con éxito!',
      updateErrorMessage: 'Error al actualizar usuario.',
      createLoadingMessage: 'Agregando usuario al grupo...',
      createSuccessMessage: '¡Usuario añadido con éxito al grupo!',
      createErrorMessage: 'Error al agregar usuario.',
      deleteLoadingMessage: 'Eliminando usuario del grupo...',
      deleteSuccessMessage: 'Dejaste el grupo.',
      deleteErrorMessage: 'Error al abandonar el grupo de usuarios.',
    },
  },
}
