import { Breadcrumb, Collapse, Divider } from 'antd'
import React, { useState } from 'react'

import { Container, Menu, Wrapper } from './styles'

const PageFaq: React.FC = () => {
  const [select, setSelect] = useState(1)
  const [type, setType] = useState('general')

  function handleMenu(selectMenu: number, typeMenu: string) {
    setSelect(selectMenu)
    setType(typeMenu)
  }

  const { Panel } = Collapse

  return (
    <Container>
      <Menu>
        <h2>Central de ajuda Gstor.</h2>

        <Divider />

        <div className="menu">
          <h3>Categorias</h3>
          <button
            type="button"
            onClick={() => handleMenu(1, 'general')}
            style={{ color: select === 1 ? '#3C8ACB' : '' }}
          >
            Geral
          </button>
          <button
            type="button"
            onClick={() => handleMenu(2, 'tasks')}
            style={{ color: select === 2 ? '#3C8ACB' : '' }}
          >
            Tarefas
          </button>
          <button
            type="button"
            onClick={() => handleMenu(3, 'help')}
            style={{ color: select === 3 ? '#3C8ACB' : '' }}
          >
            Suporte
          </button>
          <button
            type="button"
            onClick={() => handleMenu(4, 'registration')}
            style={{ color: select === 4 ? '#3C8ACB' : '' }}
          >
            Cadastro
          </button>
          <button
            type="button"
            onClick={() => handleMenu(5, 'registrationModules')}
            style={{ color: select === 5 ? '#3C8ACB' : '' }}
          >
            Guia de navegação
          </button>
        </div>
      </Menu>

      <Wrapper>
        {type === 'general' ? (
          <>
            <Breadcrumb>
              <Breadcrumb.Item>Dúvidas Frequentes</Breadcrumb.Item>
              <Breadcrumb.Item>Geral</Breadcrumb.Item>
            </Breadcrumb>
            <h2>Geral</h2>

            <div className="questions">
              <Collapse collapsible="header">
                <Panel header="Para que serve o Gstor?" key="1">
                  <p>
                    O Gstor é um gerenciador de tarefas que pode ser atualizado
                    via WhatsApp!
                  </p>
                </Panel>
              </Collapse>

              <Collapse collapsible="header">
                <Panel
                  header="É necessário ter um whatsapp para utilizar o Gstor?"
                  key="2"
                >
                  <p>
                    Para atualizar as tarefas de forma rápida e sem baixar
                    qualquer aplicativo é necessário acesso ao WhatsApp ou
                    Telegram.
                  </p>
                </Panel>
              </Collapse>

              <Collapse collapsible="header">
                <Panel header="Quanto custa o Gstor?" key="3">
                  <p>
                    R$39,90 por colaborador ou R$499,00 com franquia de mil
                    serviços (grátis para colaboradores cadastrados).
                  </p>
                </Panel>
              </Collapse>

              <Collapse collapsible="header">
                <Panel
                  header="O Gstor pode ser integrado a outros sistemas?"
                  key="4"
                >
                  <p>Sim.</p>
                </Panel>
              </Collapse>

              <Collapse collapsible="header">
                <Panel header="O Gstor é um CRM?" key="5">
                  <p>
                    Não, apesar de ter a avaliação do atendimento dos clientes
                    para o pós-venda, o Gstor não é um CRM.
                  </p>
                </Panel>
              </Collapse>

              <Collapse collapsible="header">
                <Panel
                  header="Para quais tipos de negócio o Gstor é recomendado?"
                  key="6"
                >
                  <p>Para field service.</p>
                </Panel>
              </Collapse>

              <Collapse collapsible="header">
                <Panel
                  header="Posso enviar uma tarefa para uma pessoa que não é um funcionário meu?"
                  key="7"
                >
                  <p>Sim, para isso basta cadastrar como colaborador.</p>
                </Panel>
              </Collapse>

              <Collapse collapsible="header">
                <Panel header="Gstor funciona em android e iphone?" key="8">
                  <p>Sim, o Gstor funciona em ambos.</p>
                </Panel>
              </Collapse>

              <Collapse collapsible="header">
                <Panel
                  header="É possível customizar o Gstor para minha empresa?"
                  key="9"
                >
                  <p>
                    Sim, o Gstor pode ser customizável de acordo com a sua
                    rotina de trabalho.
                  </p>
                </Panel>
              </Collapse>
            </div>
          </>
        ) : (
          ''
        )}

        {type === 'tasks' ? (
          <>
            <Breadcrumb>
              <Breadcrumb.Item>Dúvidas Frequentes</Breadcrumb.Item>
              <Breadcrumb.Item>Tarefas</Breadcrumb.Item>
            </Breadcrumb>
            <h2>Tarefas</h2>

            <div className="questions">
              <Collapse collapsible="header">
                <Panel header="Como cadastrar as tarefas?" key="10">
                  <p>
                    Clique no botão criar tarefas que fica no canto superior
                    direito. Preencha as informações para seguir nas abas
                    endereço e responsável. Ao finalizar o preenchimento das
                    informações clique em cadastrar tarefas.
                  </p>
                </Panel>
              </Collapse>
              <Collapse collapsible="header">
                <Panel
                  header="É possível atualizar tarefas sem ser via WhatsApp?"
                  key="11"
                >
                  <p>Sim, é posssível.</p>
                </Panel>
              </Collapse>
              <Collapse collapsible="header">
                <Panel
                  header="Meu cliente consegue abrir a tarefa diretamente pelo Sistema?"
                  key="12"
                >
                  <p>
                    Consegue, para isso basta ter login e senha para acessar o
                    Gstor.
                  </p>
                </Panel>
              </Collapse>
              <Collapse collapsible="header">
                <Panel header="O Gstor trabalha com Ordem de Serviço?" key="13">
                  <p>Sim, cada tarefa contempla uma ordem de serviço.</p>
                </Panel>
              </Collapse>
              <Collapse collapsible="header">
                <Panel header="Como eu atualizo uma tarefa no Gstor?" key="14">
                  <p>
                    Para atualizar basta responder a mensagem de aviso da
                    tarefa.
                  </p>
                </Panel>
              </Collapse>
              <Collapse collapsible="header">
                <Panel header="O Gstor faz Relatório de Atendimento?" key="15">
                  <p>Sim.</p>
                </Panel>
              </Collapse>
            </div>
          </>
        ) : (
          ''
        )}

        {type === 'help' ? (
          <>
            <Breadcrumb>
              <Breadcrumb.Item>Dúvidas Frequentes</Breadcrumb.Item>
              <Breadcrumb.Item>Suporte</Breadcrumb.Item>
            </Breadcrumb>
            <h2>Suporte</h2>

            <div className="questions">
              <Collapse collapsible="header">
                <Panel
                  header="Como faço para solicitar Suporte do Gstor?"
                  key="16"
                >
                  <p>Sim.</p>
                </Panel>
              </Collapse>

              <Collapse collapsible="header">
                <Panel header="O Gstor tem manual de uso?" key="17">
                  <p>Sim e você pode acessar (inserir link).</p>
                </Panel>
              </Collapse>
            </div>
          </>
        ) : (
          ''
        )}

        {type === 'registration' ? (
          <>
            <Breadcrumb>
              <Breadcrumb.Item>Dúvidas Frequentes</Breadcrumb.Item>
              <Breadcrumb.Item>Cadastro</Breadcrumb.Item>
            </Breadcrumb>
            <h2>Cadastro</h2>

            <div className="questions">
              <Collapse collapsible="header">
                <Panel
                  header="Como cadastrar colaboradores no sistema?"
                  key="18"
                >
                  <p>
                    Clique no botão no canto superior esquerdo (novo
                    colaborador). Preencha as informações e clique em cadastrar.
                  </p>
                </Panel>
              </Collapse>
              <Collapse collapsible="header">
                <Panel
                  header="Quantas equipes posso ter cadastrado no sistema?"
                  key="19"
                >
                  <p>Não temos limites de cadastro de colaboradores.</p>
                </Panel>
              </Collapse>
              <Collapse collapsible="header">
                <Panel header="Como faço o cadastro de equipes?" key="20">
                  <p>
                    Clique na opção colaboradores para abrir o menu, depois
                    clique em equipes. Clique no botão no canto superior
                    esquerdo (nova equipe). Preencha as informações e clique em
                    cadastrar.
                  </p>
                </Panel>
              </Collapse>
              <Collapse collapsible="header">
                <Panel
                  header="Como eu edito as informações do colaborador?"
                  key="21"
                >
                  <p>
                    No menu lateral esquerdo clique em cadastro. Nesse menu você
                    pode ver a lista de colaboradores cadastrados. Na coluna
                    ações é possível deletar ou visualizar o colaborador.
                    Clicando na visualização todas as informações do cadastro
                    são mostradas e essas podem ser editadas pelo botão editar.
                    Salve suas edições no botão salvar.
                  </p>
                </Panel>
              </Collapse>
              <Collapse collapsible="header">
                <Panel
                  header="Consigo controlar a escala de trabalho dos meus funcionários?"
                  key="22"
                >
                  <p>
                    No Gstor você pode definir e editar a qualquer momento a
                    escala dos funcionários.
                  </p>
                </Panel>
              </Collapse>
              <Collapse collapsible="header">
                <Panel
                  header="Posso definir o horário de trabalho da equipe no Gstor?"
                  key="23"
                >
                  <p>
                    Sim. Para criar horários para sua equipe clique em
                    colaboradores e depois clique na opção escala. Clique no
                    botão no canto superior direito (criar escala) e preencha as
                    informações necessárias. Depois clique em cadastrar.
                  </p>
                </Panel>
              </Collapse>
              <Collapse collapsible="header">
                <Panel header="Como eu atualizo uma tarefa no Gstor?" key="24">
                  <p>
                    Para atualizar basta responder a mensagem de aviso da
                    tarefa.
                  </p>
                </Panel>
              </Collapse>
              <Collapse collapsible="header">
                <Panel
                  header="E se o colaborador terminar a tarefa antes do SLA determinado?"
                  key="25"
                >
                  <p>
                    Sem problemas! Atualize a tarefa e ela finalize normalmente
                    dentro do tempo que você apontou.
                  </p>
                </Panel>
              </Collapse>
              <Collapse collapsible="header">
                <Panel
                  header="E se a tarefa for concluída com atraso?"
                  key="26"
                >
                  <p>
                    A tarefa será concluída com atraso, o qual ficará
                    registrado.
                  </p>
                </Panel>
              </Collapse>
              <Collapse collapsible="header">
                <Panel
                  header="A tarefa conta com histórico de edição?"
                  key="27"
                >
                  <p>
                    Sim, a tarefa conta com histórico de edição e esse você pode
                    visualizar no painel tarefas.
                  </p>
                </Panel>
              </Collapse>
            </div>
          </>
        ) : (
          ''
        )}

        {type === 'registrationModules' ? (
          <>
            <Breadcrumb>
              <Breadcrumb.Item>Dúvidas Frequentes</Breadcrumb.Item>
              <Breadcrumb.Item>Guia de navegação</Breadcrumb.Item>
            </Breadcrumb>
            <h2>Informações sobre os módulos do Gstor.</h2>

            <div className="questions">
              <Collapse collapsible="header">
                <Panel header="Tarefas" key="28">
                  <div className="content">
                    <h3>1. Visualizar tarefas</h3>
                    <p>
                      <b>Agenda:</b>
                      &nbsp; calendário com todas as tarefas agendadas.
                    </p>

                    <p>
                      <b>Lista de tarefas:</b>
                      &nbsp; detalhes de todas as tarefas cadastradas com as
                      informações de responsável, data, tipo de tarefa e o seu
                      status.
                    </p>

                    <p>
                      É possível exportar um relatório no botão superior à
                      direita. Esse relatório contém as informações filtradas.
                      Para filtrar é só clicar no botão filtro e selecionar as
                      opções.
                    </p>

                    <p>
                      <b>Kanban de Tarefas:</b>
                      &nbsp; acompanhe o processo de todas as tarefas
                      cadastradas de forma organizada e ágil. Para saber em qual
                      status a tarefa está é só arrastar a página para o lado.
                    </p>
                  </div>

                  <Divider />

                  <div className="content">
                    <h3>2. Cadastro de tarefas</h3>
                    <p>
                      Ainda na opção tarefas clique no botão criar tarefas que
                      fica no canto superior direito. Preencha as informações
                      para seguir nas abas endereço e responsável. Ao finalizar
                      o preenchimento das informações clique em cadastrar
                      tarefas.
                    </p>
                  </div>

                  <Divider />

                  <div className="content">
                    <h3>3. Tipos de Tarefas</h3>
                    <p>
                      Cadastre o tipo de tarefas que deseja para que sua tarefa
                      seja criada com sucesso. Para isso, clique em tarefas,
                      depois em tipos de tarefas. No canto superior direito
                      clique em adicionar tipo de tarefa e preencha as
                      informações. Depois clique em cadastrar tipo de tarefa.
                    </p>
                  </div>

                  <Divider />

                  <div className="content">
                    <h3>4. Motivos de Pausa</h3>
                    <p>
                      Para criar motivos de pausa clique em Tarefas, depois em
                      motivo de pausa e no botão superior à direita clique em
                      Adicionar motivo de pausa. Insira as informações e clique
                      em salvar.
                    </p>
                  </div>
                </Panel>
              </Collapse>

              <Collapse collapsible="header">
                <Panel header="Cadastros" key="29">
                  <div className="content">
                    <h3>1. Visualizar colaboradores</h3>

                    <p>
                      No menu lateral esquerdo clique em cadastro. Nesse menu
                      você pode ver a lista de colaboradores cadastrados. Na
                      coluna ações é possível deletar ou visualizar o
                      colaborador Clicando na visualização todas as informações
                      do cadastro são mostradas e essas podem ser editadas pelo
                      botão editar. Também é possível verificar as tarefas,
                      clientes e avaliações que o colaborador recebeu na parte
                      direita do cadastro. Na parte inferior consta o status do
                      colaborador.
                    </p>
                  </div>

                  <Divider />

                  <div className="content">
                    <h3>2. Cadastro de colaboradores</h3>
                    <p>
                      Clique no botão no canto superior esquerdo (novo
                      colaborador). Preencha as informações e clique em
                      cadastrar.
                    </p>
                  </div>

                  <Divider />

                  <div className="content">
                    <h3>2. Equipes</h3>
                    <p>
                      Vá até o menu lateral esquerdo e clique na opção equipes.
                      Preencha as informações e clique em cadastrar.
                    </p>
                  </div>

                  <Divider />

                  <div className="content">
                    <h3>3. Habilidades</h3>
                    <p>
                      Vá até o menu lateral esquerdo e clique na opção
                      habilidades. Preencha as informações e clique em
                      cadastrar.
                    </p>
                  </div>

                  <Divider />

                  <div className="content">
                    <h3>4. Status</h3>
                    <p>
                      Vá até o menu lateral esquerdo e clique na opção status.
                      Preencha as informações e clique em cadastrar.
                    </p>
                  </div>
                </Panel>
              </Collapse>

              <Collapse collapsible="header">
                <Panel header="Clientes" key="30">
                  <div className="content">
                    <h3>1. Visualizar clientes</h3>
                    <p>
                      Na aba lateral esquerda clique na opção clientes. Nessa
                      aba você consegue visualizar a lista de clientes e na
                      coluna ações é possível editar ou excluí-lo.
                    </p>
                  </div>

                  <Divider />

                  <div className="content">
                    <h3>2. Cadastro de clientes</h3>
                    <p>
                      Clique no botão novo cliente no canto superior direito e
                      preencha as informações. Depois clique em cadastrar.
                    </p>
                  </div>

                  <Divider />

                  <div className="content">
                    <h3>3. Segmentos</h3>
                    <p>
                      Clique no botão adicionar segmento no canto superior
                      direito e preencha as informações. Depois clique em
                      cadastrar.
                    </p>
                  </div>
                </Panel>
              </Collapse>

              <Collapse collapsible="header">
                <Panel header="Equipamentos" key="31">
                  <div className="content">
                    <h3>1. Visualizar equipamentos</h3>
                    <p>
                      Na aba lateral esquerda clique em equipamentos para
                      verificar todos os itens. Na coluna ações é possível
                      editar ou excluir o equipamento.
                    </p>
                  </div>

                  <Divider />

                  <div className="content">
                    <h3>2. Cadastro de equipamentos</h3>
                    <p>
                      Para cadastrar clique no botão novo equipamento do canto
                      superior direito, preencha as informações e depois clique
                      em salvar equipamento.
                    </p>
                  </div>

                  <Divider />

                  <div className="content">
                    <h3>3. Categorias</h3>
                    <p>
                      Você pode cadastrar a categoria do seu equipamento na aba
                      categorias. Clique no botão nova categoria no canto
                      superior direito, preencha as informações e clique em
                      salvar.
                    </p>
                  </div>
                </Panel>
              </Collapse>

              <Collapse collapsible="header">
                <Panel header="Relatórios" key="32">
                  <div className="content">
                    <h3>1. Visualizar relatórios</h3>
                    <p>
                      No menu lateral esquerdo você encontra a opção relatório
                      para visualizar o status das tarefas e outras informações.
                      É possível exportar um relatório em CSV após selecionar as
                      opções no filtro. Para isso, é só clicar em filtros no
                      canto superior direito e selecionar as informações. Clique
                      em aplicar filtro e exporte seu relatório clicando em
                      exportar.
                    </p>
                  </div>

                  <Divider />

                  <div className="content">
                    <h3>2. Km rodados</h3>
                    <p>
                      para saber o KM rodado dos automóveis cadastrados preencha
                      as informações e clique em gerar relatório.
                    </p>
                  </div>
                </Panel>
              </Collapse>

              <Collapse collapsible="header">
                <Panel header="Configurações" key="33">
                  <div className="content">
                    <h3>1. Configurar usuários</h3>
                    <p>
                      Para configurar os usuários no Gstor basta clicar na opção
                      Configurações no menu esquerdo. Clicando em login é
                      possível ver todos os usuários cadastrados, editar e
                      excluir.
                    </p>
                  </div>

                  <Divider />

                  <div className="content">
                    <h3>2. Buscar usuários</h3>
                    <p>
                      Para buscar um usuário utilize o botão filtro que fica no
                      canto superior direito. Preencha as informações e clique
                      em aplicar.
                    </p>
                  </div>

                  <Divider />

                  <div className="content">
                    <h3>3. Permissões</h3>
                    <p>
                      Na opção permissões você pode filtrar as permissões que o
                      usuário tem no sistema.
                    </p>
                  </div>
                </Panel>
              </Collapse>
            </div>
          </>
        ) : (
          ''
        )}
      </Wrapper>
    </Container>
  )
}

export default PageFaq
