/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-curly-newline */
import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  ChangeEvent,
} from 'react'
import { useHistory } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import * as Yup from 'yup'
import {
  FiArrowLeft,
  FiUser,
  FiUsers,
  FiMail,
  FiLock,
  FiChevronRight,
} from 'react-icons/fi'

import { message, Select } from 'antd'
import { Container, Content, AnimationContainer, Background } from './styles'

import Input from '~/components/Login/Input'
import Button from '~/components/Login/Button'

import api from '~/services/api'

import { useLocale } from '~/hooks/locale/useLocale'
import { useAuth } from '~/hooks/Auth/AuthContext'

import getValidationErrors from '~/utils/getValidationErrors'

import { ShowError } from '~/utils/errors/apiErrors'
import { translate } from '~/utils/locale'

import Logo from '../../../assets/logo_icon.svg'

interface SignUpFormData {
  name: string
  companyName: string
  email: string
  password: string
  confirmPassword: string
  phone: string
  contracted_plan: string
}

const SignUp: React.FC = () => {
  const formRef = useRef<FormHandles>(null)

  const history = useHistory()

  const { locale } = useLocale()

  const { signIn } = useAuth()

  const [name, setName] = useState('DefaultValue')
  const [company, setCompany] = useState('DefaultValue')
  const [email, setEmail] = useState('DefaultValue@email.com')
  const [password, setPassword] = useState('DefaultValue1')
  const [password_confirmation, setPassword_Confirmation] =
    useState('DefaultValue1')
  const [phone, setPhone] = useState('')
  const [contracted_plan, setContracted_Plan] = useState('DefaultValue')
  const [loading, setLoading] = useState(false)

  const maskPhone = (value: any) => {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '($1) $2')
      .replace(/(\d{5})(\d)/, '$1-$2')
      .replace(/(-\d{4})(\d+?)$/, '$1')
  }

  const schema = Yup.object().shape({
    name: Yup.string().required(translate('SignUp.nameYup.required')),
    company: Yup.string().required(translate('SignUp.companyYup.required')),
    email: Yup.string()
      .required(translate('SignUp.emailYup.required'))
      .email(translate('SignUp.emailYup.validation')),
    password: Yup.string()
      .required(translate('SignUp.passwordYup.required'))
      .matches(
        /^.*((?=.*[!@#$%^&*()\-_=+{};:,<.>A-Z]){1}).*$/,
        translate('SignUp.passwordYup.validationOne'),
      )
      .matches(/^.*(?=.*\d).*$/, translate('SignUp.passwordYup.validationTwo'))
      .matches(
        /^.*((?=.*[a-z]){1}).*$/,
        translate('SignUp.passwordYup.validationThree'),
      )
      .matches(
        /^.*(?=.{8,}).*$/,
        translate('SignUp.passwordYup.validationFour'),
      ),
    password_confirmation: Yup.string()
      .nullable()
      .oneOf(
        [Yup.ref('password'), null],
        translate('SignUp.passwordConfirmationYup.required'),
      ),
  })

  function handleSelect(value: string) {
    setContracted_Plan(value)
  }

  useEffect(() => {
    ;(async () => {
      const data = {
        name,
        company,
        email,
        password,
        password_confirmation,
        phone,
        contracted_plan,
      }
      try {
        formRef.current?.setErrors({})

        await schema.validate(data, {
          abortEarly: false,
        })
      } catch (error: any) {
        const errors = getValidationErrors(error)

        formRef.current?.setErrors(errors)
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    name,
    company,
    email,
    password,
    password_confirmation,
    phone,
    contracted_plan,
  ])

  // const openNotification = () => {
  //   notification.info({
  //     message: <b>{translate('SignUp.notification.message')}</b>,
  //     description: (
  //       <p>
  //         <h4>{translate('SignUp.notification.description.title')}</h4>
  //         <ul>
  //           <li>{translate('SignUp.notification.description.stepOne')}</li>
  //           <li>{translate('SignUp.notification.description.stepTwo')}</li>
  //           <li>{translate('SignUp.notification.description.stepThree')}</li>
  //         </ul>
  //       </p>
  //     ),
  //     placement: 'topRight',
  //     duration: 0,
  //   });
  // };

  const handleSubmit = useCallback(
    async (data: SignUpFormData) => {
      setLoading(true)
      try {
        formRef.current?.setErrors({})

        await schema.validate(data, {
          abortEarly: false,
        })

        data.phone = data.phone.replace(/[^0-9]/g, '')
        data.phone = `55${data.phone}`
        data.contracted_plan = contracted_plan

        await api.post('user/register', data)

        message.success(translate('SignUp.successMessage'))

        await signIn({
          email: data.email,
          password: data.password,
        })

        // openNotification();
        history.push('/')
      } catch (error: any) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error)

          formRef.current?.setErrors(errors)
        }

        ShowError(error.message, translate('SignUp.errorMessage'), locale)
      } finally {
        setLoading(false)
      }
    },
    [schema, history],
  )

  return (
    <Container>
      <Helmet>
        <title>{translate('SignUp.helmetTitle')}</title>
      </Helmet>

      <Content>
        <AnimationContainer>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <h1>{translate('SignUp.title')}</h1>
            <p>
              {translate('SignUp.subTitleTwo')}
              <b>{translate('SignUp.subTitleThree')}</b>
              {translate('SignUp.subTitleFour')}
            </p>

            <Input
              name="name"
              icon={FiUser}
              type="text"
              placeholder={translate('SignUp.namePlaceholder')}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setName(e.target.value)
              }
            />
            <Input
              name="company"
              icon={FiUsers}
              type="text"
              placeholder={translate('SignUp.companyPlaceholder')}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setCompany(e.target.value)
              }
            />
            <Input
              name="phone"
              icon={FiMail}
              type="text"
              value={phone}
              placeholder={translate('SignUp.phonePlaceholder')}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setPhone(maskPhone(e.target.value))
              }
            />
            <Input
              name="email"
              icon={FiMail}
              type="email"
              placeholder={translate('SignUp.emailPlaceholder')}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setEmail(e.target.value)
              }
            />
            <Input
              name="password"
              icon={FiLock}
              type="password"
              isPassword
              placeholder={translate('SignUp.passwordPlaceholder')}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setPassword(e.target.value)
              }
            />
            <Input
              name="password_confirmation"
              icon={FiLock}
              type="password"
              isPassword
              placeholder={translate('SignUp.passwordConfirmPlaceholder')}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setPassword_Confirmation(e.target.value)
              }
            />

            <Select
              style={{ width: '100%' }}
              placeholder={translate('SignUp.selectPlaceholder')}
              onChange={handleSelect}
            >
              <Select.Option value="each_collaborator">
                {translate('SignUp.selectOptionOne')}
              </Select.Option>
              <Select.Option value="service_packages">
                {translate('SignUp.selectOptionTwo')}
              </Select.Option>
            </Select>

            <Button loading={loading} type="submit">
              {translate('SignUp.buttonText')}
            </Button>
          </Form>

          <a href="/">
            <FiArrowLeft />
            {translate('SignUp.backText')}
          </a>
        </AnimationContainer>
      </Content>

      <Background>
        <img src={Logo} alt="Logo go.on" width="50px" />

        <h1>Gestão de Equipe Externa.</h1>

        <div className="lg-footer">
          <hr color="#3C8ACB" />
          <h4>Cadastre-se na nossa plataforma</h4>
          <FiChevronRight size="20px" />
        </div>
      </Background>
    </Container>
  )
}

export default SignUp
