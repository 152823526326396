export default {
  pt: {
    ShowGroups: {
      helmetTitle: 'Visualizar permissões',
    },
  },
  en: {
    ShowGroups: {
      helmetTitle: 'View permissions',
    },
  },
  es: {
    ShowGroups: {
      helmetTitle: 'Ver permisos',
    },
  },
}
