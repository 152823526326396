/* eslint-disable react/jsx-one-expression-per-line */
import { LoadingOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { Button, Card, Space, Tooltip } from 'antd'

import Text from 'antd/lib/typography/Text'
import React from 'react'
import { Link } from 'react-router-dom'

import { usePause } from '~/hooks/Tasks/Pause/usePause'
import { translate } from '~/utils/locale'
import FilterHelpDesk from './Filter'
import LinkHelpDesk from './Link'
import HelpDeskTable from './Table'

const HelpDeskBoard: React.FC = () => {
  const { pauseTotal, isLoading, isFetching } = usePause()

  return (
    <Card
      title={
        <Space size="large">
          <b>{translate('HelpDesk.cardTitle')}</b>
          {isLoading ? (
            <LoadingOutlined spin />
          ) : (
            <Text
              type="secondary"
              style={{ fontWeight: 'normal', fontSize: 14 }}
            >
              {pauseTotal !== undefined ? (
                <>
                  {pauseTotal === 1
                    ? `${translate(
                        'HelpDesk.totalText',
                      )} ${pauseTotal} ${translate('HelpDesk.breakReasonText')}`
                    : `${translate(
                        'HelpDesk.totalText',
                      )} ${pauseTotal} ${translate(
                        'HelpDesk.breakReasonsText',
                      )}`}
                </>
              ) : (
                ''
              )}
            </Text>
          )}
          {!isLoading && isFetching && <LoadingOutlined spin />}
        </Space>
      }
      extra={
        <Space size="small">
          <Tooltip title={translate('HelpDesk.shareText')}>
            <LinkHelpDesk />
          </Tooltip>
          <FilterHelpDesk />
          <Link to="/novoTicket">
            <Button type="primary" icon={<PlusCircleOutlined />}>
              {translate('HelpDesk.buttonText')}
            </Button>
          </Link>
        </Space>
      }
      bordered={false}
      style={{ width: '100%', padding: 30 }}
    >
      <HelpDeskTable />
    </Card>
  )
}

export default HelpDeskBoard
