import { LoadingOutlined } from '@ant-design/icons'
import { Button, Card, Select, Space } from 'antd'
import Text from 'antd/lib/typography/Text'

import React from 'react'
import { Link } from 'react-router-dom'
import { useTaskReceived } from '~/hooks/Tasks/Kanban/useTaskReceived'
import { useListTask } from '~/hooks/Tasks/useListTasks'
import { translate } from '~/utils/locale'
import ConfigurationsKanban from '../../Options/Kanban/Configurations'
import FilterTasks from '../../Options/Kanban/Filter'

import ListKanban from '../../Options/Kanban/List'

// import { Container } from './styles';

const KanbanBoard: React.FC = () => {
  const { isLoading, isFetching, tasksTotal } = useListTask()
  const { refetch } = useTaskReceived()

  function handleVisualization(value: string) {
    localStorage.setItem('visualizationKanban', value)
    refetch()
  }

  const optionVisualization = localStorage.getItem('visualizationKanban')

  return (
    <Card
      title={
        <Space size="large">
          <b>{translate('TasksBoardsKanban.cardTitle')}</b>
          {isLoading ? (
            <LoadingOutlined spin />
          ) : (
            <Text
              type="secondary"
              style={{ fontWeight: 'normal', fontSize: 14 }}
            >
              {tasksTotal !== undefined ? (
                <>
                  {tasksTotal === 1
                    ? `${translate(
                        'TasksBoardsKanban.totalText',
                      )} ${tasksTotal} ${translate(
                        'TasksBoardsKanban.taskText',
                      )}`
                    : `${translate(
                        'TasksBoardsKanban.totalText',
                      )} ${tasksTotal} ${translate(
                        'TasksBoardsKanban.tasksText',
                      )}`}
                </>
              ) : (
                ''
              )}
            </Text>
          )}
          {!isLoading && isFetching && <LoadingOutlined spin />}
        </Space>
      }
      extra={
        <Space size="middle">
          <Select
            defaultValue={optionVisualization || 'complete'}
            onChange={handleVisualization}
          >
            <Select.Option value="complete">
              {translate('TasksBoardsKanban.completeOption')}
            </Select.Option>
            <Select.Option value="resume">
              {translate('TasksBoardsKanban.resumeOption')}
            </Select.Option>
          </Select>

          <ConfigurationsKanban />
          <FilterTasks />

          <Link to="/novaTarefa">
            <Button type="primary">
              {translate('TasksBoardsKanban.createButton')}
            </Button>
          </Link>
        </Space>
      }
      bordered={false}
      style={{ width: '100%', height: '100%' }}
    >
      <ListKanban />
    </Card>
  )
}

export default KanbanBoard
