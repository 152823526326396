/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prefer-promise-reject-errors */
import { Button, Col, Form, Input, message, Row, Select } from 'antd'
import { ValidateStatus } from 'antd/lib/form/FormItem'
import { LatLngExpression } from 'leaflet'
import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { MapContainer, Marker, TileLayer, useMap } from 'react-leaflet'
import cep from 'cep-promise'

import geolocationApi from '~/services/geolocationApi'

import { translate } from '~/utils/locale'

interface MapUpdateFunctionProps {
  center: LatLngExpression
  zoom: number
}

interface FormProps {
  form: any
}

const CreateParentClientAddress: React.FC<FormProps> = ({ form }) => {
  const [latitude, setLatitude] = useState<any>(-14.235004)
  const [longitude, setLongitude] = useState<any>(-51.925282)

  const [mapSearch, setMapSearch] = useState(false)
  const [mapValidate, setMapValidate] = useState(false)
  const [status, setStatus] = useState('' as ValidateStatus)
  const [zoomLevel, setZoomLevel] = useState(3)

  const [hidden, setHidden] = useState(true)

  const [state, setState] = useState('')
  const [city, setCity] = useState('')
  const [street, setStreet] = useState('')

  console.log(form.getFieldValue('city'))

  const formatComma = (value: string, field: 'latitude' | 'longitude') => {
    const valueFormatted = value.replace(/,/g, '.')

    if (
      valueFormatted !== '-' &&
      valueFormatted !== '.' &&
      valueFormatted !== '-.'
    ) {
      if (field === 'latitude') {
        setLatitude(valueFormatted)
      } else if (field === 'longitude') {
        setLongitude(valueFormatted)
      }
    }
  }

  useEffect(() => {
    if (latitude !== -14.235004 && longitude !== -51.925282) {
      setZoomLevel(13)
    }
  }, [latitude, longitude])

  const stateOptions = [
    { label: 'Acre', value: 'AC' },
    { label: 'Alagoas', value: 'AL' },
    { label: 'Amapá', value: 'AP' },
    { label: 'Amazonas', value: 'AM' },
    { label: 'Bahia', value: 'BA' },
    { label: 'Ceará', value: 'CE' },
    { label: 'Espírito Santo', value: 'ES' },
    { label: 'Goiás', value: 'GO' },
    { label: 'Maranhão', value: 'MA' },
    { label: 'Mato Grosso', value: 'MT' },
    { label: 'Mato Grosso do Sul', value: 'MS' },
    { label: 'Minas Gerais', value: 'MG' },
    { label: 'Pará', value: 'PA' },
    { label: 'Paraíba', value: 'PB' },
    { label: 'Paraná', value: 'PR' },
    { label: 'Pernambuco', value: 'PE' },
    { label: 'Piauí', value: 'PI' },
    { label: 'Rio de Janeiro', value: 'RJ' },
    { label: 'Rio Grande do Norte', value: 'RN' },
    { label: 'Rio Grande do Sul', value: 'RS' },
    { label: 'Rondônia', value: 'RO' },
    { label: 'Roraima', value: 'RR' },
    { label: 'Santa Catarina', value: 'SC' },
    { label: 'São Paulo', value: 'SP' },
    { label: 'Sergipe', value: 'SE' },
    { label: 'Tocantins', value: 'TO' },
    { label: 'Distrito Federal', value: 'DF' },
  ]

  const searchCEP = useCallback(
    async (address: string) => {
      try {
        setStatus('validating')
        const cepInfo = await cep(address)

        form.setFieldsValue({
          state: cepInfo.state,
          city: cepInfo.city,
          neighborhood: cepInfo.neighborhood,
          street: cepInfo.street,
        })

        setState(cepInfo.state)
        setCity(cepInfo.city)
        setStreet(cepInfo.street)

        setStatus('success')
      } catch (error) {
        message.error(translate('TasksCreateAddress.zipcodeSearchErrorMessage'))
        form.resetFields()
        setState('')
        setCity('')
        setStreet('')
        setStatus('error')
      }
    },
    [form],
  )

  const searchMap = useCallback(async () => {
    try {
      setMapSearch(true)
      const params = {
        street,
        city,
        state,
        format: 'json',
      }

      const response = await geolocationApi.get('search', { params })

      if (response.data.length > 0) {
        setHidden(true)
        form.setFieldsValue({
          latitude: response.data[0].lat,
          longitude: response.data[0].lon,
        })

        setLatitude(response.data[0].lat)
        setLongitude(response.data[0].lon)

        setZoomLevel(13)
      } else {
        setHidden(false)

        form.setFieldsValue({
          latitude: '',
          longitude: '',
        })

        setZoomLevel(3)

        message.warning(
          translate('TasksCreateAddress.geolocationWarningMessage'),
          10,
        )
      }

      setMapValidate(true)
      setMapSearch(false)
    } catch (error) {
      message.error(translate('TasksCreateAddress.geolocationErrorMessage'))
      setMapValidate(true)
      setMapSearch(false)
    }
  }, [city, state, street, form])

  function ChangeView({ center, zoom }: MapUpdateFunctionProps) {
    const map = useMap()
    map.setView(center, zoom)
    return null
  }

  return (
    <>
      <Row gutter={14}>
        <Col span={4}>
          <Form.Item
            label={translate('TasksCreateAddress.zipcodeLabel')}
            name="zip_code"
            hasFeedback
            validateStatus={status}
          >
            <Input
              placeholder="00000-000"
              onChange={(e) => {
                const cepInput = e.target.value.replace(/\D/g, '')
                if (cepInput.length === 8) {
                  searchCEP(e.target.value)
                }
              }}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <a
            href="https://buscacepinter.correios.com.br/app/endereco/index.php"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Form.Item
              label={translate('TasksCreateAddress.zipcodeButtonLabel')}
            >
              <Button key="submit" type="primary">
                {translate('TasksCreateAddress.zipcodeButton')}
              </Button>
            </Form.Item>
          </a>
        </Col>
        <Col span={8}>
          <Form.Item
            label={translate('TasksCreateAddress.stateLabel')}
            name="state"
          >
            <Select
              style={{ width: '100%' }}
              placeholder={translate('TasksCreateAddress.statePlaceholder')}
              onChange={(e) => setState(String(e))}
              className="menu_input"
              options={stateOptions}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={translate('TasksCreateAddress.cityLabel')}
            name="city"
          >
            <Input
              placeholder={translate('TasksCreateAddress.cityPlaceholder')}
              onChange={(e) => setCity(e.target.value)}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={14}>
        <Col span={8}>
          <Form.Item
            label={translate('TasksCreateAddress.neighborhoodLabel')}
            name="neighborhood"
          >
            <Input
              placeholder={translate(
                'TasksCreateAddress.neighborhoodPlaceholder',
              )}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={translate('TasksCreateAddress.streetLabel')}
            name="street"
          >
            <Input
              placeholder={translate('TasksCreateAddress.streetPlaceholder')}
              onChange={(e) => setStreet(e.target.value)}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={translate('TasksCreateAddress.numberLabel')}
            name="number"
            rules={[
              {
                required: true,
                message: translate('TasksCreateAddress.numberRule'),
              },
            ]}
          >
            <Input
              placeholder={translate('TasksCreateAddress.numberPlaceholder')}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={14}>
        <Col span={4.8}>
          <Form.Item
            label={translate('TasksCreateAddress.complementLabel')}
            name="complement"
          >
            <Input
              placeholder={translate(
                'TasksCreateAddress.complementPlaceholder',
              )}
            />
          </Form.Item>
        </Col>
        <Col span={4.8}>
          <Form.Item
            label={translate('TasksCreateAddress.mapLabel')}
            name="mapa"
            rules={[
              {
                required: true,
                validator: () => {
                  if (mapValidate) {
                    return Promise.resolve()
                  }
                  return Promise.reject(translate('TasksCreateAddress.mapRule'))
                },
              },
            ]}
          >
            <Button
              key="submit"
              type="primary"
              disabled={!!(state === '' || street === '' || city === '')}
              loading={mapSearch}
              onClick={searchMap}
            >
              {translate('TasksCreateAddress.mapButton')}
            </Button>
          </Form.Item>
        </Col>

        <Col span={4.8}>
          <Form.Item
            hidden={hidden}
            label={translate('TasksCreateAddress.latitudeLabel')}
            name="latitude"
            initialValue={latitude}
            rules={[
              {
                required: !hidden,
                message: translate('TasksCreateAddress.latitudeRule'),
              },
            ]}
          >
            <Input onChange={(e) => formatComma(e.target.value, 'latitude')} />
          </Form.Item>
        </Col>
        <Col span={4.8}>
          <Form.Item
            hidden={hidden}
            label={translate('TasksCreateAddress.longitudeLabel')}
            name="longitude"
            initialValue={longitude}
            rules={[
              {
                required: !hidden,
                message: translate('TasksCreateAddress.longitudeRule'),
              },
            ]}
          >
            <Input onChange={(e) => formatComma(e.target.value, 'longitude')} />
          </Form.Item>
        </Col>
        <Col span={4.8}>
          <a
            href="https://www.latlong.net/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Form.Item
              label={translate('TasksCreateAddress.coordinateLabel')}
              hidden={hidden}
            >
              <Button type="primary">
                {translate('TasksCreateAddress.coordinateButton')}
              </Button>
            </Form.Item>
          </a>
        </Col>
      </Row>

      <MapContainer
        center={[latitude, longitude]}
        zoom={zoomLevel}
        scrollWheelZoom={false}
        style={{ height: '350px', width: '100%' }}
      >
        <ChangeView center={[latitude, longitude]} zoom={zoomLevel} />
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {latitude !== -14.235004 && longitude !== -51.925282 && (
          <Marker position={[latitude, longitude]} />
        )}
      </MapContainer>
    </>
  )
}

export default CreateParentClientAddress
