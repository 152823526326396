/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { DeleteFilled, LoadingOutlined } from '@ant-design/icons'
import { Card, Popconfirm, Space, Table, Tooltip, Button } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import Text from 'antd/lib/typography/Text'

import React from 'react'

import { useListContacts } from '~/hooks/Clients/useListContacts'
import { translate } from '~/utils/locale'
import CreateContact from './Create'
import ContactsFilter from './Filter'
import UpdateContact from './Update'

interface ContactProps {
  name: string
  id: number
  email: string
  office: string
  phone: string
  is_responsible: boolean
  customer: {
    id: string
    contact: {
      id: string
    }
  }
}

const ContactsTab: React.FC = () => {
  const {
    listContacts,
    isFetching,
    deleteContact,
    contactTotal,
    setNumberPage,
    setPageSize,
    isLoading,
  } = useListContacts()

  const columns: ColumnsType<ContactProps> = [
    {
      title: translate('ClientsContactsTab.nameColumn'),
      align: 'left',
      dataIndex: ['contact', 'name'],
      key: 'name',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: translate('ClientsContactsTab.emailColumn'),
      align: 'center',
      key: 'email',
      dataIndex: ['contact', 'email'],
    },
    {
      title: translate('ClientsContactsTab.phoneColumn'),
      align: 'center',
      key: 'phone',
      dataIndex: ['contact', 'phone'],
    },
    {
      title: translate('ClientsContactsTab.officeColumn'),
      align: 'center',
      key: 'office',
      dataIndex: 'office',
    },
    {
      title: translate('ClientsContactsTab.actionsColumn'),
      align: 'center',
      key: 'remove',
      render: (record) => (
        <Space size="middle">
          <UpdateContact updateContacts={record} isEdit={false} />
          <Popconfirm
            title={translate('ClientsContactsTab.deletePopconfirm')}
            onConfirm={() => deleteContact(record.id)}
          >
            <Tooltip title={translate('ClientsContactsTab.deleteTooltip')}>
              <Button ghost danger shape="circle" icon={<DeleteFilled />} />
            </Tooltip>
          </Popconfirm>
        </Space>
      ),
    },
  ]

  return (
    <div>
      <Card
        bordered={false}
        title={
          <Space>
            <b>Contatos</b>
            {isLoading ? (
              <LoadingOutlined spin />
            ) : (
              <Text
                type="secondary"
                style={{ fontWeight: 'normal', fontSize: 14 }}
              >
                {contactTotal !== undefined ? (
                  <>
                    {contactTotal === 1
                      ? `${translate(
                          'ClientsContactsTab.totalText',
                        )} ${contactTotal} ${translate(
                          'ClientsContactsTab.contactText',
                        )}`
                      : `${translate(
                          'ClientsContactsTab.totalText',
                        )} ${contactTotal} ${translate(
                          'ClientsContactsTab.contactsText',
                        )}`}
                  </>
                ) : (
                  ''
                )}
              </Text>
            )}
            {!isLoading && isFetching && <LoadingOutlined spin />}
          </Space>
        }
        style={{
          display: 'inline-block',
          width: '100%',
        }}
        extra={
          <Space size="middle">
            <ContactsFilter />
            <CreateContact />
          </Space>
        }
      >
        <Table
          rowKey={(record) => record.id}
          loading={{
            indicator: <LoadingOutlined />,
            spinning: isFetching,
            size: 'large',
          }}
          columns={columns}
          dataSource={listContacts}
          scroll={{ x: 1000 }}
          pagination={{
            total: contactTotal,
            showSizeChanger: true,
            pageSizeOptions: ['10', '20', '50', '100', '200'],
            defaultPageSize: 10,
            showTotal: (total, range) => `${range[0]} - ${range[1]} / ${total}`,
            onChange: (page, pageSize) => {
              setPageSize(pageSize!)
              setNumberPage(page)
            },
          }}
        />
      </Card>
    </div>
  )
}

export default ContactsTab
