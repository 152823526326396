export default {
  pt: {
    ScalesTeamsList: {
      successMessage: 'Equipe desvinculada da escala com sucesso!',
      errorMessage: 'Erro ao desvincular equipe da escala.',
      deletePopconfirm: 'Deseja mesmo desatribuir a equipe da escala?',
      deleteTooltip: 'Desatribuir Equipe',
    },
  },
  en: {
    ScalesTeamsList: {
      successMessage: 'Team successfully unlinked from the scale!',
      errorMessage: 'Error unlinking team from scale.',
      deletePopconfirm:
        'Do you really want to de-assign the team from the scale?',
      deleteTooltip: 'Unassign Team',
    },
  },
  es: {
    ScalesTeamsList: {
      successMessage: '¡Equipo desvinculado exitosamente de la escala!',
      errorMessage: 'Error al desvincular el equipo de la escala.',
      deletePopconfirm: '¿Realmente quieres desasignar al equipo de la escala?',
      deleteTooltip: 'Desasignar equipo',
    },
  },
}
