export default {
  pt: {
    HelpDeskCreatePage: {
      helmetTitle: 'Novo Ticket',
    },
  },
  en: {
    HelpDeskCreatePage: {
      helmetTitle: 'New Ticket',
    },
  },
  es: {
    HelpDeskCreatePage: {
      helmetTitle: 'Nuevo Ticket',
    },
  },
}
