export default {
  pt: {
    TasksCreateAddress: {
      scaleAddressWarningMessage: 'Essa escala não possui nenhum endereço.',
      zipcodeSearchErrorMessage: 'Falha ao buscar CEP. Tente novamente.',
      geolocationWarningMessage:
        'Nenhum resultado encontrado para este endereço. Por gentileza insira a Latitude e Longitude manualmente.',
      geolocationErrorMessage:
        'Ocorreu um erro ao tentar conseguir a Geolocalização, por gentileza tente novamente ou insira a Latitude e Longitude manualmente.',
      manualAddress: 'Adicionar endereço manualmente',
      clientAddress: 'Adicionar endereço do cliente',
      scaleAddress: 'Adicionar endereço da escala',
      searchScaleClientLabel: 'Buscar endereço por cliente',
      searchScaleClientPlaceholder: 'Selecione o cliente',
      selectAddressLabel: 'Selecione o Endereço',
      selectAddressPlaceholder: 'Informe o endereço',
      businessAddressText: 'Endereço Comercial',
      personalAddressText: 'Endereço Pessoal',
      billingAddressText: 'Endereço de Cobrança',
      zipcodeLabel: 'CEP',
      zipcodeButtonLabel: 'Informação CEP',
      zipcodeButton: 'Não sei meu CEP',
      stateLabel: 'Estado',
      statePlaceholder: 'Informe o estado',
      cityLabel: 'Cidade',
      cityPlaceholder: 'Informe a cidade',
      neighborhoodLabel: 'Bairro',
      neighborhoodPlaceholder: 'Informe o bairro',
      streetLabel: 'Logradouro',
      streetPlaceholder: 'Informe o logradouro',
      numberLabel: 'Número',
      numberRule: 'Por favor informe o número do endereço',
      numberPlaceholder: 'Informe o número',
      complementLabel: 'Complemento',
      complementPlaceholder: 'Informe o complemento',
      mapLabel: 'Mapa',
      mapRule: 'Você precisa validar o endereço.',
      mapButton: 'Validar endereço no mapa',
      latitudeLabel: 'Latitude',
      latitudeRule: 'Por favor insira a latitude',
      longitudeLabel: 'Longitude',
      longitudeRule: 'Por favor insira a longitude',
      coordinateLabel: 'Informação Coordenadas',
      coordinateButton: 'Não sei minhas coordenadas',
      addressNotificationButton: 'Salvar Endereço',
      addressNotificationTitle:
        'Gostaria de cadastrar o endereço preenchido no cliente selecionado?',
    },
  },
  en: {
    TasksCreateAddress: {
      scaleAddressWarningMessage: 'This scale has no address.',
      cepSearchErrorMessage: 'Failed to fetch zip code. Try again.',
      geolocationWarningMessage:
        'No results found for this address. Please enter Latitude and Longitude manually.',
      geolocationErrorMessage:
        'There was an error trying to get Geolocation, please try again or enter Latitude and Longitude manually.',
      manualAddress: 'Add address manually',
      clientAddress: 'Add customer address',
      scaleAddress: 'Add scale address',
      searchScaleClienteLabel: 'Search address by customer',
      searchScaleClientePlaceholder: 'Select customer',
      selectAddressLabel: 'Select Address',
      selectAddressPlaceholder: 'Inform the address',
      businessAddressText: 'Business address',
      personalAddressText: 'Personal address',
      billingAddressText: 'Billing address',
      zipcodeLabel: 'Zipcode',
      zipcodeButtonLabel: 'Zipcode info',
      zipcodeButton: 'I do not know my Zipcode',
      stateLabel: 'State',
      statePlaceholder: 'Inform the state',
      cityLabel: 'City',
      cityPlaceholder: 'Inform the city',
      neighborhoodLabel: 'Neighborhood',
      neighborhoodPlaceholder: 'Inform the neighborhood',
      streetLabel: 'Street',
      streetPlaceholder: 'Inform the street',
      numberLabel: 'Number',
      numberRule: 'Please provide the address number',
      numberPlaceholder: 'Inform the number',
      complementLabel: 'Complement',
      complementPlaceholder: 'Inform the complement',
      mapLabel: 'Map',
      mapRule: 'You need to validate the address.',
      mapButton: 'Validate address on map',
      latitudeLabel: 'Latitude',
      latitudeRule: 'Please enter latitude',
      longitudeLabel: 'Longitude',
      longitudeRule: 'Please enter longitude',
      coordinateLabel: 'Coordinates Info',
      coordinateButton: "I don't know my coordinates",
      addressNotificationButton: 'Save Address',
      addressNotificationTitle:
        'Would you like to register the address entered in the selected customer?',
    },
  },
  es: {
    TasksCreateAddress: {
      scaleAddressWarningMessage: 'Esta escala no tiene dirección.',
      cepSearchErrorMessage:
        'No se pudo obtener el código postal. Inténtalo de nuevo.',
      geolocationWarningMessage:
        'No se han encontrado resultados para esta dirección. Ingrese la latitud y la longitud manualmente.',
      geolocationErrorMessage:
        'Hubo un error al intentar obtener la geolocalización, intente nuevamente o ingrese la latitud y la longitud manualmente.',
      manualAddress: 'Agregar dirección manualmente',
      clientAddress: 'Agregar dirección de cliente',
      scaleAddress: 'Agregar dirección de escala',
      searchScaleClienteLabel: 'Buscar dirección por cliente',
      searchScaleClientePlaceholder: 'Seleccionar cliente',
      selectAddressLabel: 'Seleccionar dirección',
      selectAddressPlaceholder: 'Informar la dirección',
      businessAddressText: 'Dirección de Negocios',
      personalAddressText: 'Dirección Personal',
      billingAddressText: 'Dirección de Envio',
      zipcodeLabel: 'Código postal',
      zipcodeButtonLabel: 'Información del código postal',
      zipcodeButton: 'No conozco mi código postal',
      stateLabel: 'Estado',
      statePlaceholder: 'Informar al estado',
      cityLabel: 'Ciudad',
      cityPlaceholder: 'Informar a la ciudad',
      neighborhoodLabel: 'Barrio',
      neighborhoodPlaceholder: 'Informar al barrio',
      streetLabel: 'Calle',
      streetPlaceholder: 'Informar a la calle',
      numberLabel: 'Número',
      numberRule: 'Por favor proporcione el número de dirección',
      numberPlaceholder: 'Informa el numero',
      complementLabel: 'Complemento',
      complementPlaceholder: 'Informar al complemento',
      mapLabel: 'Mapa',
      mapRule: 'Necesitas validar la dirección.',
      mapButton: 'Validar dirección en el mapa',
      latitudeLabel: 'Latitud',
      latitudeRule: 'Introduce la latitud',
      longitudeLabel: 'Longitud',
      longitudeRule: 'Por favor ingrese la longitud',
      coordinateLabel: 'Información de coordenadas',
      coordinateButton: 'No se mis coordenadas',
      addressNotificationButton: 'Guardar dirección',
      addressNotificationTitle:
        '¿Desea registrar la dirección ingresada en el cliente seleccionado?',
    },
  },
}
