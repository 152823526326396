export default {
  pt: {
    StatusTable: {
      titleColumn: 'Nome',
      statusTypeColumn: 'Tipo do status',
      enabledText: 'Habilitado',
      enabledWithRestrictionsText: 'Habilitado com restrições',
      blockedText: 'Bloqueado',
      customText: 'Customizado',
      descriptionColumn: 'Descrição',
      actionsColumn: 'Ações',
      viewTooltip: 'Visualizar Status',
      deletePopconfirm: 'Deseja mesmo deletar o status?',
      deleteTooltip: 'Deletar',
    },
  },
  en: {
    StatusTable: {
      titleColumn: 'Name',
      statusTypeColumn: 'Status Type',
      enabledText: 'Enabled',
      enabledWithRestrictionsText: 'Enabled with Restrictions',
      blockedText: 'Blocked',
      customText: 'Custom',
      descriptionColumn: 'Descriptions',
      actionsColumn: 'Actions',
      viewTooltip: 'View Status',
      deletePopconfirm: 'Do you really want to delete the status?',
      deleteTooltip: 'Delete',
    },
  },
  es: {
    StatusTable: {
      titleColumn: 'Nombre',
      statusTypeColumn: 'Tipo do estado',
      enabledText: 'Habilitado',
      enabledWithRestrictionsText: 'Habilitado com restrições',
      blockedText: 'Bloqueado',
      customText: 'Personalizado',
      descriptionColumn: 'Descripción',
      actionsColumn: 'Acciones',
      viewTooltip: 'Ver Estado',
      deletePopconfirm: '¿Realmente desea eliminar el estado?',
      deleteTooltip: 'Borrar',
    },
  },
}
