/* eslint-disable react/jsx-curly-newline */
import {
  DeleteOutlined,
  LoadingOutlined,
  UserOutlined,
} from '@ant-design/icons'
import { Avatar, Card, Popconfirm, Space, Tooltip, List, Button } from 'antd'

import { format } from 'date-fns'
import React from 'react'
import { Link } from 'react-router-dom'

import { useTeam } from '~/hooks/Teams/useTeams'

import { LoadingIndicator } from '~/pages/Scale/EditScale/styles'
import { createLetterAvatar } from '~/utils/letter'
import { translate } from '~/utils/locale'
import { stringToHslColor } from '~/utils/stringToHslColor'

import { Container, ListAbout } from './styles'
import CreateTeamCollaborator from '../../Collaborator'
import UpdateTeams from '../../Update'

const TeamDetails: React.FC = () => {
  const { team, isLoading, deleteTeamCollaborator } = useTeam()

  if (isLoading || !team) {
    return (
      <LoadingIndicator>
        <LoadingOutlined style={{ fontSize: 64, margin: 'auto' }} spin />
      </LoadingIndicator>
    )
  }

  const formattedDate = format(
    new Date(team.created_at),
    `dd/MM/yyyy '${translate('TeamsDetails.dateString')}' HH:mm'h'`,
  )

  return (
    <>
      <Card
        bordered={false}
        title={translate('TeamsDetails.cardTitle')}
        extra={<UpdateTeams dataTeams={team} />}
        style={{
          display: 'inline-block',
          width: '100%',
        }}
      >
        <Container>
          <div className="wrapper">
            <Avatar
              shape="square"
              size={155}
              style={{
                fontSize: 40,
                background: '#EAEAEA',
                color: stringToHslColor(`${team.title}`),
              }}
            >
              {createLetterAvatar(`${team.title}`)}
            </Avatar>
            <div className="text">
              <h3>{team.title}</h3>
              <p>
                {translate('TeamsDetails.createdText')}
                &nbsp;
                {formattedDate}
              </p>
              <p>
                <Space>
                  {team.manager !== null ? (
                    <p>
                      {translate('TeamsDetails.responsibleText')}
                      &nbsp;
                      {team?.manager?.contact?.name}
                    </p>
                  ) : (
                    translate('TeamsDetails.noResponsibleText')
                  )}
                </Space>
              </p>
              <p>
                {team.description !== null ? (
                  <p>
                    {translate('TeamsDetails.descriptionText')}
                    &nbsp;
                    {team.description}
                  </p>
                ) : (
                  translate('TeamsDetails.noDescriptionText')
                )}
              </p>
            </div>
          </div>
        </Container>
      </Card>
      <Card
        bordered={false}
        title={translate('TeamsDetails.contributorCardTitle')}
        extra={<CreateTeamCollaborator dataTeam={team} />}
        style={{
          display: 'inline-block',
          width: '100%',
          marginTop: 10,
        }}
      >
        <ListAbout>
          <List
            itemLayout="horizontal"
            dataSource={team.contributorsTeams}
            renderItem={(item) => (
              <List.Item
                actions={[
                  <Tooltip
                    title={translate('TeamsDetails.deleteTooltip')}
                    key="DeleteTooltip"
                  >
                    <Popconfirm
                      title={translate('TeamsDetails.deletePopconfirm')}
                      onConfirm={() =>
                        deleteTeamCollaborator(team?.id, item.collaborator?.id)
                      }
                    >
                      <Button
                        ghost
                        shape="circle"
                        icon={<DeleteOutlined />}
                        size="small"
                        danger
                      />
                    </Popconfirm>
                  </Tooltip>,
                ]}
              >
                {item.collaborator ? (
                  <List.Item.Meta
                    avatar={<Avatar icon={<UserOutlined />} />}
                    title={
                      <Tooltip
                        title={translate('TeamsDetails.viewContributorTooltip')}
                      >
                        <Link
                          to={`/colaboradores/visualizar/colaborador/${item.collaborator?.id}/contato/${item.collaborator?.contact?.id}`}
                        >
                          {item.collaborator?.contact?.name}
                        </Link>
                      </Tooltip>
                    }
                  />
                ) : (
                  translate('TeamsDetails.noContributorText')
                )}
              </List.Item>
            )}
          />
        </ListAbout>
      </Card>
    </>
  )
}

export default TeamDetails
