export default {
  pt: {
    NewCompanyModal: {
      successMessage: 'Empresa criada com sucesso',
      errorMessage: 'Falha em criar uma nova empresa',
      title: 'Criar nova empresa',
      okText: 'Criar',
      cancelText: 'Cancelar',
      verifyErrorMessage: 'Verifique os dados preenchidos',
      nameLabel: 'Nome',
      nameValidationError: 'Por favor digite o nome da sua empresa!',
      planLabel: 'Informe o plano',
      planValidationError: 'Por favor selecione o plano!',
      SelectOptionOne: 'Por colaborador',
      SelectOptionTwo: 'Pacote de Serviços',
    },
  },
  en: {
    NewCompanyModal: {
      successMessage: 'Company created successfully',
      errorMessage: 'Failed to create a new company',
      title: 'Create new company',
      okText: 'Create',
      cancelText: 'Cancel',
      verifyErrorMessage: 'Check the filled data',
      nameLabel: 'Name',
      nameValidationError: 'Please enter your company name!',
      planLabel: 'Inform the plan',
      planValidationError: 'Please select plan!',
      SelectOptionOne: 'Per contributor',
      SelectOptionTwo: 'Service package',
    },
  },
  es: {
    NewCompanyModal: {
      successMessage: 'Empresa creada con éxito',
      errorMessage: 'Error al crear una nueva empresa',
      title: 'Crear nueva empresa',
      okText: 'Crear',
      cancelText: 'Cancelar',
      verifyErrorMessage: 'Verifica los datos llenados',
      nameLabel: 'Nombre',
      nameValidationError: 'Por favor, introduzca el nombre de su empresa.',
      planLabel: 'Informar el plan',
      planValidationError: '¡Seleccione el plan!',
      SelectOptionOne: 'Por contribuyente',
      SelectOptionTwo: 'Paquete de servicios',
    },
  },
}
