export default {
  pt: {
    ScalesContributorsTable: {
      nameColumn: 'Nome',
      contactColumn: 'Contato',
      skillsColumn: 'Habilidades',
      statusColumn: 'Status',
      actionsColumn: 'Ações',
      infoText:
        'Para adicionar colaboradores na escala é necessário que os mesmos estejam cadastrados no sistema.',
      addButton: 'Adicionar colaboradores',
    },
  },
  en: {
    ScalesContributorsTable: {
      nameColumn: 'Name',
      contactColumn: 'Contact',
      skillsColumn: 'Skills',
      statusColumn: 'Status',
      actionsColumn: 'Actions',
      infoText:
        'To add contributors to the scale, they must be registered in the system.',
      addButton: 'Add contributors',
    },
  },
  es: {
    ScalesContributorsTable: {
      nameColumn: 'Nombre',
      contactColumn: 'Contacto',
      skillsColumn: 'Habilidades',
      statusColumn: 'Estado',
      actionsColumn: 'Acciones',
      infoText:
        'Para agregar colaboradores a la báscula, estos deben estar registrados en el sistema.',
      addButton: 'Agregar colaboradores',
    },
  },
}
