import styled from 'styled-components'
import { lighten } from 'polished'

export const Container = styled.div`
  /* #btn_add_to_scale {
    background: var(--secondary-color);
    border: 1px solid var(--secondary-color);

    &:hover {
      background: ${lighten(0.05, '#64C77C')};
      border: 1px solid ${lighten(0.1, '#64C77C')};
    }
  } */
`
