/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState } from 'react'
import { Card, Modal } from 'antd'
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Area,
  AreaChart,
  LabelList,
  LabelProps,
} from 'recharts'
import {
  DashboardTasksDataProps,
  // useDashboardTasks,
} from '~/hooks/Dashboard/useDashboardTasks'
import ModalTasksTable from '~/components/DataManipulation/Dashboard/Tasks/Tables/ModalTable'

interface TasksTotalLineChartProps {
  type: string
}

const TasksTotalLineChart: React.FC<TasksTotalLineChartProps> = ({ type }) => {
  // const { data } = useDashboardTasks()
  const data = {} as DashboardTasksDataProps
  const [tasksData, setTasksData] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false)

  function renderCustomizedLabel({ x, y, value }: LabelProps) {
    return (
      <text
        x={x}
        y={y}
        dy={-4}
        fill="#3C8ACB"
        fontSize={14}
        textAnchor="middle"
      >
        {value}
      </text>
    )
  }

  const handleClick = (data: any) => {
    const tasks = data.activePayload[0].payload.tasks
    setTasksData(tasks)
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  return (
    <Card
      title={
        <div style={{ textAlign: 'center' }}>
          Total de Tarefas nos Últimos dias
        </div>
      }
      style={{ width: '100%', height: '100%', maxHeight: '500px' }}
    >
      <ResponsiveContainer width="100%" height={type === 'dash' ? 180 : 400}>
        <AreaChart data={data?.TasksTotalLineChart} onClick={handleClick}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip />
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#64C77C" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#64C77C" stopOpacity={0} />
            </linearGradient>
          </defs>
          <Area
            type="monotone"
            dataKey="Tarefas"
            stroke="#3C8ACB"
            fill="url(#colorUv)"
            style={{ cursor: 'pointer' }}
          >
            <LabelList
              dataKey="Tarefas"
              position="top"
              content={renderCustomizedLabel}
            />
          </Area>
        </AreaChart>
      </ResponsiveContainer>

      <Modal
        width="80vw"
        open={isModalOpen}
        onCancel={closeModal}
        footer={null}
      >
        <ModalTasksTable tasks={tasksData} />
      </Modal>
    </Card>
  )
}

export default TasksTotalLineChart
