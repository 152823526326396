import React from 'react'
import { Helmet } from 'react-helmet'

import EquipmentsBoard from '~/components/DataManipulation/Equipments'

import { CategoryProvider } from '~/hooks/Equipments/Category/CategoryHooks'
import { ListEquipmentsProvider } from '~/hooks/Equipments/ListEquipmentsHooks'
import { translate } from '~/utils/locale'

import { Container } from './styles'

const Equipments: React.FC = () => {
  return (
    <Container>
      <Helmet>
        <title>{translate('Equipments.helmetTitle')}</title>
      </Helmet>
      <CategoryProvider>
        <ListEquipmentsProvider>
          <EquipmentsBoard />
        </ListEquipmentsProvider>
      </CategoryProvider>
    </Container>
  )
}

export default Equipments
