/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
import React, { useState } from 'react'

import { Tooltip, Button, Modal, Select, Checkbox } from 'antd'
import { UserAddOutlined } from '@ant-design/icons'
import { Container } from './styles'

import { useUserGroup, UserGroupProps } from '~/hooks/Groups/useUserGroup'
import { useListGroup } from '~/hooks/Groups/useListGroup'
import { translate } from '~/utils/locale'

interface UserGroup {
  group_id: string
}

const { Option } = Select

const ContributorPermission: React.FC<UserGroup> = ({ group_id }) => {
  const { listGroupsRefetch } = useListGroup()
  const { userGroup, createUser } = useUserGroup()
  const [userId, setUserId] = useState()
  const [keepRegister, setKeepRegister] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)

  function onUserChange(value: any) {
    setUserId(value)
  }

  function onCreateGroup() {
    try {
      createUser(userId, group_id)
      if (!keepRegister) {
        setIsModalVisible(false)
      }
      listGroupsRefetch()
    } catch (err) {
      if (!keepRegister) {
        setIsModalVisible(false)
      }
    }
  }

  const showModal = (event: any) => {
    event.stopPropagation()
    setIsModalVisible(true)
  }

  const closeModal = (event: any) => {
    event.stopPropagation()
    setIsModalVisible(false)
  }

  return (
    <Container onClick={(e) => e.stopPropagation()}>
      <Tooltip
        title="Disponível em Breve!"
        // title={translate('GroupsCreateContributorPermission.addTooltip')}
        key="tool-new"
      >
        <Button
          disabled
          type="primary"
          icon={<UserAddOutlined />}
          onClick={showModal}
        >
          {translate('GroupsCreateContributorPermission.addButton')}
        </Button>
      </Tooltip>

      <Modal
        title={translate('GroupsCreateContributorPermission.modalTitle')}
        open={isModalVisible}
        onOk={closeModal}
        onCancel={closeModal}
        footer={[
          <Checkbox
            key="KeepRegister"
            onChange={(e) => {
              setKeepRegister(e.target.checked)
            }}
            checked={keepRegister}
          >
            {translate('GroupsCreateContributorPermission.keepAddText')}
          </Checkbox>,
          <Button key="back" onClick={closeModal}>
            {translate('GroupsCreateContributorPermission.closeButton')}
          </Button>,
          <Button key="save" type="primary" onClick={onCreateGroup}>
            {translate('GroupsCreateContributorPermission.saveButton')}
          </Button>,
        ]}
      >
        <p>{translate('GroupsCreateContributorPermission.userLabel')}</p>
        <Select
          placeholder={translate(
            'GroupsCreateContributorPermission.userPlaceholder',
          )}
          style={{ width: '80%' }}
          onChange={onUserChange}
        >
          {userGroup?.map((users: UserGroupProps) => (
            <Option value={users.user.id} key={users.id}>
              {users.name}
            </Option>
          ))}
        </Select>
      </Modal>
    </Container>
  )
}

export default ContributorPermission
