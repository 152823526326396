import styled from 'styled-components'

interface CoverProps {
  typeBadge: string
  shape: string
}

interface Circle {
  typeBadge: string
}

export const Container = styled.div`
  margin-top: 1rem;

  display: flex;
  gap: 2rem;
`

export const Card = styled.div`
  width: 100%;
  max-width: 180px;
  height: 280px;

  border-radius: 20px;
  background: #f1f1f1;
`
export const Cover = styled.div<CoverProps>`
  width: 100%;
  height: 70px;
  background: #6297e4;

  clip-path: ${(p) =>
    p.shape === 'retangle'
      ? 'polygon(0% 0%, 100% 0%, 100% 49.5%, 0% 100%)'
      : 'none'};

  border-top-right-radius: ${(p) =>
    p.typeBadge === 'coverTop' ? '20px' : '0'};
  border-top-left-radius: ${(p) => (p.typeBadge === 'coverTop' ? '20px' : '0')};
`
export const Circle = styled.div<Circle>`
  width: 80px;
  height: 80px;
  background: #333536;
  border-radius: 100%;

  position: relative;
  bottom: ${(p) => (p.typeBadge === 'coverTop' ? '2rem' : 0)};
`
export const ContentSkeleton = styled.div`
  width: 100%;
  height: 50px;
  background: #a1a1a1;
  border-radius: 10px;
  margin-bottom: 0.8rem;
`
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
`
export const ContentBottom = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  height: 100%;
`
