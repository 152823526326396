import { lighten } from 'polished'
import styled from 'styled-components'

export const Container = styled.div`
  .ant-collapse {
    margin-top: 10px;
  }

  #btn_DeleteAddress {
    color: #f04055;
  }

  #update_address {
    color: var(--primary-color);
  }
`

export const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`

export const Address = styled.div`
  flex: 1;

  .text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-content: flex-start;
    padding-bottom: 23px;
  }
`

export const Map = styled.div`
  margin-left: 10px;
  flex: 0.3;
`
