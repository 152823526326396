/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-underscore-dangle */
import { CloseOutlined, FilterOutlined } from '@ant-design/icons'
import {
  Col,
  Drawer,
  Row,
  Form,
  Button,
  Input,
  Select,
  Tooltip,
  DatePicker,
} from 'antd'

import React, { useState, useEffect } from 'react'
import dayjs from 'dayjs'
import { useListClients } from '~/hooks/Clients/useListClients'
import { useListContributors } from '~/hooks/Contributors/useListContributors'

import { useFilterKanban } from '~/hooks/Tasks/Kanban/useFilterKanban'
import { useTaskType } from '~/hooks/Tasks/TaskType/useTaskType'

import { useTeams } from '~/hooks/Teams/useListTeams'
import { translate } from '~/utils/locale'

import { Container } from './styles'

const FilterTasks: React.FC = () => {
  const { taskType } = useTaskType()
  const { data } = useListContributors()
  const { teams } = useTeams()
  const { clients } = useListClients()

  const { filterOptions, handleResetFilters, handleUpdateFilters } =
    useFilterKanban()

  const { Option } = Select
  const [form] = Form.useForm()

  const [visible, setVisible] = useState(false)

  useEffect(() => {
    form.setFieldsValue({
      responsibleContributors: filterOptions?.responsibleContributors,
      responsibleTeams: filterOptions?.responsibleTeams,
      address: filterOptions?.address,
      customers_id: filterOptions?.customers_id,
      internal_code: filterOptions?.internal_code,
      current_status: filterOptions?.current_status,
      external_code: filterOptions?.external_code,
      priority: filterOptions?.priority,
      fromDate: dayjs(filterOptions?.fromDate),
      toDate: dayjs(filterOptions?.toDate),
    })
  }, [filterOptions, form])

  const onFinish = (dataForm: any) => {
    handleUpdateFilters(dataForm)
    setVisible(false)
  }

  const onReset = () => {
    handleResetFilters()
    form.resetFields()
    setVisible(false)
  }

  const showDrawer = () => {
    setVisible(true)
  }

  const onClose = () => {
    setVisible(false)
  }

  const isFilter = Object.keys(filterOptions).length !== 0

  return (
    <Container isFilter={isFilter}>
      <Button
        type="primary"
        onClick={showDrawer}
        loading={!clients || !data || !taskType || !teams}
        icon={
          !isFilter ? (
            <FilterOutlined />
          ) : (
            <Tooltip title={translate('TasksOptionsKanbanFilter.cleanTooltip')}>
              <CloseOutlined onClick={onReset} />
            </Tooltip>
          )
        }
        id="btn_filter_tasks"
      >
        {isFilter
          ? translate('TasksOptionsKanbanFilter.filterAppliedText')
          : translate('TasksOptionsKanbanFilter.filterText')}
      </Button>
      <Drawer
        forceRender
        title={translate('TasksOptionsKanbanFilter.drawerTitle')}
        width={580}
        onClose={onClose}
        open={visible}
        style={{ paddingBottom: 80 }}
      >
        <Form layout="vertical" form={form} onFinish={onFinish}>
          <Row gutter={10}>
            <Col span={12}>
              <Form.Item
                label={translate('TasksFilter.fromDateLabel')}
                name="fromDate"
              >
                <DatePicker
                  format="DD/MM/YYYY"
                  placeholder={translate('TasksFilter.fromDatePlaceholder')}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={translate('TasksFilter.toDateLabel')}
                name="toDate"
              >
                <DatePicker
                  format="DD/MM/YYYY"
                  placeholder={translate('TasksFilter.toDatePlaceholder')}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}>
            {data && data.length > 0 && (
              <Col span={12}>
                <Form.Item
                  label={translate('TasksOptionsKanbanFilter.contributorLabel')}
                  name="responsibleContributors"
                >
                  <Select
                    placeholder={translate(
                      'TasksOptionsKanbanFilter.contributorPlaceholder',
                    )}
                    optionFilterProp="children"
                    showSearch
                  >
                    {data.map((colaborator) => (
                      <Option value={colaborator.id} key={colaborator.id}>
                        {colaborator.contact
                          ? colaborator.contact.name
                          : 'Sem informações'}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}

            <Col span={12}>
              <Form.Item
                label={translate('TasksOptionsKanbanFilter.teamLabel')}
                name="responsibleTeams"
              >
                <Select
                  placeholder={translate(
                    'TasksOptionsKanbanFilter.teamPlaceholder',
                  )}
                  optionFilterProp="children"
                  showSearch
                >
                  {teams?.map((team) => (
                    <Option value={team.id} key={team.id}>
                      {team.title}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={8}>
            <Col span={12}>
              <Form.Item
                label={translate('TasksOptionsKanbanFilter.clientLabel')}
                name="customers_id"
              >
                <Select
                  placeholder={translate(
                    'TasksOptionsKanbanFilter.clientPlaceholder',
                  )}
                  optionFilterProp="children"
                  showSearch
                >
                  {clients?.map((client) => (
                    <Option value={client.id} key={client.id}>
                      {client.contact.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label={translate('TasksOptionsKanbanFilter.statusLabel')}
                name="current_status"
              >
                <Input
                  placeholder={translate(
                    'TasksOptionsKanbanFilter.statusPlaceholder',
                  )}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={8}>
            <Col span={12}>
              <Form.Item
                label={translate('TasksOptionsKanbanFilter.priorityLabel')}
                name="priority"
              >
                <Select
                  placeholder={translate(
                    'TasksOptionsKanbanFilter.priorityPlaceholder',
                  )}
                >
                  <Option value="high">
                    {translate('TasksOptionsKanbanFilter.priorityHigh')}
                  </Option>
                  <Option value="medium">
                    {translate('TasksOptionsKanbanFilter.priorityMedium')}
                  </Option>
                  <Option value="low">
                    {translate('TasksOptionsKanbanFilter.priorityLow')}
                  </Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={12}>
              <Form.Item
                label={translate('TasksOptionsKanbanFilter.internalCodeLabel')}
                name="internal_code"
              >
                <Input
                  placeholder={translate(
                    'TasksOptionsKanbanFilter.internalCodePlaceholder',
                  )}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={translate('TasksOptionsKanbanFilter.externalCodeLabel')}
                name="external_code"
              >
                <Input
                  placeholder={translate(
                    'TasksOptionsKanbanFilter.externalCodePlaceholder',
                  )}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                label={translate('TasksOptionsKanbanFilter.addressLabel')}
                name="address"
              >
                <Input
                  placeholder={translate(
                    'TasksOptionsKanbanFilter.addressPlaceholder',
                  )}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={12}>
              <Form.Item style={{ width: '100%' }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: '100%' }}
                >
                  {translate('TasksOptionsKanbanFilter.applyButton')}
                </Button>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item style={{ width: '100%' }}>
                <Button onClick={onReset} style={{ width: '100%' }}>
                  {translate('TasksOptionsKanbanFilter.cleanButton')}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </Container>
  )
}

export default FilterTasks
