import React from 'react'
import { Helmet } from 'react-helmet'
import ScalesBoard from '~/components/DataManipulation/Scales'
import { translate } from '~/utils/locale'

import { Container } from './styles'

const Scales: React.FC = () => {
  return (
    <Container>
      <Helmet>
        <title>{translate('Scales.helmetTitle')}</title>
      </Helmet>
      <ScalesBoard />
    </Container>
  )
}

export default Scales
