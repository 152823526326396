/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-one-expression-per-line */
import { LoadingOutlined, MenuUnfoldOutlined } from '@ant-design/icons'
import { Card, Timeline, Tooltip } from 'antd'
import React, { useState, useEffect } from 'react'
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'

import L from 'leaflet'
import { format } from 'date-fns'

import { Container, Map, Accompaniment, ContainerTimeline } from './styles'

import { useTask } from '~/hooks/Tasks/useTask'
import { LoadingIndicator } from '~/pages/Scale/EditScale/styles'
import { useListContributors } from '~/hooks/Contributors/useListContributors'
import { translate } from '~/utils/locale'

interface Status {
  type: string
  created_at: Date
  initialTime: string
  colors: string
  collaborator_id: string
  location: {
    x: number
    y: number
  }
}

// import { Container } from './styles';

const TaskStatus: React.FC = () => {
  const { task, isLoading } = useTask()
  const { data } = useListContributors()

  const [colorsPin, setColors] = useState('')

  const [isExpanded, setIsExpanded] = useState<boolean>()

  function handleExpanded() {
    if (isExpanded) {
      setIsExpanded(false)
    } else {
      setIsExpanded(true)
    }
  }

  useEffect(() => {
    const stateTitle = task?.status.map((s) => {
      return s.type
    })

    const statusState = stateTitle?.sort()

    if (statusState?.indexOf('received') === 4) {
      setColors('#16a085')
    }
    if (statusState?.indexOf('viewed') === 6) {
      setColors('#133b7c')
    }
  }, [task])

  if (isLoading || !task) {
    return (
      <LoadingIndicator>
        <LoadingOutlined style={{ fontSize: 64, margin: 'auto' }} spin />
      </LoadingIndicator>
    )
  }

  const statusFormatted = task.status.map((taskStatus: any) => {
    const dataFormat = {
      ...taskStatus,
      initialTime: format(
        new Date(taskStatus.created_at),
        `dd/MM/yyyy '${translate('TasksTabsStatus.dateString')}' HH:mm'h'`,
      ),
      colors: taskStatus.type.indexOf(0) ? '#16a085' : '#133b7c',
    }

    return dataFormat
  })

  const coordinates: any = statusFormatted.filter((coord) => {
    if (coord.location?.x && coord.location?.y !== null) {
      return coord
    }

    return coordinates
  })

  const iconAgent = L.divIcon({
    className: '',
    html: `<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128"><defs><style>.cls-1{fill:${colorsPin};}.cls-2{fill:${colorsPin};}</style></defs><title>b</title><path class="cls-1" d="M64.00178,3.36652c-25.74943,0-43.04956,14.75866-43.04956,36.7246,0,29.11223,37.01485,81.60069,37.38345,82.01113a7.60318,7.60318,0,0,0,11.3233.00579c.37394-.41623,37.3888-52.90469,37.3888-82.01692C107.04778,18.12518,89.74853,3.36652,64.00178,3.36652ZM64,74.73868a28.29593,28.29593,0,1,1,28.296-28.296A28.29592,28.29592,0,0,1,64,74.73868Z"/><path class="cls-2" d="M82.84186,58.57151c-.155.24618-.31.4741-.4741.71116a22.39884,22.39884,0,0,1-36.73256.00913c-.17323-.23705-.33733-.48323-.48323-.72935.01825-.12765.0365-.24618.0547-.3647a4.03615,4.03615,0,0,1,2.16079-2.90834c3.76529-1.87811,12.00714-4.6406,12.00714-4.6406v-2.726l-.22793-.17323a7.86155,7.86155,0,0,1-2.99042-5.00525l-.04557-.29175h-.2188a3.02492,3.02492,0,0,1-2.81719-1.88724,3.275,3.275,0,0,1-.41935-1.61368,3.1367,3.1367,0,0,1,.20967-1.12143,1.58361,1.58361,0,0,1,.61083-.9846l.76586-.45585-.18235-.82056c-1.34018-5.86225,3.04512-11.141,9.06235-11.51483a.5194.5194,0,0,1,.11853-.00913c.10027-.00913.20055-.0182.30088-.0182h.9117c.10027,0,.20055.00907.30082.0182a.51916.51916,0,0,1,.11853.00913c6.02641.37383,10.41171,5.65258,9.07147,11.51483l-.19148.82056.76586.45585a1.54549,1.54549,0,0,1,.61083.9846,3.14584,3.14584,0,0,1,.2188,1.12143,3.37534,3.37534,0,0,1-.41935,1.61368,3.02486,3.02486,0,0,1-2.81719,1.88724h-.2188l-.0547.29175a7.81113,7.81113,0,0,1-2.98129,5.00525l-.22793.17323v2.726s8.24185,2.76249,11.99806,4.6406a4.01318,4.01318,0,0,1,2.16074,2.90834C82.80541,58.31626,82.82361,58.44391,82.84186,58.57151Z"/></svg>`,
    iconSize: [40, 50],
    iconAnchor: [20, 15],
  })

  return (
    <Card
      bordered={false}
      title={translate('TasksTabsStatus.cardTitle')}
      style={{
        display: 'inline-block',
        width: '100%',
      }}
    >
      <Container>
        {task ? (
          <Map isExpanded={isExpanded}>
            <h3>{translate('TasksTabsStatus.mapTitle')}</h3>
            <MapContainer
              center={
                task.address.location.x && task.address.location.x
                  ? [task.address.location.x, task.address.location.y]
                  : [0, 0]
              }
              zoom={13}
              scrollWheelZoom={false}
              style={{ height: '450px', width: '100%' }}
            >
              <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              {task.address.location.x &&
                task.address.location.y &&
                task.address.location.x !== 0 &&
                task.address.location.y !== 0 && (
                  <Marker
                    position={[
                      task.address.location.x,
                      task.address.location.y,
                    ]}
                  />
                )}

              {coordinates.map((c: Status, index: number) => (
                <Marker
                  position={[c.location.x, c.location.y]}
                  icon={iconAgent}
                  key={index}
                >
                  <Popup>
                    {c.type === 'viewed' ? (
                      <p>{translate('TasksTabsStatus.statusViewed')}</p>
                    ) : (
                      ''
                    )}
                    {c.type === 'en-route' ? (
                      <p>{translate('TasksTabsStatus.statusEnRoute')}</p>
                    ) : (
                      ''
                    )}
                    {c.type === 'check-in' ? (
                      <p>{translate('TasksTabsStatus.statusCheckIn')}</p>
                    ) : (
                      ''
                    )}
                    {c.type === 'pause-task' ? (
                      <p>{translate('TasksTabsStatus.statusPauseTask')}</p>
                    ) : (
                      ''
                    )}
                    {c.type === 'resume-working' ? (
                      <p>{translate('TasksTabsStatus.statusResumeWorking')}</p>
                    ) : (
                      ''
                    )}
                    {c.type === 'checkout' ? (
                      <p>{translate('TasksTabsStatus.statusCheckout')}</p>
                    ) : (
                      ''
                    )}
                    <p>{c.initialTime}</p>
                  </Popup>
                </Marker>
              ))}
            </MapContainer>
          </Map>
        ) : (
          <LoadingOutlined spin />
        )}
        <Accompaniment>
          <div className="title">
            <h3>{translate('TasksTabsStatus.statusTitle')}</h3>
            <Tooltip title={!isExpanded ? 'Fechar' : 'Expandir'}>
              <MenuUnfoldOutlined onClick={handleExpanded} />
            </Tooltip>
          </div>

          <ContainerTimeline>
            <Timeline>
              {statusFormatted.map((st) => (
                <Timeline.Item color={st.colors} key={st.id}>
                  {st.type === 'received' ? (
                    <p>{translate('TasksTabsStatus.statusReceived')}</p>
                  ) : (
                    ''
                  )}
                  {st.type === 'viewed' ? (
                    <p>{translate('TasksTabsStatus.statusViewed')}</p>
                  ) : (
                    ''
                  )}
                  {st.type === 'en-route' ? (
                    <p>{translate('TasksTabsStatus.statusEnRoute')}</p>
                  ) : (
                    ''
                  )}
                  {st.type === 'check-in' ? (
                    <p>{translate('TasksTabsStatus.statusCheckIn')}</p>
                  ) : (
                    ''
                  )}
                  {st.type === 'pause-task' ? (
                    <p>{translate('TasksTabsStatus.statusPauseTask')}</p>
                  ) : (
                    ''
                  )}
                  {st.type === 'resume-working' ? (
                    <p>{translate('TasksTabsStatus.statusResumeWorking')}</p>
                  ) : (
                    ''
                  )}
                  {st.type === 'checkout' ? (
                    <p>{translate('TasksTabsStatus.statusCheckout')}</p>
                  ) : (
                    ''
                  )}
                  {data?.map((c) => (
                    <div key={c.id}>
                      {c.id === task.responsible?.id ? (
                        <p>
                          {translate('TasksTabsStatus.contributorText')}{' '}
                          {c.contact.name}
                        </p>
                      ) : (
                        ''
                      )}
                    </div>
                  ))}
                  <p style={{ color: '#A7A7A7' }}>{st.initialTime}</p>
                </Timeline.Item>
              ))}
            </Timeline>
          </ContainerTimeline>
        </Accompaniment>
      </Container>
    </Card>
  )
}

export default TaskStatus
