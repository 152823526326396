export default {
  pt: {
    EquipmentsTabsAttachments: {
      loadingText: 'Carregando...',
      cardTitle: 'Documentos/Anexos',
      downloadTooltip: 'Fazer download',
      deletePopconfirm: 'Deseja mesmo deletar o documento/anexo?',
      deleteTooltip: 'Deletar documento/anexo',
    },
  },
  en: {
    EquipmentsTabsAttachments: {
      loadingText: 'Loading...',
      cardTitle: 'Documents/Attachments',
      downloadTooltip: 'Download',
      deletePopconfirm: 'Do you really want to delete the document/attachment?',
      deleteTooltip: 'Delete document/attachment',
    },
  },
  es: {
    EquipmentsTabsAttachments: {
      loadingText: 'Cargando...',
      cardTitle: 'Documentos/Archivos adjuntos',
      downloadTooltip: 'Descargar',
      deletePopconfirm:
        '¿Realmente desea eliminar el documento/archivo adjunto?',
      deleteTooltip: 'Eliminar documento/archivo adjunto',
    },
  },
}
