/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'

import { Container } from './styles'
import { useParentCustomer } from '~/hooks/Clients/useParentCustomer'
import { Button, Card, Col, Row, Space } from 'antd'
import { EditOutlined, LoadingOutlined } from '@ant-design/icons'
import { translate } from '~/utils/locale'
import { FiClock, FiDollarSign, FiTruck } from 'react-icons/fi'

import Text from 'antd/lib/typography/Text'
import CurrencyFormatter from '~/components/DataManipulation/Misc/CurrencyFormater'

const DetailsFinancialParents: React.FC = () => {
  const { parentCustomer, isLoading, setIsEditFinancial } = useParentCustomer()

  if (isLoading || !parentCustomer) {
    return <LoadingOutlined style={{ fontSize: 64, margin: 'auto' }} spin />
  }

  return (
    <Container>
      <Card
        bordered={false}
        title={translate('ClientsPrincipalTabsFinancial.financialTitleCard')}
        extra={
          <Space>
            <Button
              type="primary"
              icon={<EditOutlined />}
              onClick={() => setIsEditFinancial(true)}
              id="btn-edit-parent-customers-financal"
            >
              {translate('TasksTabsDetails.editButton')}
            </Button>
          </Space>
        }
        style={{
          display: 'inline-block',
          width: '100%',
        }}
      >
        <>
          <Row gutter={12}>
            <Col span={8}>
              <div className="text">
                <div>
                  <FiDollarSign />
                  <Text strong>
                    {translate('ClientsPrincipalTabsFinancial.valueText')}
                  </Text>
                </div>
                {parentCustomer.financial?.task_value ? (
                  <Text>
                    <CurrencyFormatter
                      value={parentCustomer.financial?.task_value}
                    />
                  </Text>
                ) : (
                  <Text>
                    {translate('ClientsPrincipalTabsFinancial.kmValueText')}
                  </Text>
                )}
              </div>
            </Col>
            <Col span={8}>
              <div className="text">
                <div>
                  <FiClock />
                  <Text strong>
                    {translate('ClientsPrincipalTabsFinancial.hoursText')}
                  </Text>
                </div>
                {parentCustomer.financial.amount_hours ? (
                  <Text>{parentCustomer.financial.amount_hours} horas</Text>
                ) : (
                  <Text>
                    {translate('ClientsPrincipalTabsFinancial.kmValueText')}
                  </Text>
                )}
              </div>
            </Col>
            <Col span={8}>
              <div className="text">
                <div>
                  <FiClock />
                  <Text strong>
                    {translate('ClientsPrincipalTabsFinancial.valueHoursText')}
                  </Text>
                </div>
                {parentCustomer.financial.amount_hours_additional ? (
                  <Text>
                    <CurrencyFormatter
                      value={parentCustomer.financial.amount_hours_additional}
                    />
                  </Text>
                ) : (
                  <Text>
                    {translate('ClientsPrincipalTabsFinancial.kmValueText')}
                  </Text>
                )}
              </div>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={8}>
              <div className="text">
                <div>
                  <FiTruck />
                  <Text strong>
                    {translate('ClientsPrincipalTabsFinancial.kmText')}
                  </Text>
                </div>
                {parentCustomer.financial.number_km ? (
                  <Text>{parentCustomer.financial.number_km} km</Text>
                ) : (
                  <Text>
                    {translate('ClientsPrincipalTabsFinancial.kmValueText')}
                  </Text>
                )}
              </div>
            </Col>
            <Col span={8}>
              <div className="text">
                <div>
                  <FiTruck />
                  <Text strong>
                    {translate('ClientsPrincipalTabsFinancial.kmValueText')}
                  </Text>
                </div>
                {parentCustomer.financial.number_km_additional ? (
                  <Text>
                    <CurrencyFormatter
                      value={parentCustomer.financial.number_km_additional}
                    />
                  </Text>
                ) : (
                  <Text>
                    {translate('ClientsPrincipalTabsFinancial.kmValueText')}
                  </Text>
                )}
              </div>
            </Col>
          </Row>
        </>
      </Card>
    </Container>
  )
}

export default DetailsFinancialParents
