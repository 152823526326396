/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react'

import { LoadingOutlined } from '@ant-design/icons'
import Text from 'antd/lib/typography/Text'
import { format } from 'date-fns'
import { Col, Collapse, Divider, Empty, Row, Tag } from 'antd'
import { Wrapper, Container, Cards } from './styles'
import { useTask } from '~/hooks/Tasks/useTask'
import { useCompanies } from '~/hooks/Companies/useCompanies'
import { useListContributors } from '~/hooks/Contributors/useListContributors'
import { useListClients } from '~/hooks/Clients/useListClients'
import { useTaskType } from '~/hooks/Tasks/TaskType/useTaskType'
import { translate } from '~/utils/locale'

const DetailsTaskHistoric: React.FC = () => {
  const { task, isLoading } = useTask()
  const { companiesData } = useCompanies()

  const { data } = useListContributors()
  const { clients } = useListClients()
  const { taskType } = useTaskType()

  if (isLoading || !task) {
    return <LoadingOutlined style={{ fontSize: 64, margin: 'auto' }} spin />
  }

  return (
    <Wrapper>
      {task.historic.length !== 0 ? (
        <Container>
          {task.historic.map((historic, index) => (
            <Cards key={index}>
              <div className="title">
                <Text strong>
                  Tarefa atualizada em &nbsp;
                  {format(
                    new Date(historic.created_at),
                    `dd/MM/yyyy '${translate(
                      'TasksTabsDetails.dateString',
                    )}' HH:mm'h'`,
                  )}
                  &nbsp; por {companiesData?.[0].collaborator.contact.name}
                </Text>
              </div>

              <Divider />

              <Collapse ghost>
                <Collapse.Panel header="Dados da Tarefa" key={historic.id}>
                  <div className="description">
                    <div className="text">
                      {historic.external_code !== null ? (
                        <Text>
                          <b>Código Externo:</b>
                          {historic.external_code}
                        </Text>
                      ) : (
                        <Text>
                          <b>Código Externo:</b> Nenhum dado fornecido
                        </Text>
                      )}
                    </div>

                    <div className="text">
                      {historic.date !== null ? (
                        <Text>
                          <b>Data da Tarefa:</b>
                          {format(
                            new Date(historic.date),
                            `dd/MM/yyyy '${translate(
                              'TasksTabsDetails.dateString',
                            )}' HH:mm'h'`,
                          )}
                        </Text>
                      ) : (
                        <Text>
                          <b>Data da Tarefa:</b> Nenhum dado fornecido
                        </Text>
                      )}
                    </div>

                    <div className="text">
                      {historic.date !== null ? (
                        <Text>
                          <b>Responsável:</b>
                          {data.map((contributors) => (
                            <>
                              {contributors.id === historic.responsible ? (
                                <>{contributors.contact.name}</>
                              ) : (
                                ''
                              )}
                            </>
                          ))}
                        </Text>
                      ) : (
                        <Text>
                          <b>Responsável:</b> Nenhum dado fornecido
                        </Text>
                      )}
                    </div>

                    <div className="text">
                      {historic.date !== null ? (
                        <Text>
                          <b>Cliente:</b>
                          {clients?.map((client) => (
                            <>
                              {client.id === historic.customer_id ? (
                                <>{client.contact.name}</>
                              ) : (
                                ''
                              )}
                            </>
                          ))}
                        </Text>
                      ) : (
                        <Text>
                          <b>Cliente:</b> Nenhum dado fornecido
                        </Text>
                      )}
                    </div>

                    <div className="text">
                      {historic.date !== null ? (
                        <Text>
                          <b>Tipo de Tarefa:</b>
                          {taskType?.map((tasksType) => (
                            <>
                              {tasksType._id === historic.task_type_id ? (
                                <>{tasksType.title}</>
                              ) : (
                                ''
                              )}
                            </>
                          ))}
                        </Text>
                      ) : (
                        <Text>
                          <b>Tipo de Tarefa:</b> Nenhum dado fornecido
                        </Text>
                      )}
                    </div>
                  </div>

                  <div className="text">
                    {historic.priority !== null ? (
                      <Text>
                        <b>Prioridade:</b>
                        {historic.priority === 'low' ? (
                          <Tag color="green">
                            {translate('TasksTabsDetails.priorityLow')}
                          </Tag>
                        ) : (
                          ''
                        )}
                        {historic.priority === 'medium' ? (
                          <Tag color="yellow">
                            {translate('TasksTabsDetails.priorityMedium')}
                          </Tag>
                        ) : (
                          ''
                        )}
                        {historic.priority === 'high' ? (
                          <Tag color="red">
                            {translate('TasksTabsDetails.priorityHigh')}
                          </Tag>
                        ) : (
                          ''
                        )}
                      </Text>
                    ) : (
                      <Text>
                        <b>Prioridade:</b> Nenhum dado fornecido
                      </Text>
                    )}
                  </div>

                  <div className="text">
                    {historic.duration_time !== null ? (
                      <Text>
                        <b>Duração da Tarefa:</b>
                        {historic.duration_time}
                      </Text>
                    ) : (
                      <Text>
                        <b>Duração da Tarefa:</b> Nenhum dado fornecido
                      </Text>
                    )}
                  </div>

                  <div className="text">
                    {historic.value !== null ? (
                      <Text>
                        <b>Valor da Tarefa:</b>
                        {historic.value}
                      </Text>
                    ) : (
                      <Text>
                        <b>Valor da Tarefa:</b> Nenhum dado fornecido
                      </Text>
                    )}
                  </div>

                  <div className="text">
                    {historic.description !== null ? (
                      <Collapse ghost>
                        <Collapse.Panel header="Descrição" key={historic.id}>
                          <Text>{historic.description}</Text>
                        </Collapse.Panel>
                      </Collapse>
                    ) : (
                      <Text>Descrição: Nenhum dado fornecido</Text>
                    )}
                  </div>

                  <div className="text">
                    {historic.address !== null ? (
                      <Collapse ghost>
                        <Collapse.Panel header="Endereço" key={historic.id}>
                          <Row gutter={12}>
                            <Col span={6}>
                              <div className="text">
                                <Text strong>
                                  {translate('TasksTabsDetails.zipcodeText')}
                                </Text>
                                <Text>{task.address.zip_code}</Text>
                              </div>
                            </Col>
                            <Col span={18}>
                              <div className="text">
                                <Text strong>
                                  {translate('TasksTabsDetails.addressText')}
                                </Text>
                                <Text>
                                  {task.address.street},{task.address.number} -{' '}
                                  {task.address.neighborhood}
                                </Text>
                              </div>
                            </Col>
                            <Col>
                              <div className="text">
                                <Text strong>
                                  {translate('TasksTabsDetails.cityText')}
                                </Text>
                                <Text>
                                  {task.address.city} -{task.address.state}
                                </Text>
                              </div>
                            </Col>
                          </Row>
                        </Collapse.Panel>
                      </Collapse>
                    ) : (
                      <Text>Endereço: Nenhum dado fornecido</Text>
                    )}
                  </div>
                </Collapse.Panel>
              </Collapse>
            </Cards>
          ))}
        </Container>
      ) : (
        <Empty />
      )}
    </Wrapper>
  )
}

export default DetailsTaskHistoric
