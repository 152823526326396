export default {
  pt: {
    HelpDeskCreate: {
      pageTitle: 'Novo Ticket',
      pageSubTitle: 'Preencha os dados do ticket',
      newButton: 'Novo Ticket',
      keepRegisterText: 'Continuar cadastrando tickets?',
      registerButton: 'Cadastrar',
      titleLabel: 'Assunto',
      titleRule: 'Preencha o assunto do ticket.',
      titlePlaceholder: 'Informe o assunto do ticket',
      solicitationLabel: 'Tipo de Solicitação',
      solicitationRule: 'Informe o tipo de solicitação.',
      solicitationPlaceholder: 'Informe o tipo de solicitação',
      clientLabel: 'Nome do Solicitante',
      clientRule: 'Preencha o nome do solicitante',
      clientPlaceholder: 'Informe o solicitante',
      emailClientLabel: 'Email do Solicitante',
      emailClientRule: 'Preencha o e-mail do solicitante.',
      emailClientPlaceholder: 'Informe o email do solicitante',
      phoneClientLabel: 'Telefone do Solicitante',
      phoneClientPlaceholder: 'Informe o telefone do solicitante',
      priorityLabel: 'Prioridade',
      priorityRule: 'Informe a prioridade do ticket.',
      priorityPlaceholder: 'Informe a prioridade',
      descriptionLabel: 'Descrição',
      descriptionRule: 'Preencha a descrição.',
      descriptionPlaceholder: 'Informe a descrição',
      responsibleLabel: 'Responsável',
      responsibleRule: 'Selecione um tipo de responsável.',
      responsiblePlaceholder: 'Selecione um tipo de responsável',
      contributorsOption: 'Colaboradores',
      teamsOptions: 'Equipes',
      contributorsLabel: 'Selecione o colaborador',
      contributorsRule: 'Selecione um colaborador.',
      contributorsPlaceholder: 'Selecione um colaborador',
      teamsLabel: 'Selecione a equipe',
      teamsRule: 'Selecione uma equipe.',
      teamsPlaceholder: 'Selecione uma equipe',
      dateLabel: 'Data',
      dateRules: 'Informe a data.',
      datePlaceholder: 'Informe a data',
      selectPlaceholder: 'Selecione uma opção',
    },
  },
  en: {
    HelpDeskCreate: {
      pageTitle: 'New Ticket',
      pageSubTitle: 'Fill in the ticket details',
      newButton: 'New Ticket',
      keepRegisterText: 'Continue registering tickets?',
      registerButton: 'Register',
      titleLabel: 'Title',
      titleRule: 'Fill in the ticket title',
      titlePlaceholder: 'Enter the ticket title',
      solicitationLabel: 'Request Type',
      solicitationRule: 'Enter the type of request.',
      solicitationPlaceholder: 'Enter the type of request',
      clientLabel: 'Requester',
      clientRule: 'Enter the name of requester.',
      clientPlaceholder: 'Inform the requester',
      emailClientLabel: 'Requester Email',
      emailClientRule: 'Enter the e-mail of requester.',
      emailClientPlaceholder: 'Inform the email of requester',
      phoneClientLabel: 'Requester Phone',
      phoneClientPlaceholder: 'Inform the phone of requester',
      priorityLabel: 'Priority',
      priorityRule: 'Inform the priority',
      priorityPlaceholder: 'Inform the priority',
      descriptionLabel: 'Description',
      descriptionRule: 'Inform the description',
      descriptionPlaceholder: 'Inform the description',
      responsibleLabel: 'Responsible',
      responsibleRule: 'Select a responsible type.',
      responsiblePlaceholder: 'Select a responsible type',
      contributorsOption: 'Contributors',
      teamsOptions: 'Teams',
      contributorsLabel: 'Select the contributor',
      contributorsRule: 'Select the contributor.',
      contributorsPlaceholder: 'Select the contributor',
      teamsLabel: 'Select the team',
      teamsRule: 'Select the team.',
      teamsPlaceholder: 'Select the team',
      dateLabel: 'Date',
      dateRules: 'Inform the date.',
      datePlaceholder: 'Inform the date',
      selectPlaceholder: 'Selecione uma opção',
    },
  },
  es: {
    HelpDeskCreate: {
      pageTitle: 'Nuevo Ticket',
      pageSubTitle: 'Completa los detalles del ticket',
      newButton: 'Nuevo Ticket',
      keepRegisterText: '¿Continuar registrando tickets?',
      registerButton: 'Registrar',
      titleLabel: 'Sujeto',
      titleRule: 'Completa el sujeto de ticket.',
      titlePlaceholder: 'Introduzca el sujeto de ticket',
      solicitationLabel: 'Tipo de solicitud',
      solicitationRule: 'Introduzca el tipo de solicitud.',
      solicitationPlaceholder: 'Ingrese el tipo de solicitud',
      clientLabel: 'Solicitante',
      clientRule: 'Informar al solicitante.',
      clientPlaceholder: 'Informar al solicitante',
      emailClientLabel: 'Email del solicitante',
      emailClientRule: 'Informal e-mail del solicitante.',
      emailClientPlaceholder: 'Informar el email del solicitante',
      phoneClientLabel: 'Teléfono del solicitante',
      phoneClientPlaceholder: 'Informar el teléfono del solicitante',
      priorityLabel: 'Prioridad',
      priorityRule: 'Informar la prioridad.',
      priorityPlaceholder: 'Informar la prioridad',
      descriptionLabel: 'Descripción',
      descriptionRule: 'Introduce la descripción',
      descriptionPlaceholder: 'Introduce la descripción',
      responsibleLabel: 'Responsable',
      responsibleRule: 'Seleccione un tipo responsable.',
      responsiblePlaceholder: 'Seleccione un tipo responsable',
      contributorsOption: 'Colaboradores',
      teamsOptions: 'Equipos',
      contributorsLabel: 'Seleccione el colaborador',
      contributorsRule: 'Seleccione el colaborador.',
      contributorsPlaceholder: 'Seleccione el colaborador',
      teamsLabel: 'Seleccione el equipo',
      teamsRule: 'Seleccione el equipo.',
      teamsPlaceholder: 'Seleccione el equipo',
      dateLabel: 'Fecha',
      dateRules: 'Informar la fecha.',
      datePlaceholder: 'Informar la fecha',
      selectPlaceholder: 'Selecione uma opção',
    },
  },
}
