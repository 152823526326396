import React from 'react'
import { Helmet } from 'react-helmet'

import { Container } from './styles'
import ClientsBoard from '~/components/DataManipulation/Clients'
import { translate } from '~/utils/locale'

const Clients: React.FC = () => {
  return (
    <Container>
      <Helmet>
        <title>{translate('Clients.helmetTitle')}</title>
      </Helmet>

      <ClientsBoard />
    </Container>
  )
}

export default Clients
