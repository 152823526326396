/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-underscore-dangle */
import { CloseOutlined, FilterOutlined } from '@ant-design/icons'
import { Col, Drawer, Row, Form, Button, Input, Select, Tooltip } from 'antd'

import React, { useEffect, useState } from 'react'
import { useListContributors } from '~/hooks/Contributors/useListContributors'
import { useListTickets } from '~/hooks/HelpDesk/useListTickets'
import { useTicketType } from '~/hooks/HelpDesk/useTicketType'
import { useTeams } from '~/hooks/Teams/useListTeams'

import { translate } from '~/utils/locale'

import { Container } from './styles'

const FilterHelpDesk: React.FC = () => {
  const { handleResetFilters, handleUpdateFilters, filterOptions } =
    useListTickets()

  const { data } = useListContributors()
  const { teams } = useTeams()
  const { ticketType } = useTicketType()

  const { Option } = Select
  const [form] = Form.useForm()

  const [visible, setVisible] = useState(false)

  useEffect(() => {
    form.setFieldsValue({
      responsibleContributors: filterOptions?.responsibleContributors,
      responsibleTeams: filterOptions?.responsibleTeams,
      title: filterOptions?.title,
      customer: filterOptions?.customer,
      request_type_id: filterOptions?.request_type_id,
      priority: filterOptions?.priority,
    })
  }, [filterOptions, form])

  const onFinish = (dataForm: any) => {
    handleUpdateFilters(dataForm)
    setVisible(false)
  }

  const onReset = () => {
    handleResetFilters()
    form.resetFields()
    setVisible(false)
  }

  const showDrawer = () => {
    setVisible(true)
  }

  const onClose = () => {
    setVisible(false)
  }

  const isFilter = Object.keys(filterOptions).length !== 0

  return (
    <Container isFilter={isFilter}>
      <Button
        type="primary"
        onClick={showDrawer}
        // loading={!clients || !data || !listEquipments || !taskType || !teams}
        icon={
          !isFilter ? (
            <FilterOutlined />
          ) : (
            <Tooltip title={translate('HelpDeskFilter.filterTooltip')}>
              <CloseOutlined onClick={onReset} />
            </Tooltip>
          )
        }
        id="btn_filter_ticket"
      >
        {isFilter
          ? translate('HelpDeskFilter.filterAppliedText')
          : translate('HelpDeskFilter.filterText')}
      </Button>
      <Drawer
        forceRender
        title={translate('HelpDeskFilter.drawerTitle')}
        width={580}
        onClose={onClose}
        open={visible}
        style={{ paddingBottom: 80 }}
      >
        <Form layout="vertical" form={form} onFinish={onFinish}>
          <Row>
            <Col span={24}>
              <Form.Item
                label={translate('HelpDeskFilter.fromTitleLabel')}
                name="title"
              >
                <Input
                  placeholder={translate('HelpDeskFilter.fromTitlePlaceholder')}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={8}>
            {data && data.length > 0 && (
              <Col span={12}>
                {
                  <Form.Item
                    label={translate('TasksFilter.contributorLabel')}
                    name="responsibleContributors"
                  >
                    <Select
                      placeholder={translate(
                        'TasksFilter.contributorPlaceholder',
                      )}
                    >
                      {data.map((colaborator) => (
                        <Option value={colaborator.id} key={colaborator.id}>
                          {colaborator.contact
                            ? colaborator.contact.name
                            : 'Sem informações'}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                }
              </Col>
            )}

            <Col span={12}>
              <Form.Item
                label={translate('TasksFilter.teamLabel')}
                name="responsibleTeams"
              >
                <Select placeholder={translate('TasksFilter.teamPlaceholder')}>
                  {teams?.map((team) => (
                    <Option value={team.id} key={team.id}>
                      {team.title}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label={translate('HelpDeskFilter.ticketTypeLabel')}
                name="request_type_id"
              >
                <Select
                  placeholder={translate(
                    'HelpDeskFilter.ticketTypePlaceholder',
                  )}
                >
                  {ticketType?.map((ticket) => (
                    <Option value={ticket._id} key={ticket._id}>
                      {ticket.title}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={translate('HelpDeskFilter.priorityLabel')}
                name="priority"
              >
                <Select
                  placeholder={translate('HelpDeskFilter.priorityPlaceholder')}
                >
                  <Option value="high">
                    {translate('HelpDeskFilter.priorityHigh')}
                  </Option>
                  <Option value="medium">
                    {translate('HelpDeskFilter.priorityMedium')}
                  </Option>
                  <Option value="low">
                    {translate('HelpDeskFilter.priorityLow')}
                  </Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label={translate('HelpDeskFilter.customerLabel')}
                name="customer"
              >
                <Input
                  placeholder={translate('HelpDeskFilter.customerPlaceholder')}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label={translate('HelpDeskFilter.descriptionLabel')}
                name="description"
              >
                <Input
                  placeholder={translate(
                    'HelpDeskFilter.descriptionPlaceholder',
                  )}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={8}>
            <Col span={12}>
              <Form.Item style={{ width: '100%' }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: '100%' }}
                >
                  {translate('HelpDeskFilter.applyButton')}
                </Button>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item style={{ width: '100%' }}>
                <Button onClick={onReset} style={{ width: '100%' }}>
                  {translate('HelpDeskFilter.cleanButton')}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </Container>
  )
}

export default FilterHelpDesk
