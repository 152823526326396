import React from 'react'
import { Helmet } from 'react-helmet'

import { StatusProvider } from '~/hooks/Status/useStatus'
import StatusDetails from '~/components/DataManipulation/Status/Details'
import { translate } from '~/utils/locale'

const Status: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>{translate('ShowStatus.helmetTitle')}</title>
      </Helmet>

      <StatusProvider>
        <StatusDetails />
      </StatusProvider>
    </>
  )
}

export default Status
