export default {
  pt: {
    TasksCreateModalTask: {
      addTaskButton: 'Replicar tarefa',
      modalTitle: 'Replicar Tarefa',
      addTaskPopconfirm: 'Deseja mesmo replicar essa tarefa?',
      addTaskTooltip: 'Selecionar tarefa',
      confirmTaskData: 'Confira os dados antes de salvar a tarefa.',
      closeButton: 'Fechar',
    },
  },
  en: {
    TasksCreateModalTask: {
      addTaskButton: 'Replicate task',
      modalTitle: 'Replicate Task',
      addTaskPopconfirm: 'Do you really want to replicate this task?',
      addTaskTooltip: 'Select task',
      confirmTaskData: 'Check the data before saving the task.',
      closeButton: 'Close',
    },
  },
  es: {
    TasksCreateModalTask: {
      addTaskButton: 'Replicar tarea',
      modalTitle: 'Replicar Tarea',
      addTaskPopconfirm: '¿Realmente quieres replicar esta tarea?',
      addTaskTooltip: 'Seleccionar tarea',
      confirmTaskData: 'Verifique los datos antes de guardar la tarea.',
      closeButton: 'Cerrar',
    },
  },
}
