export default {
  pt: {
    GroupsUpdatePermissions: {
      addresses: 'endereços',
      companies: 'empresas',
      contacts: 'contatos',
      contributors: 'colaboradores',
      contributorsAttachments: 'documentos/anexos de colaboradores',
      contributorsEvaluations: 'avaliações de colaboradores',
      contributorsSkills: 'habilidades de colaboradores',
      contributorsStatus: 'status de colaboradores',
      groups: 'grupos',
      groupsPermissions: 'permissões de grupos',
      reports: 'relatórios',
      tasksStatus: 'status da tarefa',
      tasksReports: 'evidências da tarefa',
      tasksEquipments: 'equipamentos da tarefa',
      contributorsTeams: 'equipe de colaboradores',
      teams: 'equipes',
      tasks: 'tarefas',
      breaksReasons: 'motivos de pausa',
      tasksTypes: 'tipos de tarefa',
      equipmentsAttachments: 'documentos/anexos do equipamento',
      equipmentsSpecifications: 'especificações do equipamento',
      specificationsTemplates: 'template de especificações',
      movements: 'movimentações',
      stocks: 'estoques',
      associatedEquipments: 'equipamentos associados',
      categories: 'categorias',
      equipments: 'equipamentos',
      contactsAddresses: 'endereços do contato',
      contributorsSchedules: 'escala de colaboradores',
      customersSchedules: 'escala de clientes',
      scheduleTimes: 'horários de escala',
      schedules: 'escalas',
      customersContacts: 'contatos do cliente',
      customersSegments: 'segmentos do cliente',
      customers: 'clientes',
      successMessage: 'Permissão atualizada com sucesso!',
      errorMessage: 'Houve um erro ao atualizar a permissão',
      editTooltip: 'Editar Permissão',
      modalTitle: 'Editar Permissão',
      closeButton: 'Fechar',
      deleteTooltip: 'Excluir',
      deletePopconfirm: 'Tem certeza que deseja deletar a permissão?',
      okPopconfirmText: 'Excluir',
      cancelPopconfirmText: 'Cancelar',
      deleteButton: 'Excluir',
      saveButton: 'Salvar',
      createText: 'Permitir que o usuário possa criar',
      editText: 'Permitir que o usuário possa editar',
      viewText: 'Permitir que o usuário possa visualizar',
      deleteText: 'Permitir que o usuário possa deletar',
    },
  },
  en: {
    GroupsUpdatePermissions: {
      addresses: 'addresses',
      companies: 'companies',
      contacts: 'contacts',
      contributors: 'collaborators',
      contributorsAttachments: 'documents/attachments of collaborators',
      contributorsEvaluations: 'collaborators reviews',
      contributorsSkills: 'collaborator skills',
      contributorsStatus: 'collaborator status',
      groups: 'groups',
      groupsPermissions: 'group permissions',
      reports: 'reports',
      tasksStatus: 'task status',
      tasksReports: 'task reports',
      tasksEquipments: 'task equipment',
      contributorsTeams: 'team of collaborators',
      teams: 'teams',
      tasks: 'tasks',
      breaksReasons: 'reasons for break',
      tasksTypes: 'task types',
      equipmentsAttachments: 'equipment documents/attachments',
      equipmentsSpecifications: 'equipment specifications',
      specificationsTemplates: 'specification template',
      movements: 'movements',
      stocks: 'stocks',
      associatedEquipments: 'associated equipment',
      categories: 'categories',
      equipments: 'equipment',
      contactsAddresses: 'contact addresses',
      contributorsSchedules: 'scale of collaborators',
      customersSchedules: 'customer scale',
      scheduleTimes: 'stopover schedules',
      schedules: 'scales',
      customersContacts: 'customer contacts',
      customersSegments: 'customer segments',
      customers: 'customers',
      successMessage: 'Permission updated successfully!',
      errorMessage: 'There was an error updating the permission',
      editTooltip: 'Edit Permission',
      modalTitle: 'Edit Permission',
      closeButton: 'Close',
      deleteTooltip: 'Delete',
      deletePopconfirm: 'Are you sure you want to delete the permission?',
      okPopconfirmText: 'Delete',
      cancelPopconfirmText: 'Cancel',
      deleteButton: 'Delete',
      saveButton: 'Save',
      createText: 'Allow user to create',
      editText: 'Allow user to edit',
      viewText: 'Allow user to view',
      deleteText: 'Allow user to delete',
    },
  },
  es: {
    GroupsUpdatePermissions: {
      addresses: 'direcciones',
      companies: 'compañías',
      contacts: 'contactos',
      contributors: 'colaboradores',
      contributorsAttachments: 'documentos/archivos adjuntos de colaboradores',
      contributorsEvaluations: 'reseñas de colaboradores',
      contributorsSkills: 'habilidades de colaboradores',
      contributorsStatus: 'estatus de colaborador',
      groups: 'grupos',
      groupsPermissions: 'permisos de grupo',
      reports: 'informes',
      tasksStatus: 'estado de la tarea',
      tasksReports: 'informes de tareas',
      tasksEquipments: 'equipo de trabajo',
      contributorsTeams: 'equipo de colaboradores',
      teams: 'equipos',
      tasks: 'tareas',
      breaksReasons: 'motivos de descanso',
      tasksTypes: 'tipos de tareas',
      equipmentsAttachments: 'documentos/archivos adjuntos del equipo',
      equipmentsSpecifications: 'especificaciones del equipo',
      specificationsTemplates: 'plantilla de especificación',
      movements: 'movimientos',
      stocks: 'inventarios',
      associatedEquipments: 'equipamiento asociado',
      categories: 'categorías',
      equipments: 'equipo',
      contactsAddresses: 'direcciones de contacto',
      contributorsSchedules: 'escala de colaboradores',
      customersSchedules: 'escala del cliente',
      scheduleTimes: 'horarios de escala',
      schedules: 'escalas',
      customersContacts: 'contactos de clientes',
      customersSegments: 'segmentos de clientes',
      customers: 'clientes',
      successMessage: '¡Permiso actualizado con éxito!',
      errorMessage: 'Hubo un error al actualizar el permiso.',
      editTooltip: 'Editar permiso',
      modalTitle: 'Editar permiso',
      closeButton: 'Cerrar',
      deleteTooltip: 'Borrar',
      deletePopconfirm: 'Tem certeza que deseja deletar a permissão?',
      okPopconfirmText: 'Borrar',
      cancelPopconfirmText: 'Cancelar',
      deleteButton: 'Borrar',
      saveButton: 'Guardar',
      createText: 'Permitir al usuario crear',
      editText: 'Permitir que el usuario edite',
      viewText: 'Permitir que el usuario vea',
      deleteText: 'Permitir que el usuario elimine',
    },
  },
}
