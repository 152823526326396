export default {
  pt: {
    TasksCreateModalScale: {
      actionsColumn: 'Ações',
      addPopconfirm: 'Deseja mesmo adicionar a escala?',
      addTooltip: 'Adicionar escala',
      titleColumn: 'Título',
      viewTooltip: 'Ver escala',
      daysColumn: 'Dias',
      contributorsColumn: 'Colaboradores',
      noContributorText: 'Nenhum Colaborador',
      contributorText: 'Colaborador',
      contributorsText: 'Colaboradores',
      teamsColumn: 'Equipes',
      noTeamText: 'Nenhuma Equipe',
      teamText: 'Equipe',
      teamsText: 'Equipes',
      notificationMessage: 'Escalas encontradas',
      notificationDescription:
        'Encontramos escalas que atendem aos requisitos do cliente selecionado.',
      addButton: 'Adicionar Escala',
      changeButton: 'Alterar Escala',
      modalTitle: 'Adicionar Escala',
      closeButton: 'Fechar',
    },
  },
  en: {
    TasksCreateModalScale: {
      actionsColumn: 'Actions',
      addPopconfirm: 'Do you really want to add the scale?',
      addTooltip: 'Add scale',
      titleColumn: 'Tile',
      viewTooltip: 'View scale',
      daysColumn: 'Days',
      contributorsColumn: 'Contributors',
      noContributorText: 'No Contributor',
      contributorText: 'Contributor',
      contributorsText: 'Contributors',
      teamsColumn: 'Teams',
      noTeamText: 'No Team',
      teamText: 'Team',
      teamsText: 'Teams',
      notificationMessage: 'Scales found',
      notificationDescription:
        'We found scales that meet the requirements of the selected client.',
      addButton: 'Add scale',
      changeButton: 'Change Scale',
      modalTitle: 'Add scale',
      closeButton: 'Close',
    },
  },
  es: {
    TasksCreateModalScale: {
      actionsColumn: 'Acciones',
      addPopconfirm: '¿De verdad quieres añadir la escala?',
      addTooltip: 'Añadir Escala',
      titleColumn: 'Título',
      viewTooltip: 'Ver escala',
      daysColumn: 'Días',
      contributorsColumn: 'Colaboradores',
      noContributorText: 'Sin Colaborador',
      contributorText: 'Colaborador',
      contributorsText: 'Colaboradores',
      teamsColumn: 'Equipos',
      noTeamText: 'Sin Equipo',
      teamText: 'Equipo',
      teamsText: 'Equipos',
      notificationMessage: 'Escalas encontradas',
      notificationDescription:
        'Encontramos balanzas que cumplen con los requerimientos del cliente seleccionado.',
      addButton: 'Añadir Escala',
      changeButton: 'Cambiar Escala',
      modalTitle: 'Añadir Escala',
      closeButton: 'Cerrar',
    },
  },
}
