export default {
  pt: {
    ScalesCalendar: {
      workString: 'Trabalho',
      breakString: 'Pausa',
      cardTitle: 'Horários',
    },
  },
  en: {
    ScalesCalendar: {
      workString: 'Work',
      breakString: 'Break',
      cardTitle: 'Schedules',
    },
  },
  es: {
    ScalesCalendar: {
      workString: 'Trabajo',
      breakString: 'Descanso',
      cardTitle: 'Horarios',
    },
  },
}
