export default {
  pt: {
    TicketTaskLink: {
      addButton: 'Adicionar tarefas ao ticket',
      modalTitle: 'Adicionar tarefas ao ticket',
    },
  },
  en: {
    TicketTaskLink: {
      addButton: 'Add tasks to ticket',
      modalTitle: 'Add tasks to ticket',
    },
  },
  es: {
    TicketTaskLink: {
      addButton: 'Agregar tareas al ticket',
      modalTitle: 'Agregar tareas al ticket',
    },
  },
}
