import React from 'react'
import ParentClientsBoard from '~/components/DataManipulation/Clients/Parent'
import { ListParentClientsProvider } from '~/hooks/Clients/useListParentCustomer'

// import { Container } from './styles';

const ParentCustomers: React.FC = () => {
  return (
    <ListParentClientsProvider>
      <ParentClientsBoard />
    </ListParentClientsProvider>
  )
}

export default ParentCustomers
