import styled from 'styled-components'

interface CardProps {
  colorBackground?: string
}

interface CoverProps {
  colorCover?: string
}

interface ContentProps {
  colorStrongText?: string
  colorText?: string
}

export const Container = styled.div``

export const Card = styled.div<CardProps>`
  width: 320px;
  height: 580px;

  border-radius: 40px;
  background: ${(p) => p.colorBackground};
`

export const Cover = styled.div<CoverProps>`
  width: 100%;
  height: 120px;
  background: ${(p) => p.colorCover};
  border-radius: 40px 40px 0 0;
`
export const User = styled.div`
  width: 110px;
  height: 110px;
  background: #333536;
  border-radius: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;
  bottom: 3.5rem;

  margin: 0 auto;
  cursor: pointer;

  svg {
    font-size: 45px;
    color: white;
  }
`
export const UserImage = styled.div`
  position: relative;
  bottom: 3.5rem;
  width: 110px;

  margin: 0 auto;
  cursor: pointer;
`

export const Content = styled.div<ContentProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  position: relative;
  bottom: 2rem;

  h2 {
    margin-bottom: 0%;
    color: ${(p) => p.colorStrongText};
  }

  h3 {
    color: ${(p) => p.colorStrongText};
    margin-bottom: 0.8rem;
  }

  p {
    color: ${(p) => p.colorText};
    font-size: 17px;
  }

  .text {
    margin-bottom: 1rem;
  }
`
