export default {
  pt: {
    MiscAddAddressToScaleButton: {
      successMessage: 'Endereço vinculado com sucesso!',
      errorMessage: 'Falha ao vincular endereço.',
      popconfirmTitle: 'Deseja mesmo vincular este endereço a escala?',
      tooltipTitle: 'Atribuir endereço a escala',
    },
  },
  en: {
    MiscAddAddressToScaleButton: {
      successMessage: 'Address linked successfully!',
      errorMessage: 'Failed to link address.',
      popconfirmTitle: 'Do you really want to link this address to the scale?',
      tooltipTitle: 'Assign address to scale',
    },
  },
  es: {
    MiscAddAddressToScaleButton: {
      successMessage: '¡Dirección vinculada con éxito!',
      errorMessage: 'No se pudo vincular la dirección.',
      popconfirmTitle: '¿Realmente desea vincular esta dirección a la báscula?',
      tooltipTitle: 'Asignar dirección a escala',
    },
  },
}
