export default {
  pt: {
    EquipmentsTabsSpecificationUpdate: {
      editTooltip: 'Editar',
      modalTitle: 'Editar Especificação',
      closeButton: 'Fechar',
      saveButton: 'Salvar',
      nameLabel: 'Nome da Especificação',
      nameRule: 'Por favor informe o nome da especificação.',
      namePlaceholder: 'Informe o nome da especificação',
      deleteTooltip: 'Deletar atributo',
      attributeLabel: 'Atributo',
      attributePlaceholder: 'Informe o atributo',
      valueLabel: 'Valor',
      valuePlaceholder: 'Informe um valor',
      addButton: 'Adicionar Atributo',
    },
  },
  en: {
    EquipmentsTabsSpecificationUpdate: {
      editTooltip: 'Edit',
      modalTitle: 'Edit Specification',
      closeButton: 'Close',
      saveButton: 'Save',
      nameLabel: 'Specification Name',
      nameRule: 'Please enter the specification name.',
      namePlaceholder: 'Enter the specification name',
      deleteTooltip: 'Delete attribute',
      attributeLabel: 'Attribute',
      attributePlaceholder: 'Inform the attribute',
      valueLabel: 'Value',
      valuePlaceholder: 'Enter a value',
      addButton: 'Add Attribute',
    },
  },
  es: {
    EquipmentsTabsSpecificationUpdate: {
      editTooltip: 'Editar',
      modalTitle: 'Editar Especificación',
      closeButton: 'Cerrar',
      saveButton: 'Guardar',
      nameLabel: 'Nombre de especificación',
      nameRule: 'Introduzca el nombre de la especificación.',
      namePlaceholder: 'Introduzca el nombre de la especificación',
      deleteTooltip: 'Eliminar atributo',
      attributeLabel: 'Atributo',
      attributePlaceholder: 'Informar el atributo',
      valueLabel: 'Valor',
      valuePlaceholder: 'Introduce un valor',
      addButton: 'Agregar atributo',
    },
  },
}
