import { lighten } from 'polished'
import styled from 'styled-components'

export const Container = styled.div`
  .ant-divider-horizontal {
    margin: 10px 0 20px;
  }

  #btn-edit-equipments {
    background: var(--quartenary-color);
    border: 1px solid var(--quartenary-color);

    &:hover {
      background: ${lighten(0.05, '#3969B7')};
      border: 1px solid ${lighten(0.1, '#3969B7')};
    }
  }
`

export const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
`

export const ContAvatar = styled.div`
  .delete-image {
    margin-top: 0.8rem;
    cursor: pointer;

    svg {
      margin-right: 0.3rem;
    }

    &:hover {
      color: var(--color-danger);
    }
  }
`

export const ContAboutClient = styled.div`
  margin-left: 20px;
  flex: 1;

  .text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-content: flex-start;
    padding-bottom: 23px;

    div {
      display: flex;
      align-items: center;
    }

    .equipment {
      display: flex;
      align-items: center;

      p {
        margin-right: 8px;
      }
    }

    .delete_association {
      svg {
        margin-left: 5px;
        color: var(--color-danger);
        cursor: pointer;
        transition: 0.2s ease;

        &:hover {
          color: ${lighten(0.05, '#D82143')};
        }
      }
    }

    svg {
      margin-right: 5px;
    }
  }
`

export const OtherInfos = styled.div`
  .text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-content: flex-start;
    padding-bottom: 15px;
  }

  p {
    margin-bottom: 0px;
  }

  .tags {
    margin-top: 5px;
    border: 1px solid var(--primary-color);
    padding: 4px 10px 4px 10px;
    border-radius: 4px;
    background: #f7f7f7;

    span {
      color: #4e4e4e;
    }
  }

  .notes {
    width: 100%;
    margin-top: 5px;
    border: 1px solid #dadada;
    padding: 10px;
    border-radius: 4px;

    span {
      color: #4e4e4e;
    }
  }
`

export const LoadingIndicator = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
