/* eslint-disable react/require-default-props */
import { PlusCircleOutlined } from '@ant-design/icons'
import { Button, Modal, Form, Input, Space } from 'antd'

import React, { useState } from 'react'

import { useCategory } from '~/hooks/Equipments/Category/CategoryHooks'
import { translate } from '~/utils/locale'
import { ContainerButton } from '../styles'

interface CategoryProps {
  title: string
  id: string
}

interface CategoryFormProps {
  buttonStyle?: React.CSSProperties
}

const CreateEquipmentsCategory: React.FC<CategoryFormProps> = ({
  buttonStyle = {},
}) => {
  const { createCategory } = useCategory()

  const [isModalVisible, setIsModalVisible] = useState(false)

  function onFormNewCategory(valueForm: CategoryProps) {
    try {
      createCategory(valueForm)

      setIsModalVisible(false)
    } catch (err) {
      console.log(err)
    }
  }

  const showModal = () => {
    setIsModalVisible(true)
  }

  const closeModal = () => {
    setIsModalVisible(false)
  }

  return (
    <>
      <Button
        type="primary"
        onClick={showModal}
        className="category"
        style={buttonStyle}
        icon={<PlusCircleOutlined />}
      >
        {translate('EquipmentsCategoryCreate.newCategoryButton')}
      </Button>
      <Modal
        title={translate('EquipmentsCategoryCreate.modalTitle')}
        open={isModalVisible}
        onOk={closeModal}
        onCancel={closeModal}
        width="800px"
        footer={[
          <Space key="buttons">
            <Button key="back" onClick={() => setIsModalVisible(false)}>
              {translate('EquipmentsCategoryCreate.closeButton')}
            </Button>
            <ContainerButton>
              <Button
                form="newCategoryForm"
                key="save"
                type="primary"
                htmlType="submit"
                id="btn_create_category"
              >
                {translate('EquipmentsCategoryCreate.saveButton')}
              </Button>
            </ContainerButton>
          </Space>,
        ]}
      >
        <Form
          layout="vertical"
          id="newCategoryForm"
          onFinish={onFormNewCategory}
        >
          <Form.Item
            label={translate('EquipmentsCategoryCreate.descriptionLabel')}
            name="title"
            rules={[
              {
                required: true,
                message: translate('EquipmentsCategoryCreate.descriptionRule'),
              },
            ]}
          >
            <Input.TextArea
              placeholder={translate(
                'EquipmentsCategoryCreate.descriptionPlaceholder',
              )}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default CreateEquipmentsCategory
