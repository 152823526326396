export default {
  pt: {
    TasksCreateModalContributor: {
      addContributor: 'Adicionar Colaborador',
      changeContributor: 'Alterar Colaborador',
      modalTitle: 'Adicionar Colaborador',
      rangeText: 'Colaboradores em um raio de',
      contributorHeader: 'Colaboradores',
      addPopconfirm: 'Deseja mesmo adicionar o colaborador?',
      addTooltip: 'Adicionar colaborador',
    },
  },
  en: {
    TasksCreateModalContributor: {
      addContributor: 'Add Contributor',
      changeContributor: 'Change Contributor',
      modalTitle: 'Add Contributor',
      rangeText: 'Contributors within a radius of',
      contributorHeader: 'Colaboradores',
      addPopconfirm: 'Do you really want to add the contributor?',
      addTooltip: 'Add Contributor',
    },
  },
  es: {
    TasksCreateModalContributor: {
      addContributor: 'Añadir Colaborador',
      changeContributor: 'Cambiar Colaborador',
      modalTitle: 'Añadir Colaborador',
      rangeText: 'Colaboradores dentro de un radio de',
      contributorHeader: 'Colaboradores',
      addPopconfirm: '¿Realmente quieres agregar al colaborador?',
      addTooltip: 'Añadir Colaborador',
    },
  },
}
