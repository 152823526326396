/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, createContext, useState, useCallback } from 'react'
import { useQuery } from 'react-query'

import { message } from 'antd'

import api from '~/services/api'

import { useCompanies } from '~/hooks/Companies/useCompanies'
import { useLocale } from '~/hooks/locale/useLocale'

import { ShowError } from '~/utils/errors/apiErrors'
import { translate } from '~/utils/locale'

interface MandatoryProps {
  is_active: boolean
  name: string
  type: string
  value: boolean
}

export interface TaskTypeProps {
  description: string
  is_enabled: boolean
  runtime: string
  tolerance_time: string
  _id: string
  createdAt: string
  title: string
  valueTask?: number
  amount_to_pay: number
  amount_to_receive: number
  mandatory: MandatoryProps[]
  forms?: Array<{ id: string }>
}

interface FilterOptionsProps {
  title?: string
}
interface TaskTypeContextData {
  taskType?: TaskTypeProps[]
  createTaskType(valueForm: object | undefined): void
  updateTaskType(valueForm: object | undefined, taskTypeId: string): void
  updateMandatory(valueForm: object | undefined, taskTypeId: string): void
  deleteTaskType(taskTypeId: string): void
  isLoading: boolean
  refetch: () => void
  isFetching: boolean
  tasksTypeTotal?: number
  handleUpdateFilters: (newFilterOptions: any) => void
  handleResetFilters: () => void
  filterOptions: FilterOptionsProps
}

const TaskTypeContext = createContext<TaskTypeContextData>(
  {} as TaskTypeContextData,
)

export const TaskTypeProvider: React.FC = ({ children }) => {
  const [filterOptions, setFilterOptions] = useState<FilterOptionsProps>(() => {
    const filter = localStorage.getItem('@Gstor:taskTypesFilterOptions')

    if (filter) {
      return JSON.parse(filter)
    }

    return {} as FilterOptionsProps
  })

  const { selectedCompany } = useCompanies()
  const { locale } = useLocale()

  const [tasksTypeTotal, setTasksTypeTotal] = useState<number>()

  const {
    data: taskType,
    isLoading,
    refetch,
    isFetching,
  } = useQuery(
    [
      `taskTypes${selectedCompany}`,
      selectedCompany,
      tasksTypeTotal,
      filterOptions,
    ],

    async () => {
      try {
        const response = await api.get(
          `/company/${selectedCompany}/tasks-types`,
          {
            params: {
              ...filterOptions,
            },
          },
        )

        const { data } = response

        setTasksTypeTotal(data.total)

        return data.results
      } catch (err: any) {
        ShowError(err.message, translate('useTaskType.getErrorMessage'), locale)
      }
    },
  )

  async function createTaskType(valueForm: object) {
    try {
      await api.post(`/company/${selectedCompany}/task-type`, valueForm)

      refetch()
      message.success(translate('useTaskType.createSuccessMessage'))
    } catch (err: any) {
      ShowError(
        err.message,
        translate('useTaskType.createErrorMessage'),
        locale,
      )
    }
  }

  async function updateTaskType(valueForm: object, taskTypeId: string) {
    try {
      await api.put(
        `/company/${selectedCompany}/task-type/${taskTypeId}`,
        valueForm,
      )

      message.success(translate('useTaskType.updateSuccessMessage'))
      refetch()
    } catch (err: any) {
      ShowError(
        err.message,
        translate('useTaskType.updateErrorMessage'),
        locale,
      )
    }
  }

  async function updateMandatory(valueForm: object, taskTypeId: string) {
    try {
      await api.put(
        `/company/${selectedCompany}/task-type/${taskTypeId}/mandatory`,
        valueForm,
      )

      refetch()
    } catch (err: any) {
      ShowError(
        err.message,
        translate('useTaskType.updateGenericError'),
        locale,
      )
    }
  }

  async function deleteTaskType(taskTypeId: string) {
    try {
      await api.delete(`/company/${selectedCompany}/task-type/${taskTypeId}`)

      refetch()
      message.success(translate('useTaskType.deleteSuccessMessage'))
    } catch (err: any) {
      ShowError(
        err.message,
        translate('useTaskType.deleteErrorMessage'),
        locale,
      )
    }
  }

  const handleUpdateFilters = useCallback((newFilterOptions) => {
    const newFilters = {
      title: newFilterOptions?.title,
    }
    setFilterOptions(newFilters)
    localStorage.setItem(
      '@Gstor:taskTypesFilterOptions',
      JSON.stringify(newFilters),
    )
  }, [])

  const handleResetFilters = useCallback(() => {
    localStorage.removeItem('@Gstor:taskTypesFilterOptions')
    setFilterOptions({})
  }, [])

  return (
    <TaskTypeContext.Provider
      value={{
        taskType,
        createTaskType,
        updateTaskType,
        updateMandatory,
        deleteTaskType,
        isLoading,
        refetch,
        isFetching,
        tasksTypeTotal,
        handleUpdateFilters,
        handleResetFilters,
        filterOptions,
      }}
    >
      {children}
    </TaskTypeContext.Provider>
  )
}

export function useTaskType(): TaskTypeContextData {
  const context = useContext(TaskTypeContext)

  if (!context) {
    throw new Error('useAuth must be used within an AutoProvider')
  }

  return context
}
