export default {
  pt: {
    ScalesContributorsForm: {
      cardTitle: 'Colaboradores',
    },
  },
  en: {
    ScalesContributorsForm: {
      cardTitle: 'Contributors',
    },
  },
  es: {
    ScalesContributorsForm: {
      cardTitle: 'Colaboradores',
    },
  },
}
