export default {
  pt: {
    HelpDeskTable: {
      codeColumn: 'Código',
      ticketColumn: 'Ticket',
      clientColumn: 'Cliente',
      statusColumn: 'Status',
      priorityColumn: 'Prioridade',
      validateColumn: 'Validade',
      dateColumn: 'Data de abertura',
      actionsColumn: 'Ações',
      deletePopconfirm: 'Deseja mesmo deletar o ticket?',
      deleteTooltip: 'Deletar',
      viewTooltip: 'Ver Ticket',
    },
  },
  en: {
    HelpDeskTable: {
      codeColumn: 'Code',
      ticketColumn: 'Ticket',
      clientColumn: 'Client',
      statusColumn: 'Status',
      priorityColumn: 'Priority',
      validateColumn: 'Validity',
      dateColumn: 'Opening date',
      actionsColumn: 'Actions',
      deletePopconfirm: 'Do you really want to delete the ticket?',
      deleteTooltip: 'Delete',
      viewTooltip: 'View Ticket',
    },
  },
  es: {
    HelpDeskTable: {
      codeColumn: 'Código',
      ticketColumn: 'Ticket',
      clientColumn: 'Cliente',
      statusColumn: 'Status',
      priorityColumn: 'Prioridad',
      validateColumn: 'Validez',
      dateColumn: 'Fecha de apertura',
      actionsColumn: 'Acciones',
      deletePopconfirm: '¿Realmente desea eliminar el ticket?',
      deleteTooltip: 'Borrar',
      viewTooltip: 'Ver Ticket',
    },
  },
}
