/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import i18n from 'i18n-js'

import { EnglishLocalization } from '~/utils/locale/en'
import { SpanishLocalization } from '~/utils/locale/es'
import { PortugueseLocalization } from '~/utils/locale/pt-br'

interface LanguageProps {
  [key: string]: {
    [key: string]: {
      [key: string]: any
    }
  }
}

export const i18nConfig = (localeCode: 'pt-BR' | 'en-US' | 'es-ES') => {
  const languages: LanguageProps = {
    'pt-BR': {
      ...PortugueseLocalization,
    },
    'en-US': {
      ...EnglishLocalization,
    },
    'es-ES': {
      ...SpanishLocalization,
    },
  }
  i18n.translations = {
    pt: languages['pt-BR'],
    en: languages['en-US'],
    es: languages['es-ES'],
  }

  i18n.defaultLocale = 'pt-BR'
  i18n.locale = localeCode
  i18n.fallbacks = true
}

export const translate = (key: string): string => {
  return i18n.translate(key)
}
