export default {
  pt: {
    EquipmentsUpdateDetails: {
      uploadArchiveTypeError:
        'Você só pode fazer upload de arquivos JPG ou PNG!',
      uploadArchiveSizeError: 'A imagem tem que ser menor que 2MB!',
      cardTitle: 'Detalhes',
      saveButton: 'Salvar',
      cancelButton: 'Cancelar',
      imageLabel: 'Imagem do Equipamento',
      nameLabel: 'Nome',
      nameRule: 'Por favor informe o nome do equipamento',
      namePlaceholder: 'Informe seu nome',
      identifierLabel: 'Identificador',
      identifierPlaceholder: 'Informe o identificador',
      expirationDateLabel: 'Data de Validade',
      categoryLabel: 'Categoria',
      categoryPlaceholder: 'Selecione a categoria',
      descriptionLabel: 'Descrição',
      descriptionPlaceholder: 'Informe a descrição',
      statusLabel: 'Status',
      statusActive: 'Ativo',
      statusInactive: 'Inativo',
    },
  },
  en: {
    EquipmentsUpdateDetails: {
      uploadArchiveTypeError: 'You can only upload JPG or PNG files!',
      uploadArchiveSizeError: 'The image has to be smaller than 2MB!',
      cardTitle: 'Details',
      saveButton: 'Save',
      cancelButton: 'Cancel',
      imageLabel: 'Equipment Image',
      nameLabel: 'Name',
      nameRule: 'Please enter the equipment name',
      namePlaceholder: 'Enter your name',
      identifierLabel: 'Identifier',
      identifierPlaceholder: 'Inform the identifier',
      expirationDateLabel: 'Expiration date',
      categoryLabel: 'Category',
      categoryPlaceholder: 'Select category',
      descriptionLabel: 'Description',
      descriptionPlaceholder: 'Enter the description',
      statusLabel: 'Status',
      statusActive: 'Active',
      statusInactive: 'Inactive',
    },
  },
  es: {
    EquipmentsUpdateDetails: {
      uploadArchiveTypeError: '¡Solo puede cargar archivos JPG o PNG!',
      uploadArchiveSizeError:
        '¡La imagen debe tener un tamaño inferior a 2 MB!',
      cardTitle: 'Detalles',
      saveButton: 'Guardar',
      cancelButton: 'Cancelar',
      imageLabel: 'Imagen del equipo',
      nameLabel: 'Nombre',
      nameRule: 'Por favor ingrese el nombre del dispositivo',
      namePlaceholder: 'Introduzca su nombre',
      identifierLabel: 'Identificador',
      identifierPlaceholder: 'Informar al identificador',
      expirationDateLabel: 'Fecha de validez',
      categoryLabel: 'Categoría',
      categoryPlaceholder: 'Selecciona una categoría',
      descriptionLabel: 'Descripción',
      descriptionPlaceholder: 'Introduce la descripción',
      statusLabel: 'Status',
      statusActive: 'Activo',
      statusInactive: 'Inactivo',
    },
  },
}
