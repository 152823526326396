export default {
  pt: {
    NewContacts: {
      helmetTitle: 'Novo Contato do Cliente',
    },
  },
  en: {
    NewContacts: {
      helmetTitle: 'New Customer Contact',
    },
  },
  es: {
    NewContacts: {
      helmetTitle: 'Nuevo contacto de Cliente',
    },
  },
}
