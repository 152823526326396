import styled, { keyframes } from 'styled-components'

import { shade } from 'polished'

import ConfirmMailImg from '~/assets/Login-Cadastro/Confirm_Mail.svg'

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  place-content: center;

  background-color: var(--primary-initial-bg);

  width: 100%;
  max-width: 700px;
`

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`

export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  animation: ${appearFromLeft} 1s;

  img {
    align-self: flex-start;
    height: 75px;
    width: 75px;
  }

  width: 400px;

  h1 {
    align-self: flex-start;
    margin-bottom: 8px;
  }

  p {
    margin-bottom: 35px;
    color: var(--secondary-initial-text);
  }

  a {
    color: var(--secondary-initial-text);
    display: block;
    margin-top: 10px;
    transition: color 0.2s;
    font-size: 15px;

    svg {
      margin-right: 8px;
    }

    &:hover {
      color: ${shade(0.2, '#f4ede8')};
    }
  }

  > button {
    background-color: Transparent;
    color: var(--secondary-color);
    display: block;
    margin-top: 24px;
    transition: color 0.2s;
    display: flex;
    align-items: center;
    svg {
      margin-right: 16px;
    }
    &:hover {
      color: ${shade(0.2, '#64C77C')};
    }
  }

  > a {
    color: var(--secondary-color);
    display: block;
    margin-top: 24px;
    transition: color 0.2s;
    display: flex;
    align-items: center;
    svg {
      margin-right: 16px;
    }
    &:hover {
      color: ${shade(0.2, '#64C77C')};
    }
  }
`

export const Background = styled.div`
  flex: 1;
  background: url(${ConfirmMailImg}) no-repeat center;
  background-color: var(--secondary-initial-bg);
`
