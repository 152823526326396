/* eslint-disable react/jsx-one-expression-per-line */
import {
  LoadingOutlined,
  DownloadOutlined,
  QuestionOutlined,
} from '@ant-design/icons'
import { Card, Space, Button, Tooltip, Tour } from 'antd'
import Text from 'antd/lib/typography/Text'
import React from 'react'
import { Link } from 'react-router-dom'
import { useListTask } from '~/hooks/Tasks/useListTasks'
import { translate } from '~/utils/locale'
import MoreOptions from '~/components/DataManipulation/Misc/MoreOptions'

import FilterTasks from '../../Filter'
import TableTasks from '../../Options/Table'
import { useTaskTour } from '~/hooks/Tour/useTaskTour'
import { useClientTour } from '~/hooks/Tour/useClientTour'
import { useTour } from '~/hooks/Tour/useTour'
import { format } from 'date-fns'
import moment from 'moment'

// import { Container } from './styles';

const TasksListBoard: React.FC = () => {
  const { isLoading, isFetching, tasksTotal } = useListTask()
  const { taskSteps, createTaskRef } = useTaskTour()
  const { openTask, setOpenTask } = useClientTour()
  const { setIsTour } = useTour()

  const filter = JSON.parse(localStorage.getItem('@Gstor:tasksFilterOptions')!)

  const fromDate = filter
    ? format(new Date(filter.fromDate), `dd/MM/yyyy`)
    : moment().startOf('day').format(`DD/MM/yyyy`)

  const toDate = filter
    ? format(new Date(filter.toDate), `dd/MM/yyyy`)
    : moment().startOf('day').format(`DD/MM/yyyy`)

  function onClose() {
    setIsTour(false)
    setOpenTask(false)
  }

  return (
    <Card
      title={
        <Space size="large">
          <b>{translate('TasksBoardsList.cardTitle')}</b>
          {isLoading ? (
            <LoadingOutlined spin />
          ) : (
            <Text
              type="secondary"
              style={{ fontWeight: 'normal', fontSize: 14 }}
            >
              {tasksTotal !== undefined ? (
                <>
                  {tasksTotal === 1
                    ? `${translate(
                        'TasksBoardsList.totalText',
                      )} ${tasksTotal} ${translate('TasksBoardsList.taskText')}`
                    : `${translate(
                        'TasksBoardsList.totalText',
                      )} ${tasksTotal} ${translate(
                        'TasksBoardsList.tasksText',
                      )}`}
                </>
              ) : (
                ''
              )}
            </Text>
          )}
          {!isLoading && isFetching && <LoadingOutlined spin />}

          {filter ? (
            <Text>
              Tarefas filtradas do dia {fromDate} até {toDate}
            </Text>
          ) : (
            ''
          )}
        </Space>
      }
      extra={
        <Space size="middle">
          <Tooltip title="Tour Tarefas">
            <Button
              icon={<QuestionOutlined />}
              shape="circle"
              size="small"
              onClick={() => setOpenTask(true)}
            />
          </Tooltip>

          <Tooltip title={translate('TasksBoardsList.exportTooltip')}>
            <Link to="/relatorioTarefas">
              <Button icon={<DownloadOutlined />} shape="circle" />
            </Link>
          </Tooltip>

          <MoreOptions
            exportTooltip={translate('TasksBoardsList.exportTooltip')}
            importTooltip={translate('TasksBoardsList.importTooltip')}
            modalTitle={translate('TasksBoardsList.modalTitle')}
            importTitle={translate('TasksBoardsList.importTitle')}
            importDescription={translate('TasksBoardsList.importDescription')}
            modelURL="/ModelDownload/tasks.xlsx"
            uploadRoute="tasks/import"
            uploadFileName="tasks"
          />

          <FilterTasks />

          <Link to="/novaTarefa">
            <Button type="primary" ref={createTaskRef}>
              {translate('TasksBoardsList.createButton')}
            </Button>
          </Link>
        </Space>
      }
      bordered={false}
      style={{ width: '100%', padding: 30 }}
    >
      <TableTasks />

      <Tour
        open={openTask}
        onClose={onClose}
        steps={taskSteps}
        indicatorsRender={(current, total) => (
          <span>
            {current + 1} / {total}
          </span>
        )}
      />
    </Card>
  )
}

export default TasksListBoard
