export default {
  pt: {
    UserAccess: {
      resultTitle: 'Você não possui acesso a plataforma.',
      resultSubTitle:
        'Por gentileza contate o gestor da plataforma para conseguir acesso.',
    },
  },
  en: {
    UserAccess: {
      resultTitle: 'You do not have access to the platform.',
      resultSubTitle: 'Please contact the platform manager to gain access.',
    },
  },
  es: {
    UserAccess: {
      resultTitle: 'No tienes acceso a la plataforma.',
      resultSubTitle:
        'Póngase en contacto con el administrador de la plataforma para obtener acceso',
    },
  },
}
