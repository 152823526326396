export default {
  pt: {
    ClientsContactsCreate: {
      pageheaderTitle: 'Novo Contato do Cliente',
      cardTitle: 'Preencha os dados do contato',
      keepRegisterText: 'Continuar cadastrando contatos do cliente?',
      saveButtonText: 'Salvar contato do cliente',
      customerLabel: 'Cliente',
      customerRule: 'Por favor informe o cliente.',
      customerPlaceholder: 'Informe o cliente que deseja adicionar um contato',
      nameLabel: 'Nome',
      nameRule: 'Por favor informe o nome do cliente.',
      namePlaceholder: 'Insira o nome do cliente',
      emailLabel: 'E-mail',
      emailRule: 'Por favor informe o e-mail do cliente.',
      emailPlaceholder: 'Insira o e-mail do cliente',
      phoneLabel: 'Telefone',
      phoneRule: 'Insira um número válido.',
      phonePlaceholder: 'Telefone do Cliente',
      officeLabel: 'Cargo',
      officeRule: 'Por favor informe o cargo do cliente.',
      officePlaceholder: 'Insira o cargo do cliente',
      responsibleLabel: 'Responsável',
      responsibleCheckbox: 'Tornar o contato como responsável pelo cliente?',
      descriptionLabel: 'Descrição',
      descriptionPlaceholder: 'Insira a descrição',
    },
  },
  en: {
    ClientsContactsCreate: {
      pageheaderTitle: 'New Customer Contact',
      cardTitle: 'Fill in the contact details',
      keepRegisterText: 'Continue registering customer contacts?',
      saveButtonText: 'Save customer contact',
      customerLabel: 'Customer',
      customerRule: 'Please inform the customer.',
      customerPlaceholder: 'Inform the customer that you want to add a contact',
      nameLabel: 'Name',
      nameRule: "Please enter the customer's name",
      namePlaceholder: 'Enter customer name',
      emailLabel: 'Email',
      emailRule: "Please provide the customer's email address.",
      emailPlaceholder: 'Enter customer email',
      phoneLabel: 'Phone',
      phoneRule: 'Please enter a valid number.',
      phonePlaceholder: 'Customer phone',
      officeLabel: 'Office',
      officeRule: "Please inform the customer's office.",
      officePlaceholder: "Enter the customer's office",
      responsibleLabel: 'Responsible',
      responsibleCheckbox: 'Make the contact responsible for the customer?',
      descriptionLabel: 'Description',
      descriptionPlaceholder: 'Enter description',
    },
  },
  es: {
    ClientsContactsCreate: {
      pageheaderTitle: 'Nuevo contacto de cliente',
      cardTitle: 'Rellene los datos de contacto',
      keepRegisterText: '¿Continuar registrando contactos de clientes?',
      saveButtonText: 'Guardar contacto con el cliente',
      customerLabel: 'Cliente',
      customerRule: 'Por favor, informe al cliente.',
      customerPlaceholder: 'Informe al cliente que desea agregar un contacto',
      nameLabel: 'Nombre',
      nameRule: 'Por favor ingrese el nombre del cliente.',
      namePlaceholder: 'Ingrese el nombre del cliente',
      emailLabel: 'Correo electrónico',
      emailRule: 'Proporcione la dirección de correo electrónico del cliente.',
      emailPlaceholder: 'Ingrese el correo electrónico del cliente',
      phoneLabel: 'Teléfono',
      phoneRule: 'Por favor ingrese un número valido.',
      phonePlaceholder: 'Teléfono del cliente',
      officeLabel: 'Cargo',
      officeRule: 'Por favor, informe el cargo del cliente.',
      officePlaceholder: 'Ingrese el cargo del cliente',
      responsibleLabel: 'Responsable',
      responsibleCheckbox:
        '¿Hacer que el contacto sea responsable del cliente?',
      descriptionLabel: 'Descripción',
      descriptionPlaceholder: 'Ingrese la descripción',
    },
  },
}
