export default {
  pt: {
    404: {
      subtitle: 'Desculpe, essa página não existe.',
      backText: 'Voltar pra o Gstor.',
    },
  },
  en: {
    404: {
      subtitle: 'Sorry, this page does not exist.',
      backText: 'Back to Gstor.',
    },
  },
  es: {
    404: {
      subtitle: 'Lo siento, esa pagina no existe.',
      backText: 'Volver al Gstor.',
    },
  },
}
