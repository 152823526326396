/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-one-expression-per-line */
import { LikeOutlined, SafetyCertificateOutlined } from '@ant-design/icons'
import { Rate, Tooltip } from 'antd'

import React from 'react'
import { Link } from 'react-router-dom'

import { useDashboard } from '~/hooks/Dashboard/useDashboard'

import { translate } from '~/utils/locale'

import { Container, Card, Content, HelpContent, Wrapper } from './styles'
import HelpTooltip from '~/components/DataManipulation/Misc/HelpTooltip'

const Rates: React.FC = () => {
  const { contribuitors, totalContribuitors } = useDashboard()

  const internalContribuitors = contribuitors?.map((c) => {
    return c.internal_evaluation
  })

  const evaluationContribuitors = contribuitors?.map((c) => {
    return c.evaluation
  })

  const mediaInternal: number | undefined = internalContribuitors?.reduce(
    (totalM, valor, índice, array) => {
      totalM += valor
      if (índice === array.length - 1) {
        return totalM / array.length
      }
      return totalM
    },
    0,
  )

  const mediaEvaluation: number | undefined = evaluationContribuitors?.reduce(
    (totalM, valor, índice, array) => {
      totalM += valor
      if (índice === array.length - 1) {
        return totalM / array.length
      }
      return totalM
    },
    0,
  )

  return (
    <Container>
      <Tooltip title={translate('DashboardRates.viewRatingsTooltip')}>
        <Link to="/colaboradores">
          <Card>
            <Wrapper>
              <Content>
                <HelpContent>
                  <div className="header">
                    <span style={{ background: '#3969B7' }}>
                      <SafetyCertificateOutlined />
                      <h4>
                        {translate('DashboardRates.internalEvaluationTitle')}
                      </h4>
                    </span>
                  </div>
                  <HelpTooltip
                    title={translate('DashboardRates.internalEvaluationHelp')}
                  />
                </HelpContent>
                <div className="section" id="rate">
                  <div className="title">
                    <h3 id="rate">
                      {mediaInternal && Number(mediaInternal).toFixed(2)}
                    </h3>
                  </div>
                  <Rate allowHalf disabled value={mediaInternal} />
                  <div className="subtitle">
                    <p>
                      {translate('DashboardRates.totalText')}{' '}
                      {totalContribuitors}
                    </p>
                  </div>
                </div>
              </Content>
            </Wrapper>
          </Card>
        </Link>
      </Tooltip>
      <Tooltip title={translate('DashboardRates.viewRatingsTooltip')}>
        <Link to="/colaboradores">
          <Card>
            <Wrapper>
              <Content>
                <HelpContent>
                  <div className="header">
                    <span style={{ background: '#64C77C' }}>
                      <LikeOutlined />
                      <h4>{translate('DashboardRates.evaluationTitle')}</h4>
                    </span>
                  </div>
                  <HelpTooltip
                    title={translate('DashboardRates.evaluationHelp')}
                  />
                </HelpContent>
                <div className="section" id="rate">
                  <div className="title">
                    <h3 id="rate">
                      {mediaEvaluation && Number(mediaEvaluation).toFixed(2)}
                    </h3>
                  </div>
                  <Rate allowHalf disabled value={mediaEvaluation} />
                  <div className="subtitle">
                    <p>
                      {translate('DashboardRates.totalText')}{' '}
                      {totalContribuitors}
                    </p>
                  </div>
                </div>
              </Content>
            </Wrapper>
          </Card>
        </Link>
      </Tooltip>
    </Container>
  )
}

export default Rates
