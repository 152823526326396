/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, createContext, useState } from 'react'
import { useQuery } from 'react-query'

import { message } from 'antd'

import { useCompanies } from '~/hooks/Companies/useCompanies'
import { useLocale } from '~/hooks/locale/useLocale'

import { ShowError } from '~/utils/errors/apiErrors'
import { translate } from '~/utils/locale'
import api from '~/services/api'

interface PaymentProps {
  id: string
  dateCreated: string
  value: number
  billingType: string
  status: string
  dueDate: string
  bankSlipUrl: string
  invoiceNumber: number
  url: string
  name: string
  description: string
}

interface PaymentContextData {
  payment?: PaymentProps[]
  createBankPayment(valueForm: object | undefined): void
  //   updatePause(valueForm: object | undefined, pauseId: string): void
  //   deletePause(pauseId: string): void
  isLoading: boolean
  refetch: () => void
  isFetching: boolean
  setDisabled(value: boolean): void
  disabled?: boolean
}

const PaymentContext = createContext<PaymentContextData>(
  {} as PaymentContextData,
)

export const PaymentProvider: React.FC = ({ children }) => {
  const { selectedCompany } = useCompanies()
  const { locale } = useLocale()

  const [disabled, setDisabled] = useState(true)

  const {
    data: payment,
    isLoading,
    refetch,
    isFetching,
  } = useQuery(
    [`Payment Details${selectedCompany}`, selectedCompany],

    async () => {
      try {
        const response = await api.get(
          `payment/details?name=${selectedCompany}`,
        )

        const { data } = response

        return data.data
      } catch (err: any) {
        ShowError(err.message, translate('usePause.getErrorMessage'), locale)
      }
    },
  )

  async function createBankPayment(valueForm: object) {
    try {
      await api.post('payment', valueForm)

      refetch()
      message.success('Compra realizada. Efetue o pagamento.')
    } catch (err: any) {
      ShowError(err.message, translate('usePause.createErrorMessage'), locale)
    }
  }

  return (
    <PaymentContext.Provider
      value={{
        payment,
        createBankPayment,
        isLoading,
        refetch,
        isFetching,
        setDisabled,
        disabled,
      }}
    >
      {children}
    </PaymentContext.Provider>
  )
}

export function usePayment(): PaymentContextData {
  const context = useContext(PaymentContext)

  if (!context) {
    throw new Error('useAuth must be used within an AutoProvider')
  }

  return context
}
