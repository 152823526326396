import { LoadingOutlined } from '@ant-design/icons'
import { Tabs } from 'antd'
import { PageHeader } from '@ant-design/pro-components'
import React from 'react'
import { useGroup } from '~/hooks/Groups/useGroup'
import { LoadingIndicator } from '~/pages/Contributor/ShowContributor/styles'
import { translate } from '~/utils/locale'

import DetailsGroup from './Details'
import DetailsPermissions from './Details/Permissions'

const GroupsTabs: React.FC = () => {
  const { groups, isLoading } = useGroup()
  const { TabPane } = Tabs

  if (isLoading || !groups) {
    return (
      <LoadingIndicator>
        <LoadingOutlined style={{ fontSize: 64, margin: 'auto' }} spin />
      </LoadingIndicator>
    )
  }

  return (
    <>
      <PageHeader
        className="site-page-header"
        onBack={() => window.history.back()}
        title={translate('GroupsComponent.pageheaderTitle')}
        subTitle={groups.name}
      />
      <Tabs
        defaultActiveKey="detalhes"
        style={{ paddingLeft: 20, paddingRight: 20 }}
      >
        <TabPane tab={translate('GroupsComponent.detailsTab')} key="detalhes">
          <DetailsGroup />
        </TabPane>
        <TabPane
          tab={translate('GroupsComponent.permissionsTab')}
          key="permissions"
        >
          <DetailsPermissions />
        </TabPane>
      </Tabs>
    </>
  )
}

export default GroupsTabs
