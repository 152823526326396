export default {
  pt: {
    ForgotPassword: {
      emailYup: {
        required: 'E-mail obrigatório',
        valid: 'Digite um e-mail válido',
      },
      successMessage: 'O e-mail foi enviado. Cheque sua caixa de entrada',
      errorMessage: 'Erro ao enviar.',
      helmetTitle: 'Recuperar dados',
      formTitle: 'Recuperar dados',
      formTitleUser: 'Recuperar usuário',
      formTitlePassword: 'Recuperar senha',
      formSubTitle:
        'Enviaremos uma solicitação de recuperação de senha ou usuário para seu e-mail.',
      formSubTitleUser:
        'Enviaremos uma solicitação de recuperação de usuário para seu e-mail.',
      formSubTitlePassword:
        'Enviaremos uma solicitação de recuperação de senha para seu e-mail.',
      selectPlaceholder: 'Informe qual informação deseja recuperar',
      userOption: 'Usuário',
      passwordOption: 'Senha',
      inputPlaceholder: 'E-mail',
      buttonText: 'Enviar',
      backText: 'Faça login',
    },
  },
  en: {
    ForgotPassword: {
      emailYup: {
        required: 'Mandatory email',
        valid: 'Enter a valid email address',
      },
      successMessage: 'The email has been sent. Check your inbox',
      errorMessage: 'Error sending.',
      helmetTitle: 'Recover data',
      formTitle: 'Recover data',
      formTitleUser: 'Retrieve user',
      formTitlePassword: 'Recover Password',
      formSubTitle:
        'We will send a password or username recovery request to your email.',
      formSubTitleUser: 'We will send a user recovery request to your email.',
      formSubTitlePassword:
        'We will send a password recovery request to your email.',
      selectPlaceholder: 'Inform what information you want to recover',
      userOption: 'User',
      passwordOption: 'Password',
      inputPlaceholder: 'Email',
      buttonText: 'Submit',
      backText: 'Login',
    },
  },
  es: {
    ForgotPassword: {
      emailYup: {
        required: 'Correo electrónico obligatorio',
        valid: 'Introduzca una dirección de correo electrónico válida',
      },
      successMessage: 'El correo electrónico ha sido enviado. Revisa tu correo',
      errorMessage: 'Error al enviar.',
      helmetTitle: 'Recuperar datos',
      formTitle: 'Recuperar datos',
      formTitleUser: 'Recuperar usuario',
      formTitlePassword: 'Recuperar contraseña',
      formSubTitle:
        'Le enviaremos una solicitud de recuperación de contraseña o nombre de usuario a su correo electrónico.',
      formSubTitleUser:
        'Le enviaremos una solicitud de recuperación de usuario a su correo electrónico.',
      formSubTitlePassword:
        'Le enviaremos una solicitud de recuperación de contraseña a su correo electrónico.',
      selectPlaceholder: 'Informa qué información quieres recuperar',
      userOption: 'Usuario',
      passwordOption: 'Clave',
      inputPlaceholder: 'Correo electrónico',
      buttonText: 'Enviar',
      backText: 'Acceso',
    },
  },
}
