export default {
  pt: {
    TasksOptionsTable: {
      dateString: 'às',
      internalCodeColumn: 'Código da tarefa',
      responsibleColumn: 'Colaborador',
      clientColumn: 'Cliente',
      initialTimeColumn: 'Data',
      taskTypeColumn: 'Tipo de Tarefa',
      statusColumn: 'Status',
      statusReceived: 'Tarefa Enviada',
      statusViewed: 'Tarefa Visualizada',
      statusEnRoute: 'Tarefa a caminho',
      statusWorking: 'Tarefa sendo realizada',
      statusBreak: 'Tarefa Pausada',
      statusResumeWorking: 'Tarefa Retomada',
      statusCanceled: 'Tarefa Cancelada',
      statusFinished: 'Tarefa Finalizada',
      actionsColumn: 'Ações',
      viewTooltip: 'Visualizar Tarefa',
      deletePopconfirm: 'Deseja mesmo deletar a tarefa?',
      deleteTooltip: 'Deletar',
    },
  },
  en: {
    TasksOptionsTable: {
      dateString: 'at',
      internalCodeColumn: 'Task code',
      responsibleColumn: 'Contribuutor',
      clientColumn: 'Customer',
      initialTimeColumn: 'Date',
      taskTypeColumn: 'Task Type',
      statusColumn: 'Status',
      statusReceived: 'Task Submitted',
      statusViewed: 'Task Viewed',
      statusEnRoute: 'Task on the way',
      statusWorking: 'Task being performed',
      statusBreak: 'Paused Task',
      statusResumeWorking: 'Resume Task',
      statusCanceled: 'Canceled Task',
      statusFinished: 'Finished Task',
      actionsColumn: 'Actions',
      viewTooltip: 'View Task',
      deletePopconfirm: 'Do you really want to delete the task?',
      deleteTooltip: 'Delete',
    },
  },
  es: {
    TasksOptionsTable: {
      dateString: 'a las',
      internalCodeColumn: 'Código de la tarea',
      responsibleColumn: 'Colaborador',
      clientColumn: 'Cliente',
      initialTimeColumn: 'Fecha',
      taskTypeColumn: 'Tipo de tarea',
      statusColumn: 'Status',
      statusReceived: 'Tarea Enviada',
      statusViewed: 'Tarefa vista',
      statusEnRoute: 'Tarea en camino',
      statusWorking: 'Tarea que se está realizando',
      statusBreak: 'Tarea en Pausa',
      statusResumeWorking: 'Reanudar Tarea',
      statusCanceled: 'Tarea Cancelada',
      statusFinished: 'Tarefa Terminada',
      actionsColumn: 'Acciones',
      viewTooltip: 'Ver Tarea',
      deletePopconfirm: '¿Realmente quieres eliminar la tarea?',
      deleteTooltip: 'Borrar',
    },
  },
}
