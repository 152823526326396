/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react'

import { Divider, Drawer, Button, message, Popconfirm } from 'antd'

import Text from 'antd/lib/typography/Text'
import { FaWhatsapp } from 'react-icons/fa'
import { FiPhone } from 'react-icons/fi'
import { useParams } from 'react-router-dom'
import { Container, Wrapper } from './styles'
import CreatePhones from './Create'
import api from '~/services/api'

import { useClients } from '~/hooks/Clients/useClients'
import { useCompanies } from '~/hooks/Companies/useCompanies'
import { useLocale } from '~/hooks/locale/useLocale'

import { ShowError } from '~/utils/errors/apiErrors'
import { translate } from '~/utils/locale'

const AdditionalPhones: React.FC = () => {
  const { selectedCompany } = useCompanies()
  const { locale } = useLocale()
  const { customerId } = useParams<{ customerId: string }>()

  const { clients, isLoading, refetch } = useClients()
  const [open, setOpen] = useState(false)

  const showDrawer = () => {
    setOpen(true)
  }
  const onClose = () => {
    setOpen(false)
  }

  async function deleteTask(index: any) {
    const listPhones = clients?.phones

    if (listPhones) {
      const itensCopy = Array.from(listPhones)
      itensCopy.splice(index, 1)

      const data = {
        additional_phones: itensCopy,
      }

      try {
        await api.put(
          `/company/${selectedCompany}/customer/${customerId}`,
          data,
        )
        message.success(translate('ClientsAdditionalPhones.successMessage'))
      } catch (err: any) {
        ShowError(
          err.message,
          translate('ClientsAdditionalPhones.errorMessage'),
          locale,
        )
      }
      refetch()
    }
  }

  if (isLoading || !clients) {
    return <p />
  }

  return (
    <Container>
      <span onClick={showDrawer}>
        {translate('ClientsAdditionalPhones.seePhonesText')}
      </span>
      <Drawer
        title={translate('ClientsAdditionalPhones.drawerTitle')}
        placement="right"
        onClose={onClose}
        open={open}
        width={500}
      >
        <Wrapper>
          <CreatePhones />
          <Divider>{translate('ClientsAdditionalPhones.phonesText')}</Divider>

          {clients.phones === undefined ? (
            <p>{translate('ClientsAdditionalPhones.noPhonesText')}</p>
          ) : (
            <>
              {clients.phones.map((phone, index) => (
                <div key="index">
                  <div className="phones">
                    {phone.is_whatsapp ? <FaWhatsapp /> : <FiPhone />}
                    {phone.is_whatsapp ? (
                      <Text strong>
                        {translate('ClientsAdditionalPhones.whatsappText')}
                      </Text>
                    ) : (
                      <Text strong>
                        {translate('ClientsAdditionalPhones.noWhatsappText')}
                      </Text>
                    )}
                  </div>
                  <div className="types">
                    <Text>{phone.formattedPhone}</Text>
                    {phone.type === 'cell' ? (
                      <Text>
                        {translate('ClientsAdditionalPhones.phoneTypeCell')}
                      </Text>
                    ) : (
                      ''
                    )}
                    {phone.type === 'home' ? (
                      <Text>
                        {translate('ClientsAdditionalPhones.phoneTypeHome')}
                      </Text>
                    ) : (
                      ''
                    )}
                    {phone.type === 'commercial' ? (
                      <Text>
                        {translate(
                          'ClientsAdditionalPhones.phoneTypeCommercial',
                        )}
                      </Text>
                    ) : (
                      ''
                    )}
                  </div>

                  <Popconfirm
                    title={translate(
                      'ClientsAdditionalPhones.deletePopconfirm',
                    )}
                    onConfirm={() => deleteTask(index)}
                  >
                    <Button type="primary" danger size="small">
                      {translate('ClientsAdditionalPhones.deleteButtonText')}
                    </Button>
                  </Popconfirm>
                  <Divider />
                </div>
              ))}
            </>
          )}
        </Wrapper>
      </Drawer>
    </Container>
  )
}

export default AdditionalPhones
