/* eslint-disable react/jsx-one-expression-per-line */
import { LoadingOutlined } from '@ant-design/icons'
import { Card, Space } from 'antd'
import Text from 'antd/lib/typography/Text'
import React from 'react'
import { usePause } from '~/hooks/Tasks/Pause/usePause'
import { translate } from '~/utils/locale'
import CreateTicketType from './Create'
import TicketTypeTable from './Table'

const TaskTypeBoard: React.FC = () => {
  const { pauseTotal, isLoading, isFetching } = usePause()

  return (
    <Card
      title={
        <Space size="large">
          <b>{translate('TicketsType.cardTitle')}</b>
          {isLoading ? (
            <LoadingOutlined spin />
          ) : (
            <Text
              type="secondary"
              style={{ fontWeight: 'normal', fontSize: 14 }}
            >
              {pauseTotal !== undefined ? (
                <>
                  {pauseTotal === 1
                    ? `${translate(
                        'TicketsType.totalText',
                      )} ${pauseTotal} ${translate(
                        'TicketsType.ticketTypeText',
                      )}`
                    : `${translate(
                        'TicketsType.totalText',
                      )} ${pauseTotal} ${translate(
                        'TicketsType.ticketsTypesText',
                      )}`}
                </>
              ) : (
                ''
              )}
            </Text>
          )}
          {!isLoading && isFetching && <LoadingOutlined spin />}
        </Space>
      }
      extra={
        <Space size="small">
          <CreateTicketType />
        </Space>
      }
      bordered={false}
      style={{ width: '100%', padding: 30 }}
    >
      <TicketTypeTable />
    </Card>
  )
}

export default TaskTypeBoard
