/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, {
  ReactNode,
  useContext,
  createContext,
  useState,
  Dispatch,
  SetStateAction,
} from 'react'
import { useQuery } from 'react-query'
import { formatPhoneNumber } from 'react-phone-number-input'

import moment from 'moment'

import { message } from 'antd'
import api from '~/services/api'

import { useCompanies } from '~/hooks/Companies/useCompanies'
import { useLocale } from '~/hooks/locale/useLocale'

import { ShowError } from '~/utils/errors/apiErrors'
import { translate } from '~/utils/locale'

interface ContributorProviderProps {
  children: ReactNode
}

interface AditionalInfo {
  evaluation: number
  id: string
  internal_evaluation: number
}

interface AdditionalPhones {
  phone: string
  type: string
  is_whatsapp: boolean
  formattedPhone: string
}

interface Contact {
  additional_phones: AdditionalPhones[]
  avatar: any
  avatar_url: string
  birth: string
  formattedBirth: string
  cnpj: string
  rg: string
  formattedCnpj: string
  formattedDocument: string
  document: string
  gender: string
  email: string
  id: string
  name: string
  person_type: string
  phone: string
  formattedPhone: string
  notes: string
  value_hour_worked: number
  operating_range: number
  value_km_traveled: number
}

interface Status {
  color: string
  id: string
  description: string
  title: string
  type: string
}

interface Skill {
  id: string
  description: string
  name: string
}

interface Skills {
  description: string
  id: string
  level: string
  skill: Skill
}

interface Addresses {
  city: string
  complement: string
  id: string
  location: {
    x: number
    y: number
  }
  neighborhood: string
  number: string
  state: string
  street: string
  zip_code: string
}

interface Company {
  id: string
  name: string
  logo_url: string
}

interface Address {
  address: Addresses
  company: Company
  contact: Contact
  id: string
  notes: string
  type: string
}

interface Attachments {
  id: string
  title: string
  description: string
  attachment_url: string
  attachment: string
  type: string
}

interface ContributorData {
  id: string
  address: Address[]
  attachments: Attachments[]
  contact: Contact
  skills: Skills[]
  status: Status
  company: Company
}

interface ContributorContextData {
  contributorRefetch: () => void
  data: ContributorData | undefined
  contributorAttachments: Attachments[]
  contributorContact: Contact
  contributorStatus: Status
  contributorSkills: Skills[]
  contributorAddress: Address[]
  contributorAditionalInfo: AditionalInfo | undefined
  isLoading: boolean
  isFetching: boolean
  setSelectedContributor: Dispatch<SetStateAction<string>>
  setSelectedContact: Dispatch<SetStateAction<string>>
  setIsEdit(value: boolean): void
  isEdit?: boolean
  setIsEditBadge(value: boolean): void
  isEditBadge?: boolean
  updateStatus(statusId: string): void
}

const ContributorContext = createContext<ContributorContextData>(
  {} as ContributorContextData,
)

export function ContributorProvider({ children }: ContributorProviderProps) {
  const { selectedCompany } = useCompanies()
  const { locale } = useLocale()

  const [selectedContributor, setSelectedContributor] = useState('')
  const [selectedContact, setSelectedContact] = useState('')

  const [isEdit, setIsEdit] = useState<boolean>()
  const [isEditBadge, setIsEditBadge] = useState<any>()

  const { data, isLoading, isFetching, refetch } = useQuery(
    [
      `contributor-${selectedContributor}`,
      selectedContributor,
      selectedCompany,
    ],
    async () => {
      try {
        if (!selectedCompany || !selectedContributor) {
          return undefined
        }

        const response = await api.get(
          `/company/${selectedCompany}/collaborator/${selectedContributor}`,
        )

        const responseAddress = await api.get(
          `/company/${selectedCompany}/contact/${
            selectedContact || response.data.contact.id
          }/addresses/`,
        )

        const addressInfo = responseAddress.data

        const contributorInfoRaw = response.data

        if (contributorInfoRaw.contact.birth) {
          contributorInfoRaw.contact.birth = moment(
            contributorInfoRaw.contact.birth,
            'YYYY/MM/DD',
          )

          contributorInfoRaw.contact.formattedBirth = moment(
            contributorInfoRaw.contact.birth,
          ).format('L')
        }

        if (
          contributorInfoRaw.contact.person_type === 'legalPerson' ||
          contributorInfoRaw.contact.person_type === 'physicalPerson'
        ) {
          if (contributorInfoRaw.contact.document) {
            const match = contributorInfoRaw.contact.document.match(
              /^(\d{3})(\d{3})(\d{3})(\d{2})$/,
            )

            if (match) {
              contributorInfoRaw.contact.formattedDocument = `${match[1]}.${match[2]}.${match[3]}-${match[4]}`
            }
          }
        } else {
          contributorInfoRaw.contact.formattedDocument =
            contributorInfoRaw.contact.document
        }

        if (contributorInfoRaw.contact.phone) {
          contributorInfoRaw.contact.phone = `+${contributorInfoRaw.contact.phone}`
          contributorInfoRaw.contact.formattedPhone = formatPhoneNumber(
            contributorInfoRaw.contact.phone,
          )

          if (
            contributorInfoRaw.contact.formattedPhone.replace(/[0-9]/g, '') ===
            ''
          ) {
            if (contributorInfoRaw.contact.formattedPhone.length === 10) {
              const forceFormat =
                contributorInfoRaw.contact.formattedPhone.match(
                  /^(\d{2})(\d{4})(\d{4})$/,
                )

              if (forceFormat) {
                contributorInfoRaw.contact.formattedPhone = `(${forceFormat[1]}) ${forceFormat[2]}-${forceFormat[3]}`
              }
            } else if (contributorInfoRaw.contact.formattedPhone.length === 9) {
              const forceFormat =
                contributorInfoRaw.contact.formattedPhone.match(
                  /^(\d{2})(\d{4})(\d{3})$/,
                )

              if (forceFormat) {
                contributorInfoRaw.contact.formattedPhone = `(${forceFormat[1]}) ${forceFormat[2]}-${forceFormat[3]}`
              }
            }
          }
        }

        if (contributorInfoRaw.contact.cnpj) {
          const match = contributorInfoRaw.contact.cnpj.match(
            /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
          )

          if (match) {
            contributorInfoRaw.contact.formattedCnpj = `${match[1]}.${match[2]}.${match[3]}/${match[4]}-${match[5]}`
          }
        }

        if (contributorInfoRaw.contact.additional_phones) {
          contributorInfoRaw.contact.additional_phones.forEach(
            (additional_phone: AdditionalPhones) => {
              if (additional_phone.phone) {
                additional_phone.phone = `+${additional_phone.phone}`
                additional_phone.formattedPhone = formatPhoneNumber(
                  additional_phone.phone,
                )

                if (
                  additional_phone.formattedPhone.replace(/[0-9]/g, '') === ''
                ) {
                  if (additional_phone.formattedPhone.length === 10) {
                    const forceFormat = additional_phone.formattedPhone.match(
                      /^(\d{2})(\d{4})(\d{4})$/,
                    )

                    if (forceFormat) {
                      additional_phone.formattedPhone = `(${forceFormat[1]}) ${forceFormat[2]}-${forceFormat[3]}`
                    }
                  } else if (additional_phone.formattedPhone.length === 9) {
                    const forceFormat = additional_phone.formattedPhone.match(
                      /^(\d{2})(\d{4})(\d{3})$/,
                    )

                    if (forceFormat) {
                      additional_phone.formattedPhone = `(${forceFormat[1]}) ${forceFormat[2]}-${forceFormat[3]}`
                    }
                  }
                }
              }
            },
          )
        }

        const contributorInfo = {
          id: contributorInfoRaw.id,
          attachments: contributorInfoRaw.collaboratorAttachments,
          status: contributorInfoRaw.status,
          contact: {
            ...contributorInfoRaw.contact,
            notes: contributorInfoRaw.notes,
            value_hour_worked: contributorInfoRaw.value_hour_worked,
            value_km_traveled: contributorInfoRaw.value_km_traveled,
            operating_range: contributorInfoRaw.operating_range,
          },
          skills: contributorInfoRaw.collaboratorSkills,
          address: addressInfo,
          company: contributorInfoRaw.company,
          aditionalInfo: {
            evaluation: contributorInfoRaw.evaluation,
            id: contributorInfoRaw.id,
            internal_evaluation: contributorInfoRaw.internal_evaluation,
          },
        }

        return contributorInfo
      } catch (err: any) {
        ShowError(
          err.message,
          translate('useContributor.getErrorMessage'),
          locale,
        )
      }
    },
    {
      staleTime: 5000, // 5 segundos
    },
  )

  async function updateStatus(statusId: string) {
    try {
      await api.put(
        `/company/${selectedCompany}/contributors/${selectedContributor}/status/${statusId}`,
      )

      refetch()
      message.success(translate('useContributor.updateSuccessMessage'))
    } catch (err: any) {
      ShowError(
        err.message,
        translate('useContributor.updateErrorMessage'),
        locale,
      )
    }
  }

  return (
    <ContributorContext.Provider
      value={{
        data,
        contributorAttachments: data?.attachments,
        contributorContact: data?.contact,
        contributorStatus: data?.status,
        contributorSkills: data?.skills,
        contributorAddress: data?.address,
        contributorAditionalInfo: data?.aditionalInfo,
        isLoading,
        isFetching,
        contributorRefetch: refetch,
        setSelectedContributor,
        setSelectedContact,
        isEdit,
        setIsEdit,
        updateStatus,
        isEditBadge,
        setIsEditBadge,
      }}
    >
      {children}
    </ContributorContext.Provider>
  )
}

export function useContributor() {
  const context = useContext(ContributorContext)

  return context
}
