/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { PlusCircleOutlined } from '@ant-design/icons'
import { PageHeader } from '@ant-design/pro-components'
import {
  Form,
  Input,
  Row,
  Col,
  Checkbox,
  Card,
  Space,
  Button,
  Select,
  DatePicker,
} from 'antd'

import React, { useState } from 'react'
import PhoneInputWithCountrySelect from 'react-phone-number-input'

import pt_BR from 'react-phone-number-input/locale/pt-BR.json'

import { useListContributors } from '~/hooks/Contributors/useListContributors'
import { useListTickets } from '~/hooks/HelpDesk/useListTickets'
import { useTicketType } from '~/hooks/HelpDesk/useTicketType'
import { useTeams } from '~/hooks/Teams/useListTeams'

import { translate } from '~/utils/locale'
import { ContainerButton } from './styles'

interface TicketsData {
  title: string
  request_type_id: string
  customer: string
  customer_phone: string
  customer_email: string
  priority: string
  description: string
  id: string
  date: string
}

const CreateTickets: React.FC = () => {
  const { createTicket } = useListTickets()
  const { ticketType, isLoading } = useTicketType()
  const { data } = useListContributors()
  const { teams } = useTeams()

  const [responsible, setResponsible] = useState('')

  const [form] = Form.useForm()

  const [keepRegister, setKeepRegister] = useState(false)

  function handleResponsible(value: string) {
    setResponsible(value)
  }

  async function onCreateTickets(dataForm: TicketsData) {
    try {
      if (dataForm.customer_phone) {
        dataForm.customer_phone = dataForm.customer_phone.replace(/[^0-9]/g, '')
      }

      console.log(dataForm)

      const ticketsData = {
        title: dataForm.title,
        request_type_id: dataForm.request_type_id,
        customer: dataForm.customer,
        customer_email: dataForm.customer_email,
        customer_phone: dataForm.customer_phone,
        priority: dataForm.priority,
        description: dataForm.description,
        date: dataForm.date,
        responsible: {
          type: responsible,
          id: dataForm.id,
        },
      }

      await createTicket(ticketsData, keepRegister)
    } catch (err: any) {
      console.log(err)
    }
  }

  return (
    <>
      <PageHeader
        className="site-page-header"
        onBack={() => window.history.back()}
        title={translate('HelpDeskCreate.pageTitle')}
      />
      <Card
        title={translate('HelpDeskCreate.pageSubTitle')}
        extra={
          <Space size="small">
            <Checkbox
              onChange={(e) => {
                setKeepRegister(e.target.checked)
              }}
              checked={keepRegister}
            >
              {translate('HelpDeskCreate.keepRegisterText')}
            </Checkbox>
            <ContainerButton>
              <Button
                type="primary"
                icon={<PlusCircleOutlined />}
                form="newCreateTickets"
                htmlType="submit"
                id="btn_create_ticket"
              >
                {translate('HelpDeskCreate.registerButton')}
              </Button>
            </ContainerButton>
          </Space>
        }
        bordered={false}
        style={{ width: '100%', padding: 30 }}
      >
        <Form
          id="newCreateTickets"
          layout="vertical"
          onFinish={onCreateTickets}
          form={form}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label={translate('HelpDeskCreate.titleLabel')}
                name="title"
                rules={[
                  {
                    required: true,
                    message: translate('HelpDeskCreate.titleRule'),
                  },
                ]}
              >
                <Input
                  placeholder={translate('HelpDeskCreate.titlePlaceholder')}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={translate('HelpDeskCreate.solicitationLabel')}
                name="request_type_id"
                rules={[
                  {
                    required: true,
                    message: translate('HelpDeskCreate.solicitationRule'),
                  },
                ]}
              >
                <Select
                  placeholder={translate(
                    'HelpDeskCreate.solicitationPlaceholder',
                  )}
                  loading={isLoading}
                >
                  {ticketType?.map((ticket) => (
                    <Select.Option value={ticket._id} key={ticket._id}>
                      {ticket.title}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label={translate('HelpDeskCreate.clientLabel')}
                name="customer"
                rules={[
                  {
                    required: true,
                    message: translate('HelpDeskCreate.clientRule'),
                  },
                ]}
              >
                <Input
                  placeholder={translate('HelpDeskCreate.clientPlaceholder')}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={translate('HelpDeskCreate.emailClientLabel')}
                name="customer_email"
                rules={[
                  {
                    required: true,
                    message: translate('HelpDeskCreate.emailClientRule'),
                  },
                ]}
              >
                <Input
                  placeholder={translate(
                    'HelpDeskCreate.emailClientPlaceholder',
                  )}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label={translate('HelpDeskCreate.phoneClientLabel')}
                name="customer_phone"
                rules={[
                  {
                    required: true,
                    message: 'Informe o telefone.',
                  },
                ]}
              >
                <PhoneInputWithCountrySelect
                  placeholder={translate(
                    'HelpDeskCreate.phoneClientPlaceholder',
                  )}
                  labels={pt_BR}
                  defaultCountry="BR"
                  international
                  onChange={() => {
                    // do nothing
                  }}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label={translate('HelpDeskCreate.responsibleLabel')}
                name="responsible"
                rules={[
                  {
                    required: true,
                    message: translate('HelpDeskCreate.responsibleRule'),
                  },
                ]}
              >
                <Select
                  placeholder={translate(
                    'HelpDeskCreate.responsiblePlaceholder',
                  )}
                  onChange={handleResponsible}
                >
                  <Select.Option value="collaborator">
                    {translate('HelpDeskCreate.contributorsOption')}
                  </Select.Option>
                  <Select.Option value="team">
                    {translate('HelpDeskCreate.teamsOptions')}
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              {responsible === 'collaborator' ? (
                <Form.Item
                  label={translate('HelpDeskCreate.contributorsLabel')}
                  name="id"
                  rules={[
                    {
                      required: true,
                      message: translate('HelpDeskCreate.contributorsRule'),
                    },
                  ]}
                >
                  <Select
                    placeholder={translate(
                      'HelpDeskCreate.contributorsPlaceholder',
                    )}
                  >
                    {data?.map((contributors) => (
                      <Select.Option
                        value={contributors?.id}
                        key={contributors?.id}
                      >
                        {contributors?.contact?.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              ) : (
                ''
              )}

              {responsible === 'team' ? (
                <Form.Item
                  label={translate('HelpDeskCreate.teamsLabel')}
                  name="id"
                  rules={[
                    {
                      required: true,
                      message: translate('HelpDeskCreate.teamsRule'),
                    },
                  ]}
                >
                  <Select
                    placeholder={translate('HelpDeskCreate.teamsPlaceholder')}
                  >
                    {teams?.map((team) => (
                      <Select.Option value={team?.id} key={team?.id}>
                        {team?.title}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              ) : (
                ''
              )}
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label={translate('HelpDeskCreate.priorityLabel')}
                name="priority"
                rules={[
                  {
                    required: true,
                    message: translate('HelpDeskCreate.priorityRule'),
                  },
                ]}
              >
                <Select
                  placeholder={translate(
                    'TasksCreateDetails.priorityPlaceholder',
                  )}
                >
                  <Select.Option value="high">
                    {translate('TasksCreateDetails.priorityHigh')}
                  </Select.Option>
                  <Select.Option value="medium">
                    {translate('TasksCreateDetails.priorityMedium')}
                  </Select.Option>
                  <Select.Option value="low">
                    {translate('TasksCreateDetails.priorityLow')}
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label={translate('HelpDeskCreate.dateLabel')}
                name="date"
                rules={[
                  {
                    required: true,
                    message: translate('HelpDeskCreate.dateRules'),
                  },
                ]}
              >
                <DatePicker
                  format="DD/MM/YYYY HH:mm"
                  style={{ width: '100%' }}
                  placeholder={translate('HelpDeskCreate.datePlaceholder')}
                />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item
            label={translate('HelpDeskCreate.descriptionLabel')}
            name="description"
            rules={[
              {
                required: true,
                message: translate('HelpDeskCreate.descriptionRule'),
              },
            ]}
          >
            <Input.TextArea
              placeholder={translate('HelpDeskCreate.descriptionPlaceholder')}
            />
          </Form.Item>
        </Form>
      </Card>
    </>
  )
}

export default CreateTickets
