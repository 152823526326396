/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable func-names */
import { Col, Form, Input, Row, Select, Space, Tour } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTaskCreate } from '~/hooks/Tasks/Create/createTask'

import { translate } from '~/utils/locale'

import ContributorCreateTask from '../Modal/Contributor'
import ContributorsTaskTable from '../Modal/Contributor/Table'
import ScaleModalTask from '../Modal/Scale'
import TeamsCreateTask from '../Modal/Teams'

import { useTaskTour } from '~/hooks/Tour/useTaskTour'
import { useTour } from '~/hooks/Tour/useTour'

interface ResponsibleCreateTaskProps {
  form: any
}

const ResponsibleCreateTask: React.FC<ResponsibleCreateTaskProps> = ({
  form,
}) => {
  const {
    contributorData,
    setContributorData,
    teamData,
    setTeamData,
    scaleId,
    isSaveTask,
  } = useTaskCreate()

  const {
    openResponsibleTask,
    setOpenResponsibleTask,
    createResponsibleSteps,
  } = useTaskTour()

  const { setIsTour } = useTour()

  function onClose() {
    setIsTour(false)
    setOpenResponsibleTask(false)
  }

  const [responsible, setResponsible] = useState<string>('')
  const [typeResponsible, setTypeResponsible] = useState('')

  function handleContributor(value: string) {
    setTypeResponsible(value)
    setContributorData({ id: '', name: '' })
    setTeamData({ id: '', name: '' })

    setResponsible('')
  }

  function handleReponsible(value: string) {
    setResponsible(value)

    form.setFieldsValue({ id: '' })
    setContributorData({ id: '', name: '' })
    setTeamData({ id: '', name: '' })
  }

  useEffect(() => {
    if (scaleId?.id) {
      form.setFieldsValue({ scale_id: scaleId?.name })
    }

    if (contributorData?.id) {
      form.setFieldsValue({ id: contributorData?.name })
    }

    if (teamData?.id) {
      form.setFieldsValue({ id: teamData?.name })
    }
  }, [contributorData, teamData, scaleId, form])

  const { Option } = Select

  return (
    <>
      <Row gutter={14}>
        {isSaveTask ? (
          <Col span={12}>
            <Form.Item
              label="Selecionar responsável através de"
              name="typeResponsible"
              rules={[
                {
                  required: true,
                  message: 'Por favor informe o tipo de responsável.',
                },
              ]}
              initialValue={scaleId?.id ? 'scale' : 'contributor'}
            >
              <Select
                placeholder="Selecione uma opção"
                onChange={handleContributor}
                value={scaleId?.id ? 'scale' : 'contributor'}
                defaultValue={scaleId?.id ? 'scale' : 'contributor'}
              >
                <Option value="scale">Escala</Option>
                <Option value="contributor">Colaboradores</Option>
              </Select>
            </Form.Item>
          </Col>
        ) : (
          <Col span={12}>
            <Form.Item
              label="Selecionar responsável através de"
              name="typeResponsible"
              rules={[
                {
                  required: true,
                  message: 'Por favor informe o tipo de responsável.',
                },
              ]}
            >
              <Select
                placeholder="Selecione uma opção"
                onChange={handleContributor}
              >
                <Option value="scale">Escala</Option>
                <Option value="contributor">Colaboradores</Option>
              </Select>
            </Form.Item>
          </Col>
        )}

        <Col span={12}>
          {typeResponsible === 'scale' ? (
            <Form.Item
              label={
                <Space>
                  <p>Escala</p>
                  {scaleId?.id ? <ScaleModalTask /> : ''}
                </Space>
              }
              name="scale_id"
              rules={[
                {
                  required: true,
                  message: 'Por favor informe uma escala.',
                },
              ]}
            >
              {scaleId?.id ? <Input /> : <ScaleModalTask />}
            </Form.Item>
          ) : (
            ''
          )}

          {typeResponsible === 'contributor' || contributorData?.id ? (
            <Form.Item
              label={
                <Space>
                  <p>Colaborador</p>
                  {contributorData?.id ? (
                    <ContributorsTaskTable form={form} />
                  ) : (
                    ''
                  )}
                </Space>
              }
              name="id"
            >
              {contributorData?.id ? (
                <Input />
              ) : (
                <ContributorsTaskTable form={form} />
              )}
            </Form.Item>
          ) : (
            ''
          )}
        </Col>
      </Row>

      {(scaleId?.id && typeResponsible === 'scale') || teamData?.id ? (
        <Row gutter={14}>
          <Col span={12}>
            <Form.Item
              label="Tarefa será executada por"
              name="type"
              rules={[
                {
                  required: true,
                  message: 'Por favor informe o tipo de responsável.',
                },
              ]}
              initialValue={teamData?.id ? 'team' : 'collaborator'}
            >
              <Select
                placeholder="Selecione por quem a tarefa será executada"
                onChange={handleReponsible}
                disabled={!scaleId?.id}
                defaultValue={teamData?.id ? 'team' : 'collaborator'}
              >
                <Option value="collaborator">Colaborador</Option>
                <Option value="team">Equipe</Option>
              </Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            {responsible === 'team' || teamData?.id ? (
              <Form.Item
                label={
                  <Space>
                    <p>{translate('TasksCreateResponsible.teamText')}</p>
                    {teamData?.id ? <TeamsCreateTask /> : ''}
                  </Space>
                }
                name="id"
              >
                {teamData?.id ? <Input /> : <TeamsCreateTask />}
              </Form.Item>
            ) : (
              ''
            )}
            {responsible === 'collaborator' || contributorData?.id ? (
              <Form.Item
                label={
                  <Space>
                    <p>{translate('TasksCreateResponsible.contributorText')}</p>
                    {contributorData?.id ? <ContributorCreateTask /> : ''}
                  </Space>
                }
                name="id"
                rules={[
                  {
                    required: true,
                    message: 'Por favor informe adicione um responsável.',
                  },
                ]}
              >
                {contributorData?.id ? <Input /> : <ContributorCreateTask />}
              </Form.Item>
            ) : (
              ''
            )}
          </Col>
        </Row>
      ) : (
        ''
      )}

      <Tour
        open={openResponsibleTask}
        onClose={onClose}
        steps={createResponsibleSteps}
        indicatorsRender={(current, total) => (
          <span>
            {current + 1} / {total}
          </span>
        )}
      />
    </>
  )
}

export default ResponsibleCreateTask
