export default {
  pt: {
    TasksCreateModalTaskType: {
      actionsColumn: 'Ações',
      addPopconfirm: 'Deseja mesmo adicionar o tipo de tarefa',
      addTooltip: 'Adicionar tipo de tarefa',
      taskTypeColumn: 'Tipo de tarefa',
      runtimeColumn: 'Tempo de Execução',
      descriptionColumn: 'Descrição',
      addButton: 'Adicionar Tipo de Tarefa',
      changeButton: 'Alterar Tipo de Tarefa',
      modalTitle: 'Adicionar Tipo de Tarefa',
    },
  },
  en: {
    TasksCreateModalTaskType: {
      actionsColumn: 'Actions',
      addPopconfirm: 'Do you really want to add the task type',
      addTooltip: 'Add Task Type',
      taskTypeColumn: 'Task type',
      runtimeColumn: 'Runtime',
      descriptionColumn: 'Description',
      addButton: 'Add Task Type',
      changeButton: 'Change Task Type',
      modalTitle: 'Add Task Type',
    },
  },
  es: {
    TasksCreateModalTaskType: {
      actionsColumn: 'Acciones',
      addPopconfirm: '¿Realmente desea agregar el tipo de tarea?',
      addTooltip: 'Añadir Tipo de Tarea',
      taskTypeColumn: 'Tipo de tarea',
      runtimeColumn: 'Tiempo de ejecución',
      descriptionColumn: 'Descripción',
      addButton: 'Añadir Tipo de Tarea',
      changeButton: 'Cambiar Tipo de Tarea',
      modalTitle: 'Añadir Tipo de Tarea',
    },
  },
}
