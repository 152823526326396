import styled from 'styled-components'

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 1rem;

  .card {
    display: flex;
    justify-content: space-between;
    align-items: center;

    border: 1px solid #cccccc;
    border-radius: 3px;
    padding: 8px;
    width: 100%;
    margin: 0px 5px 5px 0px;
    p {
      color: var(--primary-initial-text);
    }
  }
`

export const Cards = styled.div``
