export default {
  pt: {
    GroupsComponent: {
      pageheaderTitle: 'Visualizar Permissões',
      detailsTab: 'Detalhes',
      permissionsTab: 'Permissões',
    },
  },
  en: {
    GroupsComponent: {
      pageheaderTitle: 'View Permissions',
      detailsTab: 'Details',
      permissionsTab: 'Permissions',
    },
  },
  es: {
    GroupsComponent: {
      pageheaderTitle: 'Ver Permisos',
      detailsTab: 'Detalles',
      permissionsTab: 'Permisos',
    },
  },
}
