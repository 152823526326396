import styled from 'styled-components'

export const Container = styled.div``

export const Specification = styled.div`
  display: flex;
  flex-direction: column;
  align-content: space-between;

  .card_specification {
    margin-bottom: 20px;
  }
`
