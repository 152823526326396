import { lighten } from 'polished'
import styled from 'styled-components'

export const Container = styled.div`
  #btn-view-clients,
  #btn-view-tasks {
    background: var(--link-color);
    border: 1px solid var(--link-color);

    &:hover {
      background: ${lighten(0.05, '#2AA494')};
      border: 1px solid ${lighten(0.1, '#2AA494')};
    }
  }
`

export const Evaluation = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 2.5fr 1fr;
`
