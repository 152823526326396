/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react'

import {
  Drawer,
  Form,
  Button,
  Col,
  Row,
  Input,
  Select,
  Slider,
  Tooltip,
} from 'antd'
import { CloseOutlined, FilterOutlined } from '@ant-design/icons'

import { Container } from './styles'

import { useSkills } from '~/hooks/Skills/useSkills'

import { useListStatus } from '~/hooks/Status/useListStatus'
import { translate } from '~/utils/locale'
import { useContributorsTask } from '~/hooks/Tasks/Create/useContributorTask'

interface SkillsOptions {
  label: string
  value: string
}

const ContributorsTaskFilter: React.FC = () => {
  const [visible, setVisible] = useState(false)
  const [skillsOptions, setSkillsOptions] = useState<SkillsOptions[]>([])

  const [form] = Form.useForm()

  const { skillsData } = useSkills()
  const { listStatus } = useListStatus()
  const { handleUpdateFilters, handleResetFilters, filterOptions } =
    useContributorsTask()

  const marks = {
    0: '0',
    1: '1',
    2: '2',
    3: '3',
    4: '4',
    5: '5',
  }

  useEffect(() => {
    if (skillsData) {
      setSkillsOptions(
        skillsData.map((skill) => ({
          label: skill.name,
          title: skill.name,
          value: skill.id,
        })),
      )
    }

    form.setFieldsValue({
      contact: filterOptions?.contact,
      address: filterOptions?.address,
      skills: filterOptions?.skills_id?.split(','),
      evaluationRange: [
        filterOptions.fromEvaluation,
        filterOptions?.toEvaluation,
      ],
      internalEvaluationRange: [
        filterOptions.fromInternalEvaluation,
        filterOptions?.toInternalEvaluation,
      ],
    })
  }, [skillsData, filterOptions, form])

  const showDrawer = () => {
    setVisible(true)
  }

  const onClose = () => {
    setVisible(false)
  }

  const onFinish = (data: any) => {
    handleUpdateFilters(data)
    setVisible(false)
  }

  const onReset = () => {
    handleResetFilters()
    setVisible(false)
    form.resetFields()
  }

  const isFilter = Object.keys(filterOptions).length !== 0

  return (
    <Container isFilter={isFilter}>
      <Button
        type="primary"
        onClick={showDrawer}
        loading={!skillsData}
        icon={
          !isFilter ? (
            <FilterOutlined />
          ) : (
            <Tooltip title={translate('ContributorFilter.cleanFilter')}>
              <CloseOutlined onClick={onReset} />
            </Tooltip>
          )
        }
        id="btn_filter_contribuitors"
      >
        {isFilter
          ? translate('ContributorFilter.filterAppliedText')
          : translate('ContributorFilter.filterText')}
      </Button>
      <Drawer
        title={translate('ContributorFilter.drawerTitle')}
        width={580}
        onClose={onClose}
        open={visible}
        style={{ paddingBottom: 80 }}
      >
        <Form layout="vertical" onFinish={onFinish} form={form}>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="contact"
                label={translate('ContributorFilter.contributorInfoLabel')}
                tooltip={
                  <p>
                    {translate('ContributorFilter.contributorInfoParagraphOne')}
                    &nbsp;
                    <b>
                      {translate(
                        'ContributorFilter.contributorInfoParagraphTwo',
                      )}
                    </b>
                    .
                  </p>
                }
              >
                <Input
                  placeholder={translate(
                    'ContributorFilter.contributorInfoPlaceholder',
                  )}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="evaluationRange"
                label={translate('ContributorFilter.evaluationLabel')}
                style={{ width: '100%' }}
              >
                <Slider marks={marks} range max={5} min={0} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="internalEvaluationRange"
                label={translate('ContributorFilter.internalEvaluationLabel')}
                style={{ width: '100%' }}
              >
                <Slider marks={marks} range max={5} min={0} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="skills"
                label={translate('ContributorFilter.skillsLabel')}
              >
                <Select
                  mode="multiple"
                  placeholder={translate('ContributorFilter.skillsPlaceholder')}
                  options={skillsOptions}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={translate('ContributorFilter.statusLabel')}
                name="status_id"
              >
                <Select
                  placeholder={translate('ContributorFilter.statusPlaceholder')}
                  mode="multiple"
                >
                  {listStatus?.map((status) => (
                    <Select.Option value={status.id} key={status.id}>
                      {status.title}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Form.Item
              name="address"
              label={translate('ContributorFilter.addressLabel')}
              style={{ width: '100%' }}
            >
              <Input
                placeholder={translate('ContributorFilter.addressPlaceholder')}
              />
            </Form.Item>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item style={{ width: '100%' }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: '100%' }}
                >
                  {translate('ContributorFilter.applyButton')}
                </Button>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item style={{ width: '100%' }}>
                <Button onClick={onReset} style={{ width: '100%' }}>
                  {translate('ContributorFilter.cleanButton')}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </Container>
  )
}

export default ContributorsTaskFilter
