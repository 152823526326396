import React from 'react'
import { Helmet } from 'react-helmet'
import KanbanBoard from '~/components/DataManipulation/Tasks/Boards/Kanban'
import { ListClientsProvider } from '~/hooks/Clients/useListClients'
import { ListContributorsProvider } from '~/hooks/Contributors/useListContributors'
import { TaskBreakProvider } from '~/hooks/Tasks/Kanban/useTaskBreak'
import { TaskCanceledProvider } from '~/hooks/Tasks/Kanban/useTaskCanceled'
import { TaskCheckInProvider } from '~/hooks/Tasks/Kanban/useTaskCheckIn'
import { TaskEnRouteProvider } from '~/hooks/Tasks/Kanban/useTaskEnRoute'
import { TaskFinishedProvider } from '~/hooks/Tasks/Kanban/useTaskFinished'
import { TaskReceivedProvider } from '~/hooks/Tasks/Kanban/useTaskReceived'
import { TaskViewedProvider } from '~/hooks/Tasks/Kanban/useTaskViewed'
import { TaskWorkingProvider } from '~/hooks/Tasks/Kanban/useTaskWorking'
import { TaskTypeProvider } from '~/hooks/Tasks/TaskType/useTaskType'
import { TaskListProvider } from '~/hooks/Tasks/useListTasks'
import { TeamsProvider } from '~/hooks/Teams/useListTeams'
import { translate } from '~/utils/locale'

const KanbanTasks: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>{translate('ListTasks.helmetTitle')}</title>
      </Helmet>
      <TaskReceivedProvider>
        <TaskEnRouteProvider>
          <TaskWorkingProvider>
            <TaskBreakProvider>
              <TaskCanceledProvider>
                <TaskFinishedProvider>
                  <ListContributorsProvider>
                    <ListClientsProvider>
                      <TeamsProvider>
                        <TaskTypeProvider>
                          <TaskListProvider>
                            <TaskViewedProvider>
                              <TaskCheckInProvider>
                                <KanbanBoard />
                              </TaskCheckInProvider>
                            </TaskViewedProvider>
                          </TaskListProvider>
                        </TaskTypeProvider>
                      </TeamsProvider>
                    </ListClientsProvider>
                  </ListContributorsProvider>
                </TaskFinishedProvider>
              </TaskCanceledProvider>
            </TaskBreakProvider>
          </TaskWorkingProvider>
        </TaskEnRouteProvider>
      </TaskReceivedProvider>
    </>
  )
}

export default KanbanTasks
