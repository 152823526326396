/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/require-default-props */
import React, { useState, useEffect } from 'react'

import { message, Upload } from 'antd'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import ImgCrop from 'antd-img-crop'

import { Container } from './styles'

import { useCompanies } from '~/hooks/Companies/useCompanies'
import { useAuth } from '~/hooks/Auth/AuthContext'
import { translate } from '~/utils/locale'

interface AvatarUploaderProps {
  contactId: string
  imageData?: any
}

const AvatarUploader: React.FC<AvatarUploaderProps> = ({
  contactId,
  imageData,
}) => {
  const { selectedCompany } = useCompanies()
  const { token } = useAuth()

  const [image, setImage] = useState()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (imageData) {
      setImage(imageData)
    }
  }, [imageData])

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>
        {translate('MiscAvatarUploader.uploadButton')}
      </div>
    </div>
  )

  function getBase64(img: any, callback: any) {
    const reader = new FileReader()
    reader.addEventListener('load', () => callback(reader.result))
    reader.readAsDataURL(img)
  }

  function beforeUpload(file: File) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isJpgOrPng) {
      message.error(translate('MiscAvatarUploader.uploadTypeError'))
    }
    const isLt2M = file.size / 1024 / 1024 < 2
    if (!isLt2M) {
      message.error(translate('MiscAvatarUploader.uploadSizeError'))
    }
    return isJpgOrPng && isLt2M
  }

  const handleChange = (info: any) => {
    console.log(info.file.status)
    if (info.file.status === 'uploading') {
      setLoading(true)
      return
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (imageUrl: any) => {
        setImage(imageUrl)
        setLoading(false)
      })
    }
    if (info.file.status === 'error') {
      setLoading(false)
      message.error(translate('MiscAvatarUploader.errorMessage'))
    }
  }

  const props = {
    accept: '.jpeg,.png',
    name: 'avatar',
    action: `${process.env.REACT_APP_API}company/${selectedCompany}/contact/${contactId}/avatar`,
    headers: {
      authorization: `Bearer ${token}`,
      contentType: 'multipart/form-data',
    },
  }

  return (
    <Container>
      <ImgCrop
        cropShape="round"
        modalTitle={translate('MiscAvatarUploader.modalTitle')}
      >
        <Upload
          {...props}
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={false}
          beforeUpload={beforeUpload}
          onChange={handleChange}
        >
          {image ? (
            <img src={image} alt="avatar" style={{ width: '100%' }} />
          ) : (
            uploadButton
          )}
        </Upload>
      </ImgCrop>
    </Container>
  )
}

export default AvatarUploader
