/* eslint-disable react/jsx-one-expression-per-line */
import {
  LoadingOutlined,
  PlusCircleOutlined,
  QuestionOutlined,
} from '@ant-design/icons'
import { Card, Button, Space, Tour, Tooltip } from 'antd'
import Text from 'antd/lib/typography/Text'
import React from 'react'
import { Link } from 'react-router-dom'
import { useListContributors } from '~/hooks/Contributors/useListContributors'
import { ListStatusProvider } from '~/hooks/Status/useListStatus'
import { translate } from '~/utils/locale'
import HelpTooltip from '~/components/DataManipulation/Misc/HelpTooltip'
import ContributorsFilter from './ContributorsFilter'
import ContributorsTable from './ContributorsTable'
import api from '~/services/api'
import { useCompanies } from '~/hooks/Companies/useCompanies'
import MoreOptions from '../Misc/MoreOptions'

import { useCollaboratorTour } from '~/hooks/Tour/useCollaboratorTour'
import { useTaskTypeTour } from '~/hooks/Tour/useTaskTypeTour'
import { useTour } from '~/hooks/Tour/useTour'

// import { Container } from './styles';

const ContributorBoard: React.FC = () => {
  const { selectedCompany } = useCompanies()
  const { data, isLoading, isFetching, contributorsTotal } =
    useListContributors()

  const { isTour } = useTour()

  const {
    createCollaboratorRef,
    collaboratorSteps,
    openListCollaborator,
    setOpenListCollaborator,
    listCollaboratorSteps,
  } = useCollaboratorTour()
  const { openCollaborator, setOpenCollaborator } = useTaskTypeTour()

  const generateContributorReport = async () => {
    const response = await api.get(
      `/company/${selectedCompany}/contributors/export`,
    )

    window.open(response.data.exportUrl, '_blank')
  }

  return (
    <Card
      title={
        <Space size="large">
          <b>{translate('Contributor.cardTitle')}</b>
          <HelpTooltip title={translate('Contributor.helpTooltip')} />
          {isLoading || isFetching ? (
            <LoadingOutlined spin />
          ) : (
            <Text
              type="secondary"
              style={{ fontWeight: 'normal', fontSize: 14 }}
            >
              {contributorsTotal !== undefined ? (
                <>
                  {contributorsTotal === 1
                    ? `${translate(
                        'Contributor.totalText',
                      )} ${contributorsTotal} ${translate(
                        'Contributor.contributorText',
                      )}`
                    : `${translate(
                        'Contributor.totalText',
                      )} ${contributorsTotal} ${translate(
                        'Contributor.contributorsText',
                      )}`}
                </>
              ) : (
                ''
              )}
            </Text>
          )}
          {!isLoading && isFetching && <LoadingOutlined spin />}
        </Space>
      }
      extra={
        <Space size="middle">
          <Tooltip title="Tour Colaboradores">
            <Button
              icon={<QuestionOutlined />}
              shape="circle"
              size="small"
              onClick={() => setOpenCollaborator(true)}
            />
          </Tooltip>
          <MoreOptions
            exportTooltip={translate('Contributor.exportTooltip')}
            exportFunction={generateContributorReport}
            importTooltip={translate('Contributor.importTooltip')}
            modalTitle={translate('Contributor.modalTitle')}
            importTitle={translate('Contributor.importTitle')}
            importDescription={translate('Contributor.importDescription')}
            modelURL="/ModelDownload/contributors.xlsx"
            uploadRoute="contributors/import"
            uploadFileName="contributors"
          />

          <ListStatusProvider>
            <ContributorsFilter />
          </ListStatusProvider>

          <Link to="/colaboradores/novo">
            <Button
              type="primary"
              icon={<PlusCircleOutlined />}
              ref={createCollaboratorRef}
            >
              {translate('Contributor.newContributorButton')}
            </Button>
          </Link>
        </Space>
      }
      bordered={false}
      style={{ width: '100%', padding: 30 }}
    >
      <ContributorsTable data={data} loading={isLoading} />

      {openListCollaborator ? (
        <>
          {isTour ? (
            <Tour
              open={openListCollaborator}
              onClose={() => setOpenListCollaborator(false)}
              steps={listCollaboratorSteps}
              indicatorsRender={(current, total) => (
                <span>
                  {current + 1} / {total}
                </span>
              )}
            />
          ) : (
            ''
          )}
        </>
      ) : (
        <Tour
          open={openCollaborator}
          onClose={() => setOpenCollaborator(false)}
          steps={collaboratorSteps}
          indicatorsRender={(current, total) => (
            <span>
              {current + 1} / {total}
            </span>
          )}
        />
      )}
    </Card>
  )
}

export default ContributorBoard
