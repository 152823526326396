export default {
  pt: {
    ScalesTeamsForm: {
      cardTitle: 'Equipes',
    },
  },
  en: {
    ScalesTeamsForm: {
      cardTitle: 'Teams',
    },
  },
  es: {
    ScalesTeamsForm: {
      cardTitle: 'Equipos',
    },
  },
}
