import { Card, Col, Row } from 'antd'
import Text from 'antd/lib/typography/Text'

import React from 'react'
import {
  FiClock,
  FiCreditCard,
  FiDollarSign,
  FiFile,
  FiFileText,
  FiMail,
  FiPhone,
  FiTarget,
  FiTruck,
  FiUser,
} from 'react-icons/fi'

import { translate } from '~/utils/locale'

import { useListParentClients } from '~/hooks/Clients/useListParentCustomer'
import { Container } from './styles'

import { useClients } from '~/hooks/Clients/useClients'
import NumberFormat from 'react-number-format'
import CurrencyFormatter from '~/components/DataManipulation/Misc/CurrencyFormater'

const DetailsParentCustomerInClient: React.FC = () => {
  const { clients } = useClients()
  const { parentClients } = useListParentClients()

  const filterParentClient = parentClients?.filter(
    (p) => p._id === clients?.parent_cnpj,
  )
  const data = filterParentClient?.[0]

  return (
    <Container>
      {clients?.parent_cnpj !== null ? (
        <>
          <Row gutter={12}>
            <Col span={12}>
              <div className="text">
                <div>
                  <FiTarget />
                  <Text strong>Razão Social / Nome</Text>
                </div>
                <Text>{data?.companyName}</Text>
              </div>
            </Col>
            <Col span={12}>
              <div className="text">
                <div>
                  <FiTarget />
                  <Text strong>Nome Fantasia</Text>
                </div>
                <Text>{data?.fantasyName}</Text>
              </div>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={12}>
              <div className="text">
                <div>
                  <FiCreditCard />
                  <Text strong>CNPJ</Text>
                </div>
                {data?.cnpj ? (
                  <Text>
                    <NumberFormat
                      value={data?.cnpj}
                      displayType="text"
                      format="##.###.###/####-##"
                    />
                  </Text>
                ) : (
                  <Text>Nenhum cnpj foi fornecido.</Text>
                )}
              </div>
            </Col>
            <Col span={12}>
              <div className="text">
                <div>
                  <FiCreditCard />
                  <Text strong>CPF</Text>
                </div>
                {data?.cpf ? (
                  <Text>
                    <NumberFormat
                      value={data?.cpf}
                      displayType="text"
                      format="###.###.###-##"
                    />
                  </Text>
                ) : (
                  <Text>Nenhum cpf foi fornecido.</Text>
                )}
              </div>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={12}>
              <div className="text">
                <div>
                  <FiFileText />
                  <Text strong>Inscrição Municipal</Text>
                </div>
                {data?.municipal_registration ? (
                  <Text>{data?.municipal_registration}</Text>
                ) : (
                  <Text>Nenhuma inscrição municipal foi fornecida.</Text>
                )}
              </div>
            </Col>
            <Col span={12}>
              <div className="text">
                <div>
                  <FiFileText />
                  <Text strong>Inscrição Estadual</Text>
                </div>
                {data?.state_registration ? (
                  <Text>{data?.state_registration}</Text>
                ) : (
                  <Text>Nenhuma inscrição estadual foi fornecida.</Text>
                )}
              </div>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={12}>
              <div className="text">
                <div>
                  <FiUser />
                  <Text strong>Nome</Text>
                </div>
                {data?.contact?.name ? (
                  <Text>{data?.contact?.name}</Text>
                ) : (
                  <Text>{translate('TasksTabsDetails.noValueText')}</Text>
                )}
              </div>
            </Col>
            <Col span={8}>
              <div className="text">
                <div>
                  <FiMail />
                  <Text strong>Email</Text>
                </div>
                {data?.contact?.email ? (
                  <Text>{data?.contact?.email}</Text>
                ) : (
                  <Text>{translate('TasksTabsDetails.noValueText')}</Text>
                )}
              </div>
            </Col>
            <Col span={8}>
              <div className="text">
                <div>
                  <FiPhone />
                  <Text strong>Telefone</Text>
                </div>
                {data?.contact?.phone ? (
                  <Text>
                    <NumberFormat
                      value={data?.contact.phone}
                      displayType="text"
                      format="(##) #####-####"
                    />
                  </Text>
                ) : (
                  <Text>Nenhum telefone foi fornecido</Text>
                )}
              </div>
            </Col>
          </Row>

          <Card
            bordered={false}
            title={translate('TasksTabsDetails.addressCardTitle')}
            style={{
              display: 'inline-block',
              width: '100%',
              marginTop: 15,
            }}
          >
            <Row gutter={12}>
              <Col span={3}>
                <div className="text">
                  <div>
                    <FiMail />
                    <Text strong>
                      {translate('TasksTabsDetails.zipcodeText')}
                    </Text>
                  </div>
                  <Text>{data?.address?.zip_code}</Text>
                </div>
              </Col>
              <Col span={14}>
                <div className="text">
                  <div>
                    <FiFile />
                    <Text strong>
                      {translate('TasksTabsDetails.addressText')}
                    </Text>
                  </div>
                  <Text>
                    {data?.address.street}, {data?.address?.number} -{' '}
                    {data?.address?.neighborhood}
                  </Text>
                </div>
              </Col>
              <Col>
                <div className="text">
                  <div>
                    <FiFile />
                    <Text strong>{translate('TasksTabsDetails.cityText')}</Text>
                  </div>
                  <Text>
                    {data?.address?.city} - {data?.address?.state}
                  </Text>
                </div>
              </Col>
            </Row>
          </Card>

          <Card
            bordered={false}
            title="Financeiro"
            style={{
              display: 'inline-block',
              width: '100%',
            }}
          >
            <>
              <Row gutter={12}>
                <Col span={8}>
                  <div className="text">
                    <div>
                      <FiDollarSign />
                      <Text strong>Valor da Tarefa</Text>
                    </div>
                    {data?.financial?.task_value ? (
                      <Text>
                        <CurrencyFormatter
                          value={data?.financial?.task_value}
                        />
                      </Text>
                    ) : (
                      <Text>Nenhum valor foi fornecido.</Text>
                    )}
                  </div>
                </Col>
                <Col span={8}>
                  <div className="text">
                    <div>
                      <FiClock />
                      <Text strong>Quantidade de Horas</Text>
                    </div>
                    {data?.financial?.amount_hours ? (
                      <Text>{data?.financial?.amount_hours}</Text>
                    ) : (
                      <Text>Nenhuma quantidade de horas foi fornecida.</Text>
                    )}
                  </div>
                </Col>
                <Col span={8}>
                  <div className="text">
                    <div>
                      <FiClock />
                      <Text strong>Valor Hora Adicional</Text>
                    </div>
                    {data?.financial?.amount_hours_additional ? (
                      <Text>
                        <CurrencyFormatter
                          value={data?.financial?.amount_hours_additional}
                        />
                      </Text>
                    ) : (
                      <Text>Nenhum valor foi fornecido.</Text>
                    )}
                  </div>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col span={8}>
                  <div className="text">
                    <div>
                      <FiTruck />
                      <Text strong>Quantidade de KM</Text>
                    </div>
                    {data?.financial?.number_km ? (
                      <Text>{data?.financial?.number_km}</Text>
                    ) : (
                      <Text>Nenhuma quantidade de km foi fornecida.</Text>
                    )}
                  </div>
                </Col>
                <Col span={8}>
                  <div className="text">
                    <div>
                      <FiTruck />
                      <Text strong>Valor KM Adicional</Text>
                    </div>
                    {data?.financial?.number_km_additional ? (
                      <Text>
                        <CurrencyFormatter
                          value={data?.financial?.number_km_additional}
                        />
                      </Text>
                    ) : (
                      <Text>Nenhum valor foi fornecido.</Text>
                    )}
                  </div>
                </Col>
              </Row>
            </>
          </Card>
        </>
      ) : (
        'Nenhum cliente associado.'
      )}
    </Container>
  )
}

export default DetailsParentCustomerInClient
