export default {
  pt: {
    TeamsUpdate: {
      editTooltip: 'Editar Equipe',
      editButton: 'Editar',
      modalTitle: 'Editar Equipe',
      closeButton: 'Fechar',
      saveButton: 'Salvar',
      titleLabel: 'Nome da Equipe',
      titleRule: 'Preencha o nome da equipe.',
      titlePlaceholder: 'Informe o nome da equipe',
      responsibleLabel: 'Responsável',
      responsibleRule: 'Preencha o nome do responsável',
      responsiblePlaceholder: 'Informe o responsável',
      descriptionLabel: 'Descrição',
      descriptionPlaceholder: 'Informe a descrição',
    },
  },
  en: {
    TeamsUpdate: {
      editTooltip: 'Edit Team',
      editButton: 'Edit',
      modalTitle: 'Edit Team',
      closeButton: 'Close',
      saveButton: 'Save',
      titleLabel: 'Team Name',
      titleRule: 'Fill in the team name.',
      titlePlaceholder: 'Enter the team name',
      responsibleLabel: 'Responsible',
      responsibleRule: 'Fill in the name of the person in charge',
      responsiblePlaceholder: 'Inform the person in charge',
      descriptionLabel: 'Description',
      descriptionPlaceholder: 'Enter the description',
    },
  },
  es: {
    TeamsUpdate: {
      editTooltip: 'Editar Equipo',
      editButton: 'Editar',
      modalTitle: 'Editar Equipo',
      closeButton: 'Cerrar',
      saveButton: 'Guardar',
      titleLabel: 'Nombre del Equipo',
      titleRule: 'Completa el nombre del equipo.',
      titlePlaceholder: 'Introduce el nombre del equipo',
      responsibleLabel: 'Responsable',
      responsibleRule: 'Completar el nombre de la persona a cargo',
      responsiblePlaceholder: 'Informar al responsable',
      descriptionLabel: 'Descripción',
      descriptionPlaceholder: 'Introduce la descripción',
    },
  },
}
