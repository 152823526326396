export default {
  pt: {
    ScalesTabs: {
      pageHeaderTitle: 'Editar escala',
      generalTab: 'Geral',
      clientsTab: 'Clientes',
      contributorsTab: 'Colaboradores',
      teamsTab: 'Equipes',
      schedulesTab: 'Horários',
    },
  },
  en: {
    ScalesTabs: {
      pageHeaderTitle: 'Edit scale',
      generalTab: 'General',
      clientsTab: 'Customers',
      contributorsTab: 'Contributors',
      teamsTab: 'Teams',
      schedulesTab: 'Schedules',
    },
  },
  es: {
    ScalesTabs: {
      pageHeaderTitle: 'Editar escala',
      generalTab: 'General',
      clientsTab: 'Clientes',
      contributorsTab: 'Colaboradores',
      teamsTab: 'Equipos',
      schedulesTab: 'Horarios',
    },
  },
}
