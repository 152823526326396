/* eslint-disable @typescript-eslint/no-explicit-any */
import { LoadingOutlined } from '@ant-design/icons'
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Radio,
  Row,
  Space,
  Tag,
  message,
} from 'antd'
import { addDays } from 'date-fns'
import React, { useState } from 'react'

import { useCompanies } from '~/hooks/Companies/useCompanies'

import { usePayment } from '~/hooks/Payment/usePayment'

// import { Container } from './styles';

interface PaymentProps {
  billingType: string
}

const CreatePayment: React.FC = () => {
  const {
    createBankPayment,
    payment,
    isLoading: isLoadingPayment,
  } = usePayment()
  const { selectedCompany } = useCompanies()

  const [loading, setLoading] = useState(false)

  const [cupom, setCupom] = useState('')
  const [value, setValue] = useState(10)
  const [valueText, setValueText] = useState('R$ 250,00')

  const endDate = addDays(new Date(), 1)

  if (isLoadingPayment || !payment) {
    return <LoadingOutlined style={{ fontSize: 64, margin: 'auto' }} spin />
  }

  const paymentId = payment.filter((p) => {
    return p.description === selectedCompany
  })

  const paymentData = paymentId[0]

  function validateCupom() {
    if (cupom === 'GSTOR') {
      message.success('Cupom adicionado com sucesso')

      setValue(10)
      setValueText('R$ 237,00')

      localStorage.setItem('@Gstor:cupom', 'R$ 237,00')
    } else {
      message.error('Este cupom não é válido')
    }
  }

  function createPayment(valueForm: PaymentProps) {
    try {
      setLoading(true)
      const dataForm = {
        name: 'Assinatura Gstor',
        description: selectedCompany,
        value,
        endDate,
        chargeType: 'RECURRENT',
        billingType: valueForm.billingType,
        dueDateLimitDays: 5,
        subscriptionCycle: 'MONTHLY',
        callback: {
          successUrl: 'https://app.gstor.com.br',
        },
      }

      createBankPayment(dataForm)

      setTimeout(() => {
        setLoading(true)
        if (paymentData) {
          window.location.replace(paymentData.url)
        }
      }, 2000)
    } catch (err: any) {}
  }

  const cupomStorage = localStorage.getItem('@Gstor:cupom')

  return (
    <Form layout="vertical" onFinish={createPayment}>
      <Row>
        <Form.Item
          label="Escolha a forma de pagamento"
          name="billingType"
          required
        >
          <Radio.Group defaultValue="" buttonStyle="solid">
            <Radio.Button value="CREDIT_CARD">Cartão de Crédito</Radio.Button>
            <Radio.Button value="BOLETO">Boleto</Radio.Button>
            <Radio.Button value="PIX">Pix</Radio.Button>
          </Radio.Group>
        </Form.Item>
      </Row>

      <Card
        style={{ boxShadow: 'none', width: '70%' }}
        title="Resumo da compra"
      >
        <Row gutter={10}>
          <Col span={8}>
            <h4>Valor Total</h4>
            <h1 style={{ margin: 0 }}>
              {!cupomStorage ? valueText : cupomStorage}
            </h1>
          </Col>

          <Col span={12}>
            <h4>Cupom de Desconto</h4>

            {!cupomStorage ? (
              <Space.Compact style={{ width: '100%' }}>
                <Input
                  placeholder="Se houver, adicione um cupom de desconto"
                  onChange={(e) => setCupom(e.target.value)}
                  value={cupom}
                />
                <Button type="primary" onClick={validateCupom}>
                  Validar
                </Button>
              </Space.Compact>
            ) : (
              <Tag color="green">Cupom de desconto já adicionado!</Tag>
            )}
          </Col>
        </Row>
      </Card>

      <Form.Item>
        <Button
          type="primary"
          style={{ marginTop: '1rem', width: '70%' }}
          htmlType="submit"
          loading={loading}
        >
          Confirmar compra
        </Button>
      </Form.Item>
    </Form>
  )
}

export default CreatePayment
