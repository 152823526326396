export default {
  pt: {
    FinancialReceive: {
      cardTitle: 'Financeiro - Valor a receber dos clientes principais',
      totalText: 'total geral',
      exportButton: 'Exportar',

      clientColumn: 'Cliente Principal',
      totalTasksColumn: 'Total de Tarefas',
      totalValueTasksColumn: 'Total Valor Tarefas',
      totalHourColumn: 'Total de Horas Extras',
      totalValueHourColumn: 'Total do Valor de Horas Extras',
      totalKmColumn: 'Total de Kms Extras',
      totalValueKmColumn: 'Total do Valor de Kms Extras',
      totalColumn: 'Total',

      subCodeTaskColumn: 'Código da Tarefa',
      subTasksColumn: 'Qntd de Tarefas',
      subValueTasksColumn: 'Qntd Valor Tarefas',
      subHoursColumn: 'Qntd de Horas Extras',
      subValueHoursColumn: 'Qntd Valor de Horas Extras',
      subKmColumn: 'Qntd de Kms Extras',
      subValueKmColumn: 'Qntd Valor de Kms Extras',
      subTotalColumn: 'Total',
    },
  },
  en: {
    FinancialReceive: {
      cardTitle: 'Financial - Amount receivable from main customers',
      totalText: 'grand total',
      exportButton: 'Export',

      clientColumn: 'Main Client',
      totalTasksColumn: 'Total Tasks',
      totalValueTasksColumn: 'Total Value Tasks',
      totalHourColumn: 'Total Overtime',
      totalValueHourColumn: 'Total Overtime Value',
      totalKmColumn: 'Total Extra Kms',
      totalValueKmColumn: 'Total Value of Extra Kms',
      totalColumn: 'Total',

      subCodeTaskColumn: 'Task Code',
      subTasksColumn: 'Qty of Tasks',
      subValueTasksColumn: 'Qty Value Tasks',
      subHoursColumn: 'Qty of Overtime',
      subValueHoursColumn: 'Qty of Overtime Value',
      subKmColumn: 'Qty Number of Extra Kms',
      subValueKmColumn: 'Qty of Extra Kms',
      subTotalColumn: 'Total',
    },
  },
  es: {
    FinancialReceive: {
      cardTitle: 'Financiero - Monto por cobrar a principales clientes',
      totalText: 'gran total',
      exportButton: 'Exportar',

      clientColumn: 'Cliente Principal',
      totalTasksColumn: 'Tareas totales',
      totalValueTasksColumn: 'Tareas de valor total',
      totalHourColumn: 'Total de horas extras',
      totalValueHourColumn: 'Valor total de horas extras',
      totalKmColumn: 'Total de kilómetros adicionales',
      totalValueKmColumn: 'Valor Total de Kms Extras',
      totalColumn: 'Total',

      subCodeTaskColumn: 'Código de tarea',
      subTasksColumn: 'Cant de tareas',
      subValueTasksColumn: 'Cant Tareas de valor',
      subHoursColumn: 'Cant de horas extras',
      subValueHoursColumn: 'Cant de valor de horas extras',
      subKmColumn: 'Cant de kilómetros adicionales',
      subValueKmColumn: 'Cant de kilómetros adicionales',
      subTotalColumn: 'Total',
    },
  },
}
