export default {
  pt: {
    Pause: {
      helmetTitle: 'Motivos de Pausa',
    },
  },
  en: {
    Pause: {
      helmetTitle: 'Break Reasons',
    },
  },
  es: {
    Pause: {
      helmetTitle: 'Romper Razones',
    },
  },
}
