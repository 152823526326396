/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, createContext, useState } from 'react'

interface TaskCreateAddressContextData {
  latitude?: any
  setLatitude(value: any): void
  longitude?: any
  setLongitude(value: any): void

  zipcode?: string
  setZipcode(value?: string): void
  state?: string
  setState(value?: string): void
  city?: string
  setCity(value?: string): void
  neighborhood?: string
  setNeighborhood(value?: string): void
  street?: string
  setStreet(value?: string): void
  number?: string
  setNumber(value?: string): void
  complement?: string
  setComplement(value?: string): void

  type?: string
  setType(value: string): void
}

const TaskCreateAddressContext = createContext<TaskCreateAddressContextData>(
  {} as TaskCreateAddressContextData,
)

export const TaskCreateAddressFieldsProvider: React.FC = ({ children }) => {
  const [zipcode, setZipcode] = useState<string>()
  const [state, setState] = useState<string>()
  const [city, setCity] = useState<string>()
  const [neighborhood, setNeighborhood] = useState<string>()
  const [street, setStreet] = useState<string>()
  const [number, setNumber] = useState<string>()
  const [complement, setComplement] = useState<string>()

  const [latitude, setLatitude] = useState<any>(-14.235004)
  const [longitude, setLongitude] = useState<any>(-51.925282)

  const [type, setType] = useState('manual')

  return (
    <TaskCreateAddressContext.Provider
      value={{
        latitude,
        setLatitude,
        longitude,
        setLongitude,
        zipcode,
        setZipcode,
        state,
        setState,
        city,
        setCity,
        neighborhood,
        setNeighborhood,
        street,
        setStreet,
        number,
        setNumber,
        complement,
        setComplement,
        type,
        setType,
      }}
    >
      {children}
    </TaskCreateAddressContext.Provider>
  )
}

export function useTaskAddressFieldsCreate(): TaskCreateAddressContextData {
  const context = useContext(TaskCreateAddressContext)

  if (!context) {
    throw new Error('useAuth must be used within an AutoProvider')
  }

  return context
}
