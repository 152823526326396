import styled from 'styled-components'

export const Container = styled.div`
  background: #fff;

  width: 100%;
  min-height: 100px;
  height: 100%;
  padding: 0.6rem 1rem;

  border-radius: 0.5rem;
  border: 1px solid #d5d5d5;

  .subtitle {
    color: var(--secondary-initial-text);
  }

  h3 {
    color: #2f2e2e;
  }

  .tags {
    margin-top: 10px;
  }

  .text {
    color: var(--primary-initial-text);
  }
`
