export default {
  pt: {
    ClientsDetailsTab: {
      cardGeneralInfoTitle: 'Informações Gerais',
      seeTasksButton: 'Ver Tarefas',
      editButton: 'Editar',
      nameLabel: 'Nome',
      emailLabel: 'E-mail',
      personTypeLabel: 'Tipo de Pessoa',
      physicalPersonType: 'Pessoa Física',
      juridicPersonType: 'Pessoa Jurídica',
      foreignType: 'Estrangeiro',
      passportLabel: 'Passaporte',
      cpfLabel: 'CPF',
      idLabel: 'RG',
      cnpjLabel: 'CNPJ',
      whatsappPhoneLabel: 'Telefone (Whatsapp)',
      phoneLabel: 'Telefone',
      phoneNullLabel: 'Nenhum telefone fornecido',
      additionalPhonesLabel: 'Telefones Adicionais',
      cardOtherInfoTitle: 'Informações Gerais',
      createdLabel: 'Criado por',
      createdNull: 'Nenhuma informação fornecida',
      corporateNameLabel: 'Razão Social',
      corporateNameNull: 'Nenhuma informação fornecida',
      segmentLabel: 'Segmento',
      segmentNull: 'Nenhum segmento fornecido',
      externalCodeLabel: 'Código Externo',
      externalCodeNull: 'Nenhum código informado',
      talkToContactLabel: 'Falar com contatos responsáveis',
      responsibleLabel: 'Colaborador Responsável',
      responsibleNull: 'Nenhum colaborador fornecido',
      observationLabel: 'Observação',
      observationNull: 'Nenhuma observação fornecida',
      parentCustomerCodeLabel: 'Cliente Pai',
      parentCustomerNull: 'Nenhum cliente pai fornecido',
      parentCustomerCNPJLabel: 'CNPJ do Cliente Pai',
      parentCustomerCNPJNull: 'Nenhum CNPJ do cliente pai fornecido',
    },
  },
  en: {
    ClientsDetailsTab: {
      cardGeneralInfoTitle: 'General information',
      seeTasksButton: 'See Tasks',
      editButton: 'Edit',
      nameLabel: 'Name',
      emailLabel: 'Email',
      personTypeLabel: 'Person Type',
      physicalPersonType: 'Physical Person',
      juridicPersonType: 'Juridic Person',
      foreignType: 'Foreign',
      passportLabel: 'Passport',
      cpfLabel: 'CPF',
      idLabel: 'ID',
      cnpjLabel: 'CNPJ',
      whatsappPhoneLabel: 'Phone (Whatsapp)',
      phoneLabel: 'Phone',
      phoneNullLabel: 'No phone provided',
      additionalPhonesLabel: 'Additional Phones',
      cardOtherInfoTitle: 'General information',
      createdLabel: 'Created by',
      createdNull: 'No information provided',
      corporateNameLabel: 'Social Reason',
      corporateNameNull: 'No information provided',
      segmentLabel: 'Segment',
      segmentNull: 'No segment provided',
      externalCodeLabel: 'External Code',
      externalCodeNull: 'No code informed',
      talkToContactLabel: 'Speak to responsible contacts',
      responsibleLabel: 'Responsible Employee',
      responsibleNull: 'No collaborator provided',
      observationLabel: 'Observation',
      observationNull: 'No remarks provided',
      parentCustomerCodeLabel: 'Parent Client',
      parentCustomerNull: 'No provided parent client',
      parentCustomerCNPJLabel: 'Parent Client CNPJ',
      parentCustomerCNPJNull: 'No parent customer CNPJ provided',
    },
  },
  es: {
    ClientsDetailsTab: {
      cardGeneralInfoTitle: 'Informaciones generales',
      seeTasksButton: 'Ver Tareas',
      editButton: 'Editar',
      nameLabel: 'Nombre',
      emailLabel: 'Correo electrónico',
      personTypeLabel: 'Tipo de persona',
      physicalPersonType: 'Persona física',
      juridicPersonType: 'Persona jurídica',
      foreignType: 'Extranjero',
      passportLabel: 'Pasaporte',
      cpfLabel: 'CPF',
      idLabel: 'ID',
      cnpjLabel: 'CNPJ',
      whatsappPhoneLabel: 'Teléfono (Whatsapp)',
      phoneLabel: 'Teléfono',
      phoneNullLabel: 'No se proporcionó teléfono',
      additionalPhonesLabel: 'Teléfonos adicionales',
      cardOtherInfoTitle: 'Informaciones generales',
      createdLabel: 'Criado por',
      createdNull: 'No se proporcionó información',
      corporateNameLabel: 'Razón social',
      corporateNameNull: 'No se proporcionó información',
      segmentLabel: 'Segmento',
      segmentNull: 'No se proporcionó ningún segmento',
      externalCodeLabel: 'Código Externo',
      externalCodeNull: 'Sin código informado',
      talkToContactLabel: 'Hable con contactos responsables',
      responsibleLabel: 'Empleado responsable',
      responsibleNull: 'No se ha proporcionado ningún colaborador',
      observationLabel: 'Observación',
      observationNull: 'No se proporcionaron comentarios',
      parentCustomerCodeLabel: 'Cliente Principal',
      parentCustomerNull: 'No provided parent client',
      parentCustomerCNPJLabel: 'Cliente Principal CNPJ',
      parentCustomerCNPJNull: 'No se proporcionó CNPJ de cliente principal',
    },
  },
}
