/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, createContext, useState } from 'react'

interface TaskTypeProps {
  id: string
  amount_to_pay: number
}

interface ParentCustomerFieldsContextData {
  companyName?: string
  setCompanyName(value: string): void
  fantasyName?: string
  setFantasyName(value: string): void
  cnpjDocument?: string
  setCnpjDocument(value: string): void
  cpfDocument?: string
  setCpfDocument(value: string): void
  municipalRegistration?: string
  setMunicipalRegistration(value: string): void
  stateRegistration?: string
  setStateRegistration(value: string): void
  name?: string
  setName(value: string): void
  email?: string
  setEmail(value: string): void
  phone?: string
  setPhone(value: string): void
  taskValue?: number
  setTaskValue(value?: number | undefined): void
  amountHours?: number
  setAmountHours(value: number): void
  amountHoursAdditional?: number
  setAmountHoursAdditional(value?: number): void
  numberKm?: number
  setNumberKm(value: number): void
  numberKmAdditional?: number
  setNumberKmAdditional(value?: number): void
  taskTypeData?: TaskTypeProps
  setTaskTypeData(value: TaskTypeProps): void
  visualization?: string
  setVisualization(value: string): void
}

const ParentCustomerFieldsContext =
  createContext<ParentCustomerFieldsContextData>(
    {} as ParentCustomerFieldsContextData,
  )

export const ParentCustomerFieldsProvider: React.FC = ({ children }) => {
  const [companyName, setCompanyName] = useState('')
  const [fantasyName, setFantasyName] = useState('')
  const [cnpjDocument, setCnpjDocument] = useState('')
  const [cpfDocument, setCpfDocument] = useState('')

  const [municipalRegistration, setMunicipalRegistration] = useState('')
  const [stateRegistration, setStateRegistration] = useState('')

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')

  const [taskValue, setTaskValue] = useState<number | undefined>(0)
  const [amountHours, setAmountHours] = useState(0)
  const [amountHoursAdditional, setAmountHoursAdditional] = useState<
    number | undefined
  >(0)
  const [numberKm, setNumberKm] = useState(0)
  const [numberKmAdditional, setNumberKmAdditional] = useState<
    number | undefined
  >(0)

  const [taskTypeData, setTaskTypeData] = useState<TaskTypeProps>(
    {} as TaskTypeProps,
  )

  const [visualization, setVisualization] = useState('customers')

  return (
    <ParentCustomerFieldsContext.Provider
      value={{
        companyName,
        setCompanyName,
        fantasyName,
        setFantasyName,
        cnpjDocument,
        setCnpjDocument,
        cpfDocument,
        setCpfDocument,
        municipalRegistration,
        setMunicipalRegistration,
        stateRegistration,
        setStateRegistration,
        name,
        setName,
        email,
        setEmail,
        phone,
        setPhone,
        taskValue,
        setTaskValue,
        amountHours,
        setAmountHours,
        amountHoursAdditional,
        setAmountHoursAdditional,
        numberKm,
        setNumberKm,
        numberKmAdditional,
        setNumberKmAdditional,
        taskTypeData,
        setTaskTypeData,
        visualization,
        setVisualization,
      }}
    >
      {children}
    </ParentCustomerFieldsContext.Provider>
  )
}

export function useParentCustomerFields(): ParentCustomerFieldsContextData {
  const context = useContext(ParentCustomerFieldsContext)

  if (!context) {
    throw new Error('useAuth must be used within an AutoProvider')
  }

  return context
}
