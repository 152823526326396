/* eslint-disable no-nested-ternary */
/* eslint-disable consistent-return */
/* eslint-disable react/jsx-curly-newline */
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import { LoadingOutlined } from '@ant-design/icons'
import { Button, Result } from 'antd'

import { Container, LoadingIndicator } from './styles'

import ScaleTabs from '~/components/DataManipulation/Scales/ScaleTabs'

import { useScale } from '~/hooks/Scales/useScale'
import { translate } from '~/utils/locale'

const EditScale: React.FC = () => {
  const { scaleId } = useParams<{ scaleId: string }>()

  const { setSelectedScale, isLoading, scaleData } = useScale()

  useEffect(() => {
    setSelectedScale(scaleId)
  }, [scaleId, setSelectedScale])

  return (
    <Container>
      <Helmet>
        <title>{translate('EditScale.helmetTitle')}</title>
      </Helmet>

      {isLoading ? (
        <LoadingIndicator>
          <LoadingOutlined style={{ fontSize: 64, margin: 'auto' }} spin />
        </LoadingIndicator>
      ) : scaleData ? (
        <ScaleTabs />
      ) : (
        <Result
          status="error"
          title={translate('EditScale.resultTitle')}
          subTitle={translate('EditScale.resultSubTitle')}
          extra={[
            <Button
              type="primary"
              onClick={() => window.location.reload()}
              key="console"
            >
              {translate('EditScale.buttonText')}
            </Button>,
          ]}
        />
      )}
    </Container>
  )
}

export default EditScale
