/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react'
import { Row, Col } from 'antd'
import TasksTotalLineChart from '~/components/DataManipulation/Dashboard/Tasks/Charts/TasksTotalLineChart'
import TasksByTypePieChart from '~/components/DataManipulation/Dashboard/Tasks/Charts/TasksByTypePieChart'
import TasksValueLineChart from '~/components/DataManipulation/Dashboard/Tasks/Charts/TasksValueLineChart'
import TasksByValuePieChart from '~/components/DataManipulation/Dashboard/Tasks/Charts/TasksByValuePieChart'
import TasksByContributorAndValuePieChart from '~/components/DataManipulation/Dashboard/Tasks/Charts/TasksByContributorAndValuePieChart'
import TasksByCustomerAndValuePieChart from '~/components/DataManipulation/Dashboard/Tasks/Charts/TasksByCustomerAndValuePieChart'

const Dashboard: React.FC = () => {
  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <TasksTotalLineChart type="dashTask" />
        </Col>
        <Col span={12}>
          <TasksByTypePieChart />
        </Col>
        <Col span={12}>
          <TasksByValuePieChart type="dashTask" />
        </Col>
        <Col span={12}>
          <TasksValueLineChart />
        </Col>
        <Col span={12}>
          <TasksByContributorAndValuePieChart type="dashTask" />
        </Col>
        <Col span={12}>
          <TasksByCustomerAndValuePieChart />
        </Col>
      </Row>
    </>
  )
}

export default Dashboard
