/* eslint-disable prefer-destructuring */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/jsx-one-expression-per-line */
import { LoadingOutlined } from '@ant-design/icons'
import { Card } from 'antd'

import React from 'react'

import { useGroup } from '~/hooks/Groups/useGroup'

import { LoadingIndicator } from '~/pages/Scale/EditScale/styles'
import { translate } from '~/utils/locale'
import CreatePermission from '../../Create/Permissions'
import EditPermission from '../../Update/Permissions'

import { Container } from './styles'

const DetailsPermissions: React.FC = () => {
  const { groups, isLoading } = useGroup()

  if (isLoading || !groups) {
    return (
      <LoadingIndicator>
        <LoadingOutlined style={{ fontSize: 64, margin: 'auto' }} spin />
      </LoadingIndicator>
    )
  }

  const permissions = groups.permissions

  const contributorsModule = permissions.filter((c) => {
    return (
      c.module.name.includes('contributors') &&
      c.module.name !== 'contributors-status' &&
      c.module.name !== 'contributors-schedules'
    )
  })

  const customersModule = permissions.filter((c) => {
    return (
      c.module.name.includes('customers') &&
      c.module.name !== 'customers-schedules'
    )
  })

  const tasksModule = permissions.filter((c) => {
    return c.module.name.includes('tasks') || c.module.name.includes('breaks')
  })

  const equipmentsModule = permissions.filter((c) => {
    return (
      (c.module.name.includes('equipments') &&
        c.module.name !== 'tasks-equipments') ||
      c.module.name.includes('movements') ||
      c.module.name.includes('stocks') ||
      c.module.name.includes('templates')
    )
  })

  const othersModule = permissions.filter((c) => {
    return (
      c.module.name.includes('addresses') ||
      c.module.name === 'contacts' ||
      c.module.name === 'reports' ||
      c.module.name.includes('companies') ||
      c.module.name.includes('users')
    )
  })

  const schedulesModule = permissions.filter((c) => {
    return (
      c.module.name.includes('schedules') || c.module.name.includes('schedule')
    )
  })

  const groupsModule = permissions.filter((c) => {
    return c.module.name.includes('groups') || c.module.name === 'teams'
  })

  return (
    <Card
      bordered={false}
      title={translate('GroupsDetailsPermissions.permissionsCardTitle')}
      extra={
        groups.type === 'admin' ||
        groups.type === 'user' ||
        groups.type === 'manager' ? (
          ''
        ) : (
          <CreatePermission groupId={groups.id} />
        )
      }
      style={{
        display: 'inline-block',
        width: '100%',
      }}
    >
      <Container>
        {groups.permissions.length === 0 ? (
          <p>{translate('GroupsDetailsPermissions.noPermissions')}</p>
        ) : (
          <>
            <Card
              title={translate(
                'GroupsDetailsPermissions.contributorsCardTitle',
              )}
              size="small"
            >
              {contributorsModule.map((c) => (
                <div key={c.id}>
                  <div className="card">
                    {c.module.name === 'contributors' ? (
                      <p>
                        {translate('GroupsDetailsPermissions.contributors')}
                      </p>
                    ) : (
                      ''
                    )}

                    {c.module.name === 'contributors-attachments' ? (
                      <p>
                        {translate(
                          'GroupsDetailsPermissions.contributorsAttachments',
                        )}
                      </p>
                    ) : (
                      ''
                    )}
                    {c.module.name === 'contributors-evaluations' ? (
                      <p>
                        {translate(
                          'GroupsDetailsPermissions.contributorsEvaluations',
                        )}
                      </p>
                    ) : (
                      ''
                    )}
                    {c.module.name === 'contributors-skills' ? (
                      <p>
                        {translate(
                          'GroupsDetailsPermissions.contributorsSkills',
                        )}
                      </p>
                    ) : (
                      ''
                    )}
                    {c.module.name === 'contributors-teams' ? (
                      <p>
                        {translate(
                          'GroupsDetailsPermissions.contributorsTeams',
                        )}
                      </p>
                    ) : (
                      ''
                    )}

                    <div className="actions">
                      {groups.type === 'custom' ? (
                        <EditPermission
                          data={c}
                          modules={c.module.name}
                          group={groups.id}
                        />
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </Card>

            <Card
              title={translate('GroupsDetailsPermissions.equipmentsCardTitle')}
              size="small"
            >
              {equipmentsModule.map((e) => (
                <div key={e.id}>
                  <div className="card" key={e.id}>
                    {e.module.name === 'equipments' ? (
                      <p>{translate('GroupsDetailsPermissions.equipments')}</p>
                    ) : (
                      ''
                    )}
                    {e.module.name === 'equipments-attachments' ? (
                      <p>
                        {translate(
                          'GroupsDetailsPermissions.equipmentsAttachments',
                        )}
                      </p>
                    ) : (
                      ''
                    )}
                    {e.module.name === 'equipments-specifications' ? (
                      <p>
                        {translate(
                          'GroupsDetailsPermissions.equipmentsSpecifications',
                        )}
                      </p>
                    ) : (
                      ''
                    )}
                    {e.module.name === 'associated-equipments' ? (
                      <p>
                        {translate(
                          'GroupsDetailsPermissions.associatedEquipments',
                        )}
                      </p>
                    ) : (
                      ''
                    )}
                    {e.module.name === 'movements' ? (
                      <p>{translate('GroupsDetailsPermissions.movements')}</p>
                    ) : (
                      ''
                    )}
                    {e.module.name === 'stocks' ? (
                      <p>{translate('GroupsDetailsPermissions.stocks')}</p>
                    ) : (
                      ''
                    )}
                    {e.module.name === 'specifications-templates' ? (
                      <p>
                        {translate(
                          'GroupsDetailsPermissions.specificationsTemplates',
                        )}
                      </p>
                    ) : (
                      ''
                    )}
                    <div className="actions">
                      {groups.type === 'custom' ? (
                        <EditPermission
                          data={e}
                          modules={e.module.name}
                          group={groups.id}
                        />
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </Card>

            <Card
              title={translate('GroupsDetailsPermissions.tasksCardTitle')}
              size="small"
            >
              {tasksModule.map((t) => (
                <div key={t.id}>
                  <div className="card" key={t.id}>
                    {t.module.name === 'tasks' ? (
                      <p>{translate('GroupsDetailsPermissions.tasks')}</p>
                    ) : (
                      ''
                    )}
                    {t.module.name === 'breaks-reasons' ? (
                      <p>
                        {translate('GroupsDetailsPermissions.breakReasons')}
                      </p>
                    ) : (
                      ''
                    )}
                    {t.module.name === 'tasks-types' ? (
                      <p>{translate('GroupsDetailsPermissions.tasksTypes')}</p>
                    ) : (
                      ''
                    )}
                    {t.module.name === 'tasks-status' ? (
                      <p>{translate('GroupsDetailsPermissions.tasksStatus')}</p>
                    ) : (
                      ''
                    )}
                    {t.module.name === 'tasks-equipments' ? (
                      <p>
                        {translate('GroupsDetailsPermissions.tasksEquipments')}
                      </p>
                    ) : (
                      ''
                    )}
                    {t.module.name === 'tasks-reports' ? (
                      <p>
                        {translate('GroupsDetailsPermissions.tasksReports')}
                      </p>
                    ) : (
                      ''
                    )}
                    <div className="actions">
                      {groups.type === 'custom' ? (
                        <EditPermission
                          data={t}
                          modules={t.module.name}
                          group={groups.id}
                        />
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </Card>

            <Card
              title={translate('GroupsDetailsPermissions.otherCardTitle')}
              size="small"
            >
              {othersModule.map((o) => (
                <div key={o.id}>
                  <div className="card" key={o.id}>
                    {o.module.name === 'users' ? (
                      <p>{translate('GroupsDetailsPermissions.users')}</p>
                    ) : (
                      ''
                    )}
                    {o.module.name === 'companies' ? (
                      <p>{translate('GroupsDetailsPermissions.companies')}</p>
                    ) : (
                      ''
                    )}
                    {o.module.name === 'contacts' ? (
                      <p>{translate('GroupsDetailsPermissions.contacts')}</p>
                    ) : (
                      ''
                    )}
                    {o.module.name === 'addresses' ? (
                      <p>{translate('GroupsDetailsPermissions.addresses')}</p>
                    ) : (
                      ''
                    )}
                    {o.module.name === 'contacts-addresses' ? (
                      <p>
                        {translate(
                          'GroupsDetailsPermissions.contactsAddresses',
                        )}
                      </p>
                    ) : (
                      ''
                    )}
                    {o.module.name === 'reports' ? (
                      <p>{translate('GroupsDetailsPermissions.reports')}</p>
                    ) : (
                      ''
                    )}
                    <div className="actions">
                      {groups.type === 'custom' ? (
                        <EditPermission
                          data={o}
                          modules={o.module.name}
                          group={groups.id}
                        />
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </Card>

            <Card
              title={translate('GroupsDetailsPermissions.customersCardTitle')}
              size="small"
            >
              {customersModule.map((c) => (
                <div key={c.id}>
                  <div className="card" key={c.id}>
                    {c.module.name === 'customers' ? (
                      <p>{translate('GroupsDetailsPermissions.customers')}</p>
                    ) : (
                      ''
                    )}
                    {c.module.name === 'customers-segments' ? (
                      <p>
                        {translate(
                          'GroupsDetailsPermissions.customersSegments',
                        )}
                      </p>
                    ) : (
                      ''
                    )}
                    {c.module.name === 'customers-contacts' ? (
                      <p>
                        {translate(
                          'GroupsDetailsPermissions.customersContacts',
                        )}
                      </p>
                    ) : (
                      ''
                    )}
                    <div className="actions">
                      {groups.type === 'custom' ? (
                        <EditPermission
                          data={c}
                          modules={c.module.name}
                          group={groups.id}
                        />
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </Card>

            <Card
              title={translate('GroupsDetailsPermissions.scalesCardTitle')}
              size="small"
            >
              {schedulesModule.map((s) => (
                <div key={s.id}>
                  <div className="card" key={s.id}>
                    {s.module.name === 'schedules' ? (
                      <p>{translate('GroupsDetailsPermissions.schedules')}</p>
                    ) : (
                      ''
                    )}
                    {s.module.name === 'customers-schedules' ? (
                      <p>
                        {translate(
                          'GroupsDetailsPermissions.customersSchedules',
                        )}
                      </p>
                    ) : (
                      ''
                    )}
                    {s.module.name === 'contributors-schedules' ? (
                      <p>
                        {translate(
                          'GroupsDetailsPermissions.contributorsSchedules',
                        )}
                      </p>
                    ) : (
                      ''
                    )}
                    {s.module.name === 'schedule-times' ? (
                      <p>
                        {translate('GroupsDetailsPermissions.scheduleTimes')}
                      </p>
                    ) : (
                      ''
                    )}
                    <div className="actions">
                      {groups.type === 'custom' ? (
                        <EditPermission
                          data={s}
                          modules={s.module.name}
                          group={groups.id}
                        />
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </Card>

            <Card
              title={translate('GroupsDetailsPermissions.groupCardTitle')}
              size="small"
            >
              {groupsModule.map((g) => (
                <div key={g.id}>
                  <div className="card" key={g.id}>
                    {g.module.name === 'groups' ? (
                      <p>{translate('GroupsDetailsPermissions.groups')}</p>
                    ) : (
                      ''
                    )}
                    {g.module.name === 'groups-permissions' ? (
                      <p>
                        {translate(
                          'GroupsDetailsPermissions.groupsPermissions',
                        )}
                      </p>
                    ) : (
                      ''
                    )}
                    {g.module.name === 'teams' ? (
                      <p>{translate('GroupsDetailsPermissions.teams')}</p>
                    ) : (
                      ''
                    )}

                    <div className="actions">
                      {groups.type === 'custom' ? (
                        <EditPermission
                          data={g}
                          modules={g.module.name}
                          group={groups.id}
                        />
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </Card>
          </>
        )}
      </Container>
    </Card>
  )
}

export default DetailsPermissions
