import React from 'react'
import { Helmet } from 'react-helmet'
import TemplateBoard from '~/components/DataManipulation/Equipments/Template'

import { ListTemplateProvider } from '~/hooks/Equipments/Especification/ListTemplate'
import { translate } from '~/utils/locale'

const ShowTemplates: React.FC = () => {
  return (
    <ListTemplateProvider>
      <Helmet>
        <title>{translate('ShowTemplates.helmetTitle')}</title>
      </Helmet>
      <TemplateBoard />
    </ListTemplateProvider>
  )
}

export default ShowTemplates
