export default {
  pt: {
    TasksOptionsKanbanFilter: {
      cleanTooltip: 'Limpar Filtro',
      filterAppliedText: 'Filtro Aplicado',
      filterText: 'Filtros',
      drawerTitle: 'Filtros de Tarefas',
      contributorLabel: 'Colaborador',
      contributorPlaceholder: 'Selecione o colaborador',
      teamLabel: 'Equipe',
      teamPlaceholder: 'Selecione a equipe',
      clientLabel: 'Cliente',
      clientPlaceholder: 'Selecione o cliente',
      statusLabel: 'Status Atual',
      statusPlaceholder: 'Informe o status',
      taskTypeLabel: 'Tipo de Tarefa',
      taskTypePlaceholder: 'Selecione o tipo de tarefa',
      priorityLabel: 'Prioridade',
      priorityPlaceholder: 'Selecione a prioridade',
      priorityHigh: 'Alta',
      priorityMedium: 'Média',
      priorityLow: 'Baixa',
      internalCodeLabel: 'Código Interno',
      internalCodePlaceholder: 'Informe o código interno',
      externalCodeLabel: 'Código Externo',
      externalCodePlaceholder: 'Informe o código externo',
      addressLabel: 'Endereço',
      addressPlaceholder: 'Informe o endereço',
      applyButton: 'Aplicar',
      cleanButton: 'Limpar',
    },
  },
  en: {
    TasksOptionsKanbanFilter: {
      cleanTooltip: 'Clear filter',
      filterAppliedText: 'Filter Applied',
      filterText: 'Filters',
      drawerTitle: 'Task Filters',
      contributorLabel: 'Contributor',
      contributorPlaceholder: 'Select the contributor',
      teamLabel: 'Team',
      teamPlaceholder: 'Select the team',
      clientLabel: 'Customer',
      clientPlaceholder: 'Select customer',
      statusLabel: 'Current status',
      statusPlaceholder: 'Report the status',
      taskTypeLabel: 'Task Type',
      taskTypePlaceholder: 'Select task type',
      priorityLabel: 'Priority',
      priorityPlaceholder: 'Select priority',
      priorityHigh: 'High',
      priorityMedium: 'Medium',
      priorityLow: 'Low',
      internalCodeLabel: 'Internal Code',
      internalCodePlaceholder: 'Enter the internal code',
      externalCodeLabel: 'External Code',
      externalCodePlaceholder: 'Enter the external code',
      addressLabel: 'Address',
      addressPlaceholder: 'Inform the address',
      applyButton: 'Apply',
      cleanButton: 'Clean',
    },
  },
  es: {
    TasksOptionsKanbanFilter: {
      cleanTooltip: 'Filtro claro',
      filterAppliedText: 'Filtro Aplicado',
      filterText: 'Filtros',
      drawerTitle: 'Filtros de Tareas',
      contributorLabel: 'Colaborador',
      contributorPlaceholder: 'Seleccione el colaborador',
      teamLabel: 'Equipo',
      teamPlaceholder: 'Seleccione el equipo',
      clientLabel: 'Cliente',
      clientPlaceholder: 'Seleccionar cliente',
      statusLabel: 'Status Actual',
      statusPlaceholder: 'Informar el status',
      taskTypeLabel: 'Tipo de Tarea',
      taskTypePlaceholder: 'Seleccionar tipo de tarea',
      priorityLabel: 'Prioridad',
      priorityPlaceholder: 'Seleccionar prioridad',
      priorityHigh: 'Alta',
      priorityMedium: 'Media',
      priorityLow: 'Baja',
      internalCodeLabel: 'Código Interno',
      internalCodePlaceholder: 'Introduce el código interno',
      externalCodeLabel: 'Código Externo',
      externalCodePlaceholder: 'Introduce el código externo',
      addressLabel: 'Dirección',
      addressPlaceholder: 'Informar la dirección',
      applyButton: 'Aplicar',
      cleanButton: 'Limpio',
    },
  },
}
