export default {
  pt: {
    DashboardRates: {
      viewTasksTooltip: 'Ver tarefas',
      tasksTitle: 'Tarefas',
      taskText: 'Tarefa',
      tasksText: 'Tarefas',
      viewRatingsTooltip: 'Ver avaliações',
      internalEvaluationTitle: 'Média de Avaliação Interna',
      internalEvaluationHelp:
        'Se trata de uma avaliação interna, ou seja, é o que foi definido pelo usuário que gerencia a conta do Gstor. O valor exibido é o valor definido pelo gestor da conta.',
      evaluationTitle: 'Média de Avaliação de Atendimento',
      evaluationHelp:
        'Avaliação realizada através de pesquisa com quem recebeu atendimento do colaborador. O valor exibido é a média de todas as avaliações realizadas para o respectivo colaborador.',
      totalText: 'Total',
    },
  },
  en: {
    DashboardRates: {
      viewTasksTooltip: 'View tasks',
      tasksTitle: 'Tasks',
      taskText: 'Task',
      tasksText: 'Tasks',
      viewRatingsTooltip: 'View ratings',
      internalEvaluationTitle: 'Average Internal Rating',
      internalEvaluationHelp:
        'This is an internal assessment, that is, it is defined by the user who manages the Gstor account. The displayed value is the value defined by the account manager.',
      evaluationTitle: 'Average Service Rating',
      evaluationHelp:
        'Evaluation carried out through a survey with those who received assistance from the employee. The displayed value is the average of all evaluations performed for the respective employee.',
      totalText: 'Total',
    },
  },
  es: {
    DashboardRates: {
      viewTasksTooltip: 'Ver tareas',
      tasksTitle: 'Tareas',
      taskText: 'Tarea',
      tasksText: 'Tareas',
      viewRatingsTooltip: 'Ver reseñas',
      internalEvaluationTitle: 'Promedio de Evaluación Interna',
      internalEvaluationHelp:
        'Esta es una evaluación interna, es decir, la define el usuario que administra la cuenta de Gstor. El valor mostrado es el valor definido por el administrador de la cuenta.',
      evaluationTitle: 'Calificación de servicio promedio',
      evaluationHelp:
        'Evaluación realizada a través de una encuesta con quienes recibieron asistencia del empleado. El valor mostrado es el promedio de todas las evaluaciones realizadas para el empleado respectivo.',
      totalText: 'Total',
    },
  },
}
