export default {
  pt: {
    TasksRegistrationPause: {
      cardTitle: 'Motivos de Pausa',
      totalText: 'Total de',
      breakReasonText: 'motivo de pausa',
      breakReasonsText: 'motivos de pausa',
    },
  },
  en: {
    TasksRegistrationPause: {
      cardTitle: 'Break Reasons',
      totalText: 'Total of',
      breakReasonText: 'break reason',
      breakReasonsText: 'break reasons',
    },
  },
  es: {
    TasksRegistrationPause: {
      cardTitle: 'Motivos de Descanso',
      totalText: 'Total de',
      breakReasonText: 'motivo de descanso',
      breakReasonsText: 'motivos de descanso',
    },
  },
}
