/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/order */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/no-duplicates */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-bitwise */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-underscore-dangle */
import { LoadingOutlined, EditOutlined } from '@ant-design/icons'
import {
  Button,
  Card,
  Col,
  Popconfirm,
  Row,
  Space,
  Tag,
  Tooltip,
  Typography,
} from 'antd'
import Text from 'antd/lib/typography/Text'
import { format, formatDistance } from 'date-fns'

import React, { useState } from 'react'
import {
  FiAlertOctagon,
  FiCalendar,
  FiClock,
  FiExternalLink,
  FiFile,
  FiFileText,
  FiMail,
  FiUser,
} from 'react-icons/fi'
import { MapContainer, Marker, TileLayer } from 'react-leaflet'

import { useListTask } from '~/hooks/Tasks/useListTasks'
import { useTask } from '~/hooks/Tasks/useTask'

import { translate } from '~/utils/locale'
import { Container } from './styles'
import { useCompanies } from '~/hooks/Companies/useCompanies'
import ToleranceTimeCountdown from './ToleranceTimeCountdown/ToleranceTimeCountdown'
import ExeuctionTimeCountdown from './ExecutionTimeCountdown'
import { ptBR } from 'date-fns/locale'
import CurrencyFormatter from '~/components/DataManipulation/Misc/CurrencyFormater'

const DetailsTask: React.FC = () => {
  const { task, isLoading, setIsEdit } = useTask()

  const { deleteTask } = useListTask()

  const { selectedCompany } = useCompanies()

  const [shortUrl, setShortUrl] = useState('')

  if (isLoading || !task) {
    return <LoadingOutlined style={{ fontSize: 64, margin: 'auto' }} spin />
  }

  const checkOutStatus: any = task.status.find((t) => t.type === 'checkout')
  const checkInStatus: any = task.status.find((t) => t.type === 'check-in')

  const finishedTaskDate = formatDistance(
    new Date(checkInStatus ? new Date(checkInStatus.created_at) : new Date()),
    checkOutStatus ? new Date(checkOutStatus.created_at) : new Date(),
    { locale: ptBR },
  )

  const createdTask = format(
    new Date(task.createdAt),
    `dd/MM/yyyy '${translate('TasksTabsDetails.dateString')}' HH:mm'h'`,
  )

  const dateTask = format(
    new Date(task.date),
    `dd/MM/yyyy '${translate('TasksTabsDetails.dateString')}' HH:mm'h'`,
  )

  const checkoutTask = format(
    new Date(checkOutStatus ? checkInStatus.created_at : new Date()),
    `dd/MM/yyyy '${translate('TasksTabsDetails.dateString')}' HH:mm'h'`,
  )

  const deleteTaskPage = () => {
    deleteTask(task?._id)
    window.history.back()
  }

  const note = localStorage.getItem('@Gstor:noteTask')

  const linkTask = `https://tasks.gstor.com.br/?auth=${selectedCompany},${task.responsible?.id}`

  const handleShortenUrl = async () => {
    const accessToken = process.env.REACT_APP_BITLY_ACCESS_TOKEN
    const response = await fetch('https://api-ssl.bitly.com/v4/shorten', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ long_url: linkTask }),
    })
    const data = await response.json()
    setShortUrl(data.link)
  }

  return (
    <Container>
      <Card
        bordered={false}
        title={`${translate('TasksTabsDetails.cardTitle')} - ${dateTask}`}
        extra={
          <Space>
            {!checkOutStatus ? (
              ''
            ) : (
              <Tag color="blue">
                A tarefa foi finalizada no período de {finishedTaskDate}
              </Tag>
            )}
            <Popconfirm
              title={translate('TasksTable.deletePopconfirm')}
              onConfirm={deleteTaskPage}
            >
              <Tooltip title={translate('TasksTable.deleteTooltip')}>
                <Button type="primary" danger>
                  Excluir
                </Button>
              </Tooltip>
            </Popconfirm>
            <Button
              type="primary"
              icon={<EditOutlined />}
              onClick={() => setIsEdit(true)}
              id="btn-edit-tasks"
            >
              {translate('TasksTabsDetails.editButton')}
            </Button>
          </Space>
        }
        style={{
          display: 'inline-block',
          width: '100%',
        }}
      >
        <>
          <Row gutter={12}>
            <Col span={12}>
              <div className="text">
                <div>
                  <FiUser />
                  <Text strong>{translate('TasksTabsDetails.createdBy')}</Text>
                </div>
                {task.responsible?.type === 'collaborator' ? (
                  <>
                    {task.responsible.data?.contact ? (
                      <Text>
                        {task.responsible.data.contact.name}{' '}
                        {translate('TasksTabsDetails.atText')}
                        &nbsp;
                        {createdTask}
                      </Text>
                    ) : (
                      <Text>{translate('TasksTabsDetails.noCreatedBy')}</Text>
                    )}
                  </>
                ) : (
                  ''
                )}

                {task.responsible?.type === 'team' ? (
                  <>
                    {task.responsible.data?.title ? (
                      <Text>
                        {task.responsible.data.title}{' '}
                        {translate('TasksTabsDetails.atText')}
                        &nbsp;
                        {createdTask}
                      </Text>
                    ) : (
                      <Text>{translate('TasksTabsDetails.noCreatedBy')}</Text>
                    )}
                  </>
                ) : (
                  ''
                )}

                {task.responsible.type === '' ? (
                  <Text>{translate('TasksTabsDetails.noCreatedBy')}</Text>
                ) : (
                  ''
                )}
              </div>
            </Col>
            <Col span={12}>
              <div className="text">
                <div>
                  <FiUser />
                  <Text strong>{translate('TasksTabsDetails.toText')}</Text>
                </div>
                {task.customer?.contact.name ? (
                  <Text>{task.customer?.contact.name}</Text>
                ) : (
                  <Text>{translate('TasksTabsDetails.noToText')}</Text>
                )}
              </div>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={12}>
              <div className="text">
                <div>
                  <FiCalendar />
                  <Text strong>{translate('TasksTabsDetails.taskDate')}</Text>
                </div>
                <Text>{createdTask}</Text>
              </div>
            </Col>
            <Col span={12}>
              <div className="text">
                <div>
                  <FiClock />
                  <Text strong>
                    {translate('TasksTabsDetails.durationTime')}
                  </Text>
                </div>
                <Text>{task.duration_time}</Text>
              </div>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={12}>
              <div className="text">
                <div>
                  <FiFileText />
                  <Text strong>
                    {translate('TasksTabsDetails.checkinType')}
                  </Text>
                </div>
                <Tag color="blue">
                  {translate('TasksTabsDetails.manualText')}
                </Tag>
              </div>
            </Col>
            <Col span={12}>
              <div className="text">
                <div>
                  <FiAlertOctagon />
                  <Text strong>
                    {translate('TasksTabsDetails.priorityText')}
                  </Text>
                </div>
                {!task.priority ? (
                  <Text>{translate('TasksTabsDetails.noPriorityText')}</Text>
                ) : (
                  ''
                )}

                {task.priority === 'low' ? (
                  <Tag color="green">
                    {translate('TasksTabsDetails.priorityLow')}
                  </Tag>
                ) : (
                  ''
                )}
                {task.priority === 'medium' ? (
                  <Tag color="yellow">
                    {translate('TasksTabsDetails.priorityMedium')}
                  </Tag>
                ) : (
                  ''
                )}
                {task.priority === 'high' ? (
                  <Tag color="red">
                    {translate('TasksTabsDetails.priorityHigh')}
                  </Tag>
                ) : (
                  ''
                )}
              </div>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={12}>
              <div className="text">
                <div>
                  <FiFile />
                  <Text strong>{translate('TasksTabsDetails.valueText')}</Text>
                </div>
                {task.value ? (
                  <Text>
                    <CurrencyFormatter value={task.value} />
                  </Text>
                ) : (
                  <Text>{translate('TasksTabsDetails.noValueText')}</Text>
                )}
              </div>
            </Col>
            <Col span={12}>
              <div className="text">
                <div>
                  <FiExternalLink />
                  <Text strong>{translate('TasksTabsDetails.statusText')}</Text>
                </div>
                {task.current_status === 'received' ? (
                  <Tag color="yellow">
                    {translate('TasksTabsDetails.statusReceived')}
                  </Tag>
                ) : (
                  ''
                )}
                {task.current_status === 'viewed' ? (
                  <Tag color="green">
                    {translate('TasksTabsDetails.statusViewed')}
                  </Tag>
                ) : (
                  ''
                )}
                {task.current_status === 'working' ? (
                  <Tag color="blue">
                    {translate('TasksTabsDetails.statusWorking')}
                  </Tag>
                ) : (
                  ''
                )}
                {task.current_status === 'break' ? (
                  <Tag color="red">
                    {translate('TasksTabsDetails.statusBreak')}
                  </Tag>
                ) : (
                  ''
                )}
                {task.current_status === 'en-route' ? (
                  <Tag color="red">
                    {translate('TasksTabsDetails.statusEnRoute')}
                  </Tag>
                ) : (
                  ''
                )}
                {task.current_status === 'resume-working' ? (
                  <Tag color="green">
                    {translate('TasksTabsDetails.statusResumeWorking')}
                  </Tag>
                ) : (
                  ''
                )}
                {task.current_status === 'canceled' ? (
                  <Tag color="gray">
                    {translate('TasksTabsDetails.statusCanceled')}
                  </Tag>
                ) : (
                  ''
                )}
                {task.current_status === 'finished' ? (
                  <Tag color="geekblue">
                    {translate('TasksTabsDetails.statusFinished')}{' '}
                    {checkoutTask}
                  </Tag>
                ) : (
                  ''
                )}
              </div>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={12}>
              <div className="text">
                <div>
                  <FiExternalLink />
                  <Text strong>
                    {translate('TasksTabsDetails.externalCode')}
                  </Text>
                </div>
                <Text>{task.external_code}</Text>
              </div>
            </Col>
            <Col span={12}>
              <div className="text">
                <div>
                  <FiExternalLink />
                  <Text strong>
                    {translate('TasksTabsDetails.internalCode')}
                  </Text>
                </div>
                <Text>{task.internal_code}</Text>
              </div>
            </Col>
          </Row>

          <Row gutter={14}>
            <Col span={12}>
              <div className="text">
                <div>
                  <FiFileText />
                  <Text strong>
                    {translate('TasksTabsDetails.description')}
                  </Text>
                </div>
                <Text>{task.description}</Text>
              </div>
            </Col>
            <Col span={12}>
              <div className="text">
                <div>
                  <FiFileText />
                  <Text strong>{note ? <>{note}</> : <>Notas:</>}</Text>
                </div>
                {task?.note ? (
                  <Text>{task?.note}</Text>
                ) : (
                  <Text>{translate('TasksTabsDetails.noteText')}</Text>
                )}
              </div>
            </Col>
          </Row>

          <Row gutter={4} align="middle">
            <Col>
              {!shortUrl ? (
                <Button onClick={handleShortenUrl}>Link da Tarefa</Button>
              ) : (
                <Col>
                  {shortUrl && (
                    <Typography.Link
                      href={shortUrl}
                      target="_blank"
                      style={{ fontSize: 16 }}
                      copyable
                    >
                      {shortUrl}
                    </Typography.Link>
                  )}
                </Col>
              )}
            </Col>
          </Row>
        </>
      </Card>
      <Card
        bordered={false}
        title={translate('TasksTabsDetails.taskTypeCardTitle')}
        style={{
          display: 'inline-block',
          width: '100%',
          marginTop: 15,
        }}
      >
        <Row gutter={14}>
          <Col span={12}>
            <div className="text">
              <div>
                <FiFileText />
                <Text strong>
                  {translate('TasksTabsDetails.taskTypeTitle')}
                </Text>
              </div>
              <Text>{task.taskType.title}</Text>
            </div>
          </Col>

          <Col span={12}>
            <div className="text">
              <div>
                <FiFileText />
                <Text strong>{translate('TasksTabsDetails.description')}</Text>
              </div>
              {task.taskType.description ? (
                <Text>{task.taskType.description}</Text>
              ) : (
                <Text>Nenhuma descrição foi fornecida.</Text>
              )}
            </div>
          </Col>
        </Row>

        <Row gutter={14}>
          <Col span={12}>
            <div className="text">
              <div>
                <FiClock />
                <Text strong>{translate('TasksTabsDetails.runtime')}</Text>
              </div>
              <Text>{task.duration_time}</Text>
              {task.current_status === 'finished' ? (
                <Text>{translate('TasksTabsDetails.statusFinished')}</Text>
              ) : (
                <ExeuctionTimeCountdown
                  executionTime={task.taskType.runtime}
                  status={task.current_status}
                  checkinDate={
                    checkInStatus ? checkInStatus.created_at : new Date()
                  }
                />
              )}
            </div>
          </Col>
          <Col span={12}>
            <div className="text">
              <div>
                <FiClock />
                <Text strong>
                  {translate('TasksTabsDetails.toleranceTime')}
                </Text>
              </div>
              <Text>
                {task.taskType.tolerance_time}
                {task.current_status === 'working' ? (
                  <Tag>{translate('TasksTabsDetails.workingStatusText')}</Tag>
                ) : (
                  <ToleranceTimeCountdown
                    toleranceTime={task.taskType.tolerance_time}
                    status={task.current_status}
                    created_at={task.createdAt}
                  />
                )}
              </Text>
            </div>
          </Col>
        </Row>
      </Card>
      <Card
        bordered={false}
        title={translate('TasksTabsDetails.addressCardTitle')}
        style={{
          display: 'inline-block',
          width: '100%',
          marginTop: 15,
        }}
      >
        <Row gutter={12}>
          <Col span={3}>
            <div className="text">
              <div>
                <FiMail />
                <Text strong>{translate('TasksTabsDetails.zipcodeText')}</Text>
              </div>
              <Text>{task.address.zip_code}</Text>
            </div>
          </Col>
          <Col span={14}>
            <div className="text">
              <div>
                <FiMail />
                <Text strong>{translate('TasksTabsDetails.addressText')}</Text>
              </div>
              <Text>
                {task.address.street}, {task.address.number} -{' '}
                {task.address.neighborhood}
              </Text>
            </div>
          </Col>
          <Col>
            <div className="text">
              <div>
                <FiMail />
                <Text strong>{translate('TasksTabsDetails.cityText')}</Text>
              </div>
              <Text>
                {task.address.city} - {task.address.state}
              </Text>
            </div>
          </Col>
        </Row>

        {/* {!isLoading && (
          <MapContainer
            center={
              task.address.location.x && task.address.location.x
                ? [task.address.location.x, task.address.location.y]
                : [0, 0]
            }
            zoom={13}
            scrollWheelZoom={false}
            style={{ height: '250px', width: '100%' }}
          >
            <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {task.address.location.x &&
              task.address.location.y &&
              task.address.location.x !== 0 &&
              task.address.location.y !== 0 && (
                <Marker
                  position={[task.address.location.x, task.address.location.y]}
                />
              )}
          </MapContainer>
        )} */}
      </Card>
    </Container>
  )
}

export default DetailsTask
