export default {
  pt: {
    TasksTabsReportCreate: {
      newButton: 'Nova Evidência',
      modalTitle: 'Nova Evidência',
      okText: 'Salvar',
      formatLabel: 'Formato da evidência',
      formatPlaceholder: 'Informe o formato',
      formatNotes: 'Texto - (Notas)',
      formatArchive: 'Arquivos - (PDF, vídeo, imagem, audio)',
      titleLabel: 'Título',
      titlePlaceholder: 'Informe o título',
      reportLabel: 'Digite a evidência',
      draggerRule: 'Por favor selecione um arquivo',
      draggerParagraphOne: 'Clique ou arraste arquivos aqui para enviar',
      draggerParagraphTwo: 'Apenas um arquivo por vez.',
    },
  },
  en: {
    TasksTabsReportCreate: {
      newButton: 'New Report',
      modalTitle: 'New Report',
      okText: 'Save',
      formatLabel: 'Report format',
      formatPlaceholder: 'Inform the format',
      formatNotes: 'Text - (Notes)',
      formatArchive: 'Files - (PDF, video, image, audio)',
      titleLabel: 'Title',
      titlePlaceholder: 'Inform the title',
      reportLabel: 'Type the story',
      draggerRule: 'Please select a file',
      draggerParagraphOne: 'Click or drag files here to upload',
      draggerParagraphTwo: 'Only one file at a time.',
    },
  },
  es: {
    TasksTabsReportCreate: {
      newButton: 'Nuevo Reporte',
      modalTitle: 'Nuevo Reporte',
      okText: 'Guardar',
      formatLabel: 'Formato de informe',
      formatPlaceholder: 'Fnformar el formato',
      formatNotes: 'Texto - (Notas)',
      formatArchive: 'Archivos - (PDF, video, imagen, audio)',
      titleLabel: 'Título',
      titlePlaceholder: 'Informar el título',
      reportLabel: 'Escriba la historia',
      draggerRule: 'Seleccione un archivo',
      draggerParagraphOne:
        'Haga clic o arrastre los archivos aquí para cargarlos',
      draggerParagraphTwo: 'Solo un archivo a la vez.',
    },
  },
}
