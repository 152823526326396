export default {
  pt: {
    useReportKilometers: {
      getErrorMessage: 'Erro ao visualizar relatório.',
      textExport: 'Exportar',
      textLoading: 'Gerando arquivo...',
      textDownload: 'Fazer Download',
      exportErrorMessage: 'Erro ao exportar relatório.',
    },
  },
  en: {
    useReportKilometers: {
      getErrorMessage: 'Error viewing report.',
      textExport: 'Export',
      textLoading: 'Generating file...',
      textDownload: 'Download',
      exportErrorMessage: 'Error exporting report.',
    },
  },
  es: {
    useReportKilometers: {
      getErrorMessage: 'Error al ver el informe.',
      textExport: 'Exportar',
      textLoading: 'Generando archivo...',
      textDownload: 'Descargar',
      exportErrorMessage: 'Error al exportar el informe.',
    },
  },
}
