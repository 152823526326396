import { lighten } from 'polished'
import styled from 'styled-components'

export const Container = styled.div`
#tasks_export_list {
    background: var(--quartenary-color);
    border: 1px solid var(--quartenary-color);

    &:hover {
      background: ${lighten(0.05, '#3969B7')};
      border: 1px solid ${lighten(0.1, '#3969B7')};
    }
`
