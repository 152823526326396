export default {
  pt: {
    ClientsComponent: {
      cardTitle: 'Clientes',
      totalText: 'Total de',
      customerText: 'cliente',
      customersText: 'clientes',
      importTooltip: 'Importar Clientes',
      exportTooltip: 'Exportar Clientes',
      modalTitle: 'Importação de Clientes',
      importTitle: 'Importar Clientes',
      importDescription: 'Upload de arquivo para importação de Clientes',
      newCustomerButtonText: 'Novo Cliente',
    },
  },
  en: {
    ClientsComponent: {
      cardTitle: 'Customers',
      totalText: 'Total of',
      customerText: 'customer',
      customersText: 'customers',
      importTooltip: 'Import Customers',
      exportTooltip: 'Export Customers',
      modalTitle: 'Customer Import',
      importTitle: 'Import Customers',
      importDescription: 'File upload for importing Customers',
      newCustomerButtonText: 'New customer',
    },
  },
  es: {
    ClientsComponent: {
      cardTitle: 'Clientes',
      totalText: 'Total de',
      customerText: 'cliente',
      customersText: 'clientes',
      importTooltip: 'Importar Clientes',
      exportTooltip: 'Exportar Clientes',
      modalTitle: 'Importación de Clientes',
      importTitle: 'Importar Clientes',
      importDescription: 'Carga de archivos para importar Clientes',
      newCustomerButtonText: 'Nuevo Cliente',
    },
  },
}
