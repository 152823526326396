/* eslint-disable no-nested-ternary */
import { LoadingOutlined } from '@ant-design/icons'
import { Card, Steps } from 'antd'
import React from 'react'

import { useMovements } from '~/hooks/Equipments/Movements/useMovements'
import { translate } from '~/utils/locale'
import CreateMoviment from './Create'

import { Container } from './styles'

const MovementsTab: React.FC = () => {
  const { movements, isLoading } = useMovements()
  const { Step } = Steps

  if (isLoading || !movements) {
    return <LoadingOutlined style={{ fontSize: 64, margin: 'auto' }} spin />
  }

  return (
    <Container>
      <Card
        bordered={false}
        title={translate('EquipmentsTabsMovements.cardTitle')}
        style={{
          display: 'inline-block',
          width: '100%',
        }}
        extra={<CreateMoviment />}
      >
        <>
          <Steps progressDot current={movements.length} direction="vertical">
            {movements.map((movement) => (
              <Step
                title={
                  movement?.type === 'stock'
                    ? translate('EquipmentsTabsMovements.inventoryText')
                    : movement?.type === 'customer'
                      ? movement.customer?.contact?.name
                      : movement.collaborator?.contact?.name
                }
                subTitle={movement.initialTime}
                description={movement.notes}
                key={movement.id}
              />
            ))}
          </Steps>
        </>
      </Card>
    </Container>
  )
}

export default MovementsTab
