/* eslint-disable react/jsx-one-expression-per-line */
import { DeleteFilled, LoadingOutlined } from '@ant-design/icons'
import { Popconfirm, Space, Button, Table, Tooltip } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import Text from 'antd/lib/typography/Text'

import React from 'react'

import { useListTemplate } from '~/hooks/Equipments/Especification/ListTemplate'
import { translate } from '~/utils/locale'
import UpdateTemplate from '../Update'

interface SpecificationProps {
  attribute: string
  value: string
}

interface ListTemplateProps {
  id: string
  name: string
  specifications: SpecificationProps[]
}
const TemplateTable: React.FC = () => {
  const { listTemplate, isFetching, deleteTemplate } = useListTemplate()

  const columns: ColumnsType<ListTemplateProps> = [
    {
      title: translate('EquipmentsTemplateTable.templateColumn'),
      align: 'left',
      key: 'name',
      dataIndex: 'name',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: translate('EquipmentsTemplateTable.attributeColumn'),
      key: 'specification',
      render: (record) => (
        <>
          {record.specifications.length === 1 ? (
            <Text>
              {record.specifications.length}{' '}
              {translate('EquipmentsTemplateTable.attributeText')}
            </Text>
          ) : (
            <Text>
              {record.specifications.length}{' '}
              {translate('EquipmentsTemplateTable.attributesText')}
            </Text>
          )}
        </>
      ),
    },
    {
      title: translate('EquipmentsTemplateTable.actionsColumn'),
      align: 'left',
      key: 'remove',
      render: (record) => (
        <Space size="middle">
          <UpdateTemplate updateTemplate={record} />
          <Popconfirm
            title={translate('EquipmentsTemplateTable.deletePopconfirm')}
            onConfirm={() => deleteTemplate(record.id)}
          >
            <Tooltip title={translate('EquipmentsTemplateTable.deleteTooltip')}>
              <Button ghost danger shape="circle" icon={<DeleteFilled />} />
            </Tooltip>
          </Popconfirm>
        </Space>
      ),
    },
  ]

  return (
    <Table
      rowKey={(record) => record.id}
      loading={{
        indicator: <LoadingOutlined />,
        spinning: isFetching,
        size: 'large',
      }}
      columns={columns}
      dataSource={listTemplate}
      pagination={{
        showTotal: (total, range) => `${range[0]} - ${range[1]} / ${total}`,
      }}
    />
  )
}

export default TemplateTable
