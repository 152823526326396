export default {
  pt: {
    CompanyLogo: {
      uploadButton: 'Upload',
      uploadTypeError: 'Você só pode fazer upload de arquivos JPG ou PNG!',
      uploadSizeError: 'A imagem tem que ser menor que 2MB!',
      errorMessage: 'Erro ao enviar imagem de Logo',
      title: 'Logo',
      cancelText: 'Cancelar',
      modalTitle: 'Logo',
    },
  },
  en: {
    CompanyLogo: {
      uploadButton: 'Upload',
      uploadTypeError: 'You can only upload JPG or PNG files!',
      uploadSizeError: 'The image has to be smaller than 2MB!',
      errorMessage: 'Error sending logo image',
      title: 'Logo',
      cancelText: 'Cancel',
      modalTitle: 'Logo',
    },
  },
  es: {
    CompanyLogo: {
      uploadButton: 'Subir',
      uploadTypeError: '¡Solo puede cargar archivos JPG o PNG!',
      uploadSizeError: '¡La imagen debe tener un tamaño inferior a 2 MB!',
      errorMessage: 'Error al enviar la imagen del logotipo',
      title: 'Logotipo',
      cancelText: 'Cancelar',
      modalTitle: 'Logotipo',
    },
  },
}
