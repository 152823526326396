import {
  CaretDownOutlined,
  CaretUpOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
} from '@ant-design/icons'
import { Button, Form, Card, Switch, FormProps, Space, Tag, Flex } from 'antd'
import React, { useState } from 'react'
import { IQuestion, useTaskForm } from '~/hooks/Tasks/Forms/useTaskForm'
import QuestionText from './text'
import QuestionMultipleChoice from './multipleChoice'
import QuestionCheckbox from './checkbox'
import { Container } from './styles'
import QuestionDropdownList from './dropdownList'
import {
  MdOutlineCheckBox,
  MdOutlineCheckCircleOutline,
  MdRadioButtonChecked,
  MdSubject,
} from 'react-icons/md'

type FieldType = IQuestion

interface QuestionProps {
  data: IQuestion
  index: number
}

const Question: React.FC<QuestionProps> = ({ data, index }) => {
  const [formAntd] = Form.useForm()
  const {
    form,
    isLoading,
    isFetching,
    updateQuestion,
    moveQuestion,
    deleteQuestion,
  } = useTaskForm()
  const [isEditing, setIsEditing] = useState(false)

  const handleSave: FormProps<FieldType>['onFinish'] = (values) => {
    updateQuestion(data.id, values)
    onCloseEditing()
  }

  const onOpenEditing = () => {
    setIsEditing(true)
  }

  const onCloseEditing = () => {
    formAntd.resetFields()
    setIsEditing(false)
  }

  if (isLoading || !form || isFetching) {
    return <Card loading />
  }

  const getTextFormattedType = () => {
    switch (data.type) {
      case 'text':
        return (
          <>
            <MdSubject size={'18px'} /> Resposta
          </>
        )
      case 'multipleChoice':
        return (
          <>
            <MdRadioButtonChecked size={'18px'} />
            Múltipla escolha
          </>
        )
      case 'checkbox':
        return (
          <>
            <MdOutlineCheckBox size={'18px'} />
            Caixas de seleção
          </>
        )
      case 'dropdownList':
        return (
          <>
            <MdOutlineCheckCircleOutline size={'18px'} />
            Lista suspensa
          </>
        )
      default:
        return <>Desconhecido</>
    }
  }

  const getFormType = () => {
    switch (data.type) {
      case 'text':
        return <QuestionText data={data} isEditing={isEditing} />
      case 'multipleChoice':
        return <QuestionMultipleChoice data={data} isEditing={isEditing} />
      case 'checkbox':
        return <QuestionCheckbox data={data} isEditing={isEditing} />
      case 'dropdownList':
        return <QuestionDropdownList data={data} isEditing={isEditing} />
      default:
        return 'Desconhecido'
    }
  }

  return (
    <Container>
      <Form
        form={formAntd}
        onFinish={handleSave}
        autoComplete="off"
        initialValues={data}
      >
        <Card
          title={
            <Flex align="center" gap="1rem">
              <div>#{index + 1}</div>
              <Tag>
                <Flex align="center" gap="0.25rem">
                  {getTextFormattedType()}
                </Flex>
              </Tag>
              <Switch
                checked={data.is_required}
                checkedChildren="Obrigatório"
                unCheckedChildren="Não obrigatório"
                onChange={(value) =>
                  updateQuestion(data.id, { is_required: value })
                }
              />
            </Flex>
          }
          type="inner"
          bordered={!!isEditing}
          extra={
            !isEditing ? (
              <Space>
                {index > 0 && (
                  <Button
                    icon={<CaretUpOutlined />}
                    onClick={() => moveQuestion(data.id, 'up')}
                  />
                )}
                {index < form.questions.length - 1 && (
                  <Button
                    icon={<CaretDownOutlined />}
                    onClick={() => moveQuestion(data.id, 'down')}
                  />
                )}
              </Space>
            ) : (
              <Button
                icon={<CloseOutlined />}
                onClick={() => onCloseEditing()}
              />
            )
          }
          actions={
            isEditing
              ? [
                  <Button
                    key="delete"
                    type="text"
                    size={'large'}
                    icon={<DeleteOutlined />}
                    onClick={() => deleteQuestion(data.id)}
                  />,
                  <Button
                    key="save"
                    type="primary"
                    size={'large'}
                    htmlType="submit"
                    id="btn_create_question_form"
                  >
                    Salvar
                  </Button>,
                ]
              : [
                  <Button
                    key="edit"
                    size={'large'}
                    type="text"
                    icon={<EditOutlined />}
                    onClick={() => onOpenEditing()}
                  >
                    Editar
                  </Button>,
                ]
          }
        >
          {getFormType()}
        </Card>
      </Form>
    </Container>
  )
}

export default Question
