import {
  FileUnknownOutlined,
  LoadingOutlined,
  PlusCircleOutlined,
  SaveOutlined,
} from '@ant-design/icons'
import { Button, Modal, Popconfirm, Space, Table, Tooltip, message } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useListClients } from '~/hooks/Clients/useListClients'
import { translate } from '~/utils/locale'

import Text from 'antd/lib/typography/Text'
import { useParentCustomer } from '~/hooks/Clients/useParentCustomer'
import { ContainerButton } from './styles'

// import { Container } from './styles';

interface ClientsProps {
  id: string
  contact?: {
    name: string
    email: string
    id: string
  }
}

const AddChildrenCustomer: React.FC = () => {
  const { clients, clientsTotal, setNumberPage, setPageSize, isFetching } =
    useListClients()

  const { addChildrenCustomer, parentCustomer, updateChildrenParentClient } =
    useParentCustomer()

  const [isModalVisible, setIsModalVisible] = useState(false)

  const [isClient, setIsClient] = useState(false)
  const [clientsId, setClientsId] = useState<React.Key[]>([])

  const columns: ColumnsType<ClientsProps> = [
    {
      title: translate('ClientsTable.columnName'),
      align: 'left',
      dataIndex: ['contact', 'name'],
      key: 'name',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (name: string, record) => (
        <Tooltip title={translate('TasksCreateModalClient.viewClientTooltip')}>
          <Link
            target="_blank"
            to={`/clientes/visualizar/${record.id}/contato/${record.contact?.id}/`}
          >
            {name}
          </Link>
        </Tooltip>
      ),
    },
    {
      title: translate('ClientsTable.columnResponsible'),
      dataIndex: ['collaborator', 'contact', 'name'],
      align: 'center',
      key: 'contact',
      render: (contact: string) => (
        <>
          {contact || (
            <Text type="secondary">
              <Space size="small">
                <FileUnknownOutlined />
                N/A
              </Space>
            </Text>
          )}
        </>
      ),
    },
    {
      title: translate('ClientsTable.columnEmail'),
      align: 'center',
      key: 'email',
      dataIndex: ['contact', 'email'],
    },
  ]

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[]) => {
      setIsClient(selectedRowKeys.length > 0)
      setClientsId(selectedRowKeys)
    },
    getCheckboxProps: (record: ClientsProps) => ({
      disabled: record.contact?.name === 'Disabled User', // Column configuration not to be checked
      name: record.contact?.name,
    }),
  }

  function AddChildrenCustomer() {
    const clients = clientsId.map((m) => {
      const data = {
        customers: [{ id: m.toLocaleString() }],
      }

      return addChildrenCustomer(data)
    })

    clientsId.map((c) => {
      const parentId = {
        parent_cnpj: parentCustomer?._id,
      }

      return updateChildrenParentClient(parentId, c.toString())
    })

    message.success('Clientes associados com sucesso!')
    setIsClient(false)
    setIsModalVisible(false)
    return clients
  }

  const showModal = () => {
    setIsModalVisible(true)
  }

  const closeModal = () => {
    setIsModalVisible(false)
  }

  return (
    <>
      <Button
        icon={<PlusCircleOutlined />}
        onClick={showModal}
        style={{ width: '100%' }}
        type="primary"
      >
        {translate('TasksCreateModalClient.addClientButton')}
      </Button>
      <Modal
        title={translate('ClientsPrincipalTabsClient.clientTitleModal')}
        open={isModalVisible}
        onOk={closeModal}
        onCancel={closeModal}
        width="1000px"
        style={{
          top: 30,
          maxHeight: 'calc(100vh - 200px)',
        }}
        footer={[
          <div key="customer_children">
            {isClient && (
              <Popconfirm
                title={translate('ClientsPrincipalTabsClient.okText')}
                onConfirm={AddChildrenCustomer}
                placement="topRight"
              >
                <ContainerButton>
                  <Button
                    type="primary"
                    icon={<SaveOutlined />}
                    style={{ marginBottom: 10 }}
                    id="btn_add_client_to_parent"
                  >
                    {translate('ClientsPrincipalTabsClient.saveButton')}
                  </Button>
                </ContainerButton>
              </Popconfirm>
            )}
          </div>,
        ]}
      >
        <Table
          rowKey={(record) => record.id}
          loading={{
            indicator: <LoadingOutlined />,
            spinning: isFetching,
            size: 'large',
          }}
          rowSelection={{
            type: 'checkbox',
            ...rowSelection,
          }}
          pagination={{
            total: clientsTotal,
            showSizeChanger: true,
            pageSizeOptions: ['10', '20', '50', '100', '200'],
            defaultPageSize: 5,
            showTotal: (total, range) => `${range[0]} - ${range[1]} / ${total}`,
            onChange: (page, pageSize) => {
              setPageSize(pageSize!)
              setNumberPage(page)
            },
          }}
          columns={columns}
          dataSource={clients}
          scroll={{ x: 1000 }}
        />
      </Modal>
    </>
  )
}

export default AddChildrenCustomer
