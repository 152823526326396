export default {
  pt: {
    ClientsAddressTab: {
      loadingText: 'Carregando',
      cardTitle: 'Endereços',
    },
  },
  en: {
    ClientsAddressTab: {
      loadingText: 'Loading',
      cardTitle: 'Addresses',
    },
  },
  es: {
    ClientsAddressTab: {
      loadingText: 'Cargando',
      cardTitle: 'Direcciones',
    },
  },
}
