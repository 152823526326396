/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable consistent-return */
import React, {
  useContext,
  createContext,
  useState,
  useCallback,
  useEffect,
} from 'react'
import { useQuery } from 'react-query'
import axios from 'axios'

import { message } from 'antd'

import api from '~/services/api'

import { useCompanies } from '~/hooks/Companies/useCompanies'
import { useLocale } from '~/hooks/locale/useLocale'

import { ShowError } from '~/utils/errors/apiErrors'
import { translate } from '~/utils/locale'

interface StatesProps {
  id: number
  nome: string
  sigla: string
}

interface Status {
  color: string
  id: string
  description: string
  title: string
  type: string
}

interface CitiesProps {
  id: number
  nome: string
  sigla: string
}

interface AddressProps {
  id: string
  city: string
  complement: string
  neighborhood: string
  number: string
  state: string
  street: string
  zip_code: string
  location: {
    x: number
    y: number
  }
}

interface Address {
  id: string
  address: AddressProps
}

interface ContactProps {
  id: string
  name: string
  phone: string
  email: string
  contactAddresses: Address[]
}

interface ContribuitorsProps {
  contact: ContactProps
  internal_evaluation: number
  evaluation: number
  status: Status
  id: string
}

interface StatusId {
  id: string
}

interface FilterOptionsProps {
  address?: string
  contact?: string
  fromEvaluation?: number
  toEvaluation?: number
  fromInternalEvaluation?: number
  toInternalEvaluation?: number
  status_id?: StatusId[]
}

interface DashboardContextData {
  states?: StatesProps[]
  cities?: CitiesProps[]
  contribuitors?: ContribuitorsProps[]
  isLoading: boolean
  isLoadingCity: boolean
  isLoadingState: boolean
  refetch: () => void
  refetchCity: () => void
  refetchState: () => void
  setCity(value: string): void
  filterOptions: FilterOptionsProps
  handleUpdateFilters: (newFilterOptions: any) => void
  handleResetFilters: () => void
  totalContribuitors?: number
}

const DashboardContext = createContext<DashboardContextData>(
  {} as DashboardContextData,
)

export const DashboardProvider: React.FC = ({ children }) => {
  const { selectedCompany } = useCompanies()
  const { locale } = useLocale()
  const [city, setCity] = useState<string>()

  const [totalContribuitors, setTotalContribuitors] = useState<number>()

  const [filterOptions, setFilterOptions] = useState<FilterOptionsProps>(() => {
    const filter = localStorage.getItem('@Gstor:dashboardFilterOptions')

    if (filter) {
      return JSON.parse(filter)
    }

    return {} as FilterOptionsProps
  })

  const {
    data: contribuitors,
    isLoading,
    refetch,
  } = useQuery(
    [
      `contribuidores mapa{selectedCompany}`,
      selectedCompany,
      totalContribuitors,
    ],

    async () => {
      try {
        if (!selectedCompany) {
          return undefined
        }

        const response = await api.get(
          `/company/${selectedCompany}/contributors`,
          {
            params: {
              limit: totalContribuitors,
              address: filterOptions?.address,
              contact: filterOptions?.contact,
              fromEvaluation: filterOptions?.fromEvaluation,
              toEvaluation: filterOptions?.toEvaluation,
              fromInternalEvaluation: filterOptions?.fromInternalEvaluation,
              toInternalEvaluation: filterOptions?.toInternalEvaluation,
              ...(filterOptions?.status_id &&
                filterOptions?.status_id?.join() !== '' && {
                  status_id: filterOptions?.status_id?.join(),
                }),
            },
          },
        )

        const { data } = response

        setTotalContribuitors(data.total)

        return data.results
      } catch (err: any) {
        ShowError(
          err.message,
          translate('useDashboard.getContributorErrorMessage'),
          locale,
        )
      }
    },
  )

  const {
    data: states,
    isLoading: isLoadingState,
    refetch: refetchState,
  } = useQuery(
    [`estados do brasil{selectedCompany}`, selectedCompany],

    async () => {
      try {
        const response = await axios.get(
          'https://servicodados.ibge.gov.br/api/v1/localidades/estados',
        )

        const { data } = response

        return data
      } catch (err: any) {
        message.error(translate('useDashboard.getStatesErrorMessage'))
      }
    },
  )

  const {
    data: cities,
    isLoading: isLoadingCity,
    refetch: refetchCity,
  } = useQuery(
    [`cidades do brasil{selectedCompany}`, selectedCompany, city],

    async () => {
      try {
        const response = await axios.get(
          `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${city}/municipios`,
        )

        const { data } = response

        return data
      } catch (err: any) {
        message.error(translate('useDashboard.getStatesErrorMessage'))
      }
    },
  )

  const handleUpdateFilters = useCallback((newFilterOptions) => {
    const newFilters = {
      address: newFilterOptions?.address,
      contact: newFilterOptions?.contact,
      fromEvaluation: newFilterOptions?.evaluationRange?.[0],
      toEvaluation: newFilterOptions?.evaluationRange?.[1],
      fromInternalEvaluation: newFilterOptions?.internalEvaluationRange?.[0],
      toInternalEvaluation: newFilterOptions?.internalEvaluationRange?.[1],
      status_id: newFilterOptions?.status_id,
    }
    setFilterOptions(newFilters)
    localStorage.setItem(
      '@Gstor:dashboardFilterOptions',
      JSON.stringify(newFilters),
    )
  }, [])

  const handleResetFilters = useCallback(() => {
    localStorage.removeItem('@Gstor:dashboardFilterOptions')
    setFilterOptions({})
  }, [])

  useEffect(() => {
    refetch()
  }, [selectedCompany, refetch, filterOptions])

  return (
    <DashboardContext.Provider
      value={{
        states,
        cities,
        contribuitors,
        isLoading,
        isLoadingCity,
        isLoadingState,
        refetch,
        refetchCity,
        refetchState,
        setCity,
        filterOptions,
        handleUpdateFilters,
        handleResetFilters,
        totalContribuitors,
      }}
    >
      {children}
    </DashboardContext.Provider>
  )
}

export function useDashboard(): DashboardContextData {
  const context = useContext(DashboardContext)

  if (!context) {
    throw new Error('useAuth must be used within an AutoProvider')
  }

  return context
}
