/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable consistent-return */
import React, { useContext, createContext, useState } from 'react'
import { useQuery } from 'react-query'

import { message } from 'antd'

import api from '~/services/api'

import { useCompanies } from '~/hooks/Companies/useCompanies'
import { useLocale } from '~/hooks/locale/useLocale'

import { ShowError } from '~/utils/errors/apiErrors'
import { translate } from '~/utils/locale'

interface Contribuitors {
  id: string
  contact: {
    id: string
    name: string
  }
}

interface ListStatusProps {
  title: string
  id: string
  type: string
  color: string
  description: string
  contributorsTeams: Contribuitors[]
}

interface ListStatusContextData {
  listStatus?: ListStatusProps[]
  createStatus(value: object | undefined): void
  deleteStatus(statusId: string): void
  isLoading: boolean
  isFetching: boolean
  refetch: () => void
  statusTotal?: number
}

const ListStatusContext = createContext<ListStatusContextData>(
  {} as ListStatusContextData,
)

export const ListStatusProvider: React.FC = ({ children }) => {
  const { selectedCompany } = useCompanies()
  const { locale } = useLocale()

  const [statusTotal, setStatusTotal] = useState<number>()

  const {
    data: listStatus,
    isLoading,
    isFetching,
    refetch,
  } = useQuery(
    [`list of status${selectedCompany}`, selectedCompany],

    async () => {
      try {
        if (!selectedCompany) {
          return undefined
        }

        const response = await api.get(
          `/company/${selectedCompany}/contributors/status`,
        )

        const { data } = response

        setStatusTotal(data.total)

        return data.results
      } catch (err: any) {
        ShowError(
          err.message,
          translate('useListStatus.getErrorMessage'),
          locale,
        )
      }
    },
  )

  async function createStatus(value: object) {
    try {
      await api.post(`/company/${selectedCompany}/contributors/status`, value)

      refetch()
      message.success(translate('useListStatus.createSuccessMessage'))
    } catch (err: any) {
      ShowError(
        err.message,
        translate('useListStatus.createErrorMessage'),
        locale,
      )
    }
  }

  async function deleteStatus(statusId: string) {
    try {
      await api.delete(
        `/company/${selectedCompany}/contributors/status/${statusId}`,
      )

      refetch()
      message.success(translate('useListStatus.deleteSuccessMessage'))
    } catch (err: any) {
      ShowError(
        err.message,
        translate('useListStatus.deleteErrorMessage'),
        locale,
      )
    }
  }

  return (
    <ListStatusContext.Provider
      value={{
        listStatus,
        createStatus,
        deleteStatus,
        isLoading,
        isFetching,
        refetch,
        statusTotal,
      }}
    >
      {children}
    </ListStatusContext.Provider>
  )
}

export function useListStatus(): ListStatusContextData {
  const context = useContext(ListStatusContext)

  if (!context) {
    throw new Error('useAuth must be used within an AutoProvider')
  }

  return context
}
