/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/jsx-one-expression-per-line */
import {
  DeleteOutlined,
  LoadingOutlined,
  LockOutlined,
  UserOutlined,
} from '@ant-design/icons'
import {
  Avatar,
  Card,
  Col,
  Divider,
  Row,
  Space,
  Tooltip,
  List,
  Popconfirm,
  Button,
} from 'antd'
import { format } from 'date-fns'
import React from 'react'
import { Link } from 'react-router-dom'
import { useGroup } from '~/hooks/Groups/useGroup'

import { useUserGroup } from '~/hooks/Groups/useUserGroup'
import { LoadingIndicator } from '~/pages/Scale/EditScale/styles'
import { createLetterAvatar } from '~/utils/letter'
import { translate } from '~/utils/locale'
import { stringToHslColor } from '~/utils/stringToHslColor'
import CreateUser from '../Create/UserGroup'
import ContributorPermission from '../Create/ContributorPermission'
import EditGroup from '../Update/Group'

import { Container, Members } from './styles'

const DetailsGroup: React.FC = () => {
  const { groups, isLoading } = useGroup()
  const { deleteUser } = useUserGroup()

  if (isLoading || !groups) {
    return (
      <LoadingIndicator>
        <LoadingOutlined style={{ fontSize: 64, margin: 'auto' }} spin />
      </LoadingIndicator>
    )
  }

  const formattedDate = format(
    new Date(groups.created_at),
    `dd/MM/yyyy '${translate('GroupsDetails.timeString')}' HH:mm'h'`,
  )

  return (
    <Row gutter={10}>
      <Col span={8}>
        <Card
          bordered={false}
          title={translate('GroupsDetails.cardTitle')}
          extra={
            groups.type === 'admin' ||
            groups.type === 'user' ||
            groups.type === 'manager' ? (
              ''
            ) : (
              <EditGroup id_group={groups.id} name_group={groups.name} />
            )
          }
          style={{
            display: 'inline-block',
            width: '100%',
          }}
        >
          <Container>
            <div className="wrapper">
              <Avatar
                shape="square"
                size={155}
                style={{
                  fontSize: 40,
                  background: '#EAEAEA',
                  color: stringToHslColor(`${groups.name}`),
                }}
              >
                {createLetterAvatar(`${groups.name}`)}
              </Avatar>
              <div className="text">
                <h3>{groups.name}</h3>
                <p>
                  {translate('GroupsDetails.createdText')} {formattedDate}
                </p>
                {groups.type === 'custom' ? (
                  <p>{translate('GroupsDetails.groupCustom')}</p>
                ) : (
                  ''
                )}
                {groups.type === 'user' ? (
                  <p>{translate('GroupsDetails.groupUser')}</p>
                ) : (
                  ''
                )}
                {groups.type === 'manager' ? (
                  <p>{translate('GroupsDetails.groupManager')}</p>
                ) : (
                  ''
                )}
                {groups.type === 'admin' ? (
                  <p>{translate('GroupsDetails.groupAdmin')}</p>
                ) : (
                  ''
                )}
              </div>
            </div>
            <Divider />
            <Row gutter={10} justify="center">
              <Col span={8}>
                <div className="tag" id="groups_permissions">
                  <Space>
                    <LockOutlined />
                    <h4>
                      {groups.permissions.length}{' '}
                      {translate('GroupsDetails.permissionsText')}
                    </h4>
                  </Space>
                </div>
              </Col>
              <Col span={8}>
                <div className="tag" id="groups_members">
                  <Space>
                    <UserOutlined />
                    {groups.userGroups.length === 1 ? (
                      <h4>1 {translate('GroupsDetails.memberText')}</h4>
                    ) : (
                      <h4>
                        {groups.userGroups.length}{' '}
                        {translate('GroupsDetails.membersText')}
                      </h4>
                    )}
                  </Space>
                </div>
              </Col>
            </Row>
          </Container>
        </Card>
      </Col>

      <Col span={8}>
        <Card
          bordered={false}
          title={translate('GroupsDetails.permissionUsersCardTitle')}
          extra={<CreateUser group_id={groups.id} />}
          style={{
            display: 'inline-block',
            width: '100%',
            height: '55vh',
          }}
        >
          <Members>
            <List
              itemLayout="horizontal"
              dataSource={groups.userGroups}
              renderItem={(item) => (
                <List.Item
                  actions={[
                    <Popconfirm
                      key="actions"
                      title={translate('GroupsDetails.deletePopconfirm')}
                      onConfirm={() => deleteUser(item.user.id, groups.id)}
                    >
                      <Tooltip title={translate('GroupsDetails.deleteTooltip')}>
                        <Button
                          ghost
                          shape="circle"
                          icon={<DeleteOutlined />}
                          size="small"
                          danger
                        />
                      </Tooltip>
                    </Popconfirm>,
                  ]}
                >
                  <List.Item.Meta
                    avatar={<Avatar icon={<UserOutlined />} />}
                    title={
                      <Tooltip title={translate('GroupsDetails.viewTooltip')}>
                        {item.user.contact && (
                          <Link
                            to={`/colaboradores/visualizar/colaborador/${item.user.contact?.collaborator?.id}/contato/${item.user.contact?.id}`}
                          >
                            {item.user?.contact.name}
                          </Link>
                        )}
                      </Tooltip>
                    }
                  />
                </List.Item>
              )}
            />
          </Members>
        </Card>
      </Col>

      <Col span={8}>
        <Card
          bordered={false}
          title={translate('GroupsDetails.permissionContributorsCardTitle')}
          extra={<ContributorPermission group_id={groups.id} />}
          style={{
            display: 'inline-block',
            width: '100%',
            height: '55vh',
          }}
        >
          <Members>
            <List
              itemLayout="horizontal"
              dataSource={groups.userGroups}
              renderItem={(item) => (
                <List.Item
                  actions={[
                    <Popconfirm
                      key="actions"
                      title={translate(
                        'GroupsDetails.deleteContributorPopconfirm',
                      )}
                      onConfirm={() => deleteUser(item.user.id, groups.id)}
                    >
                      <Tooltip
                        title={translate(
                          'GroupsDetails.deleteContributorTooltip',
                        )}
                      >
                        <Button
                          ghost
                          shape="circle"
                          icon={<DeleteOutlined />}
                          size="small"
                          danger
                        />
                      </Tooltip>
                    </Popconfirm>,
                  ]}
                >
                  <List.Item.Meta
                    avatar={<Avatar icon={<UserOutlined />} />}
                    title={
                      <Tooltip
                        title={translate(
                          'GroupsDetails.viewContributorTooltip',
                        )}
                      >
                        {item.user.contact && (
                          <Link
                            to={`/colaboradores/visualizar/colaborador/${item.user.contact?.collaborator?.id}/contato/${item.user.contact?.id}`}
                          >
                            {item.user?.contact.name}
                          </Link>
                        )}
                      </Tooltip>
                    }
                  />
                </List.Item>
              )}
            />
          </Members>
        </Card>
      </Col>
    </Row>
  )
}

export default DetailsGroup
