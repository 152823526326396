/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prefer-promise-reject-errors */
import { Button, Col, Form, Input, message, Row, Select, Tour } from 'antd'
import { ValidateStatus } from 'antd/lib/form/FormItem'
import { LatLngExpression } from 'leaflet'
import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { MapContainer, Marker, TileLayer, useMap } from 'react-leaflet'
import cep from 'cep-promise'
import geolocationApi from '~/services/geolocationApi'

import { translate } from '~/utils/locale'
import { useTaskCreate } from '~/hooks/Tasks/Create/createTask'

import { useTaskTour } from '~/hooks/Tour/useTaskTour'
import { useTour } from '~/hooks/Tour/useTour'
import { useTaskAddressFieldsCreate } from '~/hooks/Tasks/Create/Fields/useFieldsTaskAddress'
import { useListClients } from '~/hooks/Clients/useListClients'

interface AddressProps {
  id: string
  type: string
  address: {
    zip_code: string
    street: string
    number: string
    complement: string
    neighborhood: string
    city: string
    id: string
    state: string
    location: { x: number; y: number }
  }
}

interface MapUpdateFunctionProps {
  center: LatLngExpression
  zoom: number
}

interface Teste {
  form: any
}

const AddressClientCreateTask: React.FC<Teste> = ({ form }) => {
  const {
    setNumber,
    setZipcode,
    city,
    setCity,
    state,
    setState,
    street,
    setStreet,
    neighborhood,
    setNeighborhood,
    complement,
    setComplement,
    longitude,
    setLongitude,
    latitude,
    setLatitude,
  } = useTaskAddressFieldsCreate()

  const { clientData } = useTaskCreate()

  const {
    createCEPTaskRef,
    createNumberTaskRef,
    openAddressTask,
    setOpenAddressTask,
    createAddressSteps,
  } = useTaskTour()

  const [mapValidate, setMapValidate] = useState(false)
  const [status, setStatus] = useState('' as ValidateStatus)
  const [zoomLevel, setZoomLevel] = useState(3)

  const [addressInfo, setAddressInfo] = useState<AddressProps>()
  const { clients } = useListClients()

  const [hidden, setHidden] = useState(true)

  const { setIsTour } = useTour()

  function onClose() {
    setIsTour(false)
    setOpenAddressTask(false)
  }

  const formatComma = (value: string, field: 'latitude' | 'longitude') => {
    const valueFormatted = value.replace(/,/g, '.')

    if (
      valueFormatted !== '-' &&
      valueFormatted !== '.' &&
      valueFormatted !== '-.'
    ) {
      if (field === 'latitude') {
        setLatitude(valueFormatted)
      } else if (field === 'longitude') {
        setLongitude(valueFormatted)
      }
    }
  }

  const addressClient = clients?.find((client) => client.id === clientData?.id)

  function handleAddress(value: any) {
    setAddressInfo(
      addressClient?.contact.contactAddresses.find((item) => item.id === value),
    )
    setMapValidate(true)

    return addressClient?.contact.contactAddresses.find(
      (item) => item.id === value,
    )
  }

  useEffect(() => {
    form.setFieldsValue({
      zip_code: addressInfo?.address.zip_code,
      state: addressInfo?.address.state,
      city: addressInfo?.address.city,
      neighborhood: addressInfo?.address.neighborhood,
      street: addressInfo?.address.street,
      number: addressInfo?.address.number,
      complement: addressInfo?.address.complement,
    })

    setZipcode(addressInfo?.address.zip_code)
    setState(addressInfo?.address.state)
    setCity(addressInfo?.address.city)
    setNeighborhood(addressInfo?.address.neighborhood)
    setStreet(addressInfo?.address.street)
    setNumber(addressInfo?.address.number)
    setComplement(addressInfo?.address.complement)

    if (
      addressInfo?.address.location?.x !== undefined &&
      addressInfo?.address.location?.y !== undefined
    ) {
      setLatitude(addressInfo?.address.location?.x)

      form.setFieldsValue({
        latitude: addressInfo?.address.location.x,
        longitude: addressInfo?.address.location.y,
      })

      setStatus('success')
      setMapValidate(true)
    }
  }, [form, addressInfo])

  const stateOptions = [
    { label: 'Acre', value: 'AC' },
    { label: 'Alagoas', value: 'AL' },
    { label: 'Amapá', value: 'AP' },
    { label: 'Amazonas', value: 'AM' },
    { label: 'Bahia', value: 'BA' },
    { label: 'Ceará', value: 'CE' },
    { label: 'Espírito Santo', value: 'ES' },
    { label: 'Goiás', value: 'GO' },
    { label: 'Maranhão', value: 'MA' },
    { label: 'Mato Grosso', value: 'MT' },
    { label: 'Mato Grosso do Sul', value: 'MS' },
    { label: 'Minas Gerais', value: 'MG' },
    { label: 'Pará', value: 'PA' },
    { label: 'Paraíba', value: 'PB' },
    { label: 'Paraná', value: 'PR' },
    { label: 'Pernambuco', value: 'PE' },
    { label: 'Piauí', value: 'PI' },
    { label: 'Rio de Janeiro', value: 'RJ' },
    { label: 'Rio Grande do Norte', value: 'RN' },
    { label: 'Rio Grande do Sul', value: 'RS' },
    { label: 'Rondônia', value: 'RO' },
    { label: 'Roraima', value: 'RR' },
    { label: 'Santa Catarina', value: 'SC' },
    { label: 'São Paulo', value: 'SP' },
    { label: 'Sergipe', value: 'SE' },
    { label: 'Tocantins', value: 'TO' },
    { label: 'Distrito Federal', value: 'DF' },
  ]

  const searchCEP = useCallback(
    async (address: string) => {
      try {
        setStatus('validating')
        const cepInfo = await cep(address)

        form.setFieldsValue({
          state: cepInfo.state,
          city: cepInfo.city,
          neighborhood: cepInfo.neighborhood,
          street: cepInfo.street,
        })

        setState(cepInfo.state)
        setCity(cepInfo.city)
        setStreet(cepInfo.street)
        setNeighborhood(cepInfo.neighborhood)

        setStatus('success')
      } catch (error) {
        message.error(translate('TasksCreateAddress.zipcodeSearchErrorMessage'))
        form.resetFields()
        setState('')
        setCity('')
        setStreet('')
        setStatus('error')
      }
    },
    [form],
  )

  useEffect(() => {
    const loadMap = async () => {
      if (addressInfo) {
        try {
          const params = {
            street: addressInfo?.address.street,
            city: addressInfo?.address.city,
            state: addressInfo?.address.state,
            format: 'json',
          }

          const response = await geolocationApi.get('search', { params })

          if (response.data.length > 0) {
            setHidden(true)
            form.setFieldsValue({
              latitude: response.data[0].lat,
              longitude: response.data[0].lon,
            })

            setLatitude(response.data[0].lat)
            setLongitude(response.data[0].lon)

            setZoomLevel(13)
          } else {
            setHidden(false)

            form.setFieldsValue({
              latitude: '',
              longitude: '',
            })

            setZoomLevel(3)
          }

          setMapValidate(true)
        } catch (error) {
          // message.error(translate('TasksCreateAddress.geolocationErrorMessage'))
          setMapValidate(true)
        }
      }
    }

    loadMap()
  }, [addressInfo, form])

  function ChangeView({ center, zoom }: MapUpdateFunctionProps) {
    const map = useMap()
    map.setView(center, zoom)
    return null
  }

  // function SaveAddressTaskOnClient() {
  //   const address = {
  //     zip_code: form.getFieldValue('zip_code').replace(/[^0-9]/g, ''),
  //     street: form.getFieldValue('street'),
  //     number: form.getFieldValue('number'),
  //     complement: form.getFieldValue('complement'),
  //     neighborhood: form.getFieldValue('neighborhood'),
  //     city: form.getFieldValue('city'),
  //     state: form.getFieldValue('state'),
  //     location: `(${form.getFieldValue('latitude')}, ${form.getFieldValue(
  //       'longitude',
  //     )})`,
  //   }

  //   createAddressTaskOnClient(address, clientData?.contactId)
  // }

  return (
    <>
      <Row gutter={14}>
        <Col span={12}>
          <Form.Item label={translate('TasksCreateAddress.selectAddressLabel')}>
            <Select
              placeholder={translate(
                'TasksCreateAddress.selectAddressPlaceholder',
              )}
              onChange={handleAddress}
            >
              {addressClient?.contact.contactAddresses?.map((address) => (
                <Fragment key={address.id}>
                  {address.type === 'business' ? (
                    <Select.Option key={address.id} value={address.id}>
                      {translate('TasksCreateAddress.businessAddressText')}
                    </Select.Option>
                  ) : (
                    ''
                  )}

                  {address.type === 'personal' ? (
                    <Select.Option key={address.id} value={address.id}>
                      {translate('TasksCreateAddress.personalAddressText')}
                    </Select.Option>
                  ) : (
                    ''
                  )}

                  {address.type === 'billing' ? (
                    <Select.Option key={address.id} value={address.id}>
                      {translate('TasksCreateAddress.billingAddressText')}
                    </Select.Option>
                  ) : (
                    ''
                  )}
                </Fragment>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={14}>
        <Col span={8} ref={createCEPTaskRef}>
          <Form.Item
            label={translate('TasksCreateAddress.zipcodeLabel')}
            name="zip_code"
            hasFeedback
            validateStatus={status}
          >
            <Input
              placeholder="00000-000"
              onChange={(e) => {
                const cepInput = e.target.value.replace(/\D/g, '')
                if (cepInput.length === 8) {
                  searchCEP(e.target.value)
                  setZipcode(e.target.value)
                }
              }}
              disabled
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            label={translate('TasksCreateAddress.stateLabel')}
            name="state"
          >
            <Select
              style={{ width: '100%' }}
              placeholder={translate('TasksCreateAddress.statePlaceholder')}
              onChange={(e) => setState(String(e))}
              className="menu_input"
              options={stateOptions}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={translate('TasksCreateAddress.cityLabel')}
            name="city"
          >
            <Input
              placeholder={translate('TasksCreateAddress.cityPlaceholder')}
              onChange={(e) => setCity(e.target.value)}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={14}>
        <Col span={8}>
          <Form.Item
            label={translate('TasksCreateAddress.neighborhoodLabel')}
            name="neighborhood"
          >
            <Input
              placeholder={translate(
                'TasksCreateAddress.neighborhoodPlaceholder',
              )}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={translate('TasksCreateAddress.streetLabel')}
            name="street"
          >
            <Input
              placeholder={translate('TasksCreateAddress.streetPlaceholder')}
              onChange={(e) => setStreet(e.target.value)}
            />
          </Form.Item>
        </Col>
        <Col span={8} ref={createNumberTaskRef}>
          <Form.Item
            label={translate('TasksCreateAddress.numberLabel')}
            name="number"
            rules={[
              {
                required: true,
                message: translate('TasksCreateAddress.numberRule'),
              },
            ]}
          >
            <Input
              onChange={(e) => setNumber(e.target.value)}
              placeholder={translate('TasksCreateAddress.numberPlaceholder')}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={14}>
        <Col span={4.8}>
          <Form.Item
            label={translate('TasksCreateAddress.complementLabel')}
            name="complement"
          >
            <Input
              placeholder={translate(
                'TasksCreateAddress.complementPlaceholder',
              )}
              value={complement}
              onChange={(e) => setComplement(e.target.value)}
            />
          </Form.Item>
        </Col>

        <Col span={4.8}>
          <Form.Item
            hidden={hidden}
            label={translate('TasksCreateAddress.latitudeLabel')}
            name="latitude"
            initialValue={latitude}
            rules={[
              {
                required: !hidden,
                message: translate('TasksCreateAddress.latitudeRule'),
              },
            ]}
          >
            <Input onChange={(e) => formatComma(e.target.value, 'latitude')} />
          </Form.Item>
        </Col>
        <Col span={4.8}>
          <Form.Item
            hidden={hidden}
            label={translate('TasksCreateAddress.longitudeLabel')}
            name="longitude"
            initialValue={longitude}
            rules={[
              {
                required: !hidden,
                message: translate('TasksCreateAddress.longitudeRule'),
              },
            ]}
          >
            <Input onChange={(e) => formatComma(e.target.value, 'longitude')} />
          </Form.Item>
        </Col>
        <Col span={4.8}>
          <a
            href="https://www.latlong.net/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Form.Item
              label={translate('TasksCreateAddress.coordinateLabel')}
              hidden={hidden}
            >
              <Button type="primary">
                {translate('TasksCreateAddress.coordinateButton')}
              </Button>
            </Form.Item>
          </a>
        </Col>
      </Row>

      <MapContainer
        center={[latitude, longitude]}
        zoom={zoomLevel}
        scrollWheelZoom={false}
        style={{ height: '350px', width: '100%' }}
      >
        <ChangeView center={[latitude, longitude]} zoom={zoomLevel} />
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {latitude !== -14.235004 && longitude !== -51.925282 && (
          <Marker position={[latitude, longitude]} />
        )}
      </MapContainer>

      <Tour
        open={openAddressTask}
        onClose={onClose}
        steps={createAddressSteps}
        indicatorsRender={(current, total) => (
          <span>
            {current + 1} / {total}
          </span>
        )}
      />
    </>
  )
}

export default AddressClientCreateTask
