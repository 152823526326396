/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable func-names */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react/no-this-in-sfc */
import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import { FiMail, FiLogIn } from 'react-icons/fi'

import { message, Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

import * as styles from './styles'

import api from '~/services/api'

import { useAuth } from '~/hooks/Auth/AuthContext'
import { useLocale } from '~/hooks/locale/useLocale'

import logoImg from '~/assets/logo_icon.svg'

import { ShowError } from '~/utils/errors/apiErrors'
import { translate } from '~/utils/locale'

interface WithTokenProps {
  status: string
}

interface PageStates {
  [key: string]: ReactElement
}

const ConfirmPageWithToken: React.FC<WithTokenProps> = ({ status }) => {
  const { user } = useAuth()
  const { locale } = useLocale()
  const history = useHistory()
  const [timer, setTimer] = useState(10)

  const spinIcon = (
    <LoadingOutlined style={{ fontSize: 64, marginTop: 20 }} spin />
  )

  useEffect(() => {
    ;(async () => {
      if (status === 'success' && timer === 0) {
        history.go(0)
      } else {
        setTimeout(function () {
          setTimer(timer - 1)
        }, 1000)
      }
    })()
  }, [timer, history, status])

  const handleReSendCode = useCallback(async () => {
    try {
      await api.post('user/confirm/update-token', { user_id: user.id })
      message.success(
        `${translate('ConfirmPageWithToken.successMessage')} ${user.email}`,
      )
    } catch (err: any) {
      ShowError(
        err.message,
        translate('ConfirmPageWithToken.errorMessage'),
        locale,
      )
    }
  }, [user, locale])

  const handleSuccess = useCallback(() => {
    history.go(0)
  }, [history])

  const PAGE_STATES: PageStates = {
    loading: (
      <>
        <h1>{translate('ConfirmPageWithToken.loadingTitle')}</h1>
        <Spin indicator={spinIcon} />
      </>
    ),
    success: (
      <>
        <h1>{translate('ConfirmPageWithToken.successTitle')}</h1>
        <p>
          {translate('ConfirmPageWithToken.successParagraphOne')}
          <br />
          {translate('ConfirmPageWithToken.successParagraphTwo')}
          {` ${timer} `}
          {translate('ConfirmPageWithToken.successParagraphThree')}
        </p>

        <button
          data-testid="button-dashboard"
          type="submit"
          onClick={handleSuccess}
        >
          <FiLogIn />
          {translate('ConfirmPageWithToken.successButtonText')}
        </button>
      </>
    ),
    fail: (
      <>
        <h1>{translate('ConfirmPageWithToken.failTitle')}</h1>
        <p>
          {translate('ConfirmPageWithToken.failParagraphOne')}
          <br />
          {translate('ConfirmPageWithToken.failParagraphTwo')}
          <b>{translate('ConfirmPageWithToken.failParagraphThree')}</b>
          {translate('ConfirmPageWithToken.failParagraphFour')}
        </p>

        <button
          data-testid="button-email"
          type="submit"
          onClick={handleReSendCode}
        >
          <FiMail />
          {translate('ConfirmPageWithToken.failButtonText')}
        </button>
      </>
    ),
  }

  return (
    <>
      <Helmet>
        <title>{translate('ConfirmPageWithToken.helmetTitle')}</title>
      </Helmet>
      <styles.Content>
        <styles.AnimationContainer>
          <img src={logoImg} alt="Gstor" />
          {PAGE_STATES[status]}
        </styles.AnimationContainer>
      </styles.Content>

      <styles.Background />
    </>
  )
}

export default ConfirmPageWithToken
