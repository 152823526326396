export default {
  pt: {
    TasksBoardsKanban: {
      cardTitle: 'Kanban de Tarefas',
      totalText: 'Total de',
      taskText: 'tarefa',
      tasksText: 'tarefas',
      completeOption: 'Visualização completa',
      resumeOption: 'Visualização resumida',
      createButton: 'Criar Tarefa',
    },
  },
  en: {
    TasksBoardsKanban: {
      cardTitle: 'Task Kanban',
      totalText: 'Total of',
      taskText: 'task',
      tasksText: 'tasks',
      completeOption: 'Full View',
      resumeOption: 'Summary View',
      createButton: 'Create Task',
    },
  },
  es: {
    TasksBoardsKanban: {
      cardTitle: 'Tarea Kanban',
      totalText: 'Total de',
      taskText: 'tarea',
      tasksText: 'tareas',
      completeOption: 'Vista completa',
      resumeOption: 'Vista de resumen',
      createButton: 'Crear Tarea',
    },
  },
}
