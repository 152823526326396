import { lighten } from 'polished'
import styled from 'styled-components'

export const Container = styled.div`
  .btn_equipment {
    padding: 0 8px 0 8px;
    margin-left: 8px;
    border-radius: 2px;
    background: var(--primary-color);
    color: var(--color-white);
    transition: 0.2s ease;

    &:hover {
      background: ${lighten(0.1, '#3C8ACB')};
    }
  }
`
