export default {
  pt: {
    ScalesTimeAddModal: {
      successMessage: 'Horário criado com sucesso!',
      errorMessage: 'Erro ao realizar cadastro da escala',
      addButton: 'Adicionar Horários',
      modalTitle: 'Adicionar Horários',
      okText: 'Adicionar',
      keepAddText: 'Continuar adicionando horários a escala?',
      cancelButton: 'Cancelar',
      progressionText: 'Criando horários',
      addText: 'Adicionar',
      dayLabel: 'Dia',
      dayRule: 'Por favor selecione um dia',
      typeLabel: 'Tipo de horário',
      typeRule: 'Por favor selecione um tipo horário',
      typeWork: 'Trabalho',
      typeBreak: 'Pausa',
      checkinLabel: 'Hora de entrada',
      checkinRule: 'Por favor insira a hora de entrada',
      checkoutLabel: 'Hora de saída',
      checkoutRuleOne: 'Por favor insira a hora de saída',
      checkoutRuleTwo:
        'O horário de saída precisa ser mais tarde que o de entrada!',
      repeatLabel: 'Se repete?',
      repeatNever: 'Nunca',
      repeatWeekly: 'Por semana',
      repeatMonthly: 'Por mês',
      repeatAmountLabel: 'Se repete?',
      weekdaysLabel: 'Dias da semana',
      descriptionLabel: 'Descrição',
    },
  },
  en: {
    ScalesTimeAddModal: {
      successMessage: 'Schedule created successfully!',
      errorMessage: 'Error when registering the scale',
      addButton: 'Add Schedules',
      modalTitle: 'Add Schedules',
      okText: 'Add',
      keepAddText: 'Continue adding schedules to the scale?',
      cancelButton: 'Cancel',
      progressionText: 'Creating Schedules',
      addText: 'Add',
      dayLabel: 'Day',
      dayRule: 'Please select a day',
      typeLabel: 'Time Type',
      typeRule: 'Please select a time type',
      typeWork: 'Work',
      typeBreak: 'Break',
      checkinLabel: 'Check-in',
      checkinRule: 'Please enter check-in time',
      checkoutLabel: 'Check-out',
      checkoutRuleOne: 'Please enter check-out time',
      checkoutRuleTwo: 'Check-out time must be later than check-in time!',
      repeatLabel: 'Repeats?',
      repeatNever: 'Never',
      repeatWeekly: 'Per Week',
      repeatMonthly: 'Per Month',
      repeatAmountLabel: 'Repeats?',
      weekdaysLabel: 'Days of the week',
      descriptionLabel: 'Description',
    },
  },
  es: {
    ScalesTimeAddModal: {
      successMessage: '¡Horario creado con éxito!',
      errorMessage: 'Error al registrar la escala',
      addButton: 'Agregar Horarios',
      modalTitle: 'Agregar Horarios',
      okText: 'Agregar',
      keepAddText: '¿Continuar agregando horarios a la escala?',
      cancelButton: 'Cancelar',
      progressionText: 'Creando horarios',
      addText: 'Agregar',
      dayLabel: 'Día',
      dayRule: 'Por favor seleccione un día',
      typeLabel: 'Tipo de Tiempo',
      typeRule: 'Por favor seleccione un tipo de tiempo',
      typeWork: 'Trabajo',
      typeBreak: 'Descanso',
      checkinLabel: 'Hora de entrada',
      checkinRule: 'Por favor, introduzca la hora de entrada',
      checkoutLabel: 'Hora de salida',
      checkoutRuleOne: 'Por favor, introduzca la hora de salida',
      checkoutRuleTwo:
        '¡La hora de salida debe ser posterior a la hora de entrada!',
      repeatLabel: '¿Se repite?',
      repeatNever: 'Nunca',
      repeatWeekly: 'Por semana',
      repeatMonthly: 'Por mes',
      repeatAmountLabel: '¿Se repite?',
      weekdaysLabel: 'Dias de la semana',
      descriptionLabel: 'Descripción',
    },
  },
}
