import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const LeftContent = styled.div`
  display: flex;
`

export const CenterContent = styled.div`
  display: flex;
`

export const RightContent = styled.div`
  display: flex;
`
