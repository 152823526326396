export default {
  pt: {
    Scales: {
      helmetTitle: 'Escalas',
    },
  },
  en: {
    Scales: {
      helmetTitle: 'Scales',
    },
  },
  es: {
    Scales: {
      helmetTitle: 'Escala',
    },
  },
}
