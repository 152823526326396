export default {
  pt: {
    ContributorTabs: {
      pageheaderNewTitle: 'Novo Colaborador',
      pageheaderEditTitle: 'Editar Colaborador',
      pageheaderViewTitle: 'Visualizar Colaborador',
      copyTooltip: 'Copiar link para Área de tranferência',
      copyMessage: 'Link copiado para a Área de tranferência com sucesso.',
      newTaskButton: 'Criar nova tarefa',
      seeTaskButton: 'Ver tarefas',
      seeClientsButton: 'Ver Clientes',
      detailsTab: 'Detalhes',
      backTooltip: 'Voltar',
      statusCardTitle: 'Status do colaborador',
      evaluationTab: 'Avaliações de Atendimento',
      evaluationCardTitle: 'Avaliações de Atendimento',
      evaluationCardHelp:
        'Avaliação realizada através de pesquisa com quem recebeu atendimento do colaborador. O valor exibido é a média de todas as avaliações realizadas para o respectivo colaborador.',
      viewEvaluationButton: 'Visualizar Avaliações',
      internalEvaluationCardTitle: 'Avaliação Interna',
      internalEvaluationCardHelp:
        'Se trata de uma avaliação interna, ou seja, é o que foi definido pelo usuário que gerencia a conta do Gstor. O valor exibido é o valor definido pelo gestor da conta.',
      addressTab: 'Endereços',
      addressCardTitle: 'Endereços',
      documentsTab: 'Documentos/Anexos',
      documentsCardTitle: 'Documentos/Anexos',
      skillsTab: 'Habilidades',
      skillsCardTitle: 'Skills - Habilidades',
      badgeTab: 'Crachá',
      badgeCardTitle: 'Crachá',
    },
  },
  en: {
    ContributorTabs: {
      pageheaderNewTitle: 'New Contributor',
      pageheaderEditTitle: 'Edit Contributor',
      pageheaderViewTitle: 'View Contributor',
      copyTooltip: 'Copy Link to Clipboard',
      copyMessage: 'Link successfully copied to clipboard.',
      newTaskButton: 'Create new task',
      seeTaskButton: 'View tasks',
      seeClientsButton: 'View Customers',
      detailsTab: 'Details',
      backTooltip: 'Back',
      statusCardTitle: 'Contributor Status',
      evaluationCardHelp:
        'Evaluation carried out through a survey with those who received assistance from the employee. The displayed value is the average of all evaluations performed for the respective employee.',
      evaluationTab: 'Service Ratings',
      evaluationCardTitle: 'Service Ratings',
      internalEvaluationCardHelp:
        'This is an internal assessment, that is, it is defined by the user who manages the Gstor account. The displayed value is the value defined by the account manager.',
      viewEvaluationButton: 'View Reviews',
      internalEvaluationCardTitle: 'Internal Rating',
      addressTab: 'Addresses',
      addressCardTitle: 'Addresses',
      documentsTab: 'Documents/Attachments',
      documentsCardTitle: 'Documents/Attachments',
      skillsTab: 'Skills',
      skillsCardTitle: 'Skills',
      badgeTab: 'Badge',
      badgeCardTitle: 'Badge',
    },
  },
  es: {
    ContributorTabs: {
      pageheaderNewTitle: 'Nuevo Colaborador',
      pageheaderEditTitle: 'Editar Colaborador',
      pageheaderViewTitle: 'Ver Colaborador',
      copyTooltip: 'Copiar enlace al portapapeles',
      copyMessage: 'Enlace copiado con éxito al portapapeles.',
      newTaskButton: 'Crear nueva Tarea',
      seeTaskButton: 'Ver tareas',
      seeClientsButton: 'Ver Clientes',
      detailsTab: 'Detalles',
      backTooltip: 'Regresar',
      statusCardTitle: 'Status de Colaborador',
      evaluationTab: 'Calificaciones de servicio',
      evaluationCardTitle: 'Calificaciones de servicio',
      evaluationCardHelp:
        'Evaluación realizada a través de una encuesta con quienes recibieron asistencia del empleado. El valor mostrado es el promedio de todas las evaluaciones realizadas para el empleado respectivo.',
      viewEvaluationButton: 'Ver reseñas',
      internalEvaluationCardTitle: 'Evaluación Interna',
      internalEvaluationCardHelp:
        'Esta es una evaluación interna, es decir, la define el usuario que administra la cuenta de Gstor. El valor mostrado es el valor definido por el administrador de la cuenta.',
      addressTab: 'Direcciones',
      addressCardTitle: 'Direcciones',
      documentsTab: 'Documentos/Anexos',
      documentsCardTitle: 'Documentos/Anexos',
      skillsTab: 'Habilidades',
      skillsCardTitle: 'Skills - Habilidades',
      badgeTab: 'Crachá',
      badgeCardTitle: 'Crachá',
    },
  },
}
