export default {
  pt: {
    DashboardCards: {
      viewContributorsTooltip: 'Ver colaboradores',
      viewContributorsTitle: 'Ver Colaboradores',
      contributorText: 'Colaborador',
      contributorsText: 'Colaboradores',
      totalText: 'Total',
      viewStatusTooltip: 'Ver status',
      enabledTitle: 'Habilitados',
      enabledWithRestrictionsTitle: 'Habilitados com Restrições',
      blockedTitle: 'Bloqueados',
    },
  },
  en: {
    DashboardCards: {
      viewContributorsTooltip: 'View Collaborators',
      viewContributorsTitle: 'View Collaborators',
      contributorText: 'Collaborator',
      contributorsText: 'Collaborators',
      totalText: 'Total',
      viewStatusTooltip: 'View status',
      enabledTitle: 'Enabled',
      enabledWithRestrictionsTitle: 'Enabled with Restrictions',
      blockedTitle: 'Blocked',
    },
  },
  es: {
    DashboardCards: {
      viewContributorsTooltip: 'Ver Colaboradores',
      viewContributorsTitle: 'Ver Colaboradores',
      contributorText: 'Colaborador',
      contributorsText: 'Colaboradores',
      totalText: 'Total',
      viewStatusTooltip: 'Ver status',
      enabledTitle: 'Habilitados',
      enabledWithRestrictionsTitle: 'Habilitado con Restricciones',
      blockedTitle: 'Bloqueados',
    },
  },
}
