export default {
  pt: {
    TasksFilter: {
      statusReceived: 'Tarefa Enviada',
      statusViewed: 'Tarefa Visualizada',
      statusEnRoute: 'Tarefa a caminho',
      statusBreak: 'Tarefa Pausada',
      statusCanceled: 'Tarefa Cancelada',
      statusFinished: 'Tarefa Finalizada',
      statusWorking: 'Tarefa sendo realizada',
      filterTooltip: 'Limpar Filtro',
      filterAppliedText: 'Filtro Aplicado',
      filterText: 'Filtros',
      drawerTitle: 'Filtros de Tarefas',
      fromDateLabel: 'Data Inicial',
      fromDatePlaceholder: 'Informe a data de criação',
      toDateLabel: 'Data Final',
      toDatePlaceholder: 'Informe a data final',
      contributorLabel: 'Colaborador',
      contributorPlaceholder: 'Selecione o colaborador',
      teamLabel: 'Equipe',
      teamPlaceholder: 'Selecione a equipe',
      clientLabel: 'Clientes',
      clientPlaceholder: 'Selecione o cliente',
      statusLabel: 'Status Atual',
      statusPlaceholder: 'Informe o status',
      equipmentLabel: 'Equipamento',
      equipmentPlaceholder: 'Selecione o equipamento',
      taskTypeLabel: 'Tipo de Tarefa',
      taskTypePlaceholder: 'Selecione o tipo de tarefa',
      priorityLabel: 'Prioridade',
      priorityPlaceholder: 'Selecione a prioridade',
      priorityHigh: 'Alta',
      priorityMedium: 'Média',
      priorityLow: 'Baixa',
      addressLabel: 'Endereço',
      addressPlaceholder: 'Informe o endereço',
      internalCodeLabel: 'Código Interno',
      internalCodePlaceholder: 'Informe o código interno',
      externalCodeLabel: 'Código Externo',
      externalCodePlaceholder: 'Informe o código externo',
      noteLabel: 'Observação',
      notePlaceholder: 'Informe a anotação',
      applyButton: 'Aplicar',
      cleanButton: 'Limpar',
    },
  },
  en: {
    TasksFilter: {
      statusReceived: 'Task Submitted',
      statusViewed: 'Task Viewed',
      statusEnRoute: 'Task on the way',
      statusBreak: 'Paused Task',
      statusCanceled: 'Canceled Task',
      statusFinished: 'Finished Task',
      statusWorking: 'Task being performed',
      filterTooltip: 'Clear filter',
      filterAppliedText: 'Filter Applied',
      filterText: 'Filters',
      drawerTitle: 'Task Filters',
      fromDateLabel: 'Initial date',
      fromDatePlaceholder: 'Enter the creation date',
      toDateLabel: 'Final date',
      toDatePlaceholder: 'Enter the end date',
      contributorLabel: 'Contributor',
      contributorPlaceholder: 'Select the contributor',
      teamLabel: 'Team',
      teamPlaceholder: 'Select the team',
      clientLabel: 'Customers',
      clientPlaceholder: 'Select customer',
      statusLabel: 'Current status',
      statusPlaceholder: 'Report the status',
      equipmentLabel: 'Equipment',
      equipmentPlaceholder: 'Select equipment',
      taskTypeLabel: 'Task Type',
      taskTypePlaceholder: 'Select task type',
      priorityLabel: 'Priority',
      priorityPlaceholder: 'Select priority',
      priorityHigh: 'High',
      priorityMedium: 'Medium',
      priorityLow: 'Low',
      addressLabel: 'Address',
      addressPlaceholder: 'Inform the address',
      internalCodeLabel: 'Internal code',
      internalCodePlaceholder: 'Enter the internal code',
      externalCodeLabel: 'External code',
      externalCodePlaceholder: 'Enter the external code',
      noteLabel: 'Observation',
      notePlaceholder: 'Please provide observation',
      applyButton: 'Apply',
      cleanButton: 'Clear',
    },
  },
  es: {
    TasksFilter: {
      statusReceived: 'Tarea Enviada',
      statusViewed: 'Tarea Vista',
      statusEnRoute: 'Tarea en camino',
      statusBreak: 'Tarea en Pausa',
      statusCanceled: 'Tarea Cancelada',
      statusFinished: 'Tarea Terminada',
      statusWorking: 'Tarea que se está realizando',
      filterTooltip: 'Filtro Claro',
      filterAppliedText: 'Filtro Aplicado',
      filterText: 'Filtros',
      drawerTitle: 'Filtros de Tareas',
      fromDateLabel: 'La fecha de inicio',
      fromDatePlaceholder: 'Introduce la fecha de creación',
      toDateLabel: 'Fecha final',
      toDatePlaceholder: 'Introduce la fecha de finalización',
      contributorLabel: 'Colaborador',
      contributorPlaceholder: 'Seleccione el colaborador',
      teamLabel: 'Equipo',
      teamPlaceholder: 'Seleccione el equipo',
      clientLabel: 'Clientes',
      clientPlaceholder: 'Seleccionar Cliente',
      statusLabel: 'Status Actual',
      statusPlaceholder: 'Informe el status',
      equipmentLabel: 'Equipamiento',
      equipmentPlaceholder: 'Seleccionar equipamiento',
      taskTypeLabel: 'Tipo de Tarea',
      taskTypePlaceholder: 'Seleccionar tipo de tarea',
      priorityLabel: 'Prioridad',
      priorityPlaceholder: 'Seleccionar prioridad',
      priorityHigh: 'Alta',
      priorityMedium: 'Media',
      priorityLow: 'Baja',
      addressLabel: 'Dirección',
      addressPlaceholder: 'Informar la dirección',
      internalCodeLabel: 'Código Interno',
      internalCodePlaceholder: 'Introduce el código interno',
      externalCodeLabel: 'Código Externo',
      externalCodePlaceholder: 'Introduce el código externo',
      noteLabel: 'Observación',
      notePlaceholder: 'Introduce la observación',
      applyButton: 'Aplicar',
      cleanButton: 'Limpio',
    },
  },
}
