/* eslint-disable no-nested-ternary */
import styled from 'styled-components'

interface ContainerProps {
  path: string
  ticketId: string
  serviceStatus:
    | {
        newTask: boolean
        executionTask: boolean
      }
    | undefined
}

export const Container = styled.div<ContainerProps>`
  .site-layout-header {
    background: ${(props) =>
      props.serviceStatus?.executionTask === false ||
      props.serviceStatus?.newTask === false
        ? '#FF4D4F'
        : props.path === '/helpdesk' ||
            props.path === '/ticket' ||
            props.path === '/novoTicket' ||
            props.path === `/ticket/${props.ticketId}`
          ? '#1a4171'
          : 'white'};
    padding: 0 1rem;
    height: 55px;
    line-height: 55px;
    position: sticky;
    top: 0;
    z-index: 100;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 0 0 0.3rem 0.3rem;
  }

  .site-layout-content {
    padding: 0.5rem 0.3rem;
    margin: 0.3rem;
  }

  .site-layout {
    min-height: 100vh;
  }

  .ant-btn-icon-only.ant-btn-lg {
    line-height: 0px;
  }
`
