export default {
  pt: {
    SkillSkillsTable: {
      nameColumn: 'Nome',
      descriptionColumn: 'Descrição',
      removeColumn: 'Remover',
      deletePopconfirm: 'Deseja mesmo deletar a habilidade?',
      deleteTooltip: 'Deletar',
    },
  },
  en: {
    SkillSkillsTable: {
      nameColumn: 'Name',
      descriptionColumn: 'Description',
      removeColumn: 'Remove',
      deletePopconfirm: 'Do you really want to delete the skill?',
      deleteTooltip: 'Delete',
    },
  },
  es: {
    SkillSkillsTable: {
      nameColumn: 'Nombre',
      descriptionColumn: 'Descripción',
      removeColumn: 'Eliminar',
      deletePopconfirm: '¿Realmente quieres eliminar la habilidad?',
      deleteTooltip: 'Borrar',
    },
  },
}
