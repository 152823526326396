import {
  LoadingOutlined,
  PlusCircleOutlined,
  QuestionOutlined,
} from '@ant-design/icons'
import { Card, Space, Button, Tour, Tooltip } from 'antd'
import Text from 'antd/lib/typography/Text'
import React from 'react'
import { Link } from 'react-router-dom'
import { useTaskType } from '~/hooks/Tasks/TaskType/useTaskType'
import { translate } from '~/utils/locale'
import TaskTypeFilterModal from './Filter'
import TypeTasksTable from './Table'

import { useTaskTypeTour } from '~/hooks/Tour/useTaskTypeTour'
import { useTour } from '~/hooks/Tour/useTour'

const TypeTasksBoard: React.FC = () => {
  const { isLoading, isFetching, tasksTypeTotal } = useTaskType()
  const {
    createTypeTaskRef,
    taskTypeSteps,
    openListTaskType,
    setOpenListTaskType,
    listTaskTypeSteps,
  } = useTaskTypeTour()

  const { teste, setTeste, isTour, setIsTour } = useTour()

  function onClose() {
    setTeste(false)
    setIsTour(false)
  }

  return (
    <Card
      title={
        <Space size="large">
          <b>{translate('TasksRegistrationTypeTasks.cardTitle')}</b>
          {isLoading ? (
            <LoadingOutlined spin />
          ) : (
            <Text
              type="secondary"
              style={{ fontWeight: 'normal', fontSize: 14 }}
            >
              {tasksTypeTotal !== undefined ? (
                <>
                  {tasksTypeTotal === 1
                    ? `${translate(
                        'TasksRegistrationTypeTasks.totalText',
                      )} ${tasksTypeTotal} ${translate(
                        'TasksRegistrationTypeTasks.taskTypeText',
                      )}`
                    : `${translate(
                        'TasksRegistrationTypeTasks.totalText',
                      )} ${tasksTypeTotal} ${translate(
                        'TasksRegistrationTypeTasks.tasksTypesText',
                      )}`}
                </>
              ) : (
                ''
              )}
            </Text>
          )}
          {!isLoading && isFetching && <LoadingOutlined spin />}
        </Space>
      }
      extra={
        <Space size="small">
          <Tooltip title="Tour Tarefas">
            <Button
              icon={<QuestionOutlined />}
              shape="circle"
              size="small"
              onClick={() => setTeste(true)}
            />
          </Tooltip>

          <TaskTypeFilterModal />
          <Link to="/tiposDeTarefas/novo">
            <Button
              type="primary"
              icon={<PlusCircleOutlined />}
              ref={createTypeTaskRef}
            >
              {translate('TasksRegistrationTypeTasks.addTaskType')}
            </Button>
          </Link>
        </Space>
      }
      bordered={false}
      style={{ width: '100%', padding: 30 }}
    >
      <TypeTasksTable />

      {openListTaskType ? (
        <>
          {isTour ? (
            <Tour
              open={openListTaskType}
              onClose={() => setOpenListTaskType(false)}
              steps={listTaskTypeSteps}
              indicatorsRender={(current, total) => (
                <span>
                  {current + 1} / {total}
                </span>
              )}
            />
          ) : (
            ''
          )}
        </>
      ) : (
        <Tour
          open={teste}
          onClose={onClose}
          steps={taskTypeSteps}
          indicatorsRender={(current, total) => (
            <span>
              {current + 1} / {total}
            </span>
          )}
        />
      )}
    </Card>
  )
}

export default TypeTasksBoard
