/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  useContext,
  createContext,
  useState,
  useEffect,
  useCallback,
} from 'react'
import { useQuery } from 'react-query'

import { message } from 'antd'

import api from '~/services/api'

import { useCompanies } from '~/hooks/Companies/useCompanies'
import { useLocale } from '~/hooks/locale/useLocale'

import { ShowError } from '~/utils/errors/apiErrors'
import { translate } from '~/utils/locale'

interface AddressProps {
  id: string
  type: string
  address: {
    zip_code: string
    street: string
    number: string
    complement: string
    neighborhood: string
    city: string
    id: string
    state: string
    location: { x: number; y: number }
  }
}

interface ContactProps {
  name: string
  email: string
  id: string
  contactAddresses: AddressProps[]
}

export interface ClientsProps {
  id: string
  contact: ContactProps
  parent_customer?: string
  parent_cnpj?: string
}

interface FiltersId {
  id: string
}

interface FilterOptionsProps {
  contact?: string
  address?: string
  corporate_name?: string
  external_code?: string
  municipal_registration?: string
  state_registration?: string
  segment_id?: string
  collaborator_id?: FiltersId[]
}

interface ListClientsContextData {
  clients?: ClientsProps[]
  // allClients?: ClientsProps[]
  createClients(dataClient: object | undefined): void
  deleteClient(customerId: string): void
  deleteClients(customerId: string): void
  isLoading: boolean
  refetch: () => void
  isFetching: boolean
  // isAllDataLoading: boolean
  // allDataRefetch: () => void
  // isAllDataFetching: boolean
  handleUpdateFilters: (newFilterOptions: any) => void
  handleResetFilters: () => void
  filterOptions: FilterOptionsProps
  setPageSize(value: any): void
  setNumberPage(value: any): void
  clientsTotal?: number
  setMenu(value: string): void
  menu: string
}

const ListClientsContext = createContext<ListClientsContextData>(
  {} as ListClientsContextData,
)

export const ListClientsProvider: React.FC = ({ children }) => {
  const { selectedCompany } = useCompanies()
  const { locale } = useLocale()

  const [pageSize, setPageSize] = useState<number>(10)
  const [numberPage, setNumberPage] = useState<number>(1)

  const [clientsTotal, setClientsTotal] = useState<number>()

  const [menu, setMenu] = useState('')

  const [filterOptions, setFilterOptions] = useState<FilterOptionsProps>(() => {
    const filter = localStorage.getItem('@Gstor:clientsFilterOptions')

    if (filter) {
      return JSON.parse(filter)
    }

    return {} as FilterOptionsProps
  })

  const {
    data: clients,
    isLoading,
    refetch,
    isFetching,
  } = useQuery(
    [
      `lista de clientes${selectedCompany}`,
      selectedCompany,
      numberPage,
      pageSize,
      filterOptions,
    ],

    async () => {
      try {
        if (!selectedCompany) {
          return undefined
        }

        const paginationLimit = numberPage * pageSize
        const paginationOffset = paginationLimit - pageSize

        const response = await api.get(
          `/company/${selectedCompany}/customers`,
          {
            params: {
              offset: paginationOffset,
              limit: paginationLimit,
              contact: filterOptions?.contact,
              address: filterOptions?.address,
              corporate_name: filterOptions?.corporate_name,
              external_code: filterOptions?.external_code,
              municipal_registration: filterOptions?.municipal_registration,
              state_registration: filterOptions?.state_registration,
              segment_id: filterOptions?.segment_id,
              ...(filterOptions?.collaborator_id &&
                filterOptions?.collaborator_id?.join() !== '' && {
                  collaborator_id: filterOptions?.collaborator_id?.join(),
                }),
            },
          },
        )

        const { data } = response

        setClientsTotal(data.total)

        return data.results
      } catch (err: any) {
        ShowError(
          err.message,
          translate('useListClients.getErrorMessage'),
          locale,
        )
      }
    },
    {
      staleTime: 5000,
    },
  )
  // const {
  //   data: allClients,
  //   isLoading: isAllDataLoading,
  //   refetch: allDataRefetch,
  //   isFetching: isAllDataFetching,
  // } = useQuery(
  //   [`lista total de clientes${selectedCompany}`, selectedCompany],

  //   async () => {
  //     try {
  //       if (!selectedCompany) {
  //         return undefined
  //       }

  //       const response = await api.get(
  //         `/company/${selectedCompany}/customers/all`,
  //       )

  //       const { data } = response

  //       return data.results
  //     } catch (err: any) {
  //       ShowError(
  //         err.message,
  //         translate('useListClients.getErrorMessage'),
  //         locale,
  //       )
  //     }
  //   },
  //   {
  //     staleTime: 5000,
  //   },
  // )

  async function createClients(dataClient: object) {
    try {
      await api.post(`/company/${selectedCompany}/customer`, dataClient)

      refetch()
      message.success(translate('useListClients.createSuccessMessage'))
    } catch (err: any) {
      ShowError(
        err.message,
        translate('useListClients.createErrorMessage'),
        locale,
      )
    }
  }

  async function deleteClient(customerId: string) {
    try {
      await api.delete(`/company/${selectedCompany}/customer/${customerId}`)

      refetch()
      message.success(translate('useListClients.deleteSuccessMessage'))
    } catch (err: any) {
      ShowError(
        err.message,
        translate('useListClients.deleteErrorMessage'),
        locale,
      )
    }
  }

  async function deleteClients(customerId: string) {
    try {
      await api.delete(`/company/${selectedCompany}/customer/${customerId}`)

      refetch()
    } catch (err: any) {
      ShowError(
        err.message,
        translate('useListClients.deleteErrorMessage'),
        locale,
      )
    }
  }

  const handleUpdateFilters = useCallback((newFilterOptions) => {
    const newFilters = {
      contact: newFilterOptions?.contact,
      address: newFilterOptions?.address,
      corporate_name: newFilterOptions?.corporate_name,
      external_code: newFilterOptions?.external_code,
      municipal_registration: newFilterOptions?.municipal_registration,
      state_registration: newFilterOptions?.state_registration,
      segment_id: newFilterOptions?.segment_id,
      collaborator_id: newFilterOptions?.collaborator_id,
    }
    setFilterOptions(newFilters)
    localStorage.setItem(
      '@Gstor:clientsFilterOptions',
      JSON.stringify(newFilters),
    )
    // setNumberPage(1);
  }, [])

  const handleResetFilters = useCallback(() => {
    localStorage.removeItem('@Gstor:clientsFilterOptions')
    setFilterOptions({})
    // setNumberPage(1);
  }, [])

  useEffect(() => {
    refetch()
  }, [selectedCompany, refetch])

  return (
    <ListClientsContext.Provider
      value={{
        clients,
        // allClients,
        createClients,
        deleteClient,
        deleteClients,
        isLoading,
        refetch,
        // isAllDataLoading,
        // allDataRefetch,
        handleUpdateFilters,
        handleResetFilters,
        filterOptions,
        isFetching,
        // isAllDataFetching,
        setPageSize,
        setNumberPage,
        clientsTotal,
        setMenu,
        menu,
      }}
    >
      {children}
    </ListClientsContext.Provider>
  )
}

export function useListClients(): ListClientsContextData {
  const context = useContext(ListClientsContext)

  if (!context) {
    throw new Error('useAuth must be used within an AutoProvider')
  }

  return context
}
