export default {
  pt: {
    TasksTabsReport: {
      loadingText: 'Carregando...',
      cardTitle: 'Evidências de Tarefas',
      imageText: 'Imagem',
      videoText: 'Vídeo',
      applicationText: 'Aplicação',
      audioText: 'Áudio',
      textText: 'Arquivo de texto',
      notesText: 'Notas',
      imageDescription: 'Imagem',
      videoDescription: 'Vídeo',
      applicationDescription: 'Aplicação',
      audioDescription: 'Áudio',
      textDescription: 'Arquivo de texto',
      notesDescription: 'Notas',
      downloadTooltip: 'Fazer download',
    },
  },
  en: {
    TasksTabsReport: {
      loadingText: 'Loading...',
      cardTitle: 'Task Reports',
      imageText: 'Image',
      videoText: 'Video',
      applicationText: 'Application',
      audioText: 'Audio',
      textText: 'Text file',
      notesText: 'Notes',
      imageDescription: 'Image',
      videoDescription: 'Video',
      applicationDescription: 'Application',
      audioDescription: 'Audio',
      textDescription: 'Text file',
      notesDescription: 'Notes',
      downloadTooltip: 'Download',
    },
  },
  es: {
    TasksTabsReport: {
      loadingText: 'Cargando...',
      cardTitle: 'Informes de tareas',
      imageText: 'Imagen',
      videoText: 'Video',
      applicationText: 'Aplicación',
      audioText: 'Audio',
      textText: 'Archivo de texto',
      notesText: 'Notas',
      imageDescription: 'Imagen',
      videoDescription: 'Video',
      applicationDescription: 'Aplicación',
      audioDescription: 'Audio',
      textDescription: 'Archivo de texto',
      notesDescription: 'Notas',
      downloadTooltip: 'Descargar',
    },
  },
}
