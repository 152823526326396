export default {
  pt: {
    useServiceStatus: {
      getErrorMessage: 'Erro ao buscar status de serviço.',
    },
  },
  en: {
    useServiceStatus: {
      getErrorMessage: 'Error fetching service status.',
    },
  },
  es: {
    useServiceStatus: {
      getErrorMessage: 'Error al obtener el estado del servicio.',
    },
  },
}
