/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
import React, { useState } from 'react'
import { Link } from 'react-router-dom'

// import Highlighter from 'react-highlight-words';

import {
  Table,
  Tag,
  Space,
  Tooltip,
  Popconfirm,
  Button,
  Typography,
  message,
} from 'antd'
import {
  DeleteFilled,
  ProfileOutlined,
  FileUnknownOutlined,
  LoadingOutlined,
} from '@ant-design/icons'
import { ColumnsType } from 'antd/es/table'

import { useAuth } from '~/hooks/Auth/AuthContext'
import { useListContributors } from '~/hooks/Contributors/useListContributors'
import { translate } from '~/utils/locale'

interface Company {
  id: string
  logo: string
  logo_url: string
  name: string
}

interface Contact {
  avatar: string
  avatar_url: string
  birth: string
  gender: string
  id: string
  name: string
  personType: string
  phone: string
  formattedPhone?: string
}

interface Status {
  color: string
  title: string
  id: string
  description: string
  type: string
}

interface Skill {
  id: string
  description: string
  name: string
}

interface Skills {
  description: string
  id: string
  level: string
  skill: Skill
}

interface Attachments {
  id: string
  title: string
  description: string
  attachment_url: string
  attachment: string
}

interface DataType {
  collaboratorAttachments: Attachments[]
  collaboratorSkills: Skills[]
  company: Company
  contact: Contact
  evaluation: number
  id: string
  status: Status
}

interface TableProps {
  data: DataType[]
  loading: boolean
}

const ContributorsTable: React.FC<TableProps> = ({ data, loading }) => {
  const { Text } = Typography

  const { user } = useAuth()
  const {
    contributorsTotal,
    setPageSize,
    setNumberPage,
    deleteContributor,
    deleteContributors,
  } = useListContributors()

  const [isDelete, setIsDelete] = useState(false)
  const [contributorsId, setContributorsId] = useState<React.Key[]>([])

  const columns: ColumnsType<DataType> = [
    {
      title: 'ID Colaborador',
      align: 'left',
      dataIndex: 'id',
      key: 'id',
      // defaultSortOrder: 'ascend',
      // sortDirections: ['ascend', 'descend', 'ascend'],
      // sorter: (a, b) => a.contact.name.localeCompare(b.contact.name),
      render: (id: string) => (
        <Text copyable={{ text: id }}>{id.substring(0, 8)}</Text>
      ),
    },
    {
      title: translate('ContributorTable.nameColumn'),
      align: 'left',
      dataIndex: ['contact', 'name'],
      key: 'name',
      // defaultSortOrder: 'ascend',
      // sortDirections: ['ascend', 'descend', 'ascend'],
      // sorter: (a, b) => a.contact.name.localeCompare(b.contact.name),
      render: (name: string, record) => (
        <Link
          to={`/colaboradores/visualizar/colaborador/${record.id}/contato/${record.contact.id}`}
        >
          {name}
        </Link>
      ),
    },
    {
      title: translate('ContributorTable.contactColumn'),
      dataIndex: ['contact', 'formattedPhone'],
      align: 'center',
      key: 'contact',
      render: (contact: string) => (
        <>
          {contact || (
            <Text type="secondary">
              <Space size="small">
                <FileUnknownOutlined />
                N/A
              </Space>
            </Text>
          )}
        </>
      ),
    },
    {
      title: translate('ContributorTable.skillsColumn'),
      align: 'center',
      key: 'collaboratorSkills',
      dataIndex: 'collaboratorSkills',
      render: (skills: Skills[]) => (
        <>
          {skills.length === 0 ? (
            <Text type="secondary">
              <Space size="small">
                <FileUnknownOutlined />
                N/A
              </Space>
            </Text>
          ) : (
            skills.map((skill) => {
              const color =
                skill.level === 'basic'
                  ? 'orange'
                  : skill.level === 'intermediary'
                    ? 'green'
                    : 'blue'
              return (
                <Tag color={color} key={skill.id}>
                  {skill.skill.name}
                </Tag>
              )
            })
          )}
        </>
      ),
    },
    {
      title: translate('ContributorTable.statusColumn'),
      dataIndex: ['status', 'title'],
      align: 'center',
      key: 'contact',
      render: (name: string, record) => (
        <>
          {record?.status ? (
            <Tag
              color={
                record.status?.color !== null
                  ? `${record.status?.color}`
                  : '#108ee9'
              }
            >
              {name}
            </Tag>
          ) : (
            <Tag color="default">
              {translate('ContributorTable.noStatusText')}
            </Tag>
          )}
        </>
      ),
    },
    {
      title: translate('ContributorTable.actionsColumn'),
      align: 'center',
      key: 'remove',
      render: (record) => (
        <Space size="middle">
          <Tooltip title={translate('ContributorTable.viewTooltip')}>
            <Link
              to={`/colaboradores/visualizar/colaborador/${record.id}/contato/${record.contact.id}`}
            >
              <Button
                shape="circle"
                ghost
                type="primary"
                icon={<ProfileOutlined />}
              />
            </Link>
          </Tooltip>
          {record.contact.email !== user.email && (
            <Popconfirm
              title={translate('ContributorTable.deletePopconfirm')}
              onConfirm={() => deleteContributor(record.id)}
            >
              <Tooltip title={translate('ContributorTable.deleteTooltip')}>
                <Button danger ghost shape="circle" icon={<DeleteFilled />} />
              </Tooltip>
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ]

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[]) => {
      setIsDelete(selectedRowKeys.length > 0)
      setContributorsId(selectedRowKeys)
    },
    getCheckboxProps: (record: DataType) => ({
      disabled: record.contact.name === 'Disabled User', // Column configuration not to be checked
      name: record.contact.name,
    }),
  }

  function DeleteContributors() {
    const contributors = contributorsId.map((m) => {
      return deleteContributors(m.toString())
    })

    message.success('Colaboradores deletados com sucesso!')
    setIsDelete(false)
    return contributors
  }

  return (
    <>
      {isDelete && (
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <Popconfirm
            title="Deseja excluir as tarefas?"
            onConfirm={DeleteContributors}
          >
            <Tooltip title={translate('ContributorTable.deleteTooltip')}>
              <Button
                ghost
                danger
                size="middle"
                shape="circle"
                icon={<DeleteFilled />}
                style={{ marginBottom: 10 }}
              />
            </Tooltip>
          </Popconfirm>

          {contributorsId.length !== 1 ? (
            <Typography.Title level={5} type="danger">
              {contributorsId.length} colaboradores selecionados
            </Typography.Title>
          ) : (
            <Typography.Title level={5} type="danger">
              1 colaborador selecionado
            </Typography.Title>
          )}
        </div>
      )}
      <Table
        rowKey={(record) => record.id}
        loading={{
          indicator: <LoadingOutlined />,
          spinning: loading,
          size: 'large',
        }}
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        pagination={{
          total: contributorsTotal,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '50', '100', '200'],
          defaultPageSize: 10,
          showTotal: (total, range) => `${range[0]} - ${range[1]} / ${total}`,
          onChange: (page, pageSize) => {
            setPageSize(pageSize!)
            setNumberPage(page)
          },
        }}
        columns={columns}
        dataSource={data}
        scroll={{ x: 1000 }}
      />
    </>
  )
}

export default ContributorsTable
