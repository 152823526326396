export default {
  pt: {
    SegmentsCreate: {
      addButton: 'Adicionar Segmento',
      modalTitle: 'Novo Segmento',
      keepRegisterText: 'Continuar cadastrando segmentos',
      closeButton: 'Fechar',
      registerButton: 'Cadastrar',
      titleLabel: 'Título',
      titleRule: 'Por favor informe o título do segmento.',
      descriptionLabel: 'Descrição',
      descriptionRule: 'Por favor informe a descrição do segmento.',
    },
  },
  en: {
    SegmentsCreate: {
      addButton: 'Add Segment',
      modalTitle: 'New Segment',
      keepRegisterText: 'Continue registering segments',
      closeButton: 'Close',
      registerButton: 'Register',
      titleLabel: 'Title',
      titleRule: 'Please enter the segment title.',
      descriptionLabel: 'Description',
      descriptionRule: 'Please enter the segment description.',
    },
  },
  es: {
    SegmentsCreate: {
      addButton: 'Agregar Segmento',
      modalTitle: 'Nuevo Segmento',
      keepRegisterText: 'Continuar registrando segmentos',
      closeButton: 'Cerrar',
      registerButton: 'Registrar',
      titleLabel: 'Título',
      titleRule: 'Introduzca el título del segmento.',
      descriptionLabel: 'Descripción',
      descriptionRule: 'Ingrese la descripción del segmento.',
    },
  },
}
