/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { Input } from 'antd'
import NumberFormat from 'react-number-format'

interface MoneyInputProps {
  value?: number
  onChange?: (value: number | undefined) => void
}

const MoneyInput: React.FC<MoneyInputProps> = ({ value, onChange }) => {
  const handleValueChange = (values: any) => {
    const { floatValue } = values
    const centavos =
      floatValue !== undefined ? Math.round(floatValue * 100) : undefined

    if (onChange) {
      onChange(centavos)
    }
  }

  return (
    <NumberFormat
      value={
        value !== undefined
          ? (value / 100).toFixed(2).replace('.', ',')
          : undefined
      }
      customInput={Input}
      thousandSeparator="."
      decimalSeparator=","
      prefix="R$ "
      decimalScale={2}
      fixedDecimalScale={true}
      allowNegative={false}
      onValueChange={handleValueChange}
      placeholder="R$ 0,00"
      style={{ width: '100%' }}
    />
  )
}

export default MoneyInput
