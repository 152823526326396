export default {
  pt: {
    Navbar: {
      createTaskButtonText: 'Criar Tarefa',
      exitButtonText: 'Sair',
      errorCreateCompany: 'Você não tem permissão para criar empresas.',
    },
  },
  en: {
    Navbar: {
      createTaskButtonText: 'Create Task',
      exitButtonText: 'Exit',
      errorCreateCompany: 'You are not allowed to create companies.',
    },
  },
  es: {
    Navbar: {
      createTaskButtonText: 'Crear Tarea',
      exitButtonText: 'Salir',
      errorCreateCompany: 'No está permitido crear empresas.',
    },
  },
}
