export default {
  pt: {
    useListTickets: {
      getErrorMessage: 'Erro ao visualizar tickets.',
      createSuccessMessage: 'Ticket criado com sucesso!',
      createErrorMessage: 'Erro ao criar tickets.',
      deleteSuccessMessage: 'Ticket deletado com sucesso!',
      deleteErrorMessage: 'Erro ao excluir tickets.',
    },
  },
  en: {
    useListTickets: {
      getErrorMessage: 'Error viewing tickets.',
      createSuccessMessage: 'Ticket created successfully!',
      createErrorMessage: 'Error creating tickets.',
      deleteSuccessMessage: 'Ticket successfully deleted!',
      deleteErrorMessage: 'Error deleting tickets.',
    },
  },
  es: {
    useListTickets: {
      getErrorMessage: 'Error al ver las tickets.',
      createSuccessMessage: '¡Ticket creada con éxito!',
      createErrorMessage: 'Error al crear tickets.',
      deleteSuccessMessage: '¡Ticket eliminada con éxito!',
      deleteErrorMessage: 'Error al eliminar tickets.',
    },
  },
}
