/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, createContext } from 'react'
import { useQuery } from 'react-query'

import api from '~/services/api'

import { useCompanies } from '~/hooks/Companies/useCompanies'
import { useLocale } from '~/hooks/locale/useLocale'

import { ShowError } from '~/utils/errors/apiErrors'
import { translate } from '~/utils/locale'

interface Contact {
  id: string
  name: string
}

interface DataProps {
  contact: Contact
  id: string
}

interface ContributorsTaskFilterContextData {
  contributorsTaskFilter?: DataProps[]
  isLoading: boolean
  refetch: () => void
  isFetching: boolean
}

const ContributorsTaskFilterContext =
  createContext<ContributorsTaskFilterContextData>(
    {} as ContributorsTaskFilterContextData,
  )

export const ContributorsTaskFilterProvider: React.FC = ({ children }) => {
  const { selectedCompany } = useCompanies()
  const { locale } = useLocale()

  const {
    data: contributorsTaskFilter,
    isLoading,
    refetch,
    isFetching,
  } = useQuery(
    [
      `listagem de colaboradores para filtro de tarefas${selectedCompany}`,
      selectedCompany,
    ],

    async () => {
      try {
        const response = await api.get(
          `/company/${selectedCompany}/contributors`,
        )

        const { data } = response

        return data.results
      } catch (err: any) {
        ShowError(
          err.message,
          translate('useListContributors.getErrorMessage'),
          locale,
        )
      }
    },
  )
  return (
    <ContributorsTaskFilterContext.Provider
      value={{
        contributorsTaskFilter,
        isLoading,
        refetch,
        isFetching,
      }}
    >
      {children}
    </ContributorsTaskFilterContext.Provider>
  )
}

export function useContributorsTaskFilter(): ContributorsTaskFilterContextData {
  const context = useContext(ContributorsTaskFilterContext)

  if (!context) {
    throw new Error('useAuth must be used within an AutoProvider')
  }

  return context
}
