export default {
  pt: {
    EquipmentsTabsMovementsCreate: {
      addButton: 'Adicionar Movimentação',
      modalTitle: 'Adicionar Movimentação',
      closeButton: 'Fechar',
      saveButton: 'Salvar',
      toCustomer: 'Para cliente',
      toContributor: 'Para colaborador',
      toInventory: 'Para estoque',
      customerLabel: 'Cliente',
      customerPlaceholder: 'Selecione o cliente',
      contributorLabel: 'Colaborador',
      contributorPlaceholder: 'Selecione o colaborador',
      descriptionLabel: 'Descrição',
    },
  },
  en: {
    EquipmentsTabsMovementsCreate: {
      addButton: 'Add Movement',
      modalTitle: 'Add Movement',
      closeButton: 'Close',
      saveButton: 'Save',
      toCustomer: 'For customer',
      toContributor: 'For contributor',
      toInventory: 'For stock',
      customerLabel: 'Customer',
      customerPlaceholder: 'Select Customer',
      contributorLabel: 'Contributor',
      contributorPlaceholder: 'Select Contributor',
      descriptionLabel: 'Description',
    },
  },
  es: {
    EquipmentsTabsMovementsCreate: {
      addButton: 'Añadir movimiento',
      modalTitle: 'Añadir movimiento',
      closeButton: 'Cerrar',
      saveButton: 'Guardar',
      toCustomer: 'Para cliente',
      toContributor: 'Para colaborador',
      toInventory: 'Para el stock',
      customerLabel: 'Cliente',
      customerPlaceholder: 'Seleccionar cliente',
      contributorLabel: 'Colaborador',
      contributorPlaceholder: 'Seleccione el colaborador',
      descriptionLabel: 'Descripción',
    },
  },
}
