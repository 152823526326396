/* eslint-disable camelcase */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react/require-default-props */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-useless-escape */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react/jsx-indent */
/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
import React, { useState, useCallback, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import {
  Card,
  Col,
  Row,
  Button,
  Form,
  Input,
  Select,
  DatePicker,
  Space,
  Checkbox,
  Radio,
  message,
  Tooltip,
  Tour,
} from 'antd'
import MaskedInput from 'antd-mask-input'
import {
  SaveOutlined,
  WhatsAppOutlined,
  DeleteOutlined,
  PlusOutlined,
  MailOutlined,
} from '@ant-design/icons'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import pt_BR from 'react-phone-number-input/locale/pt-BR.json'

import { cpf, cnpj } from 'cpf-cnpj-validator'

import api from '~/services/api'

import { Container, Wrapper, ContForm, FormButtonContainer } from './styles'

import { useCompanies } from '~/hooks/Companies/useCompanies'
import { useListGroup } from '~/hooks/Groups/useListGroup'
import { useListContributors } from '~/hooks/Contributors/useListContributors'
import { useLocale } from '~/hooks/locale/useLocale'

import { ShowError } from '~/utils/errors/apiErrors'
import validateWhatsappApi from '~/services/validateWhatsappApi'
import { translate } from '~/utils/locale'
import { useCollaboratorTour } from '~/hooks/Tour/useCollaboratorTour'
import { useTour } from '~/hooks/Tour/useTour'

interface AdditionalPhones {
  phone: string
  is_whatsapp: boolean
  type: string
}

interface NewContributorData {
  name: string
  document: string
  cnpj: string
  rg: string
  phone: string
  email: string
  password: string
  person_type: string
  gender: string
  birth: any
  evaluation: number
  notes: string
  is_whatsapp: boolean
  additional_phones: AdditionalPhones[]
}

interface GroupsOptions {
  label: string
  value: string
  title: string
}

const ContributorCreate: React.FC = () => {
  const history = useHistory()

  const { selectedCompany } = useCompanies()
  const { group } = useListGroup()

  const { listContributorsRefetch } = useListContributors()
  const { locale } = useLocale()

  const {
    createNameCollaboratorRef,
    createEmailCollaboratorRef,
    createPhoneCollaboratorRef,
    createGroupCollaboratorRef,
    saveCollaborator,
    setOpenCreateCollaborator,
    openCreateCollaborator,
    createCollaborator,
    setOpenListCollaborator,
  } = useCollaboratorTour()

  const { isTour } = useTour()

  const [form] = Form.useForm()

  const [groupsOptions, setGroupsOptions] = useState<GroupsOptions[]>([])
  const [documentType, setDocumentType] = useState('')
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [keepRegister, setKeepRegister] = useState(false)

  const generateFakeEmail = (name: string) => {
    const randomString = Math.random().toString(36).substring(7)
    return `${name
      .replace(/\s+/g, '')
      .toLowerCase()}_${randomString}@gstor.com.br`
  }

  const handleGenerateEmail = () => {
    const nameFieldValue = form.getFieldValue('name')
    if (nameFieldValue) {
      const fakeEmail = generateFakeEmail(nameFieldValue)
      form.setFieldsValue({
        email: fakeEmail,
      })
    } else {
      message.error(translate('ContributorCreate.randomEmailError'))
    }
  }

  const handleDocumentTypeChange = (value: string) => {
    if (value === undefined) {
      setDocumentType('')
    } else {
      setDocumentType(value)
    }
  }

  const onCreate = useCallback(
    async (data: NewContributorData) => {
      try {
        setConfirmLoading(true)
        data.name = data.name.toUpperCase()
        if (data.phone) {
          data.phone = data.phone.replace(/[^0-9]/g, '')
        }
        if (data.cnpj) {
          data.cnpj = data.cnpj.replace(/[^0-9]/g, '')
        }
        if (data.document) {
          data.document = data.document.replace(/[^a-zA-Z0-9]/g, '')
        }
        if (data.rg) {
          data.rg = data.rg.replace(/[^a-zA-Z0-9]/g, '')
        }
        if (data.birth) {
          data.birth = data.birth.format('YYYY/MM/DD')
        }

        await api.post(`/company/${selectedCompany}/collaborator`, data)
        setConfirmLoading(false)
        message.success(translate('ContributorCreate.successMessage'))
        listContributorsRefetch()
        form.resetFields()

        if (!keepRegister) {
          history.push('/colaboradores')
        }

        setOpenListCollaborator(!!isTour)
      } catch (err: any) {
        ShowError(
          err.message,
          translate('ContributorCreate.errorMessage'),
          locale,
        )

        setConfirmLoading(false)
      }
    },
    [selectedCompany, form, listContributorsRefetch, keepRegister, locale],
  )

  const ValidateWhatsappPhoneNumber = useCallback(async (phone: string) => {
    try {
      const { data } = await validateWhatsappApi.post('validatePhoneNumber', {
        phoneNumber: phone.replace('+', ''),
      })
      const correctPhone = `+${data.user}`
      form.setFieldsValue({
        phone: correctPhone,
      })
      return true
    } catch (err: any) {
      return false
    }
  }, [])

  const checkCNPJ = useCallback(
    async (cnpjInput: string) => {
      try {
        cnpjInput = cnpjInput.replace(/[^0-9]/g, '')
        const { data } = await api.get(
          `company/${selectedCompany}/cnpjcheck/${cnpjInput}`,
        )
        if (data.cnpjInUse) {
          message.warning(translate('ContributorCreate.cnpjInUseMessage'), 10)
        }
      } catch (err: any) {
        console.log(err)
      }
    },
    [selectedCompany],
  )

  useEffect(() => {
    if (group) {
      setGroupsOptions(
        group.map((groups) => ({
          label: groups.name,
          title: groups.name,
          value: groups.id,
        })),
      )
    }
  }, [group])

  return (
    <Container>
      <Form form={form} layout="vertical" onFinish={onCreate}>
        <Card
          bordered={false}
          title={translate('ContributorCreate.cardTitle')}
          extra={
            <Space size="large">
              <Checkbox
                onChange={(e) => {
                  setKeepRegister(e.target.checked)
                }}
                checked={keepRegister}
              >
                {translate('ContributorCreate.keepRegisterText')}
              </Checkbox>

              <Form.Item style={{ margin: '0 auto' }}>
                <Button
                  icon={<SaveOutlined />}
                  type="primary"
                  htmlType="submit"
                  loading={confirmLoading}
                  ref={saveCollaborator}
                  id="btn_create_contributor"
                >
                  {translate('ContributorCreate.submitButton')}
                </Button>
              </Form.Item>
            </Space>
          }
          style={{
            display: 'inline-block',
            width: 'calc(100% - 20px)',
          }}
        >
          <Wrapper>
            <ContForm>
              <Row gutter={12}>
                <Col
                  span={12}
                  ref={
                    openCreateCollaborator ? createNameCollaboratorRef : null
                  }
                >
                  <Form.Item
                    label={translate('ContributorCreate.nameLabel')}
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: translate('ContributorCreate.nameRule'),
                      },
                    ]}
                  >
                    <Input style={{ textTransform: 'uppercase' }} />
                  </Form.Item>
                </Col>
                <Col span={12} ref={createEmailCollaboratorRef}>
                  <Form.Item
                    label={
                      <Space size="large">
                        {translate('ContributorCreate.emailLabel')}
                        <Tooltip
                          title={translate(
                            'ContributorCreate.emailGenerateTooltip',
                          )}
                        >
                          <Button
                            shape="circle"
                            size="small"
                            icon={<MailOutlined />}
                            onClick={handleGenerateEmail}
                          />
                        </Tooltip>
                      </Space>
                    }
                    name="email"
                    rules={[
                      {
                        type: 'email',
                        message: translate('ContributorCreate.emailRuleOne'),
                      },
                      {
                        required: true,
                        message: translate('ContributorCreate.emailRuleTwo'),
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col span={12}>
                  <Form.Item
                    label={translate('ContributorCreate.genderLabel')}
                    name="gender"
                  >
                    <Select allowClear>
                      <Select.Option value="male">
                        {translate('ContributorCreate.genderMasculine')}
                      </Select.Option>
                      <Select.Option value="female">
                        {translate('ContributorCreate.genderFeminine')}
                      </Select.Option>
                      <Select.Option value="other">
                        {translate('ContributorCreate.genderOther')}
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={translate('ContributorCreate.birthLabel')}
                    name="birth"
                  >
                    <DatePicker style={{ width: '100%' }} format="DD/MM/YYYY" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col span={12} ref={createPhoneCollaboratorRef}>
                  <Form.Item
                    label={
                      <p>
                        <WhatsAppOutlined /> Whatsapp
                      </p>
                    }
                    validateTrigger="onBlur"
                    rules={[
                      {
                        required: true,
                        message: translate('ContributorCreate.whatsappRuleOne'),
                      },
                      () => ({
                        async validator(rule, phone) {
                          if (phone && isValidPhoneNumber(phone)) {
                            const validate =
                              await ValidateWhatsappPhoneNumber(phone)
                            if (validate) {
                              return Promise.resolve()
                            }
                            return Promise.reject(
                              new Error(
                                translate('ContributorCreate.whatsappRuleTwo'),
                              ),
                            )
                          }
                          return Promise.reject(
                            new Error(
                              translate('ContributorCreate.whatsappRuleThree'),
                            ),
                          )
                        },
                      }),
                    ]}
                    name="phone"
                  >
                    <PhoneInput
                      placeholder={translate(
                        'ContributorCreate.whatsappPlaceholder',
                      )}
                      labels={pt_BR}
                      defaultCountry="BR"
                      international
                      onChange={() => {}}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={translate('ContributorCreate.personTypeLabel')}
                    name="person_type"
                  >
                    <Select allowClear onChange={handleDocumentTypeChange}>
                      <Select.Option value="legalPerson">
                        {translate('ContributorCreate.personTypeJuridic')}
                      </Select.Option>
                      <Select.Option value="physicalPerson">
                        {translate('ContributorCreate.personTypePhysical')}
                      </Select.Option>
                      <Select.Option value="foreign">
                        {translate('ContributorCreate.personTypeForeign')}
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={12}>
                {documentType !== '' && (
                  <>
                    <Col span={12}>
                      <Form.Item
                        label={
                          documentType === 'legalPerson' ||
                          documentType === 'physicalPerson'
                            ? translate('ContributorCreate.cpfLabel')
                            : translate('ContributorCreate.passportLabel')
                        }
                        rules={[
                          {
                            required: documentType === 'physicalPerson',
                            message: translate('ContributorCreate.cpfRuleOne'),
                          },
                          {
                            validator(_, value) {
                              if (value === undefined || value === null) {
                                return Promise.resolve()
                              }
                              const documentInput = value.replace(/[^0-9]/g, '')

                              if (documentInput.length === 11) {
                                if (cpf.isValid(value)) {
                                  return Promise.resolve()
                                }
                                // eslint-disable-next-line prefer-promise-reject-errors
                                return Promise.reject(
                                  translate('ContributorCreate.cpfRuleTwo'),
                                )
                              }
                              return Promise.resolve()
                            },
                          },
                        ]}
                        name="document"
                      >
                        {documentType === 'physicalPerson' ||
                        documentType === 'legalPerson' ? (
                          <MaskedInput mask={'000.000.000-00'} placeholder="" />
                        ) : (
                          <MaskedInput mask={'aa000000'} placeholder="" />
                        )}
                      </Form.Item>
                    </Col>

                    {documentType !== 'physicalPerson' && (
                      <Col span={12}>
                        <Form.Item
                          label={translate('ContributorCreate.cnpjLabel')}
                          name="cnpj"
                          rules={[
                            {
                              validator(_, value) {
                                if (value === undefined || value === null) {
                                  return Promise.resolve()
                                }
                                const CnpjInput = value.replace(/[^0-9]/g, '')
                                if (CnpjInput.length === 14) {
                                  if (cnpj.isValid(value)) {
                                    return Promise.resolve()
                                  }
                                  // eslint-disable-next-line prefer-promise-reject-errors
                                  return Promise.reject(
                                    translate('ContributorCreate.cnpjRuleTwo'),
                                  )
                                }
                                return Promise.resolve()
                              },
                            },
                          ]}
                        >
                          <MaskedInput
                            mask={'00.000.000/0000-00'}
                            onBlur={(e) => checkCNPJ(e.target.value)}
                          />
                        </Form.Item>
                      </Col>
                    )}

                    {documentType === 'physicalPerson' && (
                      <Col span={12}>
                        <Form.Item
                          label={translate('ContributorCreate.idLabel')}
                          name="rg"
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                    )}
                  </>
                )}
              </Row>

              <Row gutter={10}>
                <Col span={8} ref={createGroupCollaboratorRef}>
                  <Form.Item
                    label={translate('ContributorCreate.groupLabel')}
                    name="group_id"
                    rules={[
                      {
                        required: true,
                        message: translate('ContributorCreate.groupRule'),
                      },
                    ]}
                  >
                    <Select
                      style={{ width: '100%' }}
                      options={groupsOptions}
                      disabled={!groupsOptions}
                      allowClear
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col span={24}>
                  <Form.Item
                    label={translate('ContributorCreate.observationLabel')}
                    name="notes"
                  >
                    <Input.TextArea rows={3} />
                  </Form.Item>
                </Col>
              </Row>
            </ContForm>
          </Wrapper>

          <Form.Item
            label={translate('ContributorCreate.additionalPhonesLabel')}
            labelCol={{ span: 24 }}
            style={{ width: '100%' }}
          >
            <Form.List name="additional_phones">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <Space
                      key={key}
                      style={{ display: 'flex', marginBottom: 8 }}
                      align="baseline"
                    >
                      <Form.Item
                        {...restField}
                        label={
                          <b>{translate('ContributorCreate.phoneLabel')}</b>
                        }
                        name={[name, 'phone']}
                        labelCol={{ span: 24 }}
                        rules={[
                          () => ({
                            validator(rule, phone) {
                              if (phone && isValidPhoneNumber(phone)) {
                                return Promise.resolve()
                              }
                              return Promise.reject(
                                new Error(
                                  translate('ContributorCreate.phoneRule'),
                                ),
                              )
                            },
                          }),
                        ]}
                      >
                        <PhoneInput
                          placeholder={translate(
                            'ContributorCreate.phonePlaceholder',
                          )}
                          labels={pt_BR}
                          defaultCountry="BR"
                          international
                          onChange={() => {}}
                        />
                      </Form.Item>

                      <Form.Item
                        {...restField}
                        name={[name, 'is_whatsapp']}
                        style={{ display: 'inline-block' }}
                        valuePropName="checked"
                      >
                        <Checkbox>
                          <WhatsAppOutlined />
                        </Checkbox>
                      </Form.Item>

                      <Form.Item
                        {...restField}
                        label={translate('ContributorCreate.phoneTypeLabel')}
                        name={[name, 'type']}
                        labelCol={{ span: 24 }}
                        rules={[
                          {
                            required: true,
                            message: translate(
                              'ContributorCreate.phoneTypeRule',
                            ),
                          },
                        ]}
                      >
                        <Radio.Group>
                          <Radio.Button value="home">
                            {translate(
                              'ContributorCreate.phoneTypeResidential',
                            )}
                          </Radio.Button>
                          <Radio.Button value="cell">
                            {translate('ContributorCreate.phoneTypeCellphone')}
                          </Radio.Button>
                          <Radio.Button value="commercial">
                            {translate('ContributorCreate.phoneTypeCommercial')}
                          </Radio.Button>
                        </Radio.Group>
                      </Form.Item>

                      <DeleteOutlined onClick={() => remove(name)} />
                    </Space>
                  ))}

                  <Form.Item style={{ width: '100%', display: 'block' }}>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      {translate('ContributorCreate.addPhoneButton')}
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Form.Item>

          <FormButtonContainer>
            <Space size="large" style={{ alignItems: 'baseline' }}>
              <Checkbox
                onChange={(e) => {
                  setKeepRegister(e.target.checked)
                }}
                checked={keepRegister}
              >
                {translate('ContributorCreate.keepRegisterText')}
              </Checkbox>

              <Form.Item>
                <Button
                  icon={<SaveOutlined />}
                  type="primary"
                  htmlType="submit"
                  loading={confirmLoading}
                >
                  {translate('ContributorCreate.submitButton')}
                </Button>
              </Form.Item>
            </Space>
          </FormButtonContainer>
        </Card>

        <Tour
          open={openCreateCollaborator}
          onClose={() => setOpenCreateCollaborator(false)}
          steps={createCollaborator}
          indicatorsRender={(current, total) => (
            <span>
              {current + 1} / {total}
            </span>
          )}
        />
      </Form>
    </Container>
  )
}

export default ContributorCreate
