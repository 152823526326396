import { LoadingOutlined, LockOutlined, SmileOutlined } from '@ant-design/icons'
import { Button, Card, Form, Input, Result, message } from 'antd'
import { encode } from 'base-64'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

// import { Container } from './styles';

interface FormAccess {
  password: string
}

const RedirectPanel: React.FC = () => {
  const history = useHistory()

  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)

  const [isAccess, setIsAccess] = useState(false)

  const { email } = useParams<{ email: string }>()

  function onSubmitPassword(valueForm: FormAccess) {
    try {
      setPassword(valueForm.password)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    setLoading(true)

    if (encode(password) === process.env.REACT_APP_ACCESS) {
      setIsAccess(true)
      setTimeout(() => {
        localStorage.setItem('typeAuth', 'panel')
        localStorage.setItem('userEmail', email)
        history.push('/')
        window.location.reload()
      }, 4000)
    } else {
      if (password) {
        message.error('Senha inválida')
      }
    }
  }, [history, email, password])

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      {!isAccess ? (
        <Card style={{ width: '35%' }}>
          <Form onFinish={onSubmitPassword}>
            <h3>Informe a senha de acesso</h3>
            <Form.Item
              name="password"
              rules={[{ required: true, message: 'Insira a senha de acesso.' }]}
            >
              <Input.Password
                prefix={<LockOutlined className="site-form-item-icon" />}
                placeholder="password"
                style={{ width: '100%' }}
              />
            </Form.Item>

            <Button type="primary" style={{ width: '100%' }} htmlType="submit">
              Enviar
            </Button>
          </Form>
        </Card>
      ) : (
        <Result
          icon={<SmileOutlined />}
          title="Aguarde, você será redirecionado para o gstor."
          extra={
            loading ? <LoadingOutlined spin style={{ fontSize: 24 }} /> : ''
          }
        />
      )}
    </div>
  )
}

export default RedirectPanel
