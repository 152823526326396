export default {
  pt: {
    EquipmentsTabsDetails: {
      dateString: 'às',
      cardTitle: 'Informações Gerais',
      editButton: 'Editar',
      equipmentName: 'Nome do Equipamento',
      associatedEquipment: 'Equipamento Associado',
      deleteTooltip: 'Excluir associação',
      deletePopconfirm: 'Deseja mesmo deletar a associação?',
      noAssociatedEquipment: 'Nenhum equipamento associado',
      identifier: 'Identificador',
      expirationDate: 'Data de Validade',
      generalInfoTitle: 'Informações Gerais',
      equipmentLocale: 'Local do Equipamento',
      inventory: 'Estoque',
      category: 'Categoria',
      description: 'Descrição',
    },
  },
  en: {
    EquipmentsTabsDetails: {
      dateString: 'at',
      cardTitle: 'General information',
      editButton: 'Edit',
      equipmentName: 'Equipment Name',
      associatedEquipment: 'Associated Equipment',
      deleteTooltip: 'Delete association',
      deletePopconfirm: 'Do you really want to delete the association?',
      noAssociatedEquipment: 'No associated equipment',
      identifier: 'Identifier',
      expirationDate: 'Expiration date',
      generalInfoTitle: 'General information',
      equipmentLocale: 'Equipment Location',
      inventory: 'Inventory',
      category: 'Category',
      description: 'Description',
    },
  },
  es: {
    EquipmentsTabsDetails: {
      dateString: 'a las',
      cardTitle: 'Informaciones generales',
      editButton: 'Editar',
      equipmentName: 'Nombre del equipo',
      associatedEquipment: 'Equipamiento asociado',
      deleteTooltip: 'Eliminar asociación',
      deletePopconfirm: '¿Realmente desea eliminar la asociación?',
      noAssociatedEquipment: 'Sin equipo asociado',
      identifier: 'Identificador',
      expirationDate: 'Fecha de validez',
      generalInfoTitle: 'Informaciones generales',
      equipmentLocale: 'Ubicación del equipo',
      inventory: 'Inventario',
      category: 'Categoría',
      description: 'Descripción',
    },
  },
}
