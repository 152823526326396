import { LoadingOutlined } from '@ant-design/icons'
import { Card, Space } from 'antd'
import Text from 'antd/lib/typography/Text'
import React from 'react'
import { ListContributorsProvider } from '~/hooks/Contributors/useListContributors'
import { useListGroup } from '~/hooks/Groups/useListGroup'
import { translate } from '~/utils/locale'
import CreateGroup from '../Create'
import FilterGroups from '../Filter'
import GroupsTable from '../Table'

const GroupsBoard: React.FC = () => {
  const { isFetching, isLoading, groupsTotal } = useListGroup()

  return (
    <Card
      title={
        <Space size="large">
          <b>{translate('GroupsBoard.cardTitle')}</b>
          {isLoading ? (
            <LoadingOutlined spin />
          ) : (
            <Text
              type="secondary"
              style={{ fontWeight: 'normal', fontSize: 14 }}
            >
              {groupsTotal !== undefined ? (
                <>
                  {groupsTotal === 1
                    ? `${translate(
                        'GroupsBoard.totalText',
                      )} ${groupsTotal} ${translate('GroupsBoard.groupText')}`
                    : `${translate(
                        'GroupsBoard.totalText',
                      )} ${groupsTotal} ${translate('GroupsBoard.groupsText')}`}
                </>
              ) : (
                ''
              )}
            </Text>
          )}
          {!isLoading && isFetching && <LoadingOutlined spin />}
        </Space>
      }
      extra={
        <Space>
          <ListContributorsProvider>
            <FilterGroups />
          </ListContributorsProvider>
          <CreateGroup />
        </Space>
      }
      bordered={false}
      style={{ width: '100%', padding: 30 }}
    >
      <GroupsTable />
    </Card>
  )
}

export default GroupsBoard
