/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, createContext } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'

import { message } from 'antd'

import api from '~/services/api'

import { useCompanies } from '~/hooks/Companies/useCompanies'
import { useLocale } from '~/hooks/locale/useLocale'

import { ShowError } from '~/utils/errors/apiErrors'
import { translate } from '~/utils/locale'

interface SpecificationProps {
  attribute: string
  value: string
}

interface SpecificationsProps {
  id: string
  name: string
  specifications: SpecificationProps[]
}

interface SpecificationsContextData {
  specifications?: SpecificationsProps[]
  createSpecification(dataSpecification: object | undefined): void
  updateSpecifications(
    dataSpecification: object | undefined,
    templateId: string,
  ): void
  deleteSpecification(specificationId: string): void
  isLoading: boolean
  refetch: () => void
  isFetching: boolean
}

const SpecificationsContext = createContext<SpecificationsContextData>(
  {} as SpecificationsContextData,
)

export const SpecificationsProvider: React.FC = ({ children }) => {
  const { selectedCompany } = useCompanies()
  const { locale } = useLocale()
  const { equipmentId } = useParams<{ equipmentId: string }>()

  const {
    data: specifications,
    isLoading,
    refetch,
    isFetching,
  } = useQuery(
    [`Especificações${selectedCompany}`, selectedCompany],

    async () => {
      try {
        const response = await api.get(
          `/company/${selectedCompany}/equipment/${equipmentId}/specifications`,
        )

        const { data } = response

        return data
      } catch (err: any) {
        ShowError(
          err.message,
          translate('useSpecifications.getErrorMessage'),
          locale,
        )
      }
    },
    {
      staleTime: 5000,
    },
  )

  async function createSpecification(dataSpecification: object) {
    try {
      await api.post(
        `/company/${selectedCompany}/equipment/${equipmentId}/specification`,
        dataSpecification,
      )

      refetch()
      message.success(translate('useSpecifications.createSuccessMessage'))
    } catch (err: any) {
      ShowError(
        err.message,
        translate('useSpecifications.createErrorMessage'),
        locale,
      )
    }
  }

  async function updateSpecifications(
    dataSpecification: object,
    specificationId: string,
  ) {
    try {
      await api.put(
        `/company/${selectedCompany}/equipment/${equipmentId}/specification/${specificationId}`,
        dataSpecification,
      )

      refetch()
      message.success(translate('useSpecifications.updateSuccessMessage'))
    } catch (err: any) {
      ShowError(
        err.message,
        translate('useSpecifications.updateErrorMessage'),
        locale,
      )
    }
  }

  async function deleteSpecification(specificationId: string) {
    try {
      await api.delete(
        `/company/${selectedCompany}/equipment/${equipmentId}/specification/${specificationId}`,
      )

      refetch()
      message.success(translate('useSpecifications.deleteSuccessMessage'))
    } catch (err: any) {
      ShowError(
        err.message,
        translate('useSpecifications.deleteErrorMessage'),
        locale,
      )
    }
  }

  return (
    <SpecificationsContext.Provider
      value={{
        specifications,
        createSpecification,
        deleteSpecification,
        updateSpecifications,
        isLoading,
        refetch,
        isFetching,
      }}
    >
      {children}
    </SpecificationsContext.Provider>
  )
}

export function useSpecifications(): SpecificationsContextData {
  const context = useContext(SpecificationsContext)

  if (!context) {
    throw new Error('useAuth must be used within an AutoProvider')
  }

  return context
}
