export default {
  pt: {
    NewTaskType: {
      helmetTitle: 'Novo Tipo de Tarefa',
    },
  },
  en: {
    NewTaskType: {
      helmetTitle: 'New Task Type',
    },
  },
  es: {
    NewTaskType: {
      helmetTitle: 'Nuevo Tipo de Tarea',
    },
  },
}
