export default {
  pt: {
    ClientsTalkToContact: {
      seeContactsText: 'Ver contatos',
      drawerTitle: 'Contatos',
      noContactText: 'Nenhum contato foi atribuido.',
    },
  },
  en: {
    ClientsTalkToContact: {
      seeContactsText: 'See contacts',
      drawerTitle: 'Contacts',
      noContactText: 'No contacts have been assigned.',
    },
  },
  es: {
    ClientsTalkToContact: {
      seeContactsText: 'Ver contactos',
      drawerTitle: 'Contactos',
      noContactText: 'No se han asignado contactos.',
    },
  },
}
