export default {
  pt: {
    MiscDropdownNotifications: {
      noNotifications: 'Sem notificações',
      recent: 'Recentes',
      old: 'Antigas',
    },
  },
  en: {
    MiscDropdownNotifications: {
      noNotifications: 'No notifications',
      recent: 'Recent',
      old: 'Old',
    },
  },
  es: {
    MiscDropdownNotifications: {
      noNotifications: 'No notificaciones',
      recent: 'Recientes',
      old: 'Antiguas',
    },
  },
}
