import { lighten } from 'polished'
import styled from 'styled-components'

export const Container = styled.div`
  span {
    cursor: pointer;
    color: var(--primary-color);
    transition: 0.2s ease;

    &:hover {
      color: ${lighten(0.1, '#3C8ACB')};
    }
  }
`

export const Wrapper = styled.div`
  .phones {
    span {
      margin-left: 5px;
    }
  }

  .types {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 10px;
  }

  .avatar {
    display: flex;
    align-items: center;

    h3 {
      margin-bottom: 0px;
      margin-left: 5px;
    }
  }

  .contact {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 5px;
  }

  .ant-divider-horizontal {
    margin: 15px 0;
  }
`
