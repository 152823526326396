export default {
  pt: {
    UsersFilter: {
      filterTooltip: 'Limpar Filtro',
      filterAppliedText: 'Filtro Aplicado',
      filterText: 'Filtros',
      drawerTitle: 'Filtros de Usuários',
      contactLabel: 'Contato:',
      contactInfoOne: 'Você pode filtrar buscando por',
      contactInfoTwo:
        'nome, email, telefone, documento, cnpj, rg, tipo de pessoa e gênero do usuário',
      contactPlaceholder: 'Informações do usuário',
      emailLabel: 'E-mail:',
      emailPlaceholder: 'Insira o e-mail do usuário',
      groupLabel: 'Grupo:',
      groupPlaceholder: 'Insira o nome do grupo',
      contributorLabel: 'Colaborador:',
      contributorPlaceholder: 'Selecione o colaborador',
      applyButton: 'Aplicar',
      cleanButton: 'Limpar',
    },
  },
  en: {
    UsersFilter: {
      filterTooltip: 'Clean Filter',
      filterAppliedText: 'Filter Applied',
      filterText: 'Filters',
      drawerTitle: 'User Filters',
      contactLabel: 'Contact:',
      contactInfoOne: 'You can filter by looking for',
      contactInfoTwo:
        'name, email, phone, document, cnpj, id, type of person and gender of the user',
      contactPlaceholder: 'user information',
      emailLabel: 'Email:',
      emailPlaceholder: 'Enter email',
      groupLabel: 'Group:',
      groupPlaceholder: 'Enter the group name',
      contributorLabel: 'Contributor:',
      contributorPlaceholder: 'Select the contributor',
      applyButton: 'Apply',
      cleanButton: 'Clean',
    },
  },
  es: {
    UsersFilter: {
      filterTooltip: 'Filtro claro',
      filterAppliedText: 'Filtro Aplicado',
      filterText: 'Filtros',
      drawerTitle: 'Filtros de usuario',
      contactLabel: 'Contacto:',
      contactInfoOne: 'Puedes filtrar buscando',
      contactInfoTwo:
        'nombre, email, teléfono, documento, cnpj, id, tipo de persona y género del usuario',
      contactPlaceholder: 'Informacion del usuario',
      emailLabel: 'Email:',
      emailPlaceholder: 'Introduzca su email de usuario',
      groupLabel: 'Grupo:',
      groupPlaceholder: 'Introduce el nombre del grupo',
      contributorLabel: 'Colaborador:',
      contributorPlaceholder: 'Seleccione el colaborador',
      applyButton: 'Aplicar',
      cleanButton: 'Limpio',
    },
  },
}
