export default {
  pt: {
    ContributorFilter: {
      cleanFilter: 'Limpar Filtro',
      filterAppliedText: 'Filtro Aplicado',
      filterText: 'Filtros',
      drawerTitle: 'Filtros de Colaboradores',
      contributorInfoLabel: 'Informações do colaborador:',
      contributorInfoParagraphOne: 'Você pode filtrar buscando por',
      contributorInfoParagraphTwo:
        'nome, email, telefone, documento, cnpj, rg, tipo de pessoa e gênero do colaborador',
      contributorInfoPlaceholder: 'Informe as informações',
      evaluationLabel: 'Avaliação de Atendimento',
      internalEvaluationLabel: 'Avaliação Interna',
      skillsLabel: 'Habilidades',
      skillsPlaceholder: 'Selecione as Habilidades',
      statusLabel: 'Status',
      statusPlaceholder: 'Selecione o status',
      addressLabel: 'Endereço',
      addressPlaceholder: 'Insira o endereço',
      applyButton: 'Aplicar',
      cleanButton: 'Limpar',
    },
  },
  en: {
    ContributorFilter: {
      cleanFilter: 'Clean Filter',
      filterAppliedText: 'Filter Applied',
      filterText: 'Filters',
      drawerTitle: 'Collaborator Filters',
      contributorInfoLabel: 'Collaborator Information:',
      contributorInfoParagraphOne: 'You can filter by looking for',
      contributorInfoParagraphTwo:
        'name, email, telephone, document, cnpj, id, type of person and gender of the contributor',
      contributorInfoPlaceholder: 'Inform the information',
      evaluationLabel: 'Service Rating',
      internalEvaluationLabel: 'Internal Rating',
      skillsLabel: 'Skills',
      skillsPlaceholder: 'Select Skills',
      statusLabel: 'Status',
      statusPlaceholder: 'Select Status',
      addressLabel: 'Address',
      addressPlaceholder: 'Enter the address',
      applyButton: 'Apply',
      cleanButton: 'Clean',
    },
  },
  es: {
    ContributorFilter: {
      cleanFilter: 'Filtro Claro',
      filterAppliedText: 'Filtro Aplicado',
      filterText: 'Filtros',
      drawerTitle: 'Filtros de Colaboradores',
      contributorInfoLabel: 'Información del colaborador:',
      contributorInfoParagraphOne: 'Puedes filtrar buscando',
      contributorInfoParagraphTwo:
        'nombre, email, teléfono, documento, cnpj, id, tipo de persona y género del aportante',
      contributorInfoPlaceholder: 'Informar la información',
      evaluationLabel: 'Calificación del servicio',
      internalEvaluationLabel: 'Evaluación Interna',
      skillsLabel: 'Habilidades',
      skillsPlaceholder: 'Seleccionar Habilidades',
      statusLabel: 'Status',
      statusPlaceholder: 'Seleccionar status',
      addressLabel: 'Dirección',
      addressPlaceholder: 'Introduce la dirección',
      applyButton: 'Aplicar',
      cleanButton: 'Limpiar',
    },
  },
}
