export default {
  pt: {
    ScalesClientsTable: {
      nameColumn: 'Nome',
      responsibleColumn: 'Responsável',
      emailColumn: 'Email',
      actionsColumn: 'Ações',
      infoText:
        'Para adicionar clientes na escala é necessário que os mesmos estejam cadastrados no sistema.',
      addButton: 'Adicionar clientes',
    },
  },
  en: {
    ScalesClientsTable: {
      nameColumn: 'Name',
      responsibleColumn: 'Responsible',
      emailColumn: 'Email',
      actionsColumn: 'Actions',
      infoText:
        'To add customers to the scale, they must be registered in the system.',
      addButton: 'Add customers',
    },
  },
  es: {
    ScalesClientsTable: {
      nameColumn: 'Nombre',
      responsibleColumn: 'Responsable',
      emailColumn: 'Correo electrónico',
      actionsColumn: 'Acciones',
      infoText:
        'Para agregar clientes a la balanza, estos deben estar registrados en el sistema.',
      addButton: 'Agregar clientes',
    },
  },
}
