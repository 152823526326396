/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'

import {
  Card,
  Colors,
  Container,
  Content,
  Cover,
  User,
  Wrapper,
} from './styles'
import { Avatar, Col, Divider, Form, Input, Row, Tooltip } from 'antd'
import { PictureOutlined, UserOutlined } from '@ant-design/icons'
import QRCode from 'qrcode.react'
import { CompactPicker } from 'react-color'
import { useBadge } from '~/hooks/Badge/useBadge'
import { translate } from '~/utils/locale'

const Template01: React.FC = () => {
  const { setDataBadge } = useBadge()
  const template = localStorage.getItem('@gstorTemplateCracha')

  const [colorCover, setColorCover] = useState('#6297e4')
  const [colorBackground, setColorBackground] = useState('#f1f1f1')

  const [colorText, setColorText] = useState('#333536')
  const [colorStrongText, setColorStrongText] = useState('#333536')

  const [name, setName] = useState('')
  const [description, setDescription] = useState('')

  useEffect(() => {
    const data = {
      name,
      description,
      template: template || 'Template01',
      colorCover,
      colorBackground,
      colorText,
      colorStrongText,
    }

    setDataBadge(data)
  }, [
    colorBackground,
    colorCover,
    colorStrongText,
    colorText,
    setDataBadge,
    template,
    name,
    description,
  ])

  return (
    <Wrapper>
      <Form id="pauseForm" layout="vertical">
        <Row gutter={10}>
          <Col span={12}>
            <Form.Item
              label={translate('BadgeComponent.nameText')}
              name="name"
              rules={[
                {
                  required: true,
                  message: translate('BadgeComponent.nameRule'),
                },
              ]}
            >
              <Input
                placeholder={translate('BadgeComponent.namePlaceholder')}
                onChange={(e) => setName(e.currentTarget.value)}
                value={name}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label={translate('BadgeComponent.descriptionText')}
              name="description"
            >
              <Input
                placeholder={translate('BadgeComponent.descriptionPlaceholder')}
                onChange={(e) => setDescription(e.currentTarget.value)}
                value={description}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <Divider />

      <Container>
        <Card colorBackground={colorBackground}>
          <Cover colorCover={colorCover} />
          <Tooltip title={translate('BadgeComponent.imageTooltipText')}>
            <User>
              <UserOutlined size={20} />
            </User>
          </Tooltip>

          <Content colorStrongText={colorStrongText} colorText={colorText}>
            <div className="text">
              <h2>{translate('BadgeComponent.colaboradorText')}</h2>
              <p>{translate('BadgeComponent.companyText')}</p>
            </div>

            <div className="text">
              <h3>{translate('BadgeComponent.serviceText')}</h3>
              <Tooltip title={translate('BadgeComponent.serviceTooltipText')}>
                <Avatar icon={<PictureOutlined />} shape="square" size={60} />
              </Tooltip>
            </div>

            <div className="text">
              <Tooltip title="Qrcode">
                <QRCode value="" size={110} style={{ marginTop: '1rem' }} />
              </Tooltip>
            </div>
          </Content>
        </Card>
        <Colors>
          <div className="select-color">
            <h3>{translate('BadgeComponent.coverColorText')}</h3>
            <CompactPicker
              color={colorCover}
              onChangeComplete={(colorSelect) => {
                setColorCover(colorSelect.hex)
              }}
            />
          </div>

          <div className="select-color">
            <h3>{translate('BadgeComponent.backgroundColorText')}</h3>
            <CompactPicker
              color={colorBackground}
              onChangeComplete={(colorSelect) => {
                setColorBackground(colorSelect.hex)
              }}
            />
          </div>

          <Divider />

          <div className="select-color">
            <h3>{translate('BadgeComponent.strongColorText')}</h3>
            <CompactPicker
              color={colorStrongText}
              onChangeComplete={(colorSelect) => {
                setColorStrongText(colorSelect.hex)
              }}
            />
          </div>

          <div className="select-color">
            <h3>{translate('BadgeComponent.colorText')}</h3>
            <CompactPicker
              color={colorText}
              onChangeComplete={(colorSelect) => {
                setColorText(colorSelect.hex)
              }}
            />
          </div>
        </Colors>
      </Container>
    </Wrapper>
  )
}

export default Template01
