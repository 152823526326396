import React from 'react'
import { Button } from 'antd'
import * as XLSX from 'xlsx'

interface Task {
  responsible: { data: { contact: { name: string } } }
  customer: { contact: { name: string } }
  date: string
  duration_time: string
  current_status: string
  external_code: string
  internal_code: string
  taskType: { title: string }
  value: number
  address: {
    street: string
    number: number
    neighborhood: string
    city: string
    state: string
    zip_code: string
  }
  createdAt: Date
}

const exportTasksToExcel = (tasks: Task[]) => {
  const statusMap: { [key: string]: string } = {
    received: 'Recebida',
    viewed: 'Visualizada',
    finished: 'Finalizada',
    working: 'Em Andamento',
    'en-route': 'A Caminho',
    canceled: 'Cancelada',
    break: 'Pausada',
  }

  const headers = [
    'Responsável',
    'Cliente',
    'Data',
    'Duração',
    'Status Atual',
    'Código Externo',
    'Código Interno',
    'Tipo de Tarefa',
    'Valor',
    'Rua',
    'Número',
    'Bairro',
    'Cidade',
    'Estado',
    'CEP',
    'Criado em',
  ]

  const data = tasks.map((task) => {
    if (!task?.responsible?.data?.contact?.name) {
      // console.log('Tarefa Sem responsável', task)
    }
    return [
      task.responsible?.data?.contact?.name || '',
      task.customer?.contact?.name || '',
      task.date ? new Date(task.date).toLocaleString() : '',
      task.duration_time || '',
      statusMap[task.current_status] || '',
      task.external_code || '',
      task.internal_code || '',
      task.taskType?.title || '',
      `R$ ${task.value.toFixed(2)}`,
      task.address?.street || '',
      task.address?.number.toString() || '',
      task.address?.neighborhood || '',
      task.address?.city || '',
      task.address?.state || '',
      task.address?.zip_code || '',
      task.createdAt ? new Date(task.createdAt).toLocaleString() : '',
    ].map((value) => value.toString())
  })

  data.sort((a, b) => a[0].localeCompare(b[0]))

  const worksheet = XLSX.utils.aoa_to_sheet([headers, ...data])
  const workbook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Tarefas')

  const excelBuffer = XLSX.write(workbook, { type: 'buffer', bookType: 'xlsx' })
  return excelBuffer
}

interface TasksToExcelProps {
  tasks: Task[]
}

const TasksToExcel: React.FC<TasksToExcelProps> = ({ tasks }) => {
  const handleExportClick = () => {
    const excelBuffer = exportTasksToExcel(tasks)
    const blob = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })
    const url = URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = 'tarefas.xlsx'
    document.body.appendChild(a)
    a.click()
    URL.revokeObjectURL(url)
    document.body.removeChild(a)
  }

  return (
    <Button type="primary" onClick={handleExportClick}>
      Exportar Tarefas para Excel
    </Button>
  )
}

export default TasksToExcel
