/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useRef, useCallback, useState } from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import * as Yup from 'yup'

import { FiMail, FiLogIn } from 'react-icons/fi'

import {
  message,
  // Select
} from 'antd'

import { Container, Content, AnimationContainer, Background } from './styles'

import Input from '~/components/Login/Input'
import Button from '~/components/Login/Button'

import api from '~/services/api'

import { useLocale } from '~/hooks/locale/useLocale'

import LogoGestor from '~/assets/logo_icon.svg'

import getValidationErrors from '~/utils/getValidationErrors'
import { ShowError } from '~/utils/errors/apiErrors'
import { translate } from '~/utils/locale'

interface SignInFormData {
  email: string
}

const ForgotPassword: React.FC = () => {
  const { locale } = useLocale()
  const [loading, setLoading] = useState(false)

  const formRef = useRef<FormHandles>(null)

  const [
    handleData,
    //  setHandleData
  ] = useState<string>('password')

  // function HandleData(value: string) {
  //   setHandleData(value);
  // }

  const handleSubmit = useCallback(
    async (data: SignInFormData) => {
      try {
        setLoading(true)

        formRef.current?.setErrors({})

        const schema = Yup.object().shape({
          email: Yup.string()
            .required(translate('ForgotPassword.emailYup.required'))
            .email(translate('ForgotPassword.emailYup.valid')),
        })

        await schema.validate(data, {
          abortEarly: false,
        })

        if (handleData === 'password') {
          await api.post('password/forgot', {
            email: data.email,
          })
        } else {
          await api.post('user/username/recovery', {
            email: data.email,
          })
        }

        message.success(translate('ForgotPassword.successMessage'))
      } catch (err: any) {
        ShowError(err.message, translate('ForgotPassword.errorMessage'), locale)

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err)
          formRef.current?.setErrors(errors)
        }
      } finally {
        setLoading(false)
      }
    },
    [handleData, locale],
  )

  console.log(translate('ForgotPassword.helmetTitle'))

  return (
    <Container>
      <Helmet>
        <title>{translate('ForgotPassword.helmetTitle')}</title>
      </Helmet>

      <Content>
        <AnimationContainer>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <img src={LogoGestor} alt="Logo Gestor" />

            {handleData === '' ? (
              <h1>{translate('ForgotPassword.formTitle')}</h1>
            ) : (
              ''
            )}

            {handleData === 'user' ? (
              <h1>{translate('ForgotPassword.formTitleUser')}</h1>
            ) : (
              ''
            )}

            {handleData === 'password' ? (
              <h1>{translate('ForgotPassword.formTitlePassword')}</h1>
            ) : (
              ''
            )}

            {handleData === '' ? (
              <p>{translate('ForgotPassword.formSubTitle')}</p>
            ) : (
              ''
            )}

            {handleData === 'user' ? (
              <p>{translate('ForgotPassword.formSubTitleUser')}</p>
            ) : (
              ''
            )}

            {handleData === 'password' ? (
              <p>{translate('ForgotPassword.formSubTitlePassword')}</p>
            ) : (
              ''
            )}

            {/* <Select
              placeholder={translate('ForgotPassword.selectPlaceholder')}
              onChange={HandleData}
              style={{ width: '100%' }}
            >
              <Select.Option value="user">
                {translate('ForgotPassword.userOption')}
              </Select.Option>
              <Select.Option value="password">
                {translate('ForgotPassword.passwordOption')}
              </Select.Option>
            </Select> */}

            <Input
              name="email"
              icon={FiMail}
              placeholder={translate('ForgotPassword.inputPlaceholder')}
              type="email"
            />

            <Button loading={loading} type="submit">
              {translate('ForgotPassword.buttonText')}
            </Button>
          </Form>
          <Link to="/">
            <FiLogIn />
            {translate('ForgotPassword.backText')}
          </Link>
        </AnimationContainer>
      </Content>
      <Background />
    </Container>
  )
}

export default ForgotPassword
