import styled from 'styled-components'

export const Container = styled.div`
  .avatar-uploader > .ant-upload {
    width: 175px;
    height: 175px;
  }
`

export const ContUpload = styled.div`
  padding: 30px 20px 20px 20px;
`

export const ContForm = styled.div`
  flex: 1;
  margin-top: 40px;
`
