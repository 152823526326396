/* eslint-disable @typescript-eslint/no-explicit-any */
import { PlusCircleOutlined } from '@ant-design/icons'
import { Modal, Button, Form, Input, Checkbox } from 'antd'
import React, { useState } from 'react'
import { usePause } from '~/hooks/Tasks/Pause/usePause'
import { translate } from '~/utils/locale'

const CreatePause: React.FC = () => {
  const { createPause } = usePause()

  const [form] = Form.useForm()

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [keepRegister, setKeepRegister] = useState(false)

  function onCreatePause(valueForm: any) {
    try {
      createPause(valueForm)

      form.resetFields()

      if (!keepRegister) {
        setIsModalVisible(false)
      }
    } catch (err) {
      setIsModalVisible(false)
    }
  }

  const showModal = () => {
    setIsModalVisible(true)
  }

  const closeModal = () => {
    setIsModalVisible(false)
  }

  return (
    <div>
      <Button type="primary" icon={<PlusCircleOutlined />} onClick={showModal}>
        {translate('TasksRegistrationPauseCreate.addButton')}
      </Button>
      <Modal
        title={translate('TasksRegistrationPauseCreate.modalTitle')}
        open={isModalVisible}
        onOk={closeModal}
        onCancel={closeModal}
        width="600px"
        footer={[
          <Checkbox
            key="KeepRegister"
            onChange={(e) => {
              setKeepRegister(e.target.checked)
            }}
            checked={keepRegister}
          >
            {translate('TasksRegistrationPauseCreate.keepRegisterText')}
          </Checkbox>,
          <Button key="back" onClick={closeModal}>
            {translate('TasksRegistrationPauseCreate.closeButton')}
          </Button>,
          <Button key="save" type="primary" form="pauseForm" htmlType="submit">
            {translate('TasksRegistrationPauseCreate.saveButton')}
          </Button>,
        ]}
      >
        <Form
          id="pauseForm"
          layout="vertical"
          onFinish={onCreatePause}
          form={form}
        >
          <Form.Item
            label={translate('TasksRegistrationPauseCreate.titleLabel')}
            name="title"
            rules={[
              {
                required: true,
                message: translate('TasksRegistrationPauseCreate.titleRule'),
              },
            ]}
          >
            <Input
              placeholder={translate(
                'TasksRegistrationPauseCreate.titlePlaceholder',
              )}
            />
          </Form.Item>
          <Form.Item
            label={translate('TasksRegistrationPauseCreate.descriptionLabel')}
            name="description"
            rules={[
              {
                required: true,
                message: translate(
                  'TasksRegistrationPauseCreate.descriptionRule',
                ),
              },
            ]}
          >
            <Input.TextArea />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}

export default CreatePause
