export default {
  pt: {
    ClientsContacts: {
      contactsTitle: 'Contatos de Clientes',
      totalText: 'Total de',
      contactText: 'contato de cliente',
      contactsText: 'contatos de clientes',
      newContactButton: 'Novo contato do cliente',
    },
  },
  en: {
    ClientsContacts: {
      contactsTitle: 'Customer Contacts',
      totalText: 'Total of',
      contactText: 'customer contact',
      contactsText: 'customer contacts',
      newContactButton: 'New customer contact',
    },
  },
  es: {
    ClientsContacts: {
      contactsTitle: 'Contactos de Clientes',
      totalText: 'Total de',
      contactText: 'contacto de cliente',
      contactsText: 'contactos de clientes',
      newContactButton: 'Nuevo contacto de cliente',
    },
  },
}
