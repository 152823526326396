export default {
  pt: {
    NewTasks: {
      helmetTitle: 'Nova Tarefa',
    },
  },
  en: {
    NewTasks: {
      helmetTitle: 'New Task',
    },
  },
  es: {
    NewTasks: {
      helmetTitle: 'Nueva Tarea',
    },
  },
}
