/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react'

import {
  Button,
  Modal,
  Form,
  Select,
  Row,
  Col,
  Tooltip,
  Checkbox,
  Space,
} from 'antd'
import { UsergroupAddOutlined } from '@ant-design/icons'

import { useListContributors } from '~/hooks/Contributors/useListContributors'
import { useTeam } from '~/hooks/Teams/useTeams'
import { useTeams } from '~/hooks/Teams/useListTeams'
import { translate } from '~/utils/locale'
import { Container, ContainerButton } from './styles'

interface TeamsProps {
  title: string
  id: string
  created_at: Date
}

interface TeamsForm {
  collaborator_id: string
}

interface TeamsData {
  dataTeam: TeamsProps
}

const CreateTeamCollaborator: React.FC<TeamsData> = ({ dataTeam }) => {
  const { createTeamCollaborator } = useTeam()
  const { refetch } = useTeams()

  const { data } = useListContributors()

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [keepRegister, setKeepRegister] = useState(false)

  function onCreateTeam(valueForm: TeamsForm) {
    try {
      createTeamCollaborator(dataTeam.id, valueForm.collaborator_id)
      if (!keepRegister) {
        setIsModalVisible(false)
      }
      refetch()
    } catch (err) {
      console.log(err)
    }
  }

  const showModal = () => {
    setIsModalVisible(true)
  }

  const closeModal = () => {
    setIsModalVisible(false)
  }

  return (
    <Container>
      <Tooltip title={translate('TeamsCollaborator.addTooltip')}>
        <Button
          type="primary"
          icon={<UsergroupAddOutlined />}
          onClick={showModal}
        >
          {translate('TeamsCollaborator.addButton')}
        </Button>
      </Tooltip>
      <Modal
        title={translate('TeamsCollaborator.modalTitle')}
        open={isModalVisible}
        onOk={closeModal}
        onCancel={closeModal}
        width="600px"
        footer={[
          <Space key="buttons">
            <Checkbox
              key="KeepRegister"
              onChange={(e) => {
                setKeepRegister(e.target.checked)
              }}
              checked={keepRegister}
            >
              {translate('TeamsCollaborator.keepRegisterText')}
            </Checkbox>
            <Button key="back" onClick={closeModal}>
              {translate('TeamsCollaborator.closeButton')}
            </Button>
            <ContainerButton>
              <Button
                form="teamColForm"
                key="save"
                type="primary"
                htmlType="submit"
                id="btn_save_collaborator_team"
              >
                {translate('TeamsCollaborator.saveButton')}
              </Button>
            </ContainerButton>
          </Space>,
        ]}
      >
        <Form id="teamColForm" layout="vertical" onFinish={onCreateTeam}>
          <Row gutter={14}>
            <Col span={24}>
              <Form.Item
                label={translate('TeamsCollaborator.contributorLabel')}
                name="collaborator_id"
                rules={[
                  {
                    required: true,
                    message: translate('TeamsCollaborator.contributorRule'),
                  },
                ]}
              >
                <Select
                  placeholder={translate(
                    'TeamsCollaborator.contributorPlaceholder',
                  )}
                  optionFilterProp="children"
                  showSearch
                >
                  {data?.map(
                    (contribuitors) =>
                      contribuitors.contact?.name && (
                        <Select.Option
                          key={contribuitors.id}
                          value={contribuitors.id}
                        >
                          {contribuitors.contact?.name}
                        </Select.Option>
                      ),
                  )}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </Container>
  )
}

export default CreateTeamCollaborator
