export default {
  pt: {
    ShowUsers: {
      helmetTitle: 'Visualizar usuário',
    },
  },
  en: {
    ShowUsers: {
      helmetTitle: 'View user',
    },
  },
  es: {
    ShowUsers: {
      helmetTitle: 'Ver usuario',
    },
  },
}
