/* eslint-disable no-param-reassign */
import React, { useCallback, useState } from 'react'

import dayjs from 'dayjs'

import {
  Modal,
  Form,
  Input,
  Button,
  // message,
  Checkbox,
  Row,
  Col,
  Radio,
  TimePicker,
  DatePicker,
  Select,
  Space,
  InputNumber,
} from 'antd'
import { PlusCircleOutlined } from '@ant-design/icons'

import { ScheduleTimeInput, useScale } from '~/hooks/Scales/useScale'

import useWeekdaySelector from '~/components/DataManipulation/Misc/WeekdaySelector'

import { Container } from './styles'
import { translate } from '~/utils/locale'

interface NewScaleData {
  day: dayjs.Dayjs
  checkin: dayjs.Dayjs
  checkout: dayjs.Dayjs
  description: string
  type: string
  repeat: number
  repeatAmount: number
}

const ScaleTimeAddModal: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [keepRegister, setKeepRegister] = useState(false)
  const [hideCheckout, setHideCheckout] = useState(true)
  const [repeat, setRepeat] = useState(0)

  const { render, daysSelected, setReset } = useWeekdaySelector()

  const { createScheduleTime } = useScale()

  const [form] = Form.useForm()

  const handleOk = () => {
    form.submit()
  }

  const handleCancel = useCallback(() => {
    form.resetFields()
    setIsVisible(false)
    setRepeat(0)
    setHideCheckout(true)
    setReset(true)
  }, [form, setReset])

  const onCreate = useCallback(
    async (data: NewScaleData) => {
      try {
        setConfirmLoading(true)
        if (repeat !== 0) {
          const daysRange: dayjs.Dayjs[] = []
          const validDays: dayjs.Dayjs[] = []
          const dados: ScheduleTimeInput[] = []

          for (let i = 0; i < repeat * data.repeatAmount; i += 1) {
            if (i === 0) {
              validDays.push(data.day)
            } else {
              daysRange.push(dayjs(data.day).add(i, 'days'))
            }
          }

          daysRange.forEach((day) => {
            Object.entries(daysSelected).forEach(([key, value]) => {
              if (
                dayjs(day).locale('en').format('dddd') === key &&
                value === true
              ) {
                validDays.push(day)
              }
            })
          })

          validDays.forEach((day) => {
            dados.push({
              day,
              checkin: data.checkin,
              checkout: data.checkout,
              description: data.description,
              type: data.type,
            })
          })

          await createScheduleTime(dados)
        } else {
          const dados = [
            {
              day: data.day,
              checkin: data.checkin,
              checkout: data.checkout,
              description: data.description,
              type: data.type,
            },
          ]
          await createScheduleTime(dados)
        }

        setConfirmLoading(false)
        if (!keepRegister) {
          setIsVisible(false)
        }
        form.resetFields()
        setRepeat(0)
        setHideCheckout(true)
        setReset(true)
      } catch (error) {
        setConfirmLoading(false)
      }
    },
    [repeat, daysSelected, createScheduleTime, keepRegister, form, setReset],
  )

  const showModal = () => {
    setIsVisible(true)
  }

  return (
    <>
      {/* <Tooltip title="Em manutenção"> */}
      <Button
        type="primary"
        onClick={showModal}
        icon={<PlusCircleOutlined />}
        data-testid="TimeAddModalButton"
        // disabled
      >
        {translate('ScalesTimeAddModal.addButton')}
      </Button>
      {/* </Tooltip> */}

      <Modal
        data-testid="TimeAddModal"
        title={translate('ScalesTimeAddModal.modalTitle')}
        open={isVisible}
        onOk={handleOk}
        okText={translate('ScalesTimeAddModal.okText')}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        width="750px"
        footer={[
          <Space key="footer">
            <Checkbox
              onChange={(e) => {
                setKeepRegister(e.target.checked)
              }}
              checked={keepRegister}
            >
              {translate('ScalesTimeAddModal.keepAddText')}
            </Checkbox>
            <Button key="back" onClick={handleCancel}>
              {translate('ScalesTimeAddModal.cancelButton')}
            </Button>
            <Button
              key="submit"
              type="primary"
              loading={confirmLoading}
              onClick={handleOk}
            >
              {translate('ScalesTimeAddModal.addText')}
            </Button>
          </Space>,
        ]}
      >
        <Form
          form={form}
          onFinish={onCreate}
          layout="vertical"
          initialValues={{
            repeat: 0,
            repeatAmount: 1,
          }}
        >
          <Row gutter={12}>
            <Col span={24}>
              <Form.Item
                label={<b>{translate('ScalesTimeAddModal.dayLabel')}</b>}
                name="day"
                rules={[
                  {
                    required: true,
                    message: translate('ScalesTimeAddModal.dayRule'),
                  },
                ]}
              >
                <DatePicker style={{ width: '100%' }} format="DD/MM/YYYY" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={12}>
            <Col span={12}>
              <Form.Item
                label={<b>{translate('ScalesTimeAddModal.typeLabel')}</b>}
                name="type"
                rules={[
                  {
                    required: true,
                    message: translate('ScalesTimeAddModal.typeRule'),
                  },
                ]}
              >
                <Radio.Group>
                  <Radio.Button value="work">
                    {translate('ScalesTimeAddModal.typeWork')}
                  </Radio.Button>
                  <Radio.Button value="break">
                    {translate('ScalesTimeAddModal.typeBreak')}
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item
                label={<b>{translate('ScalesTimeAddModal.checkinLabel')}</b>}
                name="checkin"
                rules={[
                  {
                    required: true,
                    message: translate('ScalesTimeAddModal.checkinRule'),
                  },
                ]}
              >
                <TimePicker
                  format="HH:mm"
                  minuteStep={15}
                  onChange={(e) => setHideCheckout(!e)}
                />
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item
                label={<b>{translate('ScalesTimeAddModal.checkoutLabel')}</b>}
                name="checkout"
                rules={[
                  {
                    required: true,
                    message: translate('ScalesTimeAddModal.checkoutRuleOne'),
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (getFieldValue('checkin') < value) {
                        return Promise.resolve()
                      }
                      return Promise.reject(
                        new Error(
                          translate('ScalesTimeAddModal.checkoutRuleTwo'),
                        ),
                      )
                    },
                  }),
                ]}
              >
                <TimePicker
                  format="HH:mm"
                  minuteStep={15}
                  disabled={hideCheckout}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={12}>
            <Col span={12}>
              <Form.Item
                label={<b>{translate('ScalesTimeAddModal.repeatLabel')}</b>}
                name="repeat"
              >
                <Select
                  value={repeat}
                  style={{ width: '100%' }}
                  onChange={(e) => setRepeat(Number(e))}
                >
                  <Select.Option value={0}>
                    {translate('ScalesTimeAddModal.repeatNever')}
                  </Select.Option>
                  <Select.Option value={7}>
                    {translate('ScalesTimeAddModal.repeatWeekly')}
                  </Select.Option>
                  <Select.Option value={30}>
                    {translate('ScalesTimeAddModal.repeatMonthly')}
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Col span={12} hidden={repeat === 0}>
              <Form.Item
                label={
                  <b>{translate('ScalesTimeAddModal.repeatAmountLabel')}</b>
                }
                name="repeatAmount"
              >
                <InputNumber min={1} max={repeat === 7 ? 4 : 12} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={12} hidden={repeat === 0}>
            <Col span={24}>
              <Container>
                <Form.Item
                  label={<b>{translate('ScalesTimeAddModal.weekdaysLabel')}</b>}
                  name="days"
                >
                  {render}
                </Form.Item>
              </Container>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Form.Item
                label={
                  <b>{translate('ScalesTimeAddModal.descriptionLabel')}</b>
                }
                name="description"
              >
                <Input.TextArea rows={2} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  )
}

export default ScaleTimeAddModal
