/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Card, Space, Table, Typography } from 'antd'
import React from 'react'
import CurrencyFormatter from '../../Misc/CurrencyFormater'
import {
  CarOutlined,
  ClockCircleOutlined,
  DownloadOutlined,
  LoadingOutlined,
} from '@ant-design/icons'

import ExcelJS from 'exceljs'
import { saveAs } from 'file-saver'
import { useTasksTeams } from '~/hooks/Teams/useListTeamsTasks'

// import { Container } from './styles';

interface Tasks {
  _id?: string
  internal_code?: string
  financialReceive?: {
    numberTasks?: number
    receive?: number
    amount_hours_receive?: number
    amount_hours_additional_receive?: number
    number_km_receive?: number
    number_km_additional_receive?: number
  }
  totalValue?: number
}

interface DataType {
  _id: string
  title: string
  collaborator?: string
  totalTasks?: number
  totalValueReceive?: number
  totalHourExtraReceive?: number
  totalValueHourExtraReceive?: number
  totalKmExtraReceive?: number
  totalValueKmExtraReceive?: number
  total?: number
  totalValues?: number
  arrayOfCounts?: Tasks[]
  colors?: string
}

const expandedRowRender = (record: DataType) => {
  const detailsColumns = [
    {
      title: 'Código da Tarefa',
      dataIndex: 'internal_code',
      key: 'internal_code',
    },
    {
      title: 'Qntd de Tarefas',
      dataIndex: 'numberTasks',
      key: 'numberTasks',
    },
    {
      title: 'Qntd Valor a Pagar',
      dataIndex: 'receive',
      key: 'receive',
    },
    {
      title: 'Qntd de Horas Extras',
      dataIndex: 'amount_hours_receive',
      key: 'amount_hours_receive',
    },
    {
      title: 'Qntd Valor de Horas Extras',
      dataIndex: 'amount_hours_additional_receive',
      key: 'amount_hours_additional_receive',
    },
    {
      title: 'Qntd de Kms Extras',
      dataIndex: 'number_km_receive',
      key: 'number_km_receive',
    },
    {
      title: 'Qntd Valor de Kms Extras',
      dataIndex: 'number_km_additional_receive',
      key: 'number_km_additional_receive',
    },
    {
      title: 'Total',
      dataIndex: 'totalValue',
      key: 'totalValue',
    },
  ]

  const expandedData = record?.arrayOfCounts?.map((item, index) => ({
    key: index.toString(),
    internal_code: item.internal_code,
    numberTasks: 1,
    receive: <CurrencyFormatter value={item?.financialReceive!.receive} />,
    amount_hours_receive: (
      <Space>
        <ClockCircleOutlined />
        <Typography.Text>
          {item.financialReceive!.amount_hours_receive}:00
        </Typography.Text>
      </Space>
    ),
    amount_hours_additional_receive: (
      <CurrencyFormatter
        value={item?.financialReceive!.amount_hours_additional_receive}
      />
    ),
    number_km_receive: (
      <Space>
        <CarOutlined />
        {item.financialReceive!.number_km_receive}
      </Space>
    ),
    number_km_additional_receive: (
      <CurrencyFormatter
        value={item?.financialReceive!.number_km_additional_receive}
      />
    ),
    totalValue: <CurrencyFormatter value={item!.totalValue} />,
  }))

  return (
    <Table
      columns={detailsColumns}
      dataSource={expandedData}
      pagination={false}
      rowKey="key"
      size="small"
      style={{ color: 'blue' }}
    />
  )
}

const FinancialReceive: React.FC = () => {
  const { teams, isFetching } = useTasksTeams()

  const columns = [
    {
      title: 'Equipe',
      key: 'title',
      dataIndex: 'title',
    },
    {
      title: 'Total de Tarefas',
      dataIndex: 'totalTasks',
      key: 'totalTasks',
    },
    {
      title: 'Total Valor a Pagar',
      dataIndex: 'totalValueReceive',
      key: 'totalValueReceive',
      render: (totalValueReceive: number) => (
        <Typography.Text>
          <CurrencyFormatter value={totalValueReceive} />
        </Typography.Text>
      ),
    },
    {
      title: 'Total de Horas Extras',
      dataIndex: 'totalHourExtraReceive',
      key: 'totalHourExtraReceive',
      render: (totalHourExtraReceive: number) => (
        <Typography.Text>
          <ClockCircleOutlined /> {totalHourExtraReceive}:00
        </Typography.Text>
      ),
    },
    {
      title: 'Total do Valor de Horas Extras',
      dataIndex: 'totalValueHourExtraReceive',
      render: (totalValueHourExtraReceive: number) => (
        <Typography.Text>
          <CurrencyFormatter value={totalValueHourExtraReceive} />
        </Typography.Text>
      ),
    },
    {
      title: 'Total de Kms Extras',
      dataIndex: 'totalKmExtraReceive',
      key: 'totalKmExtraReceive',
      render: (totalKmExtraReceive: number) => (
        <Typography.Text>
          <CarOutlined /> {totalKmExtraReceive} km
        </Typography.Text>
      ),
    },
    {
      title: 'Total do Valor de Kms Extras',
      dataIndex: 'totalValueKmExtraReceive',
      render: (totalValueKmExtraReceive: number) => (
        <Typography.Text>
          <CurrencyFormatter value={totalValueKmExtraReceive} />
        </Typography.Text>
      ),
    },
    {
      title: 'Total',
      dataIndex: 'total',
      render: (total: number) => (
        <Typography.Text>
          <CurrencyFormatter value={total} />
        </Typography.Text>
      ),
    },
  ]

  function generateColors() {
    const brilhoMinimo = 200

    const r = brilhoMinimo + Math.floor(Math.random() * (256 - brilhoMinimo))
    const g = brilhoMinimo + Math.floor(Math.random() * (256 - brilhoMinimo))
    const b = brilhoMinimo + Math.floor(Math.random() * (256 - brilhoMinimo))

    const cor = `${((1 << 24) | (r << 16) | (g << 8) | b)
      .toString(16)
      .slice(1)
      .toUpperCase()}`

    return cor
  }

  const newData = teams?.map((parent) => {
    const totalTasks = parent?.tasks?.length

    const totalValueReceive = parent?.tasks?.reduce(
      (acc, item) => acc + item?.financialReceive?.receive!,
      0,
    )

    const totalHourExtraReceive = parent?.tasks?.reduce(
      (acc, item) => acc + item.financialReceive?.amount_hours_receive!,
      0,
    )

    const totalValueHourExtraReceive = parent?.tasks?.reduce(
      (acc, item) =>
        acc + item.financialReceive?.amount_hours_additional_receive!,
      0,
    )

    const totalKmExtraReceive = parent?.tasks?.reduce(
      (acc, item) => acc + item.financialReceive?.number_km_receive!,
      0,
    )

    const totalValueKmExtraReceive = parent?.tasks?.reduce(
      (acc, item) => acc + item.financialReceive?.number_km_additional_receive!,
      0,
    )

    const sumSpecificFields = (obj: any) => {
      return (
        (obj.financialReceive.receive || 0) +
        (obj.financialReceive.amount_hours_additional_receive || 0) +
        (obj.financialReceive.number_km_additional_receive || 0)
      )
    }

    const arrayOfCounts = parent?.tasks?.map((obj) => {
      const sum = sumSpecificFields(obj)
      return { ...obj, totalValue: sum }
    })

    const total = arrayOfCounts?.reduce((acc, obj) => {
      return acc + obj.totalValue
    }, 0)

    return {
      ...parent,
      arrayOfCounts,
      totalTasks,
      totalValueReceive,
      totalHourExtraReceive,
      totalValueHourExtraReceive,
      totalKmExtraReceive,
      totalValueKmExtraReceive,
      total,
      color: generateColors(),
    }
  })

  const total = newData?.reduce((sum, item) => sum + item.total, 0)

  const generateExcel = async () => {
    const workbook = new ExcelJS.Workbook()
    const worksheet = workbook.addWorksheet('My Sheet')

    const formatCurrency = (value: number) => {
      return new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }).format(value)
    }

    worksheet.mergeCells('A2:H1')
    const titleRow = worksheet.getCell('A1')
    titleRow.value = 'Valores a para colaboradores/equipes'
    titleRow.font = {
      name: 'Arial',
      size: 14,
      bold: true,
      color: { argb: 'FFFFFFFF' },
    }
    titleRow.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '242424' },
    }
    titleRow.alignment = { vertical: 'middle', horizontal: 'center' }

    worksheet.mergeCells('A3:H4')
    const subtitleRow = worksheet.getCell('A3')
    subtitleRow.value = `Total Geral ${formatCurrency(total! / 100)}`
    subtitleRow.font = { name: 'Arial', size: 12, color: { argb: 'FFFFFFFF' } }
    subtitleRow.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '242424' },
    }
    subtitleRow.alignment = { vertical: 'middle', horizontal: 'center' }

    worksheet.addRow([])
    worksheet.addRow([])

    worksheet.getRow(6).values = [
      'Equipe',
      'Tarefas',
      'Valor a Pagar',
      'Horas Extras',
      'Valor Horas Extras',
      'Km Extra',
      'Valor Kms Extras',
      'Total',
    ]

    worksheet.columns = [
      { key: 'title', width: 20 },
      { key: 'numberTasks', width: 20 },
      { key: 'receive', width: 20 },
      { key: 'hourExtraReceive', width: 20 },
      { key: 'valueHourExtraReceive', width: 20 },
      { key: 'kmExtraReceive', width: 20 },
      { key: 'valueKmExtraReceive', width: 20 },
      { key: 'totalValue', width: 20 },
    ]

    const columnHeaderRow = worksheet.getRow(6)
    columnHeaderRow.eachCell((cell) => {
      cell.font = { name: 'Arial', bold: true, color: { argb: 'FFFFFFFF' } }
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '3969B7' },
      }
      cell.alignment = { vertical: 'middle', horizontal: 'center' }
    })

    newData?.forEach((group) => {
      const groupColor = group.color

      const categoryRow = worksheet.addRow({
        title: group.title,
        numberTasks: group.totalTasks,
        receive: `${formatCurrency(group.totalValueReceive / 100)}`,
        hourExtraReceive: group.totalHourExtraReceive,
        valueHourExtraReceive: `${formatCurrency(
          group.totalValueHourExtraReceive / 100,
        )}`,
        kmExtraReceive: group.totalKmExtraReceive,
        valueKmExtraReceive: `${formatCurrency(
          group.totalValueKmExtraReceive / 100,
        )}`,
        totalValue: `${formatCurrency(group.total / 100)}`,
      })

      categoryRow.eachCell((cell) => {
        cell.font = { name: 'Arial', bold: true }
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: groupColor },
        }
      })

      group.arrayOfCounts!.forEach((item) => {
        const row = worksheet.addRow({
          client: item.internal_code,
          numberTasks: 1,
          receive: formatCurrency(item.financialReceive?.receive! / 100),
          hourExtraReceive: item.financialReceive?.amount_hours_receive,
          valueHourExtraReceive: formatCurrency(
            item.financialReceive?.amount_hours_additional_receive! / 100,
          ),
          kmExtraReceive: item.financialReceive?.number_km_receive,
          valueKmExtraReceive: formatCurrency(
            item.financialReceive?.number_km_additional_receive! / 100,
          ),
          totalValue: formatCurrency(item.totalValue! / 100),
        })

        row.eachCell((cell) => {
          cell.font = { name: 'Arial', size: 10 }
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: groupColor },
          }
        })
      })

      worksheet.addRow({})
    })

    worksheet.eachRow((row) => {
      row.eachCell((cell) => {
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        }
        cell.alignment = { vertical: 'middle', horizontal: 'center' } // Centralizando o texto
      })
    })

    // worksheet.views = [{ state: 'frozen', ySplit: 1 }]

    const buffer = await workbook.xlsx.writeBuffer()
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })
    saveAs(blob, 'Planilha.xlsx')
  }

  return (
    <Card
      title={
        <Space>
          <b>Financeiro - Valor a pagar para colaboradores/equipes</b>

          {total ? (
            <b style={{ color: '#3969B7' }}>
              <CurrencyFormatter value={total} /> total geral
            </b>
          ) : (
            ''
          )}
        </Space>
      }
      extra={[
        <Space key="buttons">
          <Button
            type="primary"
            icon={<DownloadOutlined />}
            onClick={generateExcel}
          >
            Exportar
          </Button>
        </Space>,
      ]}
      bordered={false}
      style={{ width: '100%', padding: 30 }}
    >
      <Table
        columns={columns}
        dataSource={newData}
        expandedRowRender={expandedRowRender}
        rowKey="_id"
        size="small"
        loading={{
          indicator: <LoadingOutlined />,
          spinning: isFetching,
          size: 'large',
        }}
      />
    </Card>
  )
}

export default FinancialReceive
