export default {
  pt: {
    useListTasks: {
      getErrorMessage: 'Erro ao visualizar tarefas.',
      createSuccessMessage: 'Tarefa criada com sucesso!',
      createAddressClientSuccessMessage:
        'Endereço cadastrado no cliente com sucesso!',
      createAddressClientErrorMessage:
        'Erro ao cadastrar o endereço no cliente. Informe o número do cliente!',
      createErrorMessage: 'Erro ao criar tarefas.',
      deleteSuccessMessage: 'Tarefa deletada com sucesso!',
      deleteErrorMessage: 'Erro ao excluir tarefas.',
    },
  },
  en: {
    useListTasks: {
      getErrorMessage: 'Error viewing tasks.',
      createSuccessMessage: 'Task created successfully!',
      createAddressClientSuccessMessage:
        'Address registered with the client successfully!',
      createAddressClientErrorMessage:
        'Error registering the address in the client.',
      createErrorMessage: 'Error creating tasks.',
      deleteSuccessMessage: 'Task successfully deleted!',
      deleteErrorMessage: 'Error deleting tasks.',
    },
  },
  es: {
    useListTasks: {
      getErrorMessage: 'Error al ver las tareas.',
      createSuccessMessage: '¡Tarea creada con éxito!',
      createAddressClientSuccessMessage:
        '¡Dirección registrada con el cliente con éxito!',
      createAddressClientErrorMessage:
        'Error al registrar la dirección en el cliente.',
      createErrorMessage: 'Error al crear tareas.',
      deleteSuccessMessage: '¡Tarea eliminada con éxito!',
      deleteErrorMessage: 'Error al eliminar tareas.',
    },
  },
}
