export default {
  pt: {
    Form: {
      helmetTitle: 'Formulário',
    },
  },
  en: {
    Form: {
      helmetTitle: 'Form',
    },
  },
  es: {
    Form: {
      helmetTitle: 'Forma',
    },
  },
}
