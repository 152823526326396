/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, createContext, useState, useCallback } from 'react'
import { useQuery } from 'react-query'

import { message } from 'antd'

import api from '~/services/api'

import { useCompanies } from '~/hooks/Companies/useCompanies'
import { useLocale } from '~/hooks/locale/useLocale'

import { ShowError } from '~/utils/errors/apiErrors'
import { translate } from '~/utils/locale'

interface ICreateForm {
  title: string
  description?: string
}

interface IUpdateForm {
  title: string
  description?: string
}

export interface FormProps {
  id: string
  title: string
  description?: string
  company_id: string
  questions: Array<{
    id: string
    title: string
    description: string | null
    type: string
    order: number
    options: string[] | null
    is_required: boolean
    created_at: Date
    updated_at: Date
  }>
  created_at: Date
  updated_at: Date
}

interface FilterOptionsProps {
  title?: string
}
interface FormsContextData {
  forms: FormProps[]
  createForm(data: ICreateForm): void
  updateForm(data: IUpdateForm, formId: string): void
  deleteForm(formId: string): void
  isLoading: boolean
  refetch: () => void
  isFetching: boolean
  formsTotal?: number
  handleUpdateFilters: (newFilterOptions: any) => void
  handleResetFilters: () => void
  filterOptions: FilterOptionsProps
  offset: number
  changeOffset: (value: number) => void
  limit: number
  changeLimit: (value: number) => void
}

const FormsContext = createContext<FormsContextData>({} as FormsContextData)

export const TaskFormsProvider: React.FC = ({ children }) => {
  const [filterOptions, setFilterOptions] = useState<FilterOptionsProps>(() => {
    const filter = localStorage.getItem('@Gstor:formsFilterOptions')

    if (filter) {
      return JSON.parse(filter)
    }

    return {} as FilterOptionsProps
  })

  const { selectedCompany } = useCompanies()
  const { locale } = useLocale()
  const [offset, setOffset] = useState(0)
  const [limit, setLimit] = useState(10)

  const [formsTotal, setFormsTotal] = useState<number>()

  const {
    data: forms,
    isLoading,
    refetch,
    isFetching,
  } = useQuery(
    [
      `forms${selectedCompany}`,
      selectedCompany,
      formsTotal,
      filterOptions,
      limit,
      offset,
    ],

    async () => {
      try {
        const response = await api.get(`/company/${selectedCompany}/forms`, {
          params: {
            ...filterOptions,
            offset,
            limit,
          },
        })

        const { data } = response

        setFormsTotal(data.total)

        return data.results
      } catch (err: any) {
        ShowError(
          err.message,
          translate('useTaskForms.getErrorMessage'),
          locale,
        )
      }
    },
  )

  const changeOffset = (value: number) => {
    setOffset(value)
  }

  const changeLimit = (value: number) => {
    setLimit(value)
  }

  async function createForm(data: ICreateForm) {
    try {
      await api.post(`/company/${selectedCompany}/forms`, data)

      refetch()
      message.success(translate('useTaskForms.createSuccessMessage'))
    } catch (err: any) {
      ShowError(
        err.message,
        translate('useTaskForms.createErrorMessage'),
        locale,
      )
    }
  }

  async function updateForm(data: IUpdateForm, formId: string) {
    try {
      await api.put(`/company/${selectedCompany}/forms/${formId}`, data)

      message.success(translate('useTaskForms.updateSuccessMessage'))
      refetch()
    } catch (err: any) {
      ShowError(
        err.message,
        translate('useTaskForms.updateErrorMessage'),
        locale,
      )
    }
  }

  async function deleteForm(formId: string) {
    try {
      await api.delete(`/company/${selectedCompany}/forms/${formId}`)

      refetch()
      message.success(translate('useTaskForms.deleteSuccessMessage'))
    } catch (err: any) {
      ShowError(
        err.message,
        translate('useTaskForms.deleteErrorMessage'),
        locale,
      )
    }
  }

  const handleUpdateFilters = useCallback((newFilterOptions) => {
    const newFilters = {
      title: newFilterOptions?.title,
    }
    setFilterOptions(newFilters)
    localStorage.setItem(
      '@Gstor:formsFilterOptions',
      JSON.stringify(newFilters),
    )
  }, [])

  const handleResetFilters = useCallback(() => {
    localStorage.removeItem('@Gstor:formsFilterOptions')
    setFilterOptions({})
  }, [])

  return (
    <FormsContext.Provider
      value={{
        forms,
        createForm,
        updateForm,
        deleteForm,
        isLoading,
        refetch,
        isFetching,
        formsTotal,
        handleUpdateFilters,
        handleResetFilters,
        filterOptions,
        offset,
        limit,
        changeLimit,
        changeOffset,
      }}
    >
      {children}
    </FormsContext.Provider>
  )
}

export function useTaskForms(): FormsContextData {
  const context = useContext(FormsContext)

  if (!context) {
    throw new Error('useTaskForms must be used within an TaskFormsProvider')
  }

  return context
}
