export default {
  pt: {
    ClientsContactsTable: {
      nameColumn: 'Nome',
      emailColumn: 'E-mail',
      officeColumn: 'Cargo',
      clientColumn: 'Cliente',
      clientTooltip: 'Ver cliente',
      responsibleColumn: 'Responsável',
      responsibleYes: 'Sim',
      responsibleNo: 'Não',
      actionsColumn: 'Ações',
      deletePopconfirm: 'Deseja mesmo deletar o contato?',
      deleteTooltip: 'Deletar',
    },
  },
  en: {
    ClientsContactsTable: {
      nameColumn: 'Name',
      emailColumn: 'Email',
      officeColumn: 'Office',
      clientColumn: 'Customer',
      clientTooltip: 'View customer',
      responsibleColumn: 'Responsible',
      responsibleYes: 'Yes',
      responsibleNo: 'No',
      actionsColumn: 'Actions',
      deletePopconfirm: 'Do you really want to delete the contact?',
      deleteTooltip: 'Delete',
    },
  },
  es: {
    ClientsContactsTable: {
      nameColumn: 'Nombre',
      emailColumn: 'Correo electrónico',
      officeColumn: 'Oficina',
      clientColumn: 'Cliente',
      clientTooltip: 'Ver cliente',
      responsibleColumn: 'Responsable',
      responsibleYes: 'Sí',
      responsibleNo: 'No',
      actionsColumn: 'Acciones',
      deletePopconfirm: '¿Realmente desea eliminar el contacto?',
      deleteTooltip: 'Borrar',
    },
  },
}
