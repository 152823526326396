import { Tabs, TabsProps, Typography } from 'antd'
import React from 'react'
import { Container } from './styles'

import Initial from './Tasks/Initial'
import TasksMapDashboard from './Tasks/Maps/Tasks'
import ContributorsDashboardMap from './Tasks/Maps/Contributors'
import DashboardCardsContributors from './Contributors/Cards'

// import { Container } from './styles';

const DashboardTabs: React.FC = () => {
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Tarefas',
      children: <Initial />,
    },
    {
      key: '2',
      label: 'Colaboradores',
      children: <DashboardCardsContributors />,
    },
    {
      key: '3',
      label: 'Mapa com tarefas',
      children: <TasksMapDashboard type={true} />,
    },
    {
      key: '4',
      label: 'Mapa com colaboradores',
      children: <ContributorsDashboardMap />,
    },
  ]

  return (
    <Container>
      <Typography.Title level={2}>Dashboard</Typography.Title>
      <Tabs defaultActiveKey="1" items={items} />
    </Container>
  )
}

export default DashboardTabs
