/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  useContext,
  createContext,
  useState,
  useCallback,
  useEffect,
} from 'react'
import { useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'

import { message } from 'antd'
import dayjs from 'dayjs'

import api from '~/services/api'

import { useCompanies } from '~/hooks/Companies/useCompanies'
import { useLocale } from '~/hooks/locale/useLocale'

import { ShowError } from '~/utils/errors/apiErrors'
import { translate } from '~/utils/locale'

interface EquipmentsTasks {
  id: string
}

export interface TaskListProps {
  checkin_type: string
  createdAt: Date
  value: number
  date: string
  duration_time: string
  external_code: string
  is_enabled: string
  priority: string
  _id: string
  hours: string
  currentDate: string
  internal_code: string
  current_status: string
  customer_id: string
  task_type_id: string
  responsible: {
    id: string
    type: string
    data: {
      contact: {
        name: string
      }
      title: string
    }
  }
  customer: {
    id: string
    contact: {
      name: string
    }
    parent_customer: string
  }
  taskType: {
    _id: string
    title: string
    description: string
    runtime: string
    valueTask: number
  }
  address: {
    city: string
    id: string
    location: {
      x: number
      y: number
    }
    neighborhood: string
    number: number
    state: string
    street: string
    zip_code: string
    complement?: string
  }
  equipments: EquipmentsTasks[]
  description: string
  note: string
}

interface FilterOptionsProps {
  responsibleContributors?: string
  responsibleTeams?: string
  address?: string
  customers_id?: string
  internal_code?: string
  current_status?: string
  tasks_types_id?: string
  equipment_id?: string
  external_code?: string
  priority?: string
  note?: string
  fromDate?: dayjs.Dayjs
  toDate?: dayjs.Dayjs
}

interface TaskListContextData {
  tasks?: TaskListProps[]
  // allTasks?: TaskListProps[]
  scheduleTasks?: TaskListProps[]
  createTask(
    valueForm: object | undefined,
    keepRegister?: boolean,
  ): Promise<void>
  createTeamTask(
    valueForm: object | undefined,
    keepRegister?: boolean,
  ): Promise<void>
  createParentCustomerTask(
    valueForm: object | undefined,
    parentCustomerId?: string,
  ): Promise<void>
  createAddressTaskOnClient(
    valueForm: object | undefined,
    contactId?: string,
  ): void
  deleteTask(taskId?: string): void
  deleteTasks(taskId?: string): void
  isLoading: boolean
  refetch: () => void
  handleUpdateFilters: (newFilterOptions: any) => void
  handleResetFilters: () => void
  filterOptions: FilterOptionsProps
  setTaskCollaborator(value: string): void
  taskCollaborator?: string
  isFetching: boolean
  setPageSize(value: any): void
  setNumberPage(value: any): void
  tasksTotal?: number
}

const TaskListContext = createContext<TaskListContextData>(
  {} as TaskListContextData,
)

export const TaskListProvider: React.FC = ({ children }) => {
  const { selectedCompany } = useCompanies()
  const { locale } = useLocale()
  const history = useHistory()
  const [taskCollaborator, setTaskCollaborator] = useState<string>()

  const [pageSize, setPageSize] = useState<number>(20)
  const [numberPage, setNumberPage] = useState<number>(1)

  const [tasksTotal, setTasksTotal] = useState<number>()

  const [response, setResponse] = useState<any>()

  const [filterOptions, setFilterOptions] = useState<FilterOptionsProps>(() => {
    const filter = localStorage.getItem('@Gstor:tasksFilterOptions')

    if (filter) {
      return JSON.parse(filter)
    }

    return {} as FilterOptionsProps
  })

  const {
    data: tasks,
    isLoading,
    refetch,
    isFetching,
  } = useQuery(
    [
      `Lista de Tarefas${selectedCompany}`,
      selectedCompany,
      filterOptions,
      pageSize,
      numberPage,
    ],

    async () => {
      try {
        if (!selectedCompany) {
          return undefined
        }

        const paginationLimit = numberPage * pageSize
        const paginationOffset = paginationLimit - pageSize

        const response = await api.get(`/company/${selectedCompany}/tasks`, {
          params: {
            offset: paginationOffset,
            limit: pageSize,
            responsibleContributors: filterOptions?.responsibleContributors,
            responsibleTeams: filterOptions?.responsibleTeams,
            address: filterOptions?.address,
            customers_id: filterOptions?.customers_id,
            internal_code: filterOptions?.internal_code,
            current_status: filterOptions?.current_status,
            equipment_id: filterOptions?.equipment_id,
            external_code: filterOptions?.external_code,
            tasks_types_id: filterOptions?.tasks_types_id,
            priority: filterOptions?.priority,
            note: filterOptions?.note,
            fromDate: filterOptions?.fromDate
              ? dayjs.utc(filterOptions?.fromDate).format()
              : dayjs().subtract(3, 'months').startOf('day').format(),
            toDate: filterOptions?.toDate
              ? dayjs.utc(filterOptions?.toDate).format()
              : dayjs().add(3, 'months').startOf('day').format(),
          },
        })

        const { data } = response

        setTasksTotal(data.total)

        return data.results
      } catch (err: any) {
        ShowError(
          err.message,
          translate('useListTasks.getErrorMessage'),
          locale,
        )
      }
    },
  )

  // const { data: allTasks } = useQuery(
  //   [`Lista de Todas as Tarefas${selectedCompany}`, selectedCompany],

  //   async () => {
  //     try {
  //       if (!selectedCompany) {
  //         return undefined
  //       }

  //       const response = await api.get(`/company/${selectedCompany}/tasks/all`)

  //       const { data } = response

  //       return data.results
  //     } catch (err: any) {
  //       ShowError(
  //         err.message,
  //         translate('useListTasks.getErrorMessage'),
  //         locale,
  //       )
  //     }
  //   },
  //   {
  //     staleTime: 300000, // 5 minutos
  //   },
  // )

  const { data: scheduleTasks, refetch: refetchScheduleTasks } = useQuery(
    [
      `Lista de Tarefas Calendário${selectedCompany}`,
      selectedCompany,
      filterOptions,
    ],

    async () => {
      try {
        if (!selectedCompany) {
          return undefined
        }

        const paginationLimit = numberPage * pageSize
        const paginationOffset = paginationLimit - pageSize

        const response = await api.get(
          `/company/${selectedCompany}/tasks/schedule`,
          {
            params: {
              offset: paginationOffset,
              limit: paginationLimit,
              responsibleContributors: filterOptions?.responsibleContributors,
              responsibleTeams: filterOptions?.responsibleTeams,
              address: filterOptions?.address,
              customers_id: filterOptions?.customers_id,
              internal_code: filterOptions?.internal_code,
              current_status: filterOptions?.current_status,
              equipment_id: filterOptions?.equipment_id,
              external_code: filterOptions?.external_code,
              tasks_types_id: filterOptions?.tasks_types_id,
              priority: filterOptions?.priority,
              note: filterOptions?.note,
              fromDate: filterOptions?.fromDate
                ? dayjs.utc(filterOptions?.fromDate).format()
                : dayjs().subtract(3, 'months').startOf('day').format(),
              toDate: filterOptions?.toDate
                ? dayjs.utc(filterOptions?.toDate).format()
                : dayjs().add(3, 'months').startOf('day').format(),
            },
          },
        )

        const { data } = response

        setTasksTotal(data.total)

        return data.results
      } catch (err: any) {
        ShowError(
          err.message,
          translate('useListTasks.getErrorMessage'),
          locale,
        )
      }
    },
  )

  async function createTask(valueForm: object, keepRegister?: boolean) {
    try {
      const createTaskResponse = await api.post(
        `/company/${selectedCompany}/task`,
        valueForm,
      )

      refetch()
      message.success(translate('useListTasks.createSuccessMessage'))

      if (!keepRegister) {
        history.push('tarefas')
      }

      const data = {
        tasks: [{ id: createTaskResponse.data._id }],
      }

      setResponse(data)
    } catch (err: any) {
      ShowError(
        err.message,
        translate('useListTasks.createErrorMessage'),
        locale,
      )
    }
  }

  async function createTeamTask(valueForm: object, keepRegister?: boolean) {
    try {
      await api.post(`/company/${selectedCompany}/team-task`, valueForm)

      refetch()

      if (!keepRegister) {
        history.push('tarefas')
      }
    } catch (err: any) {}
  }

  async function createParentCustomerTask(
    valueForm: object,
    parentCustomerId?: string,
  ) {
    try {
      await api.post(
        `/company/${selectedCompany}/parentCustomer/${parentCustomerId}/task`,
        response,
      )

      refetch()
    } catch (err: any) {}
  }

  async function createAddressTaskOnClient(
    valueForm: object,
    contactId?: string,
  ) {
    try {
      const apiCreateResponse = await api.post(
        `/company/${selectedCompany}/address`,
        valueForm,
      )

      await api.post(
        `/company/${selectedCompany}/contact/${contactId}/address/${apiCreateResponse.data.id}`,
        {
          type: 'personal',
          notes: '',
        },
      )

      refetch()
      message.success(
        translate('useListTasks.createAddressClientSuccessMessage'),
      )
    } catch (err: any) {
      ShowError(
        err.message,
        translate('useListTasks.createAddressClientErrorMessage'),
        locale,
      )
    }
  }

  async function deleteTask(taskId: string) {
    try {
      await api.delete(`/company/${selectedCompany}/task/${taskId}`)

      refetch()
      refetchScheduleTasks()
      message.success(translate('useListTasks.deleteSuccessMessage'))
    } catch (err: any) {
      ShowError(
        err.message,
        translate('useListTasks.deleteErrorMessage'),
        locale,
      )
    }
  }

  async function deleteTasks(taskId: string) {
    try {
      await api.delete(`/company/${selectedCompany}/task/${taskId}`)

      refetch()
      refetchScheduleTasks()
    } catch (err: any) {
      ShowError(
        err.message,
        translate('useListTasks.deleteErrorMessage'),
        locale,
      )
    }
  }

  const handleUpdateFilters = useCallback((newFilterOptions) => {
    const newFilters = {
      responsibleContributors: newFilterOptions?.responsibleContributors,
      responsibleTeams: newFilterOptions?.responsibleTeams,
      address: newFilterOptions?.address,
      customers_id: newFilterOptions?.customers_id,
      internal_code: newFilterOptions?.internal_code,
      current_status: newFilterOptions?.current_status,
      equipment_id: newFilterOptions?.equipment_id,
      external_code: newFilterOptions?.external_code,
      tasks_types_id: newFilterOptions?.tasks_types_id,
      priority: newFilterOptions?.priority,
      note: newFilterOptions?.note,
      fromDate: dayjs(newFilterOptions?.fromDate).startOf('day'),
      toDate: dayjs(newFilterOptions?.toDate).endOf('day'),
    }
    setFilterOptions(newFilters)
    localStorage.setItem(
      '@Gstor:tasksFilterOptions',
      JSON.stringify(newFilters),
    )
  }, [])

  const handleResetFilters = useCallback(() => {
    localStorage.removeItem('@Gstor:tasksFilterOptions')
    setFilterOptions({})
  }, [])

  useEffect(() => {
    refetch()
  }, [selectedCompany, refetch])

  return (
    <TaskListContext.Provider
      value={{
        tasks,
        // allTasks,
        scheduleTasks,
        createTask,
        createParentCustomerTask,
        createTeamTask,
        createAddressTaskOnClient,
        deleteTask,
        deleteTasks,
        isLoading,
        refetch,
        handleUpdateFilters,
        handleResetFilters,
        filterOptions,
        setTaskCollaborator,
        taskCollaborator,
        isFetching,
        setPageSize,
        setNumberPage,
        tasksTotal,
      }}
    >
      {children}
    </TaskListContext.Provider>
  )
}

export function useListTask(): TaskListContextData {
  const context = useContext(TaskListContext)

  if (!context) {
    throw new Error('useAuth must be used within an AutoProvider')
  }

  return context
}
