/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, createContext, useState } from 'react'
import { useQuery } from 'react-query'

import { message } from 'antd'

import { useCompanies } from '~/hooks/Companies/useCompanies'
import { useLocale } from '~/hooks/locale/useLocale'

import { ShowError } from '~/utils/errors/apiErrors'
import { translate } from '~/utils/locale'

import { useShowPayment } from './useShowPayment'
import api from '~/services/api'

interface ClientPaymentProps {
  id: string
  name: string
  email: string
  company: string
  mobilePhone: string
}

interface ClientPaymentContextData {
  clientPayment?: ClientPaymentProps
  createClientPayment(valueForm: object | undefined): void
  //   updatePause(valueForm: object | undefined, pauseId: string): void
  //   deletePause(pauseId: string): void
  isLoading: boolean
  refetch: () => void
  isFetching: boolean
  setDisabled(value: boolean): void
  disabled?: boolean
}

const ClientPaymentContext = createContext<ClientPaymentContextData>(
  {} as ClientPaymentContextData,
)

export const ClientPaymentProvider: React.FC = ({ children }) => {
  const { selectedCompany } = useCompanies()
  const { locale } = useLocale()

  const { showPayment } = useShowPayment()

  const [disabled, setDisabled] = useState(true)

  const {
    data: clientPayment,
    isLoading,
    refetch,
    isFetching,
  } = useQuery(
    [`Clients Payment Details${selectedCompany}`, selectedCompany],

    async () => {
      const paymentCustomer = showPayment?.[0].customer

      if (paymentCustomer) {
        try {
          const response = await api.get(
            `payment/clientDetails/${paymentCustomer}`,
          )

          const { data } = response

          return data.data
        } catch (err: any) {
          ShowError(err.message, translate('usePause.getErrorMessage'), locale)
        }
      }
    },
  )

  async function createClientPayment(valueForm: object) {
    try {
      await api.post(`payment/clientDetails`, valueForm)

      refetch()
      message.success('Cliente Criado com Sucesso')
    } catch (err: any) {
      ShowError(err.message, translate('usePause.createErrorMessage'), locale)
    }
  }

  return (
    <ClientPaymentContext.Provider
      value={{
        clientPayment,
        createClientPayment,
        isLoading,
        refetch,
        isFetching,
        setDisabled,
        disabled,
      }}
    >
      {children}
    </ClientPaymentContext.Provider>
  )
}

export function useClientPayment(): ClientPaymentContextData {
  const context = useContext(ClientPaymentContext)

  if (!context) {
    throw new Error('useAuth must be used within an AutoProvider')
  }

  return context
}
