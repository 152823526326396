import React from 'react'
import { Space, Skeleton } from 'antd'

import { Container, LeftContent, CenterContent, RightContent } from './styles'

const CalendarHeaderSkeleton: React.FC = () => {
  return (
    <Container>
      <LeftContent>
        <Space>
          <Skeleton.Button active size="default" shape="circle" />
          <Skeleton.Button active size="default" shape="circle" />
          <Skeleton.Button active size="default" shape="circle" />
          <Skeleton.Button active size="default" shape="circle" />
        </Space>
      </LeftContent>
      <CenterContent>
        <Skeleton.Input active size="default" />
      </CenterContent>
      <RightContent>
        <Space>
          <Skeleton.Button active size="default" shape="square" />
          <Skeleton.Button active size="default" shape="square" />
          <Skeleton.Button active size="default" shape="square" />
        </Space>
      </RightContent>
    </Container>
  )
}

export default CalendarHeaderSkeleton
