/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { useContext, createContext, useRef, useState } from 'react'

import { TourProps } from 'antd'
import { useHistory } from 'react-router-dom'

interface ClientTourContextData {
  createClientRef: any
  createNameClientRef: any
  createEmailClientRef: any
  createPhoneClientRef: any
  createResponsibleClientRef: any
  createClientPrincipalRef: any
  saveClientRef: any

  clientSteps: any
  createClientSteps: any
  listClientSteps: any

  openCreateClient?: boolean
  setOpenCreateClient(value: any): void
  openListClient?: boolean
  setOpenListClient(value: any): void

  openTask?: boolean
  setOpenTask(value: any): void
}

const ClientTourContext = createContext<ClientTourContextData>(
  {} as ClientTourContextData,
)

export const ClientTourProvider: React.FC = ({ children }) => {
  const createClientRef = useRef<HTMLButtonElement>(null)
  const createNameClientRef = useRef<HTMLButtonElement>(null)
  const createEmailClientRef = useRef<HTMLButtonElement>(null)
  const createPhoneClientRef = useRef<HTMLButtonElement>(null)
  const createResponsibleClientRef = useRef<HTMLButtonElement>(null)
  const createClientPrincipalRef = useRef<HTMLButtonElement>(null)
  const saveClientRef = useRef<HTMLButtonElement>(null)

  const clientRef = useRef<HTMLButtonElement>(null)

  const history = useHistory()

  const [openCreateClient, setOpenCreateClient] = useState(false)
  const [openListClient, setOpenListClient] = useState(false)
  const [openTask, setOpenTask] = useState(false)

  function OpenCreateClient() {
    setOpenCreateClient(true)

    history.push('clientes/novo')
  }

  function OpenTask() {
    setOpenTask(true)

    history.push('tarefas')
  }

  const clientSteps: TourProps['steps'] = [
    {
      title: (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <h2 style={{ marginBottom: 0 }}>Clientes</h2>{' '}
          <span style={{ marginLeft: 8, color: 'gray' }}>Tarefas</span>
        </div>
      ),
      description: 'Clicando nesse botão você consegue criar um cliente.',
      target: () => createClientRef.current!,
      nextButtonProps: { onClick: OpenCreateClient },
    },
  ]

  const createClientSteps: TourProps['steps'] = [
    {
      title: (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <h2 style={{ marginBottom: 0 }}>Criar um Cliente</h2>{' '}
          <span style={{ marginLeft: 8, color: 'gray' }}>Tarefas</span>
        </div>
      ),
      description: 'Insira o nome do cliente.',
      target: () => createNameClientRef.current!,
    },
    {
      title: (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <h2 style={{ marginBottom: 0 }}>Criar um Cliente</h2>{' '}
          <span style={{ marginLeft: 8, color: 'gray' }}>Tarefas</span>
        </div>
      ),
      description: 'Agora, informe o e-mail do cliente.',
      target: () => createEmailClientRef.current!,
    },
    {
      title: (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <h2 style={{ marginBottom: 0 }}>Criar um Cliente</h2>{' '}
          <span style={{ marginLeft: 8, color: 'gray' }}>Tarefas</span>
        </div>
      ),
      description: 'Informe o telefone do cliente.',
      target: () => createPhoneClientRef.current!,
    },
    {
      title: (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <h2 style={{ marginBottom: 0 }}>Criar um Cliente</h2>{' '}
          <span style={{ marginLeft: 8, color: 'gray' }}>Tarefas</span>
        </div>
      ),
      description: 'E por fim, o colaborador responsável por esse cliente',
      target: () => createResponsibleClientRef.current!,
    },
    {
      title: (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <h2 style={{ marginBottom: 0 }}>Criar um Cliente</h2>{' '}
          <span style={{ marginLeft: 8, color: 'gray' }}>Tarefas</span>
        </div>
      ),
      description:
        'Se necessário, tem a possibilidade de adicionar um cliente principal.',
      target: () => createClientPrincipalRef.current!,
    },
    {
      title: (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <h2 style={{ marginBottom: 0 }}>Criar um Cliente</h2>{' '}
          <span style={{ marginLeft: 8, color: 'gray' }}>Tarefas</span>
        </div>
      ),
      description: 'Agora vamos salvar o cliente.',
      target: () => saveClientRef.current!,
    },
  ]

  const listClientSteps: TourProps['steps'] = [
    {
      title: (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <h2 style={{ marginBottom: 0 }}>Tarefas</h2>{' '}
          <span style={{ marginLeft: 8, color: 'gray' }}>Tarefas</span>
        </div>
      ),
      description:
        'Agora que já temos o tipo de tarefa cadastrado. Vamos cadastrar as tarefas.',
      target: () => clientRef.current!,
      nextButtonProps: { onClick: OpenTask },
    },
  ]

  return (
    <ClientTourContext.Provider
      value={{
        createClientRef,
        createNameClientRef,
        createEmailClientRef,
        createPhoneClientRef,
        createClientPrincipalRef,
        createResponsibleClientRef,
        saveClientRef,
        clientSteps,
        createClientSteps,
        openCreateClient,
        setOpenCreateClient,
        openListClient,
        setOpenListClient,
        listClientSteps,
        openTask,
        setOpenTask,
      }}
    >
      {children}
    </ClientTourContext.Provider>
  )
}

export function useClientTour(): ClientTourContextData {
  const context = useContext(ClientTourContext)

  if (!context) {
    throw new Error('useAuth must be used within an AutoProvider')
  }

  return context
}
