/* eslint-disable camelcase */
import {
  FileUnknownOutlined,
  LoadingOutlined,
  PlusCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons'
import { Button, Modal, Popconfirm, Space, Table, Tooltip, message } from 'antd'
import React, { useState } from 'react'
import { translate } from '~/utils/locale'

import { ColumnsType } from 'antd/lib/table'
import { Link } from 'react-router-dom'

import Text from 'antd/lib/typography/Text'
import { useListParentClients } from '~/hooks/Clients/useListParentCustomer'
import { useClients } from '~/hooks/Clients/useClients'
import { useParentInClientsCustomer } from '~/hooks/Clients/useParentCustomersInClients'

// import { Container } from './styles';

interface ContactProps {
  name: string
  email: string
  celular: string
  phone: string
  _id: string
}

export interface ParentClientsProps {
  _id: string
  cnpj: string
  cpf: string
  fantasyName: string
  companyName: string
  contact: ContactProps
}

const ModalParentClient: React.FC = () => {
  const { clients, addChildrenCustomer } = useClients()

  const {
    parentClients,
    isFetching,
    clientsTotal,
    setNumberPage,
    setPageSize,
  } = useListParentClients()
  const [isModalVisible, setIsModalVisible] = useState(false)

  const { updateParentClient } = useClients()
  const { refetch } = useParentInClientsCustomer()

  async function UpdateParentCustomerInClient(parent_cnpj: string) {
    const data = {
      parent_cnpj,
    }

    const customer = {
      customers: [{ id: clients?.id }],
    }

    message.success('Cliente adicionado com sucesso!')
    await addChildrenCustomer(customer, parent_cnpj)
    await updateParentClient(data)

    setIsModalVisible(false)

    refetch()
    window.location.reload()
  }

  const columns: ColumnsType<ParentClientsProps> = [
    {
      title: translate('ClientsTable.columnActions'),
      align: 'center',
      key: 'remove',
      render: (record) => (
        <Popconfirm
          title={translate('TasksCreateModalClient.addClientPopconfirm')}
          onConfirm={() => UpdateParentCustomerInClient(record._id)}
        >
          <Tooltip title={translate('TasksCreateModalClient.addClientTooltip')}>
            <Button type="primary" shape="circle" icon={<PlusOutlined />} />
          </Tooltip>
        </Popconfirm>
      ),
    },
    {
      title: 'Razão Social / Nome',
      align: 'left',
      dataIndex: 'companyName',
      key: 'companyName',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (name: string, record) => (
        <Link to={`/clientePrincipal/visualizar/${record._id}`}>{name}</Link>
      ),
    },
    {
      title: 'Nome Fantasia',
      dataIndex: 'fantasyName',
      align: 'center',
      key: 'fantasyName',
      render: (fantasyName: string) => (
        <>
          {fantasyName || (
            <Text type="secondary">
              <Space size="small">
                <FileUnknownOutlined />
                N/A
              </Space>
            </Text>
          )}
        </>
      ),
    },
    {
      title: 'CNPJ',
      dataIndex: 'cnpj',
      align: 'center',
      key: 'cnpj',
      render: (cnpj: string) => (
        <>
          {cnpj || (
            <Text type="secondary">
              <Space size="small">
                <FileUnknownOutlined />
                N/A
              </Space>
            </Text>
          )}
        </>
      ),
    },
    {
      title: 'Nome',
      align: 'center',
      key: 'name',
      dataIndex: ['contact', 'name'],
      render: (name: string) => (
        <>
          {name || (
            <Text type="secondary">
              <Space size="small">
                <FileUnknownOutlined />
                N/A
              </Space>
            </Text>
          )}
        </>
      ),
    },
    {
      title: translate('ClientsTable.columnEmail'),
      align: 'center',
      key: 'email',
      dataIndex: ['contact', 'email'],
      render: (email: string) => (
        <>
          {email || (
            <Text type="secondary">
              <Space size="small">
                <FileUnknownOutlined />
                N/A
              </Space>
            </Text>
          )}
        </>
      ),
    },
  ]

  const showModal = () => {
    setIsModalVisible(true)
  }

  const closeModal = () => {
    setIsModalVisible(false)
  }

  return (
    <>
      <Tooltip title="Adicionar Cliente Principal">
        <Button
          onClick={showModal}
          type="primary"
          icon={<PlusCircleOutlined />}
        >
          Adicionar Cliente Principal
        </Button>
      </Tooltip>
      <Modal
        title="Cliente Principal"
        open={isModalVisible}
        onOk={closeModal}
        onCancel={closeModal}
        width="900px"
        style={{ top: 30 }}
        footer={[
          <Button key="back" onClick={closeModal}>
            {translate('TasksRegistrationTypeTasksAbout.closeButton')}
          </Button>,
        ]}
      >
        <Table
          rowKey={(record) => record._id}
          loading={{
            indicator: <LoadingOutlined />,
            spinning: isFetching,
            size: 'large',
          }}
          pagination={{
            total: clientsTotal,
            showSizeChanger: true,
            pageSizeOptions: ['10', '20', '50', '100', '200'],
            defaultPageSize: 10,
            showTotal: (total, range) => `${range[0]} - ${range[1]} / ${total}`,
            onChange: (page, pageSize) => {
              setPageSize(pageSize!)
              setNumberPage(page)
            },
          }}
          columns={columns}
          dataSource={parentClients}
          scroll={{ x: 1000 }}
        />
      </Modal>
    </>
  )
}

export default ModalParentClient
