/* eslint-disable @typescript-eslint/no-explicit-any */
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import { Modal, Button, Form, Input, Row, Col, Tooltip, Space } from 'antd'

import React, { useState } from 'react'
import { useListTemplate } from '~/hooks/Equipments/Especification/ListTemplate'
import { translate } from '~/utils/locale'
import { ContainerButton } from '../styles'

// import { Container } from './styles';

interface SpecificationProps {
  attribute: string
  value: string
}

interface UpdateTemplateProps {
  id: string
  name: string
  specifications: SpecificationProps[]
}

interface TemplateData {
  updateTemplate: UpdateTemplateProps
}

const UpdateTemplate: React.FC<TemplateData> = ({ updateTemplate }) => {
  const { updateTemplates } = useListTemplate()

  const [isModalVisible, setIsModalVisible] = useState(false)

  const initialData = {
    name: updateTemplate.name,
    specifications: updateTemplate.specifications.map((a) => {
      return { attribute: a.attribute, value: a.value }
    }),
  }

  function onUpdateTemplate(data: any) {
    try {
      updateTemplates(data, updateTemplate.id)
      setIsModalVisible(false)
    } catch (err) {
      console.log(err)
    }
  }

  const showModal = () => {
    setIsModalVisible(true)
  }

  const closeModal = () => {
    setIsModalVisible(false)
  }

  return (
    <>
      <Tooltip title={translate('EquipmentsTemplateUpdate.editTooltip')}>
        <Button
          type="primary"
          ghost
          shape="circle"
          icon={<EditOutlined />}
          style={{ color: '#3C8ACB' }}
          onClick={showModal}
        />
      </Tooltip>
      <Modal
        title={translate('EquipmentsTemplateUpdate.modalTitle')}
        open={isModalVisible}
        onOk={closeModal}
        onCancel={closeModal}
        width="800px"
        style={{
          top: 40,
          overflowY: 'scroll',
          maxHeight: 'calc(100vh - 200px)',
        }}
        footer={[
          <Space key="buttons">
            <Button key="back" onClick={closeModal}>
              {translate('EquipmentsTemplateUpdate.closeButton')}
            </Button>
            <ContainerButton>
              <Button
                key="save"
                type="primary"
                form="formUpdateTemplate"
                htmlType="submit"
                id="btn_update_template_equipments"
              >
                {translate('EquipmentsTemplateUpdate.saveButton')}
              </Button>
            </ContainerButton>
          </Space>,
        ]}
      >
        <Form
          autoComplete="off"
          layout="vertical"
          id="formUpdateTemplate"
          initialValues={initialData}
          onFinish={onUpdateTemplate}
        >
          <Row>
            <Col span={12}>
              <Form.Item
                label={translate('EquipmentsTemplateUpdate.nameLabel')}
                name="name"
              >
                <Input
                  placeholder={translate(
                    'EquipmentsTemplateUpdate.namePlaceholder',
                  )}
                />
              </Form.Item>
            </Col>
          </Row>

          <Form.List name="specifications">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Row
                    key={key}
                    gutter={12}
                    style={{ display: 'flex' }}
                    align="middle"
                  >
                    <Tooltip
                      title={translate(
                        'EquipmentsTemplateUpdate.deleteTooltip',
                      )}
                    >
                      <Button
                        ghost
                        shape="circle"
                        icon={<DeleteOutlined />}
                        onClick={() => remove(name)}
                        size="small"
                        danger
                      />
                    </Tooltip>
                    <Col span={11}>
                      <Form.Item
                        {...restField}
                        label={translate(
                          'EquipmentsTemplateUpdate.attributeLabel',
                        )}
                        name={[name, 'attribute']}
                      >
                        <Input
                          placeholder={translate(
                            'EquipmentsTemplateUpdate.attributePlaceholder',
                          )}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={11}>
                      <Form.Item
                        {...restField}
                        label={translate('EquipmentsTemplateUpdate.valueLabel')}
                        name={[name, 'value']}
                      >
                        <Input
                          placeholder={translate(
                            'EquipmentsTemplateUpdate.valuePlaceholder',
                          )}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    {translate('EquipmentsTemplateUpdate.addAttribute')}
                    Adicionar Atributo
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form>
      </Modal>
    </>
  )
}

export default UpdateTemplate
