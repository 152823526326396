export default {
  pt: {
    ServiceStatus: {
      executionCardMessage: 'Serviço de execução de tarefa',
      executionCardDescription:
        'O serviço de Whatsapp para execução de tarefa se encontra fora do ar.',
      createCardMessage: 'Serviço de criação de tarefa',
      createCardDescription:
        'O serviço de Whatsapp para criação de tarefa se encontra fora do ar.',
      errorTooltip:
        'Olá! Parece que o seu sistema não está conectado com o Whatsapp no momento. Para solucionar isso, por favor, entre em contato com o nosso suporte para que possamos ajudá-lo a resolver o problema. \nNota: É importante lembrar que, mesmo sem a conexão com o Whatsapp, você pode enviar o link para seus colaboradores para que eles executem o serviço sem qualquer problema. Então, se precisar de ajuda, não hesite em nos contatar! Estamos aqui para ajudá-lo.',
    },
  },
  en: {
    ServiceStatus: {
      executionCardMessage: 'Task execution service',
      executionCardDescription:
        'The Whatsapp service for task execution is offline.',
      createCardMessage: 'Task creation service',
      createCardDescription: 'Whatsapp service for task creation is offline.',
      errorTooltip:
        "Hello! It looks like your system is not connected with Whatsapp at the moment. To resolve this, please contact our support so that we can help you resolve the issue. \nNote: It is important to remember that, even without a Whatsapp connection, you can send the link to your collaborators so that they can run the service without any problems. So, if you need help, don't hesitate to contact us! We are here to help you.",
    },
  },
  es: {
    ServiceStatus: {
      executionCardMessage: 'Servicio de ejecución de tareas',
      executionCardDescription:
        'El servicio de Whatsapp para la ejecución de tareas está fuera de línea.',
      createCardMessage: 'Servicio de creación de tareas',
      createCardDescription:
        'El servicio de Whatsapp para la creación de tareas está fuera de línea.',
      errorTooltip:
        '¡Hola! Parece que su sistema no está conectado con Whatsapp en este momento. Para resolver esto, comuníquese con nuestro soporte para que podamos ayudarlo a resolver el problema. \nNota: Es importante recordar que, incluso sin conexión a Whatsapp, puedes enviar el enlace a tus colaboradores para que ejecuten el servicio sin problemas. Así que, si necesitas ayuda, ¡no dudes en contactarnos! Estamos aquí para ayudarte.',
    },
  },
}
