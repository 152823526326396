import { Tag } from 'antd'
import React from 'react'

import { Container } from './styles'

const CardTickets: React.FC = () => {
  return (
    <Container>
      <p className="subtitle">Ticket #4313</p>

      <h3>Dúvida quanto a instalação do produto</h3>
      <p className="text">Solicitado por Camila Santos</p>
      <p className="text">Vence em dois dias</p>

      <div className="tags">
        <Tag color="green">Aberto</Tag>
        <Tag color="red">Alta</Tag>
      </div>
    </Container>
  )
}

export default CardTickets
