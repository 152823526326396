import styled from 'styled-components'

export const Container = styled.div`
  .wrapper {
    display: flex;
    align-items: center;
  }

  h4 {
    margin-bottom: 0px;
  }

  .text {
    margin: 10px 0 0 20px;

    h3 {
      color: var(--primary-initial-text);
    }

    p {
      color: var(--secondary-initial-text);
    }
  }

  .tag {
    padding: 10px;
    border-radius: 5px;
    text-align: center;
  }

  #groups_permissions {
    border: 1px solid var(--primary-color);
    background: #eaf1f7;

    h4,
    svg {
      color: var(--primary-color);
    }
  }

  #groups_members {
    border: 1px solid var(--secondary-color);
    background: #eaf6ed;

    h4,
    svg {
      color: var(--secondary-color);
    }
  }
`

export const Members = styled.div`
  border: 1px solid #eeeeee;
  border-radius: 2px;
  padding: 10px;
  height: 250px;
  max-height: 250px;
  overflow-y: scroll;
`
