/* eslint-disable prettier/prettier */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-underscore-dangle */
import React, { Fragment, useCallback, useState } from 'react'
import { Link } from 'react-router-dom'

import {
  Avatar,
  Button,
  Col,
  Divider,
  Modal,
  Popconfirm,
  Row,
  Tabs,
  Tag,
  Tooltip,
  Typography,
} from 'antd'
import { MapContainer, Marker, TileLayer } from 'react-leaflet'

import { BuildOutlined } from '@ant-design/icons'
import { TasksWrapper, Equipments } from './styles'
import { useListTask } from '~/hooks/Tasks/useListTasks'
import { useTaskType } from '~/hooks/Tasks/TaskType/useTaskType'
import { useListContributors } from '~/hooks/Contributors/useListContributors'
import { useTeams } from '~/hooks/Teams/useListTeams'
import { useListClients } from '~/hooks/Clients/useListClients'
import { useListEquipments } from '~/hooks/Equipments/ListEquipmentsHooks'
import { translate } from '~/utils/locale'
import { useCompanies } from '~/hooks/Companies/useCompanies'

interface IEquipmentsTasks {
  id: string
}

interface ITaskList {
  checkin_type: string
  createdAt: Date
  date: string
  duration_time: string
  external_code: string
  is_enabled: string
  priority: string
  _id: string
  hours: string
  currentDate: string
  internal_code: string
  current_status: string
  customer_id: string
  task_type_id: string
  responsible: {
    id: string
    type: string
  }
  address: {
    city: string
    id: string
    location: {
      x: number
      y: number
    }
    neighborhood: string
    number: number
    state: string
    street: string
    zip_code: string
  }
  equipments: IEquipmentsTasks[]
}

interface ITaskCalendarModal {
  event: ITaskList
  visible: boolean
  setVisible: React.Dispatch<React.SetStateAction<boolean>>
}

const TaskCalendarModal: React.FC<ITaskCalendarModal> = ({
  event,
  visible,
  setVisible,
}) => {
  const { deleteTask } = useListTask()
  const { taskType } = useTaskType()
  const { data } = useListContributors()
  const { teams } = useTeams()
  const { clients } = useListClients()
  const { listEquipments } = useListEquipments()

  const { selectedCompany } = useCompanies()

  const { Text } = Typography
  const { TabPane } = Tabs

  const [shortUrl, setShortUrl] = useState('')

  const handleShortenUrl = async (longUrl: string) => {
    const accessToken = process.env.REACT_APP_BITLY_ACCESS_TOKEN
    const response = await fetch('https://api-ssl.bitly.com/v4/shorten', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ long_url: longUrl }),
    })
    const data = await response.json()
    setShortUrl(data.link)
  }

  const handleDelete = async () => {
    deleteTask(event?._id)
    handleCancel()
  }

  const handleOk = () => {
    setVisible(false)
  }

  const handleCancel = useCallback(() => {
    setVisible(false)
  }, [setVisible])

  return (
    <Modal
      title={translate('TasksCalendarModal.modalTitle')}
      open={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      width="900px"
      style={{ top: 30, overflowY: 'scroll', maxHeight: 'calc(100vh - 200px)' }}
      footer={[
        <Button key="back" onClick={handleCancel}>
          {translate('TasksCalendarModal.closeButton')}
        </Button>,

        <>
          <Popconfirm
            title={translate('TasksCalendarModal.deletePopconfirm')}
            onConfirm={handleDelete}
            key={event?._id}
          >
            <Tooltip title={translate('TasksCalendarModal.deleteTooltip')}>
              <Button
                key={event?._id}
                type="primary"
                danger
                style={{ margin: '0 8px 0 8px' }}
              >
                {translate('TasksCalendarModal.deleteButton')}
              </Button>
            </Tooltip>
          </Popconfirm>
          <Link to={`/tarefas/visualizar/${event?._id}`}>
            <Button key="back" type="primary">
              {translate('TasksCalendarModal.detailsButton')}
            </Button>
          </Link>
        </>,
      ]}
    >
      <Tabs defaultActiveKey="1">
        <TabPane tab={translate('TasksCalendarModal.detailsTab')} key="1">
          <TasksWrapper>
            <Fragment key={event?._id}>
              <Row gutter={12}>
                <Col span={12}>
                  <div className="text">
                    <Text strong>
                      {translate('TasksCalendarModal.taskResponsible')}
                    </Text>
                    {event?.responsible?.type === 'collaborator' ? (
                      <>
                        {data?.map((c) => (
                          <div key={c.id}>
                            {c.id === event?.responsible.id ? (
                              <Text>{c.contact.name}</Text>
                            ) : (
                              ''
                            )}
                          </div>
                        ))}
                      </>
                    ) : (
                      ''
                    )}

                    {event?.responsible?.type === 'team' ? (
                      <>
                        {teams?.map((c) => (
                          <div key={c.id}>
                            {c.id === event?.responsible.id ? (
                              <Text>{c.title}</Text>
                            ) : (
                              ''
                            )}
                          </div>
                        ))}
                      </>
                    ) : (
                      ''
                    )}
                  </div>
                </Col>
                <Col span={12}>
                  <div className="text">
                    <Text strong>
                      {translate('TasksCalendarModal.taskClient')}
                    </Text>
                    {clients?.map((c) => (
                      <Fragment key={c.id}>
                        {c.id === event?.customer_id ? (
                          <Text>{c.contact.name}</Text>
                        ) : (
                          ''
                        )}
                      </Fragment>
                    ))}
                  </div>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col span={12}>
                  <div className="text">
                    <Text strong>
                      {translate('TasksCalendarModal.currentDate')}
                    </Text>
                    <Text>{event?.currentDate}</Text>
                  </div>
                </Col>
                <Col span={12}>
                  <div className="text">
                    <Text strong>
                      {translate('TasksCalendarModal.durationTime')}
                    </Text>
                    <Text>{event?.duration_time}</Text>
                  </div>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col span={12}>
                  <div className="text">
                    <Text strong>
                      {translate('TasksCalendarModal.checkinType')}
                    </Text>
                    <Text>{translate('TasksCalendarModal.manualText')}</Text>
                  </div>
                </Col>
                <Col span={12}>
                  <div className="text">
                    <Text strong>
                      {translate('TasksCalendarModal.priorityText')}
                    </Text>
                    {event?.priority === 'low' ? (
                      <Tag color="green">
                        {translate('TasksCalendarModal.priorityLow')}
                      </Tag>
                    ) : (
                      ''
                    )}
                    {event?.priority === 'medium' ? (
                      <Tag color="yellow">
                        {translate('TasksCalendarModal.priorityMedium')}
                      </Tag>
                    ) : (
                      ''
                    )}
                    {event?.priority === 'high' ? (
                      <Tag color="red">
                        {translate('TasksCalendarModal.priorityHigh')}
                      </Tag>
                    ) : (
                      ''
                    )}
                  </div>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col span={12}>
                  <div className="text">
                    <Text strong>
                      {translate('TasksCalendarModal.taskType')}
                    </Text>
                    {taskType?.map((t) => (
                      <Fragment key={t._id}>
                        {t._id === event?.task_type_id ? (
                          <Text>{t.title}</Text>
                        ) : (
                          ''
                        )}
                      </Fragment>
                    ))}
                  </div>
                </Col>
                <Col span={12}>
                  <div className="text">
                    <Text strong>
                      {translate('TasksCalendarModal.statusText')}
                    </Text>
                    {event?.is_enabled === 'false' ? (
                      <Tag>
                        {translate('TasksCalendarModal.statusInactive')}
                      </Tag>
                    ) : (
                      <Tag color="green">
                        {translate('TasksCalendarModal.statusActive')}
                      </Tag>
                    )}
                  </div>
                </Col>
              </Row>

              <Row>
                <Col span={12}>
                  <div className="text">
                    <Text strong>
                      {translate('TasksCalendarModal.externalCode')}
                    </Text>
                    <Text>{event?.external_code}</Text>
                  </div>
                </Col>
                <Col span={12}>
                  <div className="text">
                    <Text strong>
                      {translate('TasksCalendarModal.internalCode')}
                    </Text>
                    <Text>{event?.internal_code}</Text>
                  </div>
                </Col>
              </Row>
            </Fragment>
          </TasksWrapper>

          <Row gutter={4} align="middle">
            <Col>
              {!shortUrl ? (
                <Button
                  onClick={() =>
                    handleShortenUrl(`https://tasks.gstor.com.br/?auth=${selectedCompany},${event.responsible?.id}`)
                  }
                >
                  Link da Tarefa
                </Button>
              ) : (
                <Col>
                  {shortUrl && (
                    <Typography.Link
                      href={shortUrl}
                      target="_blank"
                      style={{ fontSize: 16 }}
                      copyable
                    >
                      {shortUrl}
                    </Typography.Link>
                  )}
                </Col>
              )}
            </Col>
          </Row>
        </TabPane>
        <TabPane tab="Endereço" key="2">
          <Fragment key={event?._id}>
            <p>
              {`${translate('TasksCalendarModal.zipcodeText')} ${event?.address
                ?.zip_code}`}
            </p>
            <p>
              {`${translate('TasksCalendarModal.addressText')} ${event?.address
                ?.street}, ${event?.address?.number} - ${event?.address
                ?.neighborhood}`}
            </p>
            <p style={{ marginBottom: 10 }}>
              {`${translate('TasksCalendarModal.cityText')} ${event?.address
                ?.city} - ${event?.address?.state}`}
            </p>
            <MapContainer
              center={[event?.address?.location.x, event?.address?.location.y]}
              zoom={13}
              scrollWheelZoom={false}
              style={{ height: '250px', width: '100%' }}
            >
              <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              {event?.address?.location.x !== 0 &&
                event?.address?.location.y !== 0 && (
                  <Marker
                    position={[
                      event?.address?.location.x,
                      event?.address?.location.y,
                    ]}
                  />
                )}
            </MapContainer>
          </Fragment>
        </TabPane>
        <TabPane tab={translate('TasksCalendarModal.equipmentsTab')} key="3">
          <Equipments>
            {event?.equipments?.map((e) => (
              <div key={e.id}>
                {listEquipments?.map((l) => (
                  <div key={l.id}>
                    {e.id === l.id ? (
                      <>
                        <div className="equipments">
                          <Avatar
                            icon={<BuildOutlined />}
                            style={{ background: '#3C8ACB' }}
                          />
                          <div>
                            <h4>{l.name}</h4>
                            <p>
                              {translate('TasksCalendarModal.identifierText')}
                              {l.identifier}
                            </p>
                          </div>
                        </div>
                        <Divider />
                      </>
                    ) : (
                      ''
                    )}
                  </div>
                ))}
              </div>
            ))}
          </Equipments>
        </TabPane>
      </Tabs>
    </Modal>
  )
}

export default TaskCalendarModal
