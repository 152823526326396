export default {
  pt: {
    ScalesCalendarDrawer: {
      successMessage: 'Horário atualizado com sucesso!',
      errorMessage: 'Erro ao realizar atualização do horário.',
      editTooltip: 'Editar Horário',
      drawerTitle: 'Preencha as informações de horários',
      typeLabel: 'Tipo de horário',
      typeLRule: 'Por favor selecione um tipo horário',
      typeWork: 'Trabalho',
      typeBreak: 'Pausa',
      checkinLabel: 'Hora de entrada',
      checkinRule: 'Por favor insira a hora de entrada',
      checkoutLabel: 'Hora de saída',
      checkoutRule: 'Por favor insira a hora de entrada',
      descriptionLabel: 'Descrição',
      cancelButton: 'Cancelar',
      saveButton: 'Salvar',
      saveCheckbox: 'Atualizar todos os horários semelhantes?',
    },
  },
  en: {
    ScalesCalendarDrawer: {
      successMessage: 'Timetable updated successfully!',
      errorMessage: 'Error performing time update.',
      editTooltip: 'Edit Schedule',
      drawerTitle: 'Fill in the timetable information',
      typeLabel: 'Time type',
      typeLRule: 'Please select a time type',
      typeWork: 'Work',
      typeBreak: 'Break',
      checkinLabel: 'Check-in',
      checkinRule: 'Please enter check-in time',
      checkoutLabel: 'Check-out',
      checkoutRule: 'Please enter check-out time',
      descriptionLabel: 'Description',
      cancelButton: 'Cancel',
      saveButton: 'Save',
      saveCheckbox: 'Update all similar schedule times?',
    },
  },
  es: {
    ScalesCalendarDrawer: {
      successMessage: '¡Horario actualizado con éxito!',
      errorMessage: 'Error al realizar la actualización de la hora.',
      editTooltip: 'Editar Horario',
      drawerTitle: 'Rellena la información del horario',
      typeLabel: 'Tipo de tiempo',
      typeRule: 'Por favor seleccione un tipo de tiempo',
      typeWork: 'Trabajo',
      typeBreak: 'Descanso',
      checkinLabel: 'Hora de entrada',
      checkinRule: 'Por favor, introduzca la hora de entrada',
      checkoutLabel: 'Hora de Salida',
      checkoutRule: 'Por favor, introduzca la hora de salida',
      descriptionLabel: 'Descripción',
      cancelButton: 'Cancelar',
      saveButton: 'Guardar',
      saveCheckbox: 'Actualizar todos los horarios similares?',
    },
  },
}
