/* eslint-disable react/jsx-curly-newline */
import { LoadingOutlined, UserOutlined } from '@ant-design/icons'
import { Avatar, Card, Tooltip, List } from 'antd'
import { PageHeader } from '@ant-design/pro-components'
import { format } from 'date-fns'
import React from 'react'
import { Link } from 'react-router-dom'
import { useStatus } from '~/hooks/Status/useStatus'

import { LoadingIndicator } from '~/pages/Scale/EditScale/styles'
import { createLetterAvatar } from '~/utils/letter'
import { translate } from '~/utils/locale'
import UpdateStatus from '../Update'

import { Container, ListAbout } from './styles'

const StatusDetails: React.FC = () => {
  const { isLoading, status } = useStatus()

  if (isLoading || !status) {
    return (
      <LoadingIndicator>
        <LoadingOutlined style={{ fontSize: 64, margin: 'auto' }} spin />
      </LoadingIndicator>
    )
  }

  const formattedDate = format(
    new Date(status.created_at),
    `dd/MM/yyyy '${translate('StatusDetails.dateString')}' HH:mm'h'`,
  )

  return (
    <>
      <PageHeader
        className="site-page-header"
        onBack={() => window.history.back()}
        title={translate('StatusDetails.pageHeaderTitle')}
        subTitle={status.title}
      />
      <Card
        bordered={false}
        title={translate('StatusDetails.cardTitle')}
        extra={<UpdateStatus dataStatus={status} />}
        style={{
          display: 'inline-block',
          width: '100%',
        }}
      >
        <Container>
          <div className="wrapper">
            {status.color !== null ? (
              <Avatar
                shape="square"
                size={155}
                style={{
                  fontSize: 40,
                  background: '#EAEAEA',
                  color: `${status.color}`,
                }}
              >
                {createLetterAvatar(`${status.title}`)}
              </Avatar>
            ) : (
              <Avatar
                shape="square"
                size={155}
                style={{
                  fontSize: 40,
                  background: '#EAEAEA',
                  color: '#108ee9',
                }}
              >
                {createLetterAvatar(`${status.title}`)}
              </Avatar>
            )}
            <div className="text">
              <h3>{status.title}</h3>
              <p>
                {translate('StatusDetails.createdText')}
                &nbsp;
                {formattedDate}
              </p>
              <p>
                {status.type === 'enabled'
                  ? translate('StatusDetails.enabledText')
                  : ''}
                {status.type === 'enabled_with_restrictions'
                  ? translate('StatusDetails.enabledWithRestrictionsText')
                  : ''}
                {status.type === 'blocked'
                  ? translate('StatusDetails.blockedText')
                  : ''}
                {status.type === 'custom'
                  ? translate('StatusDetails.customText')
                  : ''}
              </p>
              <p>
                {status.description !== null ? (
                  <p>
                    {translate('StatusDetails.descriptionText')}
                    &nbsp;
                    {status.description}
                  </p>
                ) : (
                  'Nenhuma descrição informada.'
                )}
              </p>
            </div>
          </div>
        </Container>
      </Card>
      <Card
        bordered={false}
        title={translate('StatusDetails.statusModalTitle')}
        style={{
          display: 'inline-block',
          width: '100%',
          marginTop: 10,
        }}
      >
        <ListAbout>
          <List
            itemLayout="horizontal"
            dataSource={status.contributors}
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta
                  avatar={
                    <Avatar
                      style={{ background: `${status.color}`, fontSize: 14 }}
                      icon={<UserOutlined />}
                    />
                  }
                  title={item?.contact?.name}
                  description={
                    <Tooltip
                      title={translate('StatusDetails.contributorTooltip')}
                    >
                      <Link
                        to={`/colaboradores/visualizar/colaborador/${item.id}/contato/${item.contact.id}`}
                      >
                        {translate('StatusDetails.contributorButton')}
                      </Link>
                    </Tooltip>
                  }
                />
              </List.Item>
            )}
          />
        </ListAbout>
      </Card>
    </>
  )
}

export default StatusDetails
