export default {
  pt: {
    ClientsChargeTab: {
      cardTitle: 'Informações de Cobrança',
      icmsText: 'Contribuinte do ICMS',
      icmsNull: 'Nenhuma informação',
      icmsNotNull: 'Sim',
      billingEmailText: 'E-mail de Cobrança',
      billingEmailNull: 'Nenhum e-mail de cobrança informado',
      municipalRegistrationText: 'Inscrição Municipal',
      municipalRegistrationNull: 'Nenhuma inscrição informada',
      stateRegistrationText: 'Inscrição Estadual',
      stateRegistrationNull: 'Nenhuma inscrição informada',
      chargeAddressesTitle: 'Endereços de Cobrança',
      panelHeader: 'Endereço de Cobrança',
      zipcodeLabel: 'CEP',
      stateLabel: 'Estado',
      cityLabel: 'Cidade',
      neighborhoodLabel: 'Bairro',
      streetLabel: 'Logradouro',
      numberLabel: 'Número',
      complementLabel: 'Complemento',
      typeLabel: 'Tipo',
      billingText: 'Cobrança',
      observationLabel: 'Observação',
      chargeAddressesNull: 'Nenhum endereço de cobrança foi fornecido.',
    },
  },
  en: {
    ClientsChargeTab: {
      cardTitle: 'Billing Information',
      icmsText: 'ICMS taxpayer',
      icmsNull: 'No information',
      icmsNotNull: 'Yes',
      billingEmailText: 'Billing Email',
      billingEmailNull: 'No billing email reported',
      municipalRegistrationText: 'Municipal registration',
      municipalRegistrationNull: 'No registration informed',
      stateRegistrationText: 'State registration',
      stateRegistrationNull: 'No registration informed',
      chargeAddressesTitle: 'Billing Addresses',
      panelHeader: 'Billing address',
      zipcodeLabel: 'ZIPCODE',
      stateLabel: 'State',
      cityLabel: 'City',
      neighborhoodLabel: 'Neighborhood',
      streetLabel: 'Street',
      numberLabel: 'Number',
      complementLabel: 'Complement',
      typeLabel: 'Type',
      billingText: 'Billing',
      observationLabel: 'Observation',
      chargeAddressesNull: 'No billing address provided.',
    },
  },
  es: {
    ClientsChargeTab: {
      cardTitle: 'Datos de facturación',
      icmsText: 'Contribuyente ICMS',
      icmsNull: 'Sin información',
      icmsNotNull: 'Sí',
      billingEmailText: 'Correo Electrónico de Facturas',
      billingEmailNull:
        'No se ha informado de ningún correo electrónico de facturación.',
      municipalRegistrationText: 'Registro Municipal',
      municipalRegistrationNull: 'Sin registro informado',
      stateRegistrationText: 'Registro Estadual',
      stateRegistrationNull: 'Sin registro informado',
      chargeAddressesTitle: 'Direcciones de facturación',
      panelHeader: 'Dirección de facturación',
      zipcodeLabel: 'Código Postal',
      stateLabel: 'Estado',
      cityLabel: 'Ciudad',
      neighborhoodLabel: 'Barrio',
      streetLabel: 'Calle',
      numberLabel: 'Número',
      complementLabel: 'Complementar',
      typeLabel: 'Tipo',
      billingText: 'Cobrar',
      observationLabel: 'Observación',
      chargeAddressesNull: 'No se proporcionó la dirección de facturación.',
    },
  },
}
