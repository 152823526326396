import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
  }

  :root {

    --primary-color: #3C8ACB;
    --secondary-color: #55B86D;
    --terciary-color: #17EAD9;
    --quartenary-color: #3969B7;
    --config-color: #696969;
    --link-color: #2AA494;
    --color-white: #ffffff;


    --color-danger: #D82143;


    --primary-initial-bg: #FFF;
    --secondary-initial-bg: #F5F5F5;


    --primary-initial-button: #3C8ACB;


    --primary-initial-text: #5F5959;
    --secondary-initial-text: #8F8F8F;
    --terciary-initial-text: #4BBF7D;


    --primary-initial-input: #D9D9D9;
  }

  body {
    background: var(--secondary-initial-bg);
    color: var(--primary-initial-text);
    -webkit-font-smoothing: antialised;
  }

  body, input, button {
    font-family: 'Roboto', serif;
    font-size: 16px;
  }

  h1, h2, h3, h4, h5, h6, strong {
    font-weight: 500;
  }

  button {
    cursor: pointer;
    border: none;
  }

  a {
    text-decoration: none;
  }

  p {
    margin-bottom: 0px;
  }

  ::-webkit-scrollbar-button {
    height: 0;
    width: 0
  }
  ::-webkit-scrollbar-thumb {
      background: #8BA7CE;
      border: 8px #fff;
      border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: var(--primary-color);
    }
    ::-webkit-scrollbar-thumb:active {
      background: var(--primary-color);
    }
  ::-webkit-scrollbar-track {
    background: #d9e3f0;
    border: 9px #fff;
    border-radius: 10px;
    margin: 6px 0
  }
  ::-webkit-scrollbar-track:hover {
    background: #d9e3f0
  }
  ::-webkit-scrollbar-track:active {
    background: #d9e3f0
  }

  ::-webkit-scrollbar-corner {
    background: 0 0
  }

  ::-webkit-scrollbar {
    height: 8px;
    width: 8px
  }

  .ant-divider {
    line-height: 0px;
  }

  .ant-modal-body {
    padding: 10px 24px 10px 24px;
  }

  .ant-list-vertical .ant-list-item-meta-title {
    margin-bottom: 0px;
  }

  .PhoneInputInput {
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    line-height: 1.5715;
    padding: 4px 11px;
    font-size: 14px;
  }

  .ant-page-header-heading-title {
    color: #202A34;
    font-size: 18px;
  }

  .ant-card {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  }

  .ant-page-header {
    padding: 0 24px 10px 24px;
  }

  .ant-layout-sider
   {
    background: #0A0B0C;
  }

  .ant-menu-dark .ant-menu-inline.ant-menu-sub {
    background: #0F1112;
  }
  .ant-menu.ant-menu-dark, .ant-menu-dark,
  .ant-menu-sub, .ant-menu.ant-menu-dark,
  .ant-menu-sub {
    background: #0A0B0C;
  }
`
