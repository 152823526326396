/* eslint-disable @typescript-eslint/no-explicit-any */
import { PlusCircleOutlined } from '@ant-design/icons'
import { Modal, Button, Form, Input, Checkbox, Space } from 'antd'
import React, { useState } from 'react'
import { useSegments } from '~/hooks/Segments/useSegments'
import { translate } from '~/utils/locale'
import { ContainerButton } from '../styles'

const CreateSegments: React.FC = () => {
  const { createSegments } = useSegments()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [keepRegister, setKeepRegister] = useState(false)

  const [form] = Form.useForm()

  function onCreateSegments(valueForm: any) {
    try {
      createSegments(valueForm)
      if (!keepRegister) {
        setIsModalVisible(false)
      }
      form.resetFields()
    } catch (err) {
      setIsModalVisible(false)
    }
  }

  const showModal = () => {
    setIsModalVisible(true)
  }

  const closeModal = () => {
    setIsModalVisible(false)
  }

  return (
    <div>
      <Button type="primary" icon={<PlusCircleOutlined />} onClick={showModal}>
        {translate('SegmentsCreate.addButton')}
      </Button>
      <Modal
        title={translate('SegmentsCreate.modalTitle')}
        open={isModalVisible}
        onOk={closeModal}
        onCancel={closeModal}
        footer={[
          <Space key="buttons">
            <Checkbox
              key="KeepRegister"
              onChange={(e) => {
                setKeepRegister(e.target.checked)
              }}
              checked={keepRegister}
            >
              {translate('SegmentsCreate.keepRegisterText')}
            </Checkbox>
            <Button key="back" onClick={closeModal}>
              {translate('SegmentsCreate.closeButton')}
            </Button>
            <ContainerButton>
              <Button
                key="save"
                type="primary"
                form="onFormSegments"
                htmlType="submit"
                id="btn_create_segments"
              >
                {translate('SegmentsCreate.registerButton')}
              </Button>
            </ContainerButton>
          </Space>,
        ]}
      >
        <Form
          id="onFormSegments"
          layout="vertical"
          onFinish={onCreateSegments}
          form={form}
        >
          <Form.Item
            label={translate('SegmentsCreate.titleLabel')}
            name="title"
            rules={[
              {
                required: true,
                message: translate('SegmentsCreate.titleRule'),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={translate('SegmentsCreate.descriptionLabel')}
            name="description"
            rules={[
              {
                required: true,
                message: translate('SegmentsCreate.descriptionRule'),
              },
            ]}
          >
            <Input.TextArea />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}

export default CreateSegments
