export default {
  pt: {
    ScalesClientsList: {
      successMessage: 'Cliente desvinculado da escala com sucesso!',
      errorMessage: 'Erro ao desvincular cliente da escala.',
      deletePopconfirm: 'Deseja mesmo desatribuir o cliente da escala?',
      deleteTooltip: 'Desatribuir Cliente',
    },
  },
  en: {
    ScalesClientsList: {
      successMessage: 'Client unlinked from scale successfully!',
      errorMessage: 'Error unlinking client from scale.',
      deletePopconfirm:
        'Do you really want to unassign the client from the scale?',
      deleteTooltip: 'Unassign Customer',
    },
  },
  es: {
    ScalesClientsList: {
      successMessage: '¡El cliente se desvinculó de la báscula con éxito!',
      errorMessage: 'Error al desvincular el cliente de la escala.',
      deletePopconfirm: '¿Realmente desea desasignar al cliente de la escala?',
      deleteTooltip: 'Cancelar asignación de cliente',
    },
  },
}
