/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/require-default-props */
import { DeleteFilled, LoadingOutlined } from '@ant-design/icons'
import { Card, Popconfirm, Space, Tooltip, Button, Table } from 'antd'

import { ColumnsType } from 'antd/lib/table'
import Text from 'antd/lib/typography/Text'

import React from 'react'

import { useTask } from '~/hooks/Tasks/useTask'
import { translate } from '~/utils/locale'

import CreateEquipmentTask from './Create'

interface EquipmentsProps {
  id: string
  name: string
  created_at: Date
  expiration_date: Date
  identifier: number
  is_enabled: boolean
  category: {
    id: string
    title: string
  }
}

interface EquipmentTaskProps {
  isVisualization?: boolean
  isFetching: boolean
  taskEquipment: any[]
}

const EquipmentsTask: React.FC<EquipmentTaskProps> = ({
  isVisualization = false,
  isFetching,
  taskEquipment,
}) => {
  const { task, deleteEquipmentTask, isLoading } = useTask()

  async function deleteEquipment(equipmentId: string) {
    await deleteEquipmentTask(equipmentId)
  }

  const columns: ColumnsType<EquipmentsProps> = [
    {
      title: translate('TasksTabsEquipments.nameColumn'),
      dataIndex: 'name',
      align: 'left',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: translate('TasksTabsEquipments.identifierColumn'),
      dataIndex: 'identifier',
      align: 'center',
      key: 'codigo',
    },
    {
      title: translate('TasksTabsEquipments.categoryColumn'),
      dataIndex: ['category', 'title'],
      align: 'center',
      key: 'categoria',
    },
    {
      title: translate('TasksTabsEquipments.actionsColumn'),
      align: 'center',
      key: 'remove',
      render: (record) => (
        <Space size="middle">
          <Popconfirm
            title={translate('TasksTabsEquipments.deletePopconfirm')}
            onConfirm={() => deleteEquipment(record.id)}
          >
            <Tooltip title={translate('TasksTabsEquipments.deleteTooltip')}>
              <Button ghost danger shape="circle" icon={<DeleteFilled />} />
            </Tooltip>
          </Popconfirm>
        </Space>
      ),
    },
  ]

  return (
    <Card
      bordered={false}
      title={
        <Space size="large">
          <b>{translate('TasksTabsEquipments.cardTitle')}</b>
          {isLoading ? (
            <LoadingOutlined spin />
          ) : (
            <Text
              type="secondary"
              style={{ fontWeight: 'normal', fontSize: 14 }}
            >
              {task?.equipments?.length === 1
                ? `${translate('TasksTabsEquipments.totalText')} ${task
                    ?.equipments?.length} ${translate(
                    'TasksTabsEquipments.equipmentText',
                  )}`
                : `${translate('TasksTabsEquipments.totalText')} ${task
                    ?.equipments?.length} ${translate(
                    'TasksTabsEquipments.equipmentsText',
                  )}`}
            </Text>
          )}
          {!isLoading && isFetching && <LoadingOutlined spin />}
        </Space>
      }
      style={{
        display: 'inline-block',
        width: '100%',
      }}
      extra={!isVisualization && <CreateEquipmentTask />}
    >
      <Table
        rowKey={(record) => record.id}
        loading={{
          indicator: <LoadingOutlined />,
          spinning: isFetching,
          size: 'large',
        }}
        columns={columns}
        dataSource={task?.equipments.length !== 0 ? taskEquipment : []}
        scroll={{ x: 1000 }}
      />
    </Card>
  )
}

export default EquipmentsTask
