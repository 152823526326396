import { LoadingOutlined } from '@ant-design/icons'
import { Card, Space } from 'antd'
import Text from 'antd/lib/typography/Text'
import React from 'react'
import { ListContributorsProvider } from '~/hooks/Contributors/useListContributors'
import { useListUsers } from '~/hooks/Users/useListUsers'
import { translate } from '~/utils/locale'
import UsersFilter from './Filter'
import UsersContactsTable from './Table'

const UsersBoard: React.FC = () => {
  const { isLoading, isFetching, usersTotal } = useListUsers()

  return (
    <Card
      title={
        <Space size="large">
          <b>{translate('UsersComponent.cardTitle')}</b>
          {isLoading ? (
            <LoadingOutlined spin />
          ) : (
            <Text
              type="secondary"
              style={{ fontWeight: 'normal', fontSize: 14 }}
            >
              {usersTotal !== undefined ? (
                <>
                  {usersTotal === 1
                    ? `${translate(
                        'UsersComponent.totalText',
                      )} ${usersTotal} ${translate('UsersComponent.userText')} `
                    : `${translate(
                        'UsersComponent.totalText',
                      )} ${usersTotal} ${translate(
                        'UsersComponent.usersText',
                      )}`}
                </>
              ) : (
                ''
              )}
            </Text>
          )}
          {!isLoading && isFetching && <LoadingOutlined spin />}
        </Space>
      }
      extra={
        <ListContributorsProvider>
          <UsersFilter />
        </ListContributorsProvider>
      }
      bordered={false}
      style={{ width: '100%', padding: 30 }}
    >
      <UsersContactsTable />
    </Card>
  )
}

export default UsersBoard
