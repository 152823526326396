export default {
  pt: {
    TasksTabsDetails: {
      dateString: 'às',
      cardTitle: 'Informações Gerais da Tarefa',
      editButton: 'Editar',
      createdBy: 'Colaborador:',
      noCreatedBy: 'Nenhum colaborador foi informado.',
      atText: 'em',
      toText: 'Cliente:',
      noToText: 'Nenhum cliente foi informado.',
      taskDate: 'Data da Tarefa:',
      durationTime: 'Duração',
      checkinType: 'Tipo de Checkin',
      manualText: 'Manual',
      priorityText: 'Prioridade:',
      noPriorityText: 'Nenhuma prioridade foi informada.',
      priorityLow: 'Baixa',
      priorityMedium: 'Média',
      priorityHigh: 'Alta',
      taskType: 'Tipo de Tarefa:',
      statusText: 'Status:',
      statusReceived: 'Tarefa Enviada',
      statusViewed: 'Tarefa Visualizada',
      statusWorking: 'Tarefa sendo realizada',
      statusBreak: 'Tarefa Pausada',
      statusEnRoute: 'Tarefa a caminho',
      statusResumeWorking: 'Tarefa Retomada',
      statusCanceled: 'Tarefa Cancelada',
      statusFinished: 'Tarefa Finalizada',
      externalCode: 'Código Externo:',
      internalCode: 'Código Interno:',
      description: 'Descrição:',
      addressCardTitle: 'Endereço',
      zipcodeText: 'CEP:',
      addressText: 'Endereço:',
      cityText: 'Cidade:',
      valueText: 'Valor R$ da tarefa',
      noValueText: 'Nenhum valor da tarefa foi informado.',
      note: 'Observação',
      noteText: '',
      taskTypeCardTitle: 'Tipo de Tarefa',
      taskTypeTitle: 'Título:',
      runtime: 'Tempo de Execução:',
      toleranceTime: 'Tempo de Tolerância',
      workingStatusText: 'A tarefa foi iniciada.',
      noWorkingStatusText: 'A tarefa ainda não foi iniciada.',
      youHaveText: 'Você tem ',
      executeTaskText: ' para executar a tarefa.',
      startTaskText: ' para iniciar a tarefa.',
      theTaskLateText: 'A tarefa está atrasada ',
      finishedTaskText: ' para finalizar.',
      lateStartTaskText: ' para iniciar.',
    },
  },
  en: {
    TasksTabsDetails: {
      dateString: 'at',
      cardTitle: 'General Task Information',
      editButton: 'Edit',
      createdBy: 'Contributor:',
      noCreatedBy: 'No contributor in charge was informed.',
      atText: 'at',
      toText: 'Customer:',
      noToText: 'No customer has been informed.',
      taskDate: 'Task Date:',
      durationTime: 'Duration',
      checkinType: 'Checkin Type',
      manualText: 'Manual',
      priorityText: 'Priority:',
      noPriorityText: 'No priority has been given.',
      priorityLow: 'Low',
      priorityMedium: 'Medium',
      priorityHigh: 'High',
      taskType: 'Task Type:',
      statusText: 'Status:',
      statusReceived: 'Task Submitted',
      statusViewed: 'Task Viewed',
      statusWorking: 'Task being performed',
      statusBreak: 'Paused Task',
      statusEnRoute: 'Task on the way',
      statusResumeWorking: 'Resume Task',
      statusCanceled: 'Canceled Task',
      statusFinished: 'Finished Task',
      externalCode: 'External Code:',
      internalCode: 'Internal Code:',
      description: 'Description:',
      addressCardTitle: 'Address',
      zipcodeText: 'Zipcode:',
      addressText: 'Address:',
      cityText: 'City:',
      valueText: 'Value R$ of task',
      noValueText: 'No task value was reported.',
      note: 'Observation',
      taskTypeCardTitle: 'Task Type',
      taskTypeTitle: 'Title:',
      runtime: 'Runtime:',
      toleranceTime: 'Tolerance Time:',
      dangerTask: 'Task delayed to start ago ',
      startTask: 'Started task',
      finishedTask: 'The task was completed in a period of ',
      noteText: 'Nenhum comentário fornecido.',
      workingStatusText: 'The task has started.',
      noWorkingStatusText: 'The task has not yet started.',
      youHaveText: 'You have ',
      executeTaskText: ' to perform the task.',
      startTaskText: ' to start the task.',
      theTaskLateText: 'The task is late ',
      finishedTaskText: ' to finish.',
      lateStartTaskText: ' to start.',
    },
  },
  es: {
    TasksTabsDetails: {
      dateString: 'a las',
      cardTitle: 'Información general de tareas',
      editButton: 'Editar',
      createdBy: 'Colaborador:',
      noCreatedBy: 'No se informó a ningún colaborador..',
      atText: 'en',
      toText: 'Cliente:',
      noToText: 'Ningún cliente ha sido informado.',
      taskDate: 'Fecha de la tarea:',
      durationTime: 'Duración',
      checkinType: 'Tipo de registro',
      manualText: 'Manual',
      priorityText: 'Prioridad:',
      noPriorityText: 'No se ha dado prioridad.',
      priorityLow: 'Baja',
      priorityMedium: 'Media',
      priorityHigh: 'Alta',
      taskType: 'Tipo de tarea:',
      statusText: 'Status:',
      statusReceived: 'Tarea enviada',
      statusViewed: 'Tarea vista',
      statusWorking: 'Tarea que se está realizando',
      statusBreak: 'Tarea en pausa',
      statusEnRoute: 'Tarea en camino',
      statusResumeWorking: 'Reanudar tarea',
      statusCanceled: 'Tarea cancelada',
      statusFinished: 'Tarea terminada',
      externalCode: 'Código Externo:',
      internalCode: 'Código Interno:',
      description: 'Descripción:',
      addressCardTitle: 'Dirección',
      zipcodeText: 'Código Postal:',
      addressText: 'Dirección:',
      cityText: 'Ciudad:',
      valueText: 'Valor R$ de la Tarea',
      noValueText: 'No se informó ningún valor de tarea.',
      note: 'Observácion',
      taskTypeCardTitle: 'Tipo de Tarea',
      taskTypeTitle: 'Titulo:',
      runtime: 'Tiempo de Ejecución:',
      toleranceTime: 'Tiempo de Tolerancia',
      dangerTask: 'Tarea retrasada para comenzar hace ',
      startTask: 'Tarea iniciada',
      finishedTask: 'La tarea se completó en un período de ',
      noteText: 'No se proporcionaron comentarios.',
      workingStatusText: 'La tarea ha comenzado.',
      noWorkingStatusText: 'La tarea aún no ha comenzado.',
      youHaveText: 'Tú tienes ',
      executeTaskText: ' para realizar la tarea',
      startTaskText: ' para comenzar la tarea.',
      theTaskLateText: 'La tarea llega tarde ',
      finishedTaskText: ' para terminar.',
      lateStartTaskText: ' para empezar.',
    },
  },
}
