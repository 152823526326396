export default {
  pt: {
    AddressModal: {
      createSuccessMessage: 'Endereço Cadastrado com sucesso!',
      createErrorMessage: 'Erro ao criar endereço.',
      updateSuccessMessage: 'Endereço Atualizado com sucesso!',
      updateErrorMessage: 'Erro ao editar endereço.',
      searchZipcodeErrorMessage: 'Falha ao buscar CEP. Tente novamente.',
      geoSearchWarningMessage:
        'Nenhum resultado encontrado para este endereço. Por gentileza insira a Latitude e Longitude manualmente.',
      geoSearchErrorMessage:
        'Ocorreu um erro ao tentar conseguir a Geolocalização, por gentileza tente novamente ou insira a Latitude e Longitude manualmente.',
      editTooltipText: 'Editar Endereço',
      createButtonText: 'Adicionar Endereço',
      modalCreateTitle: 'Novo Endereço',
      modalUpdateTitle: 'Salvar Endereço',
      keepRegisterText: 'Continuar cadastrando endereços?',
      cancelButtonText: 'Cancelar',
      createFinishButtonText: 'Cadastrar',
      updateFinishButtonText: 'Salvar',
      typeLabel: 'Tipo',
      typeRule: 'Por favor selecione um tipo de endereço',
      typeOptionOne: 'Pessoal',
      typeOptionTwo: 'Empresarial',
      typeOptionThree: 'Cobrança',
      zipcodeLabel: 'CEP',
      zipcodeRule: 'Por favor insira o CEP',
      zipcodeHelpLabel: 'Informação CEP',
      zipcodeHelpText: 'Não sei meu CEP',
      stateLabel: 'Estado',
      cityLabel: 'Cidade',
      neighborhoodLabel: 'Bairro',
      streetLabel: 'Logradouro',
      numberLabel: 'Número',
      numberRule: 'Por favor insira o número',
      complementLabel: 'Complemento',
      mapLabel: 'Mapa',
      mapRule: 'Você precisa validar o endereço.',
      mapButtonText: 'Validar endereço no mapa',
      latitudeLabel: 'Latitude',
      latitudeRule: 'Por favor insira a latitude',
      longitudeLabel: 'Longitude',
      longitudeRule: 'Por favor insira a longitude',
      latlongButtonLabel: 'Informação das Coordenadas',
      latlongButtonText: 'Não sei minhas coordenadas',
      observationLabel: 'Observações',
    },
  },
  en: {
    AddressModal: {
      createSuccessMessage: 'Address Registered Successfully!',
      createErrorMessage: 'Error creating address.',
      updateSuccessMessage: 'Address Updated Successfully!',
      updateErrorMessage: 'Error editing address.',
      searchZipcodeErrorMessage: 'Failed to fetch zip code. Try again.',
      geoSearchWarningMessage:
        'No results found for this address. Please enter Latitude and Longitude manually.',
      geoSearchErrorMessage:
        'There was an error trying to get Geolocation, please try again or enter Latitude and Longitude manually.',
      editTooltipText: 'Edit Address',
      createButtonText: 'Add Address',
      modalCreateTitle: 'New Address',
      modalUpdateTitle: 'Save Address',
      keepRegisterText: 'Continue registering addresses?',
      cancelButtonText: 'Cancel',
      createFinishButtonText: 'Register',
      updateFinishButtonText: 'Save',
      typeLabel: 'Type',
      typeRule: 'Please select an address type',
      typeOptionOne: 'Personal',
      typeOptionTwo: 'Business',
      typeOptionThree: 'Charge',
      zipcodeLabel: 'ZIPCODE',
      zipcodeRule: 'Please enter the zip code',
      zipcodeHelpLabel: 'Zip code information',
      zipcodeHelpText: 'I do not know my Zip code',
      stateLabel: 'State',
      cityLabel: 'City',
      neighborhoodLabel: 'Neighborhood',
      streetLabel: 'Street',
      numberLabel: 'Number',
      numberRule: 'Please enter the number',
      complementLabel: 'Complement',
      mapLabel: 'Map',
      mapRule: 'You need to validate the address.',
      mapButtonText: 'Validate address on map',
      latitudeLabel: 'Latitude',
      latitudeRule: 'Please enter the latitude',
      longitudeLabel: 'Longitude',
      longitudeRule: 'Please enter the longitude',
      latlongButtonLabel: 'Coordinate Information',
      latlongButtonText: "I don't know my coordinates",
      observationLabel: 'Comments',
    },
  },
  es: {
    AddressModal: {
      createSuccessMessage: '¡Dirección registrada con éxito!',
      createErrorMessage: 'Error al crear la dirección.',
      updateSuccessMessage: '¡Dirección actualizada con éxito!',
      updateErrorMessage: 'Error al editar la dirección.',
      searchZipcodeErrorMessage:
        'No se pudo obtener el código postal. Inténtalo de nuevo.',
      geoSearchWarningMessage:
        'No se han encontrado resultados para esta dirección. Ingrese la latitud y la longitud manualmente.',
      geoSearchErrorMessage:
        'Hubo un error al intentar obtener la geolocalización, intente nuevamente o ingrese la latitud y la longitud manualmente.',
      editTooltipText: 'Editar dirección',
      createButtonText: 'Añadir dirección',
      modalCreateTitle: 'Nueva dirección',
      modalUpdateTitle: 'Guardar dirección',
      keepRegisterText: '¿Continuar registrando direcciones?',
      cancelButtonText: 'Cancelar',
      createFinishButtonText: 'Registrar',
      updateFinishButtonText: 'Guardar',
      typeLabel: 'Tipo',
      typeRule: 'Seleccione un tipo de dirección',
      typeOptionOne: 'Personal',
      typeOptionTwo: 'Negocio',
      typeOptionThree: 'Cobrar',
      zipcodeLabel: 'Código postal',
      zipcodeRule: 'Por favor ingrese el código postal',
      zipcodeHelpLabel: 'Información del código postal',
      zipcodeHelpText: 'No se mi código postal',
      stateLabel: 'Estado',
      cityLabel: 'Ciudad',
      neighborhoodLabel: 'Distrito',
      streetLabel: 'La carretera',
      numberLabel: 'Número',
      numberRule: 'Por favor ingrese el número',
      complementLabel: 'Complementar',
      mapLabel: 'Mapa',
      mapRule: 'Necesitas validar la dirección.',
      mapButtonText: 'Validar dirección en el mapa',
      latitudeLabel: 'Latitud',
      latitudeRule: 'Introduce la latitud',
      longitudeLabel: 'Longitud',
      longitudeRule: 'Por favor ingrese la longitud',
      latlongButtonLabel: 'Información de coordenadas',
      latlongButtonText: 'No se mis coordenadas',
      observationLabel: 'Comentarios',
    },
  },
}
