export default {
  pt: {
    ReportTasksFilter: {
      statusReceived: 'Tarefa Recebida',
      statusViewed: 'Tarefa Visualizada',
      statusEnRoute: 'Tarefa a caminho',
      statusBreak: 'Tarefa Pausada',
      statusCanceled: 'Tarefa Cancelada',
      statusFinished: 'Tarefa Finalizada',
      statusWorking: 'Tarefa sendo realizada',
      cleanTooltip: 'Limpar Filtro',
      filterAppliedText: 'Filtro Aplicado',
      filterText: 'Filtros',
      drawerTitle: 'Filtros de Relatório das Tarefas',
      fromDateLabel: 'Data Inicial',
      fromDatePlaceholder: 'Informe a data de criação',
      toDateLabel: 'Data Final',
      toDatePlaceholder: 'Informe a data de criação',
      statusLabel: 'Status',
      statusPlaceholder: 'Informe o status',
      customersLabel: 'Clientes',
      customersPlaceholder: 'Informe o cliente',
      responsibleLabel: 'Responsável',
      responsiblePlaceholder: 'Selecione o responsável',
      responsibleContributor: 'Colaborador',
      responsibleTeam: 'Equipe',
      contributorLabel: 'Colaborador',
      contributorPlaceholder: 'Selecione o colaborador',
      teamLabel: 'Equipe',
      teamPlaceholder: 'Selecione a equipe',
      equipmentLabel: 'Equipamentos',
      equipmentPlaceholder: 'Informe o equipamento',
      taskIdLabel: 'ID da tarefa',
      taskIdPlaceholder: 'Informe o ID da tarefa',
      internalCodeLabel: 'Código interno',
      internalCodePlaceholder: 'Informe o código interno',
      externalCodeLabel: 'Código externo',
      externalCodePlaceholder: 'Informe o código externo',
      applyButton: 'Aplicar',
      cleanButton: 'Limpar',
    },
  },
  en: {
    ReportTasksFilter: {
      statusReceived: 'Task Confirmed',
      statusViewed: 'Task Viewed',
      statusEnRoute: 'Task on the way',
      statusBreak: 'Paused Task',
      statusCanceled: 'Canceled Task',
      statusFinished: 'Finished Task',
      statusWorking: 'Task being performed',
      cleanTooltip: 'Clear Filter',
      filterAppliedText: 'Filter Applied',
      filterText: 'Filters',
      drawerTitle: 'Task Report Filters',
      fromDateLabel: 'Initial date',
      fromDatePlaceholder: 'Enter the creation date',
      toDateLabel: 'Final date',
      toDatePlaceholder: 'Enter the creation date',
      statusLabel: 'Status',
      statusPlaceholder: 'Report the status',
      customersLabel: 'Customers',
      customersPlaceholder: 'Inform the customer',
      responsibleLabel: 'Responsible',
      responsiblePlaceholder: 'Select responsible',
      responsibleContributor: 'Contributor',
      responsibleTeam: 'Team',
      contributorLabel: 'Contributor',
      contributorPlaceholder: 'Select the contributor',
      teamLabel: 'Team',
      teamPlaceholder: 'Select the team',
      equipmentLabel: 'Equipment',
      equipmentPlaceholder: 'Inform the equipment',
      taskIdLabel: 'Task ID',
      taskIdPlaceholder: 'Enter the task ID',
      internalCodeLabel: 'Internal Code',
      internalCodePlaceholder: 'Enter the internal code',
      externalCodeLabel: 'External Code',
      externalCodePlaceholder: 'Enter the external code',
      applyButton: 'Apply',
      cleanButton: 'Clean',
    },
  },
  es: {
    ReportTasksFilter: {
      statusReceived: 'Tarea confirmada',
      statusViewed: 'Tarea vista',
      statusEnRoute: 'Tarea en camino',
      statusBreak: 'Tarea en pausa',
      statusCanceled: 'Tarea cancelada',
      statusFinished: 'Tarea terminada',
      statusWorking: 'Tarea que se está realizando',
      cleanTooltip: 'Filtro Claro',
      filterAppliedText: 'Filtro Aplicado',
      filterText: 'Filtros',
      drawerTitle: 'Filtros de informes de tareas',
      fromDateLabel: 'La fecha de inicio',
      fromDatePlaceholder: 'Introduce la fecha de creación',
      toDateLabel: 'Fecha final',
      toDatePlaceholder: 'Introduce la fecha de creación',
      statusLabel: 'Estado',
      statusPlaceholder: 'Informar el estado',
      customersLabel: 'Clientes',
      customersPlaceholder: 'Informar al cliente',
      responsibleLabel: 'Responsable',
      responsiblePlaceholder: 'Seleccionar responsable',
      responsibleContributor: 'Colaborador',
      responsibleTeam: 'Equipo',
      contributorLabel: 'Colaborador',
      contributorPlaceholder: 'Seleccione el colaborador',
      teamLabel: 'Equipo',
      teamPlaceholder: 'Seleccione el equipo',
      equipmentLabel: 'Equipo',
      equipmentPlaceholder: 'Informar al equipo',
      taskIdLabel: 'Identificación de la tarea',
      taskIdPlaceholder: 'Ingrese la identificación de la tarea',
      internalCodeLabel: 'Código interno',
      internalCodePlaceholder: 'Introduce el código interno',
      externalCodeLabel: 'Código externo',
      externalCodePlaceholder: 'Introduce el código externo',
      applyButton: 'Aplicar',
      cleanButton: 'Limpio',
    },
  },
}
