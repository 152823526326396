export default {
  pt: {
    FinancialComponent: {
      cardTitle: 'Valores Acordados - Cliente Principal',
      cardTitleTeam: 'Valores Acordados - Equipe',
      teamText: 'Equipes',
      clientText: 'Cliente Principal',
      relatoryText: 'Relatório',
      viewButton: 'Visualizar cliente',
      editButton: 'Editar cliente',
      editTeamButton: 'Editar equipe',
      valueReceive: 'Valor a pagar',
    },
  },
  en: {
    FinancialComponent: {
      cardTitle: 'Agreed values - Main Customers',
      cardTitleTeam: 'Agreed values - Team',
      teamText: 'Teams',
      clientText: 'Main Customer',
      relatoryText: 'Report',
      viewButton: 'View customer',
      editButton: 'Edit Customer',
      editTeamButton: 'Edit team',
      valueReceive: 'Value to pay',
    },
  },
  es: {
    FinancialComponent: {
      cardTitle: 'Valores acordados - Principales Clientes',
      cardTitleTeam: 'Valores acordados - Equipo',
      teamText: 'Equipos',
      clientText: 'Cliente Principal',
      relatoryText: 'Informe',
      viewButton: 'Ver cliente',
      editButton: 'Editar cliente',
      editTeamButton: 'Editar equipo',
      valueReceive: 'Cantidad a pagar',
    },
  },
}
