export default {
  pt: {
    Sidebar: {
      dashboard: 'Dashboard',
      dashboardTasks: 'Dashboard Tarefas',
      dashboardContributors: 'Dashboard Colaboradores',
      dashboardCustomers: 'Dashboard Clientes',
      taskTitle: 'Tarefas',
      taskSchedule: 'Agenda',
      taskList: 'Lista Tarefas',
      taskKanban: 'Kanban Tarefas',
      taskType: 'Tipos de Tarefa',
      taskBreakReasons: 'Motivos de Pausa',
      collaboratorsTitle: 'Colaboradores',
      collaboratorsList: 'Lista Colaboradores',
      collaboratorsTeams: 'Equipes',
      collaboratorsSkills: 'Habilidades',
      collaboratorsScales: 'Escalas',
      collaboratorsStatus: 'Status',
      clientsTitle: 'Clientes',
      clientsList: 'Lista Clientes',
      clientsParent: 'Cliente Principal',
      clientsContacts: 'Contatos',
      clientsSegments: 'Segmentos',
      equipmentsTitle: 'Equipamentos',
      equipmentsList: 'Lista Equipamentos',
      equipmentsCategories: 'Categorias',
      equipmentsTemplates: 'Templates de Especificaçao',
      reportsTitle: 'Relatórios',
      reportsTasksTitle: 'Tarefas',
      reportsTasks: 'Tarefas',
      reportsKmTitle: 'Km rodados',
      reportsKm: 'Km Percorrido',
      settingsTitle: 'Configurações',
      settingsSubTitle: 'Usuários Web',
      settingsLogin: 'Login de acesso',
      settingsPermissions: 'Permissões',
      financialTitle: 'Financeiro',
      financialAgreed: 'Valores acordados',
      financialReceive: 'Valores a receber',
      badgeTitle: 'Crachá',
    },
  },
  en: {
    Sidebar: {
      dashboard: 'Dashboard',
      dashboardTasks: 'Dashboard Tasks',
      dashboardContributors: 'Dashboard Contributors',
      dashboardCustomers: 'Dashboard Customers',
      taskTitle: 'Tasks',
      taskSchedule: 'Schedule',
      taskList: 'Task List',
      taskKanban: 'Kanban Tasks',
      taskType: 'Task Types',
      taskBreakReasons: 'Break Reasons',
      collaboratorsTitle: 'Collaborators',
      collaboratorsList: 'List of Collaborators',
      collaboratorsTeams: 'Teams',
      collaboratorsSkills: 'Skills',
      collaboratorsScales: 'Scales',
      collaboratorsStatus: 'Status',
      clientsTitle: 'Customers',
      clientsList: 'Customer List',
      clientsParent: 'Customer Principal',
      clientsContacts: 'Contacts',
      clientsSegments: 'Segments',
      equipmentsTitle: 'Equipment',
      equipmentsList: 'Equipment List',
      equipmentsCategories: 'Categories',
      equipmentsTemplates: 'Specification Templates',
      reportsTitle: 'Reports',
      reportsTasksTitle: 'Tasks',
      reportsTasks: 'Tasks',
      reportsKmTitle: 'Km traveled',
      reportsKm: 'Km Traveled',
      settingsTitle: 'Settings',
      settingsSubTitle: 'Web Users',
      settingsLogin: 'Access Login',
      settingsPermissions: 'Permissions',
      financialTitle: 'Financial',
      financialAgreed: 'Agreed values',
      financialReceive: 'Amounts receivable',
      badgeTitle: 'Badge',
    },
  },
  es: {
    Sidebar: {
      dashboard: 'Dashboard',
      dashboardTasks: 'Panel de Tareas',
      dashboardContributors: 'Panel de Colaboradores',
      dashboardCustomers: 'Panel de Clientes',
      taskTitle: 'Tareas',
      taskSchedule: 'Calendario',
      taskList: 'Lista de tareas',
      taskKanban: 'Tareas Kanban',
      taskType: 'Tipos de tareas',
      taskBreakReasons: 'Romper Razones',
      collaboratorsTitle: 'Colaboradores',
      collaboratorsList: 'Lista de Colaboradores',
      collaboratorsTeams: 'Equipos',
      collaboratorsSkills: 'Habilidades',
      collaboratorsScales: 'Escamas',
      collaboratorsStatus: 'Estado',
      clientsTitle: 'Clientes',
      clientsList: 'Lista de clientes',
      clientsParent: 'Cliente Principal',
      clientsContacts: 'Contactos',
      clientsSegments: 'Segmentos',
      equipmentsTitle: 'Equipo',
      equipmentsList: 'Lista de equipo',
      equipmentsCategories: 'Categorías',
      equipmentsTemplates: 'Plantillas de especificaciones',
      reportsTitle: 'Informes',
      reportsTasksTitle: 'Tareas',
      reportsTasks: 'Tareas',
      reportsKmTitle: 'Km Recorridos',
      reportsKm: 'Km Recorridos',
      settingsTitle: 'Ajustes',
      settingsSubTitle: 'Usuarios Web',
      settingsLogin: 'Acceso inicio de sesión',
      settingsPermissions: 'Permisos',
      financialTitle: 'Financiero',
      financialAgreed: 'Valores acordados',
      financialReceive: 'Montos por cobrar',
      badgeTitle: 'Insignia',
    },
  },
}
