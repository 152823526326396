import styled from 'styled-components'

export const Wrapper = styled.div``

export const Container = styled.div`
  background: #fff;
  padding: 15px;
  border-radius: 3px;

  box-shadow:
    rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;

  .text {
    display: flex;
    align-items: center;

    h4 {
      margin-left: 8px;
      margin-bottom: 0px;
    }
  }
`
