/* eslint-disable camelcase */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react/require-default-props */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-useless-escape */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react/jsx-indent */
/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
import React, { useState, useCallback, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'

import {
  Card,
  Col,
  Row,
  Button,
  Form,
  Input,
  Select,
  DatePicker,
  Space,
  Checkbox,
  Radio,
  message,
  Tooltip,
} from 'antd'
import MaskedInput from 'antd-mask-input'
import {
  SaveOutlined,
  WhatsAppOutlined,
  DeleteOutlined,
  PlusOutlined,
  ArrowLeftOutlined,
  MailOutlined,
} from '@ant-design/icons'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import pt_BR from 'react-phone-number-input/locale/pt-BR.json'

import { cpf, cnpj } from 'cpf-cnpj-validator'

import api from '~/services/api'

import {
  Wrapper,
  ContAvatar,
  ContForm,
  FormButtonContainer,
  ContainerButton,
} from './styles'

import { useCompanies } from '~/hooks/Companies/useCompanies'
import { useContributor } from '~/hooks/Contributors/useContributor'
import { useListContributors } from '~/hooks/Contributors/useListContributors'
import { useLocale } from '~/hooks/locale/useLocale'

import AvatarUploader from '~/components/DataManipulation/Misc/AvatarUploader'

import { ShowError } from '~/utils/errors/apiErrors'
import validateWhatsappApi from '~/services/validateWhatsappApi'
import { translate } from '~/utils/locale'

interface AdditionalPhones {
  phone: string
  is_whatsapp: boolean
  type: string
  formattedPhone?: string
}

interface NewContributorData {
  name: string
  document: string
  cnpj: string
  rg: string
  phone: string
  email: string
  password: string
  person_type: string
  gender: string
  birth: any
  evaluation: number
  notes: string
  is_whatsapp: boolean
  additional_phones: AdditionalPhones[]
  value_hour_worked: number
  value_km_traveled: number
}

const ContributorUpdate: React.FC = () => {
  const { contributorId, contactId } = useParams<{
    contributorId: string
    contactId: string
  }>()

  const { selectedCompany } = useCompanies()
  const { contributorRefetch, contributorContact, setIsEdit } = useContributor()

  const { listContributorsRefetch } = useListContributors()

  const { locale } = useLocale()

  const [form] = Form.useForm()

  const [documentType, setDocumentType] = useState('')
  const [confirmLoading, setConfirmLoading] = useState(false)

  const generateFakeEmail = (name: string) => {
    const randomString = Math.random().toString(36).substring(7)
    return `${name
      .replace(/\s+/g, '')
      .toLowerCase()}_${randomString}@gstor.com.br`
  }

  const handleGenerateEmail = () => {
    const nameFieldValue = form.getFieldValue('name')
    if (nameFieldValue) {
      const fakeEmail = generateFakeEmail(nameFieldValue)
      form.setFieldsValue({
        email: fakeEmail,
      })
    } else {
      message.error(translate('ContributorUpdate.randomEmailError'))
    }
  }

  useEffect(() => {
    form.setFieldsValue(contributorContact)
    if (contributorContact.person_type) {
      setDocumentType(contributorContact.person_type)
    }
  }, [form, contributorContact])

  const handleDocumentTypeChange = (value: string) => {
    if (value === undefined) {
      setDocumentType('')
    } else {
      setDocumentType(value)
    }
  }

  const onUpdate = useCallback(
    async (data: NewContributorData) => {
      try {
        setConfirmLoading(true)
        // eslint-disable-next-line array-callback-return
        data.additional_phones?.map((add) => {
          delete add.formattedPhone
          if (add.is_whatsapp === undefined) {
            add.is_whatsapp = false
          }
          if (add.phone) {
            add.phone = add.phone.replace(/[^0-9]/g, '')
          }
        })

        data.name = data.name.toUpperCase()

        if (data.phone) {
          data.phone = data.phone.replace(/[^0-9]/g, '')
        }
        if (data.birth) {
          data.birth = data.birth.format('YYYY/MM/DD')
        }
        if (data.cnpj) {
          data.cnpj = data.cnpj.replace(/[^0-9]/g, '')
        }
        if (data.document) {
          data.document = data.document.replace(/[^a-zA-Z0-9]/g, '')
        }
        if (data.rg) {
          data.rg = data.rg.replace(/[^a-zA-Z0-9]/g, '')
        }
        await api.put(
          `/company/${selectedCompany}/collaborator/${contributorId}`,
          data,
        )
        setConfirmLoading(false)
        message.success(translate('ContributorUpdate.successMessage'))
        setIsEdit(false)
        contributorRefetch()
        listContributorsRefetch()
      } catch (err: any) {
        ShowError(
          err.message,
          translate('ContributorUpdate.errorMessage'),
          locale,
        )

        setConfirmLoading(false)
      }
    },
    [selectedCompany, contributorRefetch, contributorId, locale],
  )

  const ValidateWhatsappPhoneNumber = useCallback(async (phone: string) => {
    try {
      const { data } = await validateWhatsappApi.post('validatePhoneNumber', {
        phoneNumber: phone.replace('+', ''),
      })
      const correctPhone = `+${data.user}`
      form.setFieldsValue({
        phone: correctPhone,
      })
      return true
    } catch (err: any) {
      return false
    }
  }, [])

  const checkCNPJ = useCallback(
    async (cnpjInput: string) => {
      try {
        cnpjInput = cnpjInput.replace(/[^0-9]/g, '')
        const { data } = await api.get(
          `company/${selectedCompany}/cnpjcheck/${cnpjInput}`,
        )
        if (data.cnpjInUse) {
          message.warning(translate('ContributorUpdate.cnpjInUseMessage'), 10)
        }
      } catch (err: any) {
        console.log(err)
      }
    },
    [selectedCompany],
  )

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onUpdate}
      initialValues={contributorContact}
    >
      <Card
        bordered={false}
        title={
          <Space size="large">
            <Tooltip title="Voltar">
              <Link to="/colaboradores">
                <ArrowLeftOutlined />
              </Link>
            </Tooltip>
            <b>{translate('ContributorUpdate.cardTitle')}</b>
          </Space>
        }
        extra={
          <Space size="large">
            <Form.Item style={{ margin: '0 auto' }}>
              <ContainerButton>
                <Button
                  icon={<SaveOutlined />}
                  type="primary"
                  htmlType="submit"
                  loading={confirmLoading}
                  id="btn_update_contributor"
                >
                  {translate('ContributorUpdate.saveButton')}
                </Button>
              </ContainerButton>
            </Form.Item>

            <Form.Item style={{ margin: '0 auto' }}>
              <Button onClick={() => setIsEdit(false)}>
                {translate('ContributorUpdate.cancelButton')}
              </Button>
            </Form.Item>
          </Space>
        }
        style={{
          display: 'inline-block',
          width: 'calc(100% - 20px)',
        }}
      >
        <Wrapper>
          <ContAvatar>
            <AvatarUploader
              contactId={contactId}
              imageData={contributorContact?.avatar_url}
            />
          </ContAvatar>

          <ContForm>
            <Row gutter={12}>
              <Col span={12}>
                <Form.Item
                  label={translate('ContributorUpdate.nameLabel')}
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: translate('ContributorUpdate.nameRule'),
                    },
                  ]}
                >
                  <Input style={{ textTransform: 'uppercase' }} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={
                    <Space size="large">
                      {translate('ContributorUpdate.emailLabel')}
                      <Tooltip
                        title={translate(
                          'ContributorUpdate.emailGenerateTooltip',
                        )}
                      >
                        <Button
                          shape="circle"
                          size="small"
                          icon={<MailOutlined />}
                          onClick={handleGenerateEmail}
                        />
                      </Tooltip>
                    </Space>
                  }
                  name="email"
                  rules={[
                    {
                      type: 'email',
                      message: translate('ContributorUpdate.emailRuleOne'),
                    },
                    {
                      required: true,
                      message: translate('ContributorUpdate.emailRuleTwo'),
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col span={12}>
                <Form.Item
                  label={translate('ContributorUpdate.genderLabel')}
                  name="gender"
                >
                  <Select allowClear>
                    <Select.Option value="male">
                      {translate('ContributorUpdate.genderMasculine')}
                    </Select.Option>
                    <Select.Option value="female">
                      {translate('ContributorUpdate.genderFeminine')}
                    </Select.Option>
                    <Select.Option value="other">
                      {translate('ContributorUpdate.genderOther')}
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={translate('ContributorUpdate.birthLabel')}
                  name="birth"
                >
                  <DatePicker style={{ width: '100%' }} format="DD/MM/YYYY" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col span={12}>
                <Form.Item
                  label={
                    <p>
                      <WhatsAppOutlined /> Whatsapp
                    </p>
                  }
                  validateTrigger="onBlur"
                  rules={[
                    {
                      required: true,
                      message: translate('ContributorUpdate.whatsappRuleOne'),
                    },
                    () => ({
                      async validator(rule, phone) {
                        if (phone && isValidPhoneNumber(phone)) {
                          const validate =
                            await ValidateWhatsappPhoneNumber(phone)
                          if (validate) {
                            return Promise.resolve()
                          }
                          return Promise.reject(
                            new Error(
                              translate('ContributorUpdate.whatsappRuleTwo'),
                            ),
                          )
                        }
                        return Promise.reject(
                          new Error(
                            translate('ContributorUpdate.whatsappRuleThree'),
                          ),
                        )
                      },
                    }),
                  ]}
                  name="phone"
                >
                  <PhoneInput
                    placeholder={translate(
                      'ContributorUpdate.whatsappPlaceholder',
                    )}
                    labels={pt_BR}
                    defaultCountry="BR"
                    international
                    onChange={() => {}}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={translate('ContributorUpdate.personTypeLabel')}
                  name="person_type"
                >
                  <Select allowClear onChange={handleDocumentTypeChange}>
                    <Select.Option value="legalPerson">
                      {translate('ContributorUpdate.personTypeJuridic')}
                    </Select.Option>
                    <Select.Option value="physicalPerson">
                      {translate('ContributorUpdate.personTypePhysical')}
                    </Select.Option>
                    <Select.Option value="foreign">
                      {translate('ContributorUpdate.personTypeForeign')}
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12}>
              {documentType !== '' && (
                <>
                  <Col span={12}>
                    <Form.Item
                      label={
                        documentType === 'legalPerson' ||
                        documentType === 'physicalPerson'
                          ? translate('ContributorUpdate.cpfLabel')
                          : translate('ContributorUpdate.passportLabel')
                      }
                      rules={[
                        {
                          required: documentType === 'physicalPerson',
                          message: translate('ContributorUpdate.cpfRuleOne'),
                        },
                        {
                          validator(_, value) {
                            if (value === undefined || value === null) {
                              return Promise.resolve()
                            }
                            const documentInput = value.replace(/[^0-9]/g, '')
                            if (documentInput.length === 11) {
                              if (cpf.isValid(value)) {
                                return Promise.resolve()
                              }
                              // eslint-disable-next-line prefer-promise-reject-errors
                              return Promise.reject(
                                translate('ContributorUpdate.cpfRuleTwo'),
                              )
                            }
                            return Promise.resolve()
                          },
                        },
                      ]}
                      name="document"
                    >
                      {documentType === 'physicalPerson' ||
                      documentType === 'legalPerson' ? (
                        <MaskedInput mask={'000.000.000-00'} placeholder="" />
                      ) : (
                        <MaskedInput mask={'aa000000'} placeholder="" />
                      )}
                    </Form.Item>
                  </Col>

                  {documentType !== 'physicalPerson' && (
                    <Col span={12}>
                      <Form.Item
                        label={translate('ContributorUpdate.cnpjLabel')}
                        name="cnpj"
                        rules={[
                          {
                            validator(_, value) {
                              if (value === undefined || value === null) {
                                return Promise.resolve()
                              }
                              const CnpjInput = value.replace(/[^0-9]/g, '')
                              if (CnpjInput.length === 14) {
                                if (cnpj.isValid(value)) {
                                  return Promise.resolve()
                                }
                                // eslint-disable-next-line prefer-promise-reject-errors
                                return Promise.reject(
                                  translate('ContributorUpdate.cnpjRuleTwo'),
                                )
                              }
                              return Promise.resolve()
                            },
                          },
                        ]}
                      >
                        <MaskedInput
                          mask={'00.000.000/0000-00'}
                          onBlur={(e) => checkCNPJ(e.target.value)}
                        />
                      </Form.Item>
                    </Col>
                  )}

                  {documentType === 'physicalPerson' && (
                    <Col span={12}>
                      <Form.Item
                        label={translate('ContributorUpdate.idLabel')}
                        name="rg"
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  )}
                </>
              )}
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  label={translate('ContributorUpdate.observationLabel')}
                  name="notes"
                >
                  <Input.TextArea rows={3} />
                </Form.Item>
              </Col>
            </Row>
          </ContForm>
        </Wrapper>

        <Form.Item
          label={<b>{translate('ContributorUpdate.additionalPhonesLabel')}</b>}
          labelCol={{ span: 24 }}
          style={{ width: '100%' }}
        >
          <Form.List name="additional_phones">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space
                    key={key}
                    style={{ display: 'flex', marginBottom: 8 }}
                    align="baseline"
                  >
                    <Form.Item
                      {...restField}
                      label={translate('ContributorUpdate.phoneLabel')}
                      name={[name, 'phone']}
                      labelCol={{ span: 24 }}
                      rules={[
                        () => ({
                          validator(rule, phone) {
                            if (phone && isValidPhoneNumber(phone)) {
                              return Promise.resolve()
                            }
                            return Promise.reject(
                              new Error(
                                translate('ContributorUpdate.phoneRule'),
                              ),
                            )
                          },
                        }),
                      ]}
                    >
                      <PhoneInput
                        placeholder={translate(
                          'ContributorUpdate.phonePlaceholder',
                        )}
                        labels={pt_BR}
                        defaultCountry="BR"
                        international
                        onChange={() => {}}
                      />
                    </Form.Item>

                    <Form.Item
                      {...restField}
                      name={[name, 'is_whatsapp']}
                      style={{ display: 'inline-block' }}
                      valuePropName="checked"
                    >
                      <Checkbox>
                        <WhatsAppOutlined />
                      </Checkbox>
                    </Form.Item>

                    <Form.Item
                      {...restField}
                      label={translate('ContributorUpdate.phoneTypeLabel')}
                      name={[name, 'type']}
                      labelCol={{ span: 24 }}
                      rules={[
                        {
                          required: true,
                          message: translate('ContributorUpdate.phoneTypeRule'),
                        },
                      ]}
                    >
                      <Radio.Group>
                        <Radio.Button value="home">
                          {translate('ContributorUpdate.phoneTypeResidential')}
                        </Radio.Button>
                        <Radio.Button value="cell">
                          {translate('ContributorUpdate.phoneTypeCellphone')}
                        </Radio.Button>
                        <Radio.Button value="commercial">
                          {translate('ContributorUpdate.phoneTypeCommercial')}
                        </Radio.Button>
                      </Radio.Group>
                    </Form.Item>

                    <DeleteOutlined onClick={() => remove(name)} />
                  </Space>
                ))}

                <Form.Item style={{ width: '100%', display: 'block' }}>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    {translate('ContributorUpdate.addPhoneButton')}
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form.Item>

        <FormButtonContainer>
          <Space size="large">
            <Form.Item>
              <Button
                icon={<SaveOutlined />}
                type="primary"
                htmlType="submit"
                loading={confirmLoading}
              >
                {translate('ContributorUpdate.saveButton')}
              </Button>
            </Form.Item>

            <Form.Item>
              <Button onClick={() => setIsEdit(false)}>
                {translate('ContributorUpdate.cancelButton')}
              </Button>
            </Form.Item>
          </Space>
        </FormButtonContainer>
      </Card>
    </Form>
  )
}

export default ContributorUpdate
