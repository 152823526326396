import styled from 'styled-components'

export const Container = styled.div`
  h1,
  h4 {
    margin-bottom: 0px;
  }

  margin-bottom: 20px;
`

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  .total {
    border-bottom: 3px solid var(--quartenary-color);

    &:hover {
      background: var(--quartenary-color);
      svg,
      h1,
      p {
        color: #fff;
      }
    }
  }

  .execution {
    border-bottom: 3px solid var(--primary-color);

    &:hover {
      background: var(--primary-color);
      svg,
      h1,
      p {
        color: #fff;
      }
    }
  }

  .finished {
    border-bottom: 3px solid var(--secondary-color);

    &:hover {
      background: var(--secondary-color);
      svg,
      h1,
      p {
        color: #fff;
      }
    }
  }

  .canceled {
    border-bottom: 3px solid var(--terciary-color);

    &:hover {
      background: var(--terciary-color);
      svg,
      h1,
      p {
        color: #fff;
      }
    }
  }
`

export const Card = styled.div`
  border: 1px solid #dcdcdc;
  width: 100%;
  max-width: 250px;
  border-radius: 5px;
  padding: 20px 20px 10px 20px;

  transition: 0.3s ease-in;

  svg {
    font-size: 30px;
  }

  p {
    font-size: 12px;
    margin-top: 15px;
    color: var(--secondary-initial-text);
  }

  h1 {
    font-size: 25px;
    font-weight: bold;
  }

  #total {
    color: var(--quartenary-color);
  }

  #execution {
    color: var(--primary-color);
  }

  #finished {
    color: var(--secondary-color);
  }

  #canceled {
    color: var(--terciary-color);
  }
`
