import styled from 'styled-components'

interface ContainerProps {
  isExpanded?: boolean
}

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Map = styled.div<ContainerProps>`
  flex: ${(f) => (f.isExpanded ? '5' : '2')};
`

export const Accompaniment = styled.div`
  flex: 1;
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h3 {
      margin-bottom: 0;
    }

    svg {
      font-size: 18px;
      cursor: pointer;
      transition: 0.2s;

      &:hover {
        filter: brightness(150%);
      }
    }
  }
`

export const ContainerTimeline = styled.div`
  background: #f3f3f3;
  margin: 10px 0 0 10px;
  padding: 20px;
  border-radius: 2px;
  height: 450px;
  overflow-y: scroll;
`
