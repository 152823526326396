import React from 'react'
import { TaskFormWithAnswersProvider } from '~/hooks/Tasks/Forms/useTaskFormWithAnswers'
import Form from './form'
import { Card, Flex, Typography } from 'antd'

interface FormsWithAnswers {
  forms: Array<{ form_id: string }>
}

const FormsWithAnswers: React.FC<FormsWithAnswers> = ({ forms }) => {
  return (
    <>
      <Card>
        <Typography.Title level={4}>Formulários com respostas</Typography.Title>
      </Card>

      <Flex vertical gap={'2rem'}>
        {forms.map((form, index) => (
          <TaskFormWithAnswersProvider
            key={`${form.form_id}-${index}`}
            formId={form.form_id}
          >
            <Form />
          </TaskFormWithAnswersProvider>
        ))}
      </Flex>
    </>
  )
}

export default FormsWithAnswers
