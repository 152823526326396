export default {
  pt: {
    GroupsCreate: {
      newButton: 'Novo Grupo',
      modalTitle: 'Novo Grupo',
      closeButton: 'Fechar',
      saveButton: 'Salvar',
      groupNameLabel: 'Nome do Grupo',
      groupNameRule: 'Preencha o nome do grupo.',
      groupNamePlaceholder: 'Informe o nome do grupo',
    },
  },
  en: {
    GroupsCreate: {
      newButton: 'New Group',
      modalTitle: 'New Group',
      closeButton: 'Close',
      saveButton: 'Save',
      groupNameLabel: "Group's name",
      groupNameRule: 'Fill in the group name.',
      groupNamePlaceholder: 'Enter the group name',
    },
  },
  es: {
    GroupsCreate: {
      newButton: 'Nuevo Grupo',
      modalTitle: 'Nuevo Grupo',
      closeButton: 'Cerrar',
      saveButton: 'Guardar',
      groupNameLabel: 'Nombre del grupo',
      groupNameRule: 'Complete el nombre del grupo.',
      groupNamePlaceholder: 'Introduce el nombre del grupo',
    },
  },
}
