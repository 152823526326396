import styled, { keyframes } from 'styled-components'

const appearFromLeft = keyframes`
  to {
    background-position: 150% center;
  }
`

export const Container = styled.div`
  .ant-menu-dark.ant-menu-inline .ant-menu-sub.ant-menu-inline {
    background: #0f1112;
  }
`

export const Logo = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    margin: 0 auto;
    height: 1.8rem;
    margin: 1rem 0;
  }

  h2 {
    margin-bottom: 0;
    margin-left: 1rem;
    font-family: 'Open Sans', sans-serif;

    background-image: linear-gradient(
      -225deg,
      #fff 0%,
      #3c8acb 39%,
      #fff 67%,
      #17ead9 100%
    );

    background-size: auto auto;
    background-clip: border-box;
    background-size: 200% auto;
    color: #fff;
    background-clip: text;
    fill: #fff;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: ${appearFromLeft} 2s linear;
  }
`
