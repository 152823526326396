export default {
  pt: {
    TasksRegistrationFormsCreate: {
      cardTitle: 'Novo Formulário',
      keepRegisterText: 'Continuar cadastrando Formulário?',
      registerButton: 'Cadastrar Formulário',
      titleLabel: 'Título',
      titleRule: 'Por favor informe o título do formulário.',
      titlePlaceholder: 'Insira o título',
      descriptionLabel: 'Descrição',
      descriptionPlaceholder: 'Insira a descrição',
    },
  },
  en: {
    TasksRegistrationFormsCreate: {
      cardTitle: 'New Form',
      keepRegisterText: 'Continue registering Form?',
      registerButton: 'Register Form',
      titleLabel: 'Title',
      titleRule: 'Please provide the title of the form.',
      titlePlaceholder: 'Enter title',
      descriptionLabel: 'Description',
      descriptionPlaceholder: 'Enter description',
    },
  },
  es: {
    TasksRegistrationFormsCreate: {
      cardTitle: 'Nueva forma',
      keepRegisterText: 'Continuar registrándose ¿Formulario?',
      registerButton: 'Formulario de registro',
      titleLabel: 'Título',
      titleRule: 'Proporcione el título del formulario.',
      titlePlaceholder: 'Introduce el título',
      descriptionLabel: 'Descripción',
      descriptionPlaceholder: 'Introduce la descripción',
    },
  },
}
