/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import duration from 'dayjs/plugin/duration'
import updateLocale from 'dayjs/plugin/updateLocale'
import 'dayjs/locale/en'
import 'dayjs/locale/es'
import 'dayjs/locale/pt-br'

import { ConfigProvider } from 'antd'
import pt_BR from 'antd/lib/locale/pt_BR'
import en_US from 'antd/lib/locale/en_US'
import es_ES from 'antd/lib/locale/es_ES'

import { QueryClientProvider, QueryClient } from 'react-query'
import GlobalStyle from '~/global/styles'
import 'antd/dist/reset.css'

import Routes from '~/routes/index'
import { AuthProvider } from '~/hooks/Auth/AuthContext'
import { useLocale } from '~/hooks/locale/useLocale'
import { i18nConfig } from '~/utils/locale'
import { ServiceStatusProvider } from '~/hooks/ServiceStatus/useServiceStatus'

const App: React.FC = () => {
  const queryClient = new QueryClient()
  const [antdLocale, setAntdLocale] = useState(pt_BR)
  const { locale } = useLocale()

  dayjs.extend(utc)
  dayjs.extend(duration)
  dayjs.extend(updateLocale)

  useEffect(() => {
    if (locale === 'pt-BR') {
      i18nConfig('pt-BR')
      setAntdLocale(pt_BR)

      dayjs.locale('pt-br')
      dayjs.updateLocale('pt-br', {
        weekdaysMin: ['Sáb', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Dom'],
        monthsShort: [
          'Jan',
          'Fev',
          'Mar',
          'Abr',
          'Mai',
          'Jun',
          'Jul',
          'Ago',
          'Set',
          'Out',
          'Nov',
          'Dez',
        ],
      })
    } else if (locale === 'en-US') {
      i18nConfig('en-US')
      setAntdLocale(en_US)

      dayjs.locale('en')
      dayjs.updateLocale('en', {
        weekdaysMin: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        monthsShort: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec',
        ],
      })
    } else if (locale === 'es-ES') {
      i18nConfig('es-ES')
      setAntdLocale(es_ES)

      dayjs.locale('es')
      dayjs.updateLocale('es', {
        weekdaysMin: ['Sáb', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Dom'],
        monthsShort: [
          'Ene',
          'Feb',
          'Mar',
          'Abr',
          'May',
          'Jun',
          'Jul',
          'Ago',
          'Set',
          'Oct',
          'Nov',
          'Dic',
        ],
      })
    }
  }, [locale])

  return (
    <ConfigProvider locale={antdLocale}>
      <Router>
        <QueryClientProvider client={queryClient}>
          <ServiceStatusProvider>
            <AuthProvider>
              <Routes />
            </AuthProvider>
          </ServiceStatusProvider>
        </QueryClientProvider>
        <GlobalStyle />
      </Router>
    </ConfigProvider>
  )
}

export default App
