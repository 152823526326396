/* eslint-disable react/jsx-curly-newline */

import { Avatar } from 'antd'
import React from 'react'

import { useContributor } from '~/hooks/Contributors/useContributor'
import { createLetterAvatar } from '~/utils/letter'
import { translate } from '~/utils/locale'

import { Container } from './styles'

const StatusContributor: React.FC = () => {
  const { data } = useContributor()

  return (
    <Container>
      {data?.status !== null ? (
        <div className="wrapper">
          {data?.status?.color !== null ? (
            <Avatar
              shape="square"
              size={80}
              style={{
                fontSize: 20,
                background: '#EAEAEA',
                color: `${data?.status?.color}`,
              }}
            >
              {createLetterAvatar(`${data?.status?.title}`)}
            </Avatar>
          ) : (
            <Avatar
              shape="square"
              size={155}
              style={{
                fontSize: 40,
                background: '#EAEAEA',
                color: '#108ee9',
              }}
            >
              {createLetterAvatar(`${data?.status?.title}`)}
            </Avatar>
          )}
          <div className="text">
            <h3>{data?.status?.title}</h3>
            {/* <p>
            Criado em&nbsp;
            {formattedDate}
          </p> */}
            <p>
              {data?.status?.type === 'enabled'
                ? translate('StatusContributor.enabledText')
                : ''}
              {data?.status?.type === 'enabled_with_restrictions'
                ? translate('StatusContributor.enabledWithRestrictionsText')
                : ''}
              {data?.status?.type === 'blocked'
                ? translate('StatusContributor.blockedText')
                : ''}
              {data?.status?.type === 'custom'
                ? translate('StatusContributor.customText')
                : ''}
            </p>
            <p>
              {data?.status?.description !== null ? (
                <p>
                  {translate('StatusContributor.descriptionText')}
                  &nbsp;
                  {data?.status?.description}
                </p>
              ) : (
                translate('StatusContributor.noneDescriptionText')
              )}
            </p>
          </div>
        </div>
      ) : (
        <div>
          <p>{translate('StatusContributor.noneStatusText')}</p>
        </div>
      )}
    </Container>
  )
}

export default StatusContributor
