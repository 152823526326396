export default {
  pt: {
    StatusContributorUpdate: {
      editTooltip: 'Editar',
      editButton: 'Editar',
      modalTitle: 'Editar status do colaborador',
      closeButton: 'Fechar',
      saveButton: 'Salvar',
      statusLabel: 'Status',
      statusPlaceholder: 'Informe o status',
    },
  },
  en: {
    StatusContributorUpdate: {
      editTooltip: 'Edit',
      editButton: 'Edit',
      modalTitle: 'Edit contributor status',
      closeButton: 'Close',
      saveButton: 'Save',
      statusLabel: 'Status',
      statusPlaceholder: 'Report the status',
    },
  },
  es: {
    StatusContributorUpdate: {
      editTooltip: 'Editar',
      editButton: 'Editar',
      modalTitle: 'Editar estado de colaborador',
      closeButton: 'Cerrar',
      saveButton: 'Guardar',
      statusLabel: 'Estado',
      statusPlaceholder: 'Informar el estado',
    },
  },
}
