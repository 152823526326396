import styled from 'styled-components'

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  margin-top: 0.6rem;
  grid-gap: 1rem;
`

export const Card = styled.div`
  background: white;

  padding: 1.2rem;
  border-radius: 1rem;

  transition: all 0.3s ease-in;
`

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Content = styled.div`
  width: 100%;

  .header {
    display: flex;
    align-items: center;

    z-index: 1;

    h4 {
      margin-bottom: 0;

      color: white;
      font-weight: normal;
    }

    span {
      margin-inline-end: 0.4rem;
      padding: 0.4rem;

      border-radius: 8px;
      opacity: 0.8;

      display: flex;
      align-items: center;

      svg {
        color: white;
        font-size: 18px;
      }
    }

    .span-1 {
      background: var(--primary-color);
    }

    .span-2 {
      background: var(--quartenary-color);
    }

    .span-3 {
      background: var(--secondary-color);
    }
  }

  .section {
    width: 100%;
    margin-top: 0.5rem;

    display: flex;
    align-items: center;

    .title {
      h3 {
        font-weight: bold;
        color: var(--primary-initial-text);
        -webkit-font-smoothing: auto;
        margin-bottom: 0;
        margin-right: 14px;
      }

      #rate {
        font-size: 1.4rem;
      }
    }
  }

  .subtitle {
    font-size: 0.8rem;
    color: var(--secondary-initial-text);
    margin-left: 2rem;
  }
`

export const HelpContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
`
