/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable @typescript-eslint/no-explicit-any */

import React from 'react'
import { Helmet } from 'react-helmet'

import CreateContactClients from '~/components/DataManipulation/Clients/Contacts/Create'
import { ClientsContactsProvider } from '~/hooks/Clients/useContactClients'
import { ListClientsProvider } from '~/hooks/Clients/useListClients'

import { translate } from '~/utils/locale'

const NewContacts: React.FC = () => {
  return (
    <ClientsContactsProvider>
      <Helmet>
        <title>{translate('NewContacts.helmetTitle')}</title>
      </Helmet>
      <ListClientsProvider>
        <CreateContactClients />
      </ListClientsProvider>
    </ClientsContactsProvider>
  )
}

export default NewContacts
