import { Input } from 'antd'
import React from 'react'

interface TimeInputProps {
  value?: string
  placeholder?: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const TimeInput: React.FC<TimeInputProps> = ({
  value,
  onChange,
  placeholder,
}) => {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value

    const numericValue = inputValue.replace(/\D/g, '')

    let formattedValue = numericValue
    if (numericValue.length >= 5) {
      formattedValue = `${numericValue.slice(0, 2)}:${numericValue.slice(
        2,
        4,
      )}:${numericValue.slice(4, 6)}`
    } else if (numericValue.length >= 3) {
      formattedValue = `${numericValue.slice(0, 2)}:${numericValue.slice(
        2,
        4,
      )}${numericValue.length > 4 ? `:${numericValue.slice(4, 6)}` : ''}`
    } else if (numericValue.length >= 1) {
      formattedValue = `${numericValue.slice(0, 2)}${
        numericValue.length > 2 ? `:${numericValue.slice(2, 4)}` : ''
      }${numericValue.length > 4 ? `:${numericValue.slice(4, 6)}` : ''}`
    }

    if (onChange) {
      e.target.value = formattedValue
      onChange(e)
    }
  }

  return (
    <Input
      value={value}
      onChange={handleInputChange}
      placeholder={placeholder}
      maxLength={8}
    />
  )
}
export default TimeInput
