/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable consistent-return */
import React, { useContext, createContext } from 'react'
import { useQuery } from 'react-query'

import { message } from 'antd'

import api from '~/services/api'

import { useCompanies } from '~/hooks/Companies/useCompanies'
import { useGroup } from '~/hooks/Groups/useGroup'
import { useLocale } from '~/hooks/locale/useLocale'

import { ShowError } from '~/utils/errors/apiErrors'
import { translate } from '~/utils/locale'

export interface ContributorGroupProps {
  name: string
  id: string
  email: string
  phone: string
  created_at: Date
  initialTime: string
  phoneFormat: string

  user: {
    id: string
  }
}

interface ContributorGroupContextData {
  contributorGroup?: ContributorGroupProps[]
  createContributor(userId: string | undefined, group_id: string): void
  deleteContributor(userId: string | undefined, group_id: string): void
  isLoading: boolean
  refetch: () => void
  isFetching: boolean
}

const ContributorGroupContext = createContext<ContributorGroupContextData>(
  {} as ContributorGroupContextData,
)

export const ContributorGroupProvider: React.FC = ({ children }) => {
  const { refetch: refetchUser } = useGroup()
  const { locale } = useLocale()
  const { selectedCompany } = useCompanies()

  const {
    data: contributorGroup,
    isLoading,
    refetch,
    isFetching,
  } = useQuery(
    [`grupo de colaboradores${selectedCompany}`, selectedCompany],

    async () => {
      try {
        const response = await api.get(
          `/company/${selectedCompany}/users-contacts`,
        )

        const { data } = response

        return data.results
      } catch (err: any) {
        ShowError(
          err.message,
          translate('useUserGroup.getErrorMessage'),
          locale,
        )
      }
    },
  )

  async function createContributor(contributorId: string, group_id: string) {
    try {
      await api.post(
        `/company/${selectedCompany}/user/${contributorId}/user-group`,
        {
          group_id,
        },
      )

      refetchUser()
      message.success(translate('useUserGroup.createSuccessMessage'))
    } catch (err: any) {
      ShowError(
        err.message,
        translate('useUserGroup.createErrorMessage'),
        locale,
      )
    }
  }

  async function deleteContributor(contributorId: string, group_id: string) {
    try {
      await api.delete(
        `/company/${selectedCompany}/user/${contributorId}/group/${group_id}/user-group`,
      )

      refetchUser()
      message.success(translate('useUserGroup.deleteSuccessMessage'))
    } catch (err: any) {
      ShowError(
        err.message,
        translate('useUserGroup.deleteErrorMessage'),
        locale,
      )
    }
  }

  return (
    <ContributorGroupContext.Provider
      value={{
        contributorGroup,
        createContributor,
        deleteContributor,
        isLoading,
        refetch,
        isFetching,
      }}
    >
      {children}
    </ContributorGroupContext.Provider>
  )
}

export function useContributorGroup(): ContributorGroupContextData {
  const context = useContext(ContributorGroupContext)

  if (!context) {
    throw new Error('useAuth must be used within an AutoProvider')
  }

  return context
}
