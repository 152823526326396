import React, { useState } from 'react'

import { Button, Modal, Space, Typography } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

import ScaleClientsTable from '~/components/DataManipulation/Scales/ScaleClients/ScaleClientsTable'
import ClientsFilter from '~/components/DataManipulation/Clients/ClientsFilter'

import { useListContributors } from '~/hooks/Contributors/useListContributors'
import { translate } from '~/utils/locale'

const ScaleClientsAdd: React.FC = () => {
  const { Title } = Typography

  const [isModalVisible, setIsModalVisible] = useState(false)

  const { isLoading, isFetching } = useListContributors()

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  return (
    <>
      <Button type="primary" onClick={showModal}>
        {translate('ScalesClientsAdd.addButton')}
      </Button>
      <Modal
        title={
          <Space>
            <Title level={5}>{translate('ScalesClientsAdd.modalTitle')}</Title>
            {!isLoading && isFetching && <LoadingOutlined spin />}
          </Space>
        }
        open={isModalVisible}
        width={1200}
        onCancel={handleCancel}
        footer={[
          <Space key="FooterProps">
            <Button key="back" onClick={handleCancel}>
              {translate('ScalesClientsAdd.closeButton')}
            </Button>

            <ClientsFilter />
          </Space>,
        ]}
      >
        <ScaleClientsTable />
      </Modal>
    </>
  )
}

export default ScaleClientsAdd
