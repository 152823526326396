/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable func-names */
import { Col, Form, Input, Row, Select, Space, Tour } from 'antd'
import React, { useEffect } from 'react'
import { useTaskCreate } from '~/hooks/Tasks/Create/createTask'

import { translate } from '~/utils/locale'

import ContributorsTaskTable from '../Modal/Contributor/Table'

import { useTaskTour } from '~/hooks/Tour/useTaskTour'
import { useTour } from '~/hooks/Tour/useTour'
import TeamsTaskTable from '../Modal/Teams/Table'

interface ResponsibleCreateTaskProps {
  form: any
}

const ResponsibleCreateTask: React.FC<ResponsibleCreateTaskProps> = ({
  form,
}) => {
  const { contributorData, teamData, setType, type } = useTaskCreate()

  const {
    openResponsibleTask,
    setOpenResponsibleTask,
    createResponsibleSteps,
  } = useTaskTour()

  const { setIsTour } = useTour()

  function onClose() {
    setIsTour(false)
    setOpenResponsibleTask(false)
  }

  const { Option } = Select

  const handleChange = (value: string) => {
    setType(value)
  }

  useEffect(() => {
    if (contributorData?.id) {
      form.setFieldsValue({ id: contributorData?.name })
    }

    if (teamData?.id) {
      form.setFieldsValue({
        id: teamData?.name,
        receive: teamData?.amount_to_receive,
      })
    }
  }, [contributorData, teamData, form])

  return (
    <>
      <Row gutter={12}>
        <Col span={12}>
          <Form.Item label="Tarefa será realizada por" name="type">
            <Select
              placeholder="Informe por quem a tarefa será realizada"
              defaultValue="collaborator"
              onChange={handleChange}
              style={{ width: '100%' }}
            >
              <Option value="collaborator">Colaborador</Option>
              <Option value="team">Equipes</Option>
            </Select>
          </Form.Item>
        </Col>

        {type === 'collaborator' ? (
          <Col span={12}>
            <Form.Item
              label={
                <Space>
                  <p>Colaborador</p>
                  {contributorData?.id ? (
                    <ContributorsTaskTable form={form} />
                  ) : (
                    ''
                  )}
                </Space>
              }
              name="id"
            >
              {contributorData?.id ? (
                <Input />
              ) : (
                <ContributorsTaskTable form={form} />
              )}
            </Form.Item>
          </Col>
        ) : (
          ''
        )}

        {type === 'team' ? (
          <Col span={12}>
            <Form.Item
              label={
                <Space>
                  <p>{translate('TasksCreateResponsible.teamText')}</p>
                  {teamData?.id ? <TeamsTaskTable /> : ''}
                </Space>
              }
              name="id"
            >
              {teamData?.id ? <Input /> : <TeamsTaskTable />}
            </Form.Item>
          </Col>
        ) : (
          ''
        )}
      </Row>

      <Tour
        open={openResponsibleTask}
        onClose={onClose}
        steps={createResponsibleSteps}
        indicatorsRender={(current, total) => (
          <span>
            {current + 1} / {total}
          </span>
        )}
      />
    </>
  )
}

export default ResponsibleCreateTask
