/* eslint-disable camelcase */
/* eslint-disable react/no-danger */
/* eslint-disable react/require-default-props */
import React, { useEffect, useState } from 'react'
import { Menu, Button, Dropdown, Badge, Tooltip } from 'antd'

import {
  BellOutlined,
  InfoCircleOutlined,
  NotificationOutlined,
} from '@ant-design/icons'
import { useLocation, useParams } from 'react-router-dom'
import {
  INotification,
  useNotifications,
} from '~/hooks/Notifications/useNotifications'
import { Container, NotificationIcon, Title, Content, Wrapper } from './styles'
import { translate } from '~/utils/locale'

interface NotificationProps {
  data: INotification
}

const DropdownNotifications: React.FC = () => {
  const { notifications, total, isLoading, totalUnreadNotifications } =
    useNotifications()

  const [oldNotifications, setOldNotifications] = useState<INotification[]>([])
  const [newNotifications, setNewNotifications] = useState<INotification[]>([])

  const location = useLocation()
  const local = location.pathname

  const { ticketId } = useParams<{ ticketId: string }>()

  useEffect(() => {
    if (notifications) {
      setNewNotifications(() => {
        return notifications.filter(
          (notification) => notification.old === false,
        )
      })

      setOldNotifications(() => {
        return notifications.filter((notification) => notification.old === true)
      })
    }
  }, [notifications])

  if (!notifications) {
    return <></>
  }

  if (isLoading) {
    return <Button type="text" icon={<BellOutlined />} loading />
  }

  if (total === 0) {
    return (
      <Tooltip title={translate('MiscDropdownNotifications.noNotifications')}>
        <>
          {local !== '/helpdesk' &&
          local !== '/ticket' &&
          local !== '/novoTicket' &&
          local !== `/ticket/${ticketId}` ? (
            <Button type="text" icon={<BellOutlined />} />
          ) : (
            <Button
              type="text"
              icon={<BellOutlined />}
              style={{ color: 'white' }}
            />
          )}
        </>
      </Tooltip>
    )
  }

  return (
    <Dropdown
      placement="bottomCenter"
      dropdownRender={() => (
        <Menu mode="inline">
          <Wrapper>
            {newNotifications.length > 0 ? (
              <Menu.ItemGroup
                key="0"
                title={translate('MiscDropdownNotifications.recent')}
              >
                {newNotifications.map((notification) => (
                  <Notification data={notification} key={notification.id} />
                ))}
              </Menu.ItemGroup>
            ) : (
              ''
            )}

            {oldNotifications.length > 0 ? (
              <Menu.ItemGroup
                key="0"
                title={translate('MiscDropdownNotifications.old')}
              >
                {oldNotifications.map((notification) => (
                  <Notification data={notification} key={notification.id} />
                ))}
              </Menu.ItemGroup>
            ) : (
              ''
            )}
          </Wrapper>
        </Menu>
      )}
    >
      <Button
        type="text"
        icon={
          <Badge count={totalUnreadNotifications}>
            <BellOutlined />
          </Badge>
        }
      />
    </Dropdown>
  )
}
const Notification = ({ data }: NotificationProps) => {
  const { readNotification } = useNotifications()
  const { id, title, content, type, read, links_to } = data
  return (
    <Menu.Item
      key={id}
      onClick={async () => {
        await readNotification(id)
        if (links_to) {
          window.location.href = links_to
        }
      }}
      style={{ margin: '0.5rem 0' }}
    >
      <Container>
        {!read && <Badge status="error" />}
        <NotificationIcon>
          {type === 'info' ? (
            <InfoCircleOutlined style={{ fontSize: '18px' }} />
          ) : (
            <NotificationOutlined style={{ fontSize: '18px' }} />
          )}
        </NotificationIcon>
        <div>
          <Title>{title}</Title>
          <Content>
            <div
              dangerouslySetInnerHTML={{
                __html: content,
              }}
            />
          </Content>
        </div>
      </Container>
    </Menu.Item>
  )
}
export default DropdownNotifications
