export default {
  pt: {
    Users: {
      helmetTitle: 'Usuários',
    },
  },
  en: {
    Users: {
      helmetTitle: 'Users',
    },
  },
  es: {
    Users: {
      helmetTitle: 'Usuarios',
    },
  },
}
