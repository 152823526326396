export default {
  pt: {
    TasksTabs: {
      errorMessage: 'Erro ao listar equipamentos',
      pageHeaderView: 'Visualizar Tarefa',
      pageHeaderEdit: 'Editar Tarefa',
      detailsTab: 'Detalhes',
      equipmentsTab: 'Equipamentos',
      reportsTab: 'Evidências',
      statusTab: 'Status',
    },
  },
  en: {
    TasksTabs: {
      errorMessage: 'Error listing equipment',
      pageHeaderView: 'View Task',
      pageHeaderEdit: 'Edit Task',
      detailsTab: 'Details',
      equipmentsTab: 'Equipments',
      reportsTab: 'Reports',
      statusTab: 'Status',
    },
  },
  es: {
    TasksTabs: {
      errorMessage: 'Error en la lista de equipos',
      pageHeaderView: 'Ver Tarea',
      pageHeaderEdit: 'Editar Tarea',
      detailsTab: 'Detalles',
      equipmentsTab: 'Equipo',
      reportsTab: 'Informes',
      statusTab: 'Status',
    },
  },
}
