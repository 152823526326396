export default {
  pt: {
    SignIn: {
      emailYup: {
        required: 'Email obrigatório',
      },
      passwordYup: {
        required: 'Senha obrigatória',
      },
      errorMessage: 'Autenticação Falhou. Verifique os dados.',
      helmetTitle: 'Entrar',
      title: 'Faça seu Login',
      subTitle: 'Preencha os campos e faça seu login na nossa plataforma.',
      emailPlaceholder: 'Email do usuário',
      passwordPlaceholder: 'Senha',
      forgotPasswordText: 'Esqueceu a senha?',
      buttonText: 'Entrar',
      createAccountText: 'Criar conta',
    },
  },
  en: {
    SignIn: {
      emailYup: {
        required: 'Email required',
      },
      passwordYup: {
        required: 'Mandatory password',
      },
      errorMessage: 'Authentication Failed. Check the data.',
      helmetTitle: 'Login',
      title: 'Login',
      subTitle: 'Fill in the fields and log in to our platform.',
      emailPlaceholder: 'User email',
      passwordPlaceholder: 'Password',
      forgotPasswordText: 'Forgot password?',
      buttonText: 'Log in',
      createAccountText: 'Create an account',
    },
  },
  es: {
    SignIn: {
      emailYup: {
        required: 'Email requerido',
      },
      passwordYup: {
        required: 'Contraseña obligatoria',
      },
      errorMessage: 'Autenticación fallida. Comprueba los datos.',
      helmetTitle: 'Iniciar sesión',
      title: 'Acceso',
      subTitle: 'Rellena los campos e inicia sesión en nuestra plataforma.',
      emailPlaceholder: 'Email de usuario',
      passwordPlaceholder: 'Clave',
      forgotPasswordText: '¿Olvidó la contraseña?',
      buttonText: 'Iniciar sesión',
      createAccountText: 'Crea una cuenta',
    },
  },
}
