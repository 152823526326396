export default {
  pt: {
    TasksOptionsKanbanConfigurations: {
      cleanTooltip: 'Limpar Configurações',
      configAppliedText: 'Configurações Aplicadas',
      configText: 'Configurações',
      drawerTitle: 'Configurações',
      dataPatternLabel: 'Informações padrão',
      dataPatternPlaceholder: 'Selecione as informações',
      dataPatternGroup: 'Dados padrão',
      dataPatternCode: 'Código Interno',
      dataPatternDate: 'Data da Tarefa',
      dataPatternResponsible: 'Responsável',
      dataPatternClient: 'Cliente',
      dataPatternTypeTask: 'Tipo de tarefa',
      dataTaskLabel: 'Informações da tarefa',
      dataTaskPlaceholder: 'Selecione as informações',
      dataTaskGroup: 'Dados da tarefa',
      dataTaskCheckin: 'Tipo de checkin',
      dataTaskPriority: 'Prioridade',
      dataTaskStatus: 'Status',
      dataTaskDuration: 'Duração',
      dataTaskExternalCode: 'Código externo',
      dataTaskAddress: 'Endereço',
      columnsLabel: 'Recolher Colunas',
      columnsActive: 'Ativo',
      columnsInactive: 'Inativo',
      applyButton: 'Aplicar',
      cleanButton: 'Limpar',
    },
  },
  en: {
    TasksOptionsKanbanConfigurations: {
      cleanTooltip: 'Clear Settings',
      configAppliedText: 'Applied Settings',
      configText: 'Settings',
      drawerTitle: 'Settings',
      dataPatternLabel: 'Standard Info',
      dataPatternPlaceholder: 'Select information',
      dataPatternGroup: 'Default data',
      dataPatternCode: 'Internal Code',
      dataPatternDate: 'Task Date',
      dataPatternResponsible: 'Responsible',
      dataPatternClient: 'Customer',
      dataPatternTypeTask: 'Task type',
      dataTaskLabel: 'Task information',
      dataTaskPlaceholder: 'Select information',
      dataTaskGroup: 'Task data',
      dataTaskCheckin: 'Checkin type',
      dataTaskPriority: 'Priority',
      dataTaskStatus: 'Status',
      dataTaskDuration: 'Duration',
      dataTaskExternalCode: 'External Code',
      dataTaskAddress: 'Address',
      columnsLabel: 'Collapse Columns',
      columnsActive: 'Active',
      columnsInactive: 'Inactive',
      applyButton: 'Apply',
      cleanButton: 'Clear',
    },
  },
  es: {
    TasksOptionsKanbanConfigurations: {
      cleanTooltip: 'Borrar configuración',
      configAppliedText: 'Configuraciones aplicadas',
      configText: 'Configuraciones',
      drawerTitle: 'Configuraciones',
      dataPatternLabel: 'Información estándar',
      dataPatternPlaceholder: 'Seleccionar información',
      dataPatternGroup: 'Datos predeterminados',
      dataPatternCode: 'Código Interno',
      dataPatternDate: 'Fecha de la tarea',
      dataPatternResponsible: 'Responsable',
      dataPatternClient: 'Cliente',
      dataPatternTypeTask: 'Tipo de tareaa',
      dataTaskLabel: 'Información de la tarea',
      dataTaskPlaceholder: 'Seleccionar información',
      dataTaskGroup: 'Datos de la tarea',
      dataTaskCheckin: 'Tipo de entrada',
      dataTaskPriority: 'Prioridad',
      dataTaskStatus: 'Status',
      dataTaskDuration: 'Duración',
      dataTaskExternalCode: 'Código externo',
      dataTaskAddress: 'Dirección',
      columnsLabel: 'Contraer columnas',
      columnsActive: 'Activo',
      columnsInactive: 'Inactivo',
      applyButton: 'Aplicar',
      cleanButton: 'Limpio',
    },
  },
}
