/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
import React from 'react'
import { Table, Tooltip, Popconfirm, Button, Typography, Space } from 'antd'
import {
  DeleteFilled,
  LoadingOutlined,
  ProfileOutlined,
} from '@ant-design/icons'
import { ColumnsType } from 'antd/es/table'
import { Link } from 'react-router-dom'

import { useListScales } from '~/hooks/Scales/useListScales'
import { translate } from '~/utils/locale'

interface Company {
  id: string
  name: string
}

interface DataType {
  company: Company
  contributorsSchedules: any[]
  created_at: string
  customersSchedules: any[]
  id: number
  scheduleTimes: any[]
  title: string
}

interface Contributors {
  id: number
  name: string
  avatar: string
}

interface Teams {
  id: number
  team: {
    collaborator_id: string
    id: string
    title: string
  }
}

interface Clients {
  id: number
  name: string
  avatar: string
}

// interface Dia {
//   id: number
//   data: string
//   checkin: string
//   checkout: string
// }

interface ScalesTableProps {
  data: DataType[]
  loading: boolean
}

const ScalesTable: React.FC<ScalesTableProps> = ({ data, loading }) => {
  const { Text } = Typography

  const { scalesTotal, setPageSize, setNumberPage, deleteScale } =
    useListScales()

  const columns: ColumnsType<DataType> = [
    {
      title: translate('ScalesTable.titleColumn'),
      dataIndex: 'title',
      key: 'title',
      align: 'left',
      render: (title: string, record) => (
        <Link to={`/escalas/editar/${record.id}`}>{title}</Link>
      ),
    },
    {
      title: translate('ScalesTable.dateRangeColumn'),
      key: 'dateRange',
      align: 'center',
      dataIndex: 'dateRange',
    },
    {
      title: translate('ScalesTable.contributorsColumn'),
      dataIndex: 'contributorsSchedules',
      key: 'contributorsSchedules',
      align: 'center',
      render: (contributors: Contributors[]) => {
        return (
          <Text>
            {contributors.length === 0
              ? translate('ScalesTable.noneContributorText')
              : contributors.length === 1
                ? `1 ${translate('ScalesTable.contributorText')}`
                : `${contributors.length} ${translate(
                    'ScalesTable.contributorsText',
                  )}`}
          </Text>
        )
      },
    },
    {
      title: translate('ScalesTable.teamsColumn'),
      dataIndex: 'teamsSchedules',
      key: 'teamsSchedules',
      align: 'center',
      render: (teams: Teams[]) => {
        return (
          <Text>
            {teams.length === 0
              ? translate('ScalesTable.noneTeamText')
              : teams.length === 1
                ? `1 ${translate('ScalesTable.teamText')}`
                : `${teams.length} ${translate('ScalesTable.teamsText')}`}
          </Text>
        )
      },
    },
    {
      title: translate('ScalesTable.clientsColumn'),
      dataIndex: 'customersSchedules',
      key: 'customersSchedules',
      align: 'center',
      render: (clients: Clients[]) => {
        return (
          <Text>
            {clients.length === 0
              ? translate('ScalesTable.noneClientText')
              : clients.length === 1
                ? `1 ${translate('ScalesTable.clientText')}`
                : `${clients.length} ${translate('ScalesTable.clientsText')}`}
          </Text>
        )
      },
    },
    {
      title: translate('ScalesTable.actionsColumn'),
      key: 'remove',
      align: 'center',
      render: (record) => (
        <Space size="middle">
          <Tooltip title={translate('ScalesTable.viewTooltip')}>
            <Link to={`/escalas/editar/${record.id}`}>
              <Button
                type="primary"
                ghost
                shape="circle"
                icon={<ProfileOutlined />}
              />
            </Link>
          </Tooltip>
          <Popconfirm
            title={translate('ScalesTable.deletePopconfirm')}
            onConfirm={() => deleteScale(record.id)}
          >
            <Tooltip title={translate('ScalesTable.deleteTooltip')}>
              <Button danger ghost shape="circle" icon={<DeleteFilled />} />
            </Tooltip>
          </Popconfirm>
        </Space>
      ),
    },
  ]

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        'selectedRows: ',
        selectedRows,
      )
    },
    getCheckboxProps: (record: DataType) => ({
      name: record.title,
    }),
  }

  return (
    <Table
      rowKey={(record) => record.id}
      rowSelection={{ ...rowSelection }}
      loading={{
        indicator: <LoadingOutlined />,
        spinning: loading,
        size: 'large',
      }}
      pagination={{
        total: scalesTotal,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '50', '100', '200'],
        defaultPageSize: 10,
        showTotal: (total, range) => `${range[0]} - ${range[1]} / ${total}`,
        onChange: (page, pageSize) => {
          setPageSize(pageSize!)
          setNumberPage(page)
        },
      }}
      columns={columns}
      dataSource={data}
      scroll={{ x: 1000 }}
    />
  )
}

export default ScalesTable
