export default {
  pt: {
    TasksRegistrationTypeTasksAbout: {
      editTooltip: 'Editar',
      modalTitle: 'Editar tipo de tarefa',
      closeButton: 'Fechar',
      saveButton: 'Salvar',
      detailTab: 'Detalhes',
      titleLabel: 'Título',
      titlePlaceholder: 'Insira o título',
      descriptionLabel: 'Descrição',
      valueTaskLabel: 'Valor a receber do Cliente Principal',
      valueTaskPlaceholder: 'Insira o valor a receber do Cliente Principal',
      value_received_TaskLabel: 'Valor a pagar para o colaborador',
      value_received_TaskPlaceholder:
        'Insira o valor a pagar para o colaborador',
      descriptionPlaceholder: 'Insira a descrição',
      toleranceTimeLabel: 'Tempo máximo para iniciar o serviço (SLA)',
      runtimeLabel: 'Tempo estima do execução',
      obligationsTab: 'Obrigatoriedades',
      obligationsLabel: 'Obrigatoriedades',
      obligationsPlaceholder: 'Selecione a obrigatoriedade',
      fillInReport: 'Preencher Relatório',
      getSignature: 'Coletar assinaturas',
      takePictures: 'Tirar fotos',
      fillInKmDriven: 'Preencher km rodado',
      sendDigitalOSByEmail: 'Enviar OS digital por e-mail',
      statusLabel: 'Status',
      statusActive: 'Ativo',
      statusInactive: 'Inativo',
      picturesNumber: 'Selecione a quantidade de fotos',
      photoText: 'fotos',
    },
  },
  en: {
    TasksRegistrationTypeTasksAbout: {
      editTooltip: 'Edit',
      modalTitle: 'Edit Task Type',
      closeButton: 'Close',
      saveButton: 'Save',
      detailTab: 'Details',
      titleLabel: 'Title',
      titlePlaceholder: 'Enter the title',
      descriptionLabel: 'Description',
      descriptionPlaceholder: 'Enter description',
      valueTaskLabel: 'Valor a receber do Cliente Principal',
      valueTaskPlaceholder: 'Insira o valor a receber do Cliente Principal',
      value_received_TaskLabel: 'Valor a pagar para o colaborador',
      value_received_TaskPlaceholder:
        'Insira o valor a pagar para o colaborador',
      toleranceTimeLabel: 'Service SLA',
      runtimeLabel: 'Estimated execution time',
      obligationsTab: 'Obligations',
      obligationsLabel: 'Obligations',
      obligationsPlaceholder: 'Select Obligations',
      fillInReport: 'Fill in Report',
      getSignature: 'Collect signatures',
      takePictures: 'Take pictures',
      fillInKmDriven: 'Fill in km traveled',
      sendDigitalOSByEmail: 'Send digital SO by email',
      statusLabel: 'Status',
      statusActive: 'Active',
      statusInactive: 'Inactive',
      picturesNumber: 'Select the number of photos',
      photoText: 'photos',
    },
  },
  es: {
    TasksRegistrationTypeTasksAbout: {
      editTooltip: 'Editar',
      modalTitle: 'Editar tipo de tarea',
      closeButton: 'Cerrar',
      saveButton: 'Guardar',
      detailTab: 'Detalles',
      titleLabel: 'Título',
      titlePlaceholder: 'Ingresa el titulo',
      descriptionLabel: 'Descripción',
      descriptionPlaceholder: 'Ingrese la descripción',
      valueTaskLabel: 'Valor a receber do Cliente Principal',
      valueTaskPlaceholder: 'Insira o valor a receber do Cliente Principal',
      value_received_TaskLabel: 'Valor a pagar para o colaborador',
      value_received_TaskPlaceholder:
        'Insira o valor a pagar para o colaborador',
      toleranceTimeLabel: 'SLA de servicio',
      runtimeLabel: 'Tiempo estimado de ejecución',
      obligationsTab: 'Obligaciones',
      obligationsLabel: 'Obligaciones',
      obligationsPlaceholder: 'Seleccionar obligatorio',
      fillInReport: 'Rellenar informe',
      getSignature: 'Recoger firmas',
      takePictures: 'Sacar fotos',
      fillInKmDriven: 'Ingrese los km recorridos',
      sendDigitalOSByEmail: 'Enviar SO digital por correo electrónico',
      statusLabel: 'Status',
      statusActive: 'Ativo',
      statusInactive: 'Inativo',
      picturesNumber: 'Seleccione el número de fotos',
      photoText: 'fotos',
    },
  },
}
