export default {
  pt: {
    ReportKilometers: {
      backTooltip: 'Voltar',
      cardTitle: 'Relátorio de KM Rodados',
    },
  },
  en: {
    ReportKilometers: {
      backTooltip: 'Back',
      cardTitle: 'Report of KM Wheels',
    },
  },
  es: {
    ReportKilometers: {
      backTooltip: 'Regresar',
      cardTitle: 'Informe de ruedas KM',
    },
  },
}
