/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

import { List, message, Space } from 'antd'

import DeleteButton from '~/components/DataManipulation/Misc/DeleteButton'

import { useCompanies } from '~/hooks/Companies/useCompanies'
import { useScale } from '~/hooks/Scales/useScale'
import { useLocale } from '~/hooks/locale/useLocale'

import api from '~/services/api'

import { ShowError } from '~/utils/errors/apiErrors'
import { translate } from '~/utils/locale'

const ScaleClientsList: React.FC = () => {
  const [confirmLoading, setConfirmLoading] = useState(false)

  const { scaleId } = useParams<{ scaleId: string }>()

  const { selectedCompany } = useCompanies()
  const { scaleData, scaleRefetch } = useScale()
  const { locale } = useLocale()

  const handleDelete = async (id: string) => {
    try {
      setConfirmLoading(true)
      await api.delete(
        `/company/${selectedCompany}/schedule/${scaleId}/customer/${id}`,
      )
      setConfirmLoading(false)
      scaleRefetch()
      message.success(translate('ScalesClientsList.successMessage'))
    } catch (err: any) {
      ShowError(
        err.message,
        translate('ScalesClientsList.errorMessage'),
        locale,
      )

      setConfirmLoading(false)
    }
  }

  return (
    <List
      itemLayout="vertical"
      size="large"
      dataSource={scaleData.customersSchedules}
      renderItem={(item) => (
        <List.Item key={item.id}>
          <List.Item.Meta
            title={
              <Space size="large">
                <b>{item.customer?.contact.name}</b>

                <DeleteButton
                  PopconfirmText={translate(
                    'ScalesClientsList.deletePopconfirm',
                  )}
                  TooltipText={translate('ScalesClientsList.deleteTooltip')}
                  handleDelete={handleDelete}
                  confirmLoading={confirmLoading}
                  itemId={item.customer?.id}
                />
              </Space>
            }
          />
        </List.Item>
      )}
    />
  )
}

export default ScaleClientsList
