export default {
  pt: {
    ReportTasks: {
      helmetTitle: 'Relatório de Tarefas',
    },
  },
  en: {
    ReportTasks: {
      helmetTitle: 'Task Report',
    },
  },
  es: {
    ReportTasks: {
      helmetTitle: 'Informe de Tareas',
    },
  },
}
