export default {
  pt: {
    ScalesGeneralAddressList: {
      personalAddress: 'Endereço Pessoal',
      businessAddress: 'Endereço Empresarial',
      billingAddress: 'Endereço de Cobrança',
      zipcodeLabel: 'CEP:',
      stateLabel: 'Estado:',
      cityLabel: 'Cidade:',
      neighborhoodLabel: 'Bairro:',
      streetLabel: 'Logradouro:',
      numberLabel: 'Número:',
      complementLabel: 'Complemento:',
      typeLabel: 'Tipo:',
      typePersonal: 'Pessoal',
      typeBusiness: 'Empresarial',
      typeBilling: 'Cobrança',
      notesLabel: 'Observação',
    },
  },
  en: {
    ScalesGeneralAddressList: {
      personalAddress: 'Personal Address',
      businessAddress: 'Business Address',
      billingAddress: 'Billing Address',
      zipcodeLabel: 'Zipcode:',
      stateLabel: 'State:',
      cityLabel: 'City:',
      neighborhoodLabel: 'Neighborhood:',
      streetLabel: 'Street:',
      numberLabel: 'Number:',
      complementLabel: 'Complement:',
      typeLabel: 'Type:',
      typePersonal: 'Personal',
      typeBusiness: 'Business',
      typeBilling: 'Billing',
      notesLabel: 'Observation',
    },
  },
  es: {
    ScalesGeneralAddressList: {
      personalAddress: 'Dirección personal',
      businessAddress: 'Dirección de Negocios',
      billingAddress: 'Dirección de Envio',
      zipcodeLabel: 'Código postal:',
      stateLabel: 'Estado:',
      cityLabel: 'Ciudad:',
      neighborhoodLabel: 'Barrio:',
      streetLabel: 'Calle:',
      numberLabel: 'Número:',
      complementLabel: 'Complemento:',
      typeLabel: 'Tipo:',
      typePersonal: 'Personal',
      typeBusiness: 'Negocios',
      typeBilling: 'Envio',
      notesLabel: 'Observación',
    },
  },
}
