/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-one-expression-per-line */
import { Collapse, Tag, Tooltip } from 'antd'
import Text from 'antd/lib/typography/Text'
import { format } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { translate } from '~/utils/locale'

import { Container } from './styles'

interface Address {
  city: string
  id: string
  location: {
    x: number
    y: number
  }
  neighborhood: string
  number: number
  state: string
  street: string
  zip_code: string
}

interface TaskListProps {
  checkin_type: string
  createdAt: Date
  date: string
  duration_time: string
  external_code: string
  is_enabled: string
  priority: string
  _id: string
  hours: string
  currentDate: string
  internal_code: string
  current_status: string
  customer_id: string
  task_type_id: string
  address: Address
  responsible: {
    id: string
    type: string
    data: {
      contact: {
        name: string
      }
      title: string
    }
  }
  customer: {
    id: string
    contact: {
      name: string
    }
  }
  taskType: {
    _id: string
    title: string
    description: string
    runtime: string
    valueTask: number
  }
}

interface TaskStatusData {
  taskStatusData: TaskListProps
}

const CardsKanban: React.FC<TaskStatusData> = ({ taskStatusData }) => {
  const [colors, setColors] = useState<string>()
  const [colorHex, setColorHex] = useState<string>()

  const dateTask = format(
    new Date(taskStatusData.date),
    `dd/MM/yyyy '${translate('TasksOptionsKanbanCards.dateString')}' HH:mm'h'`,
  )

  const kanbanData = JSON.parse(localStorage.getItem('kanbanConfigurations')!)

  const config = kanbanData?.data_task
  const pattern = kanbanData?.data_pattern

  const size = pattern?.length

  const optionVisualization = localStorage.getItem('visualizationKanban')

  const responsibleTask = taskStatusData.responsible

  useEffect(() => {
    const status = taskStatusData.current_status

    if (status === 'received') {
      setColors('blue')
      setColorHex('#3C8ACB')
    }

    if (status === 'en-route') {
      setColors('green')
      setColorHex('#49c870')
    }

    if (status === 'working') {
      setColors('geekblue')
      setColorHex('#3969B7')
    }

    if (status === 'break') {
      setColors('yellow')
      setColorHex('#eccc01')
    }

    if (status === 'canceled') {
      setColors('magenta')
      setColorHex('#D82143')
    }

    if (status === 'finished') {
      setColors('default')
      setColorHex('#afafaf')
    }
  }, [])

  return (
    <Container
      color={colorHex}
      isDataTask={config?.length === 0 || config === undefined}
      dataSize={config?.length === 1 || config?.length === 2}
    >
      {optionVisualization === 'complete' ? (
        <Tooltip
          title={`${translate('TasksOptionsKanbanCards.viewTooltip')} ${
            taskStatusData.internal_code
          }`}
        >
          <Link
            target="_blank"
            to={`/tarefas/visualizar/${taskStatusData._id}`}
          >
            {size === 0 || size === undefined ? (
              <div className="header">
                <Tag color={colors}>{taskStatusData.internal_code}</Tag>

                <p>{dateTask}</p>
              </div>
            ) : (
              <div className="header">
                {pattern?.includes('code') ? (
                  <Tag color={colors}>{taskStatusData.internal_code}</Tag>
                ) : (
                  ''
                )}

                {pattern?.includes('date') ? <p>{dateTask}</p> : ''}
              </div>
            )}

            <div className="content">
              {size === 0 || size === undefined ? (
                <>
                  <p>
                    <b>
                      {translate('TasksOptionsKanbanCards.responsibleText')}
                    </b>
                    &nbsp;
                    {responsibleTask?.type === 'collaborator' ? (
                      <>
                        {responsibleTask.data?.contact?.name !== undefined ? (
                          <Text>{responsibleTask.data?.contact?.name}</Text>
                        ) : (
                          <Text>
                            {translate(
                              'TasksOptionsKanbanCards.noTextResponsible',
                            )}
                          </Text>
                        )}
                      </>
                    ) : (
                      ''
                    )}
                    {taskStatusData.responsible?.type === 'team' ? (
                      <>
                        {responsibleTask.data?.title !== undefined ? (
                          <Text>{responsibleTask.data?.title}</Text>
                        ) : (
                          <Text>
                            {translate('TasksOptionsKanbanCards.noTextTeam')}
                          </Text>
                        )}
                      </>
                    ) : (
                      ''
                    )}
                  </p>

                  <p>
                    <b>{translate('TasksOptionsKanbanCards.clientText')}</b>
                    &nbsp;
                    {taskStatusData.customer?.contact?.name !== undefined ? (
                      <Text>{taskStatusData.customer?.contact?.name}</Text>
                    ) : (
                      <Text>
                        {translate('TasksOptionsKanbanCards.noTextCustomer')}
                      </Text>
                    )}
                  </p>

                  <p>
                    <b>{translate('TasksOptionsKanbanCards.taskTypeText')}</b>
                    &nbsp;
                    <Text>{taskStatusData.taskType?.title}</Text>
                  </p>
                </>
              ) : (
                <div className="content">
                  {pattern?.includes('responsible') ? (
                    <>
                      <p>
                        <b>
                          {translate('TasksOptionsKanbanCards.responsibleText')}
                        </b>
                        &nbsp;
                        {responsibleTask?.type === 'collaborator' ? (
                          <>
                            {responsibleTask.data?.contact?.name !==
                            undefined ? (
                              <Text>{responsibleTask.data?.contact?.name}</Text>
                            ) : (
                              <Text>
                                {translate(
                                  'TasksOptionsKanbanCards.noTextResponsible',
                                )}
                              </Text>
                            )}
                          </>
                        ) : (
                          ''
                        )}
                        {taskStatusData.responsible?.type === 'team' ? (
                          <>
                            {responsibleTask.data?.title !== undefined ? (
                              <Text>{responsibleTask.data?.title}</Text>
                            ) : (
                              <Text>
                                {translate(
                                  'TasksOptionsKanbanCards.noTextTeam',
                                )}
                              </Text>
                            )}
                          </>
                        ) : (
                          ''
                        )}
                      </p>
                    </>
                  ) : (
                    ''
                  )}

                  {pattern?.includes('client') ? (
                    <p>
                      <b>{translate('TasksOptionsKanbanCards.clientText')}</b>
                      &nbsp;
                      {taskStatusData.customer?.contact?.name !== undefined ? (
                        <Text>{taskStatusData.customer?.contact?.name}</Text>
                      ) : (
                        <Text>
                          {translate('TasksOptionsKanbanCards.noTextCustomer')}
                        </Text>
                      )}
                    </p>
                  ) : (
                    ''
                  )}

                  {pattern?.includes('type_task') ? (
                    <p>
                      <b>{translate('TasksOptionsKanbanCards.taskTypeText')}</b>
                      &nbsp;
                      <Text>{taskStatusData.taskType?.title}</Text>
                    </p>
                  ) : (
                    ''
                  )}
                </div>
              )}
            </div>

            <div className="content">
              {config?.includes('duration') ? (
                <p>
                  <b>{translate('TasksOptionsKanbanCards.durationText')}</b>{' '}
                  {taskStatusData.duration_time}
                </p>
              ) : (
                ''
              )}

              {config?.includes('checkin') ? (
                <p>
                  <b>{translate('TasksOptionsKanbanCards.checkinText')}</b>{' '}
                  <Tag color="magenta">{taskStatusData.checkin_type}</Tag>
                </p>
              ) : (
                ''
              )}

              {config?.includes('priority') ? (
                <p>
                  <b>{translate('TasksOptionsKanbanCards.priorityText')}</b>
                  &nbsp;
                  {taskStatusData.priority === 'low' ? (
                    <Tag color="green">
                      {translate('TasksOptionsKanbanCards.priorityLow')}
                    </Tag>
                  ) : (
                    ''
                  )}
                  {taskStatusData.priority === 'medium' ? (
                    <Tag color="yellow">
                      {translate('TasksOptionsKanbanCards.priorityMedium')}
                    </Tag>
                  ) : (
                    ''
                  )}
                  {taskStatusData.priority === 'high' ? (
                    <Tag color="red">
                      {translate('TasksOptionsKanbanCards.priorityHigh')}
                    </Tag>
                  ) : (
                    ''
                  )}
                </p>
              ) : (
                ''
              )}

              {config?.includes('status') ? (
                <p>
                  <b>{translate('TasksOptionsKanbanCards.statusText')}</b>{' '}
                  {taskStatusData.is_enabled ? (
                    <Tag color="green">
                      {translate('TasksOptionsKanbanCards.statusActive')}
                    </Tag>
                  ) : (
                    <Tag>
                      {translate('TasksOptionsKanbanCards.statusInactive')}
                    </Tag>
                  )}
                </p>
              ) : (
                ''
              )}

              {config?.includes('external_code') ? (
                <p>
                  <b>{translate('TasksOptionsKanbanCards.externalCode')}</b>{' '}
                  <Tag color="geekblue">{taskStatusData.external_code}</Tag>
                </p>
              ) : (
                ''
              )}
            </div>

            {config?.includes('address') ? (
              <div className="address">
                <Collapse ghost>
                  <Collapse.Panel
                    header={translate('TasksOptionsKanbanCards.addressPanel')}
                    key="1"
                  >
                    <p>
                      <b>{translate('TasksOptionsKanbanCards.zipcodeText')}</b>{' '}
                      {taskStatusData.address.zip_code}
                    </p>
                    <p>
                      <b>
                        {translate('TasksOptionsKanbanCards.neighborhoodText')}
                      </b>
                      {taskStatusData.address.neighborhood}
                    </p>
                    <p>
                      <b>{translate('TasksOptionsKanbanCards.streetText')}</b>
                      {taskStatusData.address.street},{' '}
                      {taskStatusData.address.number}
                    </p>
                    <p>
                      <b>{translate('TasksOptionsKanbanCards.cityText')}</b>
                      {taskStatusData.address.city} <b>UF: </b>
                      {taskStatusData.address.state}
                    </p>
                  </Collapse.Panel>
                </Collapse>
              </div>
            ) : (
              ''
            )}
          </Link>
        </Tooltip>
      ) : (
        <Tooltip
          title={`${translate('TasksOptionsKanbanCards.viewTooltip')} ${
            taskStatusData.internal_code
          }`}
        >
          <Link to={`/tarefas/visualizar/${taskStatusData._id}`}>
            {size === 0 || size === undefined ? (
              <div className="header">
                <Tag color={colors}>{taskStatusData.internal_code}</Tag>

                <p>{dateTask}</p>
              </div>
            ) : (
              <div className="header">
                {pattern?.includes('code') ? (
                  <Tag color={colors}>{taskStatusData.internal_code}</Tag>
                ) : (
                  ''
                )}

                {pattern?.includes('date') ? <p>{dateTask}</p> : ''}
              </div>
            )}
          </Link>
        </Tooltip>
      )}
    </Container>
  )
}

export default CardsKanban
