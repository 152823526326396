export default {
  pt: {
    AuthContext: {
      errorMessage: 'Erro ao realizar autenticação.',
    },
  },
  en: {
    AuthContext: {
      errorMessage: 'Error performing authentication.',
    },
  },
  es: {
    AuthContext: {
      errorMessage: 'Error al realizar la autenticación.',
    },
  },
}
