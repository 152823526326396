/* eslint-disable @typescript-eslint/no-explicit-any */
import { LogoutOutlined, SaveOutlined } from '@ant-design/icons'
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  Typography,
} from 'antd'
import dayjs from 'dayjs'
import React, { useState } from 'react'
import { useTeam } from '~/hooks/Teams/useTeams'
import { ContainerButton } from './styles'
import MoneyInput from '~/components/DataManipulation/Misc/MoneyInput'
import { translate } from '~/utils/locale'

// import { Container } from './styles';

interface FinancialTeam {
  payment_day: string
  payment_conditions: string
  payment_method: string
  number_bank: string
  name_bank: string
  personal_bank: string
  agency_bank: string
  account_bank: string
  type_account_bank: string
  amount_to_receive: number
  key_pix: string
  amount_hours_receive?: number
  amount_hours_additional_receive?: number
  number_km_receive?: number
  number_km_additional_receive?: number
}

const UpdateFinancialTeam: React.FC = () => {
  const { setIsEdit, updateFinancialTeams, refetch, team } = useTeam()

  const [amountReceive, setAmountReceive] = useState(team?.amount_hours_receive)
  const [amountHours, setAmountHours] = useState(team?.amount_hours_receive)
  const [amountHoursAdditional, setAmountHoursAdditional] = useState(
    team?.amount_hours_additional_receive,
  )
  const [numberKm, setNumberKm] = useState(team?.number_km_receive)
  const [numberKmAdditional, setNumberKmAdditional] = useState(
    team?.number_km_additional_receive,
  )

  const initialData = {
    payment_day: team?.payment_day
      ? dayjs(team?.payment_day)
      : dayjs(new Date()),
    payment_conditions: team?.payment_conditions,
    payment_method: team?.payment_method,
    number_bank: team?.number_bank,
    name_bank: team?.name_bank,
    personal_bank: team?.personal_bank,
    agency_bank: team?.agency_bank,
    account_bank: team?.account_bank,
    type_account_bank: team?.type_account_bank,
    amount_to_receive: team?.amount_to_receive,
    amount_hours_receive: team?.amount_hours_receive,
    amount_hours_additional_receive: team?.amount_hours_additional_receive,
    number_km_receive: team?.number_km_receive,
    number_km_additional_receive: team?.number_km_additional_receive,
    key_pix: team?.key_pix,
  }

  const handleAmountReceive = (valueInCents: number | undefined) => {
    setAmountReceive(valueInCents)
  }

  const handleHourValue = (valueInCents: number | undefined) => {
    setAmountHoursAdditional(valueInCents)
  }

  const handleKmValue = (valueInCents: number | undefined) => {
    setNumberKmAdditional(valueInCents)
  }

  async function onEditFinancialTeam(valueForm: FinancialTeam) {
    try {
      await updateFinancialTeams(valueForm)

      refetch()

      setIsEdit(false)
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Card
      bordered={false}
      title={translate('TeamsComponentTabFinancialUpdate.cardTitle')}
      extra={
        <Space>
          <ContainerButton>
            <Button
              type="primary"
              icon={<SaveOutlined />}
              htmlType="submit"
              form="form_update_financial_team"
              id="btn_update_financial_teams"
            >
              {translate('TeamsComponentTabFinancialUpdate.buttonSave')}
            </Button>
          </ContainerButton>
          <Button
            type="default"
            icon={<LogoutOutlined />}
            onClick={() => setIsEdit(false)}
          >
            {translate('TeamsComponentTabFinancialUpdate.buttonClose')}
          </Button>
        </Space>
      }
      style={{
        display: 'inline-block',
        width: '100%',
      }}
    >
      <Form
        layout="vertical"
        onFinish={onEditFinancialTeam}
        id="form_update_financial_team"
        initialValues={initialData}
      >
        <Typography.Title level={5}>
          {translate('TeamsComponentTabFinancialUpdate.cardTitleTop')}
        </Typography.Title>

        <Row gutter={12}>
          <Col span={12}>
            <Form.Item
              label={translate('TeamsComponentTabFinancialUpdate.valueLabel')}
              name="amount_to_receive"
            >
              <MoneyInput
                value={amountReceive}
                onChange={handleAmountReceive}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={translate('TeamsComponentTabFinancialUpdate.dayLabel')}
              name="payment_day"
            >
              <DatePicker
                placeholder={translate(
                  'TeamsComponentTabFinancialUpdate.dayPlaceholder',
                )}
                format="DD/MM/YYYY"
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={14}>
          <Col span={12}>
            <Form.Item
              label={translate('TeamsComponentTabFinancialUpdate.hoursLabel')}
              name="amount_hours_receive"
            >
              <Input
                value={amountHours}
                onChange={(e) => setAmountHours(parseInt(e.target.value))}
                style={{ width: '100%' }}
                maxLength={2}
                placeholder={translate(
                  'TeamsComponentTabFinancialUpdate.hoursPlaceholder',
                )}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={translate(
                'TeamsComponentTabFinancialUpdate.valueHoursLabel',
              )}
              name="amount_hours_additional_receive"
            >
              <MoneyInput
                value={amountHoursAdditional}
                onChange={handleHourValue}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={14}>
          <Col span={12}>
            <Form.Item
              label={translate('TeamsComponentTabFinancialUpdate.kmLabel')}
              name="number_km_receive"
            >
              <Input
                placeholder={translate(
                  'TeamsComponentTabFinancialUpdate.kmPlaceholder',
                )}
                value={numberKm}
                onChange={(e) => setNumberKm(parseInt(e.target.value))}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={translate('TeamsComponentTabFinancialUpdate.kmValueLabel')}
              name="number_km_additional_receive"
            >
              <MoneyInput value={numberKmAdditional} onChange={handleKmValue} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={12}>
          <Col span={12}>
            <Form.Item
              label={translate(
                'TeamsComponentTabFinancialUpdate.conditionsLabel',
              )}
              name="payment_conditions"
            >
              <Select
                style={{ width: '100%' }}
                placeholder={translate(
                  'TeamsComponentTabFinancialUpdate.conditionsPlaceholder',
                )}
              >
                <Select.Option value="view">
                  {' '}
                  {translate('TeamsComponentTabFinancial.viewText')}
                </Select.Option>
                <Select.Option value="parcel">
                  {translate('TeamsComponentTabFinancial.parcelText')}
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={translate('TeamsComponentTabFinancialUpdate.methodLabel')}
              name="payment_method"
            >
              <Select
                style={{ width: '100%' }}
                placeholder={translate(
                  'TeamsComponentTabFinancialUpdate.methodPlaceholder',
                )}
              >
                <Select.Option value="deposit">
                  {translate('TeamsComponentTabFinancial.depositText')}
                </Select.Option>
                <Select.Option value="ticket">
                  {translate('TeamsComponentTabFinancial.ticketText')}
                </Select.Option>
                <Select.Option value="pix">PIX</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Typography.Title level={5}>
          {translate('TeamsComponentTabFinancialUpdate.cardTitleBottom')}
        </Typography.Title>
        <Row gutter={12}>
          <Col span={8}>
            <Form.Item
              label={translate(
                'TeamsComponentTabFinancialUpdate.numberBankLabel',
              )}
              name="number_bank"
            >
              <InputNumber
                placeholder={translate(
                  'TeamsComponentTabFinancialUpdate.numberBankPlaceholder',
                )}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label={translate(
                'TeamsComponentTabFinancialUpdate.nameBankLabel',
              )}
              name="name_bank"
            >
              <Input
                placeholder={translate(
                  'TeamsComponentTabFinancialUpdate.nameBankPlaceholder',
                )}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label={translate(
                'TeamsComponentTabFinancialUpdate.typePersonAccountLabel',
              )}
              name="personal_bank"
            >
              <Select
                style={{ width: '100%' }}
                placeholder={translate(
                  'TeamsComponentTabFinancialUpdate.typePersonAccountPlaceholder',
                )}
              >
                <Select.Option value="physics">
                  {translate('TeamsComponentTabFinancial.physicalText')}
                </Select.Option>
                <Select.Option value="juridicial">
                  {translate('TeamsComponentTabFinancial.juridicText')}
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={12}>
          <Col span={8}>
            <Form.Item
              label={translate('TeamsComponentTabFinancialUpdate.agencyLabel')}
              name="agency_bank"
            >
              <InputNumber
                placeholder={translate(
                  'TeamsComponentTabFinancialUpdate.agencyPlaceholder',
                )}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label={translate('TeamsComponentTabFinancialUpdate.accountLabel')}
              name="account_bank"
            >
              <InputNumber
                placeholder={translate(
                  'TeamsComponentTabFinancialUpdate.accountPlaceholder',
                )}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label={translate(
                'TeamsComponentTabFinancialUpdate.typeAccountLabel',
              )}
              name="type_account_bank"
            >
              <Select
                style={{ width: '100%' }}
                placeholder={translate(
                  'TeamsComponentTabFinancialUpdate.typeAccountPlaceholder',
                )}
              >
                <Select.Option value="current">
                  {' '}
                  {translate('TeamsComponentTabFinancial.currentText')}
                </Select.Option>
                <Select.Option value="savings">
                  {translate('TeamsComponentTabFinancial.savingsText')}
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={8}>
            <Form.Item
              label={translate('TeamsComponentTabFinancialUpdate.pixLabel')}
              name="key_pix"
            >
              <Input
                placeholder={translate(
                  'TeamsComponentTabFinancialUpdate.pixLabelPlaceholder',
                )}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  )
}

export default UpdateFinancialTeam
