export default {
  pt: {
    MiscMoreOptions: {
      uploadError: 'Erro ao realizar a importação de dados.',
      uploadSuccess: 'Importação realizada com sucesso.',
      importButton: 'Importar Colaboradores',
      cancelText: 'Fechar',
      modelTitle: 'Planilha Modelo',
      modelDescription: 'Arquivo modelo para a importação.',
      downloadButton: 'Download da Planilha',
      uploadButton: 'Upload da Planilha',
    },
  },
  en: {
    MiscMoreOptions: {
      uploadError: 'Error when importing data.',
      uploadSuccess: 'Import successful.',
      importButton: 'Import Contributors',
      cancelText: 'Close',
      modelTitle: 'Template Spreadsheet',
      modelDescription: 'Template file for import.',
      downloadButton: 'Spreadsheet Download',
      uploadButton: 'Spreadsheet upload',
    },
  },
  es: {
    MiscMoreOptions: {
      uploadError: 'Error al importar datos.',
      uploadSuccess: 'Importación exitosa.',
      importButton: 'Importar Colaboradores',
      cancelText: 'Cerrar',
      modelTitle: 'Plantilla de hoja de cálculo',
      modelDescription: 'Archivo de plantilla para importar.',
      downloadButton: 'Descargar hoja de cálculo',
      uploadButton: 'Carga de hoja de cálculo',
    },
  },
}
