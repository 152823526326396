export default {
  pt: {
    useListContributors: {
      getErrorMessage: 'Erro ao visualizar colaboradores.',
      deleteSuccessMessage: 'Colaborador removido com sucesso!',
      deleteErrorMessage: 'Erro ao excluir colaborador.',
    },
  },
  en: {
    useListContributors: {
      getErrorMessage: 'Error viewing collaborators.',
      deleteSuccessMessage: 'Contributor successfully removed!',
      deleteErrorMessage: 'Error deleting contributor.',
    },
  },
  es: {
    useListContributors: {
      getErrorMessage: 'Error al ver colaboradores.',
      deleteSuccessMessage: '¡Contribuidor eliminado con éxito!',
      deleteErrorMessage: 'Error al eliminar colaborador.',
    },
  },
}
