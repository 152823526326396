import { ExclamationCircleTwoTone } from '@ant-design/icons'
import { Tooltip, notification } from 'antd'
import React from 'react'
import { useServiceStatus } from '~/hooks/ServiceStatus/useServiceStatus'
import { translate } from '~/utils/locale'

// import { Container } from './styles';

const ServiceStatus: React.FC = () => {
  const { serviceStatus } = useServiceStatus()
  const [api, contextHolder] = notification.useNotification()

  const handleClick = () => {
    if (serviceStatus?.executionTask === true) {
      api.open({
        message: translate('ServiceStatus.executionCardMessage'),
        type: 'warning',
        description: translate('ServiceStatus.executionCardDescription'),
        duration: 0,
      })
    }
    if (serviceStatus?.newTask === true) {
      api.open({
        message: translate('ServiceStatus.createCardMessage'),
        type: 'warning',
        description: translate('ServiceStatus.createCardDescription'),
        duration: 0,
      })
    }
  }

  return (
    <Tooltip title={translate('ServiceStatus.errorTooltip')}>
      {contextHolder}
      <ExclamationCircleTwoTone
        onClick={handleClick}
        twoToneColor="#FAAD14"
        style={{
          fontSize: '1.5rem',
        }}
      />
    </Tooltip>
  )
}

export default ServiceStatus
