import React from 'react'
import { Helmet } from 'react-helmet'
import HelpDeskTabs from '~/components/HelpDesk/Tabs'
import { ListContributorsProvider } from '~/hooks/Contributors/useListContributors'
import { TicketProvider } from '~/hooks/HelpDesk/useTickets'
import { TicketTypeProvider } from '~/hooks/HelpDesk/useTicketType'
import { TaskTypeProvider } from '~/hooks/Tasks/TaskType/useTaskType'
import { TaskListProvider } from '~/hooks/Tasks/useListTasks'
import { TeamsProvider } from '~/hooks/Teams/useListTeams'
import { translate } from '~/utils/locale'

// import { Container } from './styles';

const ShowTicket: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>{translate('HelpDeskShowPage.helmetTitle')}</title>
      </Helmet>
      <TicketProvider>
        <ListContributorsProvider>
          <TeamsProvider>
            <TicketTypeProvider>
              <TaskTypeProvider>
                <TaskListProvider>
                  <HelpDeskTabs />
                </TaskListProvider>
              </TaskTypeProvider>
            </TicketTypeProvider>
          </TeamsProvider>
        </ListContributorsProvider>
      </TicketProvider>
    </>
  )
}

export default ShowTicket
