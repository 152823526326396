import { LoadingOutlined } from '@ant-design/icons'
import { Card, Space } from 'antd'
import Text from 'antd/lib/typography/Text'
import React from 'react'
import { useCategory } from '~/hooks/Equipments/Category/CategoryHooks'
import { translate } from '~/utils/locale'

import EquipmentsCategory from '..'
import CreateEquipmentsCategory from '../Create'

// import { Container } from './styles';

const CategoryBoard: React.FC = () => {
  const { isLoading, isFetching, categoryTotal } = useCategory()

  return (
    <Card
      title={
        <Space size="large">
          <b>{translate('EquipmentsCategoryBoard.categoryTitle')}</b>
          {isLoading ? (
            <LoadingOutlined spin />
          ) : (
            <Text
              type="secondary"
              style={{ fontWeight: 'normal', fontSize: 14 }}
            >
              {categoryTotal !== undefined ? (
                <>
                  {categoryTotal === 1
                    ? `${translate(
                        'EquipmentsCategoryBoard.totalText',
                      )} ${categoryTotal} ${translate(
                        'EquipmentsCategoryBoard.categoryText',
                      )}`
                    : `${translate(
                        'EquipmentsCategoryBoard.totalText',
                      )} ${categoryTotal} ${translate(
                        'EquipmentsCategoryBoard.categoriesText',
                      )}`}
                </>
              ) : (
                ''
              )}
            </Text>
          )}
          {!isLoading && isFetching && <LoadingOutlined spin />}
        </Space>
      }
      extra={<CreateEquipmentsCategory />}
      bordered={false}
      style={{ width: '100%', padding: 30 }}
    >
      <EquipmentsCategory />
    </Card>
  )
}

export default CategoryBoard
