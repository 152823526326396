import { Space, Tabs } from 'antd'
import { PageHeader } from '@ant-design/pro-components'
import Text from 'antd/lib/typography/Text'
import React from 'react'
import AttachmentsTab from './AttachmentsTab'
import EquipmentsDetails from './DetailsTab'
import MovementsTab from './MovementsTab'
import SpecificationTab from './SpecificationTab'

import UpdateDetails from '../Update/Details'
import { useEquipments } from '~/hooks/Equipments/EquipmentsHooks'
import { translate } from '~/utils/locale'

// import { Container } from './styles';

const EquipmentsTabs: React.FC = () => {
  const { equipments } = useEquipments()
  const { TabPane } = Tabs
  const { isEdit } = useEquipments()

  return (
    <>
      <PageHeader
        className="site-page-header"
        onBack={() => window.history.back()}
        title={
          !isEdit
            ? translate('EquipmentsTabs.viewPageheader')
            : translate('EquipmentsTabs.editPageheader')
        }
        subTitle={equipments?.name}
      />
      <Tabs
        defaultActiveKey="detalhes"
        tabBarExtraContent={
          <Space size="large">
            <Space size="small">
              <Text strong>{translate('EquipmentsTabs.statusText')}</Text>
              {equipments?.is_enabled ? (
                <Text>{translate('EquipmentsTabs.activeText')}</Text>
              ) : (
                <Text>{translate('EquipmentsTabs.inactiveText')}</Text>
              )}
            </Space>
          </Space>
        }
        style={{ paddingLeft: 20, paddingRight: 20 }}
      >
        <TabPane tab={translate('EquipmentsTabs.detailsTab')} key="detalhes">
          {!isEdit ? <EquipmentsDetails /> : <UpdateDetails />}
        </TabPane>
        <TabPane
          tab={translate('EquipmentsTabs.specificationsTab')}
          key="especificacoes"
        >
          <SpecificationTab />
        </TabPane>
        <TabPane tab={translate('EquipmentsTabs.docsTab')} key="anexos">
          <AttachmentsTab />
        </TabPane>
        <TabPane
          tab={translate('EquipmentsTabs.movementsTab')}
          key="movimentações"
        >
          <MovementsTab />
        </TabPane>
      </Tabs>
    </>
  )
}

export default EquipmentsTabs
