import React from 'react'
import { Helmet } from 'react-helmet'

import CreateTicketCustomer from '~/components/HelpDesk/Create/CreateTicketCustomer'

import { translate } from '~/utils/locale'
import { AnimationContainer, Background, Container, Content } from './styles'

const NewTicketClient: React.FC = () => {
  return (
    <Container>
      <Helmet>
        <title>{translate('HelpDeskCreatePage.helmetTitle')}</title>
      </Helmet>

      <Content>
        <AnimationContainer>
          <CreateTicketCustomer />
        </AnimationContainer>
      </Content>
      <Background />
    </Container>
  )
}

export default NewTicketClient
