import { Form, Flex, Radio, Space } from 'antd'
import React from 'react'
import { IQuestion } from '~/hooks/Tasks/Forms/useTaskFormWithAnswers'

interface QuestionProps {
  data: IQuestion
  isEditing: boolean
}

const QuestionMultipleChoice: React.FC<QuestionProps> = ({
  data,
  isEditing,
}) => {
  const defaultValue = isValidAnswerValue(data.answer?.value)
  return (
    <Flex vertical>
      {data.description && <h4>{data.description}</h4>}

      {isEditing ? (
        <Form.Item name={'value'} style={{ width: '100%' }}>
          <Radio.Group value={defaultValue}>
            <Space direction="vertical">
              {data.options &&
                data.options.length > 0 &&
                data.options.map((option) => (
                  <Radio key={option} value={option}>
                    {option}
                  </Radio>
                ))}
            </Space>
          </Radio.Group>
        </Form.Item>
      ) : (
        <Radio.Group disabled defaultValue={defaultValue}>
          <Space direction="vertical">
            {data.options &&
              data.options.length > 0 &&
              data.options.map((option) => (
                <Radio key={option} value={option} disabled>
                  {option}
                </Radio>
              ))}
          </Space>
        </Radio.Group>
      )}
    </Flex>
  )
}

const isValidAnswerValue = (
  value?: string | Array<string> | number | boolean | null,
): string | undefined => {
  if (typeof value === 'string' || typeof value === 'number') {
    // Se o valor for uma string ou um número, retorna o próprio valor como string
    return String(value)
  } else if (
    Array.isArray(value) &&
    value.every((item) => typeof item === 'string')
  ) {
    // Se o valor for um array contendo somente strings, retorna o array como uma única string
    return value.join(',') // Você pode ajustar como desejar a conversão de array para string
  } else {
    // Para qualquer outro tipo de valor (boolean, null), retorna undefined
    return undefined
  }
}

export default QuestionMultipleChoice
