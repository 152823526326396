import { EditOutlined } from '@ant-design/icons'
import { Card, Col, Row, Typography, Divider, Collapse, Button } from 'antd'
import React from 'react'
import { MapContainer, Marker, TileLayer } from 'react-leaflet'

import { useClients } from '~/hooks/Clients/useClients'
import { translate } from '~/utils/locale'

import { Container, Wrapper, Address, Map } from './styles'

const { Panel } = Collapse

const ChargeTab: React.FC = () => {
  const { isLoading, clients, setIsEdit } = useClients()
  const { Text } = Typography

  if (isLoading || !clients) {
    return <p />
  }

  const chargeAddress = clients.address.filter((charge) => {
    return charge.type === 'billing'
  })

  return (
    <Container>
      <Card
        bordered={false}
        title={translate('ClientsChargeTab.cardTitle')}
        style={{
          display: 'inline-block',
          width: '100%',
        }}
        extra={
          <Button
            id="btn-edit-charge-client"
            type="primary"
            icon={<EditOutlined />}
            onClick={() => setIsEdit(true)}
          >
            {translate('ClientsDetailsTab.editButton')}
          </Button>
        }
      >
        <Wrapper>
          <Address>
            <Row gutter={16}>
              <Col span={8}>
                <div className="text">
                  <Text strong>{translate('ClientsChargeTab.icmsText')}</Text>
                  {clients.icms_taxpayer === null ? (
                    <Text>{translate('ClientsChargeTab.icmsNull')}</Text>
                  ) : (
                    <Text>{translate('ClientsChargeTab.icmsNotNull')}</Text>
                  )}
                </div>
              </Col>
              <Col span={8}>
                <div className="text">
                  <Text strong>
                    {translate('ClientsChargeTab.billingEmailText')}
                  </Text>
                  {clients.billing_email === null ? (
                    <Text>
                      {translate('ClientsChargeTab.billingEmailNull')}
                    </Text>
                  ) : (
                    <Text>{clients.billing_email}</Text>
                  )}
                </div>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={8}>
                <div className="text">
                  <Text strong>
                    {translate('ClientsChargeTab.municipalRegistrationText')}
                  </Text>
                  {clients.municipal_registration === null ? (
                    <Text>
                      {translate('ClientsChargeTab.municipalRegistrationNull')}
                    </Text>
                  ) : (
                    <Text>{clients.municipal_registration}</Text>
                  )}
                </div>
              </Col>
              <Col span={14}>
                <div className="text">
                  <Text strong>
                    {translate('ClientsChargeTab.stateRegistrationText')}
                  </Text>
                  {clients.state_registration === null ? (
                    <Text>
                      {translate('ClientsChargeTab.stateRegistrationNull')}
                    </Text>
                  ) : (
                    <Text>{clients.state_registration}</Text>
                  )}
                </div>
              </Col>
            </Row>

            <Divider />

            <h3>{translate('ClientsChargeTab.chargeAddressesTitle')}</h3>
            {chargeAddress.length !== 0 ? (
              <Row>
                <div style={{ flex: 1 }}>
                  {chargeAddress.map((address) => (
                    <Collapse key={address.id}>
                      <Panel
                        header={translate('ClientsChargeTab.panelHeader')}
                        key={address.id}
                      >
                        <Wrapper>
                          <Address>
                            <Row gutter={16}>
                              <Col span={8}>
                                <div className="text">
                                  <Text strong>
                                    {translate('ClientsChargeTab.zipcodeLabel')}
                                  </Text>
                                  <Text>{address.address.zip_code}</Text>
                                </div>
                              </Col>
                              <Col span={8}>
                                <div className="text">
                                  <Text strong>
                                    {translate('ClientsChargeTab.stateLabel')}
                                  </Text>
                                  <Text>{address.address.state}</Text>
                                </div>
                              </Col>
                              <Col span={8}>
                                <div className="text">
                                  <Text strong>
                                    {translate('ClientsChargeTab.cityLabel')}
                                  </Text>
                                  <Text>{address.address.city}</Text>
                                </div>
                              </Col>
                            </Row>
                            <Row gutter={16}>
                              <Col span={8}>
                                <div className="text">
                                  <Text strong>
                                    {translate(
                                      'ClientsChargeTab.neighborhoodLabel',
                                    )}
                                  </Text>
                                  <Text>{address.address.neighborhood}</Text>
                                </div>
                              </Col>
                              <Col span={8}>
                                <div className="text">
                                  <Text strong>
                                    {translate('ClientsChargeTab.streetLabel')}
                                  </Text>
                                  <Text>{address.address.street}</Text>
                                </div>
                              </Col>
                              <Col span={8}>
                                <div className="text">
                                  <Text strong>
                                    {translate('ClientsChargeTab.numberLabel')}
                                  </Text>
                                  <Text>{address.address.number}</Text>
                                </div>
                              </Col>
                            </Row>
                            <Row gutter={16}>
                              <Col span={8}>
                                <div className="text">
                                  <Text strong>
                                    {translate(
                                      'ClientsChargeTab.complementLabel',
                                    )}
                                  </Text>
                                  <Text>{address.address.complement}</Text>
                                </div>
                              </Col>
                              <Col span={8}>
                                <div className="text">
                                  <Text strong>
                                    {translate('ClientsChargeTab.typeLabel')}
                                  </Text>
                                  {address.type === 'billing' ? (
                                    <Text>
                                      {translate(
                                        'ClientsChargeTab.billingText',
                                      )}
                                    </Text>
                                  ) : (
                                    ''
                                  )}
                                </div>
                              </Col>
                            </Row>
                            <Row gutter={16}>
                              <Col span={16}>
                                <div className="text">
                                  <Text strong>
                                    {translate(
                                      'ClientsChargeTab.observationLabel',
                                    )}
                                  </Text>
                                  <Text>{address.notes}</Text>
                                </div>
                              </Col>
                            </Row>
                          </Address>
                          <Map>
                            <MapContainer
                              center={[
                                address.address.location.x,
                                address.address.location.y,
                              ]}
                              zoom={13}
                              scrollWheelZoom={false}
                              style={{ height: '250px', width: '250px' }}
                            >
                              <TileLayer
                                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                              />

                              <Marker
                                position={[
                                  address.address.location.x,
                                  address.address.location.y,
                                ]}
                              />
                            </MapContainer>
                          </Map>
                        </Wrapper>
                      </Panel>
                    </Collapse>
                  ))}
                </div>
              </Row>
            ) : (
              <Text>{translate('ClientsChargeTab.chargeAddressesNull')}</Text>
            )}
          </Address>
        </Wrapper>
      </Card>
    </Container>
  )
}

export default ChargeTab
