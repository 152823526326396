export default {
  pt: {
    ShowClients: {
      helmetTitle: 'Visualizar Cliente',
    },
  },
  en: {
    ShowClients: {
      helmetTitle: 'View Customer',
    },
  },
  es: {
    ShowClients: {
      helmetTitle: 'Ver Cliente',
    },
  },
}
