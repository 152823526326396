export default {
  pt: {
    TasksCreateModalTeams: {
      addButton: 'Adicionar Equipe',
      changeButton: 'Alterar Equipe',
      modalTitle: 'Adicionar Equipe',
      rangeText: 'Equipes em um raio de',
      teamHeader: 'Equipes',
      addPopconfirm: 'Deseja mesmo adicionar e equipe?',
      addTooltip: 'Adicionar equipe',
    },
  },
  en: {
    TasksCreateModalTeams: {
      addButton: 'Add Team',
      changeButton: 'Change Team',
      modalTitle: 'Add Team',
      rangeText: 'Teams within a radius of',
      teamHeader: 'Teams',
      addPopconfirm: 'Do you really want to add and team?',
      addTooltip: 'Add Team',
    },
  },
  es: {
    TasksCreateModalTeams: {
      addButton: 'Agregar Equipo',
      changeButton: 'Cambiar Equipo',
      modalTitle: 'Agregar Equipo',
      rangeText: 'Equipos dentro de un radio de',
      teamHeader: 'Equipos',
      addPopconfirm: '¿De verdad quieres añadir un equipo?',
      addTooltip: 'Agregar equipo',
    },
  },
}
