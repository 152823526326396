import React from 'react'
import BadgeBoard from '~/components/DataManipulation/Badge'
import { BadgeProvider } from '~/hooks/Badge/useBadge'

// import { Container } from './styles';

const Badge: React.FC = () => {
  return (
    <BadgeProvider>
      <BadgeBoard />
    </BadgeProvider>
  )
}

export default Badge
