export default {
  pt: {
    TasksCreateDetails: {
      externalCodeButton: 'Adicionar código externo',
      externalCodeNotificationTitle: 'Código externo',
      externalCodeNotificationDescription:
        'Deseja adicionar um código externo a tarefa?',
      clientLabel: 'Cliente',
      clientRule: 'Por favor informe o cliente',
      dateLabel: 'Data e Hora do Agendamento',
      dateHelpTooltip:
        'Data e hora para os quais a tarefa foi agendada. Se não inciada até esse horário será considerada atrasada.',
      dateRule: 'Por favor informe a data.',
      taskTypeLabel: 'Tipo de Tarefa',
      taskTypeRule: 'Por favor informe o tipo de tarefa.',
      scaleLabel: 'Escala',
      scaleRule: 'Por favor informe uma escala.',
      externalCodeLabel: 'Código Externo',
      externalCodeHelp:
        'Campo não obrigatório disponível para inclusão de um código que faça vinculo entre a respectiva tarefa no Gstor e a tarefa em outros sistemas.',
      externalCodePlaceholder: 'Informe o código externo',
      durationLabel: 'Duração',
      durationHelpTooltip:
        'Tempo estimado para execução completa da tarefa, ou seja, deslocamento + execução da tarefa.',
      priorityLabel: 'Prioridade',
      priorityPlaceholder: 'Selecione a prioridade',
      priorityHigh: 'Alta',
      priorityMedium: 'Média',
      priorityLow: 'Baixa',
      descriptionLabel: 'Descrição',
      descriptionRule: 'Por favor informe a descrição',
      descriptionPlaceholder: 'Informe a descrição',
      noteLabel: 'Observação',
      notePlaceholder: 'Informe a anotação',
      noteAddTooltip: 'Personalizar campo',
      noteSaveTooltip: 'Salvar',
      value: 'Valor R$ da Tarefa',
      valuePlaceholder: 'Por favor, informe o valor da tarefa',
    },
  },
  en: {
    TasksCreateDetails: {
      externalCodeButton: 'Add external code',
      externalCodeNotificationTitle: 'External code',
      externalCodeNotificationDescription:
        'Do you want to add external code to the task?',
      clientLabel: 'Customer',
      clientRule: 'Please inform the customer',
      dateLabel: 'Date and time',
      dateHelpTooltip:
        'Date and time for which the task was scheduled. If not started by this time, it will be considered late.',
      dateRule: 'Please inform the date.',
      taskTypeLabel: 'Task Type',
      taskTypeRule: 'Please inform the type of task.',
      scaleLabel: 'Scale',
      scaleRule: 'Please enter a scale.',
      externalCodeLabel: 'External Code',
      externalCodeHelp:
        'Non-mandatory field available to include a code that links the respective task in Gstor and the task in other systems.',
      externalCodePlaceholder: 'Enter the external code',
      durationLabel: 'Duration',
      durationHelpTooltip:
        'Estimated time for complete execution of the task, that is, displacement + execution of the task.',
      priorityLabel: 'Priority',
      priorityPlaceholder: 'Select priority',
      priorityHigh: 'High',
      priorityMedium: 'Medium',
      priorityLow: 'Low',
      descriptionLabel: 'Description',
      descriptionRule: 'Please provide description',
      descriptionPlaceholder: 'Enter the description',
      noteLabel: 'Observation',
      notePlaceholder: 'Please provide observation',
      noteAddTooltip: 'Customize field',
      noteSaveTooltip: 'Save',
      value: 'Value R$ of task',
      valuePlaceholder: 'Please enter the value of the task',
    },
  },
  es: {
    TasksCreateDetails: {
      externalCodeButton: 'Añadir código externo',
      externalCodeNotificationTitle: 'Código externo',
      externalCodeNotificationDescription:
        '¿Desea agregar código externo a la tarea?',
      clientLabel: 'Cliente',
      clientRule: 'Por favor, informe al cliente',
      dateLabel: 'Fecha y hora',
      dateHelpTooltip:
        'Fecha y hora para la que se programó la tarea. Si no se inicia a esta hora, se considerará tarde.',
      dateRule: 'Por favor informe la fecha.',
      taskTypeLabel: 'Tipo de tarea',
      taskTypeRule: 'Por favor informe el tipo de tarea.',
      scaleLabel: 'Escala',
      scaleRule: 'Por favor ingrese una escala.',
      externalCodeLabel: 'Código Externo',
      externalCodeHelp:
        'Campo no obligatorio disponible para incluir un código que vincule la tarea respectiva en Gstor y la tarea en otros sistemas.',
      externalCodePlaceholder: 'Introduce el código externo',
      durationLabel: 'Duración',
      durationHelpTooltip:
        'Tiempo estimado para la ejecución completa de la tarea, es decir, desplazamiento + ejecución de la tarea.',
      priorityLabel: 'Prioridad',
      priorityPlaceholder: 'Seleccionar prioridad',
      priorityHigh: 'Alta',
      priorityMedium: 'Media',
      priorityLow: 'Baja',
      descriptionLabel: 'Descripción',
      descriptionRule: 'Proporcione una descripción',
      descriptionPlaceholder: 'Introduce la descripción',
      noteLabel: 'Observación',
      notePlaceholder: 'Introduce la observación',
      noteAddTooltip: 'Personalizar campo',
      noteSaveTooltip: 'Salvar',
      value: 'Valor R$ de la Tarea',
      valuePlaceholder: 'Por favor ingrese el valor de la tarea',
    },
  },
}
