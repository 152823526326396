/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-underscore-dangle */
import React, { useState, useRef, useEffect } from 'react'

import FullCalendar, {
  EventClickArg,
  EventContentArg,
} from '@fullcalendar/react'
import ptBr from '@fullcalendar/core/locales/pt-br'
import esES from '@fullcalendar/core/locales/es'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'

import CalendarHeader from '~/components/DataManipulation/FCCalendar/CalendarHeader'
import CalendarHeaderSkeleton from '~/components/DataManipulation/FCCalendar/CalendarSkeletonHeader'

import { Container } from './styles'
import { useLocale } from '~/hooks/locale/useLocale'

interface iFCCalendar {
  events: any[] | undefined
  Modal: React.ReactElement
  handleClassNames: (
    arg: EventContentArg,
  ) =>
    | 'fc-event-color-pink'
    | 'fc-event-color-red'
    | 'fc-event-color-yellow'
    | 'fc-event-color-orange'
    | 'fc-event-color-cyan'
    | 'fc-event-color-green'
    | 'fc-event-color-blue'
    | 'fc-event-color-purple'
    | 'fc-event-color-geekblue'
    | 'fc-event-color-magenta'
    | 'fc-event-color-volcano'
    | 'fc-event-color-gold'
    | 'fc-event-color-lime'
  handleEventClick: (calendarEvent: EventClickArg) => void
}

const FCCalendar: React.FC<iFCCalendar> = ({
  events,
  Modal,
  handleClassNames,
  handleEventClick,
}) => {
  const [fullCalendarAPI, setFullCalendarAPI] = useState<any>()
  const { locale } = useLocale()

  const fullCalendarRef = useRef<FullCalendar>(null!)

  useEffect(() => {
    if (fullCalendarRef.current) {
      setFullCalendarAPI(fullCalendarRef.current.getApi())
    }
  }, [])

  return (
    <Container>
      {fullCalendarAPI ? (
        <CalendarHeader FullCalendarAPI={fullCalendarAPI} />
      ) : (
        <CalendarHeaderSkeleton />
      )}
      <FullCalendar
        ref={fullCalendarRef}
        locale={locale === 'pt-BR' ? ptBr : locale === 'es-ES' ? esES : ''}
        plugins={[dayGridPlugin, timeGridPlugin]}
        initialView="dayGridMonth"
        headerToolbar={{
          left: '',
          center: '',
          right: '',
        }}
        navLinks={false}
        events={events}
        eventTimeFormat={{
          hour: 'numeric',
          minute: '2-digit',
          meridiem: 'short',
          hour12: true,
        }}
        dayMaxEvents={4}
        eventDisplay="list-item"
        eventClassNames={(arg) => handleClassNames(arg)}
        eventClick={(calendarEvent) => handleEventClick(calendarEvent)}
      />
      {Modal}
    </Container>
  )
}

export default FCCalendar
