export default {
  pt: {
    StatusUpdate: {
      editTooltip: 'Editar',
      editButton: 'Editar',
      modalTitle: 'Editar Status',
      closeButton: 'Fechar',
      saveButton: 'Salvar',
      titleLabel: 'Título',
      titleRule: 'Por favor informe o título do status.',
      descriptionLabel: 'Descrição',
      colorLabel: 'Cor do status',
    },
  },
  en: {
    StatusUpdate: {
      editTooltip: 'Edit',
      editButton: 'Edit',
      modalTitle: 'Edit Status',
      closeButton: 'Close',
      saveButton: 'Save',
      titleLabel: 'Title',
      titleRule: 'Please enter the status title.',
      descriptionLabel: 'Description',
      colorLabel: 'Status color',
    },
  },
  es: {
    StatusUpdate: {
      editTooltip: 'Editar',
      editButton: 'Editar',
      modalTitle: 'Editar Estado',
      closeButton: 'Cerrar',
      saveButton: 'Guardar',
      titleLabel: 'Título',
      titleRule: 'Ingrese el título del estado.',
      descriptionLabel: 'Descripción',
      colorLabel: 'Color de estado',
    },
  },
}
