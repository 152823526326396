/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
import React from 'react'
import { Link } from 'react-router-dom'

// import Highlighter from 'react-highlight-words';

import { Table, Tag, Space, Tooltip, Typography, Button } from 'antd'
import { FileUnknownOutlined, LoadingOutlined } from '@ant-design/icons'
import { ColumnsType } from 'antd/es/table'

import AddToScaleButton from '~/components/DataManipulation/Misc/AddToScaleButton'

import { useListContributors } from '~/hooks/Contributors/useListContributors'
import { translate } from '~/utils/locale'

interface Company {
  id: string
  logo: string
  logo_url: string
  name: string
}

interface Contact {
  avatar: string
  avatar_url: string
  birth: string
  gender: string
  id: string
  name: string
  personType: string
  phone: string
  formattedPhone?: string
}

interface Status {
  color: string
  title: string
  id: string
  description: string
  type: string
}

interface Skill {
  id: string
  description: string
  name: string
}

interface Skills {
  description: string
  id: string
  level: string
  skill: Skill
}

interface Attachments {
  id: string
  title: string
  description: string
  attachment_url: string
  attachment: string
}

interface DataType {
  collaboratorAttachments: Attachments[]
  collaboratorSkills: Skills[]
  company: Company
  contact: Contact
  evaluation: number
  id: string
  status: Status
}

interface TableProps {
  data: DataType[]
  loading: boolean
}

const ScaleContributorsTable: React.FC<TableProps> = ({ data, loading }) => {
  const { Text } = Typography

  const { contributorsTotal, setPageSize, setNumberPage } =
    useListContributors()

  const columns: ColumnsType<DataType> = [
    {
      title: translate('ScalesContributorsTable.nameColumn'),
      align: 'left',
      dataIndex: ['contact', 'name'],
      key: 'name',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a, b) => a.contact.name.localeCompare(b.contact.name),
      render: (name: string, record) => (
        <Link
          to={`/colaboradores/visualizar/colaborador/${record.id}/contato/${record.contact.id}`}
        >
          {name}
        </Link>
      ),
    },
    {
      title: translate('ScalesContributorsTable.contactColumn'),
      dataIndex: ['contact', 'formattedPhone'],
      align: 'center',
      key: 'contact',
      render: (contact: string) => (
        <>
          {contact || (
            <Text type="secondary">
              <Space size="small">
                <FileUnknownOutlined />
                N/A
              </Space>
            </Text>
          )}
        </>
      ),
    },
    {
      title: translate('ScalesContributorsTable.skillsColumn'),
      align: 'center',
      key: 'collaboratorSkills',
      dataIndex: 'collaboratorSkills',
      render: (skills: Skills[]) => (
        <>
          {skills.length === 0 ? (
            <Text type="secondary">
              <Space size="small">
                <FileUnknownOutlined />
                N/A
              </Space>
            </Text>
          ) : (
            skills.map((skill) => {
              const color =
                skill.level === 'basic'
                  ? 'orange'
                  : skill.level === 'intermediary'
                    ? 'green'
                    : 'blue'
              return (
                <Tag color={color} key={skill.id}>
                  {skill.skill.name}
                </Tag>
              )
            })
          )}
        </>
      ),
    },
    {
      title: translate('ScalesContributorsTable.statusColumn'),
      align: 'center',
      key: 'status',
      dataIndex: 'status',
      render: (status: Status, record) => (
        <>
          {!status ? (
            <Text type="secondary">
              <Space size="small">
                <FileUnknownOutlined />
                N/A
              </Space>
            </Text>
          ) : (
            <Tooltip placement="top" title={status.description}>
              {status.title === 'Incompleto' ? (
                <Link
                  to={`/colaboradores/visualizar/colaborador/${record.id}/contato/${record.contact.id}/enderecos`}
                >
                  <Tag color={status.color} key={status.id}>
                    {status.title}
                  </Tag>
                </Link>
              ) : (
                <Tag color={status.color} key={status.id}>
                  {status.title}
                </Tag>
              )}
            </Tooltip>
          )}
        </>
      ),
    },
    {
      title: translate('ScalesContributorsTable.actionsColumn'),
      align: 'center',
      key: 'remove',
      render: (record) => (
        <Space size="middle">
          <AddToScaleButton id={record.id} />
        </Space>
      ),
    },
  ]

  return (
    <Table
      rowKey={(record) => record.id}
      loading={{
        indicator: <LoadingOutlined />,
        spinning: loading,
        size: 'large',
      }}
      pagination={{
        total: contributorsTotal,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '50', '100', '200'],
        defaultPageSize: 10,
        showTotal: (total, range) => `${range[0]} - ${range[1]} / ${total}`,
        onChange: (page, pageSize) => {
          setPageSize(pageSize!)
          setNumberPage(page)
        },
      }}
      locale={{
        emptyText: (
          <Space direction="vertical">
            <span style={{ color: '#9B9B9B' }}>
              {translate('ScalesContributorsTable.infoText')}
            </span>
            <Link to="/colaboradores/novo">
              <Button type="primary">
                {translate('ScalesContributorsTable.addButton')}
              </Button>
            </Link>
          </Space>
        ),
      }}
      columns={columns}
      dataSource={data}
      scroll={{ x: 1000 }}
    />
  )
}

export default ScaleContributorsTable
