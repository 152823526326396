export default {
  pt: {
    ScalesTable: {
      titleColumn: 'Título',
      dateRangeColumn: 'Dias',
      contributorsColumn: 'Colaboradores',
      noneContributorText: 'Nenhum Colaborador',
      contributorText: 'Colaborador',
      contributorsText: 'Colaboradores',
      teamsColumn: 'Equipes',
      noneTeamText: 'Nenhuma Equipe',
      teamText: 'Equipe',
      teamsText: 'Equipes',
      clientsColumn: 'Clientes',
      noneClientText: 'Nenhum Cliente',
      clientText: 'Cliente',
      clientsText: 'Clientes',
      actionsColumn: 'Ações',
      viewTooltip: 'Visualizar Escala',
      deletePopconfirm: 'Deseja mesmo deletar a escala?',
      deleteTooltip: 'Deletar',
    },
  },
  en: {
    ScalesTable: {
      titleColumn: 'Title',
      dateRangeColumn: 'Days',
      contributorsColumn: 'Contributors',
      noneContributorText: 'No Contributor',
      contributorText: 'Contributor',
      contributorsText: 'Contributors',
      teamsColumn: 'Teams',
      noneTeamText: 'No Team',
      teamText: 'Team',
      teamsText: 'Teams',
      clientsColumn: 'Customers',
      noneClientText: 'No Customer',
      clientText: 'Customer',
      clientsText: 'Customers',
      actionsColumn: 'Actions',
      viewTooltip: 'View Scale',
      deletePopconfirm: 'Do you really want to delete the scale?',
      deleteTooltip: 'Delete',
    },
  },
  es: {
    ScalesTable: {
      titleColumn: 'Título',
      dateRangeColumn: 'Días',
      contributorsColumn: 'Colaboradores',
      noneContributorText: 'Sin Colaborador',
      contributorText: 'Colaborador',
      contributorsText: 'Colaboradores',
      teamsColumn: 'Equipos',
      noneTeamText: 'Sin Equipo',
      teamText: 'Equipo',
      teamsText: 'Equipos',
      clientsColumn: 'Clientes',
      noneClientText: 'Ningún Cliente',
      clientText: 'Cliente',
      clientsText: 'Clientes',
      actionsColumn: 'Acciones',
      viewTooltip: 'Ver Escala',
      deletePopconfirm: '¿Realmente desea eliminar la escala?',
      deleteTooltip: 'Borrar',
    },
  },
}
