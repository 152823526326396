/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react/jsx-indent */
/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
import React, { useState } from 'react'
import { useHistory, useParams, Link } from 'react-router-dom'

import {
  Card,
  Tabs,
  Col,
  Row,
  Typography,
  Rate,
  Space,
  Button,
  Tooltip,
  message,
} from 'antd'
import { PageHeader } from '@ant-design/pro-components'
import {
  LoadingOutlined,
  ArrowLeftOutlined,
  CopyOutlined,
} from '@ant-design/icons'

import { Container, Evaluation } from './styles'

import Skills from '~/components/DataManipulation/Skill/Skills'
import SkillsList from '~/components/DataManipulation/Skill/SkillsList'
import AddressModal from '~/components/DataManipulation/Address/AddressModal'
import AddressList from '~/components/DataManipulation/Address/AddressList'
import AttachmentsModal from '~/components/DataManipulation/Attachment/AttachmentsModal'
import AttachmentsList from '~/components/DataManipulation/Attachment/AttachmentsList'
import ContributorCreate from '~/components/DataManipulation/Contributor/ContributorForm/ContributorCreate'
import ContributorDetails from '~/components/DataManipulation/Contributor/ContributorForm/ContributorDetails'
import ContributorUpdate from '~/components/DataManipulation/Contributor/ContributorForm/ContributorUpdate'
import EvaluationModal from '~/components/DataManipulation/Evaluation/EvaluationModal'
import InternalEvaluationModal from '~/components/DataManipulation/Evaluation/InternalEvaluationModal'
import EvaluationList from '~/components/DataManipulation/Evaluation/EvaluationList'
import StatusContributor from '~/components/DataManipulation/Status/Contributor'
import UpdateStatusContribuitor from '~/components/DataManipulation/Status/Contributor/Update'

import { useContributor } from '~/hooks/Contributors/useContributor'
import { useCompanies } from '~/hooks/Companies/useCompanies'

import { useListTask } from '~/hooks/Tasks/useListTasks'
import { useListClients } from '~/hooks/Clients/useListClients'
import { ListStatusProvider } from '~/hooks/Status/useListStatus'
import { translate } from '~/utils/locale'
import HelpTooltip from '../../Misc/HelpTooltip'
import ContributorFinancial from '../ContributorFinancial'

interface ContributorTabsProps {
  isCreation?: boolean
}

const ContributorTabs: React.FC<ContributorTabsProps> = ({
  isCreation = false,
}) => {
  const history = useHistory()

  const { contributorId, contactId, tab } = useParams<{
    contributorId: string
    contactId: string
    tab: string
  }>()

  const [activeKey, setActiveKey] = useState(tab ? `${tab}` : 'detalhes')

  const {
    isLoading,
    isFetching,
    contributorAddress,
    contributorAditionalInfo,
    contributorRefetch,
    isEdit,
    data,
  } = useContributor()
  const { selectedCompany } = useCompanies()

  const { handleUpdateFilters } = useListTask()
  const { handleUpdateFilters: filtersClient } = useListClients()

  const { TabPane } = Tabs
  const { Title } = Typography

  function handleFilterTask() {
    const dataTask = {
      responsibleContributors: contributorId,
    }

    handleUpdateFilters(dataTask)
    history.push('/agenda')
  }

  function handleFilterClient() {
    const dataClient = {
      collaborator_id: [contributorId],
    }

    filtersClient(dataClient)
    history.push('/clientes')
    window.location.reload()
  }

  const handleTabChange = (key: string) => {
    const path = window.location.pathname.split('/')

    if (key === 'detalhes') {
      history.replace({
        pathname: `/colaboradores/${path[2]}/colaborador/${contributorId}/contato/${contactId}`,
      })
    } else {
      history.replace({
        pathname: `/colaboradores/${path[2]}/colaborador/${contributorId}/contato/${contactId}/${key}`,
      })
    }
  }

  return (
    <Container>
      <PageHeader
        className="site-page-header"
        onBack={() => window.history.back()}
        title={
          isCreation
            ? translate('ContributorTabs.pageheaderNewTitle')
            : isEdit
              ? translate('ContributorTabs.pageheaderEditTitle')
              : translate('ContributorTabs.pageheaderViewTitle')
        }
        subTitle={data?.contact.name}
      />
      <Tabs
        tabBarExtraContent={
          <Space size="large">
            {!isLoading && isFetching && <LoadingOutlined spin />}
            <Space>
              <Tooltip title={translate('ContributorTabs.copyTooltip')}>
                <Button
                  ghost
                  type="primary"
                  icon={<CopyOutlined />}
                  onClick={() => {
                    navigator.clipboard.writeText(
                      `https://tasks.gstor.com.br/?auth=${selectedCompany},${contributorId}`,
                    )
                    message.success(translate('ContributorTabs.copyMessage'))
                  }}
                  shape="circle"
                />
              </Tooltip>
              {/* <Link to="/novaTarefa">
                <Button
                  type="primary"
                  onClick={() =>
                    sessionStorage.setItem('@taskCollaborator', contributorId)
                  }
                >
                  {translate('ContributorTabs.newTaskButton')}
                </Button>
              </Link> */}
              <Button
                id="btn-view-tasks"
                type="primary"
                onClick={handleFilterTask}
              >
                {translate('ContributorTabs.seeTaskButton')}
              </Button>
              <Button
                id="btn-view-clients"
                type="primary"
                onClick={handleFilterClient}
              >
                {translate('ContributorTabs.seeClientsButton')}
              </Button>
            </Space>
          </Space>
        }
        onTabClick={(key: string) => {
          handleTabChange(key)
          setActiveKey(key)
        }}
        activeKey={activeKey}
        defaultActiveKey={tab ? `${tab}` : 'detalhes'}
        style={{ paddingLeft: 20, paddingRight: 20 }}
      >
        <TabPane tab={translate('ContributorTabs.detailsTab')} key="detalhes">
          <Row>
            <Col span={!isCreation ? 16 : 24}>
              {isCreation ? (
                <ContributorCreate />
              ) : isEdit ? (
                <ContributorUpdate />
              ) : (
                <ContributorDetails />
              )}

              {!isCreation && (
                <Card
                  bordered={false}
                  style={{
                    display: 'inline-block',
                    width: 'calc(100% - 20px)',
                    marginTop: 10,
                  }}
                  extra={
                    <ListStatusProvider>
                      <UpdateStatusContribuitor />
                    </ListStatusProvider>
                  }
                  title={
                    <Space size="large">
                      <Tooltip title={translate('ContributorTabs.backTooltip')}>
                        <Link to="/colaboradores">
                          <ArrowLeftOutlined />
                        </Link>
                      </Tooltip>
                      <b>{translate('ContributorTabs.statusCardTitle')}</b>
                    </Space>
                  }
                >
                  <StatusContributor />
                </Card>
              )}
            </Col>
            {!isCreation ? (
              <Col span={8}>
                <Card
                  bordered={false}
                  title={
                    <Space size="small">
                      {translate('ContributorTabs.evaluationCardTitle')}
                      <HelpTooltip
                        title={translate('ContributorTabs.evaluationCardHelp')}
                      />
                    </Space>
                  }
                  style={{
                    display: 'inline-block',
                    width: '100%',
                  }}
                  extra={
                    <Button
                      type="primary"
                      onClick={() => {
                        handleTabChange('avaliacoes')
                        setActiveKey('avaliacoes')
                      }}
                    >
                      {translate('ContributorTabs.viewEvaluationButton')}
                    </Button>
                  }
                >
                  <Evaluation>
                    <Title level={4}>
                      {contributorAditionalInfo?.evaluation}
                    </Title>
                    <Rate
                      disabled
                      allowHalf
                      value={contributorAditionalInfo?.evaluation}
                      style={{ color: '#1E8AFF' }}
                    />
                  </Evaluation>
                </Card>

                <Card
                  bordered={false}
                  title={
                    <Space size="small">
                      {translate('ContributorTabs.internalEvaluationCardTitle')}
                      <HelpTooltip
                        title={translate(
                          'ContributorTabs.internalEvaluationCardHelp',
                        )}
                      />
                    </Space>
                  }
                  style={{
                    display: 'inline-block',
                    width: '100%',
                    marginTop: '20px',
                  }}
                  extra={<InternalEvaluationModal />}
                >
                  <Evaluation>
                    <Title level={4}>
                      {contributorAditionalInfo?.internal_evaluation}
                    </Title>
                    <Rate
                      disabled
                      allowHalf
                      value={contributorAditionalInfo?.internal_evaluation}
                      style={{ color: '#1E8AFF' }}
                    />
                  </Evaluation>
                </Card>
              </Col>
            ) : (
              ''
            )}
          </Row>
        </TabPane>
        {!isCreation && (
          <TabPane
            tab={translate('ContributorTabs.addressTab')}
            key="enderecos"
          >
            <Card
              bordered={false}
              title={translate('ContributorTabs.addressCardTitle')}
              extra={<AddressModal refetch={contributorRefetch} />}
            >
              <Space
                direction="vertical"
                size="small"
                style={{ width: '100%' }}
              >
                <AddressList
                  refetch={contributorRefetch}
                  addressInfo={contributorAddress}
                />
              </Space>
            </Card>
          </TabPane>
        )}
        {!isCreation && (
          <TabPane tab={translate('ContributorTabs.documentsTab')} key="anexos">
            <Card
              bordered={false}
              extra={<AttachmentsModal />}
              title={translate('ContributorTabs.documentsCardTitle')}
            >
              <Space
                direction="vertical"
                size="small"
                style={{ width: '100%' }}
              >
                <AttachmentsList />
              </Space>
            </Card>
          </TabPane>
        )}
        {!isCreation && (
          <TabPane
            tab={translate('ContributorTabs.skillsTab')}
            key="habilidades"
          >
            <Card
              bordered={false}
              title={translate('ContributorTabs.skillsCardTitle')}
              extra={<Skills />}
              style={{
                display: 'inline-block',
                width: '100%',
              }}
            >
              <SkillsList />
            </Card>
          </TabPane>
        )}
        {!isCreation && (
          <TabPane
            tab={translate('ContributorTabs.evaluationTab')}
            key="avaliacoes"
          >
            <Card
              bordered={false}
              extra={<EvaluationModal />}
              title={
                <Space size="large">
                  {translate('ContributorTabs.evaluationCardTitle')}
                  <HelpTooltip
                    title={translate('ContributorTabs.evaluationCardHelp')}
                  />
                </Space>
              }
            >
              <Space
                direction="vertical"
                size="small"
                style={{ width: '100%' }}
              >
                <EvaluationList />
              </Space>
            </Card>
          </TabPane>
        )}

        {!isCreation && (
          <TabPane tab="Valores Acordados" key="financial">
            <Card
              bordered={false}
              title={<Space size="large">Valores Acordados</Space>}
            >
              <Space
                direction="vertical"
                size="small"
                style={{ width: '100%' }}
              >
                <ContributorFinancial />
              </Space>
            </Card>
          </TabPane>
        )}
      </Tabs>
    </Container>
  )
}

export default ContributorTabs
