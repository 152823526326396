export default {
  pt: {
    ClientsPrincipalFinancialUpdate: {
      cardTitle: 'Editar Financeiro',

      valueLabel: 'Valor da Tarefa',
      valuePlaceholder: 'Informe o valor da tarefa',
      valueRule: 'Por favor, informe o valor da tarefa.',

      hoursLabel: 'Quantidade de horas',
      hoursPlaceholder: 'Horas',
      valueHoursLabel: 'Valor Hora Adicional',

      kmLabel: 'Quantidade de KM',
      kmPlaceholder: 'Informe os KM',
      valueKmLabel: 'Valor KM Adicional',

      deleteButton: 'Excluir',
      saveButton: 'Salvar',
    },
  },
  en: {
    ClientsPrincipalFinancialUpdate: {
      cardTitle: 'Edit Financial',

      valueLabel: 'Task Value',
      valuePlaceholder: 'Informe o valor da tarefa',
      valueRule: 'Por favor, informe o valor da tarefa.',

      hoursLabel: 'Number of hours',
      hoursPlaceholder: 'Hours',
      valueHoursLabel: 'Additional Hourly Value',

      kmLabel: 'Quantity of km',
      kmPlaceholder: 'Enter the km',
      valueKmLabel: 'Additional KM Value',

      deleteButton: 'Delete',
      saveButton: 'Save',
    },
  },
  es: {
    ClientsPrincipalFinancialUpdate: {
      cardTitle: 'Editar financiero',
      valueLabel: 'Valor de la tarea',
      valuePlaceholder: 'Informe o valor da tarefa',
      valueRule: 'Por favor, informe o valor da tarefa.',

      hoursLabel: 'Número de horas',
      hoursPlaceholder: 'Horas',
      valueHoursLabel: 'Valor Hora Adicional',

      kmLabel: 'Cantidad de KM',
      kmPlaceholder: 'Introduce el KM',
      valueKmLabel: 'Valor KM Adicional',

      deleteButton: 'Borrar',
      saveButton: 'Ahorrar',
    },
  },
}
