import styled from 'styled-components'

interface CardProps {
  colorBackground: string
}

interface ContentProps {
  colorStrongText: string
  colorText: string
}

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 2rem;
  margin-top: 1rem;
`

export const Card = styled.div<CardProps>`
  width: 100%;
  max-width: 320px;
  height: 550px;

  border-radius: 40px;
  background: ${(p) => p.colorBackground};

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: space-evenly;
`
export const User = styled.div`
  width: 110px;
  height: 110px;
  background: #333536;
  border-radius: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  margin: 0 auto;
  cursor: pointer;

  svg {
    font-size: 45px;
    color: white;
  }
`

export const Content = styled.div<ContentProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  h2 {
    margin-bottom: 0%;
    color: ${(p) => p.colorStrongText};
  }

  h3 {
    color: ${(p) => p.colorStrongText};
  }

  p {
    color: ${(p) => p.colorText};
    font-size: 17px;
  }

  .text {
    margin-bottom: 1rem;
  }
`
export const Colors = styled.div`
  .select-color {
    margin-bottom: 2rem;
  }
`
