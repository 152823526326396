export default {
  pt: {
    HelpDeskFilter: {
      filterTooltip: 'Limpar Filtro',
      filterAppliedText: 'Filtro Aplicado',
      filterText: 'Filtros',
      drawerTitle: 'Filtrar Tickets',
      fromTitleLabel: 'Título',
      fromTitlePlaceholder: 'Pesquisar por título',
      priorityLabel: 'Prioridade',
      priorityPlaceholder: 'Selecione a prioridade',
      ticketTypeLabel: 'Tipo de Solicitação',
      ticketTypePlaceholder: 'Selecione o tipo de solicitação',
      customerLabel: 'Cliente',
      customerPlaceholder: 'Informe o cliente',
      descriptionLabel: 'Descrição',
      descriptionPlaceholder: 'Informe a descrição',
      priorityHigh: 'Alta',
      priorityMedium: 'Média',
      priorityLow: 'Baixa',
      applyButton: 'Aplicar',
      cleanButton: 'Limpar',
    },
  },
  en: {
    HelpDeskFilter: {
      filterTooltip: 'Clear filter',
      filterAppliedText: 'Filter Applied',
      filterText: 'Filters',
      drawerTitle: 'Filter Tickets',
      fromTitle: 'Title',
      fromTitlePlaceholder: 'Search for title, code or client',
      priorityLabel: 'Priority',
      priorityPlaceholder: 'Select priority',
      ticketTypeLabel: 'Ticket Type',
      ticketTypePlaceholder: 'Select the ticket type',
      customerLabel: 'Cliente',
      customerPlaceholder: 'Enter the client',
      descriptionLabel: 'Description',
      descriptionPlaceholder: 'Enter the description',
      priorityHigh: 'High',
      priorityMedium: 'Medium',
      priorityLow: 'Low',
      applyButton: 'Apply',
      cleanButton: 'Clear',
    },
  },
  es: {
    HelpDeskFilter: {
      filterTooltip: 'Filtro Claro',
      filterAppliedText: 'Filtro Aplicado',
      filterText: 'Filtros',
      drawerTitle: 'Filtrar Tickets',
      fromTitle: 'Título',
      fromTitlePlaceholder: 'Búsqueda por título',
      priorityLabel: 'Prioridad',
      priorityPlaceholder: 'Seleccionar prioridad',
      ticketTypeLabel: 'Tipo de Solicitude',
      ticketTypePlaceholder: 'Seleccionar el tipo de solicitude',
      customerLabel: 'Cliente',
      customerPlaceholder: 'Informar al cliente',
      descriptionLabel: 'Descripción',
      descriptionPlaceholder: 'Informar al descripción',
      priorityHigh: 'Alta',
      priorityMedium: 'Media',
      priorityLow: 'Baja',
      applyButton: 'Aplicar',
      cleanButton: 'Limpio',
    },
  },
}
