/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable consistent-return */

import React, { useContext, createContext, useState, useCallback } from 'react'
import dayjs from 'dayjs'
import { FilterOptionsProps } from './tasksStatusModel'

interface FilterKanbanContextData {
  handleUpdateFilters: (newFilterOptions: any) => void
  handleResetFilters: () => void
  filterOptions: FilterOptionsProps
  paramsFilter: FilterOptionsProps
}

const FilterKanbanContext = createContext<FilterKanbanContextData>(
  {} as FilterKanbanContextData,
)

export const FilterKanbanProvider: React.FC = ({ children }) => {
  const [filterOptions, setFilterOptions] = useState<FilterOptionsProps>(() => {
    const filter = localStorage.getItem('@Gstor:tasksFilterKanbanOptions')

    if (filter) {
      return JSON.parse(filter)
    }

    return {} as FilterOptionsProps
  })

  const paramsFilter = {
    responsibleContributors: filterOptions?.responsibleContributors,
    responsibleTeams: filterOptions?.responsibleTeams,
    address: filterOptions?.address,
    customers_id: filterOptions?.customers_id,
    internal_code: filterOptions?.internal_code,
    current_status: filterOptions?.current_status,
    external_code: filterOptions?.external_code,
    task_type_id: filterOptions?.task_type_id,
    priority: filterOptions?.priority,
    fromDate: filterOptions?.fromDate
      ? dayjs.utc(filterOptions?.fromDate).format()
      : dayjs().subtract(3, 'months').startOf('day').format(),
    toDate: filterOptions?.toDate
      ? dayjs.utc(filterOptions?.toDate).format()
      : dayjs().add(3, 'months').startOf('day').format(),
  }

  const handleUpdateFilters = useCallback((newFilterOptions) => {
    const newFilters = {
      responsibleContributors: newFilterOptions?.responsibleContributors,
      responsibleTeams: newFilterOptions?.responsibleTeams,
      address: newFilterOptions?.address,
      customers_id: newFilterOptions?.customers_id,
      internal_code: newFilterOptions?.internal_code,
      current_status: newFilterOptions?.current_status,
      external_code: newFilterOptions?.external_code,
      task_type_id: newFilterOptions?.task_type_id,
      priority: newFilterOptions?.priority,
      fromDate: dayjs(newFilterOptions?.fromDate).startOf('day'),
      toDate: dayjs(newFilterOptions?.toDate).endOf('day'),
    }
    setFilterOptions(newFilters)
    localStorage.setItem(
      '@Gstor:tasksFilterKanbanOptions',
      JSON.stringify(newFilters),
    )
  }, [])

  const handleResetFilters = useCallback(() => {
    localStorage.removeItem('@Gstor:tasksFilterKanbanOptions')
    setFilterOptions({})
  }, [])

  return (
    <FilterKanbanContext.Provider
      value={{
        handleUpdateFilters,
        handleResetFilters,
        filterOptions,
        paramsFilter,
      }}
    >
      {children}
    </FilterKanbanContext.Provider>
  )
}

export function useFilterKanban(): FilterKanbanContextData {
  const context = useContext(FilterKanbanContext)

  if (!context) {
    throw new Error('useAuth must be used within an AutoProvider')
  }

  return context
}
