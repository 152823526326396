/* eslint-disable react/jsx-one-expression-per-line */
import { LoadingOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { Button, Card, Space } from 'antd'
import Text from 'antd/lib/typography/Text'
import React from 'react'
import { Link } from 'react-router-dom'
import { usePause } from '~/hooks/Tasks/Pause/usePause'
import { translate } from '~/utils/locale'
import ListKanbanTicket from './List'

const KanbanTicketsBoard: React.FC = () => {
  const { pauseTotal, isLoading, isFetching } = usePause()

  return (
    <Card
      title={
        <Space size="large">
          <b>Kanban de Tickets </b>
          {isLoading ? (
            <LoadingOutlined spin />
          ) : (
            <Text
              type="secondary"
              style={{ fontWeight: 'normal', fontSize: 14 }}
            >
              {pauseTotal !== undefined ? (
                <>
                  {pauseTotal === 1
                    ? `${translate(
                        'HelpDesk.totalText',
                      )} ${pauseTotal} ${translate('HelpDesk.breakReasonText')}`
                    : `${translate(
                        'HelpDesk.totalText',
                      )} ${pauseTotal} ${translate(
                        'HelpDesk.breakReasonsText',
                      )}`}
                </>
              ) : (
                ''
              )}
            </Text>
          )}
          {!isLoading && isFetching && <LoadingOutlined spin />}
        </Space>
      }
      extra={
        <Space size="small">
          {/* <FilterHelpDesk /> */}
          <Link to="/novoTicket">
            <Button type="primary" icon={<PlusCircleOutlined />}>
              Novo ticket
            </Button>
          </Link>
        </Space>
      }
      bordered={false}
      style={{ width: '100%', padding: 30 }}
    >
      <ListKanbanTicket />
    </Card>
  )
}

export default KanbanTicketsBoard
