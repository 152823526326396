export default {
  pt: {
    TasksRegistrationPauseUpdate: {
      editTooltip: 'Editar',
      modalTitle: 'Editar tipo de tarefa',
      closeButton: 'Fechar',
      saveButton: 'Salvar',
      titleLabel: 'Título',
      titleRule: 'Por favor informe o título do motivo de pausa.',
      titlePlaceholder: 'Informe o título',
      descriptionLabel: 'Descrição',
      descriptionRule: 'Por favor informe a descrição do motivo.',
      statusLabel: 'Status',
      statusRule: 'Por favor informe o status.',
      statusActive: 'Ativo',
      statusInactive: 'Inativo',
    },
  },
  en: {
    TasksRegistrationPauseUpdate: {
      editTooltip: 'Editar',
      modalTitle: 'Editar tipo de tarefa',
      closeButton: 'Fechar',
      saveButton: 'Salvar',
      titleLabel: 'Título',
      titleRule: 'Por favor informe o título do motivo de pausa.',
      titlePlaceholder: 'Informe o título',
      descriptionLabel: 'Descrição',
      descriptionRule: 'Por favor informe a descrição do motivo.',
      statusLabel: 'Status',
      statusRule: 'Por favor informe o status.',
      statusActive: 'Ativo',
      statusInactive: 'Inativo',
    },
  },
  es: {
    TasksRegistrationPauseUpdate: {
      editTooltip: 'Editar',
      modalTitle: 'Editar tipo de tarefa',
      closeButton: 'Fechar',
      saveButton: 'Salvar',
      titleLabel: 'Título',
      titleRule: 'Por favor informe o título do motivo de pausa.',
      titlePlaceholder: 'Informe o título',
      descriptionLabel: 'Descrição',
      descriptionRule: 'Por favor informe a descrição do motivo.',
      statusLabel: 'Status',
      statusRule: 'Por favor informe o status.',
      statusActive: 'Ativo',
      statusInactive: 'Inativo',
    },
  },
}
