import React, { createContext, useContext, useState } from 'react'
import { useQuery } from 'react-query'

import api from '~/services/api'
import { useProfile } from '../Auth/useProfile'
import { useCompanies } from '../Companies/useCompanies'

export interface INotification {
  id: string
  title: string
  content: string
  links_to?: string
  type: 'info' | 'update'
  read: boolean
  old: boolean
  collaborator_id: string
  company_id: string
  createdAt: Date
  updatedAt: Date
}

interface IResponse {
  total: number
  previous: boolean
  next: boolean
  results: INotification[]
}

interface NotificationsData {
  notifications?: INotification[]
  total: number
  moreNotifications: () => void
  totalUnreadNotifications: number
  isLoading: boolean
  isFetching: boolean
  readNotification: (notificationId: string) => Promise<void>
}

const Notifications = createContext<NotificationsData>({} as NotificationsData)

const NotificationsProvider: React.FC = ({ children }) => {
  const { profile } = useProfile()
  const { selectedCompany } = useCompanies()

  const [totalUnreadNotifications, setTotalUnreadNotifications] = useState(0)

  const pageSize = 20
  const [limit, setLimit] = useState(pageSize)

  const updatePageTitleCounter = (value: number) => {
    if (document.title.includes(') ')) {
      const [, title] = document.title.split(') ')
      document.title = `${value > 0 ? `(${value}) ` : ''}${title}`
    } else {
      document.title = `${value > 0 ? `(${value}) ` : ''}${document.title}`
    }
  }

  const { data, isLoading, isFetching, refetch } = useQuery(
    [`noticiactions${selectedCompany}`, selectedCompany, profile, limit],
    async () => {
      if (!selectedCompany || !profile) {
        return undefined
      }

      try {
        const response = await api.get<IResponse>(
          `/company/${selectedCompany}/notifications`,
          {
            params: {
              collaborator_id: profile.collaborator_id,
              offset: 0,
              limit,
            },
          },
        )

        const { results } = response.data

        if (results) {
          setTotalUnreadNotifications(() => {
            const total = results.filter(
              (notification) => notification.read === false,
            ).length
            updatePageTitleCounter(total)
            return total
          })
        }
        return response.data
      } catch (error) {
        return undefined
      }
    },
    {
      refetchInterval: 5 * 1000,
    },
  )

  const moreNotifications = () => {
    setLimit((oldLimit) => oldLimit + pageSize)
  }

  const readNotification = async (notificationId: string) => {
    await api.put<IResponse>(
      `/company/${selectedCompany}/notifications/${notificationId}/read`,
    )
    refetch()
  }

  return (
    <Notifications.Provider
      value={{
        notifications: data ? data.results : undefined,
        total: data ? data.total : 0,
        totalUnreadNotifications,
        moreNotifications,
        isLoading,
        isFetching,
        readNotification,
      }}
    >
      {children}
    </Notifications.Provider>
  )
}

function useNotifications(): NotificationsData {
  const context = useContext(Notifications)

  if (!context) {
    throw new Error(
      'useNotifications must be used within an NotificationsProvider',
    )
  }

  return context
}

export { NotificationsProvider, useNotifications }
