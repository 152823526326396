export default {
  pt: {
    BadgeComponent: {
      cardTitle: 'Crachá',
      totalText: 'Total de',
      badgeText: 'crachá',
      badgesText: 'crachás',
      newBadgeButtonText: 'Novo Crachá',
      tooltipText: 'Visualizar crachá e Adicionar logo da empresa',

      deletePopconfirm: 'Deseja mesmo deletar o crachá?',

      createBadgeTitle: 'Criar Crachá',
      stepOneTitle: 'Modelo do Crachá',
      stepOneDescription: 'Escolha o modelo que você mais gostar',
      stepTwoTitle: 'Personalização',
      stepTwoDescription: 'Personalize com as cores que você desejar',

      nameText: 'Nome do Template',
      nameRule: 'Por favor, insira o nome do template.',
      namePlaceholder: 'Informe o nome do template',
      descriptionText: 'Descrição do Template',
      descriptionPlaceholder: 'Informe a descrição do template',

      colaboradorText: 'Nome do Colaborador',
      companyText: 'Nome da Empresa',
      serviceText: 'A serviço do(a)',

      coverColorText: 'Escolha a cor da capa',
      backgroundColorText: 'Escolha a cor do fundo',
      strongColorText: 'Escolha a cor do texto de destaque',
      colorText: 'Escolha a cor do texto',

      serviceTooltipText:
        'A imagem da empresa é adicionado após a criação do crachá.',
      imageTooltipText: 'Imagem do Usuário',

      nextButton: 'Próximo',
      saveButton: 'Salvar',
      backButton: 'Voltar',

      errorImage: 'Erro ao fazer o upload da imagem',
      sucessImage: 'Imagem salva com sucesso!',
      sucessBadge: 'Crachá criado com sucesso!',
      errorBadge: 'Não foi possível criar o crachá',
      errorListBadge: 'Houve um erro ao listar os crachás',
      sucessDeleteBadge: 'Crachá deletado com sucesso!',
      errorDeleteBadge: 'Não foi possível deletar o crachá',
    },
  },
  en: {
    BadgeComponent: {
      cardTitle: 'Badge',
      totalText: 'Total of',
      badgeText: 'badge',
      badgesText: 'badges',
      newBadgeButtonText: 'New badge',
      tooltipText: 'View badge and Add company logo',

      deletePopconfirm: 'Do you really want to delete the badge?',

      createBadgeTitle: 'Create Badge',
      stepOneTitle: 'Badge Template',
      stepOneDescription: 'Choose the model you like best',
      stepTwoTitle: 'Customization',
      stepTwoDescription: 'Customize with the colors you want',

      nameText: 'Template Name',
      nameRule: 'Please enter the template name.',
      namePlaceholder: 'Enter the template name',
      descriptionText: 'Template Description',
      descriptionPlaceholder: 'Enter the template description',

      colaboradorText: 'Collaborator Name',
      companyText: 'Company Name',
      serviceText: 'At the service of',

      coverColorText: 'Choose cover color',
      backgroundColorText: 'Choose background color',
      strongColorText: 'Choose highlight text color',
      colorText: 'Choose text color',

      serviceTooltipText:
        'The company image is added after creating the badge.',
      imageTooltipText: 'User Image',

      nextButton: 'Next',
      saveButton: 'Save',
      backButton: 'Back',

      errorImage: 'Error uploading image',
      sucessImage: 'Image saved successfully!',
      sucessBadge: 'Badge created successfully!',
      errorBadge: 'Unable to create badge',
      errorListBadge: 'There was an error listing badges',
      sucessDeleteBadge: 'Badge deleted successfully!',
      errorDeleteBadge: 'Unable to delete badge',
    },
  },
  es: {
    BadgeComponent: {
      cardTitle: 'Insignia',
      totalText: 'Total de',
      badgeText: 'insignia',
      badgesText: 'insignias',
      newBadgeButtonText: 'Crear insignia',
      tooltipText: 'Ver insignia y Agregar el logotipo de la empresa',

      deletePopconfirm: '¿Realmente deseas eliminar la insignia?',

      createBadgeTitle: 'Crear insignia',
      stepOneTitle: 'Plantilla de insignia',
      stepOneDescription: 'Elige el modelo que más te guste',
      stepTwoTitle: 'Personalización',
      stepTwoDescription: 'Personaliza con los colores que quieras',

      nameText: 'Nombre de la plantilla',
      nameRule: 'Por favor ingrese el nombre de la plantilla.',
      namePlaceholder: 'Introduzca el nombre de la plantilla',
      descriptionText: 'Descripción de la plantilla',
      descriptionPlaceholder: 'Ingrese la descripción de la plantilla',

      colaboradorText: 'Nombre del Colaborador',
      companyText: 'Nombre de Empresa',
      serviceText: 'Al servicio de',

      coverColorText: 'Elige color de portada',
      backgroundColorText: 'Elige el color de fondo',
      strongColorText: 'Elija el color del texto resaltado',
      colorText: 'Elige el color del texto',

      serviceTooltipText:
        'La imagen de la empresa se agrega después de crear la insignia.',
      imageTooltipText: 'Imagen de usuario',

      nextButton: 'Próximo',
      saveButton: 'Guardar',
      backButton: 'Volver',

      errorImage: 'Error al subir la imagen',
      sucessImage: 'Imagen guardada exitosamente!',
      sucessBadge: 'Insignia creada exitosamente!',
      errorBadge: 'No se puede crear la insignia',
      errorListBadge: 'Se produjo un error al enumerar las insignias.',
      sucessDeleteBadge: '¡La insignia se eliminó correctamente!',
      errorDeleteBadge: 'No se puede eliminar la insignia',
    },
  },
}
