export default {
  pt: {
    usePermission: {
      createSuccessMessage: 'Permissão criada com sucesso!',
      createErrorMessage: 'Erro ao criar grupos de permissão.',
      updateErrorMessage: 'Erro ao atualizar grupos de permissão.',
      deleteLoadingMessage: 'Deletando permissão...',
      deleteSuccessMessage: 'Permissão deletada com sucesso!',
      deleteErrorMessage: 'Erro ao excluir grupos de permissão.',
    },
  },
  en: {
    usePermission: {
      createSuccessMessage: 'Permission created successfully!',
      createErrorMessage: 'Error creating permission groups.',
      updateErrorMessage: 'Error updating permission groups.',
      deleteLoadingMessage: 'Deleting permission...',
      deleteSuccessMessage: 'Permission successfully deleted!',
      deleteErrorMessage: 'Error deleting permission groups.',
    },
  },
  es: {
    usePermission: {
      createSuccessMessage: '¡Permiso creado con éxito!',
      createErrorMessage: 'Error al crear grupos de permisos.',
      updateErrorMessage: 'Error al actualizar los grupos de permisos.',
      deleteLoadingMessage: 'Borrando permiso...',
      deleteSuccessMessage: '¡Permiso eliminado con éxito!',
      deleteErrorMessage: 'Error al eliminar grupos de permisos.',
    },
  },
}
