import { Modal, Form, Select, Button } from 'antd'
import React, { useState } from 'react'
import { useListEquipments } from '~/hooks/Equipments/ListEquipmentsHooks'
import { useTask } from '~/hooks/Tasks/useTask'
import { translate } from '~/utils/locale'
import { ContainerButton } from './styles'

// import { Container } from './styles';

interface EquipmentTaskData {
  equipment: string[]
}

const CreateEquipmentTask: React.FC = () => {
  const { createEquipmentTask } = useTask()
  const { listEquipments } = useListEquipments()
  const { Option } = Select

  const [isModalVisible, setIsModalVisible] = useState(false)

  const [form] = Form.useForm()

  function onCreateEquipmentsTask(valueForm: EquipmentTaskData) {
    const equipmentsId = valueForm.equipment.map((e) => {
      return { id: e }
    })

    const data = {
      equipments: equipmentsId,
    }

    try {
      createEquipmentTask(data)
      setIsModalVisible(false)
    } catch (err) {
      setIsModalVisible(false)
    }
  }

  const showModal = () => {
    setIsModalVisible(true)
  }

  const closeModal = () => {
    setIsModalVisible(false)
  }

  return (
    <>
      <Button type="primary" onClick={showModal}>
        {translate('TasksTabsEquipmentsCreate.addButton')}
      </Button>
      <Modal
        title={translate('TasksTabsEquipmentsCreate.modalTitle')}
        open={isModalVisible}
        onCancel={closeModal}
        width="600px"
        footer={[
          <ContainerButton key="buttons">
            <Button
              key="save"
              type="primary"
              htmlType="submit"
              form="createEquipmentsTask"
              id="btn_create_equipmens_task"
            >
              {translate('TasksTabsEquipmentsCreate.okText')}
            </Button>
          </ContainerButton>,
        ]}
      >
        <Form
          layout="vertical"
          onFinish={onCreateEquipmentsTask}
          form={form}
          id="createEquipmentsTask"
        >
          <Form.Item
            label={translate('TasksTabsEquipmentsCreate.equipmentLabel')}
            name="equipment"
          >
            <Select
              placeholder={translate(
                'TasksTabsEquipmentsCreate.equipmentPlaceholder',
              )}
              mode="multiple"
            >
              {listEquipments?.map((equipments) => (
                <Option value={equipments.id} key={equipments.id}>
                  {equipments.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default CreateEquipmentTask
