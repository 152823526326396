export default {
  pt: {
    NewForm: {
      helmetTitle: 'Novo Formulário',
    },
  },
  en: {
    NewForm: {
      helmetTitle: 'New Form',
    },
  },
  es: {
    NewForm: {
      helmetTitle: 'Nuevo Forma',
    },
  },
}
