import { Card } from 'antd'
import React from 'react'
import { translate } from '~/utils/locale'
import LinkTasksTickets from './Link'
import TableTasksTickets from './Table'

// import { Container } from './styles';

const TasksTickets: React.FC = () => {
  return (
    <Card
      bordered={false}
      title={translate('HelpDeskTabs.tasksTab')}
      style={{
        display: 'inline-block',
        width: '100%',
      }}
      extra={<LinkTasksTickets />}
    >
      <TableTasksTickets />
    </Card>
  )
}

export default TasksTickets
