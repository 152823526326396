export default {
  pt: {
    MiscAvatarUploader: {
      uploadButton: 'Upload',
      uploadTypeError: 'Você só pode fazer upload de arquivos JPG ou PNG!',
      uploadSizeError: 'A imagem tem que ser menor que 2MB!',
      errorMessage: 'Erro ao enviar imagem de Avatar',
      modalTitle: 'Editar Avatar',
    },
  },
  en: {
    MiscAvatarUploader: {
      uploadButton: 'Upload',
      uploadTypeError: 'You can only upload JPG or PNG files!',
      uploadSizeError: 'The image has to be smaller than 2MB!',
      errorMessage: 'Error uploading Avatar image',
      modalTitle: 'Edit Avatar',
    },
  },
  es: {
    MiscAvatarUploader: {
      uploadButton: 'Subir',
      uploadTypeError: '¡Solo puede cargar archivos JPG o PNG!',
      uploadSizeError: '¡La imagen debe tener un tamaño inferior a 2 MB!',
      errorMessage: 'Error al cargar la imagen de Avatar',
      modalTitle: 'Editar Avatar',
    },
  },
}
