import { Input, Flex, Typography, Form, Tag } from 'antd'
import React from 'react'
import { IQuestion } from '~/hooks/Tasks/Forms/useTaskFormWithAnswers'

interface QuestionProps {
  data: IQuestion
  isEditing: boolean
}

const QuestionText: React.FC<QuestionProps> = ({ data, isEditing }) => {
  const defaultValue = isValidAnswerValue(data.answer?.value)
  return (
    <Flex vertical>
      {data.description && <h4>{data.description}</h4>}
      {isEditing ? (
        <Form.Item name={'value'} style={{ width: '100%' }}>
          <Input.TextArea
            placeholder="Resposta"
            size="large"
            value={defaultValue}
          />
        </Form.Item>
      ) : (
        <>
          <Typography.Paragraph>
            {defaultValue ? (
              <>
                <p>Resposta:</p> <blockquote>{defaultValue}</blockquote>
              </>
            ) : (
              <Tag>Sem resposta</Tag>
            )}
          </Typography.Paragraph>
        </>
      )}
    </Flex>
  )
}

const isValidAnswerValue = (
  value?: string | Array<string> | number | boolean | null,
): string | number | readonly string[] | undefined => {
  if (
    typeof value === 'string' ||
    typeof value === 'number' ||
    (Array.isArray(value) && value.every((item) => typeof item === 'string'))
  ) {
    return value
  }
  return undefined
}

export default QuestionText
