export default {
  pt: {
    ScalesFilter: {
      cleanTooltip: 'Limpar Filtro',
      filterText: 'Filtros',
      filterAppliedText: 'Filtro Aplicado',
      drawerTitle: 'Filtros de Escalas',
      titleLabel: 'Título da escala:',
      titlePlaceholder: 'Insira o título da escala',
      fromDayLabel: 'Início do alcance',
      fromDayPlaceholder: 'Informe a data inicial do alcance',
      toDayLabel: 'Final do alcance',
      toDayPlaceholder: 'Informe a data final do alcance',
      applyButton: 'Aplicar',
      cleanButton: 'Limpar',
    },
  },
  en: {
    ScalesFilter: {
      cleanTooltip: 'Clear filter',
      filterText: 'Filters',
      filterAppliedText: 'Filter Applied',
      drawerTitle: 'Scale Filters',
      titleLabel: 'Scale title:',
      titlePlaceholder: 'Enter the scale title',
      fromDayLabel: 'Range start',
      fromDayPlaceholder: 'Enter the start date of the scope',
      toDayLabel: 'End of range',
      toDayPlaceholder: 'Enter the reach end date',
      applyButton: 'Apply',
      cleanButton: 'Clean',
    },
  },
  es: {
    ScalesFilter: {
      cleanTooltip: 'Filtro claro',
      filterText: 'Filtros',
      filterAppliedText: 'Filtro Aplicado',
      drawerTitle: 'Filtros de Escalas',
      titleLabel: 'Título da escala:',
      titlePlaceholder: 'Ingrese el título de la escala',
      fromDayLabel: 'Rango de inicio',
      fromDayPlaceholder: 'Introduzca la fecha de inicio del alcance',
      toDayLabel: 'Fin de rango',
      toDayPlaceholder: 'Ingrese la fecha de finalización del alcance',
      applyButton: 'Aplicar',
      cleanButton: 'Limpio',
    },
  },
}
