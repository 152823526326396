export default {
  pt: {
    TasksCreateResponsible: {
      typeLabel: 'Tarefa será executada por',
      typeRule: 'Por favor informe o tipo de responsável.',
      typePlaceholder: 'Selecione por quem a tarefa será executada',
      typeContributor: 'Colaborador',
      typeTeam: 'Equipe',
      teamText: 'Equipe',
      contributorText: 'Colaborador',
      contributorRule: 'Por favor informe adicione um responsável.',
    },
  },
  en: {
    TasksCreateResponsible: {
      typeLabel: 'Task will be performed by',
      typeRule: 'Please inform the type of person responsible.',
      typePlaceholder: 'Select by whom the task will be performed',
      typeContributor: 'Contributor',
      typeTeam: 'Team',
      teamText: 'Team',
      contributorText: 'Contributor',
      contributorRule: 'Please inform add a responsible person.',
    },
  },
  es: {
    TasksCreateResponsible: {
      typeLabel: 'La tarea será realizada por',
      typeRule: 'Por favor, informe el tipo de persona responsable.',
      typePlaceholder: 'Seleccione quién realizará la tarea',
      typeContributor: 'Colaborador',
      typeTeam: 'Equipo',
      teamText: 'Equipo',
      contributorText: 'Colaborador',
      contributorRule: 'Por favor informe agregar una persona responsable.',
    },
  },
}
