export default {
  pt: {
    GroupsTable: {
      nameColumn: 'Nome',
      groupTypeColumn: 'Tipo do Grupo',
      groupTypeAdmin: 'Administrador',
      groupTypeCustom: 'Personalizado',
      groupTypeUser: 'Usuário',
      groupTypeManager: 'Gestor',
      groupMembersColumn: 'Total de membros',
      userText: 'usuário',
      usersText: 'usuários',
      groupPermissionsColumn: 'Total de permissões',
      permissionText: 'permissão',
      permissionsText: 'permissões',
      actionsColumn: 'Ações',
      viewTooltip: 'Visualizar Grupo',
      deletePopconfirm: 'Deseja mesmo deletar o grupo?',
      deleteTooltip: 'Deletar',
    },
  },
  en: {
    GroupsTable: {
      nameColumn: 'Name',
      groupTypeColumn: 'Group Type',
      groupTypeAdmin: 'Admin',
      groupTypeCustom: 'Custom',
      groupTypeUser: 'User',
      groupTypeManager: 'Manager',
      groupMembersColumn: 'Total members',
      userText: 'user',
      usersText: 'users',
      groupPermissionsColumn: 'Total permissions',
      permissionText: 'permission',
      permissionsText: 'permissions',
      actionsColumn: 'Actions',
      viewTooltip: 'View Group',
      deletePopconfirm: 'Do you really want to delete the group?',
      deleteTooltip: 'Delete',
    },
  },
  es: {
    GroupsTable: {
      nameColumn: 'Nombre',
      groupTypeColumn: 'Tipo do Grupo',
      groupTypeAdmin: 'Administrador',
      groupTypeCustom: 'Personalizado',
      groupTypeUser: 'Usuario',
      groupTypeManager: 'Gerente',
      groupMembersColumn: 'Miembros totales',
      userText: 'usuario',
      usersText: 'usuarios',
      groupPermissionsColumn: 'Permisos totales',
      permissionText: 'permiso',
      permissionsText: 'permisos',
      actionsColumn: 'Acciones',
      viewTooltip: 'Ver grupo',
      deletePopconfirm: '¿Realmente quieres eliminar el grupo?',
      deleteTooltip: 'Borrar',
    },
  },
}
