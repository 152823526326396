import React, { useCallback, useState } from 'react'

import { Modal, Form, Input, Button, message, Checkbox, Space } from 'antd'
import { PlusCircleOutlined } from '@ant-design/icons'

import { useListScales } from '~/hooks/Scales/useListScales'
import { translate } from '~/utils/locale'
import { ContainerButton } from './styles'

interface NewScaleData {
  title: string
  description: string
}

const NewScaleModal: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [keepRegister, setKeepRegister] = useState(false)

  const { createScale } = useListScales()

  const [form] = Form.useForm()

  const { TextArea } = Input

  const handleOk = () => {
    form.submit()
  }

  const handleCancel = useCallback(() => {
    form.resetFields()
    setIsVisible(false)
  }, [form])

  const onCreate = useCallback(
    async (data: NewScaleData) => {
      try {
        setConfirmLoading(true)
        await createScale(data)
        if (!keepRegister) {
          handleCancel()
        }
        form.resetFields()
        setConfirmLoading(false)
      } catch (error) {
        setConfirmLoading(false)
        message.error(translate('ScalesNewScaleModal.errorMessage'))
      }
    },
    [form, createScale, keepRegister, handleCancel],
  )

  const showModal = () => {
    setIsVisible(true)
  }

  return (
    <>
      <Button type="primary" onClick={showModal} icon={<PlusCircleOutlined />}>
        {translate('ScalesNewScaleModal.createButton')}
      </Button>

      <Modal
        title={translate('ScalesNewScaleModal.modalTitle')}
        open={isVisible}
        onOk={handleOk}
        okText={translate('ScalesNewScaleModal.okText')}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        footer={[
          <Space key="buttons">
            <Checkbox
              key="KeepRegister"
              onChange={(e) => {
                setKeepRegister(e.target.checked)
              }}
              checked={keepRegister}
            >
              {translate('ScalesNewScaleModal.keepRegisterText')}
            </Checkbox>

            <Button key="back" onClick={handleCancel}>
              {translate('ScalesNewScaleModal.cancelButton')}
            </Button>

            <ContainerButton>
              <Button
                key="submit"
                type="primary"
                loading={confirmLoading}
                onClick={handleOk}
                id="btn_create_scale"
              >
                {translate('ScalesNewScaleModal.submitButton')}
              </Button>
            </ContainerButton>
          </Space>,
        ]}
      >
        <Form form={form} onFinish={onCreate}>
          <Form.Item
            label={translate('ScalesNewScaleModal.nameLabel')}
            name="title"
            labelCol={{ span: 24 }}
            style={{
              width: '100%',
            }}
            rules={[
              {
                required: true,
                message: translate('ScalesNewScaleModal.nameRule'),
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={translate('ScalesNewScaleModal.descriptionLabel')}
            name="description"
            labelCol={{ span: 24 }}
            style={{
              width: '100%',
            }}
          >
            <TextArea rows={2} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default NewScaleModal
