export default {
  pt: {
    Dashboard: {
      helmetTitle: 'Dashboard',
      errorCompany:
        'Desculpe, você não tem autorização para acessar essa página.',
    },
  },
  en: {
    Dashboard: {
      helmetTitle: 'Dashboard',
      errorCompany: 'Sorry, you are not authorized to access this page.',
    },
  },
  es: {
    Dashboard: {
      helmetTitle: 'Dashboard',
      errorCompany:
        'Lo sentimos, no está autorizado para acceder a esta página.',
    },
  },
}
