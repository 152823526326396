/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

import { MapContainer, TileLayer, Marker } from 'react-leaflet'

import {
  Button,
  Form,
  Input,
  InputNumber,
  Card,
  Switch,
  Space,
  Typography,
  Row,
  Col,
  message,
} from 'antd'
import { SaveOutlined } from '@ant-design/icons'

import ScaleGeneralAddressList from '~/components/DataManipulation/Scales/ScaleGeneral/ScaleGeneralAddressList'
import ScaleGeneralAddressModal from '~/components/DataManipulation/Scales/ScaleGeneral/ScaleGeneralAddressModal'

import api from '~/services/api'

import { useCompanies } from '~/hooks/Companies/useCompanies'
import { useScale } from '~/hooks/Scales/useScale'
import { useListScales } from '~/hooks/Scales/useListScales'
import { useLocale } from '~/hooks/locale/useLocale'

import { ShowError } from '~/utils/errors/apiErrors'
import { translate } from '~/utils/locale'
import { Container } from './styles'

interface NewScaleValues {
  description: string
  is_enabled: boolean
  operating_range: number
  title: string
}

const ScaleGeneralForm: React.FC = () => {
  const [form] = Form.useForm()

  const { scaleId } = useParams<{ scaleId: string }>()

  const { selectedCompany } = useCompanies()
  const { scaleData, scaleRefetch } = useScale()
  const { scalesRefetch } = useListScales()
  const { locale } = useLocale()

  const [confirmLoading, setConfirmLoading] = useState(false)

  const { Text } = Typography

  const onFinish = async (values: NewScaleValues) => {
    try {
      setConfirmLoading(true)
      await api.put(`/company/${selectedCompany}/schedule/${scaleId}`, values)
      message.success(translate('ScalesGeneralForm.successMessage'))
      scaleRefetch()
      scalesRefetch()
      setConfirmLoading(false)
    } catch (err: any) {
      ShowError(
        err.message,
        translate('ScalesGeneralForm.errorMessage'),
        locale,
      )

      setConfirmLoading(false)
    }
  }

  return (
    <Form
      form={form}
      name="scaleForm"
      onFinish={onFinish}
      initialValues={scaleData}
    >
      <Card
        bordered={false}
        title={<b>{translate('ScalesGeneralForm.cardTitle')}</b>}
        extra={
          <Form.Item style={{ margin: '0 auto' }}>
            <Container>
              <Button
                type="primary"
                htmlType="submit"
                icon={<SaveOutlined />}
                loading={confirmLoading}
                id="btn_save_scale"
              >
                {translate('ScalesGeneralForm.saveButton')}
              </Button>
            </Container>
          </Form.Item>
        }
        style={{
          display: 'inline-block',
          width: 'calc(100% - 20px)',
        }}
      >
        <Form.Item
          name="title"
          label={<b>{translate('ScalesGeneralForm.titleLabel')}</b>}
          style={{
            display: 'inline-block',
            width: 'calc(50% - 13.4px)',
          }}
          rules={[
            {
              required: true,
              message: translate('ScalesGeneralForm.titleRule'),
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={<b>{translate('ScalesGeneralForm.operatingRangeLabel')}</b>}
          name="operating_range"
          style={{
            display: 'inline-block',
            width: 'calc(25% - 13.3px)',
            marginLeft: '20px',
          }}
          rules={[
            {
              required: true,
              message: translate('ScalesGeneralForm.operatingRangeRule'),
            },
          ]}
        >
          <InputNumber style={{ width: '100%' }} />
        </Form.Item>

        <Form.Item
          label={<b>{translate('ScalesGeneralForm.isEnabledLabel')}</b>}
          name="is_enabled"
          valuePropName="checked"
          style={{
            display: 'inline-block',
            width: 'calc(25% - 13.3px)',
            marginLeft: '20px',
          }}
        >
          <Switch
            checkedChildren={translate('ScalesGeneralForm.isEnabledActive')}
            unCheckedChildren={translate('ScalesGeneralForm.isEnabledInactive')}
          />
        </Form.Item>

        <Form.Item
          name="description"
          style={{
            display: 'inline-block',
            width: '100%',
          }}
          label={<b>{translate('ScalesGeneralForm.descriptionLabel')}</b>}
        >
          <Input.TextArea rows={2} />
        </Form.Item>
      </Card>

      <Card
        bordered={false}
        title={translate('ScalesGeneralForm.addressCardTitle')}
        style={{
          display: 'inline-block',
          width: 'calc(100% - 20px)',
        }}
      >
        {scaleData.address ? (
          <Row>
            <Col span={16}>
              <Space direction="vertical">
                <Space size="large">
                  <Space size="small">
                    <Text strong>
                      {translate('ScalesGeneralForm.zipcodeLabel')}
                    </Text>
                    <Text>{scaleData.address.zip_code}</Text>
                  </Space>
                  <Space size="small">
                    <Text strong>
                      {translate('ScalesGeneralForm.stateLabel')}
                    </Text>
                    <Text>{scaleData.address.state}</Text>
                  </Space>
                  <Space size="small">
                    <Text strong>
                      {translate('ScalesGeneralForm.cityLabel')}
                    </Text>
                    <Text>{scaleData.address.city}</Text>
                  </Space>
                </Space>
                <Space size="large">
                  <Space size="small">
                    <Text strong>
                      {translate('ScalesGeneralForm.neighborhoodLabel')}
                    </Text>
                    <Text>{scaleData.address.neighborhood}</Text>
                  </Space>
                  <Space size="small">
                    <Text strong>
                      {translate('ScalesGeneralForm.streetLabel')}
                    </Text>
                    <Text>{scaleData.address.street}</Text>
                  </Space>
                  <Space size="small">
                    <Text strong>
                      {translate('ScalesGeneralForm.numberLabel')}
                    </Text>
                    <Text>{scaleData.address.number}</Text>
                  </Space>
                  <Space size="small">
                    <Text strong>
                      {translate('ScalesGeneralForm.complementLabel')}
                    </Text>
                    <Text>{scaleData.address.complement}</Text>
                  </Space>
                </Space>
              </Space>
            </Col>
            <Col span={8}>
              <MapContainer
                center={
                  scaleData.address.location
                    ? [
                        scaleData.address.location.x,
                        scaleData.address.location.y,
                      ]
                    : [0, 0]
                }
                zoom={17}
                scrollWheelZoom={false}
                style={{ height: '200px', width: '350px' }}
                zoomControl={false}
                dragging={false}
                boxZoom={false}
                doubleClickZoom={false}
              >
                <TileLayer
                  attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {scaleData.address.location && (
                  <Marker
                    position={[
                      scaleData.address.location.x,
                      scaleData.address.location.y,
                    ]}
                  />
                )}
              </MapContainer>
            </Col>
          </Row>
        ) : (
          translate('ScalesGeneralForm.noAddressFound')
        )}
      </Card>

      <Card
        bordered={false}
        title={translate('ScalesGeneralForm.possibleAddressesCardTitle')}
        extra={<ScaleGeneralAddressModal />}
        style={{
          display: 'inline-block',
          width: 'calc(100% - 20px)',
        }}
      >
        <ScaleGeneralAddressList />
      </Card>
    </Form>
  )
}

export default ScaleGeneralForm
