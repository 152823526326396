export default {
  pt: {
    SegmentsComponent: {
      cardTitle: 'Segmentos',
      totalText: 'Total de',
      segmentText: 'segmento',
      segmentsText: 'segmentos',
    },
  },
  en: {
    SegmentsComponent: {
      cardTitle: 'Segments',
      totalText: 'Total of',
      segmentText: 'segment',
      segmentsText: 'segments',
    },
  },
  es: {
    SegmentsComponent: {
      cardTitle: 'Segmentos',
      totalText: 'Total de',
      segmentText: 'segmento',
      segmentsText: 'segmentos',
    },
  },
}
