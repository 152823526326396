/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/require-default-props */
import { DownloadOutlined } from '@ant-design/icons'
import { Card, List, Space, Tooltip } from 'antd'
import React from 'react'
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox'
import ReactPlayer from 'react-player'
import ReactAudioPlayer from 'react-audio-player'

import { useTask } from '~/hooks/Tasks/useTask'
import CreateReport from './Create'
import { translate } from '~/utils/locale'

// import { Container } from './styles';

interface ReportTaskProps {
  isVisualization?: boolean
}

interface Report {
  created_at: Date
  id: string
  type: string
  value: string
  title: string
  contributor: string
  from: string
  file_url: string
}

const ReportTask: React.FC<ReportTaskProps> = ({ isVisualization = false }) => {
  const { task, isLoading } = useTask()

  if (isLoading || !task) {
    return <p>{translate('TasksTabsReport.loadingText')}</p>
  }

  function organizeReports(reports: Report[]) {
    const reportsWithChecklist = reports.filter((report: Report) => {
      return /\[\w+\]\s*\(\d+\s+de\s+\d+\)/.test(report.title) // Verifica se o título segue o padrão [algo] (número de número)
    })

    const reportsWithoutChecklist = reports.filter((report: Report) => {
      return !/\[\w+\]\s*\(\d+\s+de\s+\d+\)/.test(report.title) // Verifica se o título não segue o padrão [algo] (número de número)
    })

    const reportsOrderedByTitle = reportsWithChecklist.sort(
      (a: any, b: any) => {
        const numberReportA = parseInt(a.title.match(/\((\d+)\s+de\s+\d+\)/)[1]) // Extrai o primeiro número de dentro dos parênteses
        const numberReportB = parseInt(b.title.match(/\((\d+)\s+de\s+\d+\)/)[1])
        return numberReportA - numberReportB
      },
    )

    const reportsOrganized = reportsOrderedByTitle.concat(
      reportsWithoutChecklist,
    )

    return reportsOrganized
  }

  return (
    <Card
      bordered={false}
      title={translate('TasksTabsReport.cardTitle')}
      style={{
        display: 'inline-block',
        width: '100%',
      }}
      extra={!isVisualization && <CreateReport />}
    >
      <List
        itemLayout="vertical"
        size="large"
        dataSource={organizeReports(task.reports)}
        renderItem={(item) => (
          <List.Item key={item.id}>
            <List.Item.Meta
              title={
                <Space size="large">
                  {item.title === null || item.title === 'undefined' ? (
                    <Space size="small">
                      {item.type === 'image' ? (
                        <b>{translate('TasksTabsReport.imageText')}</b>
                      ) : (
                        ''
                      )}
                      {item.type === 'video' ? (
                        <b>{translate('TasksTabsReport.videoText')}</b>
                      ) : (
                        ''
                      )}
                      {item.type === 'application' ? (
                        <b>{translate('TasksTabsReport.applicationText')}</b>
                      ) : (
                        ''
                      )}
                      {item.type === 'audio' ? (
                        <b>{translate('TasksTabsReport.audioText')}</b>
                      ) : (
                        ''
                      )}
                      {item.type === 'text' ? (
                        <b>{translate('TasksTabsReport.textText')}</b>
                      ) : (
                        ''
                      )}
                      {item.type === 'notes' ? (
                        <b>{translate('TasksTabsReport.notesText')}</b>
                      ) : (
                        ''
                      )}
                    </Space>
                  ) : (
                    <b>{item.title}</b>
                  )}
                  <p style={{ fontSize: 14, color: '#5F5959' }}>
                    <b>
                      Adicionado por {item.contributor} através do dispositivo{' '}
                      {item.from}
                    </b>
                  </p>
                </Space>
              }
              description={
                <div>
                  <Space>
                    <Space size="small">
                      {item.type === 'image' ? (
                        <Space direction="vertical">
                          <p>{translate('TasksTabsReport.imageDescription')}</p>
                          <SimpleReactLightbox>
                            <SRLWrapper>
                              <div
                                style={{
                                  border: '1px solid #cccccc',
                                  padding: 5,
                                  borderRadius: 3,
                                }}
                              >
                                <img
                                  src={item.value}
                                  alt="Imagem relato"
                                  width="200px"
                                  style={{ borderRadius: 3 }}
                                />
                              </div>
                            </SRLWrapper>
                          </SimpleReactLightbox>
                        </Space>
                      ) : (
                        ''
                      )}
                      {item.type === 'video' ? (
                        <Space direction="vertical">
                          <p>{translate('TasksTabsReport.videoDescription')}</p>
                          <div
                            style={{
                              border: '1px solid #cccccc',
                              padding: 5,
                              borderRadius: 3,
                            }}
                          >
                            <ReactPlayer
                              controls
                              url={item.value}
                              width="200px"
                              height="100px"
                            />
                          </div>
                        </Space>
                      ) : (
                        ''
                      )}
                      {item.type === 'application' ? (
                        <b>
                          {translate('TasksTabsReport.applicationDescription')}
                        </b>
                      ) : (
                        ''
                      )}
                      {item.type === 'audio' ? (
                        <Space direction="vertical">
                          <p>{translate('TasksTabsReport.audioDescription')}</p>
                          <ReactAudioPlayer src={item.value} controls />
                        </Space>
                      ) : (
                        ''
                      )}
                      {item.type === 'text' ? (
                        <b>{translate('TasksTabsReport.textDescription')}</b>
                      ) : (
                        ''
                      )}
                      {item.type === 'notes' ? (
                        <b>{translate('TasksTabsReport.notesDescription')}</b>
                      ) : (
                        ''
                      )}
                    </Space>

                    <div>
                      {item.type === 'notes' ? <p>{item.value}</p> : ''}
                      {item.type === 'image' || item.type === 'application' ? (
                        <Tooltip
                          title={translate('TasksTabsReport.downloadTooltip')}
                        >
                          <a
                            href={item.value}
                            target="_blank"
                            rel="noopener noreferrer"
                            download
                          >
                            <DownloadOutlined />
                          </a>
                        </Tooltip>
                      ) : (
                        ''
                      )}
                    </div>
                  </Space>
                  {/* <p style={{ marginTop: 10, color: '#3C8ACB' }}>
                    <b>
                      Adicionado por {item.contributor} através do dispositivo{' '}
                      {item.from}
                    </b>
                  </p> */}
                </div>
              }
            />
          </List.Item>
        )}
      />
    </Card>
  )
}

export default ReportTask
