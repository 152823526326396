/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState } from 'react'
import { Table, Input, Button, Space } from 'antd'
import { SearchOutlined } from '@ant-design/icons'

interface CustomerTaskValueProps {
  nome: string
  totalTarefas: number
  totalMedia: number
  valorTotal: number
}

interface Props {
  customersTasks: CustomerTaskValueProps[]
  loading: boolean
}

const TasksByCustomers: React.FC<Props> = ({ customersTasks, loading }) => {
  const [, setSearchText] = useState<string>('')
  const [, setSearchedColumn] = useState<string>('')

  const getColumnSearchProps = (dataIndex: keyof CustomerTaskValueProps) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: any) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Buscar nome`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Resetar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value: any, record: CustomerTaskValueProps) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
  })

  const handleSearch = (
    selectedKeys: React.Key[],
    confirm: () => void,
    dataIndex: keyof CustomerTaskValueProps,
  ) => {
    confirm()
    setSearchText(selectedKeys[0] as string)
    setSearchedColumn(dataIndex as string)
  }

  const handleReset = (clearFilters: () => void) => {
    clearFilters()
    setSearchText('')
  }

  const columns = [
    {
      title: 'Nome do Cliente',
      dataIndex: 'nome',
      key: 'nome',
      sorter: (a: CustomerTaskValueProps, b: CustomerTaskValueProps) =>
        a.nome.localeCompare(b.nome), // Ordenação alfabética
      ...getColumnSearchProps('nome'),
    },
    {
      title: 'Total de Tarefas',
      dataIndex: 'totalTarefas',
      key: 'totalTarefas',
      sorter: (a: CustomerTaskValueProps, b: CustomerTaskValueProps) =>
        a.totalTarefas! - b.totalTarefas!, // Ordenação por quantidade
    },
    {
      title: 'Valor Média',
      dataIndex: 'totalMedia',
      key: 'totalMedia',
      sorter: (a: CustomerTaskValueProps, b: CustomerTaskValueProps) =>
        (a.totalMedia || 0) - (b.totalMedia || 0), // Ordenação por valor total
      render: (totalMedia: number) => {
        return totalMedia?.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        })
      },
    },
    {
      title: 'Valor Total',
      dataIndex: 'valorTotal',
      key: 'valorTotal',
      sorter: (a: CustomerTaskValueProps, b: CustomerTaskValueProps) =>
        (a.valorTotal || 0) - (b.valorTotal || 0), // Ordenação por valor total
      render: (totalValue: number) => {
        return totalValue?.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        })
      },
    },
  ]

  return (
    <Table
      dataSource={customersTasks}
      loading={loading}
      columns={columns}
      pagination={false}
      scroll={{ x: 1000, y: 500 }}
      bordered
    />
  )
}

export default TasksByCustomers
