/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-curly-newline */
import {
  BuildOutlined,
  DeleteOutlined,
  LoadingOutlined,
  EditOutlined,
} from '@ant-design/icons'
import {
  Card,
  Col,
  Row,
  Typography,
  Space,
  Tooltip,
  Popconfirm,
  Avatar,
  Button,
} from 'antd'
import { format } from 'date-fns'
import React from 'react'

import { FiArchive, FiCalendar, FiKey } from 'react-icons/fi'

import { useAssociatedEquipments } from '~/hooks/Equipments/AssociatedEquipments'
import { useEquipments } from '~/hooks/Equipments/EquipmentsHooks'
import { translate } from '~/utils/locale'
import CreateAssociatedEquipments from '../AssociatedEquipments/Create'

import {
  Container,
  Wrapper,
  ContAvatar,
  ContAboutClient,
  OtherInfos,
  LoadingIndicator,
} from './styles'

const EquipmentsDetails: React.FC = () => {
  const { isLoading, equipments, setIsEdit } = useEquipments()
  const { deleteAssociatedEquipment } = useAssociatedEquipments()
  const { Text } = Typography

  if (isLoading || !equipments) {
    return (
      <LoadingIndicator>
        <LoadingOutlined style={{ fontSize: 64, margin: 'auto' }} spin />
      </LoadingIndicator>
    )
  }

  const formatterDate = (value: Date) => {
    return format(
      value,
      `dd/MM/yyyy '${translate('EquipmentsTabsDetails.dateString')}' HH:mm'h'`,
    )
  }
  const newDate = formatterDate(new Date(equipments.expiration_date))

  function deleteImage() {
    localStorage.setItem('removeImage', 'delete')
    window.location.reload()
  }

  const isRemoveImage = localStorage.getItem('removeImage')

  return (
    <Container>
      <Card
        bordered={false}
        title={translate('EquipmentsTabsDetails.cardTitle')}
        extra={
          <Button
            id="btn-edit-equipments"
            type="primary"
            icon={<EditOutlined />}
            onClick={() => setIsEdit(true)}
          >
            {translate('EquipmentsTabsDetails.editButton')}
          </Button>
        }
        style={{
          display: 'inline-block',
          width: '100%',
        }}
      >
        <Wrapper>
          <ContAvatar>
            {equipments.photo_url !== null && isRemoveImage === 'image' ? (
              <Avatar src={equipments.photo_url} shape="square" size={175} />
            ) : (
              <Avatar
                icon={<BuildOutlined />}
                shape="square"
                size={175}
                style={{ background: '#EAEAEA' }}
              />
            )}

            {isRemoveImage === 'image' ? (
              <div className="delete-image">
                <Popconfirm
                  title={translate('EquipmentsTable.deletePopconfirm')}
                  onConfirm={deleteImage}
                >
                  <Tooltip title="Apagar Imagem">
                    <span>
                      <DeleteOutlined />
                      Apagar Imagem
                    </span>
                  </Tooltip>
                </Popconfirm>
              </div>
            ) : (
              ''
            )}
          </ContAvatar>
          <ContAboutClient>
            <Row gutter={16}>
              <Col span={10}>
                <div className="text">
                  <div>
                    <FiArchive />
                    <Text strong>
                      {translate('EquipmentsTabsDetails.equipmentName')}
                    </Text>
                  </div>
                  <Text>{equipments.name}</Text>
                </div>
              </Col>
              <Col span={12}>
                <div className="text">
                  <div>
                    <FiArchive />

                    <div className="equipment">
                      <Text strong>
                        {translate('EquipmentsTabsDetails.associatedEquipment')}
                      </Text>
                      <CreateAssociatedEquipments />
                    </div>
                  </div>
                  {equipments.associatedEquipment !== null ? (
                    <div className="delete_association">
                      <Space>
                        <Text>
                          {equipments.associatedEquipment.equipment.name}
                        </Text>
                        <Tooltip
                          title={translate(
                            'EquipmentsTabsDetails.deleteTooltip',
                          )}
                        >
                          <Popconfirm
                            title={translate(
                              'EquipmentsTabsDetails.deletePopconfirm',
                            )}
                            onConfirm={() =>
                              deleteAssociatedEquipment(
                                equipments.associatedEquipment.equipment.id,
                                equipments.associatedEquipment.id,
                              )
                            }
                          >
                            <Button
                              ghost
                              shape="circle"
                              icon={<DeleteOutlined />}
                              danger
                              size="small"
                            />
                          </Popconfirm>
                        </Tooltip>
                      </Space>
                    </div>
                  ) : (
                    <Text>
                      {translate('EquipmentsTabsDetails.noAssociatedEquipment')}
                    </Text>
                  )}
                </div>
              </Col>
            </Row>

            <Row>
              <Col span={10}>
                <div className="text">
                  <div>
                    <FiKey />
                    <Text strong>
                      {translate('EquipmentsTabsDetails.identifier')}
                    </Text>
                  </div>
                  <Text>{equipments.identifier}</Text>
                </div>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={10}>
                <div className="text">
                  <div>
                    <FiCalendar />
                    <Text strong>
                      {translate('EquipmentsTabsDetails.expirationDate')}
                    </Text>
                  </div>
                  <Text>{newDate}</Text>
                </div>
              </Col>
            </Row>
          </ContAboutClient>
        </Wrapper>
      </Card>

      <Card
        bordered={false}
        title={translate('EquipmentsTabsDetails.generalInfoTitle')}
        style={{
          display: 'inline-block',
          width: '100%',
          marginTop: 10,
        }}
      >
        <OtherInfos>
          <Row gutter={16}>
            <Col span={10}>
              <div className="text">
                <Text strong>
                  {translate('EquipmentsTabsDetails.equipmentLocale')}
                </Text>
                <Text>{translate('EquipmentsTabsDetails.inventory')}</Text>
              </div>
            </Col>
            <Col span={10}>
              <div className="text">
                <Text strong>
                  {translate('EquipmentsTabsDetails.category')}
                </Text>
                <Text>{equipments.category.title}</Text>
              </div>
            </Col>
          </Row>
          <Row>
            <div className="text">
              <Text strong>
                {translate('EquipmentsTabsDetails.description')}
              </Text>
              <Text>{equipments.description}</Text>
            </div>
          </Row>
        </OtherInfos>
      </Card>
    </Container>
  )
}

export default EquipmentsDetails
