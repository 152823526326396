/* eslint-disable @typescript-eslint/no-explicit-any */
import { LoadingOutlined } from '@ant-design/icons'
import { Button, Card, Col, Empty, Popconfirm, Row, Space, Tag } from 'antd'
import { addDays, format } from 'date-fns'
import React from 'react'
import { useShowPayment } from '~/hooks/Payment/useShowPayment'

import { Container, ContainerEmpty } from './styles'
import { useCompanies } from '~/hooks/Companies/useCompanies'

const DetailsPayment: React.FC = () => {
  const { showPayment, isLoading, deletePayment } = useShowPayment()
  const { selectedCompany } = useCompanies()

  if (isLoading || !showPayment) {
    return <LoadingOutlined style={{ fontSize: 64, margin: 'auto' }} spin />
  }

  const paymentId = showPayment.filter((p) => {
    return p.description === selectedCompany
  })

  const payment = paymentId[0]

  const formatDate = (date: any) => {
    return format(new Date(date), 'dd/MM/yyyy')
  }

  console.log(payment)

  const date = addDays(payment ? new Date(payment.dateCreated) : new Date(), 1)

  const dueDate = addDays(
    payment ? new Date(payment?.nextDueDate) : new Date(),
    1,
  )

  localStorage.setItem('@Gstor:payment', payment?.status)

  return (
    <Container>
      {payment ? (
        <Card
          style={{ boxShadow: 'none', width: '50%' }}
          title={
            <Space size="large">
              Detalhes da Assinatura
              {payment?.status === 'PENDING' ? (
                <Tag color="orange">Aguardando pagamento</Tag>
              ) : (
                ''
              )}
              {payment?.status === 'RECEIVED' ? (
                <Tag color="green">Pagamento Recebido</Tag>
              ) : (
                ''
              )}
              {payment?.status === 'CONFIRMED' ? (
                <Tag color="blue">Pagamento Confirmado</Tag>
              ) : (
                ''
              )}
              {payment?.status === 'ACTIVE' ? (
                <Tag color="blue">Pagamento Ativo</Tag>
              ) : (
                ''
              )}
            </Space>
          }
        >
          <Row gutter={14}>
            <Col span={8}>
              <h4>Data da Compra</h4>
              <p>{formatDate(date)}</p>
            </Col>

            <Col span={8}>
              <h4>Forma de Pagamento</h4>
              {payment?.billingType === 'BOLETO' ? <p>Boleto Bancário</p> : ''}

              {payment?.billingType === 'PIX' ? <p>PIX</p> : ''}

              {payment?.billingType === 'CREDIT_CARD' ? (
                <p>Cartão de Crédito</p>
              ) : (
                ''
              )}
            </Col>

            <Col span={8}>
              <h4>Valor da Assinatura</h4>
              <p>R$ 250,00</p>
            </Col>
          </Row>

          <Row gutter={14} style={{ marginTop: '1rem' }}>
            <Col span={8}>
              <h4>Data de Vencimento</h4>
              <p>{formatDate(dueDate)}</p>
            </Col>

            {payment?.status === 'RECEIVED' ||
            payment?.status === 'CONFIRMED' ||
            payment?.status === 'ACTIVE' ? (
              <Col span={8}>
                <h4>Cartão de Crédito</h4>
                <Tag color="blue">{payment?.creditCard.creditCardNumber}</Tag>
              </Col>
            ) : (
              ''
            )}
          </Row>

          <Row gutter={12} style={{ marginTop: '1rem' }}>
            {payment?.status === 'PENDING' ? (
              <Col>
                <Button type="primary" href={payment.invoiceUrl} target="BLANK">
                  Pagar Assinatura
                </Button>
              </Col>
            ) : (
              ''
            )}

            <Col>
              <Popconfirm
                title="Deseja mesmo encerrar a assinatura?"
                onConfirm={() => deletePayment(payment.id)}
              >
                <Button danger type="primary">
                  Cancelar Assinatura
                </Button>
              </Popconfirm>
            </Col>
          </Row>
        </Card>
      ) : (
        <ContainerEmpty>
          <Empty description="Nenhuma assinatura registrada." />
        </ContainerEmpty>
      )}
    </Container>
  )
}

export default DetailsPayment
