import React from 'react'

import { Button } from 'antd'
import { DownloadOutlined } from '@ant-design/icons'

import { useReportTasks } from '~/hooks/Report/Tasks/useReportTasks'
import { translate } from '~/utils/locale'
// import { Container } from './styles';

const ExportReportTask: React.FC = () => {
  const { exportReportTasks, urlDownload, loading, text } = useReportTasks()

  return (
    <Button
      type="primary"
      key="export"
      icon={<DownloadOutlined />}
      loading={loading}
      onClick={exportReportTasks}
      href={urlDownload}
      target="_blank"
      rel="noopener noreferrer"
      download
      danger={text === translate('ReportTasksExport.downloadText')}
    >
      {text}
    </Button>
  )
}

export default ExportReportTask
