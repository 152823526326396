export default {
  pt: {
    ClientsContactsTabFilter: {
      filterTooltip: 'Limpar Filtro',
      filterApplied: 'Filtro Aplicado',
      filterText: 'Filtros',
      drawerTitle: 'Filtros de Contatos do Cliente',
      contactLabel: 'Contato:',
      contactPlaceholder: 'Insira o nome do contato',
      officeLabel: 'Cargo:',
      officePlaceholder: 'Insira o cargo',
      responsibleLabel: 'Responsáveis:',
      responsibleActive: 'Ativo',
      responsibleInactive: 'Inativo',
      applyButtonText: 'Aplicar',
      cleanButtonText: 'Limpar',
    },
  },
  en: {
    ClientsContactsTabFilter: {
      filterTooltip: 'Clear Filter',
      filterApplied: 'Filter Applied',
      filterText: 'Filters',
      drawerTitle: 'Customer Contact Filters',
      contactLabel: 'Contact:',
      contactPlaceholder: 'Enter contact name',
      officeLabel: 'Office:',
      officePlaceholder: 'Enter the position',
      responsibleLabel: 'Responsible:',
      responsibleActive: 'Active',
      responsibleInactive: 'Inactive',
      applyButtonText: 'Apply',
      cleanButtonText: 'Clean',
    },
  },
  es: {
    ClientsContactsTabFilter: {
      filterTooltip: 'Filtro Claro',
      filterApplied: 'Filtro Aplicado',
      filterText: 'Filtros',
      drawerTitle: 'Filtros de contacto del cliente',
      contactLabel: 'Contacto:',
      contactPlaceholder: 'Introduce el nombre del contacto',
      officeLabel: 'Oficina:',
      officePlaceholder: 'Ingrese la posición',
      responsibleLabel: 'Responsable:',
      responsibleActive: 'Activo',
      responsibleInactive: 'Inactivo',
      applyButtonText: 'Aplicar',
      cleanButtonText: 'Limpio',
    },
  },
}
