export default {
  pt: {
    TasksPrintButton: {
      printTooltip: 'Imprimir Tarefa',
    },
  },
  en: {
    TasksPrintButton: {
      printTooltip: 'Print Task',
    },
  },
  es: {
    TasksPrintButton: {
      printTooltip: 'Imprimir Tarea',
    },
  },
}
