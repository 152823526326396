/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, createContext } from 'react'
import { useQuery } from 'react-query'

import api from '~/services/api'

import { useCompanies } from '~/hooks/Companies/useCompanies'
import { useLocale } from '~/hooks/locale/useLocale'

import { ShowError } from '~/utils/errors/apiErrors'
import { translate } from '~/utils/locale'

interface AddressProps {
  _id: string
  zip_code: string
  street: string
  number: string
  complement: string
  neighborhood: string
  city: string
  id: string
  state: string
  location: { x: number; y: number }
}

interface Financial {
  task_value: number
  amount_hours: number
  amount_hours_additional?: number
  number_km: number
  number_km_additional?: number
}

interface ContactProps {
  name: string
  email: string
  celular: string
  phone: string
  _id: string
}

export interface ParentClientsProps {
  _id: string
  cnpj: string
  cpf: string
  fantasyName: string
  companyName: string
  municipal_registration: string
  state_registration: string
  contact: ContactProps
  address: AddressProps
  financial: Financial
}

interface ParentCustomerInClientsContextData {
  parentCustomer?: ParentClientsProps
  isLoading: boolean
  refetch: () => void
}

const ParentCustomerInClientsContext =
  createContext<ParentCustomerInClientsContextData>(
    {} as ParentCustomerInClientsContextData,
  )

export const ParentCustomerInClientsProvider: React.FC = ({ children }) => {
  const { selectedCompany } = useCompanies()
  const { locale } = useLocale()
  const parentCustomerId = localStorage.getItem('@parentCustomerId')!

  const {
    data: parentCustomer,
    isLoading,
    refetch,
  } = useQuery(
    [`cliente principal em clientes${selectedCompany}`, selectedCompany],

    async () => {
      if (parentCustomerId) {
        try {
          const response = await api.get(
            `/company/${selectedCompany}/parentCustomer/${parentCustomerId}`,
          )

          const { data } = response

          return data
        } catch (err: any) {
          ShowError(
            err.message,
            translate('EquipmentsHooks.getErrorMessage'),
            locale,
          )
        }
      }
    },
  )

  return (
    <ParentCustomerInClientsContext.Provider
      value={{
        parentCustomer,
        isLoading,
        refetch,
      }}
    >
      {children}
    </ParentCustomerInClientsContext.Provider>
  )
}

export function useParentInClientsCustomer(): ParentCustomerInClientsContextData {
  const context = useContext(ParentCustomerInClientsContext)

  if (!context) {
    throw new Error('useAuth must be used within an AutoProvider')
  }

  return context
}
