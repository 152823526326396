/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
import React, { useContext, createContext, useState } from 'react'
import { useQuery } from 'react-query'
import { formatPhoneNumber } from 'react-phone-number-input'
import { useParams } from 'react-router-dom'

import { message } from 'antd'

import api from '~/services/api'

import { useCompanies } from '~/hooks/Companies/useCompanies'
import { useLocale } from '~/hooks/locale/useLocale'

import { ShowError } from '~/utils/errors/apiErrors'
import { translate } from '~/utils/locale'

interface ContactProps {
  avatar: string
  avatar_url: string
  cnpj: string
  rg: string
  document: string
  formattedDocument: string
  email: string
  id: string
  phone: string
  formattedPhone: string
  name: string
  person_type: string
  is_whatsapp: boolean
}

interface AddressProps {
  id: string
  notes: string
  type: string
  address: {
    zip_code: string
    type: string
    street: string
    number: string
    complement: string
    neighborhood: string
    city: string
    id: string
    state: string
    notes: string
    location: { x: number; y: number }
    urlGoogle?: string
  }
}

interface SegmentProps {
  description: string
  title: string
  id: string
}

interface CollaboratorProps {
  id: string
  contact: {
    id: string
    name: string
  }
}

interface AdditionalPhonesProps {
  phone: string
  type: string
  is_whatsapp: boolean
  formattedPhone: string
}

interface ClientsProps {
  billing_email: string
  colaborator: string
  corporate_name: string
  created_at: string
  external_code: string
  icms_taxpayer: string
  is_enabled: string
  municipal_registration: string
  id: number
  state_registration: string
  is_responsible: boolean
  notes: string
  contact: ContactProps
  segment: SegmentProps
  collaborator: CollaboratorProps
  address: AddressProps[]
  phones: AdditionalPhonesProps[]
  parent_customer: string
  parent_cnpj: string
}

interface ClientsContextData {
  clients?: ClientsProps
  isLoading: boolean
  refetch: () => void
  isFetching: boolean
  setIsEdit(value: boolean): void
  updateClient(dataClient: object): void
  updateParentClient(dataClient: object): void
  addChildrenCustomer(dataClient: object, parentCustomerId: string): void
  deleteClient(dataClient: object): void
  isEdit?: boolean
}

const ClientsContext = createContext<ClientsContextData>(
  {} as ClientsContextData,
)

export const ClientsProvider: React.FC = ({ children }) => {
  const { selectedCompany } = useCompanies()
  const { locale } = useLocale()
  const { customerId } = useParams<{ customerId: string }>()

  const [isEdit, setIsEdit] = useState<boolean>()

  const {
    data: clients,
    isLoading,
    refetch,
    isFetching,
  } = useQuery(
    [`clientes${selectedCompany}`, selectedCompany],

    async () => {
      try {
        const response = await api.get(
          `/company/${selectedCompany}/customer/${customerId}`,
        )

        const { data } = response

        if (data.contact.phone) {
          data.contact.phone = `+${data.contact.phone}`
          data.contact.formattedPhone = formatPhoneNumber(data.contact.phone)

          if (data.contact.formattedPhone.replace(/[0-9]/g, '') === '') {
            if (data.contact.formattedPhone.length === 10) {
              const forceFormat = data.contact.formattedPhone.match(
                /^(\d{2})(\d{4})(\d{4})$/,
              )

              if (forceFormat) {
                data.contact.formattedPhone = `(${forceFormat[1]}) ${forceFormat[2]}-${forceFormat[3]}`
              }
            } else if (data.contact.formattedPhone.length === 9) {
              const forceFormat = data.contact.formattedPhone.match(
                /^(\d{2})(\d{4})(\d{3})$/,
              )

              if (forceFormat) {
                data.contact.formattedPhone = `(${forceFormat[1]}) ${forceFormat[2]}-${forceFormat[3]}`
              }
            }
          }
        }

        if (
          data.contact.person_type === 'legalPerson' ||
          data.contact.person_type === 'physicalPerson'
        ) {
          if (data.contact.document) {
            const match = data.contact.document.match(
              /^(\d{3})(\d{3})(\d{3})(\d{2})$/,
            )

            if (match) {
              data.contact.formattedDocument = `${match[1]}.${match[2]}.${match[3]}-${match[4]}`
            }
          }
        } else {
          data.contact.formattedDocument = data.contact.document
        }

        if (data.contact.additional_phones) {
          data.contact.additional_phones.forEach(
            (additional_phone: AdditionalPhonesProps) => {
              if (additional_phone.phone) {
                additional_phone.phone = `+${additional_phone.phone}`
                additional_phone.formattedPhone = formatPhoneNumber(
                  additional_phone.phone,
                )

                if (
                  additional_phone.formattedPhone.replace(/[0-9]/g, '') === ''
                ) {
                  if (additional_phone.formattedPhone.length === 10) {
                    const forceFormat = additional_phone.formattedPhone.match(
                      /^(\d{2})(\d{4})(\d{4})$/,
                    )

                    if (forceFormat) {
                      additional_phone.formattedPhone = `(${forceFormat[1]}) ${forceFormat[2]}-${forceFormat[3]}`
                    }
                  } else if (additional_phone.formattedPhone.length === 9) {
                    const forceFormat = additional_phone.formattedPhone.match(
                      /^(\d{2})(\d{4})(\d{3})$/,
                    )

                    if (forceFormat) {
                      additional_phone.formattedPhone = `(${forceFormat[1]}) ${forceFormat[2]}-${forceFormat[3]}`
                    }
                  }
                }
              }
            },
          )
        }

        const dataFormatted = {
          billing_email: data.billing_email,
          colaborator: data.colaborator,
          corporate_name: data.corporate_name,
          created_at: data.created_at,
          external_code: data.external_code,
          icms_taxpayer: data.icms_taxpayer,
          is_enabled: data.is_enabled,
          municipal_registration: data.municipal_registration,
          id: data.id,
          state_registration: data.state_registration,
          is_responsible: data.is_responsible,
          notes: data.notes,
          contact: data.contact,
          segment: data.segment,
          collaborator: data.collaborator,
          address: data.contact.contactAddresses,
          phones: data.contact.additional_phones,
          parent_customer: data.parent_customer,
          parent_cnpj: data.parent_cnpj,
        }

        return dataFormatted
      } catch (err: any) {
        ShowError(err.message, translate('useClients.getErrorMessage'), locale)
      }
    },
    {
      staleTime: 5000,
    },
  )

  async function updateClient(dataClient: object) {
    try {
      await api.put(
        `/company/${selectedCompany}/customer/${customerId}`,
        dataClient,
      )

      refetch()

      message.success(translate('useClients.updateSuccessMessage'))
    } catch (err: any) {
      ShowError(err.message, translate('useClients.updateErrorMessage'), locale)
    }
  }

  async function updateParentClient(dataClient: object) {
    try {
      await api.put(
        `/company/${selectedCompany}/customer/${customerId}/parentCustomer`,
        dataClient,
      )

      refetch()

      message.success('Cliente principal adicionado com sucesso.')
    } catch (err: any) {
      ShowError(err.message, translate('useClients.updateErrorMessage'), locale)
    }
  }

  async function addChildrenCustomer(
    valueForm: object,
    parentCustomerId: string,
  ) {
    try {
      await api.post(
        `/company/${selectedCompany}/parentCustomer/${parentCustomerId}/customer`,
        valueForm,
      )

      refetch()
    } catch (err: any) {
      // ShowError(
      //   err.message,
      //   translate('useTask.updateReportErrorMessage'),
      //   locale,
      // )
    }
  }

  async function deleteClient(dataClient: object) {
    try {
      await api.put(
        `/company/${selectedCompany}/customer/${customerId}`,
        dataClient,
      )

      refetch()
      message.success(translate('useClients.deleteSuccessMessage'))
    } catch (err: any) {
      ShowError(err.message, translate('useClients.deleteErrorMessage'), locale)
    }
  }

  return (
    <ClientsContext.Provider
      value={{
        clients,
        isLoading,
        refetch,
        isFetching,
        setIsEdit,
        isEdit,
        updateClient,
        deleteClient,
        updateParentClient,
        addChildrenCustomer,
      }}
    >
      {children}
    </ClientsContext.Provider>
  )
}

export function useClients(): ClientsContextData {
  const context = useContext(ClientsContext)

  if (!context) {
    throw new Error('useAuth must be used within an AutoProvider')
  }

  return context
}
