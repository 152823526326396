export default {
  pt: {
    ReportKilometersExport: {
      downloadText: 'Fazer Download',
    },
  },
  en: {
    ReportKilometersExport: {
      downloadText: 'Download',
    },
  },
  es: {
    ReportKilometersExport: {
      downloadText: 'Descargar',
    },
  },
}
