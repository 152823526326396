import React from 'react'
import { Helmet } from 'react-helmet'
import CreateTickets from '~/components/HelpDesk/Create'
import { ListTicketsProvider } from '~/hooks/HelpDesk/useListTickets'
import { TicketTypeProvider } from '~/hooks/HelpDesk/useTicketType'
import { TeamsProvider } from '~/hooks/Teams/useListTeams'
import { translate } from '~/utils/locale'

// import { Container } from './styles';

const NewTicket: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>{translate('HelpDeskCreatePage.helmetTitle')}</title>
      </Helmet>
      <TicketTypeProvider>
        <TeamsProvider>
          <ListTicketsProvider>
            <CreateTickets />
          </ListTicketsProvider>
        </TeamsProvider>
      </TicketTypeProvider>
    </>
  )
}

export default NewTicket
