export default {
  pt: {
    HelpDeskDetailTab: {
      createdAtLabel: 'Criado em: ',
      requestedLabel: 'Solicitado por: ',
      responsibleLabel: 'Responsável: ',
      descriptionTitle: 'Descrição',
      propertyTitle: 'Propriedades do Ticket',
      typeRequestLabel: 'Tipo de solicitação: ',
      typeRequestTitle: 'Título: ',
      typeRequestDescription: 'Descrição: ',
      assignedLabel: 'Atribuido a: ',
      originLabel: 'Origem: ',
      teamLabel: 'Equipe: ',
      pipelineLabel: 'Pipeline: ',
      statusLabel: 'Status: ',
      priorityLabel: 'Prioridade: ',
      historicTitle: 'Histórico do Ticket',
      date: 'Data do ticket: ',
    },
  },
  en: {
    HelpDeskDetailTab: {
      createdAtLabel: 'Created in: ',
      requestedLabel: 'Asked by: ',
      responsibleLabel: 'Responsible: ',
      descriptionTitle: 'Description',
      propertyTitle: 'Ticket Properties',
      typeRequestLabel: 'Ticket Type: ',
      typeRequestTitle: 'Title',
      typeRequestDescription: 'Description',
      assignedLabel: 'Assigned to: ',
      originLabel: 'Origin: ',
      teamLabel: 'Team: ',
      pipelineLabel: 'Pipeline: ',
      statusLabel: 'Status: ',
      priorityLabel: 'Priority: ',
      historicTitle: 'Ticket History',
      date: 'Date of ticket: ',
    },
  },
  es: {
    HelpDeskDetailTab: {
      createdAtLabel: 'Creado en: ',
      requestedLabel: 'Preguntado por: ',
      responsibleLabel: 'Responsable: ',
      descriptionTitle: 'Descripción',
      propertyTitle: 'Propiedades del Ticket',
      typeRequestLabel: 'Tipo de solicitud: ',
      typeRequestTitle: 'Título: ',
      typeRequestDescription: 'Descripción: ',
      assignedLabel: 'Asignado a: ',
      originLabel: 'Origen: ',
      teamLabel: 'Equipo: ',
      pipelineLabel: 'Pipeline: ',
      statusLabel: 'Status: ',
      priorityLabel: 'Prioridad: ',
      historicTitle: 'Historial del Ticket',
      date: 'Fecha del ticket: ',
    },
  },
}
