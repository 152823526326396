import { Button, Card, Result, Space } from 'antd'
import React from 'react'

import { Container } from './styles'

import CreatePayment from './Create'
import { usePayment } from '~/hooks/Payment/usePayment'
import { LoadingOutlined } from '@ant-design/icons'
import { useCompanies } from '~/hooks/Companies/useCompanies'
import { Link } from 'react-router-dom'

const PaymentBoard: React.FC = () => {
  const { payment, isLoading } = usePayment()
  const { selectedCompany } = useCompanies()

  if (isLoading || !payment) {
    return <LoadingOutlined style={{ fontSize: 64, margin: 'auto' }} spin />
  }

  const paymentId = payment.filter((p) => {
    return p.description === selectedCompany
  })

  const paymentData = paymentId[0]

  const status = localStorage.getItem('@Gstor:payment')

  return (
    <Container>
      <Card
        title={<b>Pagamento</b>}
        bordered={false}
        style={{ width: '100%', padding: 30 }}
      >
        {!paymentData ? (
          <CreatePayment />
        ) : (
          <Result
            status="success"
            title="Compra Realizada"
            subTitle="Sua assinatura foi realizada. Agora para continuar, realize o pagamento!"
            extra={[
              <Space key="1">
                {status ||
                status === 'CONFIRMED' ||
                status === 'RECEIVED' ||
                status === 'ACTIVE' ? (
                  <Button
                    type="primary"
                    key="2"
                    href={paymentData.url}
                    style={{ width: '100%' }}
                  >
                    Pagar Assinatura
                  </Button>
                ) : (
                  <Link to="pagamento/visualizar">
                    <Button
                      type="primary"
                      key="2"
                      href={paymentData.url}
                      style={{ width: '100%' }}
                    >
                      Ver Assinatura
                    </Button>
                  </Link>
                )}
              </Space>,
            ]}
          />
        )}
      </Card>
    </Container>
  )
}

export default PaymentBoard
