import { lighten } from 'polished'
import styled from 'styled-components'

export const Container = styled.div`
  .text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-content: flex-start;
    padding-bottom: 23px;

    svg {
      margin-right: 5px;
    }
  }

  #btn-edit-tasks {
    background: var(--quartenary-color);
    border: 1px solid var(--quartenary-color);

    &:hover {
      background: ${lighten(0.05, '#3969B7')};
      border: 1px solid ${lighten(0.1, '#3969B7')};
    }
  }
`
