export default {
  pt: {
    TicketTypePage: {
      helmetTitle: 'Tipos de Solicitação',
    },
  },
  en: {
    TicketTypePage: {
      helmetTitle: 'Tickets Type',
    },
  },
  es: {
    TicketTypePage: {
      helmetTitle: 'Tipos de Solicitudes',
    },
  },
}
