/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react'

import {
  Card,
  Space,
  Button,
  Form,
  Input,
  Row,
  Col,
  Checkbox,
  InputNumber,
  Tour,
} from 'antd'

import { SaveOutlined, ArrowLeftOutlined } from '@ant-design/icons'

import { Link, useHistory } from 'react-router-dom'

import { MaskedInput } from 'antd-mask-input'
import { useTaskType } from '~/hooks/Tasks/TaskType/useTaskType'
import { translate } from '~/utils/locale'
import HelpTooltip from '~/components/DataManipulation/Misc/HelpTooltip'
import { useTaskTypeTour } from '~/hooks/Tour/useTaskTypeTour'
import { useTour } from '~/hooks/Tour/useTour'

import { Container } from './styles'

interface TaskTypeData {
  description: string
  tolerance_time: string
  runtime: string
  value: string
}

const CreateTasks: React.FC = () => {
  const { createTaskType } = useTaskType()
  const {
    createTitle,
    createValue,
    createSLA,
    createExecutationTime,
    createDescription,
    saveTaskType,
    createTaskTypeSteps,
    openCreateTaskType,
    setOpenCreateTaskType,
    setOpenListTaskType,
  } = useTaskTypeTour()

  const { isTour, setIsTour } = useTour()

  const history = useHistory()

  const [form] = Form.useForm()

  const [keepRegister, setKeepRegister] = useState(false)

  function onCreateTaskType(data: TaskTypeData) {
    try {
      if (data.runtime) {
        data.runtime = `${data.runtime}:00`
      }

      if (data.tolerance_time) {
        data.tolerance_time = `${data.tolerance_time}:00`
      }

      createTaskType(data)

      form.resetFields()

      if (!keepRegister) {
        history.push('/tiposDeTarefas')
      }
      setOpenListTaskType(!!isTour)
    } catch (err) {
      console.log(err)
    }
  }

  function onClose() {
    setOpenCreateTaskType(false)
    setIsTour(false)
  }

  const [value, setValue] = useState('')
  const [receive, setReceive] = useState('')

  const formatMoney = (inputValue: any) => {
    let cleanValue = inputValue.replace(/\D/g, '')

    cleanValue = cleanValue.padStart(3, '0')

    const regex = /(\d+)(\d{2})$/
    const match = cleanValue.match(regex)

    if (match) {
      let inteiro = match[1]
      const centavos = match[2]

      inteiro = inteiro.replace(/\B(?=(\d{3})+(?!\d))/g, '.')

      const formattedValue = `${inteiro},${centavos}`

      return formattedValue
    }

    return inputValue
  }

  const handleChange = (value: any) => {
    const inputValue = value ? value.toString() : ''
    const formattedValue = formatMoney(inputValue)
    setValue(formattedValue)
  }

  const handleChangeReceive = (value: any) => {
    const inputValue = value ? value.toString() : ''
    const formattedValue = formatMoney(inputValue)
    setReceive(formattedValue)
  }

  return (
    <Container>
      <Card
        title={
          <Space size="large">
            <Link to="/tiposDeTarefas">
              <ArrowLeftOutlined />
            </Link>
            <b>{translate('TasksRegistrationTypeTasksCreate.cardTitle')}</b>
          </Space>
        }
        extra={
          <Space size="middle">
            <Checkbox
              onChange={(e) => {
                setKeepRegister(e.target.checked)
              }}
              checked={keepRegister}
            >
              {translate('TasksRegistrationTypeTasksCreate.keepRegisterText')}
            </Checkbox>
            <Button
              type="primary"
              icon={<SaveOutlined />}
              htmlType="submit"
              form="taskType"
              ref={saveTaskType}
              id="btn_create_typeTask"
            >
              {translate('TasksRegistrationTypeTasksCreate.registerButton')}
            </Button>
          </Space>
        }
        bordered={false}
        style={{ width: '100%', padding: 30 }}
      >
        <Form
          layout="vertical"
          onFinish={onCreateTaskType}
          id="taskType"
          form={form}
        >
          <Row gutter={12}>
            <Col span={12} ref={openCreateTaskType ? createTitle : null}>
              <Form.Item
                label={translate('TasksRegistrationTypeTasksCreate.titleLabel')}
                name="title"
                rules={[
                  {
                    required: true,
                    message: translate(
                      'TasksRegistrationTypeTasksCreate.titleRule',
                    ),
                  },
                ]}
              >
                <Input
                  placeholder={translate(
                    'TasksRegistrationTypeTasksCreate.titlePlaceholder',
                  )}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={12}>
            <Col span={12} ref={createSLA}>
              <Form.Item
                label={
                  <Space>
                    {translate(
                      'TasksRegistrationTypeTasksCreate.toleranceTimeLabel',
                    )}
                    <HelpTooltip
                      title={translate(
                        'TasksRegistrationTypeTasksCreate.toleranceTimeTooltip',
                      )}
                    />
                  </Space>
                }
                name="tolerance_time"
                rules={[
                  {
                    required: true,
                    message: translate(
                      'TasksRegistrationTypeTasksCreate.toleranceTimeRule',
                    ),
                  },
                ]}
              >
                <MaskedInput mask={'00:00'} />
              </Form.Item>
            </Col>
            <Col span={12} ref={createExecutationTime}>
              <Form.Item
                label={
                  <Space>
                    {translate('TasksRegistrationTypeTasksCreate.runtimeLabel')}
                    <HelpTooltip
                      title={translate(
                        'TasksRegistrationTypeTasksCreate.runtimeTooltip',
                      )}
                    />
                  </Space>
                }
                name="runtime"
                rules={[
                  {
                    required: true,
                    message: translate(
                      'TasksRegistrationTypeTasksCreate.runtimeRule',
                    ),
                  },
                ]}
              >
                <MaskedInput mask={'00:00'} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={12}>
            <Col span={12} ref={createValue}>
              <Form.Item
                label={
                  <Space>
                    {translate(
                      'TasksRegistrationTypeTasksCreate.valueTaskLabel',
                    )}
                    <HelpTooltip
                      title="Esta informação será utilizada 
                    para calcular os valores a receber dos clientes principais 
                    que não possuem valores acordados em seu cadastro."
                    />
                  </Space>
                }
                name="amount_to_pay"
                rules={[
                  {
                    required: true,
                    message: translate(
                      'TasksRegistrationTypeTasksCreate.valueTaskRule',
                    ),
                  },
                ]}
              >
                <InputNumber
                  style={{ width: '100%' }}
                  value={value}
                  onChange={handleChange}
                  formatter={formatMoney}
                  parser={(value) => value?.replace(/\D/g, '')}
                  placeholder={translate(
                    'TasksRegistrationTypeTasksCreate.valueTaskPlaceholder',
                  )}
                />
              </Form.Item>
            </Col>

            <Col span={12} ref={createValue}>
              <Form.Item
                label={
                  <Space>
                    {translate(
                      'TasksRegistrationTypeTasksCreate.value_received_TaskLabel',
                    )}
                    <HelpTooltip
                      title="Esta informação será utilizada 
                    para calcular os valores a pagar dos colaboradores 
                    que não possuem valores acordados em equipes."
                    />
                  </Space>
                }
                name="amount_to_receive"
                rules={[
                  {
                    required: true,
                    message: translate(
                      'TasksRegistrationTypeTasksCreate.value_received_TaskPlaceholder',
                    ),
                  },
                ]}
              >
                <InputNumber
                  style={{ width: '100%' }}
                  value={receive}
                  onChange={handleChangeReceive}
                  formatter={formatMoney}
                  parser={(value) => value?.replace(/\D/g, '')}
                  placeholder={translate(
                    'TasksRegistrationTypeTasksCreate.value_received_TaskPlaceholder',
                  )}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row ref={createDescription}>
            <Col span={24}>
              <Form.Item
                label={translate(
                  'TasksRegistrationTypeTasksCreate.descriptionLabel',
                )}
                name="description"
              >
                <Input.TextArea
                  placeholder={translate(
                    'TasksRegistrationTypeTasksCreate.descriptionPlaceholder',
                  )}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>

        <Tour
          open={openCreateTaskType}
          onClose={onClose}
          steps={createTaskTypeSteps}
          indicatorsRender={(current, total) => (
            <span>
              {current + 1} / {total}
            </span>
          )}
        />
      </Card>
    </Container>
  )
}

export default CreateTasks
