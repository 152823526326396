/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable consistent-return */
import React, { useContext, createContext } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'

import api from '~/services/api'

import { useCompanies } from '~/hooks/Companies/useCompanies'
import { useLocale } from '~/hooks/locale/useLocale'

import { ShowError } from '~/utils/errors/apiErrors'
import { translate } from '~/utils/locale'

interface UserGroup {
  id: string
  user: {
    id: string
    contact: {
      id: string
      name: string
      collaborator: {
        id: string
      }
    }
  }
}

interface Permissions {
  id: string
  create: boolean
  delete: boolean
  edit: boolean
  view: boolean
  module: {
    id: string
    name: string
  }
}

interface GroupProps {
  name: string
  id: string
  type: string
  created_at: string
  userGroups: UserGroup[]
  permissions: Permissions[]
}

interface GroupContextData {
  groups?: GroupProps
  isLoading: boolean
  refetch: () => void
}

const GroupContext = createContext<GroupContextData>({} as GroupContextData)

export const GroupProvider: React.FC = ({ children }) => {
  const { selectedCompany } = useCompanies()
  const { locale } = useLocale()
  const { groupId } = useParams<{ groupId: string }>()

  const {
    data: groups,
    isLoading,
    refetch,
  } = useQuery(
    [`Determinado grupo${selectedCompany}`, selectedCompany],

    async () => {
      try {
        const response = await api.get(
          `/company/${selectedCompany}/group/${groupId}`,
        )

        const { data } = response

        return data
      } catch (err: any) {
        ShowError(err.message, translate('useGroup.getErrorMessage'), locale)
      }
    },
  )

  return (
    <GroupContext.Provider
      value={{
        groups,
        isLoading,
        refetch,
      }}
    >
      {children}
    </GroupContext.Provider>
  )
}

export function useGroup(): GroupContextData {
  const context = useContext(GroupContext)

  if (!context) {
    throw new Error('useAuth must be used within an AutoProvider')
  }

  return context
}
