export default {
  pt: {
    ClientsUpdateCharge: {
      cardTitle: 'Editar Informações de Cobrança',
      saveButton: 'Salvar',
      cancelButton: 'Cancelar',
      emailLabel: 'E-mail de cobrança',
      icmsLabel: 'Imposto sobre Circulação de Mercadorias e Serviços',
      icmsCheckbox: 'Você é contribuinte do ICMS?',
      municipalRegistrationLabel: 'Inscrição Municipal',
      stateRegistrationLabel: 'Inscrição Estadual',
    },
  },
  en: {
    ClientsUpdateCharge: {
      cardTitle: 'Edit Billing Information',
      saveButton: 'Save',
      cancelButton: 'Cancel',
      emailLabel: 'Billing email',
      icmsLabel: 'Tax on the Circulation of Goods and Services',
      icmsCheckbox: 'Are you an ICMS taxpayer?',
      municipalRegistrationLabel: 'Municipal Registration',
      stateRegistrationLabel: 'State Registration',
    },
  },
  es: {
    ClientsUpdateCharge: {
      cardTitle: 'Editar información de facturación',
      saveButton: 'Guardar',
      cancelButton: 'Cancelar',
      emailLabel: 'Correo Electrónico de Facturas',
      icmsLabel: 'Impuesto sobre la Circulación de Bienes y Servicios',
      icmsCheckbox: '¿Eres contribuyente del ICMS?',
      municipalRegistrationLabel: 'Registro Municipal',
      stateRegistrationLabel: 'Registro Estadual',
    },
  },
}
