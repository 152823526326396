export default {
  pt: {
    useTeams: {
      getErrorMessage: 'Erro ao visualizar equipe.',
      createSuccessMessage: 'Colaborador adicionado com sucesso!',
      createErrorMessage: 'Erro ao adicionar.',
      updateSuccessMessage: 'Equipe atualizada com sucesso!',
      updateErrorMessage: 'Erro ao atualizar equipe.',
      deleteSuccessMessage: 'Colaborador deletado da equipe com sucesso!',
      deleteErrorMessage: 'Erro ao remover.',
    },
  },
  en: {
    useTeams: {
      getErrorMessage: 'Error viewing team.',
      createSuccessMessage: 'Contributor added successfully!',
      createErrorMessage: 'Error adding.',
      updateSuccessMessage: 'Team successfully updated!',
      updateErrorMessage: 'Error updating team.',
      deleteSuccessMessage: 'Contributor deleted from team successfully!',
      deleteErrorMessage: 'Error removing.',
    },
  },
  es: {
    useTeams: {
      getErrorMessage: 'Error al ver el equipo.',
      createSuccessMessage: '¡Contribuidor agregado con éxito!',
      createErrorMessage: 'Error al agregar.',
      updateSuccessMessage: '¡Equipo actualizado con éxito!',
      updateErrorMessage: 'Error al actualizar el equipo.',
      deleteSuccessMessage: '¡Contribuidor eliminado del equipo con éxito!',
      deleteErrorMessage: 'Error al eliminar.',
    },
  },
}
