export default {
  pt: {
    TasksOptionsCalendar: {
      dateString: 'às',
    },
  },
  en: {
    TasksOptionsCalendar: {
      dateString: 'at',
    },
  },
  es: {
    TasksOptionsCalendar: {
      dateString: 'a las',
    },
  },
}
