/* eslint-disable camelcase */
import React, { useState } from 'react'

import { Tooltip, Button, Modal, Form, Input } from 'antd'
import { EditOutlined } from '@ant-design/icons'

import { useListGroup } from '~/hooks/Groups/useListGroup'
import { useGroup } from '~/hooks/Groups/useGroup'
import { translate } from '~/utils/locale'

interface EditGroupData {
  id_group: string
  name_group: string
}

const EditGroup: React.FC<EditGroupData> = ({ id_group, name_group }) => {
  const { updateGroup, listGroupsRefetch } = useListGroup()
  const { refetch } = useGroup()
  const [isModalVisible, setIsModalVisible] = useState(false)

  async function onFormEditGroup(valueForm: object) {
    try {
      await updateGroup(valueForm, id_group)
      refetch()
      listGroupsRefetch()
      setIsModalVisible(false)
    } catch (err) {
      console.log(err)
    }
  }

  const showModal = () => {
    setIsModalVisible(true)
  }

  const closeModal = () => {
    setIsModalVisible(false)
  }

  return (
    <>
      <Tooltip
        title={translate('GroupsUpdateGroup.editTooltip')}
        key="tool-new"
      >
        <Button onClick={showModal} icon={<EditOutlined />} type="primary">
          {translate('GroupsUpdateGroup.editButton')}
        </Button>
      </Tooltip>

      <Modal
        title={translate('GroupsUpdateGroup.modalTitle')}
        open={isModalVisible}
        onOk={closeModal}
        onCancel={closeModal}
        footer={[
          <Button key="close" onClick={closeModal}>
            {translate('GroupsUpdateGroup.closeButton')}
          </Button>,
          <Button
            form="editGroupForm"
            key="save"
            type="primary"
            htmlType="submit"
          >
            {translate('GroupsUpdateGroup.saveButton')}
          </Button>,
        ]}
      >
        <Form
          layout="vertical"
          id="editGroupForm"
          initialValues={{ name: name_group }}
          onFinish={onFormEditGroup}
        >
          <Form.Item
            label={translate('GroupsUpdateGroup.groupNameLabel')}
            name="name"
            rules={[
              {
                required: true,
                message: translate('GroupsUpdateGroup.groupNameRule'),
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default EditGroup
