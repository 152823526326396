export default {
  pt: {
    AttachmentsModal: {
      successMessage: 'Documento/Anexo enviado com sucesso!',
      errorMessage: 'Erro ao realizar cadastro',
      modalButtonText: 'Novo Documento/Anexo',
      modalTitle: 'Novo Documento/Anexo',
      keepRegisterText: 'Continuar enviando documentos/anexos?',
      cancelText: 'Cancelar',
      finishText: 'Enviar',
      titleLabel: 'Título',
      titleRule: 'Por favor insira um titulo para o documento/anexo.',
      descriptionLabel: 'Descrição',
      descriptionRule: 'Por favor insira uma descrição para o documento/anexo.',
      draggerRule: 'Por favor selecione um arquivo',
      draggerInstructionOne: 'Clique ou arraste arquivos aqui para Enviar',
      draggerInstructionTwo: 'Apenas um arquivo por vez.',
    },
  },
  en: {
    AttachmentsModal: {
      successMessage: 'Document/Attachment sent successfully!',
      errorMessage: 'Error when registering',
      modalButtonText: 'New Document/Attachment',
      modalTitle: 'New Document/Attachment',
      keepRegisterText: 'Continue sending documents/attachments?',
      cancelText: 'Cancel',
      finishText: 'Submit',
      titleLabel: 'Title',
      titleRule: 'Please enter a title for the document/attachment.',
      descriptionLabel: 'Description',
      descriptionRule:
        'Please enter a description for the document/attachment.',
      draggerRule: 'Please select a file',
      draggerInstructionOne: 'Click or drag files here to Upload',
      draggerInstructionTwo: 'Only one file at a time.',
    },
  },
  es: {
    AttachmentsModal: {
      successMessage: '¡Documento/Adjunto enviado con éxito!',
      errorMessage: 'Error al registrarse',
      modalButtonText: 'Nuevo documento/archivo adjunto',
      modalTitle: 'Nuevo documento/archivo adjunto',
      keepRegisterText: '¿Continuar enviando documentos/adjuntos?',
      cancelText: 'Cancelar',
      finishText: 'Enviar',
      titleLabel: 'Título',
      titleRule: 'Introduzca un título para el documento/archivo adjunto.',
      descriptionLabel: 'Descripción',
      descriptionRule:
        'Ingrese una descripción para el documento/archivo adjunto.',
      draggerRule: 'Seleccione un archivo',
      draggerInstructionOne:
        'Haga clic o arrastre los archivos aquí para cargar',
      draggerInstructionTwo: 'Solo un archivo a la vez.',
    },
  },
}
