export default {
  pt: {
    GroupsCreateUserGroup: {
      addTooltip: 'Novo Usuário',
      addButton: 'Adicionar usuário',
      modalTitle: 'Novo Usuário',
      keepAddText: 'Continuar adicionando usuários?',
      closeButton: 'Fechar',
      saveButton: 'Salvar',
      userLabel: 'Usuário',
      userPlaceholder: 'Selecione o usuário',
    },
  },
  en: {
    GroupsCreateUserGroup: {
      addTooltip: 'New User',
      addButton: 'Add User',
      modalTitle: 'New User',
      keepAddText: 'Continue adding users?',
      closeButton: 'Close',
      saveButton: 'Save',
      userLabel: 'User',
      userPlaceholder: 'Select User',
    },
  },
  es: {
    GroupsCreateUserGroup: {
      addTooltip: 'Nuevo Usuario',
      addButton: 'Agregar usuario',
      modalTitle: 'Nuevo Usuario',
      keepAddText: '¿Continuar agregando usuarios?',
      closeButton: 'Cerrar',
      saveButton: 'Guardar',
      userLabel: 'Usuario',
      userPlaceholder: 'Seleccionar usuario',
    },
  },
}
