/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'

import { Card, Col, Row, Form, Input, Button, Space, Checkbox } from 'antd'

import { Container, ContainerButton } from './styles'

import { useClients } from '~/hooks/Clients/useClients'
import { translate } from '~/utils/locale'

const ChargeUpdate: React.FC = () => {
  const { clients, setIsEdit, updateClient } = useClients()

  const initialData = {
    billing_email: clients?.billing_email,
    icms_taxpayer: clients?.icms_taxpayer,
    municipal_registration: clients?.municipal_registration,
    state_registration: clients?.state_registration,
  }

  function onUpdateClient(valueForm: any) {
    try {
      updateClient(valueForm)
      setIsEdit(false)
    } catch (err) {
      setIsEdit(true)
    }
  }

  return (
    <Card
      bordered={false}
      title={translate('ClientsUpdateCharge.cardTitle')}
      style={{
        display: 'inline-block',
        width: '100%',
      }}
      extra={
        <Space size="middle">
          <ContainerButton>
            <Button
              htmlType="submit"
              form="onUpdateChargeForm"
              type="primary"
              id="btn_save_charge_client"
            >
              {translate('ClientsUpdateCharge.saveButton')}
            </Button>
          </ContainerButton>

          <Button onClick={() => setIsEdit(false)}>
            {translate('ClientsUpdateCharge.cancelButton')}
          </Button>
        </Space>
      }
    >
      <Container>
        <Form
          layout="vertical"
          id="onUpdateChargeForm"
          onFinish={onUpdateClient}
          initialValues={initialData}
        >
          <Row gutter={16}>
            <Col span={10}>
              <Form.Item
                label={translate('ClientsUpdateCharge.emailLabel')}
                name="billing_email"
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item
                label={translate('ClientsUpdateCharge.icmsLabel')}
                name="icms_taxpayer"
                valuePropName="checked"
              >
                <Checkbox>
                  {translate('ClientsUpdateCharge.icmsCheckbox')}
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={10}>
              <Form.Item
                label={translate(
                  'ClientsUpdateCharge.municipalRegistrationLabel',
                )}
                name="municipal_registration"
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item
                label={translate('ClientsUpdateCharge.stateRegistrationLabel')}
                name="state_registration"
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Container>
    </Card>
  )
}

export default ChargeUpdate
