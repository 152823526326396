export default {
  pt: {
    ClientsPrincipalTabsClient: {
      clientTitleModal: 'Vincular clientes filhos',
      undefinedText: 'Nenhum cliente associado.',
      okText: 'Deseja vincular os clientes?',
      saveButton: 'Salvar',
    },
  },
  en: {
    ClientsPrincipalTabsClient: {
      clientTitleModal: 'Link child clients',
      undefinedText: 'No associated customers.',
      okText: 'Want to link customers?',
      saveButton: 'Save',
    },
  },
  es: {
    ClientsPrincipalTabsClient: {
      clientTitleModal: 'Vincular clientes niños',
      undefinedText: 'Sin clientes asociados.',
      okText: '¿Quieres vincular clientes?',
      saveButton: 'Guardar',
    },
  },
}
