/* eslint-disable react/jsx-curly-newline */
import {
  FileUnknownOutlined,
  LoadingOutlined,
  PlusOutlined,
} from '@ant-design/icons'
import { Button, Modal, Popconfirm, Space, Tooltip, Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import Paragraph from 'antd/lib/typography/Paragraph'
import Text from 'antd/lib/typography/Text'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { encode } from 'base-64'
import { useListClients } from '~/hooks/Clients/useListClients'
import { useCompanies } from '~/hooks/Companies/useCompanies'
import { useListTickets } from '~/hooks/HelpDesk/useListTickets'
import { translate } from '~/utils/locale'

// import { Container } from './styles';

interface ClientsProps {
  id: string
  company?: {
    name: string
  }
  contact?: {
    name: string
    email: string
    phone?: string
    id: string
  }
}

const LinkHelpDesk: React.FC = () => {
  const { selectedCompany } = useCompanies()

  const { clients, isFetching, clientsTotal } = useListClients()
  const { clientData, setClientData } = useListTickets()

  const [link, setLink] = useState(false)

  const [isModalVisible, setIsModalVisible] = useState(false)

  async function handleClientTicket(
    id: string,
    name: string,
    email: string,
    phone: string,
    companyName: string,
  ) {
    await setClientData({ id, name, email, phone, companyName })
  }

  const nameEncoded = encode(clientData?.name ? clientData.name : 'null')
  const emailEncoded = encode(clientData?.email ? clientData.email : 'null')
  const phoneEncoded = encode(clientData?.phone ? clientData.phone : 'null')
  const companyNameEncoded = encode(
    clientData?.companyName ? clientData.companyName : 'null',
  )

  const showModal = () => {
    setIsModalVisible(true)
  }

  const closeModal = () => {
    setIsModalVisible(false)
  }

  const columns: ColumnsType<ClientsProps> = [
    {
      title: translate('ClientsTable.columnActions'),
      align: 'center',
      key: 'remove',
      render: (record) => (
        <Popconfirm
          title={translate('TasksCreateModalClient.addClientPopconfirm')}
          onConfirm={() =>
            handleClientTicket(
              record.id,
              record.contact.name,
              record.contact.email,
              record.contact.phone,
              record.company.name,
            )
          }
        >
          <Tooltip title={translate('TasksCreateModalClient.addClientTooltip')}>
            <Button type="primary" shape="circle" icon={<PlusOutlined />} />
          </Tooltip>
        </Popconfirm>
      ),
    },
    {
      title: translate('ClientsTable.columnName'),
      align: 'left',
      dataIndex: ['contact', 'name'],
      key: 'name',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (name: string, record) => (
        <Tooltip title={translate('TasksCreateModalClient.viewClientTooltip')}>
          <Link
            to={`/clientes/visualizar/${record.id}/contato/${record.contact?.id}/`}
          >
            {name}
          </Link>
        </Tooltip>
      ),
    },
    {
      title: translate('ClientsTable.columnResponsible'),
      dataIndex: ['collaborator', 'contact', 'name'],
      align: 'center',
      key: 'contact',
      render: (contact: string) => (
        <>
          {contact || (
            <Text type="secondary">
              <Space size="small">
                <FileUnknownOutlined />
                N/A
              </Space>
            </Text>
          )}
        </>
      ),
    },
    {
      title: translate('ClientsTable.columnEmail'),
      align: 'center',
      key: 'email',
      dataIndex: ['contact', 'email'],
    },
  ]

  return (
    <>
      <Button onClick={showModal}>{translate('HelpDesk.shareLink')}</Button>

      <Modal
        title={translate('HelpDesk.linkText')}
        open={isModalVisible}
        onOk={closeModal}
        onCancel={closeModal}
        width="900px"
        style={{
          top: 60,
          overflowY: 'scroll',
          maxHeight: 'calc(100vh - 200px)',
        }}
        footer={[
          <Button key="back" onClick={closeModal}>
            {translate('TasksRegistrationPauseCreate.closeButton')}
          </Button>,
        ]}
      >
        <p style={{ marginBottom: 15 }}>{translate('HelpDesk.clientText')}</p>
        <Table
          rowKey={(record) => record.id}
          loading={{
            indicator: <LoadingOutlined />,
            spinning: isFetching,
            size: 'large',
          }}
          pagination={{
            total: clientsTotal,
            showSizeChanger: true,
            pageSizeOptions: ['10', '20', '50', '100', '200'],
            defaultPageSize: 10,
            showTotal: (total, range) => `${range[0]} - ${range[1]} / ${total}`,
          }}
          columns={columns}
          dataSource={clients}
          scroll={{ x: 1000 }}
          size="small"
        />

        {clientData?.id ? (
          <>
            {link ? (
              <Paragraph copyable>
                {`https://app.gstor.com.br/novoTicket/${selectedCompany}/client/${nameEncoded}/${emailEncoded}/${phoneEncoded}/${companyNameEncoded}`}
              </Paragraph>
            ) : (
              <Button type="primary" onClick={() => setLink(true)}>
                {translate('HelpDesk.buttonLink')}
              </Button>
            )}
          </>
        ) : (
          ''
        )}
      </Modal>
    </>
  )
}

export default LinkHelpDesk
