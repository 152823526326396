export default {
  pt: {
    ClientParentTabs: {
      pageHeaderTitleVisualization: 'Visualizar Cliente',
      pageHeaderTitleUpdate: 'Editar Cliente',
      detailsTab: 'Detalhes',
      financialTab: 'Financeiro',
      childrenClientsTab: 'Clientes Filhos',
    },
  },
  en: {
    ClientParentTabs: {
      pageHeaderTitleVisualization: 'View Customer',
      pageHeaderTitleUpdate: 'Edit Customer',
      detailsTab: 'Details',
      financialTab: 'Financial',
      childrenClientsTab: 'Children Customers',
    },
  },
  es: {
    ClientParentTabs: {
      pageHeaderTitleVisualization: 'Ver cliente',
      pageHeaderTitleUpdate: 'Editar cliente',
      detailsTab: 'Detalles',
      financialTab: 'Financiero',
      childrenClientsTab: 'Clientes niños',
    },
  },
}
