/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react'
import { Card, Container, Content, User } from './styles'
import { Tooltip, Upload, message } from 'antd'
import { LoadingOutlined, PlusOutlined, UserOutlined } from '@ant-design/icons'
import QRCode from 'qrcode.react'
import { useCompanies } from '~/hooks/Companies/useCompanies'
import { useAuth } from '~/hooks/Auth/AuthContext'
import { translate } from '~/utils/locale'
import ImgCrop from 'antd-img-crop'

interface BadgeProps {
  description: string
  name: string
  _id: string
  colorBackground?: string
  colorCover?: string
  colorStrongText?: string
  colorText?: string
  template: string
  logoUrl: string
}

interface BadgeData {
  data: BadgeProps
}

const Template03: React.FC<BadgeData> = ({ data }) => {
  const [image, setImage] = useState()
  const [loading, setLoading] = useState(false)
  const { token } = useAuth()
  const { selectedCompany } = useCompanies()

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  )

  function getBase64(img: any, callback: any) {
    const reader = new FileReader()
    reader.addEventListener('load', () => callback(reader.result))
    reader.readAsDataURL(img)
  }

  function beforeUpload(file: File) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isJpgOrPng) {
      message.error(translate('EquipmentsUpdateDetails.uploadArchiveTypeError'))
    }
    const isLt2M = file.size / 1024 / 1024 < 2
    if (!isLt2M) {
      message.error(translate('BadgeComponent.errorImage'))
    }
    return isJpgOrPng && isLt2M
  }

  const handleChange = (info: any) => {
    if (info.file.status === 'uploading') {
      setLoading(true)
      return
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (imageUrl: any) => {
        setImage(imageUrl)
        setLoading(false)
      })

      message.success(translate('BadgeComponent.sucessImage'))
    }

    localStorage.setItem('removeImage', 'image')
  }

  const props = {
    accept: '.jpeg,.png',
    name: 'logo',
    action: `${process.env.REACT_APP_API}company/${selectedCompany}/badge/${data?._id}/logo`,
    headers: {
      authorization: `Bearer ${token}`,
      contentType: 'multipart/form-data',
    },
  }

  return (
    <Container>
      <Card colorBackground={data.colorBackground}>
        <Tooltip title={translate('BadgeComponent.imageTooltipText')}>
          <User>
            <UserOutlined size={20} />
          </User>
        </Tooltip>

        <Content
          colorStrongText={data.colorStrongText}
          colorText={data.colorText}
        >
          <div className="text">
            <h2>{translate('BadgeComponent.colaboradorText')}</h2>
            <p>{translate('BadgeComponent.companyText')}</p>
          </div>

          <div className="text">
            <h3>{translate('BadgeComponent.serviceText')}</h3>
            {data.logoUrl ? (
              <img src={data.logoUrl} alt="avatar" style={{ width: '35%' }} />
            ) : (
              <ImgCrop rotationSlider>
                <Upload
                  {...props}
                  method="PATCH"
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  beforeUpload={beforeUpload}
                  onChange={handleChange}
                >
                  {image ? (
                    <img src={image} alt="avatar" style={{ width: '100%' }} />
                  ) : (
                    uploadButton
                  )}
                </Upload>
              </ImgCrop>
            )}
          </div>

          <div className="text">
            <Tooltip title="Qrcode">
              <QRCode value="" size={110} style={{ marginTop: '0.3rem' }} />
            </Tooltip>
          </div>
        </Content>
      </Card>
    </Container>
  )
}

export default Template03
