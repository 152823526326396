export default {
  pt: {
    ClientsUpdate: {
      randomEmailError: 'Preencha o campo nome para gerar um email aleatório',
      cnpjInUseMessage:
        'Este CNPJ já se encontra em uso, isso não o impede de atualizar o cliente.',
      backTooltip: 'Voltar',
      cardTitle: 'Editar Cliente',
      saveButton: 'Salvar',
      cancelButton: 'Cancelar',
      nameLabel: 'Nome',
      nameRule: 'Por favor informe o nome do cliente',
      emailLabel: 'E-mail',
      emailGenerateTooltip: 'Gerar email aleatório falso',
      emailRuleOne: 'Insira um email válido',
      emailRuleTwo: 'Por favor informe o e-mail do cliente',
      personTypeLabel: 'Pessoa',
      personTypePlaceholder: 'Selecione o tipo',
      physicalPerson: 'Pessoa Física',
      juridicPerson: 'Pessoa Jurídica',
      foreign: 'Estrangeiro',
      cpfLabel: 'CPF',
      passportLabel: 'Passaporte',
      documentRuleOne: 'Por gentileza insira o CPF',
      documentRuleTwo: 'Por gentileza insira um CPF Válido!',
      cnpjLabel: 'CNPJ',
      cnpjRuleOne: 'Por favor digite o CNPJ',
      cnpjRuleTwo: 'Por gentileza insira um CNPJ Válido!',
      idLabel: 'RG',
      phoneLabel: 'Telefone',
      phoneRule: 'Insira um número válido.',
      phonePlaceholder: 'Telefone do Cliente',
      corporateNameLabel: 'Razão Social',
      segmentLabel: 'Segmentos',
      externalCodeLabel: 'Código Externo',
      responsibleLabel: 'Responsável',
      observationLabel: 'Observação',
    },
  },
  en: {
    ClientsUpdate: {
      randomEmailError: 'Fill in the name field to generate a random email',
      cnpjInUseMessage:
        'This CNPJ is already in use, this does not prevent you from updating the client.',
      backTooltip: 'Back',
      cardTitle: 'Edit Customer',
      saveButton: 'Save',
      cancelButton: 'Cancel',
      nameLabel: 'Name',
      nameRule: "Please enter the customer's name",
      emailLabel: 'Email',
      emailGenerateTooltip: 'Generate fake random email',
      emailRuleOne: 'Enter a valid email',
      emailRuleTwo: "Please provide the customer's email",
      personTypeLabel: 'Person',
      personTypePlaceholder: 'Select the type',
      physicalPerson: 'Physical Person',
      juridicPerson: 'Juridic Person',
      foreign: 'Foreign',
      cpfLabel: 'CPF',
      passportLabel: 'Passaport',
      documentRuleOne: 'Please enter your CPF',
      documentRuleTwo: 'Please enter a Valid CPF!',
      cnpjLabel: 'CNPJ',
      cnpjRuleOne: 'Please enter the CNPJ',
      cnpjRuleTwo: 'Please enter a Valid CNPJ!',
      idLabel: 'ID',
      phoneLabel: 'Phone',
      phoneRule: 'Please enter a valid number.',
      phonePlaceholder: 'Customer phone',
      corporateNameLabel: 'Social Reason',
      segmentLabel: 'Segments',
      externalCodeLabel: 'External Code',
      responsibleLabel: 'Responsible',
      observationLabel: 'Observation',
    },
  },
  es: {
    ClientsUpdate: {
      randomEmailError:
        'Complete el campo de nombre para generar un correo electrónico aleatorio',
      cnpjInUseMessage:
        'Este CNPJ ya está en uso, esto no impide que puedas actualizar el cliente.',
      backTooltip: 'Regresar',
      cardTitle: 'Editar Cliente',
      saveButton: 'Guardar',
      cancelButton: 'Cancelar',
      nameLabel: 'Nombre',
      nameRule: 'Por favor ingrese el nombre del cliente',
      emailLabel: 'Correo electrónico',
      emailGenerateTooltip: 'Generar correo electrónico aleatorio falso',
      emailRuleOne: 'Introduzca un correo electrónico válido',
      emailRuleTwo: 'Por favor proporcione el correo electrónico del cliente',
      personTypeLabel: 'Pessoa',
      personTypePlaceholder: 'Seleccione  tipo',
      physicalPerson: 'Pessoa Física',
      juridicPerson: 'Pessoa Jurídica',
      foreign: 'Extranjero',
      cpfLabel: 'CPF',
      passportLabel: 'Pasaporte',
      documentRuleOne: 'Por favor ingrese su CPF',
      documentRuleTwo: '¡Ingrese un CPF válido!',
      cnpjLabel: 'CNPJ',
      cnpjRuleOne: 'Por favor ingrese el CNPJ',
      cnpjRuleTwo: '¡Ingrese un CNPJ válido!',
      idLabel: 'ID',
      phoneLabel: 'Teléfono',
      phoneRule: 'Por favor ingrese un número valido.',
      phonePlaceholder: 'Teléfono del Cliente',
      corporateNameLabel: 'Razón social',
      segmentLabel: 'Segmentos',
      externalCodeLabel: 'Código externo',
      responsibleLabel: 'Responsable',
      observationLabel: 'Observación',
    },
  },
}
