export default {
  pt: {
    UsersGroup: {
      groupTooltip: 'Adicionar grupo',
      groupButton: 'Grupos',
      modalTitle: 'Adicionar grupo',
      closeButton: 'Fechar',
      addButton: 'Adicionar',
      groupLabel: 'Selecione o grupo',
      groupPlaceholder: 'Informe o grupo',
    },
  },
  en: {
    UsersGroup: {
      groupTooltip: 'Add group',
      groupButton: 'Groups',
      modalTitle: 'Add group',
      closeButton: 'Close',
      addButton: 'Add',
      groupLabel: 'Select the group',
      groupPlaceholder: 'Inform the group',
    },
  },
  es: {
    UsersGroup: {
      groupTooltip: 'Agregar grupo',
      groupButton: 'Grupos',
      modalTitle: 'Agregar grupo',
      closeButton: 'Cerrar',
      addButton: 'Agregar',
      groupLabel: 'Seleccione el grupo',
      groupPlaceholder: 'Informar al grupo',
    },
  },
}
