/* eslint-disable camelcase */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  Typography,
  Space,
  Table,
  Button,
  Tooltip,
  Popconfirm,
  message,
} from 'antd'
import {
  DeleteFilled,
  FileUnknownOutlined,
  LoadingOutlined,
  ProfileOutlined,
} from '@ant-design/icons'

import { ColumnsType } from 'antd/lib/table'
import React, { useState } from 'react'

import { Link } from 'react-router-dom'

import { translate } from '~/utils/locale'
import { useListParentClients } from '~/hooks/Clients/useListParentCustomer'
import NumberFormat from 'react-number-format'

interface ContactProps {
  name: string
  email: string
  celular: string
  phone: string
  _id: string
}

export interface ParentClientsProps {
  _id: string
  cnpj: string
  cpf: string
  fantasyName: string
  companyName: string
  contact: ContactProps
}

const ParentClientsTable: React.FC = () => {
  const {
    parentClients,
    isFetching,
    deleteParentClient,
    deleteParentClients,
    setNumberPage,
    setPageSize,
    clientsTotal,
  } = useListParentClients()
  const { Text } = Typography

  const [isDelete, setIsDelete] = useState(false)
  const [clientsId, setClientsId] = useState<React.Key[]>([])

  const columns: ColumnsType<ParentClientsProps> = [
    {
      title: translate('ClientsParentTable.reasonColumn'),
      align: 'left',
      dataIndex: 'companyName',
      key: 'companyName',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (name: string, record) => (
        <Link to={`/clientePrincipal/visualizar/${record._id}`}>{name}</Link>
      ),
    },
    {
      title: translate('ClientsParentTable.fantasyNameColumn'),
      dataIndex: 'fantasyName',
      align: 'center',
      key: 'fantasyName',
      render: (fantasyName: string) => (
        <>
          {fantasyName || (
            <Text type="secondary">
              <Space size="small">
                <FileUnknownOutlined />
                N/A
              </Space>
            </Text>
          )}
        </>
      ),
    },
    {
      title: translate('ClientsParentTable.documentColumn'),
      dataIndex: 'cnpj',
      align: 'center',
      key: 'cnpj',
      render: (cnpj: string) => (
        <>
          {!cnpj ? (
            <Text type="secondary">
              <Space size="small">
                <FileUnknownOutlined />
                N/A
              </Space>
            </Text>
          ) : (
            <Text>
              <NumberFormat
                value={cnpj}
                displayType="text"
                format="##.###.###/####-##"
              />
            </Text>
          )}
        </>
      ),
    },
    {
      title: translate('ClientsParentTable.emailColumn'),
      align: 'center',
      key: 'name',
      dataIndex: ['contact', 'name'],
      render: (name: string) => (
        <>
          {name || (
            <Text type="secondary">
              <Space size="small">
                <FileUnknownOutlined />
                N/A
              </Space>
            </Text>
          )}
        </>
      ),
    },
    {
      title: translate('ClientsParentTable.nameColumn'),
      align: 'center',
      key: 'email',
      dataIndex: ['contact', 'email'],
      render: (email: string) => (
        <>
          {email || (
            <Text type="secondary">
              <Space size="small">
                <FileUnknownOutlined />
                N/A
              </Space>
            </Text>
          )}
        </>
      ),
    },
    {
      title: translate('ClientsParentTable.actionsColumn'),
      align: 'center',
      key: 'remove',
      render: (record) => (
        <Space size="middle">
          <Tooltip title={translate('ClientsParentTable.clientTooltip')}>
            <Link to={`/clientePrincipal/visualizar/${record._id}`}>
              <Button
                ghost
                type="primary"
                shape="circle"
                icon={<ProfileOutlined />}
              />
            </Link>
          </Tooltip>
          <Popconfirm
            title={translate('ClientsParentTable.deletePopconfirm')}
            onConfirm={() => deleteParentClient(record._id)}
          >
            <Tooltip title={translate('ClientsParentTable.deleteTooltip')}>
              <Button ghost danger shape="circle" icon={<DeleteFilled />} />
            </Tooltip>
          </Popconfirm>
        </Space>
      ),
    },
  ]

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[]) => {
      setIsDelete(selectedRowKeys.length > 0)
      setClientsId(selectedRowKeys)
    },
    getCheckboxProps: (record: ParentClientsProps) => ({
      disabled: record.contact?.name === 'Disabled User', // Column configuration not to be checked
      name: record.contact?.name,
    }),
  }

  function DeleteClients() {
    const clients = clientsId.map((m) => {
      return deleteParentClients(m.toString())
    })

    message.success(translate('ClientsParentTable.deleteClientMessage'))
    setIsDelete(false)
    return clients
  }

  return (
    <>
      {isDelete && (
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <Popconfirm
            title={translate('ClientsParentTable.deleteClientTitle')}
            onConfirm={DeleteClients}
          >
            <Tooltip title={translate('ClientsParentTable.deleteTooltip')}>
              <Button
                ghost
                danger
                size="middle"
                shape="circle"
                icon={<DeleteFilled />}
                style={{ marginBottom: 10 }}
              />
            </Tooltip>
          </Popconfirm>

          {clientsId.length !== 1 ? (
            <Typography.Title level={5} type="danger">
              {clientsId.length}{' '}
              {translate('ClientsParentTable.deleteClientsText')}
            </Typography.Title>
          ) : (
            <Typography.Title level={5} type="danger">
              {translate('ClientsParentTable.deleteClientText')}
            </Typography.Title>
          )}
        </div>
      )}

      <Table
        rowKey={(record) => record._id}
        loading={{
          indicator: <LoadingOutlined />,
          spinning: isFetching,
          size: 'large',
        }}
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        pagination={{
          total: clientsTotal,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '50', '100', '200'],
          defaultPageSize: 10,
          showTotal: (total, range) => `${range[0]} - ${range[1]} / ${total}`,
          onChange: (page, pageSize) => {
            setPageSize(pageSize!)
            setNumberPage(page)
          },
        }}
        columns={columns}
        dataSource={parentClients}
        scroll={{ x: 1000 }}
      />
    </>
  )
}

export default ParentClientsTable
