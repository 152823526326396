export default {
  pt: {
    EquipmentsCategoryUpdate: {
      editCategoryTooltip: 'Editar',
      modalTitle: 'Adicionar Categoria',
      closeButton: 'Fechar',
      saveButton: 'Salvar',
      descriptionLabel: 'Descrição',
      descriptionRule: 'Por favor insira a descrição da categoria',
      descriptionPlaceholder: 'Descrição da categoria',
    },
  },
  en: {
    EquipmentsCategoryUpdate: {
      editCategoryTooltip: 'Edit',
      modalTitle: 'Add Category',
      closeButton: 'Close',
      saveButton: 'Save',
      descriptionLabel: 'Description',
      descriptionRule: 'Please enter category description',
      descriptionPlaceholder: 'Category description',
    },
  },
  es: {
    EquipmentsCategoryUpdate: {
      editCategoryTooltip: 'Editar',
      modalTitle: 'Añadir Categoría',
      closeButton: 'Cerrar',
      saveButton: 'Guardar',
      descriptionLabel: 'Descripción',
      descriptionRule: 'Por favor ingrese la descripción de la categoría',
      descriptionPlaceholder: 'Descripción de categoría',
    },
  },
}
