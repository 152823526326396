export default {
  pt: {
    ClientsPrincipalDetailsUpdate: {
      cardTitle: 'Editar Cliente',
      reasonLabel: 'Razão Social / Nome',
      reasonPlaceholder: 'Informe a razão social / nome',
      reasonRule: 'Por favor, informe a razão social / nome.',

      fantasyLabel: 'Nome Fantasia',
      fantasyPlaceholder: 'Informe o nome fantasia',
      fantasyRule: 'Por favor, informe o nome fantasia',

      cnpjLabel: 'CNPJ',
      cnpjPlaceholder: 'Informe o CNPJ',

      cpfLabel: 'CPF',
      cpfPlaceholder: 'Informe o CPF',

      registrationMunicipalLabel: 'Inscrição Municipal',
      registrationMunicipalPlaceholder: 'Informe a inscrição municipal',

      registrationStateLabel: 'Inscrição Estadual',
      registrationStatePlaceholder: 'Informe a inscrição estadual',

      nameLabel: 'Nome',
      namePlaceholder: 'Informe o nome',
      nameRule: 'Por favor, informe o nome.',

      emailLabel: 'E-mail',
      emailPlaceholder: 'Informe o e-mail',
      emailRule: 'Por favor, informe o e-mail.',

      phoneLabel: 'Celular',
      phonePlaceholder: 'Informe o celular',

      deleteButton: 'Excluir',
      saveButton: 'Salvar',
      cancelButton: 'Cancelar',
    },
  },
  en: {
    ClientsPrincipalDetailsUpdate: {
      cardTitle: 'Edit Client',
      reasonLabel: 'Corporate name / Name',
      reasonPlaceholder: 'Inform corporate name / name',
      reasonRule: 'Please, inform the corporate name / name.',

      fantasyLabel: 'Fantasy Name',
      fantasyPlaceholder: 'Inform the fantasy name',
      fantasyRule: 'Please, inform the fantasy name',

      cnpjLabel: 'CNPJ',
      cnpjPlaceholder: 'Inform the CNPJ',

      cpfLabel: 'CPF',
      cpfPlaceholder: 'Inform the CPF',

      registrationMunicipalLabel: 'Municipal Registration',
      registrationMunicipalPlaceholder: 'Inform the municipal registration ',

      registrationStateLabel: 'State Registration',
      registrationStatePlaceholder: 'Inform the state registration',

      nameLabel: 'Name',
      namePlaceholder: 'Inform the name',
      nameRule: 'Please, inform the name.',

      emailLabel: 'E-mail',
      emailPlaceholder: 'Inform the e-mail',
      emailRule: 'Please, inform the e-mail.',

      phoneLabel: 'Phone',
      phonePlaceholder: 'Inform the phone',

      deleteButton: 'Delete',
      saveButton: 'Save',
      cancelButton: 'Cancel',
    },
  },
  es: {
    ClientsPrincipalDetailsUpdate: {
      cardTitle: 'Editar Cliente',
      reasonLabel: 'Razón social / Nombre',
      reasonPlaceholder: 'Introduce el nombre de la empresa / nombre',
      reasonRule: 'Proporcione el nombre/nombre de la empresa.',

      fantasyLabel: 'Nombre de Fantasía',
      fantasyPlaceholder: 'Introduce tu nombre de fantasía',
      fantasyRule: 'Por favor proporcione su nombre legal',

      cnpjLabel: 'CNPJ',
      cnpjPlaceholder: 'Introduce al CNPJ',

      cpfLabel: 'CPF',
      cpfPlaceholder: 'Introduce al CPF',

      registrationMunicipalLabel: 'Inscrição Municipal',
      registrationMunicipalPlaceholder: 'Introduce registro municipal',

      registrationStateLabel: 'Inscrição Estadual',
      registrationStatePlaceholder: 'Introduce al registro estatal',

      nameLabel: 'Nombre',
      namePlaceholder: 'Introduce el nombre',
      nameRule: 'Por favor, informe o nome.',

      emailLabel: 'E-mail',
      emailPlaceholder: 'Introduce el correo electrónico',
      emailRule: 'Por favor proporcione el correo electrónico.',

      phoneLabel: 'Celular',
      phonePlaceholder: 'Introduce tu celular',

      deleteButton: 'Borrar',
      saveButton: 'Ahorrar',
      cancelButton: 'Cancelar',
    },
  },
}
