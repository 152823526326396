/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useState } from 'react'
import { Select, Radio, Button, Space, Typography, Tooltip } from 'antd'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'

import { CalendarApi } from '@fullcalendar/react'
import moment from 'moment'
import { Container, LeftContent, CenterContent, RightContent } from './styles'
import { translate } from '~/utils/locale'

interface ICalendarHeader {
  FullCalendarAPI: CalendarApi
}

const CalendarHeader: React.FC<ICalendarHeader> = ({ FullCalendarAPI }) => {
  const [tooltipText, setTooltipText] = useState(
    translate('FCCalendarHeader.monthText'),
  )
  const [dateInfo, setDateInfo] = useState(() => {
    const { viewTitle, currentDate } =
      FullCalendarAPI.currentDataManager!.getCurrentData()

    return {
      title: viewTitle,
      currentMonth: Number(moment(currentDate).format('MM')),
      currentYear: Number(moment(currentDate).format('YYYY')),
    }
  })

  const selectMonthOptions = [
    { label: translate('FCCalendarHeader.janText'), value: 1 },
    { label: translate('FCCalendarHeader.febText'), value: 2 },
    { label: translate('FCCalendarHeader.marText'), value: 3 },
    { label: translate('FCCalendarHeader.aprText'), value: 4 },
    { label: translate('FCCalendarHeader.mayText'), value: 5 },
    { label: translate('FCCalendarHeader.junText'), value: 6 },
    { label: translate('FCCalendarHeader.julText'), value: 7 },
    { label: translate('FCCalendarHeader.augText'), value: 8 },
    { label: translate('FCCalendarHeader.sepText'), value: 9 },
    { label: translate('FCCalendarHeader.octText'), value: 10 },
    { label: translate('FCCalendarHeader.novText'), value: 11 },
    { label: translate('FCCalendarHeader.decText'), value: 12 },
  ]

  const { Title } = Typography

  function handleDateInfoChange() {
    const { viewTitle, currentDate } =
      FullCalendarAPI.currentDataManager!.getCurrentData()
    const currentMonth = Number(
      moment(currentDate).add(3, 'hours').format('MM'),
    )
    const currentYear = Number(
      moment(currentDate).add(3, 'hours').format('YYYY'),
    )
    setDateInfo({
      title: viewTitle,
      currentMonth,
      currentYear,
    })
  }

  function handleMonthChange(monthValue: number) {
    let formattedMonthValue
    if (monthValue.toString().length === 1) {
      formattedMonthValue = `0${monthValue}`
    } else {
      formattedMonthValue = `${monthValue}`
    }
    const date = moment(
      `${formattedMonthValue}${dateInfo.currentYear}`,
      'MMYYYY',
    )
      .startOf('month')
      .format('YYYY-MM-DD')
    FullCalendarAPI.gotoDate(date)
    handleDateInfoChange()
  }

  function handleGridChange(gridOption: string) {
    FullCalendarAPI.changeView(gridOption)
    if (gridOption === 'dayGridDay') {
      setTooltipText(translate('FCCalendarHeader.dayText'))
    } else if (gridOption === 'dayGridMonth') {
      setTooltipText(translate('FCCalendarHeader.monthText'))
    } else {
      setTooltipText(translate('FCCalendarHeader.weekText'))
    }
    handleDateInfoChange()
  }

  function handleDateChange(action: 'prev' | 'next') {
    if (action === 'prev') {
      FullCalendarAPI.prev()
    } else if (action === 'next') {
      FullCalendarAPI.next()
    }
    handleDateInfoChange()
  }

  function handleBackToToday() {
    FullCalendarAPI.today()
    handleDateInfoChange()
  }

  return (
    <Container>
      <LeftContent>
        <Space size="middle">
          <Space>
            <Tooltip
              title={`${translate(
                'FCCalendarHeader.prevText',
              )} ${tooltipText}.`}
            >
              <Button
                onClick={() => handleDateChange('prev')}
                type="primary"
                shape="circle"
                icon={<LeftOutlined />}
              />
            </Tooltip>
            <Tooltip
              title={`${translate(
                'FCCalendarHeader.nextText',
              )} ${tooltipText}.`}
            >
              <Button
                onClick={() => handleDateChange('next')}
                type="primary"
                shape="circle"
                icon={<RightOutlined />}
              />
            </Tooltip>
          </Space>
          <Title level={4}>{dateInfo.title}</Title>
        </Space>
      </LeftContent>
      <CenterContent>
        <Space>
          <Button onClick={handleBackToToday} type="primary">
            {translate('FCCalendarHeader.backToTodayButton')}
          </Button>
          <Select
            value={dateInfo.currentMonth}
            defaultValue={dateInfo.currentMonth}
            onChange={handleMonthChange}
            options={selectMonthOptions}
          />
        </Space>
      </CenterContent>
      <RightContent>
        <Radio.Group
          onChange={(e) => handleGridChange(e.target.value)}
          defaultValue="dayGridMonth"
        >
          <Radio.Button value="dayGridDay">
            {translate('FCCalendarHeader.dailyOption')}
          </Radio.Button>
          <Radio.Button value="dayGridWeek">
            {translate('FCCalendarHeader.weeklyOption')}
          </Radio.Button>
          <Radio.Button value="dayGridMonth">
            {translate('FCCalendarHeader.monthlyOption')}
          </Radio.Button>
          <Radio.Button value="timeGridWeek">
            {translate('FCCalendarHeader.weeklyWithHoursOption')}
          </Radio.Button>
        </Radio.Group>
      </RightContent>
    </Container>
  )
}

export default CalendarHeader
