/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react'

import { ColumnsType } from 'antd/lib/table'
import { Popconfirm, Space, Table, Tooltip, Button } from 'antd'
import {
  DeleteFilled,
  FileUnknownOutlined,
  LoadingOutlined,
  ProfileOutlined,
} from '@ant-design/icons'

import Text from 'antd/lib/typography/Text'
import { Link } from 'react-router-dom'
// import EditGroup from '../Update/Group';
import { useTeams } from '~/hooks/Teams/useListTeams'
import { translate } from '~/utils/locale'

interface contribuitorsTeams {
  collaborator: {
    id: string
    contact: {
      id: string
      name: string
    }
  }
}

interface Managers {
  id: string
  contact: {
    id: string
    name: string
  }
}

interface TeamsProps {
  title: string
  id: string
  created_at: Date
  manager: Managers
  description: string
  contributorsTeams: contribuitorsTeams[]
}

const TeamsTable: React.FC = () => {
  const { teams, deleteTeams, isFetching } = useTeams()

  const columns: ColumnsType<TeamsProps> = [
    {
      title: translate('TeamsTable.titleColumn'),
      dataIndex: 'title',
      key: 'title',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (name: string, record) => (
        <Link to={`/equipes/visualizar/${record?.id}`}>{name}</Link>
      ),
    },
    {
      title: translate('TeamsTable.responsibleColumn'),
      dataIndex: ['manager', 'contact', 'name'],
      key: 'manager',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (manager: string) => (
        <>
          {manager || (
            <Text type="secondary">
              <Space size="small">
                <FileUnknownOutlined />
                N/A
              </Space>
            </Text>
          )}
        </>
      ),
    },
    {
      title: translate('TeamsTable.teamUserColumn'),
      key: 'team_user',
      render: (record) => (
        <>
          {record.contributorsTeams.length === 1 ? (
            <Text>
              {record.contributorsTeams.length}{' '}
              {translate('TeamsTable.contributorText')}
            </Text>
          ) : (
            <Text>
              {record.contributorsTeams.length}{' '}
              {translate('TeamsTable.contributorsText')}
            </Text>
          )}
        </>
      ),
    },
    {
      title: translate('TeamsTable.actionsColumn'),
      align: 'center',
      key: 'remove',
      render: (record) => (
        <Space size="middle">
          <Tooltip title={translate('TeamsTable.viewTooltip')}>
            <Link to={`/equipes/visualizar/${record.id}`}>
              <Button
                type="primary"
                ghost
                shape="circle"
                icon={<ProfileOutlined />}
              />
            </Link>
          </Tooltip>

          <Popconfirm
            title={translate('TeamsTable.deletePopconfirm')}
            onConfirm={() => deleteTeams(record.id)}
          >
            <Tooltip title={translate('TeamsTable.deleteTooltip')}>
              <Button ghost danger shape="circle" icon={<DeleteFilled />} />
            </Tooltip>
          </Popconfirm>
        </Space>
      ),
    },
  ]

  return (
    <>
      <Table
        rowKey={(record) => record.id}
        loading={{
          indicator: <LoadingOutlined />,
          spinning: isFetching,
          size: 'large',
        }}
        columns={columns}
        dataSource={teams}
        scroll={{ x: 1000 }}
        pagination={{
          showTotal: (total, range) => `${range[0]} - ${range[1]} / ${total}`,
        }}
      />
    </>
  )
}

export default TeamsTable
