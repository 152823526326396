import { lighten } from 'polished'
import styled from 'styled-components'

export const Container = styled.div`
  #btn_create_contributor {
    background: #3db065;
    border: 1px solid #3db065;
    color: white;

    &:hover {
      background: ${lighten(0.05, '#3DB065')};
      border: 1px solid ${lighten(0.1, '#3DB065')};
    }
  }
`

export const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
`

export const ContAvatar = styled.div`
  flex: 0.5;
`
export const ContForm = styled.div`
  flex: 1;
`

export const FormButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
`

export const Groups = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .groups {
    margin: 10px;
  }
`
