import { LoadingOutlined } from '@ant-design/icons'
import { Card, Space } from 'antd'
import Text from 'antd/lib/typography/Text'
import React from 'react'
import { useSegments } from '~/hooks/Segments/useSegments'
import { translate } from '~/utils/locale'
import CreateSegments from './Create'
import SegmentsTable from './SegmentsTable'

// import { Container } from './styles';

const SegmentsBoard: React.FC = () => {
  const { isLoading, isFetching, segmentsTotal } = useSegments()

  return (
    <Card
      title={
        <Space size="large">
          <b>{translate('SegmentsComponent.cardTitle')}</b>
          {isLoading ? (
            <LoadingOutlined spin />
          ) : (
            <Text
              type="secondary"
              style={{ fontWeight: 'normal', fontSize: 14 }}
            >
              {segmentsTotal !== undefined ? (
                <>
                  {segmentsTotal === 1
                    ? `${translate(
                        'SegmentsComponent.totalText',
                      )} ${segmentsTotal} ${translate(
                        'SegmentsComponent.segmentText',
                      )}`
                    : `${translate(
                        'SegmentsComponent.totalText',
                      )} ${segmentsTotal} ${translate(
                        'SegmentsComponent.segmentsText',
                      )}`}
                </>
              ) : (
                ''
              )}
            </Text>
          )}
          {!isLoading && isFetching && <LoadingOutlined spin />}
        </Space>
      }
      extra={
        <Space size="middle">
          <CreateSegments />
        </Space>
      }
      bordered={false}
      style={{ width: '100%', padding: 30 }}
    >
      <SegmentsTable />
    </Card>
  )
}

export default SegmentsBoard
