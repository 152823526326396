import styled from 'styled-components'

export const Container = styled.div`
  height: 100%;
  display: flex;
  gap: 1rem;
`

export const Menu = styled.div`
  background: white;
  flex: 0.25;
  border-radius: 8px;
  padding: 20px;
  box-shadow:
    rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;

  h2 {
    color: var(--primary-initial-text);
  }

  h3 {
    color: var(--primary-color);
    margin-bottom: 0;
  }

  .menu {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    button {
      cursor: pointer;
      transition: 0.2s;
      text-align: start;
      background: transparent;

      &:hover {
        color: var(--primary-color);
      }
    }
  }
`

export const Wrapper = styled.div`
  flex: 1;
  background: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow:
    rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;

  overflow-y: scroll;
  max-height: 573px;

  .questions {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
`
