export default {
  pt: {
    ClientsParentTaskTypeModalCreate: {
      buttonText: 'Adicionar valor do tipo de tarefa',
      updateButtonText: 'Alterar Valor',
    },
  },
  en: {
    ClientsParentTaskTypeModalCreate: {
      buttonText: 'Add task type value',
      updateButtonText: 'Change value',
    },
  },
  es: {
    ClientsParentTaskTypeModalCreate: {
      buttonText: 'Agregar valor de tipo de tarea',
      updateButtonText: 'Cambiar valor',
    },
  },
}
