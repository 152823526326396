/* eslint-disable consistent-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, createContext } from 'react'
import { useQuery } from 'react-query'

import api from '~/services/api'

import { useCompanies } from '~/hooks/Companies/useCompanies'
import { useLocale } from '~/hooks/locale/useLocale'

import { ShowError } from '~/utils/errors/apiErrors'
import { translate } from '~/utils/locale'

interface ModuleProps {
  name: string
  id: number
}

interface ModuleContextData {
  module?: ModuleProps[]
  isLoading: boolean
  refetch: () => void
}

const ModuleContext = createContext<ModuleContextData>({} as ModuleContextData)

export const ModuleProvider: React.FC = ({ children }) => {
  const { selectedCompany } = useCompanies()
  const { locale } = useLocale()

  const {
    data: module,
    isLoading,
    refetch,
  } = useQuery(
    [`módulos${selectedCompany}`, selectedCompany],

    async () => {
      try {
        const response = await api.get(`/modules`)

        const { data } = response

        return data.results
      } catch (err: any) {
        ShowError(err.message, translate('useModule.getErrorMessage'), locale)
      }
    },
  )

  return (
    <ModuleContext.Provider
      value={{
        module,
        isLoading,
        refetch,
      }}
    >
      {children}
    </ModuleContext.Provider>
  )
}

export function useModule(): ModuleContextData {
  const context = useContext(ModuleContext)

  if (!context) {
    throw new Error('useAuth must be used within an AutoProvider')
  }

  return context
}
