export default {
  pt: {
    SkillModal: {
      createSuccessMessage: 'Habilidade Cadastrada com sucesso!',
      createErrorMessage: 'Erro ao realizar cadastro',
      updateSuccessMessage: 'Habilidade Atualizada com sucesso!',
      updateErrorMessage: 'Erro ao realizar atualização',
      editTooltip: 'Editar',
      createButton: 'Criar Habilidade',
      updateModalTitle: 'Editar Habilidade',
      createModalTitle: 'Nova Habilidade',
      keepRegisterText: 'Continuar cadastrando habilidades?',
      cancelButton: 'Cancelar',
      saveButton: 'Salvar',
      registerButton: 'Cadastrar',
      nameLabel: 'Nome',
      nameRule: 'Por favor insira um nome para a habilidade',
      descriptionLabel: 'Descrição',
      descriptionRule: 'Por favor insira uma descrição para a habilidade',
    },
  },
  en: {
    SkillModal: {
      createSuccessMessage: 'Skill Registered Successfully!',
      createErrorMessage: 'Error when registering',
      updateSuccessMessage: 'Skill Updated Successfully!',
      updateErrorMessage: 'Error performing update',
      editTooltip: 'Edit',
      createButton: 'Create Skill',
      updateModalTitle: 'Edit Skill',
      createModalTitle: 'New Skill',
      keepRegisterText: 'Continue registering skills?',
      cancelButton: 'Cancel',
      saveButton: 'Save',
      registerButton: 'Register',
      nameLabel: 'Name',
      nameRule: 'Please enter a name for the skill',
      descriptionName: 'Description',
      descriptionRule: 'Please enter a description for the skill',
    },
  },
  es: {
    SkillModal: {
      createSuccessMessage: 'Habilidade Cadastrada com sucesso!',
      createErrorMessage: 'Erro ao realizar cadastro',
      updateSuccessMessage: 'Habilidade Atualizada com sucesso!',
      updateErrorMessage: 'Erro ao realizar atualização',
      editTooltip: 'Editar',
      createButton: 'Criar Habilidade',
      updateModalTitle: 'Editar Habilidade',
      createModalTitle: 'Nova Habilidade',
      keepRegisterText: 'Continuar cadastrando habilidades?',
      cancelButton: 'Cancelar',
      saveButton: 'Salvar',
      registerButton: 'Cadastrar',
      nameLabel: 'Nome',
      nameRule: 'Por favor insira um nome para a habilidade',
      descriptionName: 'Descrição',
      descriptionRule: 'Por favor insira uma descrição para a habilidade',
    },
  },
}
