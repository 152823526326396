export default {
  pt: {
    TasksUpdate: {
      searchZipcodeErrorMessage: 'Falha ao buscar CEP. Tente novamente.',
      geolocationWarningMessage:
        'Nenhum resultado encontrado para este endereço. Por gentileza insira a Latitude e Longitude manualmente.',
      geolocationErrorMessage:
        'Ocorreu um erro ao tentar conseguir a Geolocalização, por gentileza tente novamente ou insira a Latitude e Longitude manualmente.',
      backTooltip: 'Voltar',
      cardTitle: 'Editar Tarefa',
      saveButton: 'Salvar',
      cancelButton: 'Cancelar',
      taskResponsibleLabel: 'Tarefa será executada por',
      taskResponsiblePlaceholder: 'Selecione por quem a tarefa será executada',
      taskResponsibleContributor: 'Colaborador',
      taskResponsibleTeam: 'Equipe',
      contributorLabel: 'Colaborador',
      contributorPlaceholder: 'Selecione o Colaborador',
      teamLabel: 'Equipe',
      teamPlaceholder: 'Selecione a Equipe',
      clientLabel: 'Cliente',
      clientPlaceholder: 'Selecione o Cliente',
      taskTypeLabel: 'Tipo de Tarefa',
      taskTypeRule: 'Por favor informe o tipo de tarefa.',
      taskTypePlaceholder: 'Selecione o tipo de tarefa',
      externalCodeLabel: 'Código Externo',
      externalCodeHelp:
        'Campo não obrigatório disponível para inclusão de um código que faça vinculo entre a respectiva tarefa no Gstor e a tarefa em outros sistemas.',
      dateLabel: 'Data e Hora do Agendamento',
      dateRule: 'Por favor informe a data.',
      durationLabel: 'Duração',
      priorityLabel: 'Prioridade',
      priorityPlaceholder: 'Selecione a prioridade',
      priorityHigh: 'Alta',
      priorityMedium: 'Média',
      priorityLow: 'Baixa',
      descriptionLabel: 'Descrição',
      descriptionPlaceholder: 'Informe a descrição',
      addressCardTitle: 'Endereço',
      clientAddressLabel: 'Buscar endereço por cliente',
      clientAddressPlaceholder: 'Selecione o cliente',
      selectAddressLabel: 'Selecione o Endereço',
      selectAddressPlaceholder: 'Informe o endereço',
      selectAddressBusiness: 'Endereço Comercial',
      selectAddressPersonal: 'Endereço Pessoal',
      selectAddressBilling: 'Endereço de Cobrança',
      zipcodeLabel: 'CEP',
      infoZipcodeLabel: 'Informação CEP',
      infoZipcodeButton: 'Não sei meu CEP',
      stateLabel: 'Estado',
      statePlaceholder: 'Informe o estado',
      cityLabel: 'Cidade',
      cityPlaceholder: 'Informe a cidade',
      neighborhoodLabel: 'Bairro',
      neighborhoodPlaceholder: 'Informe o bairro',
      streetLabel: 'Logradouro',
      streetPlaceholder: 'Informe o logradouro',
      numberLabel: 'Número',
      numberRule: 'Por favor informe o número do endereço.',
      numberPlaceholder: 'Informe o número',
      complementLabel: 'Complemento',
      complementPlaceholder: 'Informe o complemento',
      mapLabel: 'Mapa',
      mapRule: 'Você precisa validar o endereço',
      mapButton: 'Validar endereço no mapa',
      value: 'Valor R$ da Tarefa',
      valuePlaceholder: 'Por favor, informe o valor da tarefa',
      noteLabel: 'Observação',
      notePlaceholder: 'Informe a anotação',
      noteAddTooltip: 'Personalizar campo',
      latitudeLabel: 'Latitude',
      latitudeRule: 'Por favor insira a latitude',
      longitudeLabel: 'Longitude',
      longitudeRule: 'Por favor insira a longitude',
      coordinatesInfoLabel: 'Informação Coordenadas',
      coordinatesInfoButton: 'Não sei minhas coordenadas',
    },
  },
  en: {
    TasksUpdate: {
      searchZipcodeErrorMessage: 'Failed to fetch zip code. Try again.',
      geolocationWarningMessage:
        'No results found for this address. Please enter Latitude and Longitude manually.',
      geolocationErrorMessage:
        'There was an error trying to get Geolocation, please try again or enter Latitude and Longitude manually.',
      backTooltip: 'Back',
      cardTitle: 'Edit Task',
      saveButton: 'Save',
      cancelButton: 'Cancel',
      taskResponsibleLabel: 'Task will be performed by',
      taskResponsiblePlaceholder: 'Select by whom the task will be performed',
      taskResponsibleContributor: 'Contributor',
      taskResponsibleTeam: 'Team',
      contributorLabel: 'Contributor',
      contributorPlaceholder: 'Select the Contributor',
      teamLabel: 'Team',
      teamPlaceholder: 'Select Team',
      clientLabel: 'Customer',
      clientPlaceholder: 'Select Customer',
      taskTypeLabel: 'Task Type',
      taskTypeRule: 'Please inform the type of task.',
      taskTypePlaceholder: 'Select task type',
      externalCodeLabel: 'External Code',
      externalCodeHelp:
        'Non-mandatory field available to include a code that links the respective task in Gstor and the task in other systems.',
      dateLabel: 'Date and time',
      dateRule: 'Please inform the date.',
      durationLabel: 'Duration',
      priorityLabel: 'Priority',
      priorityPlaceholder: 'Select priority',
      priorityHigh: 'High',
      priorityMedium: 'Medium',
      priorityLow: 'Low',
      descriptionLabel: 'Description',
      descriptionPlaceholder: 'Enter the description',
      addressCardTitle: 'Address',
      clientAddressLabel: 'Search address by customer',
      clientAddressPlaceholder: 'Select Customer',
      selectAddressLabel: 'Select Address',
      selectAddressPlaceholder: 'Inform the address',
      selectAddressBusiness: 'Business Address',
      selectAddressPersonal: 'Personal Address',
      selectAddressBilling: 'Billing Address',
      value: 'Value R$ of task',
      valuePlaceholder: 'Please enter the value of the task',
      noteLabel: 'Observation',
      notePlaceholder: 'Please provide observation',
      noteAddTooltip: 'Customize field',
      zipcodeLabel: 'Zipcode',
      infoZipcodeLabel: 'Zipcode Info',
      infoZipcodeButton: 'I do not know my Zipcode',
      stateLabel: 'State',
      statePlaceholder: 'Inform the state',
      cityLabel: 'City',
      cityPlaceholder: 'Inform the city',
      neighborhoodLabel: 'Neighborhood',
      neighborhoodPlaceholder: 'Inform the neighborhood',
      streetLabel: 'Street',
      streetPlaceholder: 'Inform the street',
      numberLabel: 'Number',
      numberRule: 'Please provide the address number.',
      numberPlaceholder: 'Inform the number',
      complementLabel: 'Complement',
      complementPlaceholder: 'Inform the complement',
      mapLabel: 'Map',
      mapRule: 'You need to validate the address',
      mapButton: 'Validate address on map',
      latitudeLabel: 'Latitude',
      latitudeRule: 'Please enter latitude',
      longitudeLabel: 'Longitude',
      longitudeRule: 'Please enter the longitude',
      coordinatesInfoLabel: 'Coordinates Info',
      coordinatesInfoButton: "I don't know my coordinates",
    },
  },
  es: {
    TasksUpdate: {
      searchZipcodeErrorMessage:
        'No se pudo obtener el código postal. Inténtalo de nuevo.',
      geolocationWarningMessage:
        'No se han encontrado resultados para esta dirección. Ingrese la latitud y la longitud manualmente.',
      geolocationErrorMessage:
        'Hubo un error al intentar obtener la geolocalización, intente nuevamente o ingrese la latitud y la longitud manualmente.',
      backTooltip: 'Regresar',
      cardTitle: 'Editar Tarea',
      saveButton: 'Guardar',
      cancelButton: 'Cancelar',
      taskResponsibleLabel: 'La tarea será realizada por',
      taskResponsiblePlaceholder: 'Seleccione quién realizará la tarea',
      taskResponsibleContributor: 'Colaborador',
      taskResponsibleTeam: 'Equipo',
      contributorLabel: 'Colaborador',
      contributorPlaceholder: 'Seleccione el Colaborador',
      teamLabel: 'Equipo',
      teamPlaceholder: 'Selecciona un Equipo',
      clientLabel: 'Cliente',
      clientPlaceholder: 'Seleccionar cliente',
      taskTypeLabel: 'Tipo de Tarea',
      taskTypeRule: 'Por favor informe el tipo de tarea.',
      taskTypePlaceholder: 'Seleccionar tipo de tarea',
      externalCodeLabel: 'Código Externo',
      externalCodeHelp:
        'Campo no obligatorio disponible para incluir un código que vincule la tarea respectiva en Gstor y la tarea en otros sistemas.',
      dateLabel: 'Fecha y hora',
      dateRule: 'Por favor informe la fecha.',
      durationLabel: 'Duración',
      priorityLabel: 'Prioridad',
      priorityPlaceholder: 'Seleccionar prioridad',
      priorityHigh: 'Alta',
      priorityMedium: 'Media',
      priorityLow: 'Baja',
      descriptionLabel: 'Descripción',
      descriptionPlaceholder: 'Introduce la descripción',
      addressCardTitle: 'Dirección',
      clientAddressLabel: 'Buscar dirección por cliente',
      clientAddressPlaceholder: 'Seleccionar cliente',
      selectAddressLabel: 'Seleccionar dirección',
      selectAddressPlaceholder: 'Informar la dirección',
      selectAddressBusiness: 'Dirección de Negocios',
      selectAddressPersonal: 'Dirección personal',
      selectAddressBilling: 'Dirección de Envio',
      value: 'Valor R$ de la Tarea',
      valuePlaceholder: 'Por favor ingrese el valor de la tarea',
      noteLabel: 'Observación',
      notePlaceholder: 'Introduce la observación',
      noteAddTooltip: 'Personalizar campo',
      zipcodeLabel: 'Código Postal',
      infoZipcodeLabel: 'Información del código postal',
      infoZipcodeButton: 'No conozco mi CEP',
      stateLabel: 'Estado',
      statePlaceholder: 'Informar al estado',
      cityLabel: 'Ciudad',
      cityPlaceholder: 'Informar a la ciudad',
      neighborhoodLabel: 'Barrio',
      neighborhoodPlaceholder: 'Informar al barrio',
      streetLabel: 'Calle',
      streetPlaceholder: 'Informar a la calle',
      numberLabel: 'Número',
      numberRule: 'Por favor proporcione el número de dirección.',
      numberPlaceholder: 'Informa el numero',
      complementLabel: 'Complemento',
      complementPlaceholder: 'Informar al complemento',
      mapLabel: 'Mapa',
      mapRule: 'Necesitas validar la dirección',
      mapButton: 'Validar dirección en el mapa',
      latitudeLabel: 'Latitud',
      latitudeRule: 'Introduce la latitud',
      longitudeLabel: 'Longitud',
      longitudeRule: 'Por favor ingrese la longitud',
      coordinatesInfoLabel: 'Información de coordenadas',
      coordinatesInfoButton: 'No se mis coordenadas',
    },
  },
}
