export default {
  pt: {
    useListClients: {
      getErrorMessage: 'Não foi possível listar clientes.',
      createSuccessMessage: 'Cliente criado com sucesso!',
      createErrorMessage: 'Houve um erro ao criar cliente',
      deleteSuccessMessage: 'Cliente deletado com sucesso!',
      deleteErrorMessage: 'Houve um erro ao deletar o cliente',
    },
  },
  en: {
    useListClients: {
      getErrorMessage: 'Unable to list customers.',
      createSuccessMessage: 'Customer created successfully!',
      createErrorMessage: 'There was an error creating client',
      deleteSuccessMessage: 'Client successfully deleted!',
      deleteErrorMessage: 'There was an error deleting the client',
    },
  },
  es: {
    useListClients: {
      getErrorMessage: 'No se pueden enumerar los clientes.',
      createSuccessMessage: '¡Cliente creado con éxito!',
      createErrorMessage: 'Hubo un error al crear el cliente',
      deleteSuccessMessage: '¡Cliente eliminado con éxito!',
      deleteErrorMessage: 'Hubo un error al eliminar el cliente',
    },
  },
}
