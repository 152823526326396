/* eslint-disable no-underscore-dangle */
import { ColumnsType } from 'antd/lib/table'
import React from 'react'

import { Popconfirm, Space, Table, Tooltip, Button } from 'antd'
import { DeleteFilled, LoadingOutlined } from '@ant-design/icons'
import Text from 'antd/lib/typography/Text'
import { usePause } from '~/hooks/Tasks/Pause/usePause'
import UpdatePause from '../Update'
import { translate } from '~/utils/locale'

// import { Container } from './styles';

interface PauseProps {
  description: string
  is_enabled: boolean
  _id: string
  title: string
}

const PauseTable: React.FC = () => {
  const { pause, deletePause, isFetching } = usePause()

  const columns: ColumnsType<PauseProps> = [
    {
      title: translate('TasksRegistrationPauseTable.reasonColumn'),
      key: 'title',
      dataIndex: 'title',
    },
    {
      title: translate('TasksRegistrationPauseTable.descriptionColumn'),
      align: 'left',
      dataIndex: 'description',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: translate('TasksRegistrationPauseTable.statusColumn'),
      key: 'is_enabled',
      render: (record) => (
        <Text>{record.is_enabled ? 'Ativo' : 'Inativo'}</Text>
      ),
    },
    {
      title: translate('TasksRegistrationPauseTable.actionsColumn'),
      align: 'center',
      key: 'remove',
      render: (record) => (
        <Space size="middle">
          <UpdatePause data={record} />
          <Popconfirm
            title={translate('TasksRegistrationPauseTable.deletePopconfirm')}
            onConfirm={() => deletePause(record._id)}
          >
            <Tooltip
              title={translate('TasksRegistrationPauseTable.deleteTooltip')}
            >
              <Button ghost danger shape="circle" icon={<DeleteFilled />} />
            </Tooltip>
          </Popconfirm>
        </Space>
      ),
    },
  ]

  return (
    <>
      <Table
        rowKey={(record) => record._id}
        loading={{
          indicator: <LoadingOutlined />,
          spinning: isFetching,
          size: 'large',
        }}
        scroll={{ x: 1000 }}
        columns={columns}
        dataSource={pause}
        pagination={{
          showTotal: (total, range) => `${range[0]} - ${range[1]} / ${total}`,
        }}
      />
    </>
  )
}

export default PauseTable
