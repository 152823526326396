export default {
  pt: {
    Skills: {
      helmetTitle: 'Habilidades',
    },
  },
  en: {
    Skills: {
      helmetTitle: 'Skills',
    },
  },
  es: {
    Skills: {
      helmetTitle: 'Habilidades',
    },
  },
}
