export default {
  pt: {
    ScalesGeneralAddressModal: {
      createSuccessMessage: 'Endereço vinculado a escala com sucesso!',
      createErrorMessage: 'Erro ao vincular endereço a escala',
      searchErrorMessage: 'Falha ao buscar CEP. Tente novamente.',
      mapWarningMessage:
        'Nenhum resultado encontrado para este endereço. Por gentileza insira a Latitude e Longitude manualmente.',
      mapErrorMessage:
        'Ocorreu um erro ao tentar conseguir a Geolocalização, por gentileza tente novamente ou insira a Latitude e Longitude manualmente.',
      newButton: 'Atribuir outro Endereço',
      modalTitle: 'Novo Endereço',
      okText: 'Criar',
      zipcodeLabel: 'CEP',
      zipcodeRule: 'Por favor insira o CEP',
      zipcodeInfoLabel: 'Informação CEP',
      zipcodeInfoButton: 'Não sei meu CEP',
      stateLabel: 'Estado',
      cityLabel: 'Cidade',
      neighborhoodLabel: 'Bairro',
      streetLabel: 'Logradouro',
      numberLabel: 'Número',
      numberRule: 'Por favor insira o número',
      complementLabel: 'Complemento',
      mapLabel: 'Mapa',
      mapRule: 'Você precisa validar o endereço.',
      mapButton: 'Validar endereço no mapa',
      latitudeLabel: 'Latitude',
      latitudeRule: 'Por favor insira a latitude',
      longitudeLabel: 'Longitude',
      longitudeRule: 'Por favor insira a longitude',
      coordinatesInfoLabel: 'Informação Coordenadas',
      coordinatesInfoRule: 'Não sei minhas coordenadas',
    },
  },
  en: {
    ScalesGeneralAddressModal: {
      createSuccessMessage: 'Address linked to scale successfully!',
      createErrorMessage: 'Error linking address to scale',
      searchErrorMessage: 'Failed to fetch zip code. Try again.',
      mapWarningMessage:
        'No results found for this address. Please enter Latitude and Longitude manually.',
      mapErrorMessage:
        'There was an error trying to get Geolocation, please try again or enter Latitude and Longitude manually.',
      newButton: 'Assign another Address',
      modalTitle: 'New Address',
      okText: 'Create',
      zipcodeLabel: 'Zipcode',
      zipcodeRule: 'Please enter the zip code',
      zipcodeInfoLabel: 'Zip code information',
      zipcodeInfoButton: 'I do not know my Zipcode',
      stateLabel: 'State',
      cityLabel: 'City',
      neighborhoodLabel: 'Neighborhood',
      streetLabel: 'Street',
      numberLabel: 'Number',
      numberRule: 'Please enter the number',
      complementLabel: 'Complement',
      mapLabel: 'Map',
      mapRule: 'You need to validate the address.',
      mapButton: 'Validate address on map',
      latitudeLabel: 'Latitude',
      latitudeRule: 'Please enter latitude',
      longitudeLabel: 'Longitude',
      longitudeRule: 'Please enter the longitude',
      coordinatesInfoLabel: 'Information Coordinates',
      coordinatesInfoRule: "I don't know my coordinates",
    },
  },
  es: {
    ScalesGeneralAddressModal: {
      createSuccessMessage: '¡Dirección vinculada a escala con éxito!',
      createErrorMessage: 'Error al vincular la dirección a la escala',
      searchErrorMessage:
        'No se pudo obtener el código postal. Inténtalo de nuevo.',
      mapWarningMessage:
        'No se han encontrado resultados para esta dirección. Ingrese la latitud y la longitud manualmente.',
      mapErrorMessage:
        'Hubo un error al intentar obtener la geolocalización, intente nuevamente o ingrese la latitud y la longitud manualmente.',
      newButton: 'Asignar otra dirección',
      modalTitle: 'Nueva dirección',
      okText: 'Crear',
      zipcodeLabel: 'Código postal',
      zipcodeRule: 'Por favor ingrese el código postal',
      zipcodeInfoLabel: 'Información del código postal',
      zipcodeInfoButton: 'No conozco mi código postal',
      stateLabel: 'Estado',
      cityLabel: 'Ciudad',
      neighborhoodLabel: 'Barrio',
      streetLabel: 'Calle',
      numberLabel: 'Número',
      numberRule: 'Por favor ingrese el número',
      complementLabel: 'Complemento',
      mapLabel: 'Mapa',
      mapRule: 'Necesitas validar la dirección.',
      mapButton: 'Validar dirección en el mapa',
      latitudeLabel: 'Latitud',
      latitudeRule: 'Introduce la latitud',
      longitudeLabel: 'Longitud',
      longitudeRule: 'Por favor ingrese la longitud',
      coordinatesInfoLabel: 'Información Coordenadas',
      coordinatesInfoRule: 'No se mis coordenadas',
    },
  },
}
