import { Tag } from 'antd'
import React, { useEffect, useState } from 'react'
import { useCompanies } from '~/hooks/Companies/useCompanies'
import { FormProps } from '~/hooks/Tasks/Forms/useTaskForms'
import api from '~/services/api'

interface TaskTypeForm {
  formId: string
}

const TaskTypeForm: React.FC<TaskTypeForm> = ({ formId }) => {
  const { selectedCompany } = useCompanies()
  const [form, setForm] = useState<FormProps | null>(null)

  useEffect(() => {
    async function getForm() {
      const { data } = await api.get<FormProps>(
        `/company/${selectedCompany}/forms/${formId}`,
      )

      setForm(data)
    }
    getForm()
  }, [formId, selectedCompany])

  if (!form) {
    return <Tag color="processing">Carregando...</Tag>
  }
  return (
    <>
      <Tag>{form.title}</Tag>
    </>
  )
}

export default TaskTypeForm
