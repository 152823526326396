export default {
  pt: {
    Contributor: {
      cardTitle: 'Colaboradores',
      helpTooltip:
        'Entenda como “Colaborador” qualquer pessoa que trabalhe na sua empresa. Seja um técnico que vai executar serviços, seja um atendente que vai ficar enviando serviços para esse técnico.',
      totalText: 'Total de',
      contributorText: 'colaborador',
      contributorsText: 'colaboradores',
      importTooltip: 'Importar Colaboradores',
      exportTooltip: 'Exportar Colaboradores',
      modalTitle: 'Importação de Colaboradores',
      importTitle: 'Importar Colaboradores',
      importDescription: 'Upload de arquivo para importação de Colaboradores',
      newContributorButton: 'Novo Colaborador',
    },
  },
  en: {
    Contributor: {
      cardTitle: 'Collaborators',
      helpTooltip:
        'Understand as “Collaborator” any person who works in your company. Be a technician who will perform services, be an attendant who will be sending services to this technician.',
      totalText: 'Total of',
      contributorText: 'collaborator',
      contributorsText: 'collaborators',
      importTooltip: 'Import Collaborators',
      exportTooltip: 'Export Collaborators',
      modalTitle: 'Importation of Employees',
      importTitle: 'Import Collaborators',
      importDescription: 'File upload to import Collaborators',
      newContributorButton: 'New Collaborator',
    },
  },
  es: {
    Contributor: {
      cardTitle: 'Colaboradores',
      helpTooltip:
        'Entiéndase como "Colaborador" a cualquier persona que trabaje en su empresa. Sea un técnico que realizará servicios, sea un asistente que enviará servicios a este técnico.',
      totalText: 'Total de',
      contributorText: 'colaborador',
      contributorsText: 'colaboradores',
      importTooltip: 'Importar Colaboradores',
      exportTooltip: 'Exportar Colaboradores',
      modalTitle: 'Importación de Empleados',
      importTitle: 'Importar Colaboradores',
      importDescription: 'Carga de archivos para importar Colaboradores',
      newContributorButton: 'Nuevo Colaborador',
    },
  },
}
