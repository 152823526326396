/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

import { Button, message, Popconfirm, Tooltip } from 'antd'
import { FileAddOutlined } from '@ant-design/icons'

import { useCompanies } from '~/hooks/Companies/useCompanies'
import { useScale } from '~/hooks/Scales/useScale'
import { useLocale } from '~/hooks/locale/useLocale'

import api from '~/services/api'

import { ShowError } from '~/utils/errors/apiErrors'
import { translate } from '~/utils/locale'

interface AddAddressToScaleButtonProps {
  id: string
}

const AddAddressToScaleButton: React.FC<AddAddressToScaleButtonProps> = ({
  id,
}) => {
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)

  const { selectedCompany } = useCompanies()
  const { scaleRefetch } = useScale()
  const { locale } = useLocale()

  const { scaleId } = useParams<{ scaleId: string }>()

  const showPopconfirm = () => {
    setVisible(true)
  }

  const handleCancel = () => {
    setVisible(false)
  }

  const onAdd = async () => {
    try {
      setLoading(true)

      await api.put(`/company/${selectedCompany}/schedule/${scaleId}`, {
        address_id: id,
      })

      message.success(translate('MiscAddAddressToScaleButton.successMessage'))

      scaleRefetch()
      setLoading(false)
    } catch (err: any) {
      ShowError(
        err.message,
        translate('MiscAddAddressToScaleButton.errorMessage'),
        locale,
      )

      setLoading(false)
    } finally {
      setVisible(false)
    }
  }

  return (
    <Popconfirm
      title={translate('MiscAddAddressToScaleButton.popconfirmTitle')}
      okButtonProps={{ loading }}
      onConfirm={() => onAdd()}
      open={visible}
      onCancel={handleCancel}
    >
      <Tooltip title={translate('MiscAddAddressToScaleButton.tooltipTitle')}>
        <Button
          ghost
          shape="circle"
          icon={<FileAddOutlined />}
          onClick={showPopconfirm}
        />
      </Tooltip>
    </Popconfirm>
  )
}

export default AddAddressToScaleButton
