/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/require-default-props */
import React, { useState, useCallback } from 'react'
import { useParams } from 'react-router-dom'

import { Modal, Form, Button, Rate, Input, message, Tooltip, Space } from 'antd'
import { EditOutlined, PlusCircleOutlined } from '@ant-design/icons'

import api from '~/services/api'

import { useContributor } from '~/hooks/Contributors/useContributor'
import { useEvaluations } from '~/hooks/Evaluations/useEvaluations'
import { useCompanies } from '~/hooks/Companies/useCompanies'
import { useListContributors } from '~/hooks/Contributors/useListContributors'
import { useLocale } from '~/hooks/locale/useLocale'

import { ShowError } from '~/utils/errors/apiErrors'
import { translate } from '~/utils/locale'
import { ContainerButton } from './styles'

interface InternalEvaluationData {
  internal_evaluation: number
}

interface Evaluation {
  id: string
  notes: string
  star: number
}

interface EvaluationModalProps {
  isUpdate?: boolean
  EvaluationInfo?: Evaluation
}

const EvaluationModal: React.FC<EvaluationModalProps> = ({
  EvaluationInfo = {},
  isUpdate = false,
}) => {
  const [isVisible, setIsVisible] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)

  const { contributorRefetch } = useContributor()
  const { listContributorsRefetch } = useListContributors()
  const { evaluationsRefetch } = useEvaluations()
  const { contributorId } = useParams<{ contributorId: string }>()
  const { selectedCompany } = useCompanies()
  const { locale } = useLocale()

  const [form] = Form.useForm()

  const handleOk = () => {
    form.submit()
  }

  const handleCancel = useCallback(() => {
    form.resetFields()
    setIsVisible(false)
  }, [form])

  const onCreate = useCallback(
    async (data: InternalEvaluationData) => {
      try {
        setConfirmLoading(true)
        await api.post(
          `/company/${selectedCompany}/collaborator/${contributorId}/evaluation`,
          data,
        )
        contributorRefetch()
        listContributorsRefetch()
        evaluationsRefetch()
        message.success(translate('EvaluationModal.createSuccessMessage'))
        setConfirmLoading(false)
        setIsVisible(false)
      } catch (err: any) {
        ShowError(
          err.message,
          translate('EvaluationModal.createErrorMessage'),
          locale,
        )

        setConfirmLoading(false)
      }
    },
    [
      contributorId,
      selectedCompany,
      contributorRefetch,
      listContributorsRefetch,
      evaluationsRefetch,
      locale,
    ],
  )

  const onUpdate = useCallback(
    async (data: InternalEvaluationData) => {
      try {
        setConfirmLoading(true)
        await api.put(
          `/company/${selectedCompany}/collaborator/${contributorId}/evaluation/${EvaluationInfo.id}`,
          data,
        )
        contributorRefetch()
        listContributorsRefetch()
        evaluationsRefetch()
        message.success(translate('EvaluationModal.updateSuccessMessage'))
        setConfirmLoading(false)
        setIsVisible(false)
      } catch (err: any) {
        ShowError(
          err.message,
          translate('EvaluationModal.updateErrorMessage'),
          locale,
        )

        setConfirmLoading(false)
      }
    },
    [
      contributorId,
      selectedCompany,
      contributorRefetch,
      listContributorsRefetch,
      evaluationsRefetch,
      EvaluationInfo.id,
      locale,
    ],
  )

  const showModal = () => {
    setIsVisible(true)
  }

  return (
    <>
      {isUpdate ? (
        <Tooltip title={translate('EvaluationModal.editTooltip')}>
          <Button
            ghost
            shape="circle"
            icon={<EditOutlined />}
            onClick={showModal}
          />
        </Tooltip>
      ) : (
        <Button
          type="primary"
          onClick={showModal}
          icon={<PlusCircleOutlined />}
        >
          {translate('EvaluationModal.createButton')}
        </Button>
      )}
      <Modal
        title={
          isUpdate
            ? translate('EvaluationModal.editTitle')
            : translate('EvaluationModal.createTitle')
        }
        open={isVisible}
        confirmLoading={confirmLoading}
        style={{ top: 40 }}
        footer={[
          <Space key="buttons">
            <Button key="back" onClick={handleCancel}>
              {translate('SkillModal.cancelButton')}
            </Button>
            <ContainerButton>
              <Button
                key="submit"
                type="primary"
                onClick={handleOk}
                id="btn_create_evaluation"
              >
                Salvar
              </Button>
            </ContainerButton>
          </Space>,
        ]}
      >
        <Form
          form={form}
          onFinish={isUpdate ? onUpdate : onCreate}
          initialValues={EvaluationInfo}
        >
          <Form.Item
            label={translate('EvaluationModal.evaluationLabel')}
            name="star"
            labelCol={{ span: 24 }}
            style={{
              width: '100%',
            }}
          >
            <Rate style={{ color: '#1E8AFF' }} />
          </Form.Item>

          <Form.Item
            label={translate('EvaluationModal.descriptionLabel')}
            name="notes"
            labelCol={{ span: 24 }}
            style={{
              width: '100%',
            }}
          >
            <Input.TextArea rows={3} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default EvaluationModal
