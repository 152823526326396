/* eslint-disable no-underscore-dangle */
import { ColumnsType } from 'antd/lib/table'
import React from 'react'

import { Popconfirm, Space, Table, Button, Tooltip, Collapse } from 'antd'
import {
  DeleteFilled,
  EditOutlined,
  FileUnknownOutlined,
  LoadingOutlined,
} from '@ant-design/icons'

import Text from 'antd/lib/typography/Text'

import { translate } from '~/utils/locale'
import { useTaskForms } from '~/hooks/Tasks/Forms/useTaskForms'
import { Link } from 'react-router-dom'

interface TableProps {
  id: string
  title: string
}

const { Panel } = Collapse
const FormsTable: React.FC = () => {
  const {
    isFetching,
    forms,
    deleteForm,
    limit,
    formsTotal,
    changeLimit,
    changeOffset,
  } = useTaskForms()

  if (!forms) {
    return <>Carregando...</>
  }

  const columns: ColumnsType<TableProps> = [
    {
      title: translate('TasksRegistrationFormsTable.formColumn'),
      key: 'title',
      dataIndex: 'title',
    },
    {
      title: translate('TasksRegistrationFormsTable.descriptionColumn'),
      key: 'description',
      dataIndex: 'description',
      render: (description: string, record) => (
        <>
          <Collapse bordered={false}>
            <Panel
              header="Ver descrição"
              key={record.id}
              style={{ padding: -10, border: 'none', borderRadius: 5 }}
            >
              {description || (
                <Text type="secondary">
                  <Space size="small">
                    <FileUnknownOutlined />
                    N/A
                  </Space>
                </Text>
              )}
            </Panel>
          </Collapse>
        </>
      ),
    },
    {
      title: translate('TasksRegistrationFormsTable.actionsColumn'),
      align: 'center',
      key: 'remove',
      render: (record) => (
        <Space size="middle">
          <Link to={`/formularios/${record.id}`}>
            <Button
              shape="circle"
              ghost
              type="primary"
              icon={<EditOutlined />}
            />
          </Link>
          <Popconfirm
            title={translate('TasksRegistrationFormsTable.deletePopconfirm')}
            onConfirm={() => deleteForm(record.id)}
          >
            <Tooltip
              title={translate('TasksRegistrationFormsTable.deleteTooltip')}
            >
              <Button ghost danger shape="circle" icon={<DeleteFilled />} />
            </Tooltip>
          </Popconfirm>
        </Space>
      ),
    },
  ]

  return (
    <Table
      rowKey={(record) => record.id}
      loading={{
        indicator: <LoadingOutlined />,
        spinning: isFetching,
        size: 'large',
      }}
      size="small"
      columns={columns}
      dataSource={forms}
      scroll={{ x: 1000 }}
      pagination={{
        total: formsTotal,
        showSizeChanger: true,
        defaultPageSize: limit,
        showTotal: (total, range) => `${range[0]} - ${range[1]} / ${total}`,
        onChange: (page, pageSize) => {
          changeOffset((page - 1) * pageSize)
          changeLimit(pageSize)
        },
      }}
    />
  )
}

export default FormsTable
