import React from 'react'
import { Helmet } from 'react-helmet'
import KanbanTicketsBoard from '~/components/HelpDesk/Kanban'

// import { Container } from './styles';

const KanbanTicket: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>Kanban Tickets</title>
      </Helmet>
      <KanbanTicketsBoard />
    </>
  )
}

export default KanbanTicket
