import React from 'react'
import { Helmet } from 'react-helmet'

import TasksListBoard from '~/components/DataManipulation/Tasks/Boards/List'

import { ListClientsProvider } from '~/hooks/Clients/useListClients'

import { ListEquipmentsProvider } from '~/hooks/Equipments/ListEquipmentsHooks'
import { TaskTypeProvider } from '~/hooks/Tasks/TaskType/useTaskType'
import { TaskListProvider } from '~/hooks/Tasks/useListTasks'
import { TeamsProvider } from '~/hooks/Teams/useListTeams'
import { translate } from '~/utils/locale'

import { Container } from './styles'
import { ContributorsTaskFilterProvider } from '~/hooks/Tasks/useListContributorsTask'

const ListTasks: React.FC = () => {
  return (
    <Container>
      <Helmet>
        <title>{translate('ListTasks.helmetTitle')}</title>
      </Helmet>
      <ListEquipmentsProvider>
        <TaskTypeProvider>
          <TaskListProvider>
            <ListClientsProvider>
              <TeamsProvider>
                <ContributorsTaskFilterProvider>
                  <TasksListBoard />
                </ContributorsTaskFilterProvider>
              </TeamsProvider>
            </ListClientsProvider>
          </TaskListProvider>
        </TaskTypeProvider>
      </ListEquipmentsProvider>
    </Container>
  )
}

export default ListTasks
