/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useCallback } from 'react'
import { useParams } from 'react-router-dom'

import {
  Button,
  Drawer,
  Form,
  Input,
  Tooltip,
  Radio,
  Select,
  message,
  ConfigProvider,
  Empty,
} from 'antd'
import {
  EditOutlined,
  PlusCircleOutlined,
  SaveOutlined,
} from '@ant-design/icons'

import api from '~/services/api'

import { useSkills } from '~/hooks/Skills/useSkills'
import { useCompanies } from '~/hooks/Companies/useCompanies'
import { useContributor } from '~/hooks/Contributors/useContributor'
import { useLocale } from '~/hooks/locale/useLocale'

import { ShowError } from '~/utils/errors/apiErrors'
import { translate } from '~/utils/locale'
import { ContainerButton } from './styles'

interface SkillsOptions {
  label: string
  value: string
}

interface NewSkillData {
  skill_id: string
  level: string
  description: string
}

interface Skill {
  description: string
  id: string
  level: string
  skill: {
    description: string
    id: string
    name: string
  }
}

interface SkillProps {
  SkillInfo?: Skill
  isUpdate?: boolean
}

const Skills: React.FC<SkillProps> = ({ SkillInfo = {}, isUpdate = false }) => {
  const { contributorId } = useParams<{ contributorId: string }>()

  const { selectedCompany } = useCompanies()
  const { contributorRefetch } = useContributor()
  const { skillsData } = useSkills()
  const { locale } = useLocale()

  const [skillsForm] = Form.useForm()

  const [drawerVisible, setDrawerVisible] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [skillsOptions, setSkillsOptions] = useState<SkillsOptions[]>([])
  const [confirmLoading, setConfirmLoading] = useState(false)

  useEffect(() => {
    if (skillsData) {
      setSkillsOptions(
        skillsData.map((skill) => ({
          label: skill.name,
          title: skill.name,
          value: skill.id,
        })),
      )
    }
  }, [skillsData])

  const handleCancel = useCallback(() => {
    skillsForm.resetFields()
    setDrawerVisible(false)
  }, [skillsForm])

  const onCreate = useCallback(
    async (newSkillData: NewSkillData) => {
      try {
        setConfirmLoading(true)
        await api.post(
          `/company/${selectedCompany}/collaborator/${contributorId}/collaborator-skill`,
          newSkillData,
        )
        contributorRefetch()
        setConfirmLoading(false)
        message.success(translate('SkillSkills.createSuccessMessage'))
        handleCancel()
      } catch (err: any) {
        ShowError(
          err.message,
          translate('SkillSkills.createErrorMessage'),
          locale,
        )

        setConfirmLoading(false)
      }
    },
    [selectedCompany, contributorId, contributorRefetch, handleCancel, locale],
  )

  const onUpdate = useCallback(
    async (updateSkillData: NewSkillData) => {
      try {
        setConfirmLoading(true)
        await api.put(
          `/company/${selectedCompany}/collaborator/${contributorId}/collaborator-skill/${SkillInfo.id}`,
          updateSkillData,
        )
        contributorRefetch()
        setConfirmLoading(false)
        message.success(translate('SkillSkills.updateSuccessMessage'))
        handleCancel()
      } catch (err: any) {
        ShowError(
          err.message,
          translate('SkillSkills.updateErrorMessage'),
          locale,
        )

        setConfirmLoading(false)
      }
    },
    [
      selectedCompany,
      contributorId,
      SkillInfo,
      contributorRefetch,
      handleCancel,
      locale,
    ],
  )

  const filterOption = (
    input: string,
    option?: { label: string; value: string },
  ) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())

  const customizeRenderEmpty = () => (
    <Empty
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      description={translate('SkillSkills.emptyDescription')}
    />
  )

  return (
    <>
      {isUpdate ? (
        <Tooltip title={translate('SkillSkills.editTooltip')}>
          <Button
            ghost
            shape="circle"
            icon={<EditOutlined />}
            onClick={() => {
              setDrawerVisible(true)
            }}
          />
        </Tooltip>
      ) : (
        <Button
          style={{
            width: '100%',
          }}
          type="primary"
          icon={<PlusCircleOutlined />}
          onClick={() => setDrawerVisible(true)}
        >
          {translate('SkillSkills.addButton')}
        </Button>
      )}

      <Drawer
        title={
          isUpdate
            ? translate('SkillSkills.updateDrawerTitle')
            : translate('SkillSkills.addDrawerTitle')
        }
        width={500}
        closable={false}
        onClose={handleCancel}
        open={drawerVisible}
      >
        <Form
          form={skillsForm}
          onFinish={isUpdate ? onUpdate : onCreate}
          initialValues={SkillInfo}
        >
          {!isUpdate && (
            <ConfigProvider renderEmpty={customizeRenderEmpty}>
              <Form.Item
                label={translate('SkillSkills.skillLabel')}
                name="skill_id"
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: translate('SkillSkills.skillRule'),
                  },
                ]}
              >
                <Select
                  style={{ width: '100%' }}
                  showSearch
                  className="menu_input"
                  optionFilterProp="children"
                  filterOption={filterOption}
                >
                  {skillsOptions.map((skill, index) => (
                    <Select.Option key={index} value={skill.value}>
                      {skill.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </ConfigProvider>
          )}

          <Form.Item
            label={translate('SkillSkills.levelLabel')}
            name="level"
            labelCol={{ span: 24 }}
            rules={[
              {
                required: true,
                message: translate('SkillSkills.levelRule'),
              },
            ]}
          >
            <Radio.Group>
              <Radio.Button value="basic">
                {translate('SkillSkills.levelBasic')}
              </Radio.Button>
              <Radio.Button value="intermediary">
                {translate('SkillSkills.levelIntermediary')}
              </Radio.Button>
              <Radio.Button value="advanced">
                {translate('SkillSkills.levelAdvanced')}
              </Radio.Button>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            label={translate('SkillSkills.descriptionLabel')}
            name="description"
            labelCol={{ span: 24 }}
            rules={[
              {
                required: true,
                message: translate('SkillSkills.descriptionRule'),
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item>
            <ContainerButton>
              <Button
                style={{
                  width: '100%',
                }}
                type="primary"
                icon={<SaveOutlined />}
                htmlType="submit"
                loading={confirmLoading}
                id="btn_update_skill_contributor"
              >
                {isUpdate
                  ? translate('SkillSkills.saveButtonText')
                  : translate('SkillSkills.addButtonText')}
              </Button>
            </ContainerButton>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  )
}

export default Skills
