/* eslint-disable @typescript-eslint/no-explicit-any */
import { Modal, Form, Input, Tooltip, Button, Space } from 'antd'

import React, { useState } from 'react'
import { EditOutlined } from '@ant-design/icons'
import { useAttachment } from '~/hooks/Equipments/Attachments/useAttachments'
import { translate } from '~/utils/locale'
import { ContainerButton } from '../../styles'

// import { Container } from './styles';

interface NewAttachmentData {
  type: string
  title: string
  description: string
}

interface AttachmentProps {
  id: string
  title: string
  attachment: string
  attachment_url: string
  description: string
  type: string
}

interface AttachmentData {
  updateAttachments: AttachmentProps
}

const UpdateAttachment: React.FC<AttachmentData> = ({ updateAttachments }) => {
  const { updateAttachment } = useAttachment()

  const [isModalVisible, setIsModalVisible] = useState(false)

  const [form] = Form.useForm()

  const initialData = {
    title: updateAttachments.title,
    type: updateAttachments.type,
    description: updateAttachments.description,
  }

  function onUpdateAttachment(data: NewAttachmentData) {
    try {
      updateAttachment(data, updateAttachments.id)

      setIsModalVisible(false)
    } catch (error) {
      console.log(error)
    }
  }

  const showModal = () => {
    setIsModalVisible(true)
  }

  const closeModal = () => {
    setIsModalVisible(false)
  }

  return (
    <>
      <Tooltip title={translate('EquipmentsTabsAttachmentsUpdate.editTooltip')}>
        <Button
          ghost
          shape="circle"
          icon={<EditOutlined />}
          id="edit_attachment"
          onClick={showModal}
        />
      </Tooltip>
      <Modal
        title={translate('EquipmentsTabsAttachmentsUpdate.modalTitle')}
        open={isModalVisible}
        onCancel={closeModal}
        footer={[
          <Space key="buttons">
            <Button key="back" onClick={closeModal}>
              {translate('EquipmentsTabsSpecificationUpdate.closeButton')}
            </Button>
            <ContainerButton>
              <Button
                key="save"
                type="primary"
                form="formUpdateAttachmentEquipment"
                htmlType="submit"
                id="btn_update_attachment_equipment"
              >
                {translate('EquipmentsTabsAttachmentsCreate.saveText')}
              </Button>
            </ContainerButton>
          </Space>,
        ]}
      >
        <Form
          form={form}
          initialValues={initialData}
          onFinish={onUpdateAttachment}
          id="formUpdateAttachmentEquipment"
        >
          <Form.Item
            label={translate('EquipmentsTabsAttachmentsUpdate.titleLabel')}
            name="title"
            labelCol={{ span: 24 }}
            style={{
              width: '100%',
            }}
            rules={[
              {
                required: true,
                message: translate('EquipmentsTabsAttachmentsUpdate.titleRule'),
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={translate(
              'EquipmentsTabsAttachmentsUpdate.archiveTypeLabel',
            )}
            name="type"
            labelCol={{ span: 24 }}
            style={{
              width: '100%',
            }}
            rules={[
              {
                required: true,
                message: translate(
                  'EquipmentsTabsAttachmentsUpdate.archiveTypeRule',
                ),
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={translate(
              'EquipmentsTabsAttachmentsUpdate.descriptionLabel',
            )}
            name="description"
            labelCol={{ span: 24 }}
            style={{
              width: '100%',
            }}
            rules={[
              {
                required: true,
                message: translate(
                  'EquipmentsTabsAttachmentsUpdate.descriptionRule',
                ),
              },
            ]}
          >
            <Input.TextArea rows={2} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default UpdateAttachment
