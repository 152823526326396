/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import React, { useState, useCallback } from 'react'

import { Button, Tooltip, notification } from 'antd'

import { PrinterFilled } from '@ant-design/icons'
import { formatPhoneNumber } from 'react-phone-number-input'
import moment from 'moment'
import TaskPDF from '../TaskPDF/index.jsx'

import { useTask } from '~/hooks/Tasks/useTask'
import { useTaskType } from '~/hooks/Tasks/TaskType/useTaskType'
import { translate } from '~/utils/locale'
import api from '~/services/api'
import { useCompanies } from '~/hooks/Companies/useCompanies'
import { convertImageUrlToBase64 } from '~/utils/convertImageUrlToBase64'
import AlternativeTaskPDF from '../TaskPDF/alternative.jsx'

interface TaskEquipment {
  name: string
  identifier: string
  category: {
    title: string
  }
}

interface PrintButtonProps {
  taskEquipment: TaskEquipment[]
}

interface AdditionalPhonesProps {
  phone: string
  type: string
  is_whatsapp: boolean
  formattedPhone: string
}

interface Status {
  color: string
  id: string
  description: string
  title: string
  type: string
}

interface Skill {
  id: string
  description: string
  name: string
}

interface Skills {
  description: string
  id: string
  level: string
  skill: Skill
}

interface Managers {
  id: string
  contact: {
    id: string
    name: string
  }
}

interface contribuitorsTeams {
  collaborator: {
    id: string
    contact: {
      id: string
      name: string
    }
  }
}

interface TeamsProps {
  title: string
  id: string
  created_at: Date
  manager: Managers
  description: string
  contributorsTeams: contribuitorsTeams[]
}

interface Addresses {
  city: string
  complement: string
  id: string
  location: {
    x: number
    y: number
  }
  neighborhood: string
  number: string
  state: string
  street: string
  zip_code: string
}

interface Company {
  id: string
  name: string
}

interface Contact {
  additional_phones: AdditionalPhonesProps[]
  avatar: string
  avatar_url: string
  birth: string
  formattedBirth: string
  cnpj: string
  rg: string
  formattedCnpj: string
  formattedDocument: string
  document: string
  gender: string
  email: string
  id: string
  name: string
  person_type: string
  phone: string
  formattedPhone: string
  notes: string
  value_hour_worked: number
  operating_range: number
  value_km_traveled: number
}

interface Address {
  address: Addresses
  company: Company
  contact: Contact
  id: string
  notes: string
  type: string
}

interface Attachments {
  id: string
  title: string
  description: string
  attachment_url: string
  attachment: string
  type: string
}

interface Report {
  value: string | ArrayBuffer | null
  created_at: Date
  id: string
  type: string
  title: string
}

interface ContributorData {
  id: string
  address: Address[]
  attachments: Attachments[]
  contact: Contact
  skills: Skills[]
  status: Status
}

interface MandatoryProps {
  is_active: boolean
  name: string
  type: string
  value: boolean
}

interface TaskTypeProps {
  description: string
  is_enabled: boolean
  runtime: string
  tolerance_time: string
  _id: string
  createdAt: string
  title: string
  valueTask?: number
  mandatory: MandatoryProps[]
}

const PrintButton: React.FC<PrintButtonProps> = ({ taskEquipment }) => {
  const [apiNotification, contextHolder] = notification.useNotification()
  const { task } = useTask()
  const { taskType: taskTypes } = useTaskType()
  const { selectedCompany, currentCompanyData } = useCompanies()

  const openPdfGenerationErrorNotification = () => {
    apiNotification.open({
      message: 'Não foi possível gerar documento',
      description: 'Falha ao gerar documento para impressão tente novamente',
      duration: 0,
    })
  }

  const getCustomer = useCallback(async () => {
    if (!task || !task.customer_id) return null
    try {
      const response = await api.get(
        `/company/${selectedCompany}/customer/${task.customer_id}`,
      )

      const { data } = response

      if (data.contact.phone) {
        data.contact.phone = `+${data.contact.phone}`
        data.contact.formattedPhone = formatPhoneNumber(data.contact.phone)

        if (data.contact.formattedPhone.replace(/[0-9]/g, '') === '') {
          if (data.contact.formattedPhone.length === 10) {
            const forceFormat = data.contact.formattedPhone.match(
              /^(\d{2})(\d{4})(\d{4})$/,
            )

            if (forceFormat) {
              data.contact.formattedPhone = `(${forceFormat[1]}) ${forceFormat[2]}-${forceFormat[3]}`
            }
          } else if (data.contact.formattedPhone.length === 9) {
            const forceFormat = data.contact.formattedPhone.match(
              /^(\d{2})(\d{4})(\d{3})$/,
            )

            if (forceFormat) {
              data.contact.formattedPhone = `(${forceFormat[1]}) ${forceFormat[2]}-${forceFormat[3]}`
            }
          }
        }
      }

      if (
        data.contact.person_type === 'legalPerson' ||
        data.contact.person_type === 'physicalPerson'
      ) {
        if (data.contact.document) {
          const match = data.contact.document.match(
            /^(\d{3})(\d{3})(\d{3})(\d{2})$/,
          )

          if (match) {
            data.contact.formattedDocument = `${match[1]}.${match[2]}.${match[3]}-${match[4]}`
          }
        }
      } else {
        data.contact.formattedDocument = data.contact.document
      }

      if (data.contact.additional_phones) {
        data.contact.additional_phones.forEach(
          (additional_phone: AdditionalPhonesProps) => {
            if (additional_phone.phone) {
              additional_phone.phone = `+${additional_phone.phone}`
              additional_phone.formattedPhone = formatPhoneNumber(
                additional_phone.phone,
              )

              if (
                additional_phone.formattedPhone.replace(/[0-9]/g, '') === ''
              ) {
                if (additional_phone.formattedPhone.length === 10) {
                  const forceFormat = additional_phone.formattedPhone.match(
                    /^(\d{2})(\d{4})(\d{4})$/,
                  )

                  if (forceFormat) {
                    additional_phone.formattedPhone = `(${forceFormat[1]}) ${forceFormat[2]}-${forceFormat[3]}`
                  }
                } else if (additional_phone.formattedPhone.length === 9) {
                  const forceFormat = additional_phone.formattedPhone.match(
                    /^(\d{2})(\d{4})(\d{3})$/,
                  )

                  if (forceFormat) {
                    additional_phone.formattedPhone = `(${forceFormat[1]}) ${forceFormat[2]}-${forceFormat[3]}`
                  }
                }
              }
            }
          },
        )
      }

      const dataFormatted = {
        billing_email: data.billing_email,
        colaborator: data.colaborator,
        corporate_name: data.corporate_name,
        created_at: data.created_at,
        external_code: data.external_code,
        icms_taxpayer: data.icms_taxpayer,
        is_enabled: data.is_enabled,
        municipal_registration: data.municipal_registration,
        id: data.id,
        state_registration: data.state_registration,
        is_responsible: data.is_responsible,
        notes: data.notes,
        contact: data.contact,
        segment: data.segment,
        collaborator: data.collaborator,
        address: data.contact.contactAddresses,
        phones: data.contact.additional_phones,
        parent_customer: data.parent_customer,
        parent_cnpj: data.parent_cnpj,
      }

      return dataFormatted
    } catch (err) {
      return null
    }
  }, [task, selectedCompany])

  const getContributor = useCallback(
    async (contributorId: string | undefined): Promise<ContributorData> => {
      try {
        const response = await api.get(
          `/company/${selectedCompany}/collaborator/${contributorId}`,
        )

        const responseAddress = await api.get(
          `/company/${selectedCompany}/contact/${response.data.contact.id}/addresses/`,
        )

        const addressInfo = responseAddress.data

        const contributorInfoRaw = response.data

        if (contributorInfoRaw.contact.birth) {
          contributorInfoRaw.contact.birth = moment(
            contributorInfoRaw.contact.birth,
            'YYYY/MM/DD',
          )

          contributorInfoRaw.contact.formattedBirth = moment(
            contributorInfoRaw.contact.birth,
          ).format('L')
        }

        if (
          contributorInfoRaw.contact.person_type === 'legalPerson' ||
          contributorInfoRaw.contact.person_type === 'physicalPerson'
        ) {
          if (contributorInfoRaw.contact.document) {
            const match = contributorInfoRaw.contact.document.match(
              /^(\d{3})(\d{3})(\d{3})(\d{2})$/,
            )

            if (match) {
              contributorInfoRaw.contact.formattedDocument = `${match[1]}.${match[2]}.${match[3]}-${match[4]}`
            }
          }
        } else {
          contributorInfoRaw.contact.formattedDocument =
            contributorInfoRaw.contact.document
        }

        if (contributorInfoRaw.contact.phone) {
          contributorInfoRaw.contact.phone = `+${contributorInfoRaw.contact.phone}`
          contributorInfoRaw.contact.formattedPhone = formatPhoneNumber(
            contributorInfoRaw.contact.phone,
          )

          if (
            contributorInfoRaw.contact.formattedPhone.replace(/[0-9]/g, '') ===
            ''
          ) {
            if (contributorInfoRaw.contact.formattedPhone.length === 10) {
              const forceFormat =
                contributorInfoRaw.contact.formattedPhone.match(
                  /^(\d{2})(\d{4})(\d{4})$/,
                )

              if (forceFormat) {
                contributorInfoRaw.contact.formattedPhone = `(${forceFormat[1]}) ${forceFormat[2]}-${forceFormat[3]}`
              }
            } else if (contributorInfoRaw.contact.formattedPhone.length === 9) {
              const forceFormat =
                contributorInfoRaw.contact.formattedPhone.match(
                  /^(\d{2})(\d{4})(\d{3})$/,
                )

              if (forceFormat) {
                contributorInfoRaw.contact.formattedPhone = `(${forceFormat[1]}) ${forceFormat[2]}-${forceFormat[3]}`
              }
            }
          }
        }

        if (contributorInfoRaw.contact.cnpj) {
          const match = contributorInfoRaw.contact.cnpj.match(
            /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
          )

          if (match) {
            contributorInfoRaw.contact.formattedCnpj = `${match[1]}.${match[2]}.${match[3]}/${match[4]}-${match[5]}`
          }
        }

        if (contributorInfoRaw.contact.additional_phones) {
          contributorInfoRaw.contact.additional_phones.forEach(
            (additional_phone: AdditionalPhonesProps) => {
              if (additional_phone.phone) {
                additional_phone.phone = `+${additional_phone.phone}`
                additional_phone.formattedPhone = formatPhoneNumber(
                  additional_phone.phone,
                )

                if (
                  additional_phone.formattedPhone.replace(/[0-9]/g, '') === ''
                ) {
                  if (additional_phone.formattedPhone.length === 10) {
                    const forceFormat = additional_phone.formattedPhone.match(
                      /^(\d{2})(\d{4})(\d{4})$/,
                    )

                    if (forceFormat) {
                      additional_phone.formattedPhone = `(${forceFormat[1]}) ${forceFormat[2]}-${forceFormat[3]}`
                    }
                  } else if (additional_phone.formattedPhone.length === 9) {
                    const forceFormat = additional_phone.formattedPhone.match(
                      /^(\d{2})(\d{4})(\d{3})$/,
                    )

                    if (forceFormat) {
                      additional_phone.formattedPhone = `(${forceFormat[1]}) ${forceFormat[2]}-${forceFormat[3]}`
                    }
                  }
                }
              }
            },
          )
        }

        const contributorInfoFetch = {
          id: contributorInfoRaw.id,
          attachments: contributorInfoRaw.collaboratorAttachments,
          status: contributorInfoRaw.status,
          contact: {
            ...contributorInfoRaw.contact,
            notes: contributorInfoRaw.notes,
            value_hour_worked: contributorInfoRaw.value_hour_worked,
            value_km_traveled: contributorInfoRaw.value_km_traveled,
            operating_range: contributorInfoRaw.operating_range,
          },
          skills: contributorInfoRaw.collaboratorSkills,
          address: addressInfo,
          company: contributorInfoRaw.company,
          aditionalInfo: {
            evaluation: contributorInfoRaw.evaluation,
            id: contributorInfoRaw.id,
            internal_evaluation: contributorInfoRaw.internal_evaluation,
          },
        }

        return contributorInfoFetch
      } catch (err) {
        return {} as ContributorData
      }
    },
    [selectedCompany],
  )

  const getTeam = useCallback(
    async (teamId: string | undefined): Promise<TeamsProps> => {
      try {
        const response = await api.get(
          `/company/${selectedCompany}/team/${teamId}`,
        )
        return response.data
      } catch (err) {
        return {} as TeamsProps
      }
    },
    [selectedCompany],
  )

  const getCreatedBy = useCallback(async (): Promise<string | null> => {
    if (!task || !task.responsible) return null
    if (task.responsible.type === 'team') {
      const team = await getTeam(task.responsible.id)
      if (!team.title) return null
      return team.title
    }
    const contributor = await getContributor(task?.responsible.id)
    if (!contributor.contact.name) return null
    return contributor.contact.name
  }, [task, getTeam, getContributor])

  const getResponsible =
    useCallback(async (): Promise<ContributorData | null> => {
      if (!task || !task.responsible) return null
      if (task.responsible.type === 'team') {
        const team = await getTeam(task.responsible.id)
        if (!team || !team.manager.id) return null
        const managerTeam = await getContributor(team.manager.id)
        if (!managerTeam) return null
        return managerTeam
      }
      const responsible = await getContributor(task.responsible.id)
      if (!responsible) return null
      return responsible
    }, [task, getTeam, getContributor])

  const getTaskType = useCallback((): TaskTypeProps | null => {
    if (!taskTypes || !task || !task.task_type_id) return null
    const tType = taskTypes.find(
      (taskType) => taskType._id === task.task_type_id,
    )
    if (!tType) return null
    return tType
  }, [task, taskTypes])

  const getLogoURL = useCallback(async (): Promise<string | null> => {
    if (!currentCompanyData || !currentCompanyData.company.logo_url) return null
    const logoUrl = await convertImageUrlToBase64(
      currentCompanyData.company.logo_url,
    )
      .then((value) => value)
      .catch(() => null)
    return logoUrl
  }, [currentCompanyData])

  const getTaskStatusInfo = (): string => {
    if (!task || !task.status) return 'Sem dados'
    const lastTaskStatus = task.status.slice(-1)[0]
    if (!lastTaskStatus) return 'Sem dados'
    const date = moment(lastTaskStatus.created_at).format('LLL')
    let statusInfo
    switch (task.current_status) {
      case 'received':
        statusInfo = `${translate('TasksPrintPDF.statusReceived')} - ${date}`
        break
      case 'viewed':
        statusInfo = `${translate('TasksPrintPDF.statusViewed')} - ${date}`
        break
      case 'working':
        statusInfo = `${translate('TasksPrintPDF.statusWorking')} - ${date}`
        break
      case 'break':
        statusInfo = `${translate('TasksPrintPDF.statusBreak')} - ${date}`
        break
      case 'en-route':
        statusInfo = `${translate('TasksPrintPDF.statusEnRoute')} - ${date}`
        break
      case 'resume-working':
        statusInfo = `${translate(
          'TasksPrintPDF.statusResumeWorking',
        )} - ${date}`
        break
      case 'canceled':
        statusInfo = `${translate('TasksPrintPDF.statusCanceled')} - ${date}`
        break
      case 'finished':
        statusInfo = `${translate('TasksPrintPDF.statusFinished')} - ${date}`
        break
      default:
        statusInfo = `Sem informação`
        break
    }
    return statusInfo
  }

  const getReports = async (): Promise<Report[]> => {
    if (!task || !task.reports) return []

    const response = await api.get(
      `/company/${selectedCompany}/tasks/${task._id}/base64`,
    )
    return response.data
  }

  const getFormsInfo = async () => {
    if (!task || !task.forms) return []

    const formsInfo = task.forms.map(async (form) => {
      const response = await api.get(
        `/company/${selectedCompany}/tasks/${task._id}/forms/${form.form_id}/answers`,
      )
      return response.data
    })
    return Promise.all(formsInfo)
  }

  const [isGeneratingPdf, setIsGeneratingPdf] = useState(false)

  const handleGeneratingPdf = async () => {
    setIsGeneratingPdf(true)
    try {
      const reports = await getReports()
      const createdBy = await getCreatedBy()
      const contributorInfo = await getResponsible()
      const type = getTaskType()
      const clientInfo = await getCustomer()
      const logoURL = await getLogoURL()
      const statusTime = getTaskStatusInfo()
      const formsInfo = await getFormsInfo()

      if (type?.title === 'ORDEM DE SERVIÇO EXTERNA') {
        AlternativeTaskPDF(
          task,
          reports,
          contributorInfo,
          clientInfo,
          formsInfo,
        )
      } else {
        TaskPDF(
          task,
          reports,
          type,
          createdBy,
          taskEquipment,
          contributorInfo,
          clientInfo,
          logoURL,
          statusTime,
          formsInfo,
        )
      }
    } catch (error) {
      openPdfGenerationErrorNotification()
    }
    setIsGeneratingPdf(false)
  }
  if (!task || !taskTypes || !currentCompanyData) {
    return (
      <Tooltip title={translate('TasksPrintButton.printTooltip')}>
        <Button disabled icon={<PrinterFilled />} loading></Button>
      </Tooltip>
    )
  }

  return (
    <>
      {contextHolder}
      <Tooltip title={translate('TasksPrintButton.printTooltip')}>
        <Button
          icon={<PrinterFilled />}
          loading={isGeneratingPdf}
          onClick={() => handleGeneratingPdf()}
        >
          {isGeneratingPdf ? 'Gerando arquivo...' : ''}
        </Button>
      </Tooltip>
    </>
  )
}

export default PrintButton
