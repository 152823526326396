export default {
  pt: {
    TasksRegistrationPauseTable: {
      reasonColumn: 'Motivo',
      descriptionColumn: 'Descrição',
      statusColumn: 'Status',
      actionsColumn: 'Ações',
      deletePopconfirm: 'Deseja mesmo deletar o motivo de pausa?',
      deleteTooltip: 'Deletar',
    },
  },
  en: {
    TasksRegistrationPauseTable: {
      reasonColumn: 'Reason',
      descriptionColumn: 'Description',
      statusColumn: 'Status',
      actionsColumn: 'Actions',
      deletePopconfirm: 'Do you really want to delete the break reason?',
      deleteTooltip: 'Delete',
    },
  },
  es: {
    TasksRegistrationPauseTable: {
      reasonColumn: 'Razón',
      descriptionColumn: 'Descripción',
      statusColumn: 'Status',
      actionsColumn: 'Acciones',
      deletePopconfirm: '¿Realmente desea eliminar el motivo de la pausa?',
      deleteTooltip: 'Borrar',
    },
  },
}
