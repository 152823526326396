export default {
  pt: {
    ConfirmPageWithToken: {
      successMessage: 'E-mail enviado para:',
      errorMessage: 'Erro ao re-enviar e-mail de confirmação.',
      loadingTitle: 'Confirmando seu Cadastro',
      successTitle: 'Cadastro Confirmado',
      successParagraphOne: 'Parabens, sua conta foi ativada com sucesso!',
      successParagraphTwo:
        'Para prosseguir para a plataforma clique no botão abaixo ou espere',
      successParagraphThree: 'segundos.',
      successButtonText: 'Ir para a plataforma.',
      failTitle: 'Erro na confirmação',
      failParagraphOne: 'O seu código não é válido ou se encontra expirado.',
      failParagraphTwo: 'Por gentileza clique em',
      failParagraphThree: ' Re-enviar e-mail ',
      failParagraphFour: 'para receber um novo link de autenticação.',
      failButtonText: 'Re-enviar e-mail',
      helmetTitle: 'Confirmação',
    },
  },
  en: {
    ConfirmPageWithToken: {
      successMessage: 'Email sent to:',
      errorMessage: 'Error resending confirmation email.',
      loadingTitle: 'Confirming your Registration',
      successTitle: 'Confirmed Registration',
      successParagraphOne:
        'Congratulations, your account has been successfully activated!',
      successParagraphTwo:
        'To proceed to the platform click on the button below or wait',
      successParagraphThree: 'seconds.',
      successButtonText: 'Go to the platform.',
      failTitle: 'Error in confirmation',
      failParagraphOne: 'Your code is not valid or is expired.',
      failParagraphTwo: 'Please click',
      failParagraphThree: ' Resend email ',
      failParagraphFour: 'to receive a new authentication link.',
      failButtonText: 'Resend email',
      helmetTitle: 'Confirmation',
    },
  },
  es: {
    ConfirmPageWithToken: {
      successMessage: 'Email enviado a:',
      errorMessage: 'Error al reenviar el correo electrónico de confirmación.',
      loadingTitle: 'Confirmando tu Registro',
      successTitle: 'Registro confirmado',
      successParagraphOne:
        '¡Felicitaciones, su cuenta ha sido activada con éxito!',
      successParagraphTwo:
        'Para proceder a la plataforma, haga clic en el botón de abajo o espere',
      successParagraphThree: 'segundos.',
      successButtonText: 'Ir a la plataforma.',
      failTitle: 'Error en la confirmación',
      failParagraphOne: 'Su código no es válido o está vencido.',
      failParagraphTwo: 'Por favor haz click',
      failParagraphThree: ' Reenviar email ',
      failParagraphFour: 'para recibir un nuevo enlace de autenticación.',
      failButtonText: 'Reenviar email',
      helmetTitle: 'Confirmación',
    },
  },
}
