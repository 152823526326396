/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, createContext, useState } from 'react'
import { useQuery } from 'react-query'

import { message } from 'antd'

import api from '~/services/api'

import { useCompanies } from '~/hooks/Companies/useCompanies'
import { useLocale } from '~/hooks/locale/useLocale'

import { ShowError } from '~/utils/errors/apiErrors'
import { translate } from '~/utils/locale'

interface PauseProps {
  description: string
  is_enabled: boolean
  _id: string
  title: string
}

interface PauseContextData {
  pause?: PauseProps[]
  createPause(valueForm: object | undefined): void
  updatePause(valueForm: object | undefined, pauseId: string): void
  deletePause(pauseId: string): void
  isLoading: boolean
  refetch: () => void
  isFetching: boolean
  pauseTotal?: number
}

const PauseContext = createContext<PauseContextData>({} as PauseContextData)

export const PauseProvider: React.FC = ({ children }) => {
  const { selectedCompany } = useCompanies()
  const { locale } = useLocale()

  const [pauseTotal, setPauseTotal] = useState<number>()

  const {
    data: pause,
    isLoading,
    refetch,
    isFetching,
  } = useQuery(
    [`categoria${selectedCompany}`, selectedCompany, pauseTotal],

    async () => {
      try {
        const response = await api.get(
          `/company/${selectedCompany}/breaks-reasons`,
        )

        const { data } = response

        setPauseTotal(data.total)

        return data.results
      } catch (err: any) {
        ShowError(err.message, translate('usePause.getErrorMessage'), locale)
      }
    },
  )

  async function createPause(valueForm: object) {
    try {
      await api.post(`/company/${selectedCompany}/break-reason`, valueForm)

      refetch()
      message.success(translate('usePause.createSuccessMessage'))
    } catch (err: any) {
      ShowError(err.message, translate('usePause.createErrorMessage'), locale)
    }
  }

  async function updatePause(valueForm: object, pauseId: string) {
    try {
      await api.put(
        `/company/${selectedCompany}/break-reason/${pauseId}`,
        valueForm,
      )

      message.success(translate('usePause.updateSuccessMessage'))
      refetch()
    } catch (err: any) {
      ShowError(err.message, translate('usePause.updateErrorMessage'), locale)
    }
  }

  async function deletePause(pauseId: string) {
    try {
      await api.delete(`/company/${selectedCompany}/break-reason/${pauseId}`)

      refetch()
      message.success(translate('usePause.deleteSuccessMessage'))
    } catch (err: any) {
      ShowError(err.message, translate('usePause.deleteErrorMessage'), locale)
    }
  }

  return (
    <PauseContext.Provider
      value={{
        pause,
        createPause,
        updatePause,
        deletePause,
        isLoading,
        refetch,
        isFetching,
        pauseTotal,
      }}
    >
      {children}
    </PauseContext.Provider>
  )
}

export function usePause(): PauseContextData {
  const context = useContext(PauseContext)

  if (!context) {
    throw new Error('useAuth must be used within an AutoProvider')
  }

  return context
}
