import React from 'react'
import { Helmet } from 'react-helmet'

import CreateTasksTesting from '~/components/DataManipulation/Tasks/CreateTaskTesting'
import { ListParentClientsProvider } from '~/hooks/Clients/useListParentCustomer'
import { ListContributorsProvider } from '~/hooks/Contributors/useListContributors'
import { ListEquipmentsProvider } from '~/hooks/Equipments/ListEquipmentsHooks'
import { TaskCreateAddressFieldsProvider } from '~/hooks/Tasks/Create/Fields/useFieldsTaskAddress'
import { ClientsListTaskProvider } from '~/hooks/Tasks/Create/clientsListTask'
import { TaskCreateProvider } from '~/hooks/Tasks/Create/createTask'
import { ScaleListTaskProvider } from '~/hooks/Tasks/Create/scaleListTask'
import { ScaleTaskProvider } from '~/hooks/Tasks/Create/scaleTask'
import { ContributorsTaskProvider } from '~/hooks/Tasks/Create/useContributorTask'
import { TaskTypeProvider } from '~/hooks/Tasks/TaskType/useTaskType'
import { TaskListProvider } from '~/hooks/Tasks/useListTasks'
import { TeamsProvider } from '~/hooks/Teams/useListTeams'
import { translate } from '~/utils/locale'

// import { Container } from './styles';

const TestingCreateTasks: React.FC = () => {
  return (
    <TaskTypeProvider>
      <Helmet>
        <title>{translate('NewTasks.helmetTitle')}</title>
      </Helmet>
      <ClientsListTaskProvider>
        <ListContributorsProvider>
          <ListEquipmentsProvider>
            <TaskListProvider>
              <TeamsProvider>
                <TaskCreateProvider>
                  <ScaleListTaskProvider>
                    <ScaleTaskProvider>
                      <ContributorsTaskProvider>
                        <TaskListProvider>
                          <ListParentClientsProvider>
                            <TaskCreateAddressFieldsProvider>
                              <CreateTasksTesting />
                            </TaskCreateAddressFieldsProvider>
                          </ListParentClientsProvider>
                        </TaskListProvider>
                      </ContributorsTaskProvider>
                    </ScaleTaskProvider>
                  </ScaleListTaskProvider>
                </TaskCreateProvider>
              </TeamsProvider>
            </TaskListProvider>
          </ListEquipmentsProvider>
        </ListContributorsProvider>
      </ClientsListTaskProvider>
    </TaskTypeProvider>
  )
}

export default TestingCreateTasks
