import styled from 'styled-components'

export const Container = styled.div`
  .buttons {
    display: flex;
    align-items: center;

    .permission {
      margin-left: 5px;
    }
  }
`
