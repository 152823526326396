/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-underscore-dangle */
import {
  Col,
  Form,
  Row,
  Select,
  Input,
  message,
  Card,
  Space,
  Button,
  Tooltip,
  Popconfirm,
  Divider,
} from 'antd'
import React, { useState, useCallback, Fragment } from 'react'

import { ValidateStatus } from 'antd/lib/form/FormItem'
import { MapContainer, Marker, TileLayer, useMap } from 'react-leaflet'
import { LatLngExpression } from 'leaflet'
import cep from 'cep-promise'
import { MaskedInput } from 'antd-mask-input'

import { Link } from 'react-router-dom'
import { ArrowLeftOutlined } from '@ant-design/icons'

import geolocationApi from '~/services/geolocationApi'

import { translate } from '~/utils/locale'

import { useParentCustomer } from '~/hooks/Clients/useParentCustomer'
import { useListParentClients } from '~/hooks/Clients/useListParentCustomer'
import { cnpj, cpf } from 'cpf-cnpj-validator'
import { FormButtonContainer } from '~/pages/Clients/NewClients/styles'
import { ContainerButton } from './styles'

interface ParentClientsProps {
  _id: string
  companyName: string
  fantasyName: string
  cnpj: string
  cpf: string
  name: string
  email: string
  phone: string
  municipal_registration: string
  state_registration: string
  zipcode: string
  street: string
  number: string
  complement: string
  neighborhood: string
  city: string
  state: string
  location: { x: number; y: number }
  latitude: any
  longitude: any
}

interface AddressProps {
  _id: string
  address: {
    zip_code: string
    street: string
    number: string
    complement: string
    neighborhood: string
    city: string
    id: string
    state: string
    location: { x: number; y: number }
  }
}

interface MapUpdateFunctionProps {
  center: LatLngExpression
  zoom: number
}

const UpdateParentClient: React.FC = () => {
  const [form] = Form.useForm()

  const {
    parentCustomer,
    setIsEdit,
    updateParentCustomer,
    refetch: refetchParentCustomer,
  } = useParentCustomer()
  const { refetch } = useListParentClients()

  const [mapValidate, setMapValidate] = useState(false)

  const [latitude, setLatitude] = useState<any>(
    parentCustomer?.address.location.x,
  )
  const [longitude, setLongitude] = useState<any>(
    parentCustomer?.address.location.y,
  )

  const [state, setState] = useState('')
  const [city, setCity] = useState('')
  const [street, setStreet] = useState('')

  const [hidden, setHidden] = useState(true)

  const [mapSearch, setMapSearch] = useState(false)

  const [status, setStatus] = useState('' as ValidateStatus)

  const formatComma = (value: string, field: 'latitude' | 'longitude') => {
    const valueFormatted = value.replace(/,/g, '.')

    if (
      valueFormatted !== '-' &&
      valueFormatted !== '.' &&
      valueFormatted !== '-.'
    ) {
      if (field === 'latitude') {
        setLatitude(valueFormatted)
      } else if (field === 'longitude') {
        setLongitude(valueFormatted)
      }
    }
  }

  const initialValues = {
    companyName: parentCustomer?.companyName,
    fantasyName: parentCustomer?.fantasyName,
    cnpj: parentCustomer?.cnpj,
    cpf: parentCustomer?.cpf,
    name: parentCustomer?.contact.name,
    email: parentCustomer?.contact.email,
    phone: parentCustomer?.contact.phone,
    municipal_registration: parentCustomer?.municipal_registration,
    state_registration: parentCustomer?.state_registration,
    zipcode: parentCustomer?.address.zip_code,
    state: parentCustomer?.address.state,
    city: parentCustomer?.address.city,
    neighborhood: parentCustomer?.address.neighborhood,
    street: parentCustomer?.address.street,
    number: parentCustomer?.address.number,
    complement: parentCustomer?.address.complement,
  }

  const stateOptions = [
    { label: 'Acre', value: 'AC' },
    { label: 'Alagoas', value: 'AL' },
    { label: 'Amapá', value: 'AP' },
    { label: 'Amazonas', value: 'AM' },
    { label: 'Bahia', value: 'BA' },
    { label: 'Ceará', value: 'CE' },
    { label: 'Espírito Santo', value: 'ES' },
    { label: 'Goiás', value: 'GO' },
    { label: 'Maranhão', value: 'MA' },
    { label: 'Mato Grosso', value: 'MT' },
    { label: 'Mato Grosso do Sul', value: 'MS' },
    { label: 'Minas Gerais', value: 'MG' },
    { label: 'Pará', value: 'PA' },
    { label: 'Paraíba', value: 'PB' },
    { label: 'Paraná', value: 'PR' },
    { label: 'Pernambuco', value: 'PE' },
    { label: 'Piauí', value: 'PI' },
    { label: 'Rio de Janeiro', value: 'RJ' },
    { label: 'Rio Grande do Norte', value: 'RN' },
    { label: 'Rio Grande do Sul', value: 'RS' },
    { label: 'Rondônia', value: 'RO' },
    { label: 'Roraima', value: 'RR' },
    { label: 'Santa Catarina', value: 'SC' },
    { label: 'São Paulo', value: 'SP' },
    { label: 'Sergipe', value: 'SE' },
    { label: 'Tocantins', value: 'TO' },
    { label: 'Distrito Federal', value: 'DF' },
  ]

  const searchCEP = useCallback(
    async (address: string) => {
      try {
        setStatus('validating')
        const cepInfo = await cep(address)

        form.setFieldsValue({
          state: cepInfo.state,
          city: cepInfo.city,
          neighborhood: cepInfo.neighborhood,
          street: cepInfo.street,
        })

        setState(cepInfo.state)
        setCity(cepInfo.city)
        setStreet(cepInfo.street)

        setStatus('success')
      } catch (error) {
        message.error(translate('TasksUpdate.searchZipcodeErrorMessage'))
        form.resetFields()
        setState('')
        setCity('')
        setStreet('')
        setStatus('error')
      }
    },
    [form],
  )

  const searchMap = useCallback(async () => {
    try {
      setMapSearch(true)
      const params = {
        street,
        city,
        state,
        format: 'json',
      }

      const response = await geolocationApi.get('search', { params })

      if (response.data.length > 0) {
        setHidden(true)
        form.setFieldsValue({
          latitude: response.data[0].lat,
          longitude: response.data[0].lon,
        })

        setLatitude(response.data[0].lat)
        setLongitude(response.data[0].lon)
      } else {
        setHidden(false)

        form.setFieldsValue({
          latitude: '',
          longitude: '',
        })

        message.warning(translate('TasksUpdate.geolocationWarningMessage'), 10)
      }
      setMapValidate(true)
      setMapSearch(false)
    } catch (error) {
      message.error(translate('TasksUpdate.geolocationErrorMessage'))
      setMapValidate(true)
      setMapSearch(false)
    }
  }, [form, city, state, street])

  function ChangeView({ center, zoom }: MapUpdateFunctionProps) {
    const map = useMap()
    map.setView(center, zoom)
    return null
  }

  function updateTaskForm(valueForm: ParentClientsProps) {
    const dataForm = {
      companyName: valueForm.companyName,
      fantasyName: valueForm.fantasyName,
      cpf: valueForm.cpf.replace(/[^0-9]/g, ''),
      cnpj: valueForm.cnpj.replace(/[^0-9]/g, ''),
      municipal_registration: valueForm.municipal_registration,
      state_registration: valueForm.state_registration,
      contact: {
        email: valueForm.email,
        name: valueForm.name,
        phone: valueForm.phone.replace(/[^0-9]/g, ''),
      },
      financial: {
        task_value: parentCustomer?.financial.task_value,
        amount_hours: parentCustomer?.financial.amount_hours,
        amount_hours_additional:
          parentCustomer?.financial.amount_hours_additional,
        number_km: parentCustomer?.financial.number_km,
        number_km_additional: parentCustomer?.financial.number_km_additional,
      },
      address: {
        zip_code: valueForm.zipcode,
        street: valueForm.street,
        number: valueForm.number,
        complement: valueForm.complement,
        neighborhood: valueForm.neighborhood,
        city: valueForm.city,
        state: valueForm.state,
        location: {
          x: latitude,
          y: longitude,
        },
      },
    }

    try {
      updateParentCustomer(dataForm)
      setIsEdit(false)
      setMapValidate(false)
      refetch()
      refetchParentCustomer()
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Card
      bordered={false}
      title={
        <Space size="large">
          <Tooltip title={translate('TasksUpdate.backTooltip')}>
            <Link to="/clientePrincipal">
              <ArrowLeftOutlined />
            </Link>
          </Tooltip>
          {translate('ClientsPrincipalDetailsUpdate.cardTitle')}
        </Space>
      }
      style={{
        display: 'inline-block',
        width: '100%',
      }}
      extra={
        <Space size="middle">
          <Popconfirm
            title={translate('TasksTable.deletePopconfirm')}
            // onConfirm={() => deleteParentClient(parentCustomer?._id)}
          >
            <Tooltip title={translate('TasksTable.deleteTooltip')}>
              <Button type="primary" danger>
                {translate('ClientsPrincipalDetailsUpdate.deleteButton')}
              </Button>
            </Tooltip>
          </Popconfirm>
          <ContainerButton>
            <Button
              htmlType="submit"
              form="updateParentClient"
              type="primary"
              id="btn_update_parent_client"
            >
              {translate('ClientsPrincipalDetailsUpdate.saveButton')}
            </Button>
          </ContainerButton>
          <Button onClick={() => setIsEdit(false)}>
            {translate('ClientsPrincipalDetailsUpdate.cancelButton')}
          </Button>
        </Space>
      }
    >
      <Form
        layout="vertical"
        onFinish={updateTaskForm}
        initialValues={initialValues}
        id="updateParentClient"
        form={form}
      >
        <Row gutter={14}>
          <Col span={12}>
            <Form.Item
              label={translate('ClientsPrincipalDetailsUpdate.reasonLabel')}
              name="companyName"
              rules={[
                {
                  required: true,
                  message: translate(
                    'ClientsPrincipalDetailsUpdate.reasonRule',
                  ),
                },
              ]}
            >
              <Input
                placeholder={translate(
                  'ClientsPrincipalDetailsUpdate.reasonPlaceholder',
                )}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={translate('ClientsPrincipalDetailsUpdate.fantasyLabel')}
              name="fantasyName"
              rules={[
                {
                  required: true,
                  message: translate(
                    'ClientsPrincipalDetailsUpdate.fantasyRule',
                  ),
                },
              ]}
            >
              <Input
                placeholder={translate(
                  'ClientsPrincipalDetailsUpdate.fantasyPlaceholder',
                )}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={14}>
          <Col span={12}>
            <Form.Item
              label="CNPJ"
              name="cnpj"
              rules={[
                {
                  validator(_, value) {
                    if (value === undefined || value === null) {
                      return Promise.resolve()
                    }
                    const CnpjInput = value.replace(/[^0-9]/g, '')
                    if (CnpjInput.length === 14) {
                      if (cnpj.isValid(value)) {
                        return Promise.resolve()
                      }
                      // eslint-disable-next-line prefer-promise-reject-errors
                      return Promise.reject(
                        translate('NewClients.cnpjRuleMessageTwo'),
                      )
                    }
                    return Promise.resolve()
                  },
                },
              ]}
            >
              <MaskedInput mask={'00.000.000/0000-00'} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="CPF"
              name="cpf"
              rules={[
                {
                  validator(_, value) {
                    if (value === undefined || value === null) {
                      return Promise.resolve()
                    }
                    const CpfInput = value.replace(/[^0-9]/g, '')
                    if (CpfInput.length === 11) {
                      if (cpf.isValid(value)) {
                        return Promise.resolve()
                      }
                      // eslint-disable-next-line prefer-promise-reject-errors
                      return Promise.reject(
                        translate('NewClients.documentRuleMessageTwo'),
                      )
                    }
                    return Promise.resolve()
                  },
                },
              ]}
            >
              <MaskedInput mask={'000.000.000-00'} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={14}>
          <Col span={12}>
            <Form.Item
              label={translate(
                'ClientsPrincipalDetailsUpdate.registrationMunicipalLabel',
              )}
              name="municipal_registration"
            >
              <Input
                placeholder={translate(
                  'ClientsPrincipalDetailsUpdate.registrationMunicipalPlaceholder',
                )}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={translate(
                'ClientsPrincipalDetailsUpdate.registrationStateLabel',
              )}
              name="state_registration"
            >
              <Input
                placeholder={translate(
                  'ClientsPrincipalDetailsUpdate.registrationStatePlaceholder',
                )}
              />
            </Form.Item>
          </Col>
        </Row>

        <Divider>Dados de Contato</Divider>

        <Row gutter={14}>
          <Col span={8}>
            <Form.Item
              label={translate('ClientsPrincipalDetailsUpdate.nameLabel')}
              name="name"
              rules={[
                {
                  required: true,
                  message: translate('ClientsPrincipalDetailsUpdate.nameRule'),
                },
              ]}
            >
              <Input
                placeholder={translate(
                  'ClientsPrincipalDetailsUpdate.namePlaceholder',
                )}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={translate('ClientsPrincipalDetailsUpdate.emailLabel')}
              name="email"
              rules={[
                {
                  required: true,
                  message: translate('ClientsPrincipalDetailsUpdate.emailRule'),
                },
              ]}
            >
              <Input
                placeholder={translate(
                  'ClientsPrincipalDetailsUpdate.emailPlaceholder',
                )}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={translate('ClientsPrincipalDetailsUpdate.phoneLabel')}
              name="phone"
            >
              <MaskedInput mask={'(00) 00000-0000'} />
            </Form.Item>
          </Col>
        </Row>
        <Card title={translate('TasksUpdate.addressCardTitle')}>
          <Row gutter={14}>
            <Col span={4}>
              <Form.Item
                label={translate('TasksUpdate.zipcodeLabel')}
                name="zipcode"
                hasFeedback
                validateStatus={status}
              >
                <MaskedInput
                  onChange={(e) => {
                    const cepInput = e.target.value.replace(/\D/g, '')
                    if (cepInput.length === 8) {
                      searchCEP(e.target.value)
                    }
                  }}
                  mask={'00000-000'}
                />
              </Form.Item>
            </Col>

            <Col span={4}>
              <a
                href="https://buscacepinter.correios.com.br/app/endereco/index.php"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Form.Item label={translate('TasksUpdate.infoZipcodeLabel')}>
                  <Button key="submit" type="primary">
                    {translate('TasksUpdate.infoZipcodeButton')}
                  </Button>
                </Form.Item>
              </a>
            </Col>
            <Col span={8}>
              <Form.Item
                label={translate('TasksUpdate.stateLabel')}
                name="state"
              >
                <Select
                  style={{ width: '100%' }}
                  placeholder={translate('TasksUpdate.statePlaceholder')}
                  className="menu_input"
                  onChange={(e) => setState(String(e))}
                  options={stateOptions}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={translate('TasksUpdate.cityLabel')} name="city">
                <Input
                  placeholder={translate('TasksUpdate.cityPlaceholder')}
                  onChange={(e) => setCity(e.target.value)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={14}>
            <Col span={8}>
              <Form.Item
                label={translate('TasksUpdate.neighborhoodLabel')}
                name="neighborhood"
              >
                <Input
                  placeholder={translate('TasksUpdate.neighborhoodPlaceholder')}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={translate('TasksUpdate.streetLabel')}
                name="street"
              >
                <Input
                  placeholder={translate('TasksUpdate.streetPlaceholder')}
                  onChange={(e) => setStreet(e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={translate('TasksUpdate.numberLabel')}
                name="number"
                rules={[
                  {
                    required: true,
                    message: translate('TasksUpdate.numberRule'),
                  },
                ]}
              >
                <Input
                  placeholder={translate('TasksUpdate.numberPlaceholder')}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={14}>
            <Col span={4.8}>
              <Form.Item
                label={translate('TasksUpdate.complementLabel')}
                name="complement"
              >
                <Input
                  placeholder={translate('TasksUpdate.complementPlaceholder')}
                />
              </Form.Item>
            </Col>
            <Col span={4.8}>
              <Form.Item label={translate('TasksUpdate.mapLabel')} name="mapa">
                <Button
                  key="submit"
                  type="primary"
                  disabled={!!(state === '' || street === '' || city === '')}
                  loading={mapSearch}
                  onClick={searchMap}
                >
                  {translate('TasksUpdate.mapButton')}
                </Button>
              </Form.Item>
            </Col>
            <Col span={4.8}>
              <Form.Item
                hidden={hidden}
                label={translate('TasksUpdate.latitudeLabel')}
                name="latitude"
                initialValue={latitude}
                rules={[
                  {
                    required: !hidden,
                    message: translate('TasksUpdate.latitudeRule'),
                  },
                ]}
              >
                <Input
                  onChange={(e) => formatComma(e.target.value, 'latitude')}
                />
              </Form.Item>
            </Col>
            <Col span={4.8}>
              <Form.Item
                hidden={hidden}
                label={translate('TasksUpdate.longitudeLabel')}
                name="longitude"
                initialValue={longitude}
                rules={[
                  {
                    required: !hidden,
                    message: translate('TasksUpdate.longitudeRule'),
                  },
                ]}
              >
                <Input
                  onChange={(e) => formatComma(e.target.value, 'longitude')}
                />
              </Form.Item>
            </Col>
            <Col span={4.8}>
              <a
                href="https://www.latlong.net/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Form.Item
                  label={translate('TasksUpdate.coordinatesInfoLabel')}
                  hidden={hidden}
                >
                  <Button type="primary">
                    {translate('TasksUpdate.coordinatesInfoButton')}
                  </Button>
                </Form.Item>
              </a>
            </Col>
          </Row>
          <MapContainer
            center={[latitude, longitude]}
            zoom={13}
            scrollWheelZoom={false}
            style={{ height: '350px', width: '100%' }}
          >
            <ChangeView center={[latitude, longitude]} zoom={17} />
            <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {latitude !== 0 && longitude !== 0 && (
              <Marker position={[latitude, longitude]} />
            )}
          </MapContainer>

          <FormButtonContainer>
            <Space size="large" style={{ alignItems: 'baseline' }}>
              <Button htmlType="submit" type="primary">
                {translate('TasksUpdate.saveButton')}
              </Button>
              <Button onClick={() => setIsEdit(false)}>
                {translate('TasksUpdate.cancelButton')}
              </Button>
            </Space>
          </FormButtonContainer>
        </Card>
      </Form>
    </Card>
  )
}

export default UpdateParentClient
