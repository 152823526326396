// Import das Páginas
import Page404 from '~/utils/locale/pages/404'
import PageConfirmCode from '~/utils/locale/pages/Auth/ConfirmCode'
import PageForgotPassword from '~/utils/locale/pages/Auth/ForgotPassword'
import PageResetPassword from '~/utils/locale/pages/Auth/ResetPassword'
import PageSignIn from '~/utils/locale/pages/Auth/SignIn'
import PageSignUp from '~/utils/locale/pages/Auth/SignUp'
import Clients from '~/utils/locale/pages/Clients'
import NewClients from '~/utils/locale/pages/Clients/NewClients'
import NewContacts from '~/utils/locale/pages/Clients/NewContacts'
import ShowClients from '~/utils/locale/pages/Clients/ShowClients'
import ShowContacts from '~/utils/locale/pages/Clients/ShowContacts'
import Contributors from '~/utils/locale/pages/Contributor/Contributors'
import NewContributor from '~/utils/locale/pages/Contributor/NewContributor'
import ShowContributor from '~/utils/locale/pages/Contributor/ShowContributor'
import Dashboard from '~/utils/locale/pages/Dashboard'
import Equipments from '~/utils/locale/pages/Equipments'
import NewEquipments from '~/utils/locale/pages/Equipments/NewEquipments'
import ShowCategory from '~/utils/locale/pages/Equipments/ShowCategory'
import ShowEquipments from '~/utils/locale/pages/Equipments/ShowEquipments'
import ShowTemplates from '~/utils/locale/pages/Equipments/ShowTemplates'
import Groups from '~/utils/locale/pages/Groups'
import ShowGroups from '~/utils/locale/pages/Groups/ShowGroups'
import Kilometers from '~/utils/locale/pages/Report/Kilometers'
import ReportTasks from '~/utils/locale/pages/Report/Tasks'
import EditScale from '~/utils/locale/pages/Scale/EditScale'
import Scales from '~/utils/locale/pages/Scale/Scales'
import Segments from '~/utils/locale/pages/Segments'
import Skills from '~/utils/locale/pages/Skills'
import Status from '~/utils/locale/pages/Status'
import ShowStatus from '~/utils/locale/pages/Status/ShowStatus'
import Tasks from '~/utils/locale/pages/Tasks'
import ListTasks from '~/utils/locale/pages/Tasks/ListTasks'
import NewTasks from '~/utils/locale/pages/Tasks/NewTasks'
import ShowTasks from '~/utils/locale/pages/Tasks/ShowTasks'
import Pause from '~/utils/locale/pages/TasksRegistration/Pause'
import TaskType from '~/utils/locale/pages/TasksRegistration/TaskType'
import NewTaskType from '~/utils/locale/pages/TasksRegistration/TaskType/NewTaskType'
import Teams from '~/utils/locale/pages/Teams'
import ShowTeams from '~/utils/locale/pages/Teams/ShowTeams'
import UserAccess from '~/utils/locale/pages/UserAccess'
import Users from '~/utils/locale/pages/Users'
import ShowUsers from '~/utils/locale/pages/Users/ShowUsers'
import HelpDeskShowPage from '~/utils/locale/pages/HelpDesk/ShowTicket'
import TicketTypePage from '~/utils/locale/pages/HelpDesk/TicketType'

// Import dos Hooks
import AuthContext from '~/utils/locale/hooks/Auth/AuthContext'
import useClients from '~/utils/locale/hooks/Clients/useClients'
import useContactClients from '~/utils/locale/hooks/Clients/useContactClients'
import useListClients from '~/utils/locale/hooks/Clients/useListClients'
import useListContacts from '~/utils/locale/hooks/Clients/useListContacts'
import useCompanies from '~/utils/locale/hooks/Companies/useCompanies'
import useContributor from '~/utils/locale/hooks/Contributors/useContributor'
import useListContributors from '~/utils/locale/hooks/Contributors/useListContributors'
import useDashboard from '~/utils/locale/hooks/Dashboard/useDashboard'
import useAttachments from '~/utils/locale/hooks/Equipments/Attachments/useAttachments'
import CategoryHooks from '~/utils/locale/hooks/Equipments/Category/CategoryHooks'
import ListTemplate from '~/utils/locale/hooks/Equipments/Especification/ListTemplate'
import useSpecifications from '~/utils/locale/hooks/Equipments/Especification/useSpecifications'
import useTemplate from '~/utils/locale/hooks/Equipments/Especification/useTemplate'
import useMovements from '~/utils/locale/hooks/Equipments/Movements/useMovements'
import AssociatedEquipments from '~/utils/locale/hooks/Equipments/AssociatedEquipments'
import EquipmentsHooks from '~/utils/locale/hooks/Equipments/EquipmentsHooks'
import ListEquipmentsHooks from '~/utils/locale/hooks/Equipments/ListEquipmentsHooks'
import useEvaluations from '~/utils/locale/hooks/Evaluations/useEvaluations'
import useGroup from '~/utils/locale/hooks/Groups/useGroup'
import useListGroup from '~/utils/locale/hooks/Groups/useListGroup'
import useModule from '~/utils/locale/hooks/Groups/useModule'
import usePermission from '~/utils/locale/hooks/Groups/usePermission'
import useUserGroup from '~/utils/locale/hooks/Groups/useUserGroup'
import useReportKilometers from '~/utils/locale/hooks/Report/Kilometers/useReportKilometers'
import useReportTasks from '~/utils/locale/hooks/Report/Tasks/useReportTasks'
import useListScales from '~/utils/locale/hooks/Scales/useListScales'
import useScale from '~/utils/locale/hooks/Scales/useScale'
import useSegments from '~/utils/locale/hooks/Segments/useSegments'
import useSkills from '~/utils/locale/hooks/Skills/useSkills'
import useListStatus from '~/utils/locale/hooks/Status/useListStatus'
import useStatus from '~/utils/locale/hooks/Status/useStatus'
import usePause from '~/utils/locale/hooks/Tasks/Pause/usePause'
import useTaskType from '~/utils/locale/hooks/Tasks/TaskType/useTaskType'
import useTaskForms from '~/utils/locale/hooks/Tasks/Forms/useTaskForms'
import useListTasks from '~/utils/locale/hooks/Tasks/useListTasks'
import useTask from '~/utils/locale/hooks/Tasks/useTask'
import useListTeams from '~/utils/locale/hooks/Teams/useListTeams'
import useTeams from '~/utils/locale/hooks/Teams/useTeams'
import useListUsers from '~/utils/locale/hooks/Users/useListUsers'
import useUsers from '~/utils/locale/hooks/Users/useUsers'
import NewHelpDesk from '~/utils/locale/pages/HelpDesk/NewTicket'
import useTicketType from '~/utils/locale/hooks/HelpDesk/useTicketType'
import useListTickets from '~/utils/locale/hooks/HelpDesk/useListTickets'
import useTicket from '~/utils/locale/hooks/HelpDesk/useTicket'
import useServiceStatus from '~/utils/locale/hooks/ServiceStatus/useServiceStatus'

// Import dos Componentes
import ConfirmPageWithNoToken from '~/utils/locale/components/CodeConfirmation/ConfirmPageWithNoToken'
import ConfirmPageWithToken from '~/utils/locale/components/CodeConfirmation/ConfirmPageWithToken'
import Navbar from '~/utils/locale/components/DefaultLayout/Navbar'
import NewCompanyModal from '~/utils/locale/components/DefaultLayout/NewCompanyModal'
import SelectCompany from '~/utils/locale/components/DefaultLayout/SelectCompany'
import Sidebar from '~/utils/locale/components/DefaultLayout/Sidebar'
import AddressList from '~/utils/locale/components/DataManipulation/Address/AddressList'
import AddressModal from '~/utils/locale/components/DataManipulation/Address/AddressModal'
import AttachmentsList from '~/utils/locale/components/DataManipulation/Attachment/AttachmentsList'
import AttachmentsModal from '~/utils/locale/components/DataManipulation/Attachment/AttachmentsModal'
import ClientsFilter from '~/utils/locale/components/DataManipulation/Clients/ClientsFilter'
import ClientsTable from '~/utils/locale/components/DataManipulation/Clients/ClientsTable'
import ClientsAddressTab from '~/utils/locale/components/DataManipulation/Clients/ClientTabs/AddressTab'
import ClientsAdditionalPhonesCreate from '~/utils/locale/components/DataManipulation/Clients/ClientTabs/AditionalPhones/Create'
import ClientsAdditionalPhones from '~/utils/locale/components/DataManipulation/Clients/ClientTabs/AditionalPhones'
import ClientsChargeTab from '~/utils/locale/components/DataManipulation/Clients/ClientTabs/ChargeTab'
import ClientsContactsTabCreate from '~/utils/locale/components/DataManipulation/Clients/ClientTabs/ContactsTab/Create'
import ClientsContactsTabFilter from '~/utils/locale/components/DataManipulation/Clients/ClientTabs/ContactsTab/Filter'
import ClientsContactsTabUpdate from '~/utils/locale/components/DataManipulation/Clients/ClientTabs/ContactsTab/Update'
import ClientsContactsTab from '~/utils/locale/components/DataManipulation/Clients/ClientTabs/ContactsTab'
import ClientsDetailsTab from '~/utils/locale/components/DataManipulation/Clients/ClientTabs/DetailsTab'
import ClientsTalkToContact from '~/utils/locale/components/DataManipulation/Clients/ClientTabs/TalkToContact'
import ClientsTabs from '~/utils/locale/components/DataManipulation/Clients/ClientTabs'
import ClientsContactsCreate from '~/utils/locale/components/DataManipulation/Clients/Contacts/Create'
import ClientsContactsFilter from '~/utils/locale/components/DataManipulation/Clients/Contacts/Filter'
import ClientsContactsTable from '~/utils/locale/components/DataManipulation/Clients/Contacts/Table'
import ClientsContacts from '~/utils/locale/components/DataManipulation/Clients/Contacts'
import ClientsUpdateCharge from '~/utils/locale/components/DataManipulation/Clients/Update/Charge'
import ClientsUpdate from '~/utils/locale/components/DataManipulation/Clients/Update/ClientsUpdate'
import ClientsComponent from '~/utils/locale/components/DataManipulation/Clients'
import ContributorCreate from '~/utils/locale/components/DataManipulation/Contributor/ContributorForm/ContributorCreate'
import ContributorDetails from '~/utils/locale/components/DataManipulation/Contributor/ContributorForm/ContributorDetails'
import ContributorUpdate from '~/utils/locale/components/DataManipulation/Contributor/ContributorForm/ContributorUpdate'
import ContributorFilter from '~/utils/locale/components/DataManipulation/Contributor/ContributorsFilter'
import ContributorTable from '~/utils/locale/components/DataManipulation/Contributor/ContributorsTable'
import ContributorTabs from '~/utils/locale/components/DataManipulation/Contributor/ContributorsTabs'
import ContributorWorkday from '~/utils/locale/components/DataManipulation/Contributor/ContributorWorkday'
import Contributor from '~/utils/locale/components/DataManipulation/Contributor'
import DashboardCards from '~/utils/locale/components/DataManipulation/Dashboard/Cards'
import DashboardFilter from '~/utils/locale/components/DataManipulation/Dashboard/Filter'
import DashboardMap from '~/utils/locale/components/DataManipulation/Dashboard/Map'
import DashboardRates from '~/utils/locale/components/DataManipulation/Dashboard/Rates'
import EquipmentsCategoryBoard from '~/utils/locale/components/DataManipulation/Equipments/Category/Board'
import EquipmentsCategoryCreate from '~/utils/locale/components/DataManipulation/Equipments/Category/Create'
import EquipmentsCategoryUpdate from '~/utils/locale/components/DataManipulation/Equipments/Category/Update'
import EquipmentsCategory from '~/utils/locale/components/DataManipulation/Equipments/Category'
import EquipmentsCreate from '~/utils/locale/components/DataManipulation/Equipments/Create'
import EquipmentsFilter from '~/utils/locale/components/DataManipulation/Equipments/Filter'
import EquipmentsTable from '~/utils/locale/components/DataManipulation/Equipments/Table'
import EquipmentsTabsAssociatedEquipmentsCreate from '~/utils/locale/components/DataManipulation/Equipments/Tabs/AssociatedEquipments/Create'
import EquipmentsTabsAttachmentsCreate from '~/utils/locale/components/DataManipulation/Equipments/Tabs/AttachmentsTab/Create'
import EquipmentsTabsAttachmentsUpdate from '~/utils/locale/components/DataManipulation/Equipments/Tabs/AttachmentsTab/Update'
import EquipmentsTabsAttachments from '~/utils/locale/components/DataManipulation/Equipments/Tabs/AttachmentsTab'
import EquipmentsTabsDetails from '~/utils/locale/components/DataManipulation/Equipments/Tabs/DetailsTab'
import EquipmentsTabsMovementsCreate from '~/utils/locale/components/DataManipulation/Equipments/Tabs/MovementsTab/Create'
import EquipmentsTabsMovements from '~/utils/locale/components/DataManipulation/Equipments/Tabs/MovementsTab'
import EquipmentsTabsSpecificationCreate from '~/utils/locale/components/DataManipulation/Equipments/Tabs/SpecificationTab/Create'
import EquipmentsTabsSpecificationImportTemplate from '~/utils/locale/components/DataManipulation/Equipments/Tabs/SpecificationTab/ImportTemplate'
import EquipmentsTabsSpecificationUpdate from '~/utils/locale/components/DataManipulation/Equipments/Tabs/SpecificationTab/Update'
import EquipmentsTabsSpecification from '~/utils/locale/components/DataManipulation/Equipments/Tabs/SpecificationTab'
import EquipmentsTabsTasks from '~/utils/locale/components/DataManipulation/Equipments/Tabs/TasksTab'
import EquipmentsTabs from '~/utils/locale/components/DataManipulation/Equipments/Tabs'
import EquipmentsTemplateCreate from '~/utils/locale/components/DataManipulation/Equipments/Template/Create'
import EquipmentsTemplateTable from '~/utils/locale/components/DataManipulation/Equipments/Template/Table'
import EquipmentsTemplateUpdate from '~/utils/locale/components/DataManipulation/Equipments/Template/Update'
import EquipmentsTemplate from '~/utils/locale/components/DataManipulation/Equipments/Template'
import EquipmentsUpdateDetails from '~/utils/locale/components/DataManipulation/Equipments/Update/Details'
import EquipmentsComponent from '~/utils/locale/components/DataManipulation/Equipments'
import EvaluationList from '~/utils/locale/components/DataManipulation/Evaluation/EvaluationList'
import EvaluationModal from '~/utils/locale/components/DataManipulation/Evaluation/EvaluationModal'
import EvaluationInternalModal from '~/utils/locale/components/DataManipulation/Evaluation/InternalEvaluationModal'
import FCCalendarHeader from '~/utils/locale/components/DataManipulation/FCCalendar/CalendarHeader'
import GroupsBoard from '~/utils/locale/components/DataManipulation/Groups/Board'
import GroupsCreatePermissions from '~/utils/locale/components/DataManipulation/Groups/Create/Permissions'
import GroupsCreateUserGroup from '~/utils/locale/components/DataManipulation/Groups/Create/UserGroup'
import GroupsCreateContributorPermission from '~/utils/locale/components/DataManipulation/Groups/Create/ContributorPermission'
import GroupsCreate from '~/utils/locale/components/DataManipulation/Groups/Create'
import GroupsDetailsPermissions from '~/utils/locale/components/DataManipulation/Groups/Details/Permissions'
import GroupsDetails from '~/utils/locale/components/DataManipulation/Groups/Details'
import GroupsFilter from '~/utils/locale/components/DataManipulation/Groups/Filter'
import GroupsTable from '~/utils/locale/components/DataManipulation/Groups/Table'
import GroupsUpdateGroup from '~/utils/locale/components/DataManipulation/Groups/Update/Group'
import GroupsUpdatePermissions from '~/utils/locale/components/DataManipulation/Groups/Update/Permissions'
import GroupsComponent from '~/utils/locale/components/DataManipulation/Groups'
import MiscAddAddressToScaleButton from '~/utils/locale/components/DataManipulation/Misc/AddAddressToScaleButton'
import MiscAddToScaleButton from '~/utils/locale/components/DataManipulation/Misc/AddToScaleButton'
import MiscAvatarUploader from '~/utils/locale/components/DataManipulation/Misc/AvatarUploader'
import MiscMoreOptions from '~/utils/locale/components/DataManipulation/Misc/MoreOptions'
import MiscWeekdaySelector from '~/utils/locale/components/DataManipulation/Misc/WeekdaySelector'
import ReportKilometersData from '~/utils/locale/components/DataManipulation/Report/Kilometers/Data'
import ReportKilometersExport from '~/utils/locale/components/DataManipulation/Report/Kilometers/Export'
import ReportKilometersFilter from '~/utils/locale/components/DataManipulation/Report/Kilometers/Filter'
import ReportKilometers from '~/utils/locale/components/DataManipulation/Report/Kilometers'
import ReportTasksExport from '~/utils/locale/components/DataManipulation/Report/Tasks/Export'
import ReportTasksFilter from '~/utils/locale/components/DataManipulation/Report/Tasks/Filter'
import ReportTasksGraphics from '~/utils/locale/components/DataManipulation/Report/Tasks/Graphics'
import ReportTasksModal from '~/utils/locale/components/DataManipulation/Report/Tasks/Modal'
import ReportTasksTable from '~/utils/locale/components/DataManipulation/Report/Tasks/Table'
import ReportTasksComponent from '~/utils/locale/components/DataManipulation/Report/Tasks'
import ScalesNewScaleModal from '~/utils/locale/components/DataManipulation/Scales/NewScaleModal'
import ScalesCalendarDrawer from '~/utils/locale/components/DataManipulation/Scales/ScaleCalendar/ScaleCalendarDrawer'
import ScalesCalendarModal from '~/utils/locale/components/DataManipulation/Scales/ScaleCalendar/ScaleModal'
import ScalesCalendar from '~/utils/locale/components/DataManipulation/Scales/ScaleCalendar'
import ScalesClientsAdd from '~/utils/locale/components/DataManipulation/Scales/ScaleClients/ScaleClientsAdd'
import ScalesClientsForm from '~/utils/locale/components/DataManipulation/Scales/ScaleClients/ScaleClientsForm'
import ScalesClientsList from '~/utils/locale/components/DataManipulation/Scales/ScaleClients/ScaleClientsList'
import ScalesClientsTable from '~/utils/locale/components/DataManipulation/Scales/ScaleClients/ScaleClientsTable'
import ScalesContributorsAdd from '~/utils/locale/components/DataManipulation/Scales/ScaleContributors/ScaleContributorsAdd'
import ScalesContributorsForm from '~/utils/locale/components/DataManipulation/Scales/ScaleContributors/ScaleContributorsForm'
import ScalesContributorsList from '~/utils/locale/components/DataManipulation/Scales/ScaleContributors/ScaleContributorsList'
import ScalesContributorsTable from '~/utils/locale/components/DataManipulation/Scales/ScaleContributors/ScaleContributorsTable'
import ScalesGeneralAddressList from '~/utils/locale/components/DataManipulation/Scales/ScaleGeneral/ScaleGeneralAddressList'
import ScalesGeneralAddressModal from '~/utils/locale/components/DataManipulation/Scales/ScaleGeneral/ScaleGeneralAddressModal'
import ScalesGeneralForm from '~/utils/locale/components/DataManipulation/Scales/ScaleGeneral/ScaleGeneralForm'
import ScalesFilter from '~/utils/locale/components/DataManipulation/Scales/ScalesFilter'
import ScalesTable from '~/utils/locale/components/DataManipulation/Scales/ScalesTable'
import ScalesTabs from '~/utils/locale/components/DataManipulation/Scales/ScaleTabs'
import ScalesTeamsAdd from '~/utils/locale/components/DataManipulation/Scales/ScaleTeams/ScaleTeamsAdd'
import ScalesTeamsForm from '~/utils/locale/components/DataManipulation/Scales/ScaleTeams/ScaleTeamsForm'
import ScalesTeamsList from '~/utils/locale/components/DataManipulation/Scales/ScaleTeams/ScaleTeamsList'
import ScalesTeamsTable from '~/utils/locale/components/DataManipulation/Scales/ScaleTeams/ScaleTeamsTable'
import ScalesTimeAddModal from '~/utils/locale/components/DataManipulation/Scales/ScaleTimeAddModal'
import ScalesComponent from '~/utils/locale/components/DataManipulation/Scales'
import SegmentsCreate from '~/utils/locale/components/DataManipulation/Segments/Create'
import SegmentsTable from '~/utils/locale/components/DataManipulation/Segments/SegmentsTable'
import SegmentsUpdate from '~/utils/locale/components/DataManipulation/Segments/Update'
import SegmentsComponent from '~/utils/locale/components/DataManipulation/Segments'
import SkillModal from '~/utils/locale/components/DataManipulation/Skill/SkillModal'
import SkillSkills from '~/utils/locale/components/DataManipulation/Skill/Skills'
import SkillSkillsList from '~/utils/locale/components/DataManipulation/Skill/SkillsList'
import SkillSkillsTable from '~/utils/locale/components/DataManipulation/Skill/SkillsTable'
import Skill from '~/utils/locale/components/DataManipulation/Skill'
import StatusContributorUpdate from '~/utils/locale/components/DataManipulation/Status/Contributor/Update'
import StatusContributor from '~/utils/locale/components/DataManipulation/Status/Contributor'
import StatusCreate from '~/utils/locale/components/DataManipulation/Status/Create'
import StatusDetails from '~/utils/locale/components/DataManipulation/Status/Details'
import StatusTable from '~/utils/locale/components/DataManipulation/Status/Table'
import StatusUpdate from '~/utils/locale/components/DataManipulation/Status/Update'
import StatusComponent from '~/utils/locale/components/DataManipulation/Status'
import TeamsCollaborator from '~/utils/locale/components/DataManipulation/Teams/Collaborator'
import TeamsCreate from '~/utils/locale/components/DataManipulation/Teams/Create'
import TeamsDetails from '~/utils/locale/components/DataManipulation/Teams/Details'
import TeamsTable from '~/utils/locale/components/DataManipulation/Teams/Table'
import TeamsUpdate from '~/utils/locale/components/DataManipulation/Teams/Update'
import TeamsComponent from '~/utils/locale/components/DataManipulation/Teams'
import UsersDetails from '~/utils/locale/components/DataManipulation/Users/Details'
import UsersFilter from '~/utils/locale/components/DataManipulation/Users/Filter'
import UsersGroup from '~/utils/locale/components/DataManipulation/Users/Group'
import UsersTable from '~/utils/locale/components/DataManipulation/Users/Table'
import UsersUpdate from '~/utils/locale/components/DataManipulation/Users/Update'
import UsersUpdatePassword from '~/utils/locale/components/DataManipulation/Users/UpdatePassword'
import UsersComponent from '~/utils/locale/components/DataManipulation/Users'
import TasksBoardsCalendar from '~/utils/locale/components/DataManipulation/Tasks/Boards/Calendar'
import TasksBoardsKanban from '~/utils/locale/components/DataManipulation/Tasks/Boards/Kanban'
import TasksBoardsList from '~/utils/locale/components/DataManipulation/Tasks/Boards/List'
import TasksCreateAddress from '~/utils/locale/components/DataManipulation/Tasks/Create/Address'
import TasksCreateDetails from '~/utils/locale/components/DataManipulation/Tasks/Create/Details'
import TasksCreateModalClient from '~/utils/locale/components/DataManipulation/Tasks/Create/Modal/Client'
import TasksCreateModalContributor from '~/utils/locale/components/DataManipulation/Tasks/Create/Modal/Contributor'
import TasksCreateModalScaleFilterModal from '~/utils/locale/components/DataManipulation/Tasks/Create/Modal/Scale/ScalesFilterModal'
import TasksCreateModalScale from '~/utils/locale/components/DataManipulation/Tasks/Create/Modal/Scale'
import TasksCreateModalTaskType from '~/utils/locale/components/DataManipulation/Tasks/Create/Modal/TaskType'
import TasksCreateModalTeams from '~/utils/locale/components/DataManipulation/Tasks/Create/Modal/Teams'
import TasksCreateModalTask from '~/utils/locale/components/DataManipulation/Tasks/Create/Modal/Task'
import TasksCreateResponsible from '~/utils/locale/components/DataManipulation/Tasks/Create/Responsible'
import TasksCreate from '~/utils/locale/components/DataManipulation/Tasks/Create'
import TasksFilter from '~/utils/locale/components/DataManipulation/Tasks/Filter'
import TasksOptionsCalendarListModal from '~/utils/locale/components/DataManipulation/Tasks/Options/Calendar/List/SecondModal'
import TasksOptionsCalendarList from '~/utils/locale/components/DataManipulation/Tasks/Options/Calendar/List'
import TasksOptionsCalendar from '~/utils/locale/components/DataManipulation/Tasks/Options/Calendar'
import TasksOptionsKanbanCards from '~/utils/locale/components/DataManipulation/Tasks/Options/Kanban/Cards'
import TasksOptionsKanbanConfigurations from '~/utils/locale/components/DataManipulation/Tasks/Options/Kanban/Configurations'
import TasksOptionsKanbanFilter from '~/utils/locale/components/DataManipulation/Tasks/Options/Kanban/Filter'
import TasksOptionsKanbanList from '~/utils/locale/components/DataManipulation/Tasks/Options/Kanban/List'
import TasksOptionsTable from '~/utils/locale/components/DataManipulation/Tasks/Options/Table'
import TasksPrintButton from '~/utils/locale/components/DataManipulation/Tasks/Print/PrintButton'
import TasksPrintPDF from '~/utils/locale/components/DataManipulation/Tasks/Print/TaskPDF'
import TasksTable from '~/utils/locale/components/DataManipulation/Tasks/Table'
import TasksTabsDetails from '~/utils/locale/components/DataManipulation/Tasks/Tabs/Details'
import TasksTabsEquipmentsCreate from '~/utils/locale/components/DataManipulation/Tasks/Tabs/Equipments/Create'
import TasksTabsEquipments from '~/utils/locale/components/DataManipulation/Tasks/Tabs/Equipments'
import TasksTabsReportCreate from '~/utils/locale/components/DataManipulation/Tasks/Tabs/Report/Create'
import TasksTabsReport from '~/utils/locale/components/DataManipulation/Tasks/Tabs/Report'
import TasksTabsStatus from '~/utils/locale/components/DataManipulation/Tasks/Tabs/Status'
import TasksTabs from '~/utils/locale/components/DataManipulation/Tasks/Tabs'
import TasksCalendarModal from '~/utils/locale/components/DataManipulation/Tasks/TaskCalendarModal'
import TasksCalendar from '~/utils/locale/components/DataManipulation/Tasks/TasksCalendar'
import TasksUpdate from '~/utils/locale/components/DataManipulation/Tasks/Update'
import TasksRegistrationPauseCreate from '~/utils/locale/components/DataManipulation/TasksRegistration/Pause/Create'
import TasksRegistrationPauseTable from '~/utils/locale/components/DataManipulation/TasksRegistration/Pause/Table'
import TasksRegistrationPauseUpdate from '~/utils/locale/components/DataManipulation/TasksRegistration/Pause/Update'
import TasksRegistrationPause from '~/utils/locale/components/DataManipulation/TasksRegistration/Pause'
import TasksRegistrationTypeTasksAbout from '~/utils/locale/components/DataManipulation/TasksRegistration/TypeTasks/About'
import TasksRegistrationTypeTasksCreate from '~/utils/locale/components/DataManipulation/TasksRegistration/TypeTasks/Create'
import TasksRegistrationTypeTasksTable from '~/utils/locale/components/DataManipulation/TasksRegistration/TypeTasks/Table'
import TasksRegistrationTypeTasks from '~/utils/locale/components/DataManipulation/TasksRegistration/TypeTasks'
import TasksRegistrationFormsAbout from '~/utils/locale/components/DataManipulation/TasksRegistration/Form/About'
import TasksRegistrationFormsCreate from '~/utils/locale/components/DataManipulation/TasksRegistration/Form/Create'
import TasksRegistrationFormsTable from '~/utils/locale/components/DataManipulation/TasksRegistration/Form/Table'
import TasksRegistrationForms from '~/utils/locale/components/DataManipulation/TasksRegistration/Form'
import HelpDesk from '~/utils/locale/components/HelpDesk'
import HelpDeskTable from '~/utils/locale/components/HelpDesk/Table'
import HelpDeskFilter from '~/utils/locale/components/HelpDesk/Filter'
import HelpDeskCreate from '~/utils/locale/components/HelpDesk/Create'
import HelpDeskTabs from '~/utils/locale/components/HelpDesk/Tabs'
import HelpDeskDetailTab from '~/utils/locale/components/HelpDesk/Tabs/Details'
import HelpDeskClientTab from '~/utils/locale/components/HelpDesk/Tabs/DetailsClient'
import TicketsType from '~/utils/locale/components/HelpDesk/TicketType'
import CreateTicketType from '~/utils/locale/components/HelpDesk/TicketType/Create'
import TicketTypeTable from '~/utils/locale/components/HelpDesk/TicketType/Table'
import TicketTypeUpdate from '~/utils/locale/components/HelpDesk/TicketType/Update'
import TicketTaskLink from '~/utils/locale/components/HelpDesk/Tabs/Tasks/Link'
import ServiceStatus from '~/utils/locale/components/DataManipulation/ServiceStatus'
import CompanyLogo from '~/utils/locale/components/DefaultLayout/CompanyLogo'

import ClientsParent from '~/utils/locale/components/DataManipulation/Clients/Parent'
import ClientsParentTable from '~/utils/locale/components/DataManipulation/Clients/Parent/Table'
import ClientsPrincipalSteps from '~/utils/locale/components/DataManipulation/Clients/Parent/Create'
import ClientsPrincipalDetailsCreate from '~/utils/locale/components/DataManipulation/Clients/Parent/Create/Details'
import ClientsPrincipalFinancialCreate from '~/utils/locale/components/DataManipulation/Clients/Parent/Create/Financial'
import ClientsParentTaskTypeModalCreate from '~/utils/locale/components/DataManipulation/Clients/Parent/Create/Modal'
import ClientParentTabs from '~/utils/locale/components/DataManipulation/Clients/Parent/Tabs'
import ClientsPrincipalTabsDetails from '~/utils/locale/components/DataManipulation/Clients/Parent/Tabs/Details'
import ClientsPrincipalTabsFinancial from '~/utils/locale/components/DataManipulation/Clients/Parent/Tabs/Financial'
import ClientsPrincipalTabsClient from '~/utils/locale/components/DataManipulation/Clients/Parent/Tabs/Clients'
import ClientsPrincipalDetailsUpdate from '~/utils/locale/components/DataManipulation/Clients/Parent/Update'
import ClientsPrincipalFinancialUpdate from '~/utils/locale/components/DataManipulation/Clients/Parent/Tabs/Financial/Update'

import FinancialComponent from '~/utils/locale/components/DataManipulation/Financial'
import FinancialReceive from '~/utils/locale/components/DataManipulation/Financial/Receive'

import TeamsComponentTabFinancial from '~/utils/locale/components/DataManipulation/Teams/Financial'
import TeamsComponentTabFinancialUpdate from '~/utils/locale/components/DataManipulation/Teams/Financial/Update'

import BadgeComponent from '~/utils/locale/components/DataManipulation/Badge'

import DropdownNotifications from './components/DataManipulation/Misc/DropdownNotifications'

export const SpanishLocalization = {
  ...Page404.es,
  ...PageConfirmCode.es,
  ...PageForgotPassword.es,
  ...PageResetPassword.es,
  ...PageSignIn.es,
  ...PageSignUp.es,
  ...Clients.es,
  ...NewClients.es,
  ...NewContacts.es,
  ...ShowClients.es,
  ...ShowContacts.es,
  ...Contributors.es,
  ...NewContributor.es,
  ...ShowContributor.es,
  ...Dashboard.es,
  ...Equipments.es,
  ...NewEquipments.es,
  ...ShowCategory.es,
  ...ShowEquipments.es,
  ...ShowTemplates.es,
  ...Groups.es,
  ...ShowGroups.es,
  ...Kilometers.es,
  ...ReportTasks.es,
  ...EditScale.es,
  ...Scales.es,
  ...Segments.es,
  ...Skills.es,
  ...Status.es,
  ...ShowStatus.es,
  ...Tasks.es,
  ...ListTasks.es,
  ...NewTasks.es,
  ...ShowTasks.es,
  ...Pause.es,
  ...TaskType.es,
  ...NewTaskType.es,
  ...Teams.es,
  ...ShowTeams.es,
  ...UserAccess.es,
  ...Users.es,
  ...ShowUsers.es,
  ...NewHelpDesk.es,
  ...HelpDeskShowPage.es,
  ...TicketTypePage.es,

  ...AuthContext.es,
  ...useClients.es,
  ...useContactClients.es,
  ...useListClients.es,
  ...useListContacts.es,
  ...useCompanies.es,
  ...useContributor.es,
  ...useListContributors.es,
  ...useDashboard.es,
  ...useAttachments.es,
  ...CategoryHooks.es,
  ...ListTemplate.es,
  ...useSpecifications.es,
  ...useTemplate.es,
  ...useMovements.es,
  ...AssociatedEquipments.es,
  ...EquipmentsHooks.es,
  ...ListEquipmentsHooks.es,
  ...useEvaluations.es,
  ...useGroup.es,
  ...useListGroup.es,
  ...useModule.es,
  ...usePermission.es,
  ...useUserGroup.es,
  ...useReportKilometers.es,
  ...useReportTasks.es,
  ...useListScales.es,
  ...useScale.es,
  ...useSegments.es,
  ...useSkills.es,
  ...useListStatus.es,
  ...useStatus.es,
  ...usePause.es,
  ...useTaskType.es,
  ...useTaskForms.es,
  ...useListTasks.es,
  ...useTask.es,
  ...useListTeams.es,
  ...useTeams.es,
  ...useListUsers.es,
  ...useUsers.es,
  ...useTicketType.es,
  ...useListTickets.es,
  ...useTicket.es,
  ...useServiceStatus.es,

  ...ConfirmPageWithNoToken.es,
  ...ConfirmPageWithToken.es,
  ...Navbar.es,
  ...NewCompanyModal.es,
  ...SelectCompany.es,
  ...Sidebar.es,
  ...AddressList.es,
  ...AddressModal.es,
  ...AttachmentsList.es,
  ...AttachmentsModal.es,
  ...ClientsFilter.es,
  ...ClientsTable.es,
  ...ClientsAddressTab.es,
  ...ClientsAdditionalPhonesCreate.es,
  ...ClientsAdditionalPhones.es,
  ...ClientsChargeTab.es,
  ...ClientsContactsTabCreate.es,
  ...ClientsContactsTabFilter.es,
  ...ClientsContactsTabUpdate.es,
  ...ClientsContactsTab.es,
  ...ClientsDetailsTab.es,
  ...ClientsTalkToContact.es,
  ...ClientsTabs.es,
  ...ClientsContactsCreate.es,
  ...ClientsContactsFilter.es,
  ...ClientsContactsTable.es,
  ...ClientsContacts.es,
  ...ClientsUpdateCharge.es,
  ...ClientsUpdate.es,
  ...ClientsComponent.es,
  ...ContributorCreate.es,
  ...ContributorDetails.es,
  ...ContributorUpdate.es,
  ...ContributorFilter.es,
  ...ContributorTable.es,
  ...ContributorTabs.es,
  ...ContributorWorkday.es,
  ...Contributor.es,
  ...DashboardCards.es,
  ...DashboardFilter.es,
  ...DashboardMap.es,
  ...DashboardRates.es,
  ...EquipmentsCategoryBoard.es,
  ...EquipmentsCategoryCreate.es,
  ...EquipmentsCategoryUpdate.es,
  ...EquipmentsCategory.es,
  ...EquipmentsCreate.es,
  ...EquipmentsFilter.es,
  ...EquipmentsTable.es,
  ...EquipmentsTabsAssociatedEquipmentsCreate.es,
  ...EquipmentsTabsAttachmentsCreate.es,
  ...EquipmentsTabsAttachmentsUpdate.es,
  ...EquipmentsTabsAttachments.es,
  ...EquipmentsTabsDetails.es,
  ...EquipmentsTabsMovementsCreate.es,
  ...EquipmentsTabsMovements.es,
  ...EquipmentsTabsSpecificationCreate.es,
  ...EquipmentsTabsSpecificationImportTemplate.es,
  ...EquipmentsTabsSpecificationUpdate.es,
  ...EquipmentsTabsSpecification.es,
  ...EquipmentsTabsTasks.es,
  ...EquipmentsTabs.es,
  ...EquipmentsTemplateCreate.es,
  ...EquipmentsTemplateTable.es,
  ...EquipmentsTemplateUpdate.es,
  ...EquipmentsTemplate.es,
  ...EquipmentsUpdateDetails.es,
  ...EquipmentsComponent.es,
  ...EvaluationList.es,
  ...EvaluationModal.es,
  ...EvaluationInternalModal.es,
  ...FCCalendarHeader.es,
  ...GroupsBoard.es,
  ...GroupsCreatePermissions.es,
  ...GroupsCreateUserGroup.es,
  ...GroupsCreateContributorPermission.es,
  ...GroupsCreate.es,
  ...GroupsDetailsPermissions.es,
  ...GroupsDetails.es,
  ...GroupsFilter.es,
  ...GroupsTable.es,
  ...GroupsUpdateGroup.es,
  ...GroupsUpdatePermissions.es,
  ...GroupsComponent.es,
  ...MiscAddAddressToScaleButton.es,
  ...MiscAddToScaleButton.es,
  ...MiscAvatarUploader.es,
  ...MiscMoreOptions.es,
  ...MiscWeekdaySelector.es,
  ...ReportKilometersData.es,
  ...ReportKilometersExport.es,
  ...ReportKilometersFilter.es,
  ...ReportKilometers.es,
  ...ReportTasksExport.es,
  ...ReportTasksFilter.es,
  ...ReportTasksGraphics.es,
  ...ReportTasksModal.es,
  ...ReportTasksTable.es,
  ...ReportTasksComponent.es,
  ...ScalesNewScaleModal.es,
  ...ScalesCalendarDrawer.es,
  ...ScalesCalendarModal.es,
  ...ScalesCalendar.es,
  ...ScalesClientsAdd.es,
  ...ScalesClientsForm.es,
  ...ScalesClientsList.es,
  ...ScalesClientsTable.es,
  ...ScalesContributorsAdd.es,
  ...ScalesContributorsForm.es,
  ...ScalesContributorsList.es,
  ...ScalesContributorsTable.es,
  ...ScalesGeneralAddressList.es,
  ...ScalesGeneralAddressModal.es,
  ...ScalesGeneralForm.es,
  ...ScalesFilter.es,
  ...ScalesTable.es,
  ...ScalesTabs.es,
  ...ScalesTeamsAdd.es,
  ...ScalesTeamsForm.es,
  ...ScalesTeamsList.es,
  ...ScalesTeamsTable.es,
  ...ScalesTimeAddModal.es,
  ...ScalesComponent.es,
  ...SegmentsCreate.es,
  ...SegmentsTable.es,
  ...SegmentsUpdate.es,
  ...SegmentsComponent.es,
  ...SkillModal.es,
  ...SkillSkills.es,
  ...SkillSkillsList.es,
  ...SkillSkillsTable.es,
  ...Skill.es,
  ...StatusContributorUpdate.es,
  ...StatusContributor.es,
  ...StatusCreate.es,
  ...StatusDetails.es,
  ...StatusTable.es,
  ...StatusUpdate.es,
  ...StatusComponent.es,
  ...TeamsCollaborator.es,
  ...TeamsCreate.es,
  ...TeamsDetails.es,
  ...TeamsTable.es,
  ...TeamsUpdate.es,
  ...TeamsComponent.es,
  ...UsersDetails.es,
  ...UsersFilter.es,
  ...UsersGroup.es,
  ...UsersTable.es,
  ...UsersUpdate.es,
  ...UsersUpdatePassword.es,
  ...UsersComponent.es,
  ...TasksBoardsCalendar.es,
  ...TasksBoardsKanban.es,
  ...TasksBoardsList.es,
  ...TasksCreateAddress.es,
  ...TasksCreateDetails.es,
  ...TasksCreateModalClient.es,
  ...TasksCreateModalContributor.es,
  ...TasksCreateModalScaleFilterModal.es,
  ...TasksCreateModalScale.es,
  ...TasksCreateModalTaskType.es,
  ...TasksCreateModalTeams.es,
  ...TasksCreateModalTask.es,
  ...TasksCreateResponsible.es,
  ...TasksCreate.es,
  ...TasksFilter.es,
  ...TasksOptionsCalendarListModal.es,
  ...TasksOptionsCalendarList.es,
  ...TasksOptionsCalendar.es,
  ...TasksOptionsKanbanCards.es,
  ...TasksOptionsKanbanConfigurations.es,
  ...TasksOptionsKanbanFilter.es,
  ...TasksOptionsKanbanList.es,
  ...TasksOptionsTable.es,
  ...TasksPrintButton.es,
  ...TasksPrintPDF.es,
  ...TasksTable.es,
  ...TasksTabsDetails.es,
  ...TasksTabsEquipmentsCreate.es,
  ...TasksTabsEquipments.es,
  ...TasksTabsReportCreate.es,
  ...TasksTabsReport.es,
  ...TasksTabsStatus.es,
  ...TasksTabs.es,
  ...TasksCalendarModal.es,
  ...TasksCalendar.es,
  ...TasksUpdate.es,
  ...TasksRegistrationPauseCreate.es,
  ...TasksRegistrationPauseTable.es,
  ...TasksRegistrationPauseUpdate.es,
  ...TasksRegistrationPause.es,
  ...TasksRegistrationTypeTasksAbout.es,
  ...TasksRegistrationTypeTasksCreate.es,
  ...TasksRegistrationTypeTasksTable.es,
  ...TasksRegistrationTypeTasks.es,
  ...TasksRegistrationFormsAbout.es,
  ...TasksRegistrationFormsCreate.es,
  ...TasksRegistrationFormsTable.es,
  ...TasksRegistrationForms.es,
  ...HelpDesk.es,
  ...HelpDeskTable.es,
  ...HelpDeskFilter.es,
  ...HelpDeskCreate.es,
  ...HelpDeskTabs.es,
  ...HelpDeskDetailTab.es,
  ...HelpDeskClientTab.es,
  ...TicketsType.es,
  ...CreateTicketType.es,
  ...TicketTypeTable.es,
  ...TicketTypeUpdate.es,
  ...TicketTaskLink.es,
  ...ClientsParent.es,
  ...ClientsParentTable.es,
  ...ClientsPrincipalSteps.es,
  ...ClientsPrincipalDetailsCreate.es,
  ...ClientsPrincipalFinancialCreate.es,
  ...ClientParentTabs.es,
  ...ClientsParentTaskTypeModalCreate.es,
  ...ClientsPrincipalTabsDetails.es,
  ...ClientsPrincipalTabsFinancial.es,
  ...ClientsPrincipalTabsClient.es,
  ...ClientsPrincipalDetailsUpdate.es,
  ...ClientsPrincipalFinancialUpdate.es,
  ...FinancialComponent.es,
  ...FinancialReceive.es,
  ...TeamsComponentTabFinancial.es,
  ...TeamsComponentTabFinancialUpdate.es,
  ...BadgeComponent.es,
  ...DropdownNotifications.es,
  ...ServiceStatus.es,
  ...CompanyLogo.es,
}
