/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-curly-newline */
import { PlusCircleOutlined } from '@ant-design/icons'
import { Button, Card, Checkbox, Form, message, Space, Tabs } from 'antd'
import { PageHeader } from '@ant-design/pro-components'
import React, { useEffect, useState } from 'react'

import { useTaskCreate } from '~/hooks/Tasks/Create/createTask'
import { useListTask } from '~/hooks/Tasks/useListTasks'
import { translate } from '~/utils/locale'

import AddressCreateTask from './Address'
import DetailsCreateTask from './Details'
import ResponsibleCreateTask from './Responsible'
import TaskModal from './Modal/Task'

import { useTaskTour } from '~/hooks/Tour/useTaskTour'
import FormsCreateTask from './Forms'
import { TaskFormsProvider } from '~/hooks/Tasks/Forms/useTaskForms'
import { Container } from './styles'
import CreateFinancialTask from './Financial'

// import { FormButtonContainer } from './styles';

interface TaskProps {
  type: string
  task_type_id: string
  address_id: string
  id: string
  initialDate: Date
  duration_time: Date
  priority: string
  description: string
  customer_id: string
  checkin_type: string
  external_code: string
  zip_code: string
  street: string
  number: string
  complement: string
  neighborhood: string
  city: string
  state: string
  location: { x: number; y: number }
  latitude: any
  longitude: any
  typeResponsible: string
  value: number
  receive: number
  note: string
  forms: Array<string>
  amount_hours: number
  amount_hours_additional: number
  number_km: number
  number_km_additional: number
  amount_hours_receive: number
  amount_hours_additional_receive: number
  number_km_receive: number
  number_km_additional_receive: number
}

const CreateTasks: React.FC = () => {
  const { createTask } = useListTask()
  const {
    taskTypeData,
    clientData,
    contributorData,
    teamData,
    description,
    number,
    isCreateTaskOnClient,
  } = useTaskCreate()

  const { openAddressTask, openResponsibleTask, saveTaskRef } = useTaskTour()

  const [keepRegister, setKeepRegister] = useState(false)
  const { TabPane } = Tabs

  const [form] = Form.useForm()

  const [activeTab, setActiveTab] = useState<string>('1')

  const [disabledTab, setDisabledTab] = useState(false)
  const [disabledAddressTab, setDisabledAddressTab] = useState(false)

  useEffect(() => {
    if (
      (clientData?.id && taskTypeData?.id && description === '') ||
      description?.length !== 0
    ) {
      setDisabledTab(true)
    }

    if (number) {
      setDisabledAddressTab(true)
    }
  }, [clientData?.id, taskTypeData?.id, description, number])

  function chargeTab(activeKey: string) {
    setActiveTab(activeKey)
  }

  async function handleForm(valueForm: TaskProps) {
    let responsibleId: string | undefined = ''

    if (form.getFieldError('mapa')[0]) {
      message.error('Teste')
    }

    if (
      valueForm.type === 'collaborator' &&
      valueForm.typeResponsible === 'scale'
    ) {
      responsibleId = contributorData?.id
    }

    if (valueForm.typeResponsible === 'contributor') {
      responsibleId = contributorData?.id
    }

    if (valueForm.type === 'team' && valueForm.typeResponsible === 'scale') {
      responsibleId = teamData?.id
    }
    const dataForm = {
      task_type_id: taskTypeData?.id,
      responsible: {
        id: responsibleId,
        type:
          valueForm.typeResponsible === 'contributor'
            ? 'collaborator'
            : valueForm.type,
      },
      date: valueForm.initialDate,
      duration_time: valueForm.duration_time,
      priority: valueForm.priority,
      description: valueForm.description,
      checkin_type: valueForm.checkin_type,
      external_code: valueForm.external_code ? valueForm.external_code : '',
      value: valueForm.value,
      receive: valueForm.receive,
      note: valueForm.note,
      customer_id: clientData?.id,
      address: {
        zip_code: valueForm.zip_code,
        street: valueForm.street,
        number: valueForm.number,
        complement: valueForm.complement,
        neighborhood: valueForm.neighborhood,
        city: valueForm.city,
        state: valueForm.state,
        location: {
          x: valueForm.latitude,
          y: valueForm.longitude,
        },
      },
      financial: {
        task_value: valueForm.value,
        amount_hours: valueForm.amount_hours,
        amount_hours_additional: valueForm.amount_hours_additional,
        number_km: valueForm.number_km,
        number_km_additional: valueForm.number_km_additional,
      },
      financialReceive: {
        receive: valueForm.receive,
        amount_hours_receive: valueForm.amount_hours_receive,
        amount_hours_additional_receive:
          valueForm.amount_hours_additional_receive,
        number_km_receive: valueForm.number_km_receive,
        number_km_additional_receive: valueForm.number_km_additional_receive,
      },
      forms:
        valueForm.forms && valueForm.forms.length > 0
          ? valueForm.forms.map((formId) => ({
              form_id: formId,
            }))
          : [],
    }

    if (contributorData?.id || teamData?.id) {
      await createTask(dataForm)
    } else {
      message.error('Adicione um responsável')
      setActiveTab('3')
    }
  }

  const initialValue = {
    external_code: Math.random().toString(16).substr(2),
  }

  useEffect(() => {
    if (openAddressTask) {
      setActiveTab('2')
    }

    if (openResponsibleTask) {
      setActiveTab('3')
    }
  }, [openAddressTask, openResponsibleTask])

  return (
    <Container>
      <PageHeader
        className="site-page-header"
        onBack={() => window.history.back()}
        title={translate('TasksCreate.pageheaderTitle')}
      />
      <Card
        title={translate('TasksCreate.cardTitle')}
        extra={
          <Space size="small">
            <Checkbox
              onChange={(e) => {
                setKeepRegister(e.target.checked)
              }}
              checked={keepRegister}
            >
              {translate('TasksCreate.keepRegisterText')}
            </Checkbox>
            <Button
              type="primary"
              icon={<PlusCircleOutlined />}
              form="tasksForm"
              htmlType="submit"
              disabled={activeTab !== '5'}
              ref={saveTaskRef}
              id={activeTab !== '5' ? '' : 'btn_create_task'}
            >
              {translate('TasksCreate.registerButton')}
            </Button>
            <TaskModal form={form} />
          </Space>
        }
        bordered={false}
        style={{ width: '100%', padding: 30 }}
      >
        <Form
          layout="vertical"
          id="tasksForm"
          form={form}
          onFinish={handleForm}
          initialValues={initialValue}
        >
          <Tabs defaultActiveKey="1" onChange={chargeTab} activeKey={activeTab}>
            <TabPane tab={translate('TasksCreate.clientPane')} key="1">
              <DetailsCreateTask form={form} />
            </TabPane>
            <TabPane tab={'Formulários'} key="2">
              <TaskFormsProvider>
                <FormsCreateTask form={form} />
              </TaskFormsProvider>
            </TabPane>
            <TabPane
              tab={translate('TasksCreate.addressPane')}
              key="3"
              disabled={!disabledTab}
            >
              <AddressCreateTask form={form} />
            </TabPane>
            <TabPane
              tab={translate('TasksCreate.responsiblePane')}
              key="4"
              disabled={!disabledAddressTab && !isCreateTaskOnClient}
            >
              <ResponsibleCreateTask form={form} />
            </TabPane>
            <TabPane
              tab="Valores acordados"
              key="5"
              disabled={!contributorData?.id && !teamData?.id}
            >
              <CreateFinancialTask form={form} />
            </TabPane>
          </Tabs>

          {/* <FormButtonContainer>
            <Space size="large" style={{ alignItems: 'baseline' }}>
              <Checkbox
                onChange={e => {
                  setKeepRegister(e.target.checked);
                }}
                checked={keepRegister}
              >
                {translate('TasksCreate.keepRegisterText')}
              </Checkbox>

              <Form.Item>
                <Button
                  type="primary"
                  icon={<PlusCircleOutlined />}
                  form="tasksForm"
                  htmlType="submit"
                >
                  {activeTab === '2'
                    ? translate('TasksCreate.registerButton')
                    : translate('TasksCreate.continueText')}
                </Button>
              </Form.Item>
            </Space>
          </FormButtonContainer> */}
        </Form>
      </Card>
    </Container>
  )
}

export default CreateTasks
