import styled from 'styled-components'

export const Container = styled.div`
  .total-row {
    display: flex;
    justify-content: space-between;
    position: sticky;
    bottom: 0;
    background-color: #fafafa;
    font-weight: bold;
    padding: 8px 16px;
    z-index: 1; /* Garante que a linha de totais esteja acima da tabela */
  }
`
