export default {
  pt: {
    useTask: {
      getErrorMessage: 'Erro ao visualizar detalhes da tarefa.',
      createSuccessMessage: 'Equipamento adicionado com sucesso!',
      createErrorMessage: 'Erro ao adicionar equipamentos à tarefa.',
      updateSuccessMessage: 'Tarefa atualizada com sucesso!',
      updateErrorMessage: 'Erro ao editar tarefa.',
      updateReportLoadingMessage: 'Enviando arquivo...',
      updateReportSuccessMessage: 'Evidência adicionada com sucesso!',
      updateReportErrorMessage: 'Erro ao editar evidência da tarefa.',
      deleteSuccessMessage: 'Equipamento deletado da tarefa com sucesso!',
      deleteErrorMessage: 'Erro ao adicionar excluir equipamentos da tarefa.',
    },
  },
  en: {
    useTask: {
      getErrorMessage: 'Error viewing task details.',
      createSuccessMessage: 'Equipment successfully added!',
      createErrorMessage: 'Error adding equipment to task.',
      updateSuccessMessage: 'Task successfully updated!',
      updateErrorMessage: 'Error editing task.',
      updateReportLoadingMessage: 'Uploading file...',
      updateReportSuccessMessage: 'Report successfully added!',
      updateReportErrorMessage: 'Error editing task report.',
      deleteSuccessMessage: 'Equipment deleted from task successfully!',
      deleteErrorMessage: 'Error adding delete equipment from task.',
    },
  },
  es: {
    useTask: {
      getErrorMessage: 'Error al ver los detalles de la tarea.',
      createSuccessMessage: '¡Equipo agregado con éxito!',
      createErrorMessage: 'Error al agregar equipo a la tarea.',
      updateSuccessMessage: '¡Tarea actualizada con éxito!',
      updateErrorMessage: 'Error al editar la tarea.',
      updateReportLoadingMessage: 'Cargando archivo...',
      updateReportSuccessMessage: '¡Informe agregado con éxito!',
      updateReportErrorMessage: 'Error al editar el informe de tareas.',
      deleteSuccessMessage: '¡Equipo eliminado de la tarea con éxito!',
      deleteErrorMessage: 'Error al agregar eliminar equipo de la tarea.',
    },
  },
}
