export default {
  pt: {
    TasksTable: {
      dateString: 'às',
      internalCodeColumn: 'Código interno da tarefa',
      responsibleColumn: 'Colaborador',
      initialTimeColumn: 'Data',
      taskTypeColumn: 'Tipo de Tarefa',
      statusColumn: 'Status',
      equipmentColumn: 'Equipamentos',
      tagEquipment: 'equipamento',
      tagEquipments: 'equipamentos',
      statusReceived: 'Tarefa Enviada',
      statusViewed: 'Tarefa Visualizada',
      statusEnRoute: 'Tarefa a caminho',
      statusWorking: 'Tarefa sendo realizada',
      statusBreak: 'Tarefa Pausada',
      statusResumeWorking: 'Tarefa Retomada',
      statusCanceled: 'Tarefa Cancelada',
      statusFinished: 'Tarefa Finalizada',
      actionsColumn: 'Ações',
      viewTooltip: 'Visualizar Tarefa',
      deletePopconfirm: 'Deseja mesmo deletar a tarefa?',
      deleteTooltip: 'Deletar',
      unlinkTask: 'Desvincular tarefa',
      unlinkPopconfirm: 'Deseja mesmo desvincular a tarefa do ticket?',
    },
  },
  en: {
    TasksTable: {
      dateString: 'at',
      internalCodeColumn: 'Task internal code',
      responsibleColumn: 'Contributor',
      initialTimeColumn: 'Date',
      taskTypeColumn: 'Task Type',
      statusColumn: 'Status',
      equipmentColumn: 'Equipments',
      tagEquipment: 'equipment',
      tagEquipments: 'equipments',
      statusReceived: 'Task Submitted',
      statusViewed: 'Task Viewed',
      statusEnRoute: 'Task on the way',
      statusWorking: 'Task being performed',
      statusBreak: 'Paused Task',
      statusResumeWorking: 'Resume Task',
      statusCanceled: 'Canceled Task',
      statusFinished: 'Finished Task',
      actionsColumn: 'Actions',
      viewTooltip: 'View Task',
      deletePopconfirm: 'Do you really want to delete the task?',
      deleteTooltip: 'Delete',
      unlinkTask: 'Unlink task',
      unlinkPopconfirm:
        'Do you really want to unlink the task from the ticket?',
    },
  },
  es: {
    TasksTable: {
      dateString: 'a las',
      internalCodeColumn: 'Código interno de la tarea',
      responsibleColumn: 'Colaborador',
      initialTimeColumn: 'Fecha',
      taskTypeColumn: 'Tipo de Tarea',
      statusColumn: 'Status',
      equipmentColumn: 'Equipos',
      tagEquipment: 'equipo',
      tagEquipments: 'equipos',
      statusReceived: 'Tarea Enviada',
      statusViewed: 'Tarea Vista',
      statusEnRoute: 'Tarea en camino',
      statusWorking: 'Tarea que se está realizando',
      statusBreak: 'Tarea en pausa',
      statusResumeWorking: 'Reanudar Tarea',
      statusCanceled: 'Tarea cancelada',
      statusFinished: 'Tarea terminada',
      actionsColumn: 'Acciones',
      viewTooltip: 'Ver Tarea',
      deletePopconfirm: '¿Realmente quieres eliminar la tarea?',
      deleteTooltip: 'Borrar',
      unlinkTask: 'Desvincular tarea',
      unlinkPopconfirm: '¿Realmente desea desvincular la tarea del ticket?',
    },
  },
}
