import React from 'react'

import { Tabs, Button } from 'antd'
import { PageHeader } from '@ant-design/pro-components'

import { EditOutlined } from '@ant-design/icons'
import { translate } from '~/utils/locale'
import DetailsTicket from './Details'
import { useTicket } from '~/hooks/HelpDesk/useTickets'
import UpdateTicket from '../Update'
import TasksTickets from './Tasks'

import { Container } from './styles'

const HelpDeskTabs: React.FC = () => {
  const { isEdit, setIsEdit, ticket } = useTicket()

  const { TabPane } = Tabs

  return (
    <>
      <PageHeader
        className="site-page-header"
        onBack={() => window.history.back()}
        title={
          !isEdit
            ? translate('HelpDeskTabs.viewTicketTitle')
            : translate('HelpDeskTabs.editTicketTitle')
        }
        subTitle={ticket?.title}
      />
      <Tabs
        tabBarExtraContent={
          <Container>
            <Button
              id="btn-edit-ticket"
              type="primary"
              icon={<EditOutlined />}
              onClick={() => setIsEdit(true)}
            >
              {translate('TasksTabsDetails.editButton')}
            </Button>
          </Container>
        }
        style={{ paddingLeft: 20, paddingRight: 20 }}
      >
        <TabPane tab={translate('HelpDeskTabs.detailsTab')} key="detalhes">
          {!isEdit ? <DetailsTicket /> : <UpdateTicket />}
        </TabPane>
        {/* <TabPane tab={translate('HelpDeskTabs.historicTab')} key="historico">
          <DetailsHistoricTicket />
        </TabPane>
        <TabPane tab={translate('HelpDeskTabs.reportsTab')} key="anexos">
          <p>Anexos</p>
        </TabPane> */}
        <TabPane tab={translate('HelpDeskTabs.tasksTab')} key="tarefas">
          <TasksTickets />
        </TabPane>
      </Tabs>
    </>
  )
}

export default HelpDeskTabs
