/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react'

import { Helmet } from 'react-helmet'

import { TaskFormsProvider } from '~/hooks/Tasks/Forms/useTaskForms'

import { Container } from './styles'
import CreateForm from '~/components/DataManipulation/TasksRegistration/Forms/Create'
import { translate } from '~/utils/locale'

const NewForm: React.FC = () => {
  return (
    <Container>
      <Helmet>
        <title>{translate('NewForm.helmetTitle')}</title>
      </Helmet>

      <TaskFormsProvider>
        <CreateForm />
      </TaskFormsProvider>
    </Container>
  )
}

export default NewForm
