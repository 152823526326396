export default {
  pt: {
    ListEquipmentsHooks: {
      getErrorMessage: 'Erro ao visualizar equipamentos.',
      createSuccessMessage: 'Equipamento criado com sucesso!',
      createErrorMessage: 'Erro ao criar equipamento.',
      deleteSuccessMessage: 'Equipamento deletado com sucesso!',
      deleteErrorMessage: 'Erro ao deletar equipamento.',
    },
  },
  en: {
    ListEquipmentsHooks: {
      getErrorMessage: 'Error viewing equipment.',
      createSuccessMessage: 'Equipment created successfully!',
      createErrorMessage: 'Error creating equipment.',
      deleteSuccessMessage: 'Equipment successfully deleted!',
      deleteErrorMessage: 'Error deleting equipment.',
    },
  },
  es: {
    ListEquipmentsHooks: {
      getErrorMessage: 'Error al ver el equipo.',
      createSuccessMessage: '¡Equipo creado con éxito!',
      createErrorMessage: 'Error al crear equipo.',
      deleteSuccessMessage: '¡Equipo eliminado con éxito!',
      deleteErrorMessage: 'Error al eliminar el equipo.',
    },
  },
}
