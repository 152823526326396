/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable consistent-return */
import React, { useContext, createContext, useState } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'

import { message } from 'antd'

import api from '~/services/api'

import { useCompanies } from '~/hooks/Companies/useCompanies'
import { useLocale } from '~/hooks/locale/useLocale'

import { ShowError } from '~/utils/errors/apiErrors'
import { translate } from '~/utils/locale'

interface EquipmentsTasks {
  id: string
}

interface Address {
  city: string
  id: string
  location: {
    x: number
    y: number
  }
  neighborhood: string
  number: number
  state: string
  street: string
  zip_code: string
  complement: string
}

interface Responsible {
  id: string
  type: string
}

interface Report {
  created_at: Date
  id: string
  type: string
  value: string
  title: string
  file_url: string
}

interface Status {
  type: string
  created_at: Date
  collaborator_id: string
  location: {
    lat: number
    long: number
  }
}

export interface TaskProps {
  checkin_type: string
  createdAt: string
  date: string
  duration_time: string
  external_code: string
  is_enabled: string
  priority: string
  description: string
  customer_id: string
  _id: string
  internal_code: string
  current_status: string
  hours: string
  currentDate: string
  task_type_id: string
  equipments: EquipmentsTasks[]
  address: Address
  responsible: Responsible
  reports: Report[]
  status: Status[]
}

interface TicketsProps {
  _id: string
  createdAt: Date
  title: string
  customer: string
  customer_phone: string
  customer_email: string
  date: Date
  description: string
  priority: string
  ticketType: {
    _id: string
    title: string
    description: string
  }
  responsible: {
    id: string
    type: string
    data: {
      contact: {
        name: string
      }
    }
  }
  tasks: TaskProps[]
}

interface TicketContextData {
  ticket?: TicketsProps
  updateTicket(dataUser: object | undefined): void
  createTaskTicket(dataTask: object | undefined): void
  deleteTaskTicket(taskId: string): void
  setIsEdit(value: boolean): void
  isEdit?: boolean
  isLoading: boolean
  refetch: () => void
  isFetching: boolean
}

const TicketContext = createContext<TicketContextData>({} as TicketContextData)

export const TicketProvider: React.FC = ({ children }) => {
  const { selectedCompany } = useCompanies()
  const { locale } = useLocale()
  const { ticketId } = useParams<{ ticketId: string }>()

  const [isEdit, setIsEdit] = useState<boolean>()

  const {
    data: ticket,
    isLoading,
    refetch,
    isFetching,
  } = useQuery(
    [`ticket${selectedCompany}`, selectedCompany],

    async () => {
      try {
        const response = await api.get(
          `/company/${selectedCompany}/ticket/${ticketId}`,
        )

        const { data } = response

        return data
      } catch (err: any) {
        ShowError(err.message, translate('useTicket.getErrorMessage'), locale)
      }
    },
    {
      staleTime: 5000,
    },
  )

  async function updateTicket(dataTicket: object) {
    try {
      await api.put(
        `/company/${selectedCompany}/ticket/${ticketId}`,
        dataTicket,
      )

      refetch()
      message.success(translate('useTicket.updateSuccessMessage'))
    } catch (err: any) {
      ShowError(err.message, translate('useTicket.updateErrorMessage'), locale)
    }
  }

  async function createTaskTicket(dataTask: object) {
    try {
      await api.post(
        `/company/${selectedCompany}/ticket/${ticketId}/task`,
        dataTask,
      )

      refetch()
      message.success(translate('useTicket.linkSuccessMessage'))
    } catch (err: any) {
      ShowError(err.message, translate('useTicket.linkErrorMessage'), locale)
    }
  }

  async function deleteTaskTicket(taskId: string) {
    try {
      await api.delete(
        `/company/${selectedCompany}/ticket/${ticketId}/task/${taskId}`,
      )

      refetch()
      message.success(translate('useTickets.deleteSuccessMessage'))
    } catch (err: any) {
      ShowError(err.message, translate('useTickets.deleteErrorMessage'), locale)
    }
  }

  return (
    <TicketContext.Provider
      value={{
        ticket,
        updateTicket,
        createTaskTicket,
        deleteTaskTicket,
        isLoading,
        refetch,
        isFetching,
        setIsEdit,
        isEdit,
      }}
    >
      {children}
    </TicketContext.Provider>
  )
}

export function useTicket(): TicketContextData {
  const context = useContext(TicketContext)

  if (!context) {
    throw new Error('useAuth must be used within an AutoProvider')
  }

  return context
}
