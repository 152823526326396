/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, createContext, useState } from 'react'
import { useQuery } from 'react-query'

import { message } from 'antd'

import api from '~/services/api'

import { useCompanies } from '~/hooks/Companies/useCompanies'
import { useLocale } from '~/hooks/locale/useLocale'

import { ShowError } from '~/utils/errors/apiErrors'
import { translate } from '~/utils/locale'

interface BadgeProps {
  description: string
  name: string
  _id: string
  colorBackground?: string
  colorCover?: string
  colorStrongText?: string
  colorText?: string
  logoUrl: string
  template: string
}

interface BadgeCreateProps {
  description: string
  name: string
  colorBackground?: string
  colorCover?: string
  colorStrongText?: string
  colorText?: string
}

interface BadgeContextData {
  badge?: BadgeProps[]
  createBadge(valueForm: object | undefined): void
  // updatePause(valueForm: object | undefined, pauseId: string): void
  deleteBadge(badgeId: string): void
  isLoading: boolean
  refetch: () => void
  isFetching: boolean
  badgeTotal?: number
  dataBadge?: BadgeCreateProps
  setDataBadge(value: BadgeCreateProps): void
}

const BadgeContext = createContext<BadgeContextData>({} as BadgeContextData)

export const BadgeProvider: React.FC = ({ children }) => {
  const { selectedCompany } = useCompanies()
  const { locale } = useLocale()

  const [badgeTotal, setBadgeTotal] = useState<number>()
  const [dataBadge, setDataBadge] = useState<BadgeCreateProps>(
    {} as BadgeCreateProps,
  )

  const {
    data: badge,
    isLoading,
    refetch,
    isFetching,
  } = useQuery(
    [`cracha${selectedCompany}`, selectedCompany, badgeTotal],

    async () => {
      try {
        const response = await api.get(`/company/${selectedCompany}/badges`)

        const { data } = response

        setBadgeTotal(data.total)

        return data.results
      } catch (err: any) {
        ShowError(
          err.message,
          translate('BadgeComponent.errorListBadge'),
          locale,
        )
      }
    },
  )

  async function createBadge(valueForm: object) {
    try {
      await api.post(`/company/${selectedCompany}/badge`, valueForm)

      refetch()
      message.success(translate('BadgeComponent.sucessBadge'))
    } catch (err: any) {
      ShowError(err.message, translate('BadgeComponent.errorBadge'), locale)
    }
  }

  //   async function updatePause(valueForm: object, pauseId: string) {
  //     try {
  //       await api.put(
  //         `/company/${selectedCompany}/break-reason/${pauseId}`,
  //         valueForm,
  //       )

  //       message.success(translate('usePause.updateSuccessMessage'))
  //       refetch()
  //     } catch (err: any) {
  //       ShowError(err.message, translate('usePause.updateErrorMessage'), locale)
  //     }
  //   }

  async function deleteBadge(badgeId: string) {
    try {
      await api.delete(`/company/${selectedCompany}/badge/${badgeId}`)

      refetch()
      message.success(translate('BadgeComponent.sucessDeleteBadge'))
    } catch (err: any) {
      ShowError(
        err.message,
        translate('BadgeComponent.errorDeleteBadge'),
        locale,
      )
    }
  }

  return (
    <BadgeContext.Provider
      value={{
        badge,
        createBadge,
        // updatePause,
        deleteBadge,
        isLoading,
        refetch,
        isFetching,
        badgeTotal,
        dataBadge,
        setDataBadge,
      }}
    >
      {children}
    </BadgeContext.Provider>
  )
}

export function useBadge(): BadgeContextData {
  const context = useContext(BadgeContext)

  if (!context) {
    throw new Error('useAuth must be used within an AutoProvider')
  }

  return context
}
