/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react'

import { Button, Form, Modal, Select, Space, Tooltip } from 'antd'

import { useParams } from 'react-router-dom'
import { Container } from './styles'
import { useListEquipments } from '~/hooks/Equipments/ListEquipmentsHooks'
import { useAssociatedEquipments } from '~/hooks/Equipments/AssociatedEquipments'
import { translate } from '~/utils/locale'
import { ContainerButton } from '../../styles'

const CreateAssociatedEquipments: React.FC = () => {
  const { equipmentId } = useParams<{ equipmentId: string }>()

  const { createAssociatedEquipment } = useAssociatedEquipments()
  const { listEquipments, isLoading } = useListEquipments()
  const [isModalVisible, setIsModalVisible] = useState(false)

  const [associatedEquipment, setAssociatedEquipment] = useState<string>()

  function handleAssociatedEquipment(value: any) {
    setAssociatedEquipment(value)
  }

  const filterEquipments = listEquipments?.filter((equipment) => {
    return equipment.id !== equipmentId
  })

  function onCreateAssociatedEquipments() {
    try {
      createAssociatedEquipment(associatedEquipment)
      setIsModalVisible(false)
    } catch (err) {
      console.log(err)
    }
  }

  const { Option } = Select

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleOk = () => {
    setIsModalVisible(false)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  return (
    <Container>
      <Tooltip
        title={translate(
          'EquipmentsTabsAssociatedEquipmentsCreate.tooltipText',
        )}
      >
        <button type="button" className="btn_equipment" onClick={showModal}>
          +
        </button>
      </Tooltip>
      <Modal
        title={translate('EquipmentsTabsAssociatedEquipmentsCreate.modalTitle')}
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width="600px"
        footer={[
          <Space key="buttons">
            <Button key="back" onClick={handleCancel}>
              {translate(
                'EquipmentsTabsAssociatedEquipmentsCreate.closeButton',
              )}
            </Button>
            <ContainerButton>
              <Button
                key="save"
                type="primary"
                onClick={onCreateAssociatedEquipments}
                id="btn_create_associated_equipment"
              >
                {translate(
                  'EquipmentsTabsAssociatedEquipmentsCreate.saveButton',
                )}
              </Button>
            </ContainerButton>
          </Space>,
        ]}
      >
        <Form layout="vertical">
          <Form.Item
            label={translate(
              'EquipmentsTabsAssociatedEquipmentsCreate.equipmentLabel',
            )}
          >
            <Select
              loading={isLoading}
              placeholder={translate(
                'EquipmentsTabsAssociatedEquipmentsCreate.equipmentPlaceholder',
              )}
              onChange={handleAssociatedEquipment}
            >
              {filterEquipments?.map((equipment) => (
                <Option value={equipment.id} key={equipment.id}>
                  {equipment.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </Container>
  )
}

export default CreateAssociatedEquipments
