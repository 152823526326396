export default {
  pt: {
    useTicketType: {
      getErrorMessage: 'Erro ao visualizar tipos de solicitação.',
      createSuccessMessage: 'Tipo de solicitação criado com sucesso!',
      createErrorMessage: 'Erro ao criar tipos de solicitação.',
      updateSuccessMessage: 'Tipo de solicitação atualizado com sucesso!',
      updateErrorMessage: 'Erro ao atualizar tipo de solicitação.',
      deleteSuccessMessage: 'Tipo de solicitação deletado com sucesso!',
      deleteErrorMessage: 'Erro ao deletar tipo de solicitação.',
    },
  },
  en: {
    useTicketType: {
      getErrorMessage: 'Error viewing tickets types.',
      createSuccessMessage: 'Ticket Type created successfully!',
      createErrorMessage: 'Error creating tickets types.',
      updateSuccessMessage: 'Ticket Type updated successfully!',
      updateErrorMessage: 'Error updating tickets types.',
      deleteSuccessMessage: 'Ticket Type deleted successfully!',
      deleteErrorMessage: 'Error deleting tickets types.',
    },
  },
  es: {
    useTicketType: {
      getErrorMessage: 'Error al ver los tipos de solicitudes.',
      createSuccessMessage: '¡Tipo de Solicitude creada con éxito!',
      createErrorMessage: 'Error al crear tipos de solicitudes.',
      updateSuccessMessage: '¡Tipo de Solicitude actualizado con éxito!',
      updateErrorMessage: 'Error al actualizar los tipos de solicitudes.',
      deleteSuccessMessage: '¡Tipo de Solicitude eliminada con éxito!',
      deleteErrorMessage: 'Error al eliminar los tipos de solicitudes.',
    },
  },
}
