export default {
  pt: {
    ListTemplate: {
      getErrorMessage: 'Erro ao visualizar templates de especificações.',
      createSuccessMessage: 'Template criado com sucesso!',
      createErrorMessage: 'Erro ao criar templates de especificações.',
      updateSuccessMessage: 'Template atualizado com sucesso!',
      updateErrorMessage: 'Erro ao atualizar templates de especificações.',
      deleteSuccessMessage: 'Template deletado com sucesso!',
      deleteErrorMessage: 'Erro ao excluir templates de especificações.',
    },
  },
  en: {
    ListTemplate: {
      getErrorMessage: 'Error viewing specification templates.',
      createSuccessMessage: 'Template created successfully!',
      createErrorMessage: 'Error creating specification templates.',
      updateSuccessMessage: 'Template updated successfully!',
      updateErrorMessage: 'Error updating specification templates.',
      deleteSuccessMessage: 'Template successfully deleted!',
      deleteErrorMessage: 'Error deleting specification templates.',
    },
  },
  es: {
    ListTemplate: {
      getErrorMessage: 'Error al ver las plantillas de especificaciones.',
      createSuccessMessage: '¡Plantilla creada con éxito!',
      createErrorMessage: 'Error al crear plantillas de especificación.',
      updateSuccessMessage: '¡Plantilla actualizada con éxito!',
      updateErrorMessage:
        'Error al actualizar las plantillas de especificaciones.',
      deleteSuccessMessage: '¡Plantilla eliminada con éxito!',
      deleteErrorMessage: 'Error al eliminar las hojas de especificaciones.',
    },
  },
}
