/* eslint-disable react-hooks/exhaustive-deps */
import { Card, Space } from 'antd'
import React from 'react'

import AddressList from '~/components/DataManipulation/Address/AddressList'
import AddressModal from '~/components/DataManipulation/Address/AddressModal'

import { useClients } from '~/hooks/Clients/useClients'
import { translate } from '~/utils/locale'

import { Container } from './styles'

const AddressTab: React.FC = () => {
  const { clients, isLoading, refetch } = useClients()

  if (isLoading || !clients) {
    return <p>{translate('ClientsAddressTab.loadingText')}</p>
  }

  return (
    <Container>
      <Card
        bordered={false}
        title={translate('ClientsAddressTab.cardTitle')}
        loading={isLoading || !clients}
        style={{
          display: 'inline-block',
          width: '100%',
        }}
        extra={
          <Space size="middle">
            <AddressModal refetch={refetch} />
          </Space>
        }
      >
        <AddressList addressInfo={clients.address} refetch={refetch} />
      </Card>
    </Container>
  )
}

export default AddressTab
