/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react'

import { Button, Modal, Form, Input } from 'antd'
import { PlusCircleOutlined } from '@ant-design/icons'

import { useListGroup } from '~/hooks/Groups/useListGroup'
import { translate } from '~/utils/locale'

const CreateGroup: React.FC = () => {
  const { createGroup } = useListGroup()
  const [isModalVisible, setIsModalVisible] = useState(false)

  function onFormNewGroup(valueForm: any) {
    try {
      createGroup(valueForm)
      setIsModalVisible(false)
    } catch (err) {
      console.log(err)
    }
  }

  const showModal = () => {
    setIsModalVisible(true)
  }

  const closeModal = () => {
    setIsModalVisible(false)
  }

  return (
    <>
      <Button type="primary" onClick={showModal} icon={<PlusCircleOutlined />}>
        {translate('GroupsCreate.newButton')}
      </Button>
      <Modal
        title={translate('GroupsCreate.modalTitle')}
        open={isModalVisible}
        onOk={closeModal}
        onCancel={closeModal}
        footer={[
          <Button key="back" onClick={closeModal}>
            {translate('GroupsCreate.closeButton')}
          </Button>,
          <Button
            form="newGroupForm"
            key="save"
            type="primary"
            htmlType="submit"
          >
            {translate('GroupsCreate.saveButton')}
          </Button>,
        ]}
      >
        <Form id="newGroupForm" layout="vertical" onFinish={onFormNewGroup}>
          <Form.Item
            label={translate('GroupsCreate.groupNameLabel')}
            name="name"
            rules={[
              {
                required: true,
                message: translate('GroupsCreate.groupNameRule'),
              },
            ]}
          >
            <Input
              placeholder={translate('GroupsCreate.groupNamePlaceholder')}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default CreateGroup
