import { lighten } from 'polished'
import styled from 'styled-components'

export const Container = styled.div``

export const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  .form {
    margin-left: 20px;
    flex: 1;
  }
`
export const ContainerButton = styled.div`
  #btn_save_charge_client {
    background: #3db065;
    border: 1px solid #3db065;
    color: white;

    &:hover {
      background: ${lighten(0.05, '#3DB065')};
      border: 1px solid ${lighten(0.1, '#3DB065')};
    }
  }
`
