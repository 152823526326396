/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/require-default-props */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react'

import { MapContainer, TileLayer, Marker } from 'react-leaflet'

import { List, Space, Avatar, Typography } from 'antd'
import { HomeOutlined, ShopOutlined, BankOutlined } from '@ant-design/icons'

import { useScale } from '~/hooks/Scales/useScale'

import AddAddressToScaleButton from '~/components/DataManipulation/Misc/AddAddressToScaleButton'
import { translate } from '~/utils/locale'

interface ScaleGeneralAddressListProps {
  isVisualization?: boolean
}

const ScaleGeneralAddressList: React.FC<ScaleGeneralAddressListProps> = ({
  isVisualization = false,
}) => {
  const [clientsAddresses, setClientsAddresses] = useState<any[]>()

  const { Text } = Typography

  const { scaleData } = useScale()

  useEffect(() => {
    function getClientsAddress() {
      let allClients: any[] | undefined

      scaleData.customersSchedules.filter((custumerSchedule) => {
        if (custumerSchedule.customer?.contact.contactAddresses) {
          custumerSchedule.customer?.contact.contactAddresses.filter(
            (address: any) => {
              if (allClients) {
                allClients = [...allClients, address]
              } else {
                allClients = [address]
              }
            },
          )
        }
      })

      return allClients
    }

    const allAddresses = getClientsAddress()

    setClientsAddresses(allAddresses)
  }, [scaleData])

  return (
    <List
      itemLayout="vertical"
      size="large"
      dataSource={clientsAddresses}
      renderItem={(item) => (
        <List.Item
          key={item.id}
          extra={
            <MapContainer
              center={
                item.address.location
                  ? [item.address.location.x, item.address.location.y]
                  : [0, 0]
              }
              zoom={17}
              scrollWheelZoom={false}
              style={{ height: '200px', width: '350px' }}
              zoomControl={false}
              dragging={false}
              boxZoom={false}
              doubleClickZoom={false}
            >
              <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              {item.address.location && (
                <Marker
                  position={[item.address.location.x, item.address.location.y]}
                />
              )}
            </MapContainer>
          }
        >
          <List.Item.Meta
            avatar={
              <Avatar
                style={{
                  color: '#000',
                  backgroundColor: 'transparent',
                }}
                icon={
                  item.type === 'personal' ? (
                    <HomeOutlined />
                  ) : item.type === 'business' ? (
                    <ShopOutlined />
                  ) : (
                    <BankOutlined />
                  )
                }
              />
            }
            title={
              <Space size="large">
                <b>
                  {item.type === 'personal'
                    ? translate('ScalesGeneralAddressList.personalAddress')
                    : item.type === 'business'
                      ? translate('ScalesGeneralAddressList.businessAddress')
                      : translate('ScalesGeneralAddressList.billingAddress')}
                </b>
                {!isVisualization && (
                  <Space size="small">
                    <AddAddressToScaleButton id={item.address.id} />
                  </Space>
                )}
              </Space>
            }
          />
          <Space direction="vertical">
            <Space size="large">
              <Space size="small">
                <Text strong>
                  {translate('ScalesGeneralAddressList.zipcodeLabel')}
                </Text>
                <Text>{item.address.zip_code}</Text>
              </Space>
              <Space size="small">
                <Text strong>
                  {translate('ScalesGeneralAddressList.stateLabel')}:
                </Text>
                <Text>{item.address.state}</Text>
              </Space>
              <Space size="small">
                <Text strong>
                  {translate('ScalesGeneralAddressList.cityLabel')}
                </Text>
                <Text>{item.address.city}</Text>
              </Space>
            </Space>
            <Space size="large">
              <Space size="small">
                <Text strong>
                  {translate('ScalesGeneralAddressList.neighborhoodLabel')}
                </Text>
                <Text>{item.address.neighborhood}</Text>
              </Space>
              <Space size="small">
                <Text strong>
                  {translate('ScalesGeneralAddressList.streetLabel')}
                </Text>
                <Text>{item.address.street}</Text>
              </Space>
              <Space size="small">
                <Text strong>
                  {translate('ScalesGeneralAddressList.numberLabel')}
                </Text>
                <Text>{item.address.number}</Text>
              </Space>
              <Space size="small">
                <Text strong>
                  {translate('ScalesGeneralAddressList.complementLabel')}
                </Text>
                <Text>{item.address.complement}</Text>
              </Space>
            </Space>
            <Space size="large">
              <Space size="small">
                <Text strong>
                  {translate('ScalesGeneralAddressList.typeLabel')}
                </Text>
                <Text>
                  {item.type === 'personal'
                    ? translate('ScalesGeneralAddressList.typePersonal')
                    : item.type === 'business'
                      ? translate('ScalesGeneralAddressList.typeBusiness')
                      : translate('ScalesGeneralAddressList.typeBilling')}
                </Text>
              </Space>
              <Space size="small">
                <Text strong>
                  {translate('ScalesGeneralAddressList.notesLabel')}
                </Text>
                <Text>{item.notes}</Text>
              </Space>
            </Space>
          </Space>
        </List.Item>
      )}
    />
  )
}

export default ScaleGeneralAddressList
