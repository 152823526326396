export default {
  pt: {
    EvaluationList: {
      deletePopconfirm: 'Deseja mesmo deletar essa avaliação?',
      deleteTooltip: 'Deletar avaliação',
    },
  },
  en: {
    EvaluationList: {
      deletePopconfirm: 'Do you really want to delete this review?',
      deleteTooltip: 'Delete review',
    },
  },
  es: {
    EvaluationList: {
      deletePopconfirm: '¿Realmente quieres eliminar esta reseña?',
      deleteTooltip: 'Borrar reseña',
    },
  },
}
