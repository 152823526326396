import { LoadingOutlined } from '@ant-design/icons'

import React from 'react'

import { Helmet } from 'react-helmet'
import TasksTab from '~/components/DataManipulation/Tasks/Tabs'
import { ListClientsProvider } from '~/hooks/Clients/useListClients'
import { ListContributorsProvider } from '~/hooks/Contributors/useListContributors'
import { ListEquipmentsProvider } from '~/hooks/Equipments/ListEquipmentsHooks'
import { TaskTypeProvider } from '~/hooks/Tasks/TaskType/useTaskType'
import { TaskListProvider } from '~/hooks/Tasks/useListTasks'
import { TaskProvider, useTask } from '~/hooks/Tasks/useTask'
import { TeamsProvider } from '~/hooks/Teams/useListTeams'
import { translate } from '~/utils/locale'
import { Container, LoadingIndicator } from './styles'
import { ContributorsTaskFilterProvider } from '~/hooks/Tasks/useListContributorsTask'
import { ListParentClientsProvider } from '~/hooks/Clients/useListParentCustomer'

const ShowClients: React.FC = () => {
  const { isLoading } = useTask()

  return (
    <Container>
      <Helmet>
        <title>{translate('ShowTasks.helmetTitle')}</title>
      </Helmet>

      <TaskProvider>
        {isLoading ? (
          <LoadingIndicator>
            <LoadingOutlined style={{ fontSize: 64, margin: 'auto' }} spin />
          </LoadingIndicator>
        ) : (
          <ListContributorsProvider>
            <ContributorsTaskFilterProvider>
              <TaskTypeProvider>
                <ListEquipmentsProvider>
                  <TeamsProvider>
                    <ListClientsProvider>
                      <TaskListProvider>
                        <ListParentClientsProvider>
                          <TasksTab />
                        </ListParentClientsProvider>
                      </TaskListProvider>
                    </ListClientsProvider>
                  </TeamsProvider>
                </ListEquipmentsProvider>
              </TaskTypeProvider>
            </ContributorsTaskFilterProvider>
          </ListContributorsProvider>
        )}
      </TaskProvider>
    </Container>
  )
}

export default ShowClients
