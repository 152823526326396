import styled from 'styled-components'

export const Container = styled.div`
  h1 {
    padding: 10px;
  }
`

export const Evaluation = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
