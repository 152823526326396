export default {
  pt: {
    Equipments: {
      helmetTitle: 'Equipamentos',
    },
  },
  en: {
    Equipments: {
      helmetTitle: 'Equipment',
    },
  },
  es: {
    Equipments: {
      helmetTitle: 'Equipo',
    },
  },
}
