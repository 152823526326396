export default {
  pt: {
    UsersDetails: {
      dateString: 'às',
      pageHeaderTitle: 'Visualizar Usuário',
      cardTitle: 'Informações Gerais',
      activeText: 'Ativo',
      inactiveText: 'Inativo',
      createdText: 'Criado em',
      emailText: 'E-mail:',
      noEmailText: 'Nenhum e-mail informado.',
      groupsCardTitle: 'Grupos pertencentes',
      viewGroupTooltip: 'Ver grupo',
      deletePopconfirm: 'Deseja mesmo sair do grupo?',
      deleteTooltip: 'Sair do grupo',
      permissionText: 'permissão',
      permissionsText: 'permissões',
      companiesCardTitle: 'Companhias pertencentes',
    },
  },
  en: {
    UsersDetails: {
      dateString: 'at',
      pageHeaderTitle: 'View User',
      cardTitle: 'General information',
      activeText: 'Active',
      inactiveText: 'Inactive',
      createdText: 'Created at',
      emailText: 'Email:',
      noEmailText: 'No email informed.',
      groupsCardTitle: 'Belonging groups',
      viewGroupTooltip: 'View group',
      deletePopconfirm: 'Do you really want to leave the group?',
      deleteTooltip: 'Leave the group',
      permissionText: 'permission',
      permissionsText: 'permissions',
      companiesCardTitle: 'Companies belonging',
    },
  },
  es: {
    UsersDetails: {
      dateString: 'a las',
      pageHeaderTitle: 'Ver Usuario',
      cardTitle: 'Informaciones Generales',
      activeText: 'Activo',
      inactiveText: 'Inactivo',
      createdText: 'Creado en',
      emailText: 'Correo electrónico:',
      noEmailText: 'No hay correo electrónico informado.',
      groupsCardTitle: 'Grupos de pertenencia',
      viewGroupTooltip: 'Ver grupo',
      deletePopconfirm: '¿De verdad quieres dejar el grupo?',
      deleteTooltip: 'Dejar el grupo',
      permissionText: 'permiso',
      permissionsText: 'permisos',
      companiesCardTitle: 'Empresas pertenecientes',
    },
  },
}
