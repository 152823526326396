export default {
  pt: {
    EquipmentsTabsSpecification: {
      attributeColumn: 'Atributo',
      valueColumn: 'Valor',
      cardTitle: 'Especificações',
      deletePopconfirm: 'Deseja mesmo deletar a especificação',
      deleteTooltip: 'Deletar especificação',
    },
  },
  en: {
    EquipmentsTabsSpecification: {
      attributeColumn: 'Attribute',
      valueColumn: 'Value',
      cardTitle: 'Specifications',
      deletePopconfirm: 'Do you really want to delete the specification',
      deleteTooltip: 'Delete Specification',
    },
  },
  es: {
    EquipmentsTabsSpecification: {
      attributeColumn: 'Atributo',
      valueColumn: 'Valor',
      cardTitle: 'Especificaciones',
      deletePopconfirm: '¿Realmente desea eliminar la especificación?',
      deleteTooltip: 'Eliminar Especificación',
    },
  },
}
