/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { useContext, createContext, useRef, useState } from 'react'

import { TourProps } from 'antd'
import { useHistory } from 'react-router-dom'

interface TourContextData {
  welcomeRef: any
  welcomeTaskRef: any
  typeTaskRef: any
  initialSteps: any
  teste?: boolean
  setTeste(value: any): void
  isTour?: boolean
  setIsTour(value: any): void
}

const TourContext = createContext<TourContextData>({} as TourContextData)

export const TourProvider: React.FC = ({ children }) => {
  const welcomeRef = useRef<HTMLButtonElement>(null)
  const welcomeTaskRef = useRef<HTMLButtonElement>(null)
  const typeTaskRef = useRef<HTMLButtonElement>(null)

  const history = useHistory()

  const [teste, setTeste] = useState(false)

  const [isTour, setIsTour] = useState(false)

  function Teste() {
    setTeste(true)
    setIsTour(true)

    history.push('tiposDeTarefas')
  }

  const initialSteps: TourProps['steps'] = [
    {
      title: <h1 style={{ marginBottom: 0 }}>Bem Vindo ao Gstor!</h1>,
      description: (
        <div>
          <p>
            Estamos empolgados por tê-lo(a) a bordo e confiantes de que nossa
            plataforma proporcionará uma experiência inigualável na gestão dos
            seus serviços. Se precisar de suporte ou tiver alguma dúvida, nossa
            equipe está pronta para ajudar.
          </p>
          <h4 style={{ marginTop: 8 }}>
            Vamos juntos alcançar novos patamares de eficiência!
          </h4>
        </div>
      ),
      cover: (
        <img
          alt="tour.png"
          src="https://images.pexels.com/photos/3183186/pexels-photo-3183186.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
        />
      ),
      target: () => welcomeRef.current!,
    },
    {
      title: <h1 style={{ marginBottom: 0 }}>Conheça o Gstor</h1>,
      description: (
        <div>
          <p>
            Vamos começar criando os serviços, que desempenham um papel crucial
            na organização e execução de operações específicas, proporcionando
            uma estrutura organizada para a realização de atividades designadas.
          </p>
        </div>
      ),
      cover: (
        <img
          alt="tour.png"
          src="https://images.pexels.com/photos/3183190/pexels-photo-3183190.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
        />
      ),
      target: () => welcomeTaskRef.current!,
      nextButtonProps: { onClick: Teste },
    },
  ]

  return (
    <TourContext.Provider
      value={{
        initialSteps,
        welcomeRef,
        welcomeTaskRef,
        typeTaskRef,
        teste,
        setTeste,
        isTour,
        setIsTour,
      }}
    >
      {children}
    </TourContext.Provider>
  )
}

export function useTour(): TourContextData {
  const context = useContext(TourContext)

  if (!context) {
    throw new Error('useAuth must be used within an AutoProvider')
  }

  return context
}
