export default {
  pt: {
    TicketTypeTable: {
      titleColumn: 'Tipo de Solicitação',
      descriptionColumn: 'Descrição',
      actionsColumn: 'Ações',
      deletePopconfirm: 'Deseja mesmo deletar o tipo de solicitação?',
      deleteTooltip: 'Deletar',
    },
  },
  en: {
    TicketTypeTable: {
      titleColumn: 'Ticket Type',
      descriptionColumn: 'Description',
      actionsColumn: 'Actions',
      deletePopconfirm: 'Do you really want to delete the ticket type?',
      deleteTooltip: 'Delete',
    },
  },
  es: {
    TicketTypeTable: {
      titleColumn: 'Tipo de Solicitude',
      descriptionColumn: 'Descripción',
      actionsColumn: 'Acciones',
      deletePopconfirm: '¿Realmente desea eliminar el tipo de solicitude?',
      deleteTooltip: 'Borrar',
    },
  },
}
