export default {
  pt: {
    TasksPrintPDF: {
      headerTitle: 'Relatório de Atendimento Técnico',
      noteText: 'Anotação',
      archiveText: 'Arquivo',
      contentTitle: 'RELATÓRIO DE ATENDIMENTO TÉCNICO',
      serviceNumber: 'Número Serviço:',
      externalCode: 'Código Externo:',
      taskDate: 'Data do Serviço',
      dateString: 'às',
      priorityText: 'Prioridade:',
      priorityLow: 'Baixa',
      priorityMedium: 'Média',
      priorityHigh: 'Alta',
      createdBy: 'Criado por:',
      taskType: 'Tipo de Tarefa:',
      durationTime: 'Duração',
      toleranceTime: 'Tempo máximo para iniciar o serviço (SLA):',
      statusText: 'Status:',
      statusReceived: 'Tarefa Enviada',
      statusViewed: 'Tarefa Visualizada',
      statusWorking: 'Tarefa sendo realizada',
      statusBreak: 'Tarefa Pausada',
      statusEnRoute: 'Tarefa a caminho',
      statusResumeWorking: 'Tarefa Retomada',
      statusCanceled: 'Tarefa Cancelada',
      statusFinished: 'Tarefa Finalizada',
      responsibleData: 'Dados do Prestador:',
      clientData: 'Dados do Cliente:',
      companyName: 'Nome da Empresa:',
      clientName: 'Nome do Cliente:',
      addressText: 'Endereço:',
      cnpjText: 'CNPJ:',
      contributorName: 'Técnico:',
      responsibleText: 'Responsável',
      phoneText: 'Telefone:',
      emailText: 'email:',
      taskAddress: 'Endereço Serviço',
      streetText: 'Logradouro:',
      neighborhoodText: 'Bairro:',
      numberText: 'Número',
      complementText: 'Complemento:',
      cityText: 'Cidade:',
      stateText: 'Estado',
      zipcodeText: 'CEP',
      taskEquipments: 'Equipamentos da Tarefa:',
      equipmentName: 'Nome:',
      equipmentIdentifier: 'Identificador:',
      equipmentCategory: 'Categoria:',
      taskReportsText: 'Evidências da Tarefa:',
      taskReportsType: 'Tipo:',
      taskReportsTitle: 'Título:',
      taskReportsContent: 'Conteúdo:',
      footerTitle: 'Relatório gerado por app.gstor.com.br',
      footerPageOne: 'Página',
      footerPageTwo: 'de',
    },
  },
  en: {
    TasksPrintPDF: {
      headerTitle: 'Technical Service Report',
      noteText: 'Annotation',
      archiveText: 'File',
      contentTitle: 'TECHNICAL SERVICE REPORT',
      serviceNumber: 'Service Number:',
      externalCode: 'External Code:',
      taskDate: 'Service Date',
      dateString: 'at',
      priorityText: 'Priority:',
      priorityLow: 'Low',
      priorityMedium: 'Medium',
      priorityHigh: 'High',
      createdBy: 'Created by:',
      taskType: 'Task Type:',
      durationTime: 'Duration',
      toleranceTime: 'Service SLA:',
      statusText: 'Status:',
      statusReceived: 'Task Submitted',
      statusViewed: 'Task Viewed',
      statusWorking: 'Task being performed',
      statusBreak: 'Paused Task',
      statusEnRoute: 'Task on the way',
      statusResumeWorking: 'Resume Task',
      statusCanceled: 'Canceled Task',
      statusFinished: 'Finished Task',
      responsibleData: 'Provider Data:',
      clientData: "Customer's data:",
      companyName: 'Company Name:',
      clientName: 'Customer Name:',
      addressText: 'Address:',
      cnpjText: 'CNPJ:',
      contributorName: 'Technician:',
      responsibleText: 'Responsible',
      phoneText: 'Phone:',
      emailText: 'email:',
      taskAddress: 'Service Address',
      streetText: 'Public place:',
      neighborhoodText: 'Neighborhood:',
      numberText: 'Number',
      complementText: 'Complement:',
      cityText: 'City:',
      stateText: 'State',
      zipcodeText: 'Zipcode',
      taskEquipments: 'Task Equipment:',
      equipmentName: 'Name:',
      equipmentIdentifier: 'Identifier:',
      equipmentCategory: 'Category:',
      taskReportsText: 'Task Reports:',
      taskReportsType: 'Type:',
      taskReportsTitle: 'Title:',
      taskReportsContent: 'Content:',
      footerTitle: 'Report generated by app.gstor.com.br',
      footerPageOne: 'Page',
      footerPageTwo: 'of',
    },
  },
  es: {
    TasksPrintPDF: {
      headerTitle: 'Informe de servicio técnico',
      noteText: 'Anotación',
      archiveText: 'Archivo',
      contentTitle: 'INFORME DE SERVICIO TÉCNICO',
      serviceNumber: 'Número de servicio:',
      externalCode: 'Código Externo:',
      taskDate: 'Fecha de servicio',
      dateString: 'a las',
      priorityText: 'Prioridad:',
      priorityLow: 'Baja',
      priorityMedium: 'Media',
      priorityHigh: 'Alta',
      createdBy: 'Criado por:',
      taskType: 'Tipo de Tarea:',
      durationTime: 'Duración',
      toleranceTime: 'Acuerdo de Nivel de Servicio:',
      statusText: 'Status:',
      statusReceived: 'Tarea enviada',
      statusViewed: 'Tarea vista',
      statusWorking: 'Tarea que se está realizando',
      statusBreak: 'Tarea en pausa',
      statusEnRoute: 'Tarea en camino',
      statusResumeWorking: 'Reanudar tarea',
      statusCanceled: 'Tarea cancelada',
      statusFinished: 'Tarea terminada',
      responsibleData: 'Datos del Proveedor:',
      clientData: 'Datos del Cliente:',
      companyName: 'Nombre de la empresa:',
      clientName: 'Nombre del cliente:',
      addressText: 'Dirección:',
      cnpjText: 'CNPJ:',
      contributorName: 'Técnico:',
      responsibleText: 'Responsable',
      phoneText: 'Teléfono:',
      emailText: 'Correo electrónico:',
      taskAddress: 'Dirección de Servicio',
      streetText: 'Lugar público:',
      neighborhoodText: 'Barrio:',
      numberText: 'Número',
      complementText: 'Complemento:',
      cityText: 'Ciudad:',
      stateText: 'Estado',
      zipcodeText: 'Código Postal',
      taskEquipments: 'Equipo de trabajo:',
      equipmentName: 'Nombre:',
      equipmentIdentifier: 'Identificador:',
      equipmentCategory: 'Categoría:',
      taskReportsText: 'Informes de tareas:',
      taskReportsType: 'Tipo:',
      taskReportsTitle: 'Título:',
      taskReportsContent: 'Contenido:',
      footerTitle: 'Informe generado por app.gstor.com.br',
      footerPageOne: 'Página',
      footerPageTwo: 'de',
    },
  },
}
