import { ArrowLeftOutlined } from '@ant-design/icons'
import { Button, Card, Form, Input, Space } from 'antd'

import React from 'react'
import { Link } from 'react-router-dom'
import { TaskFormProvider, useTaskForm } from '~/hooks/Tasks/Forms/useTaskForm'
import { translate } from '~/utils/locale'
import Questions from './questions'
import { Container } from './styles'

interface FormData {
  id: string
  title: string
  description?: string
}

const AboutForm: React.FC = () => {
  return (
    <TaskFormProvider>
      <Page />
    </TaskFormProvider>
  )
}

const Page: React.FC = () => {
  const { updateForm, form, isLoading } = useTaskForm()
  if (isLoading || !form) {
    return (
      <Card
        title={
          <Space size="large">
            <Link to="/formularios">
              <ArrowLeftOutlined />
            </Link>
            <b>{translate('TasksRegistrationFormsAbout.modalTitle')}</b>
          </Space>
        }
        loading
        bordered={false}
        style={{ width: '100%', padding: 30 }}
      />
    )
  }
  const initialData = {
    description: form.description,
    title: form.title,
  }

  function onFinish(data: FormData) {
    try {
      updateForm(data)
    } catch (err) {}
  }

  return (
    <Container>
      <Card
        title={
          <Space size="large">
            <Link to="/formularios">
              <ArrowLeftOutlined />
            </Link>
            <b>{translate('TasksRegistrationFormsAbout.modalTitle')}</b>
          </Space>
        }
        bordered={false}
        style={{ width: '100%', padding: 30 }}
      >
        <Form
          onFinish={onFinish}
          initialValues={initialData}
          layout="vertical"
          id="taskTypeForm"
        >
          <Form.Item
            label={translate('TasksRegistrationFormsAbout.titleLabel')}
            name="title"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input
              placeholder={translate(
                'TasksRegistrationFormsAbout.titlePlaceholder',
              )}
            />
          </Form.Item>
          <Form.Item
            label={translate('TasksRegistrationFormsAbout.descriptionLabel')}
            name="description"
          >
            <Input.TextArea
              placeholder={translate(
                'TasksRegistrationFormsAbout.descriptionPlaceholder',
              )}
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" id="btn_update_form">
              {translate('TasksRegistrationFormsAbout.saveButton')}
            </Button>
          </Form.Item>
        </Form>
      </Card>
      <Questions />
    </Container>
  )
}

export default AboutForm
