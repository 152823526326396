/* eslint-disable @typescript-eslint/no-explicit-any */
import { EditOutlined } from '@ant-design/icons'
import { Modal, Button, Form, Input, Tooltip, Space } from 'antd'
import React, { useState } from 'react'
import { useCategory } from '~/hooks/Equipments/Category/CategoryHooks'
import { translate } from '~/utils/locale'
import { ContainerButton } from '../styles'

interface CategoryProps {
  id: string
  title: string
}

interface Category {
  category: CategoryProps
}

const UpdateCategory: React.FC<Category> = ({ category }) => {
  const { updateCategory } = useCategory()
  const [isModalVisible, setIsModalVisible] = useState(false)

  const initialData = {
    title: category.title,
  }

  function onUpdateCategory(valueForm: any) {
    try {
      updateCategory(valueForm, category.id)
      setIsModalVisible(false)
    } catch (err) {
      setIsModalVisible(false)
    }
  }

  const showModal = () => {
    setIsModalVisible(true)
  }

  const closeModal = () => {
    setIsModalVisible(false)
  }

  return (
    <div>
      <Tooltip
        title={translate('EquipmentsCategoryUpdate.editCategoryTooltip')}
      >
        <Button
          type="primary"
          onClick={showModal}
          ghost
          shape="circle"
          icon={<EditOutlined />}
        />
      </Tooltip>
      <Modal
        title={translate('EquipmentsCategoryUpdate.modalTitle')}
        open={isModalVisible}
        onOk={closeModal}
        onCancel={closeModal}
        width="800px"
        footer={[
          <Space key="buttons">
            <Button key="back" onClick={closeModal}>
              {translate('EquipmentsCategoryUpdate.closeButton')}
            </Button>
            <ContainerButton>
              <Button
                form="newCategoryForm"
                key="save"
                type="primary"
                htmlType="submit"
                id="btn_update_category"
              >
                {translate('EquipmentsCategoryUpdate.saveButton')}
              </Button>
            </ContainerButton>
          </Space>,
        ]}
      >
        <Form
          layout="vertical"
          id="newCategoryForm"
          initialValues={initialData}
          onFinish={onUpdateCategory}
        >
          <Form.Item
            label={translate('EquipmentsCategoryUpdate.descriptionLabel')}
            name="title"
            rules={[
              {
                required: true,
                message: translate('EquipmentsCategoryUpdate.descriptionRule'),
              },
            ]}
          >
            <Input.TextArea
              placeholder={translate(
                'EquipmentsCategoryUpdate.descriptionPlaceholder',
              )}
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}

export default UpdateCategory
