import React, { useState } from 'react'

import {
  Container,
  Card,
  Cover,
  Circle,
  ContentSkeleton,
  Content,
  ContentBottom,
} from './styles'
import QRCode from 'qrcode.react'
import { Radio } from 'antd'
import { RadioChangeEvent } from 'antd/lib'

const Preview: React.FC = () => {
  const [value, setValue] = useState('Template01')

  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value)
  }

  localStorage.setItem('@gstorTemplateCracha', value)

  return (
    <Container>
      <Card
        style={{ border: value === 'Template01' ? '1px solid black' : 'none' }}
      >
        <Cover typeBadge="coverTop" shape="circle" />

        <Content>
          <Circle typeBadge="coverTop" />
          <ContentSkeleton />
          <QRCode
            value="https://ant.design/"
            fgColor="#333536"
            // bgColor="#fff"
            size={50}
          />
        </Content>
      </Card>

      <Card
        style={{ border: value === 'Template02' ? '1px solid black' : 'none' }}
      >
        <Cover typeBadge="coverTop" shape="retangle" />

        <Content>
          <Circle typeBadge="coverTop" />
          <ContentSkeleton />
          <QRCode
            value="https://ant.design/"
            fgColor="#333536"
            // bgColor="#fff"
            size={50}
          />
        </Content>
      </Card>

      <Card
        style={{ border: value === 'Template03' ? '1px solid black' : 'none' }}
      >
        <ContentBottom>
          <Circle typeBadge="coverBottom" />
          <ContentSkeleton />
          <QRCode
            value="https://ant.design/"
            fgColor="#333536"
            // bgColor="#fff"
            size={50}
          />
        </ContentBottom>
      </Card>

      <Radio.Group onChange={onChange} value={value}>
        <Radio value="Template01">Template 01</Radio>
        <Radio value="Template02">Template 02</Radio>
        <Radio value="Template03">Template 03</Radio>
      </Radio.Group>
    </Container>
  )
}

export default Preview
