/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react'

import { Avatar, Divider, Drawer } from 'antd'

import Text from 'antd/lib/typography/Text'
import { UserOutlined } from '@ant-design/icons'
import NumberFormat from 'react-number-format'
import { Container, Wrapper } from './styles'

import { useListContacts } from '~/hooks/Clients/useListContacts'
import { translate } from '~/utils/locale'

const TalkToContact: React.FC = () => {
  const { contacts, isLoading } = useListContacts()
  const [open, setOpen] = useState(false)

  const showDrawer = () => {
    setOpen(true)
  }
  const onClose = () => {
    setOpen(false)
  }

  if (isLoading || !contacts) {
    return <p />
  }

  const contactResponsible = contacts.filter((contact) => {
    return contact.is_responsible === true
  })

  return (
    <Container>
      <span onClick={showDrawer}>
        {translate('ClientsTalkToContact.seeContactsText')}
      </span>
      <Drawer
        title={translate('ClientsTalkToContact.drawerTitle')}
        placement="right"
        onClose={onClose}
        open={open}
        width={500}
      >
        <Wrapper>
          {contactResponsible.length !== 0 ? (
            <>
              {contactResponsible.map((contact) => (
                <div key={contact.contact.id}>
                  <div className="avatar">
                    <Avatar icon={<UserOutlined />} />
                    <h3>
                      {contact.contact.name} - {contact.office}
                    </h3>
                  </div>
                  <div className="contact">
                    <Text>{contact.contact.email}</Text>
                    <Text>
                      <NumberFormat
                        value={contact.contact.phone}
                        displayType="text"
                        format="(##) #-####-####"
                      />
                    </Text>
                  </div>
                  <Divider />
                </div>
              ))}
            </>
          ) : (
            <p>{translate('ClientsTalkToContact.noContactText')}</p>
          )}
        </Wrapper>
      </Drawer>
    </Container>
  )
}

export default TalkToContact
