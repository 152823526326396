export default {
  pt: {
    useClients: {
      getErrorMessage: 'Erro ao conseguir dados de cliente.',
      updateSuccessMessage: 'Cliente atualizado com sucesso!',
      updateErrorMessage: 'Você não tem permissão para editar clientes.',
      deleteSuccessMessage: 'Cliente deletado com sucesso!',
      deleteErrorMessage: 'Houve um erro ao deletar o cliente.',
    },
  },
  en: {
    useClients: {
      getErrorMessage: 'Error getting customer data.',
      updateSuccessMessage: 'Client successfully updated!',
      updateErrorMessage: 'You are not allowed to edit accounts.',
      deleteSuccessMessage: 'Client successfully deleted!',
      deleteErrorMessage: 'There was an error deleting the client.',
    },
  },
  es: {
    useClients: {
      getErrorMessage: 'Error al obtener los datos del cliente.',
      updateSuccessMessage: '¡Cliente actualizado con éxito!',
      updateErrorMessage: 'No se le permite editar cuentas.',
      deleteSuccessMessage: '¡Cliente eliminado con éxito!',
      deleteErrorMessage: 'Hubo un error al eliminar el cliente.',
    },
  },
}
