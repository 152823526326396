import { LoadingOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { Card, Space, Button } from 'antd'
import Text from 'antd/lib/typography/Text'
import React from 'react'
import { Link } from 'react-router-dom'
import { useClientsContacts } from '~/hooks/Clients/useContactClients'
import { translate } from '~/utils/locale'

import ClientsContactsFilter from './Filter'
import ClientsContactsTable from './Table'

const ContactsBoard: React.FC = () => {
  const { isLoading, isFetching, clientContactTotal } = useClientsContacts()

  return (
    <Card
      title={
        <Space size="large">
          <b>{translate('ClientsContacts.contactsTitle')}</b>
          {isLoading ? (
            <LoadingOutlined spin />
          ) : (
            <Text
              type="secondary"
              style={{ fontWeight: 'normal', fontSize: 14 }}
            >
              {clientContactTotal !== undefined ? (
                <>
                  {clientContactTotal === 1
                    ? `${translate(
                        'ClientsContacts.totalText',
                      )} ${clientContactTotal} ${translate(
                        'ClientsContacts.contactText',
                      )}`
                    : `${translate(
                        'ClientsContacts.totalText',
                      )} ${clientContactTotal} ${translate(
                        'ClientsContacts.contactsText',
                      )}`}
                </>
              ) : (
                ''
              )}
            </Text>
          )}
          {!isLoading && isFetching && <LoadingOutlined spin />}
        </Space>
      }
      extra={
        <Space>
          <ClientsContactsFilter />

          <Link to="/contatosCliente/novo">
            <Button type="primary" icon={<PlusCircleOutlined />}>
              {translate('ClientsContacts.newContactButton')}
            </Button>
          </Link>
        </Space>
      }
      bordered={false}
      style={{ width: '100%', padding: 30 }}
    >
      <ClientsContactsTable />
    </Card>
  )
}

export default ContactsBoard
