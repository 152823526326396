export default {
  pt: {
    HelpDeskClientTab: {
      clientLabel: 'Cliente: ',
      telephoneLabel: 'Telefone: ',
      emailLabel: 'E-mail: ',
      attendanceLabel: 'Tempo máximo para iniciar o serviço (SLA):',
      groupLabel: 'Grupo:',
      segmentLabel: 'Segmento:',
      clientTabTitle: 'Detalhes do Cliente',
    },
  },
  en: {
    HelpDeskClientTab: {
      clientLabel: 'Client:',
      telephoneLabel: 'Telephone:',
      emailLabel: 'E-mail:',
      attendanceLabel: 'Service SLA:',
      groupLabel: 'Group:',
      segmentLabel: 'Segment:',
      clientTabTitle: 'Customer Details',
    },
  },
  es: {
    HelpDeskClientTab: {
      clientLabel: 'Cliente:',
      telephoneLabel: 'Teléfono:',
      emailLabel: 'E-mail:',
      attendanceLabel: 'SLA de servicio',
      groupLabel: 'Grupo',
      segmentLabel: 'Segmento',
      clientTabTitle: 'Detalles del cliente',
    },
  },
}
