import { Button, Card, Col, Row, Typography } from 'antd'
import React from 'react'

import { Container } from './styles'
import {
  FiBriefcase,
  FiCalendar,
  FiClock,
  FiCreditCard,
  FiDollarSign,
  FiFileText,
  FiTruck,
} from 'react-icons/fi'

import Text from 'antd/lib/typography/Text'
import { useTeam } from '~/hooks/Teams/useTeams'
import { EditOutlined, LoadingOutlined } from '@ant-design/icons'
import { LoadingIndicator } from '~/pages/Contributor/ShowContributor/styles'
import { format } from 'date-fns'
import CurrencyFormatter from '~/components/DataManipulation/Misc/CurrencyFormater'
import { translate } from '~/utils/locale'

const FinancialTeam: React.FC = () => {
  const { team, isLoading, setIsEdit } = useTeam()

  if (isLoading || !team) {
    return (
      <LoadingIndicator>
        <LoadingOutlined style={{ fontSize: 64, margin: 'auto' }} spin />
      </LoadingIndicator>
    )
  }

  const formattedDate = format(new Date(team.payment_day), `dd/MM/yyyy '`)

  return (
    <Container>
      <Card
        bordered={false}
        title={translate('TeamsComponentTabFinancial.tabTitle')}
        extra={
          <Button
            type="primary"
            icon={<EditOutlined />}
            onClick={() => setIsEdit(true)}
            id="btn_edit_financial_team"
          >
            {translate('TeamsComponentTabFinancial.buttonEdit')}
          </Button>
        }
        style={{
          display: 'inline-block',
          width: '100%',
        }}
      >
        <Typography.Title level={5}>
          {translate('TeamsComponentTabFinancial.cardTitle')}
        </Typography.Title>
        <Row>
          <Col span={12}>
            <div className="text">
              <div>
                <FiDollarSign />
                <Text strong>
                  {translate('TeamsComponentTabFinancial.valueText')}
                </Text>
              </div>
              {team.amount_to_receive === null ? (
                <Text>
                  {translate('TeamsComponentTabFinancial.undefinedText')}
                </Text>
              ) : (
                <Text>
                  <CurrencyFormatter value={team.amount_to_receive} />
                </Text>
              )}
            </div>
          </Col>
          <Col span={12}>
            <div className="text">
              <div>
                <FiCalendar />
                <Text strong>
                  {translate('TeamsComponentTabFinancial.dayText')}
                </Text>
              </div>
              {team.payment_day === null ? (
                <Text>
                  {translate('TeamsComponentTabFinancial.undefinedText')}
                </Text>
              ) : (
                <Text>{formattedDate}</Text>
              )}
            </div>
          </Col>
        </Row>

        <Row gutter={4}>
          <Col span={12}>
            <div className="text">
              <div>
                <FiClock />
                <Text strong>
                  {translate('TeamsComponentTabFinancial.hoursText')}
                </Text>
              </div>
              {team.amount_hours_receive ? (
                <Text>
                  {team.amount_hours_receive}{' '}
                  {translate('TeamsComponentTabFinancial.hoursLabel')}
                </Text>
              ) : (
                <Text>
                  {translate('TeamsComponentTabFinancial.undefinedText')}
                </Text>
              )}
            </div>
          </Col>
          <Col span={12}>
            <div className="text">
              <div>
                <FiClock />
                <Text strong>
                  {translate('TeamsComponentTabFinancial.valueHoursText')}
                </Text>
              </div>
              {team.amount_hours_additional_receive ? (
                <Text>
                  <CurrencyFormatter
                    value={team.amount_hours_additional_receive}
                  />
                </Text>
              ) : (
                <Text>
                  {translate('TeamsComponentTabFinancial.undefinedText')}
                </Text>
              )}
            </div>
          </Col>
        </Row>

        <Row gutter={4}>
          <Col span={12}>
            <div className="text">
              <div>
                <FiTruck />
                <Text strong>
                  {translate('TeamsComponentTabFinancial.kmText')}
                </Text>
              </div>
              {team.number_km_receive ? (
                <Text>{team.number_km_receive} km</Text>
              ) : (
                <Text>
                  {translate('TeamsComponentTabFinancial.undefinedText')}
                </Text>
              )}
            </div>
          </Col>
          <Col span={12}>
            <div className="text">
              <div>
                <FiTruck />
                <Text strong>
                  {translate('TeamsComponentTabFinancial.kmValueText')}
                </Text>
              </div>
              {team.number_km_additional_receive ? (
                <Text>
                  <CurrencyFormatter
                    value={team.number_km_additional_receive}
                  />
                </Text>
              ) : (
                <Text>
                  {translate('TeamsComponentTabFinancial.undefinedText')}
                </Text>
              )}
            </div>
          </Col>
        </Row>

        <Row gutter={4}>
          <Col span={12}>
            <div className="text">
              <div>
                <FiDollarSign />
                <Text strong>
                  {translate('TeamsComponentTabFinancial.conditionsText')}
                </Text>
              </div>
              {team.payment_conditions ? (
                <>
                  {team.payment_conditions === 'view' ? (
                    <Text>
                      {translate('TeamsComponentTabFinancial.parcelText')}
                    </Text>
                  ) : (
                    <Text>
                      {translate('TeamsComponentTabFinancial.viewText')}
                    </Text>
                  )}
                </>
              ) : (
                <Text>
                  {translate('TeamsComponentTabFinancial.undefinedText')}
                </Text>
              )}
            </div>
          </Col>
          <Col span={12}>
            <div className="text">
              <div>
                <FiCreditCard />
                <Text strong>
                  {translate('TeamsComponentTabFinancial.methodText')}
                </Text>
              </div>
              {team.payment_method ? (
                <>
                  {team.payment_method === 'deposit' ? (
                    <Text>
                      {translate('TeamsComponentTabFinancial.depositText')}
                    </Text>
                  ) : (
                    ''
                  )}

                  {team.payment_method === 'ticket' ? (
                    <Text>
                      {translate('TeamsComponentTabFinancial.ticketText')}
                    </Text>
                  ) : (
                    ''
                  )}

                  {team.payment_method === 'pix' ? <Text>PIX</Text> : ''}
                </>
              ) : (
                <Text>
                  {translate('TeamsComponentTabFinancial.undefinedText')}
                </Text>
              )}
            </div>
          </Col>
        </Row>
      </Card>

      <Card
        bordered={false}
        title={translate('TeamsComponentTabFinancial.cardTitleBottom')}
        style={{
          display: 'inline-block',
          width: '100%',
          marginTop: '1rem',
        }}
      >
        <Row gutter={4}>
          <Col span={8}>
            <div className="text">
              <div>
                <FiFileText />
                <Text strong>
                  {translate('TeamsComponentTabFinancial.numberBankText')}
                </Text>
              </div>
              {team.number_bank ? (
                <Text>{team.number_bank}</Text>
              ) : (
                <Text>
                  {translate('TeamsComponentTabFinancial.undefinedText')}
                </Text>
              )}
            </div>
          </Col>
          <Col span={8}>
            <div className="text">
              <div>
                <FiBriefcase />
                <Text strong>
                  {translate('TeamsComponentTabFinancial.nameBankText')}
                </Text>
              </div>
              {team.name_bank ? (
                <Text>{team.name_bank}</Text>
              ) : (
                <Text>
                  {translate('TeamsComponentTabFinancial.undefinedText')}
                </Text>
              )}
            </div>
          </Col>
          <Col span={8}>
            <div className="text">
              <div>
                <FiFileText />
                <Text strong>
                  {translate(
                    'TeamsComponentTabFinancial.typePersonAccountText',
                  )}
                </Text>
              </div>
              {team.type_account_bank ? (
                <>
                  {team.type_account_bank === 'current' ? (
                    <Text>
                      {translate('TeamsComponentTabFinancial.currentText')}
                    </Text>
                  ) : (
                    <Text>
                      {translate('TeamsComponentTabFinancial.savingsText')}
                    </Text>
                  )}
                </>
              ) : (
                <Text>
                  {translate('TeamsComponentTabFinancial.undefinedText')}
                </Text>
              )}
            </div>
          </Col>
        </Row>

        <Row gutter={4}>
          <Col span={8}>
            <div className="text">
              <div>
                <FiFileText />
                <Text strong>
                  {translate('TeamsComponentTabFinancial.agencyText')}
                </Text>
              </div>
              {team.agency_bank ? (
                <Text>{team.agency_bank}</Text>
              ) : (
                <Text>
                  {translate('TeamsComponentTabFinancial.undefinedText')}
                </Text>
              )}
            </div>
          </Col>
          <Col span={8}>
            <div className="text">
              <div>
                <FiFileText />
                <Text strong>
                  {translate('TeamsComponentTabFinancial.accountText')}
                </Text>
              </div>
              {team.account_bank ? (
                <Text>{team.account_bank}</Text>
              ) : (
                <Text>
                  {translate('TeamsComponentTabFinancial.undefinedText')}
                </Text>
              )}
            </div>
          </Col>
          <Col span={8}>
            <div className="text">
              <div>
                <FiFileText />
                <Text strong>
                  {translate('TeamsComponentTabFinancial.typeAccountText')}
                </Text>
              </div>
              {team.personal_bank ? (
                <>
                  {team.personal_bank === 'physics' ? (
                    <Text>
                      {translate('TeamsComponentTabFinancial.physicalText')}
                    </Text>
                  ) : (
                    <Text>
                      {translate('TeamsComponentTabFinancial.juridicText')}
                    </Text>
                  )}
                </>
              ) : (
                <Text>
                  {translate('TeamsComponentTabFinancial.undefinedText')}
                </Text>
              )}
            </div>
          </Col>
        </Row>

        <Row gutter={4}>
          <Col span={8}>
            <div className="text">
              <div>
                <FiDollarSign />
                <Text strong>
                  {translate('TeamsComponentTabFinancial.pixText')}
                </Text>
              </div>
              {team.key_pix ? (
                <Text>{team.key_pix}</Text>
              ) : (
                <Text>
                  {translate('TeamsComponentTabFinancial.undefinedText')}
                </Text>
              )}
            </div>
          </Col>
        </Row>
      </Card>
    </Container>
  )
}

export default FinancialTeam
