import React from 'react'
import { Helmet } from 'react-helmet'

import FormsBoard from '~/components/DataManipulation/TasksRegistration/Forms'

import { TaskFormsProvider } from '~/hooks/Tasks/Forms/useTaskForms'

import { Container } from './styles'

const Forms: React.FC = () => {
  return (
    <Container>
      <Helmet>
        <title>Formularios</title>
      </Helmet>

      <TaskFormsProvider>
        <FormsBoard />
      </TaskFormsProvider>
    </Container>
  )
}

export default Forms
