/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prefer-promise-reject-errors */
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  message,
  Radio,
  RadioChangeEvent,
  Row,
  Select,
  Tour,
} from 'antd'
import { ValidateStatus } from 'antd/lib/form/FormItem'
import { LatLngExpression } from 'leaflet'
import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { MapContainer, Marker, TileLayer, useMap } from 'react-leaflet'
import cep from 'cep-promise'
import { useListClients } from '~/hooks/Clients/useListClients'
import geolocationApi from '~/services/geolocationApi'

import { translate } from '~/utils/locale'
import { useTaskCreate } from '~/hooks/Tasks/Create/createTask'
import { useListTask } from '~/hooks/Tasks/useListTasks'

import { useTaskTour } from '~/hooks/Tour/useTaskTour'
import { useTour } from '~/hooks/Tour/useTour'

interface AddressProps {
  id: string
  type: string
  address: {
    zip_code: string
    street: string
    number: string
    complement: string
    neighborhood: string
    city: string
    id: string
    state: string
    location: { x: number; y: number }
  }
}

interface MapUpdateFunctionProps {
  center: LatLngExpression
  zoom: number
}

interface Teste {
  form: any
}

const AddressCreateTask: React.FC<Teste> = ({ form }) => {
  const { clients } = useListClients()
  const { clientData, setNumber, addressData, setIsCreateTaskOnClient } =
    useTaskCreate()

  const {
    taskAddressOptions,
    createCEPTaskRef,
    createNumberTaskRef,
    openAddressTask,
    setOpenAddressTask,
    createAddressSteps,
  } = useTaskTour()

  const { createAddressTaskOnClient } = useListTask()

  const [addressInfo, setAddressInfo] = useState<AddressProps>()

  const [latitude, setLatitude] = useState<any>(-14.235004)
  const [longitude, setLongitude] = useState<any>(-51.925282)

  const [mapValidate, setMapValidate] = useState(false)
  const [status, setStatus] = useState('' as ValidateStatus)
  const [zoomLevel, setZoomLevel] = useState(3)

  const [hidden, setHidden] = useState(true)

  const [state, setState] = useState('')
  const [city, setCity] = useState('')
  const [street, setStreet] = useState('')

  const [valueButton, setValueButton] = useState(1)

  const { Option } = Select

  const { setIsTour } = useTour()

  function onClose() {
    setIsTour(false)
    setOpenAddressTask(false)
  }

  const formatComma = (value: string, field: 'latitude' | 'longitude') => {
    const valueFormatted = value.replace(/,/g, '.')

    if (
      valueFormatted !== '-' &&
      valueFormatted !== '.' &&
      valueFormatted !== '-.'
    ) {
      if (field === 'latitude') {
        setLatitude(valueFormatted)
      } else if (field === 'longitude') {
        setLongitude(valueFormatted)
      }
    }
  }

  const onChange = (e: RadioChangeEvent) => {
    setValueButton(e.target.value)

    form.setFieldsValue({
      zip_code: '',
      state: '',
      city: '',
      neighborhood: '',
      street: '',
      number: '',
      complement: '',
    })

    setLatitude(-14.235004)
    setLongitude(-51.925282)
    setStatus('')
  }

  useEffect(() => {
    if (latitude !== -14.235004 && longitude !== -51.925282) {
      setZoomLevel(13)
    }
  }, [latitude, longitude])

  const addressClient = clients?.find((client) => client.id === clientData?.id)

  function handleAddress(value: any) {
    setAddressInfo(
      addressClient?.contact.contactAddresses.find((item) => item.id === value),
    )
    setMapValidate(true)

    return addressClient?.contact.contactAddresses.find(
      (item) => item.id === value,
    )
  }

  useEffect(() => {
    form.setFieldsValue({
      zip_code:
        valueButton === 2 ? addressInfo?.address.zip_code : addressData?.cep,
      state:
        valueButton === 2 ? addressInfo?.address.state : addressData?.state,
      city: valueButton === 2 ? addressInfo?.address.city : addressData?.city,
      neighborhood:
        valueButton === 2
          ? addressInfo?.address.neighborhood
          : addressData?.neighborhood,
      street:
        valueButton === 2 ? addressInfo?.address.street : addressData?.street,
      number:
        valueButton === 2 ? addressInfo?.address.number : addressData?.number,
      complement:
        valueButton === 2
          ? addressInfo?.address.complement
          : addressData?.complement,
    })

    if (
      valueButton === 2
        ? addressInfo?.address.location?.x !== undefined &&
          addressInfo?.address.location?.y !== undefined
        : addressData?.location?.x !== undefined &&
          addressData?.location?.y !== undefined
    ) {
      setLatitude(
        valueButton === 2
          ? addressInfo?.address.location?.x
          : addressData?.location?.x,
      )

      form.setFieldsValue({
        latitude:
          valueButton === 2
            ? addressInfo?.address.location.x
            : addressData?.location.x,
        longitude:
          valueButton === 2
            ? addressInfo?.address.location.y
            : addressData?.location.y,
      })

      setIsCreateTaskOnClient(true)

      setStatus('success')
      setMapValidate(true)
    }
  }, [form, addressData, addressInfo])

  const stateOptions = [
    { label: 'Acre', value: 'AC' },
    { label: 'Alagoas', value: 'AL' },
    { label: 'Amapá', value: 'AP' },
    { label: 'Amazonas', value: 'AM' },
    { label: 'Bahia', value: 'BA' },
    { label: 'Ceará', value: 'CE' },
    { label: 'Espírito Santo', value: 'ES' },
    { label: 'Goiás', value: 'GO' },
    { label: 'Maranhão', value: 'MA' },
    { label: 'Mato Grosso', value: 'MT' },
    { label: 'Mato Grosso do Sul', value: 'MS' },
    { label: 'Minas Gerais', value: 'MG' },
    { label: 'Pará', value: 'PA' },
    { label: 'Paraíba', value: 'PB' },
    { label: 'Paraná', value: 'PR' },
    { label: 'Pernambuco', value: 'PE' },
    { label: 'Piauí', value: 'PI' },
    { label: 'Rio de Janeiro', value: 'RJ' },
    { label: 'Rio Grande do Norte', value: 'RN' },
    { label: 'Rio Grande do Sul', value: 'RS' },
    { label: 'Rondônia', value: 'RO' },
    { label: 'Roraima', value: 'RR' },
    { label: 'Santa Catarina', value: 'SC' },
    { label: 'São Paulo', value: 'SP' },
    { label: 'Sergipe', value: 'SE' },
    { label: 'Tocantins', value: 'TO' },
    { label: 'Distrito Federal', value: 'DF' },
  ]

  const searchCEP = useCallback(
    async (address: string) => {
      try {
        setStatus('validating')
        const cepInfo = await cep(address)

        form.setFieldsValue({
          state: cepInfo.state,
          city: cepInfo.city,
          neighborhood: cepInfo.neighborhood,
          street: cepInfo.street,
        })

        setState(cepInfo.state)
        setCity(cepInfo.city)
        setStreet(cepInfo.street)

        setStatus('success')
      } catch (error) {
        message.error(translate('TasksCreateAddress.zipcodeSearchErrorMessage'))
        form.resetFields()
        setState('')
        setCity('')
        setStreet('')
        setStatus('error')
      }
    },
    [form],
  )

  useEffect(() => {
    const teste = async () => {
      if (status === 'success') {
        try {
          const params = {
            street: valueButton === 2 ? addressInfo?.address.street : street,
            city: valueButton === 2 ? addressInfo?.address.city : city,
            state: valueButton === 2 ? addressInfo?.address.state : state,
            format: 'json',
          }

          const response = await geolocationApi.get('search', { params })

          if (response.data.length > 0) {
            setHidden(true)
            form.setFieldsValue({
              latitude: response.data[0].lat,
              longitude: response.data[0].lon,
            })

            setLatitude(response.data[0].lat)
            setLongitude(response.data[0].lon)

            setZoomLevel(13)
          } else {
            setHidden(false)

            form.setFieldsValue({
              latitude: '',
              longitude: '',
            })

            setZoomLevel(3)

            message.warning(
              translate('TasksCreateAddress.geolocationWarningMessage'),
              10,
            )
          }

          setMapValidate(true)
        } catch (error) {
          message.error(translate('TasksCreateAddress.geolocationErrorMessage'))
          setMapValidate(true)
        }
      }
    }

    teste()
  }, [city, state, street, form, status])

  function ChangeView({ center, zoom }: MapUpdateFunctionProps) {
    const map = useMap()
    map.setView(center, zoom)
    return null
  }

  function SaveAddressTaskOnClient() {
    const address = {
      zip_code: form.getFieldValue('zip_code').replace(/[^0-9]/g, ''),
      street: form.getFieldValue('street'),
      number: form.getFieldValue('number'),
      complement: form.getFieldValue('complement'),
      neighborhood: form.getFieldValue('neighborhood'),
      city: form.getFieldValue('city'),
      state: form.getFieldValue('state'),
      location: `(${form.getFieldValue('latitude')}, ${form.getFieldValue(
        'longitude',
      )})`,
    }

    createAddressTaskOnClient(address, clientData?.contactId)
  }

  return (
    <>
      <Row ref={taskAddressOptions}>
        <Radio.Group value={valueButton} onChange={onChange}>
          <Radio value={1}>
            {translate('TasksCreateAddress.manualAddress')}
          </Radio>
          <Radio value={2}>
            {translate('TasksCreateAddress.clientAddress')}
          </Radio>
        </Radio.Group>
      </Row>
      <Divider />

      {valueButton === 2 ? (
        <Row gutter={14} style={{ marginTop: 20 }}>
          <Col span={12}>
            <Form.Item
              label={translate('TasksCreateAddress.selectAddressLabel')}
            >
              <Select
                placeholder={translate(
                  'TasksCreateAddress.selectAddressPlaceholder',
                )}
                onChange={handleAddress}
              >
                {addressClient?.contact.contactAddresses?.map((address) => (
                  <Fragment key={address.id}>
                    {address.type === 'business' ? (
                      <Option key={address.id} value={address.id}>
                        {translate('TasksCreateAddress.businessAddressText')}
                      </Option>
                    ) : (
                      ''
                    )}

                    {address.type === 'personal' ? (
                      <Option key={address.id} value={address.id}>
                        {translate('TasksCreateAddress.personalAddressText')}
                      </Option>
                    ) : (
                      ''
                    )}

                    {address.type === 'billing' ? (
                      <Option key={address.id} value={address.id}>
                        {translate('TasksCreateAddress.billingAddressText')}
                      </Option>
                    ) : (
                      ''
                    )}
                  </Fragment>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      ) : (
        ''
      )}

      <Row gutter={14}>
        <Col span={4} ref={createCEPTaskRef}>
          <Form.Item
            label={translate('TasksCreateAddress.zipcodeLabel')}
            name="zip_code"
            hasFeedback
            validateStatus={status}
          >
            <Input
              placeholder="00000-000"
              onChange={(e) => {
                const cepInput = e.target.value.replace(/\D/g, '')
                if (cepInput.length === 8) {
                  searchCEP(e.target.value)
                }
              }}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <a
            href="https://buscacepinter.correios.com.br/app/endereco/index.php"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Form.Item
              label={translate('TasksCreateAddress.zipcodeButtonLabel')}
            >
              <Button key="submit" type="primary">
                {translate('TasksCreateAddress.zipcodeButton')}
              </Button>
            </Form.Item>
          </a>
        </Col>
        <Col span={8}>
          <Form.Item
            label={translate('TasksCreateAddress.stateLabel')}
            name="state"
          >
            <Select
              style={{ width: '100%' }}
              placeholder={translate('TasksCreateAddress.statePlaceholder')}
              onChange={(e) => setState(String(e))}
              className="menu_input"
              options={stateOptions}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={translate('TasksCreateAddress.cityLabel')}
            name="city"
          >
            <Input
              placeholder={translate('TasksCreateAddress.cityPlaceholder')}
              onChange={(e) => setCity(e.target.value)}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={14}>
        <Col span={8}>
          <Form.Item
            label={translate('TasksCreateAddress.neighborhoodLabel')}
            name="neighborhood"
          >
            <Input
              placeholder={translate(
                'TasksCreateAddress.neighborhoodPlaceholder',
              )}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={translate('TasksCreateAddress.streetLabel')}
            name="street"
          >
            <Input
              placeholder={translate('TasksCreateAddress.streetPlaceholder')}
              onChange={(e) => setStreet(e.target.value)}
            />
          </Form.Item>
        </Col>
        <Col span={8} ref={createNumberTaskRef}>
          <Form.Item
            label={translate('TasksCreateAddress.numberLabel')}
            name="number"
            rules={[
              {
                required: true,
                message: translate('TasksCreateAddress.numberRule'),
              },
            ]}
          >
            <Input
              onChange={(e) => setNumber(e.target.value)}
              placeholder={translate('TasksCreateAddress.numberPlaceholder')}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={14}>
        <Col span={4.8}>
          <Form.Item
            label={translate('TasksCreateAddress.complementLabel')}
            name="complement"
          >
            <Input
              placeholder={translate(
                'TasksCreateAddress.complementPlaceholder',
              )}
            />
          </Form.Item>
        </Col>

        {mapValidate && valueButton !== 2 ? (
          <Col span={4.8}>
            <Form.Item label="Endereço do cliente">
              <Button onClick={SaveAddressTaskOnClient}>
                Salvar endereço no cliente
              </Button>
            </Form.Item>
          </Col>
        ) : (
          ''
        )}

        <Col span={4.8}>
          <Form.Item
            hidden={hidden}
            label={translate('TasksCreateAddress.latitudeLabel')}
            name="latitude"
            initialValue={latitude}
            rules={[
              {
                required: !hidden,
                message: translate('TasksCreateAddress.latitudeRule'),
              },
            ]}
          >
            <Input onChange={(e) => formatComma(e.target.value, 'latitude')} />
          </Form.Item>
        </Col>
        <Col span={4.8}>
          <Form.Item
            hidden={hidden}
            label={translate('TasksCreateAddress.longitudeLabel')}
            name="longitude"
            initialValue={longitude}
            rules={[
              {
                required: !hidden,
                message: translate('TasksCreateAddress.longitudeRule'),
              },
            ]}
          >
            <Input onChange={(e) => formatComma(e.target.value, 'longitude')} />
          </Form.Item>
        </Col>
        <Col span={4.8}>
          <a
            href="https://www.latlong.net/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Form.Item
              label={translate('TasksCreateAddress.coordinateLabel')}
              hidden={hidden}
            >
              <Button type="primary">
                {translate('TasksCreateAddress.coordinateButton')}
              </Button>
            </Form.Item>
          </a>
        </Col>
      </Row>

      <MapContainer
        center={[latitude, longitude]}
        zoom={zoomLevel}
        scrollWheelZoom={false}
        style={{ height: '350px', width: '100%' }}
      >
        <ChangeView center={[latitude, longitude]} zoom={zoomLevel} />
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {latitude !== -14.235004 && longitude !== -51.925282 && (
          <Marker position={[latitude, longitude]} />
        )}
      </MapContainer>

      <Tour
        open={openAddressTask}
        onClose={onClose}
        steps={createAddressSteps}
        indicatorsRender={(current, total) => (
          <span>
            {current + 1} / {total}
          </span>
        )}
      />
    </>
  )
}

export default AddressCreateTask
