export default {
  pt: {
    EquipmentsTabsAttachmentsUpdate: {
      editTooltip: 'Editar',
      modalTitle: 'Editar Documento/Anexo',
      saveText: 'Salvar',
      titleLabel: 'Título',
      titleRule: 'Por favor insira um titulo para o documento/anexo.',
      archiveTypeLabel: 'Tipo do arquivo',
      archiveTypeRule: 'Por favor insira o tipo do documento/anexo.',
      descriptionLabel: 'Descrição',
      descriptionRule: 'Por favor insira uma descrição para o documento/anexo.',
    },
  },
  en: {
    EquipmentsTabsAttachmentsUpdate: {
      editTooltip: 'Edit',
      modalTitle: 'Edit Document/Attachment',
      saveText: 'Save',
      titleLabel: 'Title',
      titleRule: 'Please enter a title for the document/attachment.',
      archiveTypeLabel: 'File type',
      archiveTypeRule: 'Please enter the document/attachment type.',
      descriptionLabel: 'Description',
      descriptionRule:
        'Please enter a description for the document/attachment.',
    },
  },
  es: {
    EquipmentsTabsAttachmentsUpdate: {
      editTooltip: 'Editar',
      modalTitle: 'Editar documento/adjunto',
      saveText: 'Guardar',
      titleLabel: 'Título',
      titleRule: 'Introduzca un título para el documento/archivo adjunto.',
      archiveTypeLabel: 'Tipo de archivo',
      archiveTypeRule: 'Ingrese el tipo de documento/archivo adjunto.',
      descriptionLabel: 'Descripción',
      descriptionRule:
        'Ingrese una descripción para el documento/archivo adjunto.',
    },
  },
}
