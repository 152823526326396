export default {
  pt: {
    Kilometers: {
      helmetTitle: 'Relatório de KM rodados',
    },
  },
  en: {
    Kilometers: {
      helmetTitle: 'KM Run Report',
    },
  },
  es: {
    Kilometers: {
      helmetTitle: 'Informe de carrera de KM',
    },
  },
}
