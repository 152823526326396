export default {
  pt: {
    ReportTasksModal: {
      menuDetails: 'Detalhes da Tarefa',
      menuMap: 'Ver no Mapa',
      dateString: 'às',
      modalTitle: 'Detalhes da Tarefa',
      closeButton: 'Fechar',
      viewButton: 'Ver tarefa',
      taskDetailTab: 'Detalhes da Tarefa',
      executerLabel: 'Executador da Tarefa:',
      toLabel: 'Para:',
      initialTimeLabel: 'Data da tarefa: ',
      durationTimeLabel: 'Duração: ',
      checkinType: 'Tipo de Checkin: ',
      checkinTypeManual: 'Manual',
      priorityLabel: 'Prioridade: ',
      priorityLow: 'Baixa',
      priorityMedium: 'Média',
      priorityHigh: 'Alta',
      taskType: 'Tipo de Tarefa: ',
      externalCode: 'Código Externo',
      statusLabel: 'Status: ',
      statusActive: 'Inativa',
      statusInactive: 'Ativa',
      addressTab: 'Endereço',
      zipcodeLabel: 'CEP:',
      addressLabel: 'Endereço:',
      cityLabel: 'Cidade:',
      statusModalTitle: 'Status da Tarefa',
      statusViewed: 'Status - Visualizado',
      statusEnRoute: 'Status - A Caminho',
      statusCheckIn: 'Status - Tarefa sendo realizada',
      statusPauseTask: 'Status - Tarefa Pausada',
      statusResumeWorking: 'Status - Retomar Trabalho',
      statusCheckout: 'Status - Tarefa Finalizada',
      statusReceived: 'Status - Recebido',
      accompanimentLabel: 'Acompanhamento',
    },
  },
  en: {
    ReportTasksModal: {
      menuDetails: 'Task Details',
      menuMap: 'View on Map',
      dateString: 'at',
      modalTitle: 'Task Details',
      closeButton: 'Close',
      viewButton: 'View Task',
      taskDetailTab: 'Task Details',
      executerLabel: 'Task Executor:',
      toLabel: 'To:',
      initialTimeLabel: 'Task date: ',
      durationTimeLabel: 'Duration: ',
      checkinType: 'Checkin Type: ',
      checkinTypeManual: 'Manual',
      priorityLabel: 'Priority: ',
      priorityLow: 'Low',
      priorityMedium: 'Medium',
      priorityHigh: 'High',
      taskType: 'Task Type: ',
      externalCode: 'External Code',
      statusLabel: 'Status: ',
      statusActive: 'Inactive',
      statusInactive: 'Active',
      addressTab: 'Address',
      zipcodeLabel: 'Zipcode:',
      addressLabel: 'Address:',
      cityLabel: 'City:',
      statusModalTitle: 'Task Status',
      statusViewed: 'Status - Viewed',
      statusEnRoute: 'Status - On the way',
      statusCheckIn: 'Status - Task being performed',
      statusPauseTask: 'Status - Task Paused',
      statusResumeWorking: 'Status - Resume Work',
      statusCheckout: 'Status - Task Completed',
      statusReceived: 'Status - Received',
      accompanimentLabel: 'Accompaniment',
    },
  },
  es: {
    ReportTasksModal: {
      menuDetails: 'Detalles de la tarea',
      menuMap: 'Ver en el mapa',
      dateString: 'a las',
      modalTitle: 'Detalles de la tarea',
      closeButton: 'Cerrar',
      viewButton: 'Ver tarea',
      taskDetailTab: 'Detalles de la tarea',
      executerLabel: 'Ejecutor de tareas:',
      toLabel: 'Para:',
      initialTimeLabel: 'Fecha de la tarea: ',
      durationTimeLabel: 'Duración: ',
      checkinType: 'Tipo de Registro: ',
      checkinTypeManual: 'Manual',
      priorityLabel: 'Prioridad: ',
      priorityLow: 'Baja',
      priorityMedium: 'Media',
      priorityHigh: 'Alta',
      taskType: 'Tipo de tarea: ',
      externalCode: 'Código externo',
      statusLabel: 'Estado: ',
      statusActive: 'Inactivo',
      statusInactive: 'Activo',
      addressTab: 'Dirección',
      zipcodeLabel: 'Código postal:',
      addressLabel: 'Dirección:',
      cityLabel: 'Ciudad:',
      statusModalTitle: 'Estado de la tarea',
      statusViewed: 'Estado - Visto',
      statusEnRoute: 'Estado - En camino',
      statusCheckIn: 'Estado - Tarea que se está realizando',
      statusPauseTask: 'Estado - Tarea en pausa',
      statusResumeWorking: 'Estado - Reanudar el trabajo',
      statusCheckout: 'Estado - Tarea completada',
      statusReceived: 'Estado - Recibido',
      accompanimentLabel: 'Seguimiento',
    },
  },
}
