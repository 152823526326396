export default {
  pt: {
    MiscAddToScaleButton: {
      clientSuccessMessage: 'Cliente atribuido com sucesso na escala.',
      contributorSuccessMessage: 'Colaborador atribuido com sucesso na escala.',
      teamSuccessMessage: 'Equipe atribuida com sucesso a escala.',
      contributorErrorMessage: 'Erro ao atribuir colaborador na escala',
      teamErrorMessage: 'Erro ao atribuir equipe a escala',
      teamPopconfirm: 'Deseja mesmo atribuir a equipe a esta escala?',
      popconfirmAddText: 'Deseja mesmo atribuir o',
      popconfirmClientText: 'cliente',
      popconfirmContributorText: 'colaborador',
      popconfirmScaleText: 'a esta escala?',
      teamTooltip: 'Adicionar Equipe a escala',
      tooltipAddText: 'Adicionar',
      tooltipClientText: 'Cliente',
      tooltipContributorText: 'Colaborador',
      tooltipScaleText: 'na escala',
    },
  },
  en: {
    MiscAddToScaleButton: {
      clientSuccessMessage: 'Customer successfully assigned on the scale.',
      contributorSuccessMessage:
        'Contributor successfully assigned to the scale.',
      teamSuccessMessage: 'Team successfully assigned the scale.',
      contributorErrorMessage: 'Error assigning collaborator on scale',
      teamErrorMessage: 'Error assigning team to scale',
      teamPopconfirm: 'Do you really want to assign the team to this scale?',
      popconfirmAddText: 'Do you really want to assign the',
      popconfirmClientText: 'customer',
      popconfirmContributorText: 'contributor',
      popconfirmScaleText: 'at this scale?',
      teamTooltip: 'Add Team to scale',
      tooltipAddText: 'Add',
      tooltipClientText: 'Customer',
      tooltipContributorText: 'Contributor',
      tooltipScaleText: 'on the scale',
    },
  },
  es: {
    MiscAddToScaleButton: {
      clientSuccessMessage: 'Cliente asignado con éxito a la escala.',
      contributorSuccessMessage: 'Colaborador asignado con éxito a la escala.',
      teamSuccessMessage: 'El equipo asignó correctamente la escala.',
      contributorErrorMessage: 'Error al asignar colaborador en escala',
      teamErrorMessage: 'Error al asignar equipo a escala',
      teamPopconfirm: '¿De verdad quieres asignar el equipo a esta escala?',
      popconfirmAddText: '¿Realmente desea asignar el',
      popconfirmClientText: 'cliente',
      popconfirmContributorText: 'colaborador',
      popconfirmScaleText: 'a esta escala?',
      teamTooltip: 'Agregar equipo a escala',
      tooltipAddText: 'Agregar',
      tooltipClientText: 'Cliente',
      tooltipContributorText: 'Colaborador',
      tooltipScaleText: 'en la escala',
    },
  },
}
