import { Alert, Button, Card, Divider, Form, Select, Space } from 'antd'
import { FormInstance } from 'antd/lib'
import React from 'react'
import { Link } from 'react-router-dom'
import { useTaskForms } from '~/hooks/Tasks/Forms/useTaskForms'

interface FormsCreateTaskProps {
  form: FormInstance
}

const FormsCreateTask: React.FC<FormsCreateTaskProps> = ({ form }) => {
  const { forms, isLoading, limit, changeLimit, formsTotal } = useTaskForms()

  if (!forms || isLoading) return <Card loading />

  if (!formsTotal || formsTotal === 0) {
    return (
      <>
        <Alert
          message="Sem formulários criados. Clique no botão para criar um formulário."
          type="info"
        />
        <div style={{ marginTop: '1rem' }}>
          <Link to={`/formularios/novo`} target="_blank">
            <Button type="primary">Criar Formulário</Button>
          </Link>
        </div>
      </>
    )
  }

  const handleChange = (value: Array<string>) => {
    form.setFieldsValue({ forms: value })
  }

  return (
    <Form.Item name="forms" label={'Formulários'}>
      <Select
        mode="multiple"
        placeholder="Selecione um ou mais formulários"
        options={forms.map((form) => ({
          label: form.title,
          value: form.id,
        }))}
        onChange={handleChange}
        dropdownRender={(menu) => (
          <>
            {menu}
            {limit < formsTotal && (
              <>
                <Divider style={{ margin: '8px 0' }} />
                <Space style={{ padding: '0 8px 4px' }}>
                  <Button type="text" onClick={() => changeLimit(limit + 100)}>
                    Carregar mais
                  </Button>
                </Space>
              </>
            )}
          </>
        )}
      />
    </Form.Item>
  )
}

export default FormsCreateTask
