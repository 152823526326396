export default {
  pt: {
    ScalesContributorsAdd: {
      addButton: 'Adicionar Colaboradores',
      modalTitle: 'Adicionar Colaboradores',
      closeButton: 'Fechar',
    },
  },
  en: {
    ScalesContributorsAdd: {
      addButton: 'Add Contributors',
      modalTitle: 'Add Contributors',
      closeButton: 'Close',
    },
  },
  es: {
    ScalesContributorsAdd: {
      addButton: 'Agregar Colaboradores',
      modalTitle: 'Agregar Colaboradores',
      closeButton: 'Cerrar',
    },
  },
}
