import React from 'react'
import { Helmet } from 'react-helmet'
import TaskTypeBoard from '~/components/HelpDesk/TicketType'
import { TicketTypeProvider } from '~/hooks/HelpDesk/useTicketType'

import { translate } from '~/utils/locale'

const TicketType: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>{translate('TicketTypePage.helmetTitle')}</title>
      </Helmet>
      <TicketTypeProvider>
        <TaskTypeBoard />
      </TicketTypeProvider>
    </>
  )
}

export default TicketType
