export default {
  pt: {
    ClientsPrincipalSteps: {
      pageheaderTitle: 'Novo Cliente Principal',
      stepOne: 'Dados Cadastrais',
      stepOneDescription: 'Informe seus dados',
      stepTwo: 'Dados Financeiros',
      stepTwoDescription: 'Informe seus dados',
      stepThree: 'Endereço',
      stepThreeDescription: 'Informe os dados de endereço',
      saveButton: 'Salvar Cliente',
      nextButton: 'Próximo',
      backButton: 'Voltar',
    },
  },
  en: {
    ClientsPrincipalSteps: {
      pageheaderTitle: 'New Principal Customer',
      stepOne: 'Registration Data',
      stepOneDescription: 'Enter your data',
      stepTwo: 'Financial Data',
      stepTwoDescription: 'Enter your data',
      stepThree: 'Address',
      stepThreeDescription: 'Enter address details',
      saveButton: 'Save Client',
      nextButton: 'Next',
      backButton: 'Back',
    },
  },
  es: {
    ClientsPrincipalSteps: {
      pageheaderTitle: 'Nuevo Cliente Principal',
      stepOne: 'Datos de Registro',
      stepOneDescription: 'Introduce tus datos',
      stepTwo: 'Datos Financieros',
      stepTwoDescription: 'Introduce tus datos',
      stepThree: 'Dirección',
      stepThreeDescription: 'Ingrese los detalles de la dirección',
      saveButton: 'Guardar Cliente',
      nextButton: 'Próximo',
      backButton: 'Para Volver',
    },
  },
}
