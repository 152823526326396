/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable consistent-return */
import React, {
  useContext,
  createContext,
  useState,
  useCallback,
  useEffect,
} from 'react'
import { useQuery } from 'react-query'

import dayjs from 'dayjs'

import api from '~/services/api'

import { useCompanies } from '~/hooks/Companies/useCompanies'
import { useLocale } from '~/hooks/locale/useLocale'

import { ShowError } from '~/utils/errors/apiErrors'
import { translate } from '~/utils/locale'

interface GraphicProps {
  totalKmTraveled: number
  totalValueKmTraveled: number
}

interface StatusProps {
  type: string
  created_at: Date
  collaborator_id: string
  location: {
    lat: number
    long: number
  }
}

interface Responsible {
  id: string
  type: string
}

interface TasksKMProps {
  createdAt: string
  _id: string
  status: StatusProps
  kmTraveled: number
  valueKmTraveled: number
  internal_code: string
  external_code: string
  dateValue: string
  priority: string
  description: string
  responsible: Responsible
}

interface ResultsProps {
  date: string
  tasks: TasksKMProps[]
}

interface DataProps {
  graphic: GraphicProps
  results: ResultsProps[]
}

interface FilterOptionsProps {
  kmValue?: string
  responsibleId?: string
  teamResponsibleId?: string
  fromDate?: dayjs.Dayjs
  toDate?: dayjs.Dayjs
}

interface ReportKilometersContextData {
  reportKilometers?: DataProps
  isLoading: boolean
  isFetching: boolean
  refetch: () => void
  handleUpdateFilters: (newFilterOptions: any) => void
  handleResetFilters: () => void
  filterOptions: FilterOptionsProps
  setDataKm(value: boolean): void
  dataKm: boolean
  exportReportKilometers(): void
  urlDownload?: string
  loading: boolean
  text: string
  setSendFilter(value: boolean): void
  dataReport: ResultsProps[]
}

const ReportKilometersContext = createContext<ReportKilometersContextData>(
  {} as ReportKilometersContextData,
)

export const ReportKilometersProvider: React.FC = ({ children }) => {
  const { selectedCompany } = useCompanies()
  const { locale } = useLocale()
  const [urlDownload, setUrlDownload] = useState<string>()
  const [loading, setLoading] = useState(false)
  const [text, setText] = useState(translate('useReportKilometers.textExport'))

  const [sendFilter, setSendFilter] = useState(false)

  const [dataKm, setDataKm] = useState<boolean>(false)
  const [dataReport, setDataReport] = useState<ResultsProps[]>([])

  const [filterOptions, setFilterOptions] = useState<FilterOptionsProps>(() => {
    const filter = localStorage.getItem('@Gstor:reportKilometersFilterOptions')

    if (filter) {
      return JSON.parse(filter)
    }

    return {} as FilterOptionsProps
  })

  const {
    data: reportKilometers,
    isLoading,
    isFetching,
    refetch,
  } = useQuery(
    [
      `Relatório de km rodados${selectedCompany}`,
      selectedCompany,
      filterOptions,
      sendFilter,
    ],

    async () => {
      if (sendFilter) {
        try {
          const response = await api.get(
            `company/${selectedCompany}/reports/km`,
            {
              params: {
                responsibleId: filterOptions?.responsibleId,
                teamResponsibleId: filterOptions?.teamResponsibleId,
                fromDate: dayjs(filterOptions?.fromDate).format(),
                toDate: dayjs(filterOptions?.toDate).format(),
                kmValue: filterOptions?.kmValue,
              },
            },
          )

          const { data } = response

          setDataReport(data.results)

          return data
        } catch (err: any) {
          ShowError(
            err.message,
            translate('useKilometers.getErrorMessage'),
            locale,
          )
        }
      }
    },
  )

  async function exportReportKilometers() {
    try {
      setLoading(true)
      setText(translate('useReportKilometers.textLoading'))
      const response = await api.post(
        `company/${selectedCompany}/reports/km/export`,
        {},
        {
          params: {
            responsibleId: filterOptions?.responsibleId,
            teamResponsibleId: filterOptions?.teamResponsibleId,
            fromDate: dayjs.utc(filterOptions?.fromDate).format(),
            toDate: dayjs.utc(filterOptions?.toDate).format(),
            kmValue: filterOptions?.kmValue,
          },
        },
      )
      setUrlDownload(response.data.url)

      setText(translate('useReportKilometers.textDownload'))
      setLoading(false)
    } catch (err: any) {
      ShowError(
        err.message,
        translate('useReportKilometers.exportErrorMessage'),
        locale,
      )

      setLoading(false)
      setText(translate('useReportKilometers.textExport'))
    }
  }

  const handleUpdateFilters = useCallback((newFilterOptions) => {
    const newFilters = {
      responsibleId: newFilterOptions?.responsibleId,
      teamResponsibleId: newFilterOptions?.teamResponsibleId,
      fromDate: dayjs.utc(newFilterOptions?.fromDate).startOf('day'),
      toDate: dayjs.utc(newFilterOptions?.toDate).endOf('day'),
    }
    setFilterOptions(newFilters)
    localStorage.setItem(
      '@Gstor:reportKilometersFilterOptions',
      JSON.stringify(newFilters),
    )
    // setNumberPage(1);
  }, [])

  const handleResetFilters = useCallback(() => {
    localStorage.removeItem('@Gstor:reportKilometersFilterOptions')
    setFilterOptions({})
    // setNumberPage(1);
  }, [])

  useEffect(() => {
    refetch()
  }, [selectedCompany, refetch])

  return (
    <ReportKilometersContext.Provider
      value={{
        reportKilometers,
        isLoading,
        isFetching,
        refetch,
        filterOptions,
        handleUpdateFilters,
        handleResetFilters,
        setDataKm,
        dataKm,
        exportReportKilometers,
        urlDownload,
        loading,
        text,
        setSendFilter,
        dataReport,
      }}
    >
      {children}
    </ReportKilometersContext.Provider>
  )
}

export function useReportKilometers(): ReportKilometersContextData {
  const context = useContext(ReportKilometersContext)

  if (!context) {
    throw new Error('useAuth must be used within an AutoProvider')
  }

  return context
}
