export default {
  pt: {
    TasksRegistrationForms: {
      cardTitle: 'Formulários',
      totalText: 'Total de',
      formText: 'formulário',
      forms: 'formulários',
      addForm: 'Adicionar Formulário',
    },
  },
  en: {
    TasksRegistrationForms: {
      cardTitle: 'Forms',
      totalText: 'Total of',
      formText: 'form',
      forms: 'forms',
      addForm: 'Add Form',
    },
  },
  es: {
    TasksRegistrationForms: {
      cardTitle: 'Formas',
      totalText: 'Total de',
      formText: 'forma',
      forms: 'formas',
      addForm: 'Añadir Forma',
    },
  },
}
