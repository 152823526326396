import styled from 'styled-components'

export const Container = styled.div`
  .list {
    display: flex;
    flex-direction: column;
  }

  .list-row {
    display: flex;
    align-items: center;
    gap: 4rem;
    margin-top: 10px;
  }
`
