import styled from 'styled-components'

export const Container = styled.div`
  h1 {
    padding: 10px;
  }
`

export const LoadingIndicator = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
