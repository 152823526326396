export default {
  pt: {
    ScalesNewScaleModal: {
      errorMessage: 'Erro ao realizar cadastro da escala',
      createButton: 'Criar Escala',
      modalTitle: 'Nova Escala',
      okText: 'Criar',
      keepRegisterText: 'Continuar cadastrando escalas?',
      cancelButton: 'Cancelar',
      submitButton: 'Cadastrar',
      nameLabel: 'Nome da escala',
      nameRule: 'Por favor insira um nome para a escala',
      descriptionLabel: 'Descrição da escala',
    },
  },
  en: {
    ScalesNewScaleModal: {
      errorMessage: 'Error when registering the scale',
      createButton: 'Create Scale',
      modalTitle: 'New Scale',
      okText: 'Create',
      keepRegisterText: 'Continue registering scales?',
      cancelButton: 'Cancel',
      submitButton: 'Register',
      nameLabel: 'Scale name',
      nameRule: 'Please enter a name for the scale',
      descriptionLabel: 'Scale Description',
    },
  },
  es: {
    ScalesNewScaleModal: {
      errorMessage: 'Error al registrar la escala',
      createButton: 'Crear Escala',
      modalTitle: 'Nueva Escala',
      okText: 'Crear',
      keepRegisterText: '¿Continuar registrando escalas?',
      cancelButton: 'Cancelar',
      submitButton: 'Registrar',
      nameLabel: 'Nombre de escala',
      nameRule: 'Por favor, introduzca un nombre para la escala',
      descriptionLabel: 'Descripción de la escala',
    },
  },
}
