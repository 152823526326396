/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable consistent-return */
import dayjs from 'dayjs'
import React, { useContext, createContext, useEffect } from 'react'
import { useQuery } from 'react-query'
import { useCompanies } from '~/hooks/Companies/useCompanies'

import api from '~/services/api'

import { useTaskCreate } from './createTask'

interface AddressProps {
  city: string
  complement: string
  neighborhood: string
  id: string
  location: {
    x: number
    y: number
  }
  number: string
  state: string
  street: string
  zip_code: string
  type: string
}

interface TeamProps {
  collaborator_id: string
  id: string
  title: string
  amount_to_receive: number
}

interface TeamsSchedulesProps {
  id: string
  team: TeamProps
}

interface CustomerContactData {
  contactAddresses: any[]
  id: string
  name: string
}

interface CustomerProps {
  contact: CustomerContactData
  id: string
}

interface CustomersSchedulesProps {
  customer: CustomerProps
  id: string
}

interface ScheduleTimesProps {
  checkin: dayjs.Dayjs
  checkout: dayjs.Dayjs
  day: dayjs.Dayjs
  description: string
  id: string
  type: string
}

interface CompanyData {
  id: string
  name: string
}

interface CollaboratorProps {
  contact: CompanyData
  id: string
}

interface ContributorsSchedulesProps {
  collaborator: CollaboratorProps
  id: string
}

interface Scale {
  address: AddressProps
  contributorsSchedules: ContributorsSchedulesProps[]
  created_at: string
  id: string
  is_enabled: boolean
  operating_range: number
  teamsSchedules: TeamsSchedulesProps[]
  scheduleTimes: ScheduleTimesProps[]
  customersSchedules: CustomersSchedulesProps[]
}

interface ScaleTaskContextData {
  scaleData: Scale
  isLoading: boolean
  isFetching: boolean
  refetch: () => void
}

const ScaleTaskContext = createContext<ScaleTaskContextData>(
  {} as ScaleTaskContextData,
)

export const ScaleTaskProvider: React.FC = ({ children }) => {
  const { selectedCompany } = useCompanies()

  const { scaleId } = useTaskCreate()

  const {
    data: scaleData,
    isLoading,
    isFetching,
    refetch,
  } = useQuery(
    ['scale task', selectedCompany, scaleId?.id],
    async () => {
      try {
        if (!selectedCompany || !scaleId?.id) {
          return undefined
        }

        const response = await api.get(
          `/company/${selectedCompany}/schedule/${scaleId?.id}`,
        )

        const scale = response.data

        return scale
      } catch (err: any) {
        console.log('')
      }
    },
    {
      staleTime: 5000, // 5 segundos
    },
  )

  useEffect(() => {
    refetch()
  }, [selectedCompany, refetch])

  return (
    <ScaleTaskContext.Provider
      value={{ scaleData, isLoading, isFetching, refetch }}
    >
      {children}
    </ScaleTaskContext.Provider>
  )
}

export function useScaleTask(): ScaleTaskContextData {
  const context = useContext(ScaleTaskContext)

  if (!context) {
    throw new Error('useAuth must be used within an AutoProvider')
  }

  return context
}
