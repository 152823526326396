/* eslint-disable react/jsx-one-expression-per-line */
import { LoadingOutlined } from '@ant-design/icons'
import { Card, Space } from 'antd'
import Text from 'antd/lib/typography/Text'
import React from 'react'
import { usePause } from '~/hooks/Tasks/Pause/usePause'
import { translate } from '~/utils/locale'
import CreatePause from './Create'
import PauseTable from './Table'

const PauseBoard: React.FC = () => {
  const { pauseTotal, isLoading, isFetching } = usePause()

  return (
    <Card
      title={
        <Space size="large">
          <b>{translate('TasksRegistrationPause.cardTitle')}</b>
          {isLoading ? (
            <LoadingOutlined spin />
          ) : (
            <Text
              type="secondary"
              style={{ fontWeight: 'normal', fontSize: 14 }}
            >
              {pauseTotal !== undefined ? (
                <>
                  {pauseTotal === 1
                    ? `${translate(
                        'TasksRegistrationPause.totalText',
                      )} ${pauseTotal} ${translate(
                        'TasksRegistrationPause.breakReasonText',
                      )}`
                    : `${translate(
                        'TasksRegistrationPause.totalText',
                      )} ${pauseTotal} ${translate(
                        'TasksRegistrationPause.breakReasonsText',
                      )}`}
                </>
              ) : (
                ''
              )}
            </Text>
          )}
          {!isLoading && isFetching && <LoadingOutlined spin />}
        </Space>
      }
      extra={
        <Space size="small">
          <CreatePause />
        </Space>
      }
      bordered={false}
      style={{ width: '100%', padding: 30 }}
    >
      <PauseTable />
    </Card>
  )
}

export default PauseBoard
