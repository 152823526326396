import React, { useState } from 'react'
import { Button, Col, Form, Input, Modal, Row, Tooltip } from 'antd'
import { translate } from '~/utils/locale'
import { EditOutlined } from '@ant-design/icons'
import { TeamsFinancialProps } from '../Table'
import MoneyInput from '~/components/DataManipulation/Misc/MoneyInput'
import { useTeams } from '~/hooks/Teams/useListTeams'

// import { Container } from './styles';

interface UpdateProps {
  data: TeamsFinancialProps
}

interface FinancialTeam {
  amount_to_receive: number
  amount_hours_receive?: number
  amount_hours_additional_receive?: number
  number_km_receive?: number
  number_km_additional_receive?: number
}

const UpdateFinancialTeams: React.FC<UpdateProps> = ({ data }) => {
  const { updateFinancialTeams, refetch } = useTeams()

  const [amountReceive, setAmountReceive] = useState(data.amount_hours_receive)
  const [amountHours, setAmountHours] = useState(data.amount_hours_receive)
  const [amountHoursAdditional, setAmountHoursAdditional] = useState(
    data.amount_hours_additional_receive,
  )
  const [form] = Form.useForm()

  const [numberKm, setNumberKm] = useState(data.number_km_receive)
  const [numberKmAdditional, setNumberKmAdditional] = useState(
    data.number_km_additional_receive,
  )

  const initialData = {
    amount_to_receive: data.amount_to_receive,
    amount_hours_receive: data.amount_hours_receive,
    amount_hours_additional_receive: data.amount_hours_additional_receive,
    number_km_receive: data.number_km_receive,
    number_km_additional_receive: data.number_km_additional_receive,
  }

  const handleAmountReceive = (valueInCents: number | undefined) => {
    setAmountReceive(valueInCents)
  }

  const handleHourValue = (valueInCents: number | undefined) => {
    setAmountHoursAdditional(valueInCents)
  }

  const handleKmValue = (valueInCents: number | undefined) => {
    setNumberKmAdditional(valueInCents)
  }

  async function updateFinancialTeam(valueForm: FinancialTeam) {
    try {
      await updateFinancialTeams(valueForm, data.id)

      refetch()

      setIsModalVisible(false)
    } catch (err) {
      console.log(err)
    }
  }

  const [isModalVisible, setIsModalVisible] = useState(false)

  const showModal = () => {
    setIsModalVisible(true)
  }

  const closeModal = () => {
    setIsModalVisible(false)
  }

  return (
    <>
      <Tooltip title={translate('FinancialComponent.editTeamButton')}>
        <Button
          onClick={showModal}
          ghost
          shape="circle"
          type="primary"
          icon={<EditOutlined />}
        />
      </Tooltip>
      <Modal
        title={translate('FinancialComponent.editTeamButton')}
        open={isModalVisible}
        onOk={closeModal}
        onCancel={closeModal}
        width="700px"
        footer={[
          <Button key="back" onClick={closeModal}>
            {translate('TasksRegistrationPauseUpdate.closeButton')}
          </Button>,
          <Button
            type="primary"
            key="update"
            htmlType="submit"
            form="updateFinancialPageTeam"
          >
            {translate('TasksRegistrationPauseUpdate.saveButton')}
          </Button>,
        ]}
      >
        <Form
          layout="vertical"
          onFinish={updateFinancialTeam}
          initialValues={initialData}
          id="updateFinancialPageTeam"
          form={form}
        >
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item
                label={translate('FinancialComponent.valueReceive')}
                name="amount_to_receive"
              >
                <MoneyInput
                  value={amountReceive}
                  onChange={handleAmountReceive}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={14}>
            <Col span={12}>
              <Form.Item
                label={translate('ClientsPrincipalFinancialUpdate.hoursLabel')}
                name="amount_hours_receive"
              >
                <Input
                  value={amountHours}
                  onChange={(e) => setAmountHours(parseInt(e.target.value))}
                  style={{ width: '100%' }}
                  maxLength={2}
                  placeholder={translate(
                    'ClientsPrincipalFinancialUpdate.hoursPlaceholder',
                  )}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={translate(
                  'ClientsPrincipalFinancialUpdate.valueHoursLabel',
                )}
                name="amount_hours_additional_receive"
              >
                <MoneyInput
                  value={amountHoursAdditional}
                  onChange={handleHourValue}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={14}>
            <Col span={12}>
              <Form.Item
                label={translate('ClientsPrincipalFinancialUpdate.kmLabel')}
                name="number_km_receive"
              >
                <Input
                  placeholder={translate(
                    'ClientsPrincipalFinancialUpdate.kmPlaceholder',
                  )}
                  value={numberKm}
                  onChange={(e) => setNumberKm(parseInt(e.target.value))}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={translate(
                  'ClientsPrincipalFinancialUpdate.valueKmLabel',
                )}
                name="number_km_additional_receive"
              >
                <MoneyInput
                  value={numberKmAdditional}
                  onChange={handleKmValue}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  )
}

export default UpdateFinancialTeams
