export default {
  pt: {
    useListUsers: {
      getErrorMessage: 'Erro ao visualizar usuários.',
      createSuccessMessage: 'Usuário criado com sucesso!',
      createErrorMessage: 'Erro ao criar usuário.',
      deleteSuccessMessage: 'Usuário deletado com sucesso!',
      deleteErrorMessage: 'Erro ao excluir usuário.',
    },
  },
  en: {
    useListUsers: {
      getErrorMessage: 'Error viewing users.',
      createSuccessMessage: 'User created successfully!',
      createErrorMessage: 'Error creating user.',
      deleteSuccessMessage: 'User successfully deleted!',
      deleteErrorMessage: 'Error deleting user.',
    },
  },
  es: {
    useListUsers: {
      getErrorMessage: 'Error al ver los usuarios.',
      createSuccessMessage: '¡Usuario creado con éxito!',
      createErrorMessage: 'Error al crear usuario.',
      deleteSuccessMessage: '¡Usuario eliminado con éxito!',
      deleteErrorMessage: 'Error al eliminar usuario.',
    },
  },
}
