import React from 'react'

import FinancialCustomers from '~/components/DataManipulation/Financial/Customers'
import { ListParentClientsProvider } from '~/hooks/Clients/useListParentCustomer'

const FinancialCustomersPage: React.FC = () => {
  return (
    <ListParentClientsProvider>
      <FinancialCustomers />
    </ListParentClientsProvider>
  )
}

export default FinancialCustomersPage
