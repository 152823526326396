export default {
  pt: {
    TicketTypeUpdate: {
      editTooltip: 'Editar',
      modalTitle: 'Editar Tipo de Solicitação',
      closeButton: 'Fechar',
      saveButton: 'Salvar',
      titleLabel: 'Título',
      titleRule: 'Por favor informe o título do tipo de solicitação.',
      titlePlaceholder: 'Informe o título',
      descriptionLabel: 'Descrição',
      descriptionRule: 'Por favor informe a descrição do tipo de solicitação.',
    },
  },
  en: {
    TicketTypeUpdate: {
      editTooltip: 'Edit',
      modalTitle: 'Edit ticket type',
      closeButton: 'Close',
      saveButton: 'Save',
      titleLabel: 'Title',
      titleRule: 'Please enter the ticket type title.',
      titlePlaceholder: 'Inform the title',
      descriptionLabel: 'Description',
      descriptionRule: 'Please provide a description of the ticket type.',
    },
  },
  es: {
    TicketTypeUpdate: {
      editTooltip: 'Editar',
      modalTitle: 'Editar Tipo de Solicitudes',
      closeButton: 'Cerrar',
      saveButton: 'Guardar',
      titleLabel: 'Título',
      titleRule: 'Ingrese el título del tipo de solicitude.',
      titlePlaceholder: 'Informar el título',
      descriptionLabel: 'Descripción',
      descriptionRule: 'Proporcione una descripción del tipo de solicitude.',
    },
  },
}
