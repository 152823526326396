export default {
  pt: {
    useListTeams: {
      getErrorMessage: 'Erro ao visualizar equipes.',
      createSuccessMessage: 'Equipe criada com sucesso!',
      createErrorMessage: 'Erro ao criar equipes.',
      updateSuccessMessage: 'Equipe atualizada com sucesso!',
      updateErrorMessage: 'Erro ao atualizar equipes.',
      deleteSuccessMessage: 'Equipe deletada com sucesso!',
      deleteErrorMessage: 'Erro ao excluir equipes.',
    },
  },
  en: {
    useListTeams: {
      getErrorMessage: 'Error viewing teams.',
      createSuccessMessage: 'Team created successfully!',
      createErrorMessage: 'Error creating teams.',
      updateSuccessMessage: 'Team successfully updated!',
      updateErrorMessage: 'Error updating teams.',
      deleteSuccessMessage: 'Team successfully deleted!',
      deleteErrorMessage: 'Error deleting teams.',
    },
  },
  es: {
    useListTeams: {
      getErrorMessage: 'Error al ver equipos.',
      createSuccessMessage: '¡Equipo creado con éxito!',
      createErrorMessage: 'Error al crear equipos.',
      updateSuccessMessage: '¡Equipo actualizado con éxito!',
      updateErrorMessage: 'Error al actualizar equipos.',
      deleteSuccessMessage: '¡Equipo eliminado con éxito!',
      deleteErrorMessage: 'Error al eliminar equipos.',
    },
  },
}
