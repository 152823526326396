export default {
  pt: {
    useReportTasks: {
      getErrorMessage: 'Erro ao visualizar relatórios.',
      textExport: 'Exportar',
      textLoading: 'Gerando arquivo...',
      textDownload: 'Fazer Download',
      exportErrorMessage: 'Erro ao exportar relatórios.',
    },
  },
  en: {
    useReportTasks: {
      getErrorMessage: 'Error viewing reports.',
      textExport: 'Export',
      textLoading: 'Generating file...',
      textDownload: 'Download',
      exportErrorMessage: 'Error exporting reports.',
    },
  },
  es: {
    useReportTasks: {
      getErrorMessage: 'Error al ver los informes.',
      textExport: 'Exportar',
      textLoading: 'Generando archivo...',
      textDownload: 'Descargar',
      exportErrorMessage: 'Error al exportar informes.',
    },
  },
}
