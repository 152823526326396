export default {
  pt: {
    DashboardFilter: {
      cleanFilter: 'Limpar Filtro',
      filterAppliedText: 'Filtro Aplicado',
      filterText: 'Filtros',
      drawerTitle: 'Filtros',
      contributorInfo: 'Informações do colaborador:',
      contributorParagraphOne: 'Você pode filtrar buscando por',
      contributorParagraphTwo:
        'nome, email, telefone, documento, cnpj, rg, tipo de pessoa e gênero do colaborador',
      contributorPlaceholder: 'Informe as informações',
      addressLabel: 'Endereço:',
      addressSelectPlaceholder: 'Filtrar por: ',
      addressSelectState: 'Estado',
      addressSelectCity: 'Cidade',
      addressSelectZipcode: 'CEP',
      addressSelectStreet: 'Logradouro ou Bairro',
      stateLabel: 'Estado:',
      stateSelectPlaceholder: 'Selecione o estado',
      zipcodeLabel: 'CEP',
      zipcodeRule: 'Por favor insira o CEP',
      streetLabel: 'Nome da rua ou bairro:',
      streetPlaceholder: 'Informe o nome da rua ou bairro',
      cityLabel: 'Cidade:',
      citySelectPlaceholder: 'Selecione a cidade',
      statusLabel: 'Status',
      statusSelectPlaceholder: 'Selecione o status',
      evaluationLabel: 'Avaliação de Atendimento',
      internalEvaluationLabel: 'Avaliação Interna',
      applyButton: 'Aplicar',
      cleanButton: 'Limpar',
      taskFilterLabel: 'Filtros de Tarefas',
      contributorFilterLabel: 'Filtros de Colaboradores',
    },
  },
  en: {
    DashboardFilter: {
      cleanFilter: 'Clean Filter',
      filterAppliedText: 'Filter Applied',
      filterText: 'Filters',
      drawerTitle: 'Filters',
      contributorInfo: 'Contributor information:',
      contributorParagraphOne: 'You can filter by looking for',
      contributorParagraphTwo:
        'name, email, telephone, document, cnpj, rg, type of person and gender of the contributor',
      contributorPlaceholder: 'Inform the information',
      addressLabel: 'Address:',
      addressSelectPlaceholder: 'Filter by: ',
      addressSelectState: 'State',
      addressSelectCity: 'City',
      addressSelectZipcode: 'Zipcode',
      addressSelectStreet: 'Street or Neighborhood',
      stateLabel: 'State:',
      stateSelectPlaceholder: 'Select state',
      zipcodeLabel: 'Zipcode',
      zipcodeRule: 'Please enter the zip code',
      streetLabel: 'Street or neighborhood name:',
      streetPlaceholder: 'Enter the name of the street or neighborhood',
      cityLabel: 'City:',
      citySelectPlaceholder: 'Select city',
      statusLabel: 'Status',
      statusSelectPlaceholder: 'Select status',
      evaluationLabel: 'Service Rating',
      internalEvaluationLabel: 'Internal Rating',
      applyButton: 'Apply',
      cleanButton: 'Clean',
      taskFilterLabel: 'Tasks Filters',
      contributorFilterLabel: 'Contributors Filters',
    },
  },
  es: {
    DashboardFilter: {
      cleanFilter: 'Filtro Claro',
      filterAppliedText: 'Filtro Aplicado',
      filterText: 'Filtros',
      drawerTitle: 'Filtros',
      contributorInfo: 'Información del colaborador:',
      contributorParagraphOne: 'Puedes filtrar buscando',
      contributorParagraphTwo:
        'nombre, email, teléfono, documento, cnpj, rg, tipo de persona y género del aportante',
      contributorPlaceholder: 'informar la información',
      addressLabel: 'Dirección:',
      addressSelectPlaceholder: 'Filtrado por: ',
      addressSelectState: 'Estado',
      addressSelectCity: 'Ciudad',
      addressSelectZipcode: 'Código postal',
      addressSelectStreet: 'Calle o Barrio',
      stateLabel: 'Estado:',
      stateSelectPlaceholder: 'Seleccione estado',
      zipcodeLabel: 'Código postal',
      zipcodeRule: 'Por favor ingrese el código postal',
      streetLabel: 'Nombre de la calle o barrio:',
      streetPlaceholder: 'Introduce el nombre de la calle o barrio',
      cityLabel: 'Ciudad:',
      citySelectPlaceholder: 'Seleccione la ciudad',
      statusLabel: 'Status',
      statusSelectPlaceholder: 'Seleccionar status',
      evaluationLabel: 'Calificación del servicio',
      internalEvaluationLabel: 'Evaluación Interna',
      applyButton: 'Aplicar',
      cleanButton: 'Limpio',
      taskFilterLabel: 'Filtros de Tareas',
      contributorFilterLabel: 'Filtros de Colaboradores',
    },
  },
}
