/* eslint-disable no-underscore-dangle */
/* eslint-disable no-return-assign */
/* eslint-disable consistent-return */
/* eslint-disable react/jsx-curly-newline */
import React, { useEffect, useState } from 'react'

import { Card } from 'antd'

import dayjs from 'dayjs'
import { EventClickArg, EventContentArg } from '@fullcalendar/react'
import FCCalendar from '~/components/DataManipulation/FCCalendar'
import ScaleTimeAddModal from '~/components/DataManipulation/Scales/ScaleTimeAddModal'
import ScaleModal from '~/components/DataManipulation/Scales/ScaleCalendar/ScaleModal'

import { useScale } from '~/hooks/Scales/useScale'
import { translate } from '~/utils/locale'

interface iScaleSchedules {
  id: string
  title: string
  start: Date
  end: Date
  description: string
  type: string
}

interface IEvent {
  id: string
  title: string
  type: string
  description?: string
  group_id?: string
  day: string
  checkin: dayjs.Dayjs
  checkout: dayjs.Dayjs
  checkinFormatted: string
  checkoutFormatted: string
}

const ScaleCalendar: React.FC = () => {
  const [events, setEvents] = useState<iScaleSchedules[] | undefined>([])
  const { scaleData } = useScale()

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [event, setEvent] = useState({} as IEvent)

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleEventClassNames = (arg: EventContentArg) => {
    if (arg.event._def.extendedProps.type === 'work') {
      return 'fc-event-color-red'
    }
    return 'fc-event-color-green'
  }

  const handleEventClick = (calendarEvent: EventClickArg) => {
    setEvent({
      id: calendarEvent.event._def.publicId,
      title: calendarEvent.event._def.title,
      type: calendarEvent.event._def.extendedProps.type,
      description: calendarEvent.event._def.extendedProps.description,
      group_id: calendarEvent.event._def.extendedProps?.group_id,
      day: calendarEvent.event._def.extendedProps.day,
      checkin: dayjs(calendarEvent.event._instance?.range.start).add(
        3,
        'hours',
      ),
      checkout: dayjs(calendarEvent.event._instance?.range.end).add(3, 'hours'),
      checkinFormatted: dayjs(calendarEvent.event._instance?.range.start)
        .add(3, 'hours')
        .format('hh:mm a'),
      checkoutFormatted: dayjs(calendarEvent.event._instance?.range.end)
        .add(3, 'hours')
        .format('hh:mm a'),
    })
    showModal()
  }

  useEffect(() => {
    function getListData() {
      let scalesList: iScaleSchedules[] | undefined

      scaleData.scheduleTimes.forEach((time) => {
        const checkinTime = dayjs(time.checkin).format('hh:mm')
        const checkoutTime = dayjs(time.checkout).format('hh:mm')
        const day = dayjs(time.day).format('YYYY-MM-DD')
        const eventObj = {
          id: time.id,
          title:
            time.type === 'work'
              ? translate('ScalesCalendar.workString')
              : translate('ScalesCalendar.breakString'),
          start: new Date(`${day}T${checkinTime}:00-03:00`),
          end: new Date(`${day}T${checkoutTime}:00-03:00`),
          description: time.description,
          group_id: time.group_id,
          day: dayjs(time.day).format('LL'),
          type: time.type,
        }

        if (scalesList) {
          return (scalesList = [...scalesList, eventObj])
        }
        return (scalesList = [eventObj])
      })

      return scalesList
    }
    const eventsData = getListData()
    setEvents(eventsData)
  }, [scaleData.scheduleTimes])

  return (
    <Card
      bordered={false}
      title={<b>{translate('ScalesCalendar.cardTitle')}</b>}
      extra={<ScaleTimeAddModal />}
    >
      <FCCalendar
        events={events}
        Modal={
          <ScaleModal
            event={event}
            visible={isModalVisible}
            setVisible={setIsModalVisible}
          />
        }
        handleClassNames={handleEventClassNames}
        handleEventClick={handleEventClick}
      />
    </Card>
  )
}

export default ScaleCalendar
