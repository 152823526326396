/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, {
  ReactNode,
  useContext,
  createContext,
  useEffect,
  useState,
} from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'

import { message } from 'antd'

import api from '~/services/api'

import { useCompanies } from '~/hooks/Companies/useCompanies'
import { useLocale } from '~/hooks/locale/useLocale'

import { ShowError } from '~/utils/errors/apiErrors'
import { translate } from '~/utils/locale'

interface EvaluationsProviderProps {
  children: ReactNode
}

interface Evaluation {
  id: string
  star: number
  notes: string
  created_at: string
}

interface EvaluationsContextData {
  evaluationsRefetch: () => void
  deleteEvaluation(evaluationId: string): void
  evaluationsData: Evaluation[]
  isLoading: boolean
  isFetching: boolean
  deleteLoading: boolean
}

const EvaluationsContext = createContext<EvaluationsContextData>(
  {} as EvaluationsContextData,
)

export function EvaluationsProvider({ children }: EvaluationsProviderProps) {
  const [deleteLoading, setDeleteLoading] = useState(false)
  const { selectedCompany } = useCompanies()
  const { locale } = useLocale()
  const { contributorId } = useParams<{ contributorId: string }>()

  const { data, isLoading, isFetching, refetch } = useQuery(
    ['list-evaluations', selectedCompany, contributorId],
    async () => {
      try {
        if (!selectedCompany || !contributorId) {
          return undefined
        }

        const response = await api.get(
          `/company/${selectedCompany}/collaborator/${contributorId}/evaluations`,
        )
        const evaluations = response.data.results

        return evaluations
      } catch (err: any) {
        ShowError(
          err.message,
          translate('useEvaluations.getErrorMessage'),
          locale,
        )
      }
    },
    {
      staleTime: 5000, // 5 segundos
    },
  )

  async function deleteEvaluation(evaluationId: string) {
    try {
      setDeleteLoading(true)
      await api.delete(
        `/company/${selectedCompany}/collaborator/${contributorId}/evaluation/${evaluationId}`,
      )

      refetch()
      message.success(translate('useEvaluations.deleteSuccessMessage'))
      setDeleteLoading(false)
    } catch (err: any) {
      ShowError(
        err.message,
        translate('useEvaluations.deleteErrorMessage'),
        locale,
      )
      setDeleteLoading(false)
    }
  }

  useEffect(() => {
    refetch()
  }, [selectedCompany, contributorId, refetch])

  return (
    <EvaluationsContext.Provider
      value={{
        evaluationsData: data,
        isLoading,
        isFetching,
        evaluationsRefetch: refetch,
        deleteEvaluation,
        deleteLoading,
      }}
    >
      {children}
    </EvaluationsContext.Provider>
  )
}

export function useEvaluations() {
  const context = useContext(EvaluationsContext)

  return context
}
