/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable @typescript-eslint/no-explicit-any */

import React from 'react'
import { Helmet } from 'react-helmet'

import { CategoryProvider } from '~/hooks/Equipments/Category/CategoryHooks'

import { Container } from './styles'

import CreateEquipments from '~/components/DataManipulation/Equipments/Create'
import { ListEquipmentsProvider } from '~/hooks/Equipments/ListEquipmentsHooks'
import { translate } from '~/utils/locale'

const NewEquipments: React.FC = () => {
  return (
    <Container>
      <Helmet>
        <title>{translate('NewEquipments.helmetTitle')}</title>
      </Helmet>
      <CategoryProvider>
        <ListEquipmentsProvider>
          <CreateEquipments />
        </ListEquipmentsProvider>
      </CategoryProvider>
    </Container>
  )
}

export default NewEquipments
