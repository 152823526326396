export default {
  pt: {
    useListScales: {
      dateRangeOne: 'Registros entre',
      dateRangeTwo: 'Nenhum horário criado na escala',
      getErrorMessage: 'Erro ao visualizar escalas.',
      createSuccessMessage: 'Escala criada com sucesso!',
      createErrorMessage: 'Erro ao criar escalas.',
      deleteSuccessMessage: 'Escala removida com sucesso!',
      deleteErrorMessage: 'Erro ao excluir escalas.',
    },
  },
  en: {
    useListScales: {
      dateRangeOne: 'Records between',
      dateRangeTwo: 'No timetable created in the scale',
      getErrorMessage: 'Error viewing scales.',
      createSuccessMessage: 'Scale created successfully!',
      createErrorMessage: 'Error creating scales.',
      deleteSuccessMessage: 'Scale successfully removed!',
      deleteErrorMessage: 'Error deleting scales.',
    },
  },
  es: {
    useListScales: {
      dateRangeOne: 'Registros entre',
      dateRangeTwo: 'No hay horario creado en la escala',
      getErrorMessage: 'Error al visualizar escalas.',
      createSuccessMessage: '¡Escala creada con éxito!',
      createErrorMessage: 'Error al crear escalas.',
      deleteSuccessMessage: '¡Báscula eliminada con éxito!',
      deleteErrorMessage: 'Error al eliminar escalas.',
    },
  },
}
