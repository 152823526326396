export default {
  pt: {
    ScalesComponent: {
      cardTitle: 'Escalas',
      totalText: 'Total de',
      scaleText: 'escala',
      scalesText: 'escalas',
    },
  },
  en: {
    ScalesComponent: {
      cardTitle: 'Scales',
      totalText: 'Total of',
      scaleText: 'scale',
      scalesText: 'scales',
    },
  },
  es: {
    ScalesComponent: {
      cardTitle: 'Escalas',
      totalText: 'Total de',
      scaleText: 'escala',
      scalesText: 'escalas',
    },
  },
}
