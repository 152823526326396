import React from 'react'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'

interface IHelpTooltip {
  title: string
}

const HelpTooltip: React.FC<IHelpTooltip> = ({ title }) => {
  return (
    <Tooltip title={title}>
      <QuestionCircleOutlined style={{ color: '#00000073' }} />
    </Tooltip>
  )
}

export default HelpTooltip
