/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/require-default-props */
import React, { useState, ReactElement } from 'react'
import { useParams } from 'react-router-dom'

import { List, Space, Button, Tooltip, message, Tag } from 'antd'
import { DownloadOutlined } from '@ant-design/icons'

import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox'
import ReactPlayer from 'react-player'
import ReactAudioPlayer from 'react-audio-player'

import DeleteButton from '~/components/DataManipulation/Misc/DeleteButton'

import { useCompanies } from '~/hooks/Companies/useCompanies'
import { useContributor } from '~/hooks/Contributors/useContributor'
import { useLocale } from '~/hooks/locale/useLocale'

import { ShowError } from '~/utils/errors/apiErrors'

import api from '~/services/api'
import { translate } from '~/utils/locale'

interface AttachmentsListProps {
  isVisualization?: boolean
}

interface AttachmentTags {
  [key: string]: ReactElement
}

const AttachmentsList: React.FC<AttachmentsListProps> = ({
  isVisualization = false,
}) => {
  const [confirmLoading, setConfirmLoading] = useState(false)

  const { contributorId } = useParams<{ contributorId: string }>()

  const { selectedCompany } = useCompanies()
  const { locale } = useLocale()
  const { contributorRefetch, contributorAttachments } = useContributor()

  const ATTACHMENT_TAGS: AttachmentTags = {
    image: <Tag color="geekblue">{translate('AttachmentsList.imageTag')}</Tag>,
    video: <Tag color="geekblue">{translate('AttachmentsList.videoTag')}</Tag>,
    audio: <Tag color="geekblue">{translate('AttachmentsList.audioTag')}</Tag>,
    application: (
      <Tag color="geekblue">{translate('AttachmentsList.applicationTag')}</Tag>
    ),
    text: <Tag color="geekblue">{translate('AttachmentsList.textTag')}</Tag>,
  }

  const handleDelete = async (id: string) => {
    try {
      setConfirmLoading(true)
      await api.delete(
        `/company/${selectedCompany}/collaborator/${contributorId}/collaborator-attachment/${id}`,
      )
      setConfirmLoading(false)
      contributorRefetch()
      message.success(translate('AttachmentsList.successMessage'))
    } catch (err: any) {
      ShowError(err.message, translate('AttachmentsList.errorMessage'), locale)

      setConfirmLoading(false)
    }
  }

  return (
    <List
      itemLayout="vertical"
      size="large"
      dataSource={contributorAttachments.sort((a, b) =>
        a.title.localeCompare(b.title),
      )}
      renderItem={(item) => (
        <List.Item key={item.id}>
          <List.Item.Meta
            title={
              <Space size="large">
                <Space size="small">
                  <b>{item.title}</b>
                  {ATTACHMENT_TAGS[item.type]}
                </Space>
                <Space size="small">
                  <Tooltip title={translate('AttachmentsList.downloadTooltip')}>
                    <a
                      href={item.attachment_url}
                      target="_blank"
                      rel="noopener noreferrer"
                      download
                    >
                      <Button
                        ghost
                        shape="circle"
                        icon={<DownloadOutlined />}
                      />
                    </a>
                  </Tooltip>
                  {!isVisualization && (
                    <>
                      <DeleteButton
                        PopconfirmText={translate(
                          'AttachmentsList.deletePopconfirm',
                        )}
                        TooltipText={translate('AttachmentsList.deleteTooltip')}
                        handleDelete={handleDelete}
                        confirmLoading={confirmLoading}
                        itemId={item.id}
                      />
                    </>
                  )}
                </Space>
              </Space>
            }
            description={
              <Space direction="vertical">
                {item.description}
                <Space size="small">
                  {item.type === 'image' ? (
                    <Space direction="vertical">
                      <SimpleReactLightbox>
                        <SRLWrapper>
                          <div
                            style={{
                              border: '1px solid #cccccc',
                              padding: 5,
                              borderRadius: 3,
                            }}
                          >
                            <img
                              src={item.attachment_url}
                              alt="Imagem relato"
                              width="200px"
                              style={{ borderRadius: 3 }}
                            />
                          </div>
                        </SRLWrapper>
                      </SimpleReactLightbox>
                    </Space>
                  ) : (
                    ''
                  )}
                  {item.type === 'video' ? (
                    <Space direction="vertical">
                      <div
                        style={{
                          border: '1px solid #cccccc',
                          padding: 5,
                          borderRadius: 3,
                        }}
                      >
                        <ReactPlayer
                          controls
                          url={item.attachment_url}
                          width="200px"
                          height="100px"
                        />
                      </div>
                    </Space>
                  ) : (
                    ''
                  )}
                  {item.type === 'application' ? (
                    <b>{translate('AttachmentsList.typeApplication')}</b>
                  ) : (
                    ''
                  )}
                  {item.type === 'audio' ? (
                    <Space direction="vertical">
                      <ReactAudioPlayer src={item.attachment_url} controls />
                    </Space>
                  ) : (
                    ''
                  )}
                  {item.type === 'text' ? (
                    <b>{translate('AttachmentsList.typeText')}</b>
                  ) : (
                    ''
                  )}
                  {item.type === 'notes' ? (
                    <b>{translate('AttachmentsList.typeNotes')}</b>
                  ) : (
                    ''
                  )}
                </Space>
              </Space>
            }
          />
        </List.Item>
      )}
    />
  )
}

export default AttachmentsList
