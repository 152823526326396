export default {
  pt: {
    GroupsBoard: {
      cardTitle: 'Permissões',
      totalText: 'Total de',
      groupText: 'grupo',
      groupsText: 'grupos',
    },
  },
  en: {
    GroupsBoard: {
      cardTitle: 'Permissions',
      totalText: 'Total of',
      groupText: 'group',
      groupsText: 'groups',
    },
  },
  es: {
    GroupsBoard: {
      cardTitle: 'Permisos',
      totalText: 'Total de',
      groupText: 'grupo',
      groupsText: 'grupos',
    },
  },
}
