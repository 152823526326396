/* eslint-disable react/require-default-props */
import React from 'react'

import { Select } from 'antd'

import { Flag } from './styles'

import { useLocale } from '~/hooks/locale/useLocale'

import br from '~/assets/LocaleFlags/br.svg'
import es from '~/assets/LocaleFlags/es.svg'
import us from '~/assets/LocaleFlags/us.svg'

// import { Container } from './styles';

interface ILocaleSelector {
  style?: React.CSSProperties
}

const LocaleSelector: React.FC<ILocaleSelector> = ({ style }) => {
  const { changeLocale, locale } = useLocale()
  return (
    <Select onChange={changeLocale} value={locale} style={style}>
      <Select.Option value="pt-BR">
        <Flag src={br} alt="Brazil_Flag" />
      </Select.Option>
      <Select.Option value="es-ES">
        <Flag src={es} alt="Spain_Flag" />
      </Select.Option>
      <Select.Option value="en-US">
        <Flag src={us} alt="USA_Flag" />
      </Select.Option>
    </Select>
  )
}

export default LocaleSelector
