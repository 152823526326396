import { ArrowLeftOutlined } from '@ant-design/icons'
import { Card, Space, Tooltip } from 'antd'
import React from 'react'
import { useReportKilometers } from '~/hooks/Report/Kilometers/useReportKilometers'
import { translate } from '~/utils/locale'
import ReportDataKilometers from './Data'
import ExportReportKilometers from './Export'
import ReportFilterKilometer from './Filter'
import GraphicsReportKilometers from './Graphics'

// import { Container } from './styles';

const ReportKilometersComponents: React.FC = () => {
  const { dataKm, setDataKm } = useReportKilometers()

  return (
    <Card
      title={
        <Space size="large">
          {dataKm ? (
            <Tooltip title={translate('ReportKilometers.backTooltip')}>
              <ArrowLeftOutlined
                style={{ color: '#3C8ACB' }}
                onClick={() => setDataKm(false)}
              />
            </Tooltip>
          ) : (
            ''
          )}
          <b>{translate('ReportKilometers.cardTitle')}</b>
        </Space>
      }
      extra={<ExportReportKilometers />}
      bordered={false}
      style={{ width: '100%', padding: 30 }}
    >
      {dataKm ? (
        <>
          <GraphicsReportKilometers />
          <ReportDataKilometers />
        </>
      ) : (
        <ReportFilterKilometer />
      )}
    </Card>
  )
}

export default ReportKilometersComponents
