import styled from 'styled-components'

export const Wrapper = styled.div`
  max-height: 250px;
  overflow-y: auto;
`

export const Container = styled.div`
  margin: 0.25rem 0;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;

  .ant-badge-status-text {
    margin: 0;
  }
`

export const NotificationIcon = styled.div``
export const Title = styled.div`
  font-weight: bold;
`
export const Content = styled.div`
  font-size: 0.75rem;
`
