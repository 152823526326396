export default {
  pt: {
    useMovements: {
      getErrorMessage: 'Erro ao visualizar movimentações.',
      createSuccessMessage: 'Movimentação criada com sucesso!',
      createErrorMessage: 'Erro ao criar movimentações.',
    },
  },
  en: {
    useMovements: {
      getErrorMessage: 'Error viewing movements.',
      createSuccessMessage: 'Movement created successfully!',
      createErrorMessage: 'Error creating moves.',
    },
  },
  es: {
    useMovements: {
      getErrorMessage: 'Error al visualizar movimientos.',
      createSuccessMessage: '¡Movimiento creado con éxito!',
      createErrorMessage: 'Error al crear movimientos.',
    },
  },
}
