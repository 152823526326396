/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { Fragment } from 'react'

import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'
// import L from 'leaflet';
import { Rate, Collapse, Divider, Tooltip, Typography } from 'antd'
import NumberFormat from 'react-number-format'
import { Link } from 'react-router-dom'

import { useDashboard } from '~/hooks/Dashboard/useDashboard'

import { Container, PopupHeader, Content, Loading, Title } from './styles'
import { translate } from '~/utils/locale'

import { LoadingOutlined } from '@ant-design/icons'
import ContributorDashboardFilter from '../Filters/Contributors'

const ContributorsDashboardMap: React.FC = () => {
  const { contribuitors } = useDashboard()

  const enabledCompany = localStorage.getItem('@Gstor:enabledCompany')

  return (
    <>
      {enabledCompany === 'true' ? (
        <Container>
          <Title>
            <Typography.Title level={4}>Mapa de Colaboradores</Typography.Title>

            <ContributorDashboardFilter />
          </Title>
          {contribuitors ? (
            <MapContainer
              center={[-23.1895062, -45.8630127]}
              zoom={6}
              scrollWheelZoom={false}
              style={{
                height: '100vh',
                width: '100%',
                boxShadow: 'rgba(99, 99, 99, 0.5) 0px 2px 8px 0px',
                borderRadius: 5,
              }}
              id="t"
            >
              <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              {contribuitors?.map((c) => (
                <div key={c.id}>
                  {c.contact?.contactAddresses.map((a) => (
                    <Marker
                      position={[a.address.location.x, a.address.location.y]}
                      // icon={iconAgent}
                      key={a.id}
                    >
                      <Popup>
                        <PopupHeader>
                          <Tooltip
                            title={translate(
                              'DashboardMap.viewContributorTooltip',
                            )}
                          >
                            <Link
                              to={`/colaboradores/visualizar/colaborador/${c.id}/contato/${c.contact.id}`}
                            >
                              {c.contact.name}
                            </Link>
                          </Tooltip>
                        </PopupHeader>
                        <Content>
                          <p>
                            {translate('DashboardMap.nameLabel')}
                            &nbsp;
                            {c.contact.name}
                          </p>
                          <p>
                            {translate('DashboardMap.phoneLabel')}
                            &nbsp;
                            <NumberFormat
                              value={c.contact.phone}
                              displayType="text"
                              format="+## (##) #####-####"
                            />
                          </p>

                          <p>
                            {translate('DashboardMap.emailLabel')}
                            &nbsp;
                            {c.contact.email}
                          </p>

                          <Divider />
                          <div className="avaliations">
                            <div className="avaliation">
                              <p>{translate('DashboardMap.evaluationLabel')}</p>
                              <Rate disabled defaultValue={c.evaluation} />
                            </div>
                            <div className="avaliation">
                              <p>
                                {translate(
                                  'DashboardMap.internalEvaluationLabel',
                                )}
                              </p>
                              <Rate
                                disabled
                                defaultValue={c.internal_evaluation}
                              />
                            </div>
                          </div>
                          <Divider />
                          <Collapse ghost>
                            <Collapse.Panel
                              header={translate('DashboardMap.addressTitle')}
                              key="1"
                              style={{ fontSize: 12 }}
                            >
                              <div>
                                <p>
                                  {translate('DashboardMap.zipcodeLabel')}
                                  &nbsp;
                                  <NumberFormat
                                    value={a.address.zip_code}
                                    displayType="text"
                                    format="#####-###"
                                  />
                                </p>
                                <p>
                                  {translate('DashboardMap.streetLabel')}
                                  &nbsp;
                                  {a.address.street}
                                  ,&nbsp;
                                  {a.address.number}
                                  &nbsp; -&nbsp;
                                  {a.address.neighborhood}
                                </p>
                                <p>
                                  {translate('DashboardMap.cityLabel')}
                                  &nbsp;
                                  {a.address.city}
                                  ,&nbsp;
                                  {a.address.state}
                                </p>
                                {a.address.complement !== null ? (
                                  <p>
                                    {translate('DashboardMap.complementLabel')}
                                    &nbsp;
                                    {a.address.complement}
                                  </p>
                                ) : (
                                  ''
                                )}
                              </div>
                            </Collapse.Panel>
                          </Collapse>
                        </Content>
                      </Popup>
                    </Marker>
                  ))}
                </div>
              ))}
            </MapContainer>
          ) : (
            <Loading>
              <LoadingOutlined style={{ fontSize: 24 }} spin />
            </Loading>
          )}
        </Container>
      ) : (
        ''
      )}
    </>
  )
}

export default ContributorsDashboardMap
