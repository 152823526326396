export default {
  pt: {
    ScalesGeneralForm: {
      successMessage: 'Informações da Escala atualizadas com sucesso!',
      errorMessage: 'Falha ao atualizar informações da escala!',
      cardTitle: 'Informações gerais',
      saveButton: 'Salvar',
      titleLabel: 'Nome da escala',
      titleRule: 'É necessário um Nome para a escala',
      operatingRangeLabel: 'Área de atuação em KM',
      operatingRangeRule: 'É necessário um Nome para a escala',
      isEnabledLabel: 'Status da escala',
      isEnabledActive: 'Ativo',
      isEnabledInactive: 'Inativo',
      descriptionLabel: 'Descrição da escala',
      addressCardTitle: 'Endereço da escala',
      zipcodeLabel: 'CEP:',
      stateLabel: 'Estado:',
      cityLabel: 'Cidade:',
      neighborhoodLabel: 'Bairro:',
      streetLabel: 'Logradouro:',
      numberLabel: 'Número:',
      complementLabel: 'Complemento:',
      noAddressFound:
        'Nenhum endereço atribuido a escala. Por gentileza atribua um endereço abaixo.',
      possibleAddressesCardTitle: 'Possíveis endereços da escala',
    },
  },
  en: {
    ScalesGeneralForm: {
      successMessage: 'Scale information updated successfully!',
      errorMessage: 'Failed to update scale information!',
      cardTitle: 'General information',
      saveButton: 'Save',
      titleLabel: 'Scale name',
      titleRule: ' Name is required for the scale',
      operatingRangeLabel: 'Area of ​​operation in KM',
      operatingRangeRule: ' Name is required for the scale',
      isEnabledLabel: 'Scale status',
      isEnabledActive: 'Active',
      isEnabledInactive: 'Inactive',
      descriptionLabel: 'Scale description',
      addressCardTitle: 'Scale address',
      zipcodeLabel: 'Zipcode:',
      stateLabel: 'State:',
      cityLabel: 'City:',
      neighborhoodLabel: 'Neighborhood:',
      streetLabel: 'Street:',
      numberLabel: 'Number:',
      complementLabel: 'Complement:',
      noAddressFound:
        'No address assigned to scale. Please give an address below.',
      possibleAddressesCardTitle: 'Possible scale addresses',
    },
  },
  es: {
    ScalesGeneralForm: {
      successMessage: '¡La información de la escala se actualizó con éxito!',
      errorMessage: '¡Error al actualizar la información de la escala!',
      cardTitle: 'Informaciones generales',
      saveButton: 'Guardar',
      titleLabel: 'Nombre de escala',
      titleRule: 'Se requiere un nombre para la escala',
      operatingRangeLabel: 'Área de operación en KM',
      operatingRangeRule: 'Se requiere un nombre para la escala',
      isEnabledLabel: 'Estado de la escala',
      isEnabledActive: 'Activo',
      isEnabledInactive: 'Inactivo',
      descriptionLabel: 'Descripción de la escala',
      addressCardTitle: 'Dirección de escala',
      zipcodeLabel: 'Código Postal:',
      stateLabel: 'Estado:',
      cityLabel: 'Ciudad:',
      neighborhoodLabel: 'Barrio:',
      streetLabel: 'Calle:',
      numberLabel: 'Número:',
      complementLabel: 'Complemento:',
      noAddressFound:
        'Sin dirección asignada a la escala. Indique una dirección a continuación.',
      possibleAddressesCardTitle: 'Posibles direcciones de escala',
    },
  },
}
