/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, createContext, useState } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'

import { message } from 'antd'

import api from '~/services/api'

import { useCompanies } from '~/hooks/Companies/useCompanies'
import { useLocale } from '~/hooks/locale/useLocale'

import { ShowError } from '~/utils/errors/apiErrors'
import { translate } from '~/utils/locale'

interface SpecificationProps {
  attribute: string
  value: string
}

interface EquipmentsProps {
  id: string
  name: string
  created_at: Date
  expiration_date: Date
  identifier: number
  is_enabled: boolean
  description: string
  photo_url: string
  specifications: SpecificationProps[]
  associatedEquipment: {
    id: string
    equipment: {
      name: string
      id: string
    }
  }
  category: {
    id: string
    title: string
  }
}

interface EquipmentsContextData {
  equipments?: EquipmentsProps
  updateEquipment(dataEquipment: object | undefined): void
  setIsEdit(value: boolean | undefined): void
  isEdit: boolean | undefined
  removeEquipment: boolean
  setRemoveEquipment(value: boolean): void
  isLoading: boolean
  refetch: () => void
}

const EquipmentsContext = createContext<EquipmentsContextData>(
  {} as EquipmentsContextData,
)

export const EquipmentsProvider: React.FC = ({ children }) => {
  const { selectedCompany } = useCompanies()
  const { locale } = useLocale()
  const { equipmentId } = useParams<{ equipmentId: string }>()
  const [isEdit, setIsEdit] = useState<boolean>()

  const [removeEquipment, setRemoveEquipment] = useState(false)

  const {
    data: equipments,
    isLoading,
    refetch,
  } = useQuery(
    [`equipamentos${selectedCompany}`, selectedCompany],

    async () => {
      try {
        const response = await api.get(
          `/company/${selectedCompany}/equipment/${equipmentId}`,
        )

        const { data } = response

        return data
      } catch (err: any) {
        ShowError(
          err.message,
          translate('EquipmentsHooks.getErrorMessage'),
          locale,
        )
      }
    },
  )

  async function updateEquipment(dataEquipment: object) {
    try {
      await api.put(
        `/company/${selectedCompany}/equipment/${equipmentId}`,
        dataEquipment,
      )

      refetch()

      message.success(translate('EquipmentsHooks.updateSuccessMessage'))
    } catch (err: any) {
      ShowError(
        err.message,
        translate('EquipmentsHooks.updateErrorMessage'),
        locale,
      )
    }
  }

  return (
    <EquipmentsContext.Provider
      value={{
        equipments,
        updateEquipment,
        isLoading,
        refetch,
        setIsEdit,
        isEdit,
        removeEquipment,
        setRemoveEquipment,
      }}
    >
      {children}
    </EquipmentsContext.Provider>
  )
}

export function useEquipments(): EquipmentsContextData {
  const context = useContext(EquipmentsContext)

  if (!context) {
    throw new Error('useAuth must be used within an AutoProvider')
  }

  return context
}
