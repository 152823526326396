/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, createContext, useState } from 'react'
import { useQuery } from 'react-query'

import { message } from 'antd'

import api from '~/services/api'

import { useCompanies } from '~/hooks/Companies/useCompanies'
import { useLocale } from '~/hooks/locale/useLocale'

import { ShowError } from '~/utils/errors/apiErrors'
import { translate } from '~/utils/locale'

interface CategoryProps {
  id: string
  title: string
}

interface CategoryContextData {
  category?: CategoryProps[]
  createCategory(title: object | undefined): void
  updateCategory(title: object | undefined, categoryId: string): void
  deleteCategory(categoryId: number): void
  isLoading: boolean
  isFetching: boolean
  refetch: () => void
  categoryTotal?: number
}

const CategoryContext = createContext<CategoryContextData>(
  {} as CategoryContextData,
)

export const CategoryProvider: React.FC = ({ children }) => {
  const { selectedCompany } = useCompanies()
  const { locale } = useLocale()

  const [categoryTotal, setCategoryTotal] = useState<number>()

  const {
    data: category,
    isLoading,
    refetch,
    isFetching,
  } = useQuery(
    [`categoria${selectedCompany}`, selectedCompany],

    async () => {
      try {
        const response = await api.get(`/company/${selectedCompany}/categories`)

        const { data } = response

        setCategoryTotal(data.total)

        return data.results
      } catch (err: any) {
        ShowError(
          err.message,
          translate('CategoryHooks.getErrorMessage'),
          locale,
        )
      }
    },
  )

  async function createCategory(title: object) {
    try {
      await api.post(`/company/${selectedCompany}/category`, title)

      refetch()
      message.success(translate('CategoryHooks.createSuccessMessage'))
    } catch (err: any) {
      ShowError(
        err.message,
        translate('CategoryHooks.createErrorMessage'),
        locale,
      )
    }
  }

  async function updateCategory(title: object, categoryId: string) {
    try {
      await api.put(`/company/${selectedCompany}/category/${categoryId}`, title)

      message.success(translate('CategoryHooks.updateSuccessMessage'))
      refetch()
    } catch (err: any) {
      ShowError(
        err.message,
        translate('CategoryHooks.updateErrorMessage'),
        locale,
      )
    }
  }

  async function deleteCategory(categoryId: number) {
    try {
      await api.delete(`/company/${selectedCompany}/category/${categoryId}`)

      refetch()
      message.success(translate('CategoryHooks.deleteSuccessMessage'))
    } catch (err: any) {
      ShowError(
        err.message,
        translate('CategoryHooks.deleteErrorMessage'),
        locale,
      )
    }
  }

  return (
    <CategoryContext.Provider
      value={{
        category,
        createCategory,
        updateCategory,
        deleteCategory,
        isLoading,
        refetch,
        isFetching,
        categoryTotal,
      }}
    >
      {children}
    </CategoryContext.Provider>
  )
}

export function useCategory(): CategoryContextData {
  const context = useContext(CategoryContext)

  if (!context) {
    throw new Error('useAuth must be used within an AutoProvider')
  }

  return context
}
