import React from 'react'
import { Helmet } from 'react-helmet'

import { ReportTasksProvider } from '~/hooks/Report/Tasks/useReportTasks'

import { TeamsProvider } from '~/hooks/Teams/useListTeams'

import { ListEquipmentsProvider } from '~/hooks/Equipments/ListEquipmentsHooks'
import TasksReportBoard from '~/components/DataManipulation/Report/Tasks'
import { translate } from '~/utils/locale'

const ReportTasks: React.FC = () => {
  return (
    <ReportTasksProvider>
      <Helmet>
        <title>{translate('ReportTasks.helmetTitle')}</title>
      </Helmet>
      <TeamsProvider>
        <ListEquipmentsProvider>
          <TasksReportBoard />
        </ListEquipmentsProvider>
      </TeamsProvider>
    </ReportTasksProvider>
  )
}

export default ReportTasks
