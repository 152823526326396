import React from 'react'
import { Helmet } from 'react-helmet'
import TasksCalendar from '~/components/DataManipulation/Tasks/TasksCalendar'

import { ListClientsProvider } from '~/hooks/Clients/useListClients'
import { ListContributorsProvider } from '~/hooks/Contributors/useListContributors'

import { ListEquipmentsProvider } from '~/hooks/Equipments/ListEquipmentsHooks'
import { TaskTypeProvider } from '~/hooks/Tasks/TaskType/useTaskType'
import { TaskListProvider } from '~/hooks/Tasks/useListTasks'
import { TeamsProvider } from '~/hooks/Teams/useListTeams'
import { translate } from '~/utils/locale'

import { Container } from './styles'

const Tasks: React.FC = () => {
  return (
    <Container>
      <Helmet>
        <title>{translate('Tasks.helmetTitle')}</title>
      </Helmet>
      <ListEquipmentsProvider>
        <TaskTypeProvider>
          <TaskListProvider>
            <ListContributorsProvider>
              <ListClientsProvider>
                <TeamsProvider>
                  <TasksCalendar />
                </TeamsProvider>
              </ListClientsProvider>
            </ListContributorsProvider>
          </TaskListProvider>
        </TaskTypeProvider>
      </ListEquipmentsProvider>
    </Container>
  )
}

export default Tasks
