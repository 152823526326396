/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useCallback } from 'react'

import {
  Card,
  Space,
  Button,
  Form,
  Input,
  Row,
  Col,
  Select,
  message,
  Checkbox,
  Tooltip,
  Tour,
} from 'antd'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import pt_BR from 'react-phone-number-input/locale/pt-BR.json'
import { cpf, cnpj } from 'cpf-cnpj-validator'

import { MailOutlined, SaveOutlined } from '@ant-design/icons'
import { Helmet } from 'react-helmet'

import { useHistory } from 'react-router-dom'
import { MaskedInput } from 'antd-mask-input'

import { Container } from './styles'

import api from '~/services/api'

import { useListContributors } from '~/hooks/Contributors/useListContributors'
import { useCompanies } from '~/hooks/Companies/useCompanies'
import { useListClients } from '~/hooks/Clients/useListClients'
import { useLocale } from '~/hooks/locale/useLocale'

import { ShowError } from '~/utils/errors/apiErrors'
import { translate } from '~/utils/locale'

import { useClientTour } from '~/hooks/Tour/useClientTour'
import { useTour } from '~/hooks/Tour/useTour'

interface ClientsData {
  phone: string
  name: string
  document: string
  rg: string
  email: string
  person_type: string
  cnpj: string
}

const NewClients: React.FC = () => {
  const { selectedCompany } = useCompanies()
  const { data } = useListContributors()
  const { refetch } = useListClients()
  const { locale } = useLocale()

  const [keepRegister, setKeepRegister] = useState(false)
  const [typePerson, setTypePerson] = useState('')

  const history = useHistory()
  const [form] = Form.useForm()

  const {
    createNameClientRef,
    createEmailClientRef,
    createPhoneClientRef,
    createResponsibleClientRef,
    saveClientRef,
    openCreateClient,
    createClientSteps,
    setOpenListClient,
  } = useClientTour()

  const { setIsTour } = useTour()

  function onClose() {
    setIsTour(false)
    setOpenListClient(false)
  }

  const generateFakeEmail = (name: string) => {
    const randomString = Math.random().toString(36).substring(7)
    return `${name
      .replace(/\s+/g, '')
      .toLowerCase()}_${randomString}@gstor.com.br`
  }

  const handleGenerateEmail = () => {
    const nameFieldValue = form.getFieldValue('name')
    if (nameFieldValue) {
      const fakeEmail = generateFakeEmail(nameFieldValue)
      form.setFieldsValue({
        email: fakeEmail,
      })
    } else {
      message.error(translate('NewClients.randomEmailError'))
    }
  }

  function onTypePerson(value: any) {
    if (value === undefined) {
      setTypePerson('')
    } else {
      setTypePerson(value)
    }
  }

  const onCreate = useCallback(
    async (dataForm: ClientsData) => {
      try {
        dataForm.name = dataForm.name.toUpperCase()
        if (dataForm.phone) {
          dataForm.phone = dataForm.phone.replace(/[^0-9]/g, '')
        }

        if (dataForm.cnpj) {
          dataForm.cnpj = dataForm.cnpj.replace(/[^0-9]/g, '')
        }

        if (dataForm.document) {
          dataForm.document = dataForm.document.replace(/[^a-zA-Z0-9]/g, '')
        }

        if (dataForm.rg) {
          dataForm.rg = dataForm.rg.replace(/[^a-zA-Z0-9]/g, '')
        }

        await api.post(`/company/${selectedCompany}/customer`, dataForm)

        refetch()

        if (!keepRegister) {
          history.push('/clientes')
        }

        form.resetFields()

        message.success(translate('NewClients.successMessage'))
        setOpenListClient(true)
      } catch (error: any) {
        ShowError(error.message, translate('NewClients.errorMessage'), locale)
      }
    },
    [
      history,
      selectedCompany,
      refetch,
      keepRegister,
      form,
      locale,
      setOpenListClient,
    ],
  )

  const checkCNPJ = useCallback(
    async (cnpjInput: string) => {
      try {
        cnpjInput = cnpjInput.replace(/[^0-9]/g, '')
        const { data: cnpjCheckData } = await api.get(
          `company/${selectedCompany}/cnpjcheck/${cnpjInput}`,
        )
        if (cnpjCheckData.cnpjInUse) {
          message.warning(translate('NewClients.cnpjInUseMessage'), 10)
        }
      } catch (err: any) {
        console.log(err)
      }
    },
    [selectedCompany],
  )

  return (
    <Container>
      <Helmet>
        <title>{translate('NewClients.helmetTitle')}</title>
      </Helmet>

      {/* <PageHeader
        className="site-page-header"
        onBack={() => window.history.back()}
        title={translate('NewClients.pageHeaderTitle')}
      /> */}

      <Card
        title={translate('NewClients.cardTitle')}
        extra={
          <Space size="middle">
            <Checkbox
              onChange={(e) => {
                setKeepRegister(e.target.checked)
              }}
              checked={keepRegister}
            >
              {translate('NewClients.keepRegisterText')}
            </Checkbox>
            <Button
              type="primary"
              icon={<SaveOutlined />}
              htmlType="submit"
              form="onFormNewClient"
              ref={saveClientRef}
              id="btn_create_client"
            >
              {translate('NewClients.buttonText')}
            </Button>
          </Space>
        }
        bordered={false}
        style={{ width: '100%', padding: 30 }}
      >
        <Form
          layout="vertical"
          id="onFormNewClient"
          onFinish={onCreate}
          form={form}
        >
          <Row gutter={12}>
            <Col span={12} ref={openCreateClient ? createNameClientRef : null}>
              <Form.Item
                label={translate('NewClients.nameLabel')}
                name="name"
                rules={[
                  {
                    required: true,
                    message: translate('NewClients.nameRuleMessage'),
                  },
                ]}
              >
                <Input
                  placeholder={translate('NewClients.namePlaceholder')}
                  style={{ textTransform: 'uppercase' }}
                />
              </Form.Item>
            </Col>
            <Col span={12} ref={createEmailClientRef}>
              <Form.Item
                label={
                  <Space size="large">
                    {translate('NewClients.emailLabel')}
                    <Tooltip
                      title={translate('NewClients.emailGenerateTooltip')}
                    >
                      <Button
                        shape="circle"
                        size="small"
                        icon={<MailOutlined />}
                        onClick={handleGenerateEmail}
                      />
                    </Tooltip>
                  </Space>
                }
                name="email"
              >
                <Input placeholder={translate('NewClients.emailPlaceholder')} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={12}>
            <Col span={12}>
              <Form.Item
                label={translate('NewClients.personTypeLabel')}
                name="person_type"
              >
                <Select
                  allowClear
                  placeholder={translate('NewClients.personTypePlaceholder')}
                  onChange={onTypePerson}
                >
                  <Select.Option value="physicalPerson">
                    {translate('NewClients.personTypeOptionOne')}
                  </Select.Option>
                  <Select.Option value="legalPerson">
                    {translate('NewClients.personTypeOptionTwo')}
                  </Select.Option>
                  <Select.Option value="foreign">
                    {translate('NewClients.personTypeOptionThree')}
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
            {typePerson !== '' && (
              <>
                <Col span={6}>
                  <Form.Item
                    label={
                      typePerson === 'legalPerson' ||
                      typePerson === 'physicalPerson'
                        ? translate('NewClients.documentLabelOne')
                        : translate('NewClients.documentLabelTwo')
                    }
                    name="document"
                    rules={[
                      {
                        required: typePerson === 'physicalPerson',
                        message: translate('NewClients.documentRuleMessageOne'),
                      },
                      {
                        validator(_, value) {
                          if (value === undefined || value === null) {
                            return Promise.resolve()
                          }
                          const CpfInput = value.replace(/[^0-9]/g, '')
                          if (CpfInput.length === 11) {
                            if (cpf.isValid(value)) {
                              return Promise.resolve()
                            }
                            // eslint-disable-next-line prefer-promise-reject-errors
                            return Promise.reject(
                              translate('NewClients.documentRuleMessageTwo'),
                            )
                          }
                          return Promise.resolve()
                        },
                      },
                    ]}
                  >
                    {typePerson === 'physicalPerson' ||
                    typePerson === 'legalPerson' ? (
                      <MaskedInput mask={'000.000.000-00'} placeholder="" />
                    ) : (
                      <MaskedInput mask={'aa000000'} placeholder="" />
                    )}
                  </Form.Item>
                </Col>
                <Col span={6}>
                  {typePerson !== 'physicalPerson' && (
                    <Form.Item
                      label={translate('NewClients.cnpjLabel')}
                      name="cnpj"
                      rules={[
                        {
                          validator(_, value) {
                            if (value === undefined || value === null) {
                              return Promise.resolve()
                            }
                            const CnpjInput = value.replace(/[^0-9]/g, '')
                            if (CnpjInput.length === 14) {
                              if (cnpj.isValid(value)) {
                                return Promise.resolve()
                              }
                              // eslint-disable-next-line prefer-promise-reject-errors
                              return Promise.reject(
                                translate('NewClients.cnpjRuleMessageTwo'),
                              )
                            }
                            return Promise.resolve()
                          },
                        },
                      ]}
                    >
                      <MaskedInput
                        mask={'00.000.000/0000-00'}
                        onBlur={(e) => checkCNPJ(e.target.value)}
                      />
                    </Form.Item>
                  )}

                  {typePerson === 'physicalPerson' && (
                    <Form.Item
                      label={translate('NewClients.rgLabel')}
                      name="rg"
                    >
                      <Input />
                    </Form.Item>
                  )}
                </Col>
              </>
            )}
          </Row>

          <Row gutter={12}>
            <Col span={12} ref={createPhoneClientRef}>
              <Form.Item
                label={translate('NewClients.phoneLabel')}
                name="phone"
                rules={[
                  () => ({
                    validator(rule, phone) {
                      if (phone === undefined || phone === null) {
                        return Promise.resolve()
                      }
                      if (phone && isValidPhoneNumber(phone)) {
                        return Promise.resolve()
                      }
                      return Promise.reject(
                        new Error(translate('NewClients.phoneRuleMessage')),
                      )
                    },
                  }),
                ]}
              >
                <PhoneInput
                  placeholder={translate('NewClients.phonePlaceholder')}
                  labels={pt_BR}
                  defaultCountry="BR"
                  international
                  onChange={() => {}}
                />
              </Form.Item>
            </Col>
            <Col span={12} ref={createResponsibleClientRef}>
              <Form.Item
                label={translate('NewClients.collaboratorIdLabel')}
                name="collaborator_id"
              >
                <Select
                  placeholder={translate(
                    'NewClients.collaboratorIdPlaceholder',
                  )}
                  optionFilterProp="children"
                  showSearch
                >
                  {data?.map((contribuitor) => (
                    <Select.Option
                      value={contribuitor.id}
                      key={contribuitor.id}
                    >
                      {contribuitor.contact.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Card>

      <Tour
        open={openCreateClient}
        onClose={onClose}
        steps={createClientSteps}
        indicatorsRender={(current, total) => (
          <span>
            {current + 1} / {total}
          </span>
        )}
      />
    </Container>
  )
}

export default NewClients
