/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-underscore-dangle */
import { EditOutlined } from '@ant-design/icons'
import {
  Modal,
  Button,
  Tooltip,
  Form,
  Input,
  Row,
  Col,
  Select,
  Switch,
  InputNumber,
  Tabs,
  List,
  Tag,
  Space,
  Badge,
} from 'antd'
import { MaskedInput } from 'antd-mask-input'

import React, { useState } from 'react'
import { TaskFormsProvider } from '~/hooks/Tasks/Forms/useTaskForms'
import { useTaskType } from '~/hooks/Tasks/TaskType/useTaskType'
import { translate } from '~/utils/locale'
import AboutTypeTasksForms from './forms'
import { ContainerButton } from './styles'
import HelpTooltip from '~/components/DataManipulation/Misc/HelpTooltip'

interface TaskTypeData {
  description: string
  is_enabled: boolean
  runtime: string
  tolerance_time: string
  _id: string
  createdAt: string
  name: string
  value: boolean
  is_active: boolean
  title: string
  valueTask?: number
  amount_to_pay: number
  amount_to_receive: number
  mandatory: {
    is_active: boolean
    name: string
  }
  forms: Array<string>
}

interface MandatoryProps {
  is_active: boolean
  name: string
  type: string
  value: boolean
}

export interface TaskTypeProps {
  description: string
  is_enabled: boolean
  runtime: string
  tolerance_time: string
  _id: string
  createdAt: string
  title: string
  valueTask?: number
  amount_to_pay: number
  amount_to_receive: number
  mandatory: MandatoryProps[]
  forms?: Array<{ form_id: string }>
}

interface UpdateSegmentData {
  data: TaskTypeProps
}

const AboutTypeTasks: React.FC<UpdateSegmentData> = ({ data }) => {
  const { updateTaskType, updateMandatory } = useTaskType()
  const [isModalVisible, setIsModalVisible] = useState(false)

  const [mandatory, setMandatory] = useState<string>()

  const { Option } = Select
  const { TabPane } = Tabs

  const [value, setValue] = useState('')
  const [receive, setReceive] = useState('')

  const formatMoney = (inputValue: any) => {
    let cleanValue = inputValue.replace(/\D/g, '')

    cleanValue = cleanValue.padStart(3, '0')

    const regex = /(\d+)(\d{2})$/
    const match = cleanValue.match(regex)

    if (match) {
      let inteiro = match[1]
      const centavos = match[2]

      inteiro = inteiro.replace(/\B(?=(\d{3})+(?!\d))/g, '.')

      const formattedValue = `${inteiro},${centavos}`

      return formattedValue
    }

    return inputValue
  }

  const handleChange = (value: any) => {
    const inputValue = value ? value.toString() : ''
    const formattedValue = formatMoney(inputValue)
    setValue(formattedValue)
  }

  const handleChangeReceive = (value: any) => {
    const inputValue = value ? value.toString() : ''
    const formattedValue = formatMoney(inputValue)
    setReceive(formattedValue)
  }

  const initialData = {
    description: data.description,
    runtime: data.runtime,
    tolerance_time: data.tolerance_time,
    title: data.title,
    amount_to_pay: data.valueTask ? data.valueTask : data.amount_to_pay,
    amount_to_receive: data.amount_to_receive,
  }

  function handleMandatory(value: string) {
    setMandatory(value)
  }

  const mandatoryFilter = data.mandatory?.filter((m) => {
    return m.value === true || m.type === 'number'
  })

  function onUpdateTaskType(dataForm: TaskTypeData) {
    const dataTask = {
      title: dataForm.title,
      description: dataForm.description,
      amount_to_pay: dataForm.amount_to_pay,
      amount_to_receive: dataForm.amount_to_receive,
      valueTask: dataForm.amount_to_pay,
      runtime:
        dataForm.runtime === data.runtime
          ? data.runtime
          : `${dataForm.runtime}:00`,
      tolerance_time:
        dataForm.tolerance_time === data.tolerance_time
          ? data.tolerance_time
          : `${dataForm.tolerance_time}:00`,
      forms:
        dataForm?.forms?.length > 0
          ? dataForm.forms.map((formId) => ({
              form_id: formId,
            }))
          : [],
    }

    const dataMandatory = {
      name: dataForm.name,
      value: mandatory !== 'take_pictures' ? true : dataForm.value,
      is_active: dataForm.is_active,
    }

    try {
      updateTaskType(dataTask, data._id)

      if (dataMandatory.name) {
        updateMandatory(dataMandatory, data._id)
      }
      setIsModalVisible(false)
    } catch (err) {
      // setIsModalVisible(false)
    }
  }

  const showModal = () => {
    setIsModalVisible(true)
  }

  const closeModal = () => {
    setIsModalVisible(false)
  }

  return (
    <div>
      <Tooltip title={translate('TasksRegistrationTypeTasksAbout.editTooltip')}>
        <Button
          onClick={showModal}
          type="primary"
          ghost
          shape="circle"
          icon={<EditOutlined />}
        />
      </Tooltip>
      <Modal
        title={translate('TasksRegistrationTypeTasksAbout.modalTitle')}
        open={isModalVisible}
        onOk={closeModal}
        onCancel={closeModal}
        width="700px"
        style={{ top: 30 }}
        footer={[
          <ContainerButton key="buttons">
            <Button key="back" onClick={closeModal}>
              {translate('TasksRegistrationTypeTasksAbout.closeButton')}
            </Button>
            <Button
              type="primary"
              key="update"
              htmlType="submit"
              form="taskTypeForm"
              id="btn_update_type_task"
            >
              {translate('TasksRegistrationTypeTasksAbout.saveButton')}
            </Button>
          </ContainerButton>,
        ]}
      >
        <Form
          onFinish={onUpdateTaskType}
          initialValues={initialData}
          layout="vertical"
          id="taskTypeForm"
        >
          <Tabs defaultActiveKey="1">
            <TabPane
              tab={translate('TasksRegistrationTypeTasksAbout.detailTab')}
              key="1"
            >
              <Row gutter={12}>
                <Col span={24}>
                  <Form.Item
                    label={translate(
                      'TasksRegistrationTypeTasksAbout.titleLabel',
                    )}
                    name="title"
                  >
                    <Input
                      placeholder={translate(
                        'TasksRegistrationTypeTasksAbout.titlePlaceholder',
                      )}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={12}>
                <Col span={12}>
                  <Form.Item
                    label={
                      <Space>
                        {translate(
                          'TasksRegistrationTypeTasksCreate.valueTaskLabel',
                        )}
                        <HelpTooltip
                          title="Esta informação será utilizada 
                        para calcular os valores a pagar dos colaboradores 
                        que não possuem valores acordados em equipes."
                        />
                      </Space>
                    }
                    name="amount_to_pay"
                  >
                    <InputNumber
                      style={{ width: '100%' }}
                      value={value}
                      onChange={handleChange}
                      formatter={formatMoney}
                      parser={(value) => value?.replace(/\D/g, '')}
                      placeholder={translate(
                        'TasksRegistrationTypeTasksAbout.valueTaskPlaceholder',
                      )}
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    label={
                      <Space>
                        {translate(
                          'TasksRegistrationTypeTasksCreate.value_received_TaskLabel',
                        )}
                        <HelpTooltip
                          title="Esta informação será utilizada 
                        para calcular os valores a receber dos clientes principais 
                        que não possuem valores acordados em seu cadastro."
                        />
                      </Space>
                    }
                    name="amount_to_receive"
                  >
                    <InputNumber
                      style={{ width: '100%' }}
                      value={receive}
                      onChange={handleChangeReceive}
                      formatter={formatMoney}
                      parser={(value) => value?.replace(/\D/g, '')}
                      placeholder={translate(
                        'TasksRegistrationTypeTasksAbout.valueTaskPlaceholder',
                      )}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item
                label={translate(
                  'TasksRegistrationTypeTasksAbout.descriptionLabel',
                )}
                name="description"
              >
                <Input.TextArea
                  placeholder={translate(
                    'TasksRegistrationTypeTasksAbout.descriptionPlaceholder',
                  )}
                />
              </Form.Item>

              <Row gutter={12}>
                <Col span={12}>
                  <Form.Item
                    label={translate(
                      'TasksRegistrationTypeTasksAbout.toleranceTimeLabel',
                    )}
                    name="tolerance_time"
                  >
                    <MaskedInput mask={'00:00'} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={translate(
                      'TasksRegistrationTypeTasksAbout.runtimeLabel',
                    )}
                    name="runtime"
                  >
                    <MaskedInput mask={'00:00'} />
                  </Form.Item>
                </Col>
              </Row>
            </TabPane>
            <TabPane
              tab={translate('TasksRegistrationTypeTasksAbout.obligationsTab')}
              key="2"
            >
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label={translate(
                      'TasksRegistrationTypeTasksAbout.obligationsLabel',
                    )}
                    name="name"
                  >
                    <Select
                      placeholder={translate(
                        'TasksRegistrationTypeTasksAbout.obligationsPlaceholder',
                      )}
                      style={{ width: '100%' }}
                      onChange={handleMandatory}
                    >
                      <Option value="fill_in_report">
                        {translate(
                          'TasksRegistrationTypeTasksAbout.fillInReport',
                        )}
                      </Option>
                      <Option value="get_signature">
                        {translate(
                          'TasksRegistrationTypeTasksAbout.getSignature',
                        )}
                      </Option>
                      <Option value="take_pictures">
                        {translate(
                          'TasksRegistrationTypeTasksAbout.takePictures',
                        )}
                      </Option>
                      <Option value="fill_in_km_driven">
                        {translate(
                          'TasksRegistrationTypeTasksAbout.fillInKmDriven',
                        )}
                      </Option>
                      <Option value="send_digital_OS_by_email">
                        {translate(
                          'TasksRegistrationTypeTasksAbout.sendDigitalOSByEmail',
                        )}
                      </Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={translate(
                      'TasksRegistrationTypeTasksAbout.statusLabel',
                    )}
                    name="is_active"
                    valuePropName="checked"
                  >
                    <Switch
                      checkedChildren={translate(
                        'TasksRegistrationTypeTasksAbout.statusActive',
                      )}
                      unCheckedChildren={translate(
                        'TasksRegistrationTypeTasksAbout.statusInactive',
                      )}
                    />
                  </Form.Item>
                </Col>
              </Row>

              {mandatory === 'take_pictures' ? (
                <Form.Item
                  label={translate(
                    'TasksRegistrationTypeTasksAbout.picturesNumber',
                  )}
                  name="value"
                >
                  <InputNumber />
                </Form.Item>
              ) : (
                ''
              )}

              <Row>
                <List
                  size="small"
                  bordered
                  dataSource={mandatoryFilter}
                  renderItem={(item) => (
                    <List.Item>
                      <Space>
                        {item.name === 'fill_in_report' ? (
                          <p>
                            {translate(
                              'TasksRegistrationTypeTasksAbout.fillInReport',
                            )}
                          </p>
                        ) : (
                          ''
                        )}
                        {item.name === 'get_signature' ? (
                          <p>
                            {translate(
                              'TasksRegistrationTypeTasksAbout.getSignature',
                            )}
                          </p>
                        ) : (
                          ''
                        )}
                        {item.name === 'take_pictures' ? (
                          <>
                            <p>
                              {translate(
                                'TasksRegistrationTypeTasksAbout.takePictures',
                              )}
                            </p>
                            <Badge
                              count={`${item.value} ${translate(
                                'TasksRegistrationTypeTasksAbout.photoText',
                              )}`}
                              style={{ backgroundColor: '#3C8ACB' }}
                            />
                          </>
                        ) : (
                          ''
                        )}
                        {item.name === 'fill_in_km_driven' ? (
                          <p>
                            {translate(
                              'TasksRegistrationTypeTasksAbout.fillInKmDriven',
                            )}
                          </p>
                        ) : (
                          ''
                        )}
                        {item.name === 'send_digital_OS_by_email' ? (
                          <p>
                            {translate(
                              'TasksRegistrationTypeTasksAbout.sendDigitalOSByEmail',
                            )}
                          </p>
                        ) : (
                          ''
                        )}
                        {item.is_active ? (
                          <Tag color="green">
                            {translate(
                              'TasksRegistrationTypeTasksAbout.statusActive',
                            )}
                          </Tag>
                        ) : (
                          <Tag>
                            {translate(
                              'TasksRegistrationTypeTasksAbout.statusInactive',
                            )}
                          </Tag>
                        )}
                      </Space>
                    </List.Item>
                  )}
                />
              </Row>
            </TabPane>
            <TabPane tab="Formulários" key="3">
              <TaskFormsProvider>
                <AboutTypeTasksForms data={data} />
              </TaskFormsProvider>
            </TabPane>
          </Tabs>
        </Form>
      </Modal>
    </div>
  )
}

export default AboutTypeTasks
