export default {
  pt: {
    ClientsTable: {
      columnName: 'Nome',
      columnResponsible: 'Responsável',
      columnEmail: 'Email',
      columnParentCustomer: 'Cliente Principal',
      columnParentCustomerCNPJ: 'CNPJ do Cliente Pai',
      columnActions: 'Ações',
      viewTooltip: 'Visualizar Cliente',
      deletePopconfirm: 'Deseja mesmo deletar o cliente?',
      deleteTooltip: 'Deletar',
    },
  },
  en: {
    ClientsTable: {
      columnName: 'Name',
      columnResponsible: 'Responsible',
      columnEmail: 'Email',
      columnParentCustomer: 'Parent Client',
      columnParentCustomerCNPJ: 'Parent Customer CNPJ',
      columnActions: 'Actions',
      viewTooltip: 'View Customer',
      deletePopconfirm: 'Do you really want to delete the customer?',
      deleteTooltip: 'Delete',
    },
  },
  es: {
    ClientsTable: {
      columnName: 'Nombre',
      columnResponsible: 'Responsable',
      columnEmail: 'Correo electrónico',
      columnParentCustomer: 'Cliente Principal',
      columnParentCustomerCNPJ: 'Cliente Principal CNPJ',
      columnActions: 'Acciones',
      viewTooltip: 'Ver cliente',
      deletePopconfirm: '¿Realmente desea eliminar el cliente?',
      deleteTooltip: 'Borrar',
    },
  },
}
