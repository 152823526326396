export default {
  pt: {
    useGroup: {
      getErrorMessage: 'Erro ao visualizar grupos de permissão.',
    },
  },
  en: {
    useGroup: {
      getErrorMessage: 'Error viewing permission groups.',
    },
  },
  es: {
    useGroup: {
      getErrorMessage: 'Error al ver los grupos de permisos.',
    },
  },
}
