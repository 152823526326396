/* eslint-disable no-underscore-dangle */
import { EditOutlined } from '@ant-design/icons'
import { Modal, Button, Tooltip, Form, Input, Switch } from 'antd'

import React, { useState } from 'react'
import { usePause } from '~/hooks/Tasks/Pause/usePause'
import { translate } from '~/utils/locale'

interface PauseProps {
  description: string
  is_enabled: boolean
  _id: string
  title: string
}
interface UpdateSegmentData {
  data: PauseProps
}

const UpdatePause: React.FC<UpdateSegmentData> = ({ data }) => {
  const { updatePause } = usePause()

  const [isModalVisible, setIsModalVisible] = useState(false)

  const initialData = {
    description: data.description,
    is_enabled: data.is_enabled,
    title: data.title,
  }

  function onUpdateTaskType(dataForm: PauseProps) {
    try {
      updatePause(dataForm, data._id)
      setIsModalVisible(false)
    } catch (err) {
      setIsModalVisible(false)
    }
  }

  const showModal = () => {
    setIsModalVisible(true)
  }

  const closeModal = () => {
    setIsModalVisible(false)
  }

  return (
    <div>
      <Tooltip title={translate('TasksRegistrationPauseUpdate.editTooltip')}>
        <Button
          onClick={showModal}
          ghost
          shape="circle"
          type="primary"
          icon={<EditOutlined />}
        />
      </Tooltip>
      <Modal
        title={translate('TasksRegistrationPauseUpdate.modalTitle')}
        open={isModalVisible}
        onOk={closeModal}
        onCancel={closeModal}
        width="700px"
        footer={[
          <Button key="back" onClick={closeModal}>
            {translate('TasksRegistrationPauseUpdate.closeButton')}
          </Button>,
          <Button
            type="primary"
            key="update"
            htmlType="submit"
            form="pauseUpdateForm"
          >
            {translate('TasksRegistrationPauseUpdate.saveButton')}
          </Button>,
        ]}
      >
        <Form
          onFinish={onUpdateTaskType}
          initialValues={initialData}
          layout="vertical"
          id="pauseUpdateForm"
        >
          <Form.Item
            label={translate('TasksRegistrationPauseUpdate.titleLabel')}
            name="title"
            rules={[
              {
                required: true,
                message: translate('TasksRegistrationPauseUpdate.titleRule'),
              },
            ]}
          >
            <Input
              placeholder={translate(
                'TasksRegistrationPauseUpdate.titlePlaceholder',
              )}
            />
          </Form.Item>
          <Form.Item
            label={translate('TasksRegistrationPauseUpdate.descriptionLabel')}
            name="description"
            rules={[
              {
                required: true,
                message: translate(
                  'TasksRegistrationPauseUpdate.descriptionRule',
                ),
              },
            ]}
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item
            label={translate('TasksRegistrationPauseUpdate.statusLabel')}
            name="is_enabled"
            valuePropName="checked"
            rules={[
              {
                required: true,
                message: translate('TasksRegistrationPauseUpdate.statusRule'),
              },
            ]}
          >
            <Switch
              checkedChildren={translate(
                'TasksRegistrationPauseUpdate.statusActive',
              )}
              unCheckedChildren={translate(
                'TasksRegistrationPauseUpdate.statusInactive',
              )}
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}

export default UpdatePause
