export default {
  pt: {
    EquipmentsTabsSpecificationCreate: {
      newButton: 'Nova Especificação',
      modalTitle: 'Nova Especificação',
      closeButton: 'Fechar',
      saveButton: 'Salvar',
      specificationNameLabel: 'Nome da Especificação',
      specificationNameRule: 'Por favor informe o nome da especificação.',
      specificationNamePlaceholder: 'Informe o nome da especificação',
      deleteTooltip: 'Deletar atributo',
      attributeLabel: 'Atributo',
      attributeRule: 'Por favor informe o atributo.',
      attributePlaceholder: 'Informe o atributo',
      valueLabel: 'Valor',
      valueRule: 'Por favor informe o valor.',
      valuePlaceholder: 'Informe um valor',
      addButton: 'Adicionar Atributo',
    },
  },
  en: {
    EquipmentsTabsSpecificationCreate: {
      newButton: 'New Specification',
      modalTitle: 'New Specification',
      closeButton: 'Close',
      saveButton: 'Save',
      specificationNameLabel: 'Specification Name',
      specificationNameRule: 'Please enter the specification name.',
      specificationNamePlaceholder: 'Enter the specification name',
      deleteTooltip: 'Delete Attribute',
      attributeLabel: 'Attribute',
      attributeRule: 'Please enter the attribute.',
      attributePlaceholder: 'Inform the attribute',
      valueLabel: 'Value',
      valueRule: 'Please inform the value.',
      valuePlaceholder: 'Enter a value',
      addButton: 'Add Attribute',
    },
  },
  es: {
    EquipmentsTabsSpecificationCreate: {
      newButton: 'Nueva especificación',
      modalTitle: 'Nueva especificación',
      closeButton: 'Cerrar',
      saveButton: 'Guardar',
      specificationNameLabel: 'Nombre de especificación',
      specificationNameRule: 'Introduzca el nombre de la especificación.',
      specificationNamePlaceholder: 'Introduzca el nombre de la especificación',
      deleteTooltip: 'Eliminar atributo',
      attributeLabel: 'Atributo',
      attributeRule: 'Ingrese el atributo.',
      attributePlaceholder: 'Informar el atributo',
      valueLabel: 'Valor',
      valueRule: 'Por favor informe el valor.',
      valuePlaceholder: 'Introduce un valor',
      addButton: 'Agregar atributo',
    },
  },
}
