/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-curly-newline */
import {
  BankOutlined,
  ExportOutlined,
  LoadingOutlined,
  LogoutOutlined,
  TeamOutlined,
} from '@ant-design/icons'
import {
  Avatar,
  Card,
  Space,
  Tag,
  Tooltip,
  List,
  Popconfirm,
  Button,
} from 'antd'
import { PageHeader } from '@ant-design/pro-components'
import { format } from 'date-fns'
import React from 'react'
import { Link } from 'react-router-dom'

import { useUsers } from '~/hooks/Users/useUsers'

import { LoadingIndicator } from '~/pages/Scale/EditScale/styles'
import { createLetterAvatar } from '~/utils/letter'
import { translate } from '~/utils/locale'
import { stringToHslColor } from '~/utils/stringToHslColor'
import AddGroup from '../Group'

import { Container, ListAbout } from './styles'

const UsersDetails: React.FC = () => {
  const { users, isLoading, deleteUserGroup } = useUsers()

  if (isLoading || !users) {
    return (
      <LoadingIndicator>
        <LoadingOutlined style={{ fontSize: 64, margin: 'auto' }} spin />
      </LoadingIndicator>
    )
  }

  const formattedDate = format(
    new Date(users.created_at),
    `dd/MM/yyyy '${translate('UsersDetails.dateString')}' HH:mm'h'`,
  )

  return (
    <>
      <PageHeader
        className="site-page-header"
        onBack={() => window.history.back()}
        title={translate('UsersDetails.pageHeaderTitle')}
        subTitle={users.username}
      />
      <Card
        bordered={false}
        title={translate('UsersDetails.cardTitle')}
        extra={
          <Space>
            <Tag color={users.active ? 'green' : 'default'}>
              {users.active
                ? translate('UsersDetails.activeText')
                : translate('UsersDetails.inactiveText')}
            </Tag>
          </Space>
        }
        style={{
          display: 'inline-block',
          width: '100%',
        }}
      >
        <Container>
          <div className="wrapper">
            <Avatar
              shape="square"
              size={155}
              style={{
                fontSize: 40,
                background: '#EAEAEA',
                color: stringToHslColor(`${users.username}`),
              }}
            >
              {createLetterAvatar(`${users.username}`)}
            </Avatar>
            <div className="text">
              <h3>{users.username}</h3>
              <p>
                {translate('UsersDetails.createdText')}&nbsp;
                {formattedDate}
              </p>

              <Space>
                {users.email !== null ? (
                  <p>
                    {translate('UsersDetails.emailText')}&nbsp;
                    {users.email}
                  </p>
                ) : (
                  translate('UsersDetails.noEmailText')
                )}
              </Space>
            </div>
          </div>
        </Container>
      </Card>
      <Card
        bordered={false}
        title={translate('UsersDetails.groupsCardTitle')}
        style={{
          display: 'inline-block',
          width: '100%',
          marginTop: 10,
        }}
        extra={<AddGroup />}
      >
        <ListAbout>
          <List
            itemLayout="horizontal"
            dataSource={users.userGroups}
            loading={isLoading}
            renderItem={(item) => (
              <List.Item
                actions={[
                  <Tooltip
                    title={translate('UsersDetails.viewGroupTooltip')}
                    key="ViewButton"
                  >
                    <Link to={`/permissoes/visualizar/${item.group.id}`}>
                      <Button
                        type="primary"
                        shape="circle"
                        icon={<ExportOutlined />}
                        ghost
                      />
                    </Link>
                  </Tooltip>,
                  <Popconfirm
                    title={translate('UsersDetails.deletePopconfirm')}
                    onConfirm={() => deleteUserGroup(item.group.id)}
                    key="DeleteButton"
                  >
                    <Tooltip title={translate('UsersDetails.deleteTooltip')}>
                      <Button
                        ghost
                        danger
                        shape="circle"
                        icon={<LogoutOutlined />}
                      />
                    </Tooltip>
                  </Popconfirm>,
                ]}
              >
                <List.Item.Meta
                  avatar={<Avatar icon={<TeamOutlined />} />}
                  title={<p>{item.group.name}</p>}
                  description={
                    <>
                      {item.group.permissions.length > 100 ? (
                        ''
                      ) : (
                        <>
                          {item.group.permissions.length === 1 ? (
                            <p>1 {translate('UsersDetails.permissionText')}</p>
                          ) : (
                            <p>
                              {item.group.permissions.length}{' '}
                              {translate('UsersDetails.permissionsText')}
                            </p>
                          )}
                        </>
                      )}
                    </>
                  }
                />
              </List.Item>
            )}
          />
        </ListAbout>
      </Card>
      <Card
        bordered={false}
        title={translate('UsersDetails.companiesCardTitle')}
        style={{
          display: 'inline-block',
          width: '100%',
          marginTop: 10,
        }}
      >
        <ListAbout>
          <List
            itemLayout="horizontal"
            dataSource={users.userCompanies}
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta
                  avatar={<Avatar icon={<BankOutlined />} />}
                  title={<p>{item.company.name}</p>}
                />
              </List.Item>
            )}
          />
        </ListAbout>
      </Card>
    </>
  )
}

export default UsersDetails
