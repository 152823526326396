/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Table, Input, Button, Space, Tooltip } from 'antd'
import { FileProtectOutlined, SearchOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'

interface TaskListProps {
  _id: string
  date: string
  customer: string
  parent_customer: string
  value: number
}

interface ParentCustomerTaskValueProps {
  _id: number
  nome: string
  totalTarefas: number
  totalMedia: number
  valorTotal: number
  tasks: TaskListProps[]
}

interface Props {
  parentCustomersTasks: ParentCustomerTaskValueProps[]
  loading: boolean
}

const TasksByParentCustomers: React.FC<Props> = ({
  parentCustomersTasks,
  loading,
}) => {
  const [, setSearchText] = useState<string>('')
  const [, setSearchedColumn] = useState<string>('')

  const getColumnSearchProps = (
    dataIndex: keyof ParentCustomerTaskValueProps,
  ) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: any) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Buscar nome`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Resetar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value: any, record: ParentCustomerTaskValueProps) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
  })

  const handleSearch = (
    selectedKeys: React.Key[],
    confirm: () => void,
    dataIndex: keyof ParentCustomerTaskValueProps,
  ) => {
    confirm()
    setSearchText(selectedKeys[0] as string)
    setSearchedColumn(dataIndex as string)
  }

  const handleReset = (clearFilters: () => void) => {
    clearFilters()
    setSearchText('')
  }

  const columns = [
    {
      title: 'Nome do Cliente Principal',
      dataIndex: 'nome',
      key: 'nome',
      sorter: (
        a: ParentCustomerTaskValueProps,
        b: ParentCustomerTaskValueProps,
      ) => a.nome.localeCompare(b.nome),
      ...getColumnSearchProps('nome'),
    },
    {
      title: 'Total de Tarefas',
      dataIndex: 'totalTarefas',
      key: 'totalTarefas',
      sorter: (
        a: ParentCustomerTaskValueProps,
        b: ParentCustomerTaskValueProps,
      ) => a.totalTarefas! - b.totalTarefas!,
    },
    {
      title: 'Valor Média',
      dataIndex: 'totalMedia',
      key: 'totalMedia',
      sorter: (
        a: ParentCustomerTaskValueProps,
        b: ParentCustomerTaskValueProps,
      ) => (a.totalMedia || 0) - (b.totalMedia || 0), // Ordenação por valor total
      render: (totalValue: number) => {
        return totalValue?.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        })
      },
    },
    {
      title: 'Valor Total',
      dataIndex: 'valorTotal',
      key: 'valorTotal',
      sorter: (
        a: ParentCustomerTaskValueProps,
        b: ParentCustomerTaskValueProps,
      ) => (a.valorTotal || 0) - (b.valorTotal || 0),
      render: (totalValue: number) => {
        return totalValue?.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        })
      },
    },
  ]

  const expandedRowRender = (record: ParentCustomerTaskValueProps) => {
    const taskColumns = [
      {
        title: 'Data',
        dataIndex: 'date',
        key: 'date',
        render: (date: string) => dayjs(date).format('DD/MM/YYYY'),
        sorter: (a: TaskListProps, b: TaskListProps) =>
          dayjs(a.date).unix() - dayjs(b.date).unix(),
      },
      {
        title: 'Cliente',
        dataIndex: 'customer',
        key: 'customer',
        sorter: (a: TaskListProps, b: TaskListProps) =>
          a.customer.localeCompare(b.customer),
      },
      {
        title: 'Valor',
        dataIndex: 'value',
        key: 'value',
        render: (totalValue: number) => {
          return totalValue?.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })
        },
        sorter: (a: TaskListProps, b: TaskListProps) => a.value - b.value,
      },
      {
        title: 'Visualizar',
        key: 'remove',
        render: (record: ParentCustomerTaskValueProps) => (
          <Space size="middle">
            <Tooltip title="Ver Tarefa">
              <Link to={`/tarefas/visualizar/${record._id}`}>
                <Button shape="circle" icon={<FileProtectOutlined />} />
              </Link>
            </Tooltip>
          </Space>
        ),
      },
    ]

    return (
      <Table
        columns={taskColumns}
        dataSource={record.tasks}
        pagination={false}
        rowKey={(task) => task._id}
      />
    )
  }

  return (
    <Table
      dataSource={parentCustomersTasks}
      loading={loading}
      columns={columns}
      pagination={false}
      expandable={{
        expandedRowRender,
        expandRowByClick: true,
      }}
      rowKey={(record) => record._id}
      scroll={{ x: 1000, y: 500 }}
      bordered
    />
  )
}

export default TasksByParentCustomers
