import { LoadingOutlined } from '@ant-design/icons'

import React from 'react'

import { Helmet } from 'react-helmet'
import ClientsTabs from '~/components/DataManipulation/Clients/ClientsTabs'
import { ClientsProvider, useClients } from '~/hooks/Clients/useClients'
import { ClientsContactsProvider } from '~/hooks/Clients/useContactClients'
import { ListContactsProvider } from '~/hooks/Clients/useListContacts'
import { TaskListProvider } from '~/hooks/Tasks/useListTasks'
import { Container, LoadingIndicator } from './styles'

import { translate } from '~/utils/locale'
import { ListParentClientsProvider } from '~/hooks/Clients/useListParentCustomer'

import { ParentCustomerInClientsProvider } from '~/hooks/Clients/useParentCustomersInClients'

const ShowClients: React.FC = () => {
  const { isLoading } = useClients()

  return (
    <Container>
      <Helmet>
        <title>{translate('ShowClients.helmetTitle')}</title>
      </Helmet>

      <ListContactsProvider>
        <ClientsProvider>
          <TaskListProvider>
            {isLoading ? (
              <LoadingIndicator>
                <LoadingOutlined
                  style={{ fontSize: 64, margin: 'auto' }}
                  spin
                />
              </LoadingIndicator>
            ) : (
              <ClientsContactsProvider>
                <ListParentClientsProvider>
                  <ParentCustomerInClientsProvider>
                    <ClientsTabs />
                  </ParentCustomerInClientsProvider>
                </ListParentClientsProvider>
              </ClientsContactsProvider>
            )}
          </TaskListProvider>
        </ClientsProvider>
      </ListContactsProvider>
    </Container>
  )
}

export default ShowClients
