import React from 'react'

import { Button, Card, Select, Space } from 'antd'
import { useParentCustomerFields } from '~/hooks/Clients/useParentCustomerFields'
import TableFinancialCustomers from './Customers/Table'
import TableFinancialTeams from './Receive/Table'
import { Link } from 'react-router-dom'
import { LinkOutlined } from '@ant-design/icons'
import { translate } from '~/utils/locale'

// import { Container } from './styles';

const Financial: React.FC = () => {
  const { visualization, setVisualization } = useParentCustomerFields()

  const handleChange = (value: string) => {
    setVisualization(value)
  }

  return (
    <Card
      title={
        <Space>
          {visualization === 'customers' ? (
            <b>{translate('FinancialComponent.cardTitle')}</b>
          ) : (
            <b>{translate('FinancialComponent.cardTitleTeam')}</b>
          )}
        </Space>
      }
      extra={[
        <Space key="buttons">
          <Select
            onChange={handleChange}
            defaultValue={visualization}
            style={{ width: '100%' }}
          >
            <Select.Option value="customers">
              {translate('FinancialComponent.teamText')}
            </Select.Option>
            <Select.Option value="teams">
              {translate('FinancialComponent.clientText')}
            </Select.Option>
          </Select>
          {visualization === 'customers' ? (
            <Link to="/financeiro/valoresReceber" target="_blank">
              <Button type="primary" icon={<LinkOutlined />}>
                {translate('FinancialComponent.relatoryText')}
              </Button>
            </Link>
          ) : (
            ''
          )}
        </Space>,
      ]}
      bordered={false}
      style={{ width: '100%', padding: 30 }}
    >
      {visualization === 'customers' ? (
        <TableFinancialCustomers />
      ) : (
        <TableFinancialTeams />
      )}
    </Card>
  )
}

export default Financial
