import React from 'react'
import { Helmet } from 'react-helmet'
import PaymentBoard from '~/components/DataManipulation/Payment'
import { ClientPaymentProvider } from '~/hooks/Payment/useClientPayment'
import { PaymentProvider } from '~/hooks/Payment/usePayment'

// import { Container } from './styles';

const Payment: React.FC = () => {
  return (
    <ClientPaymentProvider>
      <Helmet>
        <title>Pagamento</title>
      </Helmet>

      <PaymentProvider>
        <PaymentBoard />
      </PaymentProvider>
    </ClientPaymentProvider>
  )
}

export default Payment
