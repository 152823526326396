export default {
  pt: {
    GroupsFilter: {
      buttonTooltip: 'Limpar Filtro',
      filterText: 'Filtros',
      filterAppliedText: 'Filtro Aplicado',
      drawerTitle: 'Filtros de Permissões',
      contributorLabel: 'Colaborador',
      contributorPlaceholder: 'Selecione o colaborador',
      groupTypeLabel: 'Tipo do Grupo',
      groupTypePlaceholder: 'Selecione o tipo do grupo',
      groupTypeAdmin: 'Administrador',
      groupTypeUser: 'Usuário',
      groupTypeManager: 'Gestor',
      groupTypeCustom: 'Personalizado',
      applyButton: 'Aplicar',
      cleanButton: 'Limpar',
    },
  },
  en: {
    GroupsFilter: {
      buttonTooltip: 'Clean Filter',
      filterText: 'Filter',
      filterAppliedText: 'Filter Applied',
      drawerTitle: 'Permissions Filters',
      contributorLabel: 'Contributor',
      contributorPlaceholder: 'Select the contributor',
      groupTypeLabel: 'Group Type',
      groupTypePlaceholder: 'Select group type',
      groupTypeAdmin: 'Admin',
      groupTypeUser: 'User',
      groupTypeManager: 'Manager',
      groupTypeCustom: 'Custom',
      applyButton: 'Apply',
      cleanButton: 'Clean',
    },
  },
  es: {
    GroupsFilter: {
      buttonTooltip: 'Filtro claro',
      filterText: 'Filtros',
      filterAppliedText: 'Filtro Aplicado',
      drawerTitle: 'Filtros de permisos',
      contributorLabel: 'Colaborador',
      contributorPlaceholder: 'Seleccione el colaborador',
      groupTypeLabel: 'Tipo do Grupo',
      groupTypePlaceholder: 'Seleccionar tipo de grupo',
      groupTypeAdmin: 'Administrador',
      groupTypeUser: 'Usuario',
      groupTypeManager: 'Gerente',
      groupTypeCustom: 'Personalizado',
      applyButton: 'Aplicar',
      cleanButton: 'Limpiar',
    },
  },
}
