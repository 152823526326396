import React from 'react'
import CreateBadge from '~/components/DataManipulation/Badge/Create'
import { BadgeProvider } from '~/hooks/Badge/useBadge'

// import { Container } from './styles';

const NewBadge: React.FC = () => {
  return (
    <BadgeProvider>
      <CreateBadge />
    </BadgeProvider>
  )
}

export default NewBadge
