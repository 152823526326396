/* eslint-disable camelcase */
import styled, { keyframes } from 'styled-components'

import Image_Login from '../../../../assets/HelpDesk/img2.jpg'

export const Container = styled.div`
  height: 100vh;
  display: flex;
  align-items: stretch;

  h2 {
    margin-bottom: 0;
    color: var(--primary-initial-text);
  }

  h1 {
    color: var(--primary-color);
    font-weight: bold;
  }

  p {
    color: var(--secondary-initial-text);
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  max-width: 700px;
  padding: 25px;
`

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`

export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  animation: ${appearFromLeft} 1s;
  width: 100%;
`

export const Background = styled.div`
  flex: 1;
  background: url(${Image_Login}) no-repeat center;
  background-size: cover;
`
