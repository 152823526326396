export default {
  pt: {
    EquipmentsCreate: {
      pageheaderTitle: 'Novo Equipamento',
      cardTitle: 'Preencha os dados do equipamento',
      keepRegisterText: 'Continuar cadastrando Equipamentos?',
      saveButton: 'Salvar Equipamento',
      nameLabel: 'Nome',
      nameRule: 'Por favor informe o nome do equipamento',
      namePlaceholder: 'Informe seu nome',
      identifierLabel: 'Identificador',
      identifierRule: 'Por favor informe o nome o identificador',
      identifierPlaceholder: 'Informe o identificador',
      expirationDateLabel: 'Data de Validade',
      categoryLabel: 'Categoria',
      categoryPlaceholder: 'Selecione a categoria',
      descriptionLabel: 'Descrição',
      descriptionPlaceholder: 'Informe a descrição',
    },
  },
  en: {
    EquipmentsCreate: {
      pageheaderTitle: 'New Equipment',
      cardTitle: 'Fill in the equipment data',
      keepRegisterText: 'Continue registering Equipment?',
      saveButton: 'Save Equipment',
      nameLabel: 'Name',
      nameRule: 'Please enter the equipment name',
      namePlaceholder: 'Enter name',
      identifierLabel: 'Identifier',
      identifierRule: 'Please enter the name and identifier',
      identifierPlaceholder: 'Inform the identifier',
      expirationDateLabel: 'Expiration date',
      categoryLabel: 'Category',
      categoryPlaceholder: 'Select category',
      descriptionLabel: 'Description',
      descriptionPlaceholder: 'Enter the description',
    },
  },
  es: {
    EquipmentsCreate: {
      pageheaderTitle: 'Nuevo equipamiento',
      cardTitle: 'Rellene los datos del equipo',
      keepRegisterText: '¿Continuar registrando Equipo?',
      saveButton: 'Guardar Equipo',
      nameLabel: 'Nombre',
      nameRule: 'Por favor ingrese el nombre del dispositivo',
      namePlaceholder: 'Informar nombre',
      identifierLabel: 'Identificador',
      identifierRule: 'Por favor ingrese el nombre e identificador',
      identifierPlaceholder: 'Informar al identificador',
      expirationDateLabel: 'Fecha de validez',
      categoryLabel: 'Categoría',
      categoryPlaceholder: 'Selecciona una categoría',
      descriptionLabel: 'Descripción',
      descriptionPlaceholder: 'Introduce la descripción',
    },
  },
}
