import styled from 'styled-components'

interface Colors {
  color?: string
  isDataTask?: boolean
  dataSize: boolean
}

export const Container = styled.div<Colors>`
  background: #f9f9f9;

  width: 100%;
  min-height: ${(props) => (props.dataSize ? '100px' : 0)};
  height: 100%;
  padding: 0.6rem 1rem;

  border-radius: 0.5rem;
  border: 1px solid #d5d5d5;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: ${(props) => (props.dataSize ? '0.4rem' : 0)};

    .code {
      background: var(--primary-color);
      padding: 0.4rem;
      border-radius: 0.4rem;
      font-size: 12px;
      color: white;
    }

    p {
      color: var(--primary-initial-text);
      margin-bottom: 0;
    }
  }

  .content {
    color: #3a3b45;
    margin-top: ${(props) => (props.isDataTask ? 0 : '0.3rem')};
  }

  .divider {
    height: 1px;
    background: #eeeeee;
    margin: 0.5rem 0;
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h4,
    a {
      color: ${(props) => props.color};
      margin-bottom: 0;
    }
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 0;
  }

  transition: 0.3s;

  &:hover {
    border-radius: 15px;
    cursor: pointer;
  }
`
