export default {
  pt: {
    AssociatedEquipments: {
      createSuccessMessage: 'Associamento feito com sucesso!',
      createErrorMessage: 'Erro ao associar equipamentos.',
      deleteSuccessMessage: 'Associação deletada com sucesso!',
      deleteErrorMessage: 'Erro ao desassociar equipamentos.',
    },
  },
  en: {
    AssociatedEquipments: {
      createSuccessMessage: 'Association made successfully!',
      createErrorMessage: 'Error associating equipment.',
      deleteSuccessMessage: 'Association successfully deleted!',
      deleteErrorMessage: 'Error when disassociating equipment.',
    },
  },
  es: {
    AssociatedEquipments: {
      createSuccessMessage: '¡Asociación realizada con éxito!',
      createErrorMessage: 'Error al asociar equipo.',
      deleteSuccessMessage: 'Membresía eliminada con éxito!',
      deleteErrorMessage: 'Error al desasociar equipos.',
    },
  },
}
