import { PlusOutlined } from '@ant-design/icons'
import { Button, Card, Dropdown } from 'antd'
import React from 'react'
import {
  MdOutlineCheckBox,
  MdOutlineCheckCircleOutline,
  MdRadioButtonChecked,
  MdSubject,
} from 'react-icons/md'
import { useTaskForm } from '~/hooks/Tasks/Forms/useTaskForm'
import Question from './question'
import { Wrapper, Container } from './styles'

type IQuestionTypes = 'text' | 'multipleChoice' | 'checkbox' | 'dropdownList'
const Questions: React.FC = () => {
  const { form, isLoading, createQuestion, isFetching } = useTaskForm()

  const newQuestion = (questionType: IQuestionTypes) => {
    if (!form) return
    if (
      questionType === 'multipleChoice' ||
      questionType === 'checkbox' ||
      questionType === 'dropdownList'
    ) {
      createQuestion({ type: questionType, options: ['Opção 1', 'Opção 2'] })
    } else {
      createQuestion({ type: questionType })
    }
  }

  if (isLoading || !form || isFetching) {
    return (
      <Wrapper>
        <Card bordered={false}>
          <h2>Perguntas</h2>
        </Card>
        <Container>
          <Card loading />
        </Container>
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <Card bordered={false}>
        <h2>Perguntas</h2>
      </Card>
      <Container>
        {form.questions.map((question, index) => (
          <Question key={index} data={question} index={index} />
        ))}
        <Dropdown
          menu={{
            items: [
              {
                key: '0',
                label: 'Resposta',
                icon: <MdSubject size={'24px'} />,
                onClick: () => newQuestion('text'),
              },
              {
                type: 'divider',
              },
              {
                key: '1',
                label: 'Múltipla escolha',
                icon: <MdRadioButtonChecked size={'24px'} />,
                onClick: () => newQuestion('multipleChoice'),
              },
              {
                key: '2',
                label: 'Caixas de seleção',
                icon: <MdOutlineCheckBox size={'24px'} />,
                onClick: () => newQuestion('checkbox'),
              },
              {
                key: '3',
                label: 'Lista suspensa',
                icon: <MdOutlineCheckCircleOutline size={'24px'} />,
                onClick: () => newQuestion('dropdownList'),
              },
            ],
          }}
        >
          <Button type="dashed" size="large" block icon={<PlusOutlined />}>
            Adicionar Pergunta
          </Button>
        </Dropdown>
      </Container>
    </Wrapper>
  )
}

export default Questions
