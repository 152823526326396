import React, { useState } from 'react'
import { ParentClientsFinancialProps } from '../Table'
import { Button, Col, Form, Input, Modal, Row, Tooltip } from 'antd'
import { translate } from '~/utils/locale'
import { EditOutlined } from '@ant-design/icons'
import MoneyInput from '~/components/DataManipulation/Misc/MoneyInput'
import { MaskedInput } from 'antd-mask-input'
import { cnpj } from 'cpf-cnpj-validator'
import { useListParentClients } from '~/hooks/Clients/useListParentCustomer'
import {
  FinancialAddressProps,
  FinancialContactProps,
} from '~/hooks/Clients/useParentCustomer'

// import { Container } from './styles';

interface Customers {
  id: string
}

interface ParentClientsProps {
  _id: string
  cnpj: string
  cpf: string
  fantasyName: string
  companyName: string
  municipal_registration: string
  state_registration: string
  contact: FinancialContactProps
  address: FinancialAddressProps
  financial: ParentClientsFinancialProps
  customers: Customers[]
}

interface UpdateProps {
  data: ParentClientsProps
}

const UpdateFinancialParentCustomers: React.FC<UpdateProps> = ({ data }) => {
  const { updateChildrenParentClient, refetch } = useListParentClients()
  const [form] = Form.useForm()

  const [isModalVisible, setIsModalVisible] = useState(false)

  const [taskValue, setTaskValue] = useState(data?.financial.task_value)
  const [amountHours, setAmountHours] = useState(data?.financial.amount_hours)
  const [amountHoursAdditional, setAmountHoursAdditional] = useState(
    data?.financial.amount_hours_additional,
  )
  const [numberKm, setNumberKm] = useState(data?.financial.number_km)
  const [numberKmAdditional, setNumberKmAdditional] = useState(
    data?.financial.number_km_additional,
  )

  const initialValues = {
    companyName: data?.companyName,
    cnpj: data?.cnpj,
    cpf: data?.cpf,
    task_value: data.financial.task_value,
    amount_hours: data.financial.amount_hours,
    amount_hours_additional: data.financial.amount_hours_additional,
    number_km: data.financial.number_km,
    number_km_additional: data.financial.number_km_additional,
  }

  const handleTaskValue = (valueInCents: number | undefined) => {
    setTaskValue(valueInCents)
  }

  const handleHourValue = (valueInCents: number | undefined) => {
    setAmountHoursAdditional(valueInCents)
  }

  const handleKmValue = (valueInCents: number | undefined) => {
    setNumberKmAdditional(valueInCents)
  }

  const showModal = () => {
    setIsModalVisible(true)
  }

  const closeModal = () => {
    setIsModalVisible(false)
  }

  function updateParentClientsFinancial() {
    const dataForm = {
      companyName: data?.companyName,
      fantasyName: data?.fantasyName,
      cpf: data?.cpf,
      cnpj: data?.cnpj,
      municipal_registration: data?.municipal_registration,
      state_registration: data?.state_registration,
      contact: {
        email: data?.contact.email,
        name: data?.contact.name,
        phone: data?.contact.phone,
      },
      financial: {
        task_value: taskValue,
        amount_hours: amountHours,
        amount_hours_additional: amountHoursAdditional,
        number_km: numberKm,
        number_km_additional: numberKmAdditional,
      },
      address: {
        zip_code: data?.address.zip_code,
        street: data?.address.street,
        number: data?.address.number,
        complement: data?.address.complement,
        neighborhood: data?.address.neighborhood,
        city: data?.address.city,
        state: data?.address.state,
        location: {
          x: data?.address.location.x,
          y: data?.address.location.y,
        },
      },
    }

    try {
      updateChildrenParentClient(dataForm, data._id)
      setIsModalVisible(false)

      refetch()
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <>
      <Tooltip title={translate('FinancialComponent.editButton')}>
        <Button
          onClick={showModal}
          ghost
          shape="circle"
          type="primary"
          icon={<EditOutlined />}
        />
      </Tooltip>
      <Modal
        title={translate('FinancialComponent.editButton')}
        open={isModalVisible}
        onOk={closeModal}
        onCancel={closeModal}
        width="700px"
        footer={[
          <Button key="back" onClick={closeModal}>
            {translate('TasksRegistrationPauseUpdate.closeButton')}
          </Button>,
          <Button
            type="primary"
            key="update"
            htmlType="submit"
            form="updateFinancialPageParent"
          >
            {translate('TasksRegistrationPauseUpdate.saveButton')}
          </Button>,
        ]}
      >
        <Form
          layout="vertical"
          onFinish={updateParentClientsFinancial}
          initialValues={initialValues}
          id="updateFinancialPageParent"
          form={form}
        >
          <Row gutter={14}>
            <Col span={12}>
              <Form.Item
                label={translate('ClientsPrincipalDetailsUpdate.reasonLabel')}
                name="companyName"
                rules={[
                  {
                    required: true,
                    message: translate(
                      'ClientsPrincipalDetailsUpdate.reasonRule',
                    ),
                  },
                ]}
              >
                <Input
                  placeholder={translate(
                    'ClientsPrincipalDetailsUpdate.reasonPlaceholder',
                  )}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="CNPJ"
                name="cnpj"
                rules={[
                  {
                    validator(_, value) {
                      if (value === undefined || value === null) {
                        return Promise.resolve()
                      }
                      const CnpjInput = value.replace(/[^0-9]/g, '')
                      if (CnpjInput.length === 14) {
                        if (cnpj.isValid(value)) {
                          return Promise.resolve()
                        }
                        // eslint-disable-next-line prefer-promise-reject-errors
                        return Promise.reject(
                          translate('NewClients.cnpjRuleMessageTwo'),
                        )
                      }
                      return Promise.resolve()
                    },
                  },
                ]}
              >
                <MaskedInput mask={'00.000.000/0000-00'} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item
                label={translate('ClientsPrincipalFinancialUpdate.valueLabel')}
                name="task_value"
                rules={[
                  {
                    required: true,
                    message: translate(
                      'ClientsPrincipalFinancialUpdate.valueRule',
                    ),
                  },
                ]}
              >
                <MoneyInput value={taskValue} onChange={handleTaskValue} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={14}>
            <Col span={12}>
              <Form.Item
                label={translate('ClientsPrincipalFinancialUpdate.hoursLabel')}
                name="amount_hours"
              >
                <Input
                  value={amountHours}
                  onChange={(e) => setAmountHours(parseInt(e.target.value))}
                  style={{ width: '100%' }}
                  maxLength={2}
                  placeholder={translate(
                    'ClientsPrincipalFinancialUpdate.hoursPlaceholder',
                  )}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={translate(
                  'ClientsPrincipalFinancialUpdate.valueHoursLabel',
                )}
                name="amount_hours_additional"
              >
                <MoneyInput
                  value={amountHoursAdditional}
                  onChange={handleHourValue}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={14}>
            <Col span={12}>
              <Form.Item
                label={translate('ClientsPrincipalFinancialUpdate.kmLabel')}
                name="number_km"
              >
                <Input
                  placeholder={translate(
                    'ClientsPrincipalFinancialUpdate.kmPlaceholder',
                  )}
                  value={numberKm}
                  onChange={(e) => setNumberKm(parseInt(e.target.value))}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={translate(
                  'ClientsPrincipalFinancialUpdate.valueKmLabel',
                )}
                name="number_km_additional"
              >
                <MoneyInput
                  value={numberKmAdditional}
                  onChange={handleKmValue}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  )
}

export default UpdateFinancialParentCustomers
