/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, createContext } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'

import { message } from 'antd'
import { format } from 'date-fns'

import api from '~/services/api'

import { useCompanies } from '~/hooks/Companies/useCompanies'
import { useLocale } from '~/hooks/locale/useLocale'

import { ShowError } from '~/utils/errors/apiErrors'
import { translate } from '~/utils/locale'

interface MovementsProps {
  id: string
  notes: string
  created_at: string
  initialTime: Date
  type: string
  customer: {
    id: number
    contact: {
      id: string
      name: string
    }
  }
  collaborator: {
    id: number
    contact: {
      id: string
      name: string
    }
  }
}

interface MovementsContextData {
  movements?: MovementsProps[]
  createMovements(dataMovements: object | undefined): void
  isLoading: boolean
  refetch: () => void
}

const MovementsContext = createContext<MovementsContextData>(
  {} as MovementsContextData,
)

export const MovementsProvider: React.FC = ({ children }) => {
  const { selectedCompany } = useCompanies()
  const { locale } = useLocale()
  const { equipmentId } = useParams<{ equipmentId: string }>()

  const formatterDate = (value: Date) => {
    return format(value, "dd/MM/yyyy 'às' HH:mm'h'")
  }

  const resultsMovimentsFormatted = (data: any): any => {
    const dataFormat = data.map((hours: any) => {
      const movementFormatted = {
        ...hours,
        initialTime: formatterDate(new Date(hours.created_at)),
      }

      return movementFormatted
    })
    return dataFormat
  }

  const {
    data: movements,
    isLoading,
    refetch,
  } = useQuery(
    [`movimentações${selectedCompany}`, selectedCompany],

    async () => {
      try {
        const response = await api.get(
          `/company/${selectedCompany}/equipment/${equipmentId}/movements`,
        )

        const { data } = response

        return resultsMovimentsFormatted(data)
      } catch (err: any) {
        ShowError(
          err.message,
          translate('useMovements.getErrorMessage'),
          locale,
        )
      }
    },
  )

  async function createMovements(dataMovements: object) {
    try {
      await api.post(`/company/${selectedCompany}/movement`, dataMovements)

      message.success(translate('useMovements.createSuccessMessage'))
      refetch()
    } catch (err: any) {
      ShowError(
        err.message,
        translate('useMovements.createErrorMessage'),
        locale,
      )
    }
  }

  return (
    <MovementsContext.Provider
      value={{
        createMovements,
        isLoading,
        refetch,
        movements,
      }}
    >
      {children}
    </MovementsContext.Provider>
  )
}

export function useMovements(): MovementsContextData {
  const context = useContext(MovementsContext)

  if (!context) {
    throw new Error('useAuth must be used within an AutoProvider')
  }

  return context
}
