export default {
  pt: {
    SegmentsTable: {
      nameColumn: 'Nome',
      descriptionColumn: 'Descrição',
      actionsColumn: 'Ações',
      deletePopconfirm: 'Deseja mesmo deletar o segmento?',
      deleteTooltip: 'Deletar',
    },
  },
  en: {
    SegmentsTable: {
      nameColumn: 'Name',
      descriptionColumn: 'Description',
      actionsColumn: 'Actions',
      deletePopconfirm: 'Do you really want to delete the segment?',
      deleteTooltip: 'Delete',
    },
  },
  es: {
    SegmentsTable: {
      nameColumn: 'Nombre',
      descriptionColumn: 'Descripción',
      actionsColumn: 'Acciones',
      deletePopconfirm: '¿Realmente desea eliminar el segmento?',
      deleteTooltip: 'Borrar',
    },
  },
}
