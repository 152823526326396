/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment } from 'react'

import { Container, Teams } from './styles'
import { Col, Collapse, Row, Tag, Typography } from 'antd'

import { useTeams } from '~/hooks/Teams/useListTeams'
import { LoadingIndicator } from '../../Dashboard/Tasks/styles'
import { LoadingOutlined } from '@ant-design/icons'
import { useContributor } from '~/hooks/Contributors/useContributor'
import {
  FiBriefcase,
  FiCalendar,
  FiCreditCard,
  FiDollarSign,
  FiFileText,
} from 'react-icons/fi'

import Text from 'antd/lib/typography/Text'
import { format } from 'date-fns'

const ContributorFinancial: React.FC = () => {
  const { data, isLoading: isLoadingContributor } = useContributor()
  const { teams, isLoading } = useTeams()

  if (isLoading || !teams || isLoadingContributor || !data) {
    return (
      <LoadingIndicator>
        <LoadingOutlined style={{ fontSize: 34, margin: 'auto' }} spin />
      </LoadingIndicator>
    )
  }

  const findParentArraysContainingId = (idToCheck: string) => {
    return teams.filter((t) =>
      t.contributorsTeams.some((ct) => ct.collaborator.id === idToCheck),
    )
  }

  const result = findParentArraysContainingId(data.id)

  const formattedDate = (date: any) => format(new Date(date), `dd/MM/yyyy '`)

  return (
    <Container>
      <div>
        <Typography.Title level={5} style={{ margin: 0 }}>
          Valores acordados para pagar para Equipe/Colaborador
        </Typography.Title>
        <Typography.Text>
          Os valores exibidos foram extraídos do cadastro da equipe desse
          colaborador.
        </Typography.Text>
      </div>

      <Teams>
        {result.length > 0 ? (
          <Fragment>
            {result.map((team) => (
              <Collapse key={team.id}>
                <Collapse.Panel
                  header={<b>{team.title}</b>}
                  key={team.id}
                  style={{ marginBottom: '0.5rem' }}
                >
                  <>
                    <Row gutter={12}>
                      <Col span={12}>
                        <div className="text">
                          <div>
                            <FiDollarSign />
                            <Text strong>Valor a pagar</Text>
                          </div>
                          {team.amount_to_receive === null ? (
                            <Text>Nenhum valor foi fornecido.</Text>
                          ) : (
                            <Text>
                              {/* <CurrencyFormatter
                                value={team.amount_to_receive!}
                              /> */}
                            </Text>
                          )}
                        </div>
                      </Col>
                      <Col span={12}>
                        <div className="text">
                          <div>
                            <FiCalendar />
                            <Text strong>Dia de Pagamento</Text>
                          </div>
                          {team.payment_day === null ? (
                            <Text>Nenhuma data de pagamento fornecida.</Text>
                          ) : (
                            <Text>
                              {formattedDate(
                                team.payment_day
                                  ? team.payment_day
                                  : new Date(),
                              )}
                            </Text>
                          )}
                        </div>
                      </Col>
                    </Row>
                    <Row gutter={12}>
                      <Col span={12}>
                        <div className="text">
                          <div>
                            <FiDollarSign />
                            <Text strong>Condições de Pagamento</Text>
                          </div>
                          {team.payment_conditions ? (
                            <>
                              {team.payment_conditions === 'view' ? (
                                <Text>Á vista</Text>
                              ) : (
                                <Text>Parcelado</Text>
                              )}
                            </>
                          ) : (
                            <Text>
                              Nenhuma condição de pagamento foi fornecida.
                            </Text>
                          )}
                        </div>
                      </Col>
                      <Col span={12}>
                        <div className="text">
                          <div>
                            <FiCreditCard />
                            <Text strong>Método de Pagamento</Text>
                          </div>
                          {team.payment_method ? (
                            <>
                              {team.payment_method === 'deposit' ? (
                                <Text>Depósito em Conta</Text>
                              ) : (
                                ''
                              )}

                              {team.payment_method === 'ticket' ? (
                                <Text>Boleto Bancário</Text>
                              ) : (
                                ''
                              )}

                              {team.payment_method === 'pix' ? (
                                <Text>PIX</Text>
                              ) : (
                                ''
                              )}
                            </>
                          ) : (
                            <Text>
                              Nenhum método de pagamento foi fornecido.
                            </Text>
                          )}
                        </div>
                      </Col>
                    </Row>

                    <Row gutter={12}>
                      <Col span={12}>
                        <div className="text">
                          <div>
                            <FiFileText />
                            <Text strong>Número do Banco</Text>
                          </div>
                          {team.number_bank ? (
                            <Text>{team.number_bank}</Text>
                          ) : (
                            <Text>Número do banco não fornecido.</Text>
                          )}
                        </div>
                      </Col>
                      <Col span={12}>
                        <div className="text">
                          <div>
                            <FiBriefcase />
                            <Text strong>Nome do Banco</Text>
                          </div>
                          {team.name_bank ? (
                            <Text>{team.name_bank}</Text>
                          ) : (
                            <Text>Nome do banco não fornecido.</Text>
                          )}
                        </div>
                      </Col>
                    </Row>

                    <Row gutter={12}>
                      <Col span={12}>
                        <div className="text">
                          <div>
                            <FiFileText />
                            <Text strong>Agência</Text>
                          </div>
                          {team.agency_bank ? (
                            <Text>{team.agency_bank}</Text>
                          ) : (
                            <Text>Agência não fornecida.</Text>
                          )}
                        </div>
                      </Col>
                      <Col span={12}>
                        <div className="text">
                          <div>
                            <FiFileText />
                            <Text strong>Conta / Poupança</Text>
                          </div>
                          {team.account_bank ? (
                            <Text>{team.account_bank}</Text>
                          ) : (
                            <Text>Conta do banco não fornecido.</Text>
                          )}
                        </div>
                      </Col>
                    </Row>

                    <Row gutter={12}>
                      <Col span={12}>
                        <div className="text">
                          <div>
                            <FiFileText />
                            <Text strong>Conta PF ou PJ</Text>
                          </div>
                          {team.type_account_bank ? (
                            <>
                              {team.type_account_bank === 'current' ? (
                                <Text>Conta Corrente</Text>
                              ) : (
                                <Text>Conta Poupança</Text>
                              )}
                            </>
                          ) : (
                            <Text>
                              Nenhuma condição de pagamento foi fornecida.
                            </Text>
                          )}
                        </div>
                      </Col>

                      <Col span={12}>
                        <div className="text">
                          <div>
                            <FiFileText />
                            <Text strong>Tipo de Conta</Text>
                          </div>
                          {team.personal_bank ? (
                            <>
                              {team.personal_bank === 'physics' ? (
                                <Text>Pessoa Física</Text>
                              ) : (
                                <Text>Pessoa Jurídica</Text>
                              )}
                            </>
                          ) : (
                            <Text>
                              Nenhuma condição de pagamento foi fornecida.
                            </Text>
                          )}
                        </div>
                      </Col>
                    </Row>

                    <Row gutter={12}>
                      <Col span={12}>
                        <div className="text">
                          <div>
                            <FiDollarSign />
                            <Text strong>Chave pix</Text>
                          </div>
                          {team.key_pix ? (
                            <Text>{team.key_pix}</Text>
                          ) : (
                            <Text>Chave pix não fornecida.</Text>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </>
                </Collapse.Panel>
              </Collapse>
            ))}
          </Fragment>
        ) : (
          <Tag color="red">Você não está em nenhuma equipe.</Tag>
        )}
      </Teams>
    </Container>
  )
}

export default ContributorFinancial
