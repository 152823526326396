/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react'

import {
  Button,
  Col,
  DatePicker,
  Drawer,
  Form,
  Row,
  Select,
  Input,
  Tooltip,
} from 'antd'
import { CloseOutlined, FilterOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import { useListContributors } from '~/hooks/Contributors/useListContributors'
import { useTeams } from '~/hooks/Teams/useListTeams'
import { useListClients } from '~/hooks/Clients/useListClients'
import { useListEquipments } from '~/hooks/Equipments/ListEquipmentsHooks'
import { useReportTasks } from '~/hooks/Report/Tasks/useReportTasks'

import { Container } from './styles'
import { translate } from '~/utils/locale'

const FilterReportTasks: React.FC = () => {
  const { handleUpdateFilters, handleResetFilters, filterOptions } =
    useReportTasks()

  const [visible, setVisible] = useState(false)
  const [form] = Form.useForm()

  const [selectResponsible, setSelectResponsible] = useState<string>()

  const { data } = useListContributors()
  const { teams } = useTeams()
  const { clients } = useListClients()
  const { listEquipments } = useListEquipments()

  const statusOptions = [
    {
      label: translate('ReportTasksFilter.statusReceived'),
      value: 'received',
      id: 1,
    },
    {
      label: translate('ReportTasksFilter.statusViewed'),
      value: 'viewed',
      id: 2,
    },
    {
      label: translate('ReportTasksFilter.statusEnRoute'),
      value: 'en-route',
      id: 3,
    },
    {
      label: translate('ReportTasksFilter.statusBreak'),
      value: 'break',
      id: 4,
    },
    {
      label: translate('ReportTasksFilter.statusCanceled'),
      value: 'canceled',
      id: 5,
    },
    {
      label: translate('ReportTasksFilter.statusFinished'),
      value: 'finished',
      id: 6,
    },
    {
      label: translate('ReportTasksFilter.statusWorking'),
      value: 'working',
      id: 7,
    },
  ]

  useEffect(() => {
    form.setFieldsValue({
      currentStatus: filterOptions?.currentStatus,
      responsibleId: filterOptions?.responsibleId,
      teamResponsibleId: filterOptions?.teamResponsibleId,
      equipmentsId: filterOptions?.equipmentsId,
      externalCodes: filterOptions?.externalCodes,
      internalCodes: filterOptions?.internalCodes,
      fromDate: dayjs(filterOptions?.fromDate),
      toDate: dayjs(filterOptions?.toDate),
      customersId: filterOptions?.customersId,
    })
  }, [filterOptions, form])

  function onSelectResponsible(value: string) {
    setSelectResponsible(value)
  }

  const onFinish = (dataForm: any) => {
    handleUpdateFilters(dataForm)
  }

  const onReset = () => {
    handleResetFilters()
    form.resetFields()
    setVisible(false)
  }

  const showDrawer = () => {
    setVisible(true)
  }

  const onClose = () => {
    setVisible(false)
  }

  const isFilter = Object.keys(filterOptions).length !== 0

  return (
    <Container isFilter={isFilter}>
      <Button
        type="primary"
        onClick={showDrawer}
        loading={!data || !clients || !teams || !listEquipments}
        icon={
          !isFilter ? (
            <FilterOutlined />
          ) : (
            <Tooltip title={translate('ReportTasksFilter.cleanTooltip')}>
              <CloseOutlined onClick={onReset} />
            </Tooltip>
          )
        }
        id="btn_filter_report_tasks"
      >
        {isFilter
          ? translate('ReportTasksFilter.filterAppliedText')
          : translate('ReportTasksFilter.filterText')}
      </Button>
      <Drawer
        forceRender
        title={translate('ReportTasksFilter.drawerTitle')}
        width={580}
        onClose={onClose}
        open={visible}
        style={{ paddingBottom: 80 }}
      >
        <Form layout="vertical" onFinish={onFinish} form={form}>
          <Row gutter={10}>
            <Col span={12}>
              <Form.Item
                label={translate('ReportTasksFilter.fromDateLabel')}
                name="fromDate"
              >
                <DatePicker
                  format="DD/MM/YYYY"
                  placeholder={translate(
                    'ReportTasksFilter.fromDatePlaceholder',
                  )}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={translate('ReportTasksFilter.toDateLabel')}
                name="toDate"
              >
                <DatePicker
                  format="DD/MM/YYYY"
                  placeholder={translate('ReportTasksFilter.toDatePlaceholder')}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col span={12}>
              <Form.Item
                label={translate('ReportTasksFilter.statusLabel')}
                name="currentStatus"
              >
                <Select
                  placeholder={translate('ReportTasksFilter.statusPlaceholder')}
                  mode="multiple"
                >
                  {statusOptions.map((status) => (
                    <Select.Option value={status.value} key={status.id}>
                      {status.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={translate('ReportTasksFilter.customersLabel')}
                name="customersId"
              >
                <Select
                  placeholder={translate(
                    'ReportTasksFilter.customersPlaceholder',
                  )}
                  mode="multiple"
                >
                  {clients?.map((c) => (
                    <Select.Option value={c.id} key={c.id}>
                      {c.contact.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col span={12}>
              <Form.Item
                label={translate('ReportTasksFilter.responsibleLabel')}
                name="responsible"
              >
                <Select
                  placeholder={translate(
                    'ReportTasksFilter.responsiblePlaceholder',
                  )}
                  onChange={onSelectResponsible}
                >
                  <Select.Option value="colaborador">
                    {translate('ReportTasksFilter.responsibleContributor')}
                  </Select.Option>
                  <Select.Option value="team">
                    {translate('ReportTasksFilter.responsibleTeam')}
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              {selectResponsible === 'colaborador' ? (
                <Form.Item
                  label={translate('ReportTasksFilter.contributorLabel')}
                  name="responsibleId"
                >
                  <Select
                    placeholder={translate(
                      'ReportTasksFilter.contributorPlaceholder',
                    )}
                    mode="multiple"
                  >
                    {data.map((c) => (
                      <Select.Option value={c.id} key={c.id}>
                        {c.contact.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              ) : (
                ''
              )}
              {selectResponsible === 'team' ? (
                <Form.Item
                  label={translate('ReportTasksFilter.teamLabel')}
                  name="teamResponsibleId"
                >
                  <Select
                    placeholder={translate('ReportTasksFilter.teamPlaceholder')}
                    mode="multiple"
                  >
                    {teams?.map((t) => (
                      <Select.Option value={t.id} key={t.id}>
                        {t?.title}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              ) : (
                ''
              )}
            </Col>
            <Col span={12}>
              <Form.Item
                label={translate('ReportTasksFilter.equipmentLabel')}
                name="equipmentsId"
              >
                <Select
                  placeholder={translate(
                    'ReportTasksFilter.equipmentPlaceholder',
                  )}
                  mode="multiple"
                >
                  {listEquipments?.map((e) => (
                    <Select.Option value={e.id} key={e.id}>
                      {e.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={translate('ReportTasksFilter.taskIdLabel')}
                name="tasksId"
              >
                <Input
                  placeholder={translate('ReportTasksFilter.taskIdPlaceholder')}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col span={12}>
              <Form.Item
                label={translate('ReportTasksFilter.internalCodeLabel')}
                name="internalCodes"
              >
                <Input
                  placeholder={translate(
                    'ReportTasksFilter.internalCodePlaceholder',
                  )}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={translate('ReportTasksFilter.externalCodeLabel')}
                name="externalCodes"
              >
                <Input
                  placeholder={translate(
                    'ReportTasksFilter.externalCodePlaceholder',
                  )}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item style={{ width: '100%' }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: '100%' }}
                >
                  {translate('ReportTasksFilter.applyButton')}
                </Button>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item style={{ width: '100%' }}>
                <Button onClick={onReset} style={{ width: '100%' }}>
                  {translate('ReportTasksFilter.cleanButton')}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </Container>
  )
}

export default FilterReportTasks
