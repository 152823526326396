import { CloseOutlined, EditOutlined } from '@ant-design/icons'
import { Button, Form, Card, FormProps, Tag, Flex, Typography } from 'antd'
import React, { useState } from 'react'
import {
  IAnswerValueDTO,
  IQuestion,
  useTaskFormWithAnswers,
} from '~/hooks/Tasks/Forms/useTaskFormWithAnswers'
import QuestionText from './text'
import QuestionMultipleChoice from './multipleChoice'
import QuestionCheckbox from './checkbox'
import { Container } from './styles'
import QuestionDropdownList from './dropdownList'
import { useProfile } from '~/hooks/Auth/useProfile'
import { format } from 'date-fns'

interface FieldType {
  value: IAnswerValueDTO
}

interface QuestionProps {
  data: IQuestion
  index: number
}

const Question: React.FC<QuestionProps> = ({ data, index }) => {
  const [formAntd] = Form.useForm()
  const { profile } = useProfile()
  const { form, isLoading, isFetching, createAnswer, updateAnswer } =
    useTaskFormWithAnswers()
  const [isEditing, setIsEditing] = useState(false)

  const handleSave: FormProps<FieldType>['onFinish'] = (answer) => {
    if (!profile) return
    if (data.answer) {
      updateAnswer(data.id, data.answer.id, {
        collaborator_id: profile.collaborator_id,
        value: answer.value,
      })
    } else {
      createAnswer({
        collaborator_id: profile.collaborator_id,
        answers: [{ question_id: data.id, value: answer.value }],
      })
    }
    onCloseEditing()
  }

  const onOpenEditing = () => {
    setIsEditing(true)
  }

  const onCloseEditing = () => {
    formAntd.resetFields()
    setIsEditing(false)
  }

  if (isLoading || !form || isFetching) {
    return <Card loading />
  }

  const getFormType = () => {
    switch (data.type) {
      case 'text':
        return <QuestionText data={data} isEditing={isEditing} />
      case 'multipleChoice':
        return <QuestionMultipleChoice data={data} isEditing={isEditing} />
      case 'checkbox':
        return <QuestionCheckbox data={data} isEditing={isEditing} />
      case 'dropdownList':
        return <QuestionDropdownList data={data} isEditing={isEditing} />
      default:
        return 'Desconhecido'
    }
  }

  return (
    <Container>
      <Form
        form={formAntd}
        onFinish={handleSave}
        autoComplete="off"
        initialValues={data.answer}
      >
        <Card
          title={
            <Flex align="center" gap="1rem">
              <div>#{index + 1}</div>
              <div>{data.title}</div>
              {data.is_required && <Tag color="blue">Obrigatório</Tag>}
            </Flex>
          }
          type="inner"
          bordered={!!isEditing}
          extra={
            isEditing && (
              <Button
                icon={<CloseOutlined />}
                onClick={() => onCloseEditing()}
              />
            )
          }
          actions={
            isEditing
              ? [
                  <Button
                    key="save"
                    type="primary"
                    size={'large'}
                    htmlType="submit"
                    id="btn_update_task_form"
                  >
                    Salvar
                  </Button>,
                ]
              : [
                  <Button
                    key="edit"
                    size={'large'}
                    type="text"
                    icon={<EditOutlined />}
                    onClick={() => onOpenEditing()}
                  >
                    Editar resposta
                  </Button>,
                ]
          }
        >
          {getFormType()}

          {data.answer && data.answer.collaborator && (
            <Typography.Paragraph type="secondary">
              Resposta enviada por {data.answer.collaborator.contact.name} em{' '}
              {format(
                new Date(data.answer.updated_at),
                "dd/MM/yyyy 'às' HH:mm",
              )}
            </Typography.Paragraph>
          )}
        </Card>
      </Form>
    </Container>
  )
}

export default Question
