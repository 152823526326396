export default {
  pt: {
    ShowContributor: {
      helmetTitle: 'Visualizar Colaborador',
      resultTitle: 'Erro ao buscar informações',
      resultSubTitle: 'Por favor atualize a página para tentar novamente.',
      buttonText: 'Atualizar página',
    },
  },
  en: {
    ShowContributor: {
      helmetTitle: 'View Contributor',
      resultTitle: 'Error fetching information',
      resultSubTitle: 'Please refresh the page to try again.',
      buttonText: 'Refresh page',
    },
  },
  es: {
    ShowContributor: {
      helmetTitle: 'Ver Colaborador',
      resultTitle: 'Error al obtener información',
      resultSubTitle: 'Actualice la página para volver a intentarlo.',
      buttonText: 'Actualizar página',
    },
  },
}
