export default {
  pt: {
    EquipmentsTabsAttachmentsCreate: {
      newDocumentButton: 'Novo Documento/Anexo',
      modalTitle: 'Novo Documento/Anexo',
      saveText: 'Salvar',
      titleLabel: 'Título',
      titleRule: 'Por favor insira um titulo para o documento/anexo.',
      descriptionLabel: 'Descrição',
      descriptionRule: 'Por favor insira uma descrição para o documento/anexo.',
      draggerRule: 'Por favor selecione um arquivo',
      draggerInstructionOne: 'Clique ou arraste arquivos aqui para Enviar',
      draggerInstructionTwo: 'Apenas um arquivo por vez.',
    },
  },
  en: {
    EquipmentsTabsAttachmentsCreate: {
      newDocumentButton: 'New Document/Attachment',
      modalTitle: 'New Document/Attachment',
      saveText: 'Save',
      titleLabel: 'Title',
      titleRule: 'Please enter a title for the document/attachment.',
      descriptionLabel: 'Description',
      descriptionRule:
        'Please enter a description for the document/attachment.',
      draggerRule: 'Please select a file',
      draggerInstructionOne: 'Click or drag files here to Upload',
      draggerInstructionTwo: 'Only one file at a time.',
    },
  },
  es: {
    EquipmentsTabsAttachmentsCreate: {
      newDocumentButton: 'Nuevo Documento/Anexo',
      modalTitle: 'Nuevo Documento/Anexo',
      saveText: 'Guardar',
      titleLabel: 'Título',
      titleRule: 'Introduzca un título para el documento/archivo adjunto.',
      descriptionLabel: 'Descripción',
      descriptionRule:
        'Ingrese una descripción para el documento/archivo adjunto.',
      draggerRule: 'Seleccione un archivo',
      draggerInstructionOne:
        'Haga clic o arrastre los archivos aquí para cargar',
      draggerInstructionTwo: 'Solo un archivo a la vez.',
    },
  },
}
