import React, { useState } from 'react'

import {
  Tooltip,
  Button,
  Modal,
  Form,
  Input,
  Row,
  Col,
  Select,
  Space,
} from 'antd'
import { EditOutlined } from '@ant-design/icons'
import { Container, ContainerButton } from './styles'

import { useListContributors } from '~/hooks/Contributors/useListContributors'
import { useTeam } from '~/hooks/Teams/useTeams'
import { useTeams } from '~/hooks/Teams/useListTeams'
import { translate } from '~/utils/locale'

interface Managers {
  id: string
  contact: {
    id: string
    name: string
  }
}

interface TeamsProps {
  title: string
  id: string
  created_at: Date
  manager: Managers
  description: string
}

interface Teams {
  dataTeams: TeamsProps
}

const UpdateTeams: React.FC<Teams> = ({ dataTeams }) => {
  const { updateTeams } = useTeam()
  const { refetch } = useTeams()
  const [isModalVisible, setIsModalVisible] = useState(false)

  const { data } = useListContributors()

  const initialData = {
    title: dataTeams.title,
    collaborator_id: dataTeams.manager?.id,
    description: dataTeams.description,
  }

  async function onEditTeam(valueForm: object) {
    try {
      await updateTeams(valueForm, dataTeams.id)

      refetch()

      setIsModalVisible(false)
    } catch (err) {
      console.log(err)
    }
  }

  const showModal = () => {
    setIsModalVisible(true)
  }

  const closeModal = () => {
    setIsModalVisible(false)
  }

  return (
    <Container>
      <Tooltip title={translate('TeamsUpdate.editTooltip')}>
        <Button
          id="btn-edit-teams"
          type="primary"
          icon={<EditOutlined />}
          onClick={showModal}
        >
          {translate('TeamsUpdate.editButton')}
        </Button>
      </Tooltip>

      <Modal
        title={translate('TeamsUpdate.modalTitle')}
        open={isModalVisible}
        onOk={closeModal}
        onCancel={closeModal}
        width="600px"
        footer={[
          <Space key="buttons">
            <Button key="close" onClick={closeModal}>
              {translate('TeamsUpdate.closeButton')}
            </Button>
            <ContainerButton>
              <Button
                form="editTeam"
                key="save"
                type="primary"
                htmlType="submit"
                id="btn_save_update_team"
              >
                {translate('TeamsUpdate.saveButton')}
              </Button>
            </ContainerButton>
          </Space>,
        ]}
      >
        <Form
          layout="vertical"
          id="editTeam"
          initialValues={initialData}
          onFinish={onEditTeam}
        >
          <Row gutter={14}>
            <Col span={12}>
              <Form.Item
                label={translate('TeamsUpdate.titleLabel')}
                name="title"
                rules={[
                  {
                    required: true,
                    message: translate('TeamsUpdate.titleRule'),
                  },
                ]}
              >
                <Input
                  placeholder={translate('TeamsUpdate.titlePlaceholder')}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={translate('TeamsUpdate.responsibleLabel')}
                name="collaborator_id"
                rules={[
                  {
                    required: true,
                    message: translate('TeamsUpdate.responsibleRule'),
                  },
                ]}
              >
                <Select
                  placeholder={translate('TeamsUpdate.responsiblePlaceholder')}
                >
                  {data?.map(
                    (d) =>
                      d.contact?.name && (
                        <Select.Option value={d.id} key={d.id}>
                          {d.contact?.name}
                        </Select.Option>
                      ),
                  )}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                label={translate('TeamsUpdate.descriptionLabel')}
                name="description"
              >
                <Input.TextArea
                  placeholder={translate('TeamsUpdate.descriptionPlaceholder')}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </Container>
  )
}

export default UpdateTeams
