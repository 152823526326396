export default {
  pt: {
    EquipmentsHooks: {
      getErrorMessage: 'Erro ao listar equipamentos.',
      updateSuccessMessage: 'Equipamento atualizado com sucesso!',
      updateErrorMessage: 'Erro ao atualizer equipamentos.',
    },
  },
  en: {
    EquipmentsHooks: {
      getErrorMessage: 'Error listing equipment.',
      updateSuccessMessage: 'Equipment successfully updated!',
      updateErrorMessage: 'Error updating equipment.',
    },
  },
  es: {
    EquipmentsHooks: {
      getErrorMessage: 'Error en la lista de equipos.',
      updateSuccessMessage: '¡Equipo actualizado con éxito!',
      updateErrorMessage: 'Error al actualizar el equipo.',
    },
  },
}
