export default {
  pt: {
    GroupsUpdateGroup: {
      editTooltip: 'Editar Grupo',
      editButton: 'Renomear Grupo',
      modalTitle: 'Renomear Grupo',
      closeButton: 'Fechar',
      saveButton: 'Salvar',
      groupNameLabel: 'Nome do Grupo',
      groupNameRule: 'Preencha o nome do grupo.',
    },
  },
  en: {
    GroupsUpdateGroup: {
      editTooltip: 'Edit Group',
      editButton: 'Rename Group',
      modalTitle: 'Rename Group',
      closeButton: 'Close',
      saveButton: 'Save',
      groupNameLabel: "Group's name",
      groupNameRule: 'Fill in the group name.',
    },
  },
  es: {
    GroupsUpdateGroup: {
      editTooltip: 'Editar Grupo',
      editButton: 'Renombrar Grupo',
      modalTitle: 'Renombrar Grupo',
      closeButton: 'Cerrar',
      saveButton: 'Guardar',
      groupNameLabel: 'Nombre del Grupo',
      groupNameRule: 'Complete el nombre del grupo.',
    },
  },
}
