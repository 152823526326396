/* eslint-disable react/require-default-props */
import React from 'react'
import {
  Route as ReactDOMRoute,
  RouteProps as ReactDOMRouteProps,
  Redirect,
} from 'react-router-dom'

import { notification } from 'antd'

import DefaultLayout from '~/pages/_layouts/Default'
import AuthLayout from '~/pages/_layouts/Auth'

import { useAuth } from '~/hooks/Auth/AuthContext'

import { translate } from '~/utils/locale'

interface RouteProps extends ReactDOMRouteProps {
  isPrivate?: boolean
  isConfirmation?: boolean
  UserAccess?: boolean
  component: React.ComponentType
}

const Route: React.FC<RouteProps> = ({
  isPrivate = false,
  isConfirmation = false,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  UserAccess = false,
  component: Component,
  ...rest
}) => {
  const { user } = useAuth()

  const openNotification = () => {
    notification.info({
      message: <b>{translate('SignUp.notification.message')}</b>,
      description: (
        <p>
          <h4>{translate('SignUp.notification.description.title')}</h4>
          <ul>
            <li>{translate('SignUp.notification.description.stepOne')}</li>
            <li>{translate('SignUp.notification.description.stepTwo')}</li>
            <li>{translate('SignUp.notification.description.stepThree')}</li>
          </ul>
        </p>
      ),
      placement: 'topRight',
      duration: 0,
    })
  }

  return (
    <ReactDOMRoute
      {...rest}
      render={({ location }) => {
        if (isConfirmation && !user) {
          openNotification()
          return (
            <Redirect
              to={{
                pathname: '/',
                state: { from: location },
              }}
            />
          )
        }

        if (isPrivate && !user) {
          return (
            <Redirect
              to={{
                pathname: '/',
                state: { from: location },
              }}
            />
          )
        }

        // if (
        //   !UserAccess &&
        //   user?.active === false
        //   // && isUser
        // ) {
        //   return (
        //     <Redirect
        //       to={{
        //         pathname: '/noaccess',
        //         state: { from: location },
        //       }}
        //     />
        //   );
        // }

        if (
          (!isPrivate && user) ||
          // && !isUser
          (isConfirmation && user?.active === true)
          // && !isUser
        ) {
          return (
            <Redirect
              to={{
                pathname: '/dashboard',
                state: { from: location },
              }}
            />
          )
        }

        if (!isConfirmation && user?.active === false) {
          return (
            <Redirect
              to={{
                pathname: '/confirm-user',
                state: { from: location },
              }}
            />
          )
        }

        const Layout = user && !isConfirmation ? DefaultLayout : AuthLayout

        return (
          <Layout>
            <Component />
          </Layout>
        )
      }}
    />
  )
}

export default Route
