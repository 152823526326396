export default {
  pt: {
    TasksTabsEquipmentsCreate: {
      addButton: 'Adicionar equipamento',
      modalTitle: 'Nova Evidência',
      okText: 'Salvar',
      equipmentLabel: 'Equipamentos',
      equipmentPlaceholder: 'Informe os equipamentos',
    },
  },
  en: {
    TasksTabsEquipmentsCreate: {
      addButton: 'Add equipment',
      modalTitle: 'New Report',
      okText: 'Save',
      equipmentLabel: 'Equipment',
      equipmentPlaceholder: 'Inform the equipment',
    },
  },
  es: {
    TasksTabsEquipmentsCreate: {
      addButton: 'Añadir equipo',
      modalTitle: 'Nuevo reporte',
      okText: 'Guardar',
      equipmentLabel: 'Equipo',
      equipmentPlaceholder: 'Informar al equipo',
    },
  },
}
