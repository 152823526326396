export default {
  pt: {
    EquipmentsTabsMovements: {
      cardTitle: 'Movimentações',
      inventoryText: 'Estoque',
    },
  },
  en: {
    EquipmentsTabsMovements: {
      cardTitle: 'Movements',
      inventoryText: 'Inventory',
    },
  },
  es: {
    EquipmentsTabsMovements: {
      cardTitle: 'Movimientos',
      inventoryText: 'Inventario',
    },
  },
}
