/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  FileUnknownOutlined,
  LoadingOutlined,
  PlusCircleOutlined,
  PlusOutlined,
  ReloadOutlined,
} from '@ant-design/icons'
import { Button, Modal, Space, Tooltip, Table, Popconfirm } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import Text from 'antd/lib/typography/Text'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import { useClientsListTask } from '~/hooks/Tasks/Create/clientsListTask'
import { useTaskCreate } from '~/hooks/Tasks/Create/createTask'
import { translate } from '~/utils/locale'
import ClientsFilterModal from './ClientFilterModal'
import { useListParentClients } from '~/hooks/Clients/useListParentCustomer'

interface ClientsProps {
  id: string
  contact?: {
    name: string
    email: string
    id: string
  }
  parent_cnpj?: string
}

const ClientModalTask: React.FC = () => {
  const { clients, clientsTotal, setNumberPage, setPageSize, isFetching } =
    useClientsListTask()
  const { clientData, setClientData } = useTaskCreate()

  const { parentClients } = useListParentClients()

  const [isModalVisible, setIsModalVisible] = useState(false)

  async function handleClientTask(
    id: string,
    name: string,
    contactId: string,
    parent_cnpj: string,
  ) {
    const parentClient = parentClients?.filter((c) => {
      return c._id === parent_cnpj
    })
    const valueTask = parentClient?.[0]?.financial?.task_value

    const amountHours = parentClient?.[0]?.financial?.amount_hours
    const amountHoursAdditional =
      parentClient?.[0]?.financial?.amount_hours_additional
    const numberKm = parentClient?.[0]?.financial?.number_km
    const numberKmAdditional =
      parentClient?.[0]?.financial?.number_km_additional

    await setClientData({
      id,
      name,
      contactId,
      parent_cnpj,
      valueTask,
      amountHours,
      amountHoursAdditional,
      numberKm,
      numberKmAdditional,
    })
    setIsModalVisible(false)
  }

  const columns: ColumnsType<ClientsProps> = [
    {
      title: translate('ClientsTable.columnActions'),
      align: 'center',
      key: 'remove',
      render: (record) => (
        <Popconfirm
          title={translate('TasksCreateModalClient.addClientPopconfirm')}
          onConfirm={() =>
            handleClientTask(
              record.id,
              record.contact.name,
              record.contact.id,
              record.parent_cnpj,
            )
          }
        >
          <Tooltip title={translate('TasksCreateModalClient.addClientTooltip')}>
            <Button type="primary" shape="circle" icon={<PlusOutlined />} />
          </Tooltip>
        </Popconfirm>
      ),
    },
    {
      title: translate('ClientsTable.columnName'),
      align: 'left',
      dataIndex: ['contact', 'name'],
      key: 'name',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (name: string, record) => (
        <Tooltip title={translate('TasksCreateModalClient.viewClientTooltip')}>
          <Link
            target="_blank"
            to={`/clientes/visualizar/${record.id}/contato/${record.contact?.id}/`}
          >
            {name}
          </Link>
        </Tooltip>
      ),
    },
    {
      title: translate('ClientsTable.columnResponsible'),
      dataIndex: ['collaborator', 'contact', 'name'],
      align: 'center',
      key: 'contact',
      render: (contact: string) => (
        <>
          {contact || (
            <Text type="secondary">
              <Space size="small">
                <FileUnknownOutlined />
                N/A
              </Space>
            </Text>
          )}
        </>
      ),
    },
    {
      title: translate('ClientsTable.columnEmail'),
      align: 'center',
      key: 'email',
      dataIndex: ['contact', 'email'],
    },
  ]

  const showModal = () => {
    setIsModalVisible(true)
  }

  const closeModal = () => {
    setIsModalVisible(false)
  }

  return (
    <>
      {!clientData?.id ? (
        <Button
          icon={<PlusCircleOutlined />}
          onClick={showModal}
          style={{ width: '100%' }}
        >
          {translate('TasksCreateModalClient.addClientButton')}
        </Button>
      ) : (
        <Button size="small" icon={<ReloadOutlined />} onClick={showModal}>
          {translate('TasksCreateModalClient.changeClientButton')}
        </Button>
      )}

      <Modal
        title={translate('TasksCreateModalClient.modalTitle')}
        open={isModalVisible}
        onOk={closeModal}
        onCancel={closeModal}
        width="900px"
        style={{
          top: 30,
          maxHeight: 'calc(100vh - 100px)',
        }}
        footer={[
          <Space key="FooterProps">
            <Button key="back" onClick={closeModal}>
              {translate('TasksCreateModalClient.closeButton')}
            </Button>
            <ClientsFilterModal />
            <Link target="_blank" to="/clientes/novo">
              <Button type="primary" icon={<PlusCircleOutlined />}>
                {translate('ClientsComponent.newCustomerButtonText')}
              </Button>
            </Link>
          </Space>,
        ]}
      >
        <Table
          rowKey={(record) => record.id}
          loading={{
            indicator: <LoadingOutlined />,
            spinning: isFetching,
            size: 'large',
          }}
          pagination={{
            total: clientsTotal,
            showSizeChanger: true,
            pageSizeOptions: ['10', '20', '50', '100', '200'],
            defaultPageSize: 5,
            showTotal: (total, range) => `${range[0]} - ${range[1]} / ${total}`,
            onChange: (page, pageSize) => {
              setPageSize(pageSize!)
              setNumberPage(page)
            },
          }}
          columns={columns}
          dataSource={clients}
          scroll={{ x: 1000 }}
        />
      </Modal>
    </>
  )
}

export default ClientModalTask
