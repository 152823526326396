/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react'

import { Drawer, Form, Button, Col, Row, Input, Tooltip, Switch } from 'antd'
import { CloseOutlined, FilterOutlined } from '@ant-design/icons'

import { Container } from './styles'

import { useListContacts } from '~/hooks/Clients/useListContacts'
import { translate } from '~/utils/locale'

const ContactsFilter: React.FC = () => {
  const { handleUpdateFilters, handleResetFilters, filterOptions } =
    useListContacts()

  const [open, setOpen] = useState(false)

  const [form] = Form.useForm()

  useEffect(() => {
    form.setFieldsValue({
      contact: filterOptions?.contact,
      office: filterOptions?.office,
      is_responsible: filterOptions?.is_responsible,
    })
  }, [filterOptions, form])

  const showDrawer = () => {
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
  }

  const onFinish = (data: any) => {
    handleUpdateFilters(data)
  }

  const onReset = () => {
    handleResetFilters()
    form.resetFields()
  }

  const isFilter = Object.keys(filterOptions).length !== 0

  return (
    <Container isFilter={isFilter}>
      <Button
        type="primary"
        onClick={showDrawer}
        icon={
          !isFilter ? (
            <FilterOutlined />
          ) : (
            <Tooltip
              title={translate('ClientsContactsTabFilter.filterTooltip')}
            >
              <CloseOutlined onClick={onReset} />
            </Tooltip>
          )
        }
        id="btn_filterContact"
      >
        {isFilter
          ? translate('ClientsContactsTabFilter.filterApplied')
          : translate('ClientsContactsTabFilter.filterText')}
      </Button>
      <Drawer
        forceRender
        title={translate('ClientsContactsTabFilter.drawerTitle')}
        width={580}
        onClose={onClose}
        open={open}
        style={{ paddingBottom: 80 }}
      >
        <Form layout="vertical" onFinish={onFinish} form={form}>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="contact"
                label={translate('ClientsContactsTabFilter.contactLabel')}
              >
                <Input
                  placeholder={translate(
                    'ClientsContactsTabFilter.contactPlaceholder',
                  )}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="office"
                label={translate('ClientsContactsTabFilter.officeLabel')}
              >
                <Input
                  placeholder={translate(
                    'ClientsContactsTabFilter.officePlaceholder',
                  )}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="is_responsible"
                label={translate('ClientsContactsTabFilter.responsibleLabel')}
                style={{ width: '100%' }}
                valuePropName="checked"
              >
                <Switch
                  checkedChildren={translate(
                    'ClientsContactsTabFilter.responsibleActive',
                  )}
                  unCheckedChildren={translate(
                    'ClientsContactsTabFilter.responsibleInactive',
                  )}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item style={{ width: '100%' }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: '100%' }}
                >
                  {translate('ClientsContactsTabFilter.applyButtonText')}
                </Button>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item style={{ width: '100%' }}>
                <Button onClick={onReset} style={{ width: '100%' }}>
                  {translate('ClientsContactsTabFilter.cleanButtonText')}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </Container>
  )
}

export default ContactsFilter
