export default {
  pt: {
    TeamsCreate: {
      newButton: 'Nova Equipe',
      modalTitle: 'Nova Equipe',
      keepRegisterText: 'Continuar cadastrando Equipes?',
      closeButton: 'Fechar',
      registerButton: 'Cadastrar',
      titleLabel: 'Nome da Equipe',
      titleRule: 'Preencha o nome da equipe.',
      titlePlaceholder: 'Informe o nome da equipe',
      responsibleLabel: 'Responsável',
      responsibleRule: 'Preencha o nome do responsável.',
      responsiblePlaceholder: 'Informe o responsável',
      descriptionLabel: 'Descrição',
      descriptionPlaceholder: 'Informe a descrição',
    },
  },
  en: {
    TeamsCreate: {
      newButton: 'New Team',
      modalTitle: 'New Team',
      keepRegisterText: 'Continue registering Teams?',
      closeButton: 'Close',
      registerButton: 'Register',
      titleLabel: 'Team Name',
      titleRule: 'Fill in the team name.',
      titlePlaceholder: 'Enter the team name',
      responsibleLabel: 'Responsible',
      responsibleRule: 'Fill in the name of the person in charge.',
      responsiblePlaceholder: 'Inform the person in charge',
      descriptionLabel: 'Description',
      descriptionPlaceholder: 'Enter the description',
    },
  },
  es: {
    TeamsCreate: {
      newButton: 'Nuevo equipo',
      modalTitle: 'Nuevo equipo',
      keepRegisterText: '¿Continuar registrando equipos?',
      closeButton: 'Cerrar',
      registerButton: 'Registrar',
      titleLabel: 'Nombre del equipo',
      titleRule: 'Completa el nombre del equipo.',
      titlePlaceholder: 'Introduce el nombre del equipo',
      responsibleLabel: 'Responsable',
      responsibleRule: 'Complete el nombre de la persona a cargo.',
      responsiblePlaceholder: 'Informar al responsable',
      descriptionLabel: 'Descripción',
      descriptionPlaceholder: 'Introduce la descripción',
    },
  },
}
