export default {
  pt: {
    useSkills: {
      getErrorMessage: 'Erro ao visualizar habilidades.',
      deleteSuccessMessage: 'Habilidade removida com sucesso!',
      deleteErrorMessage: 'Erro ao excluir habilidades.',
    },
  },
  en: {
    useSkills: {
      getErrorMessage: 'Error viewing skills.',
      deleteSuccessMessage: 'Skill successfully removed!',
      deleteErrorMessage: 'Error deleting skills.',
    },
  },
  es: {
    useSkills: {
      getErrorMessage: 'Habilidades de visualización de errores.',
      deleteSuccessMessage: '¡Habilidad eliminada con éxito!',
      deleteErrorMessage: 'Error al eliminar habilidades.',
    },
  },
}
