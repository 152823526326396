/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useCallback } from 'react'
import { useParams } from 'react-router-dom'

import { Modal, Form, Button, Rate, message } from 'antd'
import { EditOutlined } from '@ant-design/icons'

import api from '~/services/api'

import { Container } from './styles'

import { useListContributors } from '~/hooks/Contributors/useListContributors'
import { useContributor } from '~/hooks/Contributors/useContributor'
import { useCompanies } from '~/hooks/Companies/useCompanies'
import { useLocale } from '~/hooks/locale/useLocale'

import { ShowError } from '~/utils/errors/apiErrors'
import { translate } from '~/utils/locale'

interface InternalEvaluationData {
  internal_evaluation: number
}

const InternalEvaluationModal: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)

  const { contributorAditionalInfo, contributorRefetch } = useContributor()
  const { listContributorsRefetch } = useListContributors()
  const { contributorId } = useParams<{ contributorId: string }>()
  const { selectedCompany } = useCompanies()
  const { locale } = useLocale()

  const [form] = Form.useForm()

  const handleOk = () => {
    form.submit()
  }

  const handleCancel = useCallback(() => {
    form.resetFields()
    setIsVisible(false)
  }, [form])

  const onUpdate = useCallback(
    async (data: InternalEvaluationData) => {
      try {
        setConfirmLoading(true)
        await api.put(
          `/company/${selectedCompany}/collaborator/${contributorId}`,
          data,
        )
        contributorRefetch()
        listContributorsRefetch()
        message.success(translate('EvaluationInternalModal.successMessage'))
        setConfirmLoading(false)
        setIsVisible(false)
      } catch (err: any) {
        ShowError(
          err.message,
          translate('EvaluationInternalModal.errorMessage'),
          locale,
        )

        setConfirmLoading(false)
      }
    },
    [
      contributorId,
      selectedCompany,
      contributorRefetch,
      listContributorsRefetch,
      locale,
    ],
  )

  const showModal = () => {
    setIsVisible(true)
  }

  return (
    <Container>
      <Button
        id="btn-edit-evaluation"
        type="primary"
        onClick={showModal}
        icon={<EditOutlined />}
      >
        {translate('EvaluationInternalModal.editButton')}
      </Button>
      <Modal
        title={translate('EvaluationInternalModal.modalTitle')}
        open={isVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={confirmLoading}
        style={{ top: 40 }}
      >
        <Form
          form={form}
          onFinish={onUpdate}
          initialValues={contributorAditionalInfo}
        >
          <Form.Item
            label={translate('EvaluationInternalModal.internalEvaluationLabel')}
            name="internal_evaluation"
            labelCol={{ span: 24 }}
            style={{
              width: '100%',
            }}
          >
            <Rate allowHalf style={{ color: '#1E8AFF' }} />
          </Form.Item>
        </Form>
      </Modal>
    </Container>
  )
}

export default InternalEvaluationModal
