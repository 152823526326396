/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-underscore-dangle */
import React from 'react'

import { Empty, Table, Tag } from 'antd'

import { ColumnsType } from 'antd/lib/table'

import Text from 'antd/lib/typography/Text'
import { LoadingOutlined } from '@ant-design/icons'

import moment from 'moment'
import { Wrapper } from './styles'

import { useReportKilometers } from '~/hooks/Report/Kilometers/useReportKilometers'
import { useListContributors } from '~/hooks/Contributors/useListContributors'
import { useTeams } from '~/hooks/Teams/useListTeams'
import { LoadingIndicator } from '~/pages/Scale/EditScale/styles'
import { translate } from '~/utils/locale'

const ReportDataKilometers: React.FC = () => {
  const { dataReport, isFetching, isLoading } = useReportKilometers()
  const { data } = useListContributors()
  const { teams } = useTeams()

  if (isLoading || !dataReport) {
    return (
      <LoadingIndicator>
        <LoadingOutlined style={{ fontSize: 64, margin: 'auto' }} spin />
      </LoadingIndicator>
    )
  }

  const reportKmFormatted = dataReport?.map((dataKm: any) => {
    const dataFormat = {
      ...dataKm,
      dateKm: moment(dataKm.date).format('DD/MM/YYYY'),
    }

    return dataFormat
  })

  const columns: ColumnsType<any> = [
    {
      title: translate('ReportKilometersData.taskColumn'),
      key: 'taskType',
      dataIndex: 'internal_code',
    },
    {
      title: translate('ReportKilometersData.responsibleColumn'),
      key: 'responsible',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (record) => (
        <>
          {record.responsible?.type === 'collaborator' ? (
            <>
              {data?.map((d) => (
                <div key={d.id}>
                  {d.id === record.responsible.id ? (
                    <Text>{d.contact.name}</Text>
                  ) : (
                    ''
                  )}
                </div>
              ))}
            </>
          ) : (
            <>
              {teams?.map((t) => (
                <div key={t.id}>
                  {t.id === record.responsible?.id ? (
                    <Text>{t.title}</Text>
                  ) : (
                    ''
                  )}
                </div>
              ))}
            </>
          )}
        </>
      ),
    },
    {
      title: translate('ReportKilometersData.checkinColumn'),
      key: 'check-in',
      render: (record) => (
        <>
          {record.checkin_type === 'manual' ? (
            <Tag color="blue">
              {translate('ReportKilometersData.manualTag')}
            </Tag>
          ) : (
            ''
          )}
          {record.checkin_type === 'automatic' ? (
            <Tag color="orange">
              {translate('ReportKilometersData.automaticTag')}
            </Tag>
          ) : (
            ''
          )}
          {record.checkin_type === 'collaborator-pattern' ? (
            <Tag color="purple">
              {translate('ReportKilometersData.contributorTag')}
            </Tag>
          ) : (
            ''
          )}
        </>
      ),
    },
    {
      title: translate('ReportKilometersData.valueInformedColumn'),
      key: 'taskType',
      render: (record) => (
        <Text>
          {record.kmTraveled} Km - R$ {record.valueKmTraveled}{' '}
        </Text>
      ),
    },
  ]

  return (
    <Wrapper>
      {reportKmFormatted.length === 0 ? (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      ) : (
        <>
          {reportKmFormatted?.map((date, index) => (
            <div key={index}>
              <h3>
                {translate('ReportKilometersData.tableTitle')} {date.dateKm}
              </h3>
              <Table
                rowKey={(record) => record._id}
                loading={{
                  indicator: <LoadingOutlined />,
                  spinning: isFetching,
                  size: 'large',
                }}
                columns={columns}
                dataSource={date.tasks}
                scroll={{ x: 1000 }}
              />
            </div>
          ))}
        </>
      )}
    </Wrapper>
  )
}

export default ReportDataKilometers
