import { LoadingOutlined } from '@ant-design/icons'
import { Card, Space } from 'antd'
import Text from 'antd/lib/typography/Text'
import React from 'react'
import { useListScales } from '~/hooks/Scales/useListScales'
import { translate } from '~/utils/locale'
import NewScaleModal from './NewScaleModal'
import ScalesFilter from './ScalesFilter'
import ScalesTable from './ScalesTable'

const ScalesBoard: React.FC = () => {
  const { isLoading, isFetching, scalesData, scalesTotal } = useListScales()

  return (
    <Card
      title={
        <Space size="large">
          <b>{translate('ScalesComponent.cardTitle')}</b>
          {isLoading ? (
            <LoadingOutlined spin />
          ) : (
            <Text
              type="secondary"
              style={{ fontWeight: 'normal', fontSize: 14 }}
            >
              {scalesTotal !== undefined ? (
                <>
                  {scalesTotal === 1
                    ? `${translate(
                        'ScalesComponent.totalText',
                      )} ${scalesTotal} ${translate(
                        'ScalesComponent.scaleText',
                      )}`
                    : `${translate(
                        'ScalesComponent.totalText',
                      )} ${scalesTotal} ${translate(
                        'ScalesComponent.scalesText',
                      )}`}
                </>
              ) : (
                ''
              )}
            </Text>
          )}
          {!isLoading && isFetching && <LoadingOutlined spin />}
        </Space>
      }
      extra={
        <Space size="middle">
          <ScalesFilter />
          <NewScaleModal />
        </Space>
      }
      bordered={false}
      style={{ width: '100%', padding: 30 }}
    >
      <ScalesTable data={scalesData} loading={isLoading} />
    </Card>
  )
}

export default ScalesBoard
