export default {
  pt: {
    SegmentsUpdate: {
      editTooltip: 'Editar',
      modalTitle: 'Editar Segmento',
      closeButton: 'Fechar',
      saveButton: 'Salvar',
      titleLabel: 'Título',
      titleRule: 'Por favor informe o título do segmento.',
      descriptionLabel: 'Descrição',
      descriptionRule: 'Por favor informe a descrição do segmento.',
    },
  },
  en: {
    SegmentsUpdate: {
      editTooltip: 'Edit',
      modalTitle: 'Edit Segment',
      closeButton: 'Close',
      saveButton: 'Save',
      titleLabel: 'Title',
      titleRule: 'Please enter the segment title.',
      descriptionLabel: 'Description',
      descriptionRule: 'Please enter the segment description.',
    },
  },
  es: {
    SegmentsUpdate: {
      editTooltip: 'Editar',
      modalTitle: 'Editar Segmento',
      closeButton: 'Cerrar',
      saveButton: 'Guardar',
      titleLabel: 'Título',
      titleRule: 'Introduzca el título del segmento.',
      descriptionLabel: 'Descripción',
      descriptionRule: 'Ingrese la descripción del segmento.',
    },
  },
}
