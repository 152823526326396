import React from 'react'

interface MoneyDisplayProps {
  value?: number
}

const CurrencyFormatter: React.FC<MoneyDisplayProps> = ({ value }) => {
  const valueInReal = (value! / 100).toFixed(2)

  const formattedValue = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(parseFloat(valueInReal))

  return <span>{formattedValue}</span>
}

export default CurrencyFormatter
