export default {
  pt: {
    FCCalendarHeader: {
      janText: 'Jan',
      febText: 'Fev',
      marText: 'Mar',
      aprText: 'Abr',
      mayText: 'Mai',
      junText: 'Jun',
      julText: 'Jul',
      augText: 'Ago',
      sepText: 'Set',
      octText: 'Out',
      novText: 'Nov',
      decText: 'Dez',
      dayText: 'dia',
      monthText: 'mês',
      weekText: 'semana',
      prevText: 'Retroceder',
      nextText: 'Avançar',
      backToTodayButton: 'Voltar para hoje',
      dailyOption: 'Diária',
      weeklyOption: 'Semanal',
      monthlyOption: 'Mensal',
      weeklyWithHoursOption: 'Semanal com Horas',
    },
  },
  en: {
    FCCalendarHeader: {
      janText: 'Jan',
      febText: 'Feb',
      marText: 'Mar',
      aprText: 'Apr',
      mayText: 'May',
      junText: 'Jun',
      julText: 'Jul',
      augText: 'Aug',
      sepText: 'Sep',
      octText: 'Oct',
      novText: 'Nov',
      decText: 'Dec',
      dayText: 'day',
      monthText: 'mmonth',
      weekText: 'week',
      prevText: 'Previous',
      nextText: 'Next',
      backToTodayButton: 'Back to Today',
      dailyOption: 'Daily',
      weeklyOption: 'Weekly',
      monthlyOption: 'Monthly',
      weeklyWithHoursOption: 'Weekly with Hours',
    },
  },
  es: {
    FCCalendarHeader: {
      janText: 'Ene',
      febText: 'Feb',
      marText: 'Mar',
      aprText: 'Abr',
      mayText: 'May',
      junText: 'Jun',
      julText: 'Jul',
      augText: 'Ago',
      sepText: 'Set',
      octText: 'Oct',
      novText: 'Nov',
      decText: 'Dic',
      dayText: 'día',
      monthText: 'mes',
      weekText: 'semana',
      prevText: 'Anterior',
      nextText: 'Adelante',
      backToTodayButton: 'Volver a hoy',
      dailyOption: 'Diaria',
      weeklyOption: 'Semanal',
      monthlyOption: 'Mensual',
      weeklyWithHoursOption: 'Semanal con Horas',
    },
  },
}
