import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import { Modal, Button, Form, Input, Row, Col, Tooltip, Space } from 'antd'

import React, { useState } from 'react'

import { useSpecifications } from '~/hooks/Equipments/Especification/useSpecifications'
import { translate } from '~/utils/locale'
import { ContainerButton } from '../../styles'

// import { Container } from './styles';

interface SpecificationData {
  attribute: string
  value: string
}

const CreateSpecification: React.FC = () => {
  const { createSpecification } = useSpecifications()

  const [isModalVisible, setIsModalVisible] = useState(false)

  function onCreateSpecification(data: SpecificationData) {
    try {
      createSpecification(data)
      setIsModalVisible(false)
    } catch (err) {
      console.log(err)
    }
  }

  const showModal = () => {
    setIsModalVisible(true)
  }

  const closeModal = () => {
    setIsModalVisible(false)
  }

  return (
    <>
      <Button type="primary" onClick={showModal}>
        {translate('EquipmentsTabsSpecificationCreate.newButton')}
      </Button>
      <Modal
        title={translate('EquipmentsTabsSpecificationCreate.modalTitle')}
        open={isModalVisible}
        onOk={closeModal}
        onCancel={closeModal}
        width="800px"
        style={{
          top: 40,
          overflowY: 'scroll',
          maxHeight: 'calc(100vh - 200px)',
        }}
        footer={[
          <Space key="buttons">
            <Button key="back" onClick={closeModal}>
              {translate('EquipmentsTabsSpecificationCreate.closeButton')}
            </Button>
            <ContainerButton>
              <Button
                key="save"
                type="primary"
                htmlType="submit"
                form="formCreateSpecification"
                id="btn_create_specification_equipment"
              >
                {translate('EquipmentsTabsSpecificationCreate.saveButton')}
              </Button>
            </ContainerButton>
          </Space>,
        ]}
      >
        <Form
          autoComplete="off"
          layout="vertical"
          onFinish={onCreateSpecification}
          id="formCreateSpecification"
        >
          <Row>
            <Col span={12}>
              <Form.Item
                label={translate(
                  'EquipmentsTabsSpecificationCreate.specificationNameLabel',
                )}
                name="name"
                rules={[
                  {
                    required: true,
                    message: translate(
                      'EquipmentsTabsSpecificationCreate.specificationNameRule',
                    ),
                  },
                ]}
              >
                <Input
                  placeholder={translate(
                    'EquipmentsTabsSpecificationCreate.specificationNamePlaceholder',
                  )}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.List name="specifications">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Row
                    key={key}
                    gutter={12}
                    style={{ display: 'flex' }}
                    align="middle"
                  >
                    <Tooltip
                      title={translate(
                        'EquipmentsTabsSpecificationCreate.deleteTooltip',
                      )}
                    >
                      <Button
                        ghost
                        shape="circle"
                        icon={<DeleteOutlined />}
                        onClick={() => remove(name)}
                        size="small"
                        danger
                      />
                    </Tooltip>
                    <Col span={11}>
                      <Form.Item
                        {...restField}
                        label={translate(
                          'EquipmentsTabsSpecificationCreate.attributeLabel',
                        )}
                        name={[name, 'attribute']}
                        rules={[
                          {
                            required: true,
                            message: translate(
                              'EquipmentsTabsSpecificationCreate.attributeRule',
                            ),
                          },
                        ]}
                      >
                        <Input
                          placeholder={translate(
                            'EquipmentsTabsSpecificationCreate.attributePlaceholder',
                          )}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={11}>
                      <Form.Item
                        {...restField}
                        label={translate(
                          'EquipmentsTabsSpecificationCreate.valueLabel',
                        )}
                        name={[name, 'value']}
                        rules={[
                          {
                            required: true,
                            message: translate(
                              'EquipmentsTabsSpecificationCreate.valueRule',
                            ),
                          },
                        ]}
                      >
                        <Input
                          placeholder={translate(
                            'EquipmentsTabsSpecificationCreate.valuePlaceholder',
                          )}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    {translate('EquipmentsTabsSpecificationCreate.addButton')}
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form>
      </Modal>
    </>
  )
}

export default CreateSpecification
