/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState, useEffect } from 'react'

import {
  Drawer,
  Form,
  Button,
  Col,
  Row,
  Input,
  Switch,
  DatePicker,
  Tooltip,
  Select,
} from 'antd'
import { CloseOutlined, FilterOutlined } from '@ant-design/icons'

import dayjs from 'dayjs'
import { Container } from './styles'
import { useListEquipments } from '~/hooks/Equipments/ListEquipmentsHooks'
import { useListContributors } from '~/hooks/Contributors/useListContributors'
import { useListClients } from '~/hooks/Clients/useListClients'
import { translate } from '~/utils/locale'

const EquipmentsFilter: React.FC = () => {
  const {
    handleUpdateFilters,
    handleResetFilters,
    filterOptions,
    listEquipments,
  } = useListEquipments()
  const [visible, setVisible] = useState(false)
  const { data } = useListContributors()
  const { clients } = useListClients()

  const [form] = Form.useForm()

  useEffect(() => {
    form.setFieldsValue({
      equipments_id: filterOptions?.equipments_id,
      collaborator_id: filterOptions?.collaborator_id,
      customer_id: filterOptions?.customer_id,
      category: filterOptions?.category,
      is_enabled: filterOptions?.is_enabled,
      fromExpirationDate: dayjs(filterOptions?.fromExpirationDate),
      toExpirationDate: dayjs(filterOptions?.toExpirationDate),
    })
  }, [filterOptions, form])

  const onFinish = (dataFilter: any) => {
    handleUpdateFilters(dataFilter)
    setVisible(false)
  }

  const onReset = () => {
    handleResetFilters()
    form.resetFields()
    setVisible(false)
  }

  const showDrawer = () => {
    setVisible(true)
  }

  const onClose = () => {
    setVisible(false)
  }

  const isFilter = Object.keys(filterOptions).length !== 0

  return (
    <Container isFilter={isFilter}>
      <Button
        type="primary"
        loading={!listEquipments || !data || !clients}
        onClick={showDrawer}
        icon={
          !isFilter ? (
            <FilterOutlined />
          ) : (
            <Tooltip title={translate('EquipmentsFilter.cleanFilter')}>
              <CloseOutlined onClick={onReset} />
            </Tooltip>
          )
        }
        id="btn_filter_equipments"
      >
        {isFilter
          ? translate('EquipmentsFilter.filterAppliedText')
          : translate('EquipmentsFilter.filterText')}
      </Button>
      <Drawer
        forceRender
        title={translate('EquipmentsFilter.drawerTitle')}
        width={580}
        onClose={onClose}
        open={visible}
        style={{ paddingBottom: 80 }}
      >
        <Form layout="vertical" onFinish={onFinish} form={form}>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="equipments_id"
                label={translate('EquipmentsFilter.equipmentLabel')}
              >
                <Select
                  mode="multiple"
                  placeholder={translate(
                    'EquipmentsFilter.equipmentPlaceholder',
                  )}
                >
                  {listEquipments?.map((equipment) => (
                    <Select.Option value={equipment.id} key={equipment.id}>
                      {equipment.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="category"
                label={translate('EquipmentsFilter.categoryLabel')}
              >
                <Input
                  placeholder={translate(
                    'EquipmentsFilter.categoryPlaceholder',
                  )}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="collaborator_id"
                label={translate('EquipmentsFilter.contributorLabel')}
              >
                <Select
                  mode="multiple"
                  placeholder={translate(
                    'EquipmentsFilter.contributorPlaceholder',
                  )}
                >
                  {data?.map((contribuitor) => (
                    <Select.Option
                      value={contribuitor.id}
                      key={contribuitor.id}
                    >
                      {contribuitor.contact.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="collaborator_id"
                label={translate('EquipmentsFilter.clientLabel')}
              >
                <Select
                  mode="multiple"
                  placeholder={translate('EquipmentsFilter.clientPlaceholder')}
                >
                  {clients?.map((client) => (
                    <Select.Option value={client.id} key={client.id}>
                      {client.contact.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="toExpirationDate"
                label={translate('EquipmentsFilter.creationDateLabel')}
                style={{ width: '100%' }}
              >
                <DatePicker format="DD/MM/YYYY" style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="fromExpirationDate"
                label={translate('EquipmentsFilter.expirationDateLabel')}
                style={{ width: '100%' }}
              >
                <DatePicker format="DD/MM/YYYY" style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="is_enabled"
                label={translate('EquipmentsFilter.statusLabel')}
                valuePropName="checked"
              >
                <Switch
                  checkedChildren={translate('EquipmentsFilter.statusActive')}
                  unCheckedChildren={translate(
                    'EquipmentsFilter.statusInactive',
                  )}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item style={{ width: '100%' }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: '100%' }}
                >
                  {translate('EquipmentsFilter.applyButton')}
                </Button>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item style={{ width: '100%' }}>
                <Button onClick={onReset} style={{ width: '100%' }}>
                  {translate('EquipmentsFilter.cleanButton')}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </Container>
  )
}

export default EquipmentsFilter
