/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  CarOutlined,
  ClockCircleOutlined,
  DownloadOutlined,
  LoadingOutlined,
} from '@ant-design/icons'
import { Button, Card, Space, Table, Typography } from 'antd'
import React from 'react'
import { useListParentClients } from '~/hooks/Clients/useListParentCustomer'

import ExcelJS from 'exceljs'
import { saveAs } from 'file-saver'
import CurrencyFormatter from '../../Misc/CurrencyFormater'
import { translate } from '~/utils/locale'

interface Tasks {
  _id?: string
  internal_code?: string
  financial?: {
    numberTasks?: number
    task_value?: number
    amount_hours?: number
    amount_hours_additional?: number
    number_km?: number
    number_km_additional?: number
  }
  totalValue?: number
}

interface DataType {
  _id: string
  fantasyName: string
  totalTasks?: number
  totalValueTasks?: number
  totalHourExtra?: number
  totalValueHourExtra?: number
  totalKmExtra?: number
  totalValueKmExtra?: number
  total?: number
  totalValues?: number
  arrayOfCounts?: Tasks[]
  colors?: string
}

const expandedRowRender = (record: DataType) => {
  const detailsColumns = [
    {
      title: translate('FinancialReceive.subCodeTaskColumn'),
      dataIndex: 'internal_code',
      key: 'internal_code',
    },
    {
      title: translate('FinancialReceive.subTasksColumn'),
      dataIndex: 'numberTasks',
      key: 'numberTasks',
    },
    {
      title: translate('FinancialReceive.subValueTasksColumn'),
      dataIndex: 'task_value',
      key: 'task_value',
    },
    {
      title: translate('FinancialReceive.subHoursColumn'),
      dataIndex: 'hourExtra',
      key: 'hourExtra',
    },
    {
      title: translate('FinancialReceive.subValueHoursColumn'),
      dataIndex: 'valueHourExtra',
      key: 'valueHourExtra',
    },
    {
      title: translate('FinancialReceive.subKmColumn'),
      dataIndex: 'kmExtra',
      key: 'kmExtra',
    },
    {
      title: translate('FinancialReceive.subValueKmColumn'),
      dataIndex: 'valueKmExtra',
      key: 'valueKmExtra',
    },
    {
      title: translate('FinancialReceive.subTotalColumn'),
      dataIndex: 'totalValue',
      key: 'totalValue',
    },
  ]

  const expandedData = record?.arrayOfCounts?.map((item, index) => ({
    key: index.toString(),
    internal_code: item.internal_code,
    numberTasks: 1,
    task_value: <CurrencyFormatter value={item?.financial!.task_value} />,
    hourExtra: (
      <Space>
        <ClockCircleOutlined />
        <Typography.Text>{item?.financial!.amount_hours}:00</Typography.Text>
      </Space>
    ),
    valueHourExtra: (
      <CurrencyFormatter value={item?.financial!.amount_hours_additional} />
    ),
    kmExtra: (
      <Space>
        <CarOutlined />
        {item?.financial!.number_km}
      </Space>
    ),
    valueKmExtra: (
      <CurrencyFormatter value={item?.financial!.number_km_additional} />
    ),
    totalValue: <CurrencyFormatter value={item!.totalValue} />,
  }))

  return (
    <Table
      columns={detailsColumns}
      dataSource={expandedData}
      pagination={false}
      rowKey="key"
      size="small"
      style={{ color: 'blue' }}
    />
  )
}

const FinancialCustomers: React.FC = () => {
  const { parentClients, isFetching } = useListParentClients()

  const columns = [
    {
      title: translate('FinancialReceive.clientColumn'),
      dataIndex: 'fantasyName',
      key: 'fantasyName',
    },
    {
      title: translate('FinancialReceive.totalTasksColumn'),
      dataIndex: 'totalTasks',
      key: 'totalTasks',
    },
    {
      title: translate('FinancialReceive.totalValueTasksColumn'),
      dataIndex: 'totalValueTasks',
      key: 'totalValueTasks',
      render: (totalValueTasks: number) => (
        <Typography.Text>
          <CurrencyFormatter value={totalValueTasks} />
        </Typography.Text>
      ),
    },
    {
      title: translate('FinancialReceive.totalHourColumn'),
      dataIndex: 'totalHourExtra',
      key: 'totalHourExtra',
      render: (totalHourExtra: number) => (
        <Typography.Text>
          <ClockCircleOutlined /> {totalHourExtra}:00
        </Typography.Text>
      ),
    },
    {
      title: translate('FinancialReceive.totalValueHourColumn'),
      dataIndex: 'totalValueHourExtra',
      render: (totalValueHourExtra: number) => (
        <Typography.Text>
          <CurrencyFormatter value={totalValueHourExtra} />
        </Typography.Text>
      ),
    },
    {
      title: translate('FinancialReceive.totalKmColumn'),
      dataIndex: 'totalKmExtra',
      key: 'totalKmExtra',
      render: (totalKmExtra: number) => (
        <Typography.Text>
          <CarOutlined /> {totalKmExtra} km
        </Typography.Text>
      ),
    },
    {
      title: translate('FinancialReceive.totalValueKmColumn'),
      dataIndex: 'totalValueKmExtra',
      render: (totalValueKmExtra: number) => (
        <Typography.Text>
          <CurrencyFormatter value={totalValueKmExtra} />
        </Typography.Text>
      ),
    },
    {
      title: translate('FinancialReceive.totalColumn'),
      dataIndex: 'total',
      render: (total: number) => (
        <Typography.Text>
          <CurrencyFormatter value={total} />
        </Typography.Text>
      ),
    },
  ]

  function generateColors() {
    const brilhoMinimo = 200

    const r = brilhoMinimo + Math.floor(Math.random() * (256 - brilhoMinimo))
    const g = brilhoMinimo + Math.floor(Math.random() * (256 - brilhoMinimo))
    const b = brilhoMinimo + Math.floor(Math.random() * (256 - brilhoMinimo))

    const cor = `${((1 << 24) | (r << 16) | (g << 8) | b)
      .toString(16)
      .slice(1)
      .toUpperCase()}`

    return cor
  }

  const newData = parentClients?.map((parent) => {
    const totalTasks = parent?.tasks?.length

    const totalValueTasks = parent?.tasks?.reduce(
      (acc, item) => acc + item?.financial?.task_value!,
      0,
    )

    const totalHourExtra = parent?.tasks?.reduce(
      (acc, item) => acc + item?.financial?.amount_hours!,
      0,
    )

    const totalValueHourExtra = parent?.tasks?.reduce(
      (acc, item) => acc + item?.financial?.amount_hours_additional!,
      0,
    )

    const totalKmExtra = parent?.tasks?.reduce(
      (acc, item) => acc + item?.financial?.number_km!,
      0,
    )

    const totalValueKmExtra = parent?.tasks?.reduce(
      (acc, item) => acc + item?.financial?.number_km_additional!,
      0,
    )

    const sumSpecificFields = (obj: any) => {
      return (
        (obj?.financial.task_value || 0) +
        (obj?.financial.amount_hours_additional || 0) +
        (obj?.financial.number_km_additional || 0)
      )
    }

    const arrayOfCounts = parent?.tasks?.map((obj) => {
      const sum = sumSpecificFields(obj)
      return { ...obj, totalValue: sum }
    })

    const total = arrayOfCounts?.reduce((acc, obj) => {
      return acc + obj.totalValue
    }, 0)

    return {
      ...parent,
      arrayOfCounts,
      totalTasks,
      totalValueTasks,
      totalHourExtra,
      totalValueHourExtra,
      totalKmExtra,
      totalValueKmExtra,
      total,
      color: generateColors(),
    }
  })

  const total = newData?.reduce((sum, item) => sum + item.total, 0)

  const generateExcel = async () => {
    const workbook = new ExcelJS.Workbook()
    const worksheet = workbook.addWorksheet('My Sheet')

    const formatCurrency = (value: number) => {
      return new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }).format(value)
    }

    worksheet.mergeCells('A2:H1')
    const titleRow = worksheet.getCell('A1')
    titleRow.value = 'Valores a receber do cliente principal'
    titleRow.font = {
      name: 'Arial',
      size: 14,
      bold: true,
      color: { argb: 'FFFFFFFF' },
    }
    titleRow.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '242424' },
    }
    titleRow.alignment = { vertical: 'middle', horizontal: 'center' }

    worksheet.mergeCells('A3:H4')
    const subtitleRow = worksheet.getCell('A3')
    subtitleRow.value = `Total Geral ${formatCurrency(total! / 100)}`
    subtitleRow.font = { name: 'Arial', size: 12, color: { argb: 'FFFFFFFF' } }
    subtitleRow.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '242424' },
    }
    subtitleRow.alignment = { vertical: 'middle', horizontal: 'center' }

    worksheet.addRow([])
    worksheet.addRow([])

    worksheet.getRow(6).values = [
      'Cliente Principal',
      'Tarefas',
      'Valor Tarefa',
      'Horas Extras',
      'Valor Horas Extras',
      'Km Extra',
      'Valor Kms Extras',
      'Total',
    ]

    worksheet.columns = [
      { key: 'client', width: 20 },
      { key: 'numberTasks', width: 20 },
      { key: 'valueTasks', width: 20 },
      { key: 'hourExtra', width: 20 },
      { key: 'valueHourExtra', width: 20 },
      { key: 'kmExtra', width: 20 },
      { key: 'valueKmExtra', width: 20 },
      { key: 'totalValue', width: 20 },
    ]

    const columnHeaderRow = worksheet.getRow(6)
    columnHeaderRow.eachCell((cell) => {
      cell.font = { name: 'Arial', bold: true, color: { argb: 'FFFFFFFF' } }
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '3969B7' },
      }
      cell.alignment = { vertical: 'middle', horizontal: 'center' }
    })

    newData?.forEach((group) => {
      const groupColor = group.color

      const categoryRow = worksheet.addRow({
        client: group.fantasyName,
        numberTasks: group.totalTasks,
        valueTasks: `${formatCurrency(group.totalValueTasks / 100)}`,
        hourExtra: group.totalHourExtra,
        valueHourExtra: `${formatCurrency(group.totalValueHourExtra / 100)}`,
        kmExtra: group.totalKmExtra,
        valueKmExtra: `${formatCurrency(group.totalValueKmExtra / 100)}`,
        totalValue: `${formatCurrency(group.total / 100)}`,
      })

      categoryRow.eachCell((cell) => {
        cell.font = { name: 'Arial', bold: true }
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: groupColor },
        }
      })

      group.arrayOfCounts!.forEach((item) => {
        const row = worksheet.addRow({
          client: item.internal_code,
          numberTasks: 1,
          valueTasks: formatCurrency(item.financial.task_value! / 100),
          hourExtra: item.financial.amount_hours,
          valueHourExtra: formatCurrency(
            item.financial.amount_hours_additional! / 100,
          ),
          kmExtra: item.financial.number_km,
          valueKmExtra: formatCurrency(
            item.financial.number_km_additional! / 100,
          ),
          totalValue: formatCurrency(item.totalValue! / 100),
          ...item.financial,
        })

        row.eachCell((cell) => {
          cell.font = { name: 'Arial', size: 10 }
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: groupColor },
          }
        })
      })

      worksheet.addRow({})
    })

    worksheet.eachRow((row) => {
      row.eachCell((cell) => {
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        }
        cell.alignment = { vertical: 'middle', horizontal: 'center' } // Centralizando o texto
      })
    })

    // worksheet.views = [{ state: 'frozen', ySplit: 1 }]

    const buffer = await workbook.xlsx.writeBuffer()
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })
    saveAs(blob, 'Planilha.xlsx')
  }

  return (
    <Card
      title={
        <Space>
          <b>{translate('FinancialReceive.cardTitle')}</b>

          {total ? (
            <b style={{ color: '#3969B7' }}>
              <CurrencyFormatter value={total} />{' '}
              {translate('FinancialReceive.totalText')}
            </b>
          ) : (
            ''
          )}
        </Space>
      }
      extra={[
        <Space key="buttons">
          <Button
            type="primary"
            icon={<DownloadOutlined />}
            onClick={generateExcel}
          >
            {translate('FinancialReceive.exportButton')}
          </Button>
        </Space>,
      ]}
      bordered={false}
      style={{ width: '100%', padding: 30 }}
    >
      <Table
        columns={columns}
        dataSource={newData}
        expandedRowRender={expandedRowRender}
        rowKey="_id"
        size="small"
        loading={{
          indicator: <LoadingOutlined />,
          spinning: isFetching,
          size: 'large',
        }}
      />
    </Card>
  )
}

export default FinancialCustomers
