export default {
  pt: {
    ReportTasksGraphics: {
      totalLabel: 'Total de Tarefas',
      executionLabel: 'Tarefas Abertas',
      finishedLabel: 'Tarefas Pendentes',
      canceledLabel: 'Tarefas Fechadas',
    },
  },
  en: {
    ReportTasksGraphics: {
      totalLabel: 'Total Tasks',
      executionLabel: 'Open Tasks',
      finishedLabel: 'Pending Tasks',
      canceledLabel: 'Closed Tasks',
    },
  },
  es: {
    ReportTasksGraphics: {
      totalLabel: 'Tareas Totales',
      executionLabel: 'Tareas Abiertas',
      finishedLabel: 'Tareas Pendientes',
      canceledLabel: 'Tareas Cerradas',
    },
  },
}
