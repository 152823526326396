import React from 'react'
import { Helmet } from 'react-helmet'

import { ListClientsProvider } from '~/hooks/Clients/useListClients'
import { ListContributorsProvider } from '~/hooks/Contributors/useListContributors'
import { ListEquipmentsProvider } from '~/hooks/Equipments/ListEquipmentsHooks'
import { ReportKilometersProvider } from '~/hooks/Report/Kilometers/useReportKilometers'
import { TeamsProvider } from '~/hooks/Teams/useListTeams'
import ReportKilometersComponents from '~/components/DataManipulation/Report/Kilometers'
import { translate } from '~/utils/locale'

// import { Container } from './styles';

const ReportKilometers: React.FC = () => {
  return (
    <ListContributorsProvider>
      <Helmet>
        <title>{translate('Kilometers.helmetTitle')}</title>
      </Helmet>
      <TeamsProvider>
        <ListClientsProvider>
          <ListEquipmentsProvider>
            <ReportKilometersProvider>
              <ReportKilometersComponents />
            </ReportKilometersProvider>
          </ListEquipmentsProvider>
        </ListClientsProvider>
      </TeamsProvider>
    </ListContributorsProvider>
  )
}

export default ReportKilometers
