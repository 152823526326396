export default {
  pt: {
    ScalesClientsForm: {
      cardTitle: 'Clientes',
    },
  },
  en: {
    ScalesClientsForm: {
      cardTitle: 'Customers',
    },
  },
  es: {
    ScalesClientsForm: {
      cardTitle: 'Clientes',
    },
  },
}
