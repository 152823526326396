export default {
  pt: {
    StatusCreate: {
      addStatusButton: 'Adicionar Status',
      modalTitle: 'Novo Status',
      keepRegisterText: 'Continuar cadastrando status?',
      closeButton: 'Fechar',
      registerButton: 'Cadastrar',
      titleLabel: 'Título',
      titleRule: 'Por favor informe o título do status.',
      descriptionLabel: 'Descrição',
      colorLabel: 'Cor do status',
    },
  },
  en: {
    StatusCreate: {
      addStatusButton: 'Add Status',
      modalTitle: 'New Status',
      keepRegisterText: 'Continue registering status?',
      closeButton: 'Close',
      registerButton: 'Register',
      titleLabel: 'Title',
      titleRule: 'Please enter the status title.',
      descriptionLabel: 'Description',
      colorLabel: 'Status color',
    },
  },
  es: {
    StatusCreate: {
      addStatusButton: 'Agregar Estado',
      modalTitle: 'Nuevo Estado',
      keepRegisterText: '¿Continuar registrando estado?',
      closeButton: 'Cerrar',
      registerButton: 'Registrar',
      titleLabel: 'Título',
      titleRule: 'Ingrese el título del estado.',
      descriptionLabel: 'Descripción',
      colorLabel: 'Color de estado',
    },
  },
}
