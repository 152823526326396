export default {
  pt: {
    ClientsContactsFilter: {
      cleanFilterTooltip: 'Limpar Filtro',
      filterAppliedText: 'Filtro Aplicado',
      filterText: 'Filtros',
      drawerTitle: 'Filtros de Contatos do Cliente',
      contactLabel: 'Informações do contato:',
      contactTooltipParagraphOne: 'Você pode filtrar buscando por',
      contactTooltipParagraphTwo: 'nome, email e telefone do contato.',
      contactPlaceholder: 'Insira o nome do contato',
      officeLabel: 'Cargo:',
      officePlaceholder: 'Insira o cargo',
      responsibleLabel: 'Responsáveis: ',
      responsibleActive: 'Ativo',
      responsibleInactive: 'Inativo',
      buttonApply: 'Aplicar',
      buttonClean: 'Limpar',
    },
  },
  en: {
    ClientsContactsFilter: {
      cleanFilterTooltip: 'Clear filter',
      filterAppliedText: 'Filter Applied',
      filterText: 'Filters',
      drawerTitle: 'Customer Contact Filters',
      contactLabel: 'Contact information:',
      contactTooltipParagraphOne: 'You can filter by looking for',
      contactTooltipParagraphTwo: 'contact name, email and phone number.',
      contactPlaceholder: 'Enter contact name',
      officeLabel: 'Office:',
      officePlaceholder: 'Enter the office',
      responsibleLabel: 'Responsible: ',
      responsibleActive: 'Active',
      responsibleInactive: 'Inactive',
      buttonApply: 'Apply',
      buttonClean: 'Clean',
    },
  },
  es: {
    ClientsContactsFilter: {
      cleanFilterTooltip: 'Filtro Claro',
      filterAppliedText: 'Filtro Aplicado',
      filterText: 'Filtros',
      drawerTitle: 'Filtros de contacto del cliente',
      contactLabel: 'Información del contacto:',
      contactTooltipParagraphOne: 'Puedes filtrar buscando',
      contactTooltipParagraphTwo:
        'nombre de contacto, correo electrónico y número de teléfono.',
      contactPlaceholder: 'Introduce el nombre del contacto',
      officeLabel: 'Oficina:',
      officePlaceholder: 'Ingrese la posición',
      responsibleLabel: 'responsable: ',
      responsibleActive: 'Activo',
      responsibleInactive: 'Inactivo',
      buttonApply: 'Aplicar',
      buttonClean: 'Limpio',
    },
  },
}
