export default {
  pt: {
    useContributor: {
      getErrorMessage: 'Erro ao conseguir informações do colaborador.',
      updateSuccessMessage: 'Status atualizado com sucesso!',
      updateErrorMessage: 'Erro ao atualizar status.',
    },
  },
  en: {
    useContributor: {
      getErrorMessage: 'Error getting employee information.',
      updateSuccessMessage: 'Status updated successfully!',
      updateErrorMessage: 'Error updating status.',
    },
  },
  es: {
    useContributor: {
      getErrorMessage: 'Error al obtener la información del empleado.',
      updateSuccessMessage: '¡Estado actualizado con éxito!',
      updateErrorMessage: 'Error al actualizar el estado.',
    },
  },
}
