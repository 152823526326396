/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-underscore-dangle */
import { CloseOutlined, FilterOutlined } from '@ant-design/icons'
import { Col, Drawer, Row, Form, Button, Tooltip, Select } from 'antd'
import React, { useState, useEffect } from 'react'
import { useListContributors } from '~/hooks/Contributors/useListContributors'
import { useListGroup } from '~/hooks/Groups/useListGroup'
import { translate } from '~/utils/locale'

import { Container } from './styles'

const FilterGroups: React.FC = () => {
  const { data } = useListContributors()
  const { filterOptions, handleResetFilters, handleUpdateFilters } =
    useListGroup()

  const [form] = Form.useForm()

  const [visible, setVisible] = useState(false)

  useEffect(() => {
    form.setFieldsValue({
      contributors_id: filterOptions?.contributors_id,
      type: filterOptions?.type,
    })
  }, [filterOptions, form])

  const onFinish = (dataForm: any) => {
    handleUpdateFilters(dataForm)
    setVisible(false)
  }

  const onReset = () => {
    handleResetFilters()
    form.resetFields()
    setVisible(false)
  }

  const showDrawer = () => {
    setVisible(true)
  }

  const onClose = () => {
    setVisible(false)
  }

  const isFilter = Object.keys(filterOptions).length !== 0

  return (
    <Container isFilter={isFilter}>
      <Button
        type="primary"
        onClick={showDrawer}
        loading={!data}
        icon={
          !isFilter ? (
            <FilterOutlined />
          ) : (
            <Tooltip title={translate('GroupsFilter.buttonTooltip')}>
              <CloseOutlined onClick={onReset} />
            </Tooltip>
          )
        }
        id="btn_filter_tasks"
      >
        {isFilter
          ? translate('GroupsFilter.filterAppliedText')
          : translate('GroupsFilter.filterText')}
      </Button>
      <Drawer
        forceRender
        title={translate('GroupsFilter.drawerTitle')}
        width={580}
        onClose={onClose}
        open={visible}
        style={{ paddingBottom: 80 }}
      >
        <Form layout="vertical" form={form} onFinish={onFinish}>
          <Form.Item
            label={translate('GroupsFilter.contributorLabel')}
            name="contributors_id"
          >
            <Select
              placeholder={translate('GroupsFilter.contributorPlaceholder')}
              mode="multiple"
            >
              {data?.map((c) => (
                <Select.Option value={c.id} key={c.id}>
                  {c.contact.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label={translate('GroupsFilter.groupTypeLabel')}
            name="type"
          >
            <Select
              placeholder={translate('GroupsFilter.groupTypePlaceholder')}
            >
              <Select.Option value="admin">
                {translate('GroupsFilter.groupTypeAdmin')}
              </Select.Option>
              <Select.Option value="user">
                {translate('GroupsFilter.groupTypeUser')}
              </Select.Option>
              <Select.Option value="manager">
                {translate('GroupsFilter.groupTypeManager')}
              </Select.Option>
              <Select.Option value="custom">
                {translate('GroupsFilter.groupTypeCustom')}
              </Select.Option>
            </Select>
          </Form.Item>

          <Row gutter={8}>
            <Col span={12}>
              <Form.Item style={{ width: '100%' }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: '100%' }}
                >
                  {translate('GroupsFilter.applyButton')}
                </Button>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item style={{ width: '100%' }}>
                <Button onClick={onReset} style={{ width: '100%' }}>
                  {translate('GroupsFilter.cleanButton')}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </Container>
  )
}

export default FilterGroups
