import React, { useContext, createContext } from 'react'
import { useQuery } from 'react-query'

import { message } from 'antd'

import api from '~/services/api'

import { useCompanies } from '~/hooks/Companies/useCompanies'
import { useLocale } from '~/hooks/locale/useLocale'

import { ShowError } from '~/utils/errors/apiErrors'
import { translate } from '~/utils/locale'
import { useParams } from 'react-router-dom'

interface IUpdateForm {
  title: string
  description?: string
}

interface ICreateQuestion {
  type: string
  options?: string[] | null
}

interface IUpdateQuestion {
  title?: string
  description?: string | null
  order?: number
  options?: string[] | null
  is_required?: boolean
}

export interface IQuestion {
  id: string
  title: string
  description: string | null
  type: 'text' | 'multipleChoice' | 'checkbox' | 'dropdownList'
  order: number
  options: string[] | null
  is_required: boolean
  created_at: Date
  updated_at: Date
}

export interface FormProps {
  id: string
  title: string
  description?: string
  company_id: string
  questions: Array<IQuestion>
  created_at: Date
  updated_at: Date
}

interface FormContextData {
  form?: FormProps
  updateForm: (data: IUpdateForm) => void
  createQuestion: (data: ICreateQuestion) => Promise<void>
  updateQuestion: (questionId: string, data: IUpdateQuestion) => Promise<void>
  moveQuestion: (questionId: string, direction: 'up' | 'down') => Promise<void>
  deleteQuestion: (questionId: string) => Promise<void>
  isLoading: boolean
  isFetching: boolean
  refetch: () => void
}

const FormContext = createContext<FormContextData>({} as FormContextData)

export const TaskFormProvider: React.FC = ({ children }) => {
  const { selectedCompany } = useCompanies()
  const { locale } = useLocale()
  const { formId } = useParams<{ formId: string }>()

  const {
    data: form,
    isLoading,
    refetch,
    isFetching,
  } = useQuery(
    [`form${formId}`, selectedCompany, formId],
    async () => {
      if (!selectedCompany || !formId) return
      const data = await api
        .get<FormProps>(`/company/${selectedCompany}/forms/${formId}`)
        .then((response) => response.data)
        .catch((err) => {
          ShowError(
            err.message,
            translate('useTaskForm.getErrorMessage'),
            locale,
          )
          return undefined
        })
      return data
    },
    {
      refetchOnWindowFocus: false,
    },
  )

  async function updateForm(data: IUpdateForm) {
    if (!form) return
    await api
      .put(`/company/${selectedCompany}/forms/${form.id}`, { ...data })
      .then(() => {
        message.success(translate('useTaskForms.updateSuccessMessage'))
      })
      .catch((err) =>
        ShowError(
          err.message,
          translate('useTaskForms.updateErrorMessage'),
          locale,
        ),
      )
    refetch()
  }

  async function createQuestion(data: ICreateQuestion) {
    if (!form) return

    await api
      .post(`/company/${selectedCompany}/forms/${form.id}/questions`, {
        ...data,
        title: `Pergunta ${form.questions.length + 1}`,
        order: form.questions.length,
      })
      .then(() => {
        message.success(translate('useTaskForms.updateSuccessMessage'))
      })
      .catch((err) =>
        ShowError(
          err.message,
          translate('useTaskForms.updateErrorMessage'),
          locale,
        ),
      )
    refetch()
  }
  async function updateQuestion(questionId: string, data: IUpdateQuestion) {
    if (!form) return
    await api
      .put(
        `/company/${selectedCompany}/forms/${formId}/questions/${questionId}`,
        data,
      )
      .then(() => {
        message.success(translate('useTaskForms.updateSuccessMessage'))
      })
      .catch((err) =>
        ShowError(
          err.message,
          translate('useTaskForms.updateErrorMessage'),
          locale,
        ),
      )
    refetch()
  }

  async function moveQuestion(questionId: string, direction: 'up' | 'down') {
    if (!form) return

    const currentQuestion = form.questions.find(
      (question) => question.id === questionId,
    )
    if (!currentQuestion) return
    const currentIndex = form.questions.findIndex(
      (question) => question.id === questionId,
    )
    if (!currentIndex) return
    let otherQuestion: IQuestion | null = null
    if (direction === 'up' && currentIndex > 0) {
      otherQuestion = form.questions[currentIndex - 1]
    }
    if (direction === 'down' && currentIndex < form.questions.length - 1) {
      otherQuestion = form.questions[currentIndex + 1]
    }
    if (!otherQuestion) return
    try {
      await api.put(
        `/company/${selectedCompany}/forms/${formId}/questions/${currentQuestion.id}`,
        {
          order: otherQuestion.order,
        },
      )
      await api.put(
        `/company/${selectedCompany}/forms/${formId}/questions/${otherQuestion.id}`,
        {
          order: currentQuestion.order,
        },
      )
    } catch (error) {}
    refetch()
  }

  async function deleteQuestion(questionId: string) {
    if (!form) return
    await api
      .delete(
        `/company/${selectedCompany}/forms/${formId}/questions/${questionId}`,
      )
      .then(() => {
        message.success(translate('useTaskForms.updateSuccessMessage'))
      })
      .catch((err) =>
        ShowError(
          err.message,
          translate('useTaskForms.updateErrorMessage'),
          locale,
        ),
      )
    refetch()
  }

  return (
    <FormContext.Provider
      value={{
        form,
        updateForm,
        isLoading,
        isFetching,
        refetch,
        createQuestion,
        updateQuestion,
        moveQuestion,
        deleteQuestion,
      }}
    >
      {children}
    </FormContext.Provider>
  )
}

export function useTaskForm(): FormContextData {
  const context = useContext(FormContext)

  if (!context) {
    throw new Error('useTaskForm must be used within an FormProvider')
  }

  return context
}
