/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-underscore-dangle */
import React from 'react'

import { Tabs } from 'antd'
import { PageHeader } from '@ant-design/pro-components'

import { useHistory, useParams } from 'react-router-dom'

import { useParentCustomer } from '~/hooks/Clients/useParentCustomer'
import { translate } from '~/utils/locale'
import DetailsParentClient from './Details'
import UpdateParentClient from '../Update'
import DetailsFinancialParents from './Financial'
import UpdateParentClientFinancial from './Financial/Update'
import ChildrenClientsTab from './Clients'

const ParentClientsTab: React.FC = () => {
  const { isEdit, isEditFinancal, parentCustomer } = useParentCustomer()

  const { TabPane } = Tabs

  const { parentCustomerId, tab } = useParams<{
    parentCustomerId: string
    tab: string
  }>()

  const history = useHistory()

  const handleTabChange = (key: string) => {
    if (key === 'detalhes') {
      history.replace({
        pathname: `/clientePrincipal/visualizar/${parentCustomerId}`,
      })
    }
  }

  return (
    <>
      <PageHeader
        className="site-page-header"
        onBack={() => window.history.back()}
        title={
          !isEdit
            ? `${translate('ClientParentTabs.pageHeaderTitleVisualization')}`
            : `${translate('ClientParentTabs.pageHeaderTitleUpdate')}`
        }
        subTitle={parentCustomer?.fantasyName}
      />
      <Tabs
        onTabClick={(key: string) => handleTabChange(key)}
        defaultActiveKey={tab ? `${tab}` : 'detalhes'}
        style={{ paddingLeft: 20, paddingRight: 20 }}
      >
        <TabPane tab={translate('ClientParentTabs.detailsTab')} key="detalhes">
          {!isEdit ? <DetailsParentClient /> : <UpdateParentClient />}
        </TabPane>
        <TabPane
          tab={translate('ClientParentTabs.financialTab')}
          key="financeiro"
        >
          {!isEditFinancal ? (
            <DetailsFinancialParents />
          ) : (
            <UpdateParentClientFinancial />
          )}
        </TabPane>
        <TabPane
          tab={translate('ClientParentTabs.childrenClientsTab')}
          key="clientes"
        >
          <ChildrenClientsTab />
        </TabPane>
      </Tabs>
    </>
  )
}

export default ParentClientsTab
