export default {
  pt: {
    GroupsDetailsPermissions: {
      permissionsCardTitle: 'Permissões',
      noPermissions: 'Nenhuma permissão adicionada.',
      contributorsCardTitle: 'Colaboradores',
      contributors: 'Módulo: Colaboradores',
      contributorsAttachments: 'Módulo: Documentos/Anexos de Colaboradores',
      contributorsEvaluations: 'Módulo: Avaliações de Colaboradores',
      contributorsSkills: 'Módulo: Habilidades de Colaboradores',
      contributorsTeams: 'Módulo: Equipe de Colaboradores',
      equipmentsCardTitle: 'Equipamentos',
      equipments: 'Módulo: Equipamentos',
      equipmentsAttachments: 'Módulo: Documentos/Anexos do Equipamento',
      equipmentsSpecifications: 'Módulo: Especificações do Equipamento',
      associatedEquipments: 'Módulo: Equipamentos Associados',
      movements: 'Módulo: Movimentações',
      stocks: 'Módulo: Estoques',
      specificationsTemplates: 'Módulo: Templates de Especificações',
      tasksCardTitle: 'Tarefas',
      tasks: 'Módulo: Tarefas',
      breakReasons: 'Módulo: Motivos de Pausa',
      tasksTypes: 'Módulo: Tipos de Tarefa',
      tasksStatus: 'Módulo: Status da Tarefa',
      tasksEquipments: 'Módulo: Equipamentos da Tarefa',
      tasksReports: 'Módulo: Evidências da Tarefa',
      otherCardTitle: 'Outros',
      users: 'Módulo: Usuários',
      companies: 'Módulo: Empresas',
      contacts: 'Módulo: Contatos',
      addresses: 'Módulo: Endereços',
      contactsAddresses: 'Módulo: Endereços de Contatos',
      reports: 'Módulo: Relatórios',
      customersCardTitle: 'Clientes',
      customers: 'Módulo: Clientes',
      customersSegments: 'Módulo: Segmentos de Clientes',
      customersContacts: 'Módulo: Contatos de Clientes',
      scalesCardTitle: 'Escalas',
      schedules: 'Módulo: Escalas',
      customersSchedules: 'Módulo: Escalas de Clientes',
      contributorsSchedules: 'Módulo: Escalas de Colaboradores',
      scheduleTimes: 'Módulo: Escalas de Horários',
      groupCardTitle: 'Grupos e Equipes',
      groups: 'Módulo: Grupos',
      groupsPermissions: 'Módulo: Permissões de Grupos',
      teams: 'Módulo: Equipes',
    },
  },
  en: {
    GroupsDetailsPermissions: {
      permissionsCardTitle: 'Permissions',
      noPermissions: 'No permissions added.',
      contributorsCardTitle: 'Collaborators',
      contributors: 'Module: Collaborators',
      contributorsAttachments: 'Module: Documents/Attachments of Collaborators',
      contributorsEvaluations: 'Module: Collaborator Reviews',
      contributorsSkills: 'Module: Collaborators Skills',
      contributorsTeams: 'Module: Team of Collaborators',
      equipmentsCardTitle: 'Equipment',
      equipments: 'Module: Equipment',
      equipmentsAttachments: 'Module: Equipment Documents/Attachments',
      equipmentsSpecifications: 'Module: Equipment Specifications',
      associatedEquipments: 'Module: Associated Equipment',
      movements: 'Module: Movements',
      stocks: 'Module: Stocks',
      specificationsTemplates: 'Module: Specification Templates',
      tasksCardTitle: 'Tasks',
      tasks: 'Module: Tasks',
      breakReasons: 'Module: Break Reasons',
      tasksTypes: 'Module: Task Types',
      tasksStatus: 'Module: Task Status',
      tasksEquipments: 'Module: Task Equipment',
      tasksReports: 'Module: Task Reports',
      otherCardTitle: 'Others',
      users: 'Module: Users',
      companies: 'Module: Companies',
      contacts: 'Module: Contacts',
      addresses: 'Module: Adresses',
      contactsAddresses: 'Module: Contact Addresses',
      reports: 'Module: Reports',
      customersCardTitle: 'Customers',
      customers: 'Module: Customers',
      customersSegments: 'Module: Customer Segments',
      customersContacts: 'Module: Customer Contacts',
      scalesCardTitle: 'Scales',
      schedules: 'Module: Scales',
      customersSchedules: 'Module: Customer Scales',
      contributorsSchedules: 'Module: Collaborators Scales',
      scheduleTimes: 'Module: Schedules',
      groupCardTitle: 'Groups and Teams',
      groups: 'Module: Groups',
      groupsPermissions: 'Module: Group Permissions',
      teams: 'Module: Teams',
    },
  },
  es: {
    GroupsDetailsPermissions: {
      permissionsCardTitle: 'Permisos',
      noPermissions: 'No se agregaron permisos.',
      contributorsCardTitle: 'Colaboradores',
      contributors: 'Módulo: Colaboradores',
      contributorsAttachments:
        'Módulo: Documentos/Archivos adjuntos del Colaboradores',
      contributorsEvaluations: 'Módulo: Reseñas de empleados',
      contributorsSkills: 'Módulo: Habilidades de los Colaboradores',
      contributorsTeams: 'Módulo: Equipo de Colaboradores',
      equipmentsCardTitle: 'Equipo',
      equipments: 'Módulo: Equipo',
      equipmentsAttachments: 'Módulo: Documentos de equipo / archivos adjuntos',
      equipmentsSpecifications: 'Módulo: Especificaciones del equipo',
      associatedEquipments: 'Módulo: Equipamiento asociado',
      movements: 'Módulo: Movimientos',
      stocks: 'Módulo: Inventarios',
      specificationsTemplates: 'Módulo: Plantillas de especificaciones',
      tasksCardTitle: 'Tareas',
      tasks: 'Módulo: Tareas',
      breakReasons: 'Módulo: Romper Razones',
      tasksTypes: 'Módulo: Tipos de tareas',
      tasksStatus: 'Módulo: Estado de la tarea',
      tasksEquipments: 'Módulo: Equipo de trabajo',
      tasksReports: 'Módulo: Informes de tareas',
      otherCardTitle: 'Otros',
      users: 'Módulo: Usuarios',
      companies: 'Módulo: Empresas',
      contacts: 'Módulo: Contactos',
      addresses: 'Módulo: Direcciones',
      contactsAddresses: 'Módulo: Direcciones de Contacto',
      reports: 'Módulo: Informes',
      customersCardTitle: 'Clientes',
      customers: 'Módulo: Clientes',
      customersSegments: 'Módulo: Segmentos de Clientes',
      customersContacts: 'Módulo: Contactos de clientes',
      scalesCardTitle: 'Escalas',
      schedules: 'Módulo: Escalas',
      customersSchedules: 'Módulo: Escalas de Clientes',
      contributorsSchedules: 'Módulo: Escalas de Colaboradores',
      scheduleTimes: 'Módulo: Escalas de Horarios',
      groupCardTitle: 'Grupos y Equipos',
      groups: 'Módulo: Grupos',
      groupsPermissions: 'Módulo: Permisos de grupo',
      teams: 'Módulo: Equipos',
    },
  },
}
