import { Form, Input, Flex } from 'antd'
import React from 'react'
import { IQuestion } from '~/hooks/Tasks/Forms/useTaskForm'

interface QuestionProps {
  data: IQuestion
  isEditing: boolean
}

const QuestionText: React.FC<QuestionProps> = ({ data, isEditing }) => {
  return (
    <Flex vertical>
      {isEditing ? (
        <Form.Item
          name={'title'}
          style={{ width: '100%' }}
          rules={[{ required: true, message: 'Campo obrigatório' }]}
        >
          <Input.TextArea placeholder="Pergunta" size="large" />
        </Form.Item>
      ) : (
        <h2>{data.title}</h2>
      )}

      {isEditing ? (
        <Form.Item name={'description'}>
          <Input.TextArea placeholder="Descrição" disabled={!isEditing} />
        </Form.Item>
      ) : (
        data.description && <h4>{data.description}</h4>
      )}
    </Flex>
  )
}

export default QuestionText
