/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/order */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/no-duplicates */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-bitwise */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-underscore-dangle */
import { LoadingOutlined, EditOutlined } from '@ant-design/icons'
import { Button, Card, Col, Popconfirm, Row, Space, Tag, Tooltip } from 'antd'
import Text from 'antd/lib/typography/Text'

import React from 'react'
import {
  FiCreditCard,
  FiFile,
  FiFileText,
  FiMail,
  FiPhone,
  FiTarget,
  FiUser,
} from 'react-icons/fi'

import { translate } from '~/utils/locale'
import { Container } from './styles'

import { useParentCustomer } from '~/hooks/Clients/useParentCustomer'
import { useListParentClients } from '~/hooks/Clients/useListParentCustomer'
import NumberFormat from 'react-number-format'

const DetailsParentClient: React.FC = () => {
  const { parentCustomer, isLoading, setIsEdit } = useParentCustomer()

  const { deleteParentClient } = useListParentClients()

  if (isLoading || !parentCustomer) {
    return <LoadingOutlined style={{ fontSize: 64, margin: 'auto' }} spin />
  }

  return (
    <Container>
      <Card
        bordered={false}
        title={parentCustomer.companyName}
        extra={
          <Space>
            <Popconfirm
              title={translate('ClientsPrincipalTabsDetails.deletePopConfirm')}
              onConfirm={() => deleteParentClient(parentCustomer._id)}
            >
              <Tooltip
                title={translate('ClientsPrincipalTabsDetails.deleteTooltip')}
              >
                <Button type="primary" danger>
                  {translate('ClientsPrincipalTabsDetails.deleteButton')}
                </Button>
              </Tooltip>
            </Popconfirm>
            <Button
              type="primary"
              icon={<EditOutlined />}
              onClick={() => setIsEdit(true)}
              id="btn-edit-parent-customers"
            >
              {translate('ClientsPrincipalTabsDetails.editButton')}
            </Button>
          </Space>
        }
        style={{
          display: 'inline-block',
          width: '100%',
        }}
      >
        <>
          <Row gutter={12}>
            <Col span={12}>
              <div className="text">
                <div>
                  <FiTarget />
                  <Text strong>
                    {translate('ClientsPrincipalTabsDetails.reasonText')}
                  </Text>
                </div>
                <Text>{parentCustomer.companyName}</Text>
              </div>
            </Col>
            <Col span={12}>
              <div className="text">
                <div>
                  <FiTarget />
                  <Text strong>
                    {translate('ClientsPrincipalTabsDetails.fantasyText')}
                  </Text>
                </div>
                <Text>{parentCustomer.fantasyName}</Text>
              </div>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={12}>
              <div className="text">
                <div>
                  <FiCreditCard />
                  <Text strong>
                    {translate('ClientsPrincipalTabsDetails.cnpjText')}
                  </Text>
                </div>
                {parentCustomer.cnpj ? (
                  <Text>
                    <NumberFormat
                      value={parentCustomer.cnpj}
                      displayType="text"
                      format="##.###.###/####-##"
                    />
                  </Text>
                ) : (
                  <Text>
                    {translate('ClientsPrincipalTabsDetails.cnpjUndefined')}
                  </Text>
                )}
              </div>
            </Col>
            <Col span={12}>
              <div className="text">
                <div>
                  <FiCreditCard />
                  <Text strong>
                    {translate('ClientsPrincipalTabsDetails.cpfText')}
                  </Text>
                </div>
                {parentCustomer.cpf ? (
                  <Text>
                    <NumberFormat
                      value={parentCustomer.cpf}
                      displayType="text"
                      format="###.###.###-##"
                    />
                  </Text>
                ) : (
                  <Text>
                    {translate('ClientsPrincipalTabsDetails.cpfUndefined')}
                  </Text>
                )}
              </div>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={12}>
              <div className="text">
                <div>
                  <FiFileText />
                  <Text strong>
                    {translate(
                      'ClientsPrincipalTabsDetails.registrationMunicipalText',
                    )}
                  </Text>
                </div>
                {parentCustomer.municipal_registration ? (
                  <Text>{parentCustomer.municipal_registration}</Text>
                ) : (
                  <Text>
                    {translate(
                      'ClientsPrincipalTabsDetails.registrationMunicipalUndefined',
                    )}
                  </Text>
                )}
              </div>
            </Col>
            <Col span={12}>
              <div className="text">
                <div>
                  <FiFileText />
                  <Text strong>
                    {translate(
                      'ClientsPrincipalTabsDetails.registrationStateText',
                    )}
                  </Text>
                </div>
                {parentCustomer.state_registration ? (
                  <Text>{parentCustomer.state_registration}</Text>
                ) : (
                  <Text>
                    {translate(
                      'ClientsPrincipalTabsDetails.registrationStateUndefined',
                    )}
                  </Text>
                )}
              </div>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={12}>
              <div className="text">
                <div>
                  <FiUser />
                  <Text strong>
                    {translate('ClientsPrincipalTabsDetails.nameText')}
                  </Text>
                </div>
                {parentCustomer.contact.name ? (
                  <Text>{parentCustomer.contact.name}</Text>
                ) : (
                  <Text>{translate('TasksTabsDetails.noValueText')}</Text>
                )}
              </div>
            </Col>
            <Col span={12}>
              <div className="text">
                <div>
                  <FiMail />
                  <Text strong>
                    {translate('ClientsPrincipalTabsDetails.emailText')}
                  </Text>
                </div>
                {parentCustomer.contact.email ? (
                  <Text>{parentCustomer.contact.email}</Text>
                ) : (
                  <Text>{translate('TasksTabsDetails.noValueText')}</Text>
                )}
              </div>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={12}>
              <div className="text">
                <div>
                  <FiPhone />
                  <Text strong>
                    {translate('ClientsPrincipalTabsDetails.phoneText')}
                  </Text>
                </div>
                {parentCustomer.contact.phone ? (
                  <Text>
                    <NumberFormat
                      value={parentCustomer.contact.phone}
                      displayType="text"
                      format="(##) #####-####"
                    />
                  </Text>
                ) : (
                  <Text>
                    {translate('ClientsPrincipalTabsDetails.phoneUndefined')}
                  </Text>
                )}
              </div>
            </Col>
          </Row>
        </>
      </Card>
      <Card
        bordered={false}
        title={translate('ClientsPrincipalTabsDetails.addressCardTitle')}
        style={{
          display: 'inline-block',
          width: '100%',
          marginTop: 15,
        }}
      >
        <Row gutter={12}>
          <Col span={3}>
            <div className="text">
              <div>
                <FiMail />
                <Text strong>
                  {translate('ClientsPrincipalTabsDetails.zipcodeText')}
                </Text>
              </div>
              <Text>{parentCustomer.address.zip_code}</Text>
            </div>
          </Col>
          <Col span={14}>
            <div className="text">
              <div>
                <FiFile />
                <Text strong>
                  {translate('ClientsPrincipalTabsDetails.addressText')}
                </Text>
              </div>
              <Text>
                {parentCustomer.address.street}, {parentCustomer.address.number}{' '}
                - {parentCustomer.address.neighborhood}
              </Text>
            </div>
          </Col>
          <Col>
            <div className="text">
              <div>
                <FiFile />
                <Text strong>
                  {translate('ClientsPrincipalTabsDetails.cityText')}
                </Text>
              </div>
              <Text>
                {parentCustomer.address.city} - {parentCustomer.address.state}
              </Text>
            </div>
          </Col>
        </Row>

        {/* {!isLoading && (
          <MapContainer
            center={
              task.address.location.x && task.address.location.x
                ? [task.address.location.x, task.address.location.y]
                : [0, 0]
            }
            zoom={13}
            scrollWheelZoom={false}
            style={{ height: '250px', width: '100%' }}
          >
            <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {task.address.location.x &&
              task.address.location.y &&
              task.address.location.x !== 0 &&
              task.address.location.y !== 0 && (
                <Marker
                  position={[task.address.location.x, task.address.location.y]}
                />
              )}
          </MapContainer>
        )} */}
      </Card>
    </Container>
  )
}

export default DetailsParentClient
