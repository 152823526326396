/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/jsx-one-expression-per-line */
import { CheckOutlined, LoadingOutlined } from '@ant-design/icons'
import { Modal, Button, Table, Space, Popconfirm, Tooltip, message } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import Text from 'antd/lib/typography/Text'

import React, { useState } from 'react'
import { useListTemplate } from '~/hooks/Equipments/Especification/ListTemplate'
import { useSpecifications } from '~/hooks/Equipments/Especification/useSpecifications'
import { translate } from '~/utils/locale'

import { Container } from './styles'

interface SpecificationProps {
  attribute: string
  value: string
}

interface ListTemplateProps {
  id: string
  name: string
  specifications: SpecificationProps[]
}

const ImportTemplate: React.FC = () => {
  const [isModalVisible, setIsModalVisible] = useState(false)

  const { listTemplate, isFetching } = useListTemplate()
  const { createSpecification } = useSpecifications()

  function handleTemplate(templateName: string, specifications: any) {
    const importTemplateData = {
      name: templateName,
      specifications,
    }

    createSpecification(importTemplateData)
    setIsModalVisible(false)
    message.success(
      translate('EquipmentsTabsSpecificationImportTemplate.successMessage'),
    )
  }

  const columns: ColumnsType<ListTemplateProps> = [
    {
      title: translate(
        'EquipmentsTabsSpecificationImportTemplate.templateColumn',
      ),
      align: 'left',
      key: 'name',
      dataIndex: 'name',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: translate(
        'EquipmentsTabsSpecificationImportTemplate.attributesColumn',
      ),
      key: 'specification',
      render: (record) => (
        <>
          {record.specifications.length === 1 ? (
            <Text>
              {record.specifications.length}{' '}
              {translate(
                'EquipmentsTabsSpecificationImportTemplate.attributeText',
              )}
            </Text>
          ) : (
            <Text>
              {record.specifications.length}{' '}
              {translate(
                'EquipmentsTabsSpecificationImportTemplate.attributesText',
              )}
            </Text>
          )}
        </>
      ),
    },
    {
      title: translate(
        'EquipmentsTabsSpecificationImportTemplate.actionsColumn',
      ),
      align: 'center',
      key: 'remove',
      render: (record) => (
        <Space size="middle">
          <Popconfirm
            title={`${translate(
              'EquipmentsTabsSpecificationImportTemplate.importPopconfirm',
            )} ${record.name}?`}
            onConfirm={() => handleTemplate(record.name, record.specifications)}
          >
            <Tooltip
              title={translate(
                'EquipmentsTabsSpecificationImportTemplate.importTooltip',
              )}
            >
              <Button type="primary" shape="circle" icon={<CheckOutlined />} />
            </Tooltip>
          </Popconfirm>
        </Space>
      ),
    },
  ]

  const showModal = () => {
    setIsModalVisible(true)
  }

  const closeModal = () => {
    setIsModalVisible(false)
  }

  return (
    <Container>
      <Button type="primary" onClick={showModal} id="btn_import_template">
        {translate('EquipmentsTabsSpecificationImportTemplate.importButton')}
      </Button>
      <Modal
        title={translate(
          'EquipmentsTabsSpecificationImportTemplate.modalTitle',
        )}
        open={isModalVisible}
        onOk={closeModal}
        onCancel={closeModal}
        width="800px"
        style={{
          top: 40,
          overflowY: 'scroll',
          maxHeight: 'calc(100vh - 200px)',
        }}
        footer={[
          <Button key="back" onClick={closeModal}>
            {translate('EquipmentsTabsSpecificationImportTemplate.closeButton')}
          </Button>,
        ]}
      >
        <Table
          rowKey={(record) => record.id}
          loading={{
            indicator: <LoadingOutlined />,
            spinning: isFetching,
            size: 'large',
          }}
          columns={columns}
          dataSource={listTemplate}
        />
      </Modal>
    </Container>
  )
}

export default ImportTemplate
