/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Col,
  DatePicker,
  DatePickerProps,
  Form,
  Input,
  Row,
  Select,
  Space,
  Tooltip,
  Tour,
  Typography,
} from 'antd'
// import { MaskedInput } from 'antd-mask-input'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { CheckCircleFilled, EditFilled } from '@ant-design/icons'
import HelpTooltip from '~/components/DataManipulation/Misc/HelpTooltip'
import { useTaskCreate } from '~/hooks/Tasks/Create/createTask'
import { translate } from '~/utils/locale'
import ClientModalTask from '../Modal/Client'

import TaskTypeModalTask from '../Modal/TaskType'
import { useTaskTour } from '~/hooks/Tour/useTaskTour'
import { useTour } from '~/hooks/Tour/useTour'

interface DetailsCreateTaskProps {
  form: any
}

interface TimeInputProps {
  value?: string
  placeholder?: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const TimeInput: React.FC<TimeInputProps> = ({
  value,
  onChange,
  placeholder,
}) => {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value

    const numericValue = inputValue.replace(/\D/g, '')

    let formattedValue = numericValue
    if (numericValue.length >= 5) {
      formattedValue = `${numericValue.slice(0, 2)}:${numericValue.slice(
        2,
        4,
      )}:${numericValue.slice(4, 6)}`
    } else if (numericValue.length >= 3) {
      formattedValue = `${numericValue.slice(0, 2)}:${numericValue.slice(
        2,
        4,
      )}${numericValue.length > 4 ? `:${numericValue.slice(4, 6)}` : ''}`
    } else if (numericValue.length >= 1) {
      formattedValue = `${numericValue.slice(0, 2)}${
        numericValue.length > 2 ? `:${numericValue.slice(2, 4)}` : ''
      }${numericValue.length > 4 ? `:${numericValue.slice(4, 6)}` : ''}`
    }

    if (onChange) {
      e.target.value = formattedValue
      onChange(e)
    }
  }

  return (
    <Input
      value={value}
      onChange={handleInputChange}
      placeholder={placeholder}
      maxLength={8}
    />
  )
}

const DetailsCreateTask: React.FC<DetailsCreateTaskProps> = ({ form }) => {
  const { setDateTask, clientData, scaleId, taskTypeData, setDescription } =
    useTaskCreate()

  const {
    createClientTaskRef,
    createDateTaskRef,
    createTaskTypeTaskRef,
    createDurationTaskRef,
    createPriorityTaskRef,
    createTaskSteps,
    openCreateTask,
    setOpenCreateTask,
  } = useTaskTour()

  const [isEdit, setIsEdit] = useState(false)

  const [time, setTime] = useState<string>('')

  const handleTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTime(e.target.value)
  }

  const { setIsTour } = useTour()

  function onClose() {
    setIsTour(false)
    setOpenCreateTask(false)
  }

  function getValueNoteInput(value: string) {
    localStorage.setItem('@Gstor:noteTask', value)
  }

  const note = localStorage.getItem('@Gstor:noteTask')

  const { Option } = Select

  function handleChangeEditButton() {
    if (isEdit) {
      setIsEdit(false)
    } else {
      setIsEdit(true)
    }
  }

  const onChange: DatePickerProps['onChange'] = (date) => {
    setDateTask(dayjs(date).format('YYYY-MM-DDTHH:mm:ss.018Z'))
  }

  useEffect(() => {
    if (clientData?.id) {
      form.setFieldsValue({ customer_id: clientData?.name })
    }

    if (taskTypeData?.id) {
      form.setFieldsValue({
        task_type_id: taskTypeData?.title,
        duration_time: taskTypeData?.duration,
        description: taskTypeData?.description,
      })
    }

    if (clientData?.id) {
      form.setFieldsValue({
        value: clientData?.valueTask
          ? clientData?.valueTask
          : taskTypeData?.valueTask,
        amount_hours: clientData.amountHours,
        amount_hours_additional: clientData.amountHoursAdditional,
        number_km: clientData?.numberKm,
        number_km_additional: clientData?.numberKmAdditional,
      })
    }
  }, [form, clientData, scaleId, taskTypeData])

  return (
    <>
      <Row gutter={14}>
        <Col span={12} ref={openCreateTask ? createClientTaskRef : null}>
          <Form.Item
            label={
              <Space>
                <p>{translate('TasksCreateDetails.clientLabel')}</p>
                {clientData?.id ? <ClientModalTask /> : ''}
              </Space>
            }
            name="customer_id"
            rules={[
              {
                required: true,
                message: translate('TasksCreateDetails.clientRule'),
              },
            ]}
          >
            {clientData?.id ? <Input /> : <ClientModalTask />}
          </Form.Item>
        </Col>

        <Col span={12} ref={createDateTaskRef}>
          <Form.Item
            label={
              <Space>
                {translate('TasksCreateDetails.dateLabel')}
                <HelpTooltip
                  title={translate('TasksCreateDetails.dateHelpTooltip')}
                />
              </Space>
            }
            name="initialDate"
            rules={[
              {
                required: true,
                message: translate('TasksCreateDetails.dateRule'),
              },
            ]}
          >
            <DatePicker
              onChange={onChange}
              format="DD/MM/YYYY HH:mm"
              placeholder={translate('TasksFilter.fromDatePlaceholder')}
              style={{ width: '100%' }}
              showTime={{ format: 'HH:mm' }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={14}>
        <Col span={12} ref={createTaskTypeTaskRef}>
          <Form.Item
            label={
              <Space>
                <p>{translate('TasksCreateDetails.taskTypeLabel')}</p>
                {taskTypeData?.id ? <TaskTypeModalTask /> : ''}
              </Space>
            }
            name="task_type_id"
            rules={[
              {
                required: true,
                message: translate('TasksCreateDetails.taskTypeRule'),
              },
            ]}
          >
            {taskTypeData?.id ? <Input /> : <TaskTypeModalTask />}
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            label={
              <Space>
                <Typography.Text>
                  {translate('TasksCreateDetails.externalCodeLabel')}
                </Typography.Text>
                <HelpTooltip
                  title={translate('TasksCreateDetails.externalCodeHelp')}
                />
              </Space>
            }
            name="external_code"
          >
            <Input
              placeholder={translate(
                'TasksCreateDetails.externalCodePlaceholder',
              )}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={14}>
        <Col span={12} ref={createDurationTaskRef}>
          <Form.Item
            label={
              <Space>
                {translate('TasksCreateDetails.durationLabel')}
                <HelpTooltip
                  title={translate('TasksCreateDetails.durationHelpTooltip')}
                />
              </Space>
            }
            name="duration_time"
          >
            <TimeInput
              value={time}
              onChange={handleTimeChange}
              placeholder="HH:mm:ss"
            />
          </Form.Item>
        </Col>
        <Col span={12} ref={createPriorityTaskRef}>
          <Form.Item
            label={translate('TasksCreateDetails.priorityLabel')}
            name="priority"
          >
            <Select
              placeholder={translate('TasksCreateDetails.priorityPlaceholder')}
            >
              <Option value="high">
                {translate('TasksCreateDetails.priorityHigh')}
              </Option>
              <Option value="medium">
                {translate('TasksCreateDetails.priorityMedium')}
              </Option>
              <Option value="low">
                {translate('TasksCreateDetails.priorityLow')}
              </Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={14}>
        <Col span={12}>
          <Form.Item
            label={translate('TasksCreateDetails.descriptionLabel')}
            name="description"
          >
            <Input.TextArea
              placeholder={translate(
                'TasksCreateDetails.descriptionPlaceholder',
              )}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={
              <Space>
                <>
                  {isEdit ? (
                    <Input
                      size="small"
                      onChange={(e) => getValueNoteInput(e.target.value)}
                    />
                  ) : (
                    <>
                      {note ? (
                        <>{note}</>
                      ) : (
                        <>{translate('TasksCreateDetails.noteLabel')}</>
                      )}
                    </>
                  )}
                </>
                <Tooltip
                  title={
                    !isEdit ? (
                      <>{translate('TasksCreateDetails.noteAddTooltip')}</>
                    ) : (
                      <>{translate('TasksCreateDetails.noteSaveTooltip')}</>
                    )
                  }
                >
                  {!isEdit ? (
                    <EditFilled
                      style={{ color: '#3C8ACB', cursor: 'pointer' }}
                      onClick={handleChangeEditButton}
                    />
                  ) : (
                    <CheckCircleFilled
                      style={{ color: '#3C8ACB', cursor: 'pointer' }}
                      onClick={handleChangeEditButton}
                    />
                  )}
                </Tooltip>
              </Space>
            }
            name="note"
          >
            <Input.TextArea
              placeholder={translate('TasksCreateDetails.notePlaceholder')}
            />
          </Form.Item>
        </Col>
      </Row>

      <Tour
        open={openCreateTask}
        onClose={onClose}
        steps={createTaskSteps}
        indicatorsRender={(current, total) => (
          <span>
            {current + 1} / {total}
          </span>
        )}
      />
    </>
  )
}

export default DetailsCreateTask
