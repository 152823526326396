/* eslint-disable camelcase */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-curly-newline */
import {
  LoadingOutlined,
  PlusCircleOutlined,
  PlusOutlined,
  ReloadOutlined,
} from '@ant-design/icons'
import { Button, List, Modal, Popconfirm, Tooltip } from 'antd'
import React, { useState } from 'react'
import { useTaskCreate } from '~/hooks/Tasks/Create/createTask'
import { useScaleTask } from '~/hooks/Tasks/Create/scaleTask'
import { translate } from '~/utils/locale'

// import { Container } from './styles';

const TeamsCreateTask: React.FC = () => {
  const { scaleData, refetch, isFetching } = useScaleTask()
  const { teamData, setTeamData } = useTaskCreate()

  const [isModalVisible, setIsModalVisible] = useState(false)

  async function handleTeamTask(
    id: string,
    name: string,
    amount_to_receive: number,
  ) {
    await setTeamData({ id, name, amount_to_receive })
    setIsModalVisible(false)

    refetch()
  }

  const showModal = () => {
    setIsModalVisible(true)
  }

  const closeModal = () => {
    setIsModalVisible(false)
  }

  return (
    <>
      {!teamData?.id ? (
        <Button
          icon={<PlusCircleOutlined />}
          onClick={showModal}
          style={{ width: '100%' }}
        >
          {translate('TasksCreateModalTeams.addButton')}
        </Button>
      ) : (
        <Button size="small" icon={<ReloadOutlined />} onClick={showModal}>
          {translate('TasksCreateModalTeams.changeButton')}
        </Button>
      )}
      <Modal
        title={translate('TasksCreateModalTeams.modalTitle')}
        open={isModalVisible}
        onOk={closeModal}
        onCancel={closeModal}
        width="900px"
        style={{
          top: 30,
          overflowY: 'scroll',
          maxHeight: 'calc(100vh - 200px)',
        }}
      >
        <>
          <h4>
            {translate('TasksCreateModalTeams.rangeText')}{' '}
            {scaleData?.operating_range} KM
          </h4>
          <List
            size="small"
            header={<div>{translate('TasksCreateModalTeams.teamHeader')}</div>}
            bordered
            dataSource={scaleData?.teamsSchedules}
            loading={{
              indicator: <LoadingOutlined />,
              spinning: isFetching,
              size: 'large',
            }}
            renderItem={(item) => (
              <List.Item
                actions={[
                  <Popconfirm
                    key="ActionsProps"
                    title={translate('TasksCreateModalTeams.addPopconfirm')}
                    onConfirm={() =>
                      handleTeamTask(
                        item.team.id,
                        item.team.title,
                        item.team.amount_to_receive,
                      )
                    }
                  >
                    <Tooltip
                      title={translate('TasksCreateModalTeams.addTooltip')}
                    >
                      <Button
                        type="primary"
                        shape="circle"
                        icon={<PlusOutlined />}
                      />
                    </Tooltip>
                  </Popconfirm>,
                ]}
              >
                {item.team.title}
              </List.Item>
            )}
          />
        </>
      </Modal>
    </>
  )
}

export default TeamsCreateTask
