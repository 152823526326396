import React from 'react'
import Financial from '~/components/DataManipulation/Financial'
import { ListParentClientsProvider } from '~/hooks/Clients/useListParentCustomer'
import { ParentCustomerFieldsProvider } from '~/hooks/Clients/useParentCustomerFields'
import { TeamsProvider } from '~/hooks/Teams/useListTeams'

// import { Container } from './styles';

const FinancialPage: React.FC = () => {
  return (
    <ParentCustomerFieldsProvider>
      <ListParentClientsProvider>
        <TeamsProvider>
          <Financial />
        </TeamsProvider>
      </ListParentClientsProvider>
    </ParentCustomerFieldsProvider>
  )
}

export default FinancialPage
