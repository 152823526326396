import React, { useState, useEffect } from 'react'

import { Button, Tooltip } from 'antd'

import { Container } from './styles'
import { translate } from '~/utils/locale'

interface useWeekdaySelectorProps {
  (): {
    daysSelected: {
      Sunday: boolean
      Monday: boolean
      Tuesday: boolean
      Wednesday: boolean
      Thursday: boolean
      Friday: boolean
      Saturday: boolean
    }
    render: React.ReactElement
    setReset: React.Dispatch<React.SetStateAction<boolean>>
  }
}

const useWeekdaySelector: useWeekdaySelectorProps = () => {
  const [Sunday, setSunday] = useState(false)
  const [Monday, setMonday] = useState(false)
  const [Tuesday, setTuesday] = useState(false)
  const [Wednesday, setWednesday] = useState(false)
  const [Thursday, setThursday] = useState(false)
  const [Friday, setFriday] = useState(false)
  const [Saturday, setSaturday] = useState(false)

  const [reset, setReset] = useState(false)

  useEffect(() => {
    if (reset) {
      setSunday(false)
      setMonday(false)
      setTuesday(false)
      setWednesday(false)
      setThursday(false)
      setFriday(false)
      setSaturday(false)
      setReset(false)
    }
  }, [reset])

  return {
    setReset,
    daysSelected: {
      Sunday,
      Monday,
      Tuesday,
      Wednesday,
      Thursday,
      Friday,
      Saturday,
    },
    render: (
      <Container data-testid="WeekdaySelectorContainer">
        <Tooltip title={translate('MiscWeekdaySelector.sundayTooltip')}>
          <Button
            shape="circle"
            type={Sunday ? 'primary' : 'default'}
            onClick={() => setSunday((sundayState) => !sundayState)}
            size="large"
            data-testid="Sunday"
          >
            {translate('MiscWeekdaySelector.sunday')}
          </Button>
        </Tooltip>

        <Tooltip title={translate('MiscWeekdaySelector.mondayTooltip')}>
          <Button
            shape="circle"
            type={Monday ? 'primary' : 'default'}
            onClick={() => setMonday((mondayState) => !mondayState)}
            size="large"
            data-testid="Monday"
          >
            {translate('MiscWeekdaySelector.monday')}
          </Button>
        </Tooltip>

        <Tooltip title={translate('MiscWeekdaySelector.tuesdayTooltip')}>
          <Button
            shape="circle"
            type={Tuesday ? 'primary' : 'default'}
            onClick={() => setTuesday((tuesdayState) => !tuesdayState)}
            size="large"
            data-testid="Tuesday"
          >
            {translate('MiscWeekdaySelector.tuesday')}
          </Button>
        </Tooltip>

        <Tooltip title={translate('MiscWeekdaySelector.wednesdayTooltip')}>
          <Button
            shape="circle"
            type={Wednesday ? 'primary' : 'default'}
            onClick={() => setWednesday((wednesdayState) => !wednesdayState)}
            size="large"
            data-testid="Wednesday"
          >
            {translate('MiscWeekdaySelector.wednesday')}
          </Button>
        </Tooltip>

        <Tooltip title={translate('MiscWeekdaySelector.thursdayTooltip')}>
          <Button
            shape="circle"
            type={Thursday ? 'primary' : 'default'}
            onClick={() => setThursday((thursdayState) => !thursdayState)}
            size="large"
            data-testid="Thursday"
          >
            {translate('MiscWeekdaySelector.thursday')}
          </Button>
        </Tooltip>

        <Tooltip title={translate('MiscWeekdaySelector.fridayTooltip')}>
          <Button
            shape="circle"
            type={Friday ? 'primary' : 'default'}
            onClick={() => setFriday((fridayState) => !fridayState)}
            size="large"
            data-testid="Friday"
          >
            {translate('MiscWeekdaySelector.friday')}
          </Button>
        </Tooltip>

        <Tooltip title={translate('MiscWeekdaySelector.saturdayTooltip')}>
          <Button
            shape="circle"
            type={Saturday ? 'primary' : 'default'}
            onClick={() => setSaturday((saturdayState) => !saturdayState)}
            size="large"
            data-testid="Saturday"
          >
            {translate('MiscWeekdaySelector.saturday')}
          </Button>
        </Tooltip>
      </Container>
    ),
  }
}

export default useWeekdaySelector
