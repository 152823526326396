/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-underscore-dangle */
import {
  DeleteFilled,
  FileUnknownOutlined,
  ProfileOutlined,
  ShareAltOutlined,
} from '@ant-design/icons'
import { Popconfirm, Space, Table, Tag, Tooltip, Button } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import Text from 'antd/lib/typography/Text'
import { format } from 'date-fns'
import moment from 'moment'
import React from 'react'
import { Link } from 'react-router-dom'
import { useTaskType } from '~/hooks/Tasks/TaskType/useTaskType'
import { useListTask } from '~/hooks/Tasks/useListTasks'
import { translate } from '~/utils/locale'

// import { Container } from './styles';

interface EquipmentsTasks {
  id: string
}

interface TaskListProps {
  checkin_type: string
  createdAt: Date
  date: string
  duration_time: string
  external_code: string
  is_enabled: string
  priority: string
  _id: string
  hours: string
  value: number
  currentDate: string
  internal_code: string
  current_status: string
  customer_id: string
  task_type_id: string
  responsible: {
    id: string
    type: string
    data: {
      contact: {
        name: string
      }
      title: string
    }
  }
  customer: {
    id: string
    contact: {
      name: string
    }
    parent_customer: string
  }
  address: {
    city: string
    id: string
    location: {
      x: number
      y: number
    }
    neighborhood: string
    number: number
    state: string
    street: string
    zip_code: string
  }
  equipments: EquipmentsTasks[]
}

interface ModalTasksTableProps {
  tasks: TaskListProps[]
}

const ModalTasksTable: React.FC<ModalTasksTableProps> = ({ tasks }) => {
  const { deleteTask } = useListTask()

  const { taskType } = useTaskType()

  const taskFormatted = tasks?.map((task: TaskListProps) => {
    const dataFormat = {
      ...task,
      initialTime: format(
        new Date(task.date),
        `dd/MM/yyyy '${translate('TasksOptionsTable.dateString')}' HH:mm'h'`,
      ),
    }

    return dataFormat
  })

  const columns: ColumnsType<TaskListProps> = [
    {
      title: translate('TasksOptionsTable.internalCodeColumn'),
      dataIndex: 'internal_code',
      key: 'internal_code',
      render: (name: string, record) => (
        <Link to={`/tarefas/visualizar/${record._id}`}>{name}</Link>
      ),
    },
    {
      title: translate('TasksOptionsTable.responsibleColumn'),
      key: 'responsible',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend'],
      sorter: (a: TaskListProps, b: TaskListProps) =>
        a?.responsible?.data?.contact?.name.localeCompare(
          b?.responsible?.data?.contact?.name,
        ),
      render: (record) => (
        <>
          {record.responsible?.type === 'collaborator' ? (
            <>
              {record?.responsible?.data?.contact?.name ? (
                <Text>{record?.responsible?.data?.contact?.name}</Text>
              ) : (
                <Text type="secondary">
                  <Space size="small">
                    <FileUnknownOutlined />
                    N/A
                  </Space>
                </Text>
              )}
            </>
          ) : (
            <>
              {record?.responsible?.data?.title ? (
                <Text>{record?.responsible?.data?.title}</Text>
              ) : (
                <Text type="secondary">
                  <Space size="small">
                    <FileUnknownOutlined />
                    N/A
                  </Space>
                </Text>
              )}
            </>
          )}
        </>
      ),
    },
    {
      title: translate('TasksOptionsTable.clientColumn'),
      key: 'customer',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend'],
      sorter: (a: TaskListProps, b: TaskListProps) =>
        a?.customer?.contact?.name.localeCompare(b?.customer?.contact?.name),
      render: (record) => (
        <>
          {record?.customer?.contact?.name ? (
            <Text>{record?.customer?.contact.name}</Text>
          ) : (
            <Text type="secondary">
              <Space size="small">
                <FileUnknownOutlined />
                N/A
              </Space>
            </Text>
          )}
        </>
      ),
    },
    {
      title: translate('TasksOptionsTable.initialTimeColumn'),
      dataIndex: 'initialTime',
      key: 'date',
      sorter: (a: TaskListProps, b: TaskListProps) =>
        moment(a.createdAt).unix() - moment(b.createdAt).unix(),
    },
    {
      title: translate('TasksOptionsTable.taskTypeColumn'),
      key: 'taskType',
      render: (record) => (
        <>
          {taskType?.map((task) => (
            <div key={task._id}>
              {task._id === record.task_type_id ? (
                <Text>{task.title}</Text>
              ) : (
                ''
              )}
            </div>
          ))}
        </>
      ),
    },
    {
      title: 'Valor',
      dataIndex: 'value',
      key: 'value',
      sorter: (a: TaskListProps, b: TaskListProps) => a.value - b.value,
      render: (value: number) =>
        new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }).format(value),
    },
    {
      title: translate('TasksOptionsTable.statusColumn'),
      key: 'status',
      sortDirections: ['ascend', 'descend'],
      sorter: (a: TaskListProps, b: TaskListProps) =>
        a?.current_status.localeCompare(b?.current_status),
      render: (record) => (
        <>
          {record.current_status === 'received' ? (
            <Tag color="yellow">
              {translate('TasksOptionsTable.statusReceived')}
            </Tag>
          ) : (
            ''
          )}
          {record.current_status === 'viewed' ? (
            <Tag color="green">
              {translate('TasksOptionsTable.statusViewed')}
            </Tag>
          ) : (
            ''
          )}
          {record.current_status === 'en-route' ? (
            <Tag color="green">
              {translate('TasksOptionsTable.statusEnRoute')}
            </Tag>
          ) : (
            ''
          )}
          {record.current_status === 'working' ? (
            <Tag color="green">
              {translate('TasksOptionsTable.statusWorking')}
            </Tag>
          ) : (
            ''
          )}
          {record.current_status === 'break' ? (
            <Tag color="red">{translate('TasksOptionsTable.statusBreak')}</Tag>
          ) : (
            ''
          )}
          {record.current_status === 'resume-working' ? (
            <Tag color="green">
              {translate('TasksOptionsTable.statusResumeWorking')}
            </Tag>
          ) : (
            ''
          )}
          {record.current_status === 'canceled' ? (
            <Tag color="gray">
              {translate('TasksOptionsTable.statusCanceled')}
            </Tag>
          ) : (
            ''
          )}
          {record.current_status === 'finished' ? (
            <Tag color="geekblue">
              {translate('TasksOptionsTable.statusFinished')}
            </Tag>
          ) : (
            ''
          )}
        </>
      ),
    },
    {
      title: translate('TasksOptionsTable.actionsColumn'),
      key: 'remove',
      align: 'center',
      render: (record) => (
        <Space size="middle">
          <Tooltip title={translate('TasksOptionsTable.viewTooltip')}>
            <Link to={`/tarefas/visualizar/${record._id}`}>
              <Button ghost shape="circle" icon={<ProfileOutlined />} />
            </Link>
          </Tooltip>
          <Popconfirm
            title={translate('TasksOptionsTable.deletePopconfirm')}
            onConfirm={() => deleteTask(record._id)}
          >
            <Tooltip title={translate('TasksOptionsTable.deleteTooltip')}>
              <Button ghost danger shape="circle" icon={<DeleteFilled />} />
            </Tooltip>
          </Popconfirm>

          <Tooltip title="Compartilhar tarefa">
            <Link to="/">
              <Button ghost shape="circle" icon={<ShareAltOutlined />} />
            </Link>
          </Tooltip>
        </Space>
      ),
    },
  ]

  return (
    <Table
      rowKey={(record) => record._id}
      pagination={{
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '50', '100', '200'],
        defaultPageSize: 20,
      }}
      columns={columns}
      dataSource={taskFormatted}
      scroll={{ x: 1000, y: '60vh' }}
    />
  )
}

export default ModalTasksTable
