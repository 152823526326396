/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react'

import { Card, Space, Button, Form, Input, Row, Col, Checkbox } from 'antd'

import { SaveOutlined, ArrowLeftOutlined } from '@ant-design/icons'

import { Link, useHistory } from 'react-router-dom'

import { useTaskForms } from '~/hooks/Tasks/Forms/useTaskForms'
import { translate } from '~/utils/locale'

import { Container } from './styles'

interface TaskTypeData {
  title: string
  description?: string
}

const CreateTasks: React.FC = () => {
  const { createForm } = useTaskForms()

  const history = useHistory()

  const [form] = Form.useForm()

  const [keepRegister, setKeepRegister] = useState(false)

  function onCreateTaskType(data: TaskTypeData) {
    try {
      createForm(data)

      form.resetFields()

      if (!keepRegister) {
        history.push('/formularios')
      }
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Container>
      <Card
        title={
          <Space size="large">
            <Link to="/formularios">
              <ArrowLeftOutlined />
            </Link>
            <b>{translate('TasksRegistrationFormsCreate.cardTitle')}</b>
          </Space>
        }
        extra={
          <Space size="middle">
            <Checkbox
              onChange={(e) => {
                setKeepRegister(e.target.checked)
              }}
              checked={keepRegister}
            >
              {translate('TasksRegistrationFormsCreate.keepRegisterText')}
            </Checkbox>
            <Button
              type="primary"
              icon={<SaveOutlined />}
              htmlType="submit"
              form="form"
              id="btn_create_form"
            >
              {translate('TasksRegistrationFormsCreate.registerButton')}
            </Button>
          </Space>
        }
        bordered={false}
        style={{ width: '100%', padding: 30 }}
      >
        <Form
          layout="vertical"
          onFinish={onCreateTaskType}
          id="form"
          form={form}
        >
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item
                label={translate('TasksRegistrationFormsCreate.titleLabel')}
                name="title"
                rules={[
                  {
                    required: true,
                    message: translate(
                      'TasksRegistrationFormsCreate.titleRule',
                    ),
                  },
                ]}
              >
                <Input
                  placeholder={translate(
                    'TasksRegistrationFormsCreate.titlePlaceholder',
                  )}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Form.Item
                label={translate(
                  'TasksRegistrationFormsCreate.descriptionLabel',
                )}
                name="description"
              >
                <Input.TextArea
                  placeholder={translate(
                    'TasksRegistrationFormsCreate.descriptionPlaceholder',
                  )}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Card>
    </Container>
  )
}

export default CreateTasks
