import { lighten } from 'polished'
import styled from 'styled-components'

export const Container = styled.div`
  #btn_create_client {
    background: #3db065;
    border: 1px solid #3db065;
    color: white;

    &:hover {
      background: ${lighten(0.05, '#3DB065')};
      border: 1px solid ${lighten(0.1, '#3DB065')};
    }
  }
`

export const ClientAvatar = styled.div`
  padding: 30px 20px 20px 20px;
`

export const ClientForm = styled.div`
  margin-left: 60px;
`

export const FormButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
`
