/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useState } from 'react'

import { Button, Modal, Space, Typography } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

import ScaleTeamsTable from '~/components/DataManipulation/Scales/ScaleTeams/ScaleTeamsTable'
import ContributorsFilter from '~/components/DataManipulation/Contributor/ContributorsFilter'

import { useTeams } from '~/hooks/Teams/useListTeams'
import { translate } from '~/utils/locale'

const ScaleTeamsAdd: React.FC = () => {
  const { Title } = Typography

  const [isModalVisible, setIsModalVisible] = useState(false)

  const { teams, isLoading, isFetching } = useTeams()

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  return (
    <>
      <Button type="primary" onClick={showModal}>
        {translate('ScalesTeamsAdd.addButton')}
      </Button>
      <Modal
        title={
          <Space>
            <Title level={5}>{translate('ScalesTeamsAdd.modalTitle')}</Title>
            {!isLoading && isFetching && <LoadingOutlined spin />}
          </Space>
        }
        open={isModalVisible}
        width={1200}
        onCancel={handleCancel}
        footer={[
          <Space key="FooterProps">
            <Button key="back" onClick={handleCancel}>
              {translate('ScalesTeamsAdd.closeButton')}
            </Button>
            <ContributorsFilter />
          </Space>,
        ]}
      >
        <ScaleTeamsTable data={teams!} loading={isLoading} />
      </Modal>
    </>
  )
}

export default ScaleTeamsAdd
