/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react'

import {
  Drawer,
  Form,
  Button,
  Col,
  Row,
  Select,
  DatePicker,
  Tooltip,
} from 'antd'
import dayjs from 'dayjs'
import { CloseOutlined, FilterOutlined } from '@ant-design/icons'

import { Container } from './styles'

import { translate } from '~/utils/locale'
import { useDashboardTasks } from '~/hooks/Dashboard/useDashboardTasks'

const { Option } = Select

const DashboardTasksFilter: React.FC = () => {
  const [visible, setVisible] = useState(false)

  const [form] = Form.useForm()
  const { handleUpdateFilters, handleResetFilters, filterOptions } =
    useDashboardTasks()

  useEffect(() => {
    form.setFieldsValue({
      fromDate: dayjs(new Date()),
      toDate: dayjs(new Date()),
      current_status: filterOptions?.current_status,
    })
  }, [filterOptions, form])

  const showDrawer = () => {
    setVisible(true)
  }

  const onClose = () => {
    setVisible(false)
  }

  const onFinish = (data: any) => {
    handleUpdateFilters(data)
    setVisible(false)
  }

  const onReset = () => {
    handleResetFilters()
    setVisible(false)
    form.resetFields()
  }

  const isFilter = Object.keys(filterOptions).length !== 0

  return (
    <Container isFilter={isFilter}>
      <Button
        type="primary"
        onClick={showDrawer}
        icon={
          !isFilter ? (
            <FilterOutlined />
          ) : (
            <Tooltip title={translate('ContributorFilter.cleanFilter')}>
              <CloseOutlined onClick={onReset} />
            </Tooltip>
          )
        }
        id="btn_filter_dashboard_tasks"
      >
        {isFilter
          ? translate('ContributorFilter.filterAppliedText')
          : translate('ContributorFilter.filterText')}
      </Button>
      <Drawer
        forceRender
        title={translate('TasksFilter.drawerTitle')}
        width={580}
        onClose={onClose}
        open={visible}
        style={{ paddingBottom: 80 }}
      >
        <Form layout="vertical" form={form} onFinish={onFinish}>
          <Row gutter={10}>
            <Col span={12}>
              <Form.Item
                label={translate('TasksFilter.fromDateLabel')}
                name="fromDate"
              >
                <DatePicker
                  format="DD/MM/YYYY"
                  placeholder={translate('TasksFilter.fromDatePlaceholder')}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={translate('TasksFilter.toDateLabel')}
                name="toDate"
              >
                <DatePicker
                  format="DD/MM/YYYY"
                  placeholder={translate('TasksFilter.toDatePlaceholder')}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={8}>
            <Col span={24}>
              <Form.Item
                label={translate('TasksFilter.statusLabel')}
                name="current_status"
              >
                <Select placeholder="Selecione um status" allowClear>
                  <Option value="received">Tarefa Enviada</Option>
                  <Option value="viewed">Tarefa Visualizada</Option>
                  <Option value="working">Tarefa sendo realizada</Option>
                  <Option value="break">Tarefa Pausada</Option>
                  <Option value="en-route">Tarefa a caminho</Option>
                  <Option value="resume-working">Tarefa Retomada</Option>
                  <Option value="canceled">Tarefa Cancelada</Option>
                  <Option value="finished">Tarefa Finalizada</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={12}>
              <Form.Item style={{ width: '100%' }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: '100%' }}
                >
                  {translate('TasksFilter.applyButton')}
                </Button>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item style={{ width: '100%' }}>
                <Button onClick={onReset} style={{ width: '100%' }}>
                  {translate('TasksFilter.cleanButton')}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </Container>
  )
}

export default DashboardTasksFilter
