/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-underscore-dangle */
import React from 'react'

import { Tabs } from 'antd'
import { PageHeader } from '@ant-design/pro-components'

import { useHistory, useParams } from 'react-router-dom'

import { translate } from '~/utils/locale'
import { useTeam } from '~/hooks/Teams/useTeams'
import TeamDetails from './Details'
import FinancialTeam from './Financial'
import UpdateFinancialTeam from './Financial/Update'

const TeamTab: React.FC = () => {
  const { team, isEdit } = useTeam()

  const { TabPane } = Tabs

  const { teamId, tab } = useParams<{
    teamId: string
    tab: string
  }>()

  const history = useHistory()

  const handleTabChange = (key: string) => {
    if (key === 'detalhes') {
      history.replace({
        pathname: `/equipes/visualizar/${teamId}`,
      })
    }
  }

  return (
    <>
      <PageHeader
        className="site-page-header"
        onBack={() => window.history.back()}
        title={translate('TeamsDetails.pageHeaderTitle')}
        subTitle={team?.title}
      />
      <Tabs
        onTabClick={(key: string) => handleTabChange(key)}
        defaultActiveKey={tab ? `${tab}` : 'detalhes'}
        style={{ paddingLeft: 20, paddingRight: 20 }}
      >
        <TabPane tab={translate('TasksTabs.detailsTab')} key="detalhes">
          <TeamDetails />
        </TabPane>
        <TabPane tab="Financeiro" key="financeiro">
          {!isEdit ? <FinancialTeam /> : <UpdateFinancialTeam />}
        </TabPane>
      </Tabs>
    </>
  )
}

export default TeamTab
