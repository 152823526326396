export default {
  pt: {
    ShowStatus: {
      helmetTitle: 'Visualizar Status',
    },
  },
  en: {
    ShowStatus: {
      helmetTitle: 'View Status',
    },
  },
  es: {
    ShowStatus: {
      helmetTitle: 'Ver El Estado De',
    },
  },
}
