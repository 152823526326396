export default {
  pt: {
    TasksTabsEquipments: {
      nameColumn: 'Nome',
      identifierColumn: 'Identificador',
      categoryColumn: 'Categoria',
      actionsColumn: 'Ações',
      deletePopconfirm: 'Deseja mesmo deletar o equipamento da tarefa?',
      deleteTooltip: 'Deletar',
      cardTitle: 'Equipamentos da Tarefa',
      totalText: 'Total de',
      equipmentText: 'equipamento',
      equipmentsText: 'equipamentos',
    },
  },
  en: {
    TasksTabsEquipments: {
      nameColumn: 'Name',
      identifierColumn: 'Identifier',
      categoryColumn: 'Category',
      actionsColumn: 'Actions',
      deletePopconfirm: 'Do you really want to delete the task equipment?',
      deleteTooltip: 'Delete',
      cardTitle: 'Task Equipment',
      totalText: 'Total of',
      equipmentText: 'equipment',
      equipmentsText: 'equipments',
    },
  },
  es: {
    TasksTabsEquipments: {
      nameColumn: 'Nombre',
      identifierColumn: 'Identificador',
      categoryColumn: 'Categoría',
      actionsColumn: 'Acciones',
      deletePopconfirm: '¿Realmente desea eliminar el equipo de tareas?',
      deleteTooltip: 'Borrar',
      cardTitle: 'Equipo de trabajo',
      totalText: 'Total de',
      equipmentText: 'equipo',
      equipmentsText: 'equipo',
    },
  },
}
