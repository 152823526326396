export default {
  pt: {
    UsersUpdate: {
      editTooltip: 'Editar Usuário',
      editButton: 'Editar',
      modalTitle: 'Editar Usuário',
      closeButton: 'Fechar',
      saveButton: 'Salvar',
      emailLabel: 'E-mail',
      emailRule: 'Preencha o e-mail.',
      emailPlaceholder: 'Informe o e-mail',
      usernameLabel: 'Usuário',
      usernameRule: 'Preencha o usuário.',
      usernamePlaceholder: 'Informe o usuário',
    },
  },
  en: {
    UsersUpdate: {
      editTooltip: 'Edit User',
      editButton: 'Edit',
      modalTitle: 'Edit User',
      closeButton: 'Close',
      saveButton: 'Save',
      emailLabel: 'Email',
      emailRule: 'Fill in the email.',
      emailPlaceholder: 'Inform the email',
      usernameLabel: 'User',
      usernameRule: 'Fill in the user.',
      usernamePlaceholder: 'Inform the user',
    },
  },
  es: {
    UsersUpdate: {
      editTooltip: 'Editar Usuario',
      editButton: 'Editar',
      modalTitle: 'Editar Usuario',
      closeButton: 'Cerrar',
      saveButton: 'Guardar',
      emailLabel: 'Correo electrónico',
      emailRule: 'Rellene el correo electrónico.',
      emailPlaceholder: 'Informar al correo electrónico',
      usernameLabel: 'Usuario',
      usernameRule: 'Completar el usuario.',
      usernamePlaceholder: 'Informar al usuario',
    },
  },
}
