import React from 'react'

import App from './App'
import { LocaleProvider } from '~/hooks/locale/useLocale'
import { i18nConfig } from '~/utils/locale'
import { createRoot } from 'react-dom/client'

// import { makeServer } from './services/mirage.js';

// if (process.env.NODE_ENV === 'development') {
//   makeServer();
// }

const { language } = navigator

const esCodes = [
  'es',
  'es-AR',
  'es-GT',
  'es-CR',
  'es-PA',
  'es-DO',
  'es-MX',
  'es-VE',
  'es-CO',
  'es-PE',
  'es-EC',
  'es-CL',
  'es-UY',
  'es-PY',
  'es-BO',
  'es-SV',
  'es-HN',
  'es-NI',
  'es-PR',
]

const handleDefaultLanguage = () => {
  if (language === 'pt-BR' || language === 'pt') {
    return 'pt-BR'
  }
  if (esCodes.find((lang) => lang === language)) {
    return 'es-ES'
  }
  return 'en-US'
}

i18nConfig(handleDefaultLanguage())

const root = createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <LocaleProvider>
    <App />
  </LocaleProvider>,
)
