import styled from 'styled-components'

export const Container = styled.div`
  .ant-modal-body {
    padding: 10px 24px 15px 24px;
  }
`

export const TasksWrapper = styled.div`
  .text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-content: flex-start;
    padding-bottom: 23px;

    svg {
      margin-right: 5px;
    }
  }
`

export const Equipments = styled.div`
  .equipments {
    display: flex;
    align-items: flex-start;

    div {
      margin-left: 10px;
    }

    p {
      color: var(--secondary-initial-text);
    }
  }

  .ant-divider-horizontal {
    margin: 14px 0;
  }
`
