/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { PlusCircleOutlined } from '@ant-design/icons'
import { Modal, Button, Form, Input, Row, Col, Checkbox, Space } from 'antd'

import React, { useState } from 'react'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import pt_BR from 'react-phone-number-input/locale/pt-BR.json'
import { useListContacts } from '~/hooks/Clients/useListContacts'
import { translate } from '~/utils/locale'
import { Container, ContainerButton } from './styles'

interface ContactProps {
  name: string
  email: string
  phone: string
  office: string
  is_responsible: boolean
  description: boolean
}

const CreateContact: React.FC = () => {
  const { createContacts } = useListContacts()
  const [isModalOpen, setIsModalOpen] = useState(false)

  function onCreateSegments(dataForm: ContactProps) {
    try {
      if (dataForm.phone) {
        dataForm.phone = dataForm.phone.replace(/[^0-9]/g, '')
      }
      createContacts(dataForm)
      setIsModalOpen(false)
    } catch (err) {
      setIsModalOpen(false)
    }
  }

  const showModal = () => {
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  return (
    <Container>
      <Button type="primary" icon={<PlusCircleOutlined />} onClick={showModal}>
        {translate('ClientsContactsTabCreate.newContactButton')}
      </Button>
      <Modal
        title={translate('ClientsContactsTabCreate.modalTitle')}
        open={isModalOpen}
        onOk={closeModal}
        onCancel={closeModal}
        style={{ top: 40 }}
        width="600px"
        footer={[
          <Space key="buttons">
            <Button key="back" onClick={closeModal}>
              {translate('ClientsContactsTabCreate.closeButton')}
            </Button>

            <ContainerButton>
              <Button
                key="save"
                type="primary"
                form="onFormContacts"
                htmlType="submit"
                id="btn_create_contacts_client"
              >
                {translate('ClientsContactsTabCreate.saveButton')}
              </Button>
            </ContainerButton>
          </Space>,
        ]}
      >
        <Form id="onFormContacts" layout="vertical" onFinish={onCreateSegments}>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label={translate('ClientsContactsTabCreate.nameLabel')}
                name="name"
                rules={[
                  {
                    required: true,
                    message: translate('ClientsContactsTabCreate.nameRule'),
                  },
                ]}
              >
                <Input
                  placeholder={translate(
                    'ClientsContactsTabCreate.namePlaceholder',
                  )}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={translate('ClientsContactsTabCreate.emailLabel')}
                name="email"
                rules={[
                  {
                    required: true,
                    message: translate('ClientsContactsTabCreate.emailRule'),
                  },
                ]}
              >
                <Input
                  placeholder={translate(
                    'ClientsContactsTabCreate.emailPlaceholder',
                  )}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label={translate('ClientsContactsTabCreate.phoneLabel')}
                name="phone"
                rules={[
                  () => ({
                    validator(rule, phone) {
                      if (phone === undefined || phone === null) {
                        return Promise.resolve()
                      }
                      if (phone && isValidPhoneNumber(phone)) {
                        return Promise.resolve()
                      }
                      return Promise.reject(
                        new Error(
                          translate('ClientsContactsTabCreate.phoneRule'),
                        ),
                      )
                    },
                  }),
                ]}
              >
                <PhoneInput
                  placeholder={translate(
                    'ClientsContactsTabCreate.phonePlaceholder',
                  )}
                  labels={pt_BR}
                  defaultCountry="BR"
                  international
                  onChange={() => {
                    // Do nothing
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={translate('ClientsContactsTabCreate.officeLabel')}
                name="office"
                rules={[
                  {
                    required: true,
                    message: translate('ClientsContactsTabCreate.officeRule'),
                  },
                ]}
              >
                <Input
                  placeholder={translate(
                    'ClientsContactsTabCreate.officePlaceholder',
                  )}
                />
              </Form.Item>
            </Col>
          </Row>
          <Col span={16}>
            <Form.Item
              label={translate('ClientsContactsTabCreate.responsibleLabel')}
              name="is_responsible"
              valuePropName="checked"
            >
              <Checkbox>
                {translate('ClientsContactsTabCreate.responsibleCheckbox')}
              </Checkbox>
            </Form.Item>
          </Col>

          <Form.Item
            label={translate('ClientsContactsTabCreate.descriptionLabel')}
            name="description"
          >
            <Input.TextArea
              placeholder={translate(
                'ClientsContactsTabCreate.descriptionPlaceholder',
              )}
            />
          </Form.Item>
        </Form>
      </Modal>
    </Container>
  )
}

export default CreateContact
