/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-one-expression-per-line */

import { Card, Space, Tabs } from 'antd'

import React from 'react'

import DetailsTaskHistoric from './Details'
import ReportTaskHistoric from './Report'

const HistoricTask: React.FC = () => {
  return (
    <Card
      bordered={false}
      title={
        <Space size="large">
          <b>Histórico</b>
          {/* {!isLoading && isFetching && <LoadingOutlined spin />} */}
        </Space>
      }
      style={{
        display: 'inline-block',
        width: '100%',
      }}
    >
      <Tabs tabPosition="left">
        <Tabs.TabPane tab="Detalhes da Tarefa" key="1">
          <DetailsTaskHistoric />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Evidências" key="2">
          <ReportTaskHistoric />
        </Tabs.TabPane>
      </Tabs>
    </Card>
  )
}

export default HistoricTask
