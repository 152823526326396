/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-param-reassign */
import React, { useCallback, useState } from 'react'

import { Modal, Form, Button, Upload, Input, message, Space } from 'antd'
import { InboxOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { useParams } from 'react-router-dom'

import Checkbox from 'antd/lib/checkbox/Checkbox'
import api from '~/services/api'

import { useCompanies } from '~/hooks/Companies/useCompanies'
import { useContributor } from '~/hooks/Contributors/useContributor'
import { useLocale } from '~/hooks/locale/useLocale'

import { ShowError } from '~/utils/errors/apiErrors'
import { translate } from '~/utils/locale'
import { ContainerButton } from './styles'

interface NewAttachmentData {
  type: string
  title: string
  description: string
  dragger?: File[]
  attachment: File
}

const AttachmentsModal: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [keepRegister, setKeepRegister] = useState(false)
  const [attachment, setAttachment] = useState<File>({} as File)

  const { contributorId } = useParams<{ contributorId: string }>()

  const { selectedCompany } = useCompanies()
  const { contributorRefetch } = useContributor()
  const { locale } = useLocale()

  const [form] = Form.useForm()

  const props = {
    maxCount: 1,
    onRemove: () => {
      setAttachment({} as File)
    },
    beforeUpload: (file: any) => {
      setAttachment(file)
      return false
    },
    attachment,
  }

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e
    }
    return e && e.fileList
  }

  const handleOk = () => {
    form.submit()
  }

  const handleCancel = useCallback(() => {
    form.resetFields()
    setIsVisible(false)
  }, [form])

  const onCreate = useCallback(
    async (data: NewAttachmentData) => {
      try {
        setConfirmLoading(true)
        const dados = new FormData()
        const attachmentType = attachment.type.split('/')
        dados.append('attachment', attachment)
        dados.append('type', attachmentType[0])
        dados.append('title', data.title)
        dados.append('description', data.description)
        await api.post(
          `/company/${selectedCompany}/collaborator/${contributorId}/collaborator-attachment`,
          dados,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
        )
        contributorRefetch()
        message.success(translate('AttachmentsModal.successMessage'))
        setConfirmLoading(false)
        form.resetFields()
        if (!keepRegister) {
          handleCancel()
        }
      } catch (err: any) {
        ShowError(
          err.message,
          translate('AttachmentsModal.errorMessage'),
          locale,
        )

        setConfirmLoading(false)
      }
    },
    [
      attachment,
      contributorRefetch,
      form,
      contributorId,
      selectedCompany,
      handleCancel,
      keepRegister,
      locale,
    ],
  )

  const showModal = () => {
    setIsVisible(true)
  }

  return (
    <>
      <Button type="primary" onClick={showModal} icon={<PlusCircleOutlined />}>
        {translate('AttachmentsModal.modalButtonText')}
      </Button>
      <Modal
        title={translate('AttachmentsModal.modalTitle')}
        open={isVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        style={{ top: 40 }}
        footer={[
          <Space key="buttons">
            <Checkbox
              onChange={(e) => {
                setKeepRegister(e.target.checked)
              }}
              checked={keepRegister}
              key="keepRegister"
            >
              {translate('AttachmentsModal.keepRegisterText')}
            </Checkbox>
            <Button key="back" onClick={handleCancel}>
              {translate('AttachmentsModal.cancelText')}
            </Button>
            <ContainerButton>
              <Button
                key="submit"
                type="primary"
                loading={confirmLoading}
                onClick={handleOk}
                id="btn_create_attachment_contributor"
              >
                {translate('AttachmentsModal.finishText')}
              </Button>
            </ContainerButton>
          </Space>,
        ]}
      >
        <Form form={form} onFinish={onCreate}>
          <Form.Item
            label={translate('AttachmentsModal.titleLabel')}
            name="title"
            labelCol={{ span: 24 }}
            style={{
              width: '100%',
            }}
            rules={[
              {
                required: true,
                message: translate('AttachmentsModal.titleRule'),
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={translate('AttachmentsModal.descriptionLabel')}
            name="description"
            labelCol={{ span: 24 }}
            style={{
              width: '100%',
            }}
            rules={[
              {
                required: true,
                message: translate('AttachmentsModal.descriptionRule'),
              },
            ]}
          >
            <Input.TextArea rows={2} />
          </Form.Item>

          <Form.Item
            name="dragger"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            rules={[
              {
                required: true,
                message: translate('AttachmentsModal.draggerRule'),
              },
            ]}
          >
            <Upload.Dragger name="files" {...props}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                {translate('AttachmentsModal.draggerInstructionOne')}
              </p>
              <p className="ant-upload-hint">
                {translate('AttachmentsModal.draggerInstructionTwo')}
              </p>
            </Upload.Dragger>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default AttachmentsModal
