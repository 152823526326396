/* eslint-disable no-underscore-dangle */
/* eslint-disable no-return-assign */
/* eslint-disable react/jsx-one-expression-per-line */
import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons'
import { EventClickArg, EventContentArg } from '@fullcalendar/react'
import { Card, Space, Button, Tooltip } from 'antd'
import Text from 'antd/lib/typography/Text'
import { format, parseISO } from 'date-fns'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import FCCalendar from '~/components/DataManipulation/FCCalendar'
import { useListClients } from '~/hooks/Clients/useListClients'
import { useListTask } from '~/hooks/Tasks/useListTasks'

import FilterTasks from '~/components/DataManipulation/Tasks/Filter'
import TaskCalendarModal from '~/components/DataManipulation/Tasks/TaskCalendarModal'
import { translate } from '~/utils/locale'
import MoreOptions from '~/components/DataManipulation/Misc/MoreOptions'

interface EquipmentsTasks {
  id: string
}

interface ITask {
  checkin_type: string
  createdAt: Date
  date: string
  duration_time: string
  external_code: string
  is_enabled: string
  priority: string
  _id: string
  hours: string
  currentDate: string
  internal_code: string
  current_status: string
  customer_id: string
  task_type_id: string
  responsible: {
    id: string
    type: string
  }
  address: {
    city: string
    id: string
    location: {
      x: number
      y: number
    }
    neighborhood: string
    number: number
    state: string
    street: string
    zip_code: string
  }
  equipments: EquipmentsTasks[]
}

interface TaskListProps {
  id: string
  title: string | undefined
  start: Date
  end: Date
  generalInfo: ITask
}

const TasksCalendar: React.FC = () => {
  const [events, setEvents] = useState<TaskListProps[] | undefined>([])
  const { isLoading, isFetching, tasksTotal, tasks } = useListTask()
  const { clients } = useListClients()

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [event, setEvent] = useState({} as ITask)

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleEventClassNames = (arg: EventContentArg) => {
    if (arg.event._def.extendedProps.generalInfo.priority === 'high') {
      return 'fc-event-color-red'
    }
    if (arg.event._def.extendedProps.generalInfo.priority === 'medium') {
      return 'fc-event-color-yellow'
    }
    return 'fc-event-color-blue'
  }

  const handleEventClick = (calendarEvent: EventClickArg) => {
    setEvent({
      checkin_type:
        calendarEvent.event._def.extendedProps.generalInfo.checkin_type,
      createdAt: calendarEvent.event._def.extendedProps.generalInfo.createdAt,
      date: calendarEvent.event._def.extendedProps.generalInfo.date,
      duration_time:
        calendarEvent.event._def.extendedProps.generalInfo.duration_time,
      external_code:
        calendarEvent.event._def.extendedProps.generalInfo.external_code,
      is_enabled: calendarEvent.event._def.extendedProps.generalInfo.is_enabled,
      priority: calendarEvent.event._def.extendedProps.generalInfo.priority,
      _id: calendarEvent.event._def.extendedProps.generalInfo._id,
      hours: calendarEvent.event._def.extendedProps.generalInfo.hours,
      currentDate:
        calendarEvent.event._def.extendedProps.generalInfo.currentDate,
      internal_code:
        calendarEvent.event._def.extendedProps.generalInfo.internal_code,
      current_status:
        calendarEvent.event._def.extendedProps.generalInfo.current_status,
      customer_id:
        calendarEvent.event._def.extendedProps.generalInfo.customer_id,
      task_type_id:
        calendarEvent.event._def.extendedProps.generalInfo.task_type_id,
      responsible: {
        id: calendarEvent.event._def.extendedProps.generalInfo.responsible?.id,
        type: calendarEvent.event._def.extendedProps.generalInfo.responsible
          ?.type,
      },
      address: {
        city: calendarEvent.event._def.extendedProps.generalInfo.address.city,
        id: calendarEvent.event._def.extendedProps.generalInfo.address.id,
        location: {
          x: calendarEvent.event._def.extendedProps.generalInfo.address.location
            .x,
          y: calendarEvent.event._def.extendedProps.generalInfo.address.location
            .y,
        },
        neighborhood:
          calendarEvent.event._def.extendedProps.generalInfo.address
            .neighborhood,
        number:
          calendarEvent.event._def.extendedProps.generalInfo.address.number,
        state: calendarEvent.event._def.extendedProps.generalInfo.address.state,
        street:
          calendarEvent.event._def.extendedProps.generalInfo.address.street,
        zip_code:
          calendarEvent.event._def.extendedProps.generalInfo.address.zip_code,
      },
      equipments: calendarEvent.event._def.extendedProps.generalInfo.equipments,
    })
    showModal()
  }

  useEffect(() => {
    function getListData() {
      let tasksList: TaskListProps[] | undefined

      tasks?.forEach((task) => {
        const parsedDay = parseISO(task.date)
        const formattedDay = format(parsedDay, 'dd/MM/yyyy')
        const endMinutes = dayjs.duration(task.duration_time).asMinutes()

        const endDate = new Date(
          dayjs(parsedDay).add(endMinutes, 'minutes').format(),
        )

        const clientName = clients?.find((client) => {
          return client.id === task.customer_id
        })

        const formatttedCurrentDay = format(
          new Date(task.date),
          `dd/MM/yyyy '${translate('TasksCalendar.dateString')}' HH:mm'h'`,
        )
        const eventObj = {
          id: task._id,
          title: clientName?.contact.name,
          start: parsedDay,
          end: new Date(endDate),
          generalInfo: {
            checkin_type: task.checkin_type,
            createdAt: task.createdAt,
            date: formattedDay,
            duration_time: task.duration_time,
            external_code: task.external_code,
            is_enabled: task.is_enabled,
            priority: task.priority,
            _id: task._id,
            hours: format(parsedDay, 'HH:mm'),
            currentDate: formatttedCurrentDay,
            internal_code: task.internal_code,
            current_status: task.current_status,
            customer_id: task.customer_id,
            task_type_id: task.task_type_id,
            responsible: {
              id: task.responsible?.id,
              type: task.responsible?.type,
            },
            address: {
              city: task.address.city,
              id: task.address.id,
              location: {
                x: task.address.location.x,
                y: task.address.location.y,
              },
              neighborhood: task.address.neighborhood,
              number: task.address.number,
              state: task.address.state,
              street: task.address.street,
              zip_code: task.address.zip_code,
            },
            equipments: task.equipments,
          },
        }

        if (tasksList) {
          return (tasksList = [...tasksList, eventObj])
        }
        return (tasksList = [eventObj])
      })

      return tasksList
    }
    const eventsData = getListData()
    setEvents(eventsData)
  }, [tasks, clients])

  return (
    <Card
      title={
        <Space size="large">
          <b>{translate('TasksCalendar.cardTitle')}</b>
          {isLoading ? (
            <LoadingOutlined spin />
          ) : (
            <Text
              type="secondary"
              style={{ fontWeight: 'normal', fontSize: 14 }}
            >
              {tasksTotal !== undefined ? (
                <>
                  {tasksTotal === 1
                    ? `${translate(
                        'TasksCalendar.totalText',
                      )} ${tasksTotal} ${translate('TasksCalendar.taskText')}`
                    : `${translate(
                        'TasksCalendar.totalText',
                      )} ${tasksTotal} ${translate('TasksCalendar.tasksText')}`}
                </>
              ) : (
                ''
              )}
            </Text>
          )}
          {!isLoading && isFetching && <LoadingOutlined spin />}
        </Space>
      }
      extra={
        <Space size="middle">
          <Tooltip title={translate('TasksBoardsCalendar.exportTooltip')}>
            <Link to="/relatorioTarefas">
              <Button icon={<DownloadOutlined />} shape="circle" />
            </Link>
          </Tooltip>

          <MoreOptions
            exportTooltip={translate('TasksBoardsCalendar.exportTooltip')}
            importTooltip={translate('TasksBoardsCalendar.importTooltip')}
            modalTitle={translate('TasksBoardsCalendar.modalTitle')}
            importTitle={translate('TasksBoardsCalendar.importTitle')}
            importDescription={translate(
              'TasksBoardsCalendar.importDescription',
            )}
            modelURL="/ModelDownload/tasks.xlsx"
            uploadRoute="tasks/import"
            uploadFileName="tasks"
          />

          <FilterTasks />

          <Link to="/novaTarefa">
            <Button type="primary">
              {translate('TasksCalendar.createButton')}
            </Button>
          </Link>
        </Space>
      }
      bordered={false}
      style={{ width: '100%', padding: 30 }}
    >
      <FCCalendar
        events={events}
        Modal={
          <TaskCalendarModal
            event={event}
            visible={isModalVisible}
            setVisible={setIsModalVisible}
          />
        }
        handleClassNames={handleEventClassNames}
        handleEventClick={handleEventClick}
      />
    </Card>
  )
}

export default TasksCalendar
