export default {
  pt: {
    AddressList: {
      successMessage: 'Endereço deletado com sucesso!',
      errorMessage: 'Erro ao deletar endereço.',
      addressTypePersonal: 'Endereço Pessoal',
      addressTypeBusiness: 'Endereço Empresarial',
      addressTypeCharge: 'Endereço de Cobrança',
      popconfirmText: 'Deseja mesmo deletar endereço?',
      popconfirmTooltip: 'Deletar Endereço',
      zipcodeText: 'CEP:',
      stateText: 'Estado:',
      cityText: 'Cidade:',
      neighborhoodText: 'Bairro:',
      streetText: 'Logradouro:',
      numberText: 'Número:',
      complementText: 'Complemento:',
      typeText: 'Tipo:',
      typePersonalText: 'Pessoal',
      typeBusinessText: 'Empresarial',
      typeChargeText: 'Cobrança',
      observationText: 'Observação:',
    },
  },
  en: {
    AddressList: {
      successMessage: 'Address successfully deleted!',
      errorMessage: 'Error deleting address.',
      addressTypePersonal: 'Personal Address',
      addressTypeBusiness: 'Business Address',
      addressTypeCharge: 'Billing Address',
      popconfirmText: 'Do you really want to delete address?',
      popconfirmTooltip: 'Delete Address',
      zipcodeText: 'ZIPCODE:',
      stateText: 'State:',
      cityText: 'City:',
      neighborhoodText: 'Neighborhood:',
      streetText: 'Street:',
      numberText: 'Number:',
      complementText: 'Complement:',
      typeText: 'Type:',
      typePersonalText: 'Personal',
      typeBusinessText: 'Business',
      typeChargeText: 'Billing',
      observationText: 'Observation:',
    },
  },
  es: {
    AddressList: {
      successMessage: '¡Dirección eliminada con éxito!',
      errorMessage: 'Error al eliminar la dirección.',
      addressTypePersonal: 'Dirección Personal',
      addressTypeBusiness: 'Dirección de Negocios',
      addressTypeCharge: 'Dirección de Envio',
      popconfirmText: '¿Realmente desea eliminar la dirección?',
      popconfirmTooltip: 'Eliminar dirección',
      zipcodeText: 'Código postal:',
      stateText: 'Estado:',
      cityText: 'Ciudad:',
      neighborhoodText: 'Vecindario:',
      streetText: 'La carretera:',
      numberText: 'Número:',
      complementText: 'Complementar:',
      typeText: 'Escribe:',
      typePersonalText: 'Personal',
      typeBusinessText: 'Negocio',
      typeChargeText: 'Cobrar',
      observationText: 'Observación:',
    },
  },
}
