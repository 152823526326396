/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Col, Divider, Form, Input, Row } from 'antd'
import React from 'react'

import { cpf, cnpj } from 'cpf-cnpj-validator'
import { translate } from '~/utils/locale'
import { MaskedInput } from 'antd-mask-input'
import { useListParentClients } from '~/hooks/Clients/useListParentCustomer'

import { useParentCustomerFields } from '~/hooks/Clients/useParentCustomerFields'

// import { Container } from './styles';

const DetailsParentClients: React.FC = () => {
  const {
    companyName,
    setCompanyName,
    cnpjText,
    setCnpjText,
    cpfText,
    setCpfText,
  } = useListParentClients()
  const {
    fantasyName,
    setFantasyName,
    municipalRegistration,
    setMunicipalRegistration,
    stateRegistration,
    setStateRegistration,
    name,
    setName,
    email,
    setEmail,
    phone,
    setPhone,
  } = useParentCustomerFields()

  return (
    <>
      <Row gutter={14}>
        <Col span={12}>
          <Form.Item
            label={translate('ClientsPrincipalDetailsCreate.reasonLabel')}
            name="companyName"
            rules={[
              {
                required: true,
                message: translate('ClientsPrincipalDetailsCreate.reasonRule'),
              },
            ]}
          >
            <Input
              placeholder={translate(
                'ClientsPrincipalDetailsCreate.reasonPlaceholder',
              )}
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={translate('ClientsPrincipalDetailsCreate.fantasyLabel')}
            name="fantasyName"
            rules={[
              {
                required: true,
                message: translate('ClientsPrincipalDetailsCreate.fantasyRule'),
              },
            ]}
          >
            <Input
              placeholder={translate(
                'ClientsPrincipalDetailsCreate.fantasyPlaceholder',
              )}
              value={fantasyName}
              onChange={(e) => setFantasyName(e.target.value)}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={14}>
        <Col span={12}>
          <Form.Item
            label={translate('ClientsPrincipalDetailsCreate.cnpjLabel')}
            name="cnpj"
            rules={[
              {
                validator(_, value) {
                  if (value === undefined || value === null) {
                    return Promise.resolve()
                  }
                  const CnpjInput = value.replace(/[^0-9]/g, '')
                  if (CnpjInput.length === 14) {
                    if (cnpj.isValid(value)) {
                      return Promise.resolve()
                    }
                    // eslint-disable-next-line prefer-promise-reject-errors
                    return Promise.reject(
                      translate(
                        'ClientsPrincipalDetailsCreate.cnpjPlaceholder',
                      ),
                    )
                  }
                  return Promise.resolve()
                },
              },
            ]}
          >
            <MaskedInput
              mask={'00.000.000/0000-00'}
              value={cnpjText}
              onChange={(e) => setCnpjText(e.target.value)}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={translate('ClientsPrincipalDetailsCreate.cpfLabel')}
            name="cpf"
            rules={[
              {
                validator(_, value) {
                  if (value === undefined || value === null) {
                    return Promise.resolve()
                  }
                  const CpfInput = value.replace(/[^0-9]/g, '')
                  if (CpfInput.length === 11) {
                    if (cpf.isValid(value)) {
                      return Promise.resolve()
                    }
                    // eslint-disable-next-line prefer-promise-reject-errors
                    return Promise.reject(
                      translate('ClientsPrincipalDetailsCreate.cpfPlaceholder'),
                    )
                  }
                  return Promise.resolve()
                },
              },
            ]}
          >
            <MaskedInput
              mask={'000.000.000-00'}
              value={cpfText}
              onChange={(e) => setCpfText(e.target.value)}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={14}>
        <Col span={12}>
          <Form.Item
            label={translate(
              'ClientsPrincipalDetailsCreate.registrationMunicipalLabel',
            )}
            name="municipal_registration"
          >
            <Input
              placeholder={translate(
                'ClientsPrincipalDetailsCreate.registrationMunicipalPlaceholder',
              )}
              value={municipalRegistration}
              onChange={(e) => setMunicipalRegistration(e.target.value)}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={translate(
              'ClientsPrincipalDetailsCreate.registrationStateLabel',
            )}
            name="state_registration"
          >
            <Input
              placeholder={translate(
                'ClientsPrincipalDetailsCreate.registrationStatePlaceholder',
              )}
              value={stateRegistration}
              onChange={(e) => setStateRegistration(e.target.value)}
            />
          </Form.Item>
        </Col>
      </Row>

      <Divider>Dados de Contato</Divider>

      <Row gutter={14}>
        <Col span={8}>
          <Form.Item
            label={translate('ClientsPrincipalDetailsCreate.nameLabel')}
            name="name"
            rules={[
              {
                required: true,
                message: translate('ClientsPrincipalDetailsCreate.nameRule'),
              },
            ]}
          >
            <Input
              placeholder={translate(
                'ClientsPrincipalDetailsCreate.namePlaceholder',
              )}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={translate('ClientsPrincipalDetailsCreate.emailLabel')}
            name="email"
            rules={[
              {
                required: true,
                message: translate('ClientsPrincipalDetailsCreate.emailRule'),
              },
            ]}
          >
            <Input
              placeholder={translate(
                'ClientsPrincipalDetailsCreate.emailPlaceholder',
              )}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={translate('ClientsPrincipalDetailsCreate.phoneLabel')}
          >
            <MaskedInput
              mask={'(00) 00000-0000'}
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  )
}

export default DetailsParentClients
