/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable consistent-return */
import React, { useContext, createContext } from 'react'
import { useQuery } from 'react-query'

import validateWhatsappApi from '~/services/validateWhatsappApi'

import { useCompanies } from '~/hooks/Companies/useCompanies'
import { useLocale } from '~/hooks/locale/useLocale'

import { ShowError } from '~/utils/errors/apiErrors'
import { translate } from '~/utils/locale'

interface ServiceStatusProps {
  newTask: boolean
  executionTask: boolean
}

interface ServiceStatusContextData {
  serviceStatus?: ServiceStatusProps
  isLoading: boolean
  isFetching: boolean
  refetch: () => void
}

const ServiceStatusContext = createContext<ServiceStatusContextData>(
  {} as ServiceStatusContextData,
)

export const ServiceStatusProvider: React.FC = ({ children }) => {
  const { selectedCompany } = useCompanies()
  const { locale } = useLocale()

  const {
    data: serviceStatus,
    isLoading,
    isFetching,
    refetch,
  } = useQuery(
    [`service status${selectedCompany}`, selectedCompany],

    async () => {
      try {
        const responseNewTasks = await validateWhatsappApi.get(
          `62b869d5266eb0229fbf33aa`,
        )

        const responseExecutionTasks = await validateWhatsappApi.get(
          `62a7c9081823afa424a36c17`,
        )

        const data = {
          newTask: responseNewTasks.data.status === 'CONNECTED',
          executionTask: responseExecutionTasks.data.status === 'CONNECTED',
        }

        return data
      } catch (err: any) {
        ShowError(
          err.message,
          translate('useServiceStatus.getErrorMessage'),
          locale,
        )
      }
    },
  )

  return (
    <ServiceStatusContext.Provider
      value={{
        serviceStatus,
        isLoading,
        isFetching,
        refetch,
      }}
    >
      {children}
    </ServiceStatusContext.Provider>
  )
}

export function useServiceStatus(): ServiceStatusContextData {
  const context = useContext(ServiceStatusContext)

  if (!context) {
    throw new Error('useAuth must be used within an AutoProvider')
  }

  return context
}
