export default {
  pt: {
    ContributorUpdate: {
      randomEmailError: 'Preencha o campo nome para gerar um email aleatório',
      successMessage: 'Colaborador Atualizado com sucesso!',
      errorMessage: 'Erro ao editar colaborador.',
      cnpjInUseMessage:
        'Este CNPJ já se encontra em uso, isso não o impede de atualizar o colaborador.',
      cardTitle: 'Editar Colaborador',
      saveButton: 'Salvar',
      cancelButton: 'Cancelar',
      nameLabel: 'Nome',
      nameRule: 'Por favor insira um nome',
      emailLabel: 'E-mail',
      emailGenerateTooltip: 'Gerar email aleatório falso',
      emailRuleOne: 'Insira um email válido',
      emailRuleTwo: 'Por favor insira um email',
      genderLabel: 'Gênero',
      genderMasculine: 'Masculino',
      genderFeminine: 'Feminino',
      genderOther: 'Prefiro não dizer',
      birthLabel: 'Nascimento',
      whatsappRuleOne: 'Por favor insira um número Whatsapp',
      whatsappRuleTwo: 'Número não cadastrado no Whatsapp.',
      whatsappRuleThree: 'Insira um número válido.',
      whatsappPlaceholder: 'Insira o número de Whatsapp',
      personTypeLabel: 'Tipo de pessoa',
      personTypeJuridic: 'Pessoa Juridica',
      personTypePhysical: 'Pessoa Física',
      personTypeForeign: 'Estrangeiro',
      cpfLabel: 'CPF',
      passportLabel: 'Passaporte',
      cpfRuleOne: 'Por gentileza insira o CPF',
      cpfRuleTwo: 'Por gentileza insira um CPF Válido!',
      cnpjLabel: 'CNPJ',
      cnpjRuleOne: 'Por favor digite o CNPJ',
      cnpjRuleTwo: 'Por gentileza insira um CNPJ Válido!',
      idLabel: 'RG',
      observationLabel: 'Observações',
      additionalPhonesLabel: 'Telefones Adicionais',
      phoneLabel: 'Telefone',
      phoneRule: 'Insira um número válido.',
      phonePlaceholder: 'Insira o número de telefone',
      phoneTypeLabel: 'Tipo de telefone',
      phoneTypeRule: 'Por favor selecione o tipo de telefone',
      phoneTypeResidential: 'Residencial',
      phoneTypeCellphone: 'Celular',
      phoneTypeCommercial: 'Comercial',
      addPhoneButton: 'Adicionar Telefone',
      pricesTitle: 'Preços e Área de atuação',
      valueHourLabel: 'Valor R$ por hora trabalhada',
      valueKmLabel: 'Valor R$ por KM rodado',
      rangeLabel: 'Área de atuação em KM',
    },
  },
  en: {
    ContributorUpdate: {
      randomEmailError: 'Fill in the name field to generate a random email',
      successMessage: 'Contributor Updated successfully!',
      errorMessage: 'Error editing contributor.',
      cnpjInUseMessage:
        'This CNPJ is already in use, this does not prevent you from updating the collaborator.',
      cardTitle: 'Edit Contributor',
      saveButton: 'Save',
      cancelButton: 'Cancel',
      nameLabel: 'Name',
      nameRule: 'Please enter a name',
      emailLabel: 'Email',
      emailGenerateTooltip: 'Generate fake random email',
      emailRuleOne: 'Enter a valid email',
      emailRuleTwo: 'Please enter an email',
      genderLabel: 'Gender',
      genderMasculine: 'Male',
      genderFeminine: 'Female',
      genderOther: 'Prefer not to say',
      birthLabel: 'Birth',
      whatsappRuleOne: 'Please enter a Whatsapp number',
      whatsappRuleTwo: 'Number not registered on Shatsapp.',
      whatsappRuleThree: 'Please enter a valid number.',
      whatsappPlaceholder: 'Enter Whatsapp number',
      personTypeLabel: 'Person type',
      personTypeJuridic: 'Legal person',
      personTypePhysical: 'Physical person',
      personTypeForeign: 'Foreign',
      cpfLabel: 'CPF',
      passportLabel: 'Passport',
      cpfRuleOne: 'Please enter your CPF',
      cpfRuleTwo: 'Please enter a Valid CPF!',
      cnpjLabel: 'CNPJ',
      cnpjRuleOne: 'Please enter the CNPJ',
      cnpjRuleTwo: 'Please enter a Valid CNPJ!',
      idLabel: 'ID',
      groupLabel: 'Permission group belonging',
      groupRule: 'Permission group is required',
      observationLabel: 'Comments',
      additionalPhonesLabel: 'Additional Phones',
      phoneLabel: 'Phone',
      phoneRule: 'Please enter a valid number.',
      phonePlaceholder: 'Enter phone number',
      phoneTypeLabel: 'Phone type',
      phoneTypeRule: 'Please select phone type',
      phoneTypeResidential: 'Residential',
      phoneTypeCellphone: 'Cellphone',
      phoneTypeCommercial: 'Commercial',
      addPhoneButton: 'Add Phone',
      pricesTitle: 'Prices and Area of ​​Operation',
      valueHourLabel: 'Value R$ per hour worked',
      valueKmLabel: 'Value R$ per km driven',
      rangeLabel: 'Area of ​​operation in KM',
    },
  },
  es: {
    ContributorUpdate: {
      randomEmailError:
        'Complete el campo de nombre para generar un correo electrónico aleatorio',
      successMessage: 'Colaborador ¡Actualizado con éxito!',
      errorMessage: 'Error al editar colaborador.',
      cnpjInUseMessage:
        'Este CNPJ ya está en uso, esto no impide que usted pueda actualizar el colaborador.',
      cardTitle: 'Editar Colaborador',
      saveButton: 'Guardar',
      cancelButton: 'Cancelar',
      nameLabel: 'Nombre',
      nameRule: 'Por favor ingresa un nombre',
      emailLabel: 'Correo electrónico',
      emailGenerateTooltip: 'Generar correo electrónico aleatorio falso',
      emailRuleOne: 'Introduzca un correo electrónico válido',
      emailRuleTwo: 'Por favor ingrese un correo electrónico',
      genderLabel: 'Género',
      genderMasculine: 'Masculino',
      genderFeminine: 'Femenino',
      genderOther: 'Prefiero no decirlo',
      birthLabel: 'Nacimiento',
      whatsappRuleOne: 'Por favor ingrese un número de Whatsapp',
      whatsappRuleTwo: 'Número no registrado en whatsapp.',
      whatsappRuleThree: 'Por favor ingrese un número valido.',
      whatsappPlaceholder: 'Ingrese el número de whatsapp',
      personTypeLabel: 'Tipo de persona',
      personTypeJuridic: 'Pessoa Juridica',
      personTypePhysical: 'Pessoa Física',
      personTypeForeign: 'Extranjero',
      cpfLabel: 'CPF',
      passportLabel: 'Pasaporte',
      cpfRuleOne: 'Por favor ingrese su CPF',
      cpfRuleTwo: '¡Ingrese un CPF válido!',
      cnpjLabel: 'CNPJ',
      cnpjRuleOne: 'Por favor ingrese el CNPJ',
      cnpjRuleTwo: '¡Ingrese un CNPJ válido!',
      idLabel: 'ID',
      groupLabel: 'Pertenencia al grupo de permisos',
      groupRule: 'Se requiere grupo de permisos',
      observationLabel: 'Comentarios',
      additionalPhonesLabel: 'Teléfonos adicionales',
      phoneLabel: 'Teléfono',
      phoneRule: 'Por favor ingrese un número valido.',
      phonePlaceholder: 'Ingresa número telefónico',
      phoneTypeLabel: 'Tipo de telefono',
      phoneTypeRule: 'Seleccione el tipo de teléfono',
      phoneTypeResidential: 'Residencial',
      phoneTypeCellphone: 'Celular',
      phoneTypeCommercial: 'Comercial',
      addPhoneButton: 'Añadir teléfono',
      pricesTitle: 'Precios y Área de Operación',
      valueHourLabel: 'Valor R$ por hora trabajada',
      valueKmLabel: 'Valor R$ por km recorrido',
      rangeLabel: 'Área de operación en KM',
    },
  },
}
