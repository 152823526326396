export default {
  pt: {
    EquipmentsTemplateTable: {
      templateColumn: 'Template',
      attributeColumn: 'Atributos',
      attributeText: 'atributo',
      attributesText: 'atributos',
      actionsColumn: 'Ações',
      deletePopconfirm: 'Deseja mesmo deletar o template?',
      deleteTooltip: 'Deletar',
    },
  },
  en: {
    EquipmentsTemplateTable: {
      templateColumn: 'Template',
      attributeColumn: 'Attributes',
      attributeText: 'attribute',
      attributesText: 'attributes',
      actionsColumn: 'Actions',
      deletePopconfirm: 'Do you really want to delete the template?',
      deleteTooltip: 'Delete',
    },
  },
  es: {
    EquipmentsTemplateTable: {
      templateColumn: 'Plantilla',
      attributeColumn: 'Atributos',
      attributeText: 'atributo',
      attributesText: 'atributos',
      actionsColumn: 'Acciones',
      deletePopconfirm: '¿Realmente desea eliminar la plantilla?',
      deleteTooltip: 'Borrar',
    },
  },
}
