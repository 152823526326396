import React from 'react'

import { ColumnsType } from 'antd/lib/table'
import { Popconfirm, Space, Table, Tooltip, Button } from 'antd'
import { DeleteFilled, LoadingOutlined } from '@ant-design/icons'
import { useSegments } from '~/hooks/Segments/useSegments'

import { Container } from './styles'
import UpdateSegments from '../Update'
import { translate } from '~/utils/locale'

interface SegmentsProps {
  id: number
  title: string
  description: string
}

const SegmentsTable: React.FC = () => {
  const { segments, deleteSegments, isLoading } = useSegments()

  const columns: ColumnsType<SegmentsProps> = [
    {
      title: translate('SegmentsTable.nameColumn'),
      align: 'left',
      dataIndex: 'title',
      key: 'title',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: translate('SegmentsTable.descriptionColumn'),
      align: 'center',
      key: 'description',
      dataIndex: 'description',
    },
    {
      title: translate('SegmentsTable.actionsColumn'),
      align: 'center',
      key: 'remove',
      render: (record) => (
        <Space size="middle">
          <UpdateSegments dataSegment={record} />
          <Popconfirm
            title={translate('SegmentsTable.deletePopconfirm')}
            onConfirm={() => deleteSegments(record.id)}
          >
            <Tooltip title={translate('SegmentsTable.deleteTooltip')}>
              <Button ghost danger shape="circle" icon={<DeleteFilled />} />
            </Tooltip>
          </Popconfirm>
        </Space>
      ),
    },
  ]

  return (
    <Container>
      <Table
        rowKey={(record) => record.id}
        loading={{
          indicator: <LoadingOutlined />,
          spinning: isLoading,
          size: 'large',
        }}
        columns={columns}
        dataSource={segments}
        scroll={{ x: 1000 }}
        pagination={{
          showTotal: (total, range) => `${range[0]} - ${range[1]} / ${total}`,
        }}
      />
    </Container>
  )
}

export default SegmentsTable
