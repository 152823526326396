import React from 'react'
import { Helmet } from 'react-helmet'

import { Container } from './styles'

import SkillBoard from '~/components/DataManipulation/Skill'
import { translate } from '~/utils/locale'

const Skills: React.FC = () => {
  return (
    <Container>
      <Helmet>
        <title>{translate('Skills.helmetTitle')}</title>
      </Helmet>

      <SkillBoard />
    </Container>
  )
}

export default Skills
