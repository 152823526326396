/* eslint-disable @typescript-eslint/no-explicit-any */
import { EditOutlined } from '@ant-design/icons'
import { Modal, Button, Form, Input, Tooltip, Space } from 'antd'
import React, { useState } from 'react'
import { CompactPicker } from 'react-color'

import { useStatus } from '~/hooks/Status/useStatus'
import { translate } from '~/utils/locale'

import { Container, ContainerButton } from './styles'

interface UpdateStatusProps {
  title: string
  id: string
  type: string
  color: string
  description: string
}

interface UpdateStatusData {
  dataStatus: UpdateStatusProps
}

const UpdateStatus: React.FC<UpdateStatusData> = ({ dataStatus }) => {
  const { updateStatus } = useStatus()
  const [isModalVisible, setIsModalVisible] = useState(false)

  const [colors, setColors] = useState(
    dataStatus.color !== null ? dataStatus.color : '#108ee9',
  )

  const initialData = {
    title: dataStatus.title,
    description: dataStatus.description,
  }

  function onUpdateStatus(valueForm: any) {
    try {
      const dataForm = {
        color: colors,
        title: valueForm.title,
        description: valueForm.description || '',
      }

      updateStatus(dataForm)
      setIsModalVisible(false)
    } catch (err) {
      setIsModalVisible(false)
    }
  }

  const showModal = () => {
    setIsModalVisible(true)
  }

  const closeModal = () => {
    setIsModalVisible(false)
  }

  return (
    <Container>
      <Tooltip title={translate('StatusUpdate.editTooltip')}>
        <Button
          onClick={showModal}
          id="btn-edit-evaluation"
          type="primary"
          icon={<EditOutlined />}
        >
          {translate('StatusUpdate.editButton')}
        </Button>
      </Tooltip>
      <Modal
        title={translate('StatusUpdate.modalTitle')}
        open={isModalVisible}
        onOk={closeModal}
        onCancel={closeModal}
        footer={[
          <Space key="buttons">
            <Button key="back" onClick={closeModal}>
              {translate('StatusUpdate.closeButton')}
            </Button>
            <ContainerButton>
              <Button
                key="save"
                type="primary"
                form="editStatus"
                htmlType="submit"
                id="btn_save_update_status"
              >
                {translate('StatusUpdate.saveButton')}
              </Button>
            </ContainerButton>
          </Space>,
        ]}
      >
        <Form
          id="editStatus"
          layout="vertical"
          initialValues={initialData}
          onFinish={onUpdateStatus}
        >
          <Form.Item
            label={translate('StatusUpdate.titleLabel')}
            name="title"
            rules={[
              {
                required: true,
                message: translate('StatusUpdate.titleRule'),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={translate('StatusUpdate.descriptionLabel')}
            name="description"
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item label={translate('StatusUpdate.colorLabel')} name="color">
            <CompactPicker
              color={colors}
              onChangeComplete={(colorSelect) => {
                setColors(colorSelect.hex)
              }}
            />
          </Form.Item>
        </Form>
      </Modal>
    </Container>
  )
}

export default UpdateStatus
