/* eslint-disable react/destructuring-assignment */
import React from 'react'

import { Table, Space, Tooltip, Popconfirm, Button } from 'antd'
import { DeleteFilled, LoadingOutlined } from '@ant-design/icons'
import { ColumnsType } from 'antd/es/table'
import SkillModal from '~/components/DataManipulation/Skill/SkillModal'
import { useSkills } from '~/hooks/Skills/useSkills'
import { translate } from '~/utils/locale'

interface DataType {
  id: string
  name: string
  description: string
  company: {
    id: string
    name: string
  }
}

interface TableProps {
  data: DataType[]
  loading: boolean
}

const SkillsTable: React.FC<TableProps> = ({ data, loading }) => {
  const { deleteSkill } = useSkills()
  const columns: ColumnsType<DataType> = [
    {
      title: translate('SkillSkillsTable.nameColumn'),
      dataIndex: 'name',
      key: 'name',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: translate('SkillSkillsTable.descriptionColumn'),
      dataIndex: 'description',
      key: 'contact',
    },
    {
      title: translate('SkillSkillsTable.removeColumn'),
      align: 'center',
      key: 'remove',
      render: (record) => (
        <Space size="middle">
          <SkillModal isUpdate SkillsInfo={record} />
          <Popconfirm
            title={translate('SkillSkillsTable.deletePopconfirm')}
            onConfirm={() => deleteSkill(record.id)}
          >
            <Tooltip title={translate('SkillSkillsTable.deleteTooltip')}>
              <Button ghost danger shape="circle" icon={<DeleteFilled />} />
            </Tooltip>
          </Popconfirm>
        </Space>
      ),
    },
  ]

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        'selectedRows: ',
        selectedRows,
      )
    },
    getCheckboxProps: (record: DataType) => ({
      name: record.name,
    }),
  }

  return (
    <>
      <Table
        rowKey={(record) => record.id}
        rowSelection={{ ...rowSelection }}
        loading={{
          indicator: <LoadingOutlined />,
          spinning: loading,
          size: 'large',
        }}
        columns={columns}
        dataSource={data}
        scroll={{ x: 1000 }}
        pagination={{
          showTotal: (total, range) => `${range[0]} - ${range[1]} / ${total}`,
        }}
      />
    </>
  )
}

export default SkillsTable
