/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, createContext, useState } from 'react'
import { useQuery } from 'react-query'

import { message } from 'antd'

import { useCompanies } from '~/hooks/Companies/useCompanies'
import { useLocale } from '~/hooks/locale/useLocale'

import { ShowError } from '~/utils/errors/apiErrors'
import { translate } from '~/utils/locale'
import api from '~/services/api'

interface ShowPaymentProps {
  id: string
  dateCreated: string
  value: number
  description: string
  billingType: string
  status: string
  dueDate: string
  invoiceUrl: string
  customer: string
  paymentDate: string
  nextDueDate: string
  subscription: string
  creditCard: {
    creditCardNumber: string
  }
}

interface ShowPaymentContextData {
  showPayment?: ShowPaymentProps[]
  deletePayment(id: string): void
  isLoading: boolean
  refetch: () => void
  isFetching: boolean
  setDisabled(value: boolean): void
  disabled?: boolean
}

const ShowPaymentContext = createContext<ShowPaymentContextData>(
  {} as ShowPaymentContextData,
)

export const ShowPaymentProvider: React.FC = ({ children }) => {
  const { selectedCompany } = useCompanies()
  const { locale } = useLocale()

  const [disabled, setDisabled] = useState(true)

  const {
    data: showPayment,
    isLoading,
    refetch,
    isFetching,
  } = useQuery(
    [`Payment Details User${selectedCompany}`, selectedCompany],

    async () => {
      try {
        const response = await api.get(`payment/user`)

        const { data } = response

        return data.data
      } catch (err: any) {
        ShowError(err.message, translate('usePause.getErrorMessage'), locale)
      }
    },
  )

  async function deletePayment(id: string) {
    try {
      await api.delete(`payment/${id}`)

      refetch()
      message.success('Assinatura cancelada com sucesso!')
    } catch (err: any) {
      ShowError(err.message, translate('usePause.deleteErrorMessage'), locale)
    }
  }

  return (
    <ShowPaymentContext.Provider
      value={{
        showPayment,
        deletePayment,
        isLoading,
        refetch,
        isFetching,
        setDisabled,
        disabled,
      }}
    >
      {children}
    </ShowPaymentContext.Provider>
  )
}

export function useShowPayment(): ShowPaymentContextData {
  const context = useContext(ShowPaymentContext)

  if (!context) {
    throw new Error('useAuth must be used within an AutoProvider')
  }

  return context
}
