export default {
  pt: {
    useStatus: {
      getErrorMessage: 'Erro ao visualizar status de colaboradores.',
      updateSuccessMessage: 'Status atualizado com sucesso!',
      updateErrorMessage: 'Erro ao atualizar status.',
    },
  },
  en: {
    useStatus: {
      getErrorMessage: 'Error viewing contributor status.',
      updateSuccessMessage: 'Status updated successfully!',
      updateErrorMessage: 'Error updating status.',
    },
  },
  es: {
    useStatus: {
      getErrorMessage: 'Error al ver el estado del colaborador.',
      updateSuccessMessage: '¡Estado actualizado con éxito!',
      updateErrorMessage: 'Error al actualizar el estado.',
    },
  },
}
