export default {
  pt: {
    TeamsTable: {
      titleColumn: 'Nome',
      responsibleColumn: 'Responsável',
      teamUserColumn: 'Total de colaboradores',
      contributorText: 'colaborador',
      contributorsText: 'colaboradores',
      actionsColumn: 'Ações',
      viewTooltip: 'Visualizar Equipe',
      deletePopconfirm: 'Deseja mesmo deletar a equipe?',
      deleteTooltip: 'Deletar',
    },
  },
  en: {
    TeamsTable: {
      titleColumn: 'Name',
      responsibleColumn: 'Responsible',
      teamUserColumn: 'Total Contributors',
      contributorText: 'contributor',
      contributorsText: 'contributors',
      actionsColumn: 'Actions',
      viewTooltip: 'View Team',
      deletePopconfirm: 'Do you really want to delete the team?',
      deleteTooltip: 'Delete',
    },
  },
  es: {
    TeamsTable: {
      titleColumn: 'Nombre',
      responsibleColumn: 'Responsable',
      teamUserColumn: 'Colaboradores Totales',
      contributorText: 'colaborador',
      contributorsText: 'colaboradores',
      actionsColumn: 'Acciones',
      viewTooltip: 'Ver Equipo',
      deletePopconfirm: '¿De verdad quieres eliminar el equipo?',
      deleteTooltip: 'Borrar',
    },
  },
}
