import { LoadingOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { Card, Space, Button } from 'antd'
import Text from 'antd/lib/typography/Text'
import React from 'react'
import { Link } from 'react-router-dom'
import { useListEquipments } from '~/hooks/Equipments/ListEquipmentsHooks'
import { translate } from '~/utils/locale'
import EquipmentsFilter from './Filter'
import EquipmentsTable from './Table'

const EquipmentsBoard: React.FC = () => {
  const { isLoading, isFetching, equipmentsTotal } = useListEquipments()

  return (
    <Card
      title={
        <Space size="large">
          <b>{translate('EquipmentsComponent.cardTitle')}</b>
          {isLoading ? (
            <LoadingOutlined spin />
          ) : (
            <Text
              type="secondary"
              style={{ fontWeight: 'normal', fontSize: 14 }}
            >
              {equipmentsTotal !== undefined ? (
                <>
                  {equipmentsTotal === 1
                    ? `${translate(
                        'EquipmentsComponent.totalText',
                      )} ${equipmentsTotal} ${translate(
                        'EquipmentsComponent.equipmentText',
                      )}`
                    : `${translate(
                        'EquipmentsComponent.totalText',
                      )} ${equipmentsTotal} ${translate(
                        'EquipmentsComponent.equipmentsText',
                      )}`}
                </>
              ) : (
                ''
              )}
            </Text>
          )}
          {!isLoading && isFetching && <LoadingOutlined spin />}
        </Space>
      }
      extra={
        <Space size="small">
          <EquipmentsFilter />

          <Link to="/equipamentos/novo">
            <Button type="primary" icon={<PlusCircleOutlined />}>
              {translate('EquipmentsComponent.newEquipmentButton')}
            </Button>
          </Link>
        </Space>
      }
      bordered={false}
      style={{ width: '100%', padding: 30 }}
    >
      <EquipmentsTable />
    </Card>
  )
}

export default EquipmentsBoard
