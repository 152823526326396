/* eslint-disable camelcase */
import {
  CarOutlined,
  ClockCircleOutlined,
  FileUnknownOutlined,
  LoadingOutlined,
  ProfileOutlined,
} from '@ant-design/icons'
import { Button, Space, Table, Tooltip, Typography } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import React from 'react'
import NumberFormat from 'react-number-format'
import { Link } from 'react-router-dom'
import CurrencyFormatter from '~/components/DataManipulation/Misc/CurrencyFormater'
import { useListParentClients } from '~/hooks/Clients/useListParentCustomer'
import { translate } from '~/utils/locale'
import UpdateFinancialParentCustomers from '../Update'
import {
  FinancialAddressProps,
  FinancialContactProps,
} from '~/hooks/Clients/useParentCustomer'

export interface ParentClientsFinancialProps {
  task_value?: number
  amount_hours?: number
  amount_hours_additional?: number
  number_km?: number
  number_km_additional?: number
}

interface Customers {
  id: string
}

export interface ParentClientsProps {
  _id: string
  cnpj: string
  cpf: string
  fantasyName: string
  companyName: string
  municipal_registration?: string
  state_registration?: string
  contact: FinancialContactProps
  address: FinancialAddressProps
  financial: ParentClientsFinancialProps
  customers?: Customers[]
}

const TableFinancialCustomers: React.FC = () => {
  const {
    parentClients,
    isFetching,
    setNumberPage,
    setPageSize,
    clientsTotal,
  } = useListParentClients()
  const { Text } = Typography

  const columns: ColumnsType<ParentClientsProps> = [
    {
      title: translate('ClientsParentTable.reasonColumn'),
      align: 'left',
      dataIndex: 'fantasyName',
      key: 'fantasyName',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (name: string, record) => (
        <Link to={`/clientePrincipal/visualizar/${record._id}`}>{name}</Link>
      ),
    },
    {
      title: translate('ClientsParentTable.documentColumn'),
      dataIndex: 'cnpj',
      align: 'center',
      key: 'cnpj',
      render: (cnpj: string) => (
        <>
          {!cnpj ? (
            <Text type="secondary">
              <Space size="small">
                <FileUnknownOutlined />
                N/A
              </Space>
            </Text>
          ) : (
            <Text>
              <NumberFormat
                value={cnpj}
                displayType="text"
                format="##.###.###/####-##"
              />
            </Text>
          )}
        </>
      ),
    },
    {
      title: translate('ClientsPrincipalFinancialCreate.valueLabel'),
      dataIndex: ['financial', 'task_value'],
      key: 'task_value',
      render: (task_value: number) => (
        <Typography.Text>
          <CurrencyFormatter value={task_value} />
        </Typography.Text>
      ),
    },
    {
      title: translate('ClientsPrincipalFinancialCreate.hoursLabel'),
      dataIndex: ['financial', 'amount_hours'],
      key: 'amount_hours',
      render: (amount_hours: number) => (
        <Typography.Text>
          <ClockCircleOutlined /> {amount_hours}:00
        </Typography.Text>
      ),
    },
    {
      title: translate('ClientsPrincipalFinancialCreate.valueHoursLabel'),
      dataIndex: ['financial', 'amount_hours_additional'],
      render: (amount_hours_additional: number) => (
        <Typography.Text>
          <CurrencyFormatter value={amount_hours_additional} />
        </Typography.Text>
      ),
    },
    {
      title: translate('ClientsPrincipalFinancialCreate.kmLabel'),
      dataIndex: ['financial', 'number_km'],
      key: 'number_km',
      render: (number_km: number) => (
        <Typography.Text>
          <CarOutlined /> {number_km} km
        </Typography.Text>
      ),
    },
    {
      title: translate('ClientsPrincipalFinancialCreate.valueKmLabel'),
      dataIndex: ['financial', 'number_km_additional'],
      key: 'number_km_additional',
      render: (number_km_additional: number) => (
        <Typography.Text>
          <CurrencyFormatter value={number_km_additional} />
        </Typography.Text>
      ),
    },
    {
      title: translate('ClientsTable.columnActions'),
      align: 'center',
      key: 'remove',
      render: (record) => (
        <Space size="middle">
          <Tooltip title={translate('ClientsTable.viewTooltip')}>
            <Link to={`/clientePrincipal/visualizar/${record._id}`}>
              <Button
                ghost
                type="primary"
                shape="circle"
                icon={<ProfileOutlined />}
              />
            </Link>
          </Tooltip>
          <UpdateFinancialParentCustomers data={record} />
        </Space>
      ),
    },
  ]

  return (
    <Table
      rowKey={(record) => record._id}
      loading={{
        indicator: <LoadingOutlined />,
        spinning: isFetching,
        size: 'large',
      }}
      pagination={{
        total: clientsTotal,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '50', '100', '200'],
        defaultPageSize: 10,
        showTotal: (total, range) => `${range[0]} - ${range[1]} / ${total}`,
        onChange: (page, pageSize) => {
          setPageSize(pageSize!)
          setNumberPage(page)
        },
      }}
      columns={columns}
      dataSource={parentClients}
      scroll={{ x: 1000 }}
    />
  )
}

export default TableFinancialCustomers
