export default {
  pt: {
    UsersComponent: {
      cardTitle: 'Usuários',
      totalText: 'Total de',
      userText: 'usuário',
      usersText: 'usuários',
    },
  },
  en: {
    UsersComponent: {
      cardTitle: 'Users',
      totalText: 'Total of',
      userText: 'user',
      usersText: 'users',
    },
  },
  es: {
    UsersComponent: {
      cardTitle: 'Usuarios',
      totalText: 'Total de',
      userText: 'usuario',
      usersText: 'usuarios',
    },
  },
}
