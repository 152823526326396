import React from 'react'
import { Helmet } from 'react-helmet'
import DetailsPayment from '~/components/DataManipulation/Payment/Details'
import { ClientPaymentProvider } from '~/hooks/Payment/useClientPayment'
import { ShowPaymentProvider } from '~/hooks/Payment/useShowPayment'

// import { Container } from './styles';

const ShowPayment: React.FC = () => {
  return (
    <ShowPaymentProvider>
      <Helmet>
        <title>Visualizar pagamento</title>
      </Helmet>

      <ClientPaymentProvider>
        <DetailsPayment />
      </ClientPaymentProvider>
    </ShowPaymentProvider>
  )
}

export default ShowPayment
