import React from 'react'
import CreateParentClient from '~/components/DataManipulation/Clients/Parent/Create'
import { ListParentClientsProvider } from '~/hooks/Clients/useListParentCustomer'
import { ParentCustomerFieldsProvider } from '~/hooks/Clients/useParentCustomerFields'
import { TaskTypeProvider } from '~/hooks/Tasks/TaskType/useTaskType'

// import { Container } from './styles';

const NewParentClients: React.FC = () => {
  return (
    <ListParentClientsProvider>
      <ParentCustomerFieldsProvider>
        <TaskTypeProvider>
          <CreateParentClient />
        </TaskTypeProvider>
      </ParentCustomerFieldsProvider>
    </ListParentClientsProvider>
  )
}

export default NewParentClients
