/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-curly-newline */
import React, { useCallback, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import cep from 'cep-promise'
import { MapContainer, TileLayer, Marker, useMap } from 'react-leaflet'
import { LatLngExpression } from 'leaflet'

import { Modal, Form, Button, Input, Row, Col, Select, message } from 'antd'
import { FileAddOutlined } from '@ant-design/icons'
import { MaskedInput } from 'antd-mask-input'
import { ValidateStatus } from 'antd/lib/form/FormItem'

import api from '~/services/api'
import geolocationApi from '~/services/geolocationApi'

import { useCompanies } from '~/hooks/Companies/useCompanies'
import { useScale } from '~/hooks/Scales/useScale'
import { useLocale } from '~/hooks/locale/useLocale'

import { ShowError } from '~/utils/errors/apiErrors'
import { translate } from '~/utils/locale'

interface NewAddressData {
  zip_code: string
  type: string
  street: string
  number: string
  complement: string
  neighborhood: string
  city: string
  state: string
  notes: string
  location: string
  urlGoogle?: string
}

interface MapUpdateFunctionProps {
  center: LatLngExpression
  zoom: number
}

const ScaleGeneralAddressModal: React.FC = () => {
  const [latitude, setLatitude] = useState<any>(-14.235004)
  const [longitude, setLongitude] = useState<any>(-51.925282)

  const [zoomLevel, setZoomLevel] = useState(3)

  const [state, setState] = useState('')
  const [city, setCity] = useState('')
  const [street, setStreet] = useState('')

  const [hidden, setHidden] = useState(true)

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [mapSearch, setMapSearch] = useState(false)
  const [mapValidate, setMapValidate] = useState(false)

  const [findingCEPStatus, setFindingCEPStatus] = useState('' as ValidateStatus)

  const { scaleId } = useParams<{ scaleId: string }>()

  const { selectedCompany } = useCompanies()
  const { scaleRefetch } = useScale()
  const { locale } = useLocale()

  const [form] = Form.useForm()

  const formatComma = (value: string, field: 'latitude' | 'longitude') => {
    const valueFormatted = value.replace(/,/g, '.')

    if (
      valueFormatted !== '-' &&
      valueFormatted !== '.' &&
      valueFormatted !== '-.'
    ) {
      if (field === 'latitude') {
        setLatitude(valueFormatted)
      } else if (field === 'longitude') {
        setLongitude(valueFormatted)
      }
    }
  }

  const stateOptions = [
    { label: 'Acre', value: 'AC' },
    { label: 'Alagoas', value: 'AL' },
    { label: 'Amapá', value: 'AP' },
    { label: 'Amazonas', value: 'AM' },
    { label: 'Bahia', value: 'BA' },
    { label: 'Ceará', value: 'CE' },
    { label: 'Espírito Santo', value: 'ES' },
    { label: 'Goiás', value: 'GO' },
    { label: 'Maranhão', value: 'MA' },
    { label: 'Mato Grosso', value: 'MT' },
    { label: 'Mato Grosso do Sul', value: 'MS' },
    { label: 'Minas Gerais', value: 'MG' },
    { label: 'Pará', value: 'PA' },
    { label: 'Paraíba', value: 'PB' },
    { label: 'Paraná', value: 'PR' },
    { label: 'Pernambuco', value: 'PE' },
    { label: 'Piauí', value: 'PI' },
    { label: 'Rio de Janeiro', value: 'RJ' },
    { label: 'Rio Grande do Norte', value: 'RN' },
    { label: 'Rio Grande do Sul', value: 'RS' },
    { label: 'Rondônia', value: 'RO' },
    { label: 'Roraima', value: 'RR' },
    { label: 'Santa Catarina', value: 'SC' },
    { label: 'São Paulo', value: 'SP' },
    { label: 'Sergipe', value: 'SE' },
    { label: 'Tocantins', value: 'TO' },
    { label: 'Distrito Federal', value: 'DF' },
  ]

  useEffect(() => {
    if (latitude !== -14.235004 && longitude !== -51.925282) {
      setZoomLevel(17)
    }
  }, [latitude, longitude])

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleOk = () => {
    form.submit()
  }

  const handleCancel = useCallback(() => {
    form.resetFields()
    setLatitude(-14.235004)
    setLongitude(-51.925282)
    setFindingCEPStatus('' as ValidateStatus)
    setCity('')
    setState('')
    setStreet('')
    setZoomLevel(3)
    setIsModalVisible(false)
    setMapValidate(false)
    setHidden(true)
  }, [form])

  const onCreate = useCallback(
    async (data: NewAddressData) => {
      try {
        setConfirmLoading(true)

        data.zip_code = data.zip_code.replace(/[^0-9]/g, '')

        data.location = `(${latitude}, ${longitude})`

        const apiCreateResponse = await api.post(
          `/company/${selectedCompany}/address`,
          {
            zip_code: data.zip_code,
            street: data.street,
            number: data.number,
            complement: data.complement,
            neighborhood: data.neighborhood,
            city: data.city,
            state: data.state,
            location: data.location,
          },
        )

        await api.put(`/company/${selectedCompany}/schedule/${scaleId}`, {
          address_id: apiCreateResponse.data.id,
        })

        message.success(
          translate('ScalesGeneralAddressModal.createSuccessMessage'),
        )

        scaleRefetch()
        setMapValidate(false)

        handleCancel()
      } catch (err: any) {
        ShowError(
          err.message,
          translate('ScalesGeneralAddressModal.createErrorMessage'),
          locale,
        )

        setConfirmLoading(false)
      }
    },
    [
      selectedCompany,
      scaleId,
      latitude,
      longitude,
      scaleRefetch,
      handleCancel,
      locale,
    ],
  )

  const searchCEP = useCallback(
    async (data: string) => {
      try {
        setFindingCEPStatus('validating')
        const cepInfo = await cep(data)

        form.setFieldsValue({
          state: cepInfo.state,
          city: cepInfo.city,
          neighborhood: cepInfo.neighborhood,
          street: cepInfo.street,
        })

        setState(cepInfo.state)
        setCity(cepInfo.city)
        setStreet(cepInfo.street)

        setFindingCEPStatus('success')
      } catch (error) {
        message.error(translate('ScalesGeneralAddressModal.searchErrorMessage'))
        form.resetFields()
        setState('')
        setCity('')
        setStreet('')
        setFindingCEPStatus('error')
      }
    },
    [form],
  )

  const searchMap = useCallback(async () => {
    try {
      setMapSearch(true)
      const params = {
        street,
        city,
        state,
        format: 'json',
      }

      const { data } = await geolocationApi.get('search', { params })

      if (data.length > 0) {
        setHidden(true)
        form.setFieldsValue({
          latitude: data[0].lat,
          longitude: data[0].lon,
        })

        setLatitude(data[0].lat)
        setLongitude(data[0].lon)
        setZoomLevel(17)
      } else {
        setHidden(false)

        form.setFieldsValue({
          latitude: '',
          longitude: '',
        })

        message.warning(
          translate('ScalesGeneralAddressModal.mapWarningMessage'),
          10,
        )
      }

      setMapValidate(true)
      setMapSearch(false)
    } catch (error) {
      message.error(translate('ScalesGeneralAddressModal.mapErrorMessage'))
      setMapValidate(true)
      setMapSearch(false)
    }
  }, [form, city, state, street])

  function ChangeView({ center, zoom }: MapUpdateFunctionProps) {
    const map = useMap()
    map.setView(center, zoom)
    return null
  }

  return (
    <>
      <Button type="primary" icon={<FileAddOutlined />} onClick={showModal}>
        {translate('ScalesGeneralAddressModal.newButton')}
      </Button>
      <Modal
        width={1200}
        title={translate('ScalesGeneralAddressModal.modalTitle')}
        okText={translate('ScalesGeneralAddressModal.okText')}
        open={isModalVisible}
        confirmLoading={confirmLoading}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Row>
          <Col span={12}>
            <Form form={form} onFinish={onCreate} layout="vertical">
              <Row gutter={14}>
                <Col span={6}>
                  <Form.Item
                    label={translate('ScalesGeneralAddressModal.zipcodeLabel')}
                    name="zip_code"
                    hasFeedback
                    validateStatus={findingCEPStatus}
                    rules={[
                      {
                        required: true,
                        message: translate(
                          'ScalesGeneralAddressModal.zipcodeRule',
                        ),
                      },
                    ]}
                  >
                    <MaskedInput
                      onChange={(e) => {
                        const cepInput = e.target.value.replace(/\D/g, '')
                        if (cepInput.length === 8) {
                          searchCEP(e.target.value)
                        }
                      }}
                      mask={'00000-000'}
                    />
                  </Form.Item>
                </Col>

                <Col span={6}>
                  <a
                    href="https://buscacepinter.correios.com.br/app/endereco/index.php"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Form.Item
                      label={translate(
                        'ScalesGeneralAddressModal.zipcodeInfoLabel',
                      )}
                    >
                      <Button type="primary">
                        {translate(
                          'ScalesGeneralAddressModal.zipcodeInfoButton',
                        )}
                      </Button>
                    </Form.Item>
                  </a>
                </Col>

                <Col span={12}>
                  <Form.Item
                    label={translate('ScalesGeneralAddressModal.stateLabel')}
                    name="state"
                  >
                    <Select
                      style={{ width: '100%' }}
                      className="menu_input"
                      optionFilterProp="children"
                      onChange={(e) => setState(String(e))}
                      options={stateOptions}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={14}>
                <Col span={14}>
                  <Form.Item
                    label={translate('ScalesGeneralAddressModal.cityLabel')}
                    name="city"
                  >
                    <Input onChange={(e) => setCity(e.target.value)} />
                  </Form.Item>
                </Col>

                <Col span={10}>
                  <Form.Item
                    label={translate(
                      'ScalesGeneralAddressModal.neighborhoodLabel',
                    )}
                    name="neighborhood"
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={14}>
                <Col span={18}>
                  <Form.Item
                    label={translate('ScalesGeneralAddressModal.streetLabel')}
                    name="street"
                  >
                    <Input onChange={(e) => setStreet(e.target.value)} />
                  </Form.Item>
                </Col>

                <Col span={6}>
                  <Form.Item
                    label={translate('ScalesGeneralAddressModal.numberLabel')}
                    name="number"
                    rules={[
                      {
                        required: true,
                        message: translate(
                          'ScalesGeneralAddressModal.numberRule',
                        ),
                      },
                    ]}
                  >
                    <Input maxLength={5} />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={14}>
                <Col span={12}>
                  <Form.Item
                    label={translate(
                      'ScalesGeneralAddressModal.complementLabel',
                    )}
                    name="complement"
                  >
                    <Input />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    label={translate('ScalesGeneralAddressModal.mapLabel')}
                    name="mapa"
                    rules={[
                      {
                        required: true,
                        validator: () => {
                          if (mapValidate) {
                            return Promise.resolve()
                          }
                          return Promise.reject(
                            translate('ScalesGeneralAddressModal.mapRule'),
                          )
                        },
                      },
                    ]}
                  >
                    <Button
                      key="submit"
                      type="primary"
                      disabled={
                        !!(state === '' || street === '' || city === '')
                      }
                      loading={mapSearch}
                      onClick={searchMap}
                    >
                      {translate('ScalesGeneralAddressModal.mapButton')}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={14}>
                <Col span={8}>
                  <Form.Item
                    hidden={hidden}
                    label={translate('ScalesGeneralAddressModal.latitudeLabel')}
                    name="latitude"
                    initialValue={latitude}
                    rules={[
                      {
                        required: !hidden,
                        message: translate(
                          'ScalesGeneralAddressModal.latitudeRule',
                        ),
                      },
                    ]}
                  >
                    <Input
                      onChange={(e) => formatComma(e.target.value, 'latitude')}
                    />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    hidden={hidden}
                    label={translate(
                      'ScalesGeneralAddressModal.longitudeLabel',
                    )}
                    name="longitude"
                    initialValue={longitude}
                    rules={[
                      {
                        required: !hidden,
                        message: translate(
                          'ScalesGeneralAddressModal.longitudeRule',
                        ),
                      },
                    ]}
                  >
                    <Input
                      onChange={(e) => formatComma(e.target.value, 'longitude')}
                    />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <a
                    href="https://www.latlong.net/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Form.Item
                      label={translate(
                        'ScalesGeneralAddressModal.coordinatesInfoLabel',
                      )}
                      hidden={hidden}
                    >
                      <Button type="primary">
                        {translate(
                          'ScalesGeneralAddressModal.coordinatesInfoRule',
                        )}
                      </Button>
                    </Form.Item>
                  </a>
                </Col>
              </Row>
            </Form>
          </Col>
          <Col
            span={12}
            style={{
              justifyContent: 'center',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <MapContainer
              center={[latitude, longitude]}
              zoom={zoomLevel}
              scrollWheelZoom={false}
              style={{ height: '450px', width: '450px' }}
            >
              <ChangeView center={[latitude, longitude]} zoom={zoomLevel} />
              <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              {latitude !== -14.235004 && longitude !== -51.925282 && (
                <Marker position={[latitude, longitude]} />
              )}
            </MapContainer>
          </Col>
        </Row>
      </Modal>
    </>
  )
}

export default ScaleGeneralAddressModal
