import { LoadingOutlined } from '@ant-design/icons'
import { Card, Col, Collapse, Row, Space } from 'antd'
import React from 'react'
import {
  FiClock,
  FiCreditCard,
  FiDollarSign,
  FiMail,
  FiPhone,
  FiTarget,
  FiTruck,
  FiUser,
} from 'react-icons/fi'
import { useListParentClients } from '~/hooks/Clients/useListParentCustomer'

import { useTask } from '~/hooks/Tasks/useTask'

import Text from 'antd/lib/typography/Text'

import { Container } from './styles'
import NumberFormat from 'react-number-format'
import { translate } from '~/utils/locale'
import CurrencyFormatter from '~/components/DataManipulation/Misc/CurrencyFormater'

const FinancialTask: React.FC = () => {
  const { task, isLoading } = useTask()
  const { parentClients, isLoading: isLoadingParent } = useListParentClients()

  if (isLoading || !task) {
    return <LoadingOutlined style={{ fontSize: 64, margin: 'auto' }} spin />
  }

  if (isLoadingParent || !parentClients) {
    return <LoadingOutlined style={{ fontSize: 64, margin: 'auto' }} spin />
  }

  const parentClient = parentClients.filter((c) => {
    return c._id === task.customer.parent_cnpj
  })

  const parentClientData = parentClient[0]

  return (
    <>
      <Card
        bordered={false}
        title={
          <Space size="large">
            <b>Valores Acordados</b>
            {/* {!isLoading && isFetching && <LoadingOutlined spin />} */}
          </Space>
        }
        style={{
          display: 'inline-block',

          width: '100%',
        }}
      >
        <Container>
          <Collapse style={{ marginBottom: '0.5rem' }}>
            <Collapse.Panel
              header="Valores a receber (Cliente Principal)"
              key="1"
            >
              <Row gutter={12}>
                <Col span={12}>
                  <div className="text">
                    <div>
                      <FiDollarSign />
                      <Text strong>
                        Valor da Tarefa (Valor a receber do cliente principal)
                      </Text>
                    </div>
                    {task.value ? (
                      <Text>
                        <CurrencyFormatter value={task.value} />
                      </Text>
                    ) : (
                      <Text>Nenhum valor foi fornecido.</Text>
                    )}
                  </div>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col span={12}>
                  <div className="text">
                    <div>
                      <FiClock />
                      <Text strong>Quantidade de Horas</Text>
                    </div>
                    {task?.financial.amount_hours ? (
                      <Text>{task?.financial.amount_hours} horas</Text>
                    ) : (
                      <Text>Nenhuma quantidade de horas foi fornecida.</Text>
                    )}
                  </div>
                </Col>
                <Col span={12}>
                  <div className="text">
                    <div>
                      <FiClock />
                      <Text strong>Valor Hora Adicional</Text>
                    </div>
                    {task?.financial.amount_hours_additional ? (
                      <Text>
                        <CurrencyFormatter
                          value={task?.financial.amount_hours_additional}
                        />
                      </Text>
                    ) : (
                      <Text>Nenhum valor foi fornecido.</Text>
                    )}
                  </div>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col span={12}>
                  <div className="text">
                    <div>
                      <FiTruck />
                      <Text strong>Quantidade de KM</Text>
                    </div>
                    {task?.financial.number_km ? (
                      <Text>{task?.financial.number_km} km</Text>
                    ) : (
                      <Text>Nenhuma quantidade de km foi fornecida.</Text>
                    )}
                  </div>
                </Col>
                <Col span={12}>
                  <div className="text">
                    <div>
                      <FiTruck />
                      <Text strong>Valor KM Adicional</Text>
                    </div>
                    {task?.financial.number_km_additional ? (
                      <Text>
                        <CurrencyFormatter
                          value={task?.financial.number_km_additional}
                        />
                      </Text>
                    ) : (
                      <Text>Nenhum valor foi fornecido.</Text>
                    )}
                  </div>
                </Col>
              </Row>
            </Collapse.Panel>
          </Collapse>
          <Collapse>
            <Collapse.Panel
              header="Valores a pagar (Colaboradores/Equipe)"
              key="2"
            >
              <Row gutter={12}>
                <Col span={12}>
                  <div className="text">
                    <div>
                      <FiDollarSign />
                      <Text strong>
                        Valor a pagar para o colaborador/equipe
                      </Text>
                    </div>
                    {task.receive ? (
                      <Text>
                        <CurrencyFormatter value={task.receive} />
                      </Text>
                    ) : (
                      <Text>Nenhum valor foi fornecido.</Text>
                    )}
                  </div>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col span={12}>
                  <div className="text">
                    <div>
                      <FiClock />
                      <Text strong>Quantidade de Horas</Text>
                    </div>
                    {task?.financialReceive?.amount_hours_receive ? (
                      <Text>
                        {task?.financialReceive.amount_hours_receive} horas
                      </Text>
                    ) : (
                      <Text>Nenhuma quantidade de horas foi fornecida.</Text>
                    )}
                  </div>
                </Col>
                <Col span={12}>
                  <div className="text">
                    <div>
                      <FiClock />
                      <Text strong>Valor Hora Adicional</Text>
                    </div>
                    {task?.financialReceive?.amount_hours_additional_receive ? (
                      <Text>
                        <CurrencyFormatter
                          value={
                            task?.financialReceive
                              .amount_hours_additional_receive
                          }
                        />
                      </Text>
                    ) : (
                      <Text>Nenhum valor foi fornecido.</Text>
                    )}
                  </div>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col span={12}>
                  <div className="text">
                    <div>
                      <FiTruck />
                      <Text strong>Quantidade de KM</Text>
                    </div>
                    {task?.financialReceive?.number_km_receive ? (
                      <Text>{task?.financialReceive.number_km_receive} km</Text>
                    ) : (
                      <Text>Nenhuma quantidade de km foi fornecida.</Text>
                    )}
                  </div>
                </Col>
                <Col span={12}>
                  <div className="text">
                    <div>
                      <FiTruck />
                      <Text strong>Valor KM Adicional</Text>
                    </div>
                    {task?.financialReceive?.number_km_additional_receive ? (
                      <Text>
                        <CurrencyFormatter
                          value={
                            task?.financialReceive.number_km_additional_receive
                          }
                        />
                      </Text>
                    ) : (
                      <Text>Nenhum valor foi fornecido.</Text>
                    )}
                  </div>
                </Col>
              </Row>
            </Collapse.Panel>
          </Collapse>
        </Container>
      </Card>
      <Card
        bordered={false}
        title={
          <Space size="large">
            <b>Cliente Principal</b>
            {/* {!isLoading && isFetching && <LoadingOutlined spin />} */}
          </Space>
        }
        style={{
          display: 'inline-block',
          marginTop: '1rem',
          width: '100%',
        }}
      >
        {parentClientData !== undefined ? (
          <Container>
            <Row gutter={12}>
              <Col span={8}>
                <div className="text">
                  <div>
                    <FiTarget />
                    <Text strong>Razão Social / Nome</Text>
                  </div>
                  <Text>{parentClientData?.companyName}</Text>
                </div>
              </Col>
              <Col span={8}>
                <div className="text">
                  <div>
                    <FiTarget />
                    <Text strong>Nome Fantasia</Text>
                  </div>
                  <Text>{parentClientData?.fantasyName}</Text>
                </div>
              </Col>
              <Col span={8}>
                <div className="text">
                  <div>
                    <FiCreditCard />
                    <Text strong>CNPJ</Text>
                  </div>
                  {parentClientData?.cnpj ? (
                    <Text>
                      <NumberFormat
                        value={parentClientData?.cnpj}
                        displayType="text"
                        format="##.###.###/####-##"
                      />
                    </Text>
                  ) : (
                    <Text>Nenhum cnpj foi fornecido.</Text>
                  )}
                </div>
              </Col>
            </Row>

            <Row gutter={12}>
              <Col span={8}>
                <div className="text">
                  <div>
                    <FiUser />
                    <Text strong>Nome</Text>
                  </div>
                  {parentClientData?.contact?.name ? (
                    <Text>{parentClientData?.contact?.name}</Text>
                  ) : (
                    <Text>{translate('TasksTabsDetails.noValueText')}</Text>
                  )}
                </div>
              </Col>
              <Col span={8}>
                <div className="text">
                  <div>
                    <FiMail />
                    <Text strong>Email</Text>
                  </div>
                  {parentClientData?.contact?.email ? (
                    <Text>{parentClientData?.contact?.email}</Text>
                  ) : (
                    <Text>{translate('TasksTabsDetails.noValueText')}</Text>
                  )}
                </div>
              </Col>

              <Col span={8}>
                <div className="text">
                  <div>
                    <FiPhone />
                    <Text strong>Telefone</Text>
                  </div>
                  {parentClientData?.contact?.phone ? (
                    <Text>
                      <NumberFormat
                        value={parentClientData?.contact?.phone}
                        displayType="text"
                        format="(##) #####-####"
                      />
                    </Text>
                  ) : (
                    <Text>Nenhum telefone foi fornecido</Text>
                  )}
                </div>
              </Col>
            </Row>
          </Container>
        ) : (
          'Nenhum cliente principal informado.'
        )}
      </Card>
    </>
  )
}

export default FinancialTask
