/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, createContext, useState } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'

import { message } from 'antd'

import api from '~/services/api'

import { useCompanies } from '~/hooks/Companies/useCompanies'
import { useLocale } from '~/hooks/locale/useLocale'

import { ShowError } from '~/utils/errors/apiErrors'
import { translate } from '~/utils/locale'

export interface FinancialAddressProps {
  _id: string
  zip_code: string
  street: string
  number: string
  complement: string
  neighborhood: string
  city: string
  id: string
  state: string
  location: { x: number; y: number }
}

interface Financial {
  task_value: number
  amount_hours: number
  amount_hours_additional?: number
  number_km: number
  number_km_additional?: number
}

export interface FinancialContactProps {
  name: string
  email: string
  celular: string
  phone: string
  _id: string
}

interface Customers {
  id: string
}

export interface ParentClientsProps {
  _id: string
  cnpj: string
  cpf: string
  fantasyName: string
  companyName: string
  municipal_registration: string
  state_registration: string
  contact: FinancialContactProps
  address: FinancialAddressProps
  financial: Financial
  customers: Customers[]
}

interface ParentCustomerContextData {
  parentCustomer?: ParentClientsProps
  updateParentCustomer(dataParentCostumer: object | undefined): void
  addChildrenCustomer(dataParentCostumer: object | undefined): void
  updateChildrenParentClient(dataClient: object, customerId: string): void
  setIsEdit(value: boolean | undefined): void
  isEdit: boolean | undefined
  setIsEditFinancial(value: boolean | undefined): void
  isEditFinancal: boolean | undefined
  isLoading: boolean
  refetch: () => void
}

const ParentCustomerContext = createContext<ParentCustomerContextData>(
  {} as ParentCustomerContextData,
)

export const ParentCustomerProvider: React.FC = ({ children }) => {
  const { selectedCompany } = useCompanies()
  const { locale } = useLocale()
  const { parentCustomerId } = useParams<{ parentCustomerId: string }>()

  const [isEdit, setIsEdit] = useState<boolean>()
  const [isEditFinancal, setIsEditFinancial] = useState<boolean>()

  const {
    data: parentCustomer,
    isLoading,
    refetch,
  } = useQuery(
    [`cliente principal${selectedCompany}`, selectedCompany],

    async () => {
      try {
        const response = await api.get(
          `/company/${selectedCompany}/parentCustomer/${parentCustomerId}`,
        )

        const { data } = response

        return data
      } catch (err: any) {
        ShowError(
          err.message,
          translate('EquipmentsHooks.getErrorMessage'),
          locale,
        )
      }
    },
  )

  async function updateParentCustomer(dataParentCostumer: object) {
    try {
      await api.put(
        `/company/${selectedCompany}/parentCustomer/${parentCustomerId}`,
        dataParentCostumer,
      )

      refetch()

      message.success('Cliente editado com sucesso.')
    } catch (err: any) {
      ShowError(
        err.message,
        translate('EquipmentsHooks.updateErrorMessage'),
        locale,
      )
    }
  }

  async function addChildrenCustomer(valueForm: object) {
    try {
      await api.post(
        `/company/${selectedCompany}/parentCustomer/${parentCustomerId}/customer`,
        valueForm,
      )

      refetch()
    } catch (err: any) {
      // ShowError(
      //   err.message,
      //   translate('useTask.updateReportErrorMessage'),
      //   locale,
      // )
    }
  }

  async function updateChildrenParentClient(
    dataClient: object,
    customerId: string,
  ) {
    try {
      await api.put(
        `/company/${selectedCompany}/customer/${customerId}/parentCustomer`,
        dataClient,
      )

      refetch()
    } catch (err: any) {
      ShowError(err.message, translate('useClients.updateErrorMessage'), locale)
    }
  }

  return (
    <ParentCustomerContext.Provider
      value={{
        parentCustomer,
        updateParentCustomer,
        addChildrenCustomer,
        isLoading,
        refetch,
        setIsEdit,
        isEdit,
        isEditFinancal,
        setIsEditFinancial,
        updateChildrenParentClient,
      }}
    >
      {children}
    </ParentCustomerContext.Provider>
  )
}

export function useParentCustomer(): ParentCustomerContextData {
  const context = useContext(ParentCustomerContext)

  if (!context) {
    throw new Error('useAuth must be used within an AutoProvider')
  }

  return context
}
