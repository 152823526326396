import { FileOutlined } from '@ant-design/icons'
import { Button, Modal, Tooltip } from 'antd'
import React, { useState } from 'react'
import Template01 from './Template01'
import Template02 from './Template02'
import Template03 from './Template03'
import { translate } from '~/utils/locale'

// import { Container } from './styles';

interface BadgeProps {
  description: string
  name: string
  _id: string
  colorBackground?: string
  colorCover?: string
  colorStrongText?: string
  colorText?: string
  logoUrl: string
  template: string
}

interface BadgeData {
  data: BadgeProps
}

const ViewBadge: React.FC<BadgeData> = ({ data }) => {
  const [isModalVisible, setIsModalVisible] = useState(false)

  const showModal = () => {
    setIsModalVisible(true)
  }

  const closeModal = () => {
    setIsModalVisible(false)
  }

  return (
    <>
      <Tooltip title={translate('BadgeComponent.newBadgeButtonText')}>
        <Button
          onClick={showModal}
          ghost
          shape="circle"
          icon={<FileOutlined />}
          type="primary"
        />
      </Tooltip>
      <Modal
        title={`${translate('BadgeComponent.cardTitle')} - ${data.name}`}
        open={isModalVisible}
        onOk={closeModal}
        onCancel={closeModal}
        width="700px"
        style={{
          top: 30,
        }}
      >
        {data.template === 'Template01' ? <Template01 data={data} /> : ''}
        {data.template === 'Template02' ? <Template02 data={data} /> : ''}
        {data.template === 'Template03' ? <Template03 data={data} /> : ''}
      </Modal>
    </>
  )
}

export default ViewBadge
