export default {
  pt: {
    TasksRegistrationPauseCreate: {
      addButton: 'Adicionar Motivo de Pausa',
      modalTitle: 'Novo Motivo de Pausa',
      keepRegisterText: 'Continuar cadastrando Motivos de pausa?',
      closeButton: 'Fechar',
      saveButton: 'Salvar',
      titleLabel: 'Título',
      titleRule: 'Por favor informe o título do motivo de pausa.',
      titlePlaceholder: 'Informe o título',
      descriptionLabel: 'Descrição',
      descriptionRule: 'Por favor informe a descrição do motivo de pausa.',
    },
  },
  en: {
    TasksRegistrationPauseCreate: {
      addButton: 'Add Break Reason',
      modalTitle: 'New Break Reason',
      keepRegisterText: 'Continue registering Break reasons?',
      closeButton: 'Close',
      saveButton: 'Save',
      titleLabel: 'Title',
      titleRule: 'Please enter the pause reason title.',
      titlePlaceholder: 'Inform the title',
      descriptionLabel: 'Description',
      descriptionRule:
        'Please provide a description of the reason for the break.',
    },
  },
  es: {
    TasksRegistrationPauseCreate: {
      addButton: 'Añadir motivo de descanso',
      modalTitle: 'Nuevo motivo de pausa',
      keepRegisterText: 'Continuar registrando Razones de descanso?',
      closeButton: 'Cerrar',
      saveButton: 'Guardar',
      titleLabel: 'Título',
      titleRule: 'Ingrese el título del motivo de la pausa.',
      titlePlaceholder: 'Informar el título',
      descriptionLabel: 'Descripción',
      descriptionRule:
        'Proporcione una descripción del motivo de la interrupción.',
    },
  },
}
