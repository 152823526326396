/* eslint-disable react/jsx-one-expression-per-line */
// import { LoadingOutlined } from '@ant-design/icons'
import { Button, Card, Space } from 'antd'
import Text from 'antd/lib/typography/Text'
import React from 'react'
import { Link } from 'react-router-dom'
import BadgeTable from './Table'

import { translate } from '~/utils/locale'
import { useBadge } from '~/hooks/Badge/useBadge'
import { LoadingOutlined } from '@ant-design/icons'

const BadgeBoard: React.FC = () => {
  const { badgeTotal, isLoading, isFetching } = useBadge()

  return (
    <Card
      title={
        <Space size="large">
          <b>{translate('BadgeComponent.cardTitle')}</b>
          {isLoading ? (
            <LoadingOutlined spin />
          ) : (
            <Text
              type="secondary"
              style={{ fontWeight: 'normal', fontSize: 14 }}
            >
              {badgeTotal !== undefined ? (
                <>
                  {badgeTotal === 1
                    ? `${translate(
                        'BadgeComponent.totalText',
                      )} ${badgeTotal} ${translate('BadgeComponent.badgeText')}`
                    : `${translate(
                        'BadgeComponent.totalText',
                      )} ${badgeTotal} ${translate(
                        'BadgeComponent.badgesText',
                      )}`}
                </>
              ) : (
                ''
              )}
            </Text>
          )}
          {!isLoading && isFetching && <LoadingOutlined spin />}
        </Space>
      }
      extra={
        <Space size="small">
          <Link to="/cracha/novo">
            <Button type="primary">
              {translate('BadgeComponent.newBadgeButtonText')}
            </Button>
          </Link>
        </Space>
      }
      bordered={false}
      style={{ width: '100%', padding: 30 }}
    >
      <BadgeTable />
    </Card>
  )
}

export default BadgeBoard
