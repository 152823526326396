import React from 'react'

import { Tabs, Typography, Space, Spin } from 'antd'
import { PageHeader } from '@ant-design/pro-components'

import { LoadingOutlined } from '@ant-design/icons'
import { useHistory, useParams } from 'react-router-dom'
import { Container } from './styles'
import DetailsTab from './DetailsTab'
import AddressTab from './AddressTab'
import ChargeTab from './ChargeTab'
import ContactsTab from './ContactsTab'
import { useListContacts } from '~/hooks/Clients/useListContacts'
import { useClients } from '~/hooks/Clients/useClients'
import ClientsUpdate from '../Update/ClientsUpdate'
import ChargeUpdate from '../Update/Charge'
import { SegmentsProvider } from '~/hooks/Segments/useSegments'
import { translate } from '~/utils/locale'
import ParentClientTab from './ParentClient'

const ClientsTabs: React.FC = () => {
  const { customerId, tab } = useParams<{ customerId: string; tab: string }>()
  const { contactId } = useParams<{ contactId: string }>()
  const { isLoading } = useListContacts()
  const { isEdit, clients } = useClients()

  const history = useHistory()

  const { TabPane } = Tabs
  const { Text } = Typography

  const handleTabChange = (key: string) => {
    const path = window.location.pathname.split('/')

    if (key === 'detalhes') {
      history.replace({
        pathname: `/clientes/${path[2]}/${customerId}/contato/${contactId}/`,
      })
    } else {
      history.replace({
        pathname: `/clientes/${path[2]}/${customerId}/contato/${contactId}/${key}`,
      })
    }
  }

  return (
    <Container>
      <PageHeader
        className="site-page-header"
        onBack={() => window.history.back()}
        title={
          !isEdit
            ? translate('ClientsTabs.viewClientTitle')
            : translate('ClientsTabs.editClientTitle')
        }
        subTitle={clients?.contact.name}
      />
      <Tabs
        onTabClick={(key: string) => handleTabChange(key)}
        defaultActiveKey={tab ? `${tab}` : 'detalhes'}
        tabBarExtraContent={
          <Space size="large">
            <Space size="small">
              <Text strong>{translate('ClientsTabs.statusLabel')}</Text>
              {clients?.is_enabled ? (
                <Text>{translate('ClientsTabs.statusActive')}</Text>
              ) : (
                <Text>{translate('ClientsTabs.statusInactive')}</Text>
              )}
            </Space>
          </Space>
        }
        style={{ paddingLeft: 20, paddingRight: 20 }}
      >
        <TabPane tab={translate('ClientsTabs.detailsTab')} key="detalhes">
          {!isEdit ? (
            <DetailsTab />
          ) : (
            <SegmentsProvider>
              <ClientsUpdate />
            </SegmentsProvider>
          )}
        </TabPane>
        <TabPane tab={translate('ClientsTabs.addressTab')} key="endereço">
          <AddressTab />
        </TabPane>
        <TabPane tab={translate('ClientsTabs.chargeTab')} key="cobrança">
          {!isEdit ? <ChargeTab /> : <ChargeUpdate />}
        </TabPane>
        <TabPane tab={translate('ClientsTabs.contactTab')} key="contatos">
          {isLoading ? (
            <Spin indicator={<LoadingOutlined />} />
          ) : (
            <ContactsTab />
          )}
        </TabPane>
        <TabPane tab="Cliente Principal" key="clientePai">
          <ParentClientTab />
        </TabPane>
      </Tabs>
    </Container>
  )
}

export default ClientsTabs
