export default {
  pt: {
    ClientsAdditionalPhonesCreate: {
      loadingText: 'Carregando',
      successMessage: 'Telefone criado com sucesso!',
      errorMessage: 'Houve um erro ao criar o telefone',
      newPhoneButton: 'Novo Telefone',
      modalTitle: 'Novo Telefone',
      closeButton: 'Fechar',
      saveButton: 'Criar',
      typeLabel: 'Tipo',
      typeRule: 'Por favor selecione o tipo de telefone.',
      selectPlaceholder: 'Informe o tipo de telefone',
      selectOptionOne: 'Fixo',
      selectOptionTwo: 'Celular',
      selectOptionThree: 'Comercial',
      phoneLabel: 'Telefone',
      phoneRuleOne: 'Por favor informe o telefone.',
      phoneRuleTwo: 'Insira um número válido.',
      phonePlaceholder: 'Informe o número',
      whatsappLabel: 'Whatsapp',
      whatsappCheckbox: 'O telefone fornecido é seu número de whatsapp?',
    },
  },
  en: {
    ClientsAdditionalPhonesCreate: {
      loadingText: 'Loading',
      successMessage: 'Phone created successfully!',
      errorMessage: 'There was an error creating the phone',
      newPhoneButton: 'New Phone',
      modalTitle: 'New Phone',
      closeButton: 'Close',
      saveButton: 'Create',
      typeLabel: 'Type',
      typeRule: 'Please select your phone type.',
      selectPlaceholder: 'Enter phone type',
      selectOptionOne: 'Fixed',
      selectOptionTwo: 'Cell',
      selectOptionThree: 'Commercial',
      phoneLabel: 'Phone',
      phoneRuleOne: 'Please inform the phone number.',
      phoneRuleTwo: 'Please enter a valid number.',
      phonePlaceholder: 'Inform the number',
      whatsappLabel: 'Whatsapp',
      whatsappCheckbox: 'Is the given phone your whatsapp number?',
    },
  },
  es: {
    ClientsAdditionalPhonesCreate: {
      loadingText: 'Cargando',
      successMessage: '¡Teléfono creado con éxito!',
      errorMessage: 'Hubo un error al crear el teléfono.',
      newPhoneButton: 'Teléfono nuevo',
      modalTitle: 'Teléfono nuevo',
      closeButton: 'Cerrar',
      saveButton: 'Crear',
      typeLabel: 'Tipo',
      typeRule: 'Seleccione su tipo de teléfono.',
      selectPlaceholder: 'Introduce el tipo de teléfono',
      selectOptionOne: 'Fijo',
      selectOptionTwo: 'Celular',
      selectOptionThree: 'Comercial',
      phoneLabel: 'Teléfono',
      phoneRuleOne: 'Por favor informar al teléfono.',
      phoneRuleTwo: 'Por favor ingrese un número valido.',
      phonePlaceholder: 'Informa el numero',
      whatsappLabel: 'Whatsapp',
      whatsappCheckbox: '¿El teléfono dado es tu número de whatsapp?',
    },
  },
}
