export default {
  pt: {
    NewEquipments: {
      helmetTitle: 'Novo Equipamento',
    },
  },
  en: {
    NewEquipments: {
      helmetTitle: 'Novo Equipamento',
    },
  },
  es: {
    NewEquipments: {
      helmetTitle: 'Nuevo Equipamiento',
    },
  },
}
