export default {
  pt: {
    HelpDeskTabs: {
      viewTicketTitle: 'Visualizar Ticket',
      editTicketTitle: 'Editar Ticket',
      statusLabel: 'Status:',
      statusActive: 'Ativo',
      statusInactive: 'Inativo',
      detailsTab: 'Detalhes',
      clientTab: 'Cliente',
      historicTab: 'Histórico',
      reportsTab: 'Anexos',
      tasksTab: 'Tarefas',
      edit: 'Editar',
    },
  },
  en: {
    HelpDeskTabs: {
      viewTicketTitle: 'View Ticket',
      editTicketTitle: 'Edit Ticket',
      statusLabel: 'Status:',
      statusActive: 'Active',
      statusInactive: 'Inactive',
      detailsTab: 'Details',
      clientTab: 'Client',
      historicTab: 'Historic',
      reportsTab: 'Reports',
      tasksTab: 'Tasks',
      edit: 'Edit',
    },
  },
  es: {
    HelpDeskTabs: {
      viewTicketTitle: 'Ver Ticket',
      editTicketTitle: 'Editar Ticket',
      statusLabel: 'Status:',
      statusActive: 'Activo',
      statusInactive: 'Inactivo',
      detailsTab: 'Detalles',
      clientTab: 'Cliente',
      historicTab: 'Histórico',
      reportsTab: 'Informes',
      tasksTab: 'Tarea',
      edit: 'Editar',
    },
  },
}
