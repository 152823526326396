export default {
  pt: {
    usePause: {
      getErrorMessage: 'Erro ao visualizar motivos de pausa.',
      createSuccessMessage: 'Motivo de pausa criado com sucesso!',
      createErrorMessage: 'Erro ao criar motivos de pausa.',
      updateSuccessMessage: 'Motivo de pausa atualizado com sucesso!',
      updateErrorMessage: 'Erro ao atualizar motivos de pausa.',
      deleteSuccessMessage: 'Motivo de pausa deletado com sucesso!',
      deleteErrorMessage: 'Erro ao deletar motivos de pausa.',
    },
  },
  en: {
    usePause: {
      getErrorMessage: 'Error viewing pause reasons.',
      createSuccessMessage: 'Break reason created successfully!',
      createErrorMessage: 'Error creating break reasons.',
      updateSuccessMessage: 'Break reason updated successfully!',
      updateErrorMessage: 'Error updating pause reasons.',
      deleteSuccessMessage: 'Break reason deleted successfully!',
      deleteErrorMessage: 'Error deleting pause reasons.',
    },
  },
  es: {
    usePause: {
      getErrorMessage: 'Error al ver los motivos de la pausa.',
      createSuccessMessage: '¡Razón de ruptura creada con éxito!',
      createErrorMessage: 'Error al crear motivos de descanso.',
      updateSuccessMessage: '¡Motivo de ruptura actualizado con éxito!',
      updateErrorMessage: 'Error al actualizar los motivos de la pausa.',
      deleteSuccessMessage: '¡Razón de interrupción eliminada con éxito!',
      deleteErrorMessage: 'Error al eliminar los motivos de la pausa.',
    },
  },
}
