/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-nested-ternary */
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

import { MapContainer, TileLayer, Marker, useMap } from 'react-leaflet'

import { List, Space, Avatar, Typography, message } from 'antd'
import { HomeOutlined, ShopOutlined, BankOutlined } from '@ant-design/icons'

import { LatLngExpression } from 'leaflet'
import DeleteButton from '~/components/DataManipulation/Misc/DeleteButton'
import AddressModal from '~/components/DataManipulation/Address/AddressModal'

import { useCompanies } from '~/hooks/Companies/useCompanies'
import { useLocale } from '~/hooks/locale/useLocale'

import { ShowError } from '~/utils/errors/apiErrors'

import api from '~/services/api'
import { translate } from '~/utils/locale'

interface AddressProps {
  id: string
  notes: string
  type: string
  address: {
    zip_code: string
    street: string
    number: string
    complement: string
    neighborhood: string
    city: string
    id: string
    state: string
    location: { x: number; y: number }
  }
}

interface MapUpdateFunctionProps {
  centerView: LatLngExpression | undefined
  zoom: number
}

interface AddressItemProps {
  isVisualization?: boolean
  addressInfo: AddressProps
  refetch: () => void
}

const AddressItem: React.FC<AddressItemProps> = ({
  isVisualization = false,
  addressInfo,
  refetch,
}) => {
  const [confirmLoading, setConfirmLoading] = useState(false)

  const { Text } = Typography

  const { contactId } = useParams<{ contactId: string }>()

  const { selectedCompany } = useCompanies()
  const { locale } = useLocale()

  function ChangeView({ centerView, zoom }: MapUpdateFunctionProps) {
    const map = useMap()
    map.setView(centerView!, zoom)
    return null
  }

  const handleDelete = async (id: string) => {
    try {
      setConfirmLoading(true)
      await api.delete(
        `/company/${selectedCompany}/contact/${contactId}/address/${id}`,
      )
      setConfirmLoading(false)
      refetch()
      message.success(translate('AddressList.successMessage'))
    } catch (err: any) {
      ShowError(err.message, translate('AddressList.errorMessage'), locale)
      setConfirmLoading(false)
    }
  }

  return (
    <List.Item
      key={addressInfo.id}
      extra={
        <MapContainer
          center={
            addressInfo.address.location
              ? [addressInfo.address.location.x, addressInfo.address.location.y]
              : [0, 0]
          }
          zoom={17}
          scrollWheelZoom={false}
          style={{ height: '200px', width: '350px' }}
          zoomControl={false}
          dragging={false}
          boxZoom={false}
          doubleClickZoom={false}
        >
          <ChangeView
            centerView={
              addressInfo.address.location
                ? [
                    addressInfo.address.location.x,
                    addressInfo.address.location.y,
                  ]
                : [0, 0]
            }
            zoom={17}
          />
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {addressInfo.address.location && (
            <Marker
              position={[
                addressInfo.address.location.x,
                addressInfo.address.location.y,
              ]}
            />
          )}
        </MapContainer>
      }
    >
      <List.Item.Meta
        avatar={
          <Avatar
            style={{
              color: '#000',
              backgroundColor: 'transparent',
            }}
            icon={
              addressInfo.type === 'personal' ? (
                <HomeOutlined />
              ) : addressInfo.type === 'business' ? (
                <ShopOutlined />
              ) : (
                <BankOutlined />
              )
            }
          />
        }
        title={
          <Space size="large">
            <b>
              {addressInfo.type === 'personal'
                ? translate('AddressList.addressTypePersonal')
                : addressInfo.type === 'business'
                  ? translate('AddressList.addressTypeBusiness')
                  : translate('AddressList.addressTypeCharge')}
            </b>
            {!isVisualization && (
              <Space size="small">
                <AddressModal
                  isUpdate
                  AddressInfo={addressInfo}
                  refetch={refetch}
                />
                <DeleteButton
                  PopconfirmText={translate('AddressList.popconfirmText')}
                  TooltipText={translate('AddressList.popconfirmTooltip')}
                  handleDelete={handleDelete}
                  confirmLoading={confirmLoading}
                  itemId={addressInfo.address.id}
                />
              </Space>
            )}
          </Space>
        }
      />
      <Space direction="vertical">
        <Space size="large">
          <Space size="small">
            <Text strong>{translate('AddressList.zipcodeText')}</Text>
            <Text>{addressInfo.address.zip_code}</Text>
          </Space>
          <Space size="small">
            <Text strong>{translate('AddressList.stateText')}</Text>
            <Text>{addressInfo.address.state}</Text>
          </Space>
          <Space size="small">
            <Text strong>{translate('AddressList.cityText')}</Text>
            <Text>{addressInfo.address.city}</Text>
          </Space>
        </Space>
        <Space size="large">
          <Space size="small">
            <Text strong>{translate('AddressList.neighborhoodText')}</Text>
            <Text>{addressInfo.address.neighborhood}</Text>
          </Space>
          <Space size="small">
            <Text strong>{translate('AddressList.streetText')}</Text>
            <Text>{addressInfo.address.street}</Text>
          </Space>
          <Space size="small">
            <Text strong>{translate('AddressList.numberText')}</Text>
            <Text>{addressInfo.address.number}</Text>
          </Space>
          <Space size="small">
            <Text strong>{translate('AddressList.complementText')}</Text>
            <Text>{addressInfo.address.complement}</Text>
          </Space>
        </Space>
        <Space size="large">
          <Space size="small">
            <Text strong>{translate('AddressList.typeText')}</Text>
            <Text>
              {addressInfo.type === 'personal'
                ? translate('AddressList.typePersonalText')
                : addressInfo.type === 'business'
                  ? translate('AddressList.typeBusinessText')
                  : translate('AddressList.typeChargeText')}
            </Text>
          </Space>
          <Space size="small">
            <Text strong>{translate('AddressList.observationText')}</Text>
            <Text>{addressInfo.notes}</Text>
          </Space>
        </Space>
      </Space>
    </List.Item>
  )
}

export default AddressItem
