/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react'
import { Link } from 'react-router-dom'

import { Typography, Space, Table, Button } from 'antd'
import { FileUnknownOutlined, LoadingOutlined } from '@ant-design/icons'
import { ColumnsType } from 'antd/lib/table'

import AddToScaleButton from '~/components/DataManipulation/Misc/AddToScaleButton'

import { Container } from './styles'
import { useListClients } from '~/hooks/Clients/useListClients'
import { translate } from '~/utils/locale'

interface ClientsProps {
  id: string
  contact?: {
    name: string
    email: string
    id: string
  }
}

const ClientsTable: React.FC = () => {
  const { clients, isLoading } = useListClients()
  const { Text } = Typography

  const columns: ColumnsType<ClientsProps> = [
    {
      title: translate('ScalesClientsTable.nameColumn'),
      align: 'left',
      dataIndex: ['contact', 'name'],
      key: 'name',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (name: string, record) => (
        <Link
          to={`/clientes/visualizar/${record.id}/contato/${record.contact?.id}/`}
        >
          {name}
        </Link>
      ),
    },
    {
      title: translate('ScalesClientsTable.responsibleColumn'),
      dataIndex: ['collaborator', 'contact', 'name'],
      align: 'center',
      key: 'contact',
      render: (contact: string) => (
        <>
          {contact || (
            <Text type="secondary">
              <Space size="small">
                <FileUnknownOutlined />
                N/A
              </Space>
            </Text>
          )}
        </>
      ),
    },
    {
      title: translate('ScalesClientsTable.emailColumn'),
      align: 'center',
      key: 'email',
      dataIndex: ['contact', 'email'],
    },
    {
      title: translate('ScalesClientsTable.actionsColumn'),
      align: 'center',
      key: 'remove',
      render: (record) => (
        <Space size="middle">
          <AddToScaleButton isClient id={record.id} />
        </Space>
      ),
    },
  ]

  return (
    <Container>
      <Table
        rowKey={(record) => record.id}
        loading={{
          indicator: <LoadingOutlined />,
          spinning: isLoading,
          size: 'large',
        }}
        pagination={{
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '50', '100', '200'],
          defaultPageSize: 10,
          showTotal: (total, range) => `${range[0]} - ${range[1]} / ${total}`,
        }}
        locale={{
          emptyText: (
            <Space direction="vertical">
              <span style={{ color: '#9B9B9B' }}>
                {translate('ScalesClientsTable.infoText')}
              </span>
              <Link to="/clientes/novo">
                <Button type="primary">
                  {translate('ScalesClientsTable.addButton')}
                </Button>
              </Link>
            </Space>
          ),
        }}
        columns={columns}
        dataSource={clients}
        scroll={{ x: 1000 }}
      />
    </Container>
  )
}

export default ClientsTable
