export default {
  pt: {
    TasksTabsStatus: {
      dateString: 'às',
      cardTitle: 'Status da Tarefa',
      mapTitle: 'Mapa',
      statusReceived: 'Status - Enviado',
      statusViewed: 'Status - Visualizado',
      statusEnRoute: 'Status - A Caminho',
      statusCheckIn: 'Status - Tarefa sendo realizada',
      statusPauseTask: 'Status - Tarefa Pausada',
      statusResumeWorking: 'Status - Retomar Trabalho',
      statusCheckout: 'Status - Tarefa Finalizada',
      statusTitle: 'Status',
      closeTooltip: 'Fechar',
      expandTooltip: 'Expandir',
      contributorText: 'Colaborador',
    },
  },
  en: {
    TasksTabsStatus: {
      dateString: 'at',
      cardTitle: 'Task Status',
      mapTitle: 'Map',
      statusReceived: 'Status - Submitted',
      statusViewed: 'Status - Viewed',
      statusEnRoute: 'Status - On the way',
      statusCheckIn: 'Status - Task being performed',
      statusPauseTask: 'Status - Paused Task',
      statusResumeWorking: 'Status - Resume Work',
      statusCheckout: 'Status - Finished Task',
      statusTitle: 'Status',
      closeTooltip: 'Close',
      expandTooltip: 'Expand',
      contributorText: 'Contributor',
    },
  },
  es: {
    TasksTabsStatus: {
      dateString: 'a las',
      cardTitle: 'Status de la Tarea',
      mapTitle: 'Mapa',
      statusReceived: 'Status - Enviada',
      statusViewed: 'Status - Visto',
      statusEnRoute: 'Status - En Camino',
      statusCheckIn: 'Status - Tarea que se está realizando',
      statusPauseTask: 'Status - Tarea en Pausa',
      statusResumeWorking: 'Status - Continúa el Trabajo',
      statusCheckout: 'Status - Tarea Terminada',
      statusTitle: 'Status',
      closeTooltip: 'Cerrar',
      expandTooltip: 'Expandir',
      contributorText: 'Colaborador',
    },
  },
}
