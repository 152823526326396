/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { CloseOutlined, SettingOutlined } from '@ant-design/icons'
import { Button, Col, Drawer, Form, Row, Select, Tooltip, Switch } from 'antd'
import React, { useState } from 'react'
import { useTaskReceived } from '~/hooks/Tasks/Kanban/useTaskReceived'
import { translate } from '~/utils/locale'

import { Container } from './styles'

const ConfigurationsKanban: React.FC = () => {
  const { Option, OptGroup } = Select
  const [form] = Form.useForm()
  const { refetch } = useTaskReceived()

  const [visible, setVisible] = useState(false)

  function onFinish(data: any) {
    localStorage.setItem('kanbanConfigurations', JSON.stringify(data))

    refetch()
  }

  function onReset() {
    localStorage.removeItem('kanbanConfigurations')
    form.resetFields()
    setVisible(false)

    refetch()
  }

  const kanbanConfigurations = JSON.parse(
    localStorage.getItem('kanbanConfigurations')!,
  )

  const showDrawer = () => {
    setVisible(true)
  }

  const onClose = () => {
    setVisible(false)
  }

  const isFilter = kanbanConfigurations !== null

  const optionVisualization = localStorage.getItem('visualizationKanban')

  return (
    <Container isFilter={isFilter}>
      <Button
        type="primary"
        onClick={showDrawer}
        icon={
          !isFilter ? (
            <SettingOutlined />
          ) : (
            <Tooltip
              title={translate('TasksOptionsKanbanConfigurations.cleanTooltip')}
            >
              <CloseOutlined onClick={onReset} />
            </Tooltip>
          )
        }
        id="btn_configuration_kanban"
      >
        {isFilter
          ? translate('TasksOptionsKanbanConfigurations.configAppliedText')
          : translate('TasksOptionsKanbanConfigurations.configText')}
      </Button>
      <Drawer
        forceRender
        title={translate('TasksOptionsKanbanConfigurations.drawerTitle')}
        width={580}
        onClose={onClose}
        open={visible}
        style={{ paddingBottom: 80 }}
      >
        <Form
          layout="vertical"
          form={form}
          onFinish={onFinish}
          initialValues={kanbanConfigurations}
        >
          {optionVisualization === 'complete' ? (
            <>
              <Row>
                <Form.Item
                  label={translate(
                    'TasksOptionsKanbanConfigurations.dataPatternLabel',
                  )}
                  name="data_pattern"
                  style={{ width: '100%' }}
                >
                  <Select
                    placeholder={translate(
                      'TasksOptionsKanbanConfigurations.dataPatternPlaceholder',
                    )}
                    mode="tags"
                    allowClear
                  >
                    <OptGroup
                      label={translate(
                        'TasksOptionsKanbanConfigurations.dataPatternGroup',
                      )}
                    >
                      <Option value="code">
                        {translate(
                          'TasksOptionsKanbanConfigurations.dataPatternCode',
                        )}
                      </Option>
                      <Option value="date">
                        {translate(
                          'TasksOptionsKanbanConfigurations.dataPatternDate',
                        )}
                      </Option>
                      <Option value="responsible">
                        {translate(
                          'TasksOptionsKanbanConfigurations.dataPatternResponsible',
                        )}
                      </Option>
                      <Option value="client">
                        {translate(
                          'TasksOptionsKanbanConfigurations.dataPatternClient',
                        )}
                      </Option>
                      <Option value="type_task">
                        {translate(
                          'TasksOptionsKanbanConfigurations.dataPatternTypeTask',
                        )}
                      </Option>
                    </OptGroup>
                  </Select>
                </Form.Item>
              </Row>

              <Row>
                <Form.Item
                  label={translate(
                    'TasksOptionsKanbanConfigurations.dataTaskLabel',
                  )}
                  name="data_task"
                  style={{ width: '100%' }}
                >
                  <Select
                    placeholder={translate(
                      'TasksOptionsKanbanConfigurations.dataTaskPlaceholder',
                    )}
                    mode="tags"
                    allowClear
                  >
                    <OptGroup
                      label={translate(
                        'TasksOptionsKanbanConfigurations.dataTaskGroup',
                      )}
                    >
                      <Option value="checkin">
                        {translate(
                          'TasksOptionsKanbanConfigurations.dataTaskCheckin',
                        )}
                      </Option>
                      <Option value="priority">
                        {translate(
                          'TasksOptionsKanbanConfigurations.dataTaskPriority',
                        )}
                      </Option>
                      <Option value="status">
                        {translate(
                          'TasksOptionsKanbanConfigurations.dataTaskStatus',
                        )}
                      </Option>
                      <Option value="duration">
                        {translate(
                          'TasksOptionsKanbanConfigurations.dataTaskDuration',
                        )}
                      </Option>
                      <Option value="external_code">
                        {translate(
                          'TasksOptionsKanbanConfigurations.dataTaskExternalCode',
                        )}
                      </Option>
                      <Option value="address">
                        {translate(
                          'TasksOptionsKanbanConfigurations.dataTaskAddress',
                        )}
                      </Option>
                    </OptGroup>
                  </Select>
                </Form.Item>
              </Row>

              <Row>
                <Form.Item
                  label={translate(
                    'TasksOptionsKanbanConfigurations.columnsLabel',
                  )}
                  name="columns"
                  valuePropName="checked"
                  style={{ width: '100%' }}
                >
                  <Switch
                    checkedChildren={translate(
                      'TasksOptionsKanbanConfigurations.columnsActive',
                    )}
                    unCheckedChildren={translate(
                      'TasksOptionsKanbanConfigurations.columnsInactive',
                    )}
                  />
                </Form.Item>
              </Row>
            </>
          ) : (
            <Row>
              <Form.Item
                label={translate(
                  'TasksOptionsKanbanConfigurations.columnsLabel',
                )}
                name="columns"
                valuePropName="checked"
                style={{ width: '100%' }}
              >
                <Switch
                  checkedChildren={translate(
                    'TasksOptionsKanbanConfigurations.columnsActive',
                  )}
                  unCheckedChildren={translate(
                    'TasksOptionsKanbanConfigurations.columnsInactive',
                  )}
                />
              </Form.Item>
            </Row>
          )}

          <Row gutter={8}>
            <Col span={12}>
              <Form.Item style={{ width: '100%' }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: '100%' }}
                >
                  {translate('TasksOptionsKanbanConfigurations.applyButton')}
                </Button>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item style={{ width: '100%' }}>
                <Button style={{ width: '100%' }} onClick={onReset}>
                  {translate('TasksOptionsKanbanConfigurations.cleanButton')}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </Container>
  )
}

export default ConfigurationsKanban
