import React from 'react'

import moment from 'moment'
import {
  List,
  Button,
  Space,
  Tooltip,
  Popconfirm,
  Rate,
  Typography,
} from 'antd'
import { DeleteFilled } from '@ant-design/icons'
import { useEvaluations } from '~/hooks/Evaluations/useEvaluations'
import EvaluationModal from '~/components/DataManipulation/Evaluation/EvaluationModal'
import { translate } from '~/utils/locale'

const EvaluationList: React.FC = () => {
  const { Text } = Typography
  const { evaluationsData, deleteEvaluation, deleteLoading } = useEvaluations()

  return (
    <List
      itemLayout="horizontal"
      dataSource={evaluationsData}
      renderItem={(item) => (
        <List.Item
          actions={[
            <Space size="small" key="Actions">
              <EvaluationModal isUpdate EvaluationInfo={item} />
              <Popconfirm
                key={item.id}
                title={translate('EvaluationList.deletePopconfirm')}
                okButtonProps={{ loading: deleteLoading }}
                onConfirm={() => deleteEvaluation(item.id)}
              >
                <Tooltip title={translate('EvaluationList.deleteTooltip')}>
                  <Button danger ghost shape="circle" icon={<DeleteFilled />} />
                </Tooltip>
              </Popconfirm>
            </Space>,
          ]}
        >
          <List.Item.Meta
            title={
              <Rate
                disabled
                allowHalf
                value={item.star}
                style={{ color: '#1E8AFF' }}
              />
            }
            description={
              <Space>
                <Text>{item.notes}</Text>
                <Text type="secondary">
                  {moment(item.created_at).format('LLL')}
                </Text>
              </Space>
            }
          />
        </List.Item>
      )}
    />
  )
}

export default EvaluationList
