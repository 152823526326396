/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState } from 'react'

import { Modal, Button, Form, Tooltip, message, Popconfirm } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import Checkbox from 'antd/lib/checkbox/Checkbox'

import { usePermission } from '~/hooks/Groups/usePermission'
import { translate } from '~/utils/locale'

interface Permissions {
  id: string
  create: boolean
  delete: boolean
  edit: boolean
  view: boolean
  module: {
    id: string
    name: string
  }
}

interface Permission {
  modules: string
  data: Permissions
  group: string
}

const EditPermission: React.FC<Permission> = ({ data, modules, group }) => {
  const { updatePermission, deletePermission } = usePermission()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [module, setModule] = useState('')

  const showModal = () => {
    setIsModalVisible(true)

    if (modules === 'addresses') {
      setModule(translate('GroupsUpdatePermissions.addresses'))
    } else if (modules === 'companies') {
      setModule(translate('GroupsUpdatePermissions.companies'))
    } else if (modules === 'contacts') {
      setModule(translate('GroupsUpdatePermissions.contacts'))
    } else if (modules === 'contributors') {
      setModule(translate('GroupsUpdatePermissions.contributors'))
    } else if (modules === 'contributors-attachments') {
      setModule(translate('GroupsUpdatePermissions.contributorsAttachments'))
    } else if (modules === 'contributors-evaluations') {
      setModule(translate('GroupsUpdatePermissions.contributorsEvaluations'))
    } else if (modules === 'contributors-skills') {
      setModule(translate('GroupsUpdatePermissions.contributorsSkills'))
    } else if (modules === 'contributors-status') {
      setModule(translate('GroupsUpdatePermissions.contributorsStatus'))
    } else if (modules === 'groups') {
      setModule(translate('GroupsUpdatePermissions.groups'))
    } else if (modules === 'groups-permissions') {
      setModule(translate('GroupsUpdatePermissions.groupsPermissions'))
    } else if (modules === 'reports') {
      setModule(translate('GroupsUpdatePermissions.reports'))
    } else if (modules === 'tasks-status') {
      setModule(translate('GroupsUpdatePermissions.tasksStatus'))
    } else if (modules === 'tasks-reports') {
      setModule(translate('GroupsUpdatePermissions.tasksReports'))
    } else if (modules === 'tasks-equipments') {
      setModule(translate('GroupsUpdatePermissions.tasksEquipments'))
    } else if (modules === 'contributors-teams') {
      setModule(translate('GroupsUpdatePermissions.contributorsTeams'))
    } else if (modules === 'teams') {
      setModule(translate('GroupsUpdatePermissions.teams'))
    } else if (modules === 'tasks') {
      setModule(translate('GroupsUpdatePermissions.tasks'))
    } else if (modules === 'breaks-reasons') {
      setModule(translate('GroupsUpdatePermissions.breaksReasons'))
    } else if (modules === 'tasks-types') {
      setModule(translate('GroupsUpdatePermissions.tasksTypes'))
    } else if (modules === 'equipments-attachments') {
      setModule(translate('GroupsUpdatePermissions.equipmentsAttachments'))
    } else if (modules === 'equipments-specifications') {
      setModule(translate('GroupsUpdatePermissions.equipmentsSpecifications'))
    } else if (modules === 'specifications-templates') {
      setModule(translate('GroupsUpdatePermissions.specificationsTemplates'))
    } else if (modules === 'movements') {
      setModule(translate('GroupsUpdatePermissions.movements'))
    } else if (modules === 'stocks') {
      setModule(translate('GroupsUpdatePermissions.stocks'))
    } else if (modules === 'associated-equipments') {
      setModule(translate('GroupsUpdatePermissions.associatedEquipments'))
    } else if (modules === 'categories') {
      setModule(translate('GroupsUpdatePermissions.categories'))
    } else if (modules === 'equipments') {
      setModule(translate('GroupsUpdatePermissions.equipments'))
    } else if (modules === 'contacts-addresses') {
      setModule(translate('GroupsUpdatePermissions.contactsAddresses'))
    } else if (modules === 'contributors-schedules') {
      setModule(translate('GroupsUpdatePermissions.contributorsSchedules'))
    } else if (modules === 'customers-schedules') {
      setModule(translate('GroupsUpdatePermissions.customersSchedules'))
    } else if (modules === 'schedule-times') {
      setModule(translate('GroupsUpdatePermissions.scheduleTimes'))
    } else if (modules === 'schedules') {
      setModule(translate('GroupsUpdatePermissions.schedules'))
    } else if (modules === 'customers-contacts') {
      setModule(translate('GroupsUpdatePermissions.customersContacts'))
    } else if (modules === 'customers-segments') {
      setModule(translate('GroupsUpdatePermissions.customersSegments'))
    } else if (modules === 'customers') {
      setModule(translate('GroupsUpdatePermissions.customers'))
    }
  }

  async function deletePermissions() {
    try {
      await deletePermission(data.id)
      setIsModalVisible(false)
    } catch (err) {
      console.log(err)
    }
  }

  const closeModal = () => {
    setIsModalVisible(false)
  }

  const initialData = {
    create: data.create,
    edit: data.edit,
    view: data.view,
    delete: data.delete,
  }

  function onFormEditPermission(valueForm: object) {
    try {
      updatePermission(data.module.id, group, valueForm, data.id)
      message.success(translate('GroupsUpdatePermissions.successMessage'))
      setIsModalVisible(false)
    } catch (err) {
      message.error(translate('GroupsUpdatePermissions.errorMessage'))
    }
  }

  return (
    <>
      <Tooltip
        title={translate('GroupsUpdatePermissions.editTooltip')}
        key="permission"
      >
        <EditOutlined onClick={showModal} style={{ color: '#3C8ACB' }} />
      </Tooltip>

      <Modal
        title={translate('GroupsUpdatePermissions.modalTitle')}
        open={isModalVisible}
        onOk={closeModal}
        onCancel={closeModal}
        footer={[
          <Button key="close" onClick={closeModal}>
            {translate('GroupsUpdatePermissions.closeButton')}
          </Button>,
          <Tooltip
            title={translate('GroupsUpdatePermissions.deleteTooltip')}
            key="DeleteBtn"
          >
            <Popconfirm
              title={translate('GroupsUpdatePermissions.deletePopconfirm')}
              okText={translate('GroupsUpdatePermissions.okPopconfirmText')}
              cancelText={translate(
                'GroupsUpdatePermissions.cancelPopconfirmText',
              )}
              onConfirm={deletePermissions}
            >
              <Button type="primary" danger>
                {translate('GroupsUpdatePermissions.deleteButton')}
              </Button>
            </Popconfirm>
          </Tooltip>,
          <Button
            form="editPermissionData"
            key="save"
            type="primary"
            htmlType="submit"
          >
            {translate('GroupsUpdatePermissions.saveButton')}
          </Button>,
        ]}
      >
        <Form
          layout="vertical"
          id="editPermissionData"
          initialValues={initialData}
          onFinish={onFormEditPermission}
        >
          <Form.Item valuePropName="checked" name="create">
            <Checkbox>
              {translate('GroupsUpdatePermissions.createText')} {module}.
            </Checkbox>
          </Form.Item>
          <Form.Item valuePropName="checked" name="edit">
            <Checkbox>
              {translate('GroupsUpdatePermissions.editText')} {module}.
            </Checkbox>
          </Form.Item>
          <Form.Item valuePropName="checked" name="view">
            <Checkbox>
              {translate('GroupsUpdatePermissions.viewText')} {module}.
            </Checkbox>
          </Form.Item>
          <Form.Item valuePropName="checked" name="delete">
            <Checkbox>
              {translate('GroupsUpdatePermissions.deleteText')} {module}.
            </Checkbox>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default EditPermission
