/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-nested-ternary */
import React from 'react'

import { List } from 'antd'

import AddressItem from '~/components/DataManipulation/Address/AddressItem'

interface AddressProps {
  id: string
  notes: string
  type: string
  address: {
    zip_code: string
    street: string
    number: string
    complement: string
    neighborhood: string
    city: string
    id: string
    state: string
    location: { x: number; y: number }
  }
}

interface AddressListProps {
  isVisualization?: boolean
  addressInfo: AddressProps[]
  refetch: () => void
}

const AddressList: React.FC<AddressListProps> = ({
  isVisualization = false,
  addressInfo,
  refetch,
}) => {
  return (
    <List
      itemLayout="vertical"
      size="large"
      dataSource={addressInfo}
      renderItem={(item) => (
        <AddressItem
          addressInfo={item}
          refetch={refetch}
          isVisualization={isVisualization}
        />
      )}
    />
  )
}

export default AddressList
