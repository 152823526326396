/* eslint-disable react/jsx-curly-newline */
import React, { useState, useEffect } from 'react'

import { Select, Button, Divider, Skeleton } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import { useCompanies } from '~/hooks/Companies/useCompanies'
import { translate } from '~/utils/locale'

interface CompanyOptions {
  label: string
  value: string
  title: string
}

interface SelectCompanyProps {
  onRequestOpen: () => void
}

const SelectCompany: React.FC<SelectCompanyProps> = ({ onRequestOpen }) => {
  const [listCompaniesOptions, setListCompaniesOptions] = useState<
    CompanyOptions[]
  >([])

  const { data, selectedCompany, handleCurrentCompany, isLoading, isFetching } =
    useCompanies()

  const filterOption = (
    input: string,
    option?: { label: string; value: string },
  ) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())

  useEffect(() => {
    if (data) {
      setListCompaniesOptions(
        data.map((company) => ({
          label: company.company.name,
          title: company.company.name,
          value: company.company.id,
        })),
      )
    }
  }, [data])

  return (
    <>
      {!isLoading ? (
        <Select
          style={{ width: '100%' }}
          showSearch
          loading={isFetching}
          className="menu_input"
          optionFilterProp="children"
          filterOption={filterOption}
          defaultValue={
            selectedCompany === undefined ? data[0].company.id : selectedCompany
          }
          onChange={(e: string) => handleCurrentCompany(e)}
          options={listCompaniesOptions}
          dropdownRender={(menu) => (
            <div>
              {menu}
              <Divider style={{ margin: '4px 0' }} />
              <Button
                type="text"
                block
                icon={<PlusOutlined />}
                onClick={() => onRequestOpen()}
              >
                {translate('SelectCompany.addText')}
              </Button>
            </div>
          )}
        />
      ) : (
        <Skeleton.Input style={{ width: 182, marginTop: 15 }} active />
      )}
    </>
  )
}

export default SelectCompany
