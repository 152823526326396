import React from 'react'

import { Card } from 'antd'

import ScaleContributorsAdd from '~/components/DataManipulation/Scales/ScaleContributors/ScaleContributorsAdd'
import ScaleContributorsList from '~/components/DataManipulation/Scales/ScaleContributors/ScaleContributorsList'
import { translate } from '~/utils/locale'

const ScaleContributorsForm: React.FC = () => {
  return (
    <Card
      bordered={false}
      title={<b>{translate('ScalesContributorsForm.cardTitle')}</b>}
      extra={<ScaleContributorsAdd />}
    >
      <ScaleContributorsList />
    </Card>
  )
}

export default ScaleContributorsForm
