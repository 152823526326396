/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ArrowLeftOutlined,
  LoadingOutlined,
  PlusOutlined,
} from '@ant-design/icons'
import {
  Card,
  Space,
  Button,
  Form,
  Row,
  Col,
  Input,
  DatePicker,
  Select,
  message,
  Upload,
  Switch,
  Tooltip,
} from 'antd'
import ImgCrop from 'antd-img-crop'

import dayjs from 'dayjs'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useAuth } from '~/hooks/Auth/AuthContext'
import { useCompanies } from '~/hooks/Companies/useCompanies'
import { useCategory } from '~/hooks/Equipments/Category/CategoryHooks'
import { useEquipments } from '~/hooks/Equipments/EquipmentsHooks'
import { useListEquipments } from '~/hooks/Equipments/ListEquipmentsHooks'
import { translate } from '~/utils/locale'

import { ContainerButton } from '../../Tabs/styles'

const UpdateDetails: React.FC = () => {
  const { selectedCompany } = useCompanies()
  const { equipments, updateEquipment, isLoading, setIsEdit } = useEquipments()
  const { refetch } = useListEquipments()

  const { category } = useCategory()
  const { Option } = Select

  const { token } = useAuth()

  const [image, setImage] = useState()
  const [loading, setLoading] = useState(false)

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  )

  function getBase64(img: any, callback: any) {
    const reader = new FileReader()
    reader.addEventListener('load', () => callback(reader.result))
    reader.readAsDataURL(img)
  }

  function beforeUpload(file: File) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isJpgOrPng) {
      message.error(translate('EquipmentsUpdateDetails.uploadArchiveTypeError'))
    }
    const isLt2M = file.size / 1024 / 1024 < 2
    if (!isLt2M) {
      message.error(translate('EquipmentsUpdateDetails.uploadArchiveSizeError'))
    }
    return isJpgOrPng && isLt2M
  }

  const handleChange = (info: any) => {
    if (info.file.status === 'uploading') {
      setLoading(true)
      return
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (imageUrl: any) => {
        setImage(imageUrl)
        setLoading(false)
      })
    }

    localStorage.setItem('removeImage', 'image')
  }

  const props = {
    accept: '.jpeg,.png',
    name: 'photo',
    action: `${process.env.REACT_APP_API}company/${selectedCompany}/equipment/${equipments?.id}/photo`,
    headers: {
      authorization: `Bearer ${token}`,
      contentType: 'multipart/form-data',
    },
  }

  if (isLoading || !equipments) {
    return <p />
  }

  const initialData = {
    name: equipments.name,
    identifier: equipments.identifier,
    expiration_date: dayjs(equipments.expiration_date),
    category_id: equipments.category.id,
    description: equipments.description,
    is_enabled: equipments.is_enabled,
  }

  function onUpdateEquipment(valueForm: any) {
    try {
      updateEquipment(valueForm)
      setIsEdit(false)
      refetch()
    } catch (err) {
      setIsEdit(true)
    }
  }

  return (
    <Card
      bordered={false}
      title={
        <Space size="large">
          <Tooltip title="Voltar">
            <Link to="/equipamentos">
              <ArrowLeftOutlined />
            </Link>
          </Tooltip>
          {translate('EquipmentsUpdateDetails.cardTitle')}
        </Space>
      }
      style={{
        display: 'inline-block',
        width: '100%',
      }}
      extra={
        <Space size="middle">
          <ContainerButton>
            <Button
              htmlType="submit"
              form="onUpdateEquipmentForm"
              type="primary"
              id="btn_update_equipment"
            >
              {translate('EquipmentsUpdateDetails.saveButton')}
            </Button>
          </ContainerButton>
          <Button onClick={() => setIsEdit(false)}>
            {translate('EquipmentsUpdateDetails.cancelButton')}
          </Button>
        </Space>
      }
    >
      <Form
        layout="vertical"
        initialValues={initialData}
        onFinish={onUpdateEquipment}
        id="onUpdateEquipmentForm"
      >
        <Form.Item label={translate('EquipmentsUpdateDetails.imageLabel')}>
          <ImgCrop rotationSlider>
            <Upload
              {...props}
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              beforeUpload={beforeUpload}
              onChange={handleChange}
            >
              {image ? (
                <img src={image} alt="avatar" style={{ width: '100%' }} />
              ) : (
                uploadButton
              )}
            </Upload>
          </ImgCrop>
        </Form.Item>
        <Row gutter={12}>
          <Col span={12}>
            <Form.Item
              label={translate('EquipmentsUpdateDetails.nameLabel')}
              name="name"
              rules={[
                {
                  required: true,
                  message: translate('EquipmentsUpdateDetails.nameRule'),
                },
              ]}
            >
              <Input
                placeholder={translate(
                  'EquipmentsUpdateDetails.namePlaceholder',
                )}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={translate('EquipmentsUpdateDetails.identifierLabel')}
              name="identifier"
            >
              <Input
                placeholder={translate(
                  'EquipmentsUpdateDetails.identifierPlaceholder',
                )}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={12}>
            <Form.Item
              label={translate('EquipmentsUpdateDetails.expirationDateLabel')}
              name="expiration_date"
            >
              <DatePicker style={{ width: '100%' }} inputReadOnly />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={translate('EquipmentsUpdateDetails.categoryLabel')}
              name="category_id"
            >
              <Select
                placeholder={translate(
                  'EquipmentsUpdateDetails.categoryPlaceholder',
                )}
              >
                {category?.map((categories) => (
                  <Option value={categories.id} key={categories.id}>
                    {categories.title}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              label={translate('EquipmentsUpdateDetails.descriptionLabel')}
              name="description"
            >
              <Input.TextArea
                placeholder={translate(
                  'EquipmentsUpdateDetails.descriptionPlaceholder',
                )}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              label={translate('EquipmentsUpdateDetails.statusLabel')}
              name="is_enabled"
              valuePropName="checked"
            >
              <Switch
                checkedChildren={translate(
                  'EquipmentsUpdateDetails.statusActive',
                )}
                unCheckedChildren={translate(
                  'EquipmentsUpdateDetails.statusInactive',
                )}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  )
}

export default UpdateDetails
