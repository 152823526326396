/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, useCallback } from 'react'

import {
  Card,
  Col,
  Row,
  Form,
  Input,
  Select,
  Divider,
  Button,
  Space,
  Tooltip,
  message,
} from 'antd'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import pt_BR from 'react-phone-number-input/locale/pt-BR.json'
import { cpf, cnpj } from 'cpf-cnpj-validator'

import { MaskedInput } from 'antd-mask-input'
import { Link } from 'react-router-dom'
import { ArrowLeftOutlined, MailOutlined } from '@ant-design/icons'
import { Container, ContainerButton, FormButtonContainer } from './styles'
import { useSegments } from '~/hooks/Segments/useSegments'
import { useClients } from '~/hooks/Clients/useClients'
import { useListContributors } from '~/hooks/Contributors/useListContributors'
import { useListClients } from '~/hooks/Clients/useListClients'
import { translate } from '~/utils/locale'
import api from '~/services/api'
import { useCompanies } from '~/hooks/Companies/useCompanies'

const ClientsUpdate: React.FC = () => {
  const { segments, isLoading } = useSegments()
  const { clients, setIsEdit, updateClient } = useClients()
  const { refetch } = useListClients()
  const { data } = useListContributors()
  const { selectedCompany } = useCompanies()

  const [typePerson, setTypePerson] = useState('')

  const [form] = Form.useForm()

  const generateFakeEmail = (name: string) => {
    const randomString = Math.random().toString(36).substring(7)
    return `${name
      .replace(/\s+/g, '')
      .toLowerCase()}_${randomString}@gstor.com.br`
  }

  const handleGenerateEmail = () => {
    const nameFieldValue = form.getFieldValue('name')
    if (nameFieldValue) {
      const fakeEmail = generateFakeEmail(nameFieldValue)
      form.setFieldsValue({
        email: fakeEmail,
      })
    } else {
      message.error(translate('ClientsUpdate.randomEmailError'))
    }
  }

  function onTypePerson(value: any) {
    if (value === undefined) {
      setTypePerson('')
    } else {
      setTypePerson(value)
    }
  }

  const initialData = {
    name: clients?.contact.name,
    email: clients?.contact.email,
    person_type: clients?.contact.person_type,
    document: clients?.contact.document,
    cnpj: clients?.contact.cnpj,
    phone: clients?.contact.phone,
    corporate_name: clients?.corporate_name,
    segment_id: clients?.segment?.id,
    external_code: clients?.external_code,
    notes: clients?.notes,
    collaborator_id: clients?.collaborator?.id,
  }

  useEffect(() => {
    if (clients?.contact.person_type) {
      setTypePerson(clients?.contact.person_type)
    }
  }, [clients])

  function onUpdateClient(valueForm: any) {
    try {
      valueForm.name = valueForm.name.toUpperCase()
      if (valueForm.phone) {
        valueForm.phone = valueForm.phone.replace(/[^0-9]/g, '')
      }

      if (valueForm.cnpj) {
        valueForm.cnpj = valueForm.cnpj.replace(/[^0-9]/g, '')
      }

      if (valueForm.document) {
        valueForm.document = valueForm.document.replace(/[^a-zA-Z0-9]/g, '')
      }

      if (valueForm.rg) {
        valueForm.rg = valueForm.rg.replace(/[^a-zA-Z0-9]/g, '')
      }

      updateClient(valueForm)
      setIsEdit(false)
      refetch()
    } catch (err) {
      setIsEdit(true)
    }
  }

  const checkCNPJ = useCallback(
    async (cnpjInput: string) => {
      try {
        cnpjInput = cnpjInput.replace(/[^0-9]/g, '')
        const { data: cnpjCheckData } = await api.get(
          `company/${selectedCompany}/cnpjcheck/${cnpjInput}`,
        )
        if (cnpjCheckData.cnpjInUse) {
          message.warning(translate('ClientsUpdate.cnpjInUseMessage'), 10)
        }
      } catch (err: any) {
        console.log(err)
      }
    },
    [selectedCompany],
  )

  return (
    <Card
      bordered={false}
      title={
        <Space size="large">
          <Tooltip title={translate('ClientsUpdate.backTooltip')}>
            <Link to="/clientes">
              <ArrowLeftOutlined />
            </Link>
          </Tooltip>
          {translate('ClientsUpdate.cardTitle')}
        </Space>
      }
      style={{
        display: 'inline-block',
        width: '100%',
      }}
      extra={
        <Space size="middle">
          <ContainerButton>
            <Button
              htmlType="submit"
              form="onUpdateClientForm"
              type="primary"
              id="btn_update_client"
            >
              {translate('ClientsUpdate.saveButton')}
            </Button>
          </ContainerButton>

          <Button onClick={() => setIsEdit(false)}>
            {translate('ClientsUpdate.cancelButton')}
          </Button>
        </Space>
      }
    >
      <Container>
        <Form
          layout="vertical"
          initialValues={initialData}
          onFinish={onUpdateClient}
          form={form}
          id="onUpdateClientForm"
        >
          <Row gutter={16}>
            <Col span={10}>
              <Form.Item
                label={translate('ClientsUpdate.nameLabel')}
                name="name"
                rules={[
                  {
                    required: true,
                    message: translate('ClientsUpdate.nameRule'),
                  },
                ]}
              >
                <Input style={{ textTransform: 'uppercase' }} />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item
                label={
                  <Space size="large">
                    {translate('ClientsUpdate.emailLabel')}
                    <Tooltip
                      title={translate('ClientsUpdate.emailGenerateTooltip')}
                    >
                      <Button
                        shape="circle"
                        size="small"
                        icon={<MailOutlined />}
                        onClick={handleGenerateEmail}
                      />
                    </Tooltip>
                  </Space>
                }
                name="email"
                rules={[
                  {
                    type: 'email',
                    message: translate('ClientsUpdate.emailRuleOne'),
                  },
                  {
                    required: true,
                    message: translate('ClientsUpdate.emailRuleTwo'),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={10}>
              <Form.Item
                label={translate('ClientsUpdate.personTypeLabel')}
                name="person_type"
              >
                <Select
                  allowClear
                  placeholder={translate('ClientsUpdate.personTypePlaceholder')}
                  onChange={onTypePerson}
                >
                  <Select.Option value="physicalPerson">
                    {translate('ClientsUpdate.physicalPerson')}
                  </Select.Option>
                  <Select.Option value="legalPerson">
                    {translate('ClientsUpdate.juridicPerson')}
                  </Select.Option>
                  <Select.Option value="foreign">
                    {translate('ClientsUpdate.foreign')}
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
            {typePerson !== '' && (
              <>
                <Col span={6}>
                  <Form.Item
                    label={
                      typePerson === 'legalPerson' ||
                      typePerson === 'physicalPerson'
                        ? translate('ClientsUpdate.cpfLabel')
                        : translate('ClientsUpdate.passportLabel')
                    }
                    name="document"
                    rules={[
                      {
                        required: typePerson === 'physicalPerson',
                        message: translate('ClientsUpdate.documentRuleOne'),
                      },
                      {
                        validator(_, value) {
                          if (value === undefined || value === null) {
                            return Promise.resolve()
                          }
                          const CpfInput = value.replace(/[^0-9]/g, '')
                          if (CpfInput.length === 11) {
                            if (cpf.isValid(value)) {
                              return Promise.resolve()
                            }
                            // eslint-disable-next-line prefer-promise-reject-errors
                            return Promise.reject(
                              translate('ClientsUpdate.documentRuleTwo'),
                            )
                          }
                          return Promise.resolve()
                        },
                      },
                    ]}
                  >
                    {typePerson === 'physicalPerson' ||
                    typePerson === 'legalPerson' ? (
                      <MaskedInput mask={'000.000.000-00'} placeholder="" />
                    ) : (
                      <MaskedInput mask={'aa000000'} placeholder="" />
                    )}
                  </Form.Item>
                </Col>
                <Col span={6}>
                  {typePerson !== 'physicalPerson' && (
                    <Form.Item
                      label={translate('ClientsUpdate.cnpjLabel')}
                      name="cnpj"
                      rules={[
                        {
                          validator(_, value) {
                            if (value === undefined || value === null) {
                              return Promise.resolve()
                            }
                            const CnpjInput = value.replace(/[^0-9]/g, '')
                            if (CnpjInput.length === 14) {
                              if (cnpj.isValid(value)) {
                                return Promise.resolve()
                              }
                              // eslint-disable-next-line prefer-promise-reject-errors
                              return Promise.reject(
                                translate('ClientsUpdate.cnpjRuleTwo'),
                              )
                            }
                            return Promise.resolve()
                          },
                        },
                      ]}
                    >
                      <MaskedInput
                        mask={'00.000.000/0000-00'}
                        onBlur={(e) => checkCNPJ(e.target.value)}
                      />
                    </Form.Item>
                  )}

                  {typePerson === 'physicalPerson' && (
                    <Form.Item
                      label={translate('ClientsUpdate.idLabel')}
                      name="rg"
                    >
                      <Input />
                    </Form.Item>
                  )}
                </Col>
              </>
            )}
          </Row>
          <Row gutter={16}>
            <Col span={10}>
              <Form.Item
                label={translate('ClientsUpdate.phoneLabel')}
                name="phone"
                rules={[
                  () => ({
                    validator(rule, phone) {
                      if (phone === undefined || phone === null) {
                        return Promise.resolve()
                      }
                      if (phone && isValidPhoneNumber(phone)) {
                        return Promise.resolve()
                      }
                      return Promise.reject(
                        new Error(translate('ClientsUpdate.phoneRule')),
                      )
                    },
                  }),
                ]}
              >
                <PhoneInput
                  placeholder={translate('ClientsUpdate.phonePlaceholder')}
                  labels={pt_BR}
                  defaultCountry="BR"
                  international
                  onChange={() => {
                    // Do nothing
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Divider />
          <Row gutter={16}>
            <Col span={10}>
              <Form.Item
                label={translate('ClientsUpdate.corporateNameLabel')}
                name="corporate_name"
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item
                label={translate('ClientsUpdate.segmentLabel')}
                name="segment_id"
              >
                <Select loading={isLoading}>
                  {segments?.map((segment) => (
                    <Select.Option value={segment?.id} key={segment?.id}>
                      {segment?.title}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={10}>
              <Form.Item
                label={translate('ClientsUpdate.externalCodeLabel')}
                name="external_code"
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item
                label={translate('ClientsUpdate.responsibleLabel')}
                name="collaborator_id"
              >
                <Select optionFilterProp="children" showSearch>
                  {data?.map((contribuitor) => (
                    <Select.Option
                      value={contribuitor.id}
                      key={contribuitor.id}
                    >
                      {contribuitor.contact.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={10}>
              <Form.Item
                label={translate('ClientsUpdate.observationLabel')}
                name="notes"
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <FormButtonContainer>
            <Space size="large" style={{ alignItems: 'baseline' }}>
              <Button
                htmlType="submit"
                form="onUpdateClientForm"
                type="primary"
              >
                {translate('ClientsUpdate.saveButton')}
              </Button>
              <Button onClick={() => setIsEdit(false)}>
                {translate('ClientsUpdate.cancelButton')}
              </Button>
            </Space>
          </FormButtonContainer>
        </Form>
      </Container>
    </Card>
  )
}

export default ClientsUpdate
