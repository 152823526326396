import { Card, Col, Popconfirm, Space, Tooltip, Button, Table } from 'antd'
import React from 'react'

import { DeleteOutlined, LoadingOutlined } from '@ant-design/icons'

import { ColumnsType } from 'antd/lib/table'
import { Container, Specification } from './styles'

import CreateSpecification from './Create'
import { useSpecifications } from '~/hooks/Equipments/Especification/useSpecifications'
// import UpdateSpecification from './Update';
import ImportTemplate from './ImportTemplate'
import UpdateSpecification from './Update'
import { translate } from '~/utils/locale'

interface SpecificationProps {
  attribute: string
  value: string
}

const SpecificationTab: React.FC = () => {
  const { specifications, deleteSpecification, isLoading } = useSpecifications()

  const columns: ColumnsType<SpecificationProps> = [
    {
      title: translate('EquipmentsTabsSpecification.attributeColumn'),
      align: 'left',
      key: 'name',
      dataIndex: 'attribute',
    },
    {
      title: translate('EquipmentsTabsSpecification.valueColumn'),
      align: 'left',
      key: 'name',
      dataIndex: 'value',
    },
  ]

  return (
    <Container>
      <Card
        bordered={false}
        title={translate('EquipmentsTabsSpecification.cardTitle')}
        style={{
          display: 'inline-block',
          width: '100%',
        }}
        extra={
          <Space size="middle">
            <ImportTemplate />
            <CreateSpecification />
          </Space>
        }
      >
        <Specification>
          {specifications?.map((specification, index) => (
            <Col key={index}>
              <Card
                title={specification.name}
                size="small"
                className="card_specification"
                extra={[
                  <Space key="cardExtra">
                    <UpdateSpecification updateSpecification={specification} />
                    <Popconfirm
                      title={translate(
                        'EquipmentsTabsSpecification.deletePopconfirm',
                      )}
                      onConfirm={() => deleteSpecification(specification.id)}
                    >
                      <Tooltip
                        title={translate(
                          'EquipmentsTabsSpecification.deleteTooltip',
                        )}
                      >
                        <Button
                          ghost
                          shape="circle"
                          icon={<DeleteOutlined />}
                          size="small"
                          danger
                        />
                      </Tooltip>
                    </Popconfirm>
                  </Space>,
                ]}
              >
                <Table
                  size="small"
                  loading={{
                    indicator: <LoadingOutlined />,
                    spinning: isLoading,
                    size: 'large',
                  }}
                  columns={columns}
                  pagination={false}
                  dataSource={specification.specifications}
                />
              </Card>
            </Col>
          ))}
        </Specification>
      </Card>
    </Container>
  )
}

export default SpecificationTab
