import React from 'react'
import ParentClientsTab from '~/components/DataManipulation/Clients/Parent/Tabs'

import { ListClientsProvider } from '~/hooks/Clients/useListClients'
import { ParentCustomerProvider } from '~/hooks/Clients/useParentCustomer'

// import { Container } from './styles';

const ShowParentCustomers: React.FC = () => {
  return (
    <ParentCustomerProvider>
      <ListClientsProvider>
        <ParentClientsTab />
      </ListClientsProvider>
    </ParentCustomerProvider>
  )
}

export default ShowParentCustomers
