/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react'

import { Collapse, Divider, Empty, Space, Tooltip } from 'antd'
import { format } from 'date-fns'
import Text from 'antd/lib/typography/Text'

import ReactAudioPlayer from 'react-audio-player'
import ReactPlayer from 'react-player'
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox'
import { DownloadOutlined } from '@ant-design/icons'
import { Wrapper } from './styles'
import { useTask } from '~/hooks/Tasks/useTask'
import { translate } from '~/utils/locale'
import { Cards, Container } from '../Details/styles'
import { useCompanies } from '~/hooks/Companies/useCompanies'

const ReportTaskHistoric: React.FC = () => {
  const { task, isLoading } = useTask()
  const { companiesData } = useCompanies()

  if (isLoading || !task) {
    return <p>{translate('TasksTabsReport.loadingText')}</p>
  }

  return (
    <Wrapper>
      {task.reports.length !== 0 ? (
        <Container>
          {task.reports.map((report, index) => (
            <Cards key={index}>
              <div className="title">
                <Text strong>
                  Evidência criada em &nbsp;
                  {format(
                    new Date(report.created_at),
                    `dd/MM/yyyy '${translate(
                      'TasksTabsDetails.dateString',
                    )}' HH:mm'h'`,
                  )}
                  &nbsp; por {companiesData?.[0].collaborator.contact.name}
                </Text>
              </div>

              <Divider />

              <Collapse ghost>
                <Collapse.Panel header="Dados da Evidência" key={report.id}>
                  <div className="description">
                    <div className="text">
                      {report.title === null || report.title === 'undefined' ? (
                        <>
                          <Text>
                            <b>Título:</b>
                            {report.type === 'image' ? (
                              <b>{translate('TasksTabsReport.imageText')}</b>
                            ) : (
                              ''
                            )}
                            {report.type === 'video' ? (
                              <b>{translate('TasksTabsReport.videoText')}</b>
                            ) : (
                              ''
                            )}
                            {report.type === 'application' ? (
                              <b>
                                {translate('TasksTabsReport.applicationText')}
                              </b>
                            ) : (
                              ''
                            )}
                            {report.type === 'audio' ? (
                              <b>{translate('TasksTabsReport.audioText')}</b>
                            ) : (
                              ''
                            )}
                            {report.type === 'text' ? (
                              <b>{translate('TasksTabsReport.textText')}</b>
                            ) : (
                              ''
                            )}
                            {report.type === 'notes' ? (
                              <b>{translate('TasksTabsReport.notesText')}</b>
                            ) : (
                              ''
                            )}
                          </Text>
                        </>
                      ) : (
                        <Text>
                          <b>Título: </b>
                          {report.title}
                        </Text>
                      )}
                    </div>

                    <Space>
                      {report.type === 'image' ? (
                        <Space direction="vertical">
                          <p>{translate('TasksTabsReport.imageDescription')}</p>
                          <SimpleReactLightbox>
                            <SRLWrapper>
                              <div
                                style={{
                                  border: '1px solid #cccccc',
                                  padding: 5,
                                  borderRadius: 3,
                                }}
                              >
                                <img
                                  src={report.value}
                                  alt="Imagem relato"
                                  width="200px"
                                  style={{ borderRadius: 3 }}
                                />
                              </div>
                            </SRLWrapper>
                          </SimpleReactLightbox>
                        </Space>
                      ) : (
                        ''
                      )}
                      {report.type === 'video' ? (
                        <Space direction="vertical">
                          <p>{translate('TasksTabsReport.videoDescription')}</p>
                          <div
                            style={{
                              border: '1px solid #cccccc',
                              padding: 5,
                              borderRadius: 3,
                            }}
                          >
                            <ReactPlayer
                              controls
                              url={report.value}
                              width="200px"
                              height="100px"
                            />
                          </div>
                        </Space>
                      ) : (
                        ''
                      )}
                      {report.type === 'application' ? (
                        <b>
                          {translate('TasksTabsReport.applicationDescription')}
                        </b>
                      ) : (
                        ''
                      )}
                      {report.type === 'audio' ? (
                        <Space direction="vertical">
                          <p>{translate('TasksTabsReport.audioDescription')}</p>
                          <ReactAudioPlayer src={report.value} controls />
                        </Space>
                      ) : (
                        ''
                      )}
                      {report.type === 'text' ? (
                        <b>{translate('TasksTabsReport.textDescription')}</b>
                      ) : (
                        ''
                      )}
                      {report.type === 'notes' ? (
                        <b>{translate('TasksTabsReport.notesDescription')}</b>
                      ) : (
                        ''
                      )}

                      <div>
                        {report.type === 'image' ||
                        report.type === 'application' ? (
                          <Tooltip
                            title={translate('TasksTabsReport.downloadTooltip')}
                          >
                            <a
                              href={report.value}
                              target="_blank"
                              rel="noopener noreferrer"
                              download
                            >
                              <DownloadOutlined />
                            </a>
                          </Tooltip>
                        ) : (
                          ''
                        )}
                      </div>
                    </Space>

                    <div>
                      {report.type === 'notes' ? <p>{report.value}</p> : ''}
                    </div>
                  </div>
                </Collapse.Panel>
              </Collapse>
            </Cards>
          ))}
        </Container>
      ) : (
        <Empty />
      )}
    </Wrapper>
  )
}

export default ReportTaskHistoric
