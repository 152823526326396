export default {
  pt: {
    GroupsDetails: {
      timeString: 'às',
      cardTitle: 'Informações Gerais',
      createdText: 'Criado em',
      groupCustom: 'Tipo do grupo: Personalizado',
      groupUser: 'Tipo do grupo: Usuário',
      groupManager: 'Tipo do grupo: Gestor',
      groupAdmin: 'Tipo do grupo: Administrador',
      permissionsText: 'permissões',
      memberText: 'membro',
      membersText: 'membros',
      permissionUsersCardTitle: 'Usuários com permissões',
      deletePopconfirm: 'Deseja mesmo deletar o usuário?',
      deleteTooltip: 'Deletar usuário do grupo',
      viewTooltip: 'Ver colaborador',
      permissionContributorsCardTitle: 'Colaboradores permitidos',
      deleteContributorPopconfirm: 'Deseja mesmo remover o colaborador?',
      deleteContributorTooltip: 'Remover o colaborador das permissões',
      viewContributorTooltip: 'Ver colaborador',
    },
  },
  en: {
    GroupsDetails: {
      timeString: 'at',
      cardTitle: 'General information',
      createdText: 'Created in',
      groupCustom: 'Group Type: Custom',
      groupUser: 'Group type: User',
      groupManager: 'Group Type: Manager',
      groupAdmin: 'Group Type: Administrator',
      permissionsText: 'permissions',
      memberText: 'member',
      membersText: 'members',
      permissionUsersCardTitle: 'Users with permissions',
      deletePopconfirm: 'Do you really want to delete the user?',
      deleteTooltip: 'Delete user from group',
      viewTooltip: 'View collaborator',
      permissionContributorsCardTitle: 'Allowed collaborators',
      deleteContributorPopconfirm:
        'Do you really want to remove the collaborator?',
      deleteContributorTooltip: 'Remove collaborator from permissions',
      viewContributorTooltip: 'View contributor',
    },
  },
  es: {
    GroupsDetails: {
      timeString: 'a las',
      cardTitle: 'Informaciones generales',
      createdText: 'Creado en',
      groupCustom: 'Tipo de grupo: Personalizado',
      groupUser: 'Tipo de grupo: Usuario',
      groupManager: 'Tipo de grupo: Gerente',
      groupAdmin: 'Tipo de grupo: Administrador',
      permissionsText: 'permisos',
      memberText: 'miembro',
      membersText: 'miembros',
      permissionUsersCardTitle: 'Usuarios con permisos',
      deletePopconfirm: '¿Realmente desea eliminar el usuario?',
      deleteTooltip: 'Eliminar usuario del grupo',
      viewTooltip: 'Ver colaborador',
      permissionContributorsCardTitle: 'Colaboradores permitidos',
      deleteContributorPopconfirm:
        '¿Realmente quieres eliminar al colaborador?',
      deleteContributorTooltip: 'Eliminar colaborador de permisos',
      viewContributorTooltip: 'Ver colaborador',
    },
  },
}
