import {
  CheckCircleOutlined,
  IssuesCloseOutlined,
  StopOutlined,
  TeamOutlined,
} from '@ant-design/icons'

import React from 'react'

import { Typography } from 'antd'
import { useDashboard } from '~/hooks/Dashboard/useDashboard'

import { Container, Cards, Wrapper } from './styles'
import Rates from '../Rates'

const DashboardCardsContributors: React.FC = () => {
  const { contribuitors } = useDashboard()

  const total = contribuitors?.length

  const enabledContribuitors = contribuitors?.filter((c) => {
    return c.status?.type === 'enabled'
  })

  const enabledWithRestrictionsContribuitors = contribuitors?.filter((c) => {
    return c.status?.type === 'enabled_with_restrictions'
  })

  const blockedContribuitors = contribuitors?.filter((c) => {
    return c.status?.type === 'blocked'
  })

  const data = [
    {
      id: 1,
      backgroundColor: '#3C8ACB',
      iconBackgroundColor: '#5eacec',
      iconColor: 'white',
      titleColor: 'white',
      title: 'Total de Colaboradores',
      value: total,
      icon: <TeamOutlined />,
    },
    {
      id: 2,
      backgroundColor: '#3969B7',
      iconBackgroundColor: '#5eacec',
      iconColor: 'white',
      titleColor: 'white',
      title: 'Habilitados',
      value: enabledContribuitors?.length,
      icon: <CheckCircleOutlined />,
    },
    {
      id: 3,
      backgroundColor: '#50CD85',
      iconBackgroundColor: '#3DAA6B',
      iconColor: 'white',
      titleColor: 'white',
      title: 'Habilitados com Restrições',
      value: enabledWithRestrictionsContribuitors?.length,
      icon: <IssuesCloseOutlined />,
    },
    {
      id: 4,
      backgroundColor: '#FB4A5F',
      iconBackgroundColor: '#DE384C',
      iconColor: 'white',
      titleColor: 'white',
      title: 'Bloqueados',
      value: blockedContribuitors?.length,
      icon: <StopOutlined />,
    },
  ]

  return (
    <Wrapper>
      <Container>
        {data.map((d) => (
          <Cards
            key={d.id}
            backgroundColor={d.backgroundColor}
            iconBackgroundColor={d.iconBackgroundColor}
            iconColor={d.iconColor}
            titleColor={d.titleColor}
          >
            <div className="header">
              <div className="icon">{d.icon}</div>
              <div className="content">
                <p>{d.title}</p>
                <Typography.Title level={4}>
                  {d.value} Colaboradores{' '}
                </Typography.Title>
              </div>
            </div>
          </Cards>
        ))}
      </Container>

      <Rates />
    </Wrapper>
  )
}

export default DashboardCardsContributors
