/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable import/no-duplicates */
/* eslint-disable react-hooks/exhaustive-deps */
// import { formatDistance } from 'date-fns';
import { Tag } from 'antd'
import Text from 'antd/lib/typography/Text'
import { add, formatDistance, isPast } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import React, { useEffect, useState } from 'react'
import { translate } from '~/utils/locale'

// import { Container } from './styles';

interface ExeuctionTimeCountdownProps {
  executionTime: string
  status: string
  checkinDate: string
}

const ExeuctionTimeCountdown: React.FC<ExeuctionTimeCountdownProps> = ({
  executionTime,
  status,
  checkinDate,
}) => {
  const hourExceptionTaskReplace = executionTime.replace(/\D/g, '')

  const hoursFormatted: any = hourExceptionTaskReplace?.substring(2, 0)
  const minutesFormatted: any = hourExceptionTaskReplace?.substring(2, 4)

  const newDate = add(new Date(checkinDate), {
    hours: hoursFormatted,
    minutes: minutesFormatted,
    seconds: 0,
  })

  const distanceDate = formatDistance(newDate, new Date(), {
    locale: ptBR,
  })

  const pastDate = isPast(newDate)

  return (
    <div>
      {status !== 'working' ? (
        <Tag>{translate('TasksTabsDetails.noWorkingStatusText')}</Tag>
      ) : (
        ''
      )}

      {status === 'working' ? (
        <>
          {!pastDate ? (
            <Tag color="green">
              {translate('TasksTabsDetails.youHaveText')} {distanceDate}{' '}
              {translate('TasksTabsDetails.executeTaskText')}
            </Tag>
          ) : (
            <Tag color="red">
              {' '}
              {translate('TasksTabsDetails.theTaskLateText')} {distanceDate}{' '}
              {translate('TasksTabsDetails.finishedTaskText')}
            </Tag>
          )}
        </>
      ) : (
        ''
      )}
    </div>
  )
}

export default ExeuctionTimeCountdown
