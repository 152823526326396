/* eslint-disable @typescript-eslint/no-explicit-any */
import { PlusCircleOutlined } from '@ant-design/icons'
import { Modal, Button, Form, Input, Checkbox, Space } from 'antd'
import React, { useState } from 'react'
import { CompactPicker } from 'react-color'
import { useListStatus } from '~/hooks/Status/useListStatus'
import { translate } from '~/utils/locale'

import { Cont, Container } from './styles'

interface StatusData {
  title: string
  description: string
  color: {
    hex: string
  }
}

const CreateStatus: React.FC = () => {
  const { createStatus } = useListStatus()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [keepRegister, setKeepRegister] = useState(false)

  const [colors, setColors] = useState('#007be0')

  const [form] = Form.useForm()

  function onCreateStatus(valueForm: StatusData) {
    try {
      const dataForm = {
        color: colors,
        title: valueForm.title,
        description: valueForm.description,
      }

      createStatus(dataForm)
      if (!keepRegister) {
        setIsModalVisible(false)
      }
      form.resetFields()
    } catch (err) {
      setIsModalVisible(false)
    }
  }

  const showModal = () => {
    setIsModalVisible(true)
  }

  const closeModal = () => {
    setIsModalVisible(false)
  }

  return (
    <Container>
      <Button type="primary" icon={<PlusCircleOutlined />} onClick={showModal}>
        {translate('StatusCreate.addStatusButton')}
      </Button>
      <Modal
        title={translate('StatusCreate.modalTitle')}
        open={isModalVisible}
        onOk={closeModal}
        onCancel={closeModal}
        footer={[
          <Space key="buttons">
            <Checkbox
              onChange={(e) => {
                setKeepRegister(e.target.checked)
              }}
              checked={keepRegister}
            >
              {translate('StatusCreate.keepRegisterText')}
            </Checkbox>

            <Button onClick={closeModal}>
              {translate('StatusCreate.closeButton')}
            </Button>

            <Cont>
              <Button
                type="primary"
                form="onFormStatus"
                htmlType="submit"
                id="btn_create_status"
              >
                {translate('StatusCreate.registerButton')}
              </Button>
            </Cont>
          </Space>,
        ]}
      >
        <Form
          id="onFormStatus"
          layout="vertical"
          onFinish={onCreateStatus}
          form={form}
        >
          <Form.Item
            label={translate('StatusCreate.titleLabel')}
            name="title"
            rules={[
              {
                required: true,
                message: translate('StatusCreate.titleRule'),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={translate('StatusCreate.descriptionLabel')}
            name="description"
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item label={translate('StatusCreate.colorLabel')} name="color">
            <CompactPicker
              color={colors}
              onChangeComplete={(colorSelect) => {
                setColors(colorSelect.hex)
              }}
            />
          </Form.Item>
        </Form>
      </Modal>
    </Container>
  )
}

export default CreateStatus
