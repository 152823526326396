/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
import React from 'react'

// import Highlighter from 'react-highlight-words';

import { Table, Space, Button } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { ColumnsType } from 'antd/es/table'

import { Link } from 'react-router-dom'
import AddToScaleButton from '~/components/DataManipulation/Misc/AddToScaleButton'
import { translate } from '~/utils/locale'

interface contribuitorsTeams {
  collaborator: {
    id: string
    contact: {
      id: string
      name: string
    }
  }
}

interface Managers {
  id: string
  contact: {
    id: string
    name: string
  }
}

interface DataType {
  title: string
  id: string
  created_at: Date
  manager: Managers
  description: string
  contributorsTeams: contribuitorsTeams[]
}

interface TableProps {
  data: DataType[]
  loading: boolean
}

const ScaleTeamsTable: React.FC<TableProps> = ({ data, loading }) => {
  const columns: ColumnsType<DataType> = [
    {
      title: translate('ScalesTeamsTable.titleColumn'),
      align: 'left',
      dataIndex: ['title'],
      key: 'title',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a, b) => a.title.localeCompare(b.title),
    },
    {
      title: translate('ScalesTeamsTable.managerColumn'),
      dataIndex: ['manager', 'contact', 'name'],
      align: 'center',
      key: 'manager',
    },
    {
      title: translate('ScalesTeamsTable.actionsColumn'),
      align: 'center',
      key: 'remove',
      render: (record) => (
        <Space size="middle">
          <AddToScaleButton isTeams id={record.id} />
        </Space>
      ),
    },
  ]

  return (
    <Table
      rowKey={(record) => record.id}
      loading={{
        indicator: <LoadingOutlined />,
        spinning: loading,
        size: 'large',
      }}
      pagination={{
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '50', '100', '200'],
        defaultPageSize: 10,
        showTotal: (total, range) => `${range[0]} - ${range[1]} / ${total}`,
      }}
      locale={{
        emptyText: (
          <Space direction="vertical">
            <span style={{ color: '#9B9B9B' }}>
              {translate('ScalesTeamsTable.infoText')}
            </span>
            <Link to="/equipes">
              <Button type="primary">
                {translate('ScalesTeamsTable.addButton')}
              </Button>
            </Link>
          </Space>
        ),
      }}
      columns={columns}
      dataSource={data}
      scroll={{ x: 1000 }}
    />
  )
}

export default ScaleTeamsTable
