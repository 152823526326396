import React from 'react'
import { Helmet } from 'react-helmet'

import CategoryBoard from '~/components/DataManipulation/Equipments/Category/Board'

import { CategoryProvider } from '~/hooks/Equipments/Category/CategoryHooks'
import { translate } from '~/utils/locale'

import { Container } from './styles'

const ShowCategory: React.FC = () => {
  return (
    <Container>
      <Helmet>
        <title>{translate('ShowCategory.helmetTitle')}</title>
      </Helmet>
      <CategoryProvider>
        <CategoryBoard />
      </CategoryProvider>
    </Container>
  )
}

export default ShowCategory
