/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ColumnsType } from 'antd/lib/table'
import React from 'react'

import { Popconfirm, Space, Table, Button, Tooltip } from 'antd'
import {
  DeleteFilled,
  LoadingOutlined,
  ProfileOutlined,
} from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { useListEquipments } from '~/hooks/Equipments/ListEquipmentsHooks'
import { translate } from '~/utils/locale'

// import { Container } from './styles';

interface EquipmentsProps {
  id: string
  name: string
  created_at: Date
  expiration_date: Date
  identifier: number
  is_enabled: boolean
  category: {
    id: string
    title: string
  }
}

const EquipmentsTable: React.FC = () => {
  const {
    listEquipments,
    deleteEquipment,
    isFetching,
    setNumberPage,
    setPageSize,
    equipmentsTotal,
  } = useListEquipments()

  const columns: ColumnsType<EquipmentsProps> = [
    {
      title: translate('EquipmentsTable.nameColumn'),
      dataIndex: 'name',
      align: 'left',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (name: string, record) => (
        <Link to={`/equipamentos/visualizar/${record.id}/equipamento/`}>
          {name}
        </Link>
      ),
    },
    {
      title: translate('EquipmentsTable.identifierColumn'),
      dataIndex: 'identifier',
      align: 'center',
      key: 'codigo',
    },
    {
      title: translate('EquipmentsTable.categoryColumn'),
      dataIndex: ['category', 'title'],
      align: 'center',
      key: 'categoria',
    },
    {
      title: translate('EquipmentsTable.actionsColumn'),
      align: 'center',
      key: 'remove',
      render: (record) => (
        <Space size="middle">
          <Tooltip title={translate('EquipmentsTable.viewEquipmentTooltip')}>
            <Link to={`/equipamentos/visualizar/${record.id}/equipamento/`}>
              <Button
                type="primary"
                ghost
                shape="circle"
                icon={<ProfileOutlined />}
              />
            </Link>
          </Tooltip>
          <Popconfirm
            title={translate('EquipmentsTable.deletePopconfirm')}
            onConfirm={() => deleteEquipment(record.id)}
          >
            <Tooltip title={translate('EquipmentsTable.deleteTooltip')}>
              <Button ghost danger shape="circle" icon={<DeleteFilled />} />
            </Tooltip>
          </Popconfirm>
        </Space>
      ),
    },
  ]

  return (
    <Table
      rowKey={(record) => record.id}
      loading={{
        indicator: <LoadingOutlined />,
        spinning: isFetching,
        size: 'large',
      }}
      pagination={{
        total: equipmentsTotal,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '50', '100', '200'],
        defaultPageSize: 10,
        showTotal: (total, range) => `${range[0]} - ${range[1]} / ${total}`,
        onChange: (page, pageSize) => {
          setPageSize(pageSize!)
          setNumberPage(page)
        },
      }}
      columns={columns}
      dataSource={listEquipments}
      scroll={{ x: 1000 }}
    />
  )
}

export default EquipmentsTable
