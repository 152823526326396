/* eslint-disable camelcase */
import styled, { keyframes } from 'styled-components'

import { shade } from 'polished'

import Image_Login from '../../../assets/Home.png'

export const Container = styled.div`
  height: 100vh;

  display: flex;
  align-items: stretch;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  place-content: center;

  background-color: var(--primary-initial-bg);

  width: 100%;
  max-width: 700px;

  @media screen and (max-width: 798px) {
    max-width: 500px;
  }
`

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`

export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  animation: ${appearFromLeft} 1s;

  img {
    height: 13rem;
    width: 13rem;
    position: relative;
    right: 4rem;
  }

  form {
    width: 400px;

    h1 {
      margin-bottom: 2px;
      color: var(--primary-initial-text);
    }

    p {
      margin-bottom: 20px;
      font-size: 15px;
      color: var(--secondary-initial-text);
    }

    button {
      padding: 0 16px;
      height: 56px;
      width: 100%;
      border-radius: 10px;
      background-color: var(--primary-color);
      margin-top: 30px;
      color: var(--color-white);
      font-weight: 500;
      transition: background-color 0.4s;

      &:hover {
        background: ${shade(0.2, '#3C8ACB')};
      }
    }

    a {
      color: var(--secondary-initial-text);
      display: block;
      margin-top: 10px;
      transition: color 0.2s;
      font-size: 15px;

      svg {
        margin-right: 8px;
      }

      &:hover {
        color: ${shade(0.2, '#f4ede8')};
      }
    }
  }

  > a {
    color: var(--secondary-color);
    display: block;
    margin-top: 24px;
    transition: color 0.2s;
    display: flex;
    align-items: center;
    svg {
      margin-right: 16px;
    }
    &:hover {
      color: ${shade(0.2, '#64C77C')};
    }
  }

  @media screen and (max-width: 458px) {
    form {
      width: 350px;
    }
  }

  @media screen and (max-width: 378px) {
    form {
      width: 320px;
    }
  }

  @media screen and (max-width: 328px) {
    form {
      width: 300px;
    }
  }
`

export const Background = styled.div`
  flex: 1;
  background: url(${Image_Login}) no-repeat center;
  background-size: cover;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;

  padding: 0 4rem 0 4rem;

  h1 {
    font-size: 55px;
    color: var(--color-white);
    width: 100%;
    font-weight: bold;
    max-width: 400px;
  }

  .lg-footer {
    display: flex;
    align-items: center;

    width: 100%;

    hr {
      width: 120px;
    }

    h4 {
      margin-left: 20px;
      color: var(--primary-color);
    }

    svg {
      margin-left: 10px;
      margin-bottom: 5px;
      color: var(--primary-color);
    }
  }
`
