export default {
  pt: {
    useContactClients: {
      getErrorMessage:
        'Você não tem permissão para ver os contatos dos clientes.',
      createSuccessMessage: 'Contato criado com sucesso!',
      createErrorMessage: 'Erro ao criar contatos de clientes.',
      updateSuccessMessage: 'Contato atualizado com sucesso!',
      updateErrorMessage: 'Houve um erro ao atualizar contatos de clientes.',
      deleteSuccessMessage: 'Contato deletado com sucesso!',
      deleteErrorMessage: 'Houve um erro ao excluir contatos de clientes.',
    },
  },
  en: {
    useContactClients: {
      getErrorMessage: 'You are not allowed to view customer contacts.',
      createSuccessMessage: 'Contact created successfully!',
      createErrorMessage: 'Error creating customer contacts.',
      updateSuccessMessage: 'Contact updated successfully!',
      updateErrorMessage: 'There was an error updating customer contacts.',
      deleteSuccessMessage: 'Contact successfully deleted!',
      deleteErrorMessage: 'There was an error deleting customer contacts.',
    },
  },
  es: {
    useContactClients: {
      getErrorMessage:
        'No tiene permiso para ver los contactos de los clientes.',
      createSuccessMessage: '¡Contacto creado con éxito!',
      createErrorMessage: 'Error al crear contactos de clientes.',
      updateSuccessMessage: '¡Contacto actualizado con éxito!',
      updateErrorMessage:
        'Hubo un error al actualizar los contactos del cliente.',
      deleteSuccessMessage: '¡Contacto eliminado con éxito!',
      deleteErrorMessage:
        'Se produjo un error al eliminar los contactos de los clientes.',
    },
  },
}
