export default {
  pt: {
    useUserGroup: {
      getErrorMessage: 'Erro ao visualizar usuários.',
      createSuccessMessage: 'Usuário adicionado ao grupo com sucesso!',
      createErrorMessage: 'Erro ao adicionar usuário.',
      deleteSuccessMessage: 'Usuário deletado do grupo com sucesso!',
      deleteErrorMessage: 'Erro ao excluir usuário.',
    },
  },
  en: {
    useUserGroup: {
      getErrorMessage: 'Error viewing users.',
      createSuccessMessage: 'User successfully added to the group!',
      createErrorMessage: 'Error adding user.',
      deleteSuccessMessage: 'User deleted from group successfully!',
      deleteErrorMessage: 'Error deleting user.',
    },
  },
  es: {
    useUserGroup: {
      getErrorMessage: 'Error al ver los usuarios.',
      createSuccessMessage: '¡Usuario añadido con éxito al grupo!',
      createErrorMessage: 'Error al agregar usuario.',
      deleteSuccessMessage: '¡Usuario eliminado del grupo con éxito!',
      deleteErrorMessage: 'Error al eliminar usuario.',
    },
  },
}
