/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Input, message } from 'antd'
import React, { useState } from 'react'

import { translate } from '~/utils/locale'
import CardTickets from '../Card'

// import CardsKanban from '../Cards';

import { Container, Boards, Board, Wrapper } from './styles'

const ListKanbanTicket: React.FC = () => {
  const [isInput, setIsInput] = useState(false)
  const [column, setColumn] = useState('')

  const [teste, setTeste] = useState([] as any)

  function addColumn(e: any) {
    if (e.key === 'Enter') {
      const newItems = [...teste, column]

      setTeste(newItems)
      setIsInput(false)

      message.success('Coluna adicionada com sucesso!')

      setColumn('')
    }
  }

  return (
    <Container>
      <Boards>
        {teste.map((t: any, index: number) => (
          <Wrapper key={index}>
            <div>
              <div className="title" id="received">
                <div className="principal">
                  <h4>{t}</h4>
                </div>
                <span>
                  30
                  {translate('TasksOptionsKanbanList.tasksText')}
                </span>
              </div>
            </div>
            <Board id="scrollableDiv">
              <div className="cards" id="teste">
                <CardTickets />
              </div>
            </Board>
          </Wrapper>
        ))}

        {!isInput ? (
          <Button onClick={() => setIsInput(true)}>Adicionar coluna</Button>
        ) : (
          <Input
            placeholder="Nome da coluna"
            onKeyDown={addColumn}
            onChange={(e: any) => setColumn(e.target.value)}
            value={column}
            style={{ width: '150px' }}
          />
        )}
      </Boards>
    </Container>
  )
}

export default ListKanbanTicket
