/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react'

import { CarOutlined } from '@ant-design/icons'
import { Avatar, Space } from 'antd'
import { Container, Wrapper, Card } from './styles'
import { useReportKilometers } from '~/hooks/Report/Kilometers/useReportKilometers'

const GraphicsReportKilometers: React.FC = () => {
  const { reportKilometers } = useReportKilometers()

  return (
    <Container>
      <Wrapper>
        <Card className="execution">
          <Space>
            <Avatar
              icon={<CarOutlined />}
              size={40}
              style={{ color: '#3969B7', backgroundColor: '#EFEFEF' }}
            />
            <Avatar
              size={40}
              style={{ color: '#3969B7', backgroundColor: '#EFEFEF' }}
            >
              R$
            </Avatar>
          </Space>
          <p>Total</p>
          <h1>
            {reportKilometers?.graphic.totalKmTraveled} km - R${' '}
            {reportKilometers?.graphic.totalValueKmTraveled}
          </h1>
        </Card>
      </Wrapper>
    </Container>
  )
}

export default GraphicsReportKilometers
