/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/require-default-props */
import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import {
  Modal,
  Row,
  Card,
  Col,
  Button,
  Typography,
  Upload,
  message,
  Tooltip,
  Space,
} from 'antd'
import {
  CloudUploadOutlined,
  DownloadOutlined,
  FileExcelOutlined,
  FileOutlined,
  UploadOutlined,
} from '@ant-design/icons'

import { useAuth } from '~/hooks/Auth/AuthContext'
import { useCompanies } from '~/hooks/Companies/useCompanies'
import { useListContributors } from '~/hooks/Contributors/useListContributors'
import { translate } from '~/utils/locale'

interface IMoreOptions {
  exportTooltip: string
  exportFunction?: () => void
  importTooltip: string
  modalTitle: string
  importTitle: string
  importDescription: string
  modelURL: string
  uploadRoute: string
  uploadFileName: string
  disableImport?: boolean
  disableExport?: boolean
}

const MoreOptions: React.FC<IMoreOptions> = ({
  exportTooltip,
  exportFunction,
  importTooltip,
  modalTitle,
  importTitle,
  importDescription,
  modelURL,
  uploadRoute,
  uploadFileName,
  disableExport = false,
  disableImport = false,
}) => {
  const { selectedCompany } = useCompanies()
  const { listContributorsRefetch } = useListContributors()
  const { token } = useAuth()

  const [isModalVisible, setIsModalVisible] = useState(false)

  const { Meta } = Card
  const { Text } = Typography

  const props = {
    maxCount: 1,
    accept: '.xlsx',
    name: uploadFileName,
    action: `${process.env.REACT_APP_API}company/${selectedCompany}/${uploadRoute}`,
    headers: {
      authorization: `Bearer ${token}`,
      contentType: 'multipart/form-data',
    },
    onChange(info: any) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList)
      }
      if (info.file.status === 'done') {
        if (info.file.response.importResponse !== 'success') {
          message.error(translate('MiscMoreOptions.uploadError'))
          const url = info.file.response.importResponse
          window.open(url, '_blank')
          // window.location.assign(url);
        }

        if (info.file.response.importResponse === 'success') {
          setIsModalVisible(false)
          message.success(translate('MiscMoreOptions.uploadSuccess'))
        }

        listContributorsRefetch()
      } else if (info.file.status === 'error') {
        message.error(translate('MiscMoreOptions.uploadError'))
      }
    },
  }

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
    listContributorsRefetch()
  }

  return (
    <>
      <Space size="middle">
        {exportFunction && (
          <Tooltip title={exportTooltip}>
            <Button
              onClick={() => exportFunction()}
              icon={<DownloadOutlined />}
              shape="circle"
              disabled={disableExport}
            />
          </Tooltip>
        )}

        <Tooltip title={importTooltip}>
          <Button
            icon={<UploadOutlined />}
            onClick={showModal}
            shape="circle"
            disabled={disableImport}
          />
        </Tooltip>
      </Space>
      <Modal
        zIndex={1100}
        title={modalTitle}
        width={1200}
        open={isModalVisible}
        okButtonProps={{ hidden: true }}
        onCancel={handleCancel}
        cancelText={translate('MiscMoreOptions.cancelText')}
      >
        <Row>
          <Col span={11}>
            <Card
              style={{ height: '100%' }}
              bodyStyle={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
              }}
            >
              <Meta
                avatar={<FileOutlined style={{ fontSize: 32 }} />}
                title={
                  <Text style={{ fontSize: 26 }}>
                    {translate('MiscMoreOptions.modelTitle')}
                  </Text>
                }
                description={
                  <Text style={{ fontSize: 16 }} type="secondary">
                    {translate('MiscMoreOptions.modelDescription')}
                  </Text>
                }
              />
              <Link to={modelURL} target="_blank" download>
                <Button
                  style={{ marginTop: 30 }}
                  type="primary"
                  icon={<FileExcelOutlined />}
                >
                  {translate('MiscMoreOptions.downloadButton')}
                </Button>
              </Link>
            </Card>
          </Col>
          <Col span={2} />
          <Col span={11}>
            <Card
              style={{ height: '100%' }}
              bodyStyle={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
              }}
            >
              <Meta
                avatar={<CloudUploadOutlined style={{ fontSize: 32 }} />}
                title={<Text style={{ fontSize: 26 }}>{importTitle}</Text>}
                description={
                  <Text style={{ fontSize: 16 }} type="secondary">
                    {importDescription}
                  </Text>
                }
              />
              <Upload {...props}>
                <Button style={{ marginTop: 30 }} icon={<FileExcelOutlined />}>
                  {translate('MiscMoreOptions.uploadButton')}
                </Button>
              </Upload>
            </Card>
          </Col>
        </Row>
      </Modal>
    </>
  )
}

export default MoreOptions
