export default {
  pt: {
    SignUp: {
      nameYup: {
        required: 'Nome obrigatório',
      },
      companyYup: {
        required: 'Nome da empresa obrigatório',
      },
      emailYup: {
        required: 'E-mail obrigatório',
        validation: 'Digite um e-mail válido',
      },
      passwordYup: {
        required: 'Insira sua senha',
        validationOne:
          'A senha precisa ter 1 Letra Maiúscula ou 1 Caractere especial.',
        validationTwo: 'A senha precisa ter 1 dígito.',
        validationThree: 'A senha precisa ter uma letra minúscula.',
        validationFour: 'A senha precisa ter no mínimo 8 caracteres.',
      },
      passwordConfirmationYup: {
        required: 'As senhas precisam ser iguais.',
      },
      notification: {
        message: 'Aviso para ativar a conta!',
        description: {
          title:
            'Para realizar a ativação de sua conta siga os seguintes passos:',
          stepOne: '1 - Realize o login com seu email e senha cadastrados.',
          stepTwo:
            '2 - Verifique o email cadastrado para ter acesso ao ao email de confirmação.',
          stepThree:
            '3 - Utilize o botão dentro do e-mail enviado para realizar a confirmação da conta.',
        },
      },
      successMessage: 'Usuário Cadastrado com sucesso!',
      errorMessage: 'Erro ao cadastrar.',
      helmetTitle: 'Cadastrar',
      title: 'Registre-se',
      subTitleOne: 'Cadastre-se para começar.',
      subTitleTwo: 'O cadastro leva apenas ',
      subTitleThree: '1 minuto.',
      subTitleFour: ' Experimente todas as possibilidades do Gstor!',
      namePlaceholder: 'Seu Nome:',
      companyPlaceholder: 'Nome da Empresa:',
      phonePlaceholder: 'Seu telefone:',
      emailPlaceholder: 'Seu E-Mail:',
      passwordPlaceholder: 'Sua Senha:',
      passwordConfirmPlaceholder: 'Confirmar Senha:',
      selectPlaceholder: 'Informe o plano',
      selectOptionOne: 'Por colaborador',
      selectOptionTwo: 'Pacote de Serviços',
      buttonText: 'Cadastrar',
      backText: 'Já possui conta? Faça Login',
    },
  },
  en: {
    SignUp: {
      nameYup: {
        required: 'Mandatory name',
      },
      companyYup: {
        required: 'Mandatory company name',
      },
      emailYup: {
        required: 'Mandatory email',
        validation: 'Enter a valid email address',
      },
      passwordYup: {
        required: 'Enter your password',
        validationOne:
          'The password must have 1 Capital Letter or 1 Special Character.',
        validationTwo: 'The password must be 1 digit.',
        validationThree: 'The password must be a lowercase letter.',
        validationFour: 'The password must be at least 8 characters long.',
      },
      passwordConfirmationYup: {
        required: 'Passwords must be the same.',
      },
      notification: {
        message: 'Notice to activate the account!',
        description: {
          title: 'To activate your account, follow these steps:',
          stepOne: '1 - Log in with your registered email and password.',
          stepTwo:
            '2 - Check the registered email to access the confirmation email.',
          stepThree:
            '3 - Use the button inside the email sent to confirm the account.',
        },
      },
      successMessage: 'User Registered Successfully!',
      errorMessage: 'Error registering.',
      helmetTitle: 'Register',
      title: 'Register',
      subTitleOne: 'Sign up to get started.',
      subTitleTwo: 'Registration takes only ',
      subTitleThree: '1 minute.',
      subTitleFour: ' Try all the possibilities of Gstor!',
      namePlaceholder: 'Your name:',
      companyPlaceholder: 'Company Name:',
      phonePlaceholder: 'Your phone:',
      emailPlaceholder: 'Your email:',
      passwordPlaceholder: 'Your password:',
      passwordConfirmPlaceholder: 'Confirm password:',
      selectPlaceholder: 'Inform the plan',
      selectOptionOne: 'Per contributor',
      selectOptionTwo: 'Service package',
      buttonText: 'Register',
      backText: 'Already have an account? Login',
    },
  },
  es: {
    SignUp: {
      nameYup: {
        required: 'Nombre obligatorio',
      },
      companyYup: {
        required: 'Nombre de la empresa obligatorio',
      },
      emailYup: {
        required: 'Correo electrónico obligatorio',
        validation: 'Introduzca una dirección de correo electrónico válida',
      },
      passwordYup: {
        required: 'Ingresa tu contraseña',
        validationOne:
          'La contraseña debe tener 1 letra mayúscula o 1 carácter especial.',
        validationTwo: 'La contraseña debe ser de 1 dígito.',
        validationThree: 'La contraseña debe ser una letra minúscula.',
        validationFour: 'La contraseña debe tener al menos 8 caracteres.',
      },
      passwordConfirmationYup: {
        required: 'Las contraseñas deben ser las mismas.',
      },
      notification: {
        message: '¡Aviso para activar la cuenta!',
        description: {
          title: 'Para activar su cuenta, siga estos pasos:',
          stepOne:
            '1 - Inicie sesión con su correo electrónico y contraseña registrados.',
          stepTwo:
            '2 - Verifique el correo electrónico registrado para acceder al correo electrónico de confirmación.',
          stepThree:
            '3 - Utilice el botón dentro del correo electrónico enviado para confirmar la cuenta.',
        },
      },
      successMessage: '¡Usuario Registrado Exitosamente!',
      errorMessage: 'Error al registrar.',
      helmetTitle: 'Registrar',
      title: 'Registrarse',
      subTitleOne: 'Regístrate para empezar.',
      subTitleTwo: 'El registro toma solo ',
      subTitleThree: '1 minuto.',
      subTitleFour: ' ¡Prueba todas las posibilidades de Gstor!',
      namePlaceholder: 'Su nombre:',
      companyPlaceholder: 'Nombre de la empresa:',
      phonePlaceholder: 'Su teléfono:',
      emailPlaceholder: 'Su e-mail:',
      passwordPlaceholder: 'Su contraseña:',
      passwordConfirmPlaceholder: 'Confirmar seña:',
      selectPlaceholder: 'Informar el plan',
      selectOptionOne: 'Por Contribuyente',
      selectOptionTwo: 'Paquete de Servicios',
      buttonText: 'Registrar',
      backText: '¿Ya tienes una cuenta? Acceso',
    },
  },
}
