export default {
  pt: {
    Segments: {
      helmetTitle: 'Segmentos',
    },
  },
  en: {
    Segments: {
      helmetTitle: 'Segments',
    },
  },
  es: {
    Segments: {
      helmetTitle: 'Segmentos',
    },
  },
}
