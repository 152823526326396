import React, { useContext, createContext } from 'react'
import { useQuery } from 'react-query'

import { message } from 'antd'

import api from '~/services/api'

import { useCompanies } from '~/hooks/Companies/useCompanies'
import { useLocale } from '~/hooks/locale/useLocale'

import { ShowError } from '~/utils/errors/apiErrors'
import { translate } from '~/utils/locale'
import { useTask } from '../useTask'

export type IAnswerValueDTO = string | Array<string> | number | boolean | null

interface IUpdateAnswer {
  collaborator_id: string
  value: IAnswerValueDTO
}

interface ICreateAnswer {
  collaborator_id: string
  answers: Array<{ question_id: string; value: IAnswerValueDTO }>
}

interface IAnswer {
  id: string
  value: string | Array<string> | number | boolean | null
  collaborator: {
    id: string
    contact: {
      name: string
    }
  }
  created_at: Date
  updated_at: Date
}

export interface IQuestion {
  id: string
  title: string
  description: string | null
  type: 'text' | 'multipleChoice' | 'checkbox' | 'dropdownList'
  order: number
  options: string[] | null
  is_required: boolean
  answer?: IAnswer
  created_at: Date
  updated_at: Date
}

export interface FormWithAnswersProps {
  id: string
  title: string
  description?: string
  company_id: string
  questions: Array<IQuestion>
  created_at: Date
  updated_at: Date
}

interface FormWithAnswersContextData {
  form?: FormWithAnswersProps
  updateAnswer: (
    questionId: string,
    answerId: string,
    data: IUpdateAnswer,
  ) => Promise<void>
  createAnswer: (data: ICreateAnswer) => Promise<void>
  isLoading: boolean
  isFetching: boolean
  refetch: () => void
}

const FormWithAnswersContext = createContext<FormWithAnswersContextData>(
  {} as FormWithAnswersContextData,
)

interface TaskFormWithAnswersProviderProps {
  formId: string
}

export const TaskFormWithAnswersProvider: React.FC<
  TaskFormWithAnswersProviderProps
> = ({ children, formId }) => {
  const { task } = useTask()
  const { selectedCompany } = useCompanies()
  const { locale } = useLocale()

  const {
    data: form,
    isLoading,
    refetch,
    isFetching,
  } = useQuery(
    [`form${formId}`, selectedCompany, formId, task?._id],
    async () => {
      if (!selectedCompany || !formId || !task) return
      const data = await api
        .get<FormWithAnswersProps>(
          `/company/${selectedCompany}/tasks/${task._id}/forms/${formId}/answers`,
        )
        .then((response) => response.data)
        .catch((err) => {
          ShowError(
            err.message,
            translate('useTaskForms.getErrorMessage'),
            locale,
          )
          return undefined
        })
      return data
    },
    {
      refetchOnWindowFocus: false,
    },
  )

  async function updateAnswer(
    questionId: string,
    answerId: string,
    data: IUpdateAnswer,
  ) {
    if (!form) return
    await api
      .put(
        `/company/${selectedCompany}/forms/${form.id}/questions/${questionId}/answers/${answerId}`,
        data,
      )
      .then(() => {
        message.success(translate('useTaskForms.updateSuccessMessage'))
      })
      .catch((err) =>
        ShowError(
          err.message,
          translate('useTaskForms.updateErrorMessage'),
          locale,
        ),
      )
    refetch()
  }

  async function createAnswer(data: ICreateAnswer) {
    if (!form || !task) return
    await api
      .post(
        `/company/${selectedCompany}/tasks/${task._id}/forms/${form.id}/answers`,
        data,
      )
      .then(() => {
        message.success(translate('useTaskForms.updateSuccessMessage'))
      })
      .catch((err) =>
        ShowError(
          err.message,
          translate('useTaskForms.updateErrorMessage'),
          locale,
        ),
      )
    refetch()
  }

  return (
    <FormWithAnswersContext.Provider
      value={{
        form,
        isLoading,
        isFetching,
        refetch,
        updateAnswer,
        createAnswer,
      }}
    >
      {children}
    </FormWithAnswersContext.Provider>
  )
}

export function useTaskFormWithAnswers(): FormWithAnswersContextData {
  const context = useContext(FormWithAnswersContext)

  if (!context) {
    throw new Error(
      'useTaskFormWithAnswers must be used within an FormWithAnswersProvider',
    )
  }

  return context
}
