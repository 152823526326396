/* eslint-disable @typescript-eslint/no-explicit-any */
import { Card, Space, Table, Tooltip, message } from 'antd'
import React from 'react'
import AddChildrenCustomer from './Add'

import { useParentCustomer } from '~/hooks/Clients/useParentCustomer'
import { useQuery } from 'react-query'
import api from '~/services/api'
import { useCompanies } from '~/hooks/Companies/useCompanies'
import { ColumnsType } from 'antd/lib/table'
import { translate } from '~/utils/locale'
import { Link } from 'react-router-dom'
import { FileUnknownOutlined, LoadingOutlined } from '@ant-design/icons'

import Text from 'antd/lib/typography/Text'

// import { Container } from './styles';

interface ClientsProps {
  id: string
  contact?: {
    name: string
    email: string
    id: string
  }
}

const ChildrenClientsTab: React.FC = () => {
  const { selectedCompany } = useCompanies()
  const { parentCustomer } = useParentCustomer()

  const clientsId = parentCustomer?.customers.map((c) => c.id).join()

  const { data, isFetching } = useQuery(
    [
      `lista de clientes em cliente principais${selectedCompany}`,
      selectedCompany,
    ],

    async () => {
      try {
        if (!selectedCompany) {
          return undefined
        }

        const response = await api.get(
          `/company/${selectedCompany}/customers`,
          {
            params: {
              customer_id: clientsId,
            },
          },
        )

        const { data } = response

        return data.results
      } catch (err: any) {
        message.error('Erro ao listar clientes')
      }
    },
    {
      staleTime: 5000,
    },
  )

  const columns: ColumnsType<ClientsProps> = [
    {
      title: translate('ClientsTable.columnName'),
      align: 'left',
      dataIndex: ['contact', 'name'],
      key: 'name',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (name: string, record) => (
        <Tooltip title={translate('TasksCreateModalClient.viewClientTooltip')}>
          <Link
            to={`/clientes/visualizar/${record.id}/contato/${record.contact?.id}/`}
          >
            {name}
          </Link>
        </Tooltip>
      ),
    },
    {
      title: translate('ClientsTable.columnResponsible'),
      dataIndex: ['collaborator', 'contact', 'name'],
      align: 'center',
      key: 'contact',
      render: (contact: string) => (
        <>
          {contact || (
            <Text type="secondary">
              <Space size="small">
                <FileUnknownOutlined />
                N/A
              </Space>
            </Text>
          )}
        </>
      ),
    },
    {
      title: translate('ClientsTable.columnEmail'),
      align: 'center',
      key: 'email',
      dataIndex: ['contact', 'email'],
    },
  ]

  return (
    <Card
      bordered={false}
      title={translate('ClientsPrincipalTabsClient.clientTitleModal')}
      extra={<AddChildrenCustomer />}
      style={{
        display: 'inline-block',
        width: '100%',
      }}
    >
      {parentCustomer?.customers.length !== 0 ? (
        <Table
          rowKey={(record) => record.id}
          loading={{
            indicator: <LoadingOutlined />,
            spinning: isFetching,
            size: 'large',
          }}
          columns={columns}
          dataSource={data}
          scroll={{ x: 1000 }}
        />
      ) : (
        <Text>{translate('ClientsPrincipalTabsClient.undefinedText')}</Text>
      )}
    </Card>
  )
}

export default ChildrenClientsTab
