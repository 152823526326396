/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react'

import { Button, Modal, Form, Checkbox, Select, Divider, Row, Col } from 'antd'
import { LockOutlined } from '@ant-design/icons'

import { useModule } from '~/hooks/Groups/useModule'
import { usePermission } from '~/hooks/Groups/usePermission'
import { translate } from '~/utils/locale'

interface Permission {
  groupId: string
}

const CreatePermission: React.FC<Permission> = ({ groupId }) => {
  const { module } = useModule()
  const { createPermission } = usePermission()

  const [moduleId, setModuleId] = useState()

  const [isModalVisible, setIsModalVisible] = useState(false)

  function onModule(value: any) {
    setModuleId(value)
  }

  const initialData = {
    create: false,
    edit: false,
    view: false,
    delete: false,
  }

  function onFormCreatePermission(valueForm: any) {
    try {
      createPermission(moduleId, groupId, valueForm)
      setIsModalVisible(false)
    } catch (err) {
      console.log(err)
    }
  }

  const showModal = () => {
    setIsModalVisible(true)
  }

  const closeModal = () => {
    setIsModalVisible(false)
  }

  return (
    <>
      <Button onClick={showModal} icon={<LockOutlined />} type="primary">
        {translate('GroupsCreatePermissions.permissionsButton')}
      </Button>
      <Modal
        title={translate('GroupsCreatePermissions.modalTitle')}
        open={isModalVisible}
        onOk={closeModal}
        onCancel={closeModal}
        style={{ top: 60 }}
        width="600px"
        footer={[
          <Button key="back" onClick={closeModal}>
            {translate('GroupsCreatePermissions.closeButton')}
          </Button>,
          <Button
            form="newPermissionForm"
            key="save"
            type="primary"
            htmlType="submit"
          >
            {translate('GroupsCreatePermissions.saveButton')}
          </Button>,
        ]}
      >
        <Row gutter={16}>
          <Col span={12}>
            <h3>{translate('GroupsCreatePermissions.moduleLabel')}</h3>
            <Select
              placeholder={translate(
                'GroupsCreatePermissions.modulePlaceholder',
              )}
              showSearch
              onChange={onModule}
              style={{ width: '100%' }}
              optionFilterProp="children"
            >
              {module?.map((modules) => (
                <Select.Option value={modules.id} key={modules.id}>
                  {modules.name === 'contributors-schedules'
                    ? translate('GroupsCreatePermissions.contributorScale')
                    : ''}
                  {modules.name === 'customers-schedules'
                    ? translate('GroupsCreatePermissions.clientScale')
                    : ''}
                  {modules.name === 'schedule-times'
                    ? translate('GroupsCreatePermissions.scheduleScale')
                    : ''}
                  {modules.name === 'schedules'
                    ? translate('GroupsCreatePermissions.scales')
                    : ''}
                  {modules.name === 'customers-contacts'
                    ? translate('GroupsCreatePermissions.clientContact')
                    : ''}
                  {modules.name === 'customers-segments'
                    ? translate('GroupsCreatePermissions.clientSegments')
                    : ''}
                  {modules.name === 'customers'
                    ? translate('GroupsCreatePermissions.clients')
                    : ''}
                  {modules.name === 'contributors-status'
                    ? translate('GroupsCreatePermissions.contributorStatus')
                    : ''}
                  {modules.name === 'groups'
                    ? translate('GroupsCreatePermissions.groups')
                    : ''}
                  {modules.name === 'addresses'
                    ? translate('GroupsCreatePermissions.addresses')
                    : ''}
                  {modules.name === 'users'
                    ? translate('GroupsCreatePermissions.users')
                    : ''}
                  {modules.name === 'groups-permissions'
                    ? translate('GroupsCreatePermissions.groupsPermissions')
                    : ''}
                  {modules.name === 'companies'
                    ? translate('GroupsCreatePermissions.companies')
                    : ''}
                  {modules.name === 'contacts'
                    ? translate('GroupsCreatePermissions.contacts')
                    : ''}
                  {modules.name === 'contributors'
                    ? translate('GroupsCreatePermissions.contributors')
                    : ''}
                  {modules.name === 'contributors-attachments'
                    ? translate(
                        'GroupsCreatePermissions.contributorsAttachments',
                      )
                    : ''}
                  {modules.name === 'contributors-evaluations'
                    ? translate(
                        'GroupsCreatePermissions.contributorsEvaluations',
                      )
                    : ''}
                  {modules.name === 'contributors-skills'
                    ? translate('GroupsCreatePermissions.contributorsSkills')
                    : ''}
                  {modules.name === 'associated-equipments'
                    ? translate('GroupsCreatePermissions.associatedEquipments')
                    : ''}
                  {modules.name === 'movements'
                    ? translate('GroupsCreatePermissions.movements')
                    : ''}
                  {modules.name === 'stocks'
                    ? translate('GroupsCreatePermissions.stocks')
                    : ''}
                  {modules.name === 'equipments'
                    ? translate('GroupsCreatePermissions.equipments')
                    : ''}
                  {modules.name === 'categories'
                    ? translate('GroupsCreatePermissions.categories')
                    : ''}
                  {modules.name === 'contacts-addresses'
                    ? translate('GroupsCreatePermissions.contactsAddresses')
                    : ''}
                  {modules.name === 'specifications-templates'
                    ? translate(
                        'GroupsCreatePermissions.specificationsTemplates',
                      )
                    : ''}
                  {modules.name === 'contributors-teams'
                    ? translate('GroupsCreatePermissions.contributorsTeams')
                    : ''}
                  {modules.name === 'teams'
                    ? translate('GroupsCreatePermissions.teams')
                    : ''}
                  {modules.name === 'tasks'
                    ? translate('GroupsCreatePermissions.tasks')
                    : ''}
                  {modules.name === 'breaks-reasons'
                    ? translate('GroupsCreatePermissions.breakReasons')
                    : ''}
                  {modules.name === 'tasks-types'
                    ? translate('GroupsCreatePermissions.tasksTypes')
                    : ''}
                  {modules.name === 'equipments-attachments'
                    ? translate('GroupsCreatePermissions.equipmentsAttachments')
                    : ''}
                  {modules.name === 'equipments-specifications'
                    ? translate(
                        'GroupsCreatePermissions.equipmentsSpecifications',
                      )
                    : ''}
                  {modules.name === 'tasks-status'
                    ? translate('GroupsCreatePermissions.tasksStatus')
                    : ''}
                  {modules.name === 'tasks-reports'
                    ? translate('GroupsCreatePermissions.tasksReports')
                    : ''}
                  {modules.name === 'tasks-equipments'
                    ? translate('GroupsCreatePermissions.tasksEquipments')
                    : ''}
                  {modules.name === 'reports'
                    ? translate('GroupsCreatePermissions.reports')
                    : ''}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>
        <Form
          id="newPermissionForm"
          layout="vertical"
          initialValues={initialData}
          onFinish={onFormCreatePermission}
        >
          <Divider>
            {translate('GroupsCreatePermissions.permissionsLabel')}
          </Divider>
          <Form.Item valuePropName="checked" name="create">
            <Checkbox>
              {translate('GroupsCreatePermissions.createPermission')}
            </Checkbox>
          </Form.Item>
          <Form.Item valuePropName="checked" name="edit">
            <Checkbox>
              {translate('GroupsCreatePermissions.editPermission')}
            </Checkbox>
          </Form.Item>
          <Form.Item valuePropName="checked" name="view">
            <Checkbox>
              {translate('GroupsCreatePermissions.viewPermission')}
            </Checkbox>
          </Form.Item>
          <Form.Item valuePropName="checked" name="delete">
            <Checkbox>
              {translate('GroupsCreatePermissions.deletePermission')}
            </Checkbox>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default CreatePermission
