/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react'

import { Helmet } from 'react-helmet'

import { TaskTypeProvider } from '~/hooks/Tasks/TaskType/useTaskType'

import { Container } from './styles'
import CreateTasks from '~/components/DataManipulation/TasksRegistration/TypeTasks/Create'
import { translate } from '~/utils/locale'

const NewTypeTasks: React.FC = () => {
  return (
    <Container>
      <Helmet>
        <title>{translate('NewTaskType.helmetTitle')}</title>
      </Helmet>

      <TaskTypeProvider>
        <CreateTasks />
      </TaskTypeProvider>
    </Container>
  )
}

export default NewTypeTasks
