/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, createContext, useState } from 'react'
import { useQuery } from 'react-query'

import api from '~/services/api'

import { useCompanies } from '~/hooks/Companies/useCompanies'
import { useLocale } from '~/hooks/locale/useLocale'

import { ShowError } from '~/utils/errors/apiErrors'
import { translate } from '~/utils/locale'

interface SpecificationProps {
  attribute: string
  value: string
}

interface TemplateProps {
  id: string
  name: string
  specifications: SpecificationProps[]
}

interface TemplateContextData {
  template?: TemplateProps
  setTemplateId(value: string): void
  isLoading: boolean
  refetch: () => void
  isFetching: boolean
}

const ListTemplateContext = createContext<TemplateContextData>(
  {} as TemplateContextData,
)

export const TemplateProvider: React.FC = ({ children }) => {
  const { selectedCompany } = useCompanies()
  const { locale } = useLocale()

  const [templateId, setTemplateId] = useState<string>()

  const {
    data: template,
    isLoading,
    refetch,
    isFetching,
  } = useQuery(
    [`template${selectedCompany}`, selectedCompany, templateId],

    async () => {
      try {
        if (templateId !== undefined) {
          const response = await api.get(
            `/company/${selectedCompany}/specification-template/${templateId}`,
          )

          const { data } = response

          return data
        }
      } catch (err: any) {
        ShowError(err.message, translate('useTemplate.getErrorMessage'), locale)
      }
    },
    {
      staleTime: 5000,
    },
  )

  return (
    <ListTemplateContext.Provider
      value={{
        template,
        isLoading,
        setTemplateId,
        refetch,
        isFetching,
      }}
    >
      {children}
    </ListTemplateContext.Provider>
  )
}

export function useTemplate(): TemplateContextData {
  const context = useContext(ListTemplateContext)

  if (!context) {
    throw new Error('useAuth must be used within an AutoProvider')
  }

  return context
}
