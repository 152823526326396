/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-one-expression-per-line */
import { Card, Tabs } from 'antd'
import type { TabsProps } from 'antd'
import React, { useEffect, useState } from 'react'
import { TaskListProps, useListTask } from '~/hooks/Tasks/useListTasks'
import { LoadingIndicator } from './styles'
import { LoadingOutlined } from '@ant-design/icons'
import { ClientsProps, useListClients } from '~/hooks/Clients/useListClients'

interface MappedClientType extends ClientsProps {
  tasks: TaskListProps[]
}

interface TaskGroupedByParentCustomer {
  parent_customer: string
  tasks: TaskListProps[]
}

const DashboardCustomersGraphs: React.FC = () => {
  const { tasks } = useListTask()
  const { clients } = useListClients()

  const [mappedClients, setMappedClients] = useState<MappedClientType[]>([])
  const [tasksGroupedByParentCustomer, setTasksGroupedByParentCustomer] =
    useState<TaskGroupedByParentCustomer[]>([])
  const [maxTasksClient, setMaxTasksClient] = useState<ClientsProps | null>(
    null,
  )

  useEffect(() => {
    function mapClientsToTasks(
      clients: ClientsProps[],
      tasks: TaskListProps[],
    ) {
      let maxTasks = 0
      const clientsAndTasksMap = new Map(
        clients.map((client) => [
          client.id,
          { ...client, tasks: [] as TaskListProps[] },
        ]),
      )

      const tasksGroupedByParentCustomerMap = new Map<string, TaskListProps[]>()

      tasks.forEach((task) => {
        const client = clientsAndTasksMap.get(task.customer_id)
        if (client) {
          client.tasks.push(task)
          if (client.tasks.length > maxTasks) {
            maxTasks = client.tasks.length
            setMaxTasksClient(client)
          }

          // Group tasks by parent_customer
          const parentCustomer = client.parent_customer || 'default'
          const parentCustomerTasks =
            tasksGroupedByParentCustomerMap.get(parentCustomer) || []
          parentCustomerTasks.push(task)
          tasksGroupedByParentCustomerMap.set(
            parentCustomer,
            parentCustomerTasks,
          )
        }
      })

      setMappedClients(Array.from(clientsAndTasksMap.values()))
      setTasksGroupedByParentCustomer(
        Array.from(tasksGroupedByParentCustomerMap.entries()).map(
          ([parent_customer, tasks]) => ({ parent_customer, tasks }),
        ),
      )
    }

    if (clients && tasks) {
      mapClientsToTasks(clients, tasks)
    }
  }, [clients, tasks])

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Grafico Pizza',
      children:
        tasks && tasks.length > 0 ? (
          'yay'
        ) : (
          <LoadingIndicator>
            <LoadingOutlined style={{ fontSize: 64, margin: 'auto' }} spin />
          </LoadingIndicator>
        ),
    },
  ]

  return (
    <Card
      title="Dashboard Clientes"
      bordered={false}
      style={{ width: '100%', height: '100%', padding: 30 }}
    >
      <Tabs defaultActiveKey="1" items={items} />
    </Card>
  )
}

export default DashboardCustomersGraphs
