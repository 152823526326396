import { Alert, Card } from 'antd'
import React from 'react'
import { useTaskFormWithAnswers } from '~/hooks/Tasks/Forms/useTaskFormWithAnswers'
import Question from './question'

const Form: React.FC = () => {
  const { form, isLoading, isFetching } = useTaskFormWithAnswers()

  if (isLoading || isFetching) {
    return <Card loading />
  }

  if (!form)
    return (
      <Card>
        <Alert message="Os formulários não foram respondidos." />
      </Card>
    )
  return (
    <>
      <Card style={{ width: '100%' }} title={form.title}>
        {form.questions.map((question, index) => (
          <Question index={index} key={question.id} data={question} />
        ))}
      </Card>
    </>
  )
}

export default Form
