export default {
  pt: {
    ClientsTabs: {
      viewClientTitle: 'Visualizar Cliente',
      editClientTitle: 'Editar Cliente',
      statusLabel: 'Status:',
      statusActive: 'Ativo',
      statusInactive: 'Inativo',
      detailsTab: 'Detalhes',
      addressTab: 'Endereço',
      chargeTab: 'Cobrança',
      contactTab: 'Contatos',
    },
  },
  en: {
    ClientsTabs: {
      viewClientTitle: 'View Customer',
      editClientTitle: 'Edit Customer',
      statusLabel: 'Status:',
      statusActive: 'Active',
      statusInactive: 'Inactive',
      detailsTab: 'Details',
      addressTab: 'Address',
      chargeTab: 'Billing',
      contactTab: 'Contacts',
    },
  },
  es: {
    ClientsTabs: {
      viewClientTitle: 'Ver Cliente',
      editClientTitle: 'Editar Cliente',
      statusLabel: 'Status:',
      statusActive: 'Activo',
      statusInactive: 'Inactivo',
      detailsTab: 'Detalles',
      addressTab: 'Dirección',
      chargeTab: 'Cobrar',
      contactTab: 'Contactos',
    },
  },
}
