export default {
  pt: {
    ContributorDetails: {
      cardTitle: 'Informações Gerais',
      editButton: 'Editar',
      nameLabel: 'Nome',
      emailLabel: 'E-mail',
      genderLabel: 'Gênero',
      genderMale: 'Masculino',
      genderFemale: 'Feminino',
      genderOther: 'Prefiro não dizer',
      birthLabel: 'Nascimento',
      personTypeLabel: 'Tipo de pessoa',
      personTypeLegal: 'Pessoa Jurídica',
      personTypePhysical: 'Pessoa Física',
      personTypeForeign: 'Estrangeiro',
      cpfLabel: 'CPF',
      passportLabel: 'Passaporte',
      cnpjLabel: 'CNPJ',
      idLabel: 'RG',
      observationLabel: 'Observações',
      additionalPhonesLabel: 'Telefones Adicionais',
      phoneLabel: 'Telefone',
      phoneTypeLabel: 'Tipo de telefone',
      phoneTypeResidential: 'Residencial',
      phoneTypeCellphone: 'Celular',
      phoneTypeCommercial: 'Comercial',
      pricesTitle: 'Preços e Área de atuação',
      valueHourLabel: 'Valor R$ por hora trabalhada',
      valueKmLabel: 'Valor R$ por KM rodado',
      rangeLabel: 'Área de atuação em KM',
    },
  },
  en: {
    ContributorDetails: {
      cardTitle: 'General information',
      editButton: 'Edit',
      nameLabel: 'Name',
      emailLabel: 'Email',
      genderLabel: 'Gender',
      genderMale: 'Male',
      genderFemale: 'Female',
      genderOther: 'Prefer not to say',
      birthLabel: 'Birth',
      personTypeLabel: 'Person type',
      personTypeLegal: 'Legal person',
      personTypePhysical: 'Physical person',
      personTypeForeign: 'Foreign',
      cpfLabel: 'CPF',
      passportLabel: 'Passport',
      cnpjLabel: 'CNPJ',
      idLabel: 'ID',
      observationLabel: 'Comments',
      additionalPhonesLabel: 'Additional Phones',
      phoneLabel: 'Phone',
      phoneTypeLabel: 'Phone type',
      phoneTypeResidential: 'Residential',
      phoneTypeCellphone: 'Cellphone',
      phoneTypeCommercial: 'Commercial',
      pricesTitle: 'Prices and Area of ​​Operation',
      valueHourLabel: 'Value R$ per hour worked',
      valueKmLabel: 'Value R$ per km driven',
      rangeLabel: 'Area of ​​operation in KM',
    },
  },
  es: {
    ContributorDetails: {
      cardTitle: 'Informaciones generales',
      editButton: 'Editar',
      nameLabel: 'Nombre',
      emailLabel: 'Correo electrónico',
      genderLabel: 'Género',
      genderMale: 'Masculino',
      genderFemale: 'Femenino',
      genderOther: 'Prefiero no decirlo',
      birthLabel: 'Nacimiento',
      personTypeLabel: 'Tipo de persona',
      personTypeLegal: 'Pessoa Juridica',
      personTypePhysical: 'Pessoa Física',
      personTypeForeign: 'Extranjero',
      cpfLabel: 'CPF',
      passportLabel: 'Pasaporte',
      cnpjLabel: 'CNPJ',
      idLabel: 'ID',
      observationLabel: 'Comentarios',
      additionalPhonesLabel: 'Teléfonos adicionales',
      phoneLabel: 'Teléfono',
      phoneTypeLabel: 'Tipo de telefono',
      phoneTypeResidential: 'Residencial',
      phoneTypeCellphone: 'Celular',
      phoneTypeCommercial: 'Comercial',
      pricesTitle: 'Precios y Área de Operación',
      valueHourLabel: 'Valor R$ por hora trabajada',
      valueKmLabel: 'Valor R$ por km recorrido',
      rangeLabel: 'Área de operación en KM',
    },
  },
}
