export default {
  pt: {
    EquipmentsTabsTasks: {
      codeColumn: 'Código',
      specificationsColumn: 'Especificações',
      typeColumn: 'Tipo',
      dateColumn: 'Data',
      executeColumn: 'Executor',
      cardTitle: 'Tarefas',
    },
  },
  en: {
    EquipmentsTabsTasks: {
      codeColumn: 'Code',
      specificationsColumn: 'Specifications',
      typeColumn: 'Type',
      dateColumn: 'Date',
      executeColumn: 'Executor',
      cardTitle: 'Tasks',
    },
  },
  es: {
    EquipmentsTabsTasks: {
      codeColumn: 'Código',
      specificationsColumn: 'Especificaciones',
      typeColumn: 'Tipo',
      dateColumn: 'Fecha',
      executeColumn: 'Ejecutor',
      cardTitle: 'Tareas',
    },
  },
}
