import React from 'react'

import { Helmet } from 'react-helmet'

import { Container } from './styles'

import { ListGroupProvider } from '~/hooks/Groups/useListGroup'
import GroupsBoard from '~/components/DataManipulation/Groups/Board'
import { translate } from '~/utils/locale'

const Groups: React.FC = () => {
  return (
    <Container>
      <Helmet>
        <title>{translate('Groups.helmetTitle')}</title>
      </Helmet>

      <ListGroupProvider>
        <GroupsBoard />
      </ListGroupProvider>
    </Container>
  )
}

export default Groups
