export default {
  pt: {
    SelectCompany: {
      addText: 'Adicionar',
    },
  },
  en: {
    SelectCompany: {
      addText: 'Add',
    },
  },
  es: {
    SelectCompany: {
      addText: 'Agregar',
    },
  },
}
