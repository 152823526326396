/* eslint-disable @typescript-eslint/no-explicit-any */
import { EditOutlined } from '@ant-design/icons'
import { Modal, Button, Form, Input, Tooltip, Space } from 'antd'
import React, { useState } from 'react'
import { useSegments } from '~/hooks/Segments/useSegments'
import { translate } from '~/utils/locale'
import { ContainerButton } from '../styles'

interface UpdateSegmentProps {
  id: number
  title: string
  description: string
}

interface UpdateSegmentData {
  dataSegment: UpdateSegmentProps
}

const UpdateSegments: React.FC<UpdateSegmentData> = ({ dataSegment }) => {
  const { updateSegments } = useSegments()
  const [isModalVisible, setIsModalVisible] = useState(false)

  const initialData = {
    title: dataSegment.title,
    description: dataSegment.description,
  }

  function onUpdateSegments(valueForm: any) {
    try {
      updateSegments(valueForm, dataSegment.id)
      setIsModalVisible(false)
    } catch (err) {
      setIsModalVisible(false)
    }
  }

  const showModal = () => {
    setIsModalVisible(true)
  }

  const closeModal = () => {
    setIsModalVisible(false)
  }

  return (
    <div>
      <Tooltip title={translate('SegmentsUpdate.editTooltip')}>
        <Button
          type="primary"
          onClick={showModal}
          ghost
          shape="circle"
          icon={<EditOutlined />}
        />
      </Tooltip>
      <Modal
        title={translate('SegmentsUpdate.modalTitle')}
        open={isModalVisible}
        onOk={closeModal}
        onCancel={closeModal}
        footer={[
          <Space key="buttons">
            <Button key="back" onClick={closeModal}>
              {translate('SegmentsUpdate.closeButton')}
            </Button>
            <ContainerButton>
              <Button
                key="save"
                type="primary"
                form="editSegment"
                htmlType="submit"
                id="btn_update_segments"
              >
                {translate('SegmentsUpdate.saveButton')}
              </Button>
            </ContainerButton>
          </Space>,
        ]}
      >
        <Form
          id="editSegment"
          layout="vertical"
          initialValues={initialData}
          onFinish={onUpdateSegments}
        >
          <Form.Item
            label={translate('SegmentsUpdate.titleLabel')}
            name="title"
            rules={[
              {
                required: true,
                message: translate('SegmentsUpdate.titleRule'),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={translate('SegmentsUpdate.descriptionLabel')}
            name="description"
            rules={[
              {
                required: true,
                message: translate('SegmentsUpdate.descriptionRule'),
              },
            ]}
          >
            <Input.TextArea />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}

export default UpdateSegments
