export default {
  pt: {
    useCompanies: {
      getErrorMessage: 'Você visualizar empresas.',
      createErrorMessage: 'Erro ao criar empresa.',
    },
  },
  en: {
    useCompanies: {
      getErrorMessage: 'You view companies.',
      createErrorMessage: 'Error creating company.',
    },
  },
  es: {
    useCompanies: {
      getErrorMessage: 'Ves empresas.',
      createErrorMessage: 'Error al crear empresa.',
    },
  },
}
