/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState } from 'react'
import { Table, Input, Button, Space } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { Container } from './styles'

interface TasksValue {
  title: string
  count: number | undefined
  totalValue: number | undefined
}

interface Props {
  generalTotal: TasksValue
  dataSource: TasksValue[]
  loading: boolean
}

const TasksValueTable: React.FC<Props> = ({
  generalTotal,
  dataSource,
  loading,
}) => {
  const [, setSearchText] = useState<string>('')
  const [, setSearchedColumn] = useState<string>('')

  const getColumnSearchProps = (dataIndex: keyof TasksValue) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: any) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Buscar nome`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Resetar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value: any, record: TasksValue) => {
      const data = record[dataIndex]
      return data
        ? data.toString().toLowerCase().includes(value.toLowerCase())
        : false
    },
  })

  const handleSearch = (
    selectedKeys: React.Key[],
    confirm: () => void,
    dataIndex: keyof TasksValue,
  ) => {
    confirm()
    setSearchText(selectedKeys[0] as string)
    setSearchedColumn(dataIndex as string)
  }

  const handleReset = (clearFilters: () => void) => {
    clearFilters()
    setSearchText('')
  }

  const columns = [
    {
      title: 'Tipo de Tarefa',
      dataIndex: 'title',
      width: 100,
      key: 'title',
      sorter: (a: TasksValue, b: TasksValue) => a.title.localeCompare(b.title), // Ordenação alfabética
      ...getColumnSearchProps('title'),
    },
    {
      title: 'Quantidade de Tarefas',
      dataIndex: 'count',
      width: 100,
      key: 'count',
      sorter: (a: TasksValue, b: TasksValue) => a.count! - b.count!, // Ordenação por quantidade
    },
    {
      title: 'Valor Total',
      dataIndex: 'totalValue',
      width: 100,
      key: 'totalValue',
      sorter: (a: TasksValue, b: TasksValue) =>
        (a.totalValue || 0) - (b.totalValue || 0), // Ordenação por valor total
      render: (totalValue: number) => {
        return totalValue?.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        })
      },
    },
  ]

  return (
    <Container>
      <Table
        loading={loading}
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        scroll={{ x: 1000, y: 500 }}
        bordered
        footer={() => (
          <div className="total-row">
            <span>{generalTotal.title}</span>
            <span>{generalTotal.count}</span>
            <span>
              {generalTotal.totalValue?.toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              })}
            </span>
          </div>
        )}
      />
    </Container>
  )
}

export default TasksValueTable
