/* eslint-disable camelcase */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  Typography,
  Space,
  Table,
  Button,
  Tooltip,
  Popconfirm,
  message,
} from 'antd'
import {
  DeleteFilled,
  FileUnknownOutlined,
  LoadingOutlined,
  ProfileOutlined,
} from '@ant-design/icons'

import { ColumnsType } from 'antd/lib/table'
import React, { useState } from 'react'

import { Link } from 'react-router-dom'

import { Container } from './styles'
import { useListClients } from '~/hooks/Clients/useListClients'
import { translate } from '~/utils/locale'

interface ClientsProps {
  id: string
  parent_customer?: string
  parent_cnpj?: string
  contact?: {
    name: string
    email: string
    id: string
  }
}

const ClientsTable: React.FC = () => {
  const {
    clients,
    isFetching,
    deleteClient,
    deleteClients,
    setNumberPage,
    setPageSize,
    clientsTotal,
  } = useListClients()
  const { Text } = Typography

  const [isDelete, setIsDelete] = useState(false)
  const [clientsId, setClientsId] = useState<React.Key[]>([])

  const columns: ColumnsType<ClientsProps> = [
    {
      title: translate('ClientsTable.columnName'),
      align: 'left',
      dataIndex: ['contact', 'name'],
      key: 'name',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (name: string, record) => (
        <Link
          to={`/clientes/visualizar/${record.id}/contato/${record.contact?.id}/`}
        >
          {name}
        </Link>
      ),
    },
    {
      title: translate('ClientsTable.columnResponsible'),
      dataIndex: ['collaborator', 'contact', 'name'],
      align: 'center',
      key: 'contact',
      render: (contact: string) => (
        <>
          {contact || (
            <Text type="secondary">
              <Space size="small">
                <FileUnknownOutlined />
                N/A
              </Space>
            </Text>
          )}
        </>
      ),
    },
    {
      title: translate('ClientsTable.columnEmail'),
      align: 'center',
      key: 'email',
      dataIndex: ['contact', 'email'],
      render: (email: string) => (
        <>
          {email || (
            <Text type="secondary">
              <Space size="small">
                <FileUnknownOutlined />
                N/A
              </Space>
            </Text>
          )}
        </>
      ),
    },
    {
      title: translate('ClientsTable.columnParentCustomer'),
      align: 'center',
      key: 'parent_customer',
      dataIndex: 'parent_customer',
      render: (parent_customer: string) => (
        <>
          {parent_customer || (
            <Text type="secondary">
              <Space size="small">
                <FileUnknownOutlined />
                N/A
              </Space>
            </Text>
          )}
        </>
      ),
    },
    {
      title: translate('ClientsTable.columnActions'),
      align: 'center',
      key: 'remove',
      render: (record) => (
        <Space size="middle">
          <Tooltip title={translate('ClientsTable.viewTooltip')}>
            <Link
              to={`/clientes/visualizar/${record.id}/contato/${record.contact?.id}/`}
            >
              <Button
                ghost
                type="primary"
                shape="circle"
                icon={<ProfileOutlined />}
              />
            </Link>
          </Tooltip>
          <Popconfirm
            title={translate('ClientsTable.deletePopconfirm')}
            onConfirm={() => deleteClient(record.id)}
          >
            <Tooltip title={translate('ClientsTable.deleteTooltip')}>
              <Button ghost danger shape="circle" icon={<DeleteFilled />} />
            </Tooltip>
          </Popconfirm>
        </Space>
      ),
    },
  ]

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[]) => {
      setIsDelete(selectedRowKeys.length > 0)
      setClientsId(selectedRowKeys)
    },
    getCheckboxProps: (record: ClientsProps) => ({
      disabled: record.contact?.name === 'Disabled User', // Column configuration not to be checked
      name: record.contact?.name,
    }),
  }

  function DeleteClients() {
    const clients = clientsId.map((m) => {
      return deleteClients(m.toString())
    })

    message.success('Clientes deletados com sucesso!')
    setIsDelete(false)
    return clients
  }

  return (
    <Container>
      {isDelete && (
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <Popconfirm
            title="Deseja excluir os clientes?"
            onConfirm={DeleteClients}
          >
            <Tooltip title={translate('ClientsTable.deleteTooltip')}>
              <Button
                ghost
                danger
                size="middle"
                shape="circle"
                icon={<DeleteFilled />}
                style={{ marginBottom: 10 }}
              />
            </Tooltip>
          </Popconfirm>

          {clientsId.length !== 1 ? (
            <Typography.Title level={5} type="danger">
              {clientsId.length} clientes selecionados
            </Typography.Title>
          ) : (
            <Typography.Title level={5} type="danger">
              1 cliente selecionado
            </Typography.Title>
          )}
        </div>
      )}

      <Table
        rowKey={(record) => record.id}
        loading={{
          indicator: <LoadingOutlined />,
          spinning: isFetching,
          size: 'large',
        }}
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        pagination={{
          total: clientsTotal,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '50', '100', '200'],
          defaultPageSize: 10,
          showTotal: (total, range) => `${range[0]} - ${range[1]} / ${total}`,
          onChange: (page, pageSize) => {
            setPageSize(pageSize!)
            setNumberPage(page)
          },
        }}
        columns={columns}
        dataSource={clients}
        scroll={{ x: 1000 }}
      />
    </Container>
  )
}

export default ClientsTable
