export default {
  pt: {
    TasksRegistrationTypeTasksCreate: {
      cardTitle: 'Novo Tipo de Tarefa',
      keepRegisterText: 'Continuar cadastrando Tipos de tarefa?',
      registerButton: 'Cadastrar Tipo de Tarefa',
      titleLabel: 'Título',
      titleRule: 'Por favor informe o título do tipo de tarefa.',
      titlePlaceholder: 'Insira o título',
      valueTaskLabel: 'Valor a receber do Cliente Principal',
      valueTaskRule: 'Por favor informe valor R$ do tipo de tarefa.',
      valueTaskPlaceholder: 'Insira o valor a receber do Cliente Principal',
      value_received_TaskLabel: 'Valor a pagar para o colaborador',
      value_received_TaskRule: 'Por favor informe valor R$ do tipo de tarefa.',
      value_received_TaskPlaceholder:
        'Insira o valor a pagar para o colaborador',
      toleranceTimeLabel: 'Tempo máximo para iniciar o serviço (SLA)',
      toleranceTimeTooltip:
        'SLA é a sigla de Service Level Agreement, ou seja, Acordo de Nível de Serviço. Aqui é definido o tempo máximo para que o serviço seja iniciado.',
      toleranceTimeRule: 'Por favor informe o tempo de tolerância',
      runtimeLabel: 'Tempo estimado de execução',
      runtimeTooltip:
        'Aqui é definido o tempo estimado para execução do serviço, ou seja, do início do atendimento até sua conclusão (sem contar deslocamento).',
      runtimeRule: 'Por favor informe o estimado de execução.',
      descriptionLabel: 'Descrição',
      descriptionPlaceholder: 'Insira a descrição',
    },
  },
  en: {
    TasksRegistrationTypeTasksCreate: {
      cardTitle: 'New Task Type',
      keepRegisterText: 'Continue registering Task types?',
      registerButton: 'Register Task Type',
      titleLabel: 'Title',
      titleRule: 'Please enter the task type title.',
      titlePlaceholder: 'Enter the title',
      valueTaskLabel: 'Valor a receber do Cliente Principal',
      valueTaskRule: 'Por favor informe valor R$ do tipo de tarefa.',
      valueTaskPlaceholder: 'Insira o valor a receber do Cliente Principal',
      value_received_TaskLabel: 'Valor a pagar para o colaborador',
      value_received_TaskRule: 'Por favor informe valor R$ do tipo de tarefa.',
      value_received_TaskPlaceholder:
        'Insira o valor a pagar para o colaborador',
      toleranceTimeLabel: 'Service SLA',
      toleranceTimeTooltip:
        'SLA is the acronym for Service Level Agreement, that is, Service Level Agreement. Here the maximum time for the service to start is defined.',
      toleranceTimeRule: 'Please enter the grace period',
      runtimeLabel: 'Estimated execution time',
      runtimeTooltip:
        'Here, the estimated time for the execution of the service is defined, that is, from the beginning of the service to its conclusion (not counting displacement).',
      runtimeRule: 'Please inform the estimated execution.',
      descriptionLabel: 'Description',
      descriptionPlaceholder: 'Enter description',
    },
  },
  es: {
    TasksRegistrationTypeTasksCreate: {
      cardTitle: 'Nuevo tipo de tarea',
      keepRegisterText: '¿Continuar registrando Tipos de tareas?',
      registerButton: 'Registrar tipo de tarea',
      titleLabel: 'Título',
      titleRule: 'Introduzca el título del tipo de tarea.',
      titlePlaceholder: 'Ingresa el titulo',
      valueTaskLabel: 'Valor a receber do Cliente Principal',
      valueTaskRule: 'Por favor informe valor R$ do tipo de tarefa.',
      valueTaskPlaceholder: 'Insira o valor a receber do Cliente Principal',
      value_received_TaskLabel: 'Valor a pagar para o colaborador',
      value_received_TaskRule: 'Por favor informe valor R$ do tipo de tarefa.',
      value_received_TaskPlaceholder:
        'Insira o valor a pagar para o colaborador',
      toleranceTimeLabel: 'SLA de servicio',
      toleranceTimeTooltip:
        'SLA es el acrónimo de Service Level Agreement, es decir, Acuerdo de Nivel de Servicio. Aquí se define el tiempo máximo de inicio del servicio.',
      toleranceTimeRule: 'Por favor ingrese el período de gracia',
      runtimeLabel: 'Tiempo estimado de ejecución',
      runtimeTooltip:
        'Aquí se define el tiempo estimado para la ejecución del servicio, es decir, desde el inicio del servicio hasta su conclusión (sin contar desplazamiento).',
      runtimeRule: 'Por favor informar la ejecución estimada.',
      descriptionLabel: 'Descripción',
      descriptionPlaceholder: 'Ingrese la descripción',
    },
  },
}
