import React from 'react'
import { Helmet } from 'react-helmet'
import GroupsTabs from '~/components/DataManipulation/Groups'

import { GroupProvider } from '~/hooks/Groups/useGroup'
import { ListGroupProvider } from '~/hooks/Groups/useListGroup'
import { ModuleProvider } from '~/hooks/Groups/useModule'
import { PermissionProvider } from '~/hooks/Groups/usePermission'
import { UserGroupProvider } from '~/hooks/Groups/useUserGroup'
import { ContributorGroupProvider } from '~/hooks/Groups/useContributorGroup'
import { translate } from '~/utils/locale'

const ShowGroups: React.FC = () => {
  return (
    <GroupProvider>
      <Helmet>
        <title>{translate('ShowGroups.helmetTitle')}</title>
      </Helmet>
      <UserGroupProvider>
        <ContributorGroupProvider>
          <PermissionProvider>
            <ListGroupProvider>
              <ModuleProvider>
                <GroupsTabs />
              </ModuleProvider>
            </ListGroupProvider>
          </PermissionProvider>
        </ContributorGroupProvider>
      </UserGroupProvider>
    </GroupProvider>
  )
}

export default ShowGroups
