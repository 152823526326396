export default {
  pt: {
    AttachmentsList: {
      imageTag: 'Imagem',
      videoTag: 'Vídeo',
      audioTag: 'Audio',
      applicationTag: 'Documento',
      textTag: 'Texto',
      successMessage: 'Documento/Anexo deletado com sucesso!',
      errorMessage: 'Erro ao deletar o documento/anexo.',
      downloadTooltip: 'Fazer download',
      deletePopconfirm: 'Deseja mesmo deletar o documento/anexo?',
      deleteTooltip: 'Deletar documento/anexo',
      typeApplication: 'Aplicação',
      typeText: 'Arquivo de texto',
      typeNotes: 'Notas',
    },
  },
  en: {
    AttachmentsList: {
      imageTag: 'Image',
      videoTag: 'Video',
      audioTag: 'Audio',
      applicationTag: 'Document',
      textTag: 'Text',
      successMessage: 'Document/Attachment successfully deleted!',
      errorMessage: 'Error deleting document/attachment.',
      downloadTooltip: 'Download',
      deletePopconfirm: 'Do you really want to delete the document/attachment?',
      deleteTooltip: 'Delete document/attachment',
      typeApplication: 'Application',
      typeText: 'Text file',
      typeNotes: 'Notes',
    },
  },
  es: {
    AttachmentsList: {
      imageTag: 'Imagen',
      videoTag: 'Video',
      audioTag: 'Audio',
      applicationTag: 'Documento',
      textTag: 'Texto',
      successMessage: '¡Documento/Adjunto eliminado con éxito!',
      errorMessage: 'Error al eliminar el documento/archivo adjunto.',
      downloadTooltip: 'Descargar',
      deletePopconfirm:
        '¿Realmente desea eliminar el documento/archivo adjunto?',
      deleteTooltip: 'Eliminar documento/archivo adjunto',
      typeApplication: 'Aplicación',
      typeText: 'Archivo de texto',
      typeNotes: 'Notas',
    },
  },
}
