/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/require-default-props */
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

import { Button, message, Popconfirm, Tooltip } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { Container } from './styles'

import { useCompanies } from '~/hooks/Companies/useCompanies'
import { useScale } from '~/hooks/Scales/useScale'
import { useLocale } from '~/hooks/locale/useLocale'

import api from '~/services/api'

import { ShowError } from '~/utils/errors/apiErrors'
import { translate } from '~/utils/locale'

interface AddToScaleButtonProps {
  id: string
  isClient?: boolean
  isTeams?: boolean
}

const AddToScaleButton: React.FC<AddToScaleButtonProps> = ({
  id,
  isClient = false,
  isTeams = false,
}) => {
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)

  const { selectedCompany } = useCompanies()
  const { scaleRefetch } = useScale()
  const { locale } = useLocale()

  const { scaleId } = useParams<{ scaleId: string }>()

  const showPopconfirm = () => {
    setVisible(true)
  }

  const handleCancel = () => {
    setVisible(false)
  }

  const onAdd = async () => {
    try {
      setLoading(true)

      if (isClient) {
        await api.post(
          `/company/${selectedCompany}/schedule/${scaleId}/customer/${id}`,
        )

        message.success(translate('MiscAddToScaleButton.clientSuccessMessage'))
      } else {
        await api.post(
          isTeams
            ? `/company/${selectedCompany}/schedule/${scaleId}/team/${id}`
            : `/company/${selectedCompany}/schedule/${scaleId}/collaborator/${id}`,
        )

        message.success(
          isTeams
            ? translate('MiscAddToScaleButton.teamSuccessMessage')
            : translate('MiscAddToScaleButton.contributorSuccessMessage'),
        )
      }

      scaleRefetch()
      setLoading(false)
    } catch (err: any) {
      ShowError(
        err.message,
        isTeams
          ? translate('MiscAddToScaleButton.teamErrorMessage')
          : translate('MiscAddToScaleButton.contributorErrorMessage'),
        locale,
      )

      setLoading(false)
    } finally {
      setVisible(false)
    }
  }

  return (
    <Container>
      <Popconfirm
        title={
          isTeams
            ? translate('MiscAddToScaleButton.teamPopconfirm')
            : `${translate('MiscAddToScaleButton.popconfirmAddText')} ${
                isClient
                  ? translate('MiscAddToScaleButton.popconfirmClientText')
                  : translate('MiscAddToScaleButton.popconfirmContributorText')
              } ${translate('MiscAddToScaleButton.popconfirmScaleText')}`
        }
        okButtonProps={{ loading }}
        onConfirm={() => onAdd()}
        open={visible}
        onCancel={handleCancel}
      >
        <Tooltip
          title={
            isTeams
              ? translate('MiscAddToScaleButton.teamTooltip')
              : `${translate('MiscAddToScaleButton.tooltipAddText')} ${
                  isClient
                    ? translate('MiscAddToScaleButton.tooltipClientText')
                    : translate('MiscAddToScaleButton.tooltipContributorText')
                } na escala`
          }
        >
          <Button
            type="primary"
            shape="circle"
            icon={<PlusOutlined />}
            onClick={showPopconfirm}
            id="btn_add_to_scale"
          />
        </Tooltip>
      </Popconfirm>
    </Container>
  )
}

export default AddToScaleButton
