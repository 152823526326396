export default {
  pt: {
    ClientsPrincipalTabsFinancial: {
      financialTitleCard:
        'Financeiro - Valores acordados para receber do Cliente Principal',

      valueText: 'Valor da Tarefa',
      hoursText: 'Valor da Tarefa',
      valueHoursText: 'Valor da Tarefa',
      kmText: 'Valor da Tarefa',
      kmValueText: 'Valor da Tarefa',

      undefinedText: 'Nenhum valor foi fornecido.',
    },
  },
  en: {
    ClientsPrincipalTabsFinancial: {
      financialTitleCard:
        'Financial - Amounts agreed to receive from the Main Customer',

      valueText: 'Task Value',
      hoursText: 'Number of hours',
      valueHoursText: 'Additional Hourly Value',
      kmText: 'Quantity of km',
      kmValueText: 'Additional KM Value',

      undefinedText: 'No value was provided.',
    },
  },
  es: {
    ClientsPrincipalTabsFinancial: {
      financialTitleCard:
        'Financiero - Montos acordados a recibir del Cliente Principal',

      valueText: 'Valor de la tarea',
      hoursText: 'Número de horas',
      valueHoursText: 'Valor Hora Adicional',
      kmText: 'Cantidad de KM',
      kmValueText: 'Valor KM Adicional',

      undefinedText: 'No se proporcionó ningún valor.',
    },
  },
}
