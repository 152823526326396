/* eslint-disable camelcase */
import {
  CarOutlined,
  ClockCircleOutlined,
  FileUnknownOutlined,
  LoadingOutlined,
  ProfileOutlined,
} from '@ant-design/icons'
import { Button, Space, Table, Tooltip } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import React from 'react'
import { Link } from 'react-router-dom'
import { useTeams } from '~/hooks/Teams/useListTeams'
import { translate } from '~/utils/locale'

import Text from 'antd/lib/typography/Text'
import CurrencyFormatter from '~/components/DataManipulation/Misc/CurrencyFormater'
import UpdateFinancialTeams from '../Update'

// import { Container } from './styles';

interface Managers {
  id: string
  contact: {
    id: string
    name: string
  }
}

export interface TeamsFinancialProps {
  title: string
  id: string
  created_at: Date
  manager: Managers
  description: string
  amount_to_receive?: number
  amount_hours_receive?: number
  amount_hours_additional_receive?: number
  number_km_receive?: number
  number_km_additional_receive?: number
}

const TableFinancialTeams: React.FC = () => {
  const { teams, isFetching } = useTeams()

  const columns: ColumnsType<TeamsFinancialProps> = [
    {
      title: translate('TeamsTable.titleColumn'),
      dataIndex: 'title',
      key: 'title',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (name: string, record) => (
        <Link to={`/equipes/visualizar/${record?.id}`}>{name}</Link>
      ),
    },
    {
      title: translate('TeamsTable.responsibleColumn'),
      dataIndex: ['manager', 'contact', 'name'],
      key: 'manager',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (manager: string) => (
        <>
          {manager || (
            <Text type="secondary">
              <Space size="small">
                <FileUnknownOutlined />
                N/A
              </Space>
            </Text>
          )}
        </>
      ),
    },
    {
      title: 'Valor a pagar',
      dataIndex: 'amount_to_receive',
      key: 'task_value',
      render: (amount_to_receive: number) => (
        <Text>
          <CurrencyFormatter value={amount_to_receive} />
        </Text>
      ),
    },
    {
      title: 'Horas Extras',
      dataIndex: 'amount_hours_receive',
      key: 'amount_hours_receive',
      render: (amount_hours_receive: number) => (
        <Text>
          <ClockCircleOutlined /> {amount_hours_receive}:00
        </Text>
      ),
    },
    {
      title: 'Valor de Horas Extras',
      dataIndex: 'amount_hours_additional_receive',
      key: 'amount_hours_additional_receive',
      render: (amount_hours_additional_receive: number) => (
        <Text>
          <CurrencyFormatter value={amount_hours_additional_receive} />
        </Text>
      ),
    },
    {
      title: 'Kms Extras',
      dataIndex: 'number_km_receive',
      key: 'number_km_receive',
      render: (number_km_receive: number) => (
        <Text>
          <CarOutlined /> {number_km_receive} km
        </Text>
      ),
    },
    {
      title: 'Valor Kms Extras',
      dataIndex: 'number_km_additional_receive',
      key: 'number_km_additional_receive',
      render: (number_km_additional_receive: number) => (
        <Text>
          <CurrencyFormatter value={number_km_additional_receive} />
        </Text>
      ),
    },
    {
      title: translate('TeamsTable.actionsColumn'),
      align: 'center',
      key: 'remove',
      render: (record) => (
        <Space size="middle">
          <Tooltip title={translate('TeamsTable.viewTooltip')}>
            <Link to={`/equipes/visualizar/${record.id}`}>
              <Button
                type="primary"
                ghost
                shape="circle"
                icon={<ProfileOutlined />}
              />
            </Link>
          </Tooltip>

          <UpdateFinancialTeams data={record} />
        </Space>
      ),
    },
  ]

  return (
    <Table
      rowKey={(record) => record.id}
      loading={{
        indicator: <LoadingOutlined />,
        spinning: isFetching,
        size: 'large',
      }}
      columns={columns}
      dataSource={teams}
      scroll={{ x: 1000 }}
      pagination={{
        showTotal: (total, range) => `${range[0]} - ${range[1]} / ${total}`,
      }}
    />
  )
}

export default TableFinancialTeams
