export default {
  pt: {
    ClientsPrincipalFinancialCreate: {
      financialText: 'Valores acordados para receber do Cliente Principal',
      financialDescription:
        'Estes dados serão utilizados para calcular os valores a receber do Cliente Principal.',

      valueLabel: 'Valor da Tarefa',
      valuePlaceholder: 'Informe o valor da tarefa',
      valueRule: 'Por favor, informe o valor da tarefa.',

      hoursLabel: 'Quantidade de horas',
      hoursPlaceholder: 'Horas',
      valueHoursLabel: 'Valor Hora Adicional',

      kmLabel: 'Quantidade de KM',
      kmPlaceholder: 'Informe os KM',
      valueKmLabel: 'Valor KM Adicional',

      helpTooltipHour: 'Se excedido quantidade de horas máxima definida.',
      helpTooltipKm: 'Se excedido quantidade de KM máximo definido.',
    },
  },
  en: {
    ClientsPrincipalFinancialCreate: {
      financialText: 'Amounts agreed to receive from the Main Customer',
      financialDescription:
        'This data will be used to calculate the amounts receivable from the Main Customer.',

      valueLabel: 'Task Value',
      valuePlaceholder: 'Informe o valor da tarefa',
      valueRule: 'Por favor, informe o valor da tarefa.',

      hoursLabel: 'Number of hours',
      hoursPlaceholder: 'Hours',
      valueHoursLabel: 'Additional Hourly Value',

      kmLabel: 'Quantity of km',
      kmPlaceholder: 'Enter the km',
      valueKmLabel: 'Additional KM Value',

      helpTooltipHour: 'If the defined maximum number of hours is exceeded.',
      helpTooltipKm: 'If exceeded defined maximum KM amount.',
    },
  },
  es: {
    ClientsPrincipalFinancialCreate: {
      financialText: 'Montos acordados a recibir del Cliente Principal',
      financialDescription:
        'Estos datos se utilizarán para calcular los importes a cobrar del Cliente Principal.',

      valueLabel: 'Valor de la tarea',
      valuePlaceholder: 'Informe o valor da tarefa',
      valueRule: 'Por favor, informe o valor da tarefa.',

      hoursLabel: 'Número de horas',
      hoursPlaceholder: 'Horas',
      valueHoursLabel: 'Valor Hora Adicional',

      kmLabel: 'Cantidad de KM',
      kmPlaceholder: 'Introduce el KM',
      valueKmLabel: 'Valor KM Adicional',

      helpTooltipHour: 'Si se supera el número máximo de horas definido.',
      helpTooltipKm: 'Si se supera el número máximo de horas definido.',
    },
  },
}
