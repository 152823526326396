/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react/no-this-in-sfc */
import React, { useCallback } from 'react'
import { Helmet } from 'react-helmet'

import { FiLogOut, FiMail } from 'react-icons/fi'

import { message } from 'antd'
import * as styles from './styles'

import { useAuth } from '~/hooks/Auth/AuthContext'
import { useLocale } from '~/hooks/locale/useLocale'

import logoImg from '~/assets/logo_icon.svg'
import api from '~/services/api'

import { ShowError } from '~/utils/errors/apiErrors'
import { translate } from '~/utils/locale'

const ConfirmPageWithNoToken: React.FC = () => {
  const { signOut, user } = useAuth()

  const { locale } = useLocale()

  const handleReSendCode = useCallback(async () => {
    try {
      await api.post('user/confirm/update-token', { user_id: user.id })
      message.success(
        `${translate('ConfirmPageWithNoToken.successMessage')} ${user.email}`,
      )
    } catch (err: any) {
      ShowError(
        err.message,
        translate('ConfirmPageWithNoToken.errorMessage'),
        locale,
      )
    }
  }, [user, locale])

  return (
    <>
      <Helmet>
        <title>{translate('ConfirmPageWithNoToken.helmetTitle')}</title>
      </Helmet>

      <styles.Content>
        <styles.AnimationContainer>
          <img src={logoImg} alt="Gstor" />
          <h1>{translate('ConfirmPageWithNoToken.title')}</h1>
          <p>
            {translate('ConfirmPageWithNoToken.paragraphOne')}
            <br />
            <b>{user.email}</b>
            <br />
            {translate('ConfirmPageWithNoToken.paragraphTwo')}
          </p>

          <button
            data-testid="button-email"
            type="submit"
            onClick={handleReSendCode}
          >
            <FiMail />
            {translate('ConfirmPageWithNoToken.buttonText')}
          </button>

          <button type="button" onClick={signOut}>
            <FiLogOut />
            {translate('ConfirmPageWithNoToken.exitText')}
          </button>
        </styles.AnimationContainer>
      </styles.Content>

      <styles.Background />
    </>
  )
}

export default ConfirmPageWithNoToken
