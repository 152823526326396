export default {
  pt: {
    ReportTasksTable: {
      dateString: 'às',
      responsibleColumn: 'Responsável',
      initialTimeColumn: 'Data',
      taskTypeColumn: 'Tipo de Tarefa',
      statusColumn: 'Status',
      statusReceived: 'Tarefa Recebida',
      statusViewed: 'Tarefa Visualizada',
      statusEnRoute: 'Tarefa a caminho',
      statusCheckIn: 'Tarefa sendo realizada',
      statusPauseTask: 'Tarefa Pausada',
      statusResumeWorking: 'Retomar Trabalho',
      statusCheckout: 'Tarefa Finalizada',
      statusCanceled: 'Tarefa Cancelada',
      statusFinished: 'Tarefa Finalizada',
      actionsColumn: 'Ações',
    },
  },
  en: {
    ReportTasksTable: {
      dateString: 'at',
      responsibleColumn: 'Responsible',
      initialTimeColumn: 'Date',
      taskTypeColumn: 'Task Type',
      statusColumn: 'Status',
      statusReceived: 'Task Confirmed',
      statusViewed: 'Task Viewed',
      statusEnRoute: 'Task on the way',
      statusCheckIn: 'Task being performed',
      statusPauseTask: 'Paused Task',
      statusResumeWorking: 'Resume Work',
      statusCheckout: 'Finished Task',
      statusCanceled: 'Canceled Task',
      statusFinished: 'Finished Task',
      actionsColumn: 'Actions',
    },
  },
  es: {
    ReportTasksTable: {
      dateString: 'a las',
      responsibleColumn: 'Responsable',
      initialTimeColumn: 'Fecha',
      taskTypeColumn: 'Tipo de Tarea',
      statusColumn: 'Estado',
      statusReceived: 'Tarea Confirmada',
      statusViewed: 'Tarea Vista',
      statusEnRoute: 'Tarea en camino',
      statusCheckIn: 'Tarea que se está realizando',
      statusPauseTask: 'Tarea en pausa',
      statusResumeWorking: 'Continúa el trabajo',
      statusCheckout: 'Tarea Terminada',
      statusCanceled: 'Tarea Cancelada',
      statusFinished: 'Tarea Terminada',
      actionsColumn: 'Acciones',
    },
  },
}
