export default {
  pt: {
    TasksCreateModalClient: {
      addClientPopconfirm: 'Deseja mesmo adicionar o cliente?',
      addClientTooltip: 'Adicionar cliente',
      viewClientTooltip: 'Ver cliente',
      addClientButton: 'Adicionar Cliente',
      changeClientButton: 'Alterar Cliente',
      modalTitle: 'Adicionar Cliente',
      closeButton: 'Fechar',
    },
  },
  en: {
    TasksCreateModalClient: {
      addClientPopconfirm: 'Do you really want to add the customer?',
      addClientTooltip: 'Add customer',
      viewClientTooltip: 'View customer',
      addClientButton: 'Add customer',
      changeClientButton: 'Change Customer',
      modalTitle: 'Add customer',
      closeButton: 'Close',
    },
  },
  es: {
    TasksCreateModalClient: {
      addClientPopconfirm: '¿Realmente desea agregar al cliente?',
      addClientTooltip: 'Añadir Cliente',
      viewClientTooltip: 'Ver Cliente',
      addClientButton: 'Añadir Cliente',
      changeClientButton: 'Cambiar Cliente',
      modalTitle: 'Añadir Cliente',
      closeButton: 'Cerrar',
    },
  },
}
