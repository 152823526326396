/* eslint-disable @typescript-eslint/no-explicit-any */
import { EditOutlined } from '@ant-design/icons'
import { Modal, Button, Form, Tooltip, Select, Space } from 'antd'
import React, { useState } from 'react'

import { useContributor } from '~/hooks/Contributors/useContributor'
import { useListStatus } from '~/hooks/Status/useListStatus'
import { translate } from '~/utils/locale'

import { Container } from './styles'

interface UpdateStatusData {
  status: string
}

const UpdateStatusContribuitor: React.FC = () => {
  const { listStatus } = useListStatus()
  const { data, updateStatus } = useContributor()
  const [isModalVisible, setIsModalVisible] = useState(false)

  function onUpdateStatus(valueForm: UpdateStatusData) {
    try {
      updateStatus(valueForm.status)
      setIsModalVisible(false)
    } catch (err) {
      setIsModalVisible(false)
    }
  }

  const showModal = () => {
    setIsModalVisible(true)
  }

  const closeModal = () => {
    setIsModalVisible(false)
  }

  return (
    <Container>
      <Tooltip title={translate('StatusContributorUpdate.editTooltip')}>
        <Button
          id="btn-edit-evaluation-contributor"
          onClick={showModal}
          type="primary"
          icon={<EditOutlined />}
        >
          {translate('StatusContributorUpdate.editButton')}
        </Button>
      </Tooltip>
      <Modal
        title={translate('StatusContributorUpdate.modalTitle')}
        open={isModalVisible}
        onOk={closeModal}
        onCancel={closeModal}
        footer={[
          <Space key="buttons">
            <Button key="back" onClick={closeModal}>
              {translate('StatusContributorUpdate.closeButton')}
            </Button>
            <Button
              key="save"
              type="primary"
              form="editStatusContributor"
              htmlType="submit"
              id="btn_update_contributor"
            >
              {translate('StatusContributorUpdate.saveButton')}
            </Button>
          </Space>,
        ]}
      >
        <Form
          id="editStatusContributor"
          layout="vertical"
          onFinish={onUpdateStatus}
          initialValues={{ status: data?.status?.id }}
        >
          <Form.Item
            label={translate('StatusContributorUpdate.statusLabel')}
            name="status"
          >
            <Select
              placeholder={translate(
                'StatusContributorUpdate.statusPlaceholder',
              )}
            >
              {listStatus?.map((status) => (
                <Select.Option value={status.id} key={status.id}>
                  {status.title}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </Container>
  )
}

export default UpdateStatusContribuitor
