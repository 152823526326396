export default {
  pt: {
    CategoryHooks: {
      getErrorMessage: 'Erro ao visualizar categorias.',
      createSuccessMessage: 'Categoria criada com sucesso!',
      createErrorMessage: 'Erro ao criar categoria.',
      updateSuccessMessage: 'Categoria atualizada com sucesso!',
      updateErrorMessage: 'Erro ao atualizar categoria.',
      deleteSuccessMessage: 'Categoria deletada com sucesso!',
      deleteErrorMessage: 'Houve um erro ao deleter a Categoria',
    },
  },
  en: {
    CategoryHooks: {
      getErrorMessage: 'Error viewing categories.',
      createSuccessMessage: 'Category created successfully!',
      createErrorMessage: 'Error creating category.',
      updateSuccessMessage: 'Category updated successfully!',
      updateErrorMessage: 'Error updating category.',
      deleteSuccessMessage: 'Category deleted successfully!',
      deleteErrorMessage: 'There was an error deleting the Category',
    },
  },
  es: {
    CategoryHooks: {
      getErrorMessage: 'Error al ver las categorías.',
      createSuccessMessage: '¡Categoría creada con éxito!',
      createErrorMessage: 'Error al crear la categoría.',
      updateSuccessMessage: '¡Categoría actualizada con éxito!',
      updateErrorMessage: 'Error al actualizar la categoría.',
      deleteSuccessMessage: '¡Categoría eliminada con éxito!',
      deleteErrorMessage: 'Hubo un error al eliminar la categoría',
    },
  },
}
