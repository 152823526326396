import styled from 'styled-components'

export const Container = styled.div`
  .wrapper {
    display: flex;
    align-items: center;
  }

  h4 {
    margin-bottom: 0px;
  }

  .text {
    margin: 10px 0 0 20px;

    h3 {
      color: var(--primary-initial-text);
    }

    p {
      color: var(--secondary-initial-text);
    }
  }
`

export const ListAbout = styled.div``
