/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable consistent-return */
import React, { useContext, createContext } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'

import { message } from 'antd'

import api from '~/services/api'

import { useCompanies } from '~/hooks/Companies/useCompanies'
import { useLocale } from '~/hooks/locale/useLocale'

import { ShowError } from '~/utils/errors/apiErrors'
import { translate } from '~/utils/locale'

interface Contribuitors {
  id: string
  contact: {
    id: string
    name: string
  }
}

interface ListStatusProps {
  title: string
  id: string
  type: string
  color: string
  description: string
  created_at: string
  contributors: Contribuitors[]
}

interface StatusContextData {
  status?: ListStatusProps
  updateStatus(value: object | undefined): void
  isLoading: boolean
  isFetching: boolean
  refetch: () => void
}

const StatusContext = createContext<StatusContextData>({} as StatusContextData)

export const StatusProvider: React.FC = ({ children }) => {
  const { selectedCompany } = useCompanies()
  const { locale } = useLocale()
  const { statusId } = useParams<{ statusId: string }>()

  const {
    data: status,
    isLoading,
    isFetching,
    refetch,
  } = useQuery(
    [`status of contributors${selectedCompany}`, selectedCompany],

    async () => {
      try {
        const response = await api.get(
          `/company/${selectedCompany}/contributors/status/${statusId}`,
        )

        const { data } = response

        return data
      } catch (err: any) {
        ShowError(err.message, translate('useStatus.getErrorMessage'), locale)
      }
    },
  )

  async function updateStatus(value: object) {
    try {
      await api.put(
        `/company/${selectedCompany}/contributors/status/${statusId}`,
        value,
      )

      refetch()
      message.success(translate('useStatus.updateSuccessMessage'))
    } catch (err: any) {
      ShowError(err.message, translate('useStatus.updateErrorMessage'), locale)
    }
  }

  return (
    <StatusContext.Provider
      value={{
        status,
        updateStatus,
        isLoading,
        isFetching,
        refetch,
      }}
    >
      {children}
    </StatusContext.Provider>
  )
}

export function useStatus(): StatusContextData {
  const context = useContext(StatusContext)

  if (!context) {
    throw new Error('useAuth must be used within an AutoProvider')
  }

  return context
}
