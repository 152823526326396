import React from 'react'
import { ReactQueryDevtools } from 'react-query/devtools'

import { CompaniesProvider } from '~/hooks/Companies/useCompanies'
import { SkillsProvider } from '~/hooks/Skills/useSkills'
import { ContributorProvider } from '~/hooks/Contributors/useContributor'
import { ListContributorsProvider } from '~/hooks/Contributors/useListContributors'
import { ListScalesProvider } from './Scales/useListScales'
import { SegmentsProvider } from '~/hooks/Segments/useSegments'
import { ListClientsProvider } from '~/hooks/Clients/useListClients'
import { ScaleProvider } from '~/hooks/Scales/useScale'
import { EvaluationsProvider } from '~/hooks/Evaluations/useEvaluations'
import { FilterKanbanProvider } from './Tasks/Kanban/useFilterKanban'
import { ProfileProvider } from './Auth/useProfile'
import { NotificationsProvider } from './Notifications/useNotifications'
import { TourProvider } from './Tour/useTour'
import { TaskTypeTourProvider } from './Tour/useTaskTypeTour'
import { CollaboratorTourProvider } from './Tour/useCollaboratorTour'
import { ClientTourProvider } from './Tour/useClientTour'
import { TaskTourProvider } from './Tour/useTaskTour'

const AppProvider: React.FC = ({ children }) => (
  <>
    <CompaniesProvider>
      <ProfileProvider>
        <NotificationsProvider>
          <ListContributorsProvider>
            <ListClientsProvider>
              <SegmentsProvider>
                <ListScalesProvider>
                  <ScaleProvider>
                    <SkillsProvider>
                      <ContributorProvider>
                        <FilterKanbanProvider>
                          <EvaluationsProvider>
                            <TourProvider>
                              <TaskTypeTourProvider>
                                <CollaboratorTourProvider>
                                  <ClientTourProvider>
                                    <TaskTourProvider>
                                      {children}
                                    </TaskTourProvider>
                                  </ClientTourProvider>
                                </CollaboratorTourProvider>
                              </TaskTypeTourProvider>
                            </TourProvider>
                          </EvaluationsProvider>
                        </FilterKanbanProvider>
                      </ContributorProvider>
                    </SkillsProvider>
                  </ScaleProvider>
                </ListScalesProvider>
              </SegmentsProvider>
            </ListClientsProvider>
          </ListContributorsProvider>
        </NotificationsProvider>
      </ProfileProvider>
    </CompaniesProvider>

    <ReactQueryDevtools />
  </>
)

export default AppProvider
