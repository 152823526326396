export default {
  pt: {
    EquipmentsTemplate: {
      cardTitle: 'Templates de Especificação',
      totalText: 'Total de',
      templateText: 'template',
      templatesText: 'templates',
    },
  },
  en: {
    EquipmentsTemplate: {
      cardTitle: 'Specification Templates',
      totalText: 'Total of',
      templateText: 'template',
      templatesText: 'templates',
    },
  },
  es: {
    EquipmentsTemplate: {
      cardTitle: 'Plantillas de especificaciones',
      totalText: 'Total de',
      templateText: 'plantilla',
      templatesText: 'plantillas',
    },
  },
}
