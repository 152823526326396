export default {
  pt: {
    TeamsComponent: {
      cardTitle: 'Equipes',
      totalText: 'Total de',
      teamText: 'equipe',
      teamsText: 'equipes',
    },
  },
  en: {
    TeamsComponent: {
      cardTitle: 'Teams',
      totalText: 'Total of',
      teamText: 'team',
      teamsText: 'teams',
    },
  },
  es: {
    TeamsComponent: {
      cardTitle: 'Equipos',
      totalText: 'Total de',
      teamText: 'equipo',
      teamsText: 'equipos',
    },
  },
}
