import React from 'react'
import { Helmet } from 'react-helmet'

import ContactsBoard from '~/components/DataManipulation/Clients/Contacts'

import { ClientsContactsProvider } from '~/hooks/Clients/useContactClients'

import { translate } from '~/utils/locale'

const ShowContactsClients: React.FC = () => {
  return (
    <ClientsContactsProvider>
      <Helmet>
        <title>{translate('ShowContacts.helmetTitle')}</title>
      </Helmet>
      <ContactsBoard />
    </ClientsContactsProvider>
  )
}

export default ShowContactsClients
