/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-nested-ternary */
import {
  LoadingOutlined,
  PlusCircleOutlined,
  PlusOutlined,
  ReloadOutlined,
} from '@ant-design/icons'
import {
  Button,
  Modal,
  Tooltip,
  Table,
  Popconfirm,
  notification,
  Space,
} from 'antd'
import { ColumnsType } from 'antd/lib/table'
import Text from 'antd/lib/typography/Text'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useTaskCreate } from '~/hooks/Tasks/Create/createTask'
import { useScaleListTask } from '~/hooks/Tasks/Create/scaleListTask'
import { translate } from '~/utils/locale'
import ScalesFilterModal from './ScalesFilterModal'

interface Company {
  id: string
  name: string
}

interface DataType {
  company: Company
  contributorsSchedules: any[]
  created_at: string
  customersSchedules: any[]
  id: number
  scheduleTimes: any[]
  title: string
}

interface Contributors {
  id: number
  name: string
  avatar: string
}

interface Teams {
  id: number
  team: {
    collaborator_id: string
    id: string
    title: string
  }
}

const ScaleModalTask: React.FC = () => {
  const {
    setNumberPage,
    setPageSize,
    scalesData,
    scalesTotal,
    isFetching,
    scalesRefetch,
  } = useScaleListTask()
  const { scaleId, setScaleId, clientData } = useTaskCreate()

  const [isModalVisible, setIsModalVisible] = useState(false)

  async function handleScaleTask(id: string, name: string) {
    await setScaleId({ id, name })
    setIsModalVisible(false)
  }

  const columns: ColumnsType<DataType> = [
    {
      title: translate('TasksCreateModalScale.actionsColumn'),
      align: 'center',
      key: 'remove',
      render: (record) => (
        <Popconfirm
          title={translate('TasksCreateModalScale.addPopconfirm')}
          onConfirm={() => handleScaleTask(record.id, record.title)}
        >
          <Tooltip title={translate('TasksCreateModalScale.addTooltip')}>
            <Button type="primary" shape="circle" icon={<PlusOutlined />} />
          </Tooltip>
        </Popconfirm>
      ),
    },
    {
      title: translate('TasksCreateModalScale.titleColumn'),
      dataIndex: 'title',
      key: 'title',
      align: 'left',
      render: (title: string, record) => (
        <Tooltip title={translate('TasksCreateModalScale.viewTooltip')}>
          <Link to={`/escalas/editar/${record.id}`}>{title}</Link>
        </Tooltip>
      ),
    },
    {
      title: translate('TasksCreateModalScale.daysColumn'),
      key: 'dateRange',
      align: 'center',
      dataIndex: 'dateRange',
    },
    {
      title: translate('TasksCreateModalScale.contributorsColumn'),
      dataIndex: 'contributorsSchedules',
      key: 'contributorsSchedules',
      align: 'center',
      render: (contributors: Contributors[]) => {
        return (
          <Text>
            {contributors.length === 0
              ? translate('TasksCreateModalScale.noContributorText')
              : contributors.length === 1
                ? `1 ${translate('TasksCreateModalScale.contributorText')}`
                : `${contributors.length} ${translate(
                    'TasksCreateModalScale.contributorsText',
                  )}`}
          </Text>
        )
      },
    },
    {
      title: translate('TasksCreateModalScale.teamsColumn'),
      dataIndex: 'teamsSchedules',
      key: 'teamsSchedules',
      align: 'center',
      render: (teams: Teams[]) => {
        return (
          <Text>
            {teams.length === 0
              ? translate('TasksCreateModalScale.noTeamText')
              : teams.length === 1
                ? `1 ${translate('TasksCreateModalScale.teamText')}`
                : `${teams.length} ${translate(
                    'TasksCreateModalScale.teamsText',
                  )}`}
          </Text>
        )
      },
    },
  ]

  const showModal = () => {
    setIsModalVisible(true)
    scalesRefetch()

    notification.info({
      message: translate('TasksCreateModalScale.notificationMessage'),
      description: translate('TasksCreateModalScale.notificationDescription'),
    })
  }

  const closeModal = () => {
    setIsModalVisible(false)
  }

  return (
    <>
      {!scaleId?.id ? (
        <Button
          icon={<PlusCircleOutlined />}
          onClick={showModal}
          style={{ width: '100%' }}
          disabled={!clientData?.id}
        >
          {translate('TasksCreateModalScale.addButton')}
        </Button>
      ) : (
        <Button size="small" icon={<ReloadOutlined />} onClick={showModal}>
          {translate('TasksCreateModalScale.changeButton')}
        </Button>
      )}

      <Modal
        title={translate('TasksCreateModalScale.modalTitle')}
        open={isModalVisible}
        onOk={closeModal}
        onCancel={closeModal}
        width="900px"
        style={{
          top: 30,
          overflowY: 'scroll',
          maxHeight: 'calc(100vh - 200px)',
        }}
        footer={[
          <Space key="FooterProps">
            <Button key="back" onClick={closeModal}>
              {translate('TasksCreateModalScale.closeButton')}
            </Button>

            <ScalesFilterModal />
          </Space>,
        ]}
      >
        <Table
          rowKey={(record) => record.id}
          loading={{
            indicator: <LoadingOutlined />,
            spinning: isFetching,
            size: 'large',
          }}
          pagination={{
            total: scalesTotal,
            showSizeChanger: true,
            pageSizeOptions: ['10', '20', '50', '100', '200'],
            defaultPageSize: 10,
            showTotal: (total, range) => `${range[0]} - ${range[1]} / ${total}`,
            onChange: (page, pageSize) => {
              setPageSize(pageSize!)
              setNumberPage(page)
            },
          }}
          columns={columns}
          dataSource={scalesData}
          scroll={{ x: 1000 }}
        />
      </Modal>
    </>
  )
}

export default ScaleModalTask
