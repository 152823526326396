/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
import React, { useState } from 'react'

import { Tooltip, Button, Modal, Select, Checkbox, Space } from 'antd'
import { UserAddOutlined } from '@ant-design/icons'
import { Container, ContainerButton } from './styles'

import { useUserGroup, UserGroupProps } from '~/hooks/Groups/useUserGroup'
import { useListGroup } from '~/hooks/Groups/useListGroup'
import { translate } from '~/utils/locale'

interface UserGroup {
  group_id: string
}

const { Option } = Select

const CreateUser: React.FC<UserGroup> = ({ group_id }) => {
  const { listGroupsRefetch } = useListGroup()
  const { userGroup, createUser } = useUserGroup()
  const [userId, setUserId] = useState()
  const [keepRegister, setKeepRegister] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)

  function onUserChange(value: any) {
    setUserId(value)
  }

  function onCreateGroup() {
    try {
      createUser(userId, group_id)
      if (!keepRegister) {
        setIsModalVisible(false)
      }
      listGroupsRefetch()
    } catch (err) {
      if (!keepRegister) {
        setIsModalVisible(false)
      }
    }
  }

  const showModal = (event: any) => {
    event.stopPropagation()
    setIsModalVisible(true)
  }

  const closeModal = (event: any) => {
    event.stopPropagation()
    setIsModalVisible(false)
  }

  return (
    <Container onClick={(e) => e.stopPropagation()}>
      <Tooltip
        title={translate('GroupsCreateUserGroup.addTooltip')}
        key="tool-new"
      >
        <Button type="primary" icon={<UserAddOutlined />} onClick={showModal}>
          {translate('GroupsCreateUserGroup.addButton')}
        </Button>
      </Tooltip>

      <Modal
        title={translate('GroupsCreateUserGroup.modalTitle')}
        open={isModalVisible}
        onOk={closeModal}
        onCancel={closeModal}
        footer={[
          <Space key="buttons">
            <Checkbox
              key="KeepRegister"
              onChange={(e) => {
                setKeepRegister(e.target.checked)
              }}
              checked={keepRegister}
            >
              {translate('GroupsCreateUserGroup.keepAddText')}
            </Checkbox>
            <Button key="back" onClick={closeModal}>
              {translate('GroupsCreateUserGroup.closeButton')}
            </Button>
            <ContainerButton>
              <Button
                key="save"
                type="primary"
                onClick={onCreateGroup}
                id="btn_create_user_group"
              >
                {translate('GroupsCreateUserGroup.saveButton')}
              </Button>
            </ContainerButton>
          </Space>,
        ]}
      >
        <p>{translate('GroupsCreateUserGroup.userLabel')}</p>
        <Select
          placeholder={translate('GroupsCreateUserGroup.userPlaceholder')}
          style={{ width: '80%' }}
          onChange={onUserChange}
        >
          {userGroup?.map((users: UserGroupProps) => (
            <Option value={users.user.id} key={users.id}>
              {users.name}
            </Option>
          ))}
        </Select>
      </Modal>
    </Container>
  )
}

export default CreateUser
