import React from 'react'
import { Helmet } from 'react-helmet'
import UsersDetails from '~/components/DataManipulation/Users/Details'
import { ListGroupProvider } from '~/hooks/Groups/useListGroup'
import { UserGroupProvider } from '~/hooks/Groups/useUserGroup'
import { ContributorGroupProvider } from '~/hooks/Groups/useContributorGroup'

import { UsersProvider } from '~/hooks/Users/useUsers'
import { translate } from '~/utils/locale'

const UsersContacts: React.FC = () => {
  return (
    <UsersProvider>
      <Helmet>
        <title>{translate('ShowUsers.helmetTitle')}</title>
      </Helmet>
      <ListGroupProvider>
        <UserGroupProvider>
          <ContributorGroupProvider>
            <UsersDetails />
          </ContributorGroupProvider>
        </UserGroupProvider>
      </ListGroupProvider>
    </UsersProvider>
  )
}

export default UsersContacts
