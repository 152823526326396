import { CloseOutlined, PlusOutlined } from '@ant-design/icons'
import { Form, Input, Flex, Button, Select } from 'antd'
import React from 'react'
import { IQuestion } from '~/hooks/Tasks/Forms/useTaskForm'

interface QuestionProps {
  data: IQuestion
  isEditing: boolean
}

const QuestionDropdownList: React.FC<QuestionProps> = ({ data, isEditing }) => {
  return (
    <Flex vertical>
      {isEditing ? (
        <Form.Item
          name={'title'}
          style={{ width: '100%' }}
          rules={[{ required: true, message: 'Campo obrigatório' }]}
        >
          <Input.TextArea placeholder="Pergunta" size="large" />
        </Form.Item>
      ) : (
        <h2>{data.title}</h2>
      )}

      {isEditing ? (
        <Form.Item name={'description'}>
          <Input.TextArea placeholder="Descrição" disabled={!isEditing} />
        </Form.Item>
      ) : (
        data.description && <h4>{data.description}</h4>
      )}

      {isEditing ? (
        <Form.List
          name="options"
          rules={[
            {
              validator: async (_, options: Array<string>) => {
                if (!options || options.length < 2) {
                  return Promise.reject(
                    new Error('Precisa ter pelo menos 2 opções'),
                  )
                }

                if (
                  options.some((item, index) => options.indexOf(item) !== index)
                ) {
                  return Promise.reject(
                    new Error(
                      'Existem opções duplicadas, remova ou modifique o campo.',
                    ),
                  )
                }
              },
            },
          ]}
        >
          {(fields, { add, remove }, { errors }) => (
            <>
              {fields.map((field, index) => (
                <Flex
                  key={field.key}
                  align="center"
                  gap="1rem"
                  style={{ marginBottom: '1rem' }}
                >
                  <b>{index + 1}.</b>
                  <Form.Item
                    {...field}
                    validateTrigger={['onChange', 'onBlur']}
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message:
                          'Por favor insira a opção ou exclua este campo.',
                      },
                    ]}
                    style={{ marginBottom: 0, width: '100%' }}
                  >
                    <Input placeholder="Opção" />
                  </Form.Item>

                  {fields.length > 1 ? (
                    <CloseOutlined
                      className="dynamic-delete-button"
                      onClick={() => remove(field.name)}
                    />
                  ) : null}
                </Flex>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add(`Opção ${fields.length + 1}`)}
                  style={{ width: '100%' }}
                  icon={<PlusOutlined />}
                >
                  Adicionar opção
                </Button>
                <Form.ErrorList errors={errors} />
              </Form.Item>
            </>
          )}
        </Form.List>
      ) : (
        <Select
          defaultValue={data.options && data.options[0]}
          options={
            data.options
              ? data.options.map((option) => ({
                  label: option,
                  value: option,
                  disabled: true,
                }))
              : []
          }
        />
      )}
    </Flex>
  )
}

export default QuestionDropdownList
