export default {
  pt: {
    ShowTasks: {
      helmetTitle: 'Visualizar Tarefa',
    },
  },
  en: {
    ShowTasks: {
      helmetTitle: 'View Task',
    },
  },
  es: {
    ShowTasks: {
      helmetTitle: 'Ver Tarea',
    },
  },
}
