/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react'

import { Drawer, Form, Button, Col, Row, Input, Tooltip } from 'antd'
import { CloseOutlined, FilterOutlined } from '@ant-design/icons'

import { Container } from './styles'

import { useSegments } from '~/hooks/Segments/useSegments'
import { useListContributors } from '~/hooks/Contributors/useListContributors'
import { translate } from '~/utils/locale'
import { useTaskForms } from '~/hooks/Tasks/Forms/useTaskForms'

const TaskTypeFilterModal: React.FC = () => {
  const { handleUpdateFilters, handleResetFilters, filterOptions } =
    useTaskForms()
  const { segments } = useSegments()
  const { data } = useListContributors()

  const [visible, setVisible] = useState(false)

  const [form] = Form.useForm()

  useEffect(() => {
    form.setFieldsValue({
      title: filterOptions?.title,
    })
  }, [filterOptions, form])

  const showDrawer = () => {
    setVisible(true)
  }

  const onClose = () => {
    setVisible(false)
  }

  const onFinish = (dataForm: any) => {
    handleUpdateFilters(dataForm)
    setVisible(false)
  }

  const onReset = () => {
    handleResetFilters()
    form.resetFields()
    setVisible(false)
  }

  const isFilter = Object.keys(filterOptions).length !== 0

  return (
    <Container isFilter={isFilter}>
      <Button
        type="primary"
        onClick={showDrawer}
        loading={!data || !segments}
        icon={
          !isFilter ? (
            <FilterOutlined />
          ) : (
            <Tooltip title={translate('FormsFilter.cleanFilterTooltip')}>
              <CloseOutlined onClick={onReset} />
            </Tooltip>
          )
        }
        id="btn_filter_clients_tasks"
      >
        {isFilter
          ? translate('FormsFilter.appliedButtonFilter')
          : translate('FormsFilter.defaultButtonFilter')}
      </Button>
      <Drawer
        forceRender
        title={translate('FormsFilter.filtersTitle')}
        width={580}
        onClose={onClose}
        open={visible}
        style={{ paddingBottom: 80 }}
      >
        <Form layout="vertical" onFinish={onFinish} form={form}>
          <Row gutter={16}>
            <Col span={16}>
              <Form.Item
                name="title"
                label={translate('FormsFilter.titleLabel')}
              >
                <Input
                  placeholder={translate('FormsFilter.titlePlaceholder')}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item style={{ width: '100%' }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: '100%' }}
                >
                  {translate('FormsFilter.finishButton')}
                </Button>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item style={{ width: '100%' }}>
                <Button onClick={onReset} style={{ width: '100%' }}>
                  {translate('FormsFilter.cleanButton')}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </Container>
  )
}

export default TaskTypeFilterModal
