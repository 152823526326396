import styled from 'styled-components'

export const Container = styled.div`
  margin-top: 10px;

  .wrapper {
    display: flex;
    align-items: center;
  }

  h4 {
    margin-bottom: 0px;
  }

  .text {
    margin: 0 0 0 20px;

    p {
      color: var(--primary-initial-text);
    }
  }
`
