export default {
  pt: {
    ConfirmPageWithNoToken: {
      successMessage: 'E-mail enviado para:',
      errorMessage: 'Erro ao re-enviar e-mail de confirmação.',
      helmetTitle: 'Confirmação',
      title: 'Confirme seu Cadastro',
      paragraphOne:
        'O código de confirmação de conta foi enviado para o email:',
      paragraphTwo:
        'Caso demore a receber, verifique a caixa de Spam ou Lixeira, se não solucionar clique para reenviar um novo email de confirmação',
      buttonText: 'Re-enviar e-mail',
      exitText: 'Sair',
    },
  },
  en: {
    ConfirmPageWithNoToken: {
      successMessage: 'Email sent to:',
      errorMessage: 'Error resending confirmation email.',
      helmetTitle: 'Confirmation',
      title: 'Confirm your Registration',
      paragraphOne: 'The account confirmation code has been sent to the email:',
      paragraphTwo:
        'If it takes a while to receive it, check your Spam or Trash box, if not, click to resend a new confirmation email',
      buttonText: 'Resend email',
      exitText: 'Exit',
    },
  },
  es: {
    ConfirmPageWithNoToken: {
      successMessage: 'Email enviado a:',
      errorMessage: 'Error al reenviar el correo electrónico de confirmación.',
      helmetTitle: 'Confirmación',
      title: 'Confirma tu Registro',
      paragraphOne:
        'El código de confirmación de la cuenta ha sido enviado al correo electrónico:',
      paragraphTwo:
        'Si tarda un poco en recibirlo, marque su casilla de Spam o Papelera, si no, haga clic para volver a enviar un nuevo correo electrónico de confirmación',
      buttonText: 'Reenviar email',
      exitText: 'Salir',
    },
  },
}
