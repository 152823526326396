export default {
  pt: {
    ClientsParent: {
      parentsTitle: 'Clientes Principais',
      totalText: 'Total de',
      parentText: 'cliente',
      parentsText: 'clientes',
      newParentButton: 'Novo cliente',
    },
  },
  en: {
    ClientsParent: {
      parentsTitle: 'Principal Customer',
      totalText: 'Total of',
      parentText: 'customer',
      parentsText: 'customers',
      newParentButton: 'New customer',
    },
  },
  es: {
    ClientsParent: {
      parentsTitle: 'Cliente Principal',
      totalText: 'Total de',
      parentText: 'cliente',
      parentsText: 'clientes',
      newParentButton: 'Nuevo cliente',
    },
  },
}
