export default {
  pt: {
    TeamsCollaborator: {
      addTooltip: 'Adicionar colaborador',
      addButton: 'Adicionar colaborador',
      modalTitle: 'Adicionar colaborador',
      keepRegisterText: 'Continuar adicionando colaboradores?',
      closeButton: 'Fechar',
      saveButton: 'Salvar',
      contributorLabel: 'Colaborador',
      contributorRule: 'Selecione o colaborador.',
      contributorPlaceholder: 'Selecione o colaborador',
    },
  },
  en: {
    TeamsCollaborator: {
      addTooltip: 'Add Contributor',
      addButton: 'Add Contributor',
      modalTitle: 'Add Contributor',
      keepRegisterText: 'Continue adding contributors?',
      closeButton: 'Close',
      saveButton: 'Save',
      contributorLabel: 'Contributor',
      contributorRule: 'Select the contributor.',
      contributorPlaceholder: 'Select the contributor',
    },
  },
  es: {
    TeamsCollaborator: {
      addTooltip: 'Agregar colaborador',
      addButton: 'Agregar colaborador',
      modalTitle: 'Agregar colaborador',
      keepRegisterText: '¿Continuar agregando colaboradores?',
      closeButton: 'Cerrar',
      saveButton: 'Guardar',
      contributorLabel: 'Colaborador',
      contributorRule: 'Seleccione el colaborador.',
      contributorPlaceholder: 'Seleccione el colaborador',
    },
  },
}
