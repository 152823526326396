/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, {
  ReactNode,
  useContext,
  useCallback,
  createContext,
  useState,
  useEffect,
} from 'react'
import { useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'

import api from '~/services/api'

import { useLocale } from '~/hooks/locale/useLocale'

import { ShowError } from '~/utils/errors/apiErrors'
import { translate } from '~/utils/locale'

interface Stock {
  id: string
}

interface CompanyData {
  id: string
  name: string
  logo?: string
  logo_url?: string
  deleted: boolean
  created_at: string
  updatedAt: string
  stocks: Stock[]
}

interface Contributor {
  contact: {
    name: string
  }
  id: string
}

interface Company {
  user_id: string
  company: CompanyData
  collaborator: Contributor
}

type CompanyInput = Pick<CompanyData, 'name'>

interface CompaniesProviderProps {
  children: ReactNode
}

interface CompaniesContextData {
  data: Company[]
  selectedCompany: string | undefined
  createCompany: (company: CompanyInput) => Promise<void>
  handleCurrentCompany: (company: string) => Promise<void>
  isLoading: boolean
  isFetching: boolean
  companieRefetch: () => void
  companiesData?: Company[]
  currentCompanyData?: Company
}

const CompaniesContext = createContext<CompaniesContextData>(
  {} as CompaniesContextData,
)

export function CompaniesProvider({ children }: CompaniesProviderProps) {
  const history = useHistory()
  const { locale } = useLocale()
  const [companiesData, setCompaniesData] = useState<Company[]>()
  const [currentCompanyData, setCurrentCompanyData] = useState<Company>()

  const [selectedCompany, setSelectedCompany] = useState(() => {
    const company = localStorage.getItem('@Gstor:currentCompany')

    if (company) {
      return company
    }

    return ''
  })

  const { data, isLoading, isFetching, refetch } = useQuery(
    'companies',
    async () => {
      try {
        const response = await api.get('/users/companies')
        const companies = response.data

        if (localStorage.getItem('@Gstor:currentCompany') === null) {
          localStorage.setItem('@Gstor:currentCompany', companies[0].company.id)
          localStorage.setItem(
            '@Gstor:enabledCompany',
            companies[0].company.is_enabled,
          )
          setSelectedCompany(companies[0].company.id)
        }

        localStorage.setItem(
          '@Gstor:enabledCompany',
          companies[0].company.is_enabled,
        )

        setCompaniesData(companies)
        return companies
      } catch (err: any) {
        ShowError(
          err.message,
          translate('useCompanies.getErrorMessage'),
          locale,
        )
      }
    },
    {
      staleTime: 60000 * 10, // 1 minuto * 10
    },
  )

  const handleCurrentCompany = useCallback(
    async (company) => {
      localStorage.setItem('@Gstor:currentCompany', company)

      setSelectedCompany(company)

      history.push('/dashboard')
    },
    [history],
  )

  async function createCompany(company: CompanyInput) {
    try {
      await api.post('/company', company)

      refetch()
    } catch (err: any) {
      ShowError(
        err.message,
        translate('useCompanies.createErrorMessage'),
        locale,
      )
    }
  }

  useEffect(() => {
    if (data) {
      const companyData = data.find(
        (company: Company) => company.company.id === selectedCompany,
      )
      setCurrentCompanyData(companyData)
    }
  }, [selectedCompany, data])

  return (
    <CompaniesContext.Provider
      value={{
        selectedCompany,
        data,
        createCompany,
        handleCurrentCompany,
        companieRefetch: refetch,
        isLoading,
        isFetching,
        companiesData,
        currentCompanyData,
      }}
    >
      {children}
    </CompaniesContext.Provider>
  )
}

export function useCompanies() {
  const context = useContext(CompaniesContext)

  return context
}
