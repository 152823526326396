export default {
  pt: {
    useSegments: {
      getErrorMessage: 'Erro ao visualizar segmentos.',
      createSuccessMessage: 'Segmento criado com sucesso!',
      createErrorMessage: 'Erro ao criar segmentos.',
      updateSuccessMessage: 'Segmento atualizado com sucesso!',
      updateErrorMessage: 'Erro ao atualizar segmentos.',
      deleteSuccessMessage: 'Segmento deletado com sucesso!',
      deleteErrorMessage: 'Erro ao excluir segmentos.',
    },
  },
  en: {
    useSegments: {
      getErrorMessage: 'Error viewing segments.',
      createSuccessMessage: 'Segment created successfully!',
      createErrorMessage: 'Error creating segments.',
      updateSuccessMessage: 'Segment successfully updated!',
      updateErrorMessage: 'Error updating segments.',
      deleteSuccessMessage: 'Segment successfully deleted!',
      deleteErrorMessage: 'Error deleting segments.',
    },
  },
  es: {
    useSegments: {
      getErrorMessage: 'Error al ver segmentos.',
      createSuccessMessage: '¡Segmento creado con éxito!',
      createErrorMessage: 'Error al crear segmentos.',
      updateSuccessMessage: '¡Segmento actualizado con éxito!',
      updateErrorMessage: 'Error al actualizar segmentos.',
      deleteSuccessMessage: '¡Segmento eliminado con éxito!',
      deleteErrorMessage: 'Error al eliminar segmentos.',
    },
  },
}
