export default {
  pt: {
    UsersUpdatePassword: {
      successMessage: 'Senha alterada!',
      editTooltip: 'Alterar senha',
      editButton: 'Alterar senha',
      modalTitle: 'Alterar senha',
      closeButton: 'Fechar',
      saveButton: 'Alterar',
      oldPasswordLabel: 'Senha antiga',
      oldPasswordRule: 'Por favor, informe a senha antiga',
      oldPasswordPlaceholder: 'Informe sua senha antiga',
      passwordLabel: 'Nova Senha',
      passwordRule: 'Por favor, informe a nova senha',
      passwordPlaceholder: 'Informe a nova senha',
      passwordConfirmationLabel: 'Confirme a nova Senha',
      passwordConfirmationRuleOne: 'Por favor confirme sua senha',
      passwordConfirmationRuleTwo: 'As senhas não correspondem!',
      passwordConfirmationPlaceholder: 'Informe a senha',
    },
  },
  en: {
    UsersUpdatePassword: {
      successMessage: 'Password changed!',
      editTooltip: 'Change Password',
      editButton: 'Change Password',
      modalTitle: 'Change Password',
      closeButton: 'Close',
      saveButton: 'Change',
      oldPasswordLabel: 'Old password',
      oldPasswordRule: 'Please enter the old password',
      oldPasswordPlaceholder: 'Enter your old password',
      passwordLabel: 'New Password',
      passwordRule: 'Please enter the new password',
      passwordPlaceholder: 'Enter the new password',
      passwordConfirmationLabel: 'Confirm the new password',
      passwordConfirmationRuleOne: 'Please confirm your password',
      passwordConfirmationRuleTwo: 'Passwords do not match!',
      passwordConfirmationPlaceholder: 'Enter the password',
    },
  },
  es: {
    UsersUpdatePassword: {
      successMessage: '¡Contraseña cambiada!',
      editTooltip: 'Cambiar contraseña',
      editButton: 'Cambiar contraseña',
      modalTitle: 'Cambiar contraseña',
      closeButton: 'Cerrar',
      saveButton: 'Cambiar',
      oldPasswordLabel: 'Contraseña anterior',
      oldPasswordRule: 'Por favor ingrese la contraseña anterior',
      oldPasswordPlaceholder: 'Ingrese su antigua contraseña',
      passwordLabel: 'Nueva contraseña',
      passwordRule: 'Por favor ingrese la nueva contraseña',
      passwordPlaceholder: 'Introduce la nueva contraseña',
      passwordConfirmationLabel: 'Confirmar la nueva contraseña',
      passwordConfirmationRuleOne: 'Por favor, confirme su contraseña',
      passwordConfirmationRuleTwo: '¡Las contraseñas no coinciden!',
      passwordConfirmationPlaceholder: 'Introduce la contraseña',
    },
  },
}
