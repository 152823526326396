import React, { useState } from 'react'

import { Tooltip, Button, Modal, Form, Row, Col, Select, Space } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import { useListGroup } from '~/hooks/Groups/useListGroup'
import { useUsers } from '~/hooks/Users/useUsers'
import { translate } from '~/utils/locale'
import { ContainerButton } from './styles'

interface UserGroupData {
  group_id: string
}

const AddGroup: React.FC = () => {
  const { group } = useListGroup()
  const { refetch, createUserGroup } = useUsers()

  const [isModalVisible, setIsModalVisible] = useState(false)

  async function addGroupUser(valueForm: UserGroupData) {
    try {
      await createUserGroup(valueForm.group_id)

      refetch()

      setIsModalVisible(false)
    } catch (err) {
      console.log(err)
    }
  }

  const showModal = () => {
    setIsModalVisible(true)
  }

  const closeModal = () => {
    setIsModalVisible(false)
  }

  return (
    <>
      <Tooltip title={translate('UsersGroup.groupTooltip')}>
        <Button type="primary" icon={<PlusOutlined />} onClick={showModal}>
          {translate('UsersGroup.groupButton')}
        </Button>
      </Tooltip>

      <Modal
        title={translate('UsersGroup.modalTitle')}
        open={isModalVisible}
        onOk={closeModal}
        onCancel={closeModal}
        width="600px"
        footer={[
          <Space key="buttons">
            <Button key="close" onClick={closeModal}>
              {translate('UsersGroup.closeButton')}
            </Button>
            <ContainerButton>
              <Button
                form="addGroupUser"
                key="save"
                type="primary"
                htmlType="submit"
                id="btn_create_group_to_users"
              >
                {translate('UsersGroup.addButton')}
              </Button>
            </ContainerButton>
          </Space>,
        ]}
      >
        <Form layout="vertical" id="addGroupUser" onFinish={addGroupUser}>
          <Row gutter={14}>
            <Col span={24}>
              <Form.Item
                label={translate('UsersGroup.groupLabel')}
                name="group_id"
              >
                <Select placeholder={translate('UsersGroup.groupPlaceholder')}>
                  {group?.map((g) => (
                    <Select.Option value={g.id} key={g.id}>
                      {g.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  )
}

export default AddGroup
