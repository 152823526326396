import { LoadingOutlined } from '@ant-design/icons'
import React from 'react'
import { Helmet } from 'react-helmet'
import EquipmentsTabs from '~/components/DataManipulation/Equipments/Tabs'
import { ListClientsProvider } from '~/hooks/Clients/useListClients'
import { ListContributorsProvider } from '~/hooks/Contributors/useListContributors'
import { AssociatedEquipmentsProvider } from '~/hooks/Equipments/AssociatedEquipments'
import { AttachmentProvider } from '~/hooks/Equipments/Attachments/useAttachments'
import { CategoryProvider } from '~/hooks/Equipments/Category/CategoryHooks'
import {
  EquipmentsProvider,
  useEquipments,
} from '~/hooks/Equipments/EquipmentsHooks'
import { ListTemplateProvider } from '~/hooks/Equipments/Especification/ListTemplate'
import { SpecificationsProvider } from '~/hooks/Equipments/Especification/useSpecifications'
import { TemplateProvider } from '~/hooks/Equipments/Especification/useTemplate'
import { ListEquipmentsProvider } from '~/hooks/Equipments/ListEquipmentsHooks'
import { MovementsProvider } from '~/hooks/Equipments/Movements/useMovements'
import { translate } from '~/utils/locale'

import { Container, LoadingIndicator } from './styles'

const ShowEquipments: React.FC = () => {
  const { isLoading } = useEquipments()

  return (
    <Container>
      <Helmet>
        <title>{translate('ShowEquipments.helmetTitle')}</title>
      </Helmet>
      <EquipmentsProvider>
        <ListEquipmentsProvider>
          <AssociatedEquipmentsProvider>
            <ListClientsProvider>
              <ListContributorsProvider>
                <CategoryProvider>
                  <ListTemplateProvider>
                    <MovementsProvider>
                      <SpecificationsProvider>
                        <AttachmentProvider>
                          <TemplateProvider>
                            {isLoading ? (
                              <LoadingIndicator>
                                <LoadingOutlined
                                  style={{ fontSize: 64, margin: 'auto' }}
                                  spin
                                />
                              </LoadingIndicator>
                            ) : (
                              <EquipmentsTabs />
                            )}
                          </TemplateProvider>
                        </AttachmentProvider>
                      </SpecificationsProvider>
                    </MovementsProvider>
                  </ListTemplateProvider>
                </CategoryProvider>
              </ListContributorsProvider>
            </ListClientsProvider>
          </AssociatedEquipmentsProvider>
        </ListEquipmentsProvider>
      </EquipmentsProvider>
    </Container>
  )
}

export default ShowEquipments
