/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { InboxOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { Button, Modal, Form, Input, Upload, Space } from 'antd'
import React, { useState } from 'react'

import { useAttachment } from '~/hooks/Equipments/Attachments/useAttachments'
import { translate } from '~/utils/locale'
import { ContainerButton } from '../../styles'

interface NewAttachmentData {
  type: string
  title: string
  description: string
  dragger?: File[]
  attachment: File
}

const AttachmentsCreate: React.FC = () => {
  const { createAttachment } = useAttachment()
  const [isModalVisible, setIsModalVisible] = useState(false)

  const [confirmLoading, setConfirmLoading] = useState(false)
  const [attachment, setAttachment] = useState<File>({} as File)

  const [form] = Form.useForm()

  const props = {
    maxCount: 1,
    onRemove: () => {
      setAttachment({} as File)
    },
    beforeUpload: (file: any) => {
      setAttachment(file)
      return false
    },
    attachment,
  }

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e
    }
    return e && e.fileList
  }

  function onCreateAttachment(data: NewAttachmentData) {
    try {
      setConfirmLoading(true)
      const dados = new FormData()
      const attachmentType = attachment.type.split('/')
      dados.append('attachment', attachment)
      dados.append('type', attachmentType[0])
      dados.append('title', data.title)
      dados.append('description', data.description)

      createAttachment(dados)

      form.resetFields()
      setConfirmLoading(false)

      setIsModalVisible(false)
    } catch (error) {
      setConfirmLoading(false)
    }
  }

  const showModal = () => {
    setIsModalVisible(true)
  }

  const closeModal = () => {
    form.resetFields()
    setIsModalVisible(false)
  }

  return (
    <>
      <Button type="primary" onClick={showModal} icon={<PlusCircleOutlined />}>
        {translate('EquipmentsTabsAttachmentsCreate.newDocumentButton')}
      </Button>
      <Modal
        title={translate('EquipmentsTabsAttachmentsCreate.modalTitle')}
        open={isModalVisible}
        confirmLoading={confirmLoading}
        onCancel={closeModal}
        style={{ top: 40 }}
        footer={[
          <Space key="buttons">
            <Button key="back" onClick={closeModal}>
              {translate('EquipmentsTabsSpecificationUpdate.closeButton')}
            </Button>
            <ContainerButton>
              <Button
                key="save"
                type="primary"
                form="formCreateAttachmentEquipment"
                htmlType="submit"
                id="btn_create_attachment_equipment"
              >
                {translate('EquipmentsTabsAttachmentsCreate.saveText')}
              </Button>
            </ContainerButton>
          </Space>,
        ]}
      >
        <Form
          form={form}
          onFinish={onCreateAttachment}
          id="formCreateAttachmentEquipment"
        >
          <Form.Item
            label={translate('EquipmentsTabsAttachmentsCreate.titleLabel')}
            name="title"
            labelCol={{ span: 24 }}
            style={{
              width: '100%',
            }}
            rules={[
              {
                required: true,
                message: translate('EquipmentsTabsAttachmentsCreate.titleRule'),
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={translate(
              'EquipmentsTabsAttachmentsCreate.descriptionLabel',
            )}
            name="description"
            labelCol={{ span: 24 }}
            style={{
              width: '100%',
            }}
            rules={[
              {
                required: true,
                message: translate(
                  'EquipmentsTabsAttachmentsCreate.descriptionRule',
                ),
              },
            ]}
          >
            <Input.TextArea rows={2} />
          </Form.Item>

          <Form.Item
            name="dragger"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            rules={[
              {
                required: true,
                message: translate(
                  'EquipmentsTabsAttachmentsCreate.draggerRule',
                ),
              },
            ]}
          >
            <Upload.Dragger name="files" {...props}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                {translate(
                  'EquipmentsTabsAttachmentsCreate.draggerInstructionOne',
                )}
              </p>
              <p className="ant-upload-hint">
                {translate(
                  'EquipmentsTabsAttachmentsCreate.draggerInstructionTwo',
                )}
              </p>
            </Upload.Dragger>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default AttachmentsCreate
