import React from 'react'

import { Card } from 'antd'

import ScaleTeamsAdd from '~/components/DataManipulation/Scales/ScaleTeams/ScaleTeamsAdd'
import ScaleTeamsList from '~/components/DataManipulation/Scales/ScaleTeams/ScaleTeamsList'
import { translate } from '~/utils/locale'

const ScaleTeamsForm: React.FC = () => {
  return (
    <Card
      bordered={false}
      title={<b>{translate('ScalesTeamsForm.cardTitle')}</b>}
      extra={<ScaleTeamsAdd />}
    >
      <ScaleTeamsList />
    </Card>
  )
}

export default ScaleTeamsForm
