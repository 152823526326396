export default {
  pt: {
    ConfirmCode: {
      errorMessage: 'Erro ao confirmar.',
    },
  },
  en: {
    ConfirmCode: {
      errorMessage: 'Error confirming.',
    },
  },
  es: {
    ConfirmCode: {
      errorMessage: 'Error al confirmar.',
    },
  },
}
