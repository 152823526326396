/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-underscore-dangle */
import React from 'react'

import { Tabs, Space } from 'antd'
import { PageHeader } from '@ant-design/pro-components'

import { useHistory, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { useCompanies } from '~/hooks/Companies/useCompanies'
import DetailsTask from './Details'
import { useTask } from '~/hooks/Tasks/useTask'
import { useLocale } from '~/hooks/locale/useLocale'
import UpdateTask from '../Update'
import EquipmentsTask from './Equipments'
import ReportTask from './Report'
import PrintButton from '../Print/PrintButton'
import TaskStatus from './Status'
import api from '~/services/api'
import { ShowError } from '~/utils/errors/apiErrors'
import { translate } from '~/utils/locale'
import HistoricTask from './Historic'
import FormsTask from './Forms'
import { TaskFormsProvider } from '~/hooks/Tasks/Forms/useTaskForms'

import FinancialTask from './Financial'

const TasksTab: React.FC = () => {
  const { selectedCompany } = useCompanies()
  const { isEdit, task } = useTask()
  const { locale } = useLocale()

  const { TabPane } = Tabs

  const { taskId, tab } = useParams<{ taskId: string; tab: string }>()

  const history = useHistory()

  const handleTabChange = (key: string) => {
    if (key === 'detalhes') {
      history.replace({
        pathname: `/tarefas/visualizar/${taskId}`,
      })
    } else {
      history.replace({
        pathname: `/tarefas/visualizar/${taskId}/${key}`,
      })
    }
  }

  const equipments = task?.equipments.map((a) => {
    return a.id
  })

  const filterEquipments = equipments?.join()

  const { data: taskEquipment, isFetching } = useQuery(
    [
      `Equipamentos da Tarefa${selectedCompany}`,
      selectedCompany,
      task?.equipments,
    ],

    async () => {
      if (filterEquipments) {
        try {
          const response = await api.get(
            `/company/${selectedCompany}/equipments`,
            {
              params: {
                equipments_id: filterEquipments,
              },
            },
          )

          const { data } = response

          return data.results
        } catch (err: any) {
          ShowError(err.message, translate('TasksTabs.errorMessage'), locale)
        }
      }
      return []
    },
  )

  return (
    <>
      <PageHeader
        className="site-page-header"
        onBack={() => window.history.back()}
        title={
          !isEdit
            ? translate('TasksTabs.pageHeaderView')
            : translate('TasksTabs.pageHeaderEdit')
        }
        subTitle={task?.internal_code}
      />
      <Tabs
        onTabClick={(key: string) => handleTabChange(key)}
        defaultActiveKey={tab ? `${tab}` : 'detalhes'}
        tabBarExtraContent={
          <Space size="large">
            <Space size="small">
              <PrintButton taskEquipment={taskEquipment} />
            </Space>
          </Space>
        }
        style={{ paddingLeft: 20, paddingRight: 20 }}
      >
        <TabPane tab={translate('TasksTabs.detailsTab')} key="detalhes">
          {!isEdit ? <DetailsTask /> : <UpdateTask />}
        </TabPane>
        <TabPane tab="Valores Acordados" key="financial">
          <FinancialTask />
        </TabPane>
        <TabPane tab={'Formulários'} key="formularios">
          {task && (
            <TaskFormsProvider>
              <FormsTask />
            </TaskFormsProvider>
          )}
        </TabPane>
        <TabPane tab={translate('TasksTabs.equipmentsTab')} key="equipamentos">
          <EquipmentsTask
            isFetching={isFetching}
            taskEquipment={taskEquipment}
          />
        </TabPane>
        <TabPane tab={translate('TasksTabs.reportsTab')} key="relatos">
          <ReportTask />
        </TabPane>
        <TabPane tab={translate('TasksTabs.statusTab')} key="status">
          <TaskStatus />
        </TabPane>
        <TabPane tab="Histórico" key="historic">
          <HistoricTask />
        </TabPane>
      </Tabs>
    </>
  )
}

export default TasksTab
