/* eslint-disable no-underscore-dangle */
import { ColumnsType } from 'antd/lib/table'
import React from 'react'

import { Button, Popconfirm, Space, Table, Tooltip } from 'antd'
import { DeleteFilled, LoadingOutlined } from '@ant-design/icons'

import { translate } from '~/utils/locale'
import { useBadge } from '~/hooks/Badge/useBadge'
import ViewBadge from '../Modal'

// import { Container } from './styles';

interface BadgeProps {
  description: string
  name: string
  _id: string
  colorBackground?: string
  colorCover?: string
  colorStrongText?: string
  colorText?: string
  template: string
}

const BadgeTable: React.FC = () => {
  const { badge, isFetching, deleteBadge } = useBadge()

  const columns: ColumnsType<BadgeProps> = [
    {
      title: translate('TasksRegistrationPauseTable.reasonColumn'),
      key: 'name',
      dataIndex: 'name',
    },
    {
      title: translate('TasksRegistrationPauseTable.descriptionColumn'),
      align: 'left',
      dataIndex: 'description',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: translate('TasksRegistrationPauseTable.actionsColumn'),
      align: 'center',
      key: 'remove',
      render: (record) => (
        <Space size="middle">
          <ViewBadge data={record} />
          <Popconfirm
            title={translate('BadgeComponent.deletePopconfirm')}
            onConfirm={() => deleteBadge(record._id)}
          >
            <Tooltip
              title={translate('TasksRegistrationPauseTable.deleteTooltip')}
            >
              <Button ghost danger shape="circle" icon={<DeleteFilled />} />
            </Tooltip>
          </Popconfirm>
        </Space>
      ),
    },
  ]

  return (
    <>
      <Table
        rowKey={(record) => record._id}
        loading={{
          indicator: <LoadingOutlined />,
          spinning: isFetching,
          size: 'large',
        }}
        scroll={{ x: 1000 }}
        columns={columns}
        dataSource={badge}
        pagination={{
          showTotal: (total, range) => `${range[0]} - ${range[1]} / ${total}`,
        }}
      />
    </>
  )
}

export default BadgeTable
