export default {
  pt: {
    EquipmentsTabs: {
      viewPageheader: 'Visualizar Equipamento',
      editPageheader: 'Editar Equipamento',
      statusText: 'Status:',
      activeText: 'Ativo',
      inactiveText: 'Inativo',
      detailsTab: 'Detalhes',
      specificationsTab: 'Especificações',
      docsTab: 'Documentos/Anexos',
      movementsTab: 'Movimentações',
    },
  },
  en: {
    EquipmentsTabs: {
      viewPageheader: 'View Equipment',
      editPageheader: 'Edit Equipment',
      statusText: 'Status:',
      activeText: 'Active',
      inactiveText: 'Inactive',
      detailsTab: 'Details',
      specificationsTab: 'Specifications',
      docsTab: 'Documents/Attachments',
      movementsTab: 'Movements',
    },
  },
  es: {
    EquipmentsTabs: {
      viewPageheader: 'Ver Equipo',
      editPageheader: 'Editar equipo',
      statusText: 'Status:',
      activeText: 'Activo',
      inactiveText: 'Inactivo',
      detailsTab: 'Detalles',
      specificationsTab: 'Especificaciones',
      docsTab: 'Documentos/Archivos adjuntos',
      movementsTab: 'Movimientos',
    },
  },
}
