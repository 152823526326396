/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Col,
  DatePicker,
  DatePickerProps,
  Form,
  Input,
  Row,
  Select,
  Space,
  Tooltip,
  Tour,
  Typography,
} from 'antd'

import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { CheckCircleFilled, EditFilled } from '@ant-design/icons'
import HelpTooltip from '~/components/DataManipulation/Misc/HelpTooltip'
import { useTaskCreate } from '~/hooks/Tasks/Create/createTask'
import { translate } from '~/utils/locale'
import ClientModalTask from '../Modal/Client'

import TaskTypeModalTask from '../Modal/TaskType'
import { useTaskTour } from '~/hooks/Tour/useTaskTour'
import { useTour } from '~/hooks/Tour/useTour'
import TimeInput from '~/components/DataManipulation/Misc/TimeInput'

interface DetailsCreateTaskProps {
  form: any
}

const DetailsCreateTask: React.FC<DetailsCreateTaskProps> = ({ form }) => {
  const {
    setDateTask,
    clientData,
    scaleId,
    taskTypeData,
    description,
    setDescription,
    note,
    setNote,
    duration,
    setDuration,
    setPriority,
    externalCode,
    setExternalCode,
  } = useTaskCreate()

  const {
    createClientTaskRef,
    createDateTaskRef,
    createTaskTypeTaskRef,
    createDurationTaskRef,
    createPriorityTaskRef,
    createTaskSteps,
    openCreateTask,
    setOpenCreateTask,
  } = useTaskTour()

  const [isEdit, setIsEdit] = useState(false)

  const handleTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDuration(e.target.value)
  }

  const { setIsTour } = useTour()

  function onClose() {
    setIsTour(false)
    setOpenCreateTask(false)
  }

  function getValueNoteInput(value: string) {
    localStorage.setItem('@Gstor:noteTask', value)
  }

  const noteData = localStorage.getItem('@Gstor:noteTask')

  const { Option } = Select

  function handleChangeEditButton() {
    if (isEdit) {
      setIsEdit(false)
    } else {
      setIsEdit(true)
    }
  }

  const onChange: DatePickerProps['onChange'] = (date) => {
    setDateTask(dayjs(date).format('YYYY-MM-DDTHH:mm:ss.018Z'))
  }

  useEffect(() => {
    if (clientData?.id) {
      form.setFieldsValue({ customer_id: clientData?.name })
    }

    if (taskTypeData?.id) {
      form.setFieldsValue({
        task_type_id: taskTypeData?.title,
        duration_time: taskTypeData?.duration,
        description: taskTypeData?.description,
      })

      setDuration(taskTypeData?.duration)
      setDescription(taskTypeData?.description)
      setExternalCode(form.getFieldValue('external_code'))
    }

    if (clientData?.id) {
      form.setFieldsValue({
        value: clientData?.valueTask
          ? clientData?.valueTask
          : taskTypeData?.valueTask,
        amount_hours: clientData.amountHours,
        amount_hours_additional: clientData.amountHoursAdditional,
        number_km: clientData?.numberKm,
        number_km_additional: clientData?.numberKmAdditional,
      })
    }
  }, [
    form,
    clientData,
    scaleId,
    taskTypeData,
    setDuration,
    setDescription,
    setExternalCode,
  ])

  return (
    <>
      <Row gutter={14}>
        <Col span={12} ref={openCreateTask ? createClientTaskRef : null}>
          <Form.Item
            label={
              <Space>
                <p>{translate('TasksCreateDetails.clientLabel')}</p>

                {clientData?.id ? <ClientModalTask /> : ''}
              </Space>
            }
            name="customer_id"
            rules={[
              {
                required: true,
                message: translate('TasksCreateDetails.clientRule'),
              },
            ]}
          >
            {clientData?.id ? <Input /> : <ClientModalTask />}
          </Form.Item>
        </Col>

        <Col span={12} ref={createDateTaskRef}>
          <Form.Item
            label={
              <Space>
                <p>{translate('TasksCreateDetails.dateLabel')}</p>

                <HelpTooltip
                  title={translate('TasksCreateDetails.dateHelpTooltip')}
                />
              </Space>
            }
            name="initialDate"
            rules={[
              {
                required: true,
                message: translate('TasksCreateDetails.dateRule'),
              },
            ]}
          >
            <DatePicker
              onChange={onChange}
              format="DD/MM/YYYY HH:mm"
              placeholder={translate('TasksFilter.fromDatePlaceholder')}
              style={{ width: '100%' }}
              showTime={{ format: 'HH:mm' }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={14}>
        <Col span={12} ref={createTaskTypeTaskRef}>
          <Form.Item
            label={
              <Space>
                <p>{translate('TasksCreateDetails.taskTypeLabel')}</p>
                {taskTypeData?.id ? <TaskTypeModalTask /> : ''}
              </Space>
            }
            name="task_type_id"
            rules={[
              {
                required: true,
                message: translate('TasksCreateDetails.taskTypeRule'),
              },
            ]}
          >
            {taskTypeData?.id ? <Input /> : <TaskTypeModalTask />}
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            label={
              <Space>
                <Typography.Text>
                  {translate('TasksCreateDetails.externalCodeLabel')}
                </Typography.Text>
                <HelpTooltip
                  title={translate('TasksCreateDetails.externalCodeHelp')}
                />
              </Space>
            }
            name="external_code"
          >
            <Input
              placeholder={translate(
                'TasksCreateDetails.externalCodePlaceholder',
              )}
              value={externalCode}
              onChange={(e) => setExternalCode(e.target.value)}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={14}>
        <Col span={12} ref={createDurationTaskRef}>
          <Form.Item
            label={
              <Space>
                {translate('TasksCreateDetails.durationLabel')}
                <HelpTooltip
                  title={translate('TasksCreateDetails.durationHelpTooltip')}
                />
              </Space>
            }
            name="duration_time"
          >
            <TimeInput
              value={duration}
              onChange={handleTimeChange}
              placeholder="HH:mm:ss"
            />
          </Form.Item>
        </Col>
        <Col span={12} ref={createPriorityTaskRef}>
          <Form.Item
            label={translate('TasksCreateDetails.priorityLabel')}
            name="priority"
          >
            <Select
              placeholder={translate('TasksCreateDetails.priorityPlaceholder')}
              onChange={(value) => setPriority(value)}
            >
              <Option value="high">
                {translate('TasksCreateDetails.priorityHigh')}
              </Option>
              <Option value="medium">
                {translate('TasksCreateDetails.priorityMedium')}
              </Option>
              <Option value="low">
                {translate('TasksCreateDetails.priorityLow')}
              </Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={14}>
        <Col span={12}>
          <Form.Item
            label={translate('TasksCreateDetails.descriptionLabel')}
            name="description"
          >
            <Input.TextArea
              placeholder={translate(
                'TasksCreateDetails.descriptionPlaceholder',
              )}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={
              <Space>
                <>
                  {isEdit ? (
                    <Input
                      size="small"
                      onChange={(e) => getValueNoteInput(e.target.value)}
                    />
                  ) : (
                    <>
                      {noteData ? (
                        <>{noteData}</>
                      ) : (
                        <>{translate('TasksCreateDetails.noteLabel')}</>
                      )}
                    </>
                  )}
                </>
                <Tooltip
                  title={
                    !isEdit ? (
                      <>{translate('TasksCreateDetails.noteAddTooltip')}</>
                    ) : (
                      <>{translate('TasksCreateDetails.noteSaveTooltip')}</>
                    )
                  }
                >
                  {!isEdit ? (
                    <EditFilled
                      style={{ color: '#3C8ACB', cursor: 'pointer' }}
                      onClick={handleChangeEditButton}
                    />
                  ) : (
                    <CheckCircleFilled
                      style={{ color: '#3C8ACB', cursor: 'pointer' }}
                      onClick={handleChangeEditButton}
                    />
                  )}
                </Tooltip>
              </Space>
            }
            name="note"
          >
            <Input.TextArea
              placeholder={translate('TasksCreateDetails.notePlaceholder')}
              value={note}
              onChange={(e) => setNote(e.target.value)}
            />
          </Form.Item>
        </Col>
      </Row>

      <Tour
        open={openCreateTask}
        onClose={onClose}
        steps={createTaskSteps}
        indicatorsRender={(current, total) => (
          <span>
            {current + 1} / {total}
          </span>
        )}
      />
    </>
  )
}

export default DetailsCreateTask
