import { LoadingOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { Card, Space, Button } from 'antd'
import Text from 'antd/lib/typography/Text'
import React from 'react'
import { Link } from 'react-router-dom'
import { translate } from '~/utils/locale'
import FormFilterModal from './Filter'
import FormsTable from './Table'

import { useTaskForms } from '~/hooks/Tasks/Forms/useTaskForms'

const FormsBoard: React.FC = () => {
  const { isLoading, isFetching, forms, formsTotal } = useTaskForms()

  if (!forms || isLoading) {
    return <Card title={<LoadingOutlined spin />} />
  }

  return (
    <Card
      title={
        <Space size="large">
          <b>{translate('TasksRegistrationForms.cardTitle')}</b>
          <Text type="secondary" style={{ fontWeight: 'normal', fontSize: 14 }}>
            {formsTotal === 1
              ? `${translate(
                  'TasksRegistrationForms.totalText',
                )} ${formsTotal} ${translate(
                  'TasksRegistrationForms.formText',
                )}`
              : `${translate(
                  'TasksRegistrationForms.totalText',
                )} ${formsTotal} ${translate(
                  'TasksRegistrationForms.formText',
                )}`}
          </Text>
          {!isLoading && isFetching && <LoadingOutlined spin />}
        </Space>
      }
      extra={
        <Space size="small">
          <FormFilterModal />
          <Link to="/formularios/novo">
            <Button type="primary" icon={<PlusCircleOutlined />}>
              {translate('TasksRegistrationForms.addForm')}
            </Button>
          </Link>
        </Space>
      }
      bordered={false}
      style={{ width: '100%', padding: 30 }}
    >
      <FormsTable />
    </Card>
  )
}

export default FormsBoard
