export default {
  pt: {
    TasksOptionsKanbanCards: {
      dateString: 'às',
      viewTooltip: 'Ver tarefa',
      responsibleText: 'Colaborador:',
      clientText: 'Cliente:',
      taskTypeText: 'Tipo de tarefa:',
      durationText: 'Duração: ',
      checkinText: 'Tipo de Checkin: ',
      priorityText: 'Prioridade:',
      priorityLow: 'Baixa',
      priorityMedium: 'Média',
      priorityHigh: 'Alta',
      statusText: 'Status:',
      statusActive: 'Ativo',
      statusInactive: 'Inativo',
      externalCode: 'Código externo:',
      addressPanel: 'Endereço',
      zipcodeText: 'CEP: ',
      neighborhoodText: 'Bairro: ',
      streetText: 'Logradouro: ',
      cityText: 'Cidade: ',
      noTextResponsible: 'Nenhum responsável foi fornecido.',
      noTextCustomer: 'Nenhum cliente foi fornecido.',
      noTextTeam: 'Nenhuma equipe foi fornecida.',
    },
  },
  en: {
    TasksOptionsKanbanCards: {
      dateString: 'at',
      viewTooltip: 'View task',
      responsibleText: 'Collaborator:',
      clientText: 'Customer:',
      taskTypeText: 'Task Type:',
      durationText: 'Duration: ',
      checkinText: 'Checkin Type: ',
      priorityText: 'Priority:',
      priorityLow: 'Low',
      priorityMedium: 'Medium',
      priorityHigh: 'High',
      statusText: 'Status:',
      statusActive: 'Active',
      statusInactive: 'Inactive',
      externalCode: 'External Code:',
      addressPanel: 'Address',
      zipcodeText: 'Zipcode: ',
      neighborhoodText: 'Neighborhood: ',
      streetText: 'Street: ',
      cityText: 'City: ',
      noTextResponsible: 'No guardian were provided.',
      noTextCustomer: 'No clients were provided.',
      noTextTeam: 'No team were provided.',
    },
  },
  es: {
    TasksOptionsKanbanCards: {
      dateString: 'a las',
      viewTooltip: 'Ver tarea',
      responsibleText: 'Colaborador:',
      clientText: 'Cliente:',
      taskTypeText: 'Tipo de tarea:',
      durationText: 'Duración: ',
      checkinText: 'Tipo de entrada: ',
      priorityText: 'Prioridad:',
      priorityLow: 'Baja',
      priorityMedium: 'Media',
      priorityHigh: 'Alta',
      statusText: 'Status:',
      statusActive: 'Activo',
      statusInactive: 'Inactivo',
      externalCode: 'Código externo:',
      addressPanel: 'Dirección',
      zipcodeText: 'Código postal: ',
      neighborhoodText: 'Barrio: ',
      streetText: 'Calle: ',
      cityText: 'Ciudad: ',
      noTextResponsible: 'No se proporcionó ningún tutor.',
      noTextCustomer: 'No se proporcionaron clientes.',
      noTextTeam: 'No se proporcionó ningún equipo.',
    },
  },
}
