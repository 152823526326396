export default {
  pt: {
    TaskType: {
      helmetTitle: 'Tipos de Tarefa',
    },
  },
  en: {
    TaskType: {
      helmetTitle: 'Task Types',
    },
  },
  es: {
    TaskType: {
      helmetTitle: 'Tipos de Tareas',
    },
  },
}
