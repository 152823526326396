/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  useContext,
  createContext,
  useState,
  useCallback,
  useEffect,
  Dispatch,
  SetStateAction,
} from 'react'
import { useHistory } from 'react-router-dom'
import { useQuery } from 'react-query'

import { message } from 'antd'

import api from '~/services/api'

import { useCompanies } from '~/hooks/Companies/useCompanies'
import { useLocale } from '~/hooks/locale/useLocale'

import { ShowError } from '~/utils/errors/apiErrors'
import { translate } from '~/utils/locale'

interface ContactProps {
  contact: {
    name: string
    id: number
    email: string
    phone: string
  }
  customer: {
    contact: {
      id: string
      name: string
    }
  }
  office: string
  is_responsible: boolean
  id: string
}

interface FilterOptionsProps {
  contact?: string
  office?: string
  is_responsible?: boolean
}

interface ClientsContactsContextData {
  listContacts?: ContactProps[]
  createContacts(
    customerId?: string,
    dataContact?: object,
    keepRegister?: boolean,
  ): void
  updateContact(
    dataContact?: object,
    contactId?: string,
    customerId?: string,
  ): void
  deleteContact(contactId?: string, customerId?: string): void
  isLoading: boolean
  refetch: () => void
  isFetching: boolean
  filterOptions: FilterOptionsProps
  handleUpdateFilters: (newFilterOptions: any) => void
  handleResetFilters: () => void
  clientContactTotal?: number
  setPageSize: Dispatch<SetStateAction<number>>
  setNumberPage: Dispatch<SetStateAction<number>>
}

const ClientsContactsContext = createContext<ClientsContactsContextData>(
  {} as ClientsContactsContextData,
)

export const ClientsContactsProvider: React.FC = ({ children }) => {
  const { selectedCompany } = useCompanies()
  const { locale } = useLocale()

  const [pageSize, setPageSize] = useState(10)
  const [numberPage, setNumberPage] = useState(1)

  const history = useHistory()

  const [clientContactTotal, setClientContactTotal] = useState<number>()

  const [filterOptions, setFilterOptions] = useState<FilterOptionsProps>(() => {
    const filter = localStorage.getItem('@Gstor:contactsFilterOptions')

    if (filter) {
      return JSON.parse(filter)
    }

    return {} as FilterOptionsProps
  })

  const {
    data: listContacts,
    isLoading,
    refetch,
    isFetching,
  } = useQuery(
    [
      `Lista de Contatos de Clientes${selectedCompany}`,
      selectedCompany,
      filterOptions,
      pageSize,
      numberPage,
    ],

    async () => {
      try {
        if (!selectedCompany) {
          return undefined
        }

        const paginationLimit = numberPage * pageSize
        const paginationOffset = paginationLimit - pageSize

        const response = await api.get(
          `/company/${selectedCompany}/customers-contacts`,
          {
            params: {
              contact: filterOptions?.contact,
              office: filterOptions?.office,
              is_responsible: filterOptions?.is_responsible,
              limit: paginationLimit,
              offset: paginationOffset,
            },
          },
        )

        const { data } = response

        setClientContactTotal(response.data.total)

        return data.results
      } catch (err: any) {
        ShowError(
          err.message,
          translate('useContactClients.getErrorMessage'),
          locale,
        )
      }
    },
    {
      staleTime: 5000,
    },
  )

  async function createContacts(
    customerId: string,
    dataContact: object,
    keepRegister: boolean,
  ) {
    try {
      await api.post(
        `/company/${selectedCompany}/customer/${customerId}/customer-contact`,
        dataContact,
      )

      refetch()
      message.success(translate('useContactClients.createSuccessMessage'))

      if (!keepRegister) {
        history.push(`/contatosCliente`)
      }
    } catch (err: any) {
      ShowError(
        err.message,
        translate('useContactClients.createErrorMessage'),
        locale,
      )
    }
  }

  async function updateContact(
    dataContact: object,
    contactId: string,
    customerId: string,
  ) {
    try {
      await api.put(
        `/company/${selectedCompany}/customer/${customerId}/customer-contact/${contactId}`,
        dataContact,
      )

      refetch()
      message.success(translate('useContactClients.updateSuccessMessage'))
    } catch (err: any) {
      ShowError(
        err.message,
        translate('useContactClients.updateErrorMessage'),
        locale,
      )
    }
  }

  async function deleteContact(contactId: string, customerId: string) {
    try {
      await api.delete(
        `/company/${selectedCompany}/customer/${customerId}/customer-contact/${contactId}`,
      )

      refetch()
      message.success(translate('useContactClients.deleteSuccessMessage'))
    } catch (err: any) {
      ShowError(
        err.message,
        translate('useContactClients.deleteErrorMessage'),
        locale,
      )
    }
  }

  const handleUpdateFilters = useCallback((newFilterOptions) => {
    const newFilters = {
      contact: newFilterOptions?.contact,
      office: newFilterOptions?.office,
      is_responsible: newFilterOptions?.is_responsible,
    }
    setFilterOptions(newFilters)
    localStorage.setItem(
      '@Gstor:contactsFilterOptions',
      JSON.stringify(newFilters),
    )
  }, [])

  const handleResetFilters = useCallback(() => {
    localStorage.removeItem('@Gstor:contactsFilterOptions')
    setFilterOptions({})
  }, [])

  useEffect(() => {
    refetch()
  }, [selectedCompany, refetch])

  return (
    <ClientsContactsContext.Provider
      value={{
        listContacts,
        createContacts,
        updateContact,
        deleteContact,
        isLoading,
        refetch,
        isFetching,
        filterOptions,
        handleUpdateFilters,
        handleResetFilters,
        clientContactTotal,
        setNumberPage,
        setPageSize,
      }}
    >
      {children}
    </ClientsContactsContext.Provider>
  )
}

export function useClientsContacts(): ClientsContactsContextData {
  const context = useContext(ClientsContactsContext)

  if (!context) {
    throw new Error('useAuth must be used within an AutoProvider')
  }

  return context
}
