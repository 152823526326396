/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, createContext } from 'react'
import { message } from 'antd'

import api from '~/services/api'

import { useCompanies } from '~/hooks/Companies/useCompanies'
import { useGroup } from '~/hooks/Groups/useGroup'
import { useLocale } from '~/hooks/locale/useLocale'

import { ShowError } from '~/utils/errors/apiErrors'
import { translate } from '~/utils/locale'

interface PermissionContextData {
  createPermission(
    moduleId: string | undefined,
    groupId: string | undefined,
    valuePermission: object,
  ): void
  updatePermission(
    moduleId: string | undefined,
    groupId: string | undefined,
    valuePermission: object,
    permissionId: string | undefined,
  ): void
  deletePermission(permissionId: string | undefined): void
}

const PermissionContext = createContext<PermissionContextData>(
  {} as PermissionContextData,
)

export const PermissionProvider: React.FC = ({ children }) => {
  const { selectedCompany } = useCompanies()
  const { locale } = useLocale()
  const { refetch } = useGroup()

  async function createPermission(
    moduleId: string,
    groupId: string,
    valuePermission: object,
  ) {
    try {
      await api.post(
        `/company/${selectedCompany}/module/${moduleId}/group/${groupId}/permission`,
        valuePermission,
      )

      refetch()
      message.success(translate('usePermission.createSuccessMessage'))
    } catch (err: any) {
      ShowError(
        err.message,
        translate('usePermission.createErrorMessage'),
        locale,
      )
    }
  }

  async function updatePermission(
    moduleId: string,
    groupId: string,
    valuePermission: object,
    permissionId: string,
  ) {
    try {
      await api.put(
        `/company/${selectedCompany}/module/${moduleId}/group/${groupId}/permission/${permissionId}`,
        valuePermission,
      )

      refetch()
    } catch (err: any) {
      ShowError(
        err.message,
        translate('usePermission.updateErrorMessage'),
        locale,
      )
    }
  }

  async function deletePermission(permissionId: string) {
    try {
      message.loading(translate('usePermission.deleteLoadingMessage'))
      await api.delete(`/company/${selectedCompany}/permission/${permissionId}`)

      refetch()
      message.success(translate('usePermission.deleteSuccessMessage'))
    } catch (err: any) {
      ShowError(
        err.message,
        translate('usePermission.deleteErrorMessage'),
        locale,
      )
    }
  }

  return (
    <PermissionContext.Provider
      value={{
        createPermission,
        updatePermission,
        deletePermission,
      }}
    >
      {children}
    </PermissionContext.Provider>
  )
}

export function usePermission(): PermissionContextData {
  const context = useContext(PermissionContext)

  if (!context) {
    throw new Error('useAuth must be used within an AutoProvider')
  }

  return context
}
