import { LoadingOutlined } from '@ant-design/icons'
import { Card, Space } from 'antd'
import Text from 'antd/lib/typography/Text'
import React from 'react'
import { useTeams } from '~/hooks/Teams/useListTeams'
import { translate } from '~/utils/locale'
import CreateTeam from './Create'
import TeamsTable from './Table'

// import { Container } from './styles';

const TeamsBoard: React.FC = () => {
  const { isFetching, isLoading, teamsTotal } = useTeams()

  return (
    <Card
      title={
        <Space size="large">
          <b>{translate('TeamsComponent.cardTitle')}</b>
          {isLoading ? (
            <LoadingOutlined spin />
          ) : (
            <Text
              type="secondary"
              style={{ fontWeight: 'normal', fontSize: 14 }}
            >
              {teamsTotal !== undefined ? (
                <>
                  {teamsTotal === 1
                    ? `${translate(
                        'TeamsComponent.totalText',
                      )} ${teamsTotal} ${translate('TeamsComponent.teamText')}`
                    : `${translate(
                        'TeamsComponent.totalText',
                      )} ${teamsTotal} ${translate(
                        'TeamsComponent.teamsText',
                      )}`}
                </>
              ) : (
                ''
              )}
            </Text>
          )}
          {!isLoading && isFetching && <LoadingOutlined spin />}
        </Space>
      }
      extra={<CreateTeam />}
      bordered={false}
      style={{ width: '100%', padding: 30 }}
    >
      <TeamsTable />
    </Card>
  )
}

export default TeamsBoard
