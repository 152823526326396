export default {
  pt: {
    TeamsComponentTabFinancial: {
      tabTitle: 'Financeiro',

      undefinedText: 'Nenhum valor foi fornecido.',

      cardTitle:
        'Financeiro - Valores acordados a pagar para os fornecedores/prestadores de serviço',
      cardTitleTop: 'Dados de Pagamento',
      valueText: 'Valor a pagar',
      dayText: 'Dia de Pagamento',
      hoursText: 'Quantidade de Horas',
      valueHoursText: 'Valor Hora Adicional',
      kmText: 'Quantidade de Km',
      kmValueText: 'Valor Km Adicional',
      conditionsText: 'Condições de Pagamento',
      viewText: 'Á vista',
      parcelText: 'Parcelado',
      depositText: 'Depósito em conta',
      ticketText: 'Boleto Bancário',

      methodText: 'Método de Pagamento',

      cardTitleBottom: 'Dados Bancários',
      numberBankText: 'Número do Banco',
      nameBankText: 'Nome do Banco',
      typePersonAccountText: 'Conta PF ou PJ',
      agencyText: 'Agência',
      accountText: 'Conta / Poupança',
      typeAccountText: 'Tipo de Conta',
      pixText: 'Chave pix',

      currentText: 'Corrente',
      savingsText: 'Poupança',
      physicalText: 'Pessoa Física',
      juridicText: 'Pessoa Jurídica',

      hoursLabel: 'Horas',
      buttonEdit: 'Editar',
    },
  },
  en: {
    TeamsComponentTabFinancial: {
      tabTitle: 'Financial',

      undefinedText: 'No value was provided.',

      cardTitle:
        'Financial - Amounts agreed to be paid to suppliers/service providers',
      cardTitleTop: 'Payment Data',
      valueText: 'Amount to pay',
      dayText: 'Payday',
      hoursText: 'Number of hours',
      valueHoursText: 'Additional Hourly Value',
      kmText: 'Quantity of km',
      kmValueText: 'Additional KM Value',
      conditionsText: 'Payment Terms',
      methodText: 'Payment Method',
      viewText: 'View',
      parcelText: 'Parcel',
      depositText: 'Account deposit',
      ticketText: 'Bank Slip',

      cardTitleBottom: 'Bank Details',
      numberBankText: 'Bank Number',
      nameBankText: 'Bank Name',
      typePersonAccountText: 'PF or corporate account',
      agencyText: 'Agency',
      accountText: 'Account / Savings',
      typeAccountText: 'Account Type',
      pixText: 'Pix key',

      currentText: 'Current',
      savingsText: 'Savings',
      physicalText: 'Individual',
      juridicText: 'Legal Entity',

      hoursLabel: 'Hours',
      buttonEdit: 'Edit',
    },
  },
  es: {
    TeamsComponentTabFinancial: {
      tabTitle: 'Financiero',

      undefinedText: 'No se proporcionó ningún valor.',

      cardTitle:
        'Financiero - Montos acordados a pagar a proveedores/proveedores de servicios',
      cardTitleTop: 'Datos de pago',
      valueText: 'Cantidad a pagar',
      dayText: 'Día de paga',
      hoursText: 'Número de horas',
      valueHoursText: 'Valor Hora Adicional',
      kmText: 'Cantidad de KM',
      kmValueText: 'Valor KM Adicional',
      conditionsText: 'Condiciones de pago',
      methodText: 'Método de pago',
      viewText: 'Conocimiento',
      parcelText: 'Cuotas',
      depositText: 'Depósito en cuenta',
      ticketText: 'Recibo bancario',

      cardTitleBottom: 'Detalles bancarios',
      numberBankText: 'Número de banco',
      nameBankText: 'Nombre del banco',
      typePersonAccountText: 'PF o cuenta corporativa',
      agencyText: 'Agencia',
      accountText: 'Cuenta / Ahorros',
      typeAccountText: 'Tipo de cuenta',
      pixText: 'Clave de foto',

      currentText: 'Actual',
      savingsText: 'Ahorros',
      physicalText: 'Individual',
      juridicText: 'Entidad Legal',

      hoursLabel: 'Horas',
      buttonEdit: 'Editar',
    },
  },
}
