import styled, { css } from 'styled-components'
import Tooltip from '~/components/Login/Tooltip'

interface ContainerProps {
  isFocused: boolean
  isFilled: boolean
  isErrored: boolean
}

export const Container = styled.div<ContainerProps>`
  background: transparent;
  border: 1px solid #cdcdcd;
  border-radius: 5px;
  padding: 12px;
  width: 100%;
  color: #afafaf;
  display: flex;
  align-items: center;

  & + div {
    margin-top: 10px;
  }

  .password-icon {
    margin-left: 20px;
    cursor: pointer;
  }

  ${(props) =>
    props.isErrored &&
    css`
      border: 1px solid var(--color-danger);
    `}

  ${(props) =>
    props.isFocused &&
    css`
      color: var(--secondary-color);
      border: 1px solid var(--secondary-color);
    `}
  ${(props) =>
    props.isFilled &&
    css`
      color: var(--secondary-color);
    `}
  input {
    flex: 1;
    background: transparent;
    border: 0;
    color: var(--secondary-initial-text);
    &::placeholder {
      color: var(--secondary-initial-text);
    }
  }

  input:invalid {
    border-color: black;
    box-shadow: none;
  }

  svg {
    margin-right: 8px;
  }

  @media screen and (max-width: 360px) {
    padding: 12px;
    svg {
      margin-right: 12px;
    }
  }

  @media screen and (max-width: 328px) {
    padding: 10px;
    svg {
      margin-right: 10px;
    }
  }

  @media screen and (max-width: 320px) {
    padding: 8px;
    svg {
      margin-right: 8px;
      width: 16px;
    }
  }
`

export const Error = styled(Tooltip)`
  height: 20px;
  margin-left: 16px;
  svg {
    margin: 0;
  }
  span {
    background: var(--color-danger);
    color: var(--color-white);
    &::before {
      border-color: var(--color-danger) transparent;
    }
  }
`
