import React from 'react'
import { Helmet } from 'react-helmet'

import DashboardContributorsGraphs from '~/components/DataManipulation/Dashboard/Contributors'
import { DashboardProvider } from '~/hooks/Dashboard/useDashboard'
import { ListStatusProvider } from '~/hooks/Status/useListStatus'

import { translate } from '~/utils/locale'

import { Container } from './styles'
import { TaskListProvider } from '~/hooks/Tasks/useListTasks'
import { TaskTypeProvider } from '~/hooks/Tasks/TaskType/useTaskType'
import { ListClientsProvider } from '~/hooks/Clients/useListClients'

const DashboardContributors: React.FC = () => {
  return (
    <Container>
      <Helmet>
        <title>{translate('Dashboard.helmetTitle')}</title>
      </Helmet>

      <DashboardProvider>
        <ListStatusProvider>
          <ListClientsProvider>
            <TaskListProvider>
              <TaskTypeProvider>
                <DashboardContributorsGraphs />
              </TaskTypeProvider>
            </TaskListProvider>
          </ListClientsProvider>
        </ListStatusProvider>
      </DashboardProvider>
    </Container>
  )
}

export default DashboardContributors
