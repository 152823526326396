/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/require-default-props */
import React, { useCallback, useState } from 'react'

import {
  Modal,
  Form,
  Input,
  Button,
  Tooltip,
  message,
  Checkbox,
  Space,
} from 'antd'
import { EditOutlined, PlusCircleOutlined } from '@ant-design/icons'

import api from '~/services/api'

import { useCompanies } from '~/hooks/Companies/useCompanies'
import { useSkills } from '~/hooks/Skills/useSkills'
import { useLocale } from '~/hooks/locale/useLocale'

import { ShowError } from '~/utils/errors/apiErrors'
import { translate } from '~/utils/locale'
import { ContainerButton } from './styles'

interface NewSkillData {
  name: string
  description: string
}

interface SkillsFormProps {
  SkillsInfo?: any
  isUpdate?: boolean
  buttonStyle?: React.CSSProperties
}

const SkillModal: React.FC<SkillsFormProps> = ({
  SkillsInfo = {},
  isUpdate = false,
  buttonStyle = {},
}) => {
  const [isVisible, setIsVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [keepRegister, setKeepRegister] = useState(false)

  const { selectedCompany } = useCompanies()
  const { skillsRefetch } = useSkills()
  const { locale } = useLocale()

  const [form] = Form.useForm()

  const { TextArea } = Input

  const handleOk = () => {
    form.submit()
  }

  const handleCancel = useCallback(() => {
    form.resetFields()
    setIsVisible(false)
  }, [form])

  const onCreate = useCallback(
    async (data: NewSkillData) => {
      try {
        setLoading(true)
        await api.post(`/company/${selectedCompany}/skill`, data)
        skillsRefetch()
        form.resetFields()
        message.success(translate('SkillModal.createSuccessMessage'))

        setLoading(false)
        if (!keepRegister) {
          handleCancel()
        }
        form.resetFields()
      } catch (err: any) {
        ShowError(
          err.message,
          translate('SkillModal.createErrorMessage'),
          locale,
        )

        setLoading(false)
      }
    },
    [selectedCompany, skillsRefetch, form, handleCancel, keepRegister, locale],
  )

  const onUpdate = useCallback(
    async (data: NewSkillData) => {
      try {
        setLoading(true)
        await api.put(
          `/company/${selectedCompany}/skill/${SkillsInfo.id}`,
          data,
        )
        skillsRefetch()
        message.success(translate('SkillModal.updateSuccessMessage'))
        setLoading(false)
        handleCancel()
      } catch (err: any) {
        ShowError(
          err.message,
          translate('SkillModal.updateErrorMessage'),
          locale,
        )

        setLoading(false)
      }
    },
    [selectedCompany, SkillsInfo, skillsRefetch, handleCancel, locale],
  )

  const showModal = () => {
    setIsVisible(true)
  }

  return (
    <>
      {isUpdate ? (
        <Tooltip title={translate('SkillModal.editTooltip')}>
          <Button
            type="primary"
            onClick={showModal}
            ghost
            shape="circle"
            icon={<EditOutlined />}
          />
        </Tooltip>
      ) : (
        <Button
          type="primary"
          onClick={showModal}
          style={buttonStyle}
          icon={<PlusCircleOutlined />}
        >
          {translate('SkillModal.createButton')}
        </Button>
      )}
      <Modal
        title={
          isUpdate
            ? translate('SkillModal.updateModalTitle')
            : translate('SkillModal.createModalTitle')
        }
        open={isVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Space key="buttons">
            {!isUpdate && (
              <Checkbox
                onChange={(e) => {
                  setKeepRegister(e.target.checked)
                }}
                checked={keepRegister}
              >
                {translate('SkillModal.keepRegisterText')}
              </Checkbox>
            )}
            <Button key="back" onClick={handleCancel}>
              {translate('SkillModal.cancelButton')}
            </Button>
            <ContainerButton>
              <Button
                key="submit"
                type="primary"
                loading={loading}
                onClick={handleOk}
                id="btn_create_skill_contributor"
              >
                {isUpdate
                  ? translate('SkillModal.saveButton')
                  : translate('SkillModal.registerButton')}
              </Button>
            </ContainerButton>
          </Space>,
        ]}
      >
        <Form
          form={form}
          onFinish={isUpdate ? onUpdate : onCreate}
          initialValues={SkillsInfo}
        >
          <Form.Item
            label={translate('SkillModal.nameLabel')}
            name="name"
            labelCol={{ span: 24 }}
            style={{
              width: '100%',
            }}
            rules={[
              {
                required: true,
                message: translate('SkillModal.nameRule'),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={translate('SkillModal.descriptionLabel')}
            name="description"
            labelCol={{ span: 24 }}
            style={{
              width: '100%',
            }}
            rules={[
              {
                required: true,
                message: translate('SkillModal.descriptionRule'),
              },
            ]}
          >
            <TextArea rows={2} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default SkillModal
