/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import * as styles from './styles'

import api from '~/services/api'

import { useLocale } from '~/hooks/locale/useLocale'

import ConfirmPageWithNoToken from '~/components/CodeConfirmation/ConfirmPageWithNoToken'
import ConfirmPageWithToken from '~/components/CodeConfirmation/ConfirmPageWithToken'

import { ShowError } from '~/utils/errors/apiErrors'
import { translate } from '~/utils/locale'

interface ParamTypes {
  token: string
}

const ConfirmCode: React.FC = () => {
  const { locale } = useLocale()
  const { token } = useParams<ParamTypes>()
  const [status, setStatus] = useState('loading')

  useEffect(() => {
    ;(async () => {
      if (token !== undefined && status === 'loading') {
        try {
          const newToken = token
          await api.patch('/user/confirm', { token: newToken })

          const userInfo = localStorage.getItem('@Gstor:user')
          const parsedUserInfo = JSON.parse(userInfo!)

          parsedUserInfo.active = true
          localStorage.setItem('@Gstor:user', JSON.stringify(parsedUserInfo))

          setStatus('success')
        } catch (err: any) {
          ShowError(err.message, translate('ConfirmCode.errorMessage'), locale)
          setStatus('fail')
        }
      }
    })()
  }, [status, token, locale])

  return (
    <styles.Container>
      {token === undefined ? (
        <ConfirmPageWithNoToken />
      ) : (
        <ConfirmPageWithToken status={status} />
      )}
    </styles.Container>
  )
}

export default ConfirmCode
