export default {
  pt: {
    ShowEquipments: {
      helmetTitle: 'Visualizar Equipamento',
    },
  },
  en: {
    ShowEquipments: {
      helmetTitle: 'View Equipment',
    },
  },
  es: {
    ShowEquipments: {
      helmetTitle: 'Ver Equipo',
    },
  },
}
