import React from 'react'

import { LoadingOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { Card, Space, Button } from 'antd'
import Text from 'antd/lib/typography/Text'
import { Link } from 'react-router-dom'

import { translate } from '~/utils/locale'

import { useListParentClients } from '~/hooks/Clients/useListParentCustomer'
import ParentClientsTable from './Table'

// import { Container } from './styles';

const ParentClientsBoard: React.FC = () => {
  const { isLoading, isFetching, clientsTotal } = useListParentClients()

  return (
    <Card
      title={
        <Space size="large">
          <b>{translate('ClientsParent.parentsTitle')}</b>
          {isLoading ? (
            <LoadingOutlined spin />
          ) : (
            <Text
              type="secondary"
              style={{ fontWeight: 'normal', fontSize: 14 }}
            >
              {clientsTotal !== undefined ? (
                <>
                  {clientsTotal === 1
                    ? `${translate(
                        'ClientsParent.totalText',
                      )} ${clientsTotal} ${translate(
                        'ClientsParent.parentText',
                      )}`
                    : `${translate(
                        'ClientsParent.totalText',
                      )} ${clientsTotal} ${translate(
                        'ClientsParent.parentsText',
                      )}`}
                </>
              ) : (
                ''
              )}
            </Text>
          )}
          {!isLoading && isFetching && <LoadingOutlined spin />}
        </Space>
      }
      extra={
        <Space size="middle">
          {/* <ClientsFilter /> */}

          <Link to="/clientePrincipal/novo">
            <Button type="primary" icon={<PlusCircleOutlined />}>
              {translate('ClientsParent.newParentButton')}
            </Button>
          </Link>
        </Space>
      }
      bordered={false}
      style={{ width: '100%', padding: 30 }}
    >
      <ParentClientsTable />
    </Card>
  )
}

export default ParentClientsBoard
