export default {
  pt: {
    GroupsCreateContributorPermission: {
      addTooltip: 'Novo Colaborador',
      addButton: 'Adicionar colaborador',
      modalTitle: 'Novo Colaborador',
      keepAddText: 'Continuar adicionando colaboradores?',
      closeButton: 'Fechar',
      saveButton: 'Salvar',
      userLabel: 'Colaborador',
      userPlaceholder: 'Selecione o colaborador',
    },
  },
  en: {
    GroupsCreateContributorPermission: {
      addTooltip: 'New Collaborator',
      addButton: 'Add collaborator',
      modalTitle: 'New Collaborator',
      keepAddText: 'Continue adding collaborators?',
      closeButton: 'Close',
      saveButton: 'Save',
      userLabel: 'Collaborator',
      userPlaceholder: 'Select Collaborator',
    },
  },
  es: {
    GroupsCreateContributorPermission: {
      addTooltip: 'Nuevo colaborador',
      addButton: 'Añadir colaborador',
      modalTitle: 'Nuevo colaborador',
      keepAddText: '¿Continuar agregando colaboradores?',
      closeButton: 'Cerrar',
      saveButton: 'Guardar',
      userLabel: 'Colaborador',
      userPlaceholder: 'Seleccionar colaborador',
    },
  },
}
