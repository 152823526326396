export default {
  pt: {
    useSpecifications: {
      getErrorMessage: 'Erro ao visualizar especificações.',
      createSuccessMessage: 'Especificação criada com sucesso!',
      createErrorMessage: 'Erro ao criar especificações.',
      updateSuccessMessage: 'Especificação atualizada com sucesso!',
      updateErrorMessage: 'Erro ao atualizar especificações.',
      deleteSuccessMessage: 'Especificação deletada com sucesso!',
      deleteErrorMessage: 'Erro ao excluir especificações.',
    },
  },
  en: {
    useSpecifications: {
      getErrorMessage: 'Error viewing specs.',
      createSuccessMessage: 'Specification created successfully!',
      createErrorMessage: 'Error creating specs.',
      updateSuccessMessage: 'Specification updated successfully!',
      updateErrorMessage: 'Error updating specs.',
      deleteSuccessMessage: 'Specification deleted successfully!',
      deleteErrorMessage: 'Error deleting specs.',
    },
  },
  es: {
    useSpecifications: {
      getErrorMessage: 'Error al ver las especificaciones.',
      createSuccessMessage: '¡Especificación creada con éxito!',
      createErrorMessage: 'Error al crear especificaciones.',
      updateSuccessMessage: 'Especificación actualizada con éxito!',
      updateErrorMessage: 'Error al actualizar las especificaciones.',
      deleteSuccessMessage: '¡Especificación eliminada con éxito!',
      deleteErrorMessage: 'Error al eliminar las especificaciones.',
    },
  },
}
