export default {
  pt: {
    TasksCreate: {
      pageheaderTitle: 'Nova Tarefa',
      cardTitle: 'Preencha os dados da tarefa',
      keepRegisterText: 'Continuar cadastrando tarefas?',
      registerButton: 'Cadastrar Tarefa',
      continueText: 'Continuar',
      clientPane: 'Cliente e Detalhes da Tarefa',
      addressPane: 'Endereço',
      responsiblePane: 'Responsável',
    },
  },
  en: {
    TasksCreate: {
      pageheaderTitle: 'New Task',
      cardTitle: 'Fill in the task data',
      keepRegisterText: 'Continue registering tasks?',
      registerButton: 'Register Task',
      continueText: 'Continue',
      clientPane: 'Customer and Task Details',
      addressPane: 'Address',
      responsiblePane: 'Responsible',
    },
  },
  es: {
    TasksCreate: {
      pageheaderTitle: 'Nueva tarea',
      cardTitle: 'Rellena los datos de la tarea',
      keepRegisterText: '¿Continuar registrando tareas?',
      registerButton: 'Registrar tarea',
      continueText: 'Continuar',
      clientPane: 'Detalles del cliente y de la tarea',
      addressPane: 'Dirección',
      responsiblePane: 'Responsable',
    },
  },
}
