/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/require-default-props */
/* eslint-disable consistent-return */
/* eslint-disable react/jsx-curly-newline */
import React, { useEffect, useState } from 'react'

import dayjs from 'dayjs'

import {
  Form,
  Input,
  Button,
  Radio,
  Drawer,
  TimePicker,
  Tooltip,
  Space,
  message,
  Row,
  Col,
  Checkbox,
} from 'antd'
import { EditOutlined } from '@ant-design/icons'

import { useScale } from '~/hooks/Scales/useScale'
import { ShowError } from '~/utils/errors/apiErrors'
import { useLocale } from '~/hooks/locale/useLocale'
import { translate } from '~/utils/locale'

interface scheduleTimeProps {
  day: string
  checkin: dayjs.Dayjs
  checkout: dayjs.Dayjs
  description?: string
  group_id?: string
  id: string
  type: string
}

interface ScaleCalendarDrawerProps {
  selectedDay: dayjs.Dayjs
  listData?: scheduleTimeProps
  setClose?: React.Dispatch<React.SetStateAction<boolean>>
}

interface Values {
  checkin: dayjs.Dayjs
  checkout: dayjs.Dayjs
  description: string
  type: string
}

const ScaleCalendarDrawer: React.FC<ScaleCalendarDrawerProps> = ({
  listData = {} as scheduleTimeProps,
  selectedDay,
  setClose,
}) => {
  const [form] = Form.useForm()

  const [scheduleEditData, setScheduleEditData] = useState({})
  const [visible, setVisible] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [updateAll, setUpdateAll] = useState(false)

  const { updateScheduleTime, updateGroupScheduleTime } = useScale()
  const { locale } = useLocale()

  const onCancel = () => {
    setVisible(false)
    form.resetFields()
  }

  useEffect(() => {
    if (listData.id) {
      setScheduleEditData({
        type: listData.type,
        description: listData.description,
        // checkin: dayjs(listData.checkin, 'HH:mm:ss'),
        // checkout: dayjs(listData.checkout, 'HH:mm:ss'),
        checkin: listData.checkin,
        checkout: listData.checkout,
      })
    }
  }, [listData])

  const showDrawer = () => {
    setVisible(true)
  }

  const onUpdate = async (values: Values) => {
    try {
      setConfirmLoading(true)
      const dados = {
        day: selectedDay,
        checkin: values.checkin,
        checkout: values.checkout,
        description: values.description,
        type: values.type,
      }
      await updateScheduleTime(dados, listData.id)
      setConfirmLoading(false)
      message.success(translate('ScalesCalendarDrawer.successMessage'))
      setVisible(false)
      if (setClose) {
        setClose(true)
      }
      form.resetFields()
    } catch (err: any) {
      setConfirmLoading(false)

      ShowError(
        err.message,
        translate('ScalesCalendarDrawer.errorMessage'),
        locale,
      )
    }
  }

  const onGroupUpdate = async (values: Values) => {
    try {
      setConfirmLoading(true)
      const dados = {
        ...values,
      }
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      await updateGroupScheduleTime(dados, listData.group_id!)
      setConfirmLoading(false)
      message.success(translate('ScalesCalendarDrawer.successMessage'))
      setVisible(false)
      setUpdateAll(false)
      if (setClose) {
        setClose(true)
      }
      form.resetFields()
    } catch (err: any) {
      setConfirmLoading(false)

      ShowError(
        err.message,
        translate('ScalesCalendarDrawer.errorMessage'),
        locale,
      )
    }
  }

  return (
    <>
      <Tooltip title={translate('ScalesCalendarDrawer.editTooltip')}>
        <Button
          ghost
          shape="circle"
          icon={<EditOutlined />}
          onClick={showDrawer}
        />
      </Tooltip>
      <Drawer
        title={translate('ScalesCalendarDrawer.drawerTitle')}
        width={720}
        onClose={onCancel}
        open={visible}
        style={{ paddingBottom: 80 }}
      >
        <Form
          form={form}
          layout="vertical"
          name="schedules-time"
          onFinish={updateAll ? onGroupUpdate : onUpdate}
          initialValues={scheduleEditData}
        >
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item
                label={<b>{translate('ScalesCalendarDrawer.typeLabel')}</b>}
                name="type"
                rules={[
                  {
                    required: true,
                    message: translate('ScalesCalendarDrawer.typeRule'),
                  },
                ]}
              >
                <Radio.Group>
                  <Radio.Button value="work">
                    {translate('ScalesCalendarDrawer.typeWork')}
                  </Radio.Button>
                  <Radio.Button value="break">
                    {translate('ScalesCalendarDrawer.typeBreak')}
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item
                label={<b>{translate('ScalesCalendarDrawer.checkinLabel')}</b>}
                name="checkin"
                rules={[
                  {
                    required: true,
                    message: translate('ScalesCalendarDrawer.checkinRule'),
                  },
                ]}
              >
                <TimePicker format="HH:mm" minuteStep={15} />
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item
                label={<b>{translate('ScalesCalendarDrawer.checkoutLabel')}</b>}
                name="checkout"
                rules={[
                  {
                    required: true,
                    message: translate('ScalesCalendarDrawer.checkoutRule'),
                  },
                ]}
              >
                <TimePicker format="HH:mm" minuteStep={15} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={12}>
            <Col span={24}>
              <Form.Item
                label={
                  <b>{translate('ScalesCalendarDrawer.descriptionLabel')}</b>
                }
                name="description"
              >
                <Input.TextArea rows={2} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={12}>
            <Col span={24}>
              <Space>
                <Form.Item>
                  <Button onClick={onCancel}>
                    {translate('ScalesCalendarDrawer.cancelButton')}
                  </Button>
                </Form.Item>

                <Form.Item>
                  <Button
                    htmlType="submit"
                    type="primary"
                    loading={confirmLoading}
                  >
                    {translate('ScalesCalendarDrawer.saveButton')}
                  </Button>
                </Form.Item>

                <Form.Item>
                  <Checkbox
                    onChange={(e) => {
                      setUpdateAll(e.target.checked)
                    }}
                    checked={updateAll}
                  >
                    {translate('ScalesCalendarDrawer.saveCheckbox')}
                  </Checkbox>
                </Form.Item>
              </Space>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </>
  )
}

export default ScaleCalendarDrawer
