export default {
  pt: {
    useListStatus: {
      getErrorMessage: 'Erro ao visualizar status de colaboradores.',
      createSuccessMessage: 'Status criado com sucesso!',
      createErrorMessage: 'Erro ao criar status.',
      deleteSuccessMessage: 'Status deletado com sucesso!',
      deleteErrorMessage: 'Erro ao excluir status.',
    },
  },
  en: {
    useListStatus: {
      getErrorMessage: 'Error viewing contributor status.',
      createSuccessMessage: 'Status created successfully!',
      createErrorMessage: 'Error creating status.',
      deleteSuccessMessage: 'Status deleted successfully!',
      deleteErrorMessage: 'Error deleting status.',
    },
  },
  es: {
    useListStatus: {
      getErrorMessage: 'Error al ver el estado del colaborador.',
      createSuccessMessage: '¡Estado creado con éxito!',
      createErrorMessage: 'Error al crear el estado.',
      deleteSuccessMessage: '¡Estado eliminado con éxito!',
      deleteErrorMessage: 'Error al eliminar el estado.',
    },
  },
}
