export default {
  pt: {
    ReportKilometersData: {
      taskColumn: 'Tarefa',
      responsibleColumn: 'Responsável',
      checkinColumn: 'Check-in',
      manualTag: 'Manual',
      automaticTag: 'Automático',
      contributorTag: 'Colaborador',
      valueInformedColumn: 'Valores informados',
      tableTitle: 'Relatórios referentes ao dia',
    },
  },
  en: {
    ReportKilometersData: {
      taskColumn: 'Task',
      responsibleColumn: 'Responsible',
      checkinColumn: 'Check-in',
      manualTag: 'Manual',
      automaticTag: 'Automatic',
      contributorTag: 'Contributor',
      valueInformedColumn: 'Values ​​informed',
      tableTitle: 'Reports for the day',
    },
  },
  es: {
    ReportKilometersData: {
      taskColumn: 'Tarea',
      responsibleColumn: 'Responsable',
      checkinColumn: 'Check-in',
      manualTag: 'Manual',
      automaticTag: 'Automático',
      contributorTag: 'Colaborador',
      valueInformedColumn: 'Valores informados',
      tableTitle: 'Informes del día',
    },
  },
}
