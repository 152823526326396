export default {
  pt: {
    ReportKilometersFilter: {
      kmValueLabel: 'Valor Km (R$)',
      kmValueRule: 'Por gentileza, insira o Valor KM (R$)',
      kmValuePlaceholder: 'Informe o valor',
      fromDateLabel: 'Data Inicial',
      fromDateRule: 'Por gentileza, insira a Data Inicial',
      toDateLabel: 'Data Final',
      toDateRule: 'Por gentileza, insira a Data Final',
      responsibleLabel: 'Filtrar relátorio por',
      responsiblePlaceholder: 'Selecione o responsável',
      responsibleContributor: 'Colaborador',
      responsibleTeam: 'Equipe',
      contributorLabel: 'Colaborador',
      contributorPlaceholder: 'Selecione o colaborador',
      teamLabel: 'Equipe',
      teamPlaceholder: 'Selecione a equipe',
      submitButton: 'Gerar Relatório',
    },
  },
  en: {
    ReportKilometersFilter: {
      kmValueLabel: 'Value Km (R$)',
      kmValueRule: 'Please enter the KM Value (BRL)',
      kmValuePlaceholder: 'Inform the value',
      fromDateLabel: 'Initial date',
      fromDateRule: 'Please enter the Initial Date',
      toDateLabel: 'Final date',
      toDateRule: 'Please enter the Final Date',
      responsibleLabel: 'Filter report by',
      responsiblePlaceholder: 'Select responsible',
      responsibleContributor: 'Contributor',
      responsibleTeam: 'Team',
      contributorLabel: 'Contributor',
      contributorPlaceholder: 'Select the contributor',
      teamLabel: 'Team',
      teamPlaceholder: 'Select the team',
      submitButton: 'Generate Report',
    },
  },
  es: {
    ReportKilometersFilter: {
      kmValueLabel: 'Valor Km (R$)',
      kmValueRule: 'Ingrese el valor de KM (BRL)',
      kmValuePlaceholder: 'Informar el valor',
      fromDateLabel: 'La fecha de inicio',
      fromDateRule: 'Por favor ingrese la fecha de inicio',
      toDateLabel: 'Fecha final',
      toDateRule: 'Por favor, introduzca la fecha de final',
      responsibleLabel: 'Filtrar informe por',
      responsiblePlaceholder: 'Seleccionar responsable',
      responsibleContributor: 'Colaborador',
      responsibleTeam: 'Equipo',
      contributorLabel: 'Colaborador',
      contributorPlaceholder: 'Seleccione el colaborador',
      teamLabel: 'Equipo',
      teamPlaceholder: 'Seleccione el equipo',
      submitButton: 'Generar Informe',
    },
  },
}
