export default {
  pt: {
    TasksOptionsCalendarList: {
      tasksText: 'tarefas',
      modalTitle: 'Informações da Tarefa',
      closeButton: 'Fechar',
      deletePopconfirm: 'Deseja realmente deletar a tarefa?',
      deleteTooltip: 'Deletar Tarefa',
      deleteButton: 'Deletar',
      detailsButton: 'Mais Detalhes',
      detailsTab: 'Detalhes da Tarefa',
      taskResponsible: 'Executador da Tarefa: ',
      taskClient: 'Para:',
      currentDate: 'Data da tarefa:',
      durationTime: 'Duração: ',
      checkinType: 'Tipo de Checkin',
      manualText: 'Manual',
      priorityText: 'Prioridade: ',
      priorityLow: 'Baixa',
      priorityMedium: 'Média',
      priorityHigh: 'Alta',
      taskType: 'Tipo de Tarefa:',
      statusText: 'Status: ',
      statusActive: 'Ativa',
      statusInactive: 'Inativa',
      externalCode: 'Código Externo:',
      internalCode: 'Código Interno:',
      addressTab: 'Endereço',
      zipcodeText: 'CEP:',
      addressText: 'Endereço:',
      cityText: 'Cidade:',
      equipmentsTab: 'Equipamentos',
      identifierText: 'Identificador:',
    },
  },
  en: {
    TasksOptionsCalendarList: {
      tasksText: 'tasks',
      modalTitle: 'Task Information',
      closeButton: 'Close',
      deletePopconfirm: 'Do you really want to delete the task?',
      deleteTooltip: 'Delete task',
      deleteButton: 'Delete',
      detailsButton: 'More Details',
      detailsTab: 'Task Details',
      taskResponsible: 'Task Executor: ',
      taskClient: 'To:',
      currentDate: 'Task date:',
      durationTime: 'Duration: ',
      checkinType: 'Checkin Type',
      manualText: 'Manual',
      priorityText: 'Priority: ',
      priorityLow: 'Low',
      priorityMedium: 'Medium',
      priorityHigh: 'High',
      taskType: 'Task Type:',
      statusText: 'Status: ',
      statusActive: 'Active',
      statusInactive: 'Inactive',
      externalCode: 'Internal Code:',
      internalCode: 'External Code:',
      addressTab: 'Address',
      zipcodeText: 'Zipcode:',
      addressText: 'Address:',
      cityText: 'City:',
      equipmentsTab: 'Equipments',
      identifierText: 'Identifier:',
    },
  },
  es: {
    TasksOptionsCalendarList: {
      tasksText: 'tareaas',
      modalTitle: 'Información de la tarea',
      closeButton: 'Cerrar',
      deletePopconfirm: '¿Realmente quieres eliminar la tarea?',
      deleteTooltip: 'Eliminar tarea',
      deleteButton: 'Borrar',
      detailsButton: 'Más detalles',
      detailsTab: 'Detalles de la tarea',
      taskResponsible: 'Ejecutor de tareas: ',
      taskClient: 'Para:',
      currentDate: 'Fecha de la tarea:',
      durationTime: 'Duración: ',
      checkinType: 'Tipo de entrada',
      manualText: 'Manual',
      priorityText: 'Prioridad: ',
      priorityLow: 'Baja',
      priorityMedium: 'Media',
      priorityHigh: 'Alta',
      taskType: 'Tipo de Tareaa:',
      statusText: 'Status: ',
      statusActive: 'Activa',
      statusInactive: 'Inactiva',
      externalCode: 'Código Externo:',
      internalCode: 'Código Interno:',
      addressTab: 'Dirección',
      zipcodeText: 'Código Postal:',
      addressText: 'Dirección:',
      cityText: 'Ciudad:',
      equipmentsTab: 'Equipo',
      identifierText: 'Identificador:',
    },
  },
}
