export default {
  pt: {
    ListTasks: {
      helmetTitle: 'Tarefas',
    },
  },
  en: {
    ListTasks: {
      helmetTitle: 'Tasks',
    },
  },
  es: {
    ListTasks: {
      helmetTitle: 'Tareas',
    },
  },
}
