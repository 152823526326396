/* eslint-disable @typescript-eslint/no-explicit-any */

import { Button, Card, Col, Form, Input, Row, Space } from 'antd'
import React, { useState } from 'react'
import { useParentCustomer } from '~/hooks/Clients/useParentCustomer'
import { translate } from '~/utils/locale'
import { ContainerButton } from './styles'
import MoneyInput from '~/components/DataManipulation/Misc/MoneyInput'

// import { Container } from './styles';

const UpdateParentClientFinancial: React.FC = () => {
  const { parentCustomer, updateParentCustomer, refetch, setIsEditFinancial } =
    useParentCustomer()
  const [form] = Form.useForm()

  const [taskValue, setTaskValue] = useState(
    parentCustomer?.financial.task_value,
  )
  const [amountHours, setAmountHours] = useState(
    parentCustomer?.financial.amount_hours,
  )
  const [amountHoursAdditional, setAmountHoursAdditional] = useState(
    parentCustomer?.financial.amount_hours_additional,
  )
  const [numberKm, setNumberKm] = useState(parentCustomer?.financial.number_km)
  const [numberKmAdditional, setNumberKmAdditional] = useState(
    parentCustomer?.financial.number_km_additional,
  )

  const initialValues = {
    task_value: parentCustomer?.financial.task_value,
    amount_hours: parentCustomer?.financial.amount_hours,
    amount_hours_additional: parentCustomer?.financial.amount_hours_additional,
    number_km: parentCustomer?.financial.number_km,
    number_km_additional: parentCustomer?.financial.number_km_additional,
  }

  const handleTaskValue = (valueInCents: number | undefined) => {
    setTaskValue(valueInCents)
  }

  const handleHourValue = (valueInCents: number | undefined) => {
    setAmountHoursAdditional(valueInCents)
  }

  const handleKmValue = (valueInCents: number | undefined) => {
    setNumberKmAdditional(valueInCents)
  }

  function updateParentClientsFinancial() {
    const dataForm = {
      companyName: parentCustomer?.companyName,
      fantasyName: parentCustomer?.fantasyName,
      cpf: parentCustomer?.cpf,
      cnpj: parentCustomer?.cnpj,
      municipal_registration: parentCustomer?.municipal_registration,
      state_registration: parentCustomer?.state_registration,
      contact: {
        email: parentCustomer?.contact.email,
        name: parentCustomer?.contact.name,
        phone: parentCustomer?.contact.phone,
      },
      financial: {
        task_value: taskValue,
        amount_hours: amountHours,
        amount_hours_additional: amountHoursAdditional,
        number_km: numberKm,
        number_km_additional: numberKmAdditional,
      },
      address: {
        zip_code: parentCustomer?.address.zip_code,
        street: parentCustomer?.address.street,
        number: parentCustomer?.address.number,
        complement: parentCustomer?.address.complement,
        neighborhood: parentCustomer?.address.neighborhood,
        city: parentCustomer?.address.city,
        state: parentCustomer?.address.state,
        location: {
          x: parentCustomer?.address.location.x,
          y: parentCustomer?.address.location.y,
        },
      },
    }

    try {
      updateParentCustomer(dataForm)
      setIsEditFinancial(false)

      refetch()
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Card
      bordered={false}
      title={translate('ClientsPrincipalFinancialUpdate.cardTitle')}
      style={{
        display: 'inline-block',
        width: '100%',
      }}
      extra={
        <Space size="middle">
          <ContainerButton>
            <Button
              htmlType="submit"
              form="updateParentClientFinancial"
              type="primary"
              id="btn_update_financial_parent_client"
            >
              {translate('ClientsPrincipalFinancialUpdate.saveButton')}
            </Button>
          </ContainerButton>
          <Button onClick={() => setIsEditFinancial(false)}>
            {translate('ClientsPrincipalFinancialUpdate.deleteButton')}
          </Button>
        </Space>
      }
    >
      <Form
        layout="vertical"
        onFinish={updateParentClientsFinancial}
        initialValues={initialValues}
        id="updateParentClientFinancial"
        form={form}
      >
        <Row>
          <Col span={12}>
            <Form.Item
              label={translate('ClientsPrincipalFinancialUpdate.valueLabel')}
              name="task_value"
              rules={[
                {
                  required: true,
                  message: translate(
                    'ClientsPrincipalFinancialUpdate.valueRule',
                  ),
                },
              ]}
            >
              <MoneyInput value={taskValue} onChange={handleTaskValue} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={14}>
          <Col span={12}>
            <Form.Item
              label={translate('ClientsPrincipalFinancialUpdate.hoursLabel')}
              name="amount_hours"
            >
              <Input
                value={amountHours}
                onChange={(e) => setAmountHours(parseInt(e.target.value))}
                style={{ width: '100%' }}
                maxLength={2}
                placeholder={translate(
                  'ClientsPrincipalFinancialUpdate.hoursPlaceholder',
                )}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={translate(
                'ClientsPrincipalFinancialUpdate.valueHoursLabel',
              )}
              name="amount_hours_additional"
            >
              <MoneyInput
                value={amountHoursAdditional}
                onChange={handleHourValue}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={14}>
          <Col span={12}>
            <Form.Item
              label={translate('ClientsPrincipalFinancialUpdate.kmLabel')}
              name="number_km"
            >
              <Input
                placeholder={translate(
                  'ClientsPrincipalFinancialUpdate.kmPlaceholder',
                )}
                value={numberKm}
                onChange={(e) => setNumberKm(parseInt(e.target.value))}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={translate('ClientsPrincipalFinancialUpdate.valueKmLabel')}
              name="number_km_additional"
            >
              <MoneyInput value={numberKmAdditional} onChange={handleKmValue} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  )
}

export default UpdateParentClientFinancial
