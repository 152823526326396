/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable consistent-return */
import React, {
  useContext,
  createContext,
  useState,
  useCallback,
  useEffect,
} from 'react'
import { useQuery } from 'react-query'

import dayjs from 'dayjs'

import api from '~/services/api'

import { useCompanies } from '~/hooks/Companies/useCompanies'
import { useLocale } from '~/hooks/locale/useLocale'

import { ShowError } from '~/utils/errors/apiErrors'
import { translate } from '~/utils/locale'

interface GraphicProps {
  at_execution: number
  canceled: number
  finished: number
  total: number
}

interface EquipmentsTasks {
  id: string
}

interface TaskTypeProps {
  _id: string
  name: string
}

interface Address {
  city: string
  id: string
  location: {
    x: number
    y: number
  }
  neighborhood: string
  number: number
  state: string
  street: string
  zip_code: string
  complement: string
}

interface Responsible {
  id: string
  type: string
}

interface Status {
  type: string
  created_at: Date
  collaborator_id: string
  location: {
    lat: number
    long: number
  }
}

interface DataProps {
  checkin_type: string
  createdAt: string
  date: string
  duration_time: string
  external_code: string
  is_enabled: string
  priority: string
  description: string
  _id: string
  hours: string
  currentDate: string
  current_status: string
  initialTime: string
  task_type_id: TaskTypeProps
  equipments: EquipmentsTasks[]
  address: Address
  responsible: Responsible
  status: Status[]
}

interface ReportTasksProps {
  results: DataProps[]
  graphic: GraphicProps
}

interface FiltersId {
  id: string
}

interface StatusFilter {
  name: string
}

interface FilterOptionsProps {
  currentStatus?: StatusFilter[]
  responsibleId?: FiltersId[]
  teamResponsibleId?: FiltersId[]
  equipmentsId?: FiltersId[]
  externalCodes?: string
  internalCodes?: string
  customersId?: FiltersId[]
  fromDate?: string | number | Date | dayjs.Dayjs | null | undefined
  toDate?: string | number | Date | dayjs.Dayjs | null | undefined
}

interface ReportTasksContextData {
  reportTasks?: ReportTasksProps
  isLoading: boolean
  isFetching: boolean
  refetch: () => void
  handleUpdateFilters: (newFilterOptions: any) => void
  handleResetFilters: () => void
  filterOptions: FilterOptionsProps
  exportReportTasks(): void
  urlDownload?: string
  loading: boolean
  text: string
}

const ReportTasksContext = createContext<ReportTasksContextData>(
  {} as ReportTasksContextData,
)

export const ReportTasksProvider: React.FC = ({ children }) => {
  const { selectedCompany } = useCompanies()
  const { locale } = useLocale()
  const [urlDownload, setUrlDownload] = useState<string>()
  const [loading, setLoading] = useState(false)
  const [text, setText] = useState(translate('useReportTasks.textExport'))

  const [filterOptions, setFilterOptions] = useState<FilterOptionsProps>(() => {
    const filter = localStorage.getItem('@Gstor:reportTasksFilterOptions')

    if (filter) {
      return JSON.parse(filter)
    }

    return {} as FilterOptionsProps
  })

  const {
    data: reportTasks,
    isLoading,
    isFetching,
    refetch,
  } = useQuery(
    [`Relatório de Tarefas${selectedCompany}`, selectedCompany, filterOptions],

    async () => {
      try {
        const response = await api.get(
          `company/${selectedCompany}/reports/tasks`,
          {
            params: {
              ...(filterOptions?.currentStatus &&
                filterOptions?.currentStatus?.join() !== '' && {
                  currentStatus: filterOptions?.currentStatus?.join(),
                }),
              ...(filterOptions?.responsibleId &&
                filterOptions?.responsibleId?.join() !== '' && {
                  responsibleId: filterOptions?.responsibleId?.join(),
                }),
              ...(filterOptions?.teamResponsibleId &&
                filterOptions?.teamResponsibleId?.join() !== '' && {
                  teamResponsibleId: filterOptions?.teamResponsibleId?.join(),
                }),
              ...(filterOptions?.equipmentsId &&
                filterOptions?.equipmentsId?.join() !== '' && {
                  equipmentsId: filterOptions?.equipmentsId?.join(),
                }),
              externalCodes: filterOptions?.externalCodes,
              internalCodes: filterOptions?.internalCodes,
              ...(filterOptions?.customersId &&
                filterOptions?.customersId?.join() !== '' && {
                  customersId: filterOptions?.customersId?.join(),
                }),
              fromDate: filterOptions?.fromDate
                ? dayjs.utc(filterOptions?.fromDate).format()
                : dayjs().subtract(1, 'months').startOf('day').format(),
              toDate: filterOptions?.toDate
                ? dayjs.utc(filterOptions?.toDate).format()
                : dayjs().add(1, 'months').startOf('day').format(),
            },
          },
        )

        const { data } = response

        return data
      } catch (err: any) {
        ShowError(
          err.message,
          translate('useReportTasks.getErrorMessage'),
          locale,
        )
      }
    },
  )

  async function exportReportTasks() {
    try {
      setLoading(true)
      setText(translate('useReportTasks.textLoading'))
      const response = await api.post(
        `company/${selectedCompany}/reports/tasks/export`,
        {},
        {
          params: {
            ...(filterOptions?.currentStatus &&
              filterOptions?.currentStatus?.join() !== '' && {
                currentStatus: filterOptions?.currentStatus?.join(),
              }),
            ...(filterOptions?.responsibleId &&
              filterOptions?.responsibleId?.join() !== '' && {
                responsibleId: filterOptions?.responsibleId?.join(),
              }),
            ...(filterOptions?.teamResponsibleId &&
              filterOptions?.teamResponsibleId?.join() !== '' && {
                teamResponsibleId: filterOptions?.teamResponsibleId?.join(),
              }),
            ...(filterOptions?.equipmentsId &&
              filterOptions?.equipmentsId?.join() !== '' && {
                equipmentsId: filterOptions?.equipmentsId?.join(),
              }),
            externalCodes: filterOptions?.externalCodes,
            internalCodes: filterOptions?.internalCodes,
            ...(filterOptions?.customersId &&
              filterOptions?.customersId?.join() !== '' && {
                customersId: filterOptions?.customersId?.join(),
              }),
            fromDate: dayjs.utc(filterOptions?.fromDate).format(),
            toDate: dayjs.utc(filterOptions?.toDate).format(),
          },
        },
      )
      setUrlDownload(response.data.url)
      setText(translate('useReportTasks.textDownload'))
      setLoading(false)
    } catch (err: any) {
      ShowError(
        err.message,
        translate('useReportTasks.exportErrorMessage'),
        locale,
      )

      setLoading(false)
      setText(translate('useReportTasks.textExport'))
    }
  }

  const handleUpdateFilters = useCallback((newFilterOptions) => {
    const newFilters = {
      currentStatus: newFilterOptions?.currentStatus,
      responsibleId: newFilterOptions?.responsibleId,
      teamResponsibleId: newFilterOptions?.teamResponsibleId,
      equipmentsId: newFilterOptions?.equipmentsId,
      externalCodes: newFilterOptions?.externalCodes,
      internalCodes: newFilterOptions?.internalCodes,
      customersId: newFilterOptions?.customersId,
      fromDate: dayjs(newFilterOptions?.fromDate).startOf('day'),
      toDate: dayjs(newFilterOptions?.toDate).endOf('day'),
    }
    setFilterOptions(newFilters)
    localStorage.setItem(
      '@Gstor:reportTasksFilterOptions',
      JSON.stringify(newFilters),
    )
    // setNumberPage(1);
  }, [])

  const handleResetFilters = useCallback(() => {
    localStorage.removeItem('@Gstor:reportTasksFilterOptions')
    setFilterOptions({})
    // setNumberPage(1);
  }, [])

  useEffect(() => {
    refetch()
  }, [selectedCompany, refetch])

  return (
    <ReportTasksContext.Provider
      value={{
        reportTasks,
        isLoading,
        isFetching,
        refetch,
        handleUpdateFilters,
        handleResetFilters,
        filterOptions,
        exportReportTasks,
        urlDownload,
        loading,
        text,
      }}
    >
      {children}
    </ReportTasksContext.Provider>
  )
}

export function useReportTasks(): ReportTasksContextData {
  const context = useContext(ReportTasksContext)

  if (!context) {
    throw new Error('useAuth must be used within an AutoProvider')
  }

  return context
}
