/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, useCallback } from 'react'
import { useParams } from 'react-router-dom'

import {
  Modal,
  Descriptions,
  Typography,
  Space,
  message,
  Button,
  Checkbox,
} from 'antd'
import { FileUnknownOutlined } from '@ant-design/icons'

import dayjs from 'dayjs'

import api from '~/services/api'
import { ShowError } from '~/utils/errors/apiErrors'

import { useCompanies } from '~/hooks/Companies/useCompanies'
import { useScale } from '~/hooks/Scales/useScale'
import { useLocale } from '~/hooks/locale/useLocale'

import ScaleCalendarDrawer from '~/components/DataManipulation/Scales/ScaleCalendar/ScaleCalendarDrawer'
import DeleteButton from '~/components/DataManipulation/Misc/DeleteButton'
import { translate } from '~/utils/locale'
import { PopconfirmContainer } from './styles'

interface IScaleModal {
  event: {
    id: string
    title: string
    type: string
    description?: string
    group_id?: string
    day: string
    checkin: dayjs.Dayjs
    checkout: dayjs.Dayjs
    checkinFormatted: string
    checkoutFormatted: string
  }
  visible: boolean
  setVisible: React.Dispatch<React.SetStateAction<boolean>>
}

const ScaleModal: React.FC<IScaleModal> = ({ event, visible, setVisible }) => {
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [excludeAll, setExcludeAll] = useState(false)
  const [close, setClose] = useState(false)

  const { scaleId } = useParams<{ scaleId: string }>()

  const { selectedCompany } = useCompanies()
  const { scaleRefetch } = useScale()
  const { locale } = useLocale()

  const { Text } = Typography

  const handleOk = () => {
    setVisible(false)
  }

  const handleCancel = useCallback(() => {
    setVisible(false)
    setClose(false)
  }, [setVisible, setClose])

  const handleDelete = async (id: string) => {
    try {
      setConfirmLoading(true)
      await api.delete(
        `/company/${selectedCompany}/schedule/${scaleId}/schedule-time/${id}`,
      )
      setConfirmLoading(false)
      scaleRefetch()
      message.success(translate('ScalesCalendarModal.successMessage'))
    } catch (err: any) {
      ShowError(
        err.message,
        translate('ScalesCalendarModal.errorMessage'),
        locale,
      )

      setConfirmLoading(false)
    }
  }

  const handleDeleteByGroup = async (id: string) => {
    try {
      setConfirmLoading(true)
      await api.delete(
        `/company/${selectedCompany}/schedule/${scaleId}/schedule-time-group/${id}`,
      )
      setConfirmLoading(false)
      scaleRefetch()
      setExcludeAll(false)
      message.success(translate('ScalesCalendarModal.successMessage'))
    } catch (err: any) {
      ShowError(
        err.message,
        translate('ScalesCalendarModal.errorMessage'),
        locale,
      )

      setConfirmLoading(false)
    }
  }

  useEffect(() => {
    if (close) {
      handleCancel()
    }
  }, [close, handleCancel])

  return (
    <Modal
      title={event.day}
      open={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          {translate('ScalesCalendarModal.cancelButton')}
        </Button>,
        <ScaleCalendarDrawer
          key="CalendarDrawer"
          selectedDay={dayjs(event.day)}
          listData={event}
          setClose={setClose}
        />,
        event.group_id ? (
          <DeleteButton
            PopconfirmText={
              <PopconfirmContainer>
                {translate('ScalesCalendarModal.deletePopconfirm')}
                <Checkbox
                  onChange={(e) => {
                    setExcludeAll(e.target.checked)
                  }}
                  checked={excludeAll}
                >
                  {translate('ScalesCalendarModal.deleteCheckbox')}
                </Checkbox>
              </PopconfirmContainer>
            }
            TooltipText={translate('ScalesCalendarModal.deleteTooltip')}
            handleDelete={excludeAll ? handleDeleteByGroup : handleDelete}
            confirmLoading={confirmLoading}
            itemId={excludeAll ? event.group_id : event.id}
            setClose={setClose}
          />
        ) : (
          <DeleteButton
            PopconfirmText={translate('ScalesCalendarModal.deletePopconfirm')}
            TooltipText={translate('ScalesCalendarModal.deleteTooltip')}
            handleDelete={handleDelete}
            confirmLoading={confirmLoading}
            itemId={event.id}
            setClose={setClose}
          />
        ),
      ]}
    >
      <Descriptions column={2}>
        <Descriptions.Item
          label={
            <Text strong>{translate('ScalesCalendarModal.timeStringOne')}</Text>
          }
        >
          {event.checkinFormatted}
        </Descriptions.Item>
        <Descriptions.Item
          label={
            <Text strong>{translate('ScalesCalendarModal.timeStringTwo')}</Text>
          }
        >
          {event.checkoutFormatted}
        </Descriptions.Item>
        <Descriptions.Item
          label={
            <Text strong>
              {translate('ScalesCalendarModal.descriptionLabel')}
            </Text>
          }
        >
          {event.description ? (
            event.description
          ) : (
            <Text type="secondary">
              <Space size="small">
                <FileUnknownOutlined />
                N/A
              </Space>
            </Text>
          )}
        </Descriptions.Item>
      </Descriptions>
    </Modal>
  )
}

export default ScaleModal
