export default {
  pt: {
    ClientsFilter: {
      cleanFilterTooltip: 'Limpar Filtro',
      appliedButtonFilter: 'Filtro Aplicado',
      defaultButtonFilter: 'Filtros',
      filtersTitle: 'Filtros de Clientes',
      clientInfoLabel: 'Informações do cliente:',
      clientInfoTooltipParagraphOne: 'Você pode filtrar buscando por',
      clientInfoTooltipParagraphTwo:
        'nome, email, telefone, documento, cnpj, rg, tipo de pessoa e gênero do cliente',
      clientInfoInputPlaceholder: 'Insira o alguma informação do contato',
      contributorLabel: 'Colaborador:',
      contributorPlaceholder: 'Selecione o colaborador',
      corporateLabel: 'Nome Corporativo: ',
      corporatePlaceholder: 'Insira o nome corporativo',
      segmentLabel: 'Segmento: ',
      segmentPlaceholder: 'Selecione o segmento',
      municipalRegistrationLabel: 'Inscrição Municipal:',
      municipalRegistrationPlaceholder: 'Insira a inscrição municipal',
      stateRegistrationLabel: 'Inscrição Estadual:',
      stateRegistrationPlaceholder: 'Insira a inscrição estadual',
      externalCodeLabel: 'Código Externo:',
      externalCodePlaceholder: 'Insira o código externo',
      addressLabel: 'Endereço:',
      addressPlaceholder: 'Insira o endereço',
      finishButton: 'Aplicar',
      cleanButton: 'Limpar',
    },
  },
  en: {
    ClientsFilter: {
      cleanFilterTooltip: 'Clear Filter',
      appliedButtonFilter: 'Filter Applied',
      defaultButtonFilter: 'Filters',
      filtersTitle: 'Customer Filters',
      clientInfoLabel: 'Customer information:',
      clientInfoTooltipParagraphOne: 'You can filter by looking for',
      clientInfoTooltipParagraphTwo:
        'name, email, phone, document, cnpj, id, type of person and gender of the customer',
      clientInfoInputPlaceholder: 'Enter some contact information',
      contributorLabel: 'Contributor:',
      contributorPlaceholder: 'Select the contributor',
      corporateLabel: 'Corporate Name: ',
      corporatePlaceholder: 'Enter corporate name',
      segmentLabel: 'Segment: ',
      segmentPlaceholder: 'Select the segment',
      municipalRegistrationLabel: 'Municipal registration:',
      municipalRegistrationPlaceholder: 'Enter the municipal registration',
      stateRegistrationLabel: 'State registration:',
      stateRegistrationPlaceholder: 'Enter state registration',
      externalCodeLabel: 'External code:',
      externalCodePlaceholder: 'Enter external code',
      addressLabel: 'Address:',
      addressPlaceholder: 'Enter the address',
      finishButton: 'Apply',
      cleanButton: 'Clean',
    },
  },
  es: {
    ClientsFilter: {
      cleanFilterTooltip: 'Filtro Claro',
      appliedButtonFilter: 'Filtro Aplicado',
      defaultButtonFilter: 'Filtros',
      filtersTitle: 'Filtros de Clientes',
      clientInfoLabel: 'Información al cliente:',
      clientInfoTooltipParagraphOne: 'Puedes filtrar buscando',
      clientInfoTooltipParagraphTwo:
        'nombre, email, teléfono, documento, cnpj, id, tipo de persona y género del cliente',
      clientInfoInputPlaceholder: 'Ingrese alguna información de contacto',
      contributorLabel: 'Colaborador:',
      contributorPlaceholder: 'Seleccione el colaborador',
      corporateLabel: 'Nombre corporativo:',
      corporatePlaceholder: 'Ingrese el nombre corporativo',
      segmentLabel: 'Segmento: ',
      segmentPlaceholder: 'Seleccione el segmento',
      municipalRegistrationLabel: 'Registro Municipal:',
      municipalRegistrationPlaceholder: 'Entrar en el registro municipal',
      stateRegistrationLabel: 'Inscripción Estadual:',
      stateRegistrationPlaceholder: 'Ingrese el registro estatal',
      externalCodeLabel: 'Código Externo:',
      externalCodePlaceholder: 'Introducir código externo',
      addressLabel: 'Dirección:',
      addressPlaceholder: 'Introduce la dirección',
      finishButton: 'Aplicar',
      cleanButton: 'Limpio',
    },
  },
}
