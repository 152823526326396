/* eslint-disable no-restricted-syntax */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable react/jsx-curly-newline */
import React from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { Tabs } from 'antd'
import { PageHeader } from '@ant-design/pro-components'

import ScaleCalendar from '~/components/DataManipulation/Scales/ScaleCalendar'
import ScaleGeneralForm from '~/components/DataManipulation/Scales/ScaleGeneral/ScaleGeneralForm'
import ScaleContributorsForm from '~/components/DataManipulation/Scales/ScaleContributors/ScaleContributorsForm'
import ScaleTeamsForm from '~/components/DataManipulation/Scales/ScaleTeams/ScaleTeamsForm'
import ScaleClientsForm from '~/components/DataManipulation/Scales/ScaleClients/ScaleClientsForm'
import { TeamsProvider } from '~/hooks/Teams/useListTeams'
import { useScale } from '~/hooks/Scales/useScale'
import { translate } from '~/utils/locale'

const { TabPane } = Tabs

const ScaleTabs: React.FC = () => {
  const history = useHistory()
  const { scaleData } = useScale()

  const { scaleId, tab } = useParams<{ scaleId: string; tab: string }>()

  const handleTabChange = (key: string) => {
    if (key === 'detalhes') {
      history.replace({
        pathname: `/escalas/editar/${scaleId}`,
      })
    } else {
      history.replace({
        pathname: `/escalas/editar/${scaleId}/${key}`,
      })
    }
  }

  return (
    <>
      <PageHeader
        className="site-page-header"
        onBack={() => window.history.back()}
        title={translate('ScalesTabs.pageHeaderTitle')}
        subTitle={scaleData.title}
      />
      <Tabs
        size="large"
        style={{ padding: '0 30px' }}
        onTabClick={(key: string) => handleTabChange(key)}
        defaultActiveKey={tab ? `${tab}` : 'geral'}
      >
        <TabPane tab={translate('ScalesTabs.generalTab')} key="geral">
          <ScaleGeneralForm />
        </TabPane>
        <TabPane tab={translate('ScalesTabs.clientsTab')} key="clientes">
          <ScaleClientsForm />
        </TabPane>
        <TabPane
          tab={translate('ScalesTabs.contributorsTab')}
          key="colaboradores"
        >
          <ScaleContributorsForm />
        </TabPane>
        <TabPane tab={translate('ScalesTabs.teamsTab')} key="equipes">
          <TeamsProvider>
            <ScaleTeamsForm />
          </TeamsProvider>
        </TabPane>
        <TabPane tab={translate('ScalesTabs.schedulesTab')} key="horarios">
          <ScaleCalendar />
        </TabPane>
      </Tabs>
    </>
  )
}

export default ScaleTabs
