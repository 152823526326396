/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable consistent-return */
/* eslint-disable func-names */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, {
  ReactNode,
  useContext,
  createContext,
  useEffect,
  useState,
  SetStateAction,
  Dispatch,
  useCallback,
} from 'react'
import { useQuery } from 'react-query'

import { message } from 'antd'
import { parseISO, format } from 'date-fns'
import dayjs from 'dayjs'

import api from '~/services/api'

import { useCompanies } from '~/hooks/Companies/useCompanies'
import { useLocale } from '~/hooks/locale/useLocale'

import { ShowError } from '~/utils/errors/apiErrors'
import { translate } from '~/utils/locale'

interface ListScalesProviderProps {
  children: ReactNode
}

interface CompanyData {
  id: string
  name: string
}

interface Scale {
  company: CompanyData
  contributorsSchedules: any[]
  created_at: string
  customersSchedules: any[]
  id: number
  scheduleTimes: any[]
  dateRange?: string
  title: string
}

interface ScaleInput {
  title: string
  description: string
}

interface FilterOptionsProps {
  contactCustomer?: string
  contactCollaborator?: string
  title?: number
  fromDay?: string | number | Date | dayjs.Dayjs | null | undefined
  toDay?: string | number | Date | dayjs.Dayjs | null | undefined
}

interface ListScalesContextData {
  scalesRefetch: () => void
  createScale: (status: ScaleInput) => Promise<void>
  deleteScale(scheduleId: string): void
  handleUpdateFilters: (newFilterOptions: any) => void
  handleResetFilters: () => void
  scalesData: Scale[]
  scalesTotal: number
  previous: boolean
  next: boolean
  setPageSize: Dispatch<SetStateAction<number>>
  setNumberPage: Dispatch<SetStateAction<number>>
  filterOptions: FilterOptionsProps
  isLoading: boolean
  isFetching: boolean
}

const ListScalesContext = createContext<ListScalesContextData>(
  {} as ListScalesContextData,
)

export function ListScalesProvider({ children }: ListScalesProviderProps) {
  const { selectedCompany } = useCompanies()
  const { locale } = useLocale()

  const [filterOptions, setFilterOptions] = useState<FilterOptionsProps>(() => {
    const filter = localStorage.getItem('@Gstor:scalesFilterOptions')

    if (filter) {
      return JSON.parse(filter)
    }

    return {} as FilterOptionsProps
  })
  const [scalesTotal, setScalesTotal] = useState(0)
  const [previous, setPrevious] = useState(false)
  const [next, setNext] = useState(false)
  const [pageSize, setPageSize] = useState(10)
  const [numberPage, setNumberPage] = useState(1)

  const { data, isLoading, isFetching, refetch } = useQuery(
    ['scales', selectedCompany, pageSize, numberPage, filterOptions],
    async () => {
      try {
        if (!selectedCompany) {
          return undefined
        }

        const paginationLimit = numberPage * pageSize
        const paginationOffset = paginationLimit - pageSize

        const response = await api.get(
          `/company/${selectedCompany}/schedules`,
          {
            params: {
              limit: paginationLimit,
              offset: paginationOffset,
              contactCustomer: filterOptions?.contactCustomer,
              contactCollaborator: filterOptions?.contactCollaborator,
              title: filterOptions?.title,
              fromDay: filterOptions?.fromDay?.toString(),
              toDay: filterOptions?.toDay?.toString(),
            },
          },
        )

        const scales = response.data.results

        scales.forEach((scale: Scale) => {
          // eslint-disable-next-line func-names
          if (scale.scheduleTimes.length > 0) {
            scale.scheduleTimes.sort(function (a: any, b: any) {
              return Date.parse(a.day) - Date.parse(b.day)
            })

            const firstValue = parseISO(scale.scheduleTimes[0].day)
            const lastValue = parseISO(
              scale.scheduleTimes[scale.scheduleTimes.length - 1].day,
            )
            const stringFirstValue = format(firstValue, 'dd/MM/yyyy')
            const stringLastValue = format(lastValue, 'dd/MM/yyyy')

            // eslint-disable-next-line
            scale.dateRange = `${translate('useListScales.dateRangeOne')} ${stringFirstValue} - ${stringLastValue}`;
          } else {
            // eslint-disable-next-line
            scale.dateRange = translate('useListScales.dateRangeTwo');
          }
        })

        setScalesTotal(response.data.total)
        setPrevious(response.data.previous)
        setNext(response.data.next)

        return scales
      } catch (err: any) {
        ShowError(
          err.message,
          translate('useListScales.getErrorMessage'),
          locale,
        )
      }
    },
    {
      staleTime: 5000, // 5 segundos
    },
  )

  useEffect(() => {
    refetch()
  }, [selectedCompany, refetch])

  const handleResetFilters = useCallback(() => {
    localStorage.removeItem('@Gstor:scalesFilterOptions')
    setFilterOptions({})
    setNumberPage(1)
  }, [])

  const handleUpdateFilters = useCallback((newFilterOptions) => {
    const newFilters = {
      contactCustomer: newFilterOptions?.contactCustomer,
      contactCollaborator: newFilterOptions?.contactCollaborator,
      title: newFilterOptions?.title,
      fromDay: dayjs(newFilterOptions?.fromDay).startOf('day'),
      toDay: dayjs(newFilterOptions?.toDay).endOf('day'),
    }

    setFilterOptions(newFilters)
    localStorage.setItem(
      '@Gstor:scalesFilterOptions',
      JSON.stringify(newFilters),
    )
    setNumberPage(1)
  }, [])

  async function createScale(scale: ScaleInput) {
    try {
      await api.post(`/company/${selectedCompany}/schedule`, scale)

      refetch()
      message.success(translate('useListScales.createSuccessMessage'))
    } catch (err: any) {
      ShowError(
        err.message,
        translate('useListScales.createErrorMessage'),
        locale,
      )
    }
  }

  async function deleteScale(scheduleId: string) {
    try {
      await api.delete(`/company/${selectedCompany}/schedule/${scheduleId}`)

      refetch()
      message.success(translate('useListScales.deleteSuccessMessage'))
    } catch (err: any) {
      ShowError(
        err.message,
        translate('useListScales.deleteErrorMessage'),
        locale,
      )
    }
  }

  return (
    <ListScalesContext.Provider
      value={{
        scalesData: data,
        filterOptions,
        scalesTotal,
        previous,
        next,
        setNumberPage,
        setPageSize,
        handleUpdateFilters,
        handleResetFilters,
        createScale,
        deleteScale,
        isLoading,
        isFetching,
        scalesRefetch: refetch,
      }}
    >
      {children}
    </ListScalesContext.Provider>
  )
}

export function useListScales() {
  const context = useContext(ListScalesContext)

  return context
}
