/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react'

import { Drawer, Form, Button, Col, Row, Input, Select, Tooltip } from 'antd'
import { CloseOutlined, FilterOutlined } from '@ant-design/icons'

import { Container } from './styles'

import { useSegments } from '~/hooks/Segments/useSegments'
import { useListContributors } from '~/hooks/Contributors/useListContributors'
import { useListUsers } from '~/hooks/Users/useListUsers'
import { translate } from '~/utils/locale'

const UsersFilter: React.FC = () => {
  const { handleUpdateFilters, handleResetFilters, filterOptions } =
    useListUsers()
  const { segments } = useSegments()
  const { data } = useListContributors()

  const [visible, setVisible] = useState(false)

  const [form] = Form.useForm()

  useEffect(() => {
    form.setFieldsValue({
      contributors_id: filterOptions?.contributors_id,
      contact: filterOptions?.contact,
      email: filterOptions.email,
      group: filterOptions.group,
    })
  }, [filterOptions, form])

  const showDrawer = () => {
    setVisible(true)
  }

  const onClose = () => {
    setVisible(false)
  }

  const onFinish = (dataForm: any) => {
    handleUpdateFilters(dataForm)
    setVisible(false)
  }

  const onReset = () => {
    handleResetFilters()
    form.resetFields()
    setVisible(false)
  }

  const isFilter = Object.keys(filterOptions).length !== 0

  return (
    <Container isFilter={isFilter}>
      <Button
        type="primary"
        onClick={showDrawer}
        loading={!data || !segments}
        icon={
          !isFilter ? (
            <FilterOutlined />
          ) : (
            <Tooltip title={translate('UsersFilter.filterTooltip')}>
              <CloseOutlined onClick={onReset} />
            </Tooltip>
          )
        }
        id="btn_filter_users"
      >
        {isFilter
          ? translate('UsersFilter.filterAppliedText')
          : translate('UsersFilter.filterText')}
      </Button>
      <Drawer
        forceRender
        title={translate('UsersFilter.drawerTitle')}
        width={580}
        onClose={onClose}
        open={visible}
        style={{ paddingBottom: 80 }}
      >
        <Form layout="vertical" onFinish={onFinish} form={form}>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="contact"
                label={translate('UsersFilter.contactLabel')}
                tooltip={
                  <p>
                    {translate('UsersFilter.contactInfoOne')}
                    &nbsp;
                    <b>{translate('UsersFilter.contactInfoTwo')}</b>.
                  </p>
                }
              >
                <Input
                  placeholder={translate('UsersFilter.contactPlaceholder')}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="email"
                label={translate('UsersFilter.emailLabel')}
              >
                <Input
                  placeholder={translate('UsersFilter.emailPlaceholder')}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="group"
                label={translate('UsersFilter.groupLabel')}
              >
                <Input
                  placeholder={translate('UsersFilter.groupPlaceholder')}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="contributors_id"
                label={translate('UsersFilter.contributorLabel')}
              >
                <Select
                  placeholder={translate('UsersFilter.contributorPlaceholder')}
                  mode="multiple"
                >
                  {data?.map((contribuitor) => (
                    <Select.Option
                      value={contribuitor.id}
                      key={contribuitor.id}
                    >
                      {contribuitor.contact.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item style={{ width: '100%' }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: '100%' }}
                >
                  {translate('UsersFilter.applyButton')}
                </Button>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item style={{ width: '100%' }}>
                <Button onClick={onReset} style={{ width: '100%' }}>
                  {translate('UsersFilter.cleanButton')}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </Container>
  )
}

export default UsersFilter
