export default {
  pt: {
    HelpDeskShowPage: {
      helmetTitle: 'Visualizar Ticket',
    },
  },
  en: {
    HelpDeskShowPage: {
      helmetTitle: 'View Ticket',
    },
  },
  es: {
    HelpDeskShowPage: {
      helmetTitle: 'Ver Ticket',
    },
  },
}
