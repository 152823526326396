export default {
  pt: {
    ClientsParentTable: {
      reasonColumn: 'Razão Social/Nome',
      fantasyNameColumn: 'Cliente',
      documentColumn: 'CNPJ',
      emailColumn: 'E-mail',
      nameColumn: 'Nome',
      clientTooltip: 'Ver cliente',
      actionsColumn: 'Ações',
      deletePopconfirm: 'Deseja mesmo deletar o cliente?',
      deleteTooltip: 'Deletar',
      deleteClientMessage: 'Clientes deletados com sucesso!',
      deleteClientTitle: 'Deseja excluir os clientes?',
      deleteClientsText: 'clientes selecionados',
      deleteClientText: '1 cliente selecionado',
    },
  },
  en: {
    ClientsParentTable: {
      reasonColumn: 'Corporate Reason',
      fantasyNameColumn: 'Fantasy Name',
      documentColumn: 'Document',
      emailColumn: 'Email',
      nameColumn: 'Name',
      clientTooltip: 'View customer',
      actionsColumn: 'Actions',
      deletePopconfirm: 'Do you really want to delete the customer?',
      deleteTooltip: 'Delete',
      deleteClientMessage: 'Clients deleted successfully!',
      deleteClientTitle: 'Do you want to delete customers?',
      deleteClientsText: 'selected customers',
      deleteClientText: '1 selected customer',
    },
  },
  es: {
    ClientsParentTable: {
      reasonColumn: 'Razón Corporativa',
      fantasyNameColumn: 'Cliente',
      documentColumn: 'Documento',
      emailColumn: 'E-mail',
      nameColumn: 'Cliente',
      clientTooltip: 'Ver cliente',
      actionsColumn: 'Acciones',
      deletePopconfirm: '¿Realmente desea eliminar el cliente?',
      deleteTooltip: 'Borrar',
      deleteClientMessage: '¡Clientes eliminados exitosamente!',
      deleteClientTitle: '¿Quieres eliminar clientes?',
      deleteClientsText: 'clientes seleccionados',
      deleteClientText: '1 cliente seleccionado',
    },
  },
}
