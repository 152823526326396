import { Card, Space } from 'antd'
import React from 'react'
import ModalParentClient from './Modal'
import DetailsParentCustomerInClient from './Details'

// import { Container } from './styles';

const ParentClientTab: React.FC = () => {
  return (
    <Card
      bordered={false}
      title="Cliente Principal"
      // loading={isLoading || !clients}
      style={{
        display: 'inline-block',
        width: '100%',
      }}
      extra={
        <Space size="middle">
          <ModalParentClient />
        </Space>
      }
    >
      <DetailsParentCustomerInClient />
    </Card>
  )
}

export default ParentClientTab
