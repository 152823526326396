/* eslint-disable react/require-default-props */
import React, { ReactNode, useState } from 'react'

import { Button, Popconfirm, Tooltip } from 'antd'
import { DeleteFilled } from '@ant-design/icons'

interface DeleteButtonProps {
  confirmLoading: boolean
  itemId: string
  // eslint-disable-next-line no-unused-vars
  handleDelete(id: string): Promise<void>
  PopconfirmText: string | ReactNode
  TooltipText: string
  setClose?: React.Dispatch<React.SetStateAction<boolean>>
}

const DeleteButton: React.FC<DeleteButtonProps> = ({
  confirmLoading,
  itemId,
  handleDelete,
  PopconfirmText,
  TooltipText,
  setClose,
}) => {
  const [visible, setVisible] = useState(false)

  const showPopconfirm = () => {
    setVisible(true)
  }

  const handleCancel = () => {
    setVisible(false)
  }

  const onDelete = async () => {
    await handleDelete(itemId)
    if (setClose) {
      setClose(true)
    }
    setVisible(false)
  }

  return (
    <Popconfirm
      title={PopconfirmText}
      okButtonProps={{ loading: confirmLoading }}
      onConfirm={() => onDelete()}
      open={visible}
      onCancel={handleCancel}
    >
      <Tooltip title={TooltipText}>
        <Button
          onClick={showPopconfirm}
          danger
          ghost
          shape="circle"
          icon={<DeleteFilled />}
        />
      </Tooltip>
    </Popconfirm>
  )
}

export default DeleteButton
