import React from 'react'

import { ColumnsType } from 'antd/lib/table'
import { Popconfirm, Space, Table, Tooltip, Button, Tag } from 'antd'
import {
  DeleteFilled,
  LoadingOutlined,
  ProfileOutlined,
} from '@ant-design/icons'

import Text from 'antd/lib/typography/Text'
import { Link } from 'react-router-dom'
import { useListStatus } from '~/hooks/Status/useListStatus'
import { translate } from '~/utils/locale'

interface Contribuitors {
  id: string
  contact: {
    id: string
    name: string
  }
}

interface ListStatusProps {
  title: string
  id: string
  type: string
  color: string
  description: string
  contributorsTeams: Contribuitors[]
}
const StatusTable: React.FC = () => {
  const { listStatus, isFetching, deleteStatus } = useListStatus()

  const columns: ColumnsType<ListStatusProps> = [
    {
      title: translate('StatusTable.titleColumn'),
      align: 'left',
      key: 'title',
      dataIndex: 'title',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (name: string, record) => (
        <Link to={`/status/visualizar/${record.id}`}>
          <Tag color={record.color !== null ? `${record.color}` : '#108ee9'}>
            {name}
          </Tag>
        </Link>
      ),
    },
    {
      title: translate('StatusTable.statusTypeColumn'),
      align: 'center',
      key: 'type',
      render: (record) => (
        <>
          {record.type === 'enabled' ? (
            <Text>{translate('StatusTable.enabledText')}</Text>
          ) : (
            ''
          )}
          {record.type === 'enabled_with_restrictions' ? (
            <Text>{translate('StatusTable.enabledWithRestrictionsText')}</Text>
          ) : (
            ''
          )}
          {record.type === 'blocked' ? (
            <Text>{translate('StatusTable.blockedText')}</Text>
          ) : (
            ''
          )}
          {record.type === 'custom' ? (
            <Text>{translate('StatusTable.customText')}</Text>
          ) : (
            ''
          )}
        </>
      ),
    },
    {
      title: translate('StatusTable.descriptionColumn'),
      align: 'center',
      key: 'description',
      dataIndex: 'description',
    },
    {
      title: translate('StatusTable.actionsColumn'),
      align: 'center',
      key: 'remove',
      render: (record) => (
        <Space size="middle">
          <Tooltip title={translate('StatusTable.viewTooltip')}>
            <Link to={`/status/visualizar/${record.id}`}>
              <Button
                type="primary"
                ghost
                shape="circle"
                icon={<ProfileOutlined />}
              />
            </Link>
          </Tooltip>
          {record.type !== 'custom' ? (
            ''
          ) : (
            <Popconfirm
              title={translate('StatusTable.deletePopconfirm')}
              onConfirm={() => deleteStatus(record.id)}
            >
              <Tooltip title={translate('StatusTable.deleteTooltip')}>
                <Button ghost danger shape="circle" icon={<DeleteFilled />} />
              </Tooltip>
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ]

  return (
    <Table
      rowKey={(record) => record.id}
      loading={{
        indicator: <LoadingOutlined />,
        spinning: isFetching,
        size: 'large',
      }}
      columns={columns}
      dataSource={listStatus}
      scroll={{ x: 1000 }}
      pagination={{
        showTotal: (total, range) => `${range[0]} - ${range[1]} / ${total}`,
      }}
    />
  )
}

export default StatusTable
