export default {
  pt: {
    EquipmentsTemplateCreate: {
      newTemplateButton: 'Novo Template',
      modalTitle: 'Novo Template',
      closeButton: 'Fechar',
      saveButton: 'Salvar',
      nameLabel: 'Nome do Template',
      nameRule: 'Por favor informe o nome do template.',
      namePlaceholder: 'Informe o nome do template',
      eraseTooltip: 'Apagar atributo',
      attributeLabel: 'Atributo',
      attributeRule: 'Por favor informe o atributo.',
      attributePlaceholder: 'Informe o atributo',
      valueLabel: 'Valor',
      valueRule: 'Por favor informe o valor.',
      valuePlaceholder: 'Informe um valor',
      addButton: 'Adicionar Atributo',
    },
  },
  en: {
    EquipmentsTemplateCreate: {
      newTemplateButton: 'New Template',
      modalTitle: 'New Template',
      closeButton: 'Close',
      saveButton: 'Save',
      nameLabel: 'Template Name',
      nameRule: 'Please enter the name of the template.',
      namePlaceholder: 'Enter the name of the template',
      eraseTooltip: 'Delete Attribute',
      attributeLabel: 'Attribute',
      attributeRule: 'Please enter the attribute.',
      attributePlaceholder: 'Inform the attribute',
      valueLabel: 'Value',
      valueRule: 'Please inform the value.',
      valuePlaceholder: 'Enter a value',
      addButton: 'Add Attribute',
    },
  },
  es: {
    EquipmentsTemplateCreate: {
      newTemplateButton: 'Nueva plantilla',
      modalTitle: 'Nueva plantilla',
      closeButton: 'Cercar',
      saveButton: 'Guardar',
      nameLabel: 'Nombre de la plantilla',
      nameRule: 'Introduzca el nombre de la plantilla.',
      namePlaceholder: 'Introduce el nombre de la plantilla.',
      eraseTooltip: 'Eliminar Atributo',
      attributeLabel: 'Atributo',
      attributeRule: 'Ingrese el atributo.',
      attributePlaceholder: 'Informar el atributo',
      valueLabel: 'Valor',
      valueRule: 'Por favor informe el valor.',
      valuePlaceholder: 'Introduce un valor',
      addButton: 'Agregar atributo',
    },
  },
}
