export default {
  pt: {
    Groups: {
      helmetTitle: 'Permissões',
    },
  },
  en: {
    Groups: {
      helmetTitle: 'Permissions',
    },
  },
  es: {
    Groups: {
      helmetTitle: 'Permisos',
    },
  },
}
