import {
  Alert,
  Button,
  Card,
  Divider,
  Flex,
  Select,
  Space,
  Typography,
} from 'antd'
import React from 'react'
import { useTaskForms } from '~/hooks/Tasks/Forms/useTaskForms'
import { useTask } from '~/hooks/Tasks/useTask'
import TaskTypeForm from './taskTypeForm'
import FormsWithAnswers from './FormsWithAnswers'
import { Link } from 'react-router-dom'

const FormsTask: React.FC = () => {
  const { task, updateTask } = useTask()
  const { forms, isLoading, limit, changeLimit, formsTotal } = useTaskForms()

  if (!task || !forms || isLoading) return <Card loading />

  if (!formsTotal || formsTotal === 0) {
    return (
      <Card>
        <Alert
          message="Sem formulários criados. Clique no botão para criar um formulário."
          type="info"
        />
        <div style={{ marginTop: '1rem' }}>
          <Link to={`/formularios/novo`} target="_blank">
            <Button type="primary">Criar Formulário</Button>
          </Link>
        </div>
      </Card>
    )
  }

  const handleChange = (values: Array<string>) => {
    const data = {
      forms: values.map((formId) => ({ form_id: formId })),
    }
    updateTask(data)
  }

  const listForm: Array<{ form_id: string }> = []

  if (task.forms) {
    listForm.push(...task.forms)
  }

  if (task.taskType && task.taskType.forms) {
    listForm.push(...task.taskType.forms)
  }
  return (
    <Flex vertical gap={'1rem'}>
      <Card>
        <Typography.Title level={4}>Formulários</Typography.Title>
      </Card>
      <Card
        bordered={false}
        title={''}
        style={{
          display: 'inline-block',
          width: '100%',
        }}
      >
        <Flex vertical gap={'2rem'}>
          <Card title="Formulários da tarefa">
            <Select
              mode="multiple"
              placeholder="Selecione um ou mais formulários"
              onChange={handleChange}
              defaultValue={
                task.forms && task.forms.map((form) => form.form_id)
              }
              options={forms.map((form) => ({
                label: form.title,
                value: form.id,
              }))}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  {limit < formsTotal && (
                    <>
                      <Divider style={{ margin: '8px 0' }} />
                      <Space style={{ padding: '0 8px 4px' }}>
                        <Button
                          type="text"
                          onClick={() => changeLimit(limit + 100)}
                        >
                          Carregar mais
                        </Button>
                      </Space>
                    </>
                  )}
                </>
              )}
              style={{ width: '100%' }}
            />
          </Card>
          {task.taskType.forms && task.taskType.forms.length > 0 && (
            <Card title="Formulários herdados do tipo de tarefa">
              {task.taskType.forms.map((form) => (
                <TaskTypeForm key={form.form_id} formId={form.form_id} />
              ))}
            </Card>
          )}
        </Flex>
      </Card>

      <FormsWithAnswers forms={listForm} />
    </Flex>
  )
}

export default FormsTask
