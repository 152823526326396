export default {
  pt: {
    ScalesContributorsList: {
      successMessage: 'Colaborador desvinculado da escala com sucesso!',
      errorMessage: 'Erro ao desvincular colaborador da escala.',
      deletePopconfirm: 'Deseja mesmo desatribuir o colaborador da escala?',
      deleteTooltip: 'Desatribuir Colaborador',
    },
  },
  en: {
    ScalesContributorsList: {
      successMessage: 'Contributor successfully unlinked from the scale!',
      errorMessage: 'Error unlinking contributor from the scale.',
      deletePopconfirm:
        'Do you really want to unassign the contributor from the scale?',
      deleteTooltip: 'Unassign Contributor',
    },
  },
  es: {
    ScalesContributorsList: {
      successMessage: '¡Colaborador desvinculado con éxito de la escala!',
      errorMessage: 'Error al desvincular colaborador de la escala.',
      deletePopconfirm:
        '¿Realmente quieres desasignar al colaborador de la báscula?',
      deleteTooltip: 'Desasignar colaborador',
    },
  },
}
