/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react'

import {
  Button,
  Modal,
  Form,
  Input,
  Row,
  Col,
  Select,
  Checkbox,
  Space,
} from 'antd'
import { PlusCircleOutlined } from '@ant-design/icons'
import { Container, ContainerButton } from './styles'

import { useTeams } from '~/hooks/Teams/useListTeams'
import { useListContributors } from '~/hooks/Contributors/useListContributors'
import { translate } from '~/utils/locale'

const CreateTeam: React.FC = () => {
  const { createTeams } = useTeams()
  const { data } = useListContributors()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [keepRegister, setKeepRegister] = useState(false)

  const [form] = Form.useForm()

  function onCreateTeam(valueForm: any) {
    try {
      createTeams(valueForm)
      if (!keepRegister) {
        setIsModalVisible(false)
      }
      form.resetFields()
    } catch (err) {
      console.log(err)
    }
  }

  const showModal = () => {
    setIsModalVisible(true)
  }

  const closeModal = () => {
    setIsModalVisible(false)
  }

  return (
    <Container>
      <Button type="primary" onClick={showModal} icon={<PlusCircleOutlined />}>
        {translate('TeamsCreate.newButton')}
      </Button>
      <Modal
        title={translate('TeamsCreate.modalTitle')}
        open={isModalVisible}
        onOk={closeModal}
        onCancel={closeModal}
        width="600px"
        footer={[
          <Space key="buttons">
            <Checkbox
              key="KeepRegister"
              onChange={(e) => {
                setKeepRegister(e.target.checked)
              }}
              checked={keepRegister}
            >
              {translate('TeamsCreate.keepRegisterText')}
            </Checkbox>
            <Button key="back" onClick={closeModal}>
              {translate('TeamsCreate.closeButton')}
            </Button>
            <ContainerButton>
              <Button
                form="teamForm"
                key="save"
                type="primary"
                htmlType="submit"
                id="btn_create_team"
              >
                {translate('TeamsCreate.registerButton')}
              </Button>
            </ContainerButton>
          </Space>,
        ]}
      >
        <Form
          id="teamForm"
          layout="vertical"
          onFinish={onCreateTeam}
          form={form}
        >
          <Row gutter={14}>
            <Col span={12}>
              <Form.Item
                label={translate('TeamsCreate.titleLabel')}
                name="title"
                rules={[
                  {
                    required: true,
                    message: translate('TeamsCreate.titleRule'),
                  },
                ]}
              >
                <Input
                  placeholder={translate('TeamsCreate.titlePlaceholder')}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={translate('TeamsCreate.responsibleLabel')}
                name="collaborator_id"
                rules={[
                  {
                    required: true,
                    message: translate('TeamsCreate.responsibleRule'),
                  },
                ]}
              >
                <Select
                  placeholder={translate('TeamsCreate.responsiblePlaceholder')}
                  optionFilterProp="children"
                  showSearch
                >
                  {data?.map(
                    (d) =>
                      d.contact?.name && (
                        <Select.Option value={d.id} key={d.id}>
                          {d.contact?.name}
                        </Select.Option>
                      ),
                  )}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                label={translate('TeamsCreate.descriptionLabel')}
                name="description"
              >
                <Input.TextArea
                  placeholder={translate('TeamsCreate.descriptionPlaceholder')}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </Container>
  )
}

export default CreateTeam
