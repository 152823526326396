export default {
  pt: {
    EditScale: {
      helmetTitle: 'Permissões',
      resultTitle: 'Erro ao buscar informações',
      resultSubTitle: 'Por favor atualize a página para tentar novamente.',
      buttonText: 'Atualizar página',
    },
  },
  en: {
    EditScale: {
      helmetTitle: 'Permissions',
      resultTitle: 'Error fetching information',
      resultSubTitle: 'Please refresh the page to try again.',
      buttonText: 'Refresh Page',
    },
  },
  es: {
    EditScale: {
      helmetTitle: 'Permisos',
      resultTitle: 'Error al obtener información',
      resultSubTitle: 'Actualice la página para volver a intentarlo.',
      buttonText: 'Actualizar página',
    },
  },
}
