import styled from 'styled-components'

export const Container = styled.div`
  .leaflet-popup-content-wrapper {
    border-radius: 5px;
  }

  .leaflet-popup-content {
    margin: 0px;
    width: 300px;
  }

  .leaflet-popup-content p {
    padding: 2px 10px 6px 10px;
    margin: 0px;
  }

  .leaflet-container a.leaflet-popup-close-button {
    color: var(--color-white);
  }

  .leaflet-popup-content-wrapper {
    padding: 0px;
  }

  .ant-rate {
    font-size: 10px;
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 0 10px 10px 10px;
  }

  .ant-divider-horizontal {
    margin: 10px 0;
  }

  .ant-collapse-ghost
    > .ant-collapse-item
    > .ant-collapse-content
    > .ant-collapse-content-box,
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
  }
`

export const PopupHeader = styled.div`
  background: var(--primary-color);
  padding: 8px;

  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  position: relative;
  bottom: 5px;

  display: flex;
  align-items: center;

  a {
    font-weight: bold;
    color: var(--color-white);
    margin-bottom: 0;
    margin-right: 10px;
  }
`

export const Content = styled.div`
  .text {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .avaliation {
    display: flex;
    align-items: center;
  }
`
export const Loading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.8rem;

  h4 {
    margin: 0;
  }
`
