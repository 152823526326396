export default {
  pt: {
    StatusComponent: {
      cardTitle: 'Status',
      totalText: 'Total de',
      statusText: 'status',
    },
  },
  en: {
    StatusComponent: {
      cardTitle: 'Status',
      totalText: 'Total of',
      statusText: 'status',
    },
  },
  es: {
    StatusComponent: {
      cardTitle: 'Estado',
      totalText: 'Total de',
      statusText: 'estado',
    },
  },
}
