/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  useContext,
  createContext,
  useState,
  useCallback,
  useEffect,
  Dispatch,
  SetStateAction,
} from 'react'
import { message } from 'antd'

import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import api from '~/services/api'

import { useCompanies } from '~/hooks/Companies/useCompanies'
import { useLocale } from '~/hooks/locale/useLocale'

import { ShowError } from '~/utils/errors/apiErrors'
import { translate } from '~/utils/locale'

interface ContactProps {
  name: string
  id: number
  email: string
  office: string
  phone: string
  is_responsible: boolean
  customer: {
    id: string
    contact: {
      id: string
    }
  }
}

interface ContactClientsProps {
  contact: {
    name: string
    id: string
    email: string
    phone: string
  }
  office: string
  is_responsible: boolean
}

interface FilterOptionsProps {
  contact?: string
  office?: string
  is_responsible?: boolean
}

interface ListContactsContextData {
  listContacts?: ContactProps[]
  createContacts(dataContact: object | undefined): void
  updateContact(dataContact: object | undefined, contactId: string): void
  deleteContact(contactId: string): void
  isLoading: boolean
  refetch: () => void
  isFetching: boolean
  filterOptions: FilterOptionsProps
  handleUpdateFilters: (newFilterOptions: any) => void
  handleResetFilters: () => void
  contactTotal: number
  previous: boolean
  next: boolean
  setPageSize: Dispatch<SetStateAction<number>>
  setNumberPage: Dispatch<SetStateAction<number>>
  contacts: ContactClientsProps[]
}

const ListContactsContext = createContext<ListContactsContextData>(
  {} as ListContactsContextData,
)

export const ListContactsProvider: React.FC = ({ children }) => {
  const { selectedCompany } = useCompanies()
  const { locale } = useLocale()
  const { customerId } = useParams<{ customerId: string }>()

  const [contacts, setContacts] = useState<ContactClientsProps[]>([])

  const [contactTotal, setContactTotal] = useState(0)
  const [previous, setPrevious] = useState(false)
  const [next, setNext] = useState(false)
  const [pageSize, setPageSize] = useState(10)
  const [numberPage, setNumberPage] = useState(1)

  const [filterOptions, setFilterOptions] = useState<FilterOptionsProps>(() => {
    const filter = localStorage.getItem('@Gstor:contactsFilterOptions')

    if (filter) {
      return JSON.parse(filter)
    }

    return {} as FilterOptionsProps
  })

  const {
    data: listContacts,
    isLoading,
    refetch,
    isFetching,
  } = useQuery(
    [
      `Lista de Contatos${selectedCompany}`,
      selectedCompany,
      filterOptions,
      pageSize,
      numberPage,
    ],

    async () => {
      try {
        if (!selectedCompany) {
          return undefined
        }

        const paginationLimit = numberPage * pageSize
        const paginationOffset = paginationLimit - pageSize

        const response = await api.get(
          `/company/${selectedCompany}/customer/${customerId}/customer-contacts`,
          {
            params: {
              contact: filterOptions?.contact,
              office: filterOptions?.office,
              is_responsible: filterOptions?.is_responsible,
              limit: paginationLimit,
              offset: paginationOffset,
            },
          },
        )

        const { data } = response

        setContactTotal(response.data.total)
        setPrevious(response.data.previous)
        setNext(response.data.next)

        setContacts(data.results)
        return data.results
      } catch (err: any) {
        ShowError(
          err.message,
          translate('useListContacts.getErrorMessage'),
          locale,
        )
      }
    },
    {
      staleTime: 5000,
    },
  )

  async function createContacts(dataContact: object) {
    try {
      await api.post(
        `/company/${selectedCompany}/customer/${customerId}/customer-contact`,
        dataContact,
      )

      refetch()
      message.success(translate('useListContacts.createSuccessMessage'))
    } catch (err: any) {
      ShowError(
        err.message,
        translate('useListContacts.createErrorMessage'),
        locale,
      )
    }
  }

  async function updateContact(dataContact: object, contactId: string) {
    try {
      await api.put(
        `/company/${selectedCompany}/customer/${customerId}/customer-contact/${contactId}`,
        dataContact,
      )

      refetch()
      message.success(translate('useListContacts.updateSuccessMessage'))
    } catch (err: any) {
      ShowError(
        err.message,
        translate('useListContacts.updateErrorMessage'),
        locale,
      )
    }
  }

  async function deleteContact(contactId: string) {
    try {
      await api.delete(
        `/company/${selectedCompany}/customer/${customerId}/customer-contact/${contactId}`,
      )

      refetch()
      message.success(translate('useListContacts.deleteSuccessMessage'))
    } catch (err: any) {
      ShowError(
        err.message,
        translate('useListContacts.deleteErrorMessage'),
        locale,
      )
    }
  }

  const handleUpdateFilters = useCallback((newFilterOptions) => {
    const newFilters = {
      contact: newFilterOptions?.contact,
      office: newFilterOptions?.office,
      is_responsible: newFilterOptions?.is_responsible,
    }
    setFilterOptions(newFilters)
    localStorage.setItem(
      '@Gstor:contactsFilterOptions',
      JSON.stringify(newFilters),
    )
    setNumberPage(1)
  }, [])

  const handleResetFilters = useCallback(() => {
    localStorage.removeItem('@Gstor:contactsFilterOptions')
    setFilterOptions({})
    setNumberPage(1)
  }, [])

  useEffect(() => {
    refetch()
  }, [selectedCompany, refetch])

  return (
    <ListContactsContext.Provider
      value={{
        listContacts,
        createContacts,
        updateContact,
        deleteContact,
        isLoading,
        refetch,
        isFetching,
        filterOptions,
        handleUpdateFilters,
        handleResetFilters,
        contactTotal,
        previous,
        next,
        contacts,
        setNumberPage,
        setPageSize,
      }}
    >
      {children}
    </ListContactsContext.Provider>
  )
}

export function useListContacts(): ListContactsContextData {
  const context = useContext(ListContactsContext)

  if (!context) {
    throw new Error('useAuth must be used within an AutoProvider')
  }

  return context
}
