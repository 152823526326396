/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-one-expression-per-line */
import { Card, Space, Tabs } from 'antd'
import type { TabsProps } from 'antd'
import React, { useEffect, useState } from 'react'
import { TaskListProps } from '~/hooks/Tasks/useListTasks'
import { useTaskType } from '~/hooks/Tasks/TaskType/useTaskType'
import TasksTypesTable from '~/components/DataManipulation/Dashboard/Tasks/TasksTypesTable'
import TasksValueTable from '~/components/DataManipulation/Dashboard/Tasks/TasksValueTable'
import TasksByContributors from '~/components/DataManipulation/Dashboard/Tasks/TasksByContributors'
import TasksByCustomers from '~/components/DataManipulation/Dashboard/Tasks/TasksByCustomers'
import TasksByParentCustomers from '~/components/DataManipulation/Dashboard/Tasks/TasksByParentCustomers'
import TasksToExcel from '~/components/DataManipulation/Dashboard/Tasks/TasksToExcel'
import DashboardTasksFilter from '~/components/DataManipulation/Dashboard/Tasks/Filter'
import { LoadingIndicator } from './styles'
import { LoadingOutlined } from '@ant-design/icons'
import Dashboard from './Dashboard'
import {
  DashboardTasksDataProps,
  // useDashboardTasks,
} from '~/hooks/Dashboard/useDashboardTasks'

interface TaskType {
  _id: string
  title: string
}

interface TasksValue {
  title: string
  count: number | undefined
  totalValue: number | undefined
}

interface ContributorTaskValueProps {
  nome: string
  totalTarefas: number
  totalMedia: number
  valorTotal: number
  tasks: {
    _id: string
    date: string
    customer: string
    parent_customer: string
    value: number
  }[]
}

interface CustomerTaskValueProps {
  nome: string
  totalTarefas: number
  totalMedia: number
  valorTotal: number
  tasks: {
    _id: string
    date: string
    customer: string
    parent_customer: string
    value: number
  }[]
}

interface ParentCustomerTaskValueProps {
  _id: number
  nome: string
  totalTarefas: number
  totalMedia: number
  valorTotal: number
  tasks: {
    _id: string
    date: string
    customer: string
    parent_customer: string
    value: number
  }[]
}

const DashboardTasksGraphs: React.FC = () => {
  // const { data, isLoading } = useDashboardTasks()
  const data = {} as DashboardTasksDataProps
  const isLoading = false
  const { taskType } = useTaskType()

  const [valueTableLoading, setValueTableLoading] = useState(true)
  const [contributorTasksValue, setContributorTasksValue] = useState<
    ContributorTaskValueProps[]
  >([])
  const [customerTasksValue, setCustomerTasksValue] = useState<
    CustomerTaskValueProps[]
  >([])
  const [parentCustomerTasksValue, setParentCustomerTasksValue] = useState<
    ParentCustomerTaskValueProps[]
  >([])
  const [tasksValue, setTasksValue] = useState<TasksValue[]>([])
  const [totalGeral, setTotalGeral] = useState<TasksValue>({
    title: 'Total Geral',
    count: 0,
    totalValue: 0,
  })

  useEffect(() => {
    if (!isLoading && data?.filteredTasks && taskType) {
      const tarefasColaboradores = data.ContributorTasks
      const tarefasClientes = data.CustomersTasks
      const tarefasClientesPai = data.ParentCustomerTasks

      setContributorTasksValue(tarefasColaboradores)
      setCustomerTasksValue(tarefasClientes)
      setParentCustomerTasksValue(tarefasClientesPai)

      setValueTableLoading(false)
    }

    const taskTypeData = taskType?.map(({ _id, title }: TaskType) => {
      const tasksOfType = data?.filteredTasks?.filter(
        (task: TaskListProps) => task.task_type_id === _id,
      )
      const count = tasksOfType?.length
      const totalValue = tasksOfType?.reduce(
        (acc: number, task: TaskListProps) => acc + (task.value || 0), // Tratamento para value undefined
        0,
      )

      return {
        title,
        count,
        totalValue,
      }
    })

    const totalGeralData: TasksValue = {
      title: 'Total Geral',
      count:
        taskTypeData?.reduce((acc, curr) => acc + (curr.count || 0), 0) || 0,
      totalValue:
        taskTypeData?.reduce((acc, curr) => acc + (curr.totalValue || 0), 0) ||
        0,
    }

    setTotalGeral(totalGeralData)

    setTasksValue(taskTypeData || [])
  }, [taskType, data, isLoading])

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Resumo',
      children:
        data &&
        data.TasksByTypePieChart &&
        data.TasksByValuePieChart &&
        data.TasksTotalLineChart &&
        data.TasksValueLineChart ? (
          <Dashboard />
        ) : (
          <LoadingIndicator>
            <LoadingOutlined style={{ fontSize: 64, margin: 'auto' }} spin />
          </LoadingIndicator>
        ),
    },
    {
      key: '2',
      label: 'Tipo de tarefas realizadas por dias',
      children: (
        <TasksTypesTable taskType={taskType} loading={valueTableLoading} />
      ),
    },
    {
      key: '3',
      label: 'Valor total por tipo de tarefa',
      children: (
        <TasksValueTable
          generalTotal={totalGeral}
          dataSource={tasksValue}
          loading={valueTableLoading}
        />
      ),
    },
    {
      key: '4',
      label: 'Tarefas por Colaboradores',
      children: (
        <TasksByContributors
          contributorsTasks={contributorTasksValue}
          loading={valueTableLoading}
        />
      ),
    },
    {
      key: '5',
      label: 'Tarefas por Clientes',
      children: (
        <TasksByCustomers
          customersTasks={customerTasksValue}
          loading={valueTableLoading}
        />
      ),
    },
    {
      key: '6',
      label: 'Tarefas por Clientes Principais',
      children: (
        <TasksByParentCustomers
          parentCustomersTasks={parentCustomerTasksValue}
          loading={valueTableLoading}
        />
      ),
    },
  ]

  return (
    <Card
      title="Dashboard Tarefas"
      bordered={false}
      style={{ width: '100%', height: '100%', padding: 30 }}
      extra={
        <Space>
          <DashboardTasksFilter />
          {!isLoading && data?.filteredTasks && (
            <TasksToExcel tasks={data?.filteredTasks} />
          )}
        </Space>
      }
    >
      <Tabs defaultActiveKey="1" items={items} />
    </Card>
  )
}

export default DashboardTasksGraphs
