/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { useContext, createContext, useRef, useState } from 'react'

import { TourProps } from 'antd'
import { useHistory } from 'react-router-dom'

interface TaskTypeTourContextData {
  typeTaskRef: any
  createTypeTaskRef: any
  createTitle: any
  createValue: any
  createSLA: any
  createExecutationTime: any
  createDescription: any
  saveTaskType: any

  taskTypeSteps: any
  createTaskTypeSteps: any
  listTaskTypeSteps: any

  openCreateTaskType?: boolean
  setOpenCreateTaskType(value: any): void
  openListTaskType?: boolean
  setOpenListTaskType(value: any): void

  openCollaborator?: boolean
  setOpenCollaborator(value: any): void
}

const TaskTypeTourContext = createContext<TaskTypeTourContextData>(
  {} as TaskTypeTourContextData,
)

export const TaskTypeTourProvider: React.FC = ({ children }) => {
  const typeTaskRef = useRef<HTMLButtonElement>(null)
  const createTypeTaskRef = useRef<HTMLButtonElement>(null)
  const createTitle = useRef<HTMLButtonElement>(null)
  const createValue = useRef<HTMLButtonElement>(null)
  const createSLA = useRef<HTMLButtonElement>(null)
  const createExecutationTime = useRef<HTMLButtonElement>(null)
  const createDescription = useRef<HTMLButtonElement>(null)

  const collaboratorRef = useRef<HTMLButtonElement>(null)
  const saveTaskType = useRef<HTMLButtonElement>(null)

  const history = useHistory()

  const [openCreateTaskType, setOpenCreateTaskType] = useState(false)
  const [openListTaskType, setOpenListTaskType] = useState(false)
  const [openCollaborator, setOpenCollaborator] = useState(false)

  function OpenCreateTaskType() {
    setOpenCreateTaskType(true)

    history.push('tiposDeTarefas/novo')
  }

  function OpenCollaborator() {
    setOpenCollaborator(true)

    history.push('colaboradores')
  }

  const taskTypeSteps: TourProps['steps'] = [
    {
      title: (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <h2 style={{ marginBottom: 0 }}>Tipos de Tarefa</h2>{' '}
          <span style={{ marginLeft: 8, color: 'gray' }}>Tarefas</span>
        </div>
      ),
      description: (
        <p>
          Começaremos criando o tipo de tarefa, que auxilia definir e configurar
          tarefas personalizadas dentro do módulo.
        </p>
      ),
      target: () => typeTaskRef.current!,
    },
    {
      title: (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <h2 style={{ marginBottom: 0 }}>Tipos de Tarefa</h2>{' '}
          <span style={{ marginLeft: 8, color: 'gray' }}>Tarefas</span>
        </div>
      ),
      description:
        'Clicando nesse botão você consegue criar um tipo de tarefa.',
      target: () => createTypeTaskRef.current!,
      nextButtonProps: { onClick: OpenCreateTaskType },
    },
  ]

  const createTaskTypeSteps: TourProps['steps'] = [
    {
      title: (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <h2 style={{ marginBottom: 0 }}>Criando um Tipo de Tarefa</h2>{' '}
          <span style={{ marginLeft: 8, color: 'gray' }}>Tarefas</span>
        </div>
      ),
      description: 'Primeiramente, adicione um título para o tipo de tarefa.',
      target: () => createTitle.current!,
    },
    {
      title: (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <h2 style={{ marginBottom: 0 }}>Criando um Tipo de Tarefa</h2>{' '}
          <span style={{ marginLeft: 8, color: 'gray' }}>Tarefas</span>
        </div>
      ),
      description: 'Agora, adicione o valor do tipo de tarefa.',
      target: () => createValue.current!,
    },
    {
      title: (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <h2 style={{ marginBottom: 0 }}>Criando um Tipo de Tarefa</h2>{' '}
          <span style={{ marginLeft: 8, color: 'gray' }}>Tarefas</span>
        </div>
      ),
      description: 'Adicione o tempo de SLA de atendimento.',
      target: () => createSLA.current!,
    },
    {
      title: (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <h2 style={{ marginBottom: 0 }}>Criando um Tipo de Tarefa</h2>{' '}
          <span style={{ marginLeft: 8, color: 'gray' }}>Tarefas</span>
        </div>
      ),
      description: 'Adicione o tempo de execução do tipo de tarefa.',
      target: () => createExecutationTime.current!,
    },
    {
      title: (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <h2 style={{ marginBottom: 0 }}>Criando um Tipo de Tarefa</h2>{' '}
          <span style={{ marginLeft: 8, color: 'gray' }}>Tarefas</span>
        </div>
      ),
      description:
        'E por fim, se necessário, adicione uma descrição para o tipo de tarefa.',
      target: () => createDescription.current!,
    },
    {
      title: (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <h2 style={{ marginBottom: 0 }}>Criando um Tipo de Tarefa</h2>{' '}
          <span style={{ marginLeft: 8, color: 'gray' }}>Tarefas</span>
        </div>
      ),
      description: 'Agora vamos salvar o tipo de tarefa.',
      target: () => saveTaskType.current!,
    },
  ]

  const listTaskTypeSteps: TourProps['steps'] = [
    {
      title: (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <h2 style={{ marginBottom: 0 }}>Colaboradores</h2>{' '}
          <span style={{ marginLeft: 8, color: 'gray' }}>Tarefas</span>
        </div>
      ),
      description:
        'Agora que já temos o tipo de tarefa cadastrado. Vamos ir cadastrar os colaboradores.',
      target: () => collaboratorRef.current!,
      nextButtonProps: { onClick: OpenCollaborator },
    },
  ]

  return (
    <TaskTypeTourContext.Provider
      value={{
        typeTaskRef,
        createTypeTaskRef,
        createTitle,
        createValue,
        createSLA,
        createExecutationTime,
        createDescription,
        saveTaskType,
        taskTypeSteps,
        createTaskTypeSteps,
        openCreateTaskType,
        setOpenCreateTaskType,
        openListTaskType,
        setOpenListTaskType,
        listTaskTypeSteps,
        openCollaborator,
        setOpenCollaborator,
      }}
    >
      {children}
    </TaskTypeTourContext.Provider>
  )
}

export function useTaskTypeTour(): TaskTypeTourContextData {
  const context = useContext(TaskTypeTourContext)

  if (!context) {
    throw new Error('useAuth must be used within an AutoProvider')
  }

  return context
}
