export default {
  pt: {
    ClientsAdditionalPhones: {
      successMessage: 'Telefone deletado com sucesso!',
      errorMessage: 'Houve um erro ao deletar o telefone',
      seePhonesText: 'Ver telefones',
      drawerTitle: 'Telefones Adicionais',
      phonesText: 'Telefones',
      noPhonesText: 'Nenhum telefone cadastrado',
      whatsappText: 'Telefone (Whatsapp)',
      noWhatsappText: 'Telefone',
      phoneTypeCell: 'Celular',
      phoneTypeHome: 'Fixo',
      phoneTypeCommercial: 'Comercial',
      deletePopconfirm: 'Deseja realmente deletar o telefone?',
      deleteButtonText: 'Apagar telefone',
    },
  },
  en: {
    ClientsAdditionalPhones: {
      successMessage: 'Phone successfully deleted!',
      errorMessage: 'There was an error deleting the phone',
      seePhonesText: 'See phones',
      drawerTitle: 'Additional Phones',
      phonesText: 'Phones',
      noPhonesText: 'No registered phone',
      whatsappText: 'Phone (Whatsapp)',
      noWhatsappText: 'Phone',
      phoneTypeCell: 'Cellphone',
      phoneTypeHome: 'Fixed',
      phoneTypeCommercial: 'Commercial',
      deletePopconfirm: 'Do you really want to delete the phone?',
      deleteButtonText: 'Delete phone',
    },
  },
  es: {
    ClientsAdditionalPhones: {
      successMessage: '¡Teléfono eliminado con éxito!',
      errorMessage: 'Hubo un error al eliminar el teléfono',
      seePhonesText: 'Ver teléfonos',
      drawerTitle: 'Teléfonos adicionales',
      phonesText: 'Teléfonos',
      noPhonesText: 'Sin teléfono registrado',
      whatsappText: 'eléfono (Whatsapp)',
      noWhatsappText: 'Teléfono',
      phoneTypeCell: 'Celular',
      phoneTypeHome: 'Fijo',
      phoneTypeCommercial: 'Comercial',
      deletePopconfirm: '¿De verdad quieres borrar el teléfono?',
      deleteButtonText: 'Eliminar teléfono',
    },
  },
}
