import React from 'react'
import LocaleSelector from '~/components/DataManipulation/Misc/LocaleSelector'

const AuthLayout: React.FC = ({ children }) => {
  return (
    <>
      <LocaleSelector style={{ position: 'absolute', top: 25, right: 25 }} />
      {children}
    </>
  )
}

export default AuthLayout
