import { Button, Card, Divider, Form, Select, Space } from 'antd'
import React from 'react'
import { useTaskForms } from '~/hooks/Tasks/Forms/useTaskForms'
import { TaskTypeProps } from '.'

interface AboutTypeTasksFormsProps {
  data: TaskTypeProps
  childrel?: React.ReactNode
}

const AboutTypeTasksForms: React.FC<AboutTypeTasksFormsProps> = ({ data }) => {
  const { forms, isLoading, limit, changeLimit, formsTotal } = useTaskForms()

  if (!forms || !formsTotal || isLoading) return <Card loading />
  return (
    <Form.Item name="forms" label={'Formulários'}>
      <Select
        mode="multiple"
        placeholder="Selecione um ou mais formulários"
        defaultValue={data.forms && data.forms.map((form) => form.form_id)}
        options={forms.map((form) => ({
          label: form.title,
          value: form.id,
        }))}
        dropdownRender={(menu) => (
          <>
            {menu}
            {limit < formsTotal && (
              <>
                <Divider style={{ margin: '8px 0' }} />
                <Space style={{ padding: '0 8px 4px' }}>
                  <Button type="text" onClick={() => changeLimit(limit + 100)}>
                    Carregar mais
                  </Button>
                </Space>
              </>
            )}
          </>
        )}
      />
    </Form.Item>
  )
}

export default AboutTypeTasksForms
