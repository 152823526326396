import React from 'react'

import {
  LoadingOutlined,
  PlusCircleOutlined,
  QuestionOutlined,
} from '@ant-design/icons'
import { Card, Space, Button, Tour, Tooltip } from 'antd'
import Text from 'antd/lib/typography/Text'
import { Link } from 'react-router-dom'
import { useListClients } from '~/hooks/Clients/useListClients'
import { useCompanies } from '~/hooks/Companies/useCompanies'
import api from '~/services/api'
import { translate } from '~/utils/locale'
import ClientsFilter from './ClientsFilter'
import ClientsTable from './ClientsTable'
import MoreOptions from '../Misc/MoreOptions'

import { useClientTour } from '~/hooks/Tour/useClientTour'
import { useCollaboratorTour } from '~/hooks/Tour/useCollaboratorTour'
import { useTour } from '~/hooks/Tour/useTour'

// import { Container } from './styles';

const ClientsBoard: React.FC = () => {
  const { selectedCompany } = useCompanies()
  const { isLoading, isFetching, clientsTotal } = useListClients()

  const {
    createClientRef,
    clientSteps,
    openListClient,
    setOpenListClient,
    listClientSteps,
  } = useClientTour()
  const { openClient, setOpenClient } = useCollaboratorTour()

  const { isTour, setIsTour } = useTour()

  function onClose() {
    setIsTour(false)
    setOpenListClient(false)
  }

  const generateCustomerReport = async () => {
    const response = await api.get(
      `/company/${selectedCompany}/customers/export`,
    )

    window.open(response.data.exportUrl, '_blank')
  }

  return (
    <Card
      title={
        <Space size="large">
          <b>{translate('ClientsComponent.cardTitle')}</b>
          {isLoading ? (
            <LoadingOutlined spin />
          ) : (
            <Text
              type="secondary"
              style={{ fontWeight: 'normal', fontSize: 14 }}
            >
              {clientsTotal !== undefined ? (
                <>
                  {clientsTotal === 1
                    ? `${translate(
                        'ClientsComponent.totalText',
                      )} ${clientsTotal} ${translate(
                        'ClientsComponent.customerText',
                      )}`
                    : `${translate(
                        'ClientsComponent.totalText',
                      )} ${clientsTotal} ${translate(
                        'ClientsComponent.customersText',
                      )}`}
                </>
              ) : (
                ''
              )}
            </Text>
          )}
          {!isLoading && isFetching && <LoadingOutlined spin />}
        </Space>
      }
      extra={
        <Space size="middle">
          <Tooltip title="Tour Clientes">
            <Button
              icon={<QuestionOutlined />}
              shape="circle"
              size="small"
              onClick={() => setOpenClient(true)}
            />
          </Tooltip>
          <MoreOptions
            exportTooltip={translate('ClientsComponent.exportTooltip')}
            exportFunction={generateCustomerReport}
            importTooltip={translate('ClientsComponent.importTooltip')}
            modalTitle={translate('ClientsComponent.modalTitle')}
            importTitle={translate('ClientsComponent.importTitle')}
            importDescription={translate('ClientsComponent.importDescription')}
            modelURL="/ModelDownload/customers.xlsx"
            uploadRoute="customers/import"
            uploadFileName="customers"
          />

          <ClientsFilter />

          <Link to="/clientes/novo">
            <Button
              type="primary"
              icon={<PlusCircleOutlined />}
              ref={createClientRef}
            >
              {translate('ClientsComponent.newCustomerButtonText')}
            </Button>
          </Link>
        </Space>
      }
      bordered={false}
      style={{ width: '100%', padding: 30 }}
    >
      <ClientsTable />

      {openListClient ? (
        <>
          {isTour ? (
            <Tour
              open={openListClient}
              onClose={() => setOpenListClient(false)}
              steps={listClientSteps}
              indicatorsRender={(current, total) => (
                <span>
                  {current + 1} / {total}
                </span>
              )}
            />
          ) : (
            ''
          )}
        </>
      ) : (
        <Tour
          open={openClient}
          onClose={onClose}
          steps={clientSteps}
          indicatorsRender={(current, total) => (
            <span>
              {current + 1} / {total}
            </span>
          )}
        />
      )}
    </Card>
  )
}

export default ClientsBoard
