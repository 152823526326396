/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-underscore-dangle */
import {
  Col,
  Form,
  Row,
  Select,
  DatePicker,
  Input,
  message,
  Card,
  Space,
  Button,
  Tooltip,
  Typography,
  Popconfirm,
  InputNumber,
} from 'antd'
import React, { useState, useCallback, Fragment } from 'react'

import { ValidateStatus } from 'antd/lib/form/FormItem'
import { MapContainer, Marker, TileLayer, useMap } from 'react-leaflet'
import { LatLngExpression } from 'leaflet'
import cep from 'cep-promise'
import { MaskedInput } from 'antd-mask-input'
import dayjs from 'dayjs'
import { Link } from 'react-router-dom'
import {
  ArrowLeftOutlined,
  CheckCircleFilled,
  DeleteFilled,
  EditFilled,
} from '@ant-design/icons'
import { useListClients } from '~/hooks/Clients/useListClients'
import { useTaskType } from '~/hooks/Tasks/TaskType/useTaskType'
import { useTask } from '~/hooks/Tasks/useTask'
import { useTeams } from '~/hooks/Teams/useListTeams'
import geolocationApi from '~/services/geolocationApi'
import { useListTask } from '~/hooks/Tasks/useListTasks'

import { ContainerButton, FormButtonContainer } from './styles'
import { translate } from '~/utils/locale'
import HelpTooltip from '../../Misc/HelpTooltip'
import { useContributorsTaskFilter } from '~/hooks/Tasks/useListContributorsTask'

interface TaskProps {
  type: string
  task_type_id: string
  address_id: string
  id: string
  date: Date
  duration_time: Date
  priority: string
  description: string
  checkin_type: string
  external_code: string
  customer_id: string
  equipment_id: number[]
  zipcode: string
  street: string
  number: string
  complement: string
  neighborhood: string
  city: string
  state: string
  location: { x: number; y: number }
  value: number
  note: string
}

interface AddressProps {
  id: string
  type: string
  address: {
    zip_code: string
    street: string
    number: string
    complement: string
    neighborhood: string
    city: string
    id: string
    state: string
    location: { x: number; y: number }
  }
}

interface MapUpdateFunctionProps {
  center: LatLngExpression
  zoom: number
}

interface ContactProps {
  name: string
  email: string
  id: string
  contactAddresses: AddressProps[]
}

interface ClientsProps {
  id: string
  contact: ContactProps
}

const UpdateTask: React.FC = () => {
  const { contributorsTaskFilter } = useContributorsTaskFilter()
  const { teams } = useTeams()
  const { clients } = useListClients()
  const { taskType } = useTaskType()

  const [form] = Form.useForm()

  const {
    task,
    setIsEdit,
    updateTask,
    updateHistoric,
    refetch: refetchTask,
  } = useTask()
  const { refetch, deleteTask } = useListTask()

  const [clientInfo, setClientInfo] = useState<ClientsProps[]>()
  const [addressInfo, setAddressInfo] = useState<AddressProps>()

  const [mapValidate, setMapValidate] = useState(false)

  const [latitude, setLatitude] = useState<any>(task?.address.location.x)
  const [longitude, setLongitude] = useState<any>(task?.address.location.y)

  const [state, setState] = useState('')
  const [city, setCity] = useState('')
  const [street, setStreet] = useState('')

  const [hidden, setHidden] = useState(true)

  const [mapSearch, setMapSearch] = useState(false)

  const [status, setStatus] = useState('' as ValidateStatus)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [responsible, setResponsible] = useState<string>()

  const [editNote, setEditNote] = useState(false)

  function handleChangeEditButton() {
    if (editNote) {
      setEditNote(false)
    } else {
      setEditNote(true)
    }
  }

  function getValueNoteInput(value: string) {
    localStorage.setItem('@Gstor:noteTask', value)
  }

  const note = localStorage.getItem('@Gstor:noteTask')

  const { Option } = Select

  const formatComma = (value: string, field: 'latitude' | 'longitude') => {
    const valueFormatted = value.replace(/,/g, '.')

    if (
      valueFormatted !== '-' &&
      valueFormatted !== '.' &&
      valueFormatted !== '-.'
    ) {
      if (field === 'latitude') {
        setLatitude(valueFormatted)
      } else if (field === 'longitude') {
        setLongitude(valueFormatted)
      }
    }
  }

  const initialValues = {
    id: task?.responsible?.id,
    type: task?.responsible.type,
    customer_id: task?.customer_id,
    task_type_id: task?.task_type_id,
    external_code: task?.external_code,
    date: dayjs(task?.date),
    duration_time: task?.duration_time,
    priority: task?.priority,
    description: task?.description,
    zipcode: task?.address.zip_code,
    state: task?.address.state,
    city: task?.address.city,
    neighborhood: task?.address.neighborhood,
    street: task?.address.street,
    number: task?.address.number,
    complement: task?.address.complement,
    value: task?.value + '00',
    note: task?.note,
  }

  const stateOptions = [
    { label: 'Acre', value: 'AC' },
    { label: 'Alagoas', value: 'AL' },
    { label: 'Amapá', value: 'AP' },
    { label: 'Amazonas', value: 'AM' },
    { label: 'Bahia', value: 'BA' },
    { label: 'Ceará', value: 'CE' },
    { label: 'Espírito Santo', value: 'ES' },
    { label: 'Goiás', value: 'GO' },
    { label: 'Maranhão', value: 'MA' },
    { label: 'Mato Grosso', value: 'MT' },
    { label: 'Mato Grosso do Sul', value: 'MS' },
    { label: 'Minas Gerais', value: 'MG' },
    { label: 'Pará', value: 'PA' },
    { label: 'Paraíba', value: 'PB' },
    { label: 'Paraná', value: 'PR' },
    { label: 'Pernambuco', value: 'PE' },
    { label: 'Piauí', value: 'PI' },
    { label: 'Rio de Janeiro', value: 'RJ' },
    { label: 'Rio Grande do Norte', value: 'RN' },
    { label: 'Rio Grande do Sul', value: 'RS' },
    { label: 'Rondônia', value: 'RO' },
    { label: 'Roraima', value: 'RR' },
    { label: 'Santa Catarina', value: 'SC' },
    { label: 'São Paulo', value: 'SP' },
    { label: 'Sergipe', value: 'SE' },
    { label: 'Tocantins', value: 'TO' },
    { label: 'Distrito Federal', value: 'DF' },
  ]

  function handleClient(value: any) {
    const addresses = clients?.filter((c) => {
      return c.id === value
    })

    setClientInfo(addresses)
  }

  function handleAddress(value: string) {
    const info = clientInfo?.map((a) => {
      setAddressInfo(
        a.contact.contactAddresses.find((item) => item.id === value),
      )
      setMapValidate(true)
      return a.contact.contactAddresses.find((item) => item.id === value)
    })

    return info
  }

  function onSelectAddress() {
    form.setFieldsValue({
      zip_code: addressInfo?.address.zip_code,
      state: addressInfo?.address.state,
      city: addressInfo?.address.city,
      neighborhood: addressInfo?.address.neighborhood,
      street: addressInfo?.address.street,
      number: addressInfo?.address.number,
      complement: addressInfo?.address.complement,
    })

    if (
      addressInfo?.address.location.x !== undefined &&
      addressInfo?.address.location.y !== undefined
    ) {
      setLatitude(addressInfo?.address.location.x)
      setLongitude(addressInfo?.address.location.y)

      form.setFieldsValue({
        latitude: addressInfo?.address.location.x,
        longitude: addressInfo?.address.location.y,
      })
    }
  }

  const searchCEP = useCallback(
    async (address: string) => {
      try {
        setStatus('validating')
        const cepInfo = await cep(address)

        form.setFieldsValue({
          state: cepInfo.state,
          city: cepInfo.city,
          neighborhood: cepInfo.neighborhood,
          street: cepInfo.street,
        })

        setState(cepInfo.state)
        setCity(cepInfo.city)
        setStreet(cepInfo.street)

        setStatus('success')
      } catch (error) {
        message.error(translate('TasksUpdate.searchZipcodeErrorMessage'))
        form.resetFields()
        setState('')
        setCity('')
        setStreet('')
        setStatus('error')
      }
    },
    [form],
  )

  const searchMap = useCallback(async () => {
    try {
      setMapSearch(true)
      const params = {
        street,
        city,
        state,
        format: 'json',
      }

      const response = await geolocationApi.get('search', { params })

      if (response.data.length > 0) {
        setHidden(true)
        form.setFieldsValue({
          latitude: response.data[0].lat,
          longitude: response.data[0].lon,
        })

        setLatitude(response.data[0].lat)
        setLongitude(response.data[0].lon)
      } else {
        setHidden(false)

        form.setFieldsValue({
          latitude: '',
          longitude: '',
        })

        message.warning(translate('TasksUpdate.geolocationWarningMessage'), 10)
      }
      setMapValidate(true)
      setMapSearch(false)
    } catch (error) {
      message.error(translate('TasksUpdate.geolocationErrorMessage'))
      setMapValidate(true)
      setMapSearch(false)
    }
  }, [form, city, state, street])

  function ChangeView({ center, zoom }: MapUpdateFunctionProps) {
    const map = useMap()
    map.setView(center, zoom)
    return null
  }

  function updateTaskForm(valueForm: TaskProps) {
    const dataForm = {
      task_type_id: valueForm.task_type_id,
      responsible: {
        id: valueForm.id,
        type: valueForm.type,
      },
      date: valueForm.date,
      duration_time: valueForm.duration_time,
      priority: valueForm.priority,
      description: valueForm.description,
      customer_id: valueForm.customer_id,
      external_code: valueForm.external_code,
      note: valueForm.note,
      address: {
        zip_code: valueForm.zipcode,
        street: valueForm.street,
        number: valueForm.number,
        complement: valueForm.complement,
        neighborhood: valueForm.neighborhood,
        city: valueForm.city,
        state: valueForm.state,
        location: {
          x: latitude,
          y: longitude,
        },
      },
    }

    const dataHistoric = {
      task_type_id: valueForm.task_type_id,
      responsible: valueForm.id,
      date: valueForm.date,
      duration_time: valueForm.duration_time,
      priority: valueForm.priority,
      description: valueForm.description,
      customer_id: valueForm.customer_id,
      external_code: valueForm.external_code,
      note: valueForm.note,
      address: {
        zip_code: valueForm.zipcode,
        street: valueForm.street,
        number: valueForm.number,
        complement: valueForm.complement,
        neighborhood: valueForm.neighborhood,
        city: valueForm.city,
        state: valueForm.state,
        location: {
          x: latitude,
          y: longitude,
        },
      },
    }

    try {
      updateTask(dataForm)
      updateHistoric(dataHistoric)
      setIsEdit(false)
      setMapValidate(false)
      refetch()
      refetchTask()
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Card
      bordered={false}
      title={
        <Space size="large">
          <Tooltip title={translate('TasksUpdate.backTooltip')}>
            <Link to="/agenda">
              <ArrowLeftOutlined />
            </Link>
          </Tooltip>
          {translate('TasksUpdate.cardTitle')}
        </Space>
      }
      style={{
        display: 'inline-block',
        width: '100%',
      }}
      extra={
        <Space size="middle">
          <Popconfirm
            title={translate('TasksTable.deletePopconfirm')}
            onConfirm={() => deleteTask(task?._id)}
          >
            <Tooltip title={translate('TasksTable.deleteTooltip')}>
              <Button type="primary" danger>
                Excluir
              </Button>
            </Tooltip>
          </Popconfirm>
          <ContainerButton>
            <Button
              htmlType="submit"
              form="updateTask"
              type="primary"
              id="btn_update_task"
            >
              {translate('TasksUpdate.saveButton')}
            </Button>
          </ContainerButton>
          <Button onClick={() => setIsEdit(false)}>
            {translate('TasksUpdate.cancelButton')}
          </Button>
        </Space>
      }
    >
      <Form
        layout="vertical"
        onFinish={updateTaskForm}
        initialValues={initialValues}
        id="updateTask"
        form={form}
      >
        <Row gutter={14}>
          <Col span={12}>
            <Form.Item
              label={translate('TasksUpdate.taskResponsibleLabel')}
              name="type"
            >
              <Select
                placeholder={translate(
                  'TasksUpdate.taskResponsiblePlaceholder',
                )}
              >
                <Option value="collaborator">
                  {translate('TasksUpdate.taskResponsibleContributor')}
                </Option>
                <Option value="team">
                  {translate('TasksUpdate.taskResponsibleTeam')}
                </Option>
              </Select>
            </Form.Item>
          </Col>
          {task?.responsible?.type === 'collaborator' ? (
            <Col span={12}>
              <Form.Item
                label={translate('TasksUpdate.contributorLabel')}
                name="id"
              >
                <Select
                  optionFilterProp="children"
                  showSearch
                  placeholder={translate('TasksUpdate.contributorPlaceholder')}
                >
                  {contributorsTaskFilter?.map((contribuitor) => (
                    <Option key={contribuitor.id} value={contribuitor.id}>
                      {contribuitor.contact.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          ) : (
            ''
          )}
          {task?.responsible?.type === 'team' ? (
            <Col span={12}>
              <Form.Item label={translate('TasksUpdate.teamLabel')} name="id">
                <Select placeholder={translate('TasksUpdate.teamPlaceholder')}>
                  {teams?.map((team) => (
                    <Option key={team.id} value={team.id}>
                      {team.title}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          ) : (
            ''
          )}
        </Row>
        <Row gutter={14}>
          <Col span={8}>
            <Form.Item
              label={translate('TasksUpdate.clientLabel')}
              name="customer_id"
            >
              <Select placeholder={translate('TasksUpdate.clientPlaceholder')}>
                {clients?.map((client) => (
                  <Option value={client.id} key={client.id}>
                    {client.contact.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={translate('TasksUpdate.taskTypeLabel')}
              name="task_type_id"
              rules={[
                {
                  required: true,
                  message: translate('TasksUpdate.taskTypeRule'),
                },
              ]}
            >
              <Select
                placeholder={translate('TasksUpdate.taskTypePlaceholder')}
              >
                {taskType?.map((taskData) => (
                  <Option value={taskData._id} key={taskData._id}>
                    {taskData.title}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={
                <Space>
                  <Typography.Text>
                    {translate('TasksUpdate.externalCodeLabel')}
                  </Typography.Text>
                  <HelpTooltip
                    title={translate('TasksUpdate.externalCodeHelp')}
                  />
                </Space>
              }
              name="external_code"
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={14}>
          <Col span={8}>
            <Form.Item
              label={translate('TasksUpdate.dateLabel')}
              name="date"
              rules={[
                {
                  required: true,
                  message: translate('TasksUpdate.dateRule'),
                },
              ]}
            >
              <DatePicker
                format="DD/MM/YYYY HH:mm"
                showTime
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={translate('TasksUpdate.durationLabel')}
              name="duration_time"
            >
              <MaskedInput mask={'00:00:00'} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={translate('TasksUpdate.priorityLabel')}
              name="priority"
            >
              <Select
                placeholder={translate('TasksUpdate.priorityPlaceholder')}
              >
                <Option value="high">
                  {translate('TasksUpdate.priorityHigh')}
                </Option>
                <Option value="medium">
                  {translate('TasksUpdate.priorityMedium')}
                </Option>
                <Option value="low">
                  {translate('TasksUpdate.priorityLow')}
                </Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={14}>
          <Col span={12}>
            <Form.Item
              label={
                <Space>
                  <>
                    {editNote ? (
                      <Input
                        size="small"
                        onChange={(e) => getValueNoteInput(e.target.value)}
                      />
                    ) : (
                      <>
                        {note ? (
                          <>{note}</>
                        ) : (
                          <>{translate('TasksCreateDetails.noteLabel')}</>
                        )}
                      </>
                    )}
                  </>
                  <Tooltip
                    title={
                      !editNote ? (
                        <>{translate('TasksCreateDetails.noteAddTooltip')}</>
                      ) : (
                        <>{translate('TasksCreateDetails.noteSaveTooltip')}</>
                      )
                    }
                  >
                    {!editNote ? (
                      <EditFilled
                        style={{ color: '#3C8ACB', cursor: 'pointer' }}
                        onClick={handleChangeEditButton}
                      />
                    ) : (
                      <CheckCircleFilled
                        style={{ color: '#3C8ACB', cursor: 'pointer' }}
                        onClick={handleChangeEditButton}
                      />
                    )}
                  </Tooltip>
                </Space>
              }
              name="note"
            >
              <Input.TextArea
                placeholder={translate('TasksCreateDetails.notePlaceholder')}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={translate('TasksUpdate.descriptionLabel')}
              name="description"
            >
              <Input.TextArea
                placeholder={translate('TasksUpdate.descriptionPlaceholder')}
              />
            </Form.Item>
          </Col>
        </Row>

        <Card title={translate('TasksUpdate.addressCardTitle')}>
          <Row gutter={14}>
            <Col span={12}>
              <Form.Item label={translate('TasksUpdate.clientAddressLabel')}>
                <Select
                  placeholder={translate(
                    'TasksUpdate.clientAddressPlaceholder',
                  )}
                  onChange={handleClient}
                >
                  {clients?.map((client) => (
                    <Option value={client.id} key={client.id}>
                      {client.contact.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={translate('TasksUpdate.selectAddressLabel')}>
                <Select
                  placeholder={translate(
                    'TasksUpdate.selectAddressPlaceholder',
                  )}
                  onChange={handleAddress}
                  onSelect={onSelectAddress}
                >
                  {clientInfo?.map((address) => (
                    <Fragment key={address.id}>
                      {address.contact.contactAddresses.map((info) => (
                        <>
                          {info.type === 'business' ? (
                            <Option key={info.id} value={info.id}>
                              {translate('TasksUpdate.selectAddressBusiness')}
                            </Option>
                          ) : (
                            ''
                          )}

                          {info.type === 'personal' ? (
                            <Option key={info.id} value={info.id}>
                              {translate('TasksUpdate.selectAddressPersonal')}
                            </Option>
                          ) : (
                            ''
                          )}

                          {info.type === 'billing' ? (
                            <Option key={info.id} value={info.id}>
                              {translate('TasksUpdate.selectAddressBilling')}
                            </Option>
                          ) : (
                            ''
                          )}
                        </>
                      ))}
                    </Fragment>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={14}>
            <Col span={4}>
              <Form.Item
                label={translate('TasksUpdate.zipcodeLabel')}
                name="zipcode"
                hasFeedback
                validateStatus={status}
              >
                <MaskedInput
                  onChange={(e) => {
                    const cepInput = e.target.value.replace(/\D/g, '')
                    if (cepInput.length === 8) {
                      searchCEP(e.target.value)
                    }
                  }}
                  mask={'00000-000'}
                />
              </Form.Item>
            </Col>

            <Col span={4}>
              <a
                href="https://buscacepinter.correios.com.br/app/endereco/index.php"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Form.Item label={translate('TasksUpdate.infoZipcodeLabel')}>
                  <Button key="submit" type="primary">
                    {translate('TasksUpdate.infoZipcodeButton')}
                  </Button>
                </Form.Item>
              </a>
            </Col>
            <Col span={8}>
              <Form.Item
                label={translate('TasksUpdate.stateLabel')}
                name="state"
              >
                <Select
                  style={{ width: '100%' }}
                  placeholder={translate('TasksUpdate.statePlaceholder')}
                  className="menu_input"
                  onChange={(e) => setState(String(e))}
                  options={stateOptions}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={translate('TasksUpdate.cityLabel')} name="city">
                <Input
                  placeholder={translate('TasksUpdate.cityPlaceholder')}
                  onChange={(e) => setCity(e.target.value)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={14}>
            <Col span={8}>
              <Form.Item
                label={translate('TasksUpdate.neighborhoodLabel')}
                name="neighborhood"
              >
                <Input
                  placeholder={translate('TasksUpdate.neighborhoodPlaceholder')}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={translate('TasksUpdate.streetLabel')}
                name="street"
              >
                <Input
                  placeholder={translate('TasksUpdate.streetPlaceholder')}
                  onChange={(e) => setStreet(e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={translate('TasksUpdate.numberLabel')}
                name="number"
                rules={[
                  {
                    required: true,
                    message: translate('TasksUpdate.numberRule'),
                  },
                ]}
              >
                <Input
                  placeholder={translate('TasksUpdate.numberPlaceholder')}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={14}>
            <Col span={4.8}>
              <Form.Item
                label={translate('TasksUpdate.complementLabel')}
                name="complement"
              >
                <Input
                  placeholder={translate('TasksUpdate.complementPlaceholder')}
                />
              </Form.Item>
            </Col>
            <Col span={4.8}>
              <Form.Item label={translate('TasksUpdate.mapLabel')} name="mapa">
                <Button
                  key="submit"
                  type="primary"
                  disabled={!!(state === '' || street === '' || city === '')}
                  loading={mapSearch}
                  onClick={searchMap}
                >
                  {translate('TasksUpdate.mapButton')}
                </Button>
              </Form.Item>
            </Col>
            <Col span={4.8}>
              <Form.Item
                hidden={hidden}
                label={translate('TasksUpdate.latitudeLabel')}
                name="latitude"
                initialValue={latitude}
                rules={[
                  {
                    required: !hidden,
                    message: translate('TasksUpdate.latitudeRule'),
                  },
                ]}
              >
                <Input
                  onChange={(e) => formatComma(e.target.value, 'latitude')}
                />
              </Form.Item>
            </Col>
            <Col span={4.8}>
              <Form.Item
                hidden={hidden}
                label={translate('TasksUpdate.longitudeLabel')}
                name="longitude"
                initialValue={longitude}
                rules={[
                  {
                    required: !hidden,
                    message: translate('TasksUpdate.longitudeRule'),
                  },
                ]}
              >
                <Input
                  onChange={(e) => formatComma(e.target.value, 'longitude')}
                />
              </Form.Item>
            </Col>
            <Col span={4.8}>
              <a
                href="https://www.latlong.net/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Form.Item
                  label={translate('TasksUpdate.coordinatesInfoLabel')}
                  hidden={hidden}
                >
                  <Button type="primary">
                    {translate('TasksUpdate.coordinatesInfoButton')}
                  </Button>
                </Form.Item>
              </a>
            </Col>
          </Row>
          <MapContainer
            center={[latitude, longitude]}
            zoom={13}
            scrollWheelZoom={false}
            style={{ height: '350px', width: '100%' }}
          >
            <ChangeView center={[latitude, longitude]} zoom={17} />
            <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {latitude !== 0 && longitude !== 0 && (
              <Marker position={[latitude, longitude]} />
            )}
          </MapContainer>
        </Card>
      </Form>
    </Card>
  )
}

export default UpdateTask
