export default {
  pt: {
    FormsFilter: {
      cleanFilterTooltip: 'Limpar Filtro',
      appliedButtonFilter: 'Filtro Aplicado',
      defaultButtonFilter: 'Filtros',
      filtersTitle: 'Filtros de Formulários',
      titleLabel: 'Título:',
      titlePlaceholder: 'Filtre pelo título do formulário',
      finishButton: 'Aplicar',
      cleanButton: 'Limpar',
    },
  },
  en: {
    FormsFilter: {
      cleanFilterTooltip: 'Clear Filter',
      appliedButtonFilter: 'Filter Applied',
      defaultButtonFilter: 'Filters',
      filtersTitle: 'Form Filters',
      titleLabel: 'Title:',
      titlePlaceholder: 'Filter by form title',
      finishButton: 'Apply',
      cleanButton: 'Clean',
    },
  },
  es: {
    FormsFilter: {
      cleanFilterTooltip: 'Filtro Claro',
      appliedButtonFilter: 'Filtro Aplicado',
      defaultButtonFilter: 'Filtros',
      filtersTitle: 'Filtros de Formas',
      titleLabel: 'Título:',
      titlePlaceholder: 'Filtrar por título del formulario',
      finishButton: 'Aplicar',
      cleanButton: 'Limpio',
    },
  },
}
