/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { ReactNode, useContext, createContext, useEffect } from 'react'
import { useQuery } from 'react-query'

import { message } from 'antd'
import api from '~/services/api'

import { useCompanies } from '~/hooks/Companies/useCompanies'
import { useLocale } from '~/hooks/locale/useLocale'

import { ShowError } from '~/utils/errors/apiErrors'
import { translate } from '~/utils/locale'

interface SkillsProviderProps {
  children: ReactNode
}

interface CompanyData {
  id: string
  name: string
}

interface Skill {
  company: CompanyData
  description: string
  id: string
  name: string
}

interface SkillsContextData {
  skillsRefetch: () => void
  deleteSkill(skillId: string): void
  skillsData: Skill[]
  isLoading: boolean
  isFetching: boolean
}

const SkillsContext = createContext<SkillsContextData>({} as SkillsContextData)

export function SkillsProvider({ children }: SkillsProviderProps) {
  const { selectedCompany } = useCompanies()
  const { locale } = useLocale()

  const { data, isLoading, isFetching, refetch } = useQuery(
    ['skills', selectedCompany],
    async () => {
      try {
        if (!selectedCompany) {
          return undefined
        }

        const response = await api.get(`/company/${selectedCompany}/skills`)
        const skills = response.data

        return skills
      } catch (err: any) {
        ShowError(err.message, translate('useSkills.getErrorMessage'), locale)
      }
    },
    {
      staleTime: 5000, // 5 segundos
    },
  )

  async function deleteSkill(skillId: string) {
    try {
      await api.delete(`/company/${selectedCompany}/skill/${skillId}`)

      refetch()
      message.success(translate('useSkills.deleteSuccessMessage'))
    } catch (err: any) {
      ShowError(err.message, translate('useSkills.deleteErrorMessage'), locale)
    }
  }

  useEffect(() => {
    refetch()
  }, [selectedCompany, refetch])

  return (
    <SkillsContext.Provider
      value={{
        skillsData: data,
        isLoading,
        isFetching,
        skillsRefetch: refetch,
        deleteSkill,
      }}
    >
      {children}
    </SkillsContext.Provider>
  )
}

export function useSkills() {
  const context = useContext(SkillsContext)

  return context
}
