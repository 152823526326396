export default {
  pt: {
    TeamsDetails: {
      dateString: 'às',
      pageHeaderTitle: 'Visualizar Equipe',
      cardTitle: 'Informações Gerais',
      createdText: 'Criado em',
      responsibleText: 'Responsável:',
      noResponsibleText: 'Nenhum responsável informado.',
      descriptionText: 'Descrição:',
      noDescriptionText: 'Nenhuma descrição informada',
      contributorCardTitle: 'Colaboradores da equipe',
      deleteTooltip: 'Deletar colaborador',
      deletePopconfirm: 'Deseja mesmo deletar o colaborador?',
      viewContributorTooltip: 'Ver colaborador',
      noContributorText: 'Nenhum colaborador encontrado',
    },
  },
  en: {
    TeamsDetails: {
      dateString: 'at',
      pageHeaderTitle: 'View Team',
      cardTitle: 'General information',
      createdText: 'Created at',
      responsibleText: 'Responsible:',
      noResponsibleText: 'No responsible informed.',
      descriptionText: 'Description:',
      noDescriptionText: 'No description provided',
      contributorCardTitle: 'Team Contributors',
      deleteTooltip: 'Delete contributor',
      deletePopconfirm: 'Do you really want to delete the Contributor?',
      viewContributorTooltip: 'View Contributor',
      noContributorText: 'No contributors found',
    },
  },
  es: {
    TeamsDetails: {
      dateString: 'a las',
      pageHeaderTitle: 'Ver Equipo',
      cardTitle: 'Informaciones generales',
      createdText: 'Creado en',
      responsibleText: 'Responsable:',
      noResponsibleText: 'Ningún responsable informado.',
      descriptionText: 'Descripción:',
      noDescriptionText: 'No se proporcionó descripción',
      contributorCardTitle: 'Colaboradores del equipo',
      deleteTooltip: 'Eliminar colaborador',
      deletePopconfirm: '¿Realmente quieres eliminar al colaborador?',
      viewContributorTooltip: 'Ver colaborador',
      noContributorText: 'No se encontraron colaboradores',
    },
  },
}
