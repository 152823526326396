/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, createContext, useState } from 'react'
import { useQuery } from 'react-query'

import { message } from 'antd'

import api from '~/services/api'

import { useCompanies } from '~/hooks/Companies/useCompanies'
import { useLocale } from '~/hooks/locale/useLocale'

import { ShowError } from '~/utils/errors/apiErrors'
import { translate } from '~/utils/locale'

interface TicketTypeProps {
  description: string
  _id: string
  title: string
}

interface TicketTypeContextData {
  ticketType?: TicketTypeProps[]
  createTicketType(valueForm: object | undefined): void
  updateTicketType(valueForm: object | undefined, ticketTypeId: string): void
  deleteTicketType(ticketTypeId: string): void
  isLoading: boolean
  refetch: () => void
  isFetching: boolean
  ticketTypeTotal?: number
}

const TicketTypeContext = createContext<TicketTypeContextData>(
  {} as TicketTypeContextData,
)

export const TicketTypeProvider: React.FC = ({ children }) => {
  const { selectedCompany } = useCompanies()
  const { locale } = useLocale()

  const [ticketTypeTotal, setTicketTypeTotal] = useState<number>()

  const {
    data: ticketType,
    isLoading,
    refetch,
    isFetching,
  } = useQuery(
    [
      `tipos de solicitacao${selectedCompany}`,
      selectedCompany,
      ticketTypeTotal,
    ],

    async () => {
      try {
        const response = await api.get(
          `/company/${selectedCompany}/tickets/types`,
        )

        const { data } = response

        setTicketTypeTotal(data.total)

        return data.results
      } catch (err: any) {
        ShowError(
          err.message,
          translate('useTicketType.getErrorMessage'),
          locale,
        )
      }
    },
  )

  async function createTicketType(valueForm: object) {
    try {
      await api.post(`/company/${selectedCompany}/ticket/type`, valueForm)

      refetch()
      message.success(translate('useTicketType.createSuccessMessage'))
    } catch (err: any) {
      ShowError(
        err.message,
        translate('useTicketType.createErrorMessage'),
        locale,
      )
    }
  }

  async function updateTicketType(valueForm: object, ticketTypeId: string) {
    try {
      await api.put(
        `/company/${selectedCompany}/ticket/type/${ticketTypeId}`,
        valueForm,
      )

      message.success(translate('useTicketType.updateSuccessMessage'))
      refetch()
    } catch (err: any) {
      ShowError(
        err.message,
        translate('useTicketType.updateErrorMessage'),
        locale,
      )
    }
  }

  async function deleteTicketType(ticketTypeId: string) {
    try {
      await api.delete(
        `/company/${selectedCompany}/ticket/type/${ticketTypeId}`,
      )

      refetch()
      message.success(translate('useTicketType.deleteSuccessMessage'))
    } catch (err: any) {
      ShowError(
        err.message,
        translate('useTicketType.deleteErrorMessage'),
        locale,
      )
    }
  }

  return (
    <TicketTypeContext.Provider
      value={{
        ticketType,
        createTicketType,
        updateTicketType,
        deleteTicketType,
        isLoading,
        refetch,
        isFetching,
        ticketTypeTotal,
      }}
    >
      {children}
    </TicketTypeContext.Provider>
  )
}

export function useTicketType(): TicketTypeContextData {
  const context = useContext(TicketTypeContext)

  if (!context) {
    throw new Error('useAuth must be used within an AutoProvider')
  }

  return context
}
