import React from 'react'
import { Helmet } from 'react-helmet'
import TeamTab from '~/components/DataManipulation/Teams/Tabs'

import { TeamsProvider } from '~/hooks/Teams/useListTeams'
import { TeamProvider } from '~/hooks/Teams/useTeams'
import { translate } from '~/utils/locale'

const ShowGroups: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>{translate('ShowTeams.helmetTitle')}</title>
      </Helmet>

      <TeamsProvider>
        <TeamProvider>
          <TeamTab />
        </TeamProvider>
      </TeamsProvider>
    </>
  )
}

export default ShowGroups
