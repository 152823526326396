export default {
  pt: {
    useEvaluations: {
      getErrorMessage: 'Erro ao visualizar avaliações.',
      deleteSuccessMessage: 'Avaliação removida com sucesso!',
      deleteErrorMessage: 'Erro ao excluir avaliação.',
    },
  },
  en: {
    useEvaluations: {
      getErrorMessage: 'Error viewing reviews.',
      deleteSuccessMessage: 'Review successfully removed!',
      deleteErrorMessage: 'Error deleting review.',
    },
  },
  es: {
    useEvaluations: {
      getErrorMessage: 'Error al ver las reseñas.',
      deleteSuccessMessage: '¡Revisión eliminada con éxito!',
      deleteErrorMessage: 'Error al eliminar la reseña.',
    },
  },
}
