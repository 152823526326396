import { LoadingOutlined } from '@ant-design/icons'
import { Card, Space } from 'antd'
import Text from 'antd/lib/typography/Text'
import React from 'react'
import { useSkills } from '~/hooks/Skills/useSkills'
import { translate } from '~/utils/locale'
import SkillModal from './SkillModal'
import SkillsTable from './SkillsTable'

// import { Container } from './styles';

const SkillBoard: React.FC = () => {
  const { isLoading, isFetching, skillsData } = useSkills()

  return (
    <Card
      title={
        <Space size="large">
          <b>{translate('Skill.cardTitle')}</b>
          {isLoading ? (
            <LoadingOutlined spin />
          ) : (
            <Text
              type="secondary"
              style={{ fontWeight: 'normal', fontSize: 14 }}
            >
              {skillsData?.length === 1
                ? `${translate(
                    'Skill.totalText',
                  )} ${skillsData?.length} ${translate('Skill.skillText')}`
                : `${translate(
                    'Skill.totalText',
                  )} ${skillsData?.length} ${translate('Skill.skillsText')}`}
            </Text>
          )}
          {!isLoading && isFetching && <LoadingOutlined spin />}
        </Space>
      }
      extra={<SkillModal />}
      bordered={false}
      style={{ width: '100%', padding: 30 }}
    >
      <SkillsTable data={skillsData} loading={isLoading} />
    </Card>
  )
}

export default SkillBoard
