export default {
  pt: {
    EquipmentsTable: {
      nameColumn: 'Nome',
      identifierColumn: 'Identificador',
      categoryColumn: 'Categoria',
      actionsColumn: 'Ações',
      viewEquipmentTooltip: 'Visualizar Equipamento',
      deletePopconfirm: 'Deseja mesmo deletar o equipamento?',
      deleteTooltip: 'Deletar',
    },
  },
  en: {
    EquipmentsTable: {
      nameColumn: 'Name',
      identifierColumn: 'Identifier',
      categoryColumn: 'Category',
      actionsColumn: 'Actions',
      viewEquipmentTooltip: 'View Equipment',
      deletePopconfirm: 'Do you really want to delete the equipment?',
      deleteTooltip: 'Delete',
    },
  },
  es: {
    EquipmentsTable: {
      nameColumn: 'Nombre',
      identifierColumn: 'Identificador',
      categoryColumn: 'Categoría',
      actionsColumn: 'Acciones',
      viewEquipmentTooltip: 'Ver Equipo',
      deletePopconfirm: '¿Realmente desea eliminar el equipo?',
      deleteTooltip: 'Borrar',
    },
  },
}
