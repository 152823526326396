/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react'

import {
  Button,
  Form,
  Modal,
  Select,
  Checkbox,
  Row,
  Col,
  message,
  Space,
} from 'antd'

import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import pt_BR from 'react-phone-number-input/locale/pt-BR.json'
import { useParams } from 'react-router-dom'
import { Container, ContainerButton } from './styles'
import { useClients } from '~/hooks/Clients/useClients'

import api from '~/services/api'

import { useCompanies } from '~/hooks/Companies/useCompanies'
import { useLocale } from '~/hooks/locale/useLocale'

import { ShowError } from '~/utils/errors/apiErrors'
import { translate } from '~/utils/locale'

interface PhoneProps {
  phone: string
  type: string
  is_whatsapp: boolean
}

const CreatePhones: React.FC = () => {
  const { selectedCompany } = useCompanies()
  const { locale } = useLocale()
  const { customerId } = useParams<{ customerId: string }>()

  const { clients, isLoading, refetch } = useClients()
  const [isModalOpen, setIsModalOpen] = useState(false)

  if (isLoading || !clients) {
    return <p>{translate('ClientsAdditionalPhonesCreate.loadingText')}</p>
  }

  async function onUpdateClient(dataForm: PhoneProps) {
    try {
      if (dataForm.phone) {
        dataForm.phone = dataForm.phone.replace(/[^0-9]/g, '')
      }

      let data = {}

      if (clients?.phones) {
        data = {
          additional_phones: [...clients.phones, dataForm],
        }
      } else {
        data = {
          additional_phones: [dataForm],
        }
      }
      await api.put(`/company/${selectedCompany}/customer/${customerId}`, data)

      refetch()
      message.success(translate('ClientsAdditionalPhonesCreate.successMessage'))
    } catch (err: any) {
      ShowError(
        err.message,
        translate('ClientsAdditionalPhonesCreate.errorMessage'),
        locale,
      )
    }

    setIsModalOpen(false)
  }

  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleOk = () => {
    setIsModalOpen(false)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  return (
    <Container>
      <Button type="primary" onClick={showModal}>
        {translate('ClientsAdditionalPhonesCreate.newPhoneButton')}
      </Button>
      <Modal
        title={translate('ClientsAdditionalPhonesCreate.modalTitle')}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Space key="buttons">
            <Button key="back" onClick={handleCancel}>
              {translate('ClientsAdditionalPhonesCreate.closeButton')}
            </Button>
            <ContainerButton>
              <Button
                key="save"
                type="primary"
                form="onFormPhone"
                htmlType="submit"
                id="btn_create_aditional_phone_client"
              >
                {translate('ClientsAdditionalPhonesCreate.saveButton')}
              </Button>
            </ContainerButton>
          </Space>,
        ]}
      >
        <Form layout="vertical" onFinish={onUpdateClient} id="onFormPhone">
          <Row gutter={10}>
            <Col span={12}>
              <Form.Item
                label={translate('ClientsAdditionalPhonesCreate.typeLabel')}
                name="type"
                rules={[
                  {
                    required: true,
                    message: translate(
                      'ClientsAdditionalPhonesCreate.typeRule',
                    ),
                  },
                ]}
              >
                <Select
                  placeholder={translate(
                    'ClientsAdditionalPhonesCreate.selectPlaceholder',
                  )}
                >
                  <Select.Option value="home">
                    {translate('ClientsAdditionalPhonesCreate.selectOptionOne')}
                  </Select.Option>
                  <Select.Option value="cell">
                    {translate('ClientsAdditionalPhonesCreate.selectOptionTwo')}
                  </Select.Option>
                  <Select.Option value="commercial">
                    {translate(
                      'ClientsAdditionalPhonesCreate.selectOptionThree',
                    )}
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={translate('ClientsAdditionalPhonesCreate.phoneLabel')}
                name="phone"
                rules={[
                  {
                    required: true,
                    message: translate(
                      'ClientsAdditionalPhonesCreate.phoneRuleOne',
                    ),
                  },
                  () => ({
                    validator(rule, phone) {
                      if (phone && isValidPhoneNumber(phone)) {
                        return Promise.resolve()
                      }
                      return Promise.reject(
                        new Error(
                          translate(
                            'ClientsAdditionalPhonesCreate.phoneRuleTwo',
                          ),
                        ),
                      )
                    },
                  }),
                ]}
              >
                <PhoneInput
                  placeholder={translate(
                    'ClientsAdditionalPhonesCreate.phonePlaceholder',
                  )}
                  labels={pt_BR}
                  defaultCountry="BR"
                  international
                  onChange={() => {
                    // Do nothing
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            label={translate('ClientsAdditionalPhonesCreate.whatsappLabel')}
            name="is_whatsapp"
            valuePropName="checked"
          >
            <Checkbox>
              {translate('ClientsAdditionalPhonesCreate.whatsappCheckbox')}
            </Checkbox>
          </Form.Item>
        </Form>
      </Modal>
    </Container>
  )
}

export default CreatePhones
