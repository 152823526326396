/* eslint-disable no-underscore-dangle */
import { ColumnsType } from 'antd/lib/table'
import React from 'react'

import { Popconfirm, Space, Table, Tooltip, Button } from 'antd'
import { DeleteFilled, LoadingOutlined } from '@ant-design/icons'

import { translate } from '~/utils/locale'
import { useTicketType } from '~/hooks/HelpDesk/useTicketType'
import UpdateTicketType from '../Update'

interface PauseProps {
  description: string
  _id: string
  title: string
}

const TicketTypeTable: React.FC = () => {
  const { ticketType, deleteTicketType, isFetching } = useTicketType()

  const columns: ColumnsType<PauseProps> = [
    {
      title: translate('TicketTypeTable.titleColumn'),
      key: 'title',
      dataIndex: 'title',
    },
    {
      title: translate('TicketTypeTable.descriptionColumn'),
      align: 'left',
      dataIndex: 'description',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: translate('TicketTypeTable.actionsColumn'),
      align: 'center',
      key: 'remove',
      render: (record) => (
        <Space size="middle">
          <UpdateTicketType data={record} />
          <Popconfirm
            title={translate('TicketTypeTable.deletePopconfirm')}
            onConfirm={() => deleteTicketType(record._id)}
          >
            <Tooltip title={translate('TicketTypeTable.deleteTooltip')}>
              <Button ghost danger shape="circle" icon={<DeleteFilled />} />
            </Tooltip>
          </Popconfirm>
        </Space>
      ),
    },
  ]

  return (
    <>
      <Table
        rowKey={(record) => record._id}
        loading={{
          indicator: <LoadingOutlined />,
          spinning: isFetching,
          size: 'large',
        }}
        scroll={{ x: 1000 }}
        columns={columns}
        dataSource={ticketType}
        pagination={{
          showTotal: (total, range) => `${range[0]} - ${range[1]} / ${total}`,
        }}
      />
    </>
  )
}

export default TicketTypeTable
