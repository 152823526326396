export default {
  pt: {
    useDashboard: {
      getContributorErrorMessage: 'Erro ao visualizar colaboradores.',
      getStatesErrorMessage: 'Erro ao visualizar os estados',
    },
  },
  en: {
    useDashboard: {
      getContributorErrorMessage: 'Error viewing collaborators.',
      getStatesErrorMessage: 'Error when viewing states',
    },
  },
  es: {
    useDashboard: {
      getContributorErrorMessage: 'Error al ver colaboradores.',
      getStatesErrorMessage: 'Error al visualizar estados',
    },
  },
}
