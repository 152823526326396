import React from 'react'

import { Card } from 'antd'

import ScaleClientsAdd from '~/components/DataManipulation/Scales/ScaleClients/ScaleClientsAdd'
import ScaleClientsList from '~/components/DataManipulation/Scales/ScaleClients/ScaleClientsList'
import { translate } from '~/utils/locale'

const ScaleClientsForm: React.FC = () => {
  return (
    <Card
      bordered={false}
      title={<b>{translate('ScalesClientsForm.cardTitle')}</b>}
      extra={<ScaleClientsAdd />}
    >
      <ScaleClientsList />
    </Card>
  )
}

export default ScaleClientsForm
