export default {
  pt: {
    TicketsType: {
      cardTitle: 'Tipos de Solicitação',
      totalText: 'Total de',
      ticketTypeText: 'tipo de solicitação',
      ticketsTypesText: 'tipos de solicitação',
    },
  },
  en: {
    TicketsType: {
      cardTitle: 'Tickets Type',
      totalText: 'Total of',
      ticketTypeText: 'ticket type',
      ticketsTypesText: 'tickets type',
    },
  },
  es: {
    TicketsType: {
      cardTitle: 'Tipos de Solicitudes',
      totalText: 'Total de',
      ticketTypeText: 'tipo de solicitude',
      ticketsTypesText: 'tipos de solicitudes',
    },
  },
}
