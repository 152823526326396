import { lighten } from 'polished'
import styled from 'styled-components'

export const ContainerButton = styled.div`
  #btn_update_ticket_type {
    background: #3db065;
    border: 1px solid #3db065;
    color: white;

    &:hover {
      background: ${lighten(0.05, '#3DB065')};
      border: 1px solid ${lighten(0.1, '#3DB065')};
    }
  }
`
