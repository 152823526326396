export default {
  pt: {
    ScalesCalendarModal: {
      successMessage: 'Horário deletado da escala com sucesso!',
      errorMessage: 'Erro ao deletar horário da escala.',
      cancelButton: 'Cancelar',
      deletePopconfirm: 'Deseja mesmo deletar este horário?',
      deleteCheckbox: 'Excluir horários semelhantes?',
      deleteTooltip: 'Deletar Horário',
      timeStringOne: 'Das',
      timeStringTwo: 'Até as',
      descriptionLabel: 'Descrição',
    },
  },
  en: {
    ScalesCalendarModal: {
      successMessage: 'Schedule deleted from scale successfully!',
      errorMessage: 'Error deleting schedule from scale.',
      cancelButton: 'Cancel',
      deletePopconfirm: 'Do you really want to delete this time?',
      deleteCheckbox: 'Exclude similar schedule times?',
      deleteTooltip: 'Delete Time',
      timeStringOne: 'From',
      timeStringTwo: 'To',
      descriptionLabel: 'Description',
    },
  },
  es: {
    ScalesCalendarModal: {
      successMessage: '¡Programa eliminado de la escala con éxito!',
      errorMessage: 'Error al eliminar el programa de la báscula.',
      cancelButton: 'Cancelar',
      deletePopconfirm: '¿De verdad quieres borrar esta vez?',
      deleteCheckbox: '¿Excluir horas similares?',
      deleteTooltip: 'Eliminar hora',
      timeStringOne: 'De',
      timeStringTwo: 'A',
      descriptionLabel: 'Descripción',
    },
  },
}
