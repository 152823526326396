/* eslint-disable @typescript-eslint/no-explicit-any */
import { ArrowLeftOutlined, SaveOutlined } from '@ant-design/icons'
import { Button, Card, Form, Space, Steps, message } from 'antd'
import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import DetailsParentClients from './Details'
import CreateParentClientAddress from './Address'
import { useListParentClients } from '~/hooks/Clients/useListParentCustomer'
import { useParentCustomerFields } from '~/hooks/Clients/useParentCustomerFields'
import CreateParentClientsFinancial from './Financial'
import { ContainerButton } from './styles'
import { translate } from '~/utils/locale'

// import { Container } from './styles';

interface ParentClientProps {
  companyName: string
  fantasyName: string
  cnpj: string
  cpf: string
  name: string
  email: string
  phone: string
  municipal_registration: string
  state_registration: string
  zip_code: string
  street: string
  number: string
  complement: string
  neighborhood: string
  city: string
  state: string
  location: { x: number; y: number }
  latitude: any
  longitude: any
  task_value: number
  amount_hours: number
  amount_hours_additional?: number
  number_km: number
  number_km_additional?: number
}

const CreateParentClient: React.FC = () => {
  const { companyName, cnpjText, cpfText, createParentClients } =
    useListParentClients()

  const history = useHistory()

  const {
    fantasyName,
    municipalRegistration,
    stateRegistration,
    name,
    email,
    phone,
    taskValue,
    amountHours,
    amountHoursAdditional,
    numberKm,
    numberKmAdditional,
    taskTypeData,
  } = useParentCustomerFields()

  const [current, setCurrent] = useState(0)
  const [form] = Form.useForm()

  const [nextValue, setNextValue] = useState(true)

  const next = () => {
    setCurrent(current + 1)
  }

  const prev = () => {
    setCurrent(current - 1)
  }

  const steps = [
    {
      title: translate('ClientsPrincipalSteps.stepOne'),
      description: translate('ClientsPrincipalSteps.stepOneDescription'),
      content: <DetailsParentClients />,
    },
    {
      title: translate('ClientsPrincipalSteps.stepTwo'),
      description: translate('ClientsPrincipalSteps.stepTwoDescription'),
      content: <CreateParentClientsFinancial form={form} />,
    },
    {
      title: translate('ClientsPrincipalSteps.stepThree'),
      description: translate('ClientsPrincipalSteps.stepThreeDescription'),
      content: <CreateParentClientAddress form={form} />,
    },
  ]

  useEffect(() => {
    if (
      companyName?.length !== 0 &&
      fantasyName?.length !== 0 &&
      name?.length !== 0 &&
      email?.length !== 0
    ) {
      setNextValue(false)
    } else {
      setNextValue(true)
    }
  }, [companyName, fantasyName, email, name])

  async function CreateParentClient(valueForm: ParentClientProps) {
    if (form.getFieldError('mapa')[0]) {
      message.error('Teste')
    }

    const dataForm = {
      companyName,
      fantasyName,
      cpf: cpfText?.replace(/[^0-9]/g, ''),
      cnpj: cnpjText?.replace(/[^0-9]/g, ''),
      municipal_registration: municipalRegistration,
      state_registration: stateRegistration,
      contact: {
        email,
        name,
        phone: phone?.replace(/[^0-9]/g, ''),
      },
      financial: {
        task_value: taskTypeData?.id ? taskTypeData.amount_to_pay : taskValue,
        amount_hours: amountHours,
        amount_hours_additional: amountHoursAdditional,
        number_km: numberKm,
        number_km_additional: numberKmAdditional,
      },
      address: {
        zip_code: valueForm.zip_code,
        street: valueForm.street,
        number: valueForm.number,
        complement: valueForm.complement,
        neighborhood: valueForm.neighborhood,
        city: valueForm.city,
        state: valueForm.state,
        location: {
          x: valueForm.latitude,
          y: valueForm.longitude,
        },
      },
    }

    await createParentClients(dataForm)
    history.push('/clientePrincipal')
  }

  return (
    <Card
      title={
        <Space size="large">
          <Link to="/clientePrincipal">
            <ArrowLeftOutlined />
          </Link>
          <b>{translate('ClientsPrincipalSteps.pageheaderTitle')}</b>
        </Space>
      }
      extra={
        <Space size="middle">
          <ContainerButton>
            <Button
              type="primary"
              icon={<SaveOutlined />}
              htmlType="submit"
              form="parentClientForm"
              disabled={current < 2}
              id={current < 2 ? '' : 'btn_create_parent_client'}
            >
              {translate('ClientsPrincipalSteps.saveButton')}
            </Button>
          </ContainerButton>
        </Space>
      }
      bordered={false}
      style={{ width: '100%', padding: 30 }}
    >
      <Form
        layout="vertical"
        form={form}
        onFinish={CreateParentClient}
        id="parentClientForm"
      >
        <Steps items={steps} current={current}></Steps>
        <div>{steps[current].content}</div>
      </Form>

      <div style={{ marginTop: 24 }}>
        {current < steps.length - 1 && (
          <Button type="primary" onClick={() => next()} disabled={nextValue}>
            {translate('ClientsPrincipalSteps.nextButton')}
          </Button>
        )}
        {current > 0 && (
          <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
            {translate('ClientsPrincipalSteps.backButton')}
          </Button>
        )}
      </div>
    </Card>
  )
}

export default CreateParentClient
