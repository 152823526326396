import styled from 'styled-components'

export const Container = styled.div`
  #delete_attachment {
    color: var(--color-danger);
  }

  #edit_attachment {
    color: var(--secondary-color);
  }
`
