import { lighten } from 'polished'
import styled from 'styled-components'

export const Container = styled.div`
  #btn-edit-evaluation-contributor {
    background: var(--quartenary-color);
    border: 1px solid var(--quartenary-color);

    &:hover {
      background: ${lighten(0.05, '#3969B7')};
      border: 1px solid ${lighten(0.1, '#3969B7')};
    }
  }
`
export const ContainerButton = styled.div`
  #btn_update_contributor {
    background: #3db065;
    border: 1px solid #3db065;
    color: white;

    &:hover {
      background: ${lighten(0.05, '#3DB065')};
      border: 1px solid ${lighten(0.1, '#3DB065')};
    }
  }
`
