/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react'

import {
  Drawer,
  Form,
  Button,
  Col,
  Row,
  Select,
  Input,
  Slider,
  Tooltip,
  Divider,
} from 'antd'
import { CloseOutlined, FilterOutlined } from '@ant-design/icons'

import { MaskedInput } from 'antd-mask-input'

import { Container } from './styles'
import { useDashboard } from '~/hooks/Dashboard/useDashboard'
import { useListStatus } from '~/hooks/Status/useListStatus'
import { translate } from '~/utils/locale'

const ContributorDashboardFilter: React.FC = () => {
  const {
    states,
    cities,
    setCity,
    isLoadingState,
    isLoadingCity,
    filterOptions,
    handleUpdateFilters,
    handleResetFilters,
  } = useDashboard()

  const { listStatus } = useListStatus()

  const [addressType, setAddressType] = useState<string>('')
  // const [findingCEPStatus, setFindingCEPStatus] = useState(
  //   '' as ValidateStatus,
  // );

  const [open, setOpen] = useState(false)

  const [form] = Form.useForm()

  const { Option } = Select

  function handleAddressType(valueType: string) {
    setAddressType(valueType)
    localStorage.setItem('addressType', valueType)

    if (form.isFieldTouched('address_type')) {
      form.setFieldsValue({ address: undefined, states: undefined })
    }
  }

  function handleCity(value: string) {
    setCity(value)
    localStorage.setItem('stateType', value)
  }

  const getAddressType = localStorage.getItem('addressType')

  useEffect(() => {
    form.setFieldsValue({
      address: filterOptions?.address,
      address_type: localStorage.getItem('addressType'),
      states: localStorage.getItem('stateType'),
      contact: filterOptions?.contact,
      evaluationRange: [
        filterOptions.fromEvaluation,
        filterOptions?.toEvaluation,
      ],
      internalEvaluationRange: [
        filterOptions.fromInternalEvaluation,
        filterOptions?.toInternalEvaluation,
      ],
    })
  }, [filterOptions, form])

  const onFinish = (dataFilter: any) => {
    handleUpdateFilters(dataFilter)
    setOpen(false)
  }

  const onReset = () => {
    handleResetFilters()
    form.resetFields()
    setOpen(false)
    localStorage.removeItem('addressType')
    localStorage.removeItem('stateType')
  }

  const showDrawer = () => {
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
  }

  const isFilter = Object.keys(filterOptions).length !== 0

  const marks = {
    0: '0',
    1: '1',
    2: '2',
    3: '3',
    4: '4',
    5: '5',
  }

  return (
    <Container isFilter={isFilter}>
      <Button
        type="primary"
        onClick={showDrawer}
        icon={
          !isFilter ? (
            <FilterOutlined />
          ) : (
            <Tooltip title={translate('DashboardFilter.cleanFilter')}>
              <CloseOutlined onClick={onReset} />
            </Tooltip>
          )
        }
        id="btn_filter_dashboard_contributor"
      >
        {isFilter
          ? translate('DashboardFilter.filterAppliedText')
          : translate('DashboardFilter.filterText')}
      </Button>
      <Drawer
        forceRender
        title={translate('DashboardFilter.drawerTitle')}
        width={580}
        onClose={onClose}
        open={open}
        style={{ paddingBottom: 80 }}
      >
        <Form layout="vertical" form={form} onFinish={onFinish}>
          <Divider>
            {translate('DashboardFilter.contributorFilterLabel')}
          </Divider>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="contact"
                label={translate('DashboardFilter.contributorInfo')}
                tooltip={
                  <p>
                    {translate('DashboardFilter.contributorParagraphOne')}
                    &nbsp;
                    <b>
                      {translate('DashboardFilter.contributorParagraphTwo')}
                    </b>
                    .
                  </p>
                }
              >
                <Input
                  placeholder={translate(
                    'DashboardFilter.contributorPlaceholder',
                  )}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="address_type"
                label={translate('DashboardFilter.addressLabel')}
              >
                <Select
                  placeholder={translate(
                    'DashboardFilter.addressSelectPlaceholder',
                  )}
                  onChange={handleAddressType}
                  optionFilterProp="children"
                >
                  <Option value="state">
                    {translate('DashboardFilter.addressSelectState')}
                  </Option>
                  <Option value="city">
                    {translate('DashboardFilter.addressSelectCity')}
                  </Option>
                  <Option value="cep">
                    {translate('DashboardFilter.addressSelectZipcode')}
                  </Option>
                  <Option value="others">
                    {translate('DashboardFilter.addressSelectStreet')}
                  </Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              {addressType === 'state' || getAddressType === 'state' ? (
                <Form.Item
                  name="address"
                  label={translate('DashboardFilter.stateLabel')}
                >
                  <Select
                    placeholder={translate(
                      'DashboardFilter.stateSelectPlaceholder',
                    )}
                    onChange={handleCity}
                    loading={isLoadingState}
                    showSearch
                    optionFilterProp="children"
                  >
                    {states?.map((state) => (
                      <Option key={state.id} value={state.sigla}>
                        {state.nome}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              ) : (
                ''
              )}

              {addressType === 'cep' || getAddressType === 'cep' ? (
                <Form.Item
                  label={translate('DashboardFilter.zipcodeLabel')}
                  name="address"
                  hasFeedback
                  // validateStatus={findingCEPStatus}
                  rules={[
                    {
                      required: true,
                      message: translate('DashboardFilter.zipcodeRule'),
                    },
                  ]}
                >
                  <MaskedInput
                    // onChange={e => {
                    //   const cepInput = e.target.value.replace(/\D/g, '');
                    //   //  if (cepInput.length === 8) {
                    //   //    searchCEP(e.target.value);
                    //   //  }
                    // }}
                    mask={'00000-000'}
                  />
                </Form.Item>
              ) : (
                ''
              )}

              {addressType === 'others' || getAddressType === 'others' ? (
                <Form.Item
                  name="address"
                  label={translate('DashboardFilter.streetLabel')}
                >
                  <Input
                    placeholder={translate('DashboardFilter.streetPlacehoder')}
                  />
                </Form.Item>
              ) : (
                ''
              )}
            </Col>
          </Row>
          {addressType === 'city' || getAddressType === 'city' ? (
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="states"
                  label={translate('DashboardFilter.stateLabel')}
                >
                  <Select
                    placeholder={translate(
                      'DashboardFilter.stateSelectPlaceholder',
                    )}
                    onChange={handleCity}
                    loading={isLoadingState}
                    showSearch
                    optionFilterProp="children"
                  >
                    {states?.map((state) => (
                      <Option key={state.id} value={state.sigla}>
                        {state.nome}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="address"
                  label={translate('DashboardFilter.cityLabel')}
                >
                  <Select
                    placeholder={translate(
                      'DashboardFilter.citySelectPlaceholder',
                    )}
                    loading={isLoadingCity}
                    showSearch
                    optionFilterProp="children"
                  >
                    {cities?.map((city) => (
                      <Option key={city.id} value={city.nome}>
                        {city.nome}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          ) : (
            ''
          )}
          <Row>
            <Col span={12}>
              <Form.Item
                label={translate('DashboardFilter.statusLabel')}
                name="status_id"
              >
                <Select
                  placeholder={translate(
                    'DashboardFilter.statusSelectPlaceholder',
                  )}
                  mode="multiple"
                  style={{ width: '100%' }}
                >
                  {listStatus?.map((status) => (
                    <Select.Option value={status.id} key={status.id}>
                      {status.title}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="evaluationRange"
                label={translate('DashboardFilter.evaluationLabel')}
                style={{ width: '100%' }}
              >
                <Slider marks={marks} range max={5} min={0} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="internalEvaluationRange"
                label={translate('DashboardFilter.internalEvaluationLabel')}
                style={{ width: '100%' }}
              >
                <Slider marks={marks} range max={5} min={0} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item style={{ width: '100%' }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: '100%' }}
                >
                  {translate('DashboardFilter.applyButton')}
                </Button>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item style={{ width: '100%' }}>
                <Button style={{ width: '100%' }} onClick={onReset}>
                  {translate('DashboardFilter.cleanButton')}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </Container>
  )
}

export default ContributorDashboardFilter
