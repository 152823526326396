/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable consistent-return */
import React, { useContext, createContext, useState } from 'react'
import { useQuery } from 'react-query'

import { message } from 'antd'

import api from '~/services/api'

import { useCompanies } from '~/hooks/Companies/useCompanies'
import { useLocale } from '~/hooks/locale/useLocale'

import { ShowError } from '~/utils/errors/apiErrors'
import { translate } from '~/utils/locale'

interface contribuitorsTeams {
  collaborator: {
    id: string
    contact: {
      id: string
      name: string
    }
  }
}

interface Managers {
  id: string
  contact: {
    id: string
    name: string
  }
}

interface TeamsProps {
  title: string
  id: string
  created_at: Date
  manager: Managers
  description: string
  payment_day?: string
  payment_conditions?: string
  payment_method?: string
  number_bank?: string
  name_bank?: string
  personal_bank?: string
  agency_bank?: string
  account_bank?: string
  amount_to_receive?: number
  type_account_bank?: string
  key_pix?: string
  contributorsTeams: contribuitorsTeams[]
}

interface TeamsContextData {
  teams?: TeamsProps[]
  createTeams(name: object | undefined): void
  updateTeams(name: object | undefined, teamsId: string): void
  updateFinancialTeams(name: object | undefined, teamsId: string): void
  deleteTeams(teamsId: string): void
  isLoading: boolean
  isFetching: boolean
  refetch: () => void
  teamsTotal?: number
}

const TeamsContext = createContext<TeamsContextData>({} as TeamsContextData)

export const TeamsProvider: React.FC = ({ children }) => {
  const { selectedCompany } = useCompanies()
  const { locale } = useLocale()

  const [teamsTotal, setTeamsTotal] = useState<number>()

  const {
    data: teams,
    isLoading,
    isFetching,
    refetch,
  } = useQuery(
    [`group${selectedCompany}`, selectedCompany],

    async () => {
      try {
        const response = await api.get(`/company/${selectedCompany}/teams`)

        const { data } = response

        setTeamsTotal(data.total)

        return data.results
      } catch (err: any) {
        ShowError(
          err.message,
          translate('useListTeams.getErrorMessage'),
          locale,
        )
      }
    },
  )

  async function createTeams(name: object) {
    try {
      await api.post(`/company/${selectedCompany}/team`, name)

      refetch()
      message.success(translate('useListTeams.createSuccessMessage'))
    } catch (err: any) {
      ShowError(
        err.message,
        translate('useListTeams.createErrorMessage'),
        locale,
      )
    }
  }

  async function updateTeams(name: object, teamsId: string) {
    try {
      await api.put(`/company/${selectedCompany}/team/${teamsId}`, name)

      refetch()
      message.success(translate('useListTeams.updateSuccessMessage'))
    } catch (err: any) {
      ShowError(
        err.message,
        translate('useListTeams.updateErrorMessage'),
        locale,
      )
    }
  }

  async function updateFinancialTeams(data: object, teamId: string) {
    try {
      await api.put(
        `/company/${selectedCompany}/team/${teamId}/financial`,
        data,
      )

      refetch()
      message.success(translate('useTeams.updateSuccessMessage'))
    } catch (err: any) {
      ShowError(err.message, translate('useTeams.updateErrorMessage'), locale)
    }
  }

  async function deleteTeams(teamsId: string) {
    try {
      await api.delete(`/company/${selectedCompany}/team/${teamsId}`)

      refetch()
      message.success(translate('useListTeams.deleteSuccessMessage'))
    } catch (err: any) {
      ShowError(
        err.message,
        translate('useListTeams.deleteErrorMessage'),
        locale,
      )
    }
  }

  return (
    <TeamsContext.Provider
      value={{
        teams,
        createTeams,
        updateTeams,
        updateFinancialTeams,
        deleteTeams,
        isLoading,
        isFetching,
        refetch,
        teamsTotal,
      }}
    >
      {children}
    </TeamsContext.Provider>
  )
}

export function useTeams(): TeamsContextData {
  const context = useContext(TeamsContext)

  if (!context) {
    throw new Error('useAuth must be used within an AutoProvider')
  }

  return context
}
