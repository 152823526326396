import React from 'react'
import { Helmet } from 'react-helmet'
import UsersBoard from '~/components/DataManipulation/Users'

import { UsersListProvider } from '~/hooks/Users/useListUsers'
import { translate } from '~/utils/locale'

const UsersContacts: React.FC = () => {
  return (
    <UsersListProvider>
      <Helmet>
        <title>{translate('Users.helmetTitle')}</title>
      </Helmet>
      <UsersBoard />
    </UsersListProvider>
  )
}

export default UsersContacts
