export default {
  pt: {
    useAttachments: {
      getErrorMessage: 'Erro ao visualizar documentos/anexos do equipamento.',
      createLoadingMessage: 'Carregando arquivo...',
      createSuccessMessage: 'Documento/Anexo adicionado com sucesso!',
      createErrorMessage: 'Erro ao adicionar documentos/anexos ao equipamento.',
      updateLoadingMessage: 'Atualizando documento/anexo...',
      updateSuccessMessage: 'Documento/Anexo atualizado com sucesso!',
      updateErrorMessage: 'Erro ao atualizar documentos/anexos do equipamento.',
      deleteSuccessMessage: 'Documento/Anexo deletado com sucesso!',
      deleteErrorMessage: 'Erro ao excluir documentos/anexos do equipamento.',
    },
  },
  en: {
    useAttachments: {
      getErrorMessage: 'Error viewing equipment documents/attachments.',
      createLoadingMessage: 'Loading file...',
      createSuccessMessage: 'Document/Attachment successfully added!',
      createErrorMessage: 'Error adding documents/attachments to the device.',
      updateLoadingMessage: 'Updating document/attachment...',
      updateSuccessMessage: 'Document/Attachment successfully updated!',
      updateErrorMessage: 'Error updating equipment documents/attachments.',
      deleteSuccessMessage: 'Document/Attachment successfully deleted!',
      deleteErrorMessage:
        'Error deleting documents/attachments from the device.',
    },
  },
  es: {
    useAttachments: {
      getErrorMessage: 'Error al ver documentos/adjuntos del equipo.',
      createLoadingMessage: 'Cargando archivo...',
      createSuccessMessage: '¡Documento/Adjunto agregado exitosamente!',
      createErrorMessage:
        'Error al agregar documentos/adjuntos al dispositivo.',
      updateLoadingMessage: 'Actualizando documento/archivo adjunto...',
      updateSuccessMessage: '¡Documento/Adjunto actualizado con éxito!',
      updateErrorMessage: 'Error al actualizar documentos/adjuntos del equipo.',
      deleteSuccessMessage: '¡Documento/Adjunto eliminado con éxito!',
      deleteErrorMessage:
        'Error al eliminar documentos/adjuntos del dispositivo.',
    },
  },
}
