/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, createContext } from 'react'
import { useParams } from 'react-router-dom'

import { message } from 'antd'

// import { useQuery } from 'react-query';
import api from '~/services/api'

import { useCompanies } from '~/hooks/Companies/useCompanies'
import { useLocale } from '~/hooks/locale/useLocale'

import { useEquipments } from './EquipmentsHooks'

import { ShowError } from '~/utils/errors/apiErrors'
import { translate } from '~/utils/locale'

interface AssociatedEquipmentsContextData {
  createAssociatedEquipment(associatedEquipment: string | undefined): void
  deleteAssociatedEquipment(
    associatedEquipament: string | undefined,
    associationId: string | undefined,
  ): void
}

const AssociatedEquipmentsContext =
  createContext<AssociatedEquipmentsContextData>(
    {} as AssociatedEquipmentsContextData,
  )

export const AssociatedEquipmentsProvider: React.FC = ({ children }) => {
  const { selectedCompany } = useCompanies()
  const { locale } = useLocale()
  const { equipmentId } = useParams<{ equipmentId: string }>()
  const { refetch } = useEquipments()

  async function createAssociatedEquipment(associatedEquipment: string) {
    try {
      await api.post(
        `/company/${selectedCompany}/equipment/${equipmentId}/associated-equipment/${associatedEquipment}`,
      )

      message.success(translate('AssociatedEquipments.createSuccessMessage'))
      refetch()
    } catch (err: any) {
      ShowError(
        err.message,
        translate('AssociatedEquipments.createErrorMessage'),
        locale,
      )
    }
  }

  async function deleteAssociatedEquipment(
    associatedEquipament: string,
    associationId: string,
  ) {
    try {
      await api.delete(
        `/company/${selectedCompany}/equipment/${equipmentId}/associated-equipment/${associatedEquipament}/association/${associationId}`,
      )

      refetch()
      message.success(translate('AssociatedEquipments.deleteSuccessMessage'))
    } catch (err: any) {
      ShowError(
        err.message,
        translate('AssociatedEquipments.deleteErrorMessage'),
        locale,
      )
    }
  }

  return (
    <AssociatedEquipmentsContext.Provider
      value={{
        createAssociatedEquipment,
        deleteAssociatedEquipment,
      }}
    >
      {children}
    </AssociatedEquipmentsContext.Provider>
  )
}

export function useAssociatedEquipments(): AssociatedEquipmentsContextData {
  const context = useContext(AssociatedEquipmentsContext)

  if (!context) {
    throw new Error('useAuth must be used within an AutoProvider')
  }

  return context
}
