import styled from 'styled-components'

export const Wrapper = styled.div``

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.5rem;
`

export const Cards = styled.div`
  border: 1px solid #ececec;
  border-radius: 3px;
  flex: 1;
  width: 100%;
  padding: 0.8rem;

  .ant-divider-horizontal {
    margin: 10px 0;
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 0;
  }

  .title span {
    color: black;
  }

  span {
    color: #333333;
  }
`
