export default {
  pt: {
    EquipmentsTabsAssociatedEquipmentsCreate: {
      tooltipText: 'Associar Equipamento',
      modalTitle: 'Novo Associamento',
      closeButton: 'Fechar',
      saveButton: 'Salvar',
      equipmentLabel: 'Equipamento que deseja associar',
      equipmentPlaceholder: 'Selecione o equipamento',
    },
  },
  en: {
    EquipmentsTabsAssociatedEquipmentsCreate: {
      tooltipText: 'Associate Equipment',
      modalTitle: 'New Association',
      closeButton: 'Close',
      saveButton: 'Save',
      equipmentLabel: 'Equipment you want to associate',
      equipmentPlaceholder: 'Select equipment',
    },
  },
  es: {
    EquipmentsTabsAssociatedEquipmentsCreate: {
      tooltipText: 'Equipo Asociado',
      modalTitle: 'Nueva Asociación',
      closeButton: 'Cerrar',
      saveButton: 'Guardar',
      equipmentLabel: 'Equipo que desea asociar',
      equipmentPlaceholder: 'Seleccionar equipo',
    },
  },
}
