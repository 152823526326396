export default {
  pt: {
    EvaluationInternalModal: {
      successMessage: 'Avaliação interna atualizada com sucesso!',
      errorMessage: 'Erro ao atualizar avaliação interna.',
      editButton: 'Editar',
      modalTitle: 'Editar Avaliação Interna',
      internalEvaluationLabel: 'Avaliação Interna',
    },
  },
  en: {
    EvaluationInternalModal: {
      successMessage: 'Internal review successfully updated!',
      errorMessage: 'Error updating internal review.',
      editButton: 'Edit',
      modalTitle: 'Edit Internal Review',
      internalEvaluationLabel: 'Internal Review',
    },
  },
  es: {
    EvaluationInternalModal: {
      successMessage: '¡Evaluación interna actualizada con éxito!',
      errorMessage: 'Error al actualizar la evaluación interna.',
      editButton: 'Editar',
      modalTitle: 'Editar evaluación interna',
      internalEvaluationLabel: 'Evaluación Interna',
    },
  },
}
