export default {
  pt: {
    ShowContacts: {
      helmetTitle: 'Contatos de Clientes',
    },
  },
  en: {
    ShowContacts: {
      helmetTitle: 'Customer Contacts',
    },
  },
  es: {
    ShowContacts: {
      helmetTitle: 'Contactos de Clientes',
    },
  },
}
