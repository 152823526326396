export default {
  pt: {
    ClientsContactsTab: {
      nameColumn: 'Nome',
      emailColumn: 'Email',
      phoneColumn: 'Telefone',
      officeColumn: 'Cargo',
      actionsColumn: 'Ações',
      deletePopconfirm: 'Deseja mesmo deletar o contato?',
      deleteTooltip: 'Deletar',
      totalText: 'Total de',
      contactText: 'contato',
      contactsText: 'contatos',
    },
  },
  en: {
    ClientsContactsTab: {
      nameColumn: 'Name',
      emailColumn: 'Email',
      phoneColumn: 'Phone',
      officeColumn: 'Office',
      actionsColumn: 'Actions',
      deletePopconfirm: 'Do you really want to delete the contact?',
      deleteTooltip: 'Delete',
      totalText: 'Total of',
      contactText: 'contact',
      contactsText: 'contacts',
    },
  },
  es: {
    ClientsContactsTab: {
      nameColumn: 'Nombre',
      emailColumn: 'Correo electrónico',
      phoneColumn: 'Teléfono',
      officeColumn: 'Cargo',
      actionsColumn: 'Acciones',
      deletePopconfirm: '¿Realmente desea eliminar el contacto?',
      deleteTooltip: 'Borrar',
      totalText: 'Total de',
      contactText: 'contacto',
      contactsText: 'contactos',
    },
  },
}
