import styled from 'styled-components'

export const Container = styled.div`
  // ESPAÇAMENTO DOS DIAS
  .fc-daygrid-day {
    height: 16% !important;
  }

  // ESTILIZAÇÃO GERAL DO CALENDÁRIO (COR DO TEXTO E CURSOR)
  .fc-col-header-cell-cushion {
    color: rgba(0, 0, 0, 0.85);
    :hover {
      cursor: default;
    }
  }

  .fc-daygrid-day-number {
    color: rgba(0, 0, 0, 0.85);
    :hover {
      cursor: default;
    }
  }

  .fc-daygrid-more-link {
    color: rgba(0, 0, 0, 0.85);
  }

  //CLASSE PERSONALIZADA PARA DETERMINAR A COR DO PONTO DO EVENTO
  .fc-event-color-pink {
    .fc-daygrid-event-dot {
      border: calc(var(--fc-daygrid-event-dot-width, 8px) / 2) solid #eb2f96;
    }
    color: rgba(0, 0, 0, 0.85);
  }

  .fc-event-color-red {
    .fc-daygrid-event-dot {
      border: calc(var(--fc-daygrid-event-dot-width, 8px) / 2) solid #f5222d;
    }
    color: rgba(0, 0, 0, 0.85);
  }

  .fc-event-color-yellow {
    .fc-daygrid-event-dot {
      border: calc(var(--fc-daygrid-event-dot-width, 8px) / 2) solid #fadb14;
    }
    color: rgba(0, 0, 0, 0.85);
  }

  .fc-event-color-orange {
    .fc-daygrid-event-dot {
      border: calc(var(--fc-daygrid-event-dot-width, 8px) / 2) solid #fa8c16;
    }
    color: rgba(0, 0, 0, 0.85);
  }

  .fc-event-color-cyan {
    .fc-daygrid-event-dot {
      border: calc(var(--fc-daygrid-event-dot-width, 8px) / 2) solid #13c2c2;
    }
    color: rgba(0, 0, 0, 0.85);
  }

  .fc-event-color-green {
    .fc-daygrid-event-dot {
      border: calc(var(--fc-daygrid-event-dot-width, 8px) / 2) solid #52c41a;
    }
    color: rgba(0, 0, 0, 0.85);
  }

  .fc-event-color-blue {
    .fc-daygrid-event-dot {
      border: calc(var(--fc-daygrid-event-dot-width, 8px) / 2) solid #1890ff;
    }
    color: rgba(0, 0, 0, 0.85);
  }

  .fc-event-color-purple {
    .fc-daygrid-event-dot {
      border: calc(var(--fc-daygrid-event-dot-width, 8px) / 2) solid #722ed1;
    }
    color: rgba(0, 0, 0, 0.85);
  }

  .fc-event-color-geekblue {
    .fc-daygrid-event-dot {
      border: calc(var(--fc-daygrid-event-dot-width, 8px) / 2) solid #2f54eb;
    }
    color: rgba(0, 0, 0, 0.85);
  }

  .fc-event-color-magenta {
    .fc-daygrid-event-dot {
      border: calc(var(--fc-daygrid-event-dot-width, 8px) / 2) solid #eb2f96;
    }
    color: rgba(0, 0, 0, 0.85);
  }

  .fc-event-color-volcano {
    .fc-daygrid-event-dot {
      border: calc(var(--fc-daygrid-event-dot-width, 8px) / 2) solid #fa541c;
    }
    color: rgba(0, 0, 0, 0.85);
  }

  .fc-event-color-gold {
    .fc-daygrid-event-dot {
      border: calc(var(--fc-daygrid-event-dot-width, 8px) / 2) solid #faad14;
    }
    color: rgba(0, 0, 0, 0.85);
  }

  .fc-event-color-lime {
    .fc-daygrid-event-dot {
      border: calc(var(--fc-daygrid-event-dot-width, 8px) / 2) solid #a0d911;
    }
    color: rgba(0, 0, 0, 0.85);
  }
`
