/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, {
  ReactNode,
  useContext,
  createContext,
  useState,
  useCallback,
  useEffect,
} from 'react'

interface LocaleProviderProps {
  children: ReactNode
}

interface LocaleContextData {
  locale: 'pt-BR' | 'en-US' | 'es-ES'
  changeLocale: (localeCode: 'pt-BR' | 'en-US' | 'es-ES') => void
}

const LocaleContext = createContext<LocaleContextData>({} as LocaleContextData)

export function LocaleProvider({ children }: LocaleProviderProps) {
  const { language } = navigator

  const esCodes = [
    'es',
    'es-AR',
    'es-GT',
    'es-CR',
    'es-PA',
    'es-DO',
    'es-MX',
    'es-VE',
    'es-CO',
    'es-PE',
    'es-EC',
    'es-CL',
    'es-UY',
    'es-PY',
    'es-BO',
    'es-SV',
    'es-HN',
    'es-NI',
    'es-PR',
  ]

  const handleDefaultLanguage = () => {
    if (language === 'pt-BR' || language === 'pt') {
      return 'pt-BR'
    }
    if (esCodes.find((lang) => lang === language)) {
      return 'es-ES'
    }
    return 'en-US'
  }

  const [locale, setLocale] = useState<'pt-BR' | 'en-US' | 'es-ES'>(
    handleDefaultLanguage(),
  )

  const changeLocale = useCallback(
    (localeCode: 'pt-BR' | 'en-US' | 'es-ES') => {
      setLocale(localeCode)
      localStorage.setItem('@Gstor:locale', localeCode)
    },
    [],
  )

  useEffect(() => {
    const code = localStorage.getItem('@Gstor:locale')

    if (code === 'pt-BR' || code === 'en-US' || code === 'es-ES') {
      setLocale(code)
      localStorage.setItem('@Gstor:locale', code)
    }
  }, [])

  return (
    <LocaleContext.Provider
      value={{
        locale,
        changeLocale,
      }}
    >
      {children}
    </LocaleContext.Provider>
  )
}

export function useLocale() {
  const context = useContext(LocaleContext)

  return context
}
