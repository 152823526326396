export default {
  pt: {
    TasksRegistrationTypeTasks: {
      cardTitle: 'Tipos de Tarefas',
      totalText: 'Total de',
      taskTypeText: 'tipo de tarefa',
      tasksTypesText: 'tipos de tarefas',
      addTaskType: 'Adicionar Tipo de Tarefa',
    },
  },
  en: {
    TasksRegistrationTypeTasks: {
      cardTitle: 'Types of Tasks',
      totalText: 'Total of',
      taskTypeText: 'task type',
      tasksTypesText: 'types of tasks',
      addTaskType: 'Add Task Type',
    },
  },
  es: {
    TasksRegistrationTypeTasks: {
      cardTitle: 'Tipos de tareas',
      totalText: 'Total de',
      taskTypeText: 'tipo de tarea',
      tasksTypesText: 'tipos de tareas',
      addTaskType: 'Añadir Tipo de Tarea',
    },
  },
}
