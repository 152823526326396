import React from 'react'

import FinancialReceive from '~/components/DataManipulation/Financial/Receive'
import { TeamsTasksProvider } from '~/hooks/Teams/useListTeamsTasks'

const FinancialTeamsPage: React.FC = () => {
  return (
    <TeamsTasksProvider>
      <FinancialReceive />
    </TeamsTasksProvider>
  )
}

export default FinancialTeamsPage
