/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable import/no-duplicates */
/* eslint-disable react-hooks/exhaustive-deps */
// import { formatDistance } from 'date-fns';
import { Tag } from 'antd'
import Text from 'antd/lib/typography/Text'
import { add, differenceInSeconds, formatDistance, isPast } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import React, { useEffect, useState } from 'react'
import { translate } from '~/utils/locale'

// import { Container } from './styles';

interface ToleranceTimeCountdownProps {
  toleranceTime: string
  status: string
  created_at: string
}

const ToleranceTimeCountdown: React.FC<ToleranceTimeCountdownProps> = ({
  toleranceTime,
  status,
  created_at,
}) => {
  const hourExceptionTaskReplace = toleranceTime.replace(/\D/g, '')

  const hoursFormatted: any = hourExceptionTaskReplace?.substring(2, 0)
  const minutesFormatted: any = hourExceptionTaskReplace?.substring(2, 4)

  // const hours: any = hoursFormatted;
  // const minutes: any = minutesFormatted;
  // const seconds: any = 0;

  const newDate = add(new Date(created_at), {
    hours: hoursFormatted,
    minutes: minutesFormatted,
    seconds: 0,
  })

  const test = formatDistance(newDate, new Date(), {
    locale: ptBR,
  })

  const pastDate = isPast(newDate)

  // const duration = hours * 60 * 60 + minutes * 60 + seconds;

  // useEffect(() => {
  //   if (statusCountdown !== 'finished' && toleranceTime) {
  //     let time = duration;

  //     const interval = setInterval(() => {
  //       hours = Math.floor(time / 60 / 60);
  //       minutes = Math.floor(time / 60 - hours * 60);
  //       seconds = Math.floor(time % 60);

  //       hours = hours < 10 ? `0${hours}` : hours;
  //       minutes = minutes < 10 ? `0${minutes}` : minutes;
  //       seconds = seconds < 10 ? `0${seconds}` : seconds;

  //       time -= 1;

  //       setText(`${hours}:${minutes}:${seconds}`);
  //       localStorage.setItem(`${id}`, `${hours}:${minutes}:${seconds}`);

  //       if (time < 0) {
  //         clearInterval(interval);
  //         setText('00:00:00');

  //         localStorage.setItem('@hourTaskType', new Date().toString());
  //         localStorage.setItem(`@statusCountdown${id}`, 'finished');
  //       }
  //     }, 1000);
  //   }
  // }, []);

  // const hourBetween: any = localStorage.getItem('@hourTaskType');
  // const distance = formatDistance(new Date(), new Date(hourBetween), {
  //   locale: ptBR,
  // });

  // const clock = localStorage.getItem(`${id}`);

  return (
    <div>
      {status === 'received' || status === 'viewed' ? (
        <>
          {!pastDate ? (
            <Tag color="green">
              {translate('TasksTabsDetails.youHaveText')} {test}{' '}
              {translate('TasksTabsDetails.startTaskText')}
            </Tag>
          ) : (
            <Tag color="red">
              {translate('TasksTabsDetails.theTaskLateText')} {test}{' '}
              {translate('TasksTabsDetails.lateStartTaskText')}
            </Tag>
          )}
        </>
      ) : (
        ''
      )}
    </div>
  )
}

export default ToleranceTimeCountdown
