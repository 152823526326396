export default {
  pt: {
    useTaskForms: {
      getErrorMessage: 'Erro ao visualizar formulário.',
      createSuccessMessage: 'Formulário criada com sucesso!',
      createErrorMessage: 'Erro ao criar formulário.',
      updateSuccessMessage: 'Formulário atualizada com sucesso!',
      updateErrorMessage: 'Erro ao atualizar formulário.',
      updateGenericError: 'Erro ao atualizar.',
      deleteSuccessMessage: 'Formulário deletado com sucesso!',
      deleteErrorMessage: 'Erro ao excluir formulário.',
    },
  },
  en: {
    useTaskForms: {
      getErrorMessage: 'Error viewing form.',
      createSuccessMessage: 'Form created successfully!',
      createErrorMessage: 'Error creating form.',
      updateSuccessMessage: 'Form updated successfully!',
      updateErrorMessage: 'Error updating form.',
      updateGenericError: 'Error updating.',
      deleteSuccessMessage: 'Form deleted successfully!',
      deleteErrorMessage: 'Error deleting form.',
    },
  },
  es: {
    useTaskForms: {
      getErrorMessage: 'Error al ver el forma.',
      createSuccessMessage: '¡Forma creado con éxito!',
      createErrorMessage: 'Error al crear forma.',
      updateSuccessMessage: '¡Forma actualizado con éxito!',
      updateErrorMessage: 'Error al actualizar el forma.',
      updateGenericError: 'Error al actualizar.',
      deleteSuccessMessage: '¡Forma eliminado con éxito!',
      deleteErrorMessage: 'Error al eliminar forma.',
    },
  },
}
