export default {
  pt: {
    TicketTypeCreate: {
      addButton: 'Adicionar Tipo de Solicitação',
      modalTitle: 'Novo Tipo de Solicitação',
      keepRegisterText: 'Continuar cadastrando Tipos de Solicitação?',
      closeButton: 'Fechar',
      saveButton: 'Salvar',
      titleLabel: 'Título',
      titleRule: 'Por favor informe o título do tipo de solicitação.',
      titlePlaceholder: 'Informe o título',
      descriptionLabel: 'Descrição',
      descriptionRule: 'Por favor informe a descrição do tipo de solicitação.',
    },
  },
  en: {
    TicketTypeCreate: {
      addButton: 'Add Ticket Type',
      modalTitle: 'New Ticket Type',
      keepRegisterText: 'Continue registering Tickets Type?',
      closeButton: 'Close',
      saveButton: 'Save',
      titleLabel: 'Title',
      titleRule: 'Please enter the ticket type title.',
      titlePlaceholder: 'Inform the title',
      descriptionLabel: 'Description',
      descriptionRule: 'Please provide a description of the ticket type.',
    },
  },
  es: {
    TicketTypeCreate: {
      addButton: 'Añadir Tipos de Solicitudes',
      modalTitle: 'Nuevo Tipos de Solicitudes',
      keepRegisterText: 'Continuar registrando tipos de solicitudes?',
      closeButton: 'Cerrar',
      saveButton: 'Guardar',
      titleLabel: 'Título',
      titleRule: 'Ingrese el título del tipo de solicitude.',
      titlePlaceholder: 'Informar el título',
      descriptionLabel: 'Descripción',
      descriptionRule: 'Proporcione una descripción del tipo de solicitude.',
    },
  },
}
