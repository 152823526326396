import { lighten } from 'polished'
import styled, { css } from 'styled-components'

interface ContainerProps {
  isFilter: boolean
}

export const Container = styled.div<ContainerProps>`
  #btn_filter_scales {
    background: var(--secondary-color);
    border: 1px solid var(--secondary-color);

    &:hover {
      background: ${lighten(0.05, '#64C77C')};
      border: 1px solid ${lighten(0.1, '#64C77C')};
    }

    ${(props) =>
      props.isFilter &&
      css`
        background: var(--color-danger);
        border: 1px solid var(--color-danger);
        &:hover {
          background: ${lighten(0.05, '#D82143')};
          border: 1px solid ${lighten(0.1, '#D82143')};
        }
      `}
  }
`
