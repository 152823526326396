export default {
  pt: {
    ClientsPrincipalTabsDetails: {
      reasonText: 'Razão Social / Nome',
      fantasyText: 'Nome Fantasia',
      cnpjText: 'CNPJ',
      cnpjUndefined: 'Nenhum cnpj foi fornecido.',
      cpfText: 'CPF',
      cpfUndefined: 'Nenhum cpf foi fornecido.',
      registrationMunicipalText: 'Inscrição Municipal',
      registrationMunicipalUndefined:
        'Nenhuma inscrição municipal foi fornecida.',
      registrationStateText: 'Inscrição Estadual',
      registrationStateUndefined: 'Nenhuma inscrição estadual foi fornecida.',
      nameText: 'Nome',
      emailText: 'E-mail',
      phoneText: 'Celular',
      phoneUndefined: 'Nenhum telefone foi fornecido.',
      addressCardTitle: 'Endereço',
      zipcodeText: 'CEP',
      addressText: 'Endereço',
      cityText: 'Cidade',
      deleteButton: 'Excluir',
      deleteTooltip: 'Excluir Cliente',
      deletePopConfirm: 'Deseja mesmo excluir o cliente?',
      editButton: 'Editar',
    },
  },
  en: {
    ClientsPrincipalTabsDetails: {
      reasonText: 'Corporate name / Name',
      fantasyText: 'Fantasy Name',
      cnpjText: 'CNPJ',
      cnpjUndefined: 'No CNPJ was provided.',
      cpfText: 'CPF',
      cpfUndefined: 'No CPF was provided.',
      registrationMunicipalText: 'Municipal Registration',
      registrationMunicipalUndefined: 'No municipal registration was provided.',
      registrationStateText: 'State Registration',
      registrationStateUndefined: 'No state registration was provided.',
      nameText: 'Name',
      emailText: 'E-mail',
      phoneText: 'Phone',
      phoneUndefined: 'No telephone was provided.',
      zipcodeText: 'Zipcode',
      addressCardTitle: 'Address',
      addressText: 'Address',
      cityText: 'City',
      deleteButton: 'Delete',
      deleteTooltip: 'Delete Client',
      deletePopConfirm: 'Do you really want to delete the customer?',
      editButton: 'Edit',
    },
  },
  es: {
    ClientsPrincipalTabsDetails: {
      reasonText: 'Razón social / Nombre',
      fantasyText: 'Nombre de Fantasía',
      cnpjText: 'CNPJ',
      cnpjUndefined: 'No se proporcionó ningún CNPJ.',
      cpfText: 'CPF',
      cpfUndefined: 'No se proporcionó ningún CPF.',
      registrationMunicipalText: 'Inscrição Municipal',
      registrationMunicipalUndefined:
        'No se proporcionó ningún registro municipal.',
      registrationStateText: 'Inscrição Estadual',
      registrationStateUndefined: 'No se proporcionó ningún registro estadual.',
      nameText: 'Nombre',
      emailText: 'E-mail',
      phoneText: 'Celular',
      phoneUndefined: 'No se proporcionó ningún teléfono.',
      zipcodeText: 'Código Postal',
      addressCardTitle: 'Direccíon',
      addressText: 'Direccíon',
      cityText: 'Ciudad',
      deleteButton: 'Borrar',
      deleteTooltip: 'Borrar cliente',
      deletePopConfirm: '¿Realmente desea borrar al cliente?',
      editButton: 'Editar',
    },
  },
}
