import { LoadingOutlined } from '@ant-design/icons'
import { Card, Space } from 'antd'
import Text from 'antd/lib/typography/Text'
import React from 'react'
import { useListTemplate } from '~/hooks/Equipments/Especification/ListTemplate'
import { translate } from '~/utils/locale'
import CreateTemplate from './Create'
import TemplateTable from './Table'

const TemplateBoard: React.FC = () => {
  const { isLoading, isFetching, templateTotal } = useListTemplate()

  return (
    <Card
      title={
        <Space size="large">
          <b>{translate('EquipmentsTemplate.cardTitle')}</b>
          {isLoading ? (
            <LoadingOutlined spin />
          ) : (
            <Text
              type="secondary"
              style={{ fontWeight: 'normal', fontSize: 14 }}
            >
              {templateTotal !== undefined ? (
                <>
                  {templateTotal === 1
                    ? `${translate(
                        'EquipmentsTemplate.totalText',
                      )} ${templateTotal} ${translate(
                        'EquipmentsTemplate.templateText',
                      )}`
                    : `${translate(
                        'EquipmentsTemplate.totalText',
                      )} ${templateTotal} ${translate(
                        'EquipmentsTemplate.templatesText',
                      )}`}
                </>
              ) : (
                ''
              )}
            </Text>
          )}
          {!isLoading && isFetching && <LoadingOutlined spin />}
        </Space>
      }
      extra={<CreateTemplate />}
      bordered={false}
      style={{ width: '100%', padding: 30 }}
    >
      <TemplateTable />
    </Card>
  )
}

export default TemplateBoard
