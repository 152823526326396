/* eslint-disable camelcase */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  FileUnknownOutlined,
  LoadingOutlined,
  PlusCircleOutlined,
  PlusOutlined,
  ReloadOutlined,
} from '@ant-design/icons'
import {
  Button,
  Modal,
  Tooltip,
  Table,
  Popconfirm,
  Space,
  Collapse,
} from 'antd'
import { ColumnsType } from 'antd/lib/table'

import React, { useState } from 'react'

import Text from 'antd/lib/typography/Text'

import { useTaskType } from '~/hooks/Tasks/TaskType/useTaskType'
import { translate } from '~/utils/locale'
import TaskTypesFilterModal from './TaskTypesFilterModal'
import { Link } from 'react-router-dom'
import { useParentCustomerFields } from '~/hooks/Clients/useParentCustomerFields'

interface MandatoryProps {
  is_active: boolean
  name: string
  type: string
  value: boolean
}

interface TaskTypeProps {
  description: string
  is_enabled: boolean
  runtime: string
  tolerance_time: string
  _id: string
  createdAt: string
  title: string
  mandatory: MandatoryProps[]
  amount_to_pay: number
  amount_to_receive: number
}

const TaskTypeModalParentCustomer: React.FC = () => {
  const { isFetching, taskType } = useTaskType()
  const { taskTypeData, setTaskTypeData } = useParentCustomerFields()

  const [isModalVisible, setIsModalVisible] = useState(false)

  const { Panel } = Collapse

  const typeTasksFormatted = taskType?.map((task: TaskTypeProps) => {
    const dataFormat = {
      ...task,
      runtimeFormat: task?.runtime?.substring(0, 5),
      toleranceFormat: task?.tolerance_time?.substring(0, 5),
    }

    return dataFormat
  })

  async function handleTaskType(id: string, amount_to_pay: number) {
    await setTaskTypeData({ id, amount_to_pay })
    setIsModalVisible(false)
  }

  const columns: ColumnsType<TaskTypeProps> = [
    {
      title: translate('TasksCreateModalTaskType.actionsColumn'),
      align: 'center',
      key: 'remove',
      render: (record) => (
        <Popconfirm
          title={translate('TasksCreateModalTaskType.addPopconfirm')}
          onConfirm={() => handleTaskType(record._id, record.amount_to_pay)}
        >
          <Tooltip title={translate('TasksCreateModalTaskType.addTooltip')}>
            <Button type="primary" shape="circle" icon={<PlusOutlined />} />
          </Tooltip>
        </Popconfirm>
      ),
    },
    {
      title: translate('TasksCreateModalTaskType.taskTypeColumn'),
      key: 'title',
      dataIndex: 'title',
    },
    {
      title: translate('TasksCreateModalTaskType.runtimeColumn'),
      key: 'runtime',
      dataIndex: 'runtimeFormat',
    },
    {
      title: translate('TasksCreateModalTaskType.descriptionColumn'),
      key: 'description',
      dataIndex: 'description',
      render: (description: string, record) => (
        <>
          <Collapse bordered={false}>
            <Panel
              header="Ver descrição"
              key={record._id}
              style={{ padding: -10, border: 'none', borderRadius: 5 }}
            >
              {description || (
                <Text type="secondary">
                  <Space size="small">
                    <FileUnknownOutlined />
                    N/A
                  </Space>
                </Text>
              )}
            </Panel>
          </Collapse>
        </>
      ),
    },
  ]

  const showModal = () => {
    setIsModalVisible(true)
  }

  const closeModal = () => {
    setIsModalVisible(false)
  }

  return (
    <>
      {!taskTypeData?.id ? (
        <Button
          icon={<PlusCircleOutlined />}
          onClick={showModal}
          style={{ width: '100%' }}
        >
          {translate('ClientsParentTaskTypeModalCreate.buttonText')}
        </Button>
      ) : (
        <Button size="small" icon={<ReloadOutlined />} onClick={showModal}>
          {translate('ClientsParentTaskTypeModalCreate.updateButtonText')}
        </Button>
      )}
      <Modal
        title={translate('TasksCreateModalTaskType.modalTitle')}
        open={isModalVisible}
        onOk={closeModal}
        onCancel={closeModal}
        width="900px"
        style={{
          top: 30,
          maxHeight: 'calc(100vh - 100px)',
        }}
        footer={[
          <Space key="FooterProps">
            <Button key="back" onClick={closeModal}>
              {translate('TasksCreateModalClient.closeButton')}
            </Button>
            <TaskTypesFilterModal />
            <Link target="_blank" to="/tiposDeTarefas/novo">
              <Button type="primary" icon={<PlusCircleOutlined />}>
                {translate('TasksRegistrationTypeTasks.addTaskType')}
              </Button>
            </Link>
          </Space>,
        ]}
      >
        <Table
          rowKey={(record) => record._id}
          loading={{
            indicator: <LoadingOutlined />,
            spinning: isFetching,
            size: 'large',
          }}
          columns={columns}
          dataSource={typeTasksFormatted}
          scroll={{ x: 1000 }}
          size="small"
          pagination={{
            defaultPageSize: 5,
            showTotal: (total, range) => `${range[0]} - ${range[1]} / ${total}`,
          }}
        />
      </Modal>
    </>
  )
}

export default TaskTypeModalParentCustomer
