/* eslint-disable react/jsx-one-expression-per-line */
// import { LoadingOutlined } from '@ant-design/icons'
import { Button, Card, Form, Space, Steps } from 'antd'
// import Text from 'antd/lib/typography/Text'
import React, { useState } from 'react'
import Mini from './Preview'
import Template01 from './Cards/Template01'
import Template02 from './Cards/Template02'
import Template03 from './Cards/Template03'
import { useBadge } from '~/hooks/Badge/useBadge'
import { useHistory } from 'react-router-dom'
import { ContainerButton } from './styles'
import { translate } from '~/utils/locale'

// import { translate } from '~/utils/locale'

const CreateBadge: React.FC = () => {
  const { createBadge, dataBadge } = useBadge()
  const [current, setCurrent] = useState(0)

  const template = localStorage.getItem('@gstorTemplateCracha')

  const history = useHistory()

  const next = () => {
    setCurrent(current + 1)
  }

  const prev = () => {
    setCurrent(current - 1)
  }

  const steps = [
    {
      title: translate('BadgeComponent.stepOneTitle'),
      description: translate('BadgeComponent.stepOneDescription'),
      content: <Mini />,
    },
    {
      title: translate('BadgeComponent.stepTwoTitle'),
      description: translate('BadgeComponent.stepTwoDescription'),
      content: (
        <>
          {template === 'Template01' ? <Template01 /> : ''}
          {template === 'Template02' ? <Template02 /> : ''}
          {template === 'Template03' ? <Template03 /> : ''}
        </>
      ),
    },
  ]

  function onCreateBadge() {
    try {
      createBadge(dataBadge)

      localStorage.removeItem('@gstorTemplateCracha')
      history.push('/cracha')
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Card
      title={
        <Space size="large">
          <b>Criar Crachá</b>
        </Space>
      }
      bordered={false}
      style={{ width: '100%', padding: 30 }}
    >
      <Form>
        <Steps items={steps} current={current}></Steps>
        <div>{steps[current].content}</div>
      </Form>

      <Space style={{ marginTop: 24 }}>
        {current < steps.length - 1 && (
          <Button type="primary" onClick={() => next()}>
            {translate('BadgeComponent.nextButton')}
          </Button>
        )}
        {current === steps.length - 1 && (
          <ContainerButton>
            <Button
              type="primary"
              onClick={onCreateBadge}
              id="btn_create_badge"
            >
              {translate('BadgeComponent.saveButton')}
            </Button>
          </ContainerButton>
        )}
        {current > 0 && (
          <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
            {translate('BadgeComponent.backButton')}
          </Button>
        )}
      </Space>
    </Card>
  )
}

export default CreateBadge
