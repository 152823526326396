export default {
  pt: {
    TasksRegistrationFormsAbout: {
      editTooltip: 'Editar',
      modalTitle: 'Editar formulário',
      closeButton: 'Fechar',
      saveButton: 'Salvar',
      detailTab: 'Detalhes',
      titleLabel: 'Título',
      titlePlaceholder: 'Insira o título',
      descriptionLabel: 'Descrição',
      descriptionPlaceholder: 'Insira a descrição',
      obligationsTab: 'Obrigatoriedades',
      statusLabel: 'Status',
      statusActive: 'Ativo',
      statusInactive: 'Inativo',
    },
  },
  en: {
    TasksRegistrationFormsAbout: {
      editTooltip: 'Edit',
      modalTitle: 'Edit Form',
      closeButton: 'Close',
      saveButton: 'Save',
      detailTab: 'Details',
      titleLabel: 'Title',
      titlePlaceholder: 'Enter the title',
      descriptionLabel: 'Description',
      descriptionPlaceholder: 'Enter description',
      obligationsTab: 'Obligations',
      statusLabel: 'Status',
      statusActive: 'Active',
      statusInactive: 'Inactive',
    },
  },
  es: {
    TasksRegistrationFormsAbout: {
      editTooltip: 'Editar',
      modalTitle: 'Editar tipo de tarea',
      closeButton: 'Cerrar',
      saveButton: 'Guardar',
      detailTab: 'Detalles',
      titleLabel: 'Título',
      titlePlaceholder: 'Ingresa el titulo',
      descriptionLabel: 'Descripción',
      descriptionPlaceholder: 'Ingrese la descripción',
      obligationsTab: 'Obligaciones',
      statusLabel: 'Status',
      statusActive: 'Ativo',
      statusInactive: 'Inativo',
    },
  },
}
