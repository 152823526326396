import React, { useState } from 'react'

import { Form, Input, message, Modal, Select } from 'antd'

import { useCompanies } from '~/hooks/Companies/useCompanies'
import { translate } from '~/utils/locale'

interface FormProps {
  name: string
}

interface NewCompanyModalProps {
  isOpen: boolean
  onRequestClose: () => void
}

const NewCompanyModal: React.FC<NewCompanyModalProps> = ({
  isOpen,
  onRequestClose,
}) => {
  const [form] = Form.useForm()
  const { createCompany } = useCompanies()

  const [confirmLoading, setConfirmLoading] = useState(false)

  async function handleCreateNewCompany(data: FormProps) {
    try {
      setConfirmLoading(true)
      await createCompany(data)
      setConfirmLoading(false)
      message.success(translate('NewCompanyModal.successMessage'))
    } catch (error) {
      setConfirmLoading(false)
      message.error(translate('NewCompanyModal.errorMessage'))
    }

    onRequestClose()
  }

  return (
    <Modal
      open={isOpen}
      title={translate('NewCompanyModal.title')}
      okText={translate('NewCompanyModal.okText')}
      cancelText={translate('NewCompanyModal.cancelText')}
      confirmLoading={confirmLoading}
      onCancel={onRequestClose}
      onOk={() => {
        form
          .validateFields()
          .then((values: FormProps) => {
            form.resetFields()
            handleCreateNewCompany(values)
          })
          .catch(() => {
            message.error(translate('NewCompanyModal.verifyErrorMessage'))
          })
      }}
    >
      <Form form={form} layout="vertical" name="form_in_modal">
        <Form.Item
          name="name"
          label={translate('NewCompanyModal.nameLabel')}
          rules={[
            {
              required: true,
              message: translate('NewCompanyModal.nameValidationError'),
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="contracted_plan"
          label={translate('NewCompanyModal.planLabel')}
          rules={[
            {
              required: true,
              message: translate('NewCompanyModal.planValidationError'),
            },
          ]}
        >
          <Select>
            <Select.Option value="each_collaborator">
              {translate('NewCompanyModal.selectOptionOne')}
            </Select.Option>
            <Select.Option value="service_packages">
              {translate('NewCompanyModal.selectOptionTwo')}
            </Select.Option>
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default NewCompanyModal
