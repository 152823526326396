/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable consistent-return */
import React, { useContext, createContext, useState, useEffect } from 'react'

import { useQuery } from 'react-query'
import api from '../../../services/api'
import { useCompanies } from '../../Companies/useCompanies'
import { ShowError } from '~/utils/errors/apiErrors'
import { TaskListProps } from './tasksStatusModel'
import { useFilterKanban } from './useFilterKanban'

import { useLocale } from '~/hooks/locale/useLocale'

interface TaskReceivedContextData {
  taskReceived?: TaskListProps[]
  isLoading: boolean
  refetch: () => void
  isFetching: boolean
  loadDataReceived: () => void
  hasMore: boolean
  tasksReceivedTotal?: number
}

const TaskReceivedContext = createContext<TaskReceivedContextData>(
  {} as TaskReceivedContextData,
)

export const TaskReceivedProvider: React.FC = ({ children }) => {
  const { selectedCompany } = useCompanies()
  const { paramsFilter, filterOptions } = useFilterKanban()
  const { locale } = useLocale()

  const [tasksReceivedTotal, setTasksReceivedTotal] = useState<number>()
  const [hasMore, setHasMore] = useState(true)

  const [limit, setLimit] = useState<number>(20)
  const limitDefault = 5

  const {
    data: taskReceived,
    isLoading,
    refetch,
    isFetching,
  } = useQuery(
    [`tarefas confirmadas${selectedCompany}`, selectedCompany, filterOptions],

    async () => {
      try {
        const response = await api.get(`/company/${selectedCompany}/tasks`, {
          params: {
            offset: 0,
            limit,
            current_status: 'received',
            responsibleContributors: paramsFilter?.responsibleContributors,
            responsibleTeams: paramsFilter?.responsibleTeams,
            address: paramsFilter?.address,
            customers_id: paramsFilter?.customers_id,
            internal_code: paramsFilter?.internal_code,
            external_code: paramsFilter?.external_code,
            task_type_id: paramsFilter?.task_type_id,
            priority: paramsFilter?.priority,
            fromDate: paramsFilter?.fromDate,
            toDate: paramsFilter?.toDate,
          },
        })

        const { data } = response

        setTasksReceivedTotal(data.total)

        return data.results
      } catch (err: any) {
        ShowError(err.message, 'Erro ao visualizar o status da tarefa.', locale)
      }
    },
  )

  function loadDataReceived() {
    setLimit(limit + limitDefault)
    setHasMore(false)

    refetch()
  }

  useEffect(() => {
    refetch()
  }, [selectedCompany, refetch])

  return (
    <TaskReceivedContext.Provider
      value={{
        taskReceived,
        isLoading,
        refetch,
        isFetching,
        tasksReceivedTotal,
        loadDataReceived,
        hasMore,
      }}
    >
      {children}
    </TaskReceivedContext.Provider>
  )
}

export function useTaskReceived(): TaskReceivedContextData {
  const context = useContext(TaskReceivedContext)

  if (!context) {
    throw new Error('useAuth must be used within an AutoProvider')
  }

  return context
}
