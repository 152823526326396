/**
 * Converte o URL de uma imagem para o formato base64.
 * @param {string} url - O URL da imagem.
 * @returns {Promise<string>} Uma promessa que resolve com a representação em base64 da imagem.
 * @throws {Error} Lança um erro se houver problemas ao carregar a imagem.
 */
export async function convertImageUrlToBase64(url: string): Promise<string> {
  return new Promise((resolve, reject) => {
    const image = new Image()
    image.crossOrigin = 'anonymous'

    image.onload = () => {
      const canvas = document.createElement('canvas')
      canvas.width = image.width
      canvas.height = image.height

      const context = canvas.getContext('2d')

      if (context) {
        context.drawImage(image, 0, 0, image.width, image.height)

        const dataURL = canvas.toDataURL('image/jpeg') // Pode ser "image/png" se necessário

        resolve(dataURL)
      } else {
        reject(new Error('Contexto do canvas é nulo.'))
      }
    }

    image.onerror = () => reject(new Error('Erro ao carregar imagem'))
    image.src =
      'https://n8n.zapform.com.br/webhook/conver-image-to-base64?url=' + url
  })
}
