export default {
  pt: {
    useModule: {
      getErrorMessage: 'Erro ao visualizar módulos.',
    },
  },
  en: {
    useModule: {
      getErrorMessage: 'Error viewing modules.',
    },
  },
  es: {
    useModule: {
      getErrorMessage: 'Error al ver los módulos.',
    },
  },
}
