export default {
  pt: {
    Skill: {
      cardTitle: 'Habilidades',
      totalText: 'Total de',
      skillText: 'habilidade',
      skillsText: 'habilidades',
    },
  },
  en: {
    Skill: {
      cardTitle: 'Skills',
      totalText: 'Total of',
      skillText: 'skill',
      skillsText: 'skills',
    },
  },
  es: {
    Skill: {
      cardTitle: 'Habilidades',
      totalText: 'Total de',
      skillText: 'habilidad',
      skillsText: 'habilidades',
    },
  },
}
