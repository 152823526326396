import React from 'react'

import { Button } from 'antd'
import { DownloadOutlined } from '@ant-design/icons'

import { useReportKilometers } from '~/hooks/Report/Kilometers/useReportKilometers'
import { translate } from '~/utils/locale'
// import { Container } from './styles';

const ExportReportKilometers: React.FC = () => {
  const { exportReportKilometers, urlDownload, dataKm, loading, text } =
    useReportKilometers()

  return (
    <>
      {dataKm ? (
        <Button
          type="primary"
          key="export"
          icon={<DownloadOutlined />}
          loading={loading}
          onClick={exportReportKilometers}
          href={urlDownload}
          target="_blank"
          rel="noopener noreferrer"
          download
          danger={text === translate('ReportKilometersExport.downloadText')}
        >
          {text}
        </Button>
      ) : (
        ''
      )}
    </>
  )
}

export default ExportReportKilometers
